import React from 'react'
import { Component } from 'reflux'
import 'moment/locale/da'
import { KamActions, KamStore } from 'Stores/santanderKamStore'
import styled from 'styled-components'
import SantanderKAMGraph from 'Components/dashboard/SantanderKAMGraph'
import { Card } from 'reactstrap'
import { decodeHtmlEntities } from 'Util/decodeHtmlEntities'

const Styledcard = styled(Card)`
  background-color: white !important;
`

export default class MerchandiseProductsChart extends Component {
  constructor(props) {
    super(props)
    this.stores = [KamStore]
    this.storeKeys = ['loadingProducts', 'kamProducts', 'productKeys', 'selectedKam', 'kamUsersList', 'currentMonth', 'currentYear', 'currentQuarter']
  }

  componentDidMount() {
    KamActions.getCurrentDate()
  }

  getProductDetails(event, kam) {
    event.stopPropagation()
    let name = kam && kam.length && decodeHtmlEntities(event.target.innerHTML)
    let data = kam && kam.length ? kam.filter(item => item.product === name)[0] : kam.data
    window.open(`/dashboard/${this.selectedKam ? this.selectedKam.KAM_UID : this.state.selectedKam.KAM_UID}?product=${data.productID}`, '_blank')
  }

  getTotalKamProductDetails(event) {
    event.preventDefault()
    window.open(`/dashboard/all-kam-products?userUID=${this.selectedKam ? this.selectedKam.KAM_UID : this.state.selectedKam.KAM_UID}`, '_blank')
  }

  render() {
    return (
      <Styledcard>
        <SantanderKAMGraph
          dropdownData={this.state.kamUsersList}
          selectedKam={this.state.selectedKam}
          dropdownOnClick={e => KamActions.getMerchendaiseProducts(e)}
          showDropdown={true}
          enableGridX={true}
          enableGridY={false}
          header={this.state.selectedKam ? this.state.selectedKam.user : 'Ingen KAM valgt'}
          state={this.state.loadingProducts}
          data={this.state.kamProducts}
          keys={this.state.productKeys}
          indexBy={'product'}
          cursor={{ cursor: 'pointer' }}
          showIcon={true}
          layout='horizontal'
          axisBottom={{ format: e => Math.floor(e) === e && e }}
          margin={{ top: 50, right: 40, bottom: 30, left: 250 }}
          longestName={this.state.kamProducts && !!this.state.kamProducts.length && Math.max(...this.state.kamProducts.map(el => el.product.length))}
          pagesCount={Math.ceil(this.state.kamProducts.length / 14)}
          legends={{ translateX: -100 }}
          colors={{
            [this.state.currentMonth]: '#9DA993',
            [this.state.currentYear]: '#E4B4B4',
            [this.state.currentQuarter]: '#A2DCE7'
          }}
          onClick={this.getProductDetails}
          getTotalKamDetails={this.getTotalKamProductDetails}
          axisLeft={{ onClick: e => this.getProductDetails(e, this.state.kamProducts) }}
        />
      </Styledcard>
    )
  }
}
