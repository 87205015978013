/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StatActions, StatStore } from 'Stores/statStore'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'
import 'moment/locale/da'

export default class StatDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [StatStore, LoginStore]
    this.storeKeys = ['stats', 'fetchingStats', 'statElements', 'isAdminAccess', 'settings', 'fetchingSingleStat']
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      StatActions.giveAccess()
    }
    const BrandID = _.get(this.props, 'location.search') && this.props.location.search.split('=')[1]
    StatActions.getStat(this.props.match.params.StatID, BrandID)
  }
  render() {
    if (this.state.fetchingStats || this.state.fetchingSingleStat) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let currStat = this.state.stats.find(e => {
      return e.StatID == this.props.match.params.StatID
    })
    currStat = currStat ? currStat : {}
    let advanceSearchColumns = this.state.statElements.length ? Object.keys(this.state.statElements[0]) : []
    advanceSearchColumns = advanceSearchColumns.map(key => {
      return { key: key, name: null, operators: ['LIKE'] }
    })
    let tableoptions = {
      advanceSearchColumns: advanceSearchColumns,
      fieldFunctions: {
        TrackAndTrace: e => {
          return (
            <a href={'https://gls-group.eu/DK/da/find-pakke?match=' + e.TrackAndTrace} target='_blank' rel='noopener noreferrer'>
              {e.TrackAndTrace}
              <br />
            </a>
          )
        }
      },
      fieldStringFunctions: {
        TrackAndTrace: e => {
          return e.TrackAndTrace
        }
      },
      fieldExportFunctions: {
        TrackAndTrace: e => {
          return e.TrackAndTrace
        }
      }
    }
    return (
      <CollapsibleCard startOpen header={<strong>Statistik : {currStat.moduleName}</strong>}>
        <DynamicTable data={this.state.statElements} options={tableoptions} />
      </CollapsibleCard>
    )
  }
}
