import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, PUT, wrapFilter, POST } = Proxies

export const CustomizationActions = Reflux.createActions([
  'getCustomizations',
  'updateCostumization',
  'createCostumization',
  'giveAccess',
  'getCustomizationsData',
  'toggleActiveCustomization',
  'toggleInActiveCustomization',
  'deleteCustomization'
])

export class CustomizationStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = CustomizationActions
    this.state = {
      customizations: null,
      fetchingData: false,
      isMSBAdmin: false,
      customisedTypes: ['Company', 'Client', 'Brand'],
      showActiveCustomizations: true,
      showInActiveCustomizations: false,
      customizationFilter: 'active'
    }
  }
  getCustomizations() {
    this.setState({ fetchingData: true })
    let nextState = {
      /**/
      fetchingData: false,
      customizations: null
    }
    GET('customisationv2/admin-pool', wrapFilter({})).then(customizations => {
      if (!Array.isArray(customizations)) {
        customizations = [customizations]
      }
      customizations = customizations
        .map(e => {
          if (e.title === 'customCSS') {
            return null
          }
          e.customisationData = JSON.parse(e.customisationData)
          return e
        })
        .filter(e => {
          return e
        })
      nextState.customizations = customizations
      this.setState(nextState)
    })
  }
  updateCostumization(CustomisationID, title, description, customisationData, customisedType, CustomisedID, settings) {
    let data = {}
    Object.assign(data, { CustomisationID, title, description, customisationData, customisedType, CustomisedID })
    PUT('customisationv2', data).then(() => {
      this.state.isMSBAdmin ? this.getCustomizations() : this.getCustomizationsData(settings)
    })
  }
  createCostumization(title, description, customisationData, customisedType, CustomisedID, settings) {
    let data = {}
    Object.assign(data, { description, title, CustomisedID, customisedType })
    Object.assign(data, { customisationData })

    PUT('customisationv2', data).then(response => {
      if (response) {
        this.state.isMSBAdmin ? this.getCustomizations() : this.getCustomizationsData(settings)
      }
    })
  }

  giveAccess() {
    let customisedTypes = ['System', 'Company', 'Client', 'Brand', 'Shop']
    this.setState({ isMSBAdmin: true, customisedTypes })
  }

  getCustomizationsData(data) {
    GET('customisationv2/pool', { ClientID: Number(data.ClientID), CompanyID: Number(data.CompanyID) }).then(customizations => {
      if (!Array.isArray(customizations)) {
        customizations = [customizations]
      }
      let nextState = {}
      customizations = customizations
        .map(e => {
          if (e.title === 'customCSS') {
            return null
          }
          e.customisationData = JSON.parse(e.customisationData)
          return e
        })
        .filter(e => {
          return e
        })
      nextState.customizations = customizations
      this.setState(nextState)
    })
  }
  toggleActiveCustomization() {
    let value = !this.state.showActiveCustomizations ? 'active' : 'both'
    this.setState({ showActiveCustomizations: !this.state.showActiveCustomizations, customizationFilter: value, showInActiveCustomizations: false })
  }
  toggleInActiveCustomization() {
    let value = this.state.showInActiveCustomizations ? 'both' : 'inactive'
    this.setState({ showInActiveCustomizations: !this.state.showInActiveCustomizations, customizationFilter: value, showActiveCustomizations: false })
  }
  deleteCustomization(CustomisationID = null, removed) {
    if (CustomisationID) {
      POST('customisationv2/remove', { CustomisationID, removed: removed ? 0 : 1 }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          this.getCustomizations()
        }
      })
    }
  }
}
