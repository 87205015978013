/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import keyMirror from 'keymirror'

export const MacroActions = createActions([
  'createEmptyMacro',
  'getGroups',
  'clearToast',
  'updateMacroField',
  'getGroupElements',
  'saveMacro',
  'getMacro',
  'setMacroData'
])

export const STATUS = keyMirror({
  GROUPS_FETCHING: null,
  GROUPS_ERROR: null,
  TAKING_INPUT: null,
  MACRO_SUCCESS: null,
  MACRO_ERROR: null,
  MACRO_SAVING: null,
  FETCHING_ELEMENTS: null,
  REDIRECT: null
})

export class MacroStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = MacroActions
    this.state = {
      macroDetails: {
        filterText: ''
      },
      STATUS: STATUS,
      status: STATUS.TAKING_INPUT,
      groupID: null,
      groups: []
    }
  }

  createEmptyMacro() {
    this.setState({ macroDetails: { filterText: '' }, groupID: null })
  }

  getGroups() {
    if (this.state.status === STATUS.TAKING_INPUT && this.state.groups.length) {
      return false
    }
    this.setState({ status: STATUS.GROUPS_FETCHING })
    GET('schemas/groups').then(responseJSON => {
      let nextState = {
        groups: [],
        macroDetails: this.state.macroDetails ? this.state.macroDetails : {}
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.GROUPS_ERROR
        } else {
          nextState.status = STATUS.TAKING_INPUT
          nextState.groups = responseJSON
        }
      }
      this.setState(nextState)
    })
  }

  clearToast(data) {
    data ? this.setState({ status: data }) : this.setState({ status: STATUS.TAKING_INPUT })
  }

  updateMacroField(field) {
    let name = field.target.name
    let value = strip_tags(field.target.value)
    let macroDetails = this.state.macroDetails
    if (name === 'walletAmountPoints') {
      const regExp = /^([0-9]*)$/
      macroDetails[field.target.name] = regExp.test(field.target.value)
        ? field.target.value
        : field.target.value == ''
        ? field.target.value
        : field.target.defaultValue
    } else {
      macroDetails[name] = value
    }
    this.setState({ macroDetails })
  }

  getGroupElements() {
    let groupID = this.state.groupID
    if (groupID && groupID !== this.state.macroDetails.GroupID) {
      groupID = this.state.macroDetails.GroupID
      this.setState({ groupID })
    } else {
      if (!groupID) {
        groupID = this.state.macroDetails.GroupID
        this.setState({ groupID })
      }
    }
  }

  saveMacro() {
    if (this.state.macroDetails.GroupID) {
      this.setState({ status: STATUS.MACRO_SAVING })
      POST('Wallets/macro-points', this.state.macroDetails).then(responseJSON => {
        let nextState = {}
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.MACRO_ERROR
          } else {
            nextState.status = STATUS.MACRO_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }

  setMacroData(macroDetails) {
    this.setState({ macroDetails })
  }
}
