import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { MenuActions, MenuStore, STATUS } from 'Stores/menuStore'
import MenuForm from 'Modules/menu/menuForm'

export default class DatasetDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [MenuStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    MenuActions.getBrands()
    MenuActions.getPoolNames()
    MenuActions.getMenuDetails(this.props.match.params.MenuID)
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_MENU_FAILED) {
      toast.error('FETCHING MENU FAILED', {
        autoClose: 7500,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }

    if (this.state.status === STATUS.MENU_FAILED) {
      toast.error('UPDATE MENU FAILED', {
        autoClose: 3000,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }
    if (this.state.status === STATUS.MENU_SUCCESS) {
      toast.success('UPDATE MENU SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <MenuForm {...this.props} />
      </React.Fragment>
    )
  }
}
