import React from 'react'
import styled from 'styled-components'
import { ResponsiveLine } from '@nivo/line'
import { colorMap } from 'Components/dashboard/colorMap.js'

const ChartWrap = styled.div`
  height: 300px;
`

export default function LineChart({ data, bottomTickRotation, tooltip, legends, axisLeft, yScale, emptyGraph }) {
  return (
    <ChartWrap>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 20, bottom: 40, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ ...{ type: 'linear', min: '0', max: emptyGraph ? 1 : 'auto', stacked: true, reverse: false }, ...yScale }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: bottomTickRotation
        }}
        axisLeft={{
          ...{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: emptyGraph ? '' : 'Total brugere',
            legendOffset: -50,
            legendPosition: 'middle',
            format: emptyGraph ? e => e : '~s'
          },
          ...axisLeft
        }}
        theme={{
          axis: { legend: { text: { fontWeight: 700, fontFamily: '"Open Sans", sans-serif' } } }
        }}
        yFormat={value => new Intl.NumberFormat().format(value)}
        tooltip={tooltip}
        colors={emptyGraph ? 'nivo' : ({ id }) => colorMap[`${id}Color`]}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel='y'
        pointLabelYOffset={-12}
        enableCrosshair={true}
        useMesh={true}
        legends={[
          {
            ...{
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -30,
              itemsSpacing: 30,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: emptyGraph ? 'square' : 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)'
            },
            ...legends
          }
        ]}
      />
    </ChartWrap>
  )
}
