/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import _ from 'lodash'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Form, Input, Row, Col } from 'reactstrap'
import FormField from 'Components/formField'
import { toast } from 'react-toastify'
import { GroupStore, GroupActions } from 'Stores/groupStore'
import { operatorsIntegerField, operatorsDateField, operatorsIDField, operatorsList } from 'Util/operators'
import { LoginStore } from 'Stores/loginStore'
import styled from 'styled-components'

const StyledAnchor = styled.a`
  cursor: pointer !important;
`
const Group = props => {
  if (props.elements) {
    return <GroupElements {...props} />
  }
  if (props.create) {
    return <GroupDetails {...props} />
  }
  if (props.match && props.match.params && props.match.params.GroupID) {
    return <GroupDetails {...props} />
  }
  return <GroupList {...props} />
}

export default Group

class GroupList extends Component {
  constructor(props) {
    super(props)
    this.stores = [GroupStore, LoginStore]
    this.storeKeys = [
      'groups',
      'fetchingGroups',
      'advanceSearchFilters',
      'settings',
      'isAdminAccess',
      'showGroupDeleteMessage',
      'showActiveGroups',
      'showInActiveGroups',
      'groupFilterValue'
    ]
    this.advanceSearchColumns = [
      { key: 'BlogID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'BrandID', name: 'BrandID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'moduleName', name: 'Titel', operators: ['LIKE', 'EXACT'] },
      { key: 'moduleDescription', name: 'Description', operators: ['LIKE', 'EXACT'] },
      { key: 'poolOrder', name: 'Pool Order', operators: ['LIKE', 'EXACT'] },
      { key: 'moduleQuery', name: 'Module Query', operators: ['LIKE', 'EXACT'] }
    ]
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.brandName === 'MSB' &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      GroupActions.giveAccess(true)
    }
    if (this.state.groups.length == 0) {
      GroupActions.getGroups()
    }
  }
  componentDidUpdate() {
    if (this.state.showGroupDeleteMessage) {
      toast.error(`GROUP UPDATE FAILED`, {
        autoClose: 5000,
        onClose: () => {
          GroupActions.clearToast()
        }
      })
      GroupActions.clearToast()
    }
  }
  render() {
    if (this.state.fetchingGroups) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Groups
            {this.state.isAdminAccess && (
              <ButtonGroup className='float-right'>
                <Button outline color='success' onClick={GroupActions.toggleActiveGroup} active={this.state.showActiveGroups}>
                  Active
                </Button>
                <Button outline color='danger' onClick={GroupActions.toggleInActiveGroup} active={this.state.showInActiveGroups}>
                  In-Active
                </Button>
              </ButtonGroup>
            )}
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'GroupID'
            },
            ...(this.state.isAdminAccess && {
              advanceSearchColumns: this.advanceSearchColumns,
              preserveAdvanceSearch: GroupActions.preserveAdvanceSearch,
              advanceSearchFilters: this.state.advanceSearchFilters
            }),
            fieldFunctions: {
              rules: e => {
                let rules = e.rules ? e.rules : []
                return (
                  <React.Fragment>
                    {rules.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          {e.tableName} - {e.datacolumnName}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )
              },
              moduleQuery: e => {
                let acc = []
                if (!e.moduleQueryReadMore && !e.moduleQueryReadLess) {
                  return e.moduleQuery
                }
                if (e.moduleQueryReadMore && !e.moduleQueryReadLess) {
                  acc.push(e.moduleQuery.slice(0, 100) + '...')
                  acc.push(
                    <StyledAnchor key={e.GroupID} onClick={() => GroupActions.toggleQueryContent(e)}>
                      Show More
                    </StyledAnchor>
                  )
                  return acc
                }
                if (!e.moduleQueryReadMore && e.moduleQueryReadLess) {
                  acc.push(e.moduleQuery)
                  acc.push(
                    <StyledAnchor key={e.GroupID} onClick={() => GroupActions.toggleQueryContent(e)}>
                      Show Less
                    </StyledAnchor>
                  )
                  return acc
                }
              }
            },
            fieldExportFunctions: {
              rules: e => {
                let rules = e.rules ? e.rules : []
                let acc = []
                {
                  rules &&
                    rules.map(e => {
                      acc.push(e.tableName + '-' + e.datacolumnName)
                    })
                }
                return acc.join('\n')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'elements' + e.GroupID}>Vis</Tooltip>
                    {this.state.isAdminAccess ? (
                      <Button tag={Link} to={'/group/' + e.GroupID + '/elements?BrandID=' + e.BrandID} id={'elements' + e.GroupID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    ) : (
                      <Button tag={Link} to={'/group/' + e.GroupID + '/elements'} id={'elements' + e.GroupID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    )}
                    <Tooltip target={'edit' + e.GroupID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/group/' + e.GroupID} id={'edit' + e.GroupID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    {this.state.settings && this.state.settings.BrandID !== 9 && (
                      <React.Fragment>
                        <Tooltip target={'delete' + e.GroupID}>Slet</Tooltip>
                        <Button
                          id={'delete' + e.GroupID}
                          color='danger'
                          onClick={() => {
                            if (confirm('Vil du slette denne group?')) {
                              GroupActions.deleteGroup(e.GroupID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='trash' />
                        </Button>
                      </React.Fragment>
                    )}
                    {this.state.settings &&
                      this.state.settings.userRole === 'System' &&
                      this.state.settings.userPrivilege === 'MANAGE' &&
                      this.state.settings.BrandID === 9 &&
                      this.state.settings.brandName === 'MSB' &&
                      e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'deactivate' + e.GroupID}>Deaktivér</Tooltip>
                          <Button
                            id={'deactivate' + e.GroupID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne group?')) {
                                GroupActions.changeGroupStatus(e, true)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-check' />
                          </Button>
                        </React.Fragment>
                      )}

                    {this.state.settings &&
                      this.state.settings.userRole === 'System' &&
                      this.state.settings.userPrivilege === 'MANAGE' &&
                      this.state.settings.BrandID === 9 &&
                      this.state.settings.brandName === 'MSB' &&
                      e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip color='danger' target={'active' + e.GroupID}>
                            Aktivér
                          </Tooltip>
                          <Button
                            color='danger'
                            id={'active' + e.GroupID}
                            onClick={() => {
                              if (confirm('Vil du aktivere denne group?')) {
                                GroupActions.changeGroupStatus(e, false)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}

                    {this.state.settings &&
                      this.state.settings.BrandID === 9 &&
                      this.state.settings.brandName === 'MSB' &&
                      this.state.settings.userRole === 'System' &&
                      this.state.settings.userPrivilege === 'MANAGE' && (
                        <React.Fragment>
                          <Tooltip target={'copy' + e.GroupID}>Kopier</Tooltip>
                          <Button
                            id={'copy' + e.GroupID}
                            onClick={() => {
                              GroupActions.copyGroup(e, this.props)
                            }}
                          >
                            <FontAwesomeIcon icon='copy' />
                          </Button>
                        </React.Fragment>
                      )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            GroupID: '#',
            ...(this.state.isAdminAccess && { BrandID: 'Brand' }),
            moduleName: 'Titel',
            moduleDescription: 'Beskrivelse',
            ...(this.state.isAdminAccess && { moduleQuery: 'Module Query' }),
            rules: 'Regler'
          }}
          data={
            this.state.isAdminAccess && this.state.groups && this.state.groupFilterValue !== 'both' && !!this.state.groups.length
              ? this.state.groups.filter(group => group.removed === (this.state.groupFilterValue === 'inactive' ? 1 : 0))
              : this.state.groups
          }
        />
      </CollapsibleCard>
    )
  }
}

class GroupDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [GroupStore, LoginStore]
    this.storeKeys = [
      'settings',
      'ruleValueEmpty',
      'showGroupError',
      'showGroupSuccess',
      'datasets',
      'fetchingDatasets',
      'fetchingGroupDetails',
      'isAdminAccess',
      'groupDetails',
      'groupRules',
      'brands',
      'isSaving',
      'isPreview',
      'fetchingPreview',
      'previewElements'
    ]
    this.resetGroup = this.resetGroup.bind(this)
  }
  componentWillMount() {
    super.componentWillMount()
    this.resetGroup()
  }
  componentDidUpdate() {
    if (this.state.isAdminAccess === '' && this.state.settings) {
      if (
        this.state.settings.userRole &&
        this.state.settings.BrandID === 9 &&
        this.state.settings.userRole === 'System' &&
        this.state.settings.userPrivilege === 'MANAGE'
      ) {
        GroupActions.giveAccess(true)
        GroupActions.getBrands()
      }
    }
    if (this.state.ruleValueEmpty) {
      toast.warning('Rule value cannot be empty.', { autoClose: 5000, onClose: GroupActions.clearRuleValueError })
      GroupActions.clearRuleValueError()
    }
    if (this.state.showGroupError) {
      if (this.props.create) {
        toast.error('Der opstod en fejl i oprettelsen af gruppen', {
          autoClose: 7500,
          onClose: () => {
            GroupActions.clearToast()
          }
        })
      } else {
        toast.error('Der opstod en fejl ved opdateringen af gruppen', {
          autoClose: 7500,
          onClose: () => {
            GroupActions.clearToast()
          }
        })
      }
      GroupActions.clearToast()
    }
    if (this.state.showGroupSuccess) {
      if (this.props.create) {
        toast.success('Gruppen er oprettet', {
          autoClose: 7500,
          onClose: () => {
            GroupActions.clearToast()
          }
        })
      } else {
        toast.success('Dine ændringer er gemt', {
          autoClose: 7500,
          onClose: () => {
            GroupActions.clearToast()
          }
        })
      }
      GroupActions.clearToast()
    }
  }
  resetGroup() {
    if (this.props.create) {
      if (_.get(this.props, 'location.search')) {
        const search = this.props.location.search.split('=')
        GroupActions.getGroupDetails(search[1], true)
      } else {
        GroupActions.createEmptyGroup()
      }
    } else {
      GroupActions.getGroupDetails(this.props.match.params.GroupID)
    }
  }
  componentDidMount() {
    if (this.state.datasets.length == 0 && !this.state.fetchingDatasets) {
      GroupActions.getDatasets()
    }
    if (
      this.state.settings.userRole &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      GroupActions.giveAccess(true)
      GroupActions.getBrands()
    }
  }
  render() {
    if (this.state.fetchingGroupDetails) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    if (this.state.groupDetails.GroupID && this.props.create) {
      return <Redirect to={'/group/' + this.state.groupDetails.GroupID} />
    }

    let rules = this.state.groupRules.map((rule, indx) => {
      let operators = null
      if (rule.datasetTableName === 'WalletExt') {
        if (rule.datacolumnName === 'walletPointsEarned' || rule.datacolumnName === 'walletPointsSpent') {
          operators = operatorsIntegerField
        } else if (rule.datacolumnName === 'timeInsert') {
          operators = operatorsDateField
        } else if (rule.datacolumnName === 'UserID') {
          operators = operatorsIDField
        } else {
          operators = operatorsList
        }
      } else {
        operators = operatorsList
      }
      return (
        <React.Fragment key={indx}>
          <Row>
            <Col xs='3'>
              <Input type='select' name='DatasetID' id='DatasetID' value={rule.DatasetID} onChange={t => GroupActions.updateDatacolumns(t, indx)}>
                {this.state.datasets.map(e => {
                  return (
                    <option value={e.DatasetID} disabled={e.disabled} style={{ color: e.disabled && '#c7c4c4' }} key={e.DatasetID}>
                      {e.moduleName}
                    </option>
                  )
                })}
              </Input>
            </Col>
            <Col xs='2'>
              <Input
                type='select'
                name='DatacolumnID'
                id='DatacolumnID'
                value={rule.DatacolumnID}
                onChange={t => GroupActions.updateDatacolumns(t, indx)}
              >
                {rule.selectedDatacolumns.map(e => {
                  return (
                    <option value={e.DatacolumnID} key={e.DatacolumnID}>
                      {e.columnName ? e.columnName : e.datacolumnName}
                    </option>
                  )
                })}
              </Input>
            </Col>
            <Col xs='2'>
              <Input type='select' name='Operator' id='Operator' value={rule.ruleOperator} onChange={t => GroupActions.updateDatacolumns(t, indx)}>
                {operators.map(e => {
                  return (
                    <option value={e.value} key={e.value}>
                      {e.name}
                    </option>
                  )
                })}
              </Input>
            </Col>
            {rule.ruleOperator !== 'BETWEEN' && (
              <Col xs='4' className='mb-1'>
                <Input
                  required
                  type={rule.datacolumnName === 'timeInsert' ? 'date' : 'textarea'}
                  name='GroupruleValue'
                  id='GroupruleValue'
                  value={rule.ruleValue}
                  onChange={t => GroupActions.updateDatacolumns(t, indx)}
                />
              </Col>
            )}
            {rule.ruleOperator === 'BETWEEN' && (
              <Col xs='2' className='mb-1'>
                <Input
                  required
                  type={rule.datacolumnName === 'timeInsert' ? 'date' : 'textarea'}
                  name='GroupruleValueLower'
                  id='GroupruleValue'
                  value={rule.datacolumnName === 'timeInsert' ? moment(rule.ruleValueLower).format('YYYY-MM-DD') : rule.ruleValueLower}
                  onChange={t => GroupActions.updateDatacolumns(t, indx)}
                />
              </Col>
            )}
            {rule.ruleOperator === 'BETWEEN' && (
              <Col xs='2' className='mb-1'>
                <Input
                  required
                  type={rule.datacolumnName === 'timeInsert' ? 'date' : 'textarea'}
                  name='GroupruleValueHigher'
                  id='GroupruleValue'
                  value={rule.datacolumnName === 'timeInsert' ? moment(rule.ruleValueHigher).format('YYYY-MM-DD') : rule.ruleValueHigher}
                  onChange={t => GroupActions.updateDatacolumns(t, indx)}
                />
              </Col>
            )}
            <Col xs='1'>
              <Button color='danger' onClick={() => GroupActions.removeRule(indx)} className='ml-2'>
                <FontAwesomeIcon icon='minus' />
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )
    })
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>{this.props.create ? 'Opret' : 'Detaljer'}</strong>}>
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            {this.state.isAdminAccess && (
              <FormField field={'BrandID'} name='Brand'>
                <Input type='select' name='BrandID' value={this.state.groupDetails.BrandID} onChange={GroupActions.updateGroupField}>
                  <option value=''>Select Brand</option>
                  {this.state.brands &&
                    !!this.state.brands.length &&
                    this.state.brands.map(e => {
                      return (
                        <option value={e.BrandID} key={e.BrandID}>
                          {e.brandName}
                        </option>
                      )
                    })}
                </Input>
              </FormField>
            )}
            <FormField name='Navn' field='moduleName' data={this.state.groupDetails} onChange={GroupActions.updateGroupField} required />
            <FormField name='Beskrivelse' field='moduleDescription' data={this.state.groupDetails} onChange={GroupActions.updateGroupField} />
            {this.state.isAdminAccess && (
              <FormField
                type='textarea'
                name='Module Query'
                field='moduleQuery'
                data={this.state.groupDetails}
                onChange={GroupActions.updateGroupField}
              />
            )}
            <FormField name='Regler' field='' data={{}}>
              <Row>
                <Col xs='3'>Tabel</Col>
                <Col xs='3'>Kolonne</Col>
                <Col xs='2'>Operatør</Col>
                <Col xs='3'>Værdi</Col>
                <Col xs='1' className='mb-2'>
                  <Button onClick={() => GroupActions.addRule(operatorsList)} className='ml-2'>
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                </Col>
              </Row>
              {rules}
            </FormField>
            <Button onClick={GroupActions.saveGroup} disabled={this.state.isSaving}>
              {this.state.isSaving ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetGroup} className='ml-2'>
              Nulstil
            </Button>
            <Button color='warning' onClick={GroupActions.previewGroup} className='ml-2'>
              Preview
            </Button>
          </Form>
        </CollapsibleCard>
        {this.state.isPreview && (
          <CollapsibleCard startOpen header={<strong>Gruppe Preview</strong>}>
            {this.state.fetchingPreview && <FontAwesomeIcon icon='spinner' spin />}
            {!this.state.fetchingPreview && (
              <DynamicTable
                responsive
                size='sm'
                options={{
                  startSort: {
                    dir: 'DESC',
                    column: 'UserID'
                  },
                  fieldFunctions: {},
                  addField: () => {
                    return
                  }
                }}
                headers={{
                  UserID: '#',
                  userName: 'Navn',
                  userCompany: 'Virksomhed',
                  userUID: 'UID',
                  userEmail: 'Email',
                  userPhone: 'Telefon'
                }}
                data={this.state.previewElements}
              />
            )}
          </CollapsibleCard>
        )}
      </React.Fragment>
    )
  }
}

class GroupElements extends Component {
  constructor(props) {
    super(props)
    this.stores = [GroupStore, LoginStore]
    this.storeKeys = ['fetchingElements', 'elements']
  }
  componentDidMount() {
    const BrandID = _.get(this.props, 'location.search') && this.props.location.search.split('=')[1]
    this.props.groupID && GroupActions.getElements(this.props.groupID, BrandID)
    _.get(this.props, 'match.params.GroupID') && GroupActions.getElements(this.props.match.params.GroupID, BrandID)
  }
  componentDidUpdate(prevProps) {
    if (_.get(this.props, 'match.params.GroupID') && this.props.match.params.GroupID != prevProps.match.params.GroupID) {
      GroupActions.getElements(this.props.match.params.GroupID)
    }
    if (this.props.groupID && this.props.groupID !== prevProps.groupID) {
      GroupActions.getElements(this.props.groupID)
    }
  }
  render() {
    if (this.state.fetchingElements) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Gruppe detaljer</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'UserID'
            },
            fieldFunctions: {},
            addField: () => {
              return
            }
          }}
          headers={{
            UserID: '#',
            userName: 'Navn',
            userCompany: 'Virksomhed',
            userUID: 'UID',
            userEmail: 'Email',
            userPhone: 'Telefon',
            userAccess: 'Access',
            userPoints: 'Points'
          }}
          data={this.state.elements}
        />
      </CollapsibleCard>
    )
  }
}
