import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Collapse, Input, UncontrolledTooltip as Tooltip } from 'reactstrap'
import DynamicTable from 'Components/dynamicTable'
import { Link } from 'react-router-dom'

function CampaignCollapsible(props) {
  const { campaignData, itemData, notificationcampaign, isOpen, toggle, inx } = props
  const [users, setUsers] = useState(itemData.users || [])

  const filterDate = e => {
    let target = e.target.value
    if (target) {
      let dateFormat = moment(target).format('DD-MM-YYYY')
      let searchedData =
        itemData &&
        itemData.users.filter(data => {
          if (moment(data.timeSent).format('DD-MM-YYYY') === dateFormat) {
            return data
          }
        })
      setUsers(searchedData)
    } else {
      setUsers(itemData.users)
    }
  }

  return (
    <Card style={{ marginBottom: 0 }}>
      <CardHeader onClick={() => toggle(inx)} style={{ cursor: 'pointer' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <h6 style={{ fontWeight: 600, marginLeft: 10 }}>
            UDSENDELSE D.h {moment(itemData && itemData.title === '' ? campaignData.publishDate : itemData.title).format('DD-MM-YYYY')}
          </h6>
          {notificationcampaign && notificationcampaign.length > 1 && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
              <div>Fold sammen</div>
              <FontAwesomeIcon icon='angle-up' style={{ fontWeight: 400, rotate: isOpen ? '0deg' : '180deg', marginTop: 3 }} />
            </div>
          )}
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen === inx ? true : false}>
        <CardBody>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Datosøg:
              <Col xs='6'>
                <Input
                  style={{ width: '270px' }}
                  type='date'
                  name='dateSearch'
                  id='dateSearch'
                  onChange={e => {
                    filterDate(e)
                  }}
                />
              </Col>
            </div>
          </div>
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              fieldFunctions: {
                time: e => {
                  return <Fragment>{!e.timeSent ? '-' : moment(e.timeSent).format('HH:MM:SS')}</Fragment>
                },
                timeSent: e => {
                  return <Fragment>{!e.timeSent ? '-' : moment(e.timeSent).format('DD-MM-YYYY')}</Fragment>
                },
                length: e => {
                  return <Fragment>{e.gateway === 'EMAIL' ? '-' : campaignData && campaignData.smsBody && campaignData.smsBody.length}</Fragment>
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'resend' + e.UserID}>Resend</Tooltip>
                      <Button tag={Link} to={{ pathname: '/campaign/' }} id={'resend' + e.UserID}>
                        <FontAwesomeIcon icon='redo' />
                      </Button>

                      <Tooltip target={'delete' + e.UserID}>Slet</Tooltip>
                      <Button
                        id={'delete' + e.UserID}
                        color='danger'
                        onClick={() => {
                          if (confirm('Are you sure you want to delete?')) {
                            //  CampaignNotificationActions.deleteCampaign(e.NotificationCampaignID)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              UserID: 'User ID',
              userUID: 'USER UID',
              userName: 'User Name',
              userEmail: 'Kontaktinformation',
              userPhone: 'User Mobile',
              timeSent: 'Date',
              time: 'Time',
              length: 'Antal',
              gateway: 'type',
              status: 'Status'
            }}
            data={users}
          />
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default CampaignCollapsible
