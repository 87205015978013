import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { CatalogActions, CatalogStore, STATUS } from 'Stores/catalogStore'
import CatalogForm from 'Modules/catalogs/catalogForm'

export default class CatalogCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [CatalogStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    CatalogActions.createEmptyCatalog()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.CATALOG_SUCCESS) {
      toast.success('Catalog Created', {
        autoClose: 3000,
        onClose: () => {
          CatalogActions.clearFormToast()
        }
      })
      CatalogActions.clearFormToast(STATUS.REDIRECT)
    }
    if (this.state.status === STATUS.CATALOG_ERROR) {
      toast.error('Catalog Create Failed', {
        autoClose: 3000,
        onClose: () => {
          CatalogActions.clearFormToast()
        }
      })
      CatalogActions.clearFormToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <CatalogForm />
      </React.Fragment>
    )
  }
}
