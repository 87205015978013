import React, { useEffect } from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { fetchCron } from 'Reducers/actions/cronActions'
import { useSelector, useDispatch } from 'react-redux'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import styled from 'styled-components'

export default class CronOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <CronOverviewComponent />
      </React.Fragment>
    )
  }
}

function CronOverviewComponent() {
  const dispatch = useDispatch()
  let cronPool = useSelector(state => state.cron.cronPool)
  cronPool = cronPool.slice()
  const scheduleOptions = useSelector(state => state.cron.scheduleOptions)
  useEffect(() => {
    dispatch(fetchCron())
  }, [])
  const StyledDropdown = styled(DropdownItem)`
    padding: 0px 40px !important;
    height: 25px !important;
  `
  const options = {
    addField: () => {
      return (
        <td>
          <UncontrolledDropdown>
            <DropdownToggle caret>Change schedule</DropdownToggle>
            <DropdownMenu style={{ overflow: 'visible' }}>
              {Object.keys(scheduleOptions).map((s, is) => {
                return (
                  <StyledDropdown
                    onClick={() => {
                      confirm(`Are you sure you want to change schedule? [ This feature is coming soon ]`)
                    }}
                    value={s}
                    key={is}
                    style={{ color: 'black' }}
                  >
                    {scheduleOptions[s]}
                  </StyledDropdown>
                )
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      )
    }
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <React.Fragment>
          <strong>List of Cron jobs</strong>{' '}
        </React.Fragment>
      }
    >
      <DynamicTable
        responsive
        size='sm'
        headers={{
          implementationName: 'Task Name',
          brands: `Used for Brand`,
          scheduleEnglish: 'Current schedule',
          endpoint: 'Endpoint',
          scheduleCronFormat: ''
        }}
        data={[...cronPool]}
        options={options}
      />
    </CollapsibleCard>
  )
}
