import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET } = Proxies

export const ProfileActions = createActions(['getProfile'])

export class ProfileStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = ProfileActions
    this.state = {
      fetchingProfile: false,
      profileData: {},
      profileError: {}
    }
  }
  getProfile() {
    this.setState({ fetchingProfile: true })
    GET('profile/data').then(
      profileData => {
        if (profileData.user) {
          profileData = profileData.user
        }
        this.setState({ fetchingProfile: false, profileData })
      },
      profileError => {
        this.setState({ fetchingProfile: false, profileError })
      }
    )
  }
}
