import React from 'react'
import { Component } from 'reflux'
import { CatalogActions, CatalogStore, STATUS } from 'Stores/catalogStore'
import { Button, Form, Input, Row, Col, Label } from 'reactstrap'
import _ from 'lodash'
import CollapsibleCard from 'Components/collapsibleCard'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const catalogTypes = ['TRANSACTION', 'SUBSCRIPTION', 'QUOTE', 'EVENT', 'TRAINING', 'SEASON']
const catalogAccessTypes = ['PRIVATE', 'PROTECTED', 'PUBLIC']

export default class CatalogForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [CatalogStore]
    this.storeKeys = ['status', 'catalogDetails']
  }
  resetCatalog = () => {
    if (_.get(this.props, 'match.params.CatalogID')) {
      CatalogActions.getCatalogDetails(this.props.match.params.CatalogID)
    } else {
      CatalogActions.createEmptyCatalog()
    }
  }
  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/catalogs' />
    }
    if (this.state.status === STATUS.FETCHING_CATALOG) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>{_.get(this.props, 'match.params.CatalogID') ? 'Detaljer' : 'Opret'}</strong>}>
        <Form>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='name'>Name</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={this.state.catalogDetails.moduleName}
                name='moduleName'
                id='moduleName'
                onChange={CatalogActions.updateCatalogField}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='description'>Description</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                rows='2'
                value={this.state.catalogDetails.moduleDescription}
                name='moduleDescription'
                id='moduleDescription'
                onChange={CatalogActions.updateCatalogField}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='catalogType'>Type</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='select'
                name='catalogType'
                value={this.state.catalogDetails.catalogType}
                id='catalogType'
                onChange={CatalogActions.updateCatalogField}
              >
                <option value='' disabled>
                  Click to Select
                </option>
                {catalogTypes.map((catalog, index) => {
                  return (
                    <option value={catalog} key={index}>
                      {catalog}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='catalogAccessType'>Access</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='select'
                name='catalogAccessType'
                value={this.state.catalogDetails.catalogAccessType}
                id='catalogAccessType'
                onChange={CatalogActions.updateCatalogField}
              >
                <option value='' disabled>
                  Click to Select
                </option>
                {catalogAccessTypes.map((catalog, index) => {
                  return (
                    <option value={catalog} key={index}>
                      {catalog}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='lifeStyle'>Expose as lifestyle</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='checkbox'
                value={this.state.catalogDetails.exposeAsLifestyle}
                className='m-1 batch-header'
                checked={this.state.catalogDetails.exposeAsLifestyle}
                name='exposeAsLifestyle'
                onChange={CatalogActions.updateCatalogField}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Schedule</label>
            </Col>
            <Col xs='10'>
              <Row xs='12'>
                <Col xs='6'>
                  <Input
                    type='date'
                    value={this.state.catalogDetails.timeStart}
                    name='timeStart'
                    id='timeStart'
                    onChange={CatalogActions.updateCatalogField}
                  />
                </Col>
                <Col xs='6'>
                  <Input
                    type='date'
                    value={this.state.catalogDetails.timeEnd ? this.state.catalogDetails.timeEnd : ''}
                    name='timeEnd'
                    id='timeEnd'
                    onChange={CatalogActions.updateCatalogField}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Button onClick={CatalogActions.saveCatalog} disabled={this.state.status === STATUS.SAVING}>
            {this.state.status === STATUS.SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={this.resetCatalog} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    )
  }
}
