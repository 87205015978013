import React from 'react'
import { Col, Container, Row } from 'reactstrap'

export default class NotAdmin extends React.Component {
  render() {
    return (
      <div className='app flex-row align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='6'>
              <div className='clearfix'>
                <h1 className='float-left display-3 mr-4'>Forbudt</h1>
                <h4 className='pt-3'>Utilstrækkelige rettigheder.</h4>
                <p className='text-muted float-left'>Du har ikke de fornødne rettigheder til at se denne side.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
