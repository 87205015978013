/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { BatchStore, BatchActions, STATUS } from 'Stores/batchStore'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { Button, Row } from 'reactstrap'
import { DatasetActions, DatasetStore } from 'Stores/datasetStore'
import Styled from 'styled-components'
import moment from 'moment'

const StyledButton = Styled(Button)`
float: right!important;
&:hover{
  background-color: #c8ced3 !important;
  border: 1px solid #c8ced3 !important;
}
`
const StyledError = Styled(Row)`
color: ${props => (props && props.isWarning ? '#E08A1E' : 'red')};
margin-left: 0px !important;
margin-right: 0px !important;`

export default class BatchPreview extends Component {
  constructor(props) {
    super(props)
    this.stores = [BatchStore, DatasetStore]
    this.storeKeys = ['batchPreview', 'currBatchID', 'status', 'batchData', 'batchSaving', 'batchHeaders']
  }
  componentDidMount() {
    DatasetActions.getBatchDetails(this.props.match.params.BatchID)
    BatchActions.getBatchPreview(this.props.match.params.BatchID)
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.BATCH_PREVIEW_ERROR) {
      toast.error('NO BATCH', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.BATCH_DELETE_ERROR) {
      toast.error('BATCH DELETE FAILED', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.BATCH_INSERT_SUCCESS) {
      toast.success('BATCH INSERT SUCCESSFUL', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
      DatasetActions.getBatchDetails(this.props.match.params.BatchID)
    }
  }
  render() {
    if (this.state.status === STATUS.FETCHING_BATCH_PREVIEW) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let customBatch = false
    let options = {}
    let headers = {}
    if (this.state.batchData && this.state.batchData.dataset && this.state.batchData.dataset.datasetTableName === 'Wallet') {
      customBatch = true
      options = {
        fieldFunctions: {
          walletAmountPoints: e => {
            if (e.walletAmountPointsError) {
              return (
                <React.Fragment>
                  <StyledError>{`${e.walletAmountPoints} (${e.walletAmountPointsError})`}</StyledError>
                </React.Fragment>
              )
            } else {
              return <React.Fragment>{e.walletAmountPoints}</React.Fragment>
            }
          },
          walletUserUID: e => {
            if (e.walletUserUIDError) {
              return (
                <React.Fragment>
                  <StyledError>{`${e.walletUserUID} (${e.walletUserUIDError})`}</StyledError>
                </React.Fragment>
              )
            } else {
              return <React.Fragment>{e.walletUserUID}</React.Fragment>
            }
          }
        },
        fieldStringFunctions: {
          timeInsert: e => {
            return moment(e.timeInsert).format('x')
          },
          walletAmountPoints: e => {
            return e.walletAmountPoints
          },
          walletUserUID: e => {
            return e.walletUserUID
          }
        },
        fieldExportFunctions: {
          timeInsert: e => {
            return moment(e.timeInsert).format('DD/MM/YYYY')
          },
          walletAmountPoints: e => {
            return e.walletAmountPointsError ? `${e.walletAmountPoints} (${e.walletAmountPointsError})` : e.walletAmountPoints
          },
          walletUserUID: e => {
            return e.walletUserUIDError ? `${e.walletUserUID} (${e.walletUserUIDError})` : e.walletUserUID
          }
        }
      }
      headers = {
        walletUserUID: 'UID',
        walletAmountPoints: 'Point',
        walletNote: 'Note',
        timeInsert: 'Dato',
        VaultID: 'Vault'
      }
    }

    if (this.state.batchData && this.state.batchData.dataset && this.state.batchData.dataset.datasetTableName === 'User') {
      customBatch = true
      options = {
        fieldFunctions: {
          userUID: e => {
            if (e.userUIDError) {
              return (
                <React.Fragment>
                  <StyledError>{`${e.userUID} (${e.userUIDError})`}</StyledError>
                </React.Fragment>
              )
            } else if (e.userUIDWarning) {
              return (
                <React.Fragment>
                  <StyledError isWarning={true}>{`${e.userUID} (${e.userUIDWarning})`}</StyledError>
                </React.Fragment>
              )
            } else {
              return <React.Fragment>{e.userUID}</React.Fragment>
            }
          }
        },
        fieldStringFunctions: {
          timeInsert: e => {
            return moment(e.timeInsert).format('x')
          },
          userUID: e => {
            return e.userUID
          }
        },
        fieldExportFunctions: {
          timeInsert: e => {
            return moment(e.timeInsert).format('DD/MM/YYYY')
          },
          userUID: e => {
            return e.userUIDError ? `${e.userUID} (${e.userUIDError})` : e.userUIDWarning ? `${e.userUID} (${e.userUIDWarning})` : e.userUID
          }
        }
      }
      headers = {
        userName: 'Navn',
        userUID: 'UID (Unikt ID)',
        userPhone: 'Mobil',
        userEmail: 'Email',
        userCompany: 'Firma',
        userDepartment: 'Afdeling',
        userAddress: 'Adresse',
        userZipcode: 'Postnr.',
        userCity: 'By',
        userCompanyUID: 'Firma CVR',
        userSupervisorUID: 'KAM nr.',
        userSupervisor: 'KAM navn',
        userGroup: 'Gruppe (hvis aktuel)',
        defaultVaultID: 'Vault (hvis aktuel)'
      }
    }

    if (this.state.batchData && this.state.batchData.dataset && this.state.batchData.dataset.datasetTableName === 'Order') {
      customBatch = true
      options = {
        fieldFunctions: {
          userUID: e => {
            if (e.userUIDError) {
              return (
                <React.Fragment>
                  <StyledError>{`${e.userUID} (${e.userUIDError})`}</StyledError>
                </React.Fragment>
              )
            } else {
              return <React.Fragment>{e.userUID}</React.Fragment>
            }
          },
          ProductID: e => {
            if (e.ProductIDError) {
              return (
                <React.Fragment>
                  <StyledError>{`${e.ProductID} (${e.ProductIDError})`}</StyledError>
                </React.Fragment>
              )
            } else {
              return <React.Fragment>{e.ProductID}</React.Fragment>
            }
          },
          variationUID: e => {
            if (e.variationUIDError) {
              return (
                <React.Fragment>
                  <StyledError>{`${e.variationUID} (${e.variationUIDError})`}</StyledError>
                </React.Fragment>
              )
            } else {
              return <React.Fragment>{e.variationUID}</React.Fragment>
            }
          }
        },
        fieldStringFunctions: {
          timeInsert: e => {
            return moment(e.timeInsert).format('x')
          },
          userUID: e => {
            return e.userUID
          },
          ProductID: e => e.ProductID,
          variationUID: e => e.variationUID
        },
        fieldExportFunctions: {
          timeInsert: e => {
            return moment(e.timeInsert).format('DD/MM/YYYY')
          },
          userUID: e => {
            return e.userUIDError ? `${e.userUID} (${e.userUIDError})` : e.userUID
          },
          ProductID: e => (e.ProductIDError ? `${e.ProductID} (${e.ProductIDError})` : e.ProductID),
          variationUID: e => (e.variationUIDError ? `${e.variationUID} (${e.variationUIDError})` : e.variationUID)
        }
      }
      headers = {
        orderConsigneeName: 'Navn',
        userUID: 'UID',
        orderConsigneeEmail: 'Email',
        orderConsigneePhone: 'Mobil',
        orderConsignee: 'Firma',
        orderConsigneeAddress1: 'Adresse',
        orderConsigneeZipcode: 'Postnr.',
        orderConsigneeCity: 'By',
        orderConsigneeCountry: 'Land',
        orderlineTitle: 'Produkt.',
        variationTitle: 'Variation titel',
        variationUID: 'VariationId',
        ProductID: 'ProductID'
      }
    }

    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Batch Preview
            {this.state.batchData && this.state.batchData.batchFlowStatus && (
              <StyledButton
                disabled={this.state.batchData.batchFlowStatus === 'INSERTED' || this.state.batchData.batchFlowStatus === 'MATCHED'}
                title='Insert'
                style={{ margin: '5px' }}
                onClick={() => BatchActions.insertBatch(Number(this.props.match.params.BatchID))}
              >
                Insert {this.state.batchSaving && <FontAwesomeIcon icon='spinner' />}
              </StyledButton>
            )}
          </strong>
        }
      >
        {customBatch && <DynamicTable data={this.state.batchPreview} options={options} headers={headers} />}
        {!customBatch && <DynamicTable data={this.state.batchPreview} />}
      </CollapsibleCard>
    )
  }
}
