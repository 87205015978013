/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { DatasetStore, DatasetActions, STATUS } from 'Stores/datasetStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, CardText, CardBody, Input, Row, Col, Table } from 'reactstrap'
import _ from 'lodash'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  height: 35px !important;
`
const batchRoleOptions = [
  { id: 1, name: 'BRAND', value: 'Brand' },
  { id: 2, name: 'CLIENT', value: 'Client' },
  { id: 3, name: 'COMPANY', value: 'Company' }
]
const batchEncodingOptions = ['UTF-8', 'ISO-8859-1']

export default class DataImport extends Component {
  constructor(props) {
    super(props)
    this.stores = [DatasetStore]
    this.storeKeys = ['batchData', 'mappedCSVRecords', 'datasetDetails', 'status']
  }
  componentDidMount() {
    if (this.props.match.params.DatasetID && !_.get(this.props, 'location.search')) {
      DatasetActions.createEmptyBatch()
      DatasetActions.getDataset(this.props.match.params.DatasetID, 'file-import')
    }
    if (this.props.match.params.DatasetID && this.props.match.params.BatchID) {
      this.props.match.path.includes('edit')
        ? DatasetActions.getBatchDetails(this.props.match.params.BatchID)
        : DatasetActions.getBatchDetails(this.props.match.params.BatchID, true)
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.DatasetID != prevProps.match.params.DatasetID) {
      DatasetActions.getDataset(this.props.match.params.DatasetID, 'file-import')
    }
    if (this.state.status === STATUS.FILE_IMPORT_ERROR) {
      toast.error('BATCH INSERT FAILED', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearFileImportToast()
        }
      })
      DatasetActions.clearFileImportToast()
    }
    if (this.state.status === STATUS.REDIRECT && !this.state.batchData.BatchID) {
      toast.success('BATCH INSERT SUCCESSFUL', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearFileImportToast()
        }
      })
      DatasetActions.clearFileImportToast()
    }
    if (this.state.status === STATUS.REDIRECT && this.state.batchData.BatchID) {
      toast.success('BATCH UPDATE SUCCESSFUL', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearFileImportToast()
        }
      })
      DatasetActions.clearFileImportToast()
    }
  }
  importCSV = event => {
    let file = event.target.files[event.target.files.length - 1]
    let batchData = this.state.batchData
    if (file) {
      batchData.DatasetID = parseInt(this.props.match.params.DatasetID)
      batchData.batchFiles = {
        File: event.target.files[event.target.files.length - 1],
        contentType: file.type,
        fileExtension: file.type.split('/')[1],
        name: file.name
      }
      _.get(this.props, 'match.params.BatchID') ? DatasetActions.updateCSVFile(batchData) : DatasetActions.updateFields(batchData, 'remove-csv')
      _.get(this.props, 'match.params.BatchID') ? this.readFile(file, 'utf-8', this.readCSV) : this.readFile(file, 'utf-8', this.parseCSV)
    }
  }
  readFile = (file, encoding, callback) => {
    var reader = new FileReader()
    reader.onload = function(e) {
      let result = e.target.result
      callback(result)
    }
    reader.readAsText(file, encoding)
  }
  parseCSV = result => {
    if (result) {
      let batchData = this.state.batchData
      batchData.batchFiles.csvDataString = result
      batchData.batchFiles = [batchData.batchFiles]
      let records = result.split('\n')
      batchData.columns = records[0].split(';').map((item, index) => {
        return { csvColumnName: item.trim().length > 0 ? item : `Other${index + 1}` }
      })
      DatasetActions.mapRecords(batchData)
    }
  }
  readCSV = result => {
    let batchData = this.state.batchData
    batchData.batchFiles.csvDataString = result
    batchData.batchFiles = [batchData.batchFiles]
    DatasetActions.updateCSVFile(batchData)
  }
  saveCSV = () => {
    if (this.state.batchData && !this.state.batchData.batchEncoding) {
      toast.error('Please select Encoding', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearFileImportToast()
        }
      })
      DatasetActions.clearFileImportToast()
      return
    }
    if (this.state.batchData && !this.state.batchData.BatchID && this.state.batchData.batchFiles && this.state.batchData.batchFiles.length) {
      this.readFile(this.state.batchData.batchFiles[0].File, this.state.batchData.batchEncoding.toLowerCase(), this.parseCsvString)
    } else {
      DatasetActions.saveBatch()
    }
  }

  parseCsvString = result => {
    let batchData = this.state.batchData
    let records = result.split('\n')
    let csvDataString = ''
    records.map(item => {
      let data = item.trim()
      let csv = data.replaceAll(';', '').trim()
      if (csv.length > 1) {
        csvDataString = csvDataString + item + '\n'
      }
    })
    batchData.batchFiles[0].csvDataString = csvDataString
    DatasetActions.updateCSVFile(batchData)
    DatasetActions.saveBatch()
  }

  render() {
    if (this.state.batchData && this.state.status === STATUS.REDIRECT) {
      return <Redirect to={'/dataset/' + this.props.match.params.DatasetID + '/batch'} />
    }
    return (
      <CollapsibleCard startOpen header={<b>Import Dataset - {this.state.datasetDetails ? this.state.datasetDetails.moduleName : ''}</b>}>
        <React.Fragment>
          <Row className='mb-3'>
            <Col xs={12} md={2}>
              <label>Select CSV File to Upload</label>
            </Col>
            <Col xs={12} md={3}>
              <Input type='file' name='csvdata' onChange={this.importCSV} disabled={this.state.batchData.BatchID}></Input>
            </Col>
          </Row>
          <Row>
            <Col xs='10'>
              <Table responsive size='sm'>
                <thead>
                  <tr>
                    {'columns' in this.state.datasetDetails && <th className='text-nowrap'>Columns</th>}
                    <th className='text-nowrap'>Column No</th>
                    <th className='text-nowrap'>Match Expression</th>
                    <th className='text-nowrap'>Match Value</th>
                    <th className='text-nowrap'>Column Default value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {'columns' in this.state.datasetDetails &&
                        this.state.datasetDetails.columns.map((item, index) => {
                          return (
                            <Card key={index} className='mb-2'>
                              <CardBody className='dataset-cards__padding'>
                                {' '}
                                <CardText className='text-nowrap'>{item.columnName ? item.columnName : item.datacolumnName}</CardText>
                              </CardBody>
                            </Card>
                          )
                        })}
                    </td>
                    <td>
                      {this.state.mappedCSVRecords.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <StyledInput
                              type='text'
                              className='my-2 mt-sm-0'
                              onChange={t => DatasetActions.updateColumnField(t, index)}
                              value={item.csvColumnNo ? item.csvColumnNo : ''}
                              name='csvColumnNo'
                            />
                          </React.Fragment>
                        )
                      })}
                    </td>
                    <td>
                      {this.state.mappedCSVRecords.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <StyledInput
                              type='text'
                              className='my-2 mt-sm-0'
                              onChange={t => DatasetActions.updateColumnField(t, index)}
                              value={item.csvColumnMatchExpression}
                              name='csvColumnMatchExpression'
                            />
                          </React.Fragment>
                        )
                      })}
                    </td>
                    <td>
                      {this.state.mappedCSVRecords.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <StyledInput
                              type='text'
                              onChange={t => DatasetActions.updateColumnField(t, index)}
                              className='my-2 mt-sm-0'
                              value={item.csvColumnMatchValue}
                              name='csvColumnMatchValue'
                            />
                          </React.Fragment>
                        )
                      })}
                    </td>
                    <td>
                      {this.state.mappedCSVRecords.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <StyledInput
                              type='text'
                              onChange={t => DatasetActions.updateColumnField(t, index)}
                              className='my-2 mt-sm-0'
                              value={item.csvColumnDefault}
                              name='csvColumnDefault'
                            />
                          </React.Fragment>
                        )
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col xs={4} md={2}>
              <label className='text-nowrap'>Primary Key</label>
            </Col>
            <Col xs={8} md={10}>
              <Input
                type='select'
                name='uniqueConstraint'
                id='uniqueConstraint'
                value={this.state.batchData.uniqueConstraint}
                onChange={DatasetActions.updateConstraint}
              >
                <option value=''>None</option>
                {'columns' in this.state.datasetDetails &&
                  this.state.datasetDetails.columns.map((e, i) => {
                    return (
                      <option value={e.datacolumnName} key={i}>
                        {e.columnName ? e.columnName : e.datacolumnName}
                      </option>
                    )
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={2}>
              <label>Header</label>
            </Col>
            <Col xs={8} md={10}>
              <Input
                type='checkbox'
                disabled={this.state.batchData.BatchID}
                className='m-1 batch-header'
                checked={this.state.batchData.batchOptions.skipHeader}
                name='skipHeader'
                onChange={DatasetActions.updateFormField}
              />
              <span className='batch-header__text'>Skip header</span>
              <p className='batch-header__message'>
                If your file contains a header,a row of coulmn names,you can just skip that line from getting imported.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} xs={4}>
              <label>Hierarchy</label>
            </Col>
            <Col md={10} xs={8}>
              <Input type='select' name='batchRole' id='batchRole' value={this.state.batchData.batchRole} onChange={DatasetActions.updateFormField}>
                <option value='' disabled>
                  Click to Select
                </option>
                {batchRoleOptions.map((e, i) => {
                  return (
                    <option value={e.value} key={i}>
                      {e.name}
                    </option>
                  )
                })}
              </Input>
              <p className='batch-header__message'>
                Would you like to match across brands?Then pick Client. Would you like to match across clients?Then pick Company. The higher you
                go,the further I will go.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} xs={4}>
              <label>Encoding</label>
            </Col>
            <Col md={10} xs={8}>
              <Input
                type='select'
                name='batchEncoding'
                id='batchEncoding'
                value={this.state.batchData.batchEncoding}
                onChange={DatasetActions.updateFormField}
              >
                <option value='' disabled>
                  Click to Select
                </option>
                {batchEncodingOptions.map((e, i) => {
                  return (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  )
                })}
              </Input>
              <p className='batch-header__message'>
                Would you like to match across brands?Then pick Client. Would you like to match across clients?Then pick Company. The higher you
                go,the further I will go.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} xs={4}>
              <label>Note</label>
            </Col>
            <Col md={10} xs={8}>
              <Input
                type='textarea'
                value={this.state.batchData.batchComment}
                name='batchComment'
                id='batchComment'
                onChange={DatasetActions.updateFormField}
              />
            </Col>
          </Row>
          <Row className='m-2'>
            <Col xs='2'></Col>
            <Button
              onClick={this.saveCSV}
              disabled={
                this.state.status === STATUS.SAVING ||
                this.state.batchData.batchFlowStatus === 'MATCHED' ||
                this.state.batchData.batchFlowStatus === 'INSERTED)'
              }
            >
              {this.state.status === STATUS.SAVING ? <FontAwesomeIcon icon='spinner' spin /> : this.state.batchData.BatchID ? 'Update' : 'Upload'}
            </Button>
          </Row>
        </React.Fragment>
      </CollapsibleCard>
    )
  }
}
