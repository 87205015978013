import React, { Fragment } from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import 'moment/locale/da'
import { Button, Form } from 'reactstrap'
import FormField from 'Components/formField'
import { CustomCssStore, CustomCssActions } from 'Stores/customCssStore'

export default class CustomCss extends Component {
  constructor(props) {
    super(props)
    this.store = CustomCssStore
  }
  componentDidMount() {
    CustomCssActions.getData()
  }
  render() {
    return (
      <Fragment>
        <CollapsibleCard header={<strong>Detaljer</strong>} startOpen>
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormField name='CSS' field='CSS' data={this.state} onChange={CustomCssActions.updateCSS} type='textarea' />
          </Form>
          <Button onClick={CustomCssActions.saveData}>Gem</Button>
        </CollapsibleCard>
      </Fragment>
    )
  }
}
