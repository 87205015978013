import React from 'react'
import { Component } from 'reflux'
import PlainWallet from 'Modules/wallet'

export default class MacroList extends Component {
  constructor(props) {
    super(props)
    const timeInsertDate = new Date('2020/01/01 00:00')
    this.filter = {
      where: {
        walletType: 'MACRO',
        timeInsert: { gt: timeInsertDate }
      }
    }
  }
  render() {
    return <PlainWallet showKAMName={true} filter={this.filter} isViewMode={false} />
  }
}
