import Reflux, { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'
import { translateData } from 'Util/translations'
import moment from 'moment'
import 'moment/locale/da'
import _ from 'lodash'
export const STATUS = keyMirror({
  FETCHING_GROUPS: null,
  GROUPS_ERROR: null,
  TAKING_INPUT: null,
  FETCHING_DATASETS: null,
  DATASETS_ERROR: null,
  IS_SAVING: null,
  CAMPAIGN_SUCCESS: null,
  CAMPAIGN_ERROR: null,
  FETCHING_CAMPAIGN: null,
  FETCHING_CAMPAIGN_ERROR: null,
  FETCHING_CAMPAIGNS: null,
  FETCHING_CAMPAIGNS_ERROR: null,
  FETCHED_CAMPAIGNS: null,
  REDIRECT: null,
  RUN_CAMPAIGN_SUCCESS: null,
  RUN_CAMPAIGN_ERROR: null,
  RUN_CAMPAIGN_USER_ERROR: null,
  FETCHING_CAMPAIGN_PREVIEW: null,
  PREVIEW_CAMPAIGN_ERROR: null,
  PREVIEW_CAMPAIGN_SUCCESS: null,
  FETCHING_PRODUCTS: null,
  FETCHING_PRODUCTS_ERROR: null,
  CAMPAIGN_STATUS_CHANGE_PENDING: null,
  CAMPAIGN_STATUS_CHANGE_SUCCESS: null,
  CAMPAIGN_STATUS_CHANGE_FAILURE: null
})

export const CampaignActions = createActions([
  'getGroups',
  'getDatasets',
  'resetMacro',
  'getCampaignDetails',
  'updateMacroField',
  'setCampaignData',
  'saveCampaign',
  'clearToast',
  'getCampaigns',
  'clearListToast',
  'deleteCampaign',
  'runCampaign',
  'getCampaignPreview',
  'getProducts',
  'saveProduct',
  'updateProduct',
  'closeProduct',
  'updateCampaignProduct',
  'updateWalletNoteColumns',
  'updateSelectedUsers',
  'changeCampaignStatus',
  'checkAllProducts'
])

const campaignDetailsDefault = {
  CampaignID: null,
  status: null,
  macroName: '',
  macroDescription: '',
  timeStart: moment().format('YYYY-MM-DD'),
  timeEnd: moment().format('YYYY-MM-DD'),
  GroupID: null,
  walletNote: '',
  walletNoteColumns: [],
  isCompoundCampaign: false,
  compoundCampaignMinValue: '',
  compoundCampaignMaxValue: '',
  compoundCampaignDoublePointCheckbox: false,
  compoundCampaignSetPointCheckbox: false,
  compoundCampaignSetPointValue: '',
  normalCampaignSetPointValue: '',
  normalCampaignOperator: '',
  normalCampaignOperatorValue: '',
  productFilterText: '',
  selectAll: false,
  filterText: ''
}

export class CampaignStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = CampaignActions
    this.state = {
      campaigns: [],
      status: null,
      datasets: [],
      campaignCount: null,
      campaignID: null,
      campaignDetails: _.cloneDeep(campaignDetailsDefault),
      productDetails: {
        moduleName: '',
        moduleDescription: ''
      },
      groups: [],
      clauseDatacolumns: [],
      campaignPreview: [],
      products: [],
      showModal: false,
      filteredProducts: [],
      selectedUsers: null,
      hasAccessToCampaignStatus: false
    }
    this.enableWalletActivation =
      Reflux.GlobalState.LoginStore &&
      Reflux.GlobalState.LoginStore.settings &&
      Reflux.GlobalState.LoginStore.settings.features &&
      Reflux.GlobalState.LoginStore.settings.features.enableWalletActivation
    this.BrandID = Reflux.GlobalState.LoginStore && Reflux.GlobalState.LoginStore.settings ? Reflux.GlobalState.LoginStore.settings.BrandID : 11
    this.WALLETUSERUID = this.enableWalletActivation ? 1805 : 101
    this.WALLETAMOUNTPOINTS = this.enableWalletActivation ? 1801 : 3
    this.WALLETNOTE = this.enableWalletActivation ? 1802 : 13
    this.DATEREFERENCE_TIMESTAMP = this.BrandID === 167 ? 1837 : 131
    this.MESSAGE_PRODUCT = this.BrandID === 167 ? 1840 : 134
    this.CONTRACTNO = this.BrandID === 167 ? 1834 : 128
    this.SELLERNO_UID = this.BrandID === 167 ? 1836 : 130
    this.POINT = this.BrandID === 167 ? 1835 : 129
  }

  changeCampaignStatus({ campaignId, status }) {
    this.setState({ status: STATUS.CAMPAIGN_STATUS_CHANGE_PENDING })
    POST('campaigns/status', {
      CampaignID: parseInt(campaignId, 10),
      status
    }).then(responseJSON => {
      if (responseJSON.error) {
        this.setState({ status: STATUS.CAMPAIGN_STATUS_CHANGE_FAILURE })
      } else {
        this.setState({
          status: STATUS.CAMPAIGN_STATUS_CHANGE_SUCCESS,
          campaignDetails: {
            ...this.state.campaignDetails,
            status
          }
        })
      }
    })
  }

  getGroups() {
    if (this.state.status === STATUS.FETCHING_GROUPS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_GROUPS })
    GET('schemas/groups').then(responseJSON => {
      let nextState = {
        groups: [],
        campaignDetails: this.state.campaignDetails ? this.state.campaignDetails : {}
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.GROUPS_ERROR
        } else {
          nextState.status = STATUS.TAKING_INPUT
          nextState.groups = responseJSON
        }
      }
      this.setState(nextState)
    })
  }

  getDatasets() {
    if (this.state.status === STATUS.FETCHING_DATASETS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_DATASETS })
    GET('datasets/pool').then(responseJSON => {
      let nextState = {
        datasets: [],
        mappedCSVRecords: [],
        clauseDatacolumns: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.DATASETS_ERROR
        } else {
          responseJSON = translateData(responseJSON)
          nextState.datasets = responseJSON
          responseJSON.map(item => {
            if (item.datasetTableName === 'X11SAL20' || item.datasetTableName === 'X167NYC20') {
              nextState.clauseDatacolumns = nextState.clauseDatacolumns.concat(item.columns)
            }
          })
          nextState.status = STATUS.TAKING_INPUT
        }
      }
      this.setState(nextState)
    })
  }

  getCampaignDetails(CampaignID) {
    if (CampaignID) {
      this.setState({ campaignDetails: _.cloneDeep(campaignDetailsDefault), status: STATUS.FETCHING_CAMPAIGN })
      GET('campaigns/fetch', { CampaignID: parseInt(CampaignID) }).then(responseJSON => {
        const campaignGroup = _.get(responseJSON, 'groups[0]')
        let nextState = {
          campaignDetails: {},
          clauseDatacolumns: this.state.clauseDatacolumns
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.FETCHING_CAMPAIGN_ERROR
          } else {
            const response = this.setCampaignDetails(responseJSON)
            response.campaignDetails.CampaignID = responseJSON.CampaignID
            response.campaignDetails.MacroID = responseJSON.MacroID
            response.campaignDetails.user = responseJSON.user
            response.campaignDetails.status = responseJSON.status
            response.campaignDetails.macroName = responseJSON.macroName
            response.campaignDetails.macroDescription = responseJSON.macroDescription
            response.campaignDetails.filterText = responseJSON.groups.length ? responseJSON.groups[0].moduleName : ''
            response.campaignDetails.GroupID = responseJSON.groups.length ? responseJSON.groups[0].GroupID : ''
            response.campaignDetails.timeEnd = moment.parseZone(responseJSON.timeEnd).format('YYYY-MM-DD')
            response.campaignDetails.timeStart = moment.parseZone(responseJSON.timeStart).format('YYYY-MM-DD')

            nextState.clauseDatacolumns = response.clauseDatacolumns
            nextState.campaignDetails = response.campaignDetails
            nextState.status = STATUS.TAKING_INPUT
            nextState.hasAccessToCampaignStatus = responseJSON.hasAccessToCampaignStatus
          }
        }
        if (campaignGroup) {
          GET('groups/elements', { GroupID: campaignGroup.GroupID }).then(groupResponse => {
            nextState.campaignDetails.users = groupResponse
            this.setState(nextState)
          })
        } else {
          this.setState(nextState)
        }
      })
    }
  }

  resetMacro() {
    this.setState({
      campaignDetails: {
        CampaignID: null,
        status: null,
        macroName: '',
        macroDescription: '',
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: moment().format('YYYY-MM-DD'),
        GroupID: null,
        walletNote: '',
        walletNoteColumns: [],
        isCompoundCampaign: false,
        compoundCampaignMinValue: '',
        compoundCampaignMaxValue: '',
        compoundCampaignDoublePointCheckbox: false,
        compoundCampaignSetPointCheckbox: false,
        compoundCampaignSetPointValue: '',
        normalCampaignSetPointValue: '',
        normalCampaignOperator: '',
        normalCampaignOperatorValue: '',
        productFilterText: '',
        filterText: ''
      },
      selectAll: false
    })
  }

  checkAllProducts(field) {
    let products = this.state.products
    let selectAll = this.state.selectAll
    let checkedCount = 0
    products.map(product => {
      product.checked = field.target.checked
      if (product.checked) {
        checkedCount++
      }
    })
    selectAll = products.length === checkedCount ? true : false
    this.setState({ products, selectAll })
  }

  updateCampaignProduct(field) {
    let products = this.state.products
    let name = field.target.name
    let selectAll = this.state.selectAll
    let checkedCount = 0
    products.map(product => {
      if (product.moduleName === name) {
        product.checked = field.target.checked
      }
      if (product.checked) {
        checkedCount++
      }
    })
    selectAll = products.length === checkedCount ? true : false
    this.setState({ products, selectAll })
  }

  updateMacroField(field) {
    let campaignDetails = this.state.campaignDetails
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let isCompoundCampaign = this.state.isCompoundCampaign
    if (fieldName === 'timeStart') {
      fieldValue = moment.parseZone(fieldValue).format('YYYY-MM-DD')
    }
    if (fieldName === 'timeEnd') {
      fieldValue = moment.parseZone(fieldValue).format('YYYY-MM-DD')
    }
    if (fieldName === 'compoundCampaignMinValue' || fieldName === 'compoundCampaignMaxValue') {
      if (fieldValue) {
        isCompoundCampaign = true
      } else {
        if (
          (fieldName === 'compoundCampaignMinValue' && !this.state.campaignDetails.compoundCampaignMaxValue) ||
          (fieldName === 'compoundCampaignMaxValue' && !this.state.campaignDetails.compoundCampaignMinValue)
        ) {
          isCompoundCampaign = false
        }
      }
    }
    if (
      fieldName === 'compoundCampaignSetPointCheckbox' ||
      fieldName === 'compoundCampaignDoublePointCheckbox' ||
      fieldName === 'isCompoundCampaign'
    ) {
      campaignDetails[fieldName] = field.target.checked
      if (fieldName === 'compoundCampaignSetPointCheckbox') {
        campaignDetails.compoundCampaignDoublePointCheckbox = !campaignDetails.compoundCampaignSetPointCheckbox
      } else if (fieldName === 'compoundCampaignDoublePointCheckbox') {
        campaignDetails.compoundCampaignSetPointCheckbox = !campaignDetails.compoundCampaignDoublePointCheckbox
      } else {
        if (campaignDetails.isCompoundCampaign) {
          campaignDetails.normalCampaignOperator = ''
          campaignDetails.normalCampaignOperatorValue = ''
          campaignDetails.normalCampaignSetPointValue = ''
        } else {
          campaignDetails.compoundCampaignDoublePointCheckbox = false
          campaignDetails.compoundCampaignSetPointCheckbox = false
          campaignDetails.compoundCampaignMaxValue = ''
          campaignDetails.compoundCampaignMinValue = ''
          campaignDetails.compoundCampaignSetPointValue = ''
        }
      }
    } else {
      campaignDetails[fieldName] = fieldValue
    }
    if (fieldName === 'productFilterText') {
      const filteredProducts = fieldValue
        ? this.state.products.filter(item => item.moduleName.toLowerCase().includes(fieldValue.toLowerCase()))
        : this.state.products
      this.setState({ filteredProducts })
    }
    this.setState({ campaignDetails, isCompoundCampaign })
  }

  setCampaignData(campaignDetails) {
    this.setState({ campaignDetails })
  }

  saveCampaign(isKam) {
    if (this.state.campaignDetails.macroName) {
      let campaignDetailsReqObj = { actions: [], clauses: [] }
      if (this.state.campaignDetails.CampaignID) {
        campaignDetailsReqObj.CampaignID = this.state.campaignDetails.CampaignID
        campaignDetailsReqObj.MacroID = this.state.campaignDetails.MacroID
        campaignDetailsReqObj.status = this.state.campaignDetails.status
      }
      campaignDetailsReqObj.macroName = this.state.campaignDetails.macroName
      campaignDetailsReqObj.macroDescription = this.state.campaignDetails.macroDescription
      campaignDetailsReqObj.timeStart = this.state.campaignDetails.timeStart
      campaignDetailsReqObj.timeEnd = this.state.campaignDetails.timeEnd
      // Set selected users from a group
      if (this.state.selectedUsers) {
        campaignDetailsReqObj.users = this.state.selectedUsers
      }
      // Set GroupID
      if (this.state.campaignDetails.GroupID) {
        campaignDetailsReqObj.groups = []
        campaignDetailsReqObj.groups.push({ GroupID: this.state.campaignDetails.GroupID })
      }
      // Set selected Products eligible for campaign
      let checkedProducts = []
      this.state.products.forEach(product => {
        if (product.checked) {
          checkedProducts.push(product.moduleName)
        }
      })
      if (checkedProducts.length) {
        let productClauseMin = {
          macroclauseCase: 'AND',
          macroclauseDatacolumnID: this.MESSAGE_PRODUCT,
          macroclauseCast: '',
          macroclauseFunction: '',
          macroclauseOperator: 'EQUAL',
          macroclauseValue: []
        }

        productClauseMin.macroclauseFunctionOperator = 'HIGHER'
        productClauseMin.macroclauseFunction = 'COUNT'
        productClauseMin.macroclauseFunctionValue = this.state.campaignDetails.compoundCampaignMinValue
          ? this.state.campaignDetails.compoundCampaignMinValue - 1
          : 0
        productClauseMin.macroclauseValue = checkedProducts.join('\n')
        campaignDetailsReqObj.clauses.push(productClauseMin)

        if (this.state.campaignDetails.compoundCampaignMaxValue) {
          let productClauseMax = Object.assign({}, productClauseMin)
          productClauseMax.macroclauseFunctionOperator = 'LOWER'
          productClauseMax.macroclauseFunction = 'COUNT'
          productClauseMax.macroclauseFunctionValue = this.state.campaignDetails.compoundCampaignMaxValue
          delete productClauseMax['MacroclauseID']
          campaignDetailsReqObj.clauses.push(productClauseMax)
        }
      }
      campaignDetailsReqObj.clauses.push(
        {
          macroclauseCase: 'AND',
          macroclauseDatacolumnID: this.DATEREFERENCE_TIMESTAMP,
          macroclauseCast: 'TIME',
          macroclauseFunction: '',
          macroclauseOperator: 'LOWER',
          macroclauseValue: this.state.campaignDetails.timeEnd
        },
        {
          macroclauseCase: 'AND',
          macroclauseDatacolumnID: this.DATEREFERENCE_TIMESTAMP,
          macroclauseCast: 'TIME',
          macroclauseFunction: '',
          macroclauseOperator: 'HIGHER',
          macroclauseValue: this.state.campaignDetails.timeStart
        }
      )
      // Set walletPoints as one action
      if (this.state.campaignDetails.walletPoints) {
        campaignDetailsReqObj.actions.push({
          macroactionType: 'INSERT',
          macroactionTypeDatacolumnID: this.WALLETAMOUNTPOINTS,
          macroactionOperator: 'SET',
          macroactionOperatorDatacolumnID: '',
          macroactionValue: this.state.campaignDetails.walletPoints
        })
      }
      if (this.state.campaignDetails.walletNote) {
        campaignDetailsReqObj.actions.push({
          macroactionType: 'INSERT',
          macroactionTypeDatacolumnID: this.WALLETNOTE,
          macroactionOperator: 'SET',
          macroactionOperatorDatacolumnID: [],
          macroactionValue: this.state.campaignDetails.walletNote
        })
      }
      if (isKam) {
        // This is a hacky solution, long term the solution should be based on a roles system
        // ideally something like if access === KAM server automatically adds these and if access > KAM it doesn't happen
        // but as of right now we don't have a access level for KAM.
        let walletnoteAction1 = {
          macroactionType: 'INSERT',
          macroactionTypeDatacolumnID: this.WALLETNOTE,
          macroactionOperator: 'SET',
          macroactionOperatorDatacolumnID: [],
          macroactionValue: ''
        }
        walletnoteAction1.macroactionOperatorDatacolumnID = this.MESSAGE_PRODUCT
        let walletnoteAction2 = { ...walletnoteAction1 }
        walletnoteAction2.macroactionOperatorDatacolumnID = this.CONTRACTNO
        campaignDetailsReqObj.actions.push(walletnoteAction1)
        campaignDetailsReqObj.actions.push(walletnoteAction2)
      } else if (this.state.campaignDetails.walletNoteColumns && this.state.campaignDetails.walletNoteColumns.length) {
        this.state.campaignDetails.walletNoteColumns.forEach(column => {
          let walletnoteAction = {
            macroactionType: 'INSERT',
            macroactionTypeDatacolumnID: this.WALLETNOTE,
            macroactionOperator: 'SET',
            macroactionOperatorDatacolumnID: [],
            macroactionValue: ''
          }
          walletnoteAction.macroactionOperatorDatacolumnID = column
          campaignDetailsReqObj.actions.push(walletnoteAction)
        })
      }
      let walletPointsAction = {
        macroactionType: 'INSERT',
        macroactionTypeDatacolumnID: this.WALLETAMOUNTPOINTS,
        macroactionOperator: 'SET',
        macroactionOperatorDatacolumnID: '',
        macroactionValue: ''
      }
      if (this.state.campaignDetails.compoundCampaignMinValue) {
        if (this.state.campaignDetails.compoundCampaignDoublePointCheckbox) {
          walletPointsAction.macroactionOperatorDatacolumnID = this.POINT
        } else {
          walletPointsAction.macroactionValue = this.state.campaignDetails.compoundCampaignSetPointValue
        }
      } else if (this.state.campaignDetails.normalCampaignSetPointValue) {
        walletPointsAction.macroactionValue = this.state.campaignDetails.normalCampaignSetPointValue
      } else if (this.state.campaignDetails.normalCampaignOperator) {
        walletPointsAction.macroactionOperatorDatacolumnID = this.POINT
        if (this.state.campaignDetails.normalCampaignOperator !== 'SET') {
          walletPointsAction.macroactionOperator = this.state.campaignDetails.normalCampaignOperator
          walletPointsAction.macroactionValue = this.state.campaignDetails.normalCampaignOperatorValue
        }
      }
      campaignDetailsReqObj.actions.push(walletPointsAction)

      campaignDetailsReqObj.actions.push({
        macroactionType: 'INSERT',
        macroactionTypeDatacolumnID: this.WALLETUSERUID,
        macroactionOperator: 'SET',
        macroactionOperatorDatacolumnID: this.SELLERNO_UID,
        macroactionValue: ''
      })
      this.setState({ status: STATUS.IS_SAVING })
      /*
      if (campaignDetails.timeEnd) {
        campaignDetails.timeEnd = moment(campaignDetails.timeEnd).endOf('day')
      }
      */
      POST('campaigns/replace', campaignDetailsReqObj).then(responseJSON => {
        let nextState = {}
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.CAMPAIGN_ERROR
          } else {
            nextState.status = STATUS.REDIRECT
          }
        }
        this.setState(nextState)
      })
    }
  }

  clearToast(status) {
    status ? this.setState({ status }) : this.setState({ status: STATUS.TAKING_INPUT })
  }

  getCampaigns() {
    if (this.state.status === STATUS.FETCHING_CAMPAIGNS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_CAMPAIGNS })
    GET('campaigns/pool').then(responseJSON => {
      let nextState = {
        campaigns: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_CAMPAIGNS_ERROR
        } else {
          responseJSON.map(item => {
            this.dateCheck(item.timeStart, item.timeEnd, moment(new Date()).format('YYYY-MM-DD')) ? (item.disabled = false) : (item.disabled = true)
          })
          nextState.campaigns = responseJSON
          nextState.status = STATUS.FETCHED_CAMPAIGNS
        }
      }
      this.setState(nextState)
    })
  }

  clearListToast() {
    this.setState({ status: null })
  }

  deleteCampaign(CampaignID = null) {
    if (CampaignID) {
      POST('campaigns/remove', { CampaignID: CampaignID }).then(() => {
        this.getCampaigns()
      })
    }
  }

  runCampaign(CampaignID = null) {
    if (CampaignID) {
      this.setState({ status: STATUS.IS_SAVING, campaignID: CampaignID })
      POST('campaigns/run', { CampaignID: parseInt(CampaignID) }).then(response => {
        let nextState = {}
        if (response.error) {
          if (response.error.message && response.error.message === 'NO_USERS_GROUP') {
            nextState.status = STATUS.RUN_CAMPAIGN_USER_ERROR
          } else {
            nextState.status = STATUS.RUN_CAMPAIGN_ERROR
          }
        }
        if (response && !response.error) {
          nextState.status = STATUS.RUN_CAMPAIGN_SUCCESS
          nextState.campaignCount = response.count
        }
        this.setState(nextState)
      })
    }
  }

  dateCheck(from, to, check) {
    const fDate = Date.parse(from)
    const lDate = Date.parse(to)
    const cDate = Date.parse(check)
    if (cDate <= lDate && cDate >= fDate) {
      return true
    }
    return false
  }

  getCampaignPreview(CampaignID = null) {
    if (CampaignID && this.state !== STATUS.FETCHING_CAMPAIGN_PREVIEW) {
      this.setState({ status: STATUS.FETCHING_CAMPAIGN_PREVIEW, campaignID: CampaignID, campaignPreview: [] })

      const previewPromise = POST('campaigns/preview', {
        CampaignID: parseInt(CampaignID)
      })
      const campaignDetailsPromise = GET('campaigns/fetch', { CampaignID: parseInt(CampaignID) })

      Promise.all([previewPromise, campaignDetailsPromise]).then(values => {
        let nextState = {}
        const previewResponse = values[0]
        const campaignResponse = values[1]
        const campaignGroup = _.get(campaignResponse, 'groups[0]')

        if (campaignGroup) {
          GET('groups/elements', { GroupID: campaignGroup.GroupID }).then(groupResponse => {
            if (previewResponse.error || campaignResponse.error || groupResponse.error) {
              nextState.status = STATUS.PREVIEW_CAMPAIGN_ERROR
            } else if (previewResponse) {
              nextState.status = STATUS.PREVIEW_CAMPAIGN_SUCCESS
              if (groupResponse) {
                previewResponse.users = groupResponse
              }
              nextState.campaignPreview = previewResponse
            }
            this.setState(nextState)
          })
        } else {
          if (previewResponse.error || campaignResponse.error) {
            nextState.status = STATUS.PREVIEW_CAMPAIGN_ERROR
          } else {
            nextState.status = STATUS.PREVIEW_CAMPAIGN_SUCCESS
          }

          this.setState(nextState)
        }
      })
    }
  }

  getProducts() {
    if (this.state.status === STATUS.FETCHING_PRODUCTS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_PRODUCTS })
    GET('campaigns/getProducts').then(responseJSON => {
      let nextState = {
        products: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_PRODUCTS_ERROR
        } else {
          nextState.status = STATUS.TAKING_INPUT
          responseJSON.map(item => (item.checked = false))
          nextState.products = responseJSON
          this.updateProducts(this.state.campaignDetails)
        }
      }
      this.setState(nextState)
    })
  }

  saveProduct() {
    POST('campaigns/createProduct', this.state.productDetails).then(responseJSON => {
      let nextState = {
        showModal: false
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.showModal = true
        } else {
          nextState.showModal = false
          nextState.productDetails = {}
          this.getProducts()
        }
      }
      this.setState(nextState)
    })
  }

  updateProduct(field) {
    let productDetails = this.state.productDetails
    let fieldName = field.target.name
    let fieldValue = field.target.value
    productDetails[fieldName] = fieldValue
    this.setState({ productDetails })
  }

  closeProduct() {
    this.setState({ showModal: false })
  }

  updateWalletNoteColumns(field) {
    let fieldValue = field.target.value
    let campaignDetails = this.state.campaignDetails
    let clauseDatacolumns = this.state.clauseDatacolumns
    if (campaignDetails.walletNoteColumns.includes(Number(fieldValue))) {
      let index = campaignDetails.walletNoteColumns.indexOf(Number(fieldValue))
      if (index > -1) {
        campaignDetails.walletNoteColumns.splice(index, 1)
      }
    } else {
      campaignDetails.walletNoteColumns.push(Number(fieldValue))
    }
    clauseDatacolumns = clauseDatacolumns.map(col => {
      if (col.datacolumnName === field.target.name) {
        col.checked = field.target.checked
      }
      return col
    })
    this.setState({ campaignDetails, clauseDatacolumns })
  }

  setCampaignDetails(campaignDetailsResponse) {
    let campaignDetails = _.cloneDeep(campaignDetailsDefault)
    let clauseDatacolumns = this.state.clauseDatacolumns
    if (campaignDetailsResponse.clauses && campaignDetailsResponse.clauses.length) {
      campaignDetailsResponse.clauses.map(clause => {
        if (clause.macroclauseDatacolumnID === this.MESSAGE_PRODUCT) {
          this.updateProducts(clause.macroclauseValue.split('\n'))
          if (clause.macroclauseFunctionOperator === 'HIGHER' && clause.macroclauseFunctionValue > 0) {
            campaignDetails.compoundCampaignMinValue = clause.macroclauseFunctionValue
            campaignDetails.compoundCampaignMinValue++
            campaignDetails.isCompoundCampaign = true
          }
          if (clause.macroclauseFunctionOperator === 'LOWER') {
            campaignDetails.compoundCampaignMaxValue = clause.macroclauseFunctionValue
          }
        }
      })
    }
    if (campaignDetailsResponse.actions && campaignDetailsResponse.actions.length) {
      campaignDetailsResponse.actions.map(action => {
        if (action.macroactionTypeDatacolumnID === 13 || action.macroactionTypeDatacolumnID === 1802) {
          if (action.macroactionValue && !action.macroactionOperatorDatacolumnID) {
            campaignDetails.walletNote = action.macroactionValue
          }
          if (!action.macroactionValue && action.macroactionOperatorDatacolumnID) {
            campaignDetails.walletNoteColumns.push(action.macroactionOperatorDatacolumnID)
          }
          if (action.operatorColumn) {
            let match = clauseDatacolumns.filter(col => col.datacolumnName === action.operatorColumn.datacolumnName)
            if (match && match.length) {
              match[0].checked = true
            }
          }
        }
        if (action.macroactionTypeDatacolumnID === 3 || action.macroactionTypeDatacolumnID === 1801) {
          if (campaignDetails.isCompoundCampaign) {
            if (!action.macroactionOperatorDatacolumnID && action.macroactionValue) {
              campaignDetails.compoundCampaignSetPointValue = action.macroactionValue
              campaignDetails.compoundCampaignSetPointCheckbox = true
            }
            if (action.macroactionOperatorDatacolumnID) {
              campaignDetails.compoundCampaignDoublePointCheckbox = true
            }
          } else {
            if (!action.macroactionOperatorDatacolumnID && action.macroactionValue) {
              campaignDetails.normalCampaignSetPointValue = action.macroactionValue
            }
            if (action.macroactionOperatorDatacolumnID) {
              campaignDetails.normalCampaignOperator = action.macroactionOperator
              campaignDetails.normalCampaignOperatorValue = action.macroactionValue
            }
          }
        }
      })
    }
    return { campaignDetails, clauseDatacolumns }
  }

  updateProducts(inProducts) {
    let products = this.state.products
    let selectAll = false
    if (products.length && inProducts.length && Array.isArray(inProducts)) {
      products.map(product => {
        inProducts.map(item => {
          if (item === product.moduleName) {
            product.checked = true
          }
        })
        return product
      })
      selectAll = products.length === inProducts.length ? true : false
    }
    this.setState({ products, selectAll })
  }

  updateSelectedUsers(selectedUsers) {
    this.setState({ selectedUsers })
  }
}
