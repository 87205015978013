import React from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { BrandActions, BrandStore } from 'Stores/brandStore'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { Component } from 'reflux'
import { Button } from 'reactstrap'
import moment from 'moment'

export default class ObfuscateBrand extends Component {
  constructor(props) {
    super(props)
    this.stores = [BrandStore]
  }

  componentDidMount() {
    BrandActions.getBrandsList()
  }
  componentDidUpdate() {}

  render() {
    return (
      <>
        <CollapsibleCard startOpen header={<strong> Brand Obfuscation </strong>}>
          <FormGroup>
            <Row>
              <Col xs='3'>
                <Label for='BrandID'> Brand </Label>
              </Col>
              <Col xs='6'>
                <Input
                  required
                  type='select'
                  name='BrandID'
                  value={this.state.obfuscationBrandID || ''}
                  id='BrandID'
                  onChange={e => {
                    BrandActions.getBrandObfuscationDetails(e.target.value)
                  }}
                >
                  <option value=''>Click to Select</option>
                  {this.state.brandsList.map((e, i) => {
                    return (
                      <option value={Number(e.BrandID)} key={i}>
                        {e.BrandID} - {e.brandName}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
        </CollapsibleCard>
        {this.state.brandObfuscationDetails && (
          <>
            <CollapsibleCard startOpen header={<strong> Users </strong>}>
              {!this.state.brandObfuscationDetails.userObfuscationScheduledTime ? (
                <>
                  <Row>
                    <Col xs='3'>
                      <Label> No. of users: </Label>
                    </Col>
                    <Col xs='3'>
                      <p> {this.state.brandObfuscationDetails.userCount} </p>
                    </Col>

                    {/* <Col xs='3'>
                      <Label> No. of excepted users: </Label>
                    </Col>
                    <Col xs='3'>
                      <p> {this.state.brandObfuscationDetails.exceptedUserCount} </p>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col xs='3'>
                      <Label> Schedule obfuscation: </Label>
                    </Col>
                    <Col xs='3'>
                      <Input
                        required
                        type='select'
                        name='userObfuscationDateValue'
                        value={this.state.userObfuscationDateValue || ''}
                        id='userObfuscationDateValue'
                        onChange={e => BrandActions.setUserObfuscationDate(e.target.value)}
                      >
                        <option value=''>Click to Select</option>
                        {['now', 6, 12, 18, 24, 36, 42, 48, 54, 60].map((e, i) => {
                          return (
                            <option value={e} key={i}>
                              {e === 'now' ? 'Obfuscate Today' : `${e} Months`}
                            </option>
                          )
                        })}
                      </Input>
                    </Col>
                    {this.state.userObfuscationDate && (
                      <>
                        <Col xs='3'>
                          <p>Planned Date:</p>
                        </Col>
                        <Col xs='3'>
                          <p>
                            <strong>{moment(this.state.userObfuscationDate).format('DD.MM.YYYY')}</strong>
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row className='mt-3'>
                    <Col xs='3'>
                      <Button
                        disabled={!this.state.userObfuscationDateValue}
                        color='danger'
                        className=''
                        onClick={() => BrandActions.scheduleUserObfuscation()}
                      >
                        Schedule User Obfuscation
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col xs='3'>
                      <Label> No. of users to be obfuscated: </Label>
                    </Col>
                    <Col xs='3'>
                      <p> {this.state.brandObfuscationDetails.toBeObfuscatedUserCount} </p>
                    </Col>

                    <Col xs='3'>
                      <Label> No. of excepted users: </Label>
                    </Col>
                    <Col xs='3'>
                      <p> {this.state.brandObfuscationDetails.exceptedUserCount} </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='3'>
                      <p>
                        Scheduled date:{' '}
                        <strong> {moment(this.state.brandObfuscationDetails.userObfuscationScheduledTime).format('DD.MM.YYYY')} </strong>{' '}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='3'>
                      <Button
                        disabled={!this.state.brandObfuscationDetails.userObfuscationScheduledTime}
                        color='success'
                        className=''
                        onClick={() => BrandActions.revertUserObfuscation()}
                      >
                        Revert User Obfuscation
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </CollapsibleCard>

            <CollapsibleCard startOpen header={<strong> Orders </strong>}>
              {!this.state.brandObfuscationDetails.orderObfuscationScheduledTime ? (
                <>
                  <Row>
                    <Col xs='3'>
                      <Label> Active orders: </Label>
                    </Col>
                    <Col xs='3'>
                      <p> {this.state.brandObfuscationDetails.orderCount} </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='3'>
                      <Label> Schedule obfuscation: </Label>
                    </Col>
                    <Col xs='3'>
                      <Input
                        required
                        type='select'
                        name='orderObfuscationDateValue'
                        value={this.state.orderObfuscationDateValue || ''}
                        id='orderObfuscationDateValue'
                        onChange={e => BrandActions.setOrderObfuscationDate(e.target.value)}
                      >
                        <option value=''>Click to Select</option>
                        {['now', 6, 12, 18, 24, 36, 42, 48, 54, 60].map((e, i) => {
                          return (
                            <option value={e} key={i}>
                              {e === 'now' ? 'Obfuscate Today' : `${e} Months`}
                            </option>
                          )
                        })}
                      </Input>
                    </Col>

                    {this.state.orderObfuscationDate && (
                      <>
                        <Col xs='3'>
                          <p>Planned Date:</p>
                        </Col>
                        <Col xs='3'>
                          <p>
                            <strong>{moment(this.state.orderObfuscationDate).format('DD.MM.YYYY')}</strong>
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col xs='3'>
                      <Button
                        disabled={!this.state.orderObfuscationDateValue}
                        color='danger'
                        className=''
                        onClick={() => BrandActions.scheduleOrderObfuscation()}
                      >
                        Schedule order Obfuscation
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col xs='3'>
                      <Label> No. of orders to be obfuscated: </Label>
                    </Col>
                    <Col xs='3'>
                      <p> {this.state.brandObfuscationDetails.toBeObfuscatedOrderCount} </p>
                    </Col>
                    <Col xs='3'>
                      <Label> No. of excepted orders: </Label>
                    </Col>
                    <Col xs='3'>
                      <p> {this.state.brandObfuscationDetails.exceptedOrderCount} </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='3'>
                      <p>
                        Scheduled date:{' '}
                        <strong> {moment(this.state.brandObfuscationDetails.orderObfuscationScheduledTime).format('DD.MM.YYYY')} </strong>{' '}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='3'>
                      <Button
                        disabled={!this.state.brandObfuscationDetails.orderObfuscationScheduledTime}
                        color='success'
                        className=''
                        onClick={() => BrandActions.revertOrderObfuscation()}
                      >
                        Revert Order Obfuscation
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </CollapsibleCard>
          </>
        )}
      </>
    )
  }
}
