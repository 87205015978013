import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import moment from 'moment'
import { toast } from 'react-toastify'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let headers = {
  userName: 'Bruger Navn',
  userCompany: 'Virksomhed',
  userUID: 'Unikt ID',
  dealerCVR: 'DealerCVR',
  dealerUID: 'DealerID',
  walletPointsRemaining: 'Ubrugte point'
}

const AllUnusedPoints = props => {
  let { startDate, endDate } = props.match.params
  const isActivationPoints = props.location.pathname.indexOf('all-unused-activation-point') > -1
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    Proxies.GET(isActivationPoints ? 'wallets/activationWalletPointsUnusedWithinDates' : 'wallets/walletPointsUnusedWithinDates', {
      startDate: startDate,
      endDate: endDate
    })
      .then(responseJSON => {
        if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
          setData(
            responseJSON.map(item => {
              item.timeInsert = moment(item.timeInsert).format('YYYY-MM-DD')
              return item
            })
          )
        } else {
          setData([])
        }
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <FontAwesomeIcon icon='spinner' spin />
  }
  if (!data.length) {
    toast.error(`No details between as on ${endDate}`, {
      autoClose: 5500,
      onClose: () => {
        window.close()
      }
    })
    return <p>No details as on {endDate}</p>
  }
  return (
    <React.Fragment>
      <CollapsibleCard header={<strong>Ubrugte point: {moment(endDate).format('DD-MM-YYYY')}</strong>} startOpen={data.length ? true : false}>
        <DynamicTable data={data} headers={headers} />
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default AllUnusedPoints
