import React, { Fragment, useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import moment from 'moment'

const UserGoalsOverview = () => {
  const [usergoals, setUsergoals] = useState([])
  const [loading, setLoading] = useState(false)
  const last2MonthName = moment()
    .subtract(2, 'months')
    .format('MMMM')

  useEffect(() => {
    setLoading(true)
    getUsergoals()
  }, [])

  function getUsergoals() {
    Proxies.GET('usergoals/pool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setUsergoals(responseJSON)
      }
      setLoading(false)
    })
  }

  return (
    <CollapsibleCard startOpen header={<strong>User Goals</strong>}>
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'name'
            },
            fieldFunctions: {
              startDate: e => {
                return <Fragment>{moment(e.startDate).format('DD-MM-YYYY')}</Fragment>
              },
              endDate: e => {
                return <Fragment>{moment(e.endDate).format('DD-MM-YYYY')}</Fragment>
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.UserID}>redigere</Tooltip>
                    <Button tag={Link} to={'/usergoals/' + e.UserID} id={'edit' + e.UserID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            UserID: '#',
            userUID: 'UID',
            userName: 'User Name',
            userCompany: 'User Company',
            currentMonth: 'Current Month Goal',
            nextMonth: 'Next Month Goal',
            lastMonth: 'Last Month Goal',
            last2Month: `${last2MonthName[0].toUpperCase() + last2MonthName.slice(1)} Goal`
          }}
          data={usergoals}
        />
      )}
    </CollapsibleCard>
  )
}

export default UserGoalsOverview
