import React, { useState } from 'react'
import styled from 'styled-components'
import { colorMap } from 'Components/dashboard/colorMap.js'
import { ResponsiveBar } from '@nivo/bar'
import DataNotFound from 'Components/dashboard/DataNotFound'
import { BarLegend } from 'Components/dashboard/charts/BarLegend'
const ChartWrap = styled.div`
  height: 300px;
`
export default function BarChart({ data, keys, indexBy, bottomTickRotation, legends, className, axisLabel }) {
  const [filter, setFilter] = useState(null)
  const filteredData = filter
    ? data.map(item => {
        return { [indexBy]: item[indexBy], [filter]: item[filter] }
      })
    : data
  return (
    <ChartWrap className={className}>
      {data && data.length ? (
        <ResponsiveBar
          data={filteredData}
          keys={keys}
          indexBy={indexBy}
          margin={{ top: 30, right: 0, bottom: 40, left: 60 }}
          padding={0.3}
          colors={({ id }) => colorMap[`${id}Color`]}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: bottomTickRotation
          }}
          axisLeft={{
            format: axisLabel ? e => Math.floor(e) === e && e : '~s',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: axisLabel ? axisLabel : 'Point',
            legendPosition: 'middle',
            legendOffset: -50
          }}
          theme={{
            axis: { legend: { text: { fontWeight: 700, fontFamily: '"Open Sans", sans-serif' } } }
          }}
          layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
          enableLabel={false}
          labelSkipWidth={40}
          labelSkipHeight={16}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          tooltipFormat={value => new Intl.NumberFormat().format(value)}
          legends={
            legends || [
              {
                dataFrom: 'keys',
                data: keys.map(id => ({
                  color: colorMap[`${id}Color`],
                  id,
                  label: id
                })),
                onClick: d => {
                  filter === d.label ? setFilter(null) : setFilter(d.label)
                },
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -25,
                itemsSpacing: 2,
                itemWidth: 80,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 15
              }
            ]
          }
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      ) : (
        <DataNotFound />
      )}
    </ChartWrap>
  )
}
