import React from 'react'
import { Component } from 'reflux'
import { KamStore, KamActions } from 'Stores/santanderKamStore'
import SantanderKAMGraph from 'Components/dashboard/SantanderKAMGraph'

export default class SantanderKAMRemainingPoints extends Component {
  constructor(props) {
    super(props)
    this.stores = [KamStore]
    this.storeKeys = ['loadingState', 'remainingBudget']
  }

  render() {
    return (
      <React.Fragment>
        <SantanderKAMGraph
          header='Ubrugte point pr. KAM'
          state={this.state.loadingState}
          axisBottom={{ onClick: e => KamActions.getKamDetails(e, this.state.remainingBudget) }}
          onClick={KamActions.getKamDetails}
          cursor={{ cursor: 'pointer' }}
          showIcon={true}
          data={this.state.remainingBudget}
          longestName={
            this.state.remainingBudget && !!this.state.remainingBudget.length && Math.max(...this.state.remainingBudget.map(el => el.user.length))
          }
          keys={['Ubrugte Point']}
          indexBy={'user'}
          axisLeft={{ format: '~s' }}
          getTotalKamDetails={KamActions.getTotalKamDetails}
          pagesCount={Math.ceil(this.state.remainingBudget.length / 14)}
          legends={{ translateX: -30 }}
        />
      </React.Fragment>
    )
  }
}
