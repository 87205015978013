import React from 'react'
import styled from 'styled-components'

const StyledH1 = styled.h1`
  text-align: center;
  margin: 50px 0;
`

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <StyledH1>Ubs, der skete en ukendt fejl...</StyledH1>
        </div>
      )
    }
    return this.props.children
  }
}
