import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/cmsActions'

export const cmsSlice = createSlice({
  name: 'cms',
  initialState: {
    cmsData: {},
    brandCmsData: {},
    loadingState: false,
    shopCmsData: {},
    savedShopCmsData: {},
    headerLogoSizeData: {},
    themeOneMenuBarColors: {},
    savedHeaderConfigDetails: {},
    loginPageDetails: {},
    savedLoginPageDetails: {},
    footerDetails: {},
    savedFooterDetails: {},
    themeOverrides: {},
    savedthemeOverrides: {},
    profileDetails: {},
    savedProfileDetails: {},
    personalShopperDetails: {},
    savedpersonalShopperDetails: {},
    wishlistDetails: {},
    savedWishlistDetails: {},
    brandShops: [],
    shopProducts: [],
    shopCategories: [],
    shopCategoryProducts: [],
    hofDetails: {},
    savedhofDetails: {},
    countDownDetails: {},
    savedCountDownDetails: {}
  },
  reducers: {
    setBrandShops: (state, action) => {
      state.brandShops = action.payload
    },
    setShopProducts: (state, action) => {
      state.shopProducts = action.payload
    },
    setShopCategoryProducts: (state, action) => {
      state.shopCategoryProducts = action.payload
    },
    setShopCategories: (state, action) => {
      state.shopCategories = action.payload
    },
    setCmsData: (state, action) => {
      state.cmsData = action.payload
    },
    resetCmsData: state => {
      state.cmsData = {}
    },
    setBrandCmsData: (state, action) => {
      state.brandCmsData = action.payload
    },
    updateBannerContent: (state, action) => {
      return {
        ...state,
        cmsData: { ...state.cmsData, [action.payload.name]: action.payload.value }
      }
    },
    cmsCreationSuccess: state => {
      state.loadingState = STATUS.CMS_SUCCESS
    },
    cmsCreationFailure: state => {
      state.loadingState = STATUS.CMS_FAILED
    },
    clearToast: state => {
      state.loadingState = false
    },
    updateShopBannerContent: (state, action) => {
      return {
        ...state,
        shopCmsData: { ...state.shopCmsData, [action.payload.name]: action.payload.value }
      }
    },
    setShopCmsData: (state, action) => {
      state.shopCmsData = action.payload
    },
    resetShopCmsData: state => {
      state.shopCmsData = {}
    },
    setShopDetailsCmsData: (state, action) => {
      state.savedShopCmsData = action.payload
    },
    updateHeaderConfig: (state, action) => {
      return {
        ...state,
        headerLogoSizeData: { ...state.headerLogoSizeData, [action.payload.name]: action.payload.value },
        themeOneMenuBarColors: { ...state.themeOneMenuBarColors, [action.payload.name]: action.payload.value }
      }
    },
    setHeaderConfigData: (state, action) => {
      state.headerLogoSizeData = action.payload
      state.themeOneMenuBarColors = action.payload
    },
    resetHeaderConfigData: state => {
      state.headerLogoSizeData = {}
      state.themeOneMenuBarColors = {}
    },
    setSavedHeaderConfigDetails: (state, action) => {
      state.savedHeaderConfigDetails = action.payload
    },
    updateClubCmsContent: (state, action) => {
      return {
        ...state,
        [action.payload.fieldName]: { ...state[action.payload.fieldName], [action.payload.name]: action.payload.value }
      }
    },
    setClubCmsData: (state, action) => {
      state[action.payload.fieldName] = action.payload.response
    },
    resetClubCmsData: (state, action) => {
      state[action.payload.fieldName] = {}
    },
    setSavedClubCmsDetails: (state, action) => {
      state[action.payload.fieldName] = action.payload.response
    }
  }
})
export const {
  setCmsData,
  setBrandShops,
  setShopProducts,
  setShopCategories,
  setShopCategoryProducts,
  resetCmsData,
  updateBannerContent,
  setBrandCmsData,
  cmsCreationSuccess,
  cmsCreationFailure,
  clearToast,
  updateShopBannerContent,
  setShopCmsData,
  resetShopCmsData,
  setShopDetailsCmsData,
  updateHeaderConfig,
  setHeaderConfigData,
  resetHeaderConfigData,
  setSavedHeaderConfigDetails,
  updateClubCmsContent,
  setClubCmsData,
  resetClubCmsData,
  setSavedClubCmsDetails
} = cmsSlice.actions
export default cmsSlice.reducer
