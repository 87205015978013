/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'
import strip_tags from 'Components/striptags'

export const STATUS = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  VAULT_FAILED: null,
  VAULT_SUCCESS: null,
  FETCHING_VAULT_LIST: null,
  FETCHING_VAULT_LIST_FAILED: null,
  FETCHING_VAULT: null,
  FETCHING_VAULT_FAILED: null,
  DELETE_VAULT_ERROR: null,
  DELETE_VAULT_SUCCESS: null
})

export const VaultActions = createActions([
  'getVaults',
  'createEmptyVault',
  'getVaultDetails',
  'saveVault',
  'updateVaultField',
  'clearToast',
  'deleteVault',
  'setToDefault',
  'getBrandDefaultVault'
])

export class VaultStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = VaultActions
    this.state = {
      loadingState: '',
      vaults: [],
      brandDefaultVault: [],
      vaultDetails: {
        BrandID: '',
        vaultName: '',
        vaultSlug: '',
        isDefault: 0
      }
    }
  }
  getVaults(BrandID = null) {
    this.setState({ loadingState: STATUS.FETCHING_VAULT_LIST })
    GET('vaults/pool', BrandID && { BrandID }).then(responseJSON => {
      let nextState = {
        vaults: []
      }
      if (responseJSON.error) {
        nextState.loadingState = STATUS.FETCHING_VAULT_LIST_FAILED
      } else {
        nextState.vaults = responseJSON
        nextState.loadingState = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  getBrandDefaultVault() {
    this.setState({ loadingState: STATUS.FETCHING_VAULT_LIST })
    GET('vaults/getBrandDefaultVault').then(responseJSON => {
      let nextState = {
        brandDefaultVault: []
      }
      if (responseJSON.error) {
        nextState.loadingState = STATUS.FETCHING_VAULT_LIST_FAILED
      } else {
        nextState.brandDefaultVault = responseJSON
        nextState.loadingState = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  createEmptyVault() {
    this.setState({
      vaultDetails: {
        BrandID: '',
        vaultName: '',
        vaultSlug: '',
        isDefault: 0
      }
    })
  }

  getVaultDetails(VaultID) {
    this.setState({ loadingState: STATUS.FETCHING_VAULT })
    GET('vaults/fetch', { VaultID }).then(responseJSON => {
      let nextState = {
        vaultDetails: {}
      }
      if (responseJSON.error) {
        nextState.loadingState = STATUS.FETCHING_VAULT_FAILED
      } else {
        nextState.vaultDetails = responseJSON
        nextState.loadingState = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  saveVault(value) {
    this.setState({ loadingState: STATUS.IS_SAVING })
    const data = value ? value : this.state.vaultDetails
    POST('vaults/replace', data).then(responseJSON => {
      let nextState = {
        vaultDetails: this.state.vaultDetails
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.loadingState = STATUS.VAULT_FAILED
        } else {
          nextState.vaultDetails = responseJSON
          nextState.loadingState = STATUS.VAULT_SUCCESS
        }
      }
      this.setState(nextState)
    })
  }

  updateVaultField(field) {
    let value = strip_tags(field.target.value)
    let vaultDetails = this.state.vaultDetails
    vaultDetails[field.target.name] = field.target.name === 'isDefault' || field.target.name === 'BrandID' ? Number(value) : value
    this.setState({ vaultDetails })
  }

  clearToast(loadingState) {
    loadingState ? this.setState({ loadingState }) : this.setState({ loadingState: null })
  }

  deleteVault(VaultID = null) {
    if (VaultID) {
      POST('vaults/remove', { VaultID }).then(responseJSON => {
        if (responseJSON) {
          if (responseJSON.error) {
            this.setState({ loadingState: STATUS.DELETE_VAULT_ERROR })
          } else {
            this.setState({ loadingState: STATUS.DELETE_VAULT_SUCCESS })
          }
        }
      })
    }
  }

  setToDefault(vault) {
    vault.isDefault = 1
    this.saveVault(vault)
  }
}
