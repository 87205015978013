import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form } from 'reactstrap'
import _ from 'lodash'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { STATUS, getSupplierDetails, saveSupplier } from 'Reducers/actions/supplierActions'
import { clearToast, createEmptySupplier, updateSupplierField } from 'Reducers/supplierReducer'
import { connect } from 'react-redux'

class supplierForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.SupplierID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.getSupplierDetails(this.props.match.params.SupplierID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.createEmptySupplier()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.props.supplierDetails.supplierName ? true : false
    }

    this.setState({ fieldValidation })
    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveSupplierForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      this.props.saveSupplier(this.props.supplierDetails)
    }
  }

  render() {
    if (this.props.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/suppliers' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Leverandører</strong>}>
          <Form>
            <FormField
              required
              data={this.props.supplierDetails}
              field={'supplierName'}
              name='Name'
              onChange={e => {
                this.props.updateSupplierField({ name: e.target.name, value: e.target.value })
                this.validateForm()
              }}
            />
            <FormField
              data={this.props.supplierDetails}
              field={'supplierUID'}
              name='UID'
              onChange={e => {
                this.props.updateSupplierField({ name: e.target.name, value: e.target.value })
              }}
            />
            <Button onClick={this.saveSupplierForm} disabled={this.props.loadingState === STATUS.IS_SAVING}>
              {this.props.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ loadingState: state.supplier.loadingState, supplierDetails: state.supplier.supplierDetails })

export default connect(mapStateToProps, { getSupplierDetails, clearToast, createEmptySupplier, updateSupplierField, saveSupplier })(supplierForm)
