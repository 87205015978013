import Reflux, { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import { translateData } from '../util/translations'
import keyMirror from 'keymirror'
import store from 'Reducers/store'
import { batchCreationSuccess, batchCreationFailure } from 'Reducers/dataImportReducer'

export const STATUS = keyMirror({
  FETCHING_DATASETS: null,
  DATASETS_FETCHED: null,
  DATASETS_ERROR: null,
  DATASET_ERROR: null,
  TAKING_INPUT: null,
  DATASET_SUCCESS: null,
  REDIRECT: null,
  FETCHING_DATASET: null,
  FETCHING_DATASET_ERROR: null,
  SAVING: null,
  FILE_IMPORT_ERROR: null,
  FILE_IMPORT_SUCCESS: null,
  FETCHING_BATCH: null,
  FETCHING_BATCH_ERROR: null,
  DATASET_DELETE_SUCCESS: null,
  DATASET_DELETE_ERROR: null,
  FETCHING_ADMIN_DATASETS: null,
  FETCHING_ADMIN_DATASETS_FAILED: null,
  FETCHING_DATASET_ELEMENTS: null,
  FETCHING_DATASET_ELEMENTS_FAILED: null
})

export const DatasetActions = createActions([
  'getDatasets',
  'clearListToast',
  'deleteDataset',
  'createEmptyDataset',
  'getDataset',
  'addDatasetColumn',
  'removeDatasetColumn',
  'updateDatasetColumn',
  'updateDataset',
  'saveDataset',
  'clearToast',
  'updateFields',
  'clearFileImportToast',
  'mapRecords',
  'updateConstraint',
  'updateFormField',
  'saveBatch',
  'updateColumnField',
  'getBatchDetails',
  'updateCSVFile',
  'createEmptyBatch',
  'mapDatasetColumns',
  'getAdminDatasets',
  'getDatasetElements',
  'toggleInActiveDataset',
  'toggleActiveDataset',
  'removeDataset',
  'toggleInActiveElements',
  'toggleActiveElements',
  'removeDatasetElement',
  'setLimit'
])

export class DatasetStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = DatasetActions
    this.state = {
      status: null,
      batchData: {
        batchFiles: {},
        batchOptions: {
          columns: [],
          skipHeader: false
        },
        batchRole: '',
        batchComment: '',
        batchEncoding: '',
        uniqueConstraint: ''
      },
      mappedCSVRecords: [],
      datasets: [],
      datasetDetails: {
        moduleName: '',
        moduleDescription: '',
        columns: []
      },
      showActiveDatasets: true,
      showInActiveDatasets: false,
      datasetFilter: 'active',
      showActiveElements: true,
      showInActiveElements: false,
      datasetElementsFilter: 'active',
      datasetElements: [],
      elementsCount: 0,
      adminDatasets: [],
      start: 0,
      limit: 100000
    }
  }

  getDatasets() {
    if (this.state.status === STATUS.FETCHING_DATASETS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_DATASETS })
    GET('datasets/pool').then(responseJSON => {
      let nextState = {
        datasets: [],
        mappedCSVRecords: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.DATASETS_ERROR
        } else {
          responseJSON = translateData(responseJSON)

          if (
            !Reflux.GlobalState.LoginStore.settings ||
            !Reflux.GlobalState.LoginStore.settings.features ||
            !Reflux.GlobalState.LoginStore.settings.features.enableWalletActivation
          ) {
            responseJSON = responseJSON.filter(d => d.datasetTableName !== 'WalletActivation')
          }
          if (
            !Reflux.GlobalState.LoginStore.settings ||
            !Reflux.GlobalState.LoginStore.settings.features ||
            !Reflux.GlobalState.LoginStore.settings.features.enableUserDepartment
          ) {
            responseJSON = responseJSON.filter(d => d.datasetTableName !== 'UserDepartment')
          }
          nextState.datasets = responseJSON
          nextState.status = STATUS.DATASETS_FETCHED
        }
      }
      this.setState(nextState)
    })
  }

  clearListToast() {
    this.setState({ status: null })
  }

  deleteDataset(DatasetID = null) {
    if (DatasetID) {
      POST('datasets/remove', { DatasetID: DatasetID }).then(responseJSON => {
        if (responseJSON) {
          if (responseJSON.error) {
            this.setState({ status: STATUS.DATASET_DELETE_ERROR })
          } else {
            this.setState({ status: STATUS.DATASET_DELETE_SUCCESS })
          }
        }
      })
    }
  }

  createEmptyDataset() {
    this.setState({ datasetDetails: { moduleName: '', moduleDescription: '', columns: [] } })
  }

  getDataset(DatasetID = null) {
    if (DatasetID) {
      this.setState({ status: STATUS.FETCHING_DATASET })
      GET('datasets/fetch', { DatasetID: DatasetID }).then(responseJSON => {
        let nextState = {
          datasetDetails: {}
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.FETCHING_DATASET_ERROR
          } else {
            nextState.status = STATUS.TAKING_INPUT
            nextState.datasetDetails = responseJSON
          }
        }
        this.setState(nextState)
      })
    }
  }

  addDatasetColumn(data) {
    let datasetDetails = this.state.datasetDetails
    datasetDetails.columns.push(data)
    this.setState({ datasetDetails })
  }

  updateDatasetColumn(field, index) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let datasetDetails = this.state.datasetDetails
    let column = datasetDetails.columns[index]
    column[fieldName] = fieldValue
    this.setState({ datasetDetails })
  }

  removeDatasetColumn(index) {
    let datasetDetails = this.state.datasetDetails
    datasetDetails.columns.splice(index, 1)
    this.setState({ datasetDetails })
  }

  updateDataset(field) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let datasetDetails = this.state.datasetDetails
    datasetDetails[fieldName] = fieldValue
    this.setState({ datasetDetails })
  }

  saveDataset() {
    if (this.state.datasetDetails.moduleName) {
      this.setState({ status: STATUS.SAVING })
      POST('datasets/replace', this.state.datasetDetails).then(responseJSON => {
        let nextState = {
          datasetDetails: this.state.datasetDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.DATASET_ERROR
          } else {
            nextState.datasetDetails = responseJSON
            nextState.status = STATUS.DATASET_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }

  clearToast(value) {
    value ? this.setState({ status: value }) : this.setState({ status: STATUS.TAKING_INPUT })
  }

  updateFields(batchData, type) {
    let datasetDetails = this.state.datasetDetails
    if (type === 'remove-csv' && datasetDetails.columns.length) {
      datasetDetails.columns.map(item => {
        if (item.csvColumnName) {
          delete item.csvColumnName
        }
        if (item.csvColumnNo) {
          delete item.csvColumnNo
        }
      })
    }
    this.setState({ batchData, mappedCSVRecords: [], unMappedCSVRecords: [], datasetDetails })
  }

  clearFileImportToast(status) {
    status ? this.setState({ status }) : this.setState({ status: STATUS.TAKING_INPUT })
  }

  mapRecords(data) {
    this.setState({ batchData: data })
    let headers = []
    let mappedCSVRecords = []
    let unMappedCSVRecords = []
    this.state.datasetDetails.columns.map((item, index) => {
      const datacolumnName = item.datacolumnName.toLowerCase()
      const columnName = item.columnName.toLowerCase()
      if (this.state.batchData && this.state.batchData.columns && this.state.batchData.columns.length) {
        headers = this.state.batchData.columns
        headers.map(function(header, ind) {
          if (
            header.csvColumnName.length > 3 &&
            (columnName.search(header.csvColumnName.toLowerCase()) > -1 || datacolumnName.search(header.csvColumnName.toLowerCase()) > -1) &&
            !header.datacolumnName
          ) {
            header.datacolumnName = item.datacolumnName
            item.csvColumnName = header.csvColumnName
            item.csvColumnNo = ind + 1
          }
          return header
        })
      }
      item.id = (index + 1).toString()
      if (!item.csvColumnName) {
        item.csvColumnName = ''
      }
      if (!item.csvColumnNo) {
        item.csvColumnNo = null
      }
      item.csvColumnMatchExpression = ''
      item.csvColumnMatchValue = ''
      item.csvColumnDefault = ''
      mappedCSVRecords.push(item)
    })
    let batchData = this.state.batchData
    if (batchData && batchData.columns) {
      batchData.columns = headers
    }
    this.setState({ batchData, mappedCSVRecords, unMappedCSVRecords })
  }

  updateConstraint(field) {
    let batchData = this.state.batchData
    batchData[field.target.name] = field.target.value
    let value = strip_tags(field.target.value)
    let columns = []
    if (this.state.mappedCSVRecords.length) {
      columns = this.state.mappedCSVRecords
      columns.map(item => {
        item.datacolumnName === value ? (item.primarykey = true) : 'primarykey' in item && delete item.primarykey
        return item
      })
      this.setState({ mappedCSVRecords: columns })
    } else {
      let datasetDetails = this.state.datasetDetails
      datasetDetails.columns.map(item => {
        item.datacolumnName === value ? (item.primarykey = true) : 'primarykey' in item && delete item.primarykey
        return item
      })
      this.setState({ datasetDetails, batchData })
    }
  }

  updateFormField(field) {
    let batchData = this.state.batchData
    let value = strip_tags(field.target.value)
    batchData[field.target.name] = value
    if (field.target.name === 'skipHeader') {
      batchData.batchOptions[field.target.name] = field.target.checked
    }
    this.setState({ batchData })
  }

  saveBatch(batchObj = null) {
    let batchData = batchObj ? batchObj : this.state.batchData
    batchData.batchOptions.delimiter = ';'
    if (batchData.batchEncoding === '') {
      batchData.batchEncoding = 'UTF-8'
    }
    if (!batchObj) {
      batchData.batchOptions.columns = []
      this.state.mappedCSVRecords.map(item => {
        let obj = {}
        if (item.DatacolumnID) {
          obj.DatacolumnID = item.DatacolumnID
          obj.csvColumnNo = item.csvColumnNo
          obj.csvColumnMatchExpression = item.csvColumnMatchExpression
          obj.csvColumnMatchValue = item.csvColumnMatchValue
          obj.csvColumnDefault = item.csvColumnDefault
          if (item.primarykey || item.primary === 'on') {
            obj.primarykey = item.primarykey ? item.primarykey : item.primary
          }
          batchData.batchOptions.columns.push(obj)
        }
      })
    }
    delete batchData.columns
    if (!batchObj) {
      this.setState({ status: STATUS.SAVING })
    }
    let api = 'batch/upload'
    if (batchData.BatchID) {
      api = 'batch/update'
    }
    POST(api, batchData).then(responseJSON => {
      let nextState = {
        batchData: this.state.batchData
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FILE_IMPORT_ERROR
          if (batchObj) {
            store.dispatch(batchCreationFailure())
          }
        } else {
          nextState.status = STATUS.REDIRECT
          if (batchObj) {
            store.dispatch(batchCreationSuccess())
          }
        }
      }
      if (!batchObj) {
        this.setState(nextState)
      }
    })
  }

  updateColumnField(field, index) {
    let mappedCSVRecords = this.state.mappedCSVRecords
    let column = mappedCSVRecords[index]
    column[field.target.name] = field.target.value
    this.setState({ mappedCSVRecords })
  }

  getBatchDetails(BatchID = null, batch) {
    if (BatchID) {
      this.setState({ status: STATUS.FETCHING_BATCH })
      GET('batch/fetch', { BatchID: BatchID }).then(responseJSON => {
        let nextState = {
          batchData: {}
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.FETCHING_BATCH_ERROR
          } else {
            nextState.status = STATUS.TAKING_INPUT
            responseJSON.batchOptions = JSON.parse(responseJSON.batchOptions)
            if (responseJSON.batchOptions.columns && responseJSON.batchOptions.columns.length) {
              responseJSON.batchOptions.columns.map(batch => (batch.DatacolumnID = parseInt(batch.DatacolumnID)))
            }
            if (batch) {
              delete responseJSON.BatchID
              delete responseJSON.batchFlowStatus
              responseJSON.batchFiles = []
            }
            nextState.batchData = responseJSON
            if (responseJSON.dataset) {
              nextState.datasetDetails = responseJSON.dataset
            }
          }
        }
        this.setState(nextState)
        this.mapBatchRecords()
      })
    }
  }

  mapBatchRecords() {
    let mappedCSVRecords = []
    let batchData = this.state.batchData
    this.state.datasetDetails.columns.map((dataset, index) => {
      var obj = Object.assign(dataset)
      obj.id = (index + 1).toString()
      this.state.batchData.batchOptions.columns.map(item => {
        if (dataset.DatacolumnID === Number(item.DatacolumnID)) {
          obj = Object.assign(dataset, item)
          obj.csvColumnName = obj.columnName
          if (item.primarykey || item.primary === 'on') {
            batchData.uniqueConstraint = dataset.datacolumnName
          }
        }
      })
      mappedCSVRecords.push(obj)
    })
    this.setState({ mappedCSVRecords, batchData })
  }

  updateCSVFile(batchData) {
    this.setState({ batchData })
  }
  createEmptyBatch() {
    this.setState({
      batchData: {
        batchFiles: {},
        batchOptions: {
          columns: [],
          skipHeader: false
        },
        batchRole: '',
        batchComment: '',
        batchEncoding: '',
        uniqueConstraint: ''
      },
      unMappedCSVRecords: [],
      mappedCSVRecords: []
    })
  }

  mapDatasetColumns(data) {
    let records = data.split('\n')
    let datasetDetails = this.state.datasetDetails
    const headers = records[0].split(';')
    datasetDetails.columns = []
    headers.map(item => {
      let columnName = item.replace(/[^a-zA-Z]/g, '')
      datasetDetails.columns.push({
        datacolumnName: columnName,
        columnName: columnName,
        datacolumnArguments: '',
        datacolumnDefault: '',
        datacolumnIndex: '',
        datacolumnFormat: '',
        datacolumnClause: '',
        datacolumnClauseDatacolumnID: 0
      })
    })
    this.setState({ datasetDetails })
  }

  getAdminDatasets() {
    if (this.state.status === STATUS.FETCHING_ADMIN_DATASETS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_ADMIN_DATASETS })
    GET('datasets/admin-pool').then(responseJSON => {
      let nextState = {
        adminDatasets: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_ADMIN_DATASETS_FAILED
        } else {
          responseJSON = translateData(responseJSON)
          nextState.adminDatasets = responseJSON
          nextState.status = STATUS.TAKING_INPUT
        }
      }
      this.setState(nextState)
    })
  }

  getDatasetElements(DatasetID = null) {
    if (DatasetID) {
      this.setState({ status: STATUS.FETCHING_DATASET_ELEMENTS })
      GET('datasets/admin-elements', { DatasetID, start: this.state.start, limit: this.state.limit }).then(responseJSON => {
        let nextState = {
          datasetElements: [],
          elementsCount: 0
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.FETCHING_DATASET_ELEMENTS_FAILED
          } else {
            responseJSON.elements = translateData(responseJSON.elements)
            nextState.datasetElements = responseJSON && responseJSON.elements
            nextState.elementsCount = responseJSON && responseJSON.count
            nextState.status = STATUS.TAKING_INPUT
          }
        }
        this.setState(nextState)
      })
    }
  }

  toggleActiveDataset() {
    let value = !this.state.showActiveDatasets ? 'active' : 'both'
    this.setState({ showActiveDatasets: !this.state.showActiveDatasets, datasetFilter: value, showInActiveDatasets: false })
  }
  toggleInActiveDataset() {
    let value = this.state.showInActiveDatasets ? 'both' : 'inactive'
    this.setState({ showInActiveDatasets: !this.state.showInActiveDatasets, datasetFilter: value, showActiveDatasets: false })
  }
  removeDataset(DatasetID = null, removed) {
    if (DatasetID) {
      POST('datasets/admin-remove', { DatasetID, removed: removed === 0 ? 1 : 0 }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          this.getAdminDatasets()
        }
      })
    }
  }
  toggleActiveElements() {
    let value = !this.state.showActiveElements ? 'active' : 'both'
    this.setState({ showActiveElements: !this.state.showActiveElements, datasetElementsFilter: value, showInActiveElements: false })
  }
  toggleInActiveElements() {
    let value = this.state.showInActiveElements ? 'both' : 'inactive'
    this.setState({ showInActiveElements: !this.state.showInActiveElements, datasetElementsFilter: value, showActiveElements: false })
  }
  removeDatasetElement(CustomID = null, DatasetID = null, removed) {
    if (CustomID && DatasetID) {
      POST('datasets/admin-remove-elements', { CustomIDs: [CustomID], DatasetID, removed: removed === 0 ? 1 : 0 }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          this.getDatasetElements(DatasetID)
        }
      })
    }
  }
  setLimit(data, DatasetID) {
    if (this.state.status === STATUS.FETCHING_DATASET_ELEMENTS) {
      return
    }
    let { start, limit } = this.state
    data === 'previous' ? (start = start - limit) : (start = start + limit)
    this.setState({ start })
    this.getDatasetElements(DatasetID)
  }
}
