import React from 'react'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OrderActions, OrderStore } from 'Stores/orderStore'
import { Card, CardBody } from 'reactstrap'
import { toast } from 'react-toastify'

export default class OrderPrint extends Component {
  constructor(props) {
    super(props)
    this.stores = [OrderStore]
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search)
    OrderActions.printOrder(Number(params.get('OrderID')))
  }

  componentDidUpdate() {
    if (this.state.printOrderTemplate && this.state.printOrderTemplate.htmlContent && this.state.showPrintDialog) {
      OrderActions.clearPrintToast()
      setTimeout(() => {
        window.document.write(document.getElementById('printTemplateDiv').innerHTML)
        window.print()
        window.close()
      }, 1000)
    }
    if (this.state.fetchingPrintOrderTemplateFailed) {
      toast.error(`${this.state.printErrorMessage}`, {
        autoClose: 7500,
        onClose: () => {
          OrderActions.clearToast()
        }
      })
      OrderActions.clearToast()
    }
  }

  render() {
    if (this.state.fetchingPrintOrderTemplate) {
      return <FontAwesomeIcon icon='spinner' spin size='xs' />
    }
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {this.state.printOrderTemplate && this.state.printOrderTemplate.htmlContent && (
              <div id='printTemplateDiv' dangerouslySetInnerHTML={{ __html: this.state.printOrderTemplate.htmlContent }}></div>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
