import React, { useEffect } from 'react'
import XLSX from 'xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { Button, Input, Row } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { dataParse, setInputHeaders, batchCreationStatusReset, resetImportForm } from 'Reducers/dataImportReducer'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { postalCodesDK } from 'Util/denmarkPostalCodes.js'
import { postalCodesFI } from 'Util/finlandPostalCodes.js'
import { postalCodesSE } from 'Util/swedenPostalCodes.js'
import moment from 'moment'
import { DatasetStore } from 'Stores/datasetStore'

let importFile = null
let importFileData = null

export default class ImportOrders extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <ImportOrdersComponent props={this.props} />
      </React.Fragment>
    )
  }
}

function ImportOrdersComponent({ props }) {
  const defaultOrdersImportHeaders = [
    { columnName: 'Navn', DatacolumnID: 1703, dataType: 'string', ColumnNo: 1 },
    { columnName: 'UID', DatacolumnID: 1704, dataType: 'string', ColumnNo: 2 },
    { columnName: 'Email', DatacolumnID: 1705, dataType: 'email', ColumnNo: 3 },
    { columnName: 'Mobil', DatacolumnID: 1706, dataType: 'number', ColumnNo: 4 },
    { columnName: 'Firma', DatacolumnID: 1707, dataType: 'string', ColumnNo: 5 },
    { columnName: 'Adresse', DatacolumnID: 1708, dataType: 'string', ColumnNo: 6 },
    { columnName: 'Postnr', DatacolumnID: 1709, dataType: 'number', ColumnNo: 7 },
    { columnName: 'By', DatacolumnID: 1710, dataType: 'string', ColumnNo: 8 },
    { columnName: 'Land', DatacolumnID: 1711, dataType: 'string', ColumnNo: 9 },
    { columnName: 'Produkt', DatacolumnID: 1712, dataType: 'string', ColumnNo: 10 },
    { columnName: 'Variation titel', DatacolumnID: 1713, dataType: 'string', ColumnNo: 11 },
    { columnName: 'VariationId', DatacolumnID: 1714, dataType: 'string', ColumnNo: 12 },
    { columnName: 'ProductID', DatacolumnID: 1715, dataType: 'number', ColumnNo: 13 }
  ]
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false)
  const platformData = useSelector(state => state.login.settings)
  const brandOrderImportDatasetID =
    platformData &&
    platformData.features &&
    platformData.features.brandOrderImportDatasetID &&
    !isNaN(platformData.features.brandOrderImportDatasetID)
      ? platformData.features.brandOrderImportDatasetID
      : 91
  const customHeaders =
    platformData && platformData.features && platformData.features.orderImportHeaders && platformData.features.orderImportHeaders.length > 0
      ? platformData.features.orderImportHeaders
      : null
  const headers = customHeaders ? customHeaders : defaultOrdersImportHeaders
  const UID_COL = headers.filter(item => item.columnName === 'UID').length ? headers.filter(item => item.columnName === 'UID')[0].DatacolumnID : 1704
  const EMAIL_COL = headers.filter(item => item.columnName === 'Email').length
    ? headers.filter(item => item.columnName === 'Email')[0].DatacolumnID
    : 1705
  const MOBILE_COL = headers.filter(item => item.columnName === 'Mobil').length
    ? headers.filter(item => item.columnName === 'Mobil')[0].DatacolumnID
    : 1706
  const ZIP_COL = headers.filter(item => item.columnName === 'Postnr').length
    ? headers.filter(item => item.columnName === 'Postnr')[0].DatacolumnID
    : 1709
  const PRODUCT_COL = headers.filter(item => item.columnName === 'ProductID').length
    ? headers.filter(item => item.columnName === 'ProductID')[0].DatacolumnID
    : 1715
  const VARIATION_COL = headers.filter(item => item.columnName === 'VariationId').length
    ? headers.filter(item => item.columnName === 'VariationId')[0].DatacolumnID
    : 1714
  const fileHeaders = customHeaders ? customHeaders.map(item => item.columnName) : defaultOrdersImportHeaders.map(item => item.columnName)

  const enableOtherCountriesValidation = platformData && platformData.features && platformData.features.enableOtherCountriesValidation
  const parsedData = useSelector(state => state.dataImport.parsedData)
  const inputHeaders = useSelector(state => state.dataImport.inputHeaders)
  const batchCreationSuccessful = useSelector(state => state.dataImport.batchCreationSuccessful)
  const batchCreationFailed = useSelector(state => state.dataImport.batchCreationFailed)
  const postalCodesListDK = postalCodesDK.map(item => Number(item.postalCode))
  const postalCodesListFI = postalCodesFI.map(item => item.postalCode)
  const postalCodesListSE = postalCodesSE.map(item => Number(item.postalCode))
  const batchComment = `Manual Import by ${platformData.userName}`
  const dispatch = useDispatch()
  const regExp = /.*\(.*\).*/
  let validationSuccess = false
  let options = {}

  const downloadEXCEL = () => {
    let xlData = []
    xlData.push(fileHeaders)
    let worksheet = XLSX.utils.aoa_to_sheet(xlData)
    let workbook = new XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'download')
    XLSX.writeFile(workbook, 'Order-Import-template.xlsx')
  }

  useEffect(() => {
    if (validationSuccess) {
      setSubmitButtonEnabled(true)
    } else {
      setSubmitButtonEnabled(false)
    }
    if (batchCreationSuccessful === true) {
      toast.success('Batch created successfully', {
        autoClose: 3000
      })
      dispatch(batchCreationStatusReset())
      props.history.push('/import/orders')
    }
    if (batchCreationFailed === true) {
      toast.error('Batch creation Failed. Please try again', {
        autoClose: 3000
      })
      dispatch(batchCreationStatusReset())
    }
  })

  useEffect(() => {
    dispatch(resetImportForm())
  }, [])

  const handleExcelUpload = e => {
    e.preventDefault()
    let files = e.target.files
    importFile = files[0]
    let reader = new FileReader()
    reader.onload = function(e) {
      importFileData = e.target.result
      let readedData = XLSX.read(importFileData, { type: 'binary', cellDates: true, raw: true })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]
      const sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 })
      let fileValidation = []
      let errorMessage = null
      if (sheetData && sheetData.length > 0) {
        if (JSON.stringify(fileHeaders) !== JSON.stringify(sheetData[0])) {
          fileValidation = sheetData[0].map(item =>
            fileHeaders.some(header => header.includes(regExp.test(item) ? item.split('(')[0].trim() : item.trim()))
          )
        }
        if (sheetData.length < 2) {
          errorMessage =
            (fileValidation && !fileValidation.includes(false)) || !fileValidation.length
              ? 'Data mangler. Venligst upload ny korrekt fil med data.'
              : null
        }
      } else {
        errorMessage = 'Filen er tom. Venligst upload ny korrekt fil med data.'
      }
      if ((fileValidation && fileValidation.includes(false)) || errorMessage) {
        toast.error(errorMessage ? errorMessage : 'Forkert fil skabelon. Venligst upload korrekt skabelon', {
          autoClose: 3000
        })
        dispatch(batchCreationStatusReset())
        return
      }
      dispatch(dataParse(sheetData))
    }
    reader.readAsBinaryString(importFile)
  }

  const prepareTableData = (sheetData = parsedData) => {
    let tableData = []
    if (sheetData && sheetData.length) {
      let inputfileHeaders = sheetData[0]
      if (inputfileHeaders.length) {
        dispatch(setInputHeaders(inputfileHeaders))
        sheetData.forEach((item, i) => {
          let obj = {
            rowNo: i + 1
          }
          inputHeaders.forEach((val, index) => {
            if (item[index] && item[index].toString().trim() !== '') {
              obj[val] = item[index]
            }
          })
          if (i > 0 && Object.keys(obj).length > 1) {
            const trimmedRow = Object.entries(obj).reduce((acc, curr) => {
              let [key, value] = curr
              acc[typeof key === 'string' ? key.trim() : key] = typeof value === 'string' ? value.trim() : value
              return acc
            }, {})
            tableData.push(trimmedRow)
          }
        })
        tableData = validateTableData(tableData)
        return tableData
      }
    }
    return []
  }

  const validateTableData = tableData => {
    validationSuccess = true
    let columns = customHeaders ? customHeaders : defaultOrdersImportHeaders
    for (let row of tableData) {
      row.error = []
      for (let column of columns) {
        let columnName =
          column.columnName in row
            ? column.columnName
            : regExp.test(column.columnName)
            ? inputHeaders.includes(column.columnName.split('(')[0].trim())
              ? column.columnName.split('(')[0].trim()
              : column.columnName
            : column.columnName
        const cellData = row[columnName]
        // Mandatory validations
        if (column.DatacolumnID === UID_COL && !cellData) {
          validationSuccess = false
          if (!(columnName in row)) {
            row[columnName] = null
          }
          row.error.push(columnName)
        }
        if (column.DatacolumnID === EMAIL_COL && !cellData) {
          validationSuccess = false
          if (!(columnName in row)) {
            row[columnName] = null
          }
          row.error.push(columnName)
        }
        if (column.DatacolumnID === MOBILE_COL && !cellData) {
          validationSuccess = false
          if (!(columnName in row)) {
            row[columnName] = null
          }
          row.error.push(columnName)
        }
        if (column.DatacolumnID === PRODUCT_COL && !cellData) {
          validationSuccess = false
          if (!(columnName in row)) {
            row[columnName] = null
          }
          row.error.push(columnName)
        }
        if (column.DatacolumnID === VARIATION_COL && !cellData) {
          validationSuccess = false
          if (!(columnName in row)) {
            row[columnName] = null
          }
          row.error.push(columnName)
        }
        let date = null
        switch (column.dataType) {
          case 'number':
            if (cellData && (cellData === 0 || isNaN(cellData))) {
              validationSuccess = false
              row.error.push(columnName)
            }
            if (column.DatacolumnID === ZIP_COL) {
              if (
                !postalCodesListDK.includes(Number(cellData)) &&
                (!enableOtherCountriesValidation || (!postalCodesListFI.includes(cellData) && !postalCodesListSE.includes(Number(cellData))))
              ) {
                validationSuccess = false
                row.error.push(columnName)
              }
            }
            break
          case 'email':
            if (
              cellData &&
              !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(cellData.trim())
            ) {
              validationSuccess = false
              row.error.push(columnName)
            }
            break
          case 'date':
            if (cellData) {
              date = moment(cellData, 'DD.MM.YYYY')
              if (!date || !date.isValid()) {
                date = moment(cellData, 'DD-MM-YYYY')
                if (!date.isValid()) {
                  validationSuccess = false
                  row.error.push(columnName)
                }
              }
            }
            break
          default:
            break
        }
      }
    }
    return tableData
  }

  const handleSubmit = () => {
    initiateBatchCreation()
  }

  const initiateBatchCreation = (sheetData = parsedData, file = importFile, data = importFileData) => {
    if (sheetData && file && data) {
      let csvContent = ''
      let batchData = {}
      batchData.DatasetID = brandOrderImportDatasetID
      batchData.batchComment = batchComment
      batchData.batchEncoding = 'UTF-8'
      batchData.batchOptions = prepareBatchOptions()
      batchData.batchRole = 'Brand'
      batchData.skipHeader = 'on'
      batchData.uniqueConstraint = ''
      sheetData.forEach(function(infoArray, index) {
        let dataString = infoArray.join(';')
        csvContent += index < sheetData.length ? dataString + '\n' : dataString
      })
      batchData.batchFiles = {
        File: file,
        contentType: file.type,
        fileExtension: file.type.split('/')[1],
        name: file.name
      }
      batchData.batchFiles.csvDataString = data
      batchData.batchFiles = [batchData.batchFiles]
      parseCsvString(csvContent, batchData)
    }
  }

  const parseCsvString = (result, batchData) => {
    let records = result.split('\n')
    let csvDataString = ''
    records.map(item => {
      let data = item.trim()
      let csv = data.replaceAll(';', '').trim()
      if (csv.length > 1) {
        csvDataString = csvDataString + item + '\n'
      }
    })
    batchData.batchFiles[0].csvDataString = csvDataString
    saveBatch(batchData)
  }

  function saveBatch(batchObj) {
    let dataset = new DatasetStore()
    dataset.saveBatch(batchObj)
  }

  const prepareBatchOptions = () => {
    let columns = []
    headers.map(item => {
      let obj = {
        DatacolumnID: item.DatacolumnID,
        csvColumnDefault: '',
        csvColumnMatchExpression: '',
        csvColumnMatchValue: '',
        csvColumnNo: `${item.ColumnNo}`
      }
      columns.push(obj)
    })

    return {
      skipHeader: true,
      columns: columns
    }
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Upload Order fil
          <span className='float-right'>
            <Button
              className='margin-5'
              onClick={() => {
                downloadEXCEL()
              }}
            >
              Download tomt Excel ark til oprettelse <FontAwesomeIcon icon='file-excel' />
            </Button>
          </span>
        </strong>
      }
    >
      <label className='select-file-button margin-5' htmlFor='fileupload'>
        Vælg Excel/CSV fil til upload <FontAwesomeIcon icon='file-excel' />
      </label>
      <Input
        onChange={event => {
          handleExcelUpload(event)
        }}
        onClick={event => {
          event.target.value = null
        }}
        id='fileupload'
        type='file'
        className='display-none'
      />
      <Button disabled={!submitButtonEnabled} onClick={handleSubmit}>
        Upload Excel/CSV
      </Button>
      {parsedData && parsedData.length && !submitButtonEnabled && (
        <React.Fragment>
          <Row className='ml-2 mb-2' style={{ color: 'red' }}>
            Fremhævede celler mangler enten obligatoriske værdier eller har forkert format. Venligst se nedenfor og upload en ny tilrettet fil.
          </Row>
          <Row className='ml-2'>Nogle eksempler på korrekte data:</Row>
          <Row className='ml-5'>&#8226; UID - Feltet må ikke være blankt ( 123 / 123test / 123@test.com )</Row>
          <Row className='ml-5'>&#8226; Navn - Feltet må ikke være blankt </Row>
          <Row className='ml-5'>&#8226; Mobil - Feltet skal være et tal </Row>
          <Row className='ml-5'>&#8226; Email - Feltet må ikke være blankt ( abc123@test.com ) </Row>
          <Row className='ml-5'>&#8226; Zip Code - Det skal være gyldigt dansk postnummer </Row>
          <Row className='ml-5'>&#8226; VariationID - Feltet skal være et tal og ikke tomt </Row>
          <Row className='ml-5 mb-3'>&#8226; ProductID - Feltet skal være et tal og ikke tomt </Row>
        </React.Fragment>
      )}
      <DynamicTable data={prepareTableData(parsedData)} options={options} headers={['rowNo'].concat(...inputHeaders.map(header => header.trim()))} />
    </CollapsibleCard>
  )
}
