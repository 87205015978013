/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'
import strip_tags from 'Components/striptags'

export const STATUS = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  CATEGORY_FAILED: null,
  CATEGORY_SUCCESS: null,
  FETCHING_CATEGORY_LIST: null,
  FETCHING_CATEGORY_LIST_FAILED: null,
  FETCHING_CATEGORY: null,
  FETCHING_CATEGORY_FAILED: null,
  DELETE_CATEGORY_FAILED: null,
  DELETE_CATEGORY_SUCCESS: null
})

export const CategoryActions = createActions([
  'getCategories',
  'getSubCategories',
  'toggleLinkedSubCategory',
  'createEmptyCategory',
  'getCategoryDetails',
  'saveCategory',
  'updateCategoryField',
  'clearToast',
  'deleteCategory',
  'openDeleteModal',
  'uploadCategoryImage',
  'updateData',
  'getLanguages',
  'removeTranslation',
  'addTranslation'
])

export class CategoryStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = CategoryActions
    this.state = {
      loadingState: '',
      categories: [],
      subCategories: [],
      categoryDetails: {
        BrandID: 9,
        categoryName: '',
        categoryTaxonomy: '',
        imageURL: '',
        translations: []
      },
      file: null,
      openModal: false,
      category: {},
      isUploading: false,
      languages: []
    }
  }
  getCategories() {
    this.setState({ loadingState: STATUS.FETCHING_CATEGORY_LIST, openModal: false, catgeory: {} })
    GET('categories/adminPool').then(responseJSON => {
      let nextState = {
        categories: []
      }
      if (responseJSON.error) {
        nextState.loadingState = STATUS.FETCHING_CATEGORY_LIST_FAILED
      } else {
        nextState.categories = responseJSON
        nextState.loadingState = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }
  getSubCategories() {
    this.setState({ loadingState: STATUS.FETCHING_CATEGORY_LIST, openModal: false, catgeory: {} })
    GET('SubCategories/adminPool').then(responseJSON => {
      let nextState = {
        subCategories: []
      }
      if (responseJSON.error) {
        nextState.loadingState = STATUS.FETCHING_CATEGORY_LIST_FAILED
      } else {
        nextState.subCategories = responseJSON
        nextState.loadingState = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  toggleLinkedSubCategory(subCategory) {
    if (!subCategory || !subCategory.SubCategoryID) return
    const category = this.state.categoryDetails
    let linkedSubCategoryIDs = category.linkedSubCategoryIDs || []
    if (linkedSubCategoryIDs.includes(subCategory.SubCategoryID)) {
      linkedSubCategoryIDs = linkedSubCategoryIDs.filter(scid => scid !== subCategory.SubCategoryID)
    } else {
      linkedSubCategoryIDs.push(subCategory.SubCategoryID)
    }
    category.linkedSubCategoryIDs = linkedSubCategoryIDs
    this.setState({ categoryDetails: category })
  }

  createEmptyCategory() {
    this.setState({
      categoryDetails: {
        BrandID: 9,
        categoryName: '',
        categoryTaxonomy: '',
        imageURL: '',
        translations: [
          {
            LanguageID: null,
            categoryName: ''
          }
        ]
      }
    })
  }

  getLanguages(BrandID) {
    GET('brands/brand-languages', { BrandIDs: [BrandID] }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        this.setState({ languages: responseJSON })
      }
    })
  }

  removeTranslation = i => {
    let translations = this.state.categoryDetails.translations
    if (translations[i].CategoryTranslationID) {
      translations[i].removed = 1
      if (translations.length === 1) {
        translations.push({
          LanguageID: null,
          categoryName: ''
        })
      }
    } else {
      translations.splice(i, 1)
      if (!translations.length) {
        translations.push({
          LanguageID: null,
          categoryName: ''
        })
      } else if (translations.filter(translation => translation.CategoryTranslationID).length) {
        translations.push({
          LanguageID: null,
          categoryName: ''
        })
      }
    }
    this.setState({ categoryDetails: { ...this.state.categoryDetails, translations: translations } })
  }

  addTranslation = () => {
    let translations = this.state.categoryDetails.translations
    translations.push({ categoryName: '', LanguageID: null })
    this.setState({ categoryDetails: { ...this.state.categoryDetails, translations: translations } })
  }

  updateData(field, index, type) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let categoryDetails = this.state.categoryDetails
    let column = categoryDetails[type][index]

    if (fieldName === 'LanguageID' && type === 'translations') {
      fieldValue = Number(fieldValue)
    }

    if (!column[fieldName]) {
      column[fieldName] = ''
    }

    column[fieldName] = fieldValue

    this.setState({ categoryDetails })
  }

  getCategoryDetails(CategoryID) {
    this.setState({ loadingState: STATUS.FETCHING_CATEGORY })
    GET('categories/fetch', { CategoryID }).then(responseJSON => {
      let nextState = {
        categoryDetails: {}
      }
      if (responseJSON.error) {
        nextState.loadingState = STATUS.FETCHING_CATEGORY_FAILED
      } else {
        nextState.categoryDetails = responseJSON
        if (responseJSON.translations && !responseJSON.translations.length) {
          responseJSON.translations = [
            {
              LanguageID: null,
              categoryName: ''
            }
          ]
        }
        this.getLanguages(responseJSON.BrandID)
        nextState.loadingState = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  saveCategory(value) {
    this.setState({ loadingState: STATUS.IS_SAVING })
    const data = value ? value : this.state.categoryDetails
    data.imageURL = data.imageURL ? data.imageURL : ''
    data.translations =
      data.translations && data.translations.length
        ? data.translations.filter(translation => translation.categoryName !== '' || translation.LanguageID !== null)
        : []
    POST('categories/replace', data).then(responseJSON => {
      let nextState = {
        categoryDetails: this.state.categoryDetails
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.loadingState = STATUS.CATEGORY_FAILED
        } else {
          nextState.categoryDetails = responseJSON
          nextState.loadingState = STATUS.CATEGORY_SUCCESS
        }
      }
      this.setState(nextState)
    })
  }

  updateCategoryField(field) {
    let value = strip_tags(field.target.value)
    let categoryDetails = this.state.categoryDetails
    categoryDetails[field.target.name] = field.target.name === 'BrandID' ? Number(value) : value
    this.setState({ categoryDetails })
  }

  clearToast(loadingState) {
    loadingState ? this.setState({ loadingState }) : this.setState({ loadingState: null })
  }

  deleteCategory(CategoryID = null) {
    if (CategoryID) {
      POST('categories/removeCategory', { CategoryID: CategoryID }).then(responseJSON => {
        if (responseJSON && responseJSON.error) {
          this.setState({ loadingState: STATUS.DELETE_CATEGORY_FAILED, openModal: false, category: {} })
        } else {
          this.setState({ loadingState: STATUS.DELETE_CATEGORY_SUCCESS, openModal: false, category: {} })
        }
      })
    }
  }

  openDeleteModal = category => {
    this.setState({ openModal: true, category })
  }

  uploadCategoryImage(data) {
    let file = data.target.files[0]
    this.setState({ file: file, isUploading: true })
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON) {
        let categoryDetails = this.state.categoryDetails
        categoryDetails.imageURL = responseJSON.imageOriginSource ? responseJSON.imageOriginSource : ''
        this.setState({ categoryDetails, isUploading: false })
      }
    })
  }
}
