import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import CampaignForm from 'Modules/campaigns/campaignForm'
import { CampaignActions, CampaignStore, STATUS } from 'Stores/campaignStore'

export default class CampaignEdit extends Component {
  constructor(props) {
    super(props)
    this.stores = [CampaignStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    CampaignActions.getCampaignDetails(this.props.match.params.CampaignID)
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.CAMPAIGN_ERROR) {
      toast.error('Campaign update failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.FETCHING_CAMPAIGN_ERROR) {
      toast.error('fetching Campaign failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.REDIRECT) {
      CampaignActions.clearToast()
      toast.success('Successfully Updated', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
  }
  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to={'/macros/campaigns'} />
    }
    return <CampaignForm isKam={this.props.isKam} readOnly={this.props.readOnly} match={this.props.match} />
  }
}
