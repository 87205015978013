import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup } from 'reactstrap'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import { STATUS } from '../../components/dashboard/useDashboardData'
import { Link } from 'react-router-dom'
import getBrandslug from 'Util/getBrandslug'

export default class KAMOverview extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <SantanderKAMOverview />
      </React.Fragment>
    )
  }
}

function SantanderKAMOverview() {
  const brandSlug = getBrandslug()
  const [kamData, loadingStatus] = useDashboardData(
    brandSlug && brandSlug === 'santander' ? 'supervisors/all-santander-supervisors' : 'supervisors/all-jyske-supervisors'
  )

  return (
    <React.Fragment>
      {brandSlug !== 'santander' && (
        <CollapsibleCard startOpen header={<strong>Super KAM</strong>}>
          {loadingStatus === STATUS.LOADING && <FontAwesomeIcon icon='spinner' spin />}
          {loadingStatus === STATUS.SUCCESS && (
            <DynamicTable
              data={kamData && kamData.superKams}
              headers={{
                UserID: 'ID',
                userName: 'Navn',
                userUID: 'UID',
                userAccess: 'Adgang',
                userRole: 'Role',
                userEmail: 'Email',
                userPhone: 'Telephone',
                userCompany: 'Virksomhed',
                userCompanyUID: 'CVR',
                userDepartment: 'Department'
              }}
              options={{
                addField: () => {
                  return (
                    <td>
                      <ButtonGroup>
                        <Button tag={Link} to={`/kams/details`}>
                          Detaljer
                        </Button>
                      </ButtonGroup>
                    </td>
                  )
                }
              }}
            />
          )}
        </CollapsibleCard>
      )}
      <CollapsibleCard startOpen header={<strong>KAM Oversigt</strong>}>
        {loadingStatus === STATUS.LOADING && <FontAwesomeIcon icon='spinner' spin />}
        {loadingStatus === STATUS.SUCCESS && (
          <DynamicTable
            data={brandSlug === 'santander' ? kamData : kamData && kamData.kams}
            headers={{
              UserID: 'ID',
              userName: 'Navn',
              userUID: 'UID',
              userAccess: 'Adgang',
              userRole: 'Role',
              userEmail: 'Email',
              userPhone: 'Telephone',
              userCompany: 'Virksomhed',
              userCompanyUID: 'CVR',
              userDepartment: 'Department'
            }}
            options={{
              addField: e => {
                return (
                  <td>
                    {brandSlug === 'santander' && (
                      <ButtonGroup>
                        <Button tag={Link} to={'/kams/' + e.userUID + '/cvr'}>
                          Detaljer
                        </Button>
                      </ButtonGroup>
                    )}
                    {brandSlug !== 'santander' && (
                      <ButtonGroup>
                        <Button tag={Link} to={`/kams/${e.userUID}/details`}>
                          Detaljer
                        </Button>
                      </ButtonGroup>
                    )}
                  </td>
                )
              }
            }}
          />
        )}
      </CollapsibleCard>
    </React.Fragment>
  )
}
