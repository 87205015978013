import React, { useEffect, useState } from 'react'
import { Button, Form } from 'reactstrap'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormField from 'Components/formField'
import CollapsibleCard from 'Components/collapsibleCard'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'

const FontForm = props => {
  const [fontDetails, setFontDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('LOADING')

  const { FontID } = props.match.params

  const handleChange = e => {
    setFontDetails({
      ...fontDetails,
      [e.target.name]:
        e.target.type === 'checkbox' || e.target.type === 'radio'
          ? e.target.checked
          : e.target.type === 'number' || e.target.type === 'select-one'
          ? Number(e.target.value)
          : e.target.value
    })
  }

  const fetchCountdown = () => {
    setLoading(true)
    Proxies.GET('Font/fetch', { FontID }).then(responseJSON => {
      setLoading(false)
      if (!responseJSON.error) {
        setFontDetails(responseJSON)
      }
    })
  }

  const replaceCountdown = () => {
    Proxies.POST(`Font/replace`, FontID ? fontDetails : { ...fontDetails, removed: 0 }).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success(`Font ${FontID ? 'updated' : 'created'} successfully`, {
        autoClose: 7000
      })
      setStatus('SUCCESS')
    })
  }

  useEffect(() => {
    if (FontID) {
      fetchCountdown()
    }
  }, [])

  if (status === 'SUCCESS') {
    return <Redirect to='/font' />
  }

  return loading ? (
    <FontAwesomeIcon icon='spinner' spin />
  ) : (
    <CollapsibleCard startOpen header={<strong>Skrifttype</strong>}>
      <Form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <FormField name='Font name' field='fontName' type='text' placeholder='Roboto' data={fontDetails} onChange={handleChange} />
        <FormField
          name='Font url'
          field='fontUrl'
          placeholder='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
          type='text'
          data={fontDetails}
          onChange={handleChange}
        />
        <Button onClick={replaceCountdown}>Gem</Button>
      </Form>
      <div style={{ padding: '30px 0px 30px 0', display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div>
          For font url and name{' '}
          <a href='https://fonts.google.com/' rel='noopener noreferrer' target='_blank'>
            Click here
          </a>
          .<br></br>
        </div>
        <div>
          1. Select <span style={{ fontWeight: 900 }}>Font</span>
          {' > '} click <span style={{ fontWeight: 900 }}>Get Font</span> button{' > '} then, click{' '}
          <span style={{ fontWeight: 900 }}>Get emebed code</span> button.
        </div>
        <div>
          2. For font name copy font neo-grotesque name inside double quote with typeface family like -{' '}
          <span style={{ fontWeight: 900 }}>&quot;Roboto&quot;, sans-serif</span>
        </div>
        <div>
          3. Copy import url like -{' '}
          <span style={{ fontWeight: 900 }}>
            https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap
          </span>
        </div>
      </div>
    </CollapsibleCard>
  )
}

export default FontForm
