/* eslint-disable eqeqeq */
import React, { Fragment } from 'react'
import Reflux, { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import {
  Button,
  ButtonGroup,
  UncontrolledTooltip as Tooltip,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { LoginStore } from 'Stores/loginStore'
import { VaultStore, VaultActions } from 'Stores/vaultStore'
import { UserStore, UserActions } from 'Stores/userStore'
import styled from 'styled-components'
import _ from 'lodash'
import { HofStore, HofActions } from 'Stores/hofStore'

const userAccessData = ['APPROVED', 'LIMITED', 'EMPTY', 'WAITING', 'PAUSED', 'DECLINED', 'REMOVED']
const userRoles = ['System', 'Agent', 'Company', 'Client', 'Brand', 'User']
const userPrivilege = ['MANAGE', 'VIEW', 'EDIT', 'OWNER']
const StyledUserInfoTitleTd = styled.div`
  padding-right: 20px;
`

const User = props => {
  if (props.create) {
    return <UserCreate {...props} />
  }
  if (props.match && props.match.params && props.match.params.UserID) {
    return <UserDetail {...props} />
  }
  return <UserOverview {...props} />
}
export default User

class UserDetail extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserStore, LoginStore, VaultStore, HofStore]
    this.storeKeys = [
      'editedUserData',
      'editedUserDataPoints',
      'isSaving',
      'settings',
      'orders',
      'fetchingUserOrders',
      'showOrderDetails',
      'selectedOrder',
      'showUserSuccess',
      'showUserError',
      'showUniqueUIDError',
      'openModal',
      'openUserObfuscationModal',
      'scheduledObfuscationDate',
      'showlogOutStatus',
      'vaults',
      'brandDefaultVault',
      'brands',
      'brandFeatures',
      'isMSBAdmin'
    ]
    this.UserDetailRef = null
    this.isUserView = false
  }
  componentDidMount() {
    HofActions.getBrands()
    if (this.state.settings && this.state.settings.BrandID === 121 && this.state.settings.userPrivilege === 'VIEW') {
      this.isUserView = true
    }
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      UserActions.giveAccess()
    } else {
      VaultActions.getVaults()
    }
    if (this.props.adminUser) {
      this.props.match.params.UserID && UserActions.getAdminUserDetails(this.props.match.params.UserID)
    } else {
      this.props.match.params.UserID && UserActions.editUser(this.props.match.params.UserID)
    }
  }
  componentWillUnmount() {
    UserActions.resetUser()
  }
  componentDidUpdate() {
    if (this.state.showUserSuccess) {
      toast.success('Dine ændringer er gemt', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearToast()
        }
      })
      UserActions.clearToast()
      window.scrollTo(0, this.UserDetailRef.offsetTop)
    }
    if (this.state.showUserError) {
      toast.error('Der opstod en fejl ved opdateringen af brugeren', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearToast()
        }
      })
      UserActions.clearToast()
    }
    if (this.state.showUniqueUIDError) {
      toast.error('UID eksisterer allerede', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearToast()
        }
      })
      UserActions.clearToast()
    }
    if (this.state.showlogOutStatus === false) {
      toast.error('Der opstod en fejl ved log ud af brugeren', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearLogoutToast()
        }
      })
      UserActions.clearLogoutToast()
    }
    if (this.state.showlogOutStatus) {
      toast.success('Brugere Log Ud Successful', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearLogoutToast()
        }
      })
      UserActions.clearLogoutToast()
    }
  }
  render() {
    if (Object.keys(this.state.editedUserData).length == 0) {
      return <FontAwesomeIcon icon='sync' spin />
    }
    let userData = this.state.editedUserData
    const isUserMarkedForObfuscation =
      userData && userData.userAccess && userData.userAccess === 'TO_BE_OBFUSCATED' && userData.timeScheduled ? true : false
    const vaults = this.state.vaults
    const currentBrandID =
      this.state.isMSBAdmin && this.state.editedUserData.BrandID ? this.state.editedUserData.BrandID : this.state.settings.BrandID
    const showDefaultVaultField =
      this.state.brandFeatures && this.state.brandFeatures.features && this.state.brandFeatures.features.displayDefaultVault
        ? true
        : this.state.settings.features && this.state.settings.features.displayDefaultVault
        ? true
        : false
    const brandDefaultVault = this.state.vaults.filter(val => {
      return val.BrandID === currentBrandID && val.isDefault === 1
    })
    const brandDefaultVaultID = showDefaultVaultField && brandDefaultVault.length > 0 ? brandDefaultVault[0].VaultID : null
    if (!this.state.editedUserData.defaultVaultID && brandDefaultVaultID) {
      userData.defaultVaultID = brandDefaultVaultID
    }
    /*
		if(!userData.UserID || (userData.UserID !== UserID)) {
			userData = this.state.users.find((e) => {return e.UserID == UserID})
		}
		*/
    if (!userData) {
      return <span>Noget gik galt, der er vidst ikke en bruger med det ID</span>
    }

    return (
      <React.Fragment>
        {this.state.openModal && (
          <Modal isOpen={this.state.openModal} toggle={() => UserActions.logoutModal(this.state.openModal)} size='md'>
            <ModalHeader toggle={() => UserActions.logoutModal(this.state.openModal)}>Log Ud</ModalHeader>
            <ModalBody>
              <strong>Du er ved at lukke brugerens session ned. Brugeren vil skulle logge ind på ny</strong>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => UserActions.logoutUser(userData)}>ja</Button>
              <Button color='danger' onClick={() => UserActions.logoutModal(this.state.openModal)}>
                nej
              </Button>
            </ModalFooter>
          </Modal>
        )}
        {this.state.openUserObfuscationModal && (
          <Modal
            isOpen={this.state.openUserObfuscationModal}
            toggle={() => UserActions.userObfuscationModal(this.state.openUserObfuscationModal)}
            size='md'
          >
            <ModalHeader toggle={() => UserActions.userObfuscationModal(this.state.openUserObfuscationModal)}>
              {!isUserMarkedForObfuscation && 'Markeret til sletning'}
              {isUserMarkedForObfuscation && 'Fortryd sletning'}
            </ModalHeader>
            <ModalBody>
              <strong>
                {!isUserMarkedForObfuscation && 'Ønsker du at markere denne bruger til sletning?'}
                {isUserMarkedForObfuscation && 'Ønsker du at fortryde markeringen af denne bruger til sletning?'}
              </strong>
              <p>
                {' '}
                Planlagt dato : <strong>{moment(this.state.scheduledObfuscationDate).format('DD-MM-YYYY')}</strong>
              </p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => UserActions.confirmObfuscationSchedule(isUserMarkedForObfuscation)}>Ja</Button>
              <Button color='danger' onClick={() => UserActions.userObfuscationModal(this.state.openUserObfuscationModal)}>
                Nej
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <CollapsibleCard
          startOpen
          header={
            <React.Fragment>
              <strong>User # {userData.UserID}</strong>
              {userData.UserID && !this.props.adminUser && !userData.removed && !this.isUserView && (
                <Button style={{ float: 'right' }} onClick={() => UserActions.logoutModal(this.state.openModal)}>
                  Log Ud
                </Button>
              )}
            </React.Fragment>
          }
          ref={ref => (this.UserDetailRef = ref)}
        >
          <React.Fragment>
            <table>
              <tbody>
                <tr>
                  <StyledUserInfoTitleTd>Medlem Siden :</StyledUserInfoTitleTd>
                  <td>{moment(userData.timeInsert).format('DD/MM/YYYY')}</td>
                </tr>
                <tr>
                  <StyledUserInfoTitleTd>Sidst aktiv :</StyledUserInfoTitleTd>
                  <td>{userData.lastActiveTime ? moment(userData.lastActiveTime).format('HH:mm DD/MM/YYYY') : 'Ingen aktivitet'}</td>
                </tr>
                <tr>
                  <StyledUserInfoTitleTd>Nuværende point status :</StyledUserInfoTitleTd>
                  <td>{userData.userPointsComputed ? userData.userPointsComputed.toString() : 0}</td>
                </tr>
                <tr>
                  <StyledUserInfoTitleTd>Antal point brugt :</StyledUserInfoTitleTd>
                  <td>{userData.userPointsSpendComputed ? userData.userPointsSpendComputed.toString() : 0}</td>
                </tr>
              </tbody>
            </table>
            {((this.state.settings && this.state.settings.features && this.state.settings.features.PointExpiration) ||
              (this.state.brandFeatures && this.state.brandFeatures.features && this.state.brandFeatures.features.PointExpiration)) && (
              <table style={{ width: '100%' }} className='table-striped'>
                <tbody>
                  <tr>
                    <td>
                      <div className='pointsDot' style={{ backgroundColor: '#000000' }}></div>Udløber i denne måned
                    </td>
                    <td style={{ textAlign: 'right' }}>{this.state.editedUserDataPoints.running_out}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className='pointsDot' style={{ backgroundColor: '#e52727' }}></div>Udløber om 3 måneder
                    </td>
                    <td style={{ textAlign: 'right' }}>{this.state.editedUserDataPoints.running_out_3m}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className='pointsDot' style={{ backgroundColor: '#fff06b' }}></div>Udløber om 6 måneder
                    </td>
                    <td style={{ textAlign: 'right' }}>{this.state.editedUserDataPoints.running_out_6m}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className='pointsDot' style={{ backgroundColor: '#58bc2d' }}></div>Udløber om mere end 6 måneder
                    </td>
                    <td style={{ textAlign: 'right' }}>{this.state.editedUserDataPoints.running_out_beyond_6m}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </React.Fragment>
        </CollapsibleCard>
        <CollapsibleCard
          startOpen
          header={
            <React.Fragment>
              <strong>Settings</strong>
              {userData.UserID &&
                this.state.settings &&
                this.state.settings.userRole.toLowerCase() === 'system' &&
                this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
                !isUserMarkedForObfuscation && (
                  <Button color='danger' style={{ float: 'right' }} onClick={() => UserActions.previewObfuscationDate()}>
                    Fjern bruger
                  </Button>
                )}
              {userData.UserID &&
                this.state.settings &&
                this.state.settings.userRole.toLowerCase() === 'system' &&
                this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
                isUserMarkedForObfuscation && (
                  <Button color='danger' style={{ float: 'right' }} onClick={() => UserActions.previewObfuscationDate()}>
                    Fortryd fjern bruger
                  </Button>
                )}
            </React.Fragment>
          }
        >
          <UserForm
            isUserView={this.isUserView}
            userData={userData}
            vaults={vaults}
            showDefaultVaultField={showDefaultVaultField}
            brandDefaultVaultID={brandDefaultVaultID}
            pointExpiration={
              this.state.brandFeatures && this.state.brandFeatures.features && this.state.brandFeatures.features.PointExpiration
                ? true
                : this.state.settings && this.state.settings.features
                ? this.state.settings.features.PointExpiration
                : null
            }
            isSaving={this.state.isSaving}
            currentBrandID={currentBrandID}
            settings={this.state.settings}
            adminUser={this.props.adminUser}
            brands={_.sortBy(this.state.brands, 'brandName')}
          />
        </CollapsibleCard>
        {!this.props.adminUser && (
          <UserWallet
            UserID={userData.UserID}
            brandFeatures={this.state.brandFeatures}
            BrandID={userData.BrandID}
            disableWallet={userData.removed ? true : false}
          />
        )}
        {!this.props.adminUser && (
          <CollapsibleCard
            header={<strong>Ordrer</strong>}
            onCardOpen={currentCardState => {
              if (currentCardState) {
                UserActions.getUserOrders(userData.UserID)
              }
            }}
          >
            {this.state.fetchingUserOrders && <FontAwesomeIcon icon='sync' spin />}
            {!this.state.fetchingUserOrders && (
              <DynamicTable
                data={this.state.orders}
                headers={{
                  OrderID: '#',
                  orderlines: 'Produkter',
                  orderFlowStatus: 'Status',
                  shippingids: 'Trackingnumre',
                  timeInsert: 'Dato'
                }}
                options={{
                  startSort: {
                    dir: 'DESC',
                    column: 'OrderID'
                  },
                  fieldFunctions: {
                    orderlines: e => {
                      let orderlines = e.orderlines
                      return (
                        <React.Fragment>
                          {orderlines.map((line, i) => {
                            return (
                              <React.Fragment key={i}>
                                {line.orderlineTitle}
                                <br />
                              </React.Fragment>
                            )
                          })}
                        </React.Fragment>
                      )
                    },
                    supplier: e => {
                      let orderlines = e.orderlines
                      let suppliers = []
                      orderlines.forEach(line => {
                        if (line && line.product && line.product.supplier) {
                          suppliers.push(line.product.supplier.supplierName)
                        }
                      })
                      return (
                        <React.Fragment>
                          {suppliers.map((supplier, i) => {
                            return (
                              <React.Fragment key={i}>
                                {supplier}
                                <br />
                              </React.Fragment>
                            )
                          })}
                        </React.Fragment>
                      )
                    },
                    shippingids: e => {
                      if (!e.shippingids) {
                        return null
                      }
                      let shippingids = e.shippingids || []
                      return (
                        <Fragment>
                          {shippingids.map((id, i) => {
                            return (
                              <a key={i} href={'https://gls-group.eu/DK/da/find-pakke?match=' + id} target='_blank' rel='noopener noreferrer'>
                                {id}
                                <br />
                              </a>
                            )
                          })}
                        </Fragment>
                      )
                    }
                  },
                  addField: e => {
                    return (
                      <td>
                        <ButtonGroup>
                          <Tooltip target={'edit' + e.OrderID}>Detaljer</Tooltip>
                          <Button id={'edit' + e.OrderID} onClick={() => UserActions.viewOrderDetails(e)}>
                            <FontAwesomeIcon icon='info-circle' />
                          </Button>
                        </ButtonGroup>
                      </td>
                    )
                  }
                }}
              />
            )}
          </CollapsibleCard>
        )}
        {this.state.showOrderDetails && !this.props.adminUser && (
          <OrderDetailView selectedOrder={this.state.selectedOrder} onHandleViewDetailClick={() => UserActions.handleDetailViewClick()} />
        )}
      </React.Fragment>
    )
  }
}

export class UserWallet extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserStore, LoginStore]
    this.storeKeys = ['wallet', 'editedUserData', 'fetchingUserWallets', 'settings', 'brandFeatures', 'isMSBAdmin']
    this.brandFeatures = {}
    this.isUserView = false
  }
  componentDidMount() {
    if (this.state.settings && this.state.settings.BrandID === 121 && this.state.settings.userPrivilege === 'VIEW') {
      this.isUserView = true
    }
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      !this.state.isMSBAdmin && UserActions.giveAccess()
      this.props.UserID && UserActions.editUser(this.props.UserID)
    }
    if (this.props.startOpen && this.props.UserID) {
      UserActions.getUserWallet(this.props.UserID)
    }
  }
  render() {
    this.brandFeatures = this.props.brandFeatures ? this.props.brandFeatures : this.state.brandFeatures
    if (!this.props.UserID) {
      return <div>FEJL : Mangler UserID til UserWallet</div>
    }
    let headers = {
      WalletID: '#',
      walletNote: 'Note',
      walletAmountPoints: 'Point',
      timeInsert: 'Dato'
    }
    if (
      (this.brandDetails && this.brandDetails.features.displayDefaultVault) ||
      (this.state.settings.features && this.state.settings.features.displayDefaultVault)
    ) {
      headers.vaultName = 'Vault'
    }
    headers.functions = ' '
    return (
      <CollapsibleCard
        startOpen={this.props.startOpen}
        header={
          <React.Fragment>
            <strong>Wallet</strong>
            {!this.isUserView && (
              <>
                <Tooltip target={'newWalletUser' + this.props.UserID}>add to wallet</Tooltip>
                <Button
                  style={{ float: 'right' }}
                  disabled={this.state.editedUserData && this.state.editedUserData.removed ? true : false}
                  tag={Link}
                  to={{ pathname: '/users/' + this.props.UserID + '/wallet/new', ...(this.isMSBAdmin && { state: { BrandID: this.props.BrandID } }) }}
                  id={'newWalletUser' + this.props.UserID}
                >
                  <FontAwesomeIcon icon='plus' />
                </Button>
              </>
            )}
          </React.Fragment>
        }
        onCardOpen={currentCardState => {
          if (currentCardState) {
            UserActions.getUserWallet(this.props.UserID)
          }
        }}
      >
        {this.state.fetchingUserWallets && <FontAwesomeIcon icon='sync' spin />}
        {!this.state.fetchingUserWallets && (
          <DynamicTable
            data={this.state.wallet}
            headers={headers}
            options={{
              startSort: {
                dir: 'DESC',
                column: 'WalletID'
              },
              unsortable: ['functions'],
              fieldFunctions: {
                vaultName: e => {
                  return e.vault ? e.vault.vaultName : ''
                },
                functions: e => {
                  return (
                    <ButtonGroup>
                      <Tooltip target={'view' + e.WalletID}>Vis</Tooltip>
                      <Button tag={Link} to={'/users/' + this.props.UserID + '/wallet/' + e.WalletID} id={'view' + e.WalletID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    </ButtonGroup>
                  )
                }
              }
            }}
          />
        )}
      </CollapsibleCard>
    )
  }
}

class UserCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserStore, VaultStore, LoginStore, HofStore]
    this.storeKeys = [
      'settings',
      'showUserError',
      'showUniqueUIDError',
      'editedUserData',
      'isSaving',
      'brands',
      'vaults',
      'brandFeatures',
      'isMSBAdmin'
    ]
  }
  componentWillMount() {
    super.componentWillMount()
    HofActions.getBrands()
    if (_.get(this.props, 'location.search')) {
      const search = this.props.location.search.split('=')
      this.props.adminUser ? UserActions.getAdminUserDetails(Number(search[1]), true) : UserActions.editUser(Number(search[1]), true)
    } else {
      VaultActions.getVaults()
      UserActions.resetUser()
    }
  }
  componentDidUpdate() {
    if (this.state.showUserError) {
      toast.error('Der opstod en fejl i oprettelsen af brugeren', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearToast()
        }
      })
      UserActions.clearToast()
    }
    if (this.state.showUniqueUIDError) {
      toast.error('UID eksisterer allerede', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearToast()
        }
      })
      UserActions.clearToast()
    }
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      UserActions.giveAccess()
    }
  }
  render() {
    let userData = this.state.editedUserData
    const showDefaultVaultField =
      this.state.brandFeatures && this.state.brandFeatures.features && this.state.brandFeatures.features.displayDefaultVault
        ? true
        : this.state.settings.features && this.state.settings.features.displayDefaultVault
        ? true
        : false
    const vaults = this.state.vaults
    const currentBrandID = this.state.isMSBAdmin && this.state.editedUserData ? this.state.editedUserData.BrandID : this.state.settings.BrandID
    const brandDefaultVault = this.state.vaults.filter(val => {
      return val.BrandID === currentBrandID && val.isDefault === 1
    })
    const brandDefaultVaultID = showDefaultVaultField && brandDefaultVault.length > 0 ? brandDefaultVault[0].VaultID : null
    if (!this.state.editedUserData.defaultVaultID && brandDefaultVaultID) {
      userData.defaultVaultID = brandDefaultVaultID
    }
    if (userData.UserID) {
      return this.props.adminUser ? <Redirect to={'/admin-users/' + userData.UserID} /> : <Redirect to={'/users/' + userData.UserID} />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>Opret bruger</strong>}>
          <UserForm
            userData={userData}
            showDefaultVaultField={showDefaultVaultField}
            brandDefaultVaultID={brandDefaultVaultID}
            vaults={vaults}
            isSaving={this.state.isSaving}
            currentBrandID={currentBrandID}
            settings={this.state.settings}
            adminUser={this.props.adminUser}
            brands={_.sortBy(this.state.brands, 'BrandID')}
          />
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

const UserField = (data, fieldName, fieldNameReadable, type = 'text', fieldCode = null) => {
  return (
    <FormGroup>
      <Row>
        <Col xs='3'>
          <Label for={fieldName}>{fieldNameReadable} </Label>
        </Col>
        <Col xs='9'>
          {fieldCode ? (
            fieldCode
          ) : (
            <Input
              type={type}
              value={data[fieldName] ? data[fieldName] : ''}
              name={fieldName}
              id={fieldName}
              onChange={e => {
                UserActions.updateUserField(data.UserID, e)
              }}
            />
          )}
        </Col>
      </Row>
    </FormGroup>
  )
}

const UserForm = props => {
  let userData = props.userData
  let isSaving = props.isSaving
  const vaults = props.vaults
  const showDefaultVaultField = props.showDefaultVaultField
  const currentBrandID = props.currentBrandID
  const brandDefaultVaultID = Number(props.brandDefaultVaultID) ? Number(props.brandDefaultVaultID) : ''
  let userAccess = ['APPROVED', 'WAITING', 'DECLINED']
  let countryCodes = ['+45', '+46', '+298', '+358']
  let userRoles = ['System', 'Agent', 'Company', 'Client', 'Brand', 'User']
  let userPrivilege = ['MANAGE', 'VIEW', 'EDIT', 'OWNER']

  if (
    Reflux.GlobalState.LoginStore.settings &&
    (Reflux.GlobalState.LoginStore.settings.userRole == 'System' || Reflux.GlobalState.LoginStore.settings.userRole == 'Agent') &&
    (Reflux.GlobalState.LoginStore.settings.userPrivilege == 'MANAGE' || Reflux.GlobalState.LoginStore.settings.userPrivilege == 'OWNER')
  ) {
    userAccess = ['APPROVED', 'LIMITED', 'EMPTY', 'WAITING', 'PAUSED', 'DECLINED', 'TO_BE_OBFUSCATED']
  }
  if (userData.userBirthday && userData.userBirthday !== ' ') {
    userData.userBirthday = moment(userData.userBirthday).format('YYYY-MM-DD')
  }
  if (userData.timeStart && userData.timeStart !== ' ') {
    userData.timeStart = moment(userData.timeStart).format('YYYY-MM-DD')
  }
  if (userData.timeEnd && userData.timeEnd !== ' ') {
    userData.timeEnd = moment(userData.timeEnd).format('YYYY-MM-DD')
  }
  return (
    <fieldset disabled={props.isUserView}>
      <Form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        {UserField(
          userData,
          'userAccess',
          'Adgang',
          null,
          <Input
            type='select'
            value={userData.userAccess ? userData.userAccess : 'APPROVED'}
            name='userAccess'
            id='userAccess'
            onChange={e => {
              UserActions.updateUserField(userData.UserID, e)
            }}
            disabled={userData.disableUserAccess}
          >
            {userAccess.map((e, i) => {
              return <option key={i}>{e}</option>
            })}
          </Input>
        )}
        {userData &&
          userData.userAccess &&
          userData.userAccess === 'TO_BE_OBFUSCATED' &&
          userData.timeScheduled &&
          UserField(
            userData,
            'timeScheduled',
            'Planned date',
            null,
            <Input value={moment(userData.timeScheduled).format('DD-MM-YYYY')} name='timeScheduled' id='timeScheduled' disabled />
          )}
        <hr />
        {props.settings.userRole === 'System' && props.settings.userPrivilege === 'MANAGE' && (
          <React.Fragment>
            {props.settings.BrandID === 9 &&
              UserField(
                userData,
                'BrandID',
                'Brand',
                null,
                <Input
                  type='select'
                  value={userData.BrandID}
                  name='BrandID'
                  id='BrandID'
                  onChange={e => {
                    UserActions.updateUserField(userData.UserID, e)
                  }}
                >
                  <option value={''}>Select Brand</option>
                  {props.brands.map((e, i) => {
                    return (
                      <option key={i} value={e.BrandID}>
                        {e.BrandID}-{e.brandName}
                      </option>
                    )
                  })}
                </Input>
              )}
            {((props.settings.BrandID === 9 && userData.UserID) || props.adminUser) && (
              <React.Fragment>
                {UserField(
                  userData,
                  'userRole',
                  'User Role',
                  null,
                  <Input
                    type='select'
                    value={userData.userRole}
                    name='userRole'
                    id='userRole'
                    onChange={e => {
                      UserActions.updateUserField(userData.UserID, e)
                    }}
                  >
                    <option value={''}>Select Role</option>
                    {userRoles.map((e, i) => {
                      return <option key={i}>{e}</option>
                    })}
                  </Input>
                )}
                {UserField(
                  userData,
                  'userPrivilege',
                  'User Privilege',
                  null,
                  <Input
                    type='select'
                    value={userData.userPrivilege}
                    name='userPrivilege'
                    id='userPrivilege'
                    onChange={e => {
                      UserActions.updateUserField(userData.UserID, e)
                    }}
                  >
                    <option value=''>Select Privilege</option>
                    {userPrivilege.map((e, i) => {
                      return <option key={i}>{e}</option>
                    })}
                  </Input>
                )}
                <hr />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {UserField(userData, 'userName', 'Fulde navn')}
        {UserField(userData, 'userFirstName', 'Navn til notifikationer')}
        {UserField(userData, 'userTitle', 'Titel')}
        <hr />
        {UserField(userData, 'userEmail', 'email')}
        {UserField(
          userData,
          'userPhone',
          'Telefon',
          null,
          <Row>
            <Col xs='2'>
              <Input
                type='select'
                value={userData.userPhoneCode}
                name='userPhoneCode'
                id='userPhoneCode'
                onChange={e => {
                  UserActions.updateUserField(userData.UserID, e)
                }}
              >
                {countryCodes.map((e, i) => {
                  return <option key={i}>{e}</option>
                })}
              </Input>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={userData.userPhone}
                name='userPhone'
                id='userPhone'
                onChange={e => {
                  UserActions.updateUserField(userData.UserID, e)
                }}
              ></Input>
            </Col>
          </Row>
        )}
        {UserField(
          userData,
          'userExtraPhone',
          'Ekstra telefon',
          null,
          <Row>
            <Col xs='2'>
              <Input
                type='select'
                value={userData.userExtraPhoneCode}
                name='userExtraPhoneCode'
                id='userExtraPhoneCode'
                onChange={e => {
                  UserActions.updateUserField(userData.UserID, e)
                }}
              >
                {countryCodes.map((e, i) => {
                  return <option key={i}>{e}</option>
                })}
              </Input>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={userData.userExtraPhone}
                name='userExtraPhone'
                id='userExtraPhone'
                onChange={e => {
                  UserActions.updateUserField(userData.UserID, e)
                }}
              ></Input>
            </Col>
          </Row>
        )}
        <hr />
        {UserField(userData, 'userIdentification', 'Identifikation')}
        {UserField(userData, 'userUID', 'Unikt ID')}
        <hr />
        {UserField(userData, 'userGroup', 'Gruppe')}
        {UserField(userData, 'userGroupUID', 'Gruppe UID')}
        <hr />
        {UserField(userData, 'userSupervisor', 'Tilsynsførende')}
        {UserField(userData, 'userSupervisorUID', 'Tilsynsførende UID')}
        <hr />
        {UserField(userData, 'userCompany', 'Virksomhed')}
        {UserField(userData, 'userCompanyUID', 'CVR')}
        {UserField(userData, 'userDepartment', 'Afdeling')}
        {UserField(userData, 'userDepartmentUID', 'Afdelingens UID')}
        <hr />
        {UserField(userData, 'userAddress', 'Adresse')}
        {UserField(userData, 'userCity', 'By')}
        {UserField(userData, 'userZipcode', 'Postnummer')}
        <hr />
        {UserField(userData, 'userFlags', 'Flag', 'textarea')}
        {showDefaultVaultField &&
          UserField(
            userData,
            'defaultVaultID',
            'Default Vault',
            null,
            <Input
              type='select'
              value={userData.defaultVaultID ? Number(userData.defaultVaultID) : Number(brandDefaultVaultID)}
              name='defaultVaultID'
              id='defaultVaultID'
              onChange={e => {
                UserActions.updateUserField(userData.UserID, e)
              }}
            >
              {vaults.map((e, i) => {
                if (e.BrandID === currentBrandID) {
                  return (
                    <option key={i} value={Number(e.VaultID)}>
                      {e.VaultID} - {e.vaultName}
                    </option>
                  )
                }
              })}
            </Input>
          )}

        <hr />
        {UserField(userData, 'userBirthday', 'Fødselsdag', 'date')}
        {UserField(userData, 'timeStart', 'Start dato', 'date')}
        {UserField(userData, 'timeEnd', 'Slut dato', 'date')}
        <hr />

        {props.pointExpiration &&
          userData.UserID &&
          UserField(
            userData,
            'pointSafemode',
            'Point udløb',
            null,
            <div>
              <Input
                type='checkbox'
                checked={userData.pointSafemode ? userData.pointSafemode : 0}
                name='pointSafemode'
                id='pointSafemode'
                onChange={e => {
                  UserActions.updateUserField(userData.UserID, e)
                }}
              ></Input>
              <p>Aktivér 30 dages henstand</p>
            </div>
          )}
        <Button onClick={() => UserActions.saveUser(props.adminUser)} disabled={isSaving}>
          {isSaving ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button color='danger' onClick={UserActions.resetUser} className='ml-2'>
          Nulstil
        </Button>
      </Form>
    </fieldset>
  )
}

class UserOverview extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserStore, LoginStore]
    this.advanceSearchColumns = [
      { key: 'UserID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'userName', name: 'Name', operators: ['LIKE', 'EXACT'] },
      { key: 'userCompany', name: 'Company', operators: ['LIKE', 'EXACT'] },
      { key: 'userUID', name: 'Uid', operators: ['LIKE', 'EXACT'] },
      { key: 'userPhone', name: 'Phone', operators: ['LIKE', 'EXACT'] },
      { key: 'userExtraPhone', name: 'Extra Phone', operators: ['LIKE', 'EXACT'] },
      { key: 'userEmail', name: 'Email', operators: ['LIKE', 'EXACT'] },
      { key: 'userAccess', name: 'Access', operators: ['EXACT'] },
      { key: 'userPointsComputed', name: 'Point', operators: ['EXACT', 'LESS_THAN', 'GREATER_THAN'] }
    ]
    this.isUserView = false
  }
  componentDidMount() {
    if (this.state.settings && this.state.settings.BrandID === 121 && this.state.settings.userPrivilege === 'VIEW') {
      this.isUserView = true
    }
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      UserActions.giveAccess()
      this.advanceSearchColumns.push({ key: 'BrandID', name: 'Brand ID', operators: ['EXACT', 'LESS_THAN', 'GREATER_THAN'] })
      this.advanceSearchColumns.push({ key: 'brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT'] })
      this.advanceSearchColumns.push({ key: 'userPointsWaiting', name: 'Waiting Point', operators: ['EXACT', 'LESS_THAN', 'GREATER_THAN'] })
    }
    if (this.state && this.state.settings && this.state.settings.features && this.state.settings.features.enableWalletActivation) {
      this.advanceSearchColumns.push({ key: 'userPointsWaiting', name: 'Waiting Point', operators: ['EXACT', 'LESS_THAN', 'GREATER_THAN'] })
    }
    if ((!this.state.users || this.state.users.length == 0) && !this.state.fetchingUsers) {
      UserActions.getUsers()
    }
    if (this.state.editedUserData.UserID) {
      UserActions.resetUser()
    }
  }
  componentDidUpdate() {
    if (this.state.editedUserData.UserID) {
      UserActions.resetUser()
    }
    if (this.state.userShadowError) {
      toast.error('Bruger skal have accepteret betingelser.', { autoClose: 7500, onClose: UserActions.clearUserShadowError })
    }
    if (this.state.showStatusError) {
      toast.error('update status failed', { autoClose: 7500, onClose: UserActions.clearStatusToast })
    }
    if (this.state.userNotChecked) {
      toast.error('please select user to change the status', { autoClose: 7500, onClose: UserActions.clearStatusToast })
      UserActions.clearStatusToast()
    }
  }
  render() {
    let tableHeaders = {
      UserID: 'ID',
      ...(this.state.isMSBAdmin && { BrandID: 'Brand ID', brandName: 'Brand' }),
      userName: 'Navn',
      userCompany: 'Virksomhed',
      userUID: 'Uid',
      userPhone: 'Telefon',
      userExtraPhone: 'Ekstra telefon',
      userEmail: 'Email',
      userAccess: 'Adgang',
      ...(this.state.settings && this.state.settings.BrandID === 187 && { cprExists: 'CPR Exists' }),
      userPointsComputed: 'Point',
      ...(((this.state.settings && this.state.settings.features && this.state.settings.features.enableWalletActivation) || this.state.isMSBAdmin) && {
        userPointsWaiting: 'Waiting Point'
      }),
      actions: ' '
    }

    const statusDropdown = () => {
      return (
        <React.Fragment>
          {this.state.isMSBAdmin && (
            <Col xs={2} style={{ float: 'right' }}>
              <Input type='select' name='status' id='status' value={this.state.status} onChange={e => UserActions.openStatusModal(e)}>
                <option value={''}>Change User Role</option>
                {Object.keys(userRoles).map((s, is) => {
                  return (
                    <option key={is} value={userRoles[s]}>
                      {userRoles[s]}
                    </option>
                  )
                })}
              </Input>
            </Col>
          )}
          {this.state.isMSBAdmin && (
            <Col xs={2} style={{ float: 'right' }}>
              <Input type='select' name='status' id='status' value={this.state.status} onChange={e => UserActions.openStatusModal(e)}>
                <option value={''}>Change User Privilege</option>
                {Object.keys(userPrivilege).map((s, is) => {
                  return (
                    <option key={is} value={userPrivilege[s]}>
                      {userPrivilege[s]}
                    </option>
                  )
                })}
              </Input>
            </Col>
          )}
          <Col xs={2} style={{ float: 'right' }}>
            <Input type='select' name='status' id='status' value={this.state.status} onChange={e => UserActions.openStatusModal(e)}>
              <option value={''} disabled>
                Change Status
              </option>
              {Object.keys(userAccessData).map((s, is) => {
                return (
                  <option key={is} value={userAccessData[s]}>
                    {userAccessData[s]}
                  </option>
                )
              })}
            </Input>
          </Col>
        </React.Fragment>
      )
    }
    let tableoptions = {
      ...(Reflux.GlobalState.LoginStore.settings &&
        Reflux.GlobalState.LoginStore.settings.userRole == 'System' &&
        Reflux.GlobalState.LoginStore.settings.userPrivilege == 'MANAGE' && {
          showAdminAccessDropdown: true,
          showContent: statusDropdown,
          isAllChecked: this.state.isAllChecked,
          preserveAllChecked: UserActions.preserveAllChecked
        }),
      preserveSearch: UserActions.preserveSearch,
      preserveSort: UserActions.preserveSort,
      preserveAdvanceSearch: UserActions.preserveAdvanceSearch,
      preservePagination: UserActions.preservePagination,
      preservePaginationAmount: UserActions.preservePaginationAmount,
      advanceSearchColumns: this.advanceSearchColumns,
      startSort: {
        dir: this.state.sortDir ? this.state.sortDir : 'ASC',
        column: this.state.sortColumnID ? this.state.sortColumnID : 'UserID'
      },
      filterText: this.state.filterText,
      advanceSearchFilters: this.state.advanceSearchFilters,
      paginationPoint: this.state.paginationPoint,
      paginationAmount: this.state.paginationAmount,
      unsortable: ['actions'],
      rowClickable: true,
      redirectTo: e => {
        this.props.history.push({ pathname: '/users/' + e.UserID, ...(this.state.isMSBAdmin && { state: { BrandID: e.BrandID } }) })
      },
      fieldStringFunctions: {
        userAccess: e => {
          return e.userAccess
        },
        UserID: e => {
          return e.UserID
        }
      },
      fieldExportFunctions: {
        UserID: e => {
          return e.UserID
        }
      },
      fieldFunctions: {
        UserID: e => {
          return (
            <Fragment>
              {Reflux.GlobalState.LoginStore.settings &&
                Reflux.GlobalState.LoginStore.settings.userRole == 'System' &&
                Reflux.GlobalState.LoginStore.settings.userPrivilege == 'MANAGE' && (
                  <Input
                    type='checkbox'
                    id={'checkbox' + e.UserID}
                    name={'checkbox' + e.UserID}
                    checked={e.checked}
                    disabled={e.userAccess === 'TO_BE_OBFUSCATED'}
                    onChange={event => {
                      UserActions.selectUserHandler(event, e.UserID)
                    }}
                  />
                )}
              {e.UserID}
            </Fragment>
          )
        },
        actions: e => {
          return (
            <ButtonGroup>
              <Tooltip target={'editUser' + e.UserID}>edit user</Tooltip>
              <Button
                tag={Link}
                to={{ pathname: '/users/' + e.UserID, ...(this.state.isMSBAdmin && { state: { BrandID: e.BrandID } }) }}
                id={'editUser' + e.UserID}
              >
                <FontAwesomeIcon icon='user-cog' />
              </Button>
              {!this.isUserView && (
                <>
                  <Tooltip target={'newWalletUser' + e.UserID}>add to wallet</Tooltip>
                  <Button
                    disabled={e.removed ? true : false}
                    tag={Link}
                    to={{ pathname: '/users/' + e.UserID + '/wallet/new', ...(this.state.isMSBAdmin && { state: { BrandID: e.BrandID } }) }}
                    id={'newWalletUser' + e.UserID}
                  >
                    <FontAwesomeIcon icon='money-bill' />
                  </Button>
                </>
              )}
              <Tooltip target={'walletUser' + e.UserID}>show wallet</Tooltip>
              <Button
                tag={Link}
                params={{ disableWallet: e.removed ? true : false }}
                to={{ pathname: '/users/' + e.UserID + '/wallet', state: { disableWallet: e.removed ? true : false } }}
                id={'walletUser' + e.UserID}
              >
                <FontAwesomeIcon icon='wallet' />
              </Button>
              {!this.isUserView && (
                <>
                  <Tooltip target={'shadowUser' + e.UserID}>shadow user</Tooltip>
                  <Button disabled={e.removed ? true : false} onClick={() => UserActions.switchUser(e)} id={'shadowUser' + e.UserID}>
                    <FontAwesomeIcon icon='user-ninja' />
                  </Button>
                </>
              )}
              {!this.state.isMSBAdmin &&
                !this.isUserView &&
                this.state.settings.userRole.toLowerCase() === 'system' &&
                this.state.settings.userPrivilege.toLowerCase() === 'manage' && (
                  <React.Fragment>
                    <Tooltip target={'trash' + e.UserID}>Slet</Tooltip>
                    <Button
                      id={'trash' + e.UserID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne bruger?')) {
                          UserActions.deleteUser(e.UserID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </React.Fragment>
                )}
              {this.state.isMSBAdmin && (
                <React.Fragment>
                  {e.removed === 0 && (
                    <React.Fragment>
                      <Tooltip target={'deactivate' + e.UserID}>Deaktivér</Tooltip>
                      <Button
                        id={'deactivate' + e.UserID}
                        onClick={() => {
                          if (confirm('Vil du deaktivere denne brugere?')) {
                            UserActions.deleteUser(e.UserID)
                          }
                        }}
                      >
                        {e.UserID === this.state.deleteUserID && this.state.status === 'removingUser' ? (
                          <FontAwesomeIcon icon='spinner' />
                        ) : (
                          <FontAwesomeIcon icon='user-check' />
                        )}
                      </Button>
                    </React.Fragment>
                  )}
                  {e.removed === 1 && (
                    <React.Fragment>
                      <Tooltip color='danger' target={'active' + e.UserID}>
                        Aktivér
                      </Tooltip>
                      <Button
                        color='danger'
                        id={'active' + e.UserID}
                        onClick={() => {
                          if (confirm('Vil du aktivere denne brugere?')) {
                            UserActions.enableUser(e.UserID, 0)
                          }
                        }}
                      >
                        {e.UserID === this.state.deleteUserID && this.state.status === 'removingUser' ? (
                          <FontAwesomeIcon icon='spinner' />
                        ) : (
                          <FontAwesomeIcon icon='user-slash' />
                        )}
                      </Button>
                    </React.Fragment>
                  )}
                  <Tooltip target={'copy' + e.UserID}>Kopier</Tooltip>
                  <Button
                    id={'copy' + e.UserID}
                    onClick={() => {
                      UserActions.copyAdminUser(e, this.props)
                    }}
                  >
                    <FontAwesomeIcon icon='copy' />
                  </Button>
                </React.Fragment>
              )}
            </ButtonGroup>
          )
        }
      }
    }
    return (
      <React.Fragment>
        {this.state.openModal && (
          <Modal isOpen={this.state.openModal} toggle={() => UserActions.logoutModal(this.state.openModal)} size='md'>
            <ModalHeader toggle={() => UserActions.logoutModal(this.state.openModal)}>Change Status</ModalHeader>
            <ModalBody>
              <strong>Do you want to change the status?</strong>
            </ModalBody>
            <ModalFooter>
              <Button onClick={e => UserActions.updateStatusHandler(e, this.state.users)}>yes</Button>
              <Button color='danger' onClick={() => UserActions.logoutModal(this.state.openModal)}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <CollapsibleCard
          header={
            <strong>
              Brugere{' '}
              {this.state.settings &&
                this.state.settings.userRole.toLowerCase() === 'system' &&
                this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
                this.state.settings.BrandID === 9 &&
                this.state.users &&
                !!this.state.users.length && (
                  <ButtonGroup className='float-right'>
                    <Button outline color='success' onClick={UserActions.toggleActiveUsers} active={this.state.showActiveUsers}>
                      Active
                    </Button>
                    <Button outline color='danger' onClick={UserActions.toggleInActiveUsers} active={this.state.showInActiveUsers}>
                      In-Active
                    </Button>
                  </ButtonGroup>
                )}
            </strong>
          }
          startOpen
        >
          {this.state.fetchingUsers && <FontAwesomeIcon icon='sync' spin />}
          {!this.state.fetchingUsers && (
            <DynamicTable
              data={
                this.state.users &&
                !!this.state.users.length &&
                this.state.settings &&
                this.state.settings.userRole.toLowerCase() === 'system' &&
                this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
                this.state.settings.BrandID === 9 &&
                this.state.userFilter !== 'both'
                  ? this.state.users.filter(user => user.removed === (this.state.userFilter === 'inactive' ? 1 : 0))
                  : this.state.users
              }
              headers={tableHeaders}
              options={tableoptions}
            />
          )}
          {!this.state.fetchingUsers && this.state.users.length == 0 && <Button onClick={UserActions.getUsers}>Prøv igen</Button>}
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
const OrderDetailView = props => {
  let supportClub = ''
  let brandSlug = ''
  if (props.selectedOrder.origin && props.selectedOrder.origin.brandSlug) {
    brandSlug = props.selectedOrder.origin.brandSlug
    supportClub = '+' + props.selectedOrder.origin.brandSlug.toLowerCase()
    if (props.selectedOrder.UserID) {
      supportClub = supportClub + '-' + props.selectedOrder.UserID
    }
  }
  let orderPricePoints = props.selectedOrder.orderlines.reduce((sum, next) => sum + next.orderAmountPoints, 0)
  let orderlines = props.selectedOrder.orderlines.map((x, i) => (
    <React.Fragment key={i}>
      <Row>
        <Col md='1'>
          <p>{x.ProductID}</p>
        </Col>
        <Col md='7'>
          <p>{x.orderlineTitle}</p>
        </Col>
        <Col md='2'>
          <p>{x.orderAmountPoints}</p>
        </Col>
        <Col md='2'>
          <p>{brandSlug}</p>
        </Col>
      </Row>
    </React.Fragment>
  ))

  return (
    <React.Fragment>
      <Modal isOpen toggle={props.onHandleViewDetailClick} size='lg' className='modal-large'>
        <ModalHeader toggle={props.onHandleViewDetailClick}>Ordredetaljer</ModalHeader>
        <ModalBody>
          <Row className='pl-5 pr-5'>
            <Col>
              <h5 className='mb-2'>Faktura</h5>
              <p className='mb-0'>Fakturanummer: {props.selectedOrder.OrderID}</p>
              <p className='mb-0'>Faktura dato: {moment(props.selectedOrder.timeInsert).format('DD/MM/YYYY HH:mm')}</p>
              <p className='mb-0'>Kundenummer: {props.selectedOrder.UserID}</p>
            </Col>
          </Row>
          <Row className='pl-5 pr-5'>
            <Col>
              <div className='pt-4 pb-4 pl-4 pr-4'>
                <Row>
                  <Col md='1'>
                    <p>Vare Nr.</p>
                  </Col>
                  <Col md='7'>
                    <p>Produkt</p>
                  </Col>
                  <Col md='2'>
                    <p>Point pr. styk</p>
                  </Col>
                  <Col md='2'>
                    <p>Afsender</p>
                  </Col>
                </Row>
                {orderlines}
              </div>
            </Col>
          </Row>
          <Row className='text-right'>
            <Col md='8'></Col>
            <Col md='4'>
              <div className='pt-4 pb-4 pl-4 pr-4'>
                <Row>
                  <Col>
                    <p className='mb-0'>Subtotal</p>
                  </Col>
                  <Col>
                    <p className='mb-0'>{orderPricePoints}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className='mb-0'>Fragt</p>
                  </Col>
                  <Col>
                    <p className='mb-0'>{props.selectedOrder.orderDropShipment}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className='mb-0'>At betale</p>
                  </Col>
                  <Col>
                    <p className='mb-0'>{orderPricePoints}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className='text-right pr-4'>
            <Col>
              <h5>Leveringsinformation</h5>
              <p className='mb-0'>{props.selectedOrder.orderConsignee}</p>
              <p className='mb-0'>{props.selectedOrder.orderConsigneeName}</p>
              <p className='mb-0'>{props.selectedOrder.orderConsigneeAddress1}</p>
              <p className='mb-4'>
                {props.selectedOrder.orderConsigneeZipcode} {props.selectedOrder.orderConsigneeCity}
              </p>
            </Col>
          </Row>
          <Row className='pl-5 pr-5'>
            <Col>
              <p>
                Hvis du har spørgsmål til din ordre er du velkommen til at kontakte os{' '}
                <a href={'mailto:support' + supportClub + '@upgrader.club?subject=Ordre%20' + props.selectedOrder.OrderID}>
                  support{supportClub}@upgrader.club
                </a>
              </p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
