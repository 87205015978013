export default function getBrandSlug() {
  if (window.location.hostname === 'localhost') {
    return 'upgradertest'
  }
  let urlBase = window.location.hostname.split('.')[0].toLowerCase()
  if (urlBase.indexOf('-') !== -1) {
    let theseOptions = urlBase.split('-')
    urlBase = theseOptions.shift()
  }
  return urlBase
}
