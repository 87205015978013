import Proxies from 'Components/proxies'
import {
  fetchSuppliersRequest,
  fetchSuppliersFailed,
  fetchSuppliersSuccess,
  fetchSupplierDetailsRequest,
  fetchSupplierDetailsFailed,
  fetchSupplierDetailsSuccess,
  supplierCreationSuccess,
  supplierCreationFailure,
  isSavingSupplier,
  removeSupplierFailure,
  removeSupplierSuccess
} from 'Reducers/supplierReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  FETCHING_SUPPLIERS: null,
  FETCHING_SUPPLIERS_SUCCESS: null,
  FETCHING_SUPPLIERS_FAILED: null,
  FETCHING_SUPPLIER_DETAILS: null,
  FETCHING_SUPPLIER_DETAILS_FAILED: null,
  FETCHING_SUPPLIER_DETAILS_SUCCESS: null,
  SUPPLIER_SUCCESS: null,
  SUPPLIER_FAILED: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  DELETE_SUPPLIER_FAILED: null,
  DELETE_SUPPLIER_SUCCESS: null
})

export const getSuppliers = data => dispatch => {
  dispatch(fetchSuppliersRequest())
  Proxies.GET('suppliers/pool', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchSuppliersFailed())
    } else {
      dispatch(fetchSuppliersSuccess(responseJSON))
    }
  })
}

export const getSupplierDetails = SupplierID => dispatch => {
  dispatch(fetchSupplierDetailsRequest())
  Proxies.GET('suppliers/fetch', { SupplierID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchSupplierDetailsFailed())
    } else {
      dispatch(fetchSupplierDetailsSuccess(responseJSON))
    }
  })
}

export const saveSupplier = data => dispatch => {
  dispatch(isSavingSupplier())
  Proxies.POST('suppliers/replace', { ...data }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(supplierCreationFailure())
    } else {
      dispatch(supplierCreationSuccess(responseJSON))
    }
  })
}

export const deleteSupplier = data => dispatch => {
  dispatch(isSavingSupplier())
  Proxies.POST('suppliers/removeSupplier', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(removeSupplierFailure())
    } else {
      dispatch(removeSupplierSuccess())
    }
  })
}
