/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Form, Row, Col, FormGroup, Label, Input, Fade } from 'reactstrap'
import FormField from 'Components/formField'
import { PostStore, PostActions } from 'Stores/postStore'
import { LoginStore } from 'Stores/loginStore'
import styled from 'styled-components'
import CroppedImageUpload from './imageCropPreview'
import VideoUpload from '../util/VideoUpload'

const StyledAnchor = styled.a`
  cursor: pointer !important;
`

const StyledAttachmentDiv = styled.div`
  max-width: 15vw;
  margin-top: 5px;
`

const Post = props => {
  if (props.create) {
    return <PostDetails {...props} />
  }
  if (props.match && props.match.params && props.match.params.PostID) {
    return <PostDetails {...props} />
  }
  return <PostList {...props} />
}

export default Post

class PostList extends Component {
  constructor(props) {
    super(props)
    this.stores = [PostStore, LoginStore]
    this.poolHeader = {
      PostID: '#',
      elementTitle: 'Overskrift',
      elementContent: 'Indhold',
      poolOrder: 'Pool Order',
      timeStart: 'Post vises fra',
      timeEnd: 'Post vises til',
      attachments: 'Antal vedhæftninger'
    }
  }
  componentDidMount() {
    if (this.state.posts.length == 0 || (this.props.match && this.props.match.params && this.props.match.params.BlogID != this.state.currBlogID)) {
      PostActions.getPosts(this.props.match.params.BlogID)
    }
    if (this.state.settings && this.state.settings.features && this.state.settings.features.postReadFeature) {
      this.poolHeader.postType = 'Type'
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.BlogID != prevProps.match.params.BlogID) {
      PostActions.getPosts(this.props.match.params.BlogID)
    }
  }
  render() {
    if (this.state.fetchingPosts) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let BlogID = this.props.match.params.BlogID
    return (
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Posts</strong>{' '}
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={PostActions.toggleActivePost} active={this.state.showActivePosts}>
                Active
              </Button>
              <Button outline color='danger' onClick={PostActions.toggleInActivePost} active={this.state.showInActivePosts}>
                In-Active
              </Button>
            </ButtonGroup>
            <Button tag={Link} to={'/blog/' + BlogID + '/posts/new'} className='float-right' style={{ marginRight: '5px' }}>
              Opret post
            </Button>
          </React.Fragment>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={
            this.state.posts && !!this.state.posts.length && this.state.postFilterValue !== 'both'
              ? this.state.posts.filter(post => post.removed === (this.state.postFilterValue === 'inactive' ? 1 : 0))
              : this.state.posts
          }
          headers={this.poolHeader ? this.poolHeader : ''}
          options={{
            ...(this.state.settings &&
              this.state.settings.userRole.toLowerCase() === 'system' &&
              this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
              this.state.settings.BrandID === 9 && {
                advanceSearchColumns: [
                  { key: 'PostID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
                  { key: 'elementTitle', name: 'Overskrift', operators: ['LIKE', 'EXACT'] },
                  { key: 'elementContent', name: 'Indhold', operators: ['LIKE', 'EXACT'] },
                  { key: 'poolOrder', name: 'Pool Order', operators: ['LIKE', 'EXACT'] },
                  { key: 'timeStart', name: 'Post vises fra', operators: ['LIKE', 'DATE'] },
                  { key: 'timeEnd', name: 'Post vises ti', operators: ['LIKE', 'DATE'] }
                ],
                advanceSearchFilters: this.state.advanceSearchFilters,
                preserveAdvanceSearch: PostActions.preserveAdvanceSearch
              }),
            startSort: {
              column: 'PostID',
              dir: 'DESC'
            },
            fieldFunctions: {
              attachments: e => {
                let attachments = e.attachments ? e.attachments : []
                let links = attachments.filter(e => {
                  return e.LinkID
                }).length
                let images = attachments.filter(e => {
                  return e.ImageID
                }).length
                let total = attachments.length
                return (
                  <React.Fragment>
                    {total}
                    {total > 0 && (
                      <React.Fragment>
                        <br />
                        Heraf : <br />
                        {links > 0 && (
                          <React.Fragment>
                            {links} Link{links > 1 && 's'}
                          </React.Fragment>
                        )}
                        {links > 0 && images > 0 && <React.Fragment>og</React.Fragment>}
                        {images > 0 && (
                          <React.Fragment>
                            {images} Billede{images > 1 && 'r'}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
              },
              timeStart: e => {
                if (e.timeStart) {
                  return moment(e.timeStart).format('DD-MM-YYYY ')
                } else {
                  return ''
                }
              },
              timeEnd: e => {
                if (e.timeEnd) {
                  return moment(e.timeEnd).format('DD-MM-YYYY')
                } else {
                  return ''
                }
              },
              elementContent: e => {
                let acc = []
                if (!e.descriptionReadMore && !e.descriptionReadLess) {
                  return e.elementContent
                }
                if (e.descriptionReadMore && !e.descriptionReadLess) {
                  acc.push(e.elementContent.slice(0, 250) + '...')
                  acc.push(
                    <StyledAnchor key={e.PostID} onClick={() => PostActions.toggleQueryContent(e)}>
                      Show More
                    </StyledAnchor>
                  )
                  return acc
                }
                if (!e.descriptionReadMore && e.descriptionReadLess) {
                  acc.push(e.elementContent)
                  acc.push(<br key={e.PostID} />)
                  acc.push(
                    <StyledAnchor key={e.PostID} onClick={() => PostActions.toggleQueryContent(e)}>
                      Show Less
                    </StyledAnchor>
                  )
                  return acc
                }
              }
            },
            fieldExportFunctions: {
              elementContent: e => {
                return e.elementContent
              },
              timeStart: e => {
                if (e.timeStart) {
                  return moment(e.timeStart).format('DD-MM-YYYY ')
                } else {
                  return ''
                }
              },
              timeEnd: e => {
                if (e.timeEnd) {
                  return moment(e.timeEnd).format('DD-MM-YYYY')
                } else {
                  return ''
                }
              },
              attachments: e => {
                let attachments = e.attachments ? e.attachments : []
                let links = attachments.filter(e => {
                  return e.LinkID
                }).length
                let images = attachments.filter(e => {
                  return e.ImageID
                }).length
                let total = attachments.length
                let acc = []
                acc.push(`${total}`)
                if (total > 0) {
                  acc.push('Heraf :')
                  if (`${links}` > 0) {
                    acc.push(`${links} Link ${links > 1 ? 's' : ''}`)
                  }
                  if (`${images}` > 0) {
                    acc.push(`${images} Billede ${images > 1 ? 's' : ''}`)
                  }
                }
                return acc.join('\n')
              }
            },
            fieldStringFunctions: {
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.PostID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/blog/' + BlogID + '/posts/' + e.PostID} id={'edit' + e.PostID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'copy' + e.PostID}>Kopier</Tooltip>
                    <Button
                      id={'copy' + e.PostID}
                      onClick={() => {
                        PostActions.copyPost(e, this.props)
                      }}
                    >
                      <FontAwesomeIcon icon='copy' />
                    </Button>
                    {e.removed === 0 && (
                      <React.Fragment>
                        <Tooltip target={'delete' + e.PostID}>Deaktivér</Tooltip>
                        <Button
                          id={'delete' + e.PostID}
                          onClick={() => {
                            if (confirm('Vil du deaktivere denne post?')) {
                              PostActions.deletePost(e.PostID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-check' />
                        </Button>
                      </React.Fragment>
                    )}
                    {e.removed === 1 && (
                      <React.Fragment>
                        <Tooltip target={'enable' + e.PostID}>Aktivér</Tooltip>
                        <Button
                          color='danger'
                          id={'enable' + e.PostID}
                          onClick={() => {
                            if (confirm('Vil du aktivere denne post?')) {
                              PostActions.enablePost(e.PostID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-slash' />
                        </Button>
                      </React.Fragment>
                    )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}

class PostDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [PostStore, LoginStore]
    this.resetPost = this.resetPost.bind(this)
    this.postImageRef = React.createRef()
  }

  componentWillMount() {
    super.componentWillMount()
    this.resetPost()
    PostActions.getBlogs()
  }
  resetPost() {
    if (this.props.create) {
      let queryString = window.location.search
      let params = new URLSearchParams(queryString)
      let cloneFromPostID = params.get('copy')
      if (cloneFromPostID) {
        PostActions.getPostDetails(cloneFromPostID, true)
      } else {
        PostActions.createEmptyPost()
        if (this.props.match && this.props.match.params && this.props.match.params.BlogID) {
          PostActions.setDefaultBlogID(this.props.match.params.BlogID)
        }
      }
    } else {
      PostActions.getPostDetails(this.props.match.params.PostID)
    }
  }
  componentDidUpdate() {
    if (this.state.showPostError) {
      if (this.props.create) {
        toast.error('Der opstod en fejl i oprettelsen af posten', {
          autoClose: 7500,
          onClose: () => {
            PostActions.clearToast()
          }
        })
      } else {
        toast.error('Der opstod en fejl ved opdateringen af posten', {
          autoClose: 7500,
          onClose: () => {
            PostActions.clearToast()
          }
        })
      }
      PostActions.clearToast()
    }
    if (this.state.showPostSuccess) {
      if (this.props.create) {
        toast.success('Posten er oprettet', {
          autoClose: 7500,
          onClose: () => {
            PostActions.clearToast()
          }
        })
      } else {
        toast.success('Dine ændringer er gemt', {
          autoClose: 7500,
          onClose: () => {
            PostActions.clearToast()
          }
        })
      }
      PostActions.clearToast()
    }
  }
  isDisabledField() {
    let queryString = window.location.search
    let params = new URLSearchParams(queryString)
    let cloneFromPostID = params.get('copy') // check if this is a copy of a post
    if (this.state.postDetails.notification && this.state.postDetails.notification.isProcessed && !cloneFromPostID) {
      return true
    }
    if (
      this.state.postDetails.notification &&
      this.state.postDetails.notification.type &&
      this.state.postDetails.notification.type.toLowerCase() === 'skip'
    ) {
      return true
    }
    return false // enable field
  }
  render() {
    if (this.state.fetchingPosts) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    if (this.state.postDetails.PostID && this.props.create) {
      return <Redirect to={'/blog/' + this.props.match.params.BlogID + '/posts/' + this.state.postDetails.PostID} />
    }
    let groups = []
    const groupList =
      this.state.filteredGroupData && this.state.postDetails.groupFilterText && this.state.postDetails.groupFilterText.length
        ? this.state.filteredGroupData
        : this.state.groups
    let blogGroups = this.state.postDetails.groups ? this.state.postDetails.groups.concat([]) : []
    groupList.forEach((e, i) => {
      let isCheckedData = blogGroups.find(s => {
        return s.GroupID == e.GroupID
      })
      let isChecked = isCheckedData !== undefined
      if (isChecked) {
        blogGroups = blogGroups.filter(s => {
          return s.GroupID != e.GroupID
        })
      }
      groups.push(
        <React.Fragment key={i}>
          <Input type='checkbox' checked={isChecked} name={e.GroupID} onChange={PostActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
          <br />
        </React.Fragment>
      )
    })
    if (blogGroups.length > 0) {
      groups.push(
        <React.Fragment key={'overflow'}>
          Følgende grupper skal gendannes via databasen hvis de slettes
          <br />
        </React.Fragment>
      )
      blogGroups.forEach((e, i) => {
        groups.push(
          <React.Fragment key={'overflow' + i}>
            <Input type='checkbox' checked={true} name={e.GroupID} onChange={PostActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    let attachments = this.state.postDetails.attachments
      ? this.state.postDetails.attachments.filter(e => {
          return e.ImageID
        })
      : []
    return (
      <CollapsibleCard startOpen header={<strong>{this.props.create ? 'Opret' : 'Detaljer'}</strong>}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField name='Overskrift' field='elementTitle' data={this.state.postDetails} onChange={PostActions.updatePostField} required />
          <FormField name='Indhold' field='elementContent' type='textarea' data={this.state.postDetails} onChange={PostActions.updatePostField} />
          {/* tax calculation field */}
          {!!this.state.currBlog && this.state.currBlog.blogType.toLowerCase() === 'tax campaign' && (
            <FormField
              className='zero-margin-left'
              name='Tax calculation'
              field='taxCalculation'
              data={this.state.postDetails}
              onChange={PostActions.updatePostField}
              type='checkbox'
              checkedValue={Number(1)}
              unCheckedValue={Number(0)}
            />
          )}
          {/* additionalInput fields */}
          {!!this.state.postDetails && !!this.state.postDetails.taxCalculation && (
            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='BlogID'> Additional input </Label>
                </Col>
                <Col xs='9' style={{ gap: 10 }}>
                  <Input
                    defaultValue={this?.state?.postDetails?.additionalInput?.helpText1 || ''}
                    placeholder='Enter help text which appears below the red box'
                    name='additionalInput-helpText1'
                    onChange={e => PostActions.updatePostField(e)}
                    type='textarea'
                  />
                  <br />
                  <Input
                    defaultValue={this?.state?.postDetails?.additionalInput?.captionText || ''}
                    placeholder='Enter caption text which appears above the lines given below'
                    name='additionalInput-captionText'
                    onChange={e => PostActions.updatePostField(e)}
                    type='text'
                  />
                  <br />
                  {!!this.state.postDetails.additionalInput &&
                    this?.state?.postDetails?.additionalInput?.data?.map((input, index) => (
                      <Row key={index}>
                        <Col xs='2'>
                          <Input
                            // className={this.state.currBlogID ? 'is-valid' : 'is-invalid'}
                            // required
                            type='select'
                            name='additionalInput-valueType'
                            defaultValue={this.state.postDetails.additionalInput?.data?.[index].valueType || ''}
                            id='additionalInput-valueType'
                            onChange={e => {
                              PostActions.updatePostField(e, index)
                            }}
                          >
                            <option value=''>Click to Select</option>
                            <option value='pt'>Point</option>
                            <option value='kr'>DKK</option>
                          </Input>
                        </Col>
                        <Col xs='2'>
                          <Input
                            defaultValue={this.state.postDetails.additionalInput?.data?.[index].inputValue || ''}
                            placeholder='Enter value'
                            name='additionalInput-inputValue'
                            onChange={e => PostActions.updatePostField(e, index)}
                            type='number'
                          />
                        </Col>
                        <Col xs='8'>
                          <Input
                            defaultValue={this.state.postDetails.additionalInput?.data?.[index].inputText || ''}
                            placeholder='Enter text'
                            name='additionalInput-inputText'
                            onChange={e => PostActions.updatePostField(e, index)}
                          />
                        </Col>
                      </Row>
                    ))}
                  {!this?.state?.postDetails?.additionalInput?.data && (
                    <Row>
                      <Col xs='2'>
                        <Input
                          // className={this.state.currBlogID ? 'is-valid' : 'is-invalid'}
                          // required
                          type='select'
                          name='additionalInput-valueType'
                          defaultValue={''}
                          id='additionalInput-valueType'
                          onChange={e => {
                            PostActions.updatePostField(e, 0)
                          }}
                        >
                          <option value=''>Click to Select</option>
                          <option value='pt'>Point</option>
                          <option value='kr'>DKK</option>
                        </Input>
                      </Col>
                      <Col xs='2'>
                        <Input
                          defaultValue={''}
                          placeholder='Enter value'
                          name='additionalInput-inputValue'
                          onChange={e => PostActions.updatePostField(e, 0)}
                          type='number'
                        />
                      </Col>
                      <Col xs='8'>
                        <Input
                          defaultValue={''}
                          placeholder='Enter text'
                          name='additionalInput-inputText'
                          onChange={e => PostActions.updatePostField(e, 0)}
                        />
                      </Col>
                    </Row>
                  )}
                  <Button onClick={() => PostActions.addAdditionalInput()}>
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          )}
          {// Display Blog selection only for MSB admin
          this.state.settings &&
            this.state.settings.userRole.toLowerCase() === 'system' &&
            this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
            this.state.settings.BrandID === 9 && (
              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='BlogID'> Blog </Label>
                  </Col>
                  <Col xs='6'>
                    <Input
                      className={this.state.currBlogID ? 'is-valid' : 'is-invalid'}
                      required
                      type='select'
                      name='BlogID'
                      value={this.state.currBlogID ? Number(this.state.currBlogID) : ''}
                      id='BlogID'
                      onChange={e => {
                        PostActions.updatePostField(e)
                      }}
                    >
                      <option value=''>Click to Select</option>
                      {this.state.blogs.map((e, i) => {
                        return (
                          <option value={Number(e.BlogID)} key={i}>
                            {e.BrandID}-[{e.BrandName}] : [{e.moduleName}]-{e.BlogID}
                          </option>
                        )
                      })}
                    </Input>
                    <Fade tag='small'>
                      BrandID-[Brand Name] : [Blog Name]-BlogID <br />
                    </Fade>
                  </Col>
                </Row>
              </FormGroup>
            )}
          {!!this.state.postDetails.BrandID && !!this.state.languages && !!(this.state.languages.length > 1) && (
            <FormGroup row>
              <Label for='textColor' sm={3}>
                Translations
              </Label>
              <Col sm={9}>
                {!!this.state.postDetails &&
                  !!this.state.postDetails.translations &&
                  !!this.state.postDetails.translations.length &&
                  this.state.postDetails.translations.map((translation, inx) => {
                    return (
                      !translation.removed && (
                        <div key={inx}>
                          <Row>
                            <Col sm={11}>
                              <Input
                                type='select'
                                name='LanguageID'
                                value={translation.LanguageID ? Number(translation.LanguageID) : ''}
                                id='LanguageID'
                                onChange={e => PostActions.updateData(e, inx, 'translations')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {!!this.state.languages &&
                                  !!this.state.languages.length &&
                                  this.state.languages.map((e, i) => {
                                    return (
                                      <option value={Number(e.LanguageID)} key={i}>
                                        {e.languageName}
                                      </option>
                                    )
                                  })}
                              </Input>
                            </Col>
                            <Col sm={1}>
                              <Button onClick={() => PostActions.removeTranslation(inx)} color={'danger'}>
                                <FontAwesomeIcon icon={'minus'} />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Label for='textColor' sm={2}>
                              Overskrift
                            </Label>
                            <Col sm={9}>
                              <Input
                                name='elementTitle'
                                value={translation.elementTitle}
                                type='text'
                                onChange={e => PostActions.updateData(e, inx, 'translations')}
                              />
                            </Col>
                            <Col sm={1}>
                              <Button onClick={PostActions.addTranslation}>
                                <FontAwesomeIcon icon='plus' />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Label for='textColor' sm={2}>
                              Indhold
                            </Label>
                            <Col sm={10}>
                              <Input
                                name='elementContent'
                                value={translation.elementContent}
                                type='textarea'
                                onChange={e => PostActions.updateData(e, inx, 'translations')}
                              />
                            </Col>
                          </Row>
                          <br></br>
                        </div>
                      )
                    )
                  })}
              </Col>
            </FormGroup>
          )}
          {// Check for customisation-feature : notification; Show these fields only for create-post flow
          this.state.settings && this.state.settings.features && this.state.settings.features.postReadFeature && (
            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='notificationTitle'>
                    {/* Mobile Notification */}
                    Push notifikation
                  </Label>
                </Col>
                <Col xs='9' className='padding-left-50'>
                  <Row>
                    <Fade in={this.state.notificationDisabled} className='notificationProcessed'>
                      {/* Notification is already processed or skipped */}
                      Notifikation er allerede sendt eller valgt fra.
                    </Fade>
                    <label>
                      <Input
                        type='radio'
                        name='notificationType'
                        value='immediate'
                        checked={
                          !this.state.postDetails.notification ||
                          !this.state.postDetails.notification.type ||
                          (this.state.postDetails.notification && this.state.postDetails.notification.type.toLowerCase() === 'immediate')
                        }
                        onChange={e => PostActions.setNotification(e)}
                        disabled={this.state.notificationDisabled}
                      />
                      {/* Immediately send out push notification to Mobile devices (default). */}
                      Omgående udsendelse af push notifikation (standard)
                    </label>
                  </Row>
                  <Row>
                    <label>
                      <Input
                        type='radio'
                        name='notificationType'
                        value='scheduled'
                        checked={
                          this.state.postDetails.notification &&
                          this.state.postDetails.notification.type &&
                          this.state.postDetails.notification.type.toLowerCase() === 'scheduled'
                        }
                        onChange={e => PostActions.setNotification(e)}
                        disabled={this.state.notificationDisabled}
                      />
                      {/* Schedule notification for later. */}
                      Planlæg notifikation
                    </label>
                  </Row>
                  <Row>
                    <label>
                      <Input
                        type='radio'
                        name='notificationType'
                        value='skip'
                        checked={
                          this.state.postDetails.notification &&
                          this.state.postDetails.notification.type &&
                          this.state.postDetails.notification.type.toLowerCase() === 'skip'
                        }
                        onChange={e => PostActions.setNotification(e)}
                        disabled={this.state.notificationDisabled}
                      />
                      {/* Do not send notification. */}
                      Send ikke en notifikation
                    </label>
                  </Row>
                </Col>
                <Col xs='3'></Col>
                <Col xs='5'>
                  <Input
                    type='text'
                    className='margin-bottom-10'
                    placeholder='Notifikation titel'
                    name='notificationTitle'
                    value={
                      this.state.postDetails && this.state.postDetails.notification && this.state.postDetails.notification.title
                        ? this.state.postDetails.notification.title
                        : ''
                    }
                    id='notificationTitle'
                    onChange={e => PostActions.setNotification(e)}
                    disabled={this.isDisabledField('notificationTitle')}
                  />
                  {this.state.postDetails.notification &&
                    this.state.postDetails.notification.type &&
                    this.state.postDetails.notification.type.toLowerCase() === 'scheduled' && (
                      <span>
                        <Col xs='12'>
                          <label htmlFor='notificationDate'>
                            {/* Scheduled Date */}
                            Planlagt dato
                            <Input
                              type='date'
                              className='margin-bottom-10'
                              placeholder='Scheduled Date'
                              name='notificationDate'
                              value={this.state.postDetails.notification.scheduledDate.slice(0, 10)}
                              id='notificationDate'
                              disabled={this.isDisabledField('notificationDate')}
                              onChange={e => PostActions.setNotification(e)}
                            />
                          </label>
                        </Col>
                        <Col xs='12'>
                          <label htmlFor='notificationTime'>
                            {/* Scheduled Time */}
                            Planlagt tid
                            <Input
                              type='select'
                              className='margin-bottom-10'
                              placeholder='Scheduled Time'
                              name='notificationTime'
                              value={this.state.postDetails.notification.scheduledTime}
                              id='notificationTime'
                              onChange={e => PostActions.setNotification(e)}
                              disabled={this.isDisabledField('notificationTime')}
                            >
                              <option value={false}>Click to Select</option>
                              {[...Array(24).keys()].map(i => {
                                return (
                                  <option value={i} key={i}>
                                    {i + ':00 Hrs'}
                                  </option>
                                )
                              })}
                            </Input>
                          </label>
                        </Col>
                      </span>
                    )}
                </Col>
                <Col xs='4'>
                  <Input
                    type='textarea'
                    rows='8'
                    placeholder='Notifikation tekst'
                    name='notificationText'
                    value={
                      this.state.postDetails && this.state.postDetails.notification && this.state.postDetails.notification.text
                        ? this.state.postDetails.notification.text
                        : ''
                    }
                    id='notificationText'
                    onChange={e => PostActions.setNotification(e)}
                    disabled={this.isDisabledField('notificationText')}
                  />
                </Col>
              </Row>
            </FormGroup>
          )}
          {// check if the Post-Read feature is enabled for the user / brand / client / company
          this.state.settings && this.state.settings.features && this.state.settings.features.postReadFeature && (
            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='postType'> Type </Label>
                </Col>
                <Col xs='3'>
                  <Input
                    type='select'
                    name='postType'
                    value={this.state.postDetails.postType ? this.state.postDetails.postType : 'DEFAULT'}
                    id='postType'
                    onChange={e => {
                      PostActions.updatePostField(e)
                    }}
                  >
                    <option value=''>Click to Select</option>
                    {this.state.postTypes.map((e, i) => {
                      return (
                        <option value={e} key={i}>
                          {e}
                        </option>
                      )
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
          )}
          <FormField
            type='number'
            data={this.state.postDetails}
            field={'poolOrder'}
            name='Pool order'
            value={this.state.postDetails.poolOrder ? Number(this.state.postDetails.poolOrder) : 0}
            fieldWidth='3'
            onChange={PostActions.updatePostField}
          />
          <FormField name='Post vises fra' field='timeStart' type='date' data={this.state.postDetails} onChange={PostActions.updatePostField} />
          <FormField name='Post vises til' field='timeEnd' type='date' data={this.state.postDetails} onChange={PostActions.updatePostField} />
          {!!this.state.currBlog && this.state.currBlog.blogType.toLowerCase() === 'product' ? (
            <Row className='mb-3'>
              <Col xs='3'>
                <Label> Home Banner Image </Label>
              </Col>
              <Col xs='4'>
                {!!this.state.postDetails && !!this.state.postDetails.homeBannerImage ? (
                  <img src={this.state.postDetails.homeBannerImage} style={{ height: 'auto', width: '100%', objectFit: 'contain' }} />
                ) : (
                  ' - '
                )}
              </Col>
            </Row>
          ) : (
            <Row className='mb-3'>
              <Col xs='3'>
                <Label> Card Image </Label>
              </Col>
              <Col xs='4'>
                {!!this.state.postDetails && !!this.state.postDetails.cardImage ? (
                  <img src={this.state.postDetails.cardImage} style={{ height: 'auto', width: '100%', objectFit: 'contain' }} />
                ) : (
                  ' - '
                )}
              </Col>
            </Row>
          )}

          <Row className='mb-3'>
            <Col xs='3'>
              <Label> Post Banner Image </Label>
            </Col>
            <Col xs='4'>
              {!!this.state.postDetails && !!this.state.postDetails.bannerImage ? (
                <img src={this.state.postDetails.bannerImage} style={{ height: 'auto', width: '100%', objectFit: 'contain' }} />
              ) : (
                ' - '
              )}
            </Col>
          </Row>
          {!!attachments.length && (
            <>
              <FormField name='Vedhæftninger' field='attachments'>
                {attachments.map((e, i) => {
                  return (
                    <Row key={i}>
                      <Col xs='4'>
                        <img
                          id={`post-image${i}`}
                          crossOrigin='Anonymous'
                          ref={this.postImageRef}
                          src={e.imageOriginSource}
                          alt={e.imageName}
                          style={{ height: 'auto', width: '100%', objectFit: 'contain' }}
                        />
                        <br />
                        {e.imageName}
                        <br />
                        <Button
                          color='danger'
                          onClick={() => {
                            if (confirm('Vil du slette dette billede?')) {
                              PostActions.deleteImage(e.ImageID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='trash' />
                        </Button>
                      </Col>
                      <Col xs='4'>
                        {!!this.state.currBlog && this.state.currBlog.blogType.toLowerCase() === 'product' ? (
                          <CroppedImageUpload
                            id='post-image-homeBanner'
                            label='crop for home-banner image'
                            originalImage={document.getElementById(`post-image${i}`)}
                            originalImageId={`post-image${i}`}
                            src={e.imageOriginSource}
                            initialCrop={{ unit: '%', width: 50, aspect: 0.8 }}
                            onComplete={imgUrl => PostActions.setHomeBannerImage(imgUrl)}
                          />
                        ) : (
                          <CroppedImageUpload
                            id='post-image-card'
                            label='crop for card image'
                            originalImage={document.getElementById(`post-image${i}`)}
                            originalImageId={`post-image${i}`}
                            src={e.imageOriginSource}
                            initialCrop={{ unit: '%', width: 50, aspect: 1.33 }}
                            onComplete={imgUrl => PostActions.setCardImage(imgUrl)}
                          />
                        )}
                      </Col>
                      <Col xs='4'>
                        <CroppedImageUpload
                          id='post-image-banner'
                          label='crop for post-banner image'
                          originalImage={document.getElementById(`post-image${i}`)}
                          originalImageId={`post-image${i}`}
                          src={e.imageOriginSource}
                          initialCrop={{ unit: '%', width: 100, aspect: 2.4 }}
                          onComplete={imgUrl => PostActions.setBannerImage(imgUrl)}
                        />
                      </Col>
                    </Row>
                  )
                })}
              </FormField>
            </>
          )}
          <FormField
            name='Vedhæft billede'
            field='upload'
            type='file'
            data={{ upload: '' }}
            noValue={this.state.file != null}
            onChange={PostActions.selectFile}
            disabled={this.state.isUploading}
            accept='image/*'
          />

          {this.state.blogs.map((e, idx) => {
            if (e.BlogID == this.state.currBlogID && e.blogType == 'PRODUCT') {
              return (
                <div key={idx}>
                  <FormField
                    type='checkbox'
                    data={this.state.postDetails}
                    field={'hideReadMore'}
                    name='Hide read more button'
                    unCheckedValue={0}
                    checkedValue={1}
                    onChange={PostActions.updatePostField}
                  />
                  {this.state.postDetails.hideReadMore ? (
                    <div>
                      <FormField
                        type='text'
                        data={this.state.postDetails}
                        field={'link'}
                        name='Link'
                        value={this.state.postDetails.link ? this.state.postDetails.link : ''}
                        onChange={PostActions.updatePostField}
                      />
                    </div>
                  ) : null}
                </div>
              )
            }
          })}
          {groups.length > 0 && (
            <FormField name='Grupper' field='groups' data={{}}>
              <Row>
                <Col xs='2'>
                  <Label for='groupFilterHead'>Søg Grupper</Label>
                </Col>
                <Col xs='5'>
                  <Input
                    type='text'
                    value={this.state.postDetails.groupFilterText}
                    name='groupFilterText'
                    id='groupFilterText'
                    onChange={PostActions.updatePostField}
                  />
                </Col>
              </Row>
              <React.Fragment>
                <Col className='max-height-300 overflow-y-scroll padding-30 margin-top-30'>{groups}</Col>
              </React.Fragment>
            </FormField>
          )}

          <p style={{ marginBottom: 8 }}>
            <strong>Upload Video</strong>
          </p>
          <VideoUpload onVideoUploaded={PostActions.videoFileUploaded} />
          <Row className='mt-3 mb-3'>
            {!!this.state.postDetails.videoSrc && (
              <div className='mt-3 px-3'>
                <Row>
                  <Col xs='3'>
                    <video controls className='mt-2' src={this.state.postDetails.videoSrc} style={{ width: '100%' }} />
                  </Col>
                </Row>
                <StyledAttachmentDiv>
                  <Row>
                    <Col xs='9'></Col>
                    <Col xs='3'>
                      <Button
                        color='danger'
                        onClick={() => {
                          if (confirm('Vil du slette dette video?')) {
                            PostActions.deleteVideo()
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                    </Col>
                  </Row>
                </StyledAttachmentDiv>
              </div>
            )}
          </Row>

          <Button onClick={PostActions.savePost} disabled={this.state.isSaving}>
            {this.state.isSaving ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={this.resetPost} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    )
  }
}
