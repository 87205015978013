import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BREAKPOINTS } from 'Components/mq.js'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import { Button, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row } from 'reactstrap'
import Proxies from 'Components/proxies'
import { toast } from 'react-toastify'
import DynamicTable from 'Components/dynamicTable'
import { numberFormatter } from 'Util/currencyFormatter'

const ChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: ${BREAKPOINTS.md}) {
    flex-direction: row;
  }
`

const PointTotalWrap = styled.div`
  display: flex;
  align-items: baseline;
`

const PointTotalValue = styled.div`
  font-weight: bold;
`

const CopyIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 16px;
  left: 16px;
  color: #28d094;

  &:hover {
    color: #1b966a;
    cursor: pointer;
  }

  &.clicked {
    color: #c8ced3;
  }
`

const MailIcon = styled(FontAwesomeIcon)`
  color: #28d094;
`

const SettingIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -5;
  left: 8px;
  color: #1b966a;
  width: 15px;
  height: 15px;
`

function PointComponent({ title, pointValue }) {
  return (
    <PointTotalWrap>
      <h5>{title}</h5>
      <PointTotalValue className='ml-3'>{pointValue}</PointTotalValue>
    </PointTotalWrap>
  )
}

export default function LastMonthTotalPoints() {
  const [copyClicked, setCopyClicked] = useState(false)
  const [showBrandContactModal, setShowBrandContactModal] = useState(false)
  const [showSendEmailModal, setShowSendEmailModal] = useState(false)
  const [walletPointsStats, loadingState] = useDashboardData('reports/wallet-activation-points-stats')

  const title = 'Point til aktivering opsummering pr. ' + moment(_.get(walletPointsStats, 'period'), 'DD-MM-YYYY').format('DD/M YYYY')
  const titalEarnedTitle = 'Total points:'
  const totalEarned = numberFormatter.format(_.get(walletPointsStats, 'totalEarned'))
  const totalActivated = numberFormatter.format(_.get(walletPointsStats, 'totalActivated'))
  const totalActivatedTitle = 'Aktiverede points:'
  const totalWaiting = numberFormatter.format(_.get(walletPointsStats, 'totalWaiting'))
  const totalWaitingTitle = 'Ikke aktiveret points:'

  const copyContentClick = () => {
    setCopyClicked(true)
    setTimeout(() => {
      setCopyClicked(false)
    }, 10000)
    navigator.clipboard.writeText(
      `${title}\n${titalEarnedTitle} ${totalEarned}\n${totalActivatedTitle} ${totalActivated}\n${totalWaitingTitle} ${totalWaiting}\n`
    )
  }
  let state = DASHBOARD_COMPONENT_STATE.SUCCESS
  let content = (
    <ChartWrap className='pt-3'>
      <PointComponent title={titalEarnedTitle} pointValue={totalEarned} />
      <PointComponent title={totalActivatedTitle} pointValue={totalActivated} />
      <PointComponent title={totalWaitingTitle} pointValue={totalWaiting} />
    </ChartWrap>
  )

  return (
    <DashboardComponent
      header={
        <span>
          Point til aktivering opsummering pr.{' '}
          {_.get(walletPointsStats, 'period') ? (
            moment(walletPointsStats.period, 'DD-MM-YYYY').format('DD/M YYYY')
          ) : (
            <FontAwesomeIcon icon='spinner' spin size='xs' />
          )}
        </span>
      }
      state={state}
    >
      {copyClicked && <CopyIcon className='clicked' icon={'check'} size='lg' title='Indhold kopieret' onClick={copyContentClick} />}
      {!copyClicked && <CopyIcon icon={'copy'} size='lg' title='Kopier indhold' onClick={copyContentClick} />}
      <div style={{ cursor: 'pointer', position: 'absolute', top: 16, left: 45 }} onClick={() => setShowBrandContactModal(true)}>
        <div style={{ position: 'relative' }}>
          <MailIcon icon={'envelope'} size='lg' title='Mail indstilling' />
          <SettingIcon icon={'cog'} size='lg' title='Mail indstilling' />
        </div>
      </div>
      {loadingState === 'SUCCESS' ? (
        <div style={{ cursor: 'pointer', position: 'absolute', top: 16, left: 80 }} onClick={() => setShowSendEmailModal(true)}>
          <div style={{ position: 'relative' }}>
            <MailIcon icon={'envelope'} size='lg' title='Mail sende' />
            <SettingIcon icon={'share'} size='sm' title='Mail sende' />
          </div>
        </div>
      ) : null}
      {content}
      <ContactModal showBrandContactModal={showBrandContactModal} closeModal={() => setShowBrandContactModal(false)} />

      <ShowSendEmailModal
        emailBody={`Hermed opdaterede tal pr. ${
          walletPointsStats ? moment(walletPointsStats.period, 'DD-MM-YYYY').format('DD-MM-YYYY') : ''
        } fra SalesClub
Optjente\t\t\t\t\t\t\t\t ${totalEarned}
Aktiverede\t\t\t\t\t\t\t\t ${totalActivated}
Ikke aktiveret\t\t\t\t\t\t\t\t ${totalWaiting}`}
        showSendEmailModal={showSendEmailModal}
        closeModal={() => {
          setShowSendEmailModal(false)
        }}
      />
    </DashboardComponent>
  )
}

export const ContactModal = ({ showBrandContactModal, closeModal, showDepartments = false }) => {
  const [contacts, setContacts] = useState([])
  const [departments, setDepartments] = useState([])

  const fetchBrandContactEmails = () => {
    Proxies.GET('BrandContactEmail/pool').then(responseJSON => {
      if (responseJSON) {
        if (showDepartments) {
          responseJSON.map(item => {
            let metadata = item.metadata ? JSON.parse(item.metadata) : {}
            if (metadata.departments?.length) {
              item.departments = []
              departments.map(dept => {
                const match = metadata.departments.find(d => d === dept)
                item.departments.push({
                  userDepartmentUID: dept,
                  selected: !!match
                })
              })
            } else {
              item.departments = departments.map(dept => {
                return {
                  userDepartmentUID: dept,
                  selected: false
                }
              })
            }
            return item
          })
        }
        setContacts(responseJSON)
      }
    })
  }

  const fetchBrandDepartments = () => {
    Proxies.GET('BrandContactEmail/getDepartments').then(responseJSON => {
      if (responseJSON) {
        setDepartments(responseJSON.map(dept => dept.userDepartmentUID))
      }
    })
  }

  const saveContacts = () => {
    if (showDepartments) {
      for (let contact of contacts) {
        if (contact.departments) {
          contact.metadata = { departments: contact.departments.filter(dept => !!dept.selected).map(dept => dept.userDepartmentUID) }
        }
      }
    }
    Proxies.POST('BrandContactEmail/replace', { contacts }).then(responseJSON => {
      if (responseJSON.error) return setContacts([])
      fetchBrandContactEmails()
    })
  }

  useEffect(() => {
    if (showBrandContactModal) {
      fetchBrandContactEmails()
    }
  }, [showBrandContactModal])

  useEffect(() => {
    if (showDepartments) {
      fetchBrandDepartments()
    }
  }, [])

  return (
    <Modal isOpen={showBrandContactModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Contacts</ModalHeader>
      <ModalBody style={{ maxHeight: showDepartments ? 'full' : '720px' }}>
        <Table className='mb-sm-0'>
          <thead>
            <tr>
              <th>Emails</th>
              {!!showDepartments && <th>Departments</th>}
              <th>
                <Button
                  onClick={() =>
                    setContacts([
                      ...contacts,
                      {
                        emailTo: '',
                        departments: departments.map(dept => {
                          return { userDepartmentUID: dept, selected: false }
                        })
                      }
                    ])
                  }
                >
                  <FontAwesomeIcon icon='plus' />
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((item, key) => {
              return (
                <tr key={key}>
                  <td>
                    <Input
                      type='text'
                      name='emailTo'
                      value={item.emailTo}
                      id='emailTo'
                      onChange={t =>
                        setContacts(
                          contacts.map((contact, index) => {
                            if (key === index) {
                              contact.emailTo = t.target.value
                            }
                            return contact
                          })
                        )
                      }
                    ></Input>
                  </td>
                  {!!showDepartments && (
                    <td>
                      {item.departments.map(dept => {
                        return (
                          <div key={dept.userDepartmentUID} style={{ marginLeft: '16px' }}>
                            <Input
                              type='checkbox'
                              name='deparment'
                              value={dept.selected}
                              checked={dept.selected}
                              id='emailTo'
                              onChange={() =>
                                setContacts(
                                  contacts.map((contact, index) => {
                                    if (key === index) {
                                      const match = contact.departments.find(d => d.userDepartmentUID === dept.userDepartmentUID)
                                      match.selected = true
                                    }
                                    return contact
                                  })
                                )
                              }
                            ></Input>
                            <span>{dept.userDepartmentUID}</span>
                          </div>
                        )
                      })}
                    </td>
                  )}
                  <td>
                    <Button
                      onClick={() => {
                        setContacts(
                          contacts.filter((contact, index) => {
                            if (index !== key) return contact
                          })
                        )
                      }}
                    >
                      <FontAwesomeIcon icon='minus' />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={saveContacts}>
          Gem
        </Button>
        <Button color='secondary' onClick={closeModal}>
          Luk
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const ShowSendEmailModal = ({ closeModal, showSendEmailModal, isKamDashboard = false }) => {
  const [contacts, setContacts] = useState([])
  const [emailBody, setEmailBody] = useState('')
  const [kamSummary, setKamSummary] = useState({})

  const fetchBrandContactEmails = () => {
    Proxies.GET('BrandContactEmail/pool').then(responseJSON => {
      if (responseJSON) {
        setContacts(responseJSON)
      }
    })
  }

  const fetchBrandEmailBody = () => {
    Proxies.GET('wallets/getMonthlyPointsSummaryBrand').then(responseJSON => {
      if (responseJSON) {
        setEmailBody(responseJSON)
      }
    })
  }

  const fetchKamSummary = () => {
    Proxies.GET('BrandContactEmail/kamSummary').then(responseJSON => {
      if (responseJSON) {
        let kamSummaryMap = {}
        for (let user of responseJSON) {
          if (!kamSummaryMap[user.userDepartmentUID]) {
            kamSummaryMap[user.userDepartmentUID] = []
          }
          kamSummaryMap[user.userDepartmentUID].push(user)
        }
        for (let dept in kamSummaryMap) {
          const users = kamSummaryMap[dept]
          kamSummaryMap[dept].push({
            userDepartmentUID: '',
            userName: 'Total',
            userUID: '',
            budget: users.reduce((sum, a) => sum + a.budget, 0),
            spentThisMonth: users.reduce((sum, a) => sum + a.spentThisMonth, 0),
            spentLastMonth: users.reduce((sum, a) => sum + a.spentLastMonth, 0),
            spentCurrentYear: users.reduce((sum, a) => sum + a.spentCurrentYear, 0),
            remainingBudget: users.reduce((sum, a) => sum + a.remainingBudget, 0)
          })
        }
        setKamSummary(kamSummaryMap)
      }
    })
  }

  const sendEmailToContacts = department => {
    if (contacts.length) {
      Proxies.POST('BrandContactEmail/sendEmail', { department }).then(responseJSON => {
        if (responseJSON?.status === 'SUCCESS') {
          toast.success('Email sent to the recipient successfully!', { autoClose: 1000 })
        }
      })
      closeModal()
    }
  }

  useEffect(() => {
    fetchBrandContactEmails()
    isKamDashboard ? fetchKamSummary() : fetchBrandEmailBody()
  }, [])

  return (
    <Modal isOpen={showSendEmailModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Send Email</ModalHeader>
      <ModalBody>
        <Col>
          {isKamDashboard ? (
            Object.keys(kamSummary).map(dept => {
              const deptUsers = kamSummary[dept]
              let deptUsersApproved = kamSummary[dept].filter(kam => kam.userAccess === 'APPROVED')
              if (deptUsersApproved.length) {
                deptUsersApproved.push({
                  userDepartmentUID: '',
                  userName: 'Total',
                  userUID: '',
                  budget: deptUsersApproved.reduce((sum, a) => sum + a.budget, 0),
                  spentThisMonth: deptUsersApproved.reduce((sum, a) => sum + a.spentThisMonth, 0),
                  spentLastMonth: deptUsersApproved.reduce((sum, a) => sum + a.spentLastMonth, 0),
                  spentCurrentYear: deptUsersApproved.reduce((sum, a) => sum + a.spentCurrentYear, 0),
                  remainingBudget: deptUsersApproved.reduce((sum, a) => sum + a.remainingBudget, 0)
                })
              }
              let deptUsersDeclined = kamSummary[dept].filter(kam => kam.userAccess === 'DECLINED')
              if (deptUsersDeclined.length) {
                deptUsersDeclined.push({
                  userDepartmentUID: '',
                  userName: 'Total',
                  userUID: '',
                  budget: deptUsersDeclined.reduce((sum, a) => sum + a.budget, 0),
                  spentThisMonth: deptUsersDeclined.reduce((sum, a) => sum + a.spentThisMonth, 0),
                  spentLastMonth: deptUsersDeclined.reduce((sum, a) => sum + a.spentLastMonth, 0),
                  spentCurrentYear: deptUsersDeclined.reduce((sum, a) => sum + a.spentCurrentYear, 0),
                  remainingBudget: deptUsersDeclined.reduce((sum, a) => sum + a.remainingBudget, 0)
                })
              }
              const department = deptUsers[0].department
              return (
                <Fragment key={dept}>
                  <Row>
                    <p>
                      <strong style={{ marginBottom: '4px', marginTop: '8px' }}>Opsummering for gruppe: {department}</strong>
                    </p>
                    <div style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => sendEmailToContacts(deptUsers[0].userDepartmentUID)}>
                      <div style={{ position: 'relative' }}>
                        <MailIcon icon={'envelope'} size='lg' title='Mail sende' />
                        <SettingIcon icon={'share'} size='sm' title='Mail sende' />
                      </div>
                    </div>
                  </Row>
                  {!!deptUsersApproved.length && <p style={{ marginBottom: '16px' }}>APPROVED</p>}
                  {!!deptUsersApproved.length && (
                    <DynamicTable
                      // customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap', marginBottom: 8 }}
                      responsive
                      size='sm'
                      headerscss={{
                        budget: { textAlign: 'right' },
                        spentThisMonth: { textAlign: 'right' },
                        spentLastMonth: { textAlign: 'right' },
                        spentCurrentYear: { textAlign: 'right' },
                        remainingBudget: { textAlign: 'right' }
                      }}
                      headers={{
                        userName: 'KAM',
                        userUID: 'UID',
                        budget: 'Budget',
                        spentThisMonth: 'Forbrug denne måned',
                        spentLastMonth: 'Forbrug sidste måned',
                        spentCurrentYear: 'Forbrug i år',
                        remainingBudget: 'Resterende budget'
                      }}
                      options={{
                        fieldFunctions: {
                          spentThisMonth: e => {
                            return numberFormatter.format(e.spentThisMonth)
                          },
                          budget: e => {
                            return numberFormatter.format(e.budget)
                          },
                          spentLastMonth: e => {
                            return numberFormatter.format(e.spentLastMonth)
                          },
                          spentCurrentYear: e => {
                            return numberFormatter.format(e.spentCurrentYear)
                          },
                          remainingBudget: e => {
                            return numberFormatter.format(e.remainingBudget)
                          }
                        }
                      }}
                      data={deptUsersApproved}
                    />
                  )}
                  {!!deptUsersDeclined.length && (
                    <>
                      <p>
                        <span style={{ marginBottom: '8px', marginTop: '8px' }}>DECLINED</span>
                      </p>
                      <DynamicTable
                        // customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap', marginBottom: 8 }}
                        responsive
                        size='sm'
                        headerscss={{
                          budget: { textAlign: 'right' },
                          spentThisMonth: { textAlign: 'right' },
                          spentLastMonth: { textAlign: 'right' },
                          spentCurrentYear: { textAlign: 'right' },
                          remainingBudget: { textAlign: 'right' }
                        }}
                        headers={{
                          userName: 'KAM',
                          userUID: 'UID',
                          budget: 'Budget',
                          spentThisMonth: 'Forbrug denne måned',
                          spentLastMonth: 'Forbrug sidste måned',
                          spentCurrentYear: 'Forbrug i år',
                          remainingBudget: 'Resterende budget'
                        }}
                        options={{
                          fieldFunctions: {
                            spentThisMonth: e => {
                              return numberFormatter.format(e.spentThisMonth)
                            },
                            budget: e => {
                              return numberFormatter.format(e.budget)
                            },
                            spentLastMonth: e => {
                              return numberFormatter.format(e.spentLastMonth)
                            },
                            spentCurrentYear: e => {
                              return numberFormatter.format(e.spentCurrentYear)
                            },
                            remainingBudget: e => {
                              return numberFormatter.format(e.remainingBudget)
                            }
                          }
                        }}
                        data={deptUsersDeclined}
                      />
                    </>
                  )}
                </Fragment>
              )
            })
          ) : (
            <div dangerouslySetInnerHTML={{ __html: emailBody }}></div>
          )}
          <p style={{ marginTop: 8 }}>Email bliver sendt til følgende mail:</p>
          {contacts.map((contact, key) => {
            return <p key={key}>{contact.emailTo}</p>
          })}
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={() => sendEmailToContacts()}>
          Ok
        </Button>
        <Button color='secondary' onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
