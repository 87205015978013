import React from 'react'
import { Component } from 'reflux'
import { KamActions, KamStore } from 'Stores/santanderKamStore'
import SantanderKAMGraph from 'Components/dashboard/SantanderKAMGraph'
import { decodeHtmlEntities } from 'Util/decodeHtmlEntities'

export default class MerchandiseProductsCategoryChart extends Component {
  constructor(props) {
    super(props)
    this.stores = [KamStore]
    this.storeKeys = ['loadingCategory', 'currentMonth', 'currentYear', 'currentQuarter', 'kamProductCategories']
  }

  componentDidMount() {
    KamActions.getCurrentDate()
    KamActions.getMercheandiseProductCategories()
  }

  getCategoryDetails(event, kam) {
    event.stopPropagation()
    let name
    if (kam && kam.length) {
      name = decodeHtmlEntities(event.target.innerHTML)
    }
    let data = kam && kam.length ? kam.filter(item => item.product === name)[0] : kam.data
    const product = data && data.product.replace(/ /g, '-')
    window.open(`/dashboard/${product}`, '_blank')
  }

  getTotalKamCategoryDetails(event) {
    event.preventDefault()
    window.open(`/dashboard/all-product-categories`, '_blank')
  }

  render() {
    return (
      <React.Fragment>
        {
          <SantanderKAMGraph
            header='Købt pr. kategori'
            state={this.state.loadingCategory}
            cursor={{ cursor: 'pointer' }}
            data={this.state.kamProductCategories}
            keys={['Total', this.state.currentYear, this.state.currentQuarter, this.state.currentMonth]}
            indexBy={'product'}
            pagesCount={Math.ceil(this.state.kamProductCategories.length / 14)}
            longestName={
              this.state.kamProductCategories &&
              !!this.state.kamProductCategories.length &&
              Math.max(...this.state.kamProductCategories.map(el => el.product.length))
            }
            legends={{ translateX: 0 }}
            axisLeft={{ format: '~s' }}
            colors={{
              Total: '#E4B4B4',
              [this.state.currentYear]: '#9DA993',
              [this.state.currentMonth]: '#A2DCE7',
              [this.state.currentQuarter]: '#d3dcde'
            }}
            showIcon={true}
            axisBottom={{ onClick: e => this.getCategoryDetails(e, this.state.kamProductCategories) }}
            onClick={this.getCategoryDetails}
            getTotalKamDetails={this.getTotalKamCategoryDetails}
            iconcss='450px'
          />
        }
      </React.Fragment>
    )
  }
}
