import React, { useState } from 'react'
import PointsQuarterAndMonthChart from 'Components/dashboard/charts/PointsQuarterAndMonthChart.js'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import _ from 'lodash'
import moment from 'moment'

export default function OrdersMonthlyChart() {
  const [data, loadingState] = useDashboardData('reports/all-orders-graph')
  const [calenderToggle, setCalenderToggle] = useState(null)
  const [startDate, setStartDate] = useState(moment().format())
  const [endDate, setEndDate] = useState(moment().format())

  function formatMoment(input) {
    return moment(input).format('YYYY-MM-DD')
  }

  const getFilterDetails = () => {
    window.open(`/all-orders-within-dates/startDate=${formatMoment(startDate)}&endDate=${formatMoment(endDate)}`)
    setFilterToggle()
    setEndDate(moment().format())
  }

  function setFilterToggle() {
    setCalenderToggle(prev => (prev === true ? false : true))
  }

  return (
    <PointsQuarterAndMonthChart
      header={'Ordrer'}
      state={loadingState}
      monthlyData={_.map(_.get(data, 'monthlyResults'), value => ({
        Month: moment(value.month, 'MM/YYYY').format('MMM YY'),
        Ordrer: value.orderCount
      }))}
      quarterlyData={_.map(_.get(data, 'quarterlyResults'), value => ({
        Month: moment(value.month, 'Q/YYYY').format('Qo[kvt.] YYYY'),
        Ordrer: value.orderCount
      }))}
      keys={['Ordrer']}
      indexBy={'Month'}
      legends={[]}
      getFilterDetails={getFilterDetails}
      setFilterToggle={setFilterToggle}
      calenderToggle={calenderToggle}
      showIcon={true}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      axisLabel={'Ordrer'}
    />
  )
}
