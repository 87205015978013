import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Label, Input, FormGroup, UncontrolledTooltip } from 'reactstrap'
import { HofActions, HofStore, STATE } from 'Stores/hofStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import styled from 'styled-components'
import DynamicTable from 'Components/dynamicTable'
import { LoginStore } from 'Stores/loginStore'
import cx from 'classnames'
import { toast } from 'react-toastify'
import _ from 'lodash'

const StyledCheckbox = styled(FormField)`
  position: relative !important;
  left: 20px;
`
const StyledButton = styled(Button)`
  &.btn-warning.disabled,
  .btn-warning:disabled {
    cursor: not-allowed !important;
  }
`
const StyledWrapper = styled.div`
  max-height: 300px !important;
  overflow: auto;
  padding: 20px !important;
`
export default class HOFForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [HofStore, LoginStore]
    this.storeKeys = [
      'isAdminAccess',
      'fetchingGroups',
      'fetchingBrands',
      'brands',
      'settings',
      'status',
      'hofDetails',
      'groups',
      'filteredGroupData',
      'previewElements',
      'isPreview',
      'translations',
      'languages'
    ]
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  componentDidMount() {
    HofActions.fetchLanguages(this.state.settings.BrandID)
  }

  componentDidUpdate() {
    if (this.state.settings && this.state.settings.BrandID) {
      const fetchGroups = _.get(this.props, 'match.params.HOFID') || _.get(this.props, 'location.search') ? false : true
      if (
        this.state.settings.brandName === 'MSB' &&
        this.state.settings.BrandID === 9 &&
        this.state.settings.userPrivilege === 'MANAGE' &&
        this.state.settings.userRole === 'System' &&
        !this.state.isAdminAccess
      ) {
        HofActions.giveAccess(this.state.settings.BrandID, fetchGroups)
        HofActions.getBrands()
      } else {
        fetchGroups &&
          !this.state.isAdminAccess &&
          !this.state.fetchingGroups &&
          this.state.groups &&
          !this.state.groups.length &&
          HofActions.getGroups(this.state.settings.BrandID)
      }
      this.state.hofDetails &&
        !this.state.hofDetails.BrandID &&
        this.state.isAdminAccess &&
        fetchGroups &&
        HofActions.giveAccess(this.state.settings.BrandID, fetchGroups)
      this.state.isAdminAccess && this.state.brands && !this.state.brands.length && !this.state.fetchingBrands && HofActions.getBrands()
    }
    if (this.state.status === STATE.FETCHING_GROUPS_FAILED) {
      toast.error('FETCHING GROUPS FAILED', {
        autoClose: 3000,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }
  }

  resetData = () => {
    this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
    if (_.get(this.props, 'match.params.HOFID') || _.get(this.props, 'location.search')) {
      const copy = _.get(this.props, 'location.search') ? true : false
      const HOFID = _.get(this.props, 'location.search') ? this.props.location.search.split('=')[1] : this.props.match.params.HOFID
      HofActions.getHOFDetails(Number(HOFID), copy)
    } else {
      HofActions.createEmptyHOF()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      ...(this.state.hofDetails.timeStart &&
        this.state.hofDetails.timeEnd && { isDateValid: moment(this.state.hofDetails.timeStart).isSameOrBefore(this.state.hofDetails.timeEnd) })
    }

    this.setState({ fieldValidation })

    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveHOFForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      HofActions.saveHOF()
    }
  }

  render() {
    if (this.state.status === STATE.IS_REDIRECT) {
      return <Redirect to='/hof' />
    }

    let groups = []
    const groupList =
      this.state.filteredGroupData && this.state.hofDetails.groupFilterText && this.state.hofDetails.groupFilterText.length
        ? this.state.filteredGroupData
        : this.state.groups
    let hofGroups = this.state.hofDetails.groups ? this.state.hofDetails.groups.concat([]) : []
    groupList.forEach((e, i) => {
      let isCheckedData = hofGroups.find(s => {
        return s.GroupID === e.GroupID
      })
      let isChecked = isCheckedData !== undefined
      if (isChecked) {
        hofGroups = hofGroups.filter(s => {
          return s.GroupID !== e.GroupID
        })
      }
      groups.push(
        <React.Fragment key={i}>
          <Input type='checkbox' checked={isChecked} name={e.GroupID} onChange={HofActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
          <br />
        </React.Fragment>
      )
    })
    if (hofGroups.length > 0) {
      groups.push(
        <React.Fragment key={'overflow'}>
          Følgende grupper skal gendannes via databasen hvis de slettes
          <br />
        </React.Fragment>
      )
      hofGroups.forEach((e, i) => {
        groups.push(
          <React.Fragment key={'overflow' + i}>
            <Input type='checkbox' checked={true} name={e.GroupID} onChange={HofActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Hall Of Fame</strong>}>
          <Form>
            {this.state.isAdminAccess && (
              <FormField field={'BrandID'} name='Brand'>
                <Input type='select' name='BrandID' value={this.state.hofDetails.BrandID} onChange={HofActions.updateHOFField}>
                  <option value=''>Select Brand</option>
                  {this.state.brands &&
                    !!this.state.brands.length &&
                    this.state.brands.map(e => {
                      return (
                        <option value={e.BrandID} key={e.BrandID}>
                          {e.brandName}
                        </option>
                      )
                    })}
                </Input>
              </FormField>
            )}
            <FormField type='textarea' name='Module Query' field='moduleQuery' data={this.state.hofDetails} onChange={HofActions.updateHOFField} />
            <FormField name='Grupper' field='Groups' data={{}}>
              <Row>
                <Col xs='2'>
                  <Label for='groupFilterHead'>Søg Grupper</Label>
                </Col>
                <Col xs='5'>
                  <Input
                    type='text'
                    value={this.state.hofDetails.groupFilterText}
                    name='groupFilterText'
                    id='groupFilterText'
                    onChange={HofActions.updateHOFField}
                  />
                </Col>
              </Row>
              <React.Fragment>
                <StyledWrapper>
                  <Col>{groups}</Col>
                </StyledWrapper>
              </React.Fragment>
            </FormField>
            <FormField name='Tidsplan' data={{}}>
              <Row>
                <Col xs='6'>
                  <Input
                    className={cx({
                      border: this.state.fieldValidation.isDateValid === false,
                      rounded: this.state.fieldValidation.isDateValid === false,
                      'border-danger': this.state.fieldValidation.isDateValid === false,
                      'p-2': this.state.fieldValidation.isDateValid === false
                    })}
                    type='date'
                    placeholder='Time start'
                    value={this.state.hofDetails.timeStart}
                    name='timeStart'
                    id='timeStart'
                    onChange={e => {
                      HofActions.updateHOFField(e)
                      this.validateForm()
                    }}
                  />
                </Col>
                <Col xs='6'>
                  <Input
                    className={cx({
                      border: this.state.fieldValidation.isDateValid === false,
                      rounded: this.state.fieldValidation.isDateValid === false,
                      'border-danger': this.state.fieldValidation.isDateValid === false,
                      'p-2': this.state.fieldValidation.isDateValid === false
                    })}
                    type='date'
                    value={this.state.hofDetails.timeEnd}
                    name='timeEnd'
                    id='timeEnd'
                    onChange={e => {
                      HofActions.updateHOFField(e)
                      this.validateForm()
                    }}
                  />
                </Col>
              </Row>
            </FormField>
            <FormField type='number' name='Pool Order' field='poolOrder' data={this.state.hofDetails} onChange={HofActions.updateHOFField} />
            <StyledCheckbox
              type='checkbox'
              name='Show ProfileImages'
              field='showProfileImages'
              data={this.state.hofDetails}
              onChange={HofActions.updateHOFField}
              checkedValue={1}
              unCheckedValue={0}
            />
            <StyledCheckbox
              type='checkbox'
              name='Show Company name'
              field='showCompanyName'
              data={this.state.hofDetails}
              onChange={HofActions.updateHOFField}
              checkedValue={1}
              unCheckedValue={0}
            />
            <FormField name='Points or Entries' data={{}}>
              <Row>
                <Col xs='6'>
                  <StyledCheckbox
                    type='checkbox'
                    name='Show Points'
                    field='showPoints'
                    data={this.state.hofDetails}
                    onChange={HofActions.updateHOFField}
                    value={this.state.hofDetails.showPoints}
                    checkedValue={1}
                    unCheckedValue={0}
                  />
                </Col>
                <Col xs='6'>
                  <StyledCheckbox
                    type='checkbox'
                    name='Show Num Entries'
                    field='showEntries'
                    data={this.state.hofDetails}
                    onChange={HofActions.updateHOFField}
                    value={!this.state.hofDetails.showPoints}
                    checkedValue={1}
                    unCheckedValue={0}
                  />
                </Col>
              </Row>
            </FormField>
            <FormField name='Wallet Activation or Wallet' data={{}}>
              <Row>
                <Col xs='6'>
                  <StyledCheckbox
                    type='checkbox'
                    name='Use Wallet Activation'
                    field='useWalletActivation'
                    data={this.state.hofDetails}
                    onChange={HofActions.updateHOFField}
                    value={!this.state.hofDetails.useWalletActivation}
                    checkedValue={1}
                    unCheckedValue={0}
                  />
                </Col>
                <Col xs='6'>
                  <StyledCheckbox
                    type='checkbox'
                    name='Use Wallet'
                    field='useWallet'
                    data={this.state.hofDetails}
                    onChange={HofActions.updateHOFField}
                    value={!this.state.hofDetails.useWalletActivation}
                    checkedValue={1}
                    unCheckedValue={0}
                  />
                </Col>
              </Row>
            </FormField>
            <FormGroup row>
              <Label for='hofBannerImage' sm={3}>
                Banner Image{' '}
                <span>
                  <FontAwesomeIcon icon='info-circle' id='hofBannerImageTooltip' style={{ color: 'blue' }} />
                  <UncontrolledTooltip target='hofBannerImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
                </span>
              </Label>
              <Col sm={9}>
                <Input
                  name='bannerImageURL'
                  field='upload'
                  type='file'
                  data={{ upload: '' }}
                  noValue={this.state.hofDetails.bannerImageURL !== ''}
                  onChange={e => {
                    HofActions.uploadLoginImageURL(e, 'bannerImageURL')
                  }}
                  accept='image/*'
                />
                <img
                  src={this.state.hofDetails.bannerImageURL}
                  alt={this.state.hofDetails.bannerImageURL}
                  style={{
                    height: '100px',
                    width: '100px',
                    objectFit: 'cover',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: this.state.hofDetails.bannerImageURL ? 'inline-block' : 'none'
                  }}
                />
              </Col>
            </FormGroup>
            <FormField
              type='color'
              name='Banner Text Color'
              field='bannerTextColor'
              data={this.state.hofDetails}
              onChange={HofActions.updateHOFField}
            />
            <Row style={{ marginBottom: 32 }}>
              <Col xs='6'>
                <span style={{ fontSize: 18 }}>Add Langauage Specific Data</span>
              </Col>
              <Col xs='6'>
                <Button className='float-right' onClick={HofActions.addTranslation}>
                  <FontAwesomeIcon icon='plus' />
                </Button>
              </Col>
            </Row>

            {this.state.translations.map((translation, index) => {
              return (
                <div key={index}>
                  <Row style={{ marginBottom: 32 }}>
                    <Col xs='12'>
                      <Button color='danger' className='float-right' onClick={() => HofActions.removeTranslation(index)}>
                        <FontAwesomeIcon icon='minus' />
                      </Button>
                    </Col>
                  </Row>
                  <FormGroup row>
                    <Label for='languageKey' sm={3}>
                      Language
                    </Label>
                    <Col>
                      <Input
                        className={translation.LanguageID ? 'is-valid' : 'is-invalid'}
                        required
                        type='select'
                        name='LanguageID'
                        value={translation.LanguageID ? translation.LanguageID : ''}
                        id='LanguageID'
                        onChange={e => {
                          HofActions.updateTranslations(e.target.name, e.target.value, index)
                        }}
                      >
                        <option value=''>Click to Select</option>
                        {this.state.languages &&
                          this.state.languages.map((lan, i) => {
                            return (
                              <option value={lan.LanguageID} key={i}>
                                {`${lan.languageKey} - ${lan.languageName}`}
                              </option>
                            )
                          })}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormField
                    name='Navn'
                    required
                    field='moduleName'
                    data={translation}
                    onChange={e => {
                      HofActions.updateTranslations(e.target.name, e.target.value, index)
                    }}
                  />
                  <FormField
                    type='textarea'
                    name='Beskrivelse'
                    field='moduleDescription'
                    data={translation}
                    onChange={e => {
                      HofActions.updateTranslations(e.target.name, e.target.value, index)
                    }}
                  />
                  <FormField
                    name='Banner Title'
                    required
                    field='bannerTitle'
                    data={translation}
                    onChange={e => {
                      HofActions.updateTranslations(e.target.name, e.target.value, index)
                    }}
                  />
                  <FormField
                    type='textarea'
                    name='Banner Description'
                    field='bannerDescription'
                    data={translation}
                    onChange={e => {
                      HofActions.updateTranslations(e.target.name, e.target.value, index)
                    }}
                  />
                  <FormField
                    name='Banner Button Text'
                    required
                    field='bannerButtonText'
                    data={translation}
                    onChange={e => {
                      HofActions.updateTranslations(e.target.name, e.target.value, index)
                    }}
                  />
                </div>
              )
            })}

            <Button onClick={this.saveHOFForm} disabled={this.state.status === STATE.IS_SAVING}>
              {this.state.status === STATE.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
            <StyledButton color='warning' disabled={!this.state.hofDetails.moduleQuery} onClick={HofActions.previewHOF} className='ml-2'>
              Preview
            </StyledButton>
          </Form>
        </CollapsibleCard>
        {this.state.isPreview && (
          <CollapsibleCard startOpen header={<strong>Hall Of Fame Preview</strong>}>
            {this.state.status === STATE.FETCHING_PREVIEW ? (
              <FontAwesomeIcon icon='spinner' spin />
            ) : (
              <DynamicTable responsive size='sm' data={this.state.previewElements} />
            )}
          </CollapsibleCard>
        )}
      </React.Fragment>
    )
  }
}
