/* eslint-disable eqeqeq */
import Reflux from 'reflux'

const objectEquals = (x, y) => {
  'use strict'

  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false
  }
  if (!(y instanceof Object)) {
    return false
  }

  // recursive object equality check
  let p = Object.keys(x)
  return (
    Object.keys(y).every(function(i) {
      return p.indexOf(i) !== -1
    }) &&
    p.every(function(i) {
      return objectEquals(x[i], y[i])
    })
  )
}

const verifyChildRouteRequirements = (data, settings) => {
  data.children = data.children.filter(item => {
    if (item.requirements) {
      let requirements = item.requirements
      if (requirements.userPrivilege) {
        if (!settings.userPrivilege) {
          return false
        }
        let userPrivilege = settings.userPrivilege.toUpperCase()
        requirements = verifyUserPrivilege(requirements, userPrivilege)
        if (requirements === null) {
          return false
        }
      }
      if (requirements.userRole) {
        if (!settings.userRole) {
          return false
        }
        let userRole = settings.userRole.toUpperCase()
        requirements = verifyUserRole(requirements, userRole)
        if (requirements === null) {
          return false
        }
      }
      if (requirements.BrandID) {
        requirements = verifyBrandID(requirements, settings)
        if (requirements === null) {
          return false
        }
      }
      if (requirements.features) {
        if (!settings.features) {
          return false
        }
        let features = requirements.features
        if (typeof features == 'object') {
          if (Object.keys(features).length == Object.keys(settings.features).length) {
            if (!objectEquals(features, settings.features)) {
              return false
            }
          }
          for (let key in features) {
            if (!settings.features[key]) {
              return false
            }
            if (features[key].length == settings.features[key].length) {
              if (!objectEquals(features[key], settings.features[key])) {
                return false
              }
            }
            if (Object.keys(features[key]).length == Object.keys(settings.features[key]).length) {
              if (!objectEquals(features[key], settings.features[key])) {
                return false
              }
            }
          }
          if (features.allowUserAdmin) {
            const { UserAdminIDs, allowUserAdmin } = settings.features
            if (!allowUserAdmin || !UserAdminIDs || !UserAdminIDs.length || !UserAdminIDs.includes(settings.UserID)) return false
          }
        }
      }
    }
    if (data.hasSubMenu) {
      if (item.children && item.children.length) {
        item.hasChildMenu = true
      }
      data.subRoutes.push(item.url)
    }
    return item
  })
  return data
}

export default function verifyRequirements(item, settings = null) {
  if (!settings) {
    if (Reflux.GlobalState.LoginStore.settings) {
      settings = Reflux.GlobalState.LoginStore.settings
    }
  }
  if (settings.platform) {
    settings = settings.platform
  }

  let itemCopy = Object.assign({}, item)
  if (itemCopy.hasSubMenu) {
    itemCopy.subRoutes = []
  }
  if (itemCopy.children && itemCopy.children.length) {
    itemCopy = verifyChildRouteRequirements(itemCopy, settings)
  }
  if (!itemCopy.requirements || Object.keys(itemCopy.requirements).length === 0) {
    return itemCopy
  }
  let requirements = itemCopy.requirements
  delete itemCopy.requirements

  if (!isNaN(requirements.length)) {
    let accessAllowed = false
    requirements.forEach(requirement => {
      if (accessAllowed) return
      accessAllowed = !!processRequirements(itemCopy, requirement, settings)
    })
    return accessAllowed ? itemCopy : null
  } else {
    return processRequirements(itemCopy, requirements, settings)
  }
}

const processRequirements = (itemCopy, requirements, settings) => {
  if (requirements.UserID && requirements.UserID === settings.UserID) {
    return itemCopy
  } else {
    delete requirements.UserID
  }

  if (requirements.userPrivilege) {
    if (!settings.userPrivilege) {
      return null
    }
    let userPrivilege = settings.userPrivilege.toUpperCase()
    requirements = verifyUserPrivilege(requirements, userPrivilege)
    if (requirements === null) {
      return null
    } else {
      delete requirements.userPrivilege
    }
  }

  if (requirements.userRole) {
    if (!settings.userRole) {
      return null
    }
    let userRole = settings.userRole.toUpperCase()
    requirements = verifyUserRole(requirements, userRole)
    if (requirements === null) {
      return null
    } else {
      delete requirements.userRole
    }
  }

  if (requirements.BrandID) {
    requirements = verifyBrandID(requirements, settings)
    if (requirements === null) {
      return null
    } else {
      delete requirements.BrandID
    }
  }

  if (requirements.features) {
    if (!settings.features) {
      return null
    }
    let features = requirements.features
    if (typeof features == 'object') {
      if (Object.keys(features).length == Object.keys(settings.features).length) {
        if (!objectEquals(features, settings.features)) {
          return null
        }
      }
      for (let key in features) {
        if (!settings.features[key]) {
          return null
        }
        if (features[key].length == settings.features[key].length) {
          if (!objectEquals(features[key], settings.features[key])) {
            return null
          }
        }
        if (Object.keys(features[key]).length == Object.keys(settings.features[key]).length) {
          if (!objectEquals(features[key], settings.features[key])) {
            return null
          }
        }
      }
      if (features.allowUserAdmin) {
        const { UserAdminIDs, allowUserAdmin } = settings.features
        if (!allowUserAdmin || !UserAdminIDs || !UserAdminIDs.length || !UserAdminIDs.includes(settings.UserID)) return null
      }
    }
    delete requirements.features
  }

  if (requirements.modules) {
    if (!settings.modules) {
      return null
    }
    let modules = requirements.modules
    if (typeof modules == 'object') {
      if (Object.keys(modules).length == Object.keys(settings.modules).length) {
        if (!objectEquals(modules, settings.modules)) {
          return null
        }
      }
      for (let key in modules) {
        if (!settings.modules[key]) {
          return null
        }
        if (modules[key].length == settings.modules[key].length) {
          if (!objectEquals(modules[key], settings.modules[key])) {
            return null
          }
        }
        if (Object.keys(modules[key]).length == Object.keys(settings.modules[key]).length) {
          if (!objectEquals(modules[key], settings.modules[key])) {
            return null
          }
        }
      }
    }
    delete requirements.modules
  }

  if (Object.keys(requirements).length !== 0) {
    for (let key in requirements) {
      if (!settings[key]) {
        return null
      }
      if (requirements[key].length == settings[key].length) {
        if (!objectEquals(requirements[key], settings[key])) {
          return null
        }
      }
      if (Object.keys(requirements[key]).length == Object.keys(settings[key]).length) {
        if (!objectEquals(requirements[key], settings[key])) {
          return null
        }
      }
    }
  }
  return itemCopy
}

const verifyUserPrivilege = (requirements, userPrivilege) => {
  switch (requirements.userPrivilege.toUpperCase()) {
    case 'OWNER':
      if (['OWNER'].indexOf(userPrivilege) == -1) {
        return null
      }
      break
    case 'MANAGE':
      if (['MANAGE', 'OWNER'].indexOf(userPrivilege) == -1) {
        return null
      }
      break
    case 'EDIT':
      if (['EDIT', 'MANAGE', 'OWNER'].indexOf(userPrivilege) == -1) {
        return null
      }
      break
    case 'VIEW':
      if (['VIEW', 'EDIT', 'MANAGE', 'OWNER'].indexOf(userPrivilege) == -1) {
        return null
      }
      break
  }
  return requirements
}
const verifyUserRole = (requirements, userRole) => {
  switch (requirements.userRole.toUpperCase()) {
    case 'SYSTEM':
      if (['SYSTEM'].indexOf(userRole) == -1) {
        return null
      }
      break
    case 'AGENT':
      if (['AGENT', 'SYSTEM'].indexOf(userRole) == -1) {
        return null
      }
      break
    case 'COMPANY':
      if (['COMPANY', 'AGENT', 'SYSTEM'].indexOf(userRole) == -1) {
        return null
      }
      break
    case 'CLIENT':
      if (['CLIENT', 'COMPANY', 'AGENT', 'SYSTEM'].indexOf(userRole) == -1) {
        return null
      }
      break
    case 'BRAND':
      if (['BRAND', 'CLIENT', 'COMPANY', 'AGENT', 'SYSTEM'].indexOf(userRole) == -1) {
        return null
      }
      break
    case 'USER':
      if (['USER', 'BRAND', 'CLIENT', 'COMPANY', 'AGENT', 'SYSTEM'].indexOf(userRole) == -1) {
        return null
      }
      break
  }
  return requirements
}

const verifyBrandID = (requirements, settings) => {
  if (Array.isArray(requirements.BrandID)) {
    if (requirements.BrandID.indexOf(settings.BrandID) == -1) {
      return null
    }
  } else if (requirements.BrandID.exclude) {
    if (
      Array.isArray(requirements.BrandID.exclude) &&
      !!requirements.BrandID.exclude.length &&
      requirements.BrandID.exclude.includes(settings.BrandID)
    ) {
      return null
    }
    if (requirements.BrandID.exclude === settings.BrandID) {
      return null
    }
  } else if (requirements.BrandID != settings.BrandID) {
    return null
  }
  return requirements
}
