import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import { toast } from 'react-toastify'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'

const LanguagesList = () => {
  const [languages, setLanguages] = useState([])
  const [loading, setLoading] = useState(false)

  function getLanguages() {
    setLoading(true)
    Proxies.GET('schemas/languages').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setLanguages(responseJSON)
      }
    })
    setLoading(false)
  }

  function removeLanguage(languageID, languageName, languageNativeName, languageKey) {
    setLoading(true)
    let languageDetails = {
      languageName,
      languageNativeName,
      languageKey,
      removed: 1
    }
    Proxies.POST(`schemas/saveLanguage`, { ...languageDetails, LanguageID: languageID }).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action Failed', {
          autoClose: 7000
        })
      } else {
        toast.success('Language deleted successfully', {
          autoClose: 7000
        })
        window.location.assign(`/translations/languages`)
      }
    })
    setLoading(false)
  }

  useEffect(() => {
    getLanguages()
  }, [])

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Languages
          <Button className='float-right' tag={Link} to={'/translations/languages/new'} style={{ marginLeft: '16px' }}>
            + Opret language
          </Button>
        </strong>
      }
    >
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'name'
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.LanguageID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/translations/languages/' + e.LanguageID} id={'edit' + e.LanguageID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.LanguageID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.LanguageID}
                      color='danger'
                      onClick={() => {
                        if (confirm(`Are you sure you want to delete ${e.languageNativeName}?`)) {
                          removeLanguage(e.LanguageID, e.languageName, e.languageNativeName, e.languageKey)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            LanguageID: '#',
            languageKey: 'Language Key',
            languageName: 'Language Name',
            languageNativeName: 'Language Native Name'
          }}
          data={languages}
        />
      )}
    </CollapsibleCard>
  )
}

export default LanguagesList
