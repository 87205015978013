import React from 'react'
import { Component } from 'reflux'

import isIE from 'Components/isIE'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ProfileStore, ProfileActions } from 'Stores/profileStore'
import { LoginStore } from 'Stores/loginStore'

export default class UserHeader extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProfileStore, LoginStore]
    this.storeKeys = ['fetchingProfile', 'profileData', 'profileError', 'settings']
    this.state = {
      profileDataOpen: false
    }
  }
  toggleProfileData = e => {
    this.setState(prevState => ({
      profileDataOpen: !prevState.profileDataOpen
    }))
    if (e.stopPropagation) {
      e.stopPropagation()
    } else {
      e.cancelBubble = true
    }
  }
  componentDidMount() {
    ProfileActions.getProfile()
  }
  render() {
    let { fetchingProfile, profileData, profileError, settings } = this.state
    if (settings.platform) {
      settings = settings.platform
    }
    let profile = null
    let profileImageUrl = 'https://static.upgrader.club/img/userthumb.png'
    if (fetchingProfile) {
      profile = <FontAwesomeIcon icon='spinner' spin />
    }
    if (Object.keys(profileError).length > 0) {
      profile = (
        <li className='navItem px-3'>
          <img src={profileImageUrl} alt='Manglende data' className='img-avatar profileImage' />
        </li>
      )
    }
    if (Object.keys(profileData).length > 0) {
      if (profileData.attachments && Array.isArray(profileData.attachments) && profileData.attachments.length > 0) {
        let profileImage = profileData.attachments[0]
        if (profileImage.imageOptimizedSourceSmall) {
          profileImageUrl = profileImage.imageOptimizedSourceSmall
        } else if (profileImage.imageOptimizedSourceMedium) {
          profileImageUrl = profileImage.imageOptimizedSourceMedium
        } else if (profileImage.imageOptimizedSource) {
          profileImageUrl = profileImage.imageOptimizedSource
        } else if (profileImage.imageOriginSource) {
          profileImageUrl = profileImage.imageOriginSource
        }
      }
      profile = (
        <Dropdown nav={true} isOpen={this.state.profileDataOpen} toggle={this.toggleProfileData} className='navItem px-3'>
          <DropdownToggle tag='span' onClick={this.toggleProfileData} data-toggle='dropdown' aria-expanded={this.state.profileDataOpen}>
            <img src={profileImageUrl} alt='data' className='img-avatar profileImage headerImage' onClick={this.toggleProfileData} />
          </DropdownToggle>
          <DropdownMenu className='profileDropdown' size='sm'>
            <DropdownItem disabled>{profileData.userName}</DropdownItem>
            <DropdownItem header>Info</DropdownItem>
            <DropdownItem disabled>Privelege: {profileData.userPrivilege}</DropdownItem>
            <DropdownItem disabled>Role: {profileData.userRole}</DropdownItem>
            <DropdownItem disabled>Stage: {profileData.userStage}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )
    }
    return (
      <ul className='nav navbar-nav ml-auto'>
        {isIE() && (
          <li className='nav-item px-2 text-right btn-danger'>
            INTERNET EXPLORER
            <br />
            Siden er ikke optimeret, og der er ting der ikke vil virke
          </li>
        )}
        <li className='nav-item px-2 mb-3 mr-4 text-right'>
          <a href='https://www.upgraderadmin-manual.dk' target='blank'>
            Admin manual
          </a>
        </li>
        <li className='nav-item px-2 text-right'>
          <p className='profileName' onClick={this.toggleProfileData}>
            {profileData.userName}
          </p>
          <p className='profileBrand' onClick={this.toggleProfileData}>
            {settings.brandName}
          </p>
        </li>
        {profile}
      </ul>
    )
  }
}
