import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { DatasetStore, DatasetActions, STATUS } from 'Stores/datasetStore'
import DatasetForm from './datasetForm'

export default class DatasetCreate extends Component {
  constructor(props) {
    super(props)
    this.columnsList = []
    this.stores = [DatasetStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    DatasetActions.createEmptyDataset()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.DATASET_ERROR) {
      toast.error('CREATE DATASET FAILED', {
        autoClose: 3000,
        onClose: () => {
          DatasetActions.clearToast()
        }
      })
      DatasetActions.clearToast()
    }
    if (this.state.status === STATUS.DATASET_SUCCESS) {
      toast.success('CREATED DATASET SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          DatasetActions.clearToast()
        }
      })
      DatasetActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <DatasetForm />
      </React.Fragment>
    )
  }
}
