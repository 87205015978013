/* eslint-disable eqeqeq */
import Reflux, { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import moment from 'moment'
const countryCodes = ['+45', '+46', '+298', '+358']
import { toast } from 'react-toastify'

export const UserActions = createActions([
  'getUsers',
  'resetUsers',
  'updateUserField',
  'saveUser',
  'createUser',
  'resetUser',
  'getUserWallet',
  'getUserOrders',
  'clearWalletOrders',
  'editUser',
  'deleteUser',
  'switchUser',
  'handleDetailViewClick',
  'viewOrderDetails',
  'deleteUserWallet',
  'clearUserShadowError',
  'preserveSearch',
  'preserveSort',
  'preserveAdvanceSearch',
  'preservePagination',
  'preservePaginationAmount',
  'clearToast',
  'logoutModal',
  'userObfuscationModal',
  'previewObfuscationDate',
  'logoutUser',
  'clearLogoutToast',
  'updateStatusHandler',
  'confirmObfuscationSchedule',
  'selectUserHandler',
  'clearStatusToast',
  'preserveAllChecked',
  'openStatusModal',
  'getAdminUsersData',
  'clearAdminListToast',
  'copyAdminUser',
  'selectAdminUserHandler',
  'giveAccess',
  'enableUser',
  'getBrandFeatures',
  'getAdminUserDetails',
  'getVaultsByBrand',
  'preserveAdminAdvanceSearch',
  'toggleInActiveUsers',
  'toggleActiveUsers'
])

export class UserStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = UserActions
    this.state = {
      editedUserData: {},
      editedUserDataPoints: {},
      users: [],
      fetchingUsers: false,
      fetchingUserWallets: false,
      fetchingUserOrders: false,
      isSaving: false,
      wallet: [],
      orders: [],
      selectedOrder: {},
      showOrderDetails: false,
      sortDir: 'ASC',
      sortColumnID: 'UserID',
      filterText: '',
      advanceSearchFilters: [],
      adminAdvanceSearchFilters: [],
      paginationPoint: 0,
      paginationAmount: 25,
      showUserSuccess: false,
      showUserError: false,
      showUniqueUIDError: false,
      openModal: false,
      openUserObfuscationModal: false,
      scheduledObfuscationDate: null,
      showlogOutStatus: '',
      isAllChecked: false,
      showStatusError: false,
      status: '',
      userNotChecked: false,
      vaults: [],
      showAdminUsersError: false,
      adminUsers: [],
      fetchingAdminUsers: false,
      isMSBAdmin: false,
      brandFeatures: {},
      fetchingVaults: false,
      showActiveUsers: true,
      showInActiveUsers: false,
      userFilter: 'active'
    }
  }
  clearToast() {
    this.setState({ showUserSuccess: false, showUserError: false, showUniqueUIDError: false })
  }
  preserveSearch(filterText) {
    this.setState({ filterText: filterText })
  }
  preserveSort(sortDir, sortColumnID) {
    let nextState = { sortDir: 'ASC', sortColumnID: 'UserID' }
    if (sortDir) {
      nextState.sortDir = sortDir
    }
    if (sortColumnID) {
      nextState.sortColumnID = sortColumnID
    }
    this.setState(nextState)
  }
  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
  preservePagination(paginationPoint) {
    this.setState({ paginationPoint })
  }
  preservePaginationAmount(paginationAmount) {
    this.setState({ paginationAmount })
  }
  updateUserField(UserID, field) {
    let userData = this.state.editedUserData
    if (!userData.UserID && UserID) {
      userData = Object.assign(
        {},
        this.state.users.find(e => {
          return e.UserID == UserID
        })
      )
    }
    userData[field.target.name] = strip_tags(field.target.value)
    if (field.target.name == 'defaultVaultID') {
      userData[field.target.name] = Number(field.target.value) ? Number(field.target.value) : ''
    }
    if (field.target.type == 'checkbox') {
      userData[field.target.name] = field.target.checked ? 1 : 0
    }
    if (field.target.name == 'BrandID') {
      userData[field.target.name] = Number(field.target.value)
      if (this.state.isMSBAdmin) {
        this.getBrandFeatures(Number(field.target.value))
        this.getVaultsByBrand(Number(field.target.value))
      }
    }
    if (field.target.name === 'userPhone' || field.target.name === 'userExtraPhone') {
      const regExp = /^[0-9]{1,10}$/
      userData[field.target.name] = regExp.test(field.target.value)
        ? field.target.value
        : field.target.value == ''
        ? field.target.value
        : field.target.defaultValue
    }
    if (field.target.name === 'userName') {
      const regExp = /^([^0-9]*)$/
      userData[field.target.name] = regExp.test(field.target.value)
        ? field.target.value
        : field.target.value == ''
        ? field.target.value
        : field.target.defaultValue
      userData.userFirstName = userData.userName ? userData.userName.split(' ')[0] : ''
    }
    if (field.target.name === 'userFirstName') {
      const regExp = /^([^0-9]*)$/
      userData[field.target.name] = regExp.test(field.target.value)
        ? field.target.value
        : field.target.value == ''
        ? field.target.value
        : field.target.defaultValue
    }
    if (
      field.target.name === 'userCity' &&
      Reflux.GlobalState.LoginStore.settings &&
      Reflux.GlobalState.LoginStore.settings.features &&
      Reflux.GlobalState.LoginStore.settings.features.DkOnlyUsers
    ) {
      const regExp = /^([a-zA-Z.\u00C0-\u00ff ]*)$/
      userData[field.target.name] = regExp.test(field.target.value)
        ? field.target.value
        : field.target.value == ''
        ? field.target.value
        : field.target.defaultValue
    }
    if (
      field.target.name === 'userCompanyUID' ||
      (field.target.name === 'userZipcode' &&
        Reflux.GlobalState.LoginStore.settings &&
        Reflux.GlobalState.LoginStore.settings.features &&
        Reflux.GlobalState.LoginStore.settings.features.DkOnlyUsers)
    ) {
      const regExp = /^([0-9]*)$/
      userData[field.target.name] = regExp.test(field.target.value)
        ? field.target.value
        : field.target.value == ''
        ? field.target.value
        : field.target.defaultValue
    }
    this.setState({ editedUserData: userData, userIsChanged: true })
  }
  getUserOrders(UserID = null) {
    this.setState({ orders: [], fetchingUserOrders: true })
    let nextState = { orders: [], fetchingUserOrders: false }
    if (UserID) {
      GET('user/orders', { UserID: UserID }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          responseJSON.map(order => (order.timeInsert = moment(order.timeInsert).format('DD-MM-YYYY')))
          nextState.orders = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  getUserWallet(UserID = null) {
    this.setState({ wallet: [], fetchingUserWallets: true })
    let nextState = { wallet: [], fetchingUserWallets: false }
    if (UserID) {
      GET('user/wallet', { UserID: UserID }).then(responseJSON => {
        if (responseJSON) {
          responseJSON.map(wallet => (wallet.timeInsert = moment(wallet.timeInsert).format('DD-MM-YYYY')))
          nextState.wallet = responseJSON
          this.setState(nextState)
        }
      })
    }
  }
  clearWalletOrders() {
    this.setState({ wallet: [], orders: [] })
  }
  editUser(UserID = null, iscopyUser) {
    if (this.state.editedUserData.UserID != UserID) {
      this.setState({ editedUserData: {}, editedUserDataPoints: {} })
    }
    let api = this.state.isMSBAdmin ? 'user/msb-admin-fetch-user' : 'user/fetch'
    if (UserID) {
      GET(api, { UserID: UserID }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          if (this.state.isMSBAdmin) {
            this.getBrandFeatures(responseJSON.BrandID)
            this.getVaultsByBrand(responseJSON.BrandID)
          }
          this.getUserWalletPoints(UserID, responseJSON)
          this.setupPhone(responseJSON)
          if (iscopyUser) {
            delete responseJSON.UserID
          }
          responseJSON.disableUserAccess = responseJSON.userAccess === 'TO_BE_OBFUSCATED' ? true : false
          this.setState({ editedUserData: responseJSON })
        }
      })
    }
  }
  getUserWalletPoints(UserID, user) {
    let pointsAPI = user && (user.userAccess !== 'APPROVED' || user.pointSafemode === 1) ? 'user/user-expiry-points' : 'user/points'
    GET(pointsAPI, { UserID: UserID }).then(responseJSON => {
      if (responseJSON) {
        this.setState({ editedUserDataPoints: responseJSON })
      }
    })
  }
  setupPhone(user) {
    if (user.userPhone) {
      const countryCode = countryCodes.filter(code => user.userPhone.includes(code))
      if (countryCode.length) {
        user.userPhoneCode = countryCode[0]
        user.userPhone = user.userPhone.substring(countryCode[0].length)
      } else {
        user.userPhoneCode = countryCodes[0]
      }
    }
    if (user.userExtraPhone) {
      const countryCodeExtra = countryCodes.filter(code => user.userExtraPhone.includes(code))
      if (countryCodeExtra.length) {
        user.userExtraPhoneCode = countryCodeExtra[0]
        user.userExtraPhone = user.userExtraPhone.substring(countryCodeExtra[0].length)
      } else {
        user.userExtraPhoneCode = countryCodes[0]
      }
    }
  }
  saveUser(isAdminUser) {
    this.setState({ isSaving: true })
    const url = this.state.editedUserData.UserID ? (this.state.isMSBAdmin ? 'user/update-user' : 'user/update') : 'user/replace'
    let userRequest = { ...this.state.editedUserData }
    if (isAdminUser) {
      if (userRequest && (userRequest.userRole !== 'User' || userRequest.userPrivilege !== 'VIEW') && !userRequest.userAccessTerms) {
        userRequest.userAccessTerms = 1
      }
    }
    if (userRequest.userPhone) {
      userRequest.userPhone = userRequest.userPhoneCode ? userRequest.userPhoneCode + userRequest.userPhone : '+45' + userRequest.userPhone
    }
    if (userRequest.userExtraPhone) {
      userRequest.userExtraPhone = userRequest.userExtraPhoneCode
        ? userRequest.userExtraPhoneCode + userRequest.userExtraPhone
        : '+45' + userRequest.userExtraPhone
    }
    userRequest.userBirthday === null && delete userRequest.userBirthday
    if (!userRequest.defaultVaultID) {
      delete userRequest.defaultVaultID
    }
    POST(url, userRequest).then(responseJSON => {
      if (responseJSON) {
        if (responseJSON.error) {
          if (responseJSON.error.message === 'UID_UNIQUE_FAILED') {
            this.setState({ showUniqueUIDError: true, isSaving: false })
          } else {
            this.setState({ showUserError: true, isSaving: false })
          }
          return
        }
        let saved = false
        let users = this.state.users
        responseJSON.disableUserAccess = responseJSON.userAccess === 'TO_BE_OBFUSCATED' ? true : false
        for (let i in users) {
          if (users[i].UserID == this.state.editedUserData.UserID) {
            this.getUsers()
            users[i] = { ...responseJSON }
            if (responseJSON && responseJSON.brand) {
              users[i].brandName = responseJSON.brand.brandName
            }
            saved = true
          }
        }
        if (!saved && users.length > 0) {
          if (responseJSON && responseJSON.brand) {
            responseJSON.brandName = responseJSON.brand.brandName
          }
          users.push({ ...responseJSON })
        }
        this.setupPhone(responseJSON)
        this.setState({ users: users, isSaving: false, userIsChanged: false, editedUserData: responseJSON, showUserSuccess: true })
      }
    })
  }
  resetUser() {
    this.setState({ editedUserData: {}, userIsChanged: false })
  }
  resetUsers() {
    this.setState({ users: [] })
  }
  getUsers() {
    this.setState({ fetchingUsers: true, userIsChanged: false, editedUserData: {} })
    let nextState = {
      users: [],
      fetchingUsers: false
    }
    GET('user/raw').then(responseJSON => {
      if (responseJSON) {
        responseJSON = responseJSON.map(item => {
          item.checked = false
          return item
        })
        nextState.users = responseJSON
      }
      this.setState(nextState)
    })
  }
  deleteUser(UserID = null, isAdminList) {
    if (UserID) {
      POST('user/remove', { UserID: UserID }).then(() => {
        isAdminList ? this.getAdminUsersData() : this.getUsers()
      })
    }
  }
  switchUser(user) {
    let api = this.state.isMSBAdmin ? 'user/msb-admin-shadow-user' : 'user/shadow'
    POST(api, { UserID: user.UserID, ...(this.state.isMSBAdmin && { BrandID: user.BrandID }) }).then(response => {
      if (response && response.token) {
        const { brandSwitchUrl, token, adminToken, adminUser } = response
        const clubUrl = `${brandSwitchUrl}/shadow?token=${token}&adminToken=${adminToken || ''}&adminUser=${adminUser || ''}`
        window.open(clubUrl, '_blank')
      } else {
        if (response.error && response.error.message == 'USER_MUST_HAVE_ACCEPTED_TERMS') {
          this.setState({ userShadowError: true })
        }
      }
    })
  }
  handleDetailViewClick() {
    this.setState({ showOrderDetails: !this.state.showOrderDetails })
  }
  viewOrderDetails(order) {
    this.setState({ selectedOrder: order, showOrderDetails: true })
  }
  deleteUserWallet(WalletID = null, UserID) {
    if (WalletID && UserID) {
      POST('wallets/remove', { WalletID: WalletID }).then(() => {
        this.getUserWallet(UserID)
      })
    }
  }
  clearUserShadowError() {
    this.setState({ userShadowError: false })
  }

  logoutModal(openModal) {
    this.setState({ openModal: !openModal })
  }

  previewObfuscationDate() {
    if (this.state.editedUserData && this.state.editedUserData.UserID) {
      GET('DataRetentions/getObfuscationScheduleDate', { UserID: this.state.editedUserData.UserID }).then(response => {
        if (response.error) {
          this.setState({ showAdminUsersError: true })
        } else {
          this.setState({ scheduledObfuscationDate: response })
          this.userObfuscationModal(this.state.openUserObfuscationModal)
        }
      })
    }
  }

  confirmObfuscationSchedule(revert = false) {
    if (this.state.scheduledObfuscationDate && this.state.editedUserData.UserID) {
      if (revert) {
        POST('DataRetentions/revertObfuscationPlanSingleUser', {
          UserID: this.state.editedUserData.UserID
        }).then(response => {
          if (response.error) {
            this.setState({ showAdminUsersError: true })
            this.userObfuscationModal(this.state.openUserObfuscationModal)
            toast.error('Failed to revert data obfuscation', {
              autoClose: 3000
            })
          } else {
            this.setState({ scheduledObfuscationDate: null })
            this.userObfuscationModal(this.state.openUserObfuscationModal)
            toast.success('User data obfuscation is reverted', {
              autoClose: 3000
            })
            this.editUser(this.state.editedUserData.UserID)
          }
        })
      } else {
        POST('DataRetentions/markSingleUserForObfuscation', {
          UserID: this.state.editedUserData.UserID,
          timeScheduled: this.state.scheduledObfuscationDate
        }).then(response => {
          if (response.error) {
            this.setState({ showAdminUsersError: true })
            this.userObfuscationModal(this.state.openUserObfuscationModal)
            toast.error('Failed to schedule data obfuscation', {
              autoClose: 3000
            })
          } else {
            this.setState({ scheduledObfuscationDate: null })
            this.userObfuscationModal(this.state.openUserObfuscationModal)
            toast.success('User data is scheduled for obfuscation', {
              autoClose: 3000
            })
            this.editUser(this.state.editedUserData.UserID)
          }
        })
      }
    }
  }
  userObfuscationModal(openUserObfuscationModal) {
    this.setState({ openUserObfuscationModal: !openUserObfuscationModal })
  }

  logoutUser(user) {
    let data = { UserID: user.UserID, ...(this.state.isMSBAdmin && { BrandID: user.BrandID }) }
    POST('sessions/clear-user-session', data).then(response => {
      response.error ? this.setState({ showlogOutStatus: false }) : this.setState({ openModal: !this.state.openModal, showlogOutStatus: true })
    })
  }
  clearLogoutToast() {
    this.setState({ showlogOutStatus: null })
  }
  selectUserHandler(field, UserID) {
    let users = this.state.users
    let isAllChecked = this.state.isAllChecked
    users.map(item => {
      if (item.UserID === UserID) {
        item.checked = field.target.checked
      }
      return item
    })
    if (field.target.checked === false && isAllChecked) {
      isAllChecked = false
    }
    this.setState({ users, isAllChecked })
  }
  selectAdminUserHandler(field, UserID) {
    let adminUsers = this.state.adminUsers
    let isAllChecked = this.state.isAllChecked
    adminUsers.map(item => {
      if (item.UserID === UserID) {
        item.checked = field.target.checked
      }
      return item
    })
    if (field.target.checked === false && isAllChecked) {
      isAllChecked = false
    }
    this.setState({ adminUsers, isAllChecked })
  }
  updateStatusHandler = (field, data, isAdminUser) => {
    let user = {
      status: this.state.status,
      UserIDs: []
    }
    data.map(item => item.checked && user.UserIDs.push(item.UserID))
    if (user.UserIDs && user.UserIDs.length) {
      let api = isAdminUser ? 'user/update-admin-status' : 'user/update-users-status'
      this.setState({ showStatusError: false, status: field.target.value })
      POST(api, user).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ showStatusError: true })
        } else {
          this.setState({ isAllChecked: false, status: '', openModal: false })
          isAdminUser ? this.getAdminUsersData() : this.getUsers()
        }
      })
    } else {
      this.setState({ status: '', userNotChecked: true, openModal: false })
    }
  }
  clearStatusToast() {
    this.setState({ showStatusError: null, userNotChecked: false })
  }
  preserveAllChecked(isAllChecked) {
    this.setState({ isAllChecked: isAllChecked })
  }
  openStatusModal(field) {
    this.setState({ status: field.target.value, openModal: !this.state.openModal })
  }
  getAdminUsersData() {
    this.setState({ fetchingAdminUsers: true })
    GET('user/admin-users').then(responseJSON => {
      if (responseJSON.error) {
        this.setState({ showAdminUsersError: true })
      } else {
        responseJSON = responseJSON.map(item => {
          item.checked = false
          return item
        })
        this.setState({ adminUsers: responseJSON, fetchingAdminUsers: false })
      }
    })
  }
  clearAdminListToast() {
    this.setState({ showAdminUsersError: false })
  }
  copyAdminUser(e, props) {
    props.history.push(props.location.pathname + '/new?copy=' + e.UserID)
  }
  giveAccess() {
    this.setState({ isMSBAdmin: true })
  }
  enableUser(UserID) {
    if (UserID) {
      POST('user/activate-user', { UserID: UserID }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          this.getUsers()
        }
      })
    }
  }
  getBrandFeatures(BrandID) {
    this.setState({ brandFeatures: {} })
    GET('brands/brand-features', { BrandID }).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        this.setState({ showAdminUsersError: true })
      } else {
        this.setState({ brandFeatures: responseJSON })
      }
    })
  }
  getAdminUserDetails(UserID, iscopyUser) {
    if (this.state.editedUserData.UserID != UserID) {
      this.setState({ editedUserData: {}, editedUserDataPoints: {} })
    }
    if (UserID) {
      GET('user/fetch-admin', { UserID: UserID }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          this.getUserWalletPoints(UserID, responseJSON)
          this.setupPhone(responseJSON)
          if (iscopyUser) {
            delete responseJSON.UserID
          }
          this.setState({ editedUserData: responseJSON })
        }
      })
    }
  }
  getVaultsByBrand(BrandID = null) {
    this.setState({ fetchingVaults: true, vaults: [] })
    GET('vaults/get-brand-vaults', { BrandID }).then(responseJSON => {
      let nextState = {
        vaults: []
      }
      if (responseJSON && !responseJSON.error) {
        nextState.vaults = responseJSON
        nextState.fetchingVaults = false
      }
      this.setState(nextState)
    })
  }
  preserveAdminAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ adminAdvanceSearchFilters: advanceSearchFilters })
    }
  }
  toggleActiveUsers() {
    let value = !this.state.showActiveUsers ? 'active' : 'both'
    this.setState({ showActiveUsers: !this.state.showActiveUsers, userFilter: value, showInActiveUsers: false })
  }
  toggleInActiveUsers() {
    let value = this.state.showInActiveUsers ? 'both' : 'inactive'
    this.setState({ showInActiveUsers: !this.state.showInActiveUsers, userFilter: value, showActiveUsers: false })
  }
}
