import { Fragment, useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import React from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import DynamicTable from 'Components/dynamicTable'

export default function UserGoalsForm(props) {
  const [loading, setLoading] = useState(false)
  const { UserID } = props.match.params
  const [usergoals, setUsergoals] = useState({})
  const [currentMonthGoal, setCurrentMonthGoal] = useState({
    goalValue: 0,
    timeStart: moment()
      .startOf('month')
      .format('YYYY-MM-DD 00:00:00'),
    timeEnd: moment()
      .endOf('month')
      .format('YYYY-MM-DD 23:59:59')
  })
  const [nextMonthGoal, setNextMonthGoal] = useState({
    goalValue: 0,
    timeStart: moment()
      .add(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD 00:00:00'),
    timeEnd: moment()
      .add(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD 23:59:59')
  })
  const [status, setStatus] = useState('LOADING')
  const [groups, setGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState(null)
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState(null)
  const [errorModal, setErrorModal] = useState(null)

  let MinMonth = Date.now()
  MinMonth = moment(MinMonth).format('YYYY-MM-DD')
  let Min = MinMonth.split('-')
  let MaxMonth = new Date(MinMonth)
  MaxMonth = MaxMonth.setMonth(MaxMonth.getMonth() + 1)
  MaxMonth = moment(MaxMonth).format('YYYY-MM-DD')
  let Max = MaxMonth.split('-')

  function saveUsergoals() {
    if (UserID) {
      if (currentMonthGoal.goalValue) {
        currentMonthGoal.UserID = parseInt(UserID)
        currentMonthGoal.goalValue = parseInt(currentMonthGoal.goalValue)
        Proxies.POST('usergoals/replace', currentMonthGoal)
      }
      if (nextMonthGoal.goalValue) {
        nextMonthGoal.UserID = parseInt(UserID)
        nextMonthGoal.goalValue = parseInt(nextMonthGoal.goalValue)
        Proxies.POST('usergoals/replace', nextMonthGoal)
      }
      setStatus('SUCCESS')
    } else {
      if (selectedUsers) {
        usergoals.UserIDs = selectedUsers.map(e => e.UserID)
      }
      usergoals.goalValue = parseInt(usergoals.goalValue)
      if (!usergoals.timeStart) {
        usergoals.timeStart = moment()
          .startOf('month')
          .format('YYYY-MM-DD')
        usergoals.timeEnd = moment()
          .endOf('month')
          .format('YYYY-MM-DD')
      }

      Proxies.POST('usergoals/createMultiple', usergoals).then(responseJSON => {
        if (responseJSON.error) {
          setStatus('FAILED')
        } else if (responseJSON.existingGoals && responseJSON.existingGoals.length) {
          setErrorModal(
            `Goals already exist for the following users and hence no changes were made for these users: ${responseJSON.existingGoals
              .map(u => u.UserID)
              .join(', ')}`
          )
        } else {
          setStatus('SUCCESS')
        }
      })
    }
  }

  const fetchUsergoals = () => {
    setLoading(true)
    Proxies.GET('usergoals/fetch', { UserID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        if (responseJSON.currentMonth) {
          setCurrentMonthGoal({
            ...currentMonthGoal,
            ...responseJSON.currentMonth,
            timeStart: currentMonthGoal.timeStart,
            timeEnd: currentMonthGoal.timeEnd
          })
        }
        if (responseJSON.nextMonth) {
          setNextMonthGoal({ ...nextMonthGoal, ...responseJSON.nextMonth, timeStart: nextMonthGoal.timeStart, timeEnd: nextMonthGoal.timeEnd })
        }
      }
      setLoading(false)
    })
  }

  const resetData = () => {
    if (UserID) {
      fetchUsergoals()
    } else {
      setUsergoals({})
    }
  }

  const updateDate = e => {
    let selected_month = e.target.value
    let start_date = moment(moment(selected_month, 'YYYY-MM')).format('YYYY-MM-DD')
    let end_date = moment(start_date)
      .endOf('month')
      .format('YYYY-MM-DD')
    setUsergoals({ ...usergoals, ['timeStart']: start_date, ['timeEnd']: end_date })
  }

  const updateUsergoals = ({ target }) => {
    setUsergoals({ ...usergoals, [target.name]: target.value })
  }

  const updateGoalValue = (goalValue, month) => {
    if (month === 'currentMonth') {
      setCurrentMonthGoal({ ...currentMonthGoal, ['goalValue']: goalValue })
    } else if (month === 'nextMonth') {
      setNextMonthGoal({ ...nextMonthGoal, ['goalValue']: goalValue })
    }
  }

  useEffect(() => {
    if (UserID) {
      fetchUsergoals(UserID)
    }
    Proxies.GET('groups').then(responseJSON => {
      if (responseJSON && responseJSON.length) {
        setGroups(responseJSON)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedGroups && selectedGroups.length) {
      setShowUsersModal(true)
    }
  }, [selectedGroups])

  if (status === 'SUCCESS') {
    toast.success(UserID ? 'Successfully Updated' : 'Successfully Created', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
    return <Redirect to='/usergoals' />
  }

  if (status === 'FAILED') {
    toast.error('Error while saving usergoals', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
  }

  return (
    <CollapsibleCard startOpen={true} header={<strong>{UserID ? 'Edit' : 'Opret'}</strong>}>
      <Form>
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : UserID ? (
          <>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>User</label>
              </Col>
              <Col xs='10'>
                <span>{currentMonthGoal && currentMonthGoal.user ? currentMonthGoal.user.userName : ''}</span>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Current Month Goal</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='number'
                  value={currentMonthGoal.goalValue}
                  name='goalValue'
                  id='goalValue'
                  onChange={e => updateGoalValue(e.target.value, 'currentMonth')}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Next Month Goal</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='number'
                  value={nextMonthGoal.goalValue}
                  name='nextGoalValue'
                  id='nextGoalValue'
                  onChange={e => updateGoalValue(e.target.value, 'nextMonth')}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Goal Value</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='number'
                  value={usergoals.goalValue ? usergoals.goalValue : ''}
                  name='goalValue'
                  id='goalValue'
                  onChange={e => updateUsergoals(e)}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Month</label>
              </Col>
              <Col xs='10'>
                <input
                  className='form-control'
                  name='chosenMonth'
                  id='chosentMonth'
                  style={{ width: '230px' }}
                  type='month'
                  min={`${Min[0]}-${Min[1]}`}
                  defaultValue={
                    usergoals.timeStart ? usergoals.timeStart.split('-')[0] + '-' + usergoals.timeStart.split('-')[1] : `${Min[0]}-${Min[1]}`
                  }
                  max={`${Max[0]}-${Max[1]}`}
                  onChange={e => {
                    updateDate(e)
                  }}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Select Users</label>
              </Col>
              <Row xs='10'>
                <Button disabled={usergoals.allowAllUsers} style={{ marginLeft: '32px' }} onClick={() => setShowUsersModal(true)}>
                  Select Users
                </Button>
                <Button disabled={usergoals.allowAllUsers} style={{ marginLeft: '16px' }} onClick={() => setShowGroupModal(true)}>
                  Select Group
                </Button>
                {selectedUsers && selectedUsers.length ? <span style={{ marginLeft: '16px' }}>Selected {selectedUsers.length} users</span> : null}
              </Row>

              {showGroupModal && groups.length && (
                <GroupsModal
                  showGroupModal={showGroupModal}
                  groups={
                    selectedGroups && selectedGroups.length
                      ? groups.map(g => {
                          const match = selectedGroups.find(s => s.GroupID === g.GroupID)
                          if (match) {
                            match.checked = true
                          }
                          return g
                        })
                      : groups
                  }
                  closeModal={() => setShowGroupModal(false)}
                  onGroupSelect={groups => {
                    setShowGroupModal(false)
                    setSelectedGroups(groups)
                  }}
                />
              )}

              {showUsersModal && (
                <UsersModal
                  showUsersModal={showUsersModal}
                  selUsers={selectedUsers}
                  GroupIDs={selectedGroups && selectedGroups.length ? selectedGroups.map(g => g.GroupID) : []}
                  closeModal={() => setShowUsersModal(false)}
                  onUsersSelect={users => {
                    setShowUsersModal(false)
                    setSelectedUsers(users)
                  }}
                />
              )}
            </Row>
          </>
        )}

        <Button onClick={saveUsergoals} disabled={loading}>
          {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>

        <Button usergoals='danger' onClick={resetData} className='ml-2'>
          Nulstil
        </Button>
      </Form>
      {errorModal ? (
        <Modal isOpen={!!errorModal} toggle={() => setErrorModal(null)} size='md'>
          <ModalHeader toggle={() => setErrorModal(null)}>Change Status</ModalHeader>
          <ModalBody>
            <strong>{errorModal}</strong>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setErrorModal(null)
                setStatus('SUCCESS')
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </CollapsibleCard>
  )
}

const GroupsModal = ({ showGroupModal, closeModal, groups, onGroupSelect }) => {
  return (
    <Modal isOpen={showGroupModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Select Group</ModalHeader>
      <ModalBody style={{ maxHeight: '720px', overflowY: 'scroll' }}>
        <DynamicTable
          headers={{
            GroupID: ' ',
            moduleName: 'Title',
            rules: 'Rules'
          }}
          data={groups && groups.length ? groups : []}
          responsive
          size='lg'
          options={{
            fieldFunctions: {
              GroupID: e => {
                return (
                  <Fragment>
                    <Input
                      className='order-selection'
                      type='checkbox'
                      id={'checkbox' + e.GroupID}
                      name={'checkbox' + e.GroupID}
                      checked={e.checked}
                      onChange={() => {
                        onGroupSelect([e])
                      }}
                    />
                  </Fragment>
                )
              },
              rules: e => {
                let rules = e.rules ? e.rules : []
                return (
                  <Fragment>
                    {rules.map((e, i) => {
                      return (
                        <Fragment key={i}>
                          {e.tableName} - {e.datacolumnName}
                          <br />
                        </Fragment>
                      )
                    })}
                  </Fragment>
                )
              }
            }
          }}
        />
      </ModalBody>
    </Modal>
  )
}

const UsersModal = ({ showUsersModal, closeModal, GroupIDs, onUsersSelect, selUsers }) => {
  const [selectedUsers, setSelectedUsers] = useState(selUsers || [])
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    setLoading(true)
    if (GroupIDs && GroupIDs.length) {
      let groupUsers = []
      for (let GroupID of GroupIDs) {
        Proxies.GET('groups/elements', { GroupID }).then(responseJSON => {
          setLoading(false)
          groupUsers = groupUsers.concat(responseJSON.filter(user => !users.find(u => u.UserID === user.UserID)))
          if (selUsers && selUsers.length) {
            groupUsers = groupUsers.map(user => {
              let match = selUsers.find(u => u.UserID === user.UserID)
              if (match) {
                user.checked = true
              }
              return user
            })
          }
          setUsers(groupUsers)
        })
      }
    } else {
      Proxies.GET('user/raw').then(responseJSON => {
        setLoading(false)
        responseJSON = responseJSON.map(item => {
          if (selUsers && selUsers.length) {
            let match = selUsers.find(u => u.UserID === item.UserID)
            if (match) {
              item.checked = true
            } else {
              item.checked = false
            }
          } else {
            item.checked = false
          }
          return item
        })
        setUsers(responseJSON)
      })
    }
  }, [])

  return (
    <Modal isOpen={showUsersModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Select Users</ModalHeader>
      <ModalBody style={{ maxHeight: '720px', overflowY: 'scroll' }}>
        {loading ? (
          <FontAwesomeIcon icon='sync' spin />
        ) : (
          <DynamicTable
            headers={{
              UserID: ' ',
              userName: 'Name',
              userEmail: 'Email',
              userPhone: 'Mobile'
            }}
            data={users && users.length ? users : []}
            responsive
            size='lg'
            options={{
              fieldFunctions: {
                UserID: e => {
                  return (
                    <Fragment>
                      <Input
                        className='order-selection'
                        type='checkbox'
                        id={'checkbox' + e.UserID}
                        name={'checkbox' + e.UserID}
                        checked={e.checked}
                        onChange={event => {
                          setSelectedUsers(
                            users
                              .map(u => {
                                if (u.UserID === e.UserID) {
                                  u.checked = event.target.checked
                                }
                                return u
                              })
                              .filter(u => u.checked === true)
                          )
                        }}
                      />
                    </Fragment>
                  )
                }
              }
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Row>
          <Button
            onClick={() => {
              setUsers(
                users.map(user => {
                  user.checked = true
                  return user
                })
              )
              setSelectedUsers(
                users.map(u => {
                  u.checked = true
                  return u
                })
              )
            }}
          >
            Select All
          </Button>
          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => {
              setUsers(
                users.map(user => {
                  user.checked = false
                  return user
                })
              )
              setSelectedUsers([])
            }}
          >
            Unselect All
          </Button>
          <Button style={{ marginLeft: '32px' }} onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button style={{ marginLeft: '8px' }} onClick={() => onUsersSelect(selectedUsers)}>
            Ok
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}
