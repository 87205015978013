import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import { toast } from 'react-toastify'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormField from 'Components/formField'
import { Button, Form } from 'reactstrap'

const LanguageForm = props => {
  const [loading, setLoading] = useState(false)
  const [languageName, setLanguageName] = useState()
  const [languageNativeName, setLanguageNativeName] = useState()
  const [languageKey, setLanguageKey] = useState()
  const [languageID, setLanguageID] = useState(props.LanguageID ? Number(props.LanguageID) : null)

  function getLanguages() {
    setLoading(true)
    Proxies.GET(`schemas/languages`).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        let result = responseJSON.filter(e => e.LanguageID === languageID)
        setLanguageID(result && result[0].LanguageID ? result[0].LanguageID : '')
        setLanguageName(result && result[0].languageName ? result[0].languageName : '')
        setLanguageNativeName(result && result[0].languageNativeName ? result[0].languageNativeName : '')
        setLanguageKey(result && result[0].languageKey ? result[0].languageKey : '')
      }
      setLoading(false)
    })
  }

  function saveLanguageForm() {
    setLoading(true)
    let languageDetails = {
      languageKey,
      languageName,
      languageNativeName,
      removed: 0
    }
    if (!!languageID) {
      languageDetails = { ...languageDetails, LanguageID: languageID }
    }
    Proxies.POST(`schemas/saveLanguage`, { ...languageDetails }).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action Failed', {
          autoClose: 7000
        })
      } else {
        toast.success('Language added successfully', {
          autoClose: 7000
        })
        window.location.assign(`/translations/languages`)
      }
    })
    setLoading(false)
  }

  function resetForm() {
    setLanguageName()
    setLanguageNativeName()
    setLanguageKey()
  }

  useEffect(() => {
    !!languageID ? getLanguages() : null
  }, [])

  return (
    <React.Fragment>
      <CollapsibleCard startOpen={true} header={<strong>Language</strong>}>
        <Form>
          <FormField
            required
            data={{ languageName }}
            field={'languageName'}
            name='Language Name'
            onChange={e => {
              setLanguageName(e.target.value)
            }}
          />
          <FormField
            required
            data={{ languageNativeName }}
            field={'languageNativeName'}
            name='Language Native Name'
            onChange={e => {
              setLanguageNativeName(e.target.value)
            }}
          />
          <FormField
            required
            data={{ languageKey }}
            field={'languageKey'}
            name='Language Key'
            onChange={e => {
              setLanguageKey(e.target.value)
            }}
          />
          <Button onClick={() => saveLanguageForm()} disabled={loading === true}>
            {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={() => resetForm()} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default LanguageForm
