import React, { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'

export default function DepartmentOverview() {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  const [departmentFilterValue, setDepartmentFilterValue] = useState('active')

  useEffect(() => {
    setLoading(true)
    getDepartments()
  }, [])

  function getDepartments() {
    Proxies.GET('departments/pool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setDepartments(responseJSON)
      }
      setLoading(false)
    })
  }
  function deleteDepartment(department) {
    setLoading(true)
    Proxies.POST('departments/remove', department).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getDepartments()
      }
      setLoading(false)
    })
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Departments
          <ButtonGroup className='float-right'>
            <Button
              outline
              department='success'
              onClick={() => setDepartmentFilterValue(departmentFilterValue === 'active' ? 'both' : 'active')}
              active={departmentFilterValue === 'active'}
            >
              Active
            </Button>
            <Button
              outline
              department='danger'
              onClick={() => setDepartmentFilterValue(departmentFilterValue === 'inactive' ? 'both' : 'inactive')}
              active={departmentFilterValue === 'inactive'}
            >
              In-Active
            </Button>
          </ButtonGroup>
        </strong>
      }
    >
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'name'
            },
            fieldFunctions: {},
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.DepartmentID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/department/' + e.DepartmentID} id={'edit' + e.DepartmentID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    {e.removed === 0 && (
                      <React.Fragment>
                        <Tooltip target={'deactivate' + e.DepartmentID}>Deaktivér</Tooltip>
                        <Button
                          id={'deactivate' + e.DepartmentID}
                          onClick={() => {
                            if (confirm('Vil du deaktivere denne Department?')) {
                              deleteDepartment({ DepartmentID: e.DepartmentID, removed: 1 })
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-check' />
                        </Button>
                      </React.Fragment>
                    )}
                    {e.removed === 1 && (
                      <React.Fragment>
                        <Tooltip target={'actiavte' + e.DepartmentID}>Aktivér</Tooltip>
                        <Button
                          id={'actiavte' + e.DepartmentID}
                          department='danger'
                          onClick={() => {
                            if (confirm('Vil du aktivere denne Department?')) {
                              deleteDepartment({ DepartmentID: e.DepartmentID, removed: 0 })
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-slash' />
                        </Button>
                      </React.Fragment>
                    )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            DepartmentID: '#',
            departmentName: 'Name',
            departmentUID: 'UID'
          }}
          data={
            departments && departmentFilterValue !== 'both' && !!departments.length
              ? departments.filter(department => department.removed === (departmentFilterValue === 'inactive' ? 1 : 0))
              : departments
          }
        />
      )}
    </CollapsibleCard>
  )
}
