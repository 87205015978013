import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import Proxies from '../../components/proxies'
import CollapsibleCard from 'Components/collapsibleCard'
import { toast } from 'react-toastify'

const { GET, POST } = Proxies

const EmailConfiguration = () => {
  const [languages, setLanguages] = useState([])
  const [orderConfirmation, setOrderConfirmation] = useState([
    {
      LanguageID: null,
      BrandID: null,
      emailType: null,
      emailSubject: null,
      emailContent: null
    }
  ])

  const fetchAllLanguages = () => {
    GET('schemas/languages').then(allLangs => {
      if (allLangs.error) return setLanguages([])
      return setLanguages(_.sortBy(allLangs, 'LanguageID'))
    })
  }

  const getOrderConfirmationEmailConfig = () => {
    GET('brands/getEmailConfigurations', { emailType: 'ORDER_CONFIRMATION' }).then(orderConfirmationEmailConfig => {
      if (orderConfirmationEmailConfig.error) return
      return setOrderConfirmation(orderConfirmationEmailConfig)
    })
  }

  useEffect(() => {
    fetchAllLanguages()
    getOrderConfirmationEmailConfig()
  }, [])

  return (
    <Container fluid>
      <CollapsibleCard startOpen header={<strong>Order confirmation</strong>}>
        <Row>
          {languages.map(lang => (
            <Col xs='6' key={`ORDER_CONFIRMATION_${lang.LanguageID}`}>
              <EmailConfigForm
                languageName={lang.languageName}
                LanguageID={lang.LanguageID}
                emailType='ORDER_CONFIRMATION'
                subject={orderConfirmation.filter(i => i.LanguageID === lang.LanguageID)[0]?.emailSubject || ''}
                content={orderConfirmation.filter(i => i.LanguageID === lang.LanguageID)[0]?.emailContent || ''}
                placeholderStrings={['{{userName}}', '{{BrandID}}', '{{ConfirmationID}}', '{{brandNickname}}', '{{brandName}}']}
              />
            </Col>
          ))}
        </Row>
      </CollapsibleCard>
    </Container>
  )
}

export const DefaultEmailConfiguration = () => {
  return <></>
}

export const EmailConfigForm = props => {
  const { languageName, LanguageID, BrandID, emailType, subject, content, placeholderStrings } = props

  const [emailSubject, setEmailSubject] = useState(subject || '')
  const [emailContent, setEmailContent] = useState(content || '')

  const saveConfiguration = () => {
    POST('brands/saveEmailConfigurations', {
      LanguageID,
      BrandID,
      emailType,
      emailSubject,
      emailContent
    }).then(response => {
      if (response.error) {
        return toast.error('Update failed', {
          autoClose: 5000,
          onClose: () => {}
        })
      }
      toast.success(`${emailType} configuration for ${languageName} is updated`, {
        autoClose: 5000,
        onClose: () => {}
      })
    })
  }

  const copyTextToClipboard = value => {
    if (!navigator.clipboard) {
      // Clipboard API not available
      return
    }
    if (window.getSelection().toString() && this.props.options.copyHightlighText) {
      return
    }
    try {
      navigator.clipboard.writeText(value)
      toast.success('Kopieret: ' + value, {
        autoClose: 3000
      })
    } catch (err) {
      // console.error('Failed to copy!', err)
    }
  }

  useEffect(() => {
    setEmailSubject(subject)
    setEmailContent(content)
  }, [subject, content, LanguageID])

  return (
    <>
      <strong>{languageName}</strong>
      <FormGroup row>
        <Col sm={12}>
          {!!placeholderStrings?.length && (
            <>
              <em>Placeholders you can click to copy and use it:</em>
              <br />
              {placeholderStrings.map((i, x) => (
                <em key={x} className='placeholderString' onClick={event => copyTextToClipboard(event.target.innerText)}>
                  {' '}
                  {i}{' '}
                </em>
              ))}
              <p />
            </>
          )}
        </Col>
        <Label for='emailSubject' sm={2}>
          {`Subject`}
        </Label>
        <Col sm={10}>
          <Input
            formNoValidate
            type='textarea'
            rows='2'
            name='emailSubject'
            id='emailSubject'
            placeholder='Subject of the email'
            value={emailSubject}
            onChange={e => {
              setEmailSubject(e.target.value)
            }}
          />
        </Col>
        <Label for='emailContent' sm={2}>
          Body
        </Label>
        <Col sm={10} style={{ marginTop: '10px' }}>
          <Input
            formNoValidate
            type='textarea'
            rows='10'
            name='emailContent'
            id='emailContent'
            placeholder='Body of the email'
            value={emailContent}
            onChange={e => {
              setEmailContent(e.target.value)
            }}
          />
        </Col>

        <div style={{ display: 'flex', flex: 0.95, justifyContent: 'flex-end', width: '90%', marginTop: 20 }}>
          <Button onClick={() => saveConfiguration()} disabled={false}>
            Save
          </Button>
          <Button onClick={() => {}} disabled={false} color='danger' className='ml-2'>
            Reset
          </Button>
        </div>
      </FormGroup>
    </>
  )
}
export default EmailConfiguration
