import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import CollapsibleCard from 'Components/collapsibleCard'
import FormField from 'Components/formField'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { Link, Redirect } from 'react-router-dom'

const { GET, POST } = Proxies

const TemplateForm = props => {
  const defaultFooterLine1 = 'Copyright 2023 MS Branding A/S'
  const defaultFooterLine2 = 'Kontakt support på'
  const blackColor = '#000000'
  const whiteColor = '#FFFFFF'
  const defaultTextColor = blackColor
  const [templateDetails, setTemplateDetails] = useState({
    footerLine1: defaultFooterLine1,
    footerLine2: defaultFooterLine2,
    footerLine3: '',
    footerTextColor: defaultTextColor,
    headerTextColor: defaultTextColor
  })
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState([])
  const [name, setName] = useState((templateDetails && templateDetails.name) || '')
  const [footerline3, setFooterLine3] = useState()
  const [TemplateID, setTemplateID] = useState(
    !!props.match.params && !isNaN(props.match.params.NotificationCampaignTemplateID)
      ? Number(props.match.params.NotificationCampaignTemplateID)
      : null
  )
  const [status, setStatus] = useState('LOADING')

  const fetchBrands = useCallback(() => {
    GET('brands/getAssociatedBrands').then(responseJSON => {
      if (responseJSON.error) return setBrands([])
      if (responseJSON && responseJSON.length === 1) {
        setTemplateDetails({
          ...templateDetails,
          BrandID: Number(responseJSON[0].BrandID),
          brandName: responseJSON[0].brandName,
          brandSlug: responseJSON[0].brandSlug,
          contactEmail: responseJSON.contactEmail || null
        })
      }
      return setBrands(
        responseJSON.map(item => ({ BrandID: item.BrandID, brandName: item.brandName, contactEmail: item.contactEmail, brandSlug: item.brandSlug }))
      )
    })
  }, [])

  const fetchTemplateDetails = useCallback(() => {
    if (!TemplateID) return
    GET('notificationcampaigntemplate/fetch', { NotificationCampaignTemplateID: Number(TemplateID) }).then(responseJSON => {
      if (responseJSON.error) return setTemplateDetails(null)
      setName(responseJSON.name || name || '')
      setFooterLine3(JSON.parse(responseJSON.metadata).footerLine3)
      return setTemplateDetails(responseJSON)
    })
  }, [TemplateID])

  const submitTemplate = () => {
    setLoading(true)
    const metadata = {
      baseTemplate: 'C001', // fixed base template for campagin email
      headerLeftText: templateDetails.brandName || '',
      headerRightImage: templateDetails.brandLogo || '',
      headerColor: templateDetails.headerColor || '',
      bannerImage: templateDetails.bannerImageURL || '',
      footerColor: templateDetails.footerColor || '',
      footerLine1: templateDetails.footerLine1 || defaultFooterLine1 || '',
      footerLine2: templateDetails.footerLine2 || defaultFooterLine2 || '',
      footerLine3: footerline3 ? footerline3 : templateDetails.brandEmail || `support+${templateDetails.brandSlug}@upgrader.club`,
      footerTextColor: templateDetails.footerTextColor || defaultTextColor,
      headerTextColor: templateDetails.headerTextColor || defaultTextColor
    }
    const templateInfo = {
      ...templateDetails,
      metadata,
      name
    }

    POST(`notificationcampaigntemplate/replace`, { ...templateInfo }).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        setStatus('FAILED')
      } else {
        setTemplateDetails(responseJSON)
        setStatus('SUCCESS')
      }
    })
  }

  const resetTemplate = () => {
    if (!TemplateID) {
      setTemplateDetails({})
      setName('')
    }
    window.location.assign('/campaign/template')
  }

  function CampaignRemoveTemplate(TemplateID) {
    Proxies.POST('notificationcampaigntemplate/removeTemplate', { NotificationCampaignTemplateID: Number(TemplateID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        toast.success(`Template removed successfully`, {
          autoClose: 2000,
          onClose: () => {
            return <Redirect to={'/campaign/template'} />
          }
        })
      }
    })
  }

  useEffect(() => {
    setTemplateID(
      !!props.match.params && !!props.match.params.NotificationCampaignTemplateID ? Number(props.match.params.NotificationCampaignTemplateID) : null
    )
  }, [props.match.params])

  const uploadLoginImageURL = (data, fieldName) => {
    let file = data.target.files[0]
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        setTemplateDetails({ ...templateDetails, [fieldName]: responseJSON.imageOriginSource ? responseJSON.imageOriginSource : '' })
      }
    })
  }

  useEffect(() => {
    fetchBrands()
    fetchTemplateDetails()
  }, [TemplateID])

  if (status === 'SUCCESS') {
    toast.success(`Template ${TemplateID ? 'updated' : 'created'} successfully`, {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
    return <Redirect to='/campaign/template' />
  }

  if (status === 'FAILED') {
    toast.error('Action failed', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
  }

  return (
    <>
      <CollapsibleCard
        startOpen={true}
        header={
          <strong>
            {!templateDetails.NotificationCampaignTemplateID ? 'Opret en ny template' : 'Rediger template'}
            <Button className='float-right' style={{ marginLeft: '16px' }} onClick={submitTemplate}>
              {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem ændringer'}
            </Button>
            <Button color='dark' className='float-right' style={{ marginLeft: '16px' }} onClick={resetTemplate}>
              Annuller ændringer
            </Button>
            <Button
              color='danger'
              tag={Link}
              to={'/campaign/template'}
              className='float-right'
              style={{ marginLeft: '16px' }}
              onClick={() => {
                if (confirm('Are you sure you want to delete?')) {
                  CampaignRemoveTemplate(templateDetails.NotificationCampaignTemplateID)
                }
              }}
            >
              <FontAwesomeIcon icon='trash' />
              &nbsp;&nbsp;Slet template
            </Button>
          </strong>
        }
      >
        <Form>
          {/* Select Brand */}
          <FormGroup>
            <Row>
              <Col xs='3'>
                <Label for='BrandID'> Brand </Label>
              </Col>
              {brands && brands.length !== 1 && (
                <Col xs='6'>
                  <Input
                    className={templateDetails.BrandID ? 'is-valid' : 'is-invalid'}
                    required
                    type='select'
                    name='BrandID'
                    value={templateDetails.BrandID ? Number(templateDetails.BrandID) : ''}
                    id='BrandID'
                    onChange={e => {
                      let brand = brands.filter(brand => Number(brand.BrandID) === Number(e.target.value))
                      setFooterLine3()
                      setTemplateDetails({
                        ...templateDetails,
                        BrandID: Number(e.target.value),
                        brandName: (brand && brand.length && brand[0].brandName) || '',
                        brandSlug: (brand && brand.length && brand[0].brandSlug) || '',
                        brandEmail: (brand && brand.length && brand[0].contactEmail) || ''
                      })
                    }}
                  >
                    <option value=''>Click to Select</option>
                    {brands.map((e, i) => (
                      <option value={Number(e.BrandID)} key={i}>
                        {e.BrandID} - {e.brandName}
                      </option>
                    ))}
                  </Input>
                </Col>
              )}
              {brands && brands.length === 1 && (
                <Col xs='6'>
                  <Input
                    className={templateDetails.BrandID ? 'is-valid' : 'is-invalid'}
                    required
                    type='text'
                    name='BrandID'
                    value={brands[0] ? `${brands[0].BrandID} - ${brands[0].brandName}` : ''}
                    id='BrandID'
                  ></Input>
                </Col>
              )}
            </Row>
          </FormGroup>

          {/* Title */}
          <FormField
            required
            fieldWidth='6'
            style={{ marginBottom: 24 }}
            data={{ name }}
            field={'name'}
            name='Title'
            onChange={e => setName(e.target.value || '')}
          />

          {/* Header Text Color */}
          <Row>
            <Col xs='3'>
              <Label htmlFor='headerTextColor'> Header text color </Label>
            </Col>
            <Col xs='6' style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
              <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <input
                  style={{ cursor: 'pointer' }}
                  type='radio'
                  id='headerTextColorBlack'
                  name='headerTextColor'
                  value={blackColor}
                  checked={!templateDetails.headerTextColor || templateDetails.headerTextColor === blackColor}
                  onChange={e => {
                    setTemplateDetails({ ...templateDetails, headerTextColor: e.target.value || blackColor })
                  }}
                />
                <label htmlFor='headerTextColorBlack' style={{ margin: 0, cursor: 'pointer' }}>
                  Black
                </label>
              </div>

              <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <input
                  style={{ cursor: 'pointer' }}
                  type='radio'
                  id='headerTextColorWhite'
                  name='headerTextColor'
                  value={whiteColor}
                  checked={templateDetails.headerTextColor === whiteColor}
                  onChange={e => {
                    setTemplateDetails({ ...templateDetails, headerTextColor: e.target.value || whiteColor })
                  }}
                />
                <label htmlFor='headerTextColorWhite' style={{ margin: 0, cursor: 'pointer' }}>
                  White
                </label>
              </div>
            </Col>
          </Row>

          {/* Footer Text Color */}
          <Row>
            <Col xs='3'>
              <Label for='footerTextColor'> Footer text color </Label>
            </Col>
            <Col xs='6' style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
              <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <input
                  style={{ cursor: 'pointer' }}
                  type='radio'
                  id='footerTextColorBlack'
                  name='footerTextColor'
                  value={blackColor}
                  checked={!templateDetails.footerTextColor || templateDetails.footerTextColor === blackColor}
                  onChange={e => {
                    setTemplateDetails({ ...templateDetails, footerTextColor: e.target.value || blackColor })
                  }}
                />
                <label htmlFor='footerTextColorBlack' style={{ margin: 0, cursor: 'pointer' }}>
                  Black
                </label>
              </div>

              <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <input
                  style={{ cursor: 'pointer' }}
                  type='radio'
                  id='footerTextColorWhite'
                  name='footerTextColor'
                  value={whiteColor}
                  checked={templateDetails.footerTextColor === whiteColor}
                  onChange={e => {
                    setTemplateDetails({ ...templateDetails, footerTextColor: e.target.value || whiteColor })
                  }}
                />
                <label htmlFor='footerTextColorWhite' style={{ margin: 0, cursor: 'pointer' }}>
                  White
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs='9'>
              <div style={{ border: '1px solid #BFBFBF' }}>
                <div
                  style={{
                    backgroundColor: templateDetails && templateDetails.headerColor ? templateDetails.headerColor : '#999999',
                    height: 100,
                    paddingLeft: 50,
                    paddingRight: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                    <Input
                      name='brandLogo'
                      field='brandLogo'
                      id='brandLogo'
                      type='file'
                      style={{ display: 'none' }}
                      onChange={e => {
                        uploadLoginImageURL(e, 'brandLogo')
                      }}
                      accept='image/*'
                    />
                    {!templateDetails.brandLogo ? (
                      <Button
                        color='light'
                        style={{ marginRight: '16px' }}
                        htmlFor='brandLogo'
                        onClick={() => document.getElementById('brandLogo').click()}
                      >
                        Tilføj brandlogo
                      </Button>
                    ) : (
                      <img
                        src={templateDetails && templateDetails.brandLogo}
                        alt={templateDetails && templateDetails.brandLogo}
                        style={{
                          height: '50px',
                          width: 'auto',
                          marginRight: 16,
                          cursor: 'pointer'
                        }}
                        htmlFor='brandLogo'
                        onClick={() => document.getElementById('brandLogo').click()}
                      />
                    )}
                    <Input
                      type='color'
                      name='headerColor'
                      id='headerColor'
                      style={{ width: 60, height: 50 }}
                      value={templateDetails && templateDetails.headerColor ? templateDetails.headerColor : '#999999'}
                      onChange={e => {
                        setTemplateDetails({ ...templateDetails, headerColor: e.target.value })
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: 300, backgroundColor: blackColor, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                  <Input
                    name='bannerImageURL'
                    field='bannerImageURL'
                    id='bannerImageURL'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={e => {
                      uploadLoginImageURL(e, 'bannerImageURL')
                    }}
                    accept='image/*'
                  />
                  {!templateDetails.bannerImageURL ? (
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                      <Button
                        color='light'
                        style={{ marginBottom: '16px' }}
                        htmlFor='img'
                        onClick={() => document.getElementById('bannerImageURL').click()}
                      >
                        Tilføj billede
                      </Button>
                      <div style={{ fontSize: 14, color: 'white' }}>Hvis du fjerner billedet, vises dette felt ikke i mailen</div>
                    </div>
                  ) : (
                    <img
                      src={templateDetails && templateDetails.bannerImageURL}
                      alt={templateDetails && templateDetails.bannerImageURL}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                      htmlFor='img'
                      onClick={() => document.getElementById('bannerImageURL').click()}
                    />
                  )}
                </div>
                <div style={{ paddingLeft: '25%', paddingRight: '25%', paddingTop: 50, paddingBottom: 50 }}>
                  <div style={{ fontSize: 34, color: '#AFAFAF', fontWeight: 'bold', marginBottom: 24 }}>{name || 'Overskrift'}</div>
                  <p style={{ marginBottom: 24, color: '#9B9B9B' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit non ab alias enim incidunt sunt nemo officia iusto
                    voluptatum sequi unde quos sed nesciunt, libero adipisci. Quaerat nobis cupiditate voluptates.
                  </p>
                  <p style={{ marginBottom: 50, color: '#9B9B9B' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit non ab alias enim incidunt sunt nemo officia iusto
                    voluptatum sequi unde quos sed nesciunt, libero adipisci. Quaerat nobis cupiditate voluptates.
                  </p>
                  <div>Venlig hilsen</div>
                  <div>{templateDetails && templateDetails.brandName}</div>
                </div>
                <div
                  style={{
                    height: 200,
                    position: 'relative',
                    backgroundColor: templateDetails && templateDetails.footerColor ? templateDetails.footerColor : '#333333',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div style={{ fontSize: 15, color: templateDetails.footerTextColor, marginBottom: 16 }}>
                    {templateDetails.footerLine1 || defaultFooterLine1}
                  </div>
                  <div style={{ fontSize: 14, color: templateDetails.footerTextColor }}>{templateDetails.footerLine2 || defaultFooterLine2}</div>
                  <div style={{ fontSize: 14, color: templateDetails.footerTextColor }}>
                    {footerline3
                      ? footerline3
                      : templateDetails.brandEmail
                      ? templateDetails.brandEmail
                      : `support+${templateDetails.brandSlug}@upgrader.club`}
                  </div>
                  <Input
                    type='color'
                    name='footerColor'
                    id='footerColor'
                    style={{ width: 60, height: 50, position: 'absolute', right: 20 }}
                    value={templateDetails && templateDetails.footerColor ? templateDetails.footerColor : '#333333'}
                    onChange={e => {
                      setTemplateDetails({ ...templateDetails, footerColor: e.target.value })
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </CollapsibleCard>
    </>
  )
}

export default TemplateForm
