import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { CategoryStore, CategoryActions, STATUS } from 'Stores/categoryStore'
import CategoryForm from 'Modules/category/categoryForm'

export default class CategoryEdit extends Component {
  constructor(props) {
    super(props)
    this.stores = [CategoryStore]
    this.storeKeys = ['loadingState']
  }
  componentDidMount() {
    CategoryActions.getCategoryDetails(this.props.match.params.CategoryID)
  }
  componentDidUpdate() {
    if (this.state.loadingState === STATUS.FETCHING_CATEGORY_FAILED) {
      toast.error('FETCHING CATEGORY FAILED', {
        autoClose: 7500,
        onClose: () => {
          CategoryActions.clearToast()
        }
      })
      CategoryActions.clearToast()
    }

    if (this.state.loadingState === STATUS.CATEGORY_FAILED) {
      toast.error('UPDATE CATEGORY FAILED', {
        autoClose: 3000,
        onClose: () => {
          CategoryActions.clearToast()
        }
      })
      CategoryActions.clearToast()
    }
    if (this.state.loadingState === STATUS.CATEGORY_SUCCESS) {
      toast.success('UPDATE CATEGORY SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          CategoryActions.clearToast()
        }
      })
      CategoryActions.clearToast(STATUS.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <CategoryForm {...this.props} />
      </React.Fragment>
    )
  }
}
