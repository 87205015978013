import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'
import moment from 'moment'

function PointExpireCommunication(props) {
  const [pointExpireCommunications, setPointExpireCommunications] = useState([])
  const [loading, setLoading] = useState(false)
  const { UserID } = props.match.params
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [emailMessage, setEmailMessage] = useState(null)

  useEffect(() => {
    setLoading(true)
    if (UserID) {
      getCommunicationListByUserID()
    } else {
      getCommunicationList()
    }
  }, [])

  function getCommunicationList() {
    Proxies.GET('communications/pointExpiryUsersEmailSMS').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setPointExpireCommunications(responseJSON)
      }
      setLoading(false)
    })
  }

  function getCommunicationListByUserID() {
    Proxies.GET('communications/pointExpiryUsersEmailSMSByUserID', { UserID: UserID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setPointExpireCommunications(responseJSON)
      }
      setLoading(false)
    })
  }

  function openEmail(comm) {
    setOpenEmailModal(true)
    setEmailMessage({ body: comm.communicationMessage, subject: comm.communicationSubject, timeInsert: comm.timeInsert })
  }

  return (
    <>
      <CollapsibleCard startOpen header={<strong>Emails</strong>}>
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD HH:mm:ss') : ''
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Button>
                        <FontAwesomeIcon icon='envelope-open' onClick={() => openEmail(e)} />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              UserID: 'ID',
              userName: 'Navn',
              userUID: 'Uid',
              userPhone: 'Telefon',
              userEmail: 'Email',
              timeInsert: 'Sent dato'
            }}
            data={pointExpireCommunications && !!pointExpireCommunications.length && pointExpireCommunications}
          />
        )}
      </CollapsibleCard>
      <Modal isOpen={openEmailModal} toggle={setOpenEmailModal} size='xl'>
        <ModalHeader toggle={() => setOpenEmailModal(!openEmailModal)}>
          <small>{emailMessage ? moment(emailMessage.timeInsert).format('YYYY-MM-DD HH:mm:ss') : ''}</small>{' '}
          {emailMessage ? emailMessage.subject : ''}
        </ModalHeader>
        <ModalBody>{emailMessage ? <div dangerouslySetInnerHTML={{ __html: emailMessage.body }}></div> : null}</ModalBody>
      </Modal>
    </>
  )
}

export default PointExpireCommunication
