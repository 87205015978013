import React from 'react'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import BarChart from 'Components/dashboard/charts/BarChart.js'
import styled from 'styled-components'
import _ from 'lodash'
import useDashboardData from 'Components/dashboard/useDashboardData.js'

const BarChartWithSpacer = styled(BarChart)`
  margin-top: 28px; // Spacer while what the dashboard is settles, maybe a better solution is needed later
`

export default function OrdersYearlyChart() {
  const [data, loadingState] = useDashboardData('reports/all-orders-graph')

  return (
    <DashboardComponent header={'Ordrer pr. år'} state={loadingState}>
      {loadingState === DASHBOARD_COMPONENT_STATE.SUCCESS && (
        <BarChartWithSpacer
          data={_.map(_.get(data, 'yearlyResults'), ({ year, orderCount }, index) => ({
            year: index === 0 ? `År til dato ${year}` : `Ultimo ${year}`,
            Ordrer: orderCount
          }))}
          keys={['Ordrer']}
          indexBy={'year'}
          bottomTickRotation={-20}
          legends={[]}
          axisLabel={'Ordrer'}
        />
      )}
    </DashboardComponent>
  )
}
