import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { HofActions, HofStore, STATE } from 'Stores/hofStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { LoginStore } from 'Stores/loginStore'
import styled from 'styled-components'

const StyledAnchor = styled.a`
  cursor: pointer !important;
`

export default class HOFList extends Component {
  constructor(props) {
    super(props)
    this.stores = [HofStore, LoginStore]
    this.storeKeys = ['status', 'hofList', 'settings', 'isAdminAccess', 'HofFilterValue', 'showActiveHof', 'showInactiveHof']
  }

  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.brandName === 'MSB' &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userPrivilege === 'MANAGE' &&
      this.state.settings.userRole === 'System' &&
      !this.state.isAdminAccess
    ) {
      HofActions.giveAccess()
    }
    HofActions.getHOFData()
  }

  componentDidUpdate() {
    if (this.state.status === STATE.FETCHING_HOFLIST_FAILED) {
      toast.error('FETCHING HALL OF FAME FAILED', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }
    if (this.state.status === STATE.HOF_DELETE_ERROR) {
      toast.error('HALL OF FAME DELETE FAILED', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }
    if (this.state.status === STATE.HOF_DELETE_SUCCESS) {
      toast.success(this.state.isAdminAccess ? `SUCCESSFULLY DEACTIVATED` : 'SUCCESSFULLY DELETED', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
      HofActions.getHOFData()
    }
    if (this.state.status === STATE.ACTIVATE_HOF_SUCCESS) {
      toast.success('SUCCESSFULLY ACTIVATED', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
      HofActions.getHOFData()
    }
  }

  render() {
    if (this.state.status === STATE.FETCHING_HOFLIST) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Hall Of Fame
            {this.state.isAdminAccess && (
              <ButtonGroup className='float-right'>
                <Button outline color='success' onClick={HofActions.toggleActiveHof} active={this.state.showActiveHof}>
                  Active
                </Button>
                <Button outline color='danger' onClick={HofActions.toggleInActiveHof} active={this.state.showInActiveHof}>
                  In-Active
                </Button>
              </ButtonGroup>
            )}
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={
            this.state.isAdminAccess && this.state.hofList && this.state.HofFilterValue !== 'both' && !!this.state.hofList.length
              ? this.state.hofList.filter(hof => hof.removed === (this.state.HofFilterValue === 'inactive' ? 1 : 0))
              : this.state.hofList
          }
          headers={{
            HalloffameID: 'HOF ID',
            BrandID: 'Brand ID',
            ...(this.state.isAdminAccess && { brand: 'Brand' }),
            UserID: 'User ID',
            moduleName: 'Name',
            moduleDescription: 'Description',
            moduleQuery: 'Module Query',
            poolOrder: 'Pool Order',
            showProfileImages: 'Show Profile Images',
            timeInsert: 'Time Insert',
            timeStart: 'Time Start',
            timeEnd: 'Time End',
            groups: 'Groups'
          }}
          options={{
            startSort: {
              dir: 'DESC',
              column: 'HalloffameID'
            },
            fieldFunctions: {
              moduleQuery: e => {
                let acc = []
                if (!e.moduleQueryReadMore && !e.moduleQueryReadLess) {
                  return e.moduleQuery
                }
                if (e.moduleQueryReadMore && !e.moduleQueryReadLess) {
                  acc.push(e.moduleQuery.slice(0, 100) + '...')
                  acc.push(
                    <StyledAnchor key={e.HalloffameID} onClick={() => HofActions.toggleQueryContent(e)}>
                      Show More
                    </StyledAnchor>
                  )
                  return acc
                }
                if (!e.moduleQueryReadMore && e.moduleQueryReadLess) {
                  acc.push(e.moduleQuery)
                  acc.push(
                    <StyledAnchor key={e.HalloffameID} onClick={() => HofActions.toggleQueryContent(e)}>
                      Show Less
                    </StyledAnchor>
                  )
                  return acc
                }
              },
              brand: e => {
                return e.brand ? e.brand.brandName : ''
              },
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
              },
              timeStart: e => {
                return e.timeStart ? moment(e.timeStart).format('DD-MM-YYYY HH:mm:ss') : ''
              },
              timeEnd: e => {
                return e.timeEnd ? moment(e.timeEnd).format('DD-MM-YYYY HH:mm:ss') : ''
              },
              groups: e => {
                let groups = e.groups ? e.groups : []
                return (
                  <React.Fragment>
                    {groups.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          {e.moduleName}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )
              }
            },
            fieldExportFunctions: {
              groups: e => {
                let groups = e.groups ? e.groups : []
                let acc = []
                {
                  groups &&
                    groups.map(e => {
                      acc.push(e.moduleName)
                    })
                }
                return acc.join('\n')
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              },
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'view' + e.HalloffameID}>Vis</Tooltip>
                    <Button
                      tag={Link}
                      disabled={e.removed === 1 ? true : false}
                      to={'/hof/' + e.HalloffameID + '/elements'}
                      id={'view' + e.HalloffameID}
                    >
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    <Tooltip target={'edit' + e.HalloffameID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/hof/' + e.HalloffameID} id={'edit' + e.HalloffameID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'copy' + e.HalloffameID}>Kopier</Tooltip>
                    <Button
                      id={'copy' + e.HalloffameID}
                      onClick={() => {
                        HofActions.copyHOF(e, this.props)
                      }}
                    >
                      <FontAwesomeIcon icon='copy' />
                    </Button>
                    {this.state.isAdminAccess ? (
                      <React.Fragment>
                        {e.removed === 0 && (
                          <React.Fragment>
                            <Tooltip target={'deactivate' + e.HalloffameID}>Deaktivér</Tooltip>
                            <Button
                              id={'deactivate' + e.HalloffameID}
                              onClick={() => {
                                if (confirm('Vil du deaktivere denne HOF?')) {
                                  HofActions.deleteHOF(e.HalloffameID)
                                }
                              }}
                            >
                              <FontAwesomeIcon icon='user-check' />
                            </Button>
                          </React.Fragment>
                        )}
                        {e.removed === 1 && (
                          <React.Fragment>
                            <Tooltip target={'actiavte' + e.HalloffameID}>Aktivér</Tooltip>
                            <Button
                              id={'actiavte' + e.HalloffameID}
                              color='danger'
                              onClick={() => {
                                if (confirm('Vil du aktivere denne HOF?')) {
                                  HofActions.activateHOF(e.HalloffameID)
                                }
                              }}
                            >
                              <FontAwesomeIcon icon='user-slash' />
                            </Button>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Tooltip target={'delete' + e.HalloffameID}>Slet</Tooltip>
                        <Button
                          id={'delete' + e.HalloffameID}
                          color='danger'
                          onClick={() => {
                            if (confirm('Vil du slette denne HOF?')) {
                              HofActions.deleteHOF(e.HalloffameID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='trash' />
                        </Button>
                      </React.Fragment>
                    )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
