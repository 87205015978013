import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/customerSalesClaimActions'

export const CustomerSalesClaimSlice = createSlice({
  name: 'customerSalesClaim',
  initialState: {
    overviewLoadingState: false,
    customerSalesClaims: [],
    loadingState: false
  },
  reducers: {
    fetchCustomerSalesClaimsRequest: state => {
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_SALES_CLAIMS
    },
    fetchCustomerSalesClaimsFailed: state => {
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_SALES_CLAIMS_FAILED
    },
    fetchCustomerSalesClaimsSuccess: (state, action) => {
      state.customerSalesClaims = action.payload
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_SALES_CLAIMS_SUCCESS
    },
    isSavingCustomerSalesClaim: state => {
      state.loadingState = STATUS.IS_SAVING
    },
    clearToast: (state, action) => {
      state.loadingState = action.payload ? action.payload : null
      state.overviewLoadingState = false
    },
    statusCustomerSalesClaimFailure: state => {
      state.overviewLoadingState = STATUS.STATUS_CUSTOMER_SALES_CLAIM_FAILED
    },
    statusCustomerSalesClaimSuccess: state => {
      state.overviewLoadingState = STATUS.STATUS_CUSTOMER_SALES_CLAIM_SUCCESS
    }
  }
})

export const {
  fetchCustomerSalesClaimsRequest,
  fetchCustomerSalesClaimsFailed,
  fetchCustomerSalesClaimsSuccess,
  statusCustomerSalesClaimFailure,
  clearToast,
  statusCustomerSalesClaimSuccess,
  isSavingCustomerSalesClaim
} = CustomerSalesClaimSlice.actions

export default CustomerSalesClaimSlice.reducer
