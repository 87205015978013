/* eslint-disable eqeqeq */
import React from 'react'
import Reflux from 'reflux'
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoginActions, LoginStore } from 'Stores/loginStore'

export default class Login extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.userIdentifier = null
    this.userValidation = null
  }

  componentDidUpdate() {
    if (this.userIdentifier && !this.userIdentifier.disabled) {
      this.userIdentifier.focus()
    }
    if (this.userValidation && !this.userValidation.disabled) {
      this.userValidation.focus()
    }
  }

  inputIsEmail = () => /^[\w-+.]+@[\w-+]+\.[a-zA-Z]{2,4}$/.test(this.state.phoneNumber)

  createErrorMessage = () => {
    const { tokenError, loginErrorNoUser, loginErrorUniqueUser } = this.state
    if (tokenError)
      return (
        <p>
          Vi kunne ikke finde engangskoden. <br />
          Er den indtastet korrekt, og er det den seneste kode du har modtaget? <br />
          Husk du har kun 3 forsøg
        </p>
      )

    if (loginErrorNoUser && this.inputIsEmail())
      return (
        <p>
          Den indtastede e-mail kunne ikke findes.
          <br />
          Er den blevet tastet korrekt? Eller er du evt. blevet oprettet med en anden e-mail adresse, f.eks. din arbejdsmail eller private mail?
        </p>
      )

    if (loginErrorNoUser) {
      return (
        <p>
          Det indtastede mobilnummer kunne ikke findes.
          <br />
          Er det blevet tastet korrekt? Eller er du evt. blevet oprettet med et andet mobilnummer?
        </p>
      )
    }
    if (loginErrorUniqueUser && this.inputIsEmail())
      return (
        <p>
          Det er ikke muligt at logge ind med den indtastede email.
          <br />
          Er den blevet tastet korrekt? Eller er du evt. blevet oprettet med en anden mail?
        </p>
      )

    if (loginErrorUniqueUser) {
      return (
        <p>
          Det er ikke muligt at logge ind med det indtastede mobilnummer.
          <br />
          Er det blevet tastet korrekt? Eller er du evt. blevet oprettet med et andet mobilnummer?
        </p>
      )
    }

    return ''
  }

  render() {
    if (this.state.sessionKey) {
      return false
    }
    if (this.state.brandData == 'NOBRAND') {
      return <div>No brand associated</div>
    }
    if (!this.state.BrandID) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let submitAction = () => {
      return false
    }
    let userIdentifierDisabled = true
    let userValidationDisabled = true
    let submitText = '...'
    if (this.state.showPhoneNumberInput) {
      submitAction = LoginActions.authenticationRequested
      userIdentifierDisabled = false
      userValidationDisabled = true
      submitText = 'Få valideringskode'
    }
    if (!this.state.showPhoneNumberInput && !this.state.sessionKey) {
      submitAction = LoginActions.tokenValidationRequested
      userIdentifierDisabled = true
      userValidationDisabled = false
      submitText = 'Log ind'
    }
    return (
      <form
        className='app flex-row align-items-center'
        onSubmit={e => {
          submitAction()
          e.preventDefault()
        }}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col md='6'>
              <CardGroup>
                <Card className='p-4'>
                  <CardBody>
                    <h1>Log ind</h1>
                    <InputGroup className='mb-3'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <FontAwesomeIcon icon='user' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type='text'
                        placeholder='Telefonnummer eller email'
                        innerRef={e => {
                          this.userIdentifier = e
                        }}
                        autoFocus={!userIdentifierDisabled}
                        disabled={userIdentifierDisabled}
                        onChange={e => {
                          LoginActions.phoneNumberUpdated(e.target.value)
                        }}
                        value={this.state.phoneNumber}
                        data-cy='login.user'
                      />
                    </InputGroup>
                    <InputGroup className='mb-4'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <FontAwesomeIcon icon='lock' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type='number'
                        manglenght={6}
                        max={999999}
                        placeholder='Valideringskode'
                        innerRef={e => {
                          this.userValidation = e
                        }}
                        autoFocus={!userValidationDisabled}
                        disabled={userValidationDisabled}
                        onChange={e => {
                          LoginActions.tokenUpdated(e.target.value)
                        }}
                        value={this.state.token}
                        data-cy='login.pass'
                      />
                    </InputGroup>
                    <Row>
                      <Col xs='12' className='text-center' data-cy='login.errorMessage'>
                        {this.createErrorMessage()}
                      </Col>
                      <Col xs='6'>
                        <Button color='primary' className='px-4' data-cy='login.submit'>
                          {submitText}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </form>
    )
  }
}

export class Logout extends Reflux.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    LoginActions.signOut()
  }
  render() {
    return null
  }
}
