/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { CompanyWalletActions, CompanyWalletStore } from 'Stores/companyWalletStore'
import { toast } from 'react-toastify'

export default class CompanyWallet extends Component {
  constructor(props) {
    super(props)
    this.store = CompanyWalletStore
    this.storeKeys = ['companyWalletSum']
  }
  componentDidMount() {
    CompanyWalletActions.getWalletSum()
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardHeader>
            <h1>Nuværende saldo : {this.state.companyWalletSum}</h1>
          </CardHeader>
        </Card>
        <TransferData />
        <Users />
        <Wallet />
      </React.Fragment>
    )
  }
}

class TransferData extends Component {
  constructor(props) {
    super(props)
    this.store = CompanyWalletStore
    this.storeKeys = ['amount', 'transferMessage']
  }
  render() {
    return (
      <Card>
        <CardHeader>Data</CardHeader>
        <CardBody>
          <ListGroup>
            <ListGroupItem>
              <Label htmlFor='transferMessage'>Besked</Label>
              <Input
                type='text'
                value={this.state.transferMessage}
                name='transferMessage'
                id='transferMessage'
                onChange={e => {
                  CompanyWalletActions.updateMessage(e.target.value)
                }}
              />
            </ListGroupItem>
            <ListGroupItem>
              <Label htmlFor='Amount'>Point</Label>
              <Input
                type='number'
                value={this.state.amount || ''}
                placeholder='0'
                name='Amount'
                id='Amount'
                onChange={e => {
                  CompanyWalletActions.updateAmount(e.target.value)
                }}
              />
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    )
  }
}

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usersOpen: false
    }
    this.store = CompanyWalletStore
    this.storeKeys = ['users', 'userFilter', 'usersLoading', 'amount', 'transferMessage', 'transferringPoints', 'showSuccessToast', 'showErrorToast']
  }
  filterUsers() {
    if (this.state.users) {
      let filteredUsers = this.state.users.filter(user => {
        let userName = user.userName.indexOf(this.state.userFilter) !== '-1'
        let userCompany = user.userCompany.indexOf(this.state.userFilter) !== '-1'
        let userUID = user.userUID.indexOf(this.state.userFilter) !== '-1'
        let userCompanyUID = user.userCompanyUID.indexOf(this.state.userFilter) !== '-1'
        return userName || userCompany || userUID || userCompanyUID
      })
      return filteredUsers
    }
    return []
  }
  toggleUsers() {
    this.setState({ usersOpen: !this.state.usersOpen })
  }
  componentDidUpdate() {
    if (this.state.showSuccessToast) {
      toast.success('Point er sendt', {
        autoClose: 7500,
        onClose: () => {
          CompanyWalletActions.clearToast()
        }
      })
      CompanyWalletActions.clearToast()
    }
    if (this.state.showErrorToast) {
      toast.error('Der opstod en fejl ved pointoverførsel', {
        autoClose: 7500,
        onClose: () => {
          CompanyWalletActions.clearToast()
        }
      })
      CompanyWalletActions.clearToast()
    }
  }
  render() {
    let users = this.filterUsers()
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <FontAwesomeIcon fixedWidth icon={this.state.usersOpen ? 'times' : 'bars'} onClick={this.toggleUsers.bind(this)} />{' '}
              <strong>Brugere</strong>
            </Col>
            <Col>
              {this.state.usersOpen && (
                <InputGroup className='float-right'>
                  <Input
                    type='text'
                    id='filter'
                    name='filter'
                    placeholder='Filtrer'
                    value={this.state.userFilter}
                    onChange={e => {
                      CompanyWalletActions.updateUserFilter(e.target.value)
                    }}
                  />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText>
                      <FontAwesomeIcon icon='search' />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Col>
          </Row>
        </CardHeader>
        <Collapse isOpen={this.state.usersOpen}>
          <CardBody>
            {this.state.usersLoading && <FontAwesomeIcon icon='spinner' spin />}
            {this.state.userFilter.length >= 4 && (
              <ListGroup>
                {users.map((e, key) => {
                  return (
                    <ListGroupItem key={key}>
                      {e.userName}, {e.userCompany} ({e.userCompanyUID}) {e.userPointsComputed} points{' '}
                      <Badge color='success'>{e.userSupervisor}</Badge> {e.userUID}
                      <Button
                        disabled={!Number.isInteger(this.state.amount) || !this.state.transferMessage}
                        onClick={() => {
                          CompanyWalletActions.transfer(e.UserID)
                        }}
                      >
                        {this.state.transferringPoints ? <FontAwesomeIcon icon='spinner' spin /> : 'Send/Fjern Point'}
                      </Button>
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            )}
            {this.state.userFilter.length < 4 && 'Du skal skrive minimum 4 tegn i søgefeltet'}
          </CardBody>
        </Collapse>
      </Card>
    )
  }
}

class Wallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      walletOpen: false
    }
    this.store = CompanyWalletStore
    this.storeKeys = ['wallet', 'walletLoading']
  }
  toggleWallet() {
    if (!this.state.walletOpen && this.state.wallet.length == 0) {
      CompanyWalletActions.getWalletData()
    }
    this.setState({ walletOpen: !this.state.walletOpen })
  }
  render() {
    let wallet = this.state.wallet
    return (
      <Card>
        <CardHeader>
          <FontAwesomeIcon fixedWidth icon={this.state.walletOpen ? 'times' : 'bars'} onClick={this.toggleWallet.bind(this)} />{' '}
          <strong>Linier i wallet</strong>
        </CardHeader>
        <Collapse isOpen={this.state.walletOpen}>
          <CardBody>
            {this.state.walletLoading && <FontAwesomeIcon icon='spinner' spin />}
            {!this.state.walletLoading && (
              <ListGroup>
                {wallet.map((e, key) => {
                  return (
                    <ListGroupItem key={key}>
                      {e.user ? e.user.userName : 'Systemet'} har {moment(e.timeInsert).format('DD-MM-YYYY HH:mm')} overført{' '}
                      {0 - e.walletAmountPoints} point til {e.OriginalWallet && e.OriginalWallet.user && e.OriginalWallet.user.userName}(
                      {e.OriginalWallet && e.OriginalWallet.user && e.OriginalWallet.user.userUID}) med beskeden {e.walletNote}
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            )}
          </CardBody>
        </Collapse>
      </Card>
    )
  }
}
