import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { WalletActions, WalletStore } from 'Stores/walletStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Card, CardHeader, CardBody, Table, Input } from 'reactstrap'
import moment from 'moment'
const filterFields = {
  WalletID: 'WalletID',
  BrandID: 'Brand ID',
  BatchID: 'Batch',
  brandName: 'Brand',
  userName: 'Name',
  walletNote: 'Note',
  walletAmountPoints: 'Points',
  vaultSlug: 'Vault Slug',
  timeInsert: 'Date'
}
const operators = {
  WalletID: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'],
  BrandID: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'],
  BatchID: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'],
  brandName: ['LIKE', 'EXACT'],
  userName: ['LIKE', 'EXACT'],
  walletNote: ['LIKE', 'EXACT'],
  walletAmountPoints: ['LIKE', 'EXACT'],
  vaultSlug: ['LIKE', 'EXACT'],
  timeInsert: ['LIKE', 'EXACT']
}
export default class WalletList extends Component {
  constructor(props) {
    super(props)
    this.stores = [WalletStore]
    this.storeKeys = [
      'walletsData',
      'start',
      'limit',
      'fetchingWalletsInfo',
      'fetchingWalletsError',
      'advanceSearchFilters',
      'showActiveWallets',
      'showInActiveWallets',
      'walletFilterValue',
      'totalCount',
      'appliedFilters'
    ]
  }

  componentDidMount() {
    WalletActions.getTotalWalletDetails()
  }

  componentDidUpdate() {
    if (this.state.fetchingWalletsError) {
      toast.error('FETCHING WALLETS FAILED', {
        autoClose: 7500,
        onClose: () => {
          WalletActions.clearToast()
        }
      })
      WalletActions.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <WalletFilters />
        <CollapsibleCard
          startOpen
          header={
            <strong>
              Wallets
              <span className='float-right'>
                {this.state.walletsData &&
                  !!this.state.walletsData.length &&
                  this.state.totalCount > 0 &&
                  !this.state.appliedFilters &&
                  this.state.totalCount > this.state.limit && (
                    <ButtonGroup style={{ marginRight: '10px' }}>
                      {this.state.start !== 0 && (
                        <Button
                          disabled={this.state.fetchingWalletsInfo}
                          outline
                          color='primary'
                          onClick={() => WalletActions.setLimit('previous')}
                          active={this.state.showPreviousRecords}
                        >
                          Prev {this.state.limit}
                        </Button>
                      )}
                      {this.state.start + this.state.limit < this.state.totalCount && (
                        <Button
                          outline
                          color='primary'
                          disabled={this.state.fetchingWalletsInfo}
                          onClick={() => WalletActions.setLimit('next')}
                          active={this.state.showNextRecords}
                        >
                          Next {this.state.limit}
                        </Button>
                      )}
                    </ButtonGroup>
                  )}
                <ButtonGroup>
                  <Button outline color='success' onClick={WalletActions.toggleActiveWallet} active={this.state.showActiveWallets}>
                    Active
                  </Button>
                  <Button outline color='danger' onClick={WalletActions.toggleInActiveWallet} active={this.state.showInActiveWallets}>
                    In-Active
                  </Button>
                </ButtonGroup>
              </span>
            </strong>
          }
        >
          {this.state.fetchingWalletsInfo ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <DynamicTable
              responsive
              size='sm'
              data={
                this.state.walletsData && !!this.state.walletsData.length && this.state.walletFilterValue !== 'both'
                  ? this.state.walletsData.filter(wallet => wallet.removed === (this.state.walletFilterValue === 'inactive' ? 1 : 0))
                  : this.state.walletsData
              }
              headers={{
                WalletID: 'WalletID',
                BrandID: 'Brand ID',
                BatchID: 'Batch',
                brandName: 'Brand',
                userName: 'Name',
                walletNote: 'Note',
                walletAmountPoints: 'Points',
                vaultSlug: 'Vault Slug',
                timeInsert: 'Date'
              }}
              options={{
                fieldFunctions: {
                  timeInsert: e => {
                    return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
                  }
                },
                timeInsert: e => {
                  return moment(e.timeInsert).format('x')
                },
                addField: e => {
                  return (
                    <td>
                      <ButtonGroup>
                        {e.removed === 0 && (
                          <React.Fragment>
                            <Tooltip target={'delete' + e.WalletID}>Deaktivér</Tooltip>
                            <Button
                              id={'delete' + e.WalletID}
                              onClick={() => {
                                if (confirm('Vil du deaktivere denne Wallet?')) {
                                  WalletActions.removeWallet(e.WalletID, e.removed)
                                }
                              }}
                            >
                              <FontAwesomeIcon icon='user-check' />
                            </Button>
                          </React.Fragment>
                        )}
                        {e.removed === 1 && (
                          <React.Fragment>
                            <Tooltip target={'delete' + e.WalletID}>Aktivér</Tooltip>
                            <Button
                              color='danger'
                              id={'delete' + e.WalletID}
                              onClick={() => {
                                if (confirm('Vil du aktivere denne Wallet?')) {
                                  WalletActions.removeWallet(e.WalletID, e.removed)
                                }
                              }}
                            >
                              <FontAwesomeIcon icon='user-slash' />
                            </Button>
                          </React.Fragment>
                        )}
                      </ButtonGroup>
                    </td>
                  )
                }
              }}
            />
          )}
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

class WalletFilters extends Component {
  constructor(props) {
    super(props)
    this.store = WalletStore
    this.storeKeys = ['filters']
  }
  componentDidMount() {
    if (this.state.filters && !this.state.filters.length) {
      WalletActions.addFilter()
    }
  }
  render() {
    return (
      <Card>
        <CardHeader tag='h4'>Filtre</CardHeader>
        <CardBody>
          <Table responsive size='sm'>
            <thead>
              <tr>
                <th>Kilde</th>
                <th>Operatør</th>
                <th>Værdi</th>
                <th>
                  <Button onClick={WalletActions.addFilter}>
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.filters.map((e, i) => {
                let filter = (
                  <Input
                    type='text'
                    onChange={t => {
                      WalletActions.updateFilter(e, t)
                    }}
                    value={e.value}
                    name='value'
                  />
                )
                let column = (
                  <Input
                    type='select'
                    onChange={t => {
                      WalletActions.updateFilter(e, t)
                    }}
                    value={e.column}
                    name='column'
                  >
                    <option selected={true} disabled>
                      Vælg
                    </option>
                    {Object.keys(filterFields).map((s, is) => {
                      return (
                        <option key={is} value={s}>
                          {filterFields[s]}
                        </option>
                      )
                    })}
                  </Input>
                )
                let operatorsList = []
                if (e.column) {
                  operatorsList = operators[e.column]
                }
                let operator = (
                  <Input
                    type='select'
                    onChange={t => {
                      WalletActions.updateFilter(e, t)
                    }}
                    value={e.operator}
                    name='operator'
                  >
                    <option selected={true} disabled>
                      Choose
                    </option>
                    {operatorsList.map((s, is) => {
                      return (
                        <option key={is} value={s}>
                          {s}
                        </option>
                      )
                    })}
                  </Input>
                )
                return (
                  <tr key={i}>
                    <td>{column}</td>
                    <td>{operator}</td>
                    <td>{filter}</td>
                    <td>
                      <Button
                        color='danger'
                        onClick={() => {
                          WalletActions.removeFilter(i)
                        }}
                      >
                        <FontAwesomeIcon icon='minus' />
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <React.Fragment>{this.state.filters.length > 0 && <Button onClick={WalletActions.getTotalWalletDetails}>Filtrer</Button>}</React.Fragment>
          <React.Fragment>
            {this.state.filters.length > 0 && (
              <Button
                color='danger'
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  WalletActions.clearFilters()
                  WalletActions.applyFilters()
                }}
              >
                Clear Filtrer
              </Button>
            )}
          </React.Fragment>
        </CardBody>
      </Card>
    )
  }
}
