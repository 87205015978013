import Proxies from 'Components/proxies'
import {
  fetchAnnouncementsRequest,
  fetchAnnouncementsFailed,
  fetchAnnouncementsSuccess,
  fetchAnnouncementDetailsRequest,
  fetchAnnouncementDetailsFailed,
  fetchAnnouncementDetailsSuccess,
  announcementCreationSuccess,
  announcementCreationFailure,
  isSavingAnnouncement,
  removeAnnouncementFailure,
  removeAnnouncementSuccess,
  gettingBrandsSuccess
} from 'Reducers/announcementReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  FETCHING_ANNOUNCEMENTS: null,
  FETCHING_ANNOUNCEMENTS_SUCCESS: null,
  FETCHING_ANNOUNCEMENTS_FAILED: null,
  FETCHING_ANNOUNCEMENT_DETAILS: null,
  FETCHING_ANNOUNCEMENT_DETAILS_FAILED: null,
  FETCHING_ANNOUNCEMENT_DETAILS_SUCCESS: null,
  ANNOUNCEMENT_SUCCESS: null,
  ANNOUNCEMENT_FAILED: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  DELETE_ANNOUNCEMENT_FAILED: null,
  DELETE_ANNOUNCEMENT_SUCCESS: null
})

export const getAnnouncements = data => dispatch => {
  dispatch(fetchAnnouncementsRequest())
  Proxies.GET('announcements/pool', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchAnnouncementsFailed())
    } else {
      dispatch(fetchAnnouncementsSuccess(responseJSON))
    }
  })
}

export const getAnnouncementDetails = AnnouncementID => dispatch => {
  dispatch(fetchAnnouncementDetailsRequest())
  Proxies.GET('announcements/fetch', { AnnouncementID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchAnnouncementDetailsFailed())
    } else {
      dispatch(fetchAnnouncementDetailsSuccess(responseJSON))
    }
  })
}

export const saveAnnouncement = data => dispatch => {
  dispatch(isSavingAnnouncement())
  Proxies.POST('announcements/replace', {
    ...data,
    BrandID: Number(data.BrandID),
    isActive: Number(data.isActive),
    messageType: data.messageType ? data.messageType : 'Info'
  }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(announcementCreationFailure())
    } else {
      dispatch(announcementCreationSuccess(responseJSON))
    }
  })
}

export const deleteAnnouncement = data => dispatch => {
  dispatch(isSavingAnnouncement())
  Proxies.POST('announcements/remove', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(removeAnnouncementFailure())
    } else {
      dispatch(removeAnnouncementSuccess())
    }
  })
}

export const getBrands = () => dispatch => {
  Proxies.GET('menus/listBrands').then(responseJSON => {
    if (responseJSON) {
      dispatch(gettingBrandsSuccess(responseJSON))
    }
  })
}
