import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/announcementActions'
import moment from 'moment'

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState: {
    overviewLoadingState: false,
    announcements: [],
    announcementDetails: {
      BrandID: '',
      message: '',
      messageType: '',
      isActive: '',
      timeStart: null,
      timeEnd: null
    },
    loadingState: false,
    showActiveAnnouncements: true,
    showInActiveAnnouncements: false,
    announcementFilterValue: 'active',
    brands: []
  },
  reducers: {
    fetchAnnouncementsRequest: state => {
      state.overviewLoadingState = STATUS.FETCHING_ANNOUNCEMENTS
    },
    fetchAnnouncementsFailed: state => {
      state.overviewLoadingState = STATUS.FETCHING_ANNOUNCEMENTS_FAILED
    },
    fetchAnnouncementsSuccess: (state, action) => {
      state.announcements = action.payload
      state.overviewLoadingState = STATUS.FETCHING_ANNOUNCEMENTS_SUCCESS
    },
    fetchAnnouncementDetailsRequest: state => {
      state.loadingState = STATUS.FETCHING_ANNOUNCEMENT_DETAILS
    },
    fetchAnnouncementDetailsFailed: state => {
      state.loadingState = STATUS.FETCHING_ANNOUNCEMENT_DETAILS_FAILED
    },
    fetchAnnouncementDetailsSuccess: (state, action) => {
      state.loadingState = STATUS.FETCHING_ANNOUNCEMENT_DETAILS_SUCCESS
      state.announcementDetails = action.payload
    },
    announcementCreationSuccess: (state, action) => {
      state.loadingState = action.payload
      state.loadingState = STATUS.ANNOUNCEMENT_SUCCESS
    },
    announcementCreationFailure: state => {
      state.loadingState = STATUS.ANNOUNCEMENT_FAILED
    },
    isSavingAnnouncement: state => {
      state.loadingState = STATUS.IS_SAVING
    },
    createEmptyAnnouncement: state => {
      state.announcementDetails = {
        BrandID: '',
        message: '',
        messageType: '',
        timeStart: moment().format(),
        isActive: false
      }
    },
    clearToast: (state, action) => {
      state.loadingState = action.payload ? action.payload : null
      state.overviewLoadingState = false
    },
    updateAnnouncementField: (state, action) => {
      return {
        ...state,
        announcementDetails: { ...state.announcementDetails, [action.payload.name]: action.payload.value }
      }
    },
    removeAnnouncementFailure: state => {
      state.overviewLoadingState = STATUS.DELETE_ANNOUNCEMENT_FAILED
    },
    removeAnnouncementSuccess: state => {
      state.overviewLoadingState = STATUS.DELETE_ANNOUNCEMENT_SUCCESS
    },
    toggleActiveAnnouncement: state => {
      let value = !state.showActiveAnnouncements ? 'active' : 'both'
      state.showActiveAnnouncements = !state.showActiveAnnouncements
      state.announcementFilterValue = value
      state.showInActiveAnnouncements = false
    },
    toggleInActiveAnnouncement: state => {
      let value = state.showInActiveAnnouncements ? 'both' : 'inactive'
      state.showInActiveAnnouncements = !state.showInActiveAnnouncements
      state.announcementFilterValue = value
      state.showActiveAnnouncements = false
    },
    gettingBrandsSuccess: (state, action) => {
      state.brands = action.payload
    }
  }
})

export const {
  fetchAnnouncementsRequest,
  fetchAnnouncementsFailed,
  fetchAnnouncementsSuccess,
  fetchAnnouncementDetailsRequest,
  fetchAnnouncementDetailsFailed,
  fetchAnnouncementDetailsSuccess,
  announcementCreationSuccess,
  announcementCreationFailure,
  toggleActiveAnnouncement,
  toggleInActiveAnnouncement,
  removeAnnouncementFailure,
  resetAnnouncementDetails,
  isSavingAnnouncement,
  createEmptyAnnouncement,
  clearToast,
  updateAnnouncementField,
  removeAnnouncementSuccess,
  gettingBrandsSuccess
} = announcementSlice.actions

export default announcementSlice.reducer
