import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { getSuppliers, STATUS, deleteSupplier } from 'Reducers/actions/supplierActions'
import { connect } from 'react-redux'
import { clearToast, toggleActiveSupplier, toggleInActiveSupplier } from 'Reducers/supplierReducer'

class SuppliersOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getSuppliers()
  }

  componentDidUpdate() {
    if (this.props.overviewLoadingState === STATUS.DELETE_SUPPLIER_SUCCESS) {
      this.props.getSuppliers()
    }
  }

  render() {
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Leverandører
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={this.props.toggleActiveSupplier} active={this.props.showActiveSuppliers}>
                Active
              </Button>
              <Button outline color='danger' onClick={this.props.toggleInActiveSupplier} active={this.props.showInActiveSuppliers}>
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {this.props.overviewLoadingState === STATUS.LOADING_SUPPLIERS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'SupplierID'
              },
              fieldFunctions: {},
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.SupplierID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/suppliers/' + e.SupplierID} id={'edit' + e.SupplierID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'deactivate' + e.SupplierID}>Deaktivér</Tooltip>
                          <Button
                            id={'deactivate' + e.SupplierID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne Supplier?')) {
                                this.props.deleteSupplier({ SupplierID: e.SupplierID, removed: 1 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-check' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip target={'actiavte' + e.SupplierID}>Aktivér</Tooltip>
                          <Button
                            id={'actiavte' + e.SupplierID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du aktivere denne Supplier?')) {
                                this.props.deleteSupplier({ SupplierID: e.SupplierID, removed: 0 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              SupplierID: '#',
              supplierName: 'Name',
              supplierUID: 'UID'
            }}
            data={
              this.props.suppliers && this.props.supplierFilterValue !== 'both' && !!this.props.suppliers.length
                ? this.props.suppliers.filter(supplier => supplier.removed === (this.props.supplierFilterValue === 'inactive' ? 1 : 0))
                : this.props.suppliers
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({
  showInActiveSuppliers: state.supplier.showInActiveSuppliers,
  showActiveSuppliers: state.supplier.showActiveSuppliers,
  suppliers: state.supplier.suppliers,
  overviewLoadingState: state.supplier.overviewLoadingState,
  supplierFilterValue: state.supplier.supplierFilterValue
})

export default connect(mapStateToProps, { getSuppliers, deleteSupplier, clearToast, toggleActiveSupplier, toggleInActiveSupplier })(SuppliersOverview)
