import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { getCustomerSalesRewards, STATUS, deleteCustomerSalesReward } from 'Reducers/actions/customerSalesRewardActions'
import { clearToast, toggleActiveCustomerSalesReward, toggleInActiveCustomerSalesReward } from 'Reducers/customerSalesRewardReducer'
import { connect } from 'react-redux'
import moment from 'moment'

class CustomerSalesRewardsOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getCustomerSalesRewards()
  }

  componentDidUpdate() {
    if (this.props.overviewLoadingState === STATUS.DELETE_CUSTOMER_SALES_REWARD_SUCCESS) {
      this.props.getCustomerSalesRewards()
    }
  }

  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Produkter </strong>}>
        {this.props.overviewLoadingState === STATUS.LOADING_CUSTOMER_SALES_REWARDS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'CustomerSalesRewardID'
              },
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.postTimeInsert).format('DD-MM-YYYY') : ''
                },
                productUID: e => {
                  return e.customerProduct.productUID
                },
                customerProduct: e => {
                  return e.customerProduct.moduleName
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.CustomerSalesRewardID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/customersalesrewards/' + e.CustomerSalesRewardID} id={'edit' + e.CustomerSalesRewardID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'delete' + e.CustomerSalesRewardID}>Slet</Tooltip>
                          <Button
                            id={'delete' + e.CustomerSalesRewardID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du Slet denne CustomerSalesReward?')) {
                                this.props.deleteCustomerSalesReward({ CustomerSalesRewardID: e.CustomerSalesRewardID, removed: 1 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='trash' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip target={'actiavte' + e.CustomerSalesRewardID}>Aktivér</Tooltip>
                          <Button
                            id={'actiavte' + e.CustomerSalesRewardID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du aktivere denne CustomerSalesReward?')) {
                                this.props.deleteCustomerSalesReward({ CustomerSalesRewardID: e.CustomerSalesRewardID, removed: 0 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              CustomerSalesRewardID: '#',
              customerProduct: 'Produkt navn',
              productUID: 'ProduktUID',
              rewardPoints: 'Belønning point',
              productRatio: 'Product Ratio',
              isActive: 'Aktiv',
              timeInsert: 'Dato',
              createdBy: 'Oprettet af',
              lastUpdateBy: 'Senest ændret af'
            }}
            data={
              this.props.customerSalesRewards && this.props.customerSalesRewardFilterValue !== 'both' && !!this.props.customerSalesRewards.length
                ? this.props.customerSalesRewards.filter(
                    customerSalesReward => customerSalesReward.removed === (this.props.customerSalesRewardFilterValue === 'inactive' ? 1 : 0)
                  )
                : this.props.customerSalesRewards
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({
  showInActiveCustomerSalesRewards: state.customerSalesReward.showInActiveCustomerSalesRewards,
  showActiveCustomerSalesRewards: state.customerSalesReward.showActiveCustomerSalesRewards,
  customerSalesRewards: state.customerSalesReward.customerSalesRewards,
  overviewLoadingState: state.customerSalesReward.overviewLoadingState,
  customerSalesRewardFilterValue: state.customerSalesReward.customerSalesRewardFilterValue
})

export default connect(mapStateToProps, {
  getCustomerSalesRewards,
  deleteCustomerSalesReward,
  clearToast,
  toggleActiveCustomerSalesReward,
  toggleInActiveCustomerSalesReward
})(CustomerSalesRewardsOverview)
