import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { TranslationActions, TranslationStore, STATUS } from 'Stores/translationStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Input, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { download } from 'Components/download'
import Papa from 'papaparse'

export default class TranslationList extends Component {
  constructor(props) {
    super(props)
    this.stores = [TranslationStore]
    this.storeKeys = ['status', 'translations', 'translationsForExport', 'showUploadField']
  }

  componentDidMount() {
    TranslationActions.getTranslations()
    TranslationActions.getRawTranslations()
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_TRANSLATIONS_FAILED) {
      toast.error('FETCHING TRANSLATIONS FAILED', {
        autoClose: 7500,
        onClose: () => {
          TranslationActions.clearToast()
        }
      })
      TranslationActions.clearToast()
    }
    if (this.state.status === STATUS.DELETE_TRANSACTIONS_FAILED) {
      toast.error('TRANSLATION DELETE FAILED', {
        autoClose: 7500,
        onClose: () => {
          TranslationActions.clearToast()
        }
      })
      TranslationActions.clearToast()
    }
    if (this.state.status === STATUS.DELETE_TRANSLATION_SUCCESS) {
      toast.success('SUCCESSFULLY DELETED', {
        autoClose: 7500,
        onClose: () => {
          TranslationActions.clearToast()
        }
      })
      TranslationActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_TRANSLATIONS) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <strong>Translations</strong>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button
                onClick={() => {
                  let papasettings = {
                    quotes: true,
                    delimiter: ';',
                    header: true
                  }
                  download(Papa.unparse(this.state.translationsForExport, papasettings), 'Translations.csv')
                }}
              >
                Export
              </Button>

              <Button
                onClick={() => {
                  TranslationActions.enableImportField()
                }}
              >
                Import
              </Button>

              {!!this.state.showUploadField && (
                <Input
                  type='file'
                  onChange={e => {
                    TranslationActions.onFileUploadChange(e)
                  }}
                />
              )}
            </div>
          </div>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={this.state.translations}
          headers={{
            TranslationID: 'Translation ID',
            translationKey: 'Translation Key',
            language: 'Language',
            translationValue: 'Translation Value',
            timeInsert: 'Created'
          }}
          options={{
            startSort: {
              column: 'timeInsert',
              dir: 'DESC'
            },
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert && e.timeInsert !== '0000-00-00 00:00:00' ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
              },
              language: e => {
                if (e.language && e.language.length) {
                  const reducedLanguage = e.language.reduce((acc, cur, idx) => {
                    acc.push(cur.toUpperCase())
                    acc.push(<br key={idx} />)
                    return acc
                  }, [])
                  if (reducedLanguage.length > 0) {
                    reducedLanguage.pop()
                  }
                  return reducedLanguage
                }
              },
              translationValue: e => {
                if (e.translationValue && e.translationValue.length) {
                  const reducedTranslationValue = e.translationValue.reduce((acc, cur, idx) => {
                    acc.push(cur)
                    acc.push(<br key={idx} />)
                    return acc
                  }, [])
                  if (reducedTranslationValue.length > 0) {
                    reducedTranslationValue.pop()
                  }
                  return reducedTranslationValue
                }
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.TranslationID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/translations/' + e.TranslationID} id={'edit' + e.TranslationID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>

                    <Tooltip target={'delete' + e.TranslationID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.TranslationID}
                      color='danger'
                      onClick={() => {
                        TranslationActions.removeTranslation(e.TranslationID)
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
