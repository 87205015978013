export const colorMap = {
  SystemColor: '#a6cee3',
  ManuelColor: '#1f78b4',
  MacroColor: '#b2df8a',
  BrugteColor: '#aadea4',
  UbrugteColor: '#fb9a99',
  AktivColor: '#b2df8a',
  InaktivColor: '#FA8072',
  'Aktive brugereColor': '#f47560',
  'Inaktive brugereColor': '#e8c1a0',
  'Aktive brugere pointsColor': '#cab2d6',
  'Inaktive brugere pointsColor': '#6a3d9a',
  'Aktive BrugereColor': '#1F78B4',
  'Inaktive BrugereColor': '#A6CEE3',
  'Ubrugte PointColor': '#fb9a99',
  OrdrerColor: '#efda99',
  'Aktive dealerColor': '#f47560',
  'Inaktive dealerColor': '#e8c1a0'
}
