/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import keyMirror from 'keymirror'
import moment from 'moment'

export const STATUS = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  MENU_SUCCESS: null,
  MENU_FAILED: null,
  FETCHING_MENUS: null,
  FETCHING_MENUS_FAILED: null,
  FETCHING_MENUS_SUCCESS: null,
  FETCHING_MENU: null,
  FETCHING_MENU_FAILED: null,
  FETCHING_MENU_SUCCESS: null,
  DELETE_MENU_SUCCESS: null,
  DELETE_MENU_FAILED: null,
  ENABLE_MENU_SUCCESS: null,
  ENABLE_MENU_FAILED: null,
  FETCHING_BRANDS: null,
  FETCHING_BRANDS_FAILED: null,
  FETCHING_BRANDS_SUCCESS: null,
  FETCHING_POOLNAMES: null,
  FETCHING_POOLNAMES_FAILED: null,
  FETCHING_POOLNAMES_SUCCESS: null,
  IS_REDIRECT: null
})

export const MenuActions = createActions([
  'clearToast',
  'createEmptyMenu',
  'getMenus',
  'getMenuDetails',
  'updateMenuField',
  'saveMenu',
  'selectFile',
  'getBrands',
  'getPoolNames',
  'copyMenu',
  'enableMenu',
  'deleteMenu',
  'toggleActiveMenu',
  'toggleInActiveMenu',
  'updateData',
  'getLanguages',
  'removeTranslation',
  'addTranslation'
])

export class MenuStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = MenuActions
    this.state = {
      status: null,
      menuDetails: {
        MenuID: '',
        BrandID: '',
        poolName: '',
        menuTitle: '',
        poolOrder: '',
        timeStart: '',
        timeEnd: '',
        translations: [
          {
            LanguageID: null,
            menuTitle: ''
          }
        ]
      },
      brands: [],
      poolNames: [],
      menus: [],
      showActiveMenu: true,
      showInActiveMenu: false,
      menuFilterValue: 'active',
      languages: []
    }
  }
  clearToast(status) {
    status ? this.setState({ status }) : this.setState({ status: null })
  }
  createEmptyMenu() {
    this.setState({
      menuDetails: {
        MenuID: '',
        BrandID: '',
        poolName: '',
        menuTitle: '',
        poolOrder: '',
        timeStart: '',
        timeEnd: '',
        translations: [
          {
            LanguageID: null,
            menuTitle: ''
          }
        ]
      }
    })
  }
  getMenus() {
    if (this.state.status === STATUS.FETCHING_MENUS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_MENUS })
    GET('menus/pool').then(responseJSON => {
      let nextState = {
        menus: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_MENUS_FAILED
      } else {
        nextState.menus = responseJSON
        nextState.status = STATUS.FETCHING_MENUS_SUCCESS
      }
      this.setState(nextState)
    })
  }
  copyMenu(menu, props) {
    props.history.push(props.location.pathname + '/new?copy=' + menu.MenuID)
  }
  getMenuDetails(MenuID, copy = false) {
    this.setState({ status: STATUS.FETCHING_MENU })
    GET('menus/fetch', { MenuID }).then(responseJSON => {
      let nextState = {
        menuDetails: {}
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_MENU_FAILED
      } else {
        responseJSON.timeEnd = moment.parseZone(responseJSON.timeEnd).format('YYYY-MM-DD')
        responseJSON.timeStart = moment.parseZone(responseJSON.timeStart).format('YYYY-MM-DD')
        if (responseJSON.timeEnd === 'Invalid date') {
          responseJSON.timeEnd = ''
        }
        if (responseJSON.timeStart === 'Invalid date') {
          responseJSON.timeStart = ''
        }
        if (responseJSON.translations && !responseJSON.translations.length) {
          responseJSON.translations = [
            {
              LanguageID: null,
              menuTitle: ''
            }
          ]
        }
        nextState.menuDetails = responseJSON
        this.getLanguages(responseJSON.BrandID)
        if (copy) {
          nextState.menuDetails.MenuID = null
        }
        nextState.status = STATUS.FETCHING_MENU_SUCCESS
      }
      this.setState(nextState)
    })
  }

  getLanguages(BrandID) {
    GET('brands/brand-languages', { BrandIDs: [BrandID] }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        this.setState({ languages: responseJSON })
      }
    })
  }

  removeTranslation = i => {
    let translations = this.state.menuDetails.translations
    if (translations[i].MenuTranslationID) {
      translations[i].removed = 1
      if (translations.length === 1) {
        translations.push({
          LanguageID: null,
          menuTitle: ''
        })
      }
    } else {
      translations.splice(i, 1)
      if (!translations.length) {
        translations.push({
          LanguageID: null,
          menuTitle: ''
        })
      } else if (translations.filter(translation => translation.MenuTranslationID).length) {
        translations.push({
          LanguageID: null,
          menuTitle: ''
        })
      }
    }
    this.setState({ menuDetails: { ...this.state.menuDetails, translations: translations } })
  }

  addTranslation = () => {
    let translations = this.state.menuDetails.translations
    translations.push({ menuTitle: '', LanguageID: null })
    this.setState({ menuDetails: { ...this.state.menuDetails, translations: translations } })
  }

  updateData(field, index, type) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let menuDetails = this.state.menuDetails
    let column = menuDetails[type][index]

    if (fieldName === 'LanguageID' && type === 'translations') {
      fieldValue = Number(fieldValue)
    }

    if (!column[fieldName]) {
      column[fieldName] = ''
    }

    column[fieldName] = fieldValue

    this.setState({ menuDetails })
  }

  updateMenuField(field) {
    let value = strip_tags(field.target.value)
    let menuDetails = this.state.menuDetails
    if (value === null || value === undefined || value === 'Invalid date') {
      value = ''
    }
    if (value && (field.target.name === 'timeStart' || field.target.name === 'timeEnd')) {
      value = moment.parseZone(value).format('YYYY-MM-DD')
    }

    menuDetails[field.target.name] = value
    this.setState({ menuDetails })
  }
  saveMenu() {
    if (this.state.menuDetails.poolName != '' && this.state.menuDetails.menuTitle != '' && this.state.menuDetails.BrandID) {
      this.setState({ status: STATUS.IS_SAVING })
      if (this.state.menuDetails.timeEnd === 'Invalid Date') {
        this.state.menuDetails.timeEnd = ''
      }
      if (this.state.menuDetails.timeStart === 'Invalid Date' || !this.state.menuDetails.timeStart) {
        this.state.menuDetails.timeStart = moment.parseZone().format('YYYY-MM-DD')
      }
      if (this.state.menuDetails.translations) {
        this.state.menuDetails.translations = this.state.menuDetails.translations.filter(
          translation => translation.LanguageID !== null && translation.menuTitle !== ''
        )
      }
      POST('menus/replace', this.state.menuDetails).then(responseJSON => {
        let nextState = {
          menuDetails: this.state.menuDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.MENU_FAILED
          } else {
            if (responseJSON.translations && !responseJSON.translations.length) {
              responseJSON.translations = [
                {
                  LanguageID: null,
                  menuTitle: ''
                }
              ]
            }
            nextState.menuDetails = responseJSON
            nextState.status = STATUS.MENU_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }
  deleteMenu(MenuID = null) {
    if (MenuID) {
      POST('menus/disable', { MenuID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATUS.DELETE_MENU_FAILED })
        } else {
          this.setState({ status: STATUS.DELETE_MENU_SUCCESS })
          this.getMenus()
        }
      })
    }
  }
  enableMenu(MenuID = null) {
    if (MenuID) {
      POST('menus/enable', { MenuID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATUS.ENABLE_MENU_FAILED })
        } else {
          this.setState({ status: STATUS.ENABLE_MENU_SUCCESS })
          this.getMenus()
        }
      })
    }
  }
  selectFile(data) {
    let file = data.target.files[0]
    this.setState({ file: file, isUploading: true })
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON) {
        let menuDetails = this.state.menuDetails
        menuDetails.menuIcon = responseJSON.imageOriginSource ? (menuDetails.menuIcon = responseJSON.imageOriginSource) : ''
        this.setState({ menuDetails })
      }
    })
  }
  getBrands() {
    if (this.state.status === STATUS.FETCHING_BRANDS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_BRANDS })
    GET('menus/listBrands').then(responseJSON => {
      let nextState = {
        brands: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_BRANDS_FAILED
      } else {
        for (const iterator of responseJSON) {
          nextState.brands.push({ BrandID: iterator['BrandID'], brandName: iterator['brandName'] })
        }
        nextState.status = STATUS.FETCHING_BRANDS_SUCCESS
      }
      this.setState(nextState)
    })
  }
  getPoolNames() {
    if (this.state.status === STATUS.FETCHING_POOLNAMES) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_POOLNAMES })
    GET('menus/listPoolNames').then(responseJSON => {
      let nextState = {
        poolNames: [{ poolName: 'New Pool Name' }]
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_POOLNAMES_FAILED
      } else {
        // console.log(responseJSON);
        for (const iterator of responseJSON) {
          // console.log(iterator);
          nextState.poolNames.push({ poolName: iterator })
        }
        nextState.status = STATUS.FETCHING_POOLNAMES_SUCCESS
      }
      this.setState(nextState)
    })
  }
  toggleActiveMenu() {
    let value = !this.state.showActiveMenu ? 'active' : 'both'
    this.setState({ showActiveMenu: !this.state.showActiveMenu, menuFilterValue: value, showInActiveMenu: false })
  }
  toggleInActiveMenu() {
    let value = this.state.showInActiveMenu ? 'both' : 'inactive'
    this.setState({ showInActiveMenu: !this.state.showInActiveMenu, menuFilterValue: value, showActiveMenu: false })
  }
}
