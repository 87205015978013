import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { ClientActions, ClientStore, STATUS } from 'Stores/clientStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class ClientList extends Component {
  constructor(props) {
    super(props)
    this.stores = [ClientStore]
    this.storeKeys = ['status', 'clients']
  }

  componentDidMount() {
    ClientActions.getClients()
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_CLIENTS_FAILED) {
      toast.error('FETCHING CLIENTS FAILED', {
        autoClose: 7500,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast()
    }

    if (this.state.status === STATUS.DELETE_CLIENT_FAILED) {
      toast.error('This Client has one or more Brands and so cannot be deleted', {
        autoClose: 7500,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast()
    }

    if (this.state.status === STATUS.DELETE_CLIENT_SUCCESS) {
      toast.success('CLIENT DELETED', {
        autoClose: 7500,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_CLIENTS) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Clients</strong>}>
        <DynamicTable
          responsive
          size='sm'
          data={this.state.clients}
          headers={{
            ClientID: 'Client ID',
            CompanyID: 'Company ID',
            clientName: 'Client Name',
            clientNameShort: 'Client Name Short',
            clientAccess: 'Client Access',
            timeInsert: 'Created'
          }}
          options={{
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.ClientID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/client/' + e.ClientID} id={'edit' + e.ClientID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.ClientID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.ClientID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne client?')) {
                          ClientActions.deleteClient(e.ClientID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
