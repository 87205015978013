import { createSlice } from '@reduxjs/toolkit'
import { fetchSettings } from 'Reducers/actions/loginActions'

const slice = createSlice({
  name: 'loginReducer',
  initialState: {
    settings: {}
  },
  reducers: {},
  extraReducers: {
    [fetchSettings]: (state, action) => {
      state.settings = action.payload
    }
  }
})

export default slice.reducer
