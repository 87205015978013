import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { BrandActions, BrandStore, STATUS } from 'Stores/brandStore'

export default class ViewBrands extends Component {
  constructor(props) {
    super(props)
    this.stores = [BrandStore]
  }

  componentDidMount() {
    BrandActions.getBrands()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.DEACTIVATED_BRAND_FAILED) {
      toast.error('Brand Deactivate Failed', {
        autoClose: 7000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }
    if (this.state.status === STATUS.ACTIVATED_BRAND_FAILED) {
      toast.error('Brand Activate Failed', {
        autoClose: 7000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }
    if (this.state.status === STATUS.DEACTIVATED_BRAND_SUCCESS) {
      toast.success('Brand Deactivated', {
        autoClose: 7000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }
    if (this.state.status === STATUS.ACTIVATED_BRAND_SUCCESS) {
      toast.success('Brand Activated', {
        autoClose: 7000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }
  }
  render() {
    if (this.state.status === STATUS.FETCHING_BRANDS) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Brand oversigt{' '}
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={BrandActions.toggleActiveBrand} active={this.state.showActiveBrands}>
                Active
              </Button>
              <Button outline color='danger' onClick={BrandActions.toggleInActiveBrand} active={this.state.showInActiveBrands}>
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={
            this.state.showRemovedBrands !== 'both' && this.state.brands && !!this.state.brands.length
              ? this.state.brands.filter(brand => brand.removed === (this.state.showRemovedBrands === 'inactive' ? 1 : 0))
              : this.state.brands
          }
          headers={{
            BrandID: 'Brand-ID',
            ClientID: 'Klient-ID',
            brandName: 'Navn',
            brandNameShort: 'Kort navn',
            brandSlug: 'Slug navn',
            timeStart: 'Startdato'
          }}
          options={{
            fieldFunctions: {
              timeStart: e => {
                return e.timeStart ? moment(e.timeStart).format('DD-MM-YYYY') : ''
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.BrandID}>Redigere</Tooltip>
                    <Button tag={Link} to={'/brand/' + e.BrandID} id={'edit' + e.BrandID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    {e.removed === 0 && (
                      <React.Fragment>
                        <Tooltip target={'deactivate' + e.BrandID}>Deaktivér</Tooltip>
                        <Button
                          id={'deactivate' + e.BrandID}
                          onClick={() => {
                            if (confirm('Vil du deaktivere denne brand?')) {
                              BrandActions.deleteBrand(e.BrandID, 1)
                            }
                          }}
                        >
                          {e.BrandID === this.state.deleteBrandID && this.state.status === STATUS.DELETE_BRAND ? (
                            <FontAwesomeIcon icon='spinner' />
                          ) : (
                            <FontAwesomeIcon icon='user-check' />
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                    {e.removed === 1 && (
                      <React.Fragment>
                        <Tooltip color='danger' target={'active' + e.BrandID}>
                          Aktivér
                        </Tooltip>
                        <Button
                          color='danger'
                          id={'active' + e.BrandID}
                          onClick={() => {
                            if (confirm('Vil du aktivere denne brand?')) {
                              BrandActions.deleteBrand(e.BrandID, 0)
                            }
                          }}
                        >
                          {e.BrandID === this.state.deleteBrandID && this.state.status === STATUS.DELETE_BRAND ? (
                            <FontAwesomeIcon icon='spinner' />
                          ) : (
                            <FontAwesomeIcon icon='user-slash' />
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                    <Tooltip target={'copy' + e.BrandID}>Kopier</Tooltip>
                    <Button
                      id={'copy' + e.BrandID}
                      onClick={() => {
                        BrandActions.copyBrand(e, this.props)
                      }}
                    >
                      <FontAwesomeIcon icon='copy' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
