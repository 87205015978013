import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, PUT, POST, wrapFilter } = Proxies
import strip_tags from 'Components/striptags'
import keyMirror from 'keymirror'
export const CHRISTMAS_RECEIPT_STATUS = new keyMirror({
  CHRISTMAS_RECEIPT_SENDING: null,
  CHRISTMAS_RECEIPT_SUCCESS: null,
  CHRISTMAS_RECEIPT_FAILURE: null
})

export const ChristmasActions = Reflux.createActions([
  'getData',
  'updateEndDate',
  'updateSelectText',
  'updateChristmasReceiptText',
  'updateChristmasImage',
  'updateWrappedPresents',
  'saveData',
  'sendChristmasReceipt'
])

export class ChristmasStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = ChristmasActions
    this.state = {
      /**/
      fetchingEndDate: false,
      endDateCustomizationID: null,
      noEndDateCustomization: false,
      endDate: '',
      /**/
      fetchingSelectText: false,
      selectTextCustomizationID: null,
      noSelectTextCustomization: false,
      selectText: '',
      /**/
      fetchingChristmasImage: false,
      christmasImageCustomizationID: null,
      noChristmasImageCustomization: false,
      christmasImage: '',
      /**/
      fetchingWrappedPresents: false,
      wrappedPresentsCustomizationID: null,
      noWrappedPresentsCustomization: false,
      wrappedPresents: '',
      /**/
      fetchingChristmasReceiptText: false,
      christmasReceiptTextCustomizationID: null,
      noChristmasReceiptTextCustomization: false,
      christmasReceiptText: '',
      unsavedChristmasReceiptText: false,
      christmasReceiptStatus: null
    }
  }
  getData() {
    let baseData = { CustomisedID: Reflux.GlobalState.LoginStore.BrandID, customisedType: 'Brand' }
    this.setState({ fetchingEndDate: true, fetchingSelectText: true, fetchingChristmasReceiptText: true })
    let nextState = {
      /**/
      fetchingEndDate: false,
      endDateCustomizationID: null,
      noEndDateCustomization: true,
      endDate: '',
      /**/
      fetchingSelectText: false,
      selectTextCustomizationID: null,
      noSelectTextCustomization: true,
      selectText: '',
      /**/
      fetchingChristmasImage: false,
      christmasImageCustomizationID: null,
      noChristmasImageCustomization: true,
      christmasImage: '',
      /**/
      fetchingWrappedPresents: false,
      wrappedPresentsCustomizationID: null,
      noWrappedPresentsCustomization: true,
      wrappedPresents: '',
      /**/
      fetchingChristmasReceiptText: false,
      christmasReceiptTextCustomizationID: null,
      noChristmasReceiptTextCustomization: true,
      christmasReceiptText: ''
    }
    GET('customisationv2', wrapFilter(baseData)).then(customisations => {
      let endDateCustomization = customisations.find(e => {
        return e.title === 'ChristmasEndDate'
      })
      let selectTextCustomization = customisations.find(e => {
        return e.title === 'ChristmasSelectText'
      })
      let christmasImageCustomization = customisations.find(e => {
        return e.title === 'ChristmasHeaderImage'
      })
      let wrappedPresentsCustomization = customisations.find(e => {
        return e.title === 'WrappedPresents'
      })
      let christmasReceiptTextCustomization = customisations.find(e => {
        return e.title === 'ChristmasReceiptText'
      })
      if (endDateCustomization) {
        nextState.endDateCustomizationID = endDateCustomization.CustomisationID
        nextState.noEndDateCustomization = false
        nextState.endDate = JSON.parse(endDateCustomization.customisationData).christmasEndDate
      }
      if (selectTextCustomization) {
        nextState.selectTextCustomizationID = selectTextCustomization.CustomisationID
        nextState.noSelectTextCustomization = false
        nextState.selectText = JSON.parse(selectTextCustomization.customisationData).christmasSelectText
      }
      if (christmasImageCustomization) {
        nextState.christmasImageCustomizationID = christmasImageCustomization.CustomisationID
        nextState.noChristmasImageCustomization = false
        nextState.christmasImage = JSON.parse(christmasImageCustomization.customisationData).christmasImage
      }
      if (wrappedPresentsCustomization) {
        nextState.wrappedPresentsCustomizationID = wrappedPresentsCustomization.CustomisationID
        nextState.noWrappedPresentsCustomization = false
        nextState.wrappedPresents = JSON.parse(wrappedPresentsCustomization.customisationData).wrappedPresents
      }
      if (christmasReceiptTextCustomization) {
        ;(nextState.christmasReceiptTextCustomizationID = christmasReceiptTextCustomization.CustomisationID),
          (nextState.noChristmasReceiptTextCustomization = false),
          (nextState.christmasReceiptText = JSON.parse(christmasReceiptTextCustomization.customisationData).christmasReceiptText)
      }
      this.setState(nextState)
    })
  }
  updateEndDate(e) {
    this.setState({ endDate: strip_tags(e.target.value) })
  }
  updateSelectText(e) {
    this.setState({ selectText: strip_tags(e.target.value) })
  }
  updateChristmasReceiptText(e) {
    this.setState({ christmasReceiptText: strip_tags(e.target.value), unsavedChristmasReceiptText: true })
  }
  updateChristmasImage(e) {
    this.setState({ christmasImage: strip_tags(e.target.value) })
  }
  updateWrappedPresents(e) {
    this.setState({ wrappedPresents: strip_tags(e.target.value) })
  }
  saveData() {
    const baseData = {
      CustomisedID: Reflux.GlobalState.LoginStore.BrandID,
      customisedType: 'Brand',
      description: 'Customization for ' + Reflux.GlobalState.LoginStore.brandData.brandName
    }
    PUT(
      'customisationv2',
      Object.assign({}, baseData, {
        title: 'ChristmasEndDate',
        CustomisationID: this.state.endDateCustomizationID ? this.state.endDateCustomizationID : undefined,
        customisationData: JSON.stringify({ christmasEndDate: this.state.endDate })
      })
    ).then(responseJSON => {
      if (this.state.noEndDateCustomization) {
        this.setState({
          endDateCustomizationID: responseJSON.CustomisationID,
          noEndDateCustomization: false
        })
      }
    })

    PUT(
      'customisationv2',
      Object.assign({}, baseData, {
        title: 'ChristmasSelectText',
        CustomisationID: this.state.selectTextCustomizationID ? this.state.selectTextCustomizationID : undefined,
        customisationData: JSON.stringify({ christmasSelectText: this.state.selectText })
      })
    ).then(responseJSON => {
      if (this.state.noSelectTextCustomization) {
        this.setState({
          selectTextCustomizationID: responseJSON.CustomisationID,
          noSelectTextCustomization: false
        })
      }
    })

    PUT(
      'customisationv2',
      Object.assign({}, baseData, {
        title: 'ChristmasHeaderImage',
        CustomisationID: this.state.christmasImageCustomizationID ? this.state.christmasImageCustomizationID : undefined,
        customisationData: JSON.stringify({ christmasImage: this.state.christmasImage })
      })
    ).then(responseJSON => {
      if (this.state.noChristmasImageCustomization) {
        this.setState({
          christmasImageCustomizationID: responseJSON.CustomisationID,
          noWrappedPresentsCustomization: false
        })
      }
    })

    PUT(
      'customisationv2',
      Object.assign({}, baseData, {
        title: 'WrappedPresents',
        CustomisationID: this.state.wrappedPresentsCustomizationID ? this.state.wrappedPresentsCustomizationID : undefined,
        customisationData: JSON.stringify({ wrappedPresents: this.state.wrappedPresents })
      })
    ).then(responseJSON => {
      if (this.state.noWrappedPresentsCustomization) {
        this.setState({
          wrappedPresentsCustomizationID: responseJSON.CustomisationID,
          noChristmasImageCustomization: false
        })
      }
    })

    PUT(
      'customisationv2',
      Object.assign({}, baseData, {
        title: 'ChristmasReceiptText',
        CustomisationID: this.state.christmasReceiptTextCustomizationID ? this.state.christmasReceiptTextCustomizationID : undefined,
        customisationData: JSON.stringify({ christmasReceiptText: this.state.christmasReceiptText })
      })
    ).then(responseJSON => {
      this.setState({ unsavedChristmasReceiptText: false })
      if (this.state.noChristmasReceiptTextCustomization) {
        this.setState({
          christmasReceiptTextCustomizationID: responseJSON.CustomisationID,
          noChristmasReceiptTextCustomization: false
        })
      }
    })
  }

  sendChristmasReceipt() {
    if (confirm('Vil du sende julegave kvittering til alle brugerne?')) {
      this.setState({ christmasReceiptStatus: CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_SENDING })
      POST('shops/send-receipt-christmas').then(response => {
        if (response.error) {
          this.setState({ christmasReceiptStatus: CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_FAILURE })
        }
        this.setState({ christmasReceiptStatus: CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_SUCCESS })
      })
    }
  }
}
