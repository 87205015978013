export const brandNameAndColor ={
"Jyske Finans":'#008ECC',
"Citroen Finans":'#FF9900',
"Peugeot Finans":'#F10A0A',
"Opel Finans":'#808080',
"Mitsubishi Finans":'#1F160C',
"Jyske Finans Club":'#800000',
"Upgrader Test":'#008080',
"MSB":'#0000FF',
"Santander Salesclub":'#000080',
"AL Finans":'#62EC4D',
"FCAC":'#CD5C5C',
"ZEISS":'#5800FF',
"Tryg":'#B15882',
"Hyundai Finans Klub":'#FF69B4',
"Ikano Salgsklub":'#FF1493',
"DS Finans":'#C71585',
"ViuffGL":'#FF6347',
"Microshade":'#FF4500',
"Adept Water":'#B0DAEA',
"Struers":'#C19B10',
"Delegate":'#BA55D3',
"Ibo":'#663399',
"Opel Finansiering (SE)":'#8A2BE2',
"Basisbank":'#BD4E84',
"Pascalgl":'#4B6331',
"SantanderKam":'#8B008B',
"Express":'#4B0082',
"Nima":'#6A5ACD',
"Gjensidige Klubben":'#E96A97',
"Gouda":'#5FBAC4',
"Peugeot":'#537E7E',
"Basisbank JulGL":'#C51959',
"SputnikGL":'#E5902C',
"Socialdemokratiet Jul":'#E24C68',
"Invita":'#BD86D2',
"DBUs Merchandise Shop EM 2020":'#A9AD74',
"Ingram Julegaveshop GL":'#D9C121',
"Kia Forsikringer":'#50423F',
"Tryg Erhverv":'#F8A3A4',
"Tryg Camping":'#A9B062',
"Stark Pointshop":'#372252',
"Upgrader Test Automation":'#CB798F',
"MSB Gaver":'#29A2C0',
"MSB Merchandise Shop":'#0C1232',
"AP Pension Gammel":'#C5E0BD',
"Otto Suenson Julegaver 400 kr.":'#2B5DF5',
"Fiat Klubben":'#FBB15B',
"Otto Suenson Julegaver 560 kr.":'#6A7B59',
"Otto Suenson Julegaver 640 kr.":'#A22544',
"Tri-ConsultGL":'#A780B2',
"MF Interlogistics":'#4BF0FC',
"Trygdemo":'#6F7766',
"Spar Nord Gammel":'#B1575F',
"TraegerGL":'#BE8259',
"Randstad Jul":'#6A5662',
"PharmaITGammel":'#535447',
"Avdan":'#667A6E',
"Dansk Revision GL":'#521727',
"Combi Byg Gammel":'#0E5996',
"NEXS":'#2B3459',
"Hyundai Klubben":'#F9D94E',
"DIKU":'#D3DE32',
"MSB's Julegavekatalog 2021":'#9268C4',
"Semler":'#29744D',
"Seasonal Greetingshop":'#803474',
"Demo":'#B54442',
"FOMO":'#CE4458',
"Velkommen":'#9AB3F5',
"TrygPartner":'#C9D6DF',
"JS Danmark jul":'#00FFC6',
"Volvo Car Finance":'#3EC70B',
"AP Pension":'#3E2C41',
"Web3 DEMO":'#A43252',
"Jydsk Tagteknik":'#565A7D',
"Andritz":'#77997D',
"Polygon":'#618822',
"Spar Nord":'#805A07',
"PharmaIT":'#ED846D',
"Traeger":'#B93838',
"RTM":'#3F8653',
"Viuff":'#FFFF00',
"TriConsult":'#2F61AD',
"Ingram":'#413C69',
"BasisbankJul":'#8F318C',
"Dansk Sygeplejeråd":'#F9B208',
"Meny Rotunden":'#AA98A9',
"Dansk Revision":'#B5514D',
"Pascal":'#E85607',
"ABC Forsikring":'#34425F',
"Tryg Pointshop":'#6B3D68',
"Arbejdernes Landsbank Jul":'#6F729F',
"Skade.dk Pointshop":'#5B0D76',
"Krifa":'#909090',
"Web3 DEMO2":'#2CA180',
"Alka Pointshop":'#C88F5A',
"WLB Demoshop":'#8B3E31',
"Web3 DEMO3":'#025880',
"Ronald":'#5A5B74',
"DMGreenkeeping":'#25408E',
"nettopower":'#00B8A9',
}


