import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import moment from 'moment'
import { toast } from 'react-toastify'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let headers = {
  userName: 'Bruger Navn',
  userSupervisor: 'KAM Navn',
  userAccess: 'Adgang',
  userCompany: 'Virksomhed',
  userUID: 'Unikt ID',
  userPhone: 'Telefon',
  userEmail: 'Email',
  userStatus: 'Bruger Status',
  dealerCVR: 'DealerCVR',
  dealerUID: 'DealerID',
  timeInsert: 'Dato',
  lastLogin: 'Last Login'
}

const ActiveInactiveUsers = props => {
  let { startDate, endDate, status } = props.match.params
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  if (status === 'Declined') {
    headers.lastDeclinedDate = 'Declined Dato'
  }
  useEffect(() => {
    setLoading(true)
    Proxies.GET('reports/active-inactive-users-within-dates-status', {
      startDate,
      endDate,
      status: status === 'All' ? '' : status
    })
      .then(responseJSON => {
        if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
          setData(
            responseJSON.map(item => {
              item.timeInsert = moment(item.timeInsert).format('YYYY-MM-DD')
              item.lastDeclinedDate = item.lastDeclinedDate ? moment(item.lastDeclinedDate).format('YYYY-MM-DD hh:mm') : ''
              item.lastLogin = item.lastLogin ? moment(item.lastLogin).format('YYYY-MM-DD hh:mm') : ''
              return item
            })
          )
        } else {
          setData([])
        }
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <FontAwesomeIcon icon='spinner' spin />
  }
  if (!data.length) {
    toast.error(`No details as on ${endDate}`, {
      autoClose: 5500,
      onClose: () => {
        window.close()
      }
    })
    return <p>No details as on {endDate}</p>
  }
  return (
    <React.Fragment>
      <CollapsibleCard
        header={
          <strong>
            {' '}
            {status === 'All' ? 'Alle' : status} Aktive & Inaktive Brugere: {startDate ? startDate + ' til ' : ''} {endDate}
          </strong>
        }
        startOpen={data.length ? true : false}
      >
        <DynamicTable data={data} headers={headers} />
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default ActiveInactiveUsers
