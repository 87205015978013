/* eslint-disable eqeqeq */
import Reflux, { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import moment from 'moment'
import { toast } from 'react-toastify'

export const PostActions = createActions([
  'getPosts',
  'getPostDetails',
  'createEmptyPost',
  'clearPost',
  'updatePostField',
  'addAdditionalInput',
  'savePost',
  'deletePost',
  'enablePost',
  'deleteImage',
  'selectFile',
  'uploadFile',
  'clearToast',
  'toggleQueryContent',
  'copyPost',
  'getBlogs',
  'preserveAdvanceSearch',
  'setDefaultBlogID',
  'setNotification',
  'toggleInActivePost',
  'toggleActivePost',
  'setCardImage',
  'setBannerImage',
  'setHomeBannerImage',
  'setBlogDetails',
  'getGroups',
  'toggleGroup',
  'videoFileUploaded',
  'deleteVideo',
  'updateData',
  'getLanguages',
  'removeTranslation',
  'addTranslation'
])

export class PostStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = PostActions
    this.state = {
      posts: [],
      blogs: [],
      postDetails: {
        translations: [
          {
            LanguageID: null,
            elementContent: '',
            elementTitle: ''
          }
        ]
      },
      isSaving: false,
      fetchingPosts: false,
      groups: [],
      fetchingGroups: false,
      currBlogID: 0,
      currBlog: null,
      file: null,
      showPostError: false,
      showPostSuccess: false,
      postTypes: ['DEFAULT', 'IMPORTANT'],
      notificationDisabled: false,
      advanceSearchFilters: [],
      showActivePosts: true,
      showInActivePosts: false,
      postFilterValue: 'active',
      groupFilterText: '',
      filteredGroupData: [],
      languages: []
    }
  }
  getGroups(blog = null) {
    if (!blog) {
      return false
    }
    if (blog.groups && blog.groups.length) {
      this.setState({ groups: blog.groups })
      return true
    }
    if (this.state.fetchingGroups) {
      return false
    }
    this.setState({ fetchingGroups: true })
    if (blog.BrandID && Reflux.GlobalState.LoginStore.settings && Reflux.GlobalState.LoginStore.settings.BrandID === 9) {
      GET('groups/fetch-groups', { BrandID: blog.BrandID }).then(responseJSON => {
        let nextState = {
          groups: [],
          fetchingGroups: false
        }
        if (responseJSON) {
          nextState.groups = responseJSON
        }
        this.setState(nextState)
      })
    } else {
      GET('schemas/groups').then(responseJSON => {
        let nextState = {
          groups: [],
          fetchingGroups: false
        }
        if (responseJSON) {
          nextState.groups = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  toggleGroup(checkbox) {
    let GroupID = checkbox.target.name
    let checked = checkbox.target.checked
    let postDetails = this.state.postDetails
    if (checked) {
      let thisGroup = this.state.groups.find(e => {
        return e.GroupID == GroupID
      })
      if (postDetails.groups) {
        postDetails.groups.push(thisGroup)
      } else {
        postDetails.groups = [thisGroup]
      }
    } else {
      postDetails.groups = postDetails.groups.filter(e => {
        return e.GroupID != GroupID
      })
    }
    this.setState({ postDetails: postDetails })
  }
  setBlogDetails(BlogID = this.state.currBlogID || null) {
    if (!BlogID || isNaN(BlogID)) return
    if (!this.state || !this.state.blogs || !this.state.blogs.length) return

    const blogDetails = this.state.blogs.filter(item => item.BlogID === BlogID)
    this.getGroups(blogDetails[0])
    this.setState({ currBlog: blogDetails[0] || null })
  }
  setHomeBannerImage(imageUrl) {
    const post = { ...this.state.postDetails, homeBannerImage: imageUrl }
    this.setState({ postDetails: post })
  }
  setCardImage(imageUrl) {
    const post = { ...this.state.postDetails, cardImage: imageUrl }
    this.setState({ postDetails: post })
  }
  setBannerImage(imageUrl) {
    const post = { ...this.state.postDetails, bannerImage: imageUrl }
    this.setState({ postDetails: post })
  }
  clearToast() {
    this.setState({ showPostError: false, showPostSuccess: false })
  }
  createEmptyPost() {
    this.setState({
      postDetails: {
        timeStart: moment().format(),
        notification: {
          title: '',
          text: '',
          type: '',
          scheduledDate: '',
          scheduledTime: '',
          isProcessed: 0
        },
        translations: [
          {
            LanguageID: null,
            elementContent: '',
            elementTitle: ''
          }
        ]
      },
      notificationDisabled: false,
      groups: []
    })
  }
  setDefaultBlogID(blogIdFromUrl = null) {
    if (blogIdFromUrl) {
      this.setBlogDetails(Number(blogIdFromUrl))
      this.setState({ currBlogID: Number(blogIdFromUrl) })
    }
  }
  clearPost() {
    this.createEmptyPost()
  }
  copyPost(post, props) {
    props.history.push(props.location.pathname + '/new?copy=' + post.PostID)
  }
  getPosts(BlogID) {
    if (BlogID) {
      this.setState({ fetchingPosts: true, currBlogID: BlogID })
      GET('post/pool', { BlogID: BlogID, forAdminOverview: true }).then(responseJSON => {
        let nextState = {
          posts: [],
          fetchingPosts: false
        }
        if (responseJSON) {
          responseJSON = responseJSON.filter(item => {
            item.elementContent && item.elementContent.length > 250 ? (item.descriptionReadMore = true) : (item.descriptionReadLess = false)
            return !isNaN(item.PostID)
          })
          nextState.posts = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  getBlogs() {
    this.setState({ fetchingBlogs: true })
    GET('blogs/adminPool').then(responseJSON => {
      let nextState = {
        blogs: []
      }
      if (responseJSON) {
        responseJSON = responseJSON.filter(e => {
          return !isNaN(e.BlogID)
        })
        // Sort by Brand ID and then Blog Name within it
        responseJSON = responseJSON.sort((a, b) => (a.moduleName.toLowerCase() < b.moduleName.toLowerCase() ? 1 : -1))
        nextState.blogs = responseJSON.sort((a, b) => (a.BrandID > b.BrandID ? 1 : -1))
      }
      this.setState(nextState)
      this.setBlogDetails()
    })
  }
  getPostDetails(PostID, copy = false) {
    this.setState({ fetchingPosts: true })
    GET('post/fetch', { PostID: PostID, forAdminOverview: true }).then(responseJSON => {
      let nextState = {
        postDetails: {},
        fetchingPosts: false
      }
      if (responseJSON) {
        if (responseJSON.translations && !responseJSON.translations.length) {
          responseJSON.translations = [
            {
              LanguageID: null,
              elementTitle: '',
              elementContent: ''
            }
          ]
        }
        this.getLanguages(responseJSON.BrandID)
        responseJSON.attachments = responseJSON.attachments
          ? responseJSON.attachments.filter(e => {
              return e.ImageID
            })
          : []
        nextState.postDetails = responseJSON
        if (!responseJSON.notification || !responseJSON.notification.PushNotificationID) {
          nextState.postDetails.notification = {
            title: '',
            text: '',
            type: '',
            scheduledDate: '',
            scheduledTime: '',
            isProcessed: 1
          }
        }

        nextState.currBlogID = responseJSON.BlogID
        if (copy) {
          nextState.postDetails.PostID = null
        }
        if (nextState.postDetails.notification.isProcessed && !copy) {
          this.setState({ notificationDisabled: true })
        }
      }
      this.setState(nextState)
    })
  }

  getLanguages(BrandID) {
    GET('brands/brand-languages', { BrandIDs: [BrandID] }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        this.setState({ languages: responseJSON })
      }
    })
  }

  removeTranslation = i => {
    let translations = this.state.postDetails.translations
    if (translations[i].BlogTranslationID) {
      translations[i].removed = 1
      if (translations.length === 1) {
        translations.push({
          LanguageID: null,
          elementTitle: '',
          elementContent: ''
        })
      }
    } else {
      translations.splice(i, 1)
      if (!translations.length) {
        translations.push({
          LanguageID: null,
          elementTitle: '',
          elementContent: ''
        })
      } else if (translations.filter(translation => translation.BlogTranslationID).length) {
        translations.push({
          LanguageID: null,
          elementTitle: '',
          elementContent: ''
        })
      }
    }
    this.setState({ postDetails: { ...this.state.postDetails, translations: translations } })
  }

  addTranslation = () => {
    let translations = this.state.postDetails.translations
    translations.push({ elementTitle: '', elementContent: '', LanguageID: null })
    this.setState({ postDetails: { ...this.state.postDetails, translations: translations } })
  }

  updateData(field, index, type) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let postDetails = this.state.postDetails
    let column = postDetails[type][index]

    if (fieldName === 'LanguageID' && type === 'translations') {
      fieldValue = Number(fieldValue)
    }

    if (!column[fieldName]) {
      column[fieldName] = ''
    }

    column[fieldName] = fieldValue

    this.setState({ postDetails })
  }

  addAdditionalInput() {
    let postDetails = this.state.postDetails
    if (!postDetails?.additionalInput) {
      postDetails.additionalInput = {
        data: []
      }
    }
    let data = this.state.postDetails?.additionalInput?.data || []
    data.push({
      valueType: '',
      inputValue: '',
      inputText: ''
    })
    postDetails.additionalInput.data = data
    // console.log(postDetails)
    return this.setState({ postDetails: postDetails })
  }
  updatePostField(field, index) {
    let name = field.target.name
    let value = field.target.value
    let postDetails = this.state.postDetails
    if (!!name && name.includes('additionalInput')) {
      const key = name.replace('additionalInput-', '')
      if (key === 'captionText') {
        postDetails.additionalInput.captionText = value
        return this.setState({ postDetails: postDetails })
      }
      if (key === 'helpText1') {
        postDetails.additionalInput.helpText1 = value
        return this.setState({ postDetails: postDetails })
      }
      if (isNaN(index)) return
      let additionalInput = {}
      additionalInput.data = this.state.postDetails.additionalInput.data || []
      const currentValue = additionalInput.data[index] || {}
      const newValue = {}
      newValue[key] = value
      additionalInput.data[index] = { ...currentValue, ...newValue }
      /*
      const rootKey = this.state.postDetails.taxCalculation ? "taxCalculation" : "others"
      const tempObj = {}
      
      if(!additionalInput){
        tempObj[rootKey] = [""]
        additionalInput = {...tempObj}
      } 
      additionalInput[rootKey][index] = value
      */
      postDetails['additionalInput'] = additionalInput
      // console.log(postDetails)
      return this.setState({ postDetails: postDetails })
    }
    if (name === 'poolOrder') {
      value = Number(value)
    }
    if (name === 'BlogID') {
      value = Number(value)
      this.setState({ currBlogID: value })
      this.setBlogDetails(value)
    }
    postDetails = Object.assign({ BlogID: this.state.currBlogID }, this.state.postDetails)
    postDetails[name] = value
    if (name === 'groupFilterText') {
      const groupsFilter = value ? this.state.groups.filter(item => item.moduleName.toLowerCase().includes(value.toLowerCase())) : this.state.groups
      this.setState({ filteredGroupData: groupsFilter })
    }
    this.setState({ postDetails: postDetails })
  }
  savePost() {
    if (this.state.postDetails.moduleName != '') {
      let postDetails = Object.assign({ BlogID: this.state.currBlogID }, this.state.postDetails)
      postDetails.link = postDetails.link || ''
      let attachments = []
      if (
        postDetails.notification &&
        postDetails.notification.type &&
        postDetails.notification.type === 'scheduled' &&
        !postDetails.notification.scheduledDate
      ) {
        toast.error('Scheduled notification needs Date value', {
          autoClose: 3000
        })
        return false
      }
      if (postDetails.notification && !postDetails.notification.type) {
        postDetails.notification.type = 'IMMEDIATE'
      }
      if (postDetails.attachments) {
        postDetails.attachments.forEach(e => {
          attachments.push({
            id: e.ImageID,
            type: 'Image'
          })
        })
      }
      const isNew = postDetails.PostID ? false : true
      postDetails.attachments = attachments
      let allowNotification = false
      if (postDetails.translations) {
        postDetails.translations = postDetails.translations.filter(translation => translation.LanguageID !== null && translation.elementTitle !== '')
      }
      if (
        Reflux.GlobalState.LoginStore.settings &&
        Reflux.GlobalState.LoginStore.settings.features &&
        Reflux.GlobalState.LoginStore.settings.features.mobile &&
        Reflux.GlobalState.LoginStore.settings.features.mobile.pushNotification
      ) {
        allowNotification = true
      }
      if (!allowNotification) {
        postDetails.notification = []
      }
      this.setState({ isSaving: true })
      POST('post/replace', postDetails).then(responseJSON => {
        let nextState = {
          isSaving: false,
          postDetails: {},
          posts: this.state.posts,
          showPostSuccess: false,
          showPostError: false
        }

        if (isNew) {
          // nextState.postDetails.notification.text = ''
          // nextState.postDetails.notification.title = ''
        }

        if (responseJSON) {
          if (responseJSON.error) {
            nextState.showPostError = true
            nextState.postDetails = postDetails
          } else {
            nextState.postDetails = responseJSON
            if (responseJSON.notification.isProcessed) {
              this.setState({ notificationDisabled: true })
            }
            if (nextState.posts.length > 0) {
              let found = false
              for (let i in nextState.posts) {
                if (nextState.posts[i].PostID == responseJSON.PostID) {
                  nextState.posts[i] = responseJSON
                  found = true
                }
              }
              if (!found) {
                nextState.posts.push(responseJSON)
              }
            }
            nextState.showPostSuccess = true
          }
        }
        this.setState(nextState)
      })
    }
  }
  deleteImage(ImageID = null) {
    if (ImageID) {
      let postDetails = this.state.postDetails
      postDetails.attachments = postDetails.attachments.filter(e => {
        return e.ImageID != ImageID
      })
      this.setState({ postDetails: postDetails })
    }
  }
  deletePost(PostID = null) {
    if (PostID) {
      POST('post/remove', { PostID: PostID }).then(() => {
        this.getPosts(this.state.currBlogID)
      })
    }
  }
  enablePost(PostID = null) {
    if (PostID) {
      POST('post/enable', { PostID: PostID }).then(() => {
        this.getPosts(this.state.currBlogID)
      })
    }
  }
  selectFile(data) {
    let file = data.target.files[0]
    this.setState({ file: file, isUploading: true })
    // uploaded file size should not be more than 50MB
    // TO DO - make a generalised limit constant that can be accessed to determine max size.
    if (file && file.size && file.size >= 50 * 1024 * 1024) {
      this.showFileSizeError()
    } else {
      let BrandID = this.state.postDetails && this.state.postDetails.BrandID ? this.state.postDetails.BrandID : ''
      if (!BrandID && this.state.currBlogID && this.state.blogs.length > 0) {
        let BrandIDArray = this.state.blogs.filter(elem => this.state.currBlogID === elem.BlogID).map(item => item.BrandID)
        BrandID = BrandIDArray.length > 0 ? BrandIDArray[0] : ''
      }
      Proxies.uploadImage(file, BrandID).then(responseJSON => {
        if (responseJSON.error && responseJSON.error.statusCode && responseJSON.error.statusCode === 413) {
          this.showFileSizeError() // TO DO - pass max allowed size from API to UI
        } else {
          let nextState = {
            file: null,
            isUploading: false,
            postDetails: this.state.postDetails
          }
          let newAttachments = nextState.postDetails.attachments ? this.state.postDetails.attachments : []
          nextState.postDetails.attachments = newAttachments.concat(responseJSON)
          this.setState(nextState)
        }
      })
    }
  }
  uploadFile() {
    this.setState({ file: null })
  }
  showFileSizeError() {
    toast.error('Den valgte fil overstiger maksimum størrelsen på 50MB', {
      autoClose: 3000,
      onClose: () => {
        this.clearToast()
        let nextState = {
          file: null,
          isUploading: false,
          postDetails: this.state.postDetails
        }
        this.setState(nextState)
      }
    })
  }
  toggleQueryContent(e) {
    let posts = this.state.posts
    posts = posts.map(item => {
      if (item.PostID === e.PostID) {
        item.descriptionReadLess = !item.descriptionReadLess
        item.descriptionReadMore = !item.descriptionReadMore
      }
      return item
    })
    this.setState({ posts })
  }
  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
  setNotification(event) {
    let title = this.state.postDetails && this.state.postDetails.notification.title ? this.state.postDetails.notification.title : ''
    let text = this.state.postDetails && this.state.postDetails.notification.text ? this.state.postDetails.notification.text : ''
    let type = this.state.postDetails && this.state.postDetails.notification.type ? this.state.postDetails.notification.type : ''
    let date = this.state.postDetails && this.state.postDetails.notification.scheduledDate ? this.state.postDetails.notification.scheduledDate : ''
    let time = this.state.postDetails && this.state.postDetails.notification.scheduledTime ? this.state.postDetails.notification.scheduledTime : ''

    let fieldName = event.target.name
    if (fieldName) {
      switch (fieldName.toLowerCase()) {
        case 'notificationtitle':
          title = event.target.value
          break
        case 'notificationtext':
          text = event.target.value
          break
        case 'notificationtype':
          type = event.target.value.toUpperCase()
          break
        case 'notificationdate':
          date = event.target.value
          break
        case 'notificationtime':
          time = event.target.value
          break
        default:
          break
      }
      let notification = {
        title: title,
        text: text,
        type: type,
        scheduledDate: date,
        scheduledTime: time
      }
      let postDetails = this.state.postDetails
      postDetails.notification = { ...postDetails.notification, ...notification }

      this.setState({ postDetails: postDetails })
    }
  }
  toggleActivePost() {
    let value = !this.state.showActivePosts ? 'active' : 'both'
    this.setState({ showActivePosts: !this.state.showActivePosts, postFilterValue: value, showInActivePosts: false })
  }
  toggleInActivePost() {
    let value = this.state.showInActivePosts ? 'both' : 'inactive'
    this.setState({ showInActivePosts: !this.state.showInActivePosts, postFilterValue: value, showActivePosts: false })
  }
  videoFileUploaded(responseJSON) {
    let postDetails = this.state.postDetails
    postDetails.videoSrc = responseJSON || ''
    this.setState({ postDetails })
  }
  deleteVideo() {
    let postDetails = this.state.postDetails
    postDetails.videoSrc = ''
    this.setState({ postDetails })
  }
}
