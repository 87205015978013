import React from 'react'
import _ from 'lodash'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import TotalPointsChart from 'Components/dashboard/charts/TotalPointsChart.js'

export default function PointsEarnedTotalChart() {
  const [data, loadingState] = useDashboardData('reports/wallet-points-earned')

  return (
    <TotalPointsChart
      header='Total optjente point'
      state={loadingState}
      data={_.map(_.get(data, 'overallTotals[0]'), (value, key) => {
        if (key === 'manual') {
          return { id: 'Manuel', label: 'Manuel', value }
        } else {
          return { id: _.capitalize(key), label: _.capitalize(key), value }
        }
      })}
      applyFilters={true}
    />
  )
}
