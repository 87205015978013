import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/dataImportActions'

export const dataImportSlice = createSlice({
  name: 'dataImport',
  initialState: {
    parsedData: null,
    inputHeaders: [],
    batchCreationSuccessful: false,
    batchCreationFailed: false,
    batchOverviewData: [],
    batchElements: [],
    fetchingBatchElements: false,
    batchDetails: {},
    fetchingBatchDetails: false
  },
  reducers: {
    dataParse: (state, action) => {
      state.parsedData = action.payload
    },
    setInputHeaders: (state, action) => {
      state.inputHeaders = action.payload
    },
    batchCreationStatusReset: state => {
      state.batchCreationSuccessful = false
      state.batchCreationFailed = false
      state.parsedData = null
    },
    batchCreationSuccess: state => {
      state.batchCreationSuccessful = true
    },
    batchCreationFailure: state => {
      state.batchCreationFailed = true
    },
    setBatchOverviewData: (state, action) => {
      state.batchOverviewData = action.payload
    },
    resetBatchOverviewData: state => {
      state.batchOverviewData = []
    },
    fetchBatchElementsRequest: state => {
      state.fetchingBatchElements = STATUS.LOADING_BATCH_DETAILS
      state.batchElements = []
    },
    setBatchElements: (state, action) => {
      state.fetchingBatchElements = STATUS.LOADING_BATCH_DETAILS_SUCCESS
      state.batchElements = action.payload.validatedCsvRecords
    },
    fetchBatchElementsFailed: state => {
      state.fetchingBatchElements = STATUS.LOADING_BATCH_DETAILS_FAILED
    },
    batchInsertInitiated: (state, action) => {
      state.batchInsertStatus = STATUS.INSERT_BATCH_PROGRESS
      state.batchBeingProcessed = action.payload
    },
    batchInsertSuccess: state => {
      state.batchInsertStatus = STATUS.INSERT_BATCH_SUCCESS
    },
    batchInsertFailure: (state, action) => {
      state.batchInsertStatus = STATUS.INSERT_BATCH_FAILURE
      state.batchInsertError = action.payload
    },
    setBatchDetails: (state, action) => {
      state.fetchingBatchDetails = STATUS.FETCHING_BATCH_DETAILS_SUCCESS
      state.batchDetails = action.payload
    },
    fetchingBatchDetailsFailed: state => {
      state.fetchingBatchDetails = STATUS.FETCHING_BATCH_DETAILS_FAILED
    },
    fetchingBatchDetailsRequest: state => {
      state.fetchingBatchDetails = STATUS.FETCHING_BATCH_DETAILS
      state.batchDetails = []
    },
    resetBatchInsertStates: state => {
      state.batchInsertStatus = false
      state.batchBeingProcessed = false
      state.batchInsertError = false
    },
    resetImportForm: state => {
      ;(state.inputHeaders = []), (state.parsedData = null)
    }
  }
})

export const {
  dataParse,
  setInputHeaders,
  batchCreationSuccess,
  batchCreationFailure,
  batchCreationStatusReset,
  setBatchOverviewData,
  resetBatchOverviewData,
  fetchBatchElementsRequest,
  setBatchElements,
  fetchBatchElementsFailed,
  batchInsertInitiated,
  batchInsertSuccess,
  batchInsertFailure,
  setBatchDetails,
  fetchingBatchDetailsFailed,
  fetchingBatchDetailsRequest,
  resetBatchInsertStates,
  resetImportForm
} = dataImportSlice.actions

export default dataImportSlice.reducer
