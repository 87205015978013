import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, FormGroup, Input, Label, Col, Row } from 'reactstrap'
import _ from 'lodash'
import { MenuActions, MenuStore, STATUS } from 'Stores/menuStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'

export default class MenuForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [MenuStore]
    this.storeKeys = ['status', 'menuDetails', 'brands', 'poolNames', 'languages']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.MenuID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      MenuActions.getMenuDetails(this.props.match.params.MenuID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      MenuActions.createEmptyMenu()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.state.menuDetails.poolName ? true : false,
      isTitleValid: this.state.menuDetails.menuTitle ? true : false,
      isBrandIdValid: this.state.menuDetails.BrandID ? true : false
    }

    this.setState({ fieldValidation })

    const { isNameValid, isTitleValid, isBrandIdValid } = fieldValidation

    return isNameValid && isTitleValid && isBrandIdValid
  }

  saveMenuForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      MenuActions.saveMenu()
    }
  }

  validateBrandID() {
    if (this.state.menuDetails.BrandID) {
      return true
    } else {
      return false
    }
  }

  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/menu' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Menu</strong>}>
          <Form>
            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='poolName'> Pool Name </Label>
                </Col>
                <Col xs='3'>
                  <Input
                    className={this.state.menuDetails.poolName ? 'is-valid' : 'is-invalid'}
                    type='text'
                    name='poolName'
                    placeholder='Select from the list beside or enter new name'
                    value={this.state.menuDetails.poolName}
                    id='poolName'
                    onChange={e => {
                      MenuActions.updateMenuField(e)
                      this.validateForm()
                    }}
                  ></Input>
                </Col>
                <Col xs='3'>
                  <Input
                    className={this.state.menuDetails.poolName ? 'is-valid' : 'is-invalid'}
                    type='select'
                    name='poolName'
                    value={this.state.menuDetails.poolName}
                    id='poolName'
                    onChange={e => {
                      MenuActions.updateMenuField(e)
                      this.validateForm()
                    }}
                  >
                    <option value=''>Click to Select</option>
                    {this.state.poolNames.map((e, i) => {
                      return (
                        <option value={e.poolName} key={i}>
                          {e.poolName}
                        </option>
                      )
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormField
              required
              data={this.state.menuDetails}
              field={'menuTitle'}
              name='Title'
              fieldWidth='6'
              onChange={e => {
                MenuActions.updateMenuField(e)
                // this.validateForm()
              }}
            />

            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='BrandID'> Brand ID </Label>
                </Col>
                <Col xs='6'>
                  <Input
                    className={this.validateBrandID() ? 'is-valid' : 'is-invalid'}
                    required
                    type='select'
                    name='BrandID'
                    value={Number(this.state.menuDetails.BrandID)}
                    id='BrandID'
                    onChange={e => {
                      MenuActions.updateMenuField(e)
                      this.validateForm()
                      MenuActions.getLanguages(e.target.value)
                    }}
                  >
                    <option value=''>Click to Select</option>
                    {this.state.brands.map((e, i) => {
                      return (
                        <option value={Number(e.BrandID)} key={i}>
                          {e.BrandID} - {e.brandName}
                        </option>
                      )
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormField
              type='number'
              data={this.state.menuDetails}
              field={'poolOrder'}
              name='Order'
              value={this.state.menuDetails.poolOrder ? Number(this.state.menuDetails.poolOrder) : 0}
              fieldWidth='3'
              onChange={e => {
                MenuActions.updateMenuField(e)
                // this.validateForm()
              }}
            />

            <FormField
              type='date'
              data={this.state.menuDetails}
              field={'timeStart'}
              name='Start Date'
              fieldWidth='3'
              onChange={e => {
                MenuActions.updateMenuField(e)
                this.validateForm()
              }}
            />

            <FormField
              type='date'
              data={this.state.menuDetails}
              field={'timeEnd'}
              name='End Date'
              fieldWidth='3'
              onChange={e => {
                MenuActions.updateMenuField(e)
                this.validateForm()
              }}
            />

            {!!this.state.menuDetails.BrandID && !!this.state.languages && !!(this.state.languages.length > 1) && (
              <FormGroup row>
                <Label for='textColor' sm={3}>
                  Translations
                </Label>
                <Col sm={9}>
                  {!!this.state.menuDetails &&
                    !!this.state.menuDetails.translations &&
                    !!this.state.menuDetails.translations.length &&
                    this.state.menuDetails.translations.map((translation, inx) => {
                      return (
                        !translation.removed && (
                          <div key={inx}>
                            <Row>
                              <Col sm={11}>
                                <Input
                                  type='select'
                                  name='LanguageID'
                                  value={translation.LanguageID ? Number(translation.LanguageID) : ''}
                                  id='LanguageID'
                                  onChange={e => MenuActions.updateData(e, inx, 'translations')}
                                >
                                  <option value='' disabled>
                                    Click to Select
                                  </option>
                                  {!!this.state.languages &&
                                    !!this.state.languages.length &&
                                    this.state.languages.map((e, i) => {
                                      return (
                                        <option value={Number(e.LanguageID)} key={i}>
                                          {e.languageName}
                                        </option>
                                      )
                                    })}
                                </Input>
                              </Col>
                              <Col sm={1}>
                                <Button onClick={() => MenuActions.removeTranslation(inx)} color={'danger'}>
                                  <FontAwesomeIcon icon={'minus'} />
                                </Button>
                              </Col>
                            </Row>
                            <br></br>
                            <Row>
                              <Label for='textColor' sm={2}>
                                Title
                              </Label>
                              <Col sm={9}>
                                <Input
                                  name='menuTitle'
                                  value={translation.menuTitle}
                                  type='text'
                                  onChange={e => MenuActions.updateData(e, inx, 'translations')}
                                />
                              </Col>
                              <Col sm={1}>
                                <Button onClick={MenuActions.addTranslation}>
                                  <FontAwesomeIcon icon='plus' />
                                </Button>
                              </Col>
                            </Row>
                            <br></br>
                          </div>
                        )
                      )
                    })}
                </Col>
              </FormGroup>
            )}

            <br />

            <Button onClick={this.saveMenuForm} disabled={this.state.status === STATUS.IS_SAVING}>
              {this.state.status === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
