import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import FormField from 'Components/formField'
import Proxies from './proxies'
import moment from 'moment'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { GET, POST } = Proxies

const Comment = props => {
  const { CampaignID } = props
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(null)

  const fetchComments = useCallback(() => {
    if (!CampaignID) return
    GET('notificationcampaigncomment/fetch', { NotificationCampaignID: Number(CampaignID) }).then(responseJSON => {
      if (responseJSON.error) return setComments([])
      return setComments(responseJSON)
    })
  }, [CampaignID])

  const createComment = () => {
    setLoading(true)
    const commentInfo = {
      NotificationCampaignID: Number(CampaignID),
      comment: comment
    }

    POST(`notificationcampaigncomment/replace`, { ...commentInfo }).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      fetchComments()
      setComment('')
      toast.success(`Campaign ${!isNaN(CampaignID) ? 'updated' : 'created'} successfully`, {
        autoClose: 7000
      })
    })
  }

  useEffect(() => {
    fetchComments()
  }, [CampaignID])

  return (
    <div style={{ backgroundColor: '#ffffff', width: '100%', padding: 20, marginTop: 22, borderRadius: 5, marginBottom: 20 }}>
      <label style={{ fontSize: 18 }}>Tilføj kommentar</label>
      <FormField type='textarea' rows={5} fieldWidth='12' data={{ comment }} field={'comment'} onChange={e => setComment(e.target.value || '')} />
      <Button
        disabled={CampaignID ? false : true}
        style={{ width: '100%', marginBottom: comments && comments.length ? 50 : 0 }}
        onClick={CampaignID && createComment}
      >
        {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Tilføj kommentar'}
      </Button>
      {comments && comments.length
        ? comments.map(comment => {
            return (
              <div
                key={comment.NotificationCampaignCommentID}
                style={{
                  borderLeft: '2px solid',
                  borderLeftColor: 'grey',
                  height: 50,
                  width: '100%',
                  position: 'relative',
                  paddingLeft: 15,
                  paddingTop: 15,
                  paddingBottom: 110
                }}
              >
                <div style={{ width: 8, height: 8, borderRadius: 50, backgroundColor: '#28D094', position: 'absolute', left: -5, top: 0 }}></div>
                <div style={{ fontSize: 17, fontColor: 'black', fontWeight: '600', position: 'absolute', top: -10 }}>
                  {comment && comment.user && comment.user.userName}
                </div>
                <div style={{ fontSize: 16, fontColor: 'black', marginBottom: 10 }}>{comment.comment}</div>
                <div style={{ fontSize: 14 }}>{moment(comment.timeInsert).format('D/M/YYYY, hh:mm')}</div>
              </div>
            )
          })
        : null}
    </div>
  )
}

export default Comment
