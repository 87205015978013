import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { Form, Button, FormGroup, Label, Col, Input, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import 'react-image-crop/dist/ReactCrop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Proxies from 'Components/proxies'

const pointsGridLabelsDefault = {
  totalPointsLabel: 'Dine totale point',
  currentMonthPointsLabel: 'Indeværende måned',
  lastMonthPointsLabel: 'Sidste måned',
  thisYearPointsLabel: 'År til dato',
  lastYearPointsLabel: 'Sidste år'
}
const activationPointsGridLabelsDefault = {
  leftBoxLabel: 'Aktiverede point til brug i shoppen',
  leftBoxUnitLabel: 'point',
  leftBoxButtonLabel: 'Gå till shop',
  middleBoxLabel: 'Point - Klar til aktivering',
  middleBoxUnitLabel: 'point',
  middleBoxButtonLabel: 'Aktiver point',
  rightBoxLabel: 'Hvor meget kan jeg shoppe for. når alle mine point er aktiveret?',
  rightBoxUnitLabel: 'point',
  rightBoxButtonLabel: 'Allerede aktiverede point + tilgængelige point',
  lineOneStartText: 'Point aktiveret i indeværende år:',
  lineOneEndText: 'Værdi af point aktiveret i indeværende år (Indberettes til SKAT)',
  lineTwoStartText: 'Tilgængelige point til aktivering:',
  lineTwoEndText: 'Værdi af tilgængelige point til aktivering',
  lineThreeStartText: 'Beregn din forventede skat for aktiverede point i indeværende år',
  lineThreeCenterText: 'Indtast din trækprocent:',
  lineThreeEndText: 'Din skat er beregnet til:'
}
const activationPointsSectionLabelsDefault = {
  titleText: 'Aktivering af point',
  noPointsTitleText: 'Ingen point endnu',
  noPointsContentText: 'Når der sker en transaktion, vil du se det her.',
  automaticActivationRemarkText: 'Aktivér altid point automatisk:',
  automaticActivationLinkText: 'Hvad betyder dette?',
  sectionBoxTitleText: 'Point til aktivering',
  columnOneLabel: 'Note',
  columnTwoLabel: 'Point',
  columnThreeLabel: 'Sum',
  columnFourLabel: 'Dato',
  columnFiveLabel: 'Status',
  statusWaitingText: 'Aktivér point',
  statusActivatedText: 'Aktiveret',
  statusExpiredText: 'Udløbet',
  popupTitleText: 'Bekræft aktivering',
  popupContentText: `Er du sikker på, at du vil aktivere {{walletAmountPoints}} point?`
}
const pointsSectionLabelsDefault = {
  titleText: 'Dine point',
  noPointsTitleText: 'Ingen transaktion i din pung endnu',
  noPointsContentText: 'Når der sker en transaktion, vil du se det her.',
  sectionBoxTitleText: 'Point oversigt',
  columnOneLabel: 'Note',
  columnTwoLabel: 'Point',
  columnThreeLabel: 'Sum',
  columnFourLabel: 'Dato'
}
const statisticsSectionActivationLabelsDefault = {
  titleText: 'Din statistik',
  monthLabels: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December']
}
const statisticsSectionLabelsDefault = {
  titleText: 'Din statistik',
  subtitlePageOneText: 'For sidste måned',
  subtitlePageTwoText: 'For de sidste 2 måneder',
  subtitlePageThreeText: 'For de sidste 3 måneder',
  personalRecordTitleText: 'Ny personlige rekord!',
  personalRecordSubtitleText: 'Personlig rekord',
  personalGoalTitleText: 'Det personlige mål',
  personalGoalSubtitleText: 'Denne måneds mål:',
  youAreMissingText: 'Du mangler'
}

const CPRPopupLabelsDefault = {
  CPRPopUpApprovalText: `CPR nr. skal indtastes for bestilling af præmier. Værdi af point oplyses til SKAT, se mere i `,
  CPRPopUpTermsLinkText: 'betingelser',
  CPRPopUpTermsURL: 'https://drive.google.com/file/d/1ug6LZTtOPZ6AvlFnahxnJ4GGnpxSAmn2/view?usp=sharing',
  CPRPopUpCheckboxText: `Jeg godkender hermed, at værdien af mine point oplyses til SKAT.`
}

const sendPointsAndLoanLabelsDefault = {
  sendPointsButtonText: 'Send/lån point',
  popupTitleText: 'Send eller udlån point',
  popupContentText: 'Send point som lån eller gave',
  popupFieldOneText: 'Modtagers navn eller e-mail',
  popupFieldTwoText: 'Antal point',
  popupCheckboxText: 'Send point som lån',
  popupLoanHelperText:
    'Sender du point som et lån, vil pointene blive ført tilbage til din konto så snart der er dækning på modtagerens konto. Såfremt at du ikke vælger Send som lån, anses dette som en gave.',
  popupAcceptButtonText: 'Godkend',
  popupCloseButtonText: 'Luk'
}

export default function ConfigureLabels() {
  const [customisation, setCustomisation] = useState(null)
  const [pointsGridLabels, setPointsGridLabels] = useState({})
  const [activationPointsGridLabels, setActivationPointsGridLabels] = useState({})
  const [activationPointsSectionLabels, setActivationPointsSectionLabels] = useState({})
  const [pointsSectionLabels, setPointsSectionLabels] = useState({})
  const [statisticsSectionActivationLabels, setStatisticsSectionActivationLabels] = useState({})
  const [statisticsSectionLabels, setStatisticsSectionLabels] = useState({})
  const [CPRPopupLabels, setCPRPopupLabels] = useState({})
  const [sendPointsAndLoanLabels, setSendPointsAndLoanLabels] = useState({})
  const [loading, setLoading] = useState(false)
  const [savingData, setSavingData] = useState(false)
  const langKey = 'dk'

  const saveData = module => {
    let customisationReq = {}
    if (customisation) {
      let customisationData = JSON.parse(customisation.customisationData)
      if (!customisationData.features.upgraderClubConfigurableLabels[langKey].homePageLabels) {
        customisationData.features.upgraderClubConfigurableLabels[langKey].homePageLabels = {}
      }
      if (module === 'pointsGridLabels') {
        customisationData.features.upgraderClubConfigurableLabels[langKey].profilePageLabels.pointsGridLabels = pointsGridLabels
      } else if (module === 'activationPointsGridLabels') {
        customisationData.features.upgraderClubConfigurableLabels[langKey].profilePageLabels.activationPointsGridLabels = activationPointsGridLabels
      } else if (module === 'activationPointsSectionLabels') {
        customisationData.features.upgraderClubConfigurableLabels[
          langKey
        ].profilePageLabels.activationPointsSectionLabels = activationPointsSectionLabels
      } else if (module === 'pointsSectionLabels') {
        customisationData.features.upgraderClubConfigurableLabels[langKey].profilePageLabels.pointsSectionLabels = pointsSectionLabels
      } else if (module === 'statisticsSectionActivationLabels') {
        customisationData.features.upgraderClubConfigurableLabels[
          langKey
        ].profilePageLabels.statisticsSectionActivationLabels = statisticsSectionActivationLabels
      } else if (module === 'statisticsSectionLabels') {
        customisationData.features.upgraderClubConfigurableLabels[langKey].profilePageLabels.statisticsSectionLabels = statisticsSectionLabels
      } else if (module === 'CPRPopupLabels') {
        customisationData.features.upgraderClubConfigurableLabels[langKey].homePageLabels.CPRPopupLabels = CPRPopupLabels
      } else if (module === 'sendPointsAndLoanLabels') {
        customisationData.features.upgraderClubConfigurableLabels[langKey].profilePageLabels.sendPointsAndLoanLabels = sendPointsAndLoanLabels
      }
      customisationReq = {
        title: customisation.title,
        description: customisation.description,
        CustomisationID: customisation.CustomisationID,
        customisationData: JSON.stringify(customisationData)
      }
    } else {
      customisationReq = {
        title: 'upgraderClubConfigurableLabels',
        description: 'Configurable labels for upgrader club',
        customisationData: JSON.stringify({
          features: {
            upgraderClubConfigurableLabels: {
              [langKey]: {
                profilePageLabels: {
                  pointsGridLabels,
                  activationPointsGridLabels,
                  activationPointsSectionLabels,
                  pointsSectionLabels,
                  statisticsSectionActivationLabels,
                  statisticsSectionLabels,
                  sendPointsAndLoanLabels
                },
                homePageLabels: { CPRPopupLabels }
              }
            }
          }
        })
      }
    }
    setSavingData(true)
    Proxies.POST('customisationv2/replace', customisationReq)
      .then(() => {
        setSavingData(false)
        toast.success('Successfully Saved', {
          autoClose: 7500
        })
        fetchAllData()
      })
      .catch(() => {
        setSavingData(false)
      })
  }

  const fetchAllData = () => {
    Proxies.GET('customisationv2/findByTitle', { title: 'upgraderClubConfigurableLabels' }).then(responseJSON => {
      setLoading(false)
      if (responseJSON && responseJSON.customisationData) {
        setCustomisation(responseJSON)
        let customisationData = JSON.parse(responseJSON.customisationData)
        let allLabels =
          customisationData.features && customisationData.features.upgraderClubConfigurableLabels
            ? customisationData.features.upgraderClubConfigurableLabels[langKey]
            : {}
        if (allLabels.profilePageLabels && allLabels.profilePageLabels.pointsGridLabels) {
          setPointsGridLabels(allLabels.profilePageLabels.pointsGridLabels)
        } else {
          setPointsGridLabels(pointsGridLabelsDefault)
        }
        if (allLabels.profilePageLabels && allLabels.profilePageLabels.activationPointsGridLabels) {
          setActivationPointsGridLabels(allLabels.profilePageLabels.activationPointsGridLabels)
        } else {
          setActivationPointsGridLabels(activationPointsGridLabelsDefault)
        }
        if (allLabels.profilePageLabels && allLabels.profilePageLabels.activationPointsSectionLabels) {
          setActivationPointsSectionLabels(allLabels.profilePageLabels.activationPointsSectionLabels)
        } else {
          setActivationPointsSectionLabels(activationPointsSectionLabelsDefault)
        }
        if (allLabels.profilePageLabels && allLabels.profilePageLabels.pointsSectionLabels) {
          setPointsSectionLabels(allLabels.profilePageLabels.pointsSectionLabels)
        } else {
          setPointsSectionLabels(pointsSectionLabelsDefault)
        }
        if (allLabels.profilePageLabels && allLabels.profilePageLabels.statisticsSectionActivationLabels) {
          setStatisticsSectionActivationLabels(allLabels.profilePageLabels.statisticsSectionActivationLabels)
        } else {
          setStatisticsSectionActivationLabels(statisticsSectionActivationLabelsDefault)
        }
        if (allLabels.profilePageLabels && allLabels.profilePageLabels.statisticsSectionLabels) {
          setStatisticsSectionLabels(allLabels.profilePageLabels.statisticsSectionLabels)
        } else {
          setStatisticsSectionLabels(statisticsSectionLabelsDefault)
        }
        if (allLabels.homePageLabels && allLabels.homePageLabels.CPRPopupLabels) {
          setCPRPopupLabels(allLabels.homePageLabels.CPRPopupLabels)
        } else {
          setCPRPopupLabels(CPRPopupLabelsDefault)
        }
        if (allLabels.profilePageLabels && allLabels.profilePageLabels.sendPointsAndLoanLabels) {
          setSendPointsAndLoanLabels(allLabels.profilePageLabels.sendPointsAndLoanLabels)
        } else {
          setSendPointsAndLoanLabels(sendPointsAndLoanLabelsDefault)
        }
      } else {
        setPointsGridLabels(pointsGridLabelsDefault)
        setActivationPointsGridLabels(activationPointsGridLabelsDefault)
        setActivationPointsSectionLabels(activationPointsSectionLabelsDefault)
        setPointsSectionLabels(pointsSectionLabelsDefault)
        setStatisticsSectionActivationLabels(statisticsSectionActivationLabelsDefault)
        setStatisticsSectionLabels(statisticsSectionLabelsDefault)
        setCPRPopupLabels(CPRPopupLabelsDefault)
        setSendPointsAndLoanLabels(sendPointsAndLoanLabelsDefault)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchAllData()
  }, [])

  return loading ? (
    <FontAwesomeIcon icon='spinner' spin />
  ) : (
    <>
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Profile page</strong>{' '}
          </React.Fragment>
        }
      >
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Point Box Labels</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup row>
              <Label for='totalPointsLabel' sm={3}>
                {' '}
                Total points label
              </Label>
              <Col>
                <Input
                  required
                  id='totalPointsLabel'
                  className={pointsGridLabels.totalPointsLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsGridLabels.totalPointsLabel ? pointsGridLabels.totalPointsLabel : ''}
                  name='totalPointsLabel'
                  onChange={e => {
                    setPointsGridLabels({ ...pointsGridLabels, totalPointsLabel: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='currentMonthPointsLabel' sm={3}>
                {' '}
                Current Month Points Label
              </Label>
              <Col>
                <Input
                  required
                  id='currentMonthPointsLabel'
                  className={pointsGridLabels.currentMonthPointsLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsGridLabels.currentMonthPointsLabel ? pointsGridLabels.currentMonthPointsLabel : ''}
                  name='currentMonthPointsLabel'
                  onChange={e => {
                    setPointsGridLabels({ ...pointsGridLabels, currentMonthPointsLabel: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='lastMonthPointsLabel' sm={3}>
                {' '}
                Last Month Points Label
              </Label>
              <Col>
                <Input
                  required
                  id='lastMonthPointsLabel'
                  className={pointsGridLabels.lastMonthPointsLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsGridLabels.lastMonthPointsLabel ? pointsGridLabels.lastMonthPointsLabel : ''}
                  name='lastMonthPointsLabel'
                  onChange={e => {
                    setPointsGridLabels({ ...pointsGridLabels, lastMonthPointsLabel: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='thisYearPointsLabel' sm={3}>
                {' '}
                Current Year Points Label
              </Label>
              <Col>
                <Input
                  required
                  id='thisYearPointsLabel'
                  className={pointsGridLabels.thisYearPointsLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsGridLabels.thisYearPointsLabel ? pointsGridLabels.thisYearPointsLabel : ''}
                  name='thisYearPointsLabel'
                  onChange={e => {
                    setPointsGridLabels({ ...pointsGridLabels, thisYearPointsLabel: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='lastYearPointsLabel' sm={3}>
                {' '}
                Last Year Points Label
              </Label>
              <Col>
                <Input
                  required
                  id='lastYearPointsLabel'
                  className={pointsGridLabels.lastYearPointsLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsGridLabels.lastYearPointsLabel ? pointsGridLabels.lastYearPointsLabel : ''}
                  name='lastYearPointsLabel'
                  onChange={e => {
                    setPointsGridLabels({ ...pointsGridLabels, lastYearPointsLabel: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('pointsGridLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Point Box Labels - Activation</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <CollapsibleCard
              startOpen
              cardProps={{ style: { marginBottom: '-1rem', marginTop: 0 } }}
              header={
                <React.Fragment>
                  <strong>Left Box</strong>{' '}
                </React.Fragment>
              }
            >
              <FormGroup row>
                <Label for='leftBoxLabel' sm={3}>
                  {' '}
                  Left box label
                </Label>
                <Col>
                  <Input
                    required
                    id='leftBoxLabel'
                    className={activationPointsGridLabels.leftBoxLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.leftBoxLabel ? activationPointsGridLabels.leftBoxLabel : ''}
                    name='leftBoxLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, leftBoxLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='leftBoxUnitLabel' sm={3}>
                  {' '}
                  Unit label
                </Label>
                <Col>
                  <Input
                    required
                    id='leftBoxUnitLabel'
                    className={activationPointsGridLabels.leftBoxUnitLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.leftBoxUnitLabel ? activationPointsGridLabels.leftBoxUnitLabel : ''}
                    name='leftBoxUnitLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, leftBoxUnitLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='leftBoxButtonLabel' sm={3}>
                  {' '}
                  Button text
                </Label>
                <Col>
                  <Input
                    required
                    id='leftBoxButtonLabel'
                    className={activationPointsGridLabels.leftBoxButtonLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.leftBoxButtonLabel ? activationPointsGridLabels.leftBoxButtonLabel : ''}
                    name='leftBoxButtonLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, leftBoxButtonLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
            </CollapsibleCard>
            <CollapsibleCard
              startOpen
              cardProps={{ style: { marginBottom: '-1rem' } }}
              header={
                <React.Fragment>
                  <strong>Middle Box</strong>{' '}
                </React.Fragment>
              }
            >
              <FormGroup row>
                <Label for='leftBoxLabel' sm={3}>
                  {' '}
                  Middle box label
                </Label>
                <Col>
                  <Input
                    required
                    id='middleBoxLabel'
                    className={activationPointsGridLabels.middleBoxLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.middleBoxLabel ? activationPointsGridLabels.middleBoxLabel : ''}
                    name='middleBoxLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, middleBoxLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='leftBoxUnitLabel' sm={3}>
                  {' '}
                  Unit label
                </Label>
                <Col>
                  <Input
                    required
                    id='middleBoxUnitLabel'
                    className={activationPointsGridLabels.middleBoxUnitLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.middleBoxUnitLabel ? activationPointsGridLabels.middleBoxUnitLabel : ''}
                    name='middleBoxUnitLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, middleBoxUnitLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='leftBoxButtonLabel' sm={3}>
                  {' '}
                  Button text
                </Label>
                <Col>
                  <Input
                    required
                    id='middleBoxButtonLabel'
                    className={activationPointsGridLabels.middleBoxButtonLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.middleBoxButtonLabel ? activationPointsGridLabels.middleBoxButtonLabel : ''}
                    name='middleBoxButtonLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, middleBoxButtonLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
            </CollapsibleCard>
            <CollapsibleCard
              startOpen
              cardProps={{ style: { marginBottom: '-1rem' } }}
              header={
                <React.Fragment>
                  <strong>Right Box</strong>{' '}
                </React.Fragment>
              }
            >
              <FormGroup row>
                <Label for='rightBoxLabel' sm={3}>
                  {' '}
                  Right box label
                </Label>
                <Col>
                  <Input
                    required
                    id='rightBoxLabel'
                    className={activationPointsGridLabels.rightBoxLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.rightBoxLabel ? activationPointsGridLabels.rightBoxLabel : ''}
                    name='rightBoxLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, rightBoxLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='rightBoxUnitLabel' sm={3}>
                  {' '}
                  Unit label
                </Label>
                <Col>
                  <Input
                    required
                    id='rightBoxUnitLabel'
                    className={activationPointsGridLabels.rightBoxUnitLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.rightBoxUnitLabel ? activationPointsGridLabels.rightBoxUnitLabel : ''}
                    name='rightBoxUnitLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, rightBoxUnitLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='rightBoxButtonLabel' sm={3}>
                  {' '}
                  Button text
                </Label>
                <Col>
                  <Input
                    required
                    id='rightBoxButtonLabel'
                    className={activationPointsGridLabels.rightBoxButtonLabel ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.rightBoxButtonLabel ? activationPointsGridLabels.rightBoxButtonLabel : ''}
                    name='rightBoxButtonLabel'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, rightBoxButtonLabel: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
            </CollapsibleCard>
            <CollapsibleCard
              startOpen
              cardProps={{ style: { marginBottom: '-1rem' } }}
              header={
                <React.Fragment>
                  <strong>Line one</strong>{' '}
                </React.Fragment>
              }
            >
              <FormGroup row>
                <Label for='lineOneStartText' sm={3}>
                  {' '}
                  Start text
                </Label>
                <Col>
                  <Input
                    required
                    id='lineOneStartText'
                    className={activationPointsGridLabels.lineOneStartText ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.lineOneStartText ? activationPointsGridLabels.lineOneStartText : ''}
                    name='lineOneStartText'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, lineOneStartText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lineOneEndText' sm={3}>
                  {' '}
                  End text
                </Label>
                <Col>
                  <Input
                    required
                    id='lineOneEndText'
                    className={activationPointsGridLabels.lineOneEndText ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.lineOneEndText ? activationPointsGridLabels.lineOneEndText : ''}
                    name='lineOneEndText'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, lineOneEndText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
            </CollapsibleCard>
            <CollapsibleCard
              startOpen
              cardProps={{ style: { marginBottom: '-1rem' } }}
              header={
                <React.Fragment>
                  <strong>Line two</strong>{' '}
                </React.Fragment>
              }
            >
              <FormGroup row>
                <Label for='lineTwoStartText' sm={3}>
                  {' '}
                  Start text
                </Label>
                <Col>
                  <Input
                    required
                    id='lineTwoStartText'
                    className={activationPointsGridLabels.lineTwoStartText ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.lineTwoStartText ? activationPointsGridLabels.lineTwoStartText : ''}
                    name='lineTwoStartText'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, lineTwoStartText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lineOneEndText' sm={3}>
                  {' '}
                  End text
                </Label>
                <Col>
                  <Input
                    required
                    id='lineTwoEndText'
                    className={activationPointsGridLabels.lineTwoEndText ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.lineTwoEndText ? activationPointsGridLabels.lineTwoEndText : ''}
                    name='lineTwoEndText'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, lineTwoEndText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
            </CollapsibleCard>
            <CollapsibleCard
              startOpen
              cardProps={{ style: { marginBottom: '-1rem' } }}
              header={
                <React.Fragment>
                  <strong>Line three</strong>{' '}
                </React.Fragment>
              }
            >
              <FormGroup row>
                <Label for='lineThreeStartText' sm={3}>
                  {' '}
                  Start text
                </Label>
                <Col>
                  <Input
                    required
                    id='lineThreeStartText'
                    className={activationPointsGridLabels.lineThreeStartText ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.lineThreeStartText ? activationPointsGridLabels.lineThreeStartText : ''}
                    name='lineThreeStartText'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, lineThreeStartText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lineThreeCenterText' sm={3}>
                  {' '}
                  Center text
                </Label>
                <Col>
                  <Input
                    required
                    id='lineThreeCenterText'
                    className={activationPointsGridLabels.lineThreeCenterText ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.lineThreeCenterText ? activationPointsGridLabels.lineThreeCenterText : ''}
                    name='lineThreeCenterText'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, lineThreeCenterText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lineThreeEndText' sm={3}>
                  {' '}
                  End text
                </Label>
                <Col>
                  <Input
                    required
                    id='lineThreeEndText'
                    className={activationPointsGridLabels.lineThreeEndText ? 'is-valid' : 'is-invalid'}
                    value={activationPointsGridLabels.lineThreeEndText ? activationPointsGridLabels.lineThreeEndText : ''}
                    name='lineThreeEndText'
                    onChange={e => {
                      setActivationPointsGridLabels({ ...activationPointsGridLabels, lineThreeEndText: e.target.value })
                    }}
                  />
                </Col>
              </FormGroup>
            </CollapsibleCard>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('activationPointsGridLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Activation points section</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup row>
              <Label for='titleText' sm={3}>
                {' '}
                Title
              </Label>
              <Col>
                <Input
                  required
                  id='titleText'
                  className={activationPointsSectionLabels.titleText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.titleText ? activationPointsSectionLabels.titleText : ''}
                  name='titleText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, titleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='noPointsTitleText' sm={3}>
                {' '}
                No points - title
              </Label>
              <Col>
                <Input
                  required
                  id='noPointsTitleText'
                  className={activationPointsSectionLabels.noPointsTitleText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.noPointsTitleText ? activationPointsSectionLabels.noPointsTitleText : ''}
                  name='noPointsTitleText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, noPointsTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='noPointsContentText' sm={3}>
                {' '}
                No points - content
              </Label>
              <Col>
                <Input
                  required
                  id='noPointsContentText'
                  className={activationPointsSectionLabels.noPointsContentText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.noPointsContentText ? activationPointsSectionLabels.noPointsContentText : ''}
                  name='noPointsContentText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, noPointsContentText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='automaticActivationRemarkText' sm={3}>
                {' '}
                Automatic activation remarks
              </Label>
              <Col>
                <Input
                  required
                  id='automaticActivationRemarkText'
                  className={activationPointsSectionLabels.automaticActivationRemarkText ? 'is-valid' : 'is-invalid'}
                  value={
                    activationPointsSectionLabels.automaticActivationRemarkText ? activationPointsSectionLabels.automaticActivationRemarkText : ''
                  }
                  name='automaticActivationRemarkText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, automaticActivationRemarkText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='automaticActivationLinkText' sm={3}>
                {' '}
                Automatic activation link
              </Label>
              <Col>
                <Input
                  required
                  id='automaticActivationLinkText'
                  className={activationPointsSectionLabels.automaticActivationLinkText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.automaticActivationLinkText ? activationPointsSectionLabels.automaticActivationLinkText : ''}
                  name='automaticActivationLinkText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, automaticActivationLinkText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sectionBoxTitleText' sm={3}>
                {' '}
                Section box title
              </Label>
              <Col>
                <Input
                  required
                  id='sectionBoxTitleText'
                  className={activationPointsSectionLabels.sectionBoxTitleText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.sectionBoxTitleText ? activationPointsSectionLabels.sectionBoxTitleText : ''}
                  name='sectionBoxTitleText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, sectionBoxTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sectionBoxColumns' sm={3}>
                {' '}
                Columns
              </Label>
              <Col md='1'>
                <Input
                  required
                  id='columnOneLabel'
                  className={activationPointsSectionLabels.columnOneLabel ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.columnOneLabel ? activationPointsSectionLabels.columnOneLabel : ''}
                  name='columnOneLabel'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, columnOneLabel: e.target.value })
                  }}
                />
              </Col>
              <Col md='1'>
                <Input
                  required
                  id='columnTwoLabel'
                  className={activationPointsSectionLabels.columnTwoLabel ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.columnTwoLabel ? activationPointsSectionLabels.columnTwoLabel : ''}
                  name='columnTwoLabel'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, columnTwoLabel: e.target.value })
                  }}
                />
              </Col>
              <Col md='1'>
                <Input
                  required
                  id='columnThreeLabel'
                  className={activationPointsSectionLabels.columnThreeLabel ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.columnThreeLabel ? activationPointsSectionLabels.columnThreeLabel : ''}
                  name='columnThreeLabel'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, columnThreeLabel: e.target.value })
                  }}
                />
              </Col>
              <Col md='1'>
                <Input
                  required
                  id='columnFourLabel'
                  className={activationPointsSectionLabels.columnFourLabel ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.columnFourLabel ? activationPointsSectionLabels.columnFourLabel : ''}
                  name='columnFourLabel'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, columnFourLabel: e.target.value })
                  }}
                />
              </Col>
              <Col md='1'>
                <Input
                  required
                  id='columnFiveLabel'
                  className={activationPointsSectionLabels.columnFiveLabel ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.columnFiveLabel ? activationPointsSectionLabels.columnFiveLabel : ''}
                  name='columnFiveLabel'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, columnFiveLabel: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sectionBoxStatus' sm={3}>
                {' '}
                Status
              </Label>
              <Col md='2'>
                <Label>Waiting</Label>
                <Input
                  required
                  id='statusWaitingText'
                  className={activationPointsSectionLabels.statusWaitingText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.statusWaitingText ? activationPointsSectionLabels.statusWaitingText : ''}
                  name='statusWaitingText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, statusWaitingText: e.target.value })
                  }}
                />
              </Col>
              <Col md='2'>
                <Label>Activated</Label>
                <Input
                  required
                  id='statusActivatedText'
                  className={activationPointsSectionLabels.statusActivatedText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.statusActivatedText ? activationPointsSectionLabels.statusActivatedText : ''}
                  name='statusActivatedText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, statusActivatedText: e.target.value })
                  }}
                />
              </Col>
              <Col md='2'>
                <Label>Expired</Label>
                <Input
                  required
                  id='statusExpiredText'
                  className={activationPointsSectionLabels.statusExpiredText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.statusExpiredText ? activationPointsSectionLabels.statusExpiredText : ''}
                  name='statusExpiredText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, statusExpiredText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupTitleText' sm={3}>
                {' '}
                Popup title
              </Label>
              <Col>
                <Input
                  required
                  id='popupTitleText'
                  className={activationPointsSectionLabels.popupTitleText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.popupTitleText ? activationPointsSectionLabels.popupTitleText : ''}
                  name='popupTitleText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, popupTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupContentText' sm={3}>
                {' '}
                Popup content
              </Label>
              <Col>
                <Input
                  type='textarea'
                  required
                  id='popupContentText'
                  className={activationPointsSectionLabels.popupContentText ? 'is-valid' : 'is-invalid'}
                  value={activationPointsSectionLabels.popupContentText ? activationPointsSectionLabels.popupContentText : ''}
                  name='popupContentText'
                  onChange={e => {
                    setActivationPointsSectionLabels({ ...activationPointsSectionLabels, popupContentText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('activationPointsSectionLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Points section</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup row>
              <Label for='titleText' sm={3}>
                {' '}
                Title
              </Label>
              <Col>
                <Input
                  required
                  id='titleText'
                  className={pointsSectionLabels.titleText ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.titleText ? pointsSectionLabels.titleText : ''}
                  name='titleText'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, titleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='noPointsTitleText' sm={3}>
                {' '}
                No points - title
              </Label>
              <Col>
                <Input
                  required
                  id='noPointsTitleText'
                  className={pointsSectionLabels.noPointsTitleText ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.noPointsTitleText ? pointsSectionLabels.noPointsTitleText : ''}
                  name='noPointsTitleText'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, noPointsTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='noPointsContentText' sm={3}>
                {' '}
                No points - content
              </Label>
              <Col>
                <Input
                  required
                  id='noPointsContentText'
                  className={pointsSectionLabels.noPointsContentText ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.noPointsContentText ? pointsSectionLabels.noPointsContentText : ''}
                  name='noPointsContentText'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, noPointsContentText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sectionBoxTitleText' sm={3}>
                {' '}
                Section box title
              </Label>
              <Col>
                <Input
                  required
                  id='sectionBoxTitleText'
                  className={pointsSectionLabels.sectionBoxTitleText ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.sectionBoxTitleText ? pointsSectionLabels.sectionBoxTitleText : ''}
                  name='sectionBoxTitleText'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, sectionBoxTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sectionBoxColumns' sm={3}>
                {' '}
                Columns
              </Label>
              <Col md='1'>
                <Input
                  required
                  id='columnOneLabel'
                  className={pointsSectionLabels.columnOneLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.columnOneLabel ? pointsSectionLabels.columnOneLabel : ''}
                  name='columnOneLabel'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, columnOneLabel: e.target.value })
                  }}
                />
              </Col>
              <Col md='1'>
                <Input
                  required
                  id='columnTwoLabel'
                  className={pointsSectionLabels.columnTwoLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.columnTwoLabel ? pointsSectionLabels.columnTwoLabel : ''}
                  name='columnTwoLabel'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, columnTwoLabel: e.target.value })
                  }}
                />
              </Col>
              <Col md='1'>
                <Input
                  required
                  id='columnThreeLabel'
                  className={pointsSectionLabels.columnThreeLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.columnThreeLabel ? pointsSectionLabels.columnThreeLabel : ''}
                  name='columnThreeLabel'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, columnThreeLabel: e.target.value })
                  }}
                />
              </Col>
              <Col md='1'>
                <Input
                  required
                  id='columnFourLabel'
                  className={pointsSectionLabels.columnFourLabel ? 'is-valid' : 'is-invalid'}
                  value={pointsSectionLabels.columnFourLabel ? pointsSectionLabels.columnFourLabel : ''}
                  name='columnFourLabel'
                  onChange={e => {
                    setPointsSectionLabels({ ...pointsSectionLabels, columnFourLabel: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('pointsSectionLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Statistics section - Activation</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup row>
              <Label for='titleText' sm={3}>
                {' '}
                Title
              </Label>
              <Col>
                <Input
                  required
                  id='titleText'
                  className={statisticsSectionActivationLabels.titleText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionActivationLabels.titleText ? statisticsSectionActivationLabels.titleText : ''}
                  name='titleText'
                  onChange={e => {
                    setStatisticsSectionActivationLabels({ ...statisticsSectionActivationLabels, titleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sectionBoxColumns' sm={3}>
                {' '}
                Months
              </Label>
              <Row>
                {statisticsSectionActivationLabels &&
                  statisticsSectionActivationLabels.monthLabels &&
                  statisticsSectionActivationLabels.monthLabels.map((monthLabel, index) => {
                    return (
                      <Col md='2' key={index} style={{ marginTop: '10px' }}>
                        <Input
                          required
                          className={monthLabel ? 'is-valid' : 'is-invalid'}
                          value={monthLabel}
                          onChange={e => {
                            setStatisticsSectionActivationLabels({
                              ...statisticsSectionActivationLabels,
                              monthLabels: statisticsSectionActivationLabels.monthLabels.map((label, ind) => {
                                if (ind === index) {
                                  label = e.target.value
                                }
                                return label
                              })
                            })
                          }}
                        />
                      </Col>
                    )
                  })}
              </Row>
            </FormGroup>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('statisticsSectionActivationLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Statistics section - Points</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup row>
              <Label for='titleText' sm={3}>
                {' '}
                Title
              </Label>
              <Col>
                <Input
                  required
                  id='titleText'
                  className={statisticsSectionLabels.titleText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.titleText ? statisticsSectionLabels.titleText : ''}
                  name='titleText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, titleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='titleText' sm={3}>
                {' '}
                Subtitle page 1
              </Label>
              <Col>
                <Input
                  required
                  id='subtitlePageOneText'
                  className={statisticsSectionLabels.subtitlePageOneText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.subtitlePageOneText ? statisticsSectionLabels.subtitlePageOneText : ''}
                  name='subtitlePageOneText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, subtitlePageOneText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='titleText' sm={3}>
                {' '}
                Subtitle page 2
              </Label>
              <Col>
                <Input
                  required
                  id='subtitlePageTwoText'
                  className={statisticsSectionLabels.subtitlePageTwoText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.subtitlePageTwoText ? statisticsSectionLabels.subtitlePageTwoText : ''}
                  name='subtitlePageTwoText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, subtitlePageTwoText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='titleText' sm={3}>
                {' '}
                Subtitle page 3
              </Label>
              <Col>
                <Input
                  required
                  id='subtitlePageThreeText'
                  className={statisticsSectionLabels.subtitlePageThreeText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.subtitlePageThreeText ? statisticsSectionLabels.subtitlePageThreeText : ''}
                  name='subtitlePageThreeText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, subtitlePageThreeText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='personalRecordTitleText' sm={3}>
                {' '}
                Personal record title
              </Label>
              <Col>
                <Input
                  required
                  id='personalRecordTitleText'
                  className={statisticsSectionLabels.personalRecordTitleText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.personalRecordTitleText ? statisticsSectionLabels.personalRecordTitleText : ''}
                  name='personalRecordTitleText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, personalRecordTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='personalRecordSubtitleText' sm={3}>
                {' '}
                Personal record subtitle
              </Label>
              <Col>
                <Input
                  required
                  id='personalRecordSubtitleText'
                  className={statisticsSectionLabels.personalRecordSubtitleText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.personalRecordSubtitleText ? statisticsSectionLabels.personalRecordSubtitleText : ''}
                  name='personalRecordSubtitleText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, personalRecordSubtitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='personalGoalTitleText' sm={3}>
                {' '}
                Personal goal title
              </Label>
              <Col>
                <Input
                  required
                  id='personalGoalTitleText'
                  className={statisticsSectionLabels.personalGoalTitleText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.personalGoalTitleText ? statisticsSectionLabels.personalGoalTitleText : ''}
                  name='personalGoalTitleText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, personalGoalTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='personalGoalSubtitleText' sm={3}>
                {' '}
                Personal goal subtitle
              </Label>
              <Col>
                <Input
                  required
                  id='personalGoalSubtitleText'
                  className={statisticsSectionLabels.personalGoalSubtitleText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.personalGoalSubtitleText ? statisticsSectionLabels.personalGoalSubtitleText : ''}
                  name='personalGoalSubtitleText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, personalGoalSubtitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='youAreMissingText' sm={3}>
                {' '}
                You are missing text
              </Label>
              <Col>
                <Input
                  required
                  id='youAreMissingText'
                  className={statisticsSectionLabels.youAreMissingText ? 'is-valid' : 'is-invalid'}
                  value={statisticsSectionLabels.youAreMissingText ? statisticsSectionLabels.youAreMissingText : ''}
                  name='youAreMissingText'
                  onChange={e => {
                    setStatisticsSectionLabels({ ...statisticsSectionLabels, youAreMissingText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('statisticsSectionLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Send Points And Loan</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup row>
              <Label for='sendPointsButtonText' sm={3}>
                {' '}
                Send points button text
              </Label>
              <Col>
                <Input
                  required
                  id='sendPointsButtonText'
                  className={sendPointsAndLoanLabels.sendPointsButtonText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.sendPointsButtonText ? sendPointsAndLoanLabels.sendPointsButtonText : ''}
                  name='sendPointsButtonText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, sendPointsButtonText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sendPointsInfoText' sm={3}>
                {' '}
                Send points info text
              </Label>
              <Col>
                <Input
                  required
                  id='sendPointsInfoText'
                  className={sendPointsAndLoanLabels.sendPointsInfoText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.sendPointsInfoText ? sendPointsAndLoanLabels.sendPointsInfoText : ''}
                  name='sendPointsInfoText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, sendPointsInfoText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupTitleText' sm={3}>
                {' '}
                Popup title text
              </Label>
              <Col>
                <Input
                  required
                  id='popupTitleText'
                  className={sendPointsAndLoanLabels.popupTitleText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupTitleText ? sendPointsAndLoanLabels.popupTitleText : ''}
                  name='popupTitleText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupTitleText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupContentText' sm={3}>
                {' '}
                Popup content text
              </Label>
              <Col>
                <Input
                  required
                  id='popupContentText'
                  className={sendPointsAndLoanLabels.popupContentText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupContentText ? sendPointsAndLoanLabels.popupContentText : ''}
                  name='popupContentText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupContentText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupNoAmountText' sm={3}>
                {' '}
                Popup no amount text
              </Label>
              <Col>
                <Input
                  required
                  id='popupNoAmountText'
                  className={sendPointsAndLoanLabels.popupNoAmountText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupNoAmountText ? sendPointsAndLoanLabels.popupNoAmountText : ''}
                  name='popupNoAmountText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupNoAmountText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupFieldOneText' sm={3}>
                {' '}
                Popup line one text
              </Label>
              <Col>
                <Input
                  required
                  id='popupFieldOneText'
                  className={sendPointsAndLoanLabels.popupFieldOneText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupFieldOneText ? sendPointsAndLoanLabels.popupFieldOneText : ''}
                  name='popupFieldOneText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupFieldOneText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupFieldTwoText' sm={3}>
                {' '}
                Popup line two text
              </Label>
              <Col>
                <Input
                  required
                  id='popupFieldTwoText'
                  className={sendPointsAndLoanLabels.popupFieldTwoText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupFieldTwoText ? sendPointsAndLoanLabels.popupFieldTwoText : ''}
                  name='popupFieldTwoText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupFieldTwoText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupCheckboxText' sm={3}>
                {' '}
                Popup checkbox text
              </Label>
              <Col>
                <Input
                  required
                  id='popupCheckboxText'
                  className={sendPointsAndLoanLabels.popupCheckboxText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupCheckboxText ? sendPointsAndLoanLabels.popupCheckboxText : ''}
                  name='popupCheckboxText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupCheckboxText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupLoanHelperText' sm={3}>
                {' '}
                Popup loan helper text
              </Label>
              <Col>
                <Input
                  required
                  id='popupLoanHelperText'
                  className={sendPointsAndLoanLabels.popupLoanHelperText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupLoanHelperText ? sendPointsAndLoanLabels.popupLoanHelperText : ''}
                  name='popupLoanHelperText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupLoanHelperText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupAcceptButtonText' sm={3}>
                {' '}
                Popup accept button text
              </Label>
              <Col>
                <Input
                  required
                  id='popupAcceptButtonText'
                  className={sendPointsAndLoanLabels.popupAcceptButtonText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupAcceptButtonText ? sendPointsAndLoanLabels.popupAcceptButtonText : ''}
                  name='popupAcceptButtonText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupAcceptButtonText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='popupCloseButtonText' sm={3}>
                {' '}
                Popup close button text
              </Label>
              <Col>
                <Input
                  required
                  id='popupCloseButtonText'
                  className={sendPointsAndLoanLabels.popupCloseButtonText ? 'is-valid' : 'is-invalid'}
                  value={sendPointsAndLoanLabels.popupCloseButtonText ? sendPointsAndLoanLabels.popupCloseButtonText : ''}
                  name='popupCloseButtonText'
                  onChange={e => {
                    setSendPointsAndLoanLabels({ ...sendPointsAndLoanLabels, popupCloseButtonText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('sendPointsAndLoanLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
      </CollapsibleCard>
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Home page</strong>{' '}
          </React.Fragment>
        }
      >
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>CPR Labels</strong>{' '}
            </React.Fragment>
          }
        >
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup row>
              <Label for='CPRPopUpApprovalText' sm={3}>
                {' '}
                Approval text
              </Label>
              <Col>
                <Input
                  required
                  id='CPRPopUpApprovalText'
                  className={CPRPopupLabels.CPRPopUpApprovalText ? 'is-valid' : 'is-invalid'}
                  value={CPRPopupLabels.CPRPopUpApprovalText ? CPRPopupLabels.CPRPopUpApprovalText : ''}
                  name='CPRPopUpApprovalText'
                  onChange={e => {
                    setCPRPopupLabels({ ...CPRPopupLabels, CPRPopUpApprovalText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='CPRPopUpTermsLinkText' sm={3}>
                {' '}
                Terms & Conditions text
              </Label>
              <Col>
                <Input
                  required
                  id='CPRPopUpTermsLinkText'
                  className={CPRPopupLabels.CPRPopUpTermsLinkText ? 'is-valid' : 'is-invalid'}
                  value={CPRPopupLabels.CPRPopUpTermsLinkText ? CPRPopupLabels.CPRPopUpTermsLinkText : ''}
                  name='CPRPopUpTermsLinkText'
                  onChange={e => {
                    setCPRPopupLabels({ ...CPRPopupLabels, CPRPopUpTermsLinkText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='CPRPopUpTermsURL' sm={3}>
                {' '}
                Terms & Conditions link
              </Label>
              <Col>
                <Input
                  required
                  id='CPRPopUpTermsURL'
                  className={CPRPopupLabels.CPRPopUpTermsURL ? 'is-valid' : 'is-invalid'}
                  value={CPRPopupLabels.CPRPopUpTermsURL ? CPRPopupLabels.CPRPopUpTermsURL : ''}
                  name='CPRPopUpTermsURL'
                  onChange={e => {
                    setCPRPopupLabels({ ...CPRPopupLabels, CPRPopUpTermsURL: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='CPRPopUpCheckboxText' sm={3}>
                {' '}
                Checkbox text
              </Label>
              <Col>
                <Input
                  required
                  id='CPRPopUpCheckboxText'
                  className={CPRPopupLabels.CPRPopUpCheckboxText ? 'is-valid' : 'is-invalid'}
                  value={CPRPopupLabels.CPRPopUpCheckboxText ? CPRPopupLabels.CPRPopUpCheckboxText : ''}
                  name='CPRPopUpCheckboxText'
                  onChange={e => {
                    setCPRPopupLabels({ ...CPRPopupLabels, CPRPopUpCheckboxText: e.target.value })
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
          {savingData ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              onClick={() => {
                saveData('CPRPopupLabels')
              }}
            >
              Gem
            </Button>
          )}
        </CollapsibleCard>
      </CollapsibleCard>
    </>
  )
}
