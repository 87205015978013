import React, { useState } from 'react'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import _ from 'lodash'
import styled from 'styled-components'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import { colorMap } from 'Components/dashboard/colorMap.js'
import { ResponsiveBar } from '@nivo/bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BarLegend } from 'Components/dashboard/charts/BarLegend'

const ChartWrap = styled.div`
  height: 450px;
  cursor: pointer;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  float: right;
  position: relative;
  right: 40px;
  bottom: 430px;
`
const keys = ['Aktive brugere', 'Inaktive brugere']
function toKAMUsers(event, value) {
  if (event && value) {
    event.stopPropagation()
    const kamName = value.data.KAM ? value.data.KAM.replace(/ /g, '-') : value.data.userSupervisor.replace(/ /g, '-')
    window.open(`/dashboard/${value.data.SID}?supervisor=${kamName}`, '_blank')
  } else {
    event.preventDefault()
    window.open(`/dashboard/all-kam-users`, '_blank')
  }
}

export default function KamUsersChart() {
  const [data, loadingState] = useDashboardData('reports/active-inactive-users-kam')
  const [filter, setFilter] = useState(null)

  let longestName = _.reduce(data && data.activeUsersByKAM, (acc, val) => (val.userSupervisor.length > acc ? val.userSupervisor.length : acc), 0)
  const filteredData = filter
    ? _.map(data && data.activeUsersByKAM, value => ({
        KAM: value.userSupervisor,
        ...(filter === 'Aktive brugere' && { 'Aktive brugere': value.active, 'Inaktive brugere': 0 }),
        ...(filter === 'Inaktive brugere' && { 'Inaktive brugere': value.inactive, 'Aktive brugere': 0 }),
        SID: value.SID
      }))
    : _.map(data && data.activeUsersByKAM, value => ({
        KAM: value.userSupervisor,
        'Aktive brugere': value.active,
        'Inaktive brugere': value.inactive,
        SID: value.SID
      }))
  return (
    <DashboardComponent header={'Brugere pr. KAM'} state={loadingState}>
      {loadingState === DASHBOARD_COMPONENT_STATE.SUCCESS && (
        <>
          <ChartWrap onClick={e => toKAMUsers(e)}>
            <ResponsiveBar
              data={filteredData}
              keys={keys}
              indexBy={'KAM'}
              groupMode='grouped'
              margin={{ top: 50, right: 30, bottom: longestName * 5, left: 60 }}
              padding={0.3}
              colors={({ id }) => colorMap[`${id}Color`]}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -50,
                onClick: (event, value) => {
                  const record = data && data.activeUsersByKAM && data.activeUsersByKAM.filter(item => item.userSupervisor === value)
                  toKAMUsers(event, { data: record[0] })
                }
              }}
              layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              onClick={(value, event) => toKAMUsers(event, value)}
              theme={{
                axis: { legend: { text: { fontWeight: 700, fontFamily: '"Open Sans", sans-serif' } } }
              }}
              enableLabel={false}
              labelSkipWidth={40}
              labelSkipHeight={16}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              tooltipFormat={value => new Intl.NumberFormat().format(value)}
              legends={[
                {
                  dataFrom: 'keys',
                  data: keys.map(id => ({
                    color: colorMap[`${id}Color`],
                    id,
                    label: id
                  })),
                  onClick: (d, event) => {
                    event.stopPropagation()
                    filter === d.label ? setFilter(null) : setFilter(d.label)
                  },
                  anchor: 'top',
                  direction: 'row',
                  justify: false,
                  translateX: -10,
                  translateY: -30,
                  itemsSpacing: 30,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 15
                }
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </ChartWrap>
          <StyledFontAwesomeIcon onClick={e => toKAMUsers(e)} fixedWidth icon='list-ol' />
        </>
      )}
    </DashboardComponent>
  )
}
