import React, { useState } from 'react'
import PointsQuarterAndMonthChart from 'Components/dashboard/charts/PointsQuarterAndMonthChart.js'
import _ from 'lodash'
import moment from 'moment'
import useDashboardData from 'Components/dashboard/useDashboardData.js'

export default function PointsEarnedChart() {
  const [data, loadingState] = useDashboardData('reports/wallet-points-earned')
  const [calenderToggle, setCalenderToggle] = useState(null)
  const [startDate, setStartDate] = useState(moment().format())
  const [endDate, setEndDate] = useState(moment().format())

  function formatMoment(input) {
    return moment(input).format('YYYY-MM-DD')
  }

  const getFilterDetails = () => {
    window.open(`/all-earned-point/startDate=${formatMoment(startDate)}&endDate=${formatMoment(endDate)}`)
    setFilterToggle()
    setStartDate(moment().format())
    setEndDate(moment().format())
  }

  function setFilterToggle() {
    setCalenderToggle(prev => (prev === true ? false : true))
  }

  return (
    <PointsQuarterAndMonthChart
      header={'Optjente point'}
      state={loadingState}
      monthlyData={_.map(_.get(data, 'monthlyResults'), value => ({
        Month: moment(value.month, 'MM/YYYY').format('MMM YY'),
        System: value.system,
        Manuel: value.manual,
        Macro: value.macro
      }))}
      quarterlyData={_.map(_.get(data, 'quarterlyResults'), value => ({
        Month: moment(value.month, 'Q/YYYY').format('Qo[kvt.] YYYY'),
        System: value.system,
        Manuel: value.manual,
        Macro: value.macro
      }))}
      keys={['Macro', 'Manuel', 'System']}
      indexBy={'Month'}
      getFilterDetails={getFilterDetails}
      setFilterToggle={setFilterToggle}
      calenderToggle={calenderToggle}
      showIcon={true}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  )
}
