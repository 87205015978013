/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import { UserActions } from 'Stores/userStore'

export const WalletActions = createActions([
  'getWallets',
  'createEmptyWallet',
  'updateWalletField',
  { saveWallet: { children: ['completed'] } },
  'clearWalletLine',
  'getWalletLine',
  'deleteWallet',
  'clearToast',
  'getCampaignWalletsOverview',
  'getTotalWalletDetails',
  'preserveAdvanceSearch',
  'toggleActiveWallet',
  'toggleInActiveWallet',
  'setLimit',
  'removeWallet',
  'addFilter',
  'updateFilter',
  'removeFilter',
  'clearFilters'
])

export class WalletStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = WalletActions
    this.state = {
      currentUserID: null,
      wallets: [],
      fetchingWallets: false,
      walletLine: {},
      savingWallet: false,
      showWalletSuccess: false,
      showWalletError: false,
      walletsData: [],
      advanceSearchFilters: [],
      showActiveWallets: true,
      showInActiveWallets: false,
      walletFilterValue: 'active',
      fetchingWalletsInfo: false,
      fetchingWalletsError: false,
      start: 0,
      limit: 100000,
      totalCount: 0,
      filters: [],
      appliedFilters: false
    }
  }
  clearToast() {
    this.setState({ showWalletSuccess: false, showWalletError: false, fetchingWalletsError: false })
  }
  getWallets(data) {
    this.setState({ fetchingWallets: true })
    let nextState = {
      fetchingWallets: false,
      wallets: []
    }
    GET('wallets/raw', { filter: data }).then(responseJSON => {
      if (responseJSON) {
        nextState.showWalletSuccess = true
        nextState.wallets = responseJSON
      }
      if (responseJSON.error) {
        nextState.showWalletError = true
      }
      this.setState(nextState)
    })
  }
  addFilter(data = {}) {
    let filters = this.state.filters
    let lastFilterID = -1
    if (filters.length > 0) {
      let filterIDs = filters.map(e => {
        return e.filterID
      })
      lastFilterID = filterIDs.reduce((a, b) => {
        return Math.max(a, b)
      })
    }
    let filter = { filterID: lastFilterID + 1 }
    if (!data.column) {
      data = { column: 'WalletID', operator: 'LIKE', value: '' }
    }
    Object.assign(filter, data)
    filters.push(filter)
    this.setState({ filters: filters })
  }
  removeFilter(filterID) {
    let filters = this.state.filters
    filters.splice(filterID, 1)
    this.setState({ filters })
    if (!filters.length) {
      this.addFilter()
    }
  }
  updateFilter(editFilter, field) {
    let filterID = editFilter.filterID
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let filters = this.state.filters
    for (let filter of filters) {
      if (filter.filterID == filterID) {
        filter[fieldName] = fieldValue
        if (fieldName == 'column') {
          filter.value = ''
        }
      }
    }
    this.setState({ filters })
  }
  saveWallet(isMSBAdmin, BrandID) {
    this.setState({ savingWallet: true })
    let walletLine = this.state.walletLine
    if (isMSBAdmin) {
      walletLine.BrandID = BrandID
    }
    let api = isMSBAdmin ? 'wallets/admin-replace' : 'wallets/replace'
    POST(api, walletLine).then(responseJSON => {
      if (responseJSON) {
        if (responseJSON.error) {
          this.setState({ showWalletError: true, savingWallet: false })
        } else {
          let nextState = { walletLine: responseJSON, savingWallet: false, showWalletError: false, showWalletSuccess: false }
          if (this.state.wallets.length > 0) {
            let wallets = this.state.wallets
            let found = false
            for (let i in wallets) {
              if (wallets[i].WalletID == responseJSON.WalletID) {
                found = true
                wallets[i] = responseJSON
              }
            }
            if (!found) {
              wallets.push(responseJSON)
            }
            nextState.wallets = wallets
          }
          nextState.showWalletSuccess = true
          this.setState(nextState)
          WalletActions.saveWallet.completed()
        }
      }
      this.setState({ savingWallet: false })
    })
  }
  clearWalletLine() {
    this.setState({ walletLine: {} })
  }
  createEmptyWallet(UserID = '') {
    let emptyWallet = {
      UserID: UserID,
      walletAmountPoints: 0,
      walletType: 'MANAGE',
      walletNote: '',
      walletFlags: ''
    }
    this.setState({ walletLine: emptyWallet })
  }
  updateWalletField(input = null) {
    if (input) {
      let walletLine = this.state.walletLine
      walletLine[input.target.name] = input.target.name === 'VaultID' ? Number(strip_tags(input.target.value)) : strip_tags(input.target.value)
      this.setState({ walletLine: walletLine })
    }
  }
  getWalletLine(WalletID = null) {
    if (WalletID) {
      GET('wallets/fetch', { WalletID: WalletID }).then(responseJSON => {
        if (responseJSON) {
          this.setState({ walletLine: responseJSON })
        }
      })
    }
  }
  deleteWallet(WalletID = null) {
    if (WalletID) {
      POST('wallets/remove', { WalletID: WalletID }).then(() => {
        this.getWallets()
      })
    }
  }
  onSaveWalletCompleted() {
    UserActions.getUsers()
  }
  getCampaignWalletsOverview(data) {
    this.setState({ fetchingWallets: true })
    let nextState = {
      fetchingWallets: false,
      wallets: []
    }
    GET('wallets/campaign-overview', { filter: data }).then(responseJSON => {
      if (responseJSON) {
        nextState.showWalletSuccess = true
        nextState.wallets = responseJSON
      }
      if (responseJSON.error) {
        nextState.showWalletError = true
      }
      this.setState(nextState)
    })
  }
  getTotalWalletDetails() {
    this.setState({ fetchingWalletsInfo: true })
    let filters = this.state.filters
      .filter(e => {
        return e.column != '' && e.value != ''
      })
      .map(e => {
        return { column: e.column, operator: e.operator ? e.operator : 'LIKE', value: e.value }
      })
    GET('wallets/admin-pool', { start: this.state.start, limit: this.state.limit, filters: filters }).then(responseJSON => {
      let nextState = {
        fetchingWalletsInfo: false,
        walletsData: [],
        totalCount: 0,
        appliedFilters: filters && filters.length ? true : false
      }
      if (responseJSON.error) {
        nextState.fetchingWalletsError = true
      } else {
        nextState.walletsData = responseJSON && responseJSON.wallets ? responseJSON.wallets : []
        nextState.totalCount = responseJSON && responseJSON.count ? responseJSON.count : 0
      }
      this.setState(nextState)
    })
  }
  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
  toggleActiveWallet() {
    let value = !this.state.showActiveWallets ? 'active' : 'both'
    this.setState({ showActiveWallets: !this.state.showActiveWallets, walletFilterValue: value, showInActiveWallets: false })
  }
  toggleInActiveWallet() {
    let value = this.state.showInActiveWallets ? 'both' : 'inactive'
    this.setState({ showInActiveWallets: !this.state.showInActiveWallets, walletFilterValue: value, showActiveWallets: false })
  }
  setLimit(data) {
    if (this.state.fetchingWalletsInfo) {
      return
    }
    let { start, limit } = this.state
    data === 'previous' ? (start = start - limit) : (start = start + limit)
    this.setState({ start })
    this.getTotalWalletDetails()
  }
  removeWallet(WalletID = null, removed = null) {
    let walletsData = this.state.walletsData
    if (WalletID) {
      POST('wallets/admin-remove-wallet', { WalletID: WalletID, removed: !removed }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          walletsData.map(item => {
            if (item.WalletID === WalletID) {
              item.removed = removed ? 0 : 1
            }
            return item
          })
        }
        this.setState({ walletsData })
      })
    }
  }
  clearFilters() {
    this.setState({ filters: [] })
    this.addFilter()
    this.getTotalWalletDetails()
  }
}
