import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { StatActions, StatStore, STATE } from 'Stores/statStore'
import StatForm from 'Modules/stat/statForm'
import _ from 'lodash'

export default class StatCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [StatStore]
    this.storeKeys = ['loadingState', 'statDetails']
  }
  componentDidMount() {
    if (_.get(this.props, 'location.search')) {
      const search = this.props.location.search.split('=')
      StatActions.getStatDetails(Number(search[1]), true)
    } else {
      StatActions.createEmptyStat()
    }
  }
  componentDidUpdate() {
    if (this.state.loadingState === STATE.STAT_SUCCESS) {
      toast.success('Successfully Created', {
        autoClose: 7500,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.clearToast(STATE.IS_REDIRECT)
    }
    if (this.state.loadingState === STATE.STAT_FAILED) {
      toast.error('Error while saving STAT', {
        autoClose: 7500,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <StatForm {...this.props} />
      </React.Fragment>
    )
  }
}
