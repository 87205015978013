import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, PUT, wrapFilter } = Proxies
import css from 'css'

const compressedOptions = { compress: true }
const prettyOptions = { compress: false, indent: '\t' }

export const CustomCssActions = Reflux.createActions(['getData', 'updateCSS', 'saveData'])

export class CustomCssStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = CustomCssActions
    this.state = {
      /**/
      fetchingCSS: false,
      CSS: '',
      cssCustomizationID: null,
      noCustomCSS: true
    }
  }
  getData() {
    let baseData = { CustomisedID: Reflux.GlobalState.LoginStore.BrandID, customisedType: 'Brand', title: 'customCSS' }
    this.setState({ fetchingCSS: true, CSS: '' })
    let nextState = {
      /**/
      fetchingCSS: true,
      CSS: ''
    }
    GET('customisationv2', wrapFilter(baseData)).then(cssCustomization => {
      if (cssCustomization.length) {
        nextState.cssCustomizationID = cssCustomization[0].CustomisationID
        nextState.noCustomCSS = false
        const customCSS = JSON.parse(cssCustomization[0].customisationData).customCSS
        nextState.CSS = css.stringify(css.parse(customCSS), prettyOptions)
      }
      this.setState(nextState)
    })
  }
  updateCSS(e) {
    this.setState({ CSS: e.target.value })
  }
  saveData() {
    const baseData = {
      CustomisedID: Reflux.GlobalState.LoginStore.BrandID,
      customisedType: 'Brand',
      description: 'CSS customization for ' + Reflux.GlobalState.LoginStore.brandData.brandName,
      title: 'customCSS',
      CustomisationID: this.state.cssCustomizationID,
      customisationData: JSON.stringify({ customCSS: css.stringify(css.parse(this.state.CSS), compressedOptions) })
    }
    PUT('customisationv2', baseData).then(responseJSON => {
      this.setState({
        cssCustomizationID: responseJSON.CustomisationID,
        noCustomCSS: false
      })
    })
  }
}
