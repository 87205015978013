import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { toast } from 'react-toastify'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Button, Input, Label, Row, Col } from 'reactstrap'
import FormField from 'Components/formField'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'

let headers = {
  UserID: 'BrugerID',
  username: 'Bruger Navn',
  userUID: 'UID',
  userEmail: 'Email',
  userPhone: 'Mobile',
  userAccess: 'Access',
  cprExists: 'CPR',
  userCompany: 'Firma',
  userAddress: 'Adresse',
  userZipcode: 'Postnr.',
  userCity: 'By',
  pointsReceived: 'Earned Points',
  pointsReceivedDKK: 'Earned DKK',
  pointsSpent: 'Spent Points',
  pointsSpentDKK: 'Spent DKK',
  lastLogin: 'Last login'
}

const EarningsTaxReport = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear() - 1)
  const [quarter, setQuarter] = useState(1)
  const [BrandID, setBrandID] = useState(null)
  const [brands, setBrands] = useState([])
  const [emails, setEmails] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  function fetchEarningsTaxReport() {
    Proxies.GET('orders/getEarningsTax', { year, quarter, BrandID, startDate, endDate }).then(response => {
      if (response) {
        setData(response)
      }
    })
  }

  function sendTaxReport() {
    if (!emails) return
    Proxies.POST('orders/sendEarningsTaxReport', { year, BrandID, quarter, emails, startDate, endDate }).then(response => {
      if (response) {
        toast.success('Email sent successfully', {
          autoClose: 5000
        })
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    Proxies.GET('menus/listBrands').then(responseJSON => {
      setBrands(responseJSON)
    })
    setLoading(false)
  }, [])

  if (loading) {
    return <FontAwesomeIcon icon='spinner' spin />
  }

  return (
    <React.Fragment>
      <CollapsibleCard header={<strong>Ordrer</strong>} startOpen={true}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField field={'BrandID'} name='Brand'>
            <Input
              type='select'
              name='BrandID'
              value={BrandID}
              onChange={e => {
                setBrandID(e.target.value)
              }}
            >
              <option selected='true' disabled={true} value={''}>
                Select Brand
              </option>
              {brands &&
                !!brands.length &&
                brands.map(e => {
                  return (
                    <option value={e.BrandID} key={e.BrandID}>
                      {e.BrandID} - {e.brandName}
                    </option>
                  )
                })}
            </Input>
          </FormField>
          <Row className='mb-3'>
            <Col xs={3}>
              <Label htmlFor='startDate'>Date range</Label>
            </Col>
            <Col xs={4}>
              <ReactDatePicker
                isClearable
                placeholderText='Vælg en dato at filtrere fra'
                className='form-control'
                maxDate={moment().add(0, 'days')}
                dateFormat={'DD/MM/YYYY'}
                selected={startDate ? moment(startDate) : null}
                viewMode={'days'}
                name='startDate'
                id='startDate'
                onChange={date => {
                  setStartDate(date.format())
                }}
              />
            </Col>
            <Col xs={4}>
              <ReactDatePicker
                isClearable
                placeholderText='Vælg en dato at filtrere to'
                className='form-control'
                minDate={startDate ? moment(startDate).add(1, 'days') : moment().add(0, 'days')}
                maxDate={moment().add(0, 'days')}
                dateFormat={'DD/MM/YYYY'}
                selected={endDate ? moment(endDate) : null}
                viewMode={'days'}
                name='endDate'
                id='endDate'
                onChange={date => {
                  setEndDate(date.format())
                }}
              />
            </Col>
          </Row>
          <FormField name='Year' rows={5} field='year'>
            <Input disabled={!!startDate} type='number' onChange={e => setYear(e.target.value)} name='year' value={year} />
          </FormField>
          <FormField field={'quarter'} name='Quarter'>
            <Input
              disabled={!!startDate}
              type='select'
              name='Quarter'
              value={quarter}
              onChange={e => {
                setQuarter(e.target.value)
              }}
            >
              <option selected='true' disabled={true} value={''}>
                Select Quarter
              </option>
              <option value={1}>Q1</option>
              <option value={2}>Q2</option>
              <option value={3}>Q3</option>
              <option value={4}>Q4</option>
            </Input>
          </FormField>
          {data?.length ? (
            <FormField name='Emails' rows={5} field='emails'>
              <Input
                type='email'
                onChange={e => setEmails(e.target.value)}
                name='emails'
                value={emails}
                placeholder='Enter email addresses, use ; to specify mulitple'
              />
            </FormField>
          ) : null}
        </Form>
        <div style={{ flexDirection: 'row', marginBottom: 32, paddingHorizontal: 16 }}>
          <Button disabled={!year || !BrandID} style={{ marginRight: 16 }} onClick={() => fetchEarningsTaxReport()}>
            Search
          </Button>
          <Button disabled={!data?.length || !emails?.length} style={{ marginLeft: 16 }} onClick={() => sendTaxReport()}>
            Send Report
          </Button>
        </div>
        {data && !data.length ? <p>No Orders in the selected year.</p> : null}
        {data?.length ? <DynamicTable data={data} headers={headers} /> : null}
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default EarningsTaxReport
