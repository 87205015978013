export const postalCodesDK = [
  { postalCode: '1000', city: 'Copenhagen K' },
  { postalCode: '1001', city: 'Copenhagen K' },
  { postalCode: '1002', city: 'Copenhagen K' },
  { postalCode: '1003', city: 'Copenhagen K' },
  { postalCode: '1004', city: 'Copenhagen K' },
  { postalCode: '1005', city: 'Copenhagen K' },
  { postalCode: '1006', city: 'Copenhagen K' },
  { postalCode: '1007', city: 'Copenhagen K' },
  { postalCode: '1008', city: 'Copenhagen K' },
  { postalCode: '1009', city: 'Copenhagen K' },
  { postalCode: '1010', city: 'Copenhagen K' },
  { postalCode: '1011', city: 'Copenhagen K' },
  { postalCode: '1012', city: 'Copenhagen K' },
  { postalCode: '1013', city: 'Copenhagen K' },
  { postalCode: '1014', city: 'Copenhagen K' },
  { postalCode: '1015', city: 'Copenhagen K' },
  { postalCode: '1016', city: 'Copenhagen K' },
  { postalCode: '1017', city: 'Copenhagen K' },
  { postalCode: '1018', city: 'Copenhagen K' },
  { postalCode: '1019', city: 'Copenhagen K' },
  { postalCode: '1020', city: 'Copenhagen K' },
  { postalCode: '1021', city: 'Copenhagen K' },
  { postalCode: '1022', city: 'Copenhagen K' },
  { postalCode: '1023', city: 'Copenhagen K' },
  { postalCode: '1024', city: 'Copenhagen K' },
  { postalCode: '1025', city: 'Copenhagen K' },
  { postalCode: '1026', city: 'Copenhagen K' },
  { postalCode: '1027', city: 'Copenhagen K' },
  { postalCode: '1028', city: 'Copenhagen K' },
  { postalCode: '1029', city: 'Copenhagen K' },
  { postalCode: '1030', city: 'Copenhagen K' },
  { postalCode: '1031', city: 'Copenhagen K' },
  { postalCode: '1032', city: 'Copenhagen K' },
  { postalCode: '1033', city: 'Copenhagen K' },
  { postalCode: '1034', city: 'Copenhagen K' },
  { postalCode: '1035', city: 'Copenhagen K' },
  { postalCode: '1036', city: 'Copenhagen K' },
  { postalCode: '1037', city: 'Copenhagen K' },
  { postalCode: '1038', city: 'Copenhagen K' },
  { postalCode: '1039', city: 'Copenhagen K' },
  { postalCode: '1040', city: 'Copenhagen K' },
  { postalCode: '1041', city: 'Copenhagen K' },
  { postalCode: '1042', city: 'Copenhagen K' },
  { postalCode: '1043', city: 'Copenhagen K' },
  { postalCode: '1044', city: 'Copenhagen K' },
  { postalCode: '1045', city: 'Copenhagen K' },
  { postalCode: '1046', city: 'Copenhagen K' },
  { postalCode: '1047', city: 'Copenhagen K' },
  { postalCode: '1048', city: 'Copenhagen K' },
  { postalCode: '1049', city: 'Copenhagen K' },
  { postalCode: '1050', city: 'Copenhagen K' },
  { postalCode: '1051', city: 'Copenhagen K' },
  { postalCode: '1052', city: 'Copenhagen K' },
  { postalCode: '1053', city: 'Copenhagen K' },
  { postalCode: '1054', city: 'Copenhagen K' },
  { postalCode: '1055', city: 'Copenhagen K' },
  { postalCode: '1056', city: 'Copenhagen K' },
  { postalCode: '1057', city: 'Copenhagen K' },
  { postalCode: '1058', city: 'Copenhagen K' },
  { postalCode: '1059', city: 'Copenhagen K' },
  { postalCode: '1060', city: 'Copenhagen K' },
  { postalCode: '1061', city: 'Copenhagen K' },
  { postalCode: '1062', city: 'Copenhagen K' },
  { postalCode: '1063', city: 'Copenhagen K' },
  { postalCode: '1064', city: 'Copenhagen K' },
  { postalCode: '1065', city: 'Copenhagen K' },
  { postalCode: '1066', city: 'Copenhagen K' },
  { postalCode: '1067', city: 'Copenhagen K' },
  { postalCode: '1068', city: 'Copenhagen K' },
  { postalCode: '1069', city: 'Copenhagen K' },
  { postalCode: '1070', city: 'Copenhagen K' },
  { postalCode: '1071', city: 'Copenhagen K' },
  { postalCode: '1072', city: 'Copenhagen K' },
  { postalCode: '1073', city: 'Copenhagen K' },
  { postalCode: '1074', city: 'Copenhagen K' },
  { postalCode: '1075', city: 'Copenhagen K' },
  { postalCode: '1076', city: 'Copenhagen K' },
  { postalCode: '1077', city: 'Copenhagen K' },
  { postalCode: '1078', city: 'Copenhagen K' },
  { postalCode: '1079', city: 'Copenhagen K' },
  { postalCode: '1080', city: 'Copenhagen K' },
  { postalCode: '1081', city: 'Copenhagen K' },
  { postalCode: '1082', city: 'Copenhagen K' },
  { postalCode: '1083', city: 'Copenhagen K' },
  { postalCode: '1084', city: 'Copenhagen K' },
  { postalCode: '1085', city: 'Copenhagen K' },
  { postalCode: '1086', city: 'Copenhagen K' },
  { postalCode: '1087', city: 'Copenhagen K' },
  { postalCode: '1088', city: 'Copenhagen K' },
  { postalCode: '1089', city: 'Copenhagen K' },
  { postalCode: '1090', city: 'Copenhagen K' },
  { postalCode: '1091', city: 'Copenhagen K' },
  { postalCode: '1092', city: 'Copenhagen K' },
  { postalCode: '1093', city: 'Copenhagen K' },
  { postalCode: '1094', city: 'Copenhagen K' },
  { postalCode: '1095', city: 'Copenhagen K' },
  { postalCode: '1096', city: 'Copenhagen K' },
  { postalCode: '1097', city: 'Copenhagen K' },
  { postalCode: '1098', city: 'Copenhagen K' },
  { postalCode: '1099', city: 'Copenhagen K' },
  { postalCode: '1100', city: 'Copenhagen K' },
  { postalCode: '1101', city: 'Copenhagen K' },
  { postalCode: '1102', city: 'Copenhagen K' },
  { postalCode: '1103', city: 'Copenhagen K' },
  { postalCode: '1104', city: 'Copenhagen K' },
  { postalCode: '1105', city: 'Copenhagen K' },
  { postalCode: '1106', city: 'Copenhagen K' },
  { postalCode: '1107', city: 'Copenhagen K' },
  { postalCode: '1108', city: 'Copenhagen K' },
  { postalCode: '1109', city: 'Copenhagen K' },
  { postalCode: '1110', city: 'Copenhagen K' },
  { postalCode: '1111', city: 'Copenhagen K' },
  { postalCode: '1112', city: 'Copenhagen K' },
  { postalCode: '1113', city: 'Copenhagen K' },
  { postalCode: '1114', city: 'Copenhagen K' },
  { postalCode: '1115', city: 'Copenhagen K' },
  { postalCode: '1116', city: 'Copenhagen K' },
  { postalCode: '1117', city: 'Copenhagen K' },
  { postalCode: '1118', city: 'Copenhagen K' },
  { postalCode: '1119', city: 'Copenhagen K' },
  { postalCode: '1120', city: 'Copenhagen K' },
  { postalCode: '1121', city: 'Copenhagen K' },
  { postalCode: '1122', city: 'Copenhagen K' },
  { postalCode: '1123', city: 'Copenhagen K' },
  { postalCode: '1124', city: 'Copenhagen K' },
  { postalCode: '1125', city: 'Copenhagen K' },
  { postalCode: '1126', city: 'Copenhagen K' },
  { postalCode: '1127', city: 'Copenhagen K' },
  { postalCode: '1128', city: 'Copenhagen K' },
  { postalCode: '1129', city: 'Copenhagen K' },
  { postalCode: '1130', city: 'Copenhagen K' },
  { postalCode: '1131', city: 'Copenhagen K' },
  { postalCode: '1132', city: 'Copenhagen K' },
  { postalCode: '1133', city: 'Copenhagen K' },
  { postalCode: '1134', city: 'Copenhagen K' },
  { postalCode: '1135', city: 'Copenhagen K' },
  { postalCode: '1136', city: 'Copenhagen K' },
  { postalCode: '1137', city: 'Copenhagen K' },
  { postalCode: '1138', city: 'Copenhagen K' },
  { postalCode: '1139', city: 'Copenhagen K' },
  { postalCode: '1140', city: 'Copenhagen K' },
  { postalCode: '1141', city: 'Copenhagen K' },
  { postalCode: '1142', city: 'Copenhagen K' },
  { postalCode: '1143', city: 'Copenhagen K' },
  { postalCode: '1144', city: 'Copenhagen K' },
  { postalCode: '1145', city: 'Copenhagen K' },
  { postalCode: '1146', city: 'Copenhagen K' },
  { postalCode: '1147', city: 'Copenhagen K' },
  { postalCode: '1148', city: 'Copenhagen K' },
  { postalCode: '1149', city: 'Copenhagen K' },
  { postalCode: '1150', city: 'Copenhagen K' },
  { postalCode: '1151', city: 'Copenhagen K' },
  { postalCode: '1152', city: 'Copenhagen K' },
  { postalCode: '1153', city: 'Copenhagen K' },
  { postalCode: '1154', city: 'Copenhagen K' },
  { postalCode: '1155', city: 'Copenhagen K' },
  { postalCode: '1156', city: 'Copenhagen K' },
  { postalCode: '1157', city: 'Copenhagen K' },
  { postalCode: '1158', city: 'Copenhagen K' },
  { postalCode: '1159', city: 'Copenhagen K' },
  { postalCode: '1160', city: 'Copenhagen K' },
  { postalCode: '1161', city: 'Copenhagen K' },
  { postalCode: '1162', city: 'Copenhagen K' },
  { postalCode: '1163', city: 'Copenhagen K' },
  { postalCode: '1164', city: 'Copenhagen K' },
  { postalCode: '1165', city: 'Copenhagen K' },
  { postalCode: '1166', city: 'Copenhagen K' },
  { postalCode: '1167', city: 'Copenhagen K' },
  { postalCode: '1168', city: 'Copenhagen K' },
  { postalCode: '1169', city: 'Copenhagen K' },
  { postalCode: '1170', city: 'Copenhagen K' },
  { postalCode: '1171', city: 'Copenhagen K' },
  { postalCode: '1172', city: 'Copenhagen K' },
  { postalCode: '1173', city: 'Copenhagen K' },
  { postalCode: '1174', city: 'Copenhagen K' },
  { postalCode: '1175', city: 'Copenhagen K' },
  { postalCode: '1176', city: 'Copenhagen K' },
  { postalCode: '1177', city: 'Copenhagen K' },
  { postalCode: '1178', city: 'Copenhagen K' },
  { postalCode: '1179', city: 'Copenhagen K' },
  { postalCode: '1180', city: 'Copenhagen K' },
  { postalCode: '1181', city: 'Copenhagen K' },
  { postalCode: '1182', city: 'Copenhagen K' },
  { postalCode: '1183', city: 'Copenhagen K' },
  { postalCode: '1184', city: 'Copenhagen K' },
  { postalCode: '1185', city: 'Copenhagen K' },
  { postalCode: '1186', city: 'Copenhagen K' },
  { postalCode: '1187', city: 'Copenhagen K' },
  { postalCode: '1188', city: 'Copenhagen K' },
  { postalCode: '1189', city: 'Copenhagen K' },
  { postalCode: '1190', city: 'Copenhagen K' },
  { postalCode: '1191', city: 'Copenhagen K' },
  { postalCode: '1192', city: 'Copenhagen K' },
  { postalCode: '1193', city: 'Copenhagen K' },
  { postalCode: '1194', city: 'Copenhagen K' },
  { postalCode: '1195', city: 'Copenhagen K' },
  { postalCode: '1196', city: 'Copenhagen K' },
  { postalCode: '1197', city: 'Copenhagen K' },
  { postalCode: '1198', city: 'Copenhagen K' },
  { postalCode: '1199', city: 'Copenhagen K' },
  { postalCode: '1200', city: 'Copenhagen K' },
  { postalCode: '1201', city: 'Copenhagen K' },
  { postalCode: '1202', city: 'Copenhagen K' },
  { postalCode: '1203', city: 'Copenhagen K' },
  { postalCode: '1204', city: 'Copenhagen K' },
  { postalCode: '1205', city: 'Copenhagen K' },
  { postalCode: '1206', city: 'Copenhagen K' },
  { postalCode: '1207', city: 'Copenhagen K' },
  { postalCode: '1208', city: 'Copenhagen K' },
  { postalCode: '1209', city: 'Copenhagen K' },
  { postalCode: '1210', city: 'Copenhagen K' },
  { postalCode: '1211', city: 'Copenhagen K' },
  { postalCode: '1212', city: 'Copenhagen K' },
  { postalCode: '1213', city: 'Copenhagen K' },
  { postalCode: '1214', city: 'Copenhagen K' },
  { postalCode: '1215', city: 'Copenhagen K' },
  { postalCode: '1216', city: 'Copenhagen K' },
  { postalCode: '1217', city: 'Copenhagen K' },
  { postalCode: '1218', city: 'Copenhagen K' },
  { postalCode: '1219', city: 'Copenhagen K' },
  { postalCode: '1220', city: 'Copenhagen K' },
  { postalCode: '1221', city: 'Copenhagen K' },
  { postalCode: '1222', city: 'Copenhagen K' },
  { postalCode: '1223', city: 'Copenhagen K' },
  { postalCode: '1224', city: 'Copenhagen K' },
  { postalCode: '1225', city: 'Copenhagen K' },
  { postalCode: '1226', city: 'Copenhagen K' },
  { postalCode: '1227', city: 'Copenhagen K' },
  { postalCode: '1228', city: 'Copenhagen K' },
  { postalCode: '1229', city: 'Copenhagen K' },
  { postalCode: '1230', city: 'Copenhagen K' },
  { postalCode: '1231', city: 'Copenhagen K' },
  { postalCode: '1232', city: 'Copenhagen K' },
  { postalCode: '1233', city: 'Copenhagen K' },
  { postalCode: '1234', city: 'Copenhagen K' },
  { postalCode: '1235', city: 'Copenhagen K' },
  { postalCode: '1236', city: 'Copenhagen K' },
  { postalCode: '1237', city: 'Copenhagen K' },
  { postalCode: '1238', city: 'Copenhagen K' },
  { postalCode: '1239', city: 'Copenhagen K' },
  { postalCode: '1240', city: 'Copenhagen K' },
  { postalCode: '1241', city: 'Copenhagen K' },
  { postalCode: '1242', city: 'Copenhagen K' },
  { postalCode: '1243', city: 'Copenhagen K' },
  { postalCode: '1244', city: 'Copenhagen K' },
  { postalCode: '1245', city: 'Copenhagen K' },
  { postalCode: '1246', city: 'Copenhagen K' },
  { postalCode: '1247', city: 'Copenhagen K' },
  { postalCode: '1248', city: 'Copenhagen K' },
  { postalCode: '1249', city: 'Copenhagen K' },
  { postalCode: '1250', city: 'Copenhagen K' },
  { postalCode: '1251', city: 'Copenhagen K' },
  { postalCode: '1252', city: 'Copenhagen K' },
  { postalCode: '1253', city: 'Copenhagen K' },
  { postalCode: '1254', city: 'Copenhagen K' },
  { postalCode: '1255', city: 'Copenhagen K' },
  { postalCode: '1256', city: 'Copenhagen K' },
  { postalCode: '1257', city: 'Copenhagen K' },
  { postalCode: '1258', city: 'Copenhagen K' },
  { postalCode: '1259', city: 'Copenhagen K' },
  { postalCode: '1260', city: 'Copenhagen K' },
  { postalCode: '1261', city: 'Copenhagen K' },
  { postalCode: '1262', city: 'Copenhagen K' },
  { postalCode: '1263', city: 'Copenhagen K' },
  { postalCode: '1264', city: 'Copenhagen K' },
  { postalCode: '1265', city: 'Copenhagen K' },
  { postalCode: '1266', city: 'Copenhagen K' },
  { postalCode: '1267', city: 'Copenhagen K' },
  { postalCode: '1268', city: 'Copenhagen K' },
  { postalCode: '1269', city: 'Copenhagen K' },
  { postalCode: '1270', city: 'Copenhagen K' },
  { postalCode: '1271', city: 'Copenhagen K' },
  { postalCode: '1272', city: 'Copenhagen K' },
  { postalCode: '1273', city: 'Copenhagen K' },
  { postalCode: '1274', city: 'Copenhagen K' },
  { postalCode: '1275', city: 'Copenhagen K' },
  { postalCode: '1276', city: 'Copenhagen K' },
  { postalCode: '1277', city: 'Copenhagen K' },
  { postalCode: '1278', city: 'Copenhagen K' },
  { postalCode: '1279', city: 'Copenhagen K' },
  { postalCode: '1280', city: 'Copenhagen K' },
  { postalCode: '1281', city: 'Copenhagen K' },
  { postalCode: '1282', city: 'Copenhagen K' },
  { postalCode: '1283', city: 'Copenhagen K' },
  { postalCode: '1284', city: 'Copenhagen K' },
  { postalCode: '1285', city: 'Copenhagen K' },
  { postalCode: '1286', city: 'Copenhagen K' },
  { postalCode: '1287', city: 'Copenhagen K' },
  { postalCode: '1288', city: 'Copenhagen K' },
  { postalCode: '1289', city: 'Copenhagen K' },
  { postalCode: '1290', city: 'Copenhagen K' },
  { postalCode: '1291', city: 'Copenhagen K' },
  { postalCode: '1292', city: 'Copenhagen K' },
  { postalCode: '1293', city: 'Copenhagen K' },
  { postalCode: '1294', city: 'Copenhagen K' },
  { postalCode: '1295', city: 'Copenhagen K' },
  { postalCode: '1296', city: 'Copenhagen K' },
  { postalCode: '1297', city: 'Copenhagen K' },
  { postalCode: '1298', city: 'Copenhagen K' },
  { postalCode: '1299', city: 'Copenhagen K' },
  { postalCode: '1300', city: 'Copenhagen K' },
  { postalCode: '1301', city: 'Copenhagen K' },
  { postalCode: '1302', city: 'Copenhagen K' },
  { postalCode: '1303', city: 'Copenhagen K' },
  { postalCode: '1304', city: 'Copenhagen K' },
  { postalCode: '1305', city: 'Copenhagen K' },
  { postalCode: '1306', city: 'Copenhagen K' },
  { postalCode: '1307', city: 'Copenhagen K' },
  { postalCode: '1308', city: 'Copenhagen K' },
  { postalCode: '1309', city: 'Copenhagen K' },
  { postalCode: '1310', city: 'Copenhagen K' },
  { postalCode: '1311', city: 'Copenhagen K' },
  { postalCode: '1312', city: 'Copenhagen K' },
  { postalCode: '1313', city: 'Copenhagen K' },
  { postalCode: '1314', city: 'Copenhagen K' },
  { postalCode: '1315', city: 'Copenhagen K' },
  { postalCode: '1316', city: 'Copenhagen K' },
  { postalCode: '1317', city: 'Copenhagen K' },
  { postalCode: '1318', city: 'Copenhagen K' },
  { postalCode: '1319', city: 'Copenhagen K' },
  { postalCode: '1320', city: 'Copenhagen K' },
  { postalCode: '1321', city: 'Copenhagen K' },
  { postalCode: '1322', city: 'Copenhagen K' },
  { postalCode: '1323', city: 'Copenhagen K' },
  { postalCode: '1324', city: 'Copenhagen K' },
  { postalCode: '1325', city: 'Copenhagen K' },
  { postalCode: '1326', city: 'Copenhagen K' },
  { postalCode: '1327', city: 'Copenhagen K' },
  { postalCode: '1328', city: 'Copenhagen K' },
  { postalCode: '1329', city: 'Copenhagen K' },
  { postalCode: '1330', city: 'Copenhagen K' },
  { postalCode: '1331', city: 'Copenhagen K' },
  { postalCode: '1332', city: 'Copenhagen K' },
  { postalCode: '1333', city: 'Copenhagen K' },
  { postalCode: '1334', city: 'Copenhagen K' },
  { postalCode: '1335', city: 'Copenhagen K' },
  { postalCode: '1336', city: 'Copenhagen K' },
  { postalCode: '1337', city: 'Copenhagen K' },
  { postalCode: '1338', city: 'Copenhagen K' },
  { postalCode: '1339', city: 'Copenhagen K' },
  { postalCode: '1340', city: 'Copenhagen K' },
  { postalCode: '1341', city: 'Copenhagen K' },
  { postalCode: '1342', city: 'Copenhagen K' },
  { postalCode: '1343', city: 'Copenhagen K' },
  { postalCode: '1344', city: 'Copenhagen K' },
  { postalCode: '1345', city: 'Copenhagen K' },
  { postalCode: '1346', city: 'Copenhagen K' },
  { postalCode: '1347', city: 'Copenhagen K' },
  { postalCode: '1348', city: 'Copenhagen K' },
  { postalCode: '1349', city: 'Copenhagen K' },
  { postalCode: '1350', city: 'Copenhagen K' },
  { postalCode: '1351', city: 'Copenhagen K' },
  { postalCode: '1352', city: 'Copenhagen K' },
  { postalCode: '1353', city: 'Copenhagen K' },
  { postalCode: '1354', city: 'Copenhagen K' },
  { postalCode: '1355', city: 'Copenhagen K' },
  { postalCode: '1356', city: 'Copenhagen K' },
  { postalCode: '1357', city: 'Copenhagen K' },
  { postalCode: '1358', city: 'Copenhagen K' },
  { postalCode: '1359', city: 'Copenhagen K' },
  { postalCode: '1360', city: 'Copenhagen K' },
  { postalCode: '1361', city: 'Copenhagen K' },
  { postalCode: '1362', city: 'Copenhagen K' },
  { postalCode: '1363', city: 'Copenhagen K' },
  { postalCode: '1364', city: 'Copenhagen K' },
  { postalCode: '1365', city: 'Copenhagen K' },
  { postalCode: '1366', city: 'Copenhagen K' },
  { postalCode: '1367', city: 'Copenhagen K' },
  { postalCode: '1368', city: 'Copenhagen K' },
  { postalCode: '1369', city: 'Copenhagen K' },
  { postalCode: '1370', city: 'Copenhagen K' },
  { postalCode: '1371', city: 'Copenhagen K' },
  { postalCode: '1372', city: 'Copenhagen K' },
  { postalCode: '1373', city: 'Copenhagen K' },
  { postalCode: '1374', city: 'Copenhagen K' },
  { postalCode: '1375', city: 'Copenhagen K' },
  { postalCode: '1376', city: 'Copenhagen K' },
  { postalCode: '1377', city: 'Copenhagen K' },
  { postalCode: '1378', city: 'Copenhagen K' },
  { postalCode: '1379', city: 'Copenhagen K' },
  { postalCode: '1380', city: 'Copenhagen K' },
  { postalCode: '1381', city: 'Copenhagen K' },
  { postalCode: '1382', city: 'Copenhagen K' },
  { postalCode: '1383', city: 'Copenhagen K' },
  { postalCode: '1384', city: 'Copenhagen K' },
  { postalCode: '1385', city: 'Copenhagen K' },
  { postalCode: '1386', city: 'Copenhagen K' },
  { postalCode: '1387', city: 'Copenhagen K' },
  { postalCode: '1388', city: 'Copenhagen K' },
  { postalCode: '1389', city: 'Copenhagen K' },
  { postalCode: '1390', city: 'Copenhagen K' },
  { postalCode: '1391', city: 'Copenhagen K' },
  { postalCode: '1392', city: 'Copenhagen K' },
  { postalCode: '1393', city: 'Copenhagen K' },
  { postalCode: '1394', city: 'Copenhagen K' },
  { postalCode: '1395', city: 'Copenhagen K' },
  { postalCode: '1396', city: 'Copenhagen K' },
  { postalCode: '1397', city: 'Copenhagen K' },
  { postalCode: '1398', city: 'Copenhagen K' },
  { postalCode: '1399', city: 'Copenhagen K' },
  { postalCode: '1400', city: 'Copenhagen K' },
  { postalCode: '1401', city: 'Copenhagen K' },
  { postalCode: '1402', city: 'Copenhagen K' },
  { postalCode: '1403', city: 'Copenhagen K' },
  { postalCode: '1404', city: 'Copenhagen K' },
  { postalCode: '1405', city: 'Copenhagen K' },
  { postalCode: '1406', city: 'Copenhagen K' },
  { postalCode: '1407', city: 'Copenhagen K' },
  { postalCode: '1408', city: 'Copenhagen K' },
  { postalCode: '1409', city: 'Copenhagen K' },
  { postalCode: '1410', city: 'Copenhagen K' },
  { postalCode: '1411', city: 'Copenhagen K' },
  { postalCode: '1412', city: 'Copenhagen K' },
  { postalCode: '1413', city: 'Copenhagen K' },
  { postalCode: '1414', city: 'Copenhagen K' },
  { postalCode: '1415', city: 'Copenhagen K' },
  { postalCode: '1416', city: 'Copenhagen K' },
  { postalCode: '1417', city: 'Copenhagen K' },
  { postalCode: '1418', city: 'Copenhagen K' },
  { postalCode: '1419', city: 'Copenhagen K' },
  { postalCode: '1420', city: 'Copenhagen K' },
  { postalCode: '1421', city: 'Copenhagen K' },
  { postalCode: '1422', city: 'Copenhagen K' },
  { postalCode: '1423', city: 'Copenhagen K' },
  { postalCode: '1424', city: 'Copenhagen K' },
  { postalCode: '1425', city: 'Copenhagen K' },
  { postalCode: '1426', city: 'Copenhagen K' },
  { postalCode: '1427', city: 'Copenhagen K' },
  { postalCode: '1428', city: 'Copenhagen K' },
  { postalCode: '1429', city: 'Copenhagen K' },
  { postalCode: '1430', city: 'Copenhagen K' },
  { postalCode: '1431', city: 'Copenhagen K' },
  { postalCode: '1432', city: 'Copenhagen K' },
  { postalCode: '1433', city: 'Copenhagen K' },
  { postalCode: '1434', city: 'Copenhagen K' },
  { postalCode: '1435', city: 'Copenhagen K' },
  { postalCode: '1436', city: 'Copenhagen K' },
  { postalCode: '1437', city: 'Copenhagen K' },
  { postalCode: '1438', city: 'Copenhagen K' },
  { postalCode: '1439', city: 'Copenhagen K' },
  { postalCode: '1440', city: 'Copenhagen K' },
  { postalCode: '1441', city: 'Copenhagen K' },
  { postalCode: '1442', city: 'Copenhagen K' },
  { postalCode: '1443', city: 'Copenhagen K' },
  { postalCode: '1444', city: 'Copenhagen K' },
  { postalCode: '1445', city: 'Copenhagen K' },
  { postalCode: '1446', city: 'Copenhagen K' },
  { postalCode: '1447', city: 'Copenhagen K' },
  { postalCode: '1448', city: 'Copenhagen K' },
  { postalCode: '1449', city: 'Copenhagen K' },
  { postalCode: '1450', city: 'Copenhagen K' },
  { postalCode: '1451', city: 'Copenhagen K' },
  { postalCode: '1452', city: 'Copenhagen K' },
  { postalCode: '1453', city: 'Copenhagen K' },
  { postalCode: '1454', city: 'Copenhagen K' },
  { postalCode: '1455', city: 'Copenhagen K' },
  { postalCode: '1456', city: 'Copenhagen K' },
  { postalCode: '1457', city: 'Copenhagen K' },
  { postalCode: '1458', city: 'Copenhagen K' },
  { postalCode: '1459', city: 'Copenhagen K' },
  { postalCode: '1460', city: 'Copenhagen K' },
  { postalCode: '1461', city: 'Copenhagen K' },
  { postalCode: '1462', city: 'Copenhagen K' },
  { postalCode: '1463', city: 'Copenhagen K' },
  { postalCode: '1464', city: 'Copenhagen K' },
  { postalCode: '1465', city: 'Copenhagen K' },
  { postalCode: '1466', city: 'Copenhagen K' },
  { postalCode: '1467', city: 'Copenhagen K' },
  { postalCode: '1468', city: 'Copenhagen K' },
  { postalCode: '1469', city: 'Copenhagen K' },
  { postalCode: '1470', city: 'Copenhagen K' },
  { postalCode: '1471', city: 'Copenhagen K' },
  { postalCode: '1472', city: 'Copenhagen K' },
  { postalCode: '1473', city: 'Copenhagen K' },
  { postalCode: '1474', city: 'Copenhagen K' },
  { postalCode: '1475', city: 'Copenhagen K' },
  { postalCode: '1476', city: 'Copenhagen K' },
  { postalCode: '1477', city: 'Copenhagen K' },
  { postalCode: '1478', city: 'Copenhagen K' },
  { postalCode: '1479', city: 'Copenhagen K' },
  { postalCode: '1480', city: 'Copenhagen K' },
  { postalCode: '1481', city: 'Copenhagen K' },
  { postalCode: '1482', city: 'Copenhagen K' },
  { postalCode: '1483', city: 'Copenhagen K' },
  { postalCode: '1484', city: 'Copenhagen K' },
  { postalCode: '1485', city: 'Copenhagen K' },
  { postalCode: '1486', city: 'Copenhagen K' },
  { postalCode: '1487', city: 'Copenhagen K' },
  { postalCode: '1488', city: 'Copenhagen K' },
  { postalCode: '1489', city: 'Copenhagen K' },
  { postalCode: '1490', city: 'Copenhagen K' },
  { postalCode: '1491', city: 'Copenhagen K' },
  { postalCode: '1492', city: 'Copenhagen K' },
  { postalCode: '1493', city: 'Copenhagen K' },
  { postalCode: '1494', city: 'Copenhagen K' },
  { postalCode: '1495', city: 'Copenhagen K' },
  { postalCode: '1496', city: 'Copenhagen K' },
  { postalCode: '1497', city: 'Copenhagen K' },
  { postalCode: '1498', city: 'Copenhagen K' },
  { postalCode: '1499', city: 'Copenhagen K' },
  { postalCode: '1500', city: 'Copenhagen V' },
  { postalCode: '1501', city: 'Copenhagen V' },
  { postalCode: '1502', city: 'Copenhagen V' },
  { postalCode: '1503', city: 'Copenhagen V' },
  { postalCode: '1504', city: 'Copenhagen V' },
  { postalCode: '1505', city: 'Copenhagen V' },
  { postalCode: '1506', city: 'Copenhagen V' },
  { postalCode: '1507', city: 'Copenhagen V' },
  { postalCode: '1508', city: 'Copenhagen V' },
  { postalCode: '1509', city: 'Copenhagen V' },
  { postalCode: '1510', city: 'Copenhagen V' },
  { postalCode: '1511', city: 'Copenhagen V' },
  { postalCode: '1512', city: 'Copenhagen V' },
  { postalCode: '1513', city: 'Copenhagen V' },
  { postalCode: '1514', city: 'Copenhagen V' },
  { postalCode: '1515', city: 'Copenhagen V' },
  { postalCode: '1516', city: 'Copenhagen V' },
  { postalCode: '1517', city: 'Copenhagen V' },
  { postalCode: '1518', city: 'Copenhagen V' },
  { postalCode: '1519', city: 'Copenhagen V' },
  { postalCode: '1520', city: 'Copenhagen V' },
  { postalCode: '1521', city: 'Copenhagen V' },
  { postalCode: '1522', city: 'Copenhagen V' },
  { postalCode: '1523', city: 'Copenhagen V' },
  { postalCode: '1524', city: 'Copenhagen V' },
  { postalCode: '1525', city: 'Copenhagen V' },
  { postalCode: '1526', city: 'Copenhagen V' },
  { postalCode: '1527', city: 'Copenhagen V' },
  { postalCode: '1528', city: 'Copenhagen V' },
  { postalCode: '1529', city: 'Copenhagen V' },
  { postalCode: '1530', city: 'Copenhagen V' },
  { postalCode: '1531', city: 'Copenhagen V' },
  { postalCode: '1532', city: 'Copenhagen V' },
  { postalCode: '1533', city: 'Copenhagen V' },
  { postalCode: '1534', city: 'Copenhagen V' },
  { postalCode: '1535', city: 'Copenhagen V' },
  { postalCode: '1536', city: 'Copenhagen V' },
  { postalCode: '1537', city: 'Copenhagen V' },
  { postalCode: '1538', city: 'Copenhagen V' },
  { postalCode: '1539', city: 'Copenhagen V' },
  { postalCode: '1540', city: 'Copenhagen V' },
  { postalCode: '1541', city: 'Copenhagen V' },
  { postalCode: '1542', city: 'Copenhagen V' },
  { postalCode: '1543', city: 'Copenhagen V' },
  { postalCode: '1544', city: 'Copenhagen V' },
  { postalCode: '1545', city: 'Copenhagen V' },
  { postalCode: '1546', city: 'Copenhagen V' },
  { postalCode: '1547', city: 'Copenhagen V' },
  { postalCode: '1548', city: 'Copenhagen V' },
  { postalCode: '1549', city: 'Copenhagen V' },
  { postalCode: '1550', city: 'Copenhagen V' },
  { postalCode: '1551', city: 'Copenhagen V' },
  { postalCode: '1552', city: 'Copenhagen V' },
  { postalCode: '1553', city: 'Copenhagen V' },
  { postalCode: '1554', city: 'Copenhagen V' },
  { postalCode: '1555', city: 'Copenhagen V' },
  { postalCode: '1556', city: 'Copenhagen V' },
  { postalCode: '1557', city: 'Copenhagen V' },
  { postalCode: '1558', city: 'Copenhagen V' },
  { postalCode: '1559', city: 'Copenhagen V' },
  { postalCode: '1560', city: 'Copenhagen V' },
  { postalCode: '1561', city: 'Copenhagen V' },
  { postalCode: '1562', city: 'Copenhagen V' },
  { postalCode: '1563', city: 'Copenhagen V' },
  { postalCode: '1564', city: 'Copenhagen V' },
  { postalCode: '1565', city: 'Copenhagen V' },
  { postalCode: '1566', city: 'Copenhagen V' },
  { postalCode: '1567', city: 'Copenhagen V' },
  { postalCode: '1568', city: 'Copenhagen V' },
  { postalCode: '1569', city: 'Copenhagen V' },
  { postalCode: '1570', city: 'Copenhagen V' },
  { postalCode: '1571', city: 'Copenhagen V' },
  { postalCode: '1572', city: 'Copenhagen V' },
  { postalCode: '1573', city: 'Copenhagen V' },
  { postalCode: '1574', city: 'Copenhagen V' },
  { postalCode: '1575', city: 'Copenhagen V' },
  { postalCode: '1576', city: 'Copenhagen V' },
  { postalCode: '1577', city: 'Copenhagen V' },
  { postalCode: '1578', city: 'Copenhagen V' },
  { postalCode: '1579', city: 'Copenhagen V' },
  { postalCode: '1580', city: 'Copenhagen V' },
  { postalCode: '1581', city: 'Copenhagen V' },
  { postalCode: '1582', city: 'Copenhagen V' },
  { postalCode: '1583', city: 'Copenhagen V' },
  { postalCode: '1584', city: 'Copenhagen V' },
  { postalCode: '1585', city: 'Copenhagen V' },
  { postalCode: '1586', city: 'Copenhagen V' },
  { postalCode: '1587', city: 'Copenhagen V' },
  { postalCode: '1588', city: 'Copenhagen V' },
  { postalCode: '1589', city: 'Copenhagen V' },
  { postalCode: '1590', city: 'Copenhagen V' },
  { postalCode: '1591', city: 'Copenhagen V' },
  { postalCode: '1592', city: 'Copenhagen V' },
  { postalCode: '1593', city: 'Copenhagen V' },
  { postalCode: '1594', city: 'Copenhagen V' },
  { postalCode: '1595', city: 'Copenhagen V' },
  { postalCode: '1596', city: 'Copenhagen V' },
  { postalCode: '1597', city: 'Copenhagen V' },
  { postalCode: '1598', city: 'Copenhagen V' },
  { postalCode: '1599', city: 'Copenhagen V' },
  { postalCode: '1600', city: 'Copenhagen V' },
  { postalCode: '1601', city: 'Copenhagen V' },
  { postalCode: '1602', city: 'Copenhagen V' },
  { postalCode: '1603', city: 'Copenhagen V' },
  { postalCode: '1604', city: 'Copenhagen V' },
  { postalCode: '1605', city: 'Copenhagen V' },
  { postalCode: '1606', city: 'Copenhagen V' },
  { postalCode: '1607', city: 'Copenhagen V' },
  { postalCode: '1608', city: 'Copenhagen V' },
  { postalCode: '1609', city: 'Copenhagen V' },
  { postalCode: '1610', city: 'Copenhagen V' },
  { postalCode: '1611', city: 'Copenhagen V' },
  { postalCode: '1612', city: 'Copenhagen V' },
  { postalCode: '1613', city: 'Copenhagen V' },
  { postalCode: '1614', city: 'Copenhagen V' },
  { postalCode: '1615', city: 'Copenhagen V' },
  { postalCode: '1616', city: 'Copenhagen V' },
  { postalCode: '1617', city: 'Copenhagen V' },
  { postalCode: '1618', city: 'Copenhagen V' },
  { postalCode: '1619', city: 'Copenhagen V' },
  { postalCode: '1620', city: 'Copenhagen V' },
  { postalCode: '1621', city: 'Copenhagen V' },
  { postalCode: '1622', city: 'Copenhagen V' },
  { postalCode: '1623', city: 'Copenhagen V' },
  { postalCode: '1624', city: 'Copenhagen V' },
  { postalCode: '1625', city: 'Copenhagen V' },
  { postalCode: '1626', city: 'Copenhagen V' },
  { postalCode: '1627', city: 'Copenhagen V' },
  { postalCode: '1628', city: 'Copenhagen V' },
  { postalCode: '1629', city: 'Copenhagen V' },
  { postalCode: '1630', city: 'Copenhagen V' },
  { postalCode: '1631', city: 'Copenhagen V' },
  { postalCode: '1632', city: 'Copenhagen V' },
  { postalCode: '1633', city: 'Copenhagen V' },
  { postalCode: '1634', city: 'Copenhagen V' },
  { postalCode: '1635', city: 'Copenhagen V' },
  { postalCode: '1636', city: 'Copenhagen V' },
  { postalCode: '1637', city: 'Copenhagen V' },
  { postalCode: '1638', city: 'Copenhagen V' },
  { postalCode: '1639', city: 'Copenhagen V' },
  { postalCode: '1640', city: 'Copenhagen V' },
  { postalCode: '1641', city: 'Copenhagen V' },
  { postalCode: '1642', city: 'Copenhagen V' },
  { postalCode: '1643', city: 'Copenhagen V' },
  { postalCode: '1644', city: 'Copenhagen V' },
  { postalCode: '1645', city: 'Copenhagen V' },
  { postalCode: '1646', city: 'Copenhagen V' },
  { postalCode: '1647', city: 'Copenhagen V' },
  { postalCode: '1648', city: 'Copenhagen V' },
  { postalCode: '1649', city: 'Copenhagen V' },
  { postalCode: '1650', city: 'Copenhagen V' },
  { postalCode: '1651', city: 'Copenhagen V' },
  { postalCode: '1652', city: 'Copenhagen V' },
  { postalCode: '1653', city: 'Copenhagen V' },
  { postalCode: '1654', city: 'Copenhagen V' },
  { postalCode: '1655', city: 'Copenhagen V' },
  { postalCode: '1656', city: 'Copenhagen V' },
  { postalCode: '1657', city: 'Copenhagen V' },
  { postalCode: '1658', city: 'Copenhagen V' },
  { postalCode: '1659', city: 'Copenhagen V' },
  { postalCode: '1660', city: 'Copenhagen V' },
  { postalCode: '1661', city: 'Copenhagen V' },
  { postalCode: '1662', city: 'Copenhagen V' },
  { postalCode: '1663', city: 'Copenhagen V' },
  { postalCode: '1664', city: 'Copenhagen V' },
  { postalCode: '1665', city: 'Copenhagen V' },
  { postalCode: '1666', city: 'Copenhagen V' },
  { postalCode: '1667', city: 'Copenhagen V' },
  { postalCode: '1668', city: 'Copenhagen V' },
  { postalCode: '1669', city: 'Copenhagen V' },
  { postalCode: '1670', city: 'Copenhagen V' },
  { postalCode: '1671', city: 'Copenhagen V' },
  { postalCode: '1672', city: 'Copenhagen V' },
  { postalCode: '1673', city: 'Copenhagen V' },
  { postalCode: '1674', city: 'Copenhagen V' },
  { postalCode: '1675', city: 'Copenhagen V' },
  { postalCode: '1676', city: 'Copenhagen V' },
  { postalCode: '1677', city: 'Copenhagen V' },
  { postalCode: '1678', city: 'Copenhagen V' },
  { postalCode: '1679', city: 'Copenhagen V' },
  { postalCode: '1680', city: 'Copenhagen V' },
  { postalCode: '1681', city: 'Copenhagen V' },
  { postalCode: '1682', city: 'Copenhagen V' },
  { postalCode: '1683', city: 'Copenhagen V' },
  { postalCode: '1684', city: 'Copenhagen V' },
  { postalCode: '1685', city: 'Copenhagen V' },
  { postalCode: '1686', city: 'Copenhagen V' },
  { postalCode: '1687', city: 'Copenhagen V' },
  { postalCode: '1688', city: 'Copenhagen V' },
  { postalCode: '1689', city: 'Copenhagen V' },
  { postalCode: '1690', city: 'Copenhagen V' },
  { postalCode: '1691', city: 'Copenhagen V' },
  { postalCode: '1692', city: 'Copenhagen V' },
  { postalCode: '1693', city: 'Copenhagen V' },
  { postalCode: '1694', city: 'Copenhagen V' },
  { postalCode: '1695', city: 'Copenhagen V' },
  { postalCode: '1696', city: 'Copenhagen V' },
  { postalCode: '1697', city: 'Copenhagen V' },
  { postalCode: '1698', city: 'Copenhagen V' },
  { postalCode: '1699', city: 'Copenhagen V' },
  { postalCode: '1700', city: 'Copenhagen V' },
  { postalCode: '1701', city: 'Copenhagen V' },
  { postalCode: '1702', city: 'Copenhagen V' },
  { postalCode: '1703', city: 'Copenhagen V' },
  { postalCode: '1704', city: 'Copenhagen V' },
  { postalCode: '1705', city: 'Copenhagen V' },
  { postalCode: '1706', city: 'Copenhagen V' },
  { postalCode: '1707', city: 'Copenhagen V' },
  { postalCode: '1708', city: 'Copenhagen V' },
  { postalCode: '1709', city: 'Copenhagen V' },
  { postalCode: '1710', city: 'Copenhagen V' },
  { postalCode: '1711', city: 'Copenhagen V' },
  { postalCode: '1712', city: 'Copenhagen V' },
  { postalCode: '1713', city: 'Copenhagen V' },
  { postalCode: '1714', city: 'Copenhagen V' },
  { postalCode: '1715', city: 'Copenhagen V' },
  { postalCode: '1716', city: 'Copenhagen V' },
  { postalCode: '1717', city: 'Copenhagen V' },
  { postalCode: '1718', city: 'Copenhagen V' },
  { postalCode: '1719', city: 'Copenhagen V' },
  { postalCode: '1720', city: 'Copenhagen V' },
  { postalCode: '1721', city: 'Copenhagen V' },
  { postalCode: '1722', city: 'Copenhagen V' },
  { postalCode: '1723', city: 'Copenhagen V' },
  { postalCode: '1724', city: 'Copenhagen V' },
  { postalCode: '1725', city: 'Copenhagen V' },
  { postalCode: '1726', city: 'Copenhagen V' },
  { postalCode: '1727', city: 'Copenhagen V' },
  { postalCode: '1728', city: 'Copenhagen V' },
  { postalCode: '1729', city: 'Copenhagen V' },
  { postalCode: '1730', city: 'Copenhagen V' },
  { postalCode: '1731', city: 'Copenhagen V' },
  { postalCode: '1732', city: 'Copenhagen V' },
  { postalCode: '1733', city: 'Copenhagen V' },
  { postalCode: '1734', city: 'Copenhagen V' },
  { postalCode: '1735', city: 'Copenhagen V' },
  { postalCode: '1736', city: 'Copenhagen V' },
  { postalCode: '1737', city: 'Copenhagen V' },
  { postalCode: '1738', city: 'Copenhagen V' },
  { postalCode: '1739', city: 'Copenhagen V' },
  { postalCode: '1740', city: 'Copenhagen V' },
  { postalCode: '1741', city: 'Copenhagen V' },
  { postalCode: '1742', city: 'Copenhagen V' },
  { postalCode: '1743', city: 'Copenhagen V' },
  { postalCode: '1744', city: 'Copenhagen V' },
  { postalCode: '1745', city: 'Copenhagen V' },
  { postalCode: '1746', city: 'Copenhagen V' },
  { postalCode: '1747', city: 'Copenhagen V' },
  { postalCode: '1748', city: 'Copenhagen V' },
  { postalCode: '1749', city: 'Copenhagen V' },
  { postalCode: '1750', city: 'Copenhagen V' },
  { postalCode: '1751', city: 'Copenhagen V' },
  { postalCode: '1752', city: 'Copenhagen V' },
  { postalCode: '1753', city: 'Copenhagen V' },
  { postalCode: '1754', city: 'Copenhagen V' },
  { postalCode: '1755', city: 'Copenhagen V' },
  { postalCode: '1756', city: 'Copenhagen V' },
  { postalCode: '1757', city: 'Copenhagen V' },
  { postalCode: '1758', city: 'Copenhagen V' },
  { postalCode: '1759', city: 'Copenhagen V' },
  { postalCode: '1760', city: 'Copenhagen V' },
  { postalCode: '1761', city: 'Copenhagen V' },
  { postalCode: '1762', city: 'Copenhagen V' },
  { postalCode: '1763', city: 'Copenhagen V' },
  { postalCode: '1764', city: 'Copenhagen V' },
  { postalCode: '1765', city: 'Copenhagen V' },
  { postalCode: '1766', city: 'Copenhagen V' },
  { postalCode: '1767', city: 'Copenhagen V' },
  { postalCode: '1768', city: 'Copenhagen V' },
  { postalCode: '1769', city: 'Copenhagen V' },
  { postalCode: '1770', city: 'Copenhagen V' },
  { postalCode: '1771', city: 'Copenhagen V' },
  { postalCode: '1772', city: 'Copenhagen V' },
  { postalCode: '1773', city: 'Copenhagen V' },
  { postalCode: '1774', city: 'Copenhagen V' },
  { postalCode: '1775', city: 'Copenhagen V' },
  { postalCode: '1776', city: 'Copenhagen V' },
  { postalCode: '1777', city: 'Copenhagen V' },
  { postalCode: '1778', city: 'Copenhagen V' },
  { postalCode: '1779', city: 'Copenhagen V' },
  { postalCode: '1780', city: 'Copenhagen V' },
  { postalCode: '1781', city: 'Copenhagen V' },
  { postalCode: '1782', city: 'Copenhagen V' },
  { postalCode: '1783', city: 'Copenhagen V' },
  { postalCode: '1784', city: 'Copenhagen V' },
  { postalCode: '1785', city: 'Copenhagen V' },
  { postalCode: '1786', city: 'Copenhagen V' },
  { postalCode: '1787', city: 'Copenhagen V' },
  { postalCode: '1788', city: 'Copenhagen V' },
  { postalCode: '1789', city: 'Copenhagen V' },
  { postalCode: '1790', city: 'Copenhagen V' },
  { postalCode: '1791', city: 'Copenhagen V' },
  { postalCode: '1792', city: 'Copenhagen V' },
  { postalCode: '1793', city: 'Copenhagen V' },
  { postalCode: '1794', city: 'Copenhagen V' },
  { postalCode: '1795', city: 'Copenhagen V' },
  { postalCode: '1796', city: 'Copenhagen V' },
  { postalCode: '1797', city: 'Copenhagen V' },
  { postalCode: '1798', city: 'Copenhagen V' },
  { postalCode: '1799', city: 'Copenhagen V' },
  { postalCode: '1800', city: 'Frederiksberg C' },
  { postalCode: '1801', city: 'Frederiksberg C' },
  { postalCode: '1802', city: 'Frederiksberg C' },
  { postalCode: '1803', city: 'Frederiksberg C' },
  { postalCode: '1804', city: 'Frederiksberg C' },
  { postalCode: '1805', city: 'Frederiksberg C' },
  { postalCode: '1806', city: 'Frederiksberg C' },
  { postalCode: '1807', city: 'Frederiksberg C' },
  { postalCode: '1808', city: 'Frederiksberg C' },
  { postalCode: '1809', city: 'Frederiksberg C' },
  { postalCode: '1810', city: 'Frederiksberg C' },
  { postalCode: '1811', city: 'Frederiksberg C' },
  { postalCode: '1812', city: 'Frederiksberg C' },
  { postalCode: '1813', city: 'Frederiksberg C' },
  { postalCode: '1814', city: 'Frederiksberg C' },
  { postalCode: '1815', city: 'Frederiksberg C' },
  { postalCode: '1816', city: 'Frederiksberg C' },
  { postalCode: '1817', city: 'Frederiksberg C' },
  { postalCode: '1818', city: 'Frederiksberg C' },
  { postalCode: '1819', city: 'Frederiksberg C' },
  { postalCode: '1820', city: 'Frederiksberg C' },
  { postalCode: '1821', city: 'Frederiksberg C' },
  { postalCode: '1822', city: 'Frederiksberg C' },
  { postalCode: '1823', city: 'Frederiksberg C' },
  { postalCode: '1824', city: 'Frederiksberg C' },
  { postalCode: '1825', city: 'Frederiksberg C' },
  { postalCode: '1826', city: 'Frederiksberg C' },
  { postalCode: '1827', city: 'Frederiksberg C' },
  { postalCode: '1828', city: 'Frederiksberg C' },
  { postalCode: '1829', city: 'Frederiksberg C' },
  { postalCode: '1830', city: 'Frederiksberg C' },
  { postalCode: '1831', city: 'Frederiksberg C' },
  { postalCode: '1832', city: 'Frederiksberg C' },
  { postalCode: '1833', city: 'Frederiksberg C' },
  { postalCode: '1834', city: 'Frederiksberg C' },
  { postalCode: '1835', city: 'Frederiksberg C' },
  { postalCode: '1836', city: 'Frederiksberg C' },
  { postalCode: '1837', city: 'Frederiksberg C' },
  { postalCode: '1838', city: 'Frederiksberg C' },
  { postalCode: '1839', city: 'Frederiksberg C' },
  { postalCode: '1840', city: 'Frederiksberg C' },
  { postalCode: '1841', city: 'Frederiksberg C' },
  { postalCode: '1842', city: 'Frederiksberg C' },
  { postalCode: '1843', city: 'Frederiksberg C' },
  { postalCode: '1844', city: 'Frederiksberg C' },
  { postalCode: '1845', city: 'Frederiksberg C' },
  { postalCode: '1846', city: 'Frederiksberg C' },
  { postalCode: '1847', city: 'Frederiksberg C' },
  { postalCode: '1848', city: 'Frederiksberg C' },
  { postalCode: '1849', city: 'Frederiksberg C' },
  { postalCode: '1850', city: 'Frederiksberg C' },
  { postalCode: '1851', city: 'Frederiksberg C' },
  { postalCode: '1852', city: 'Frederiksberg C' },
  { postalCode: '1853', city: 'Frederiksberg C' },
  { postalCode: '1854', city: 'Frederiksberg C' },
  { postalCode: '1855', city: 'Frederiksberg C' },
  { postalCode: '1856', city: 'Frederiksberg C' },
  { postalCode: '1857', city: 'Frederiksberg C' },
  { postalCode: '1858', city: 'Frederiksberg C' },
  { postalCode: '1859', city: 'Frederiksberg C' },
  { postalCode: '1860', city: 'Frederiksberg C' },
  { postalCode: '1861', city: 'Frederiksberg C' },
  { postalCode: '1862', city: 'Frederiksberg C' },
  { postalCode: '1863', city: 'Frederiksberg C' },
  { postalCode: '1864', city: 'Frederiksberg C' },
  { postalCode: '1865', city: 'Frederiksberg C' },
  { postalCode: '1866', city: 'Frederiksberg C' },
  { postalCode: '1867', city: 'Frederiksberg C' },
  { postalCode: '1868', city: 'Frederiksberg C' },
  { postalCode: '1869', city: 'Frederiksberg C' },
  { postalCode: '1870', city: 'Frederiksberg C' },
  { postalCode: '1871', city: 'Frederiksberg C' },
  { postalCode: '1872', city: 'Frederiksberg C' },
  { postalCode: '1873', city: 'Frederiksberg C' },
  { postalCode: '1874', city: 'Frederiksberg C' },
  { postalCode: '1875', city: 'Frederiksberg C' },
  { postalCode: '1876', city: 'Frederiksberg C' },
  { postalCode: '1877', city: 'Frederiksberg C' },
  { postalCode: '1878', city: 'Frederiksberg C' },
  { postalCode: '1879', city: 'Frederiksberg C' },
  { postalCode: '1880', city: 'Frederiksberg C' },
  { postalCode: '1881', city: 'Frederiksberg C' },
  { postalCode: '1882', city: 'Frederiksberg C' },
  { postalCode: '1883', city: 'Frederiksberg C' },
  { postalCode: '1884', city: 'Frederiksberg C' },
  { postalCode: '1885', city: 'Frederiksberg C' },
  { postalCode: '1886', city: 'Frederiksberg C' },
  { postalCode: '1887', city: 'Frederiksberg C' },
  { postalCode: '1888', city: 'Frederiksberg C' },
  { postalCode: '1889', city: 'Frederiksberg C' },
  { postalCode: '1890', city: 'Frederiksberg C' },
  { postalCode: '1891', city: 'Frederiksberg C' },
  { postalCode: '1892', city: 'Frederiksberg C' },
  { postalCode: '1893', city: 'Frederiksberg C' },
  { postalCode: '1894', city: 'Frederiksberg C' },
  { postalCode: '1895', city: 'Frederiksberg C' },
  { postalCode: '1896', city: 'Frederiksberg C' },
  { postalCode: '1897', city: 'Frederiksberg C' },
  { postalCode: '1898', city: 'Frederiksberg C' },
  { postalCode: '1899', city: 'Frederiksberg C' },
  { postalCode: '1900', city: 'Frederiksberg C' },
  { postalCode: '1901', city: 'Frederiksberg C' },
  { postalCode: '1902', city: 'Frederiksberg C' },
  { postalCode: '1903', city: 'Frederiksberg C' },
  { postalCode: '1904', city: 'Frederiksberg C' },
  { postalCode: '1905', city: 'Frederiksberg C' },
  { postalCode: '1906', city: 'Frederiksberg C' },
  { postalCode: '1907', city: 'Frederiksberg C' },
  { postalCode: '1908', city: 'Frederiksberg C' },
  { postalCode: '1909', city: 'Frederiksberg C' },
  { postalCode: '1910', city: 'Frederiksberg C' },
  { postalCode: '1911', city: 'Frederiksberg C' },
  { postalCode: '1912', city: 'Frederiksberg C' },
  { postalCode: '1913', city: 'Frederiksberg C' },
  { postalCode: '1914', city: 'Frederiksberg C' },
  { postalCode: '1915', city: 'Frederiksberg C' },
  { postalCode: '1916', city: 'Frederiksberg C' },
  { postalCode: '1917', city: 'Frederiksberg C' },
  { postalCode: '1918', city: 'Frederiksberg C' },
  { postalCode: '1919', city: 'Frederiksberg C' },
  { postalCode: '1920', city: 'Frederiksberg C' },
  { postalCode: '1921', city: 'Frederiksberg C' },
  { postalCode: '1922', city: 'Frederiksberg C' },
  { postalCode: '1923', city: 'Frederiksberg C' },
  { postalCode: '1924', city: 'Frederiksberg C' },
  { postalCode: '1925', city: 'Frederiksberg C' },
  { postalCode: '1926', city: 'Frederiksberg C' },
  { postalCode: '1927', city: 'Frederiksberg C' },
  { postalCode: '1928', city: 'Frederiksberg C' },
  { postalCode: '1929', city: 'Frederiksberg C' },
  { postalCode: '1930', city: 'Frederiksberg C' },
  { postalCode: '1931', city: 'Frederiksberg C' },
  { postalCode: '1932', city: 'Frederiksberg C' },
  { postalCode: '1933', city: 'Frederiksberg C' },
  { postalCode: '1934', city: 'Frederiksberg C' },
  { postalCode: '1935', city: 'Frederiksberg C' },
  { postalCode: '1936', city: 'Frederiksberg C' },
  { postalCode: '1937', city: 'Frederiksberg C' },
  { postalCode: '1938', city: 'Frederiksberg C' },
  { postalCode: '1939', city: 'Frederiksberg C' },
  { postalCode: '1940', city: 'Frederiksberg C' },
  { postalCode: '1941', city: 'Frederiksberg C' },
  { postalCode: '1942', city: 'Frederiksberg C' },
  { postalCode: '1943', city: 'Frederiksberg C' },
  { postalCode: '1944', city: 'Frederiksberg C' },
  { postalCode: '1945', city: 'Frederiksberg C' },
  { postalCode: '1946', city: 'Frederiksberg C' },
  { postalCode: '1947', city: 'Frederiksberg C' },
  { postalCode: '1948', city: 'Frederiksberg C' },
  { postalCode: '1949', city: 'Frederiksberg C' },
  { postalCode: '1950', city: 'Frederiksberg C' },
  { postalCode: '1951', city: 'Frederiksberg C' },
  { postalCode: '1952', city: 'Frederiksberg C' },
  { postalCode: '1953', city: 'Frederiksberg C' },
  { postalCode: '1954', city: 'Frederiksberg C' },
  { postalCode: '1955', city: 'Frederiksberg C' },
  { postalCode: '1956', city: 'Frederiksberg C' },
  { postalCode: '1957', city: 'Frederiksberg C' },
  { postalCode: '1958', city: 'Frederiksberg C' },
  { postalCode: '1959', city: 'Frederiksberg C' },
  { postalCode: '1960', city: 'Frederiksberg C' },
  { postalCode: '1961', city: 'Frederiksberg C' },
  { postalCode: '1962', city: 'Frederiksberg C' },
  { postalCode: '1963', city: 'Frederiksberg C' },
  { postalCode: '1964', city: 'Frederiksberg C' },
  { postalCode: '1965', city: 'Frederiksberg C' },
  { postalCode: '1966', city: 'Frederiksberg C' },
  { postalCode: '1967', city: 'Frederiksberg C' },
  { postalCode: '1968', city: 'Frederiksberg C' },
  { postalCode: '1969', city: 'Frederiksberg C' },
  { postalCode: '1970', city: 'Frederiksberg C' },
  { postalCode: '1971', city: 'Frederiksberg C' },
  { postalCode: '1972', city: 'Frederiksberg C' },
  { postalCode: '1973', city: 'Frederiksberg C' },
  { postalCode: '1974', city: 'Frederiksberg C' },
  { postalCode: '1975', city: 'Frederiksberg C' },
  { postalCode: '1976', city: 'Frederiksberg C' },
  { postalCode: '1977', city: 'Frederiksberg C' },
  { postalCode: '1978', city: 'Frederiksberg C' },
  { postalCode: '1979', city: 'Frederiksberg C' },
  { postalCode: '1980', city: 'Frederiksberg C' },
  { postalCode: '1981', city: 'Frederiksberg C' },
  { postalCode: '1982', city: 'Frederiksberg C' },
  { postalCode: '1983', city: 'Frederiksberg C' },
  { postalCode: '1984', city: 'Frederiksberg C' },
  { postalCode: '1985', city: 'Frederiksberg C' },
  { postalCode: '1986', city: 'Frederiksberg C' },
  { postalCode: '1987', city: 'Frederiksberg C' },
  { postalCode: '1988', city: 'Frederiksberg C' },
  { postalCode: '1989', city: 'Frederiksberg C' },
  { postalCode: '1990', city: 'Frederiksberg C' },
  { postalCode: '1991', city: 'Frederiksberg C' },
  { postalCode: '1992', city: 'Frederiksberg C' },
  { postalCode: '1993', city: 'Frederiksberg C' },
  { postalCode: '1994', city: 'Frederiksberg C' },
  { postalCode: '1995', city: 'Frederiksberg C' },
  { postalCode: '1996', city: 'Frederiksberg C' },
  { postalCode: '1997', city: 'Frederiksberg C' },
  { postalCode: '1998', city: 'Frederiksberg C' },
  { postalCode: '1999', city: 'Frederiksberg C' },
  {
    postalCode: '2000 ',
    city: ' Frederiksberg'
  },
  {
    postalCode: '2100 ',
    city: ' Copenhagen Ø'
  },
  {
    postalCode: '2150 ',
    city: ' Nordhavn'
  },
  {
    postalCode: '2200 ',
    city: ' Copenhagen N'
  },
  {
    postalCode: '2300 ',
    city: ' Copenhagen S'
  },
  {
    postalCode: '2400 ',
    city: ' Copenhagen NV'
  },
  {
    postalCode: '2412 ',
    city: ' Santa Claus, Greenland[1]'
  },
  {
    postalCode: '2450 ',
    city: ' Copenhagen SV'
  },
  {
    postalCode: '2500 ',
    city: ' Valby'
  },
  {
    postalCode: '2600 ',
    city: ' Glostrup'
  },
  {
    postalCode: '2605 ',
    city: ' Brøndby'
  },
  {
    postalCode: '2610 ',
    city: ' Rødovre'
  },
  {
    postalCode: '2620 ',
    city: ' Albertslund'
  },
  {
    postalCode: '2625 ',
    city: ' Vallensbæk'
  },
  {
    postalCode: '2630 ',
    city: ' Taastrup'
  },
  {
    postalCode: '2635 ',
    city: ' Ishøj'
  },
  {
    postalCode: '2640 ',
    city: ' Hedehusene'
  },
  {
    postalCode: '2650 ',
    city: ' Hvidovre'
  },
  {
    postalCode: '2660 ',
    city: ' Brøndby Strand'
  },
  {
    postalCode: '2665 ',
    city: ' Vallensbæk Strand'
  },
  {
    postalCode: '2670 ',
    city: ' Greve'
  },
  {
    postalCode: '2680 ',
    city: ' Solrød Strand'
  },
  {
    postalCode: '2690 ',
    city: ' Karlslunde'
  },
  {
    postalCode: '2700 ',
    city: ' Brønshøj'
  },
  {
    postalCode: '2720 ',
    city: ' Vanløse'
  },
  {
    postalCode: '2730 ',
    city: ' Herlev'
  },
  {
    postalCode: '2740 ',
    city: ' Skovlunde'
  },
  {
    postalCode: '2750 ',
    city: ' Ballerup'
  },
  {
    postalCode: '2760 ',
    city: ' Måløv'
  },
  {
    postalCode: '2765 ',
    city: ' Smørum'
  },
  {
    postalCode: '2770 ',
    city: ' Kastrup'
  },
  {
    postalCode: '2791 ',
    city: ' Dragør'
  },
  {
    postalCode: '2800 ',
    city: ' Kongens Lyngby'
  },
  {
    postalCode: '2820 ',
    city: ' Gentofte'
  },
  {
    postalCode: '2830 ',
    city: ' Virum'
  },
  {
    postalCode: '2840 ',
    city: ' Holte'
  },
  {
    postalCode: '2850 ',
    city: ' Nærum'
  },
  {
    postalCode: '2860 ',
    city: ' Søborg'
  },
  {
    postalCode: '2870 ',
    city: ' Dyssegård'
  },
  {
    postalCode: '2880 ',
    city: ' Bagsværd'
  },
  {
    postalCode: '2900 ',
    city: ' Hellerup'
  },
  {
    postalCode: '2920 ',
    city: ' Charlottenlund'
  },
  {
    postalCode: '2930 ',
    city: ' Klampenborg'
  },
  {
    postalCode: '2942 ',
    city: ' Skodsborg'
  },
  {
    postalCode: '2950 ',
    city: ' Vedbæk'
  },
  {
    postalCode: '2960 ',
    city: ' Rungsted Kyst'
  },
  {
    postalCode: '2970 ',
    city: ' Hørsholm'
  },
  {
    postalCode: '2980 ',
    city: ' Kokkedal'
  },
  {
    postalCode: '2990 ',
    city: ' Nivå'
  },
  {
    postalCode: '3000 ',
    city: ' Helsingør'
  },
  {
    postalCode: '3050 ',
    city: ' Humlebæk'
  },
  {
    postalCode: '3060 ',
    city: ' Espergærde'
  },
  {
    postalCode: '3070 ',
    city: ' Snekkersten'
  },
  {
    postalCode: '3080 ',
    city: ' Tikøb'
  },
  {
    postalCode: '3100 ',
    city: ' Hornbæk'
  },
  {
    postalCode: '3120 ',
    city: ' Dronningmølle'
  },
  {
    postalCode: '3140 ',
    city: ' Ålsgårde'
  },
  {
    postalCode: '3150 ',
    city: ' Hellebæk'
  },
  {
    postalCode: '3200 ',
    city: ' Helsinge'
  },
  {
    postalCode: '3210 ',
    city: ' Vejby'
  },
  {
    postalCode: '3220 ',
    city: ' Tisvildeleje'
  },
  {
    postalCode: '3230 ',
    city: ' Græsted'
  },
  {
    postalCode: '3250 ',
    city: ' Gilleleje'
  },
  {
    postalCode: '3300 ',
    city: ' Frederiksværk'
  },
  {
    postalCode: '3310 ',
    city: ' Ølsted'
  },
  {
    postalCode: '3320 ',
    city: ' Skævinge'
  },
  {
    postalCode: '3330 ',
    city: ' Gørløse'
  },
  {
    postalCode: '3360 ',
    city: ' Liseleje'
  },
  {
    postalCode: '3370 ',
    city: ' Melby'
  },
  {
    postalCode: '3390 ',
    city: ' Hundested'
  },
  {
    postalCode: '3400 ',
    city: ' Hillerød'
  },
  {
    postalCode: '3450 ',
    city: ' Lillerød'
  },
  {
    postalCode: '3460 ',
    city: ' Birkerød'
  },
  {
    postalCode: '3480 ',
    city: ' Fredensborg'
  },
  {
    postalCode: '3490 ',
    city: ' Kvistgård'
  },
  {
    postalCode: '3500 ',
    city: ' Værløse'
  },
  {
    postalCode: '3520 ',
    city: ' Farum'
  },
  {
    postalCode: '3540 ',
    city: ' Lynge'
  },
  {
    postalCode: '3550 ',
    city: ' Slangerup'
  },
  {
    postalCode: '3600 ',
    city: ' Frederikssund'
  },
  {
    postalCode: '3630 ',
    city: ' Jægerspris'
  },
  {
    postalCode: '3650 ',
    city: ' Ølstykke'
  },
  {
    postalCode: '3660 ',
    city: ' Stenløse'
  },
  {
    postalCode: '3670 ',
    city: ' Veksø Sjælland'
  },
  {
    postalCode: '4000 ',
    city: ' Roskilde'
  },
  {
    postalCode: '4030 ',
    city: ' Tune'
  },
  {
    postalCode: '4040 ',
    city: ' Jyllinge'
  },
  {
    postalCode: '4050 ',
    city: ' Skibby'
  },
  {
    postalCode: '4060 ',
    city: ' Kirke Såby'
  },
  {
    postalCode: '4070 ',
    city: ' Kirke Hyllinge'
  },
  {
    postalCode: '4100 ',
    city: ' Ringsted'
  },
  {
    postalCode: '4130 ',
    city: ' Viby Sjælland'
  },
  {
    postalCode: '4140 ',
    city: ' Borup'
  },
  {
    postalCode: '4160 ',
    city: ' Herlufmagle'
  },
  {
    postalCode: '4171 ',
    city: ' Glumsø'
  },
  {
    postalCode: '4173 ',
    city: ' Fjenneslev'
  },
  {
    postalCode: '4174 ',
    city: ' Jystrup Midtsjælland'
  },
  {
    postalCode: '4180 ',
    city: ' Sorø'
  },
  {
    postalCode: '4190 ',
    city: ' Munke Bjergby'
  },
  {
    postalCode: '4200 ',
    city: ' Slagelse'
  },
  {
    postalCode: '4220 ',
    city: ' Korsør'
  },
  {
    postalCode: '4230 ',
    city: ' Skælskør'
  },
  {
    postalCode: '4241 ',
    city: ' Vemmelev'
  },
  {
    postalCode: '4242 ',
    city: ' Boeslunde'
  },
  {
    postalCode: '4243 ',
    city: ' Rude'
  },
  {
    postalCode: '4250 ',
    city: ' Fuglebjerg'
  },
  {
    postalCode: '4261 ',
    city: ' Dalmose'
  },
  {
    postalCode: '4262 ',
    city: ' Sandved'
  },
  {
    postalCode: '4270 ',
    city: ' Høng'
  },
  {
    postalCode: '4281 ',
    city: ' Gørlev'
  },
  {
    postalCode: '4291 ',
    city: ' Ruds Vedby'
  },
  {
    postalCode: '4293 ',
    city: ' Dianalund'
  },
  {
    postalCode: '4295 ',
    city: ' Stenlille'
  },
  {
    postalCode: '4296 ',
    city: ' Nyrup'
  },
  {
    postalCode: '4300 ',
    city: ' Holbæk'
  },
  {
    postalCode: '4305 ',
    city: ' Orø'
  },
  {
    postalCode: '4320 ',
    city: ' Lejre'
  },
  {
    postalCode: '4330 ',
    city: ' Hvalsø'
  },
  {
    postalCode: '4340 ',
    city: ' Tølløse'
  },
  {
    postalCode: '4350 ',
    city: ' Ugerløse'
  },
  {
    postalCode: '4360 ',
    city: ' Kirke Eskilstrup'
  },
  {
    postalCode: '4370 ',
    city: ' Store Merløse'
  },
  {
    postalCode: '4390 ',
    city: ' Vipperød'
  },
  {
    postalCode: '4400 ',
    city: ' Kalundborg'
  },
  {
    postalCode: '4420 ',
    city: ' Regstrup'
  },
  {
    postalCode: '4440 ',
    city: ' Mørkøv'
  },
  {
    postalCode: '4450 ',
    city: ' Jyderup'
  },
  {
    postalCode: '4460 ',
    city: ' Snertinge'
  },
  {
    postalCode: '4470 ',
    city: ' Svebølle'
  },
  {
    postalCode: '4480 ',
    city: ' Store Fuglede'
  },
  {
    postalCode: '4490 ',
    city: ' Jerslev Sjælland'
  },
  {
    postalCode: '4500 ',
    city: ' Nykøbing Sjælland'
  },
  {
    postalCode: '4520 ',
    city: ' Svinninge'
  },
  {
    postalCode: '4532 ',
    city: ' Gislinge'
  },
  {
    postalCode: '4534 ',
    city: ' Hørve'
  },
  {
    postalCode: '4540 ',
    city: ' Fårevejle'
  },
  {
    postalCode: '4550 ',
    city: ' Asnæs'
  },
  {
    postalCode: '4560 ',
    city: ' Vig'
  },
  {
    postalCode: '4571 ',
    city: ' Grevinge'
  },
  {
    postalCode: '4572 ',
    city: ' Nørre Asmindrup'
  },
  {
    postalCode: '4573 ',
    city: ' Højby'
  },
  {
    postalCode: '4581 ',
    city: ' Rørvig'
  },
  {
    postalCode: '4583 ',
    city: ' Sjællands Odde'
  },
  {
    postalCode: '4591 ',
    city: ' Føllenslev'
  },
  {
    postalCode: '4592 ',
    city: ' Sejerø'
  },
  {
    postalCode: '4593 ',
    city: ' Eskebjerg'
  },
  {
    postalCode: '4600 ',
    city: ' Køge'
  },
  {
    postalCode: '4621 ',
    city: ' Gadstrup'
  },
  {
    postalCode: '4622 ',
    city: ' Havdrup'
  },
  {
    postalCode: '4623 ',
    city: ' Lille Skensved'
  },
  {
    postalCode: '4632 ',
    city: ' Bjæverskov'
  },
  {
    postalCode: '4640 ',
    city: ' Faxe'
  },
  {
    postalCode: '4652 ',
    city: ' Hårlev'
  },
  {
    postalCode: '4653 ',
    city: ' Karise'
  },
  {
    postalCode: '4654 ',
    city: ' Faxe Ladeplads'
  },
  {
    postalCode: '4660 ',
    city: ' Store Heddinge'
  },
  {
    postalCode: '4671 ',
    city: ' Strøby'
  },
  {
    postalCode: '4672 ',
    city: ' Klippinge'
  },
  {
    postalCode: '4673 ',
    city: ' Rødvig Stevns'
  },
  {
    postalCode: '4681 ',
    city: ' Herfølge'
  },
  {
    postalCode: '4682 ',
    city: ' Tureby'
  },
  {
    postalCode: '4683 ',
    city: ' Rønnede'
  },
  {
    postalCode: '4684 ',
    city: ' Holmegaard'
  },
  {
    postalCode: '4690 ',
    city: ' Haslev'
  },
  {
    postalCode: '4700 ',
    city: ' Næstved'
  },
  {
    postalCode: '4720 ',
    city: ' Præstø'
  },
  {
    postalCode: '4733 ',
    city: ' Tappernøje'
  },
  {
    postalCode: '4735 ',
    city: ' Mern'
  },
  {
    postalCode: '4736 ',
    city: ' Karrebæksminde'
  },
  {
    postalCode: '4750 ',
    city: ' Lundby'
  },
  {
    postalCode: '4760 ',
    city: ' Vordingborg'
  },
  {
    postalCode: '4771 ',
    city: ' Kalvehave'
  },
  {
    postalCode: '4772 ',
    city: ' Langebæk'
  },
  {
    postalCode: '4773 ',
    city: ' Stensved'
  },
  {
    postalCode: '4780 ',
    city: ' Stege'
  },
  {
    postalCode: '4791 ',
    city: ' Borre'
  },
  {
    postalCode: '4792 ',
    city: ' Askeby'
  },
  {
    postalCode: '4793 ',
    city: ' Bogø By'
  },
  {
    postalCode: '4800 ',
    city: ' Nykøbing Falster'
  },
  {
    postalCode: '4840 ',
    city: ' Nørre Alslev'
  },
  {
    postalCode: '4850 ',
    city: ' Stubbekøbing'
  },
  {
    postalCode: '4862 ',
    city: ' Guldborg'
  },
  {
    postalCode: '4863 ',
    city: ' Eskilstrup'
  },
  {
    postalCode: '4871 ',
    city: ' Horbelev'
  },
  {
    postalCode: '4872 ',
    city: ' Idestrup'
  },
  {
    postalCode: '4873 ',
    city: ' Væggerløse'
  },
  {
    postalCode: '4874 ',
    city: ' Gedser'
  },
  {
    postalCode: '4880 ',
    city: ' Nysted'
  },
  {
    postalCode: '4891 ',
    city: ' Toreby Lolland'
  },
  {
    postalCode: '4892 ',
    city: ' Kettinge'
  },
  {
    postalCode: '4894 ',
    city: ' Øster Ulslev'
  },
  {
    postalCode: '4895 ',
    city: ' Errindlev'
  },
  {
    postalCode: '4900 ',
    city: ' Nakskov'
  },
  {
    postalCode: '4912 ',
    city: ' Harpelunde'
  },
  {
    postalCode: '4913 ',
    city: ' Horslunde'
  },
  {
    postalCode: '4920 ',
    city: ' Søllested'
  },
  {
    postalCode: '4930 ',
    city: ' Maribo'
  },
  {
    postalCode: '4941 ',
    city: ' Bandholm'
  },
  {
    postalCode: '4943 ',
    city: ' Torrig Lolland'
  },
  {
    postalCode: '4944 ',
    city: ' Fejø'
  },
  {
    postalCode: '4951 ',
    city: ' Nørreballe'
  },
  {
    postalCode: '4952 ',
    city: ' Stokkemarke'
  },
  {
    postalCode: '4953 ',
    city: ' Vesterborg'
  },
  {
    postalCode: '4960 ',
    city: ' Holeby'
  },
  {
    postalCode: '4970 ',
    city: ' Rødby'
  },
  {
    postalCode: '4983 ',
    city: ' Dannemare'
  },
  {
    postalCode: '4990 ',
    city: ' Sakskøbing'
  },
  {
    postalCode: '3700 ',
    city: ' Rønne'
  },
  {
    postalCode: '3720 ',
    city: ' Aakirkeby'
  },
  {
    postalCode: '3730 ',
    city: ' Nexø'
  },
  {
    postalCode: '3740 ',
    city: ' Svaneke'
  },
  {
    postalCode: '3751 ',
    city: ' Østermarie'
  },
  {
    postalCode: '3760 ',
    city: ' Gudhjem'
  },
  {
    postalCode: '3770 ',
    city: ' Allinge'
  },
  {
    postalCode: '3782 ',
    city: ' Klemensker'
  },
  {
    postalCode: '3790 ',
    city: ' Hasle'
  },
  {
    postalCode: '5000 ',
    city: ' Odense C'
  },
  {
    postalCode: '5100 ',
    city: ' Odense C (Post',
    undefined: 'office box)'
  },
  {
    postalCode: '5200 ',
    city: ' Odense V'
  },
  {
    postalCode: '5210 ',
    city: ' Odense NV'
  },
  {
    postalCode: '5220 ',
    city: ' Odense SØ'
  },
  {
    postalCode: '5230 ',
    city: ' Odense M'
  },
  {
    postalCode: '5240 ',
    city: ' Odense NØ'
  },
  {
    postalCode: '5250 ',
    city: ' Odense SV'
  },
  {
    postalCode: '5260 ',
    city: ' Odense S'
  },
  {
    postalCode: '5270 ',
    city: ' Odense N'
  },
  {
    postalCode: '5290 ',
    city: ' Marslev'
  },
  {
    postalCode: '5300 ',
    city: ' Kerteminde'
  },
  {
    postalCode: '5320 ',
    city: ' Agedrup'
  },
  {
    postalCode: '5330 ',
    city: ' Munkebo'
  },
  {
    postalCode: '5350 ',
    city: ' Rynkeby'
  },
  {
    postalCode: '5370 ',
    city: ' Mesinge'
  },
  {
    postalCode: '5380 ',
    city: ' Dalby'
  },
  {
    postalCode: '5390 ',
    city: ' Martofte'
  },
  {
    postalCode: '5400 ',
    city: ' Bogense'
  },
  {
    postalCode: '5450 ',
    city: ' Otterup'
  },
  {
    postalCode: '5462 ',
    city: ' Morud'
  },
  {
    postalCode: '5463 ',
    city: ' Harndrup'
  },
  {
    postalCode: '5464 ',
    city: ' Brenderup Fyn'
  },
  {
    postalCode: '5466 ',
    city: ' Asperup'
  },
  {
    postalCode: '5471 ',
    city: ' Søndersø'
  },
  {
    postalCode: '5474 ',
    city: ' Veflinge'
  },
  {
    postalCode: '5485 ',
    city: ' Skamby'
  },
  {
    postalCode: '5491 ',
    city: ' Blommenslyst'
  },
  {
    postalCode: '5492 ',
    city: ' Vissenbjerg'
  },
  {
    postalCode: '5500 ',
    city: ' Middelfart'
  },
  {
    postalCode: '5540 ',
    city: ' Ullerslev'
  },
  {
    postalCode: '5550 ',
    city: ' Langeskov'
  },
  {
    postalCode: '5560 ',
    city: ' Aarup'
  },
  {
    postalCode: '5580 ',
    city: ' Nørre Aaby'
  },
  {
    postalCode: '5591 ',
    city: ' Gelsted'
  },
  {
    postalCode: '5592 ',
    city: ' Ejby'
  },
  {
    postalCode: '5600 ',
    city: ' Faaborg'
  },
  {
    postalCode: '5610 ',
    city: ' Assens'
  },
  {
    postalCode: '5620 ',
    city: ' Glamsbjerg'
  },
  {
    postalCode: '5631 ',
    city: ' Ebberup'
  },
  {
    postalCode: '5642 ',
    city: ' Millinge'
  },
  {
    postalCode: '5672 ',
    city: ' Broby'
  },
  {
    postalCode: '5683 ',
    city: ' Haarby'
  },
  {
    postalCode: '5690 ',
    city: ' Tommerup'
  },
  {
    postalCode: '5700 ',
    city: ' Svendborg'
  },
  {
    postalCode: '5750 ',
    city: ' Ringe'
  },
  {
    postalCode: '5762 ',
    city: ' Vester Skerninge'
  },
  {
    postalCode: '5771 ',
    city: ' Stenstrup'
  },
  {
    postalCode: '5772 ',
    city: ' Kværndrup'
  },
  {
    postalCode: '5792 ',
    city: ' Årslev'
  },
  {
    postalCode: '5800 ',
    city: ' Nyborg'
  },
  {
    postalCode: '5853 ',
    city: ' Ørbæk'
  },
  {
    postalCode: '5854 ',
    city: ' Gislev'
  },
  {
    postalCode: '5856 ',
    city: ' Ryslinge'
  },
  {
    postalCode: '5863 ',
    city: ' Ferritslev Fyn'
  },
  {
    postalCode: '5871 ',
    city: ' Frørup'
  },
  {
    postalCode: '5874 ',
    city: ' Hesselager'
  },
  {
    postalCode: '5881 ',
    city: ' Skårup Fyn'
  },
  {
    postalCode: '5882 ',
    city: ' Vejstrup'
  },
  {
    postalCode: '5883 ',
    city: ' Oure'
  },
  {
    postalCode: '5884 ',
    city: ' Gudme'
  },
  {
    postalCode: '5892 ',
    city: ' Gudbjerg Sydfyn'
  },
  {
    postalCode: '5900 ',
    city: ' Rudkøbing'
  },
  {
    postalCode: '5932 ',
    city: ' Humble'
  },
  {
    postalCode: '5935 ',
    city: ' Bagenkop'
  },
  {
    postalCode: '5953 ',
    city: ' Tranekær'
  },
  {
    postalCode: '5960 ',
    city: ' Marstal'
  },
  {
    postalCode: '5970 ',
    city: ' Ærøskøbing'
  },
  {
    postalCode: '5985 ',
    city: ' Søby Ærø'
  },
  {
    postalCode: '6000 ',
    city: ' Kolding'
  },
  {
    postalCode: '6040 ',
    city: ' Egtved'
  },
  {
    postalCode: '6051 ',
    city: ' Almind'
  },
  {
    postalCode: '6052 ',
    city: ' Viuf'
  },
  {
    postalCode: '6064 ',
    city: ' Jordrup'
  },
  {
    postalCode: '6070 ',
    city: ' Christiansfeld'
  },
  {
    postalCode: '6091 ',
    city: ' Bjert'
  },
  {
    postalCode: '6092 ',
    city: ' Sønder Stenderup'
  },
  {
    postalCode: '6093 ',
    city: ' Sjølund'
  },
  {
    postalCode: '6094 ',
    city: ' Hejls'
  },
  {
    postalCode: '6100 ',
    city: ' Haderslev'
  },
  {
    postalCode: '6200 ',
    city: ' Aabenraa'
  },
  {
    postalCode: '6230 ',
    city: ' Rødekro'
  },
  {
    postalCode: '6240 ',
    city: ' Løgumkloster'
  },
  {
    postalCode: '6261 ',
    city: ' Bredebro'
  },
  {
    postalCode: '6270 ',
    city: ' Tønder'
  },
  {
    postalCode: '6280 ',
    city: ' Højer'
  },
  {
    postalCode: '6300 ',
    city: ' Gråsten'
  },
  {
    postalCode: '6310 ',
    city: ' Broager'
  },
  {
    postalCode: '6320 ',
    city: ' Egernsund'
  },
  {
    postalCode: '6330 ',
    city: ' Padborg'
  },
  {
    postalCode: '6340 ',
    city: ' Kruså'
  },
  {
    postalCode: '6360 ',
    city: ' Tinglev'
  },
  {
    postalCode: '6372 ',
    city: ' Bylderup',
    undefined: 'Bov'
  },
  {
    postalCode: '6392 ',
    city: ' Bolderslev'
  },
  {
    postalCode: '6400 ',
    city: ' Sønderborg'
  },
  {
    postalCode: '6430 ',
    city: ' Nordborg'
  },
  {
    postalCode: '6440 ',
    city: ' Augustenborg'
  },
  {
    postalCode: '6470 ',
    city: ' Sydals'
  },
  {
    postalCode: '6500 ',
    city: ' Vojens'
  },
  {
    postalCode: '6510 ',
    city: ' Gram'
  },
  {
    postalCode: '6520 ',
    city: ' Toftlund'
  },
  {
    postalCode: '6534 ',
    city: ' Agerskov'
  },
  {
    postalCode: '6535 ',
    city: ' Branderup Jylland'
  },
  {
    postalCode: '6541 ',
    city: ' Bevtoft'
  },
  {
    postalCode: '6560 ',
    city: ' Sommersted'
  },
  {
    postalCode: '6580 ',
    city: ' Vamdrup'
  },
  {
    postalCode: '6600 ',
    city: ' Vejen'
  },
  {
    postalCode: '6621 ',
    city: ' Gesten'
  },
  {
    postalCode: '6622 ',
    city: ' Bække'
  },
  {
    postalCode: '6623 ',
    city: ' Vorbasse'
  },
  {
    postalCode: '6630 ',
    city: ' Rødding'
  },
  {
    postalCode: '6640 ',
    city: ' Lunderskov'
  },
  {
    postalCode: '6650 ',
    city: ' Brørup'
  },
  {
    postalCode: '6660 ',
    city: ' Lintrup'
  },
  {
    postalCode: '6670 ',
    city: ' Holsted'
  },
  {
    postalCode: '6682 ',
    city: ' Hovborg'
  },
  {
    postalCode: '6683 ',
    city: ' Føvling'
  },
  {
    postalCode: '6690 ',
    city: ' Gørding'
  },
  {
    postalCode: '6700 ',
    city: ' Esbjerg'
  },
  {
    postalCode: '6705 ',
    city: ' Esbjerg Ø'
  },
  {
    postalCode: '6710 ',
    city: ' Esbjerg V'
  },
  {
    postalCode: '6715 ',
    city: ' Esbjerg N'
  },
  {
    postalCode: '6720 ',
    city: ' Fanø'
  },
  {
    postalCode: '6731 ',
    city: ' Tjæreborg'
  },
  {
    postalCode: '6740 ',
    city: ' Bramming'
  },
  {
    postalCode: '6752 ',
    city: ' Glejbjerg'
  },
  {
    postalCode: '6753 ',
    city: ' Agerbæk'
  },
  {
    postalCode: '6760 ',
    city: ' Ribe'
  },
  {
    postalCode: '6771 ',
    city: ' Gredstedbro'
  },
  {
    postalCode: '6780 ',
    city: ' Skærbæk'
  },
  {
    postalCode: '6792 ',
    city: ' Rømø'
  },
  {
    postalCode: '6800 ',
    city: ' Varde'
  },
  {
    postalCode: '6818 ',
    city: ' Årre'
  },
  {
    postalCode: '6823 ',
    city: ' Ansager'
  },
  {
    postalCode: '6830 ',
    city: ' Nørre Nebel'
  },
  {
    postalCode: '6840 ',
    city: ' Oksbøl'
  },
  {
    postalCode: '6851 ',
    city: ' Janderup Vestjylland'
  },
  {
    postalCode: '6852 ',
    city: ' Billum'
  },
  {
    postalCode: '6853 ',
    city: ' Vejers Strand'
  },
  {
    postalCode: '6854 ',
    city: ' Henne'
  },
  {
    postalCode: '6855 ',
    city: ' Outrup'
  },
  {
    postalCode: '6857 ',
    city: ' Blåvand'
  },
  {
    postalCode: '6862 ',
    city: ' Tistrup'
  },
  {
    postalCode: '6870 ',
    city: ' Ølgod'
  },
  {
    postalCode: '6880 ',
    city: ' Tarm'
  },
  {
    postalCode: '6893 ',
    city: ' Hemmet'
  },
  {
    postalCode: '6900 ',
    city: ' Skjern'
  },
  {
    postalCode: '6920 ',
    city: ' Videbæk'
  },
  {
    postalCode: '6933 ',
    city: ' Kibæk'
  },
  {
    postalCode: '6940 ',
    city: ' Lem St.'
  },
  {
    postalCode: '6950 ',
    city: ' Ringkøbing'
  },
  {
    postalCode: '6960 ',
    city: ' Hvide Sande'
  },
  {
    postalCode: '6971 ',
    city: ' Spjald'
  },
  {
    postalCode: '6973 ',
    city: ' Ørnhøj'
  },
  {
    postalCode: '6980 ',
    city: ' Tim'
  },
  {
    postalCode: '6990 ',
    city: ' Ulfborg'
  },
  {
    postalCode: '7000 ',
    city: ' Fredericia'
  },
  {
    postalCode: '7001 ',
    city: ' Fredericia Postbutik'
  },
  {
    postalCode: '7007 ',
    city: ' Fredericia Sydjyllands Postcenter'
  },
  {
    postalCode: '7012 ',
    city: ' Nordbyen Postbutik'
  },
  {
    postalCode: '7013 ',
    city: ' Vest',
    undefined: 'centret Posthus'
  },
  {
    postalCode: '7014 ',
    city: ' Erritsø Postbutik'
  },
  {
    postalCode: '7015 ',
    city: ' Vestcentret Postbutik'
  },
  {
    postalCode: '7060 ',
    city: ' Tidligere Taulov.'
  },
  {
    postalCode: '7070 ',
    city: ' Tidligere Pjedsted.'
  },
  {
    postalCode: '7080 ',
    city: ' Børkop'
  },
  {
    postalCode: '7081 ',
    city: ' Børkop Postbutikker '
  },
  {
    postalCode: '7090 ',
    city: ' Brejning Postbutik '
  },
  {
    postalCode: '7100 ',
    city: ' Vejle'
  },
  {
    postalCode: '7101 ',
    city: ' Tidligere posthuset Vejle 1. Post code discontinued[2].'
  },
  {
    postalCode: '7102 ',
    city: ' Vindinggård Center Postbutik'
  },
  {
    postalCode: '7103 ',
    city: ' Ny Højen Afhentningssted'
  },
  {
    postalCode: '7104 ',
    city: ' Pakkeboksen på Nørremark Center 10 i Vejle 55°43′15.75″N, 9°33′35.66″E'
  },
  {
    postalCode: '7111 ',
    city: ' Søndermarken Postbutik'
  },
  {
    postalCode: '7114 ',
    city: ' Skolegade Postbutikker'
  },
  {
    postalCode: '7117 ',
    city: ' Nørremarken Afhentningssted'
  },
  {
    postalCode: '7118 ',
    city: ' Danmarks Transport Center Afhentningssted'
  },
  {
    postalCode: '7119 ',
    city: ' Løget Høj Afhentningssted'
  },
  {
    postalCode: '7120 ',
    city: ' Vejle Øst'
  },
  {
    postalCode: '7122 ',
    city: ' Jerlev Postbutik'
  },
  {
    postalCode: '7123 ',
    city: ' Lindved Postbutik'
  },
  {
    postalCode: '7125 ',
    city: ' Ødsted Postbutik'
  },
  {
    postalCode: '7126 ',
    city: ' Vejle Øst Postbutik'
  },
  {
    postalCode: '7129 ',
    city: ' Tidligere Grejsdal.'
  },
  {
    postalCode: '7130 ',
    city: ' Juelsminde'
  },
  {
    postalCode: '7131 ',
    city: ' Juelsminde Postbutik'
  },
  {
    postalCode: '7140 ',
    city: ' Stouby'
  },
  {
    postalCode: '7150 ',
    city: ' Barrit'
  },
  {
    postalCode: '7160 ',
    city: ' Tørring'
  },
  {
    postalCode: '7161 ',
    city: ' Tørring Postbutik'
  },
  {
    postalCode: '7171 ',
    city: ' Uldum'
  },
  {
    postalCode: '7172 ',
    city: ' Tidligere Ølholm.'
  },
  {
    postalCode: '7173 ',
    city: ' Vonge'
  },
  {
    postalCode: '7174 ',
    city: ' Tidligere Kollemorten.'
  },
  {
    postalCode: '7179 ',
    city: ' Bredsten Postbutik'
  },
  {
    postalCode: '7181 ',
    city: ' Tidligere Skibet.'
  },
  {
    postalCode: '7182 ',
    city: ' Bredsten'
  },
  {
    postalCode: '7183 ',
    city: ' Randbøl'
  },
  {
    postalCode: '7184 ',
    city: ' Vandel'
  },
  {
    postalCode: '7185 ',
    city: ' Grejs Postbutik'
  },
  {
    postalCode: '7190 ',
    city: ' Billund'
  },
  {
    postalCode: '7191 ',
    city: ' Billund Postbutikker'
  },
  {
    postalCode: '7200 ',
    city: ' Grindsted'
  },
  {
    postalCode: '7201 ',
    city: ' Grindsted Postbutikker'
  },
  {
    postalCode: '7202 ',
    city: ' Pakkeboksen på Østergade 21 i Grindsted 55°45′30.52″N, 8°55′55.48″E'
  },
  {
    postalCode: '7250 ',
    city: ' Hejnsvig'
  },
  {
    postalCode: '7251 ',
    city: ' Vesterhede Afhentningssted'
  },
  {
    postalCode: '7260 ',
    city: ' Sønder Omme'
  },
  {
    postalCode: '7270 ',
    city: ' Stakroge'
  },
  {
    postalCode: '7280 ',
    city: ' Sønder Felding'
  },
  {
    postalCode: '7300 ',
    city: ' Jelling'
  },
  {
    postalCode: '7301 ',
    city: ' Jelling Postbutik'
  },
  {
    postalCode: '7320 ',
    city: ' Give Postbutikker'
  },
  {
    postalCode: '7321 ',
    city: ' Gadbjerg'
  },
  {
    postalCode: '7322 ',
    city: ' Tidligere Farre.'
  },
  {
    postalCode: '7323 ',
    city: ' Give'
  },
  {
    postalCode: '7324 ',
    city: ' Thyregod Postbutik'
  },
  {
    postalCode: '7325 ',
    city: ' Grønbjerg Afhentningssted'
  },
  {
    postalCode: '7326 ',
    city: ' Kollemorten Afhentningssted'
  },
  {
    postalCode: '7330 ',
    city: ' Brande'
  },
  {
    postalCode: '7331 ',
    city: ' Brande Postbutikker'
  },
  {
    postalCode: '7340 ',
    city: ' Tidligere Blåhøj.'
  },
  {
    postalCode: '7350 ',
    city: ' Filskov Postbutik'
  },
  {
    postalCode: '7361 ',
    city: ' Ejstrupholm'
  },
  {
    postalCode: '7362 ',
    city: ' Hampen'
  },
  {
    postalCode: '7363 ',
    city: ' Tidligere Hjøllund.'
  },
  {
    postalCode: '7364 ',
    city: ' Tidligere Christianshede.'
  },
  {
    postalCode: '7371 ',
    city: ' Tidligere Fasterholt.'
  },
  {
    postalCode: '7372 ',
    city: ' Tidligere Kølkær.'
  },
  {
    postalCode: '7400 ',
    city: ' Herning'
  },
  {
    postalCode: '7401 ',
    city: ' Herning Vestjyllands Postcenter'
  },
  {
    postalCode: '7414 ',
    city: ' Holtbjerg Postbutik '
  },
  {
    postalCode: '7416 ',
    city: ' Snejbjerg Postbutik '
  },
  {
    postalCode: '7417 ',
    city: ' Tjørring Postbutik '
  },
  {
    postalCode: '7420 ',
    city: ' Tidligere Hammerum.'
  },
  {
    postalCode: '7421 ',
    city: ' Arnborg Afhentningssted '
  },
  {
    postalCode: '7423 ',
    city: ' Hammerum Postbutik '
  },
  {
    postalCode: '7430 ',
    city: ' Ikast'
  },
  {
    postalCode: '7431 ',
    city: ' Ikast Postbutikker '
  },
  {
    postalCode: '7433 ',
    city: ' Pakkeboksen på Lille Torv 1 A i Ikast 56°8′41.99″N, 9°9′14.91″E'
  },
  {
    postalCode: '7440 ',
    city: ' Bording Postbutik'
  },
  {
    postalCode: '7441 ',
    city: ' Bording'
  },
  {
    postalCode: '7442 ',
    city: ' Engesvang'
  },
  {
    postalCode: '7451 ',
    city: ' Sunds'
  },
  {
    postalCode: '7452 ',
    city: ' Ilskov Postbutik '
  },
  {
    postalCode: '7453 ',
    city: ' Frederiks Postbutik'
  },
  {
    postalCode: '7454 ',
    city: ' Tidligere Skelhøje.'
  },
  {
    postalCode: '7460 ',
    city: ' Tidligere Kølvrå.'
  },
  {
    postalCode: '7470 ',
    city: ' Karup Jylland'
  },
  {
    postalCode: '7471 ',
    city: ' Karup J Postbutik'
  },
  {
    postalCode: '7480 ',
    city: ' Vildbjerg'
  },
  {
    postalCode: '7489 ',
    city: ' Tidligere Skibbild.'
  },
  {
    postalCode: '7490 ',
    city: ' Aulum'
  },
  {
    postalCode: '7491 ',
    city: ' Aulum Postbutik '
  },
  {
    postalCode: '7499 ',
    city: ' Tvis Postbutik '
  },
  {
    postalCode: '7500 ',
    city: ' Holstebro'
  },
  {
    postalCode: '7511 ',
    city: ' Nørreland Postbutik '
  },
  {
    postalCode: '7514 ',
    city: ' Holstebro Posthus '
  },
  {
    postalCode: '7516 ',
    city: ' Nørre Felding Postbutik '
  },
  {
    postalCode: '7521 ',
    city: ' Borbjerg',
    undefined: 'hvam Postbutik '
  },
  {
    postalCode: '7522 ',
    city: ' Hodsager Postbutik '
  },
  {
    postalCode: '7524 ',
    city: ' Skave Postbutik '
  },
  {
    postalCode: '7525 ',
    city: ' Feldborg Postbutik'
  },
  {
    postalCode: '7540 ',
    city: ' Haderup'
  },
  {
    postalCode: '7550 ',
    city: ' Sørvad'
  },
  {
    postalCode: '7560 ',
    city: ' Hjerm'
  },
  {
    postalCode: '7570 ',
    city: ' Vemb'
  },
  {
    postalCode: '7580 ',
    city: ' Tidligere Bur.'
  },
  {
    postalCode: '7600 ',
    city: ' Struer'
  },
  {
    postalCode: '7601 ',
    city: ' Struer Postbutikker '
  },
  {
    postalCode: '7604 ',
    city: ' Asp Postbutik '
  },
  {
    postalCode: '7613 ',
    city: ' Jegindø Afhentningssted '
  },
  {
    postalCode: '7620 ',
    city: ' Lemvig'
  },
  {
    postalCode: '7621 ',
    city: ' Lemvig Postbutikker '
  },
  {
    postalCode: '7624 ',
    city: ' Fjaltring Postbutik '
  },
  {
    postalCode: '7625 ',
    city: ' Nørre Nissum Postbutik '
  },
  {
    postalCode: '7630 ',
    city: ' Tidligere Bonnet.'
  },
  {
    postalCode: '7640 ',
    city: ' Ramme Postbutik '
  },
  {
    postalCode: '7650 ',
    city: ' Bøvlingbjerg'
  },
  {
    postalCode: '7660 ',
    city: ' Bækmarksbro'
  },
  {
    postalCode: '7669 ',
    city: ' Tidligere Fåre.'
  },
  {
    postalCode: '7671 ',
    city: ' Tidligere Klinkby.'
  },
  {
    postalCode: '7672 ',
    city: ' Tidligere Vrist.'
  },
  {
    postalCode: '7673 ',
    city: ' Harboøre'
  },
  {
    postalCode: '7679 ',
    city: ' Tidligere Strande.'
  },
  {
    postalCode: '7680 ',
    city: ' Thyborøn'
  },
  {
    postalCode: '7681 ',
    city: ' Thyborøn Postbutik '
  },
  {
    postalCode: '7700 ',
    city: ' Thisted'
  },
  {
    postalCode: '7702 ',
    city: ' Pakkeboksen på Jernbanegade 29 i Thisted 56°57′10.38″N, 8°41′16.71″E'
  },
  {
    postalCode: '7705 ',
    city: ' Thisted Postbutikker'
  },
  {
    postalCode: '7730 ',
    city: ' Hanstholm'
  },
  {
    postalCode: '7731 ',
    city: ' Hanstholm Postbutik '
  },
  {
    postalCode: '7740 ',
    city: ' Frøstrup Postbutik'
  },
  {
    postalCode: '7741 ',
    city: ' Frøstrup'
  },
  {
    postalCode: '7742 ',
    city: ' Vesløs'
  },
  {
    postalCode: '7743 ',
    city: ' Østerild Afhentningssted'
  },
  {
    postalCode: '7744 ',
    city: ' Hundborg Postbutik'
  },
  {
    postalCode: '7746 ',
    city: ' Nørre',
    undefined: 'vorupør Postbutik'
  },
  {
    postalCode: '7747 ',
    city: ' Tidligere Hundstrup.'
  },
  {
    postalCode: '7748 ',
    city: ' Tidligere Hillerslev.'
  },
  {
    postalCode: '7749 ',
    city: ' Nors Postbutik'
  },
  {
    postalCode: '7751 ',
    city: ' Sjørring Postbutik'
  },
  {
    postalCode: '7752 ',
    city: ' Snedsted'
  },
  {
    postalCode: '7753 ',
    city: ' Hørdum Postbutik '
  },
  {
    postalCode: '7754 ',
    city: ' Tidligere Hassing Thy.'
  },
  {
    postalCode: '7755 ',
    city: ' Bedsted Thy'
  },
  {
    postalCode: '7760 ',
    city: ' Hurup Thy'
  },
  {
    postalCode: '7761 ',
    city: ' Hurup Thy Postbutik '
  },
  {
    postalCode: '7770 ',
    city: ' Vestervig'
  },
  {
    postalCode: '7771 ',
    city: ' Agger Postbutik '
  },
  {
    postalCode: '7781 ',
    city: ' Tidligere Ydby.'
  },
  {
    postalCode: '7782 ',
    city: ' Tidligere Gettrup.'
  },
  {
    postalCode: '7790 ',
    city: ' Thyholm. Tidligere Hvidbjerg.'
  },
  {
    postalCode: '7798 ',
    city: ' Uglev Afhentningssted '
  },
  {
    postalCode: '7799 ',
    city: ' Tidligere Oddesund.'
  },
  {
    postalCode: '7800 ',
    city: ' Skive'
  },
  {
    postalCode: '7801 ',
    city: ' Egeris Postbutik '
  },
  {
    postalCode: '7802 ',
    city: ' Skive Postbutikker '
  },
  {
    postalCode: '7804 ',
    city: ' Pakkeboksen på Søndergade 14 i Skive 56°33′43.07″N, 9°1′32.35″E'
  },
  {
    postalCode: '7814 ',
    city: ' Vridsted Postbutik '
  },
  {
    postalCode: '7829 ',
    city: ' Tidligere Rønbjerg.'
  },
  {
    postalCode: '7830 ',
    city: ' Vinderup'
  },
  {
    postalCode: '7831 ',
    city: ' Tidligere Ejsing Postbutik'
  },
  {
    postalCode: '7840 ',
    city: ' Højslev'
  },
  {
    postalCode: '7850 ',
    city: ' Stoholm Jylland'
  },
  {
    postalCode: '7851 ',
    city: ' Stoholm Jyll Postbutik'
  },
  {
    postalCode: '7860 ',
    city: ' Spøttrup'
  },
  {
    postalCode: '7861 ',
    city: ' Spøttrup Postbutik'
  },
  {
    postalCode: '7862 ',
    city: ' Lem Postbutik'
  },
  {
    postalCode: '7863 ',
    city: ' Tidligere Spøttrup.'
  },
  {
    postalCode: '7866 ',
    city: ' Oddense Postbutik '
  },
  {
    postalCode: '7870 ',
    city: ' Roslev'
  },
  {
    postalCode: '7879 ',
    city: ' Breum Postbutik'
  },
  {
    postalCode: '7881 ',
    city: ' Jebjerg Postbutik'
  },
  {
    postalCode: '7882 ',
    city: ' Durup Postbutik'
  },
  {
    postalCode: '7883 ',
    city: ' Glyngøre Afhentningssted'
  },
  {
    postalCode: '7884 ',
    city: ' Fur'
  },
  {
    postalCode: '7885 ',
    city: ' Selde Postbutik'
  },
  {
    postalCode: '7900 ',
    city: ' Nykøbing Mors'
  },
  {
    postalCode: '7901 ',
    city: ' Nykøbing M Postbutikker'
  },
  {
    postalCode: '7911 ',
    city: ' Sejerslev Postbutik'
  },
  {
    postalCode: '7920 ',
    city: ' Tidligere Vildsund Vest.'
  },
  {
    postalCode: '7930 ',
    city: ' Sundby Mors Postbutik '
  },
  {
    postalCode: '7940 ',
    city: ' Tidligere Solbjerg Mors.'
  },
  {
    postalCode: '7950 ',
    city: ' Erslev'
  },
  {
    postalCode: '7960 ',
    city: ' Karby'
  },
  {
    postalCode: '7970 ',
    city: ' Redsted Mors'
  },
  {
    postalCode: '7980 ',
    city: ' Vils'
  },
  {
    postalCode: '7990 ',
    city: ' Øster Assels'
  },
  {
    postalCode: '7993 ',
    city: ' Ufrankerede svarforsendelser'
  },
  {
    postalCode: '7996 ',
    city: ' Fakturaservice'
  },
  {
    postalCode: '7997 ',
    city: ' Fakturascanning'
  },
  {
    postalCode: '7999 ',
    city: ' Kommunepost. '
  },
  {
    postalCode: '8000 ',
    city: ' Aarhus C'
  },
  {
    postalCode: '8200 ',
    city: ' Aarhus N'
  },
  {
    postalCode: '8210 ',
    city: ' Aarhus V'
  },
  {
    postalCode: '8220 ',
    city: ' Brabrand'
  },
  {
    postalCode: '8230 ',
    city: ' Åbyhøj'
  },
  {
    postalCode: '8240 ',
    city: ' Risskov'
  },
  {
    postalCode: '8250 ',
    city: ' Egå'
  },
  {
    postalCode: '8260 ',
    city: ' Viby Jylland'
  },
  {
    postalCode: '8270 ',
    city: ' Højbjerg'
  },
  {
    postalCode: '8300 ',
    city: ' Odder'
  },
  {
    postalCode: '8305 ',
    city: ' Samsø'
  },
  {
    postalCode: '8310 ',
    city: ' Tranbjerg Jylland'
  },
  {
    postalCode: '8320 ',
    city: ' Mårslet'
  },
  {
    postalCode: '8330 ',
    city: ' Beder'
  },
  {
    postalCode: '8340 ',
    city: ' Malling'
  },
  {
    postalCode: '8350 ',
    city: ' Hundslund'
  },
  {
    postalCode: '8355 ',
    city: ' Solbjerg'
  },
  {
    postalCode: '8361 ',
    city: ' Hasselager'
  },
  {
    postalCode: '8362 ',
    city: ' Hørning'
  },
  {
    postalCode: '8370 ',
    city: ' Hadsten'
  },
  {
    postalCode: '8380 ',
    city: ' Trige'
  },
  {
    postalCode: '8381 ',
    city: ' Tilst'
  },
  {
    postalCode: '8382 ',
    city: ' Hinnerup'
  },
  {
    postalCode: '8400 ',
    city: ' Ebeltoft'
  },
  {
    postalCode: '8410 ',
    city: ' Rønde'
  },
  {
    postalCode: '8420 ',
    city: ' Knebel'
  },
  {
    postalCode: '8444 ',
    city: ' Balle'
  },
  {
    postalCode: '8450 ',
    city: ' Hammel'
  },
  {
    postalCode: '8462 ',
    city: ' Harlev Jylland'
  },
  {
    postalCode: '8464 ',
    city: ' Galten'
  },
  {
    postalCode: '8471 ',
    city: ' Sabro'
  },
  {
    postalCode: '8472 ',
    city: ' Sporup'
  },
  {
    postalCode: '8500 ',
    city: ' Grenaa'
  },
  {
    postalCode: '8520 ',
    city: ' Lystrup'
  },
  {
    postalCode: '8530 ',
    city: ' Hjortshøj'
  },
  {
    postalCode: '8541 ',
    city: ' Skødstrup'
  },
  {
    postalCode: '8543 ',
    city: ' Hornslet'
  },
  {
    postalCode: '8544 ',
    city: ' Mørke'
  },
  {
    postalCode: '8550 ',
    city: ' Ryomgård'
  },
  {
    postalCode: '8560 ',
    city: ' Kolind'
  },
  {
    postalCode: '8570 ',
    city: ' Trustrup'
  },
  {
    postalCode: '8581 ',
    city: ' Nimtofte'
  },
  {
    postalCode: '8585 ',
    city: ' Glesborg'
  },
  {
    postalCode: '8586 ',
    city: ' Ørum Djurs'
  },
  {
    postalCode: '8592 ',
    city: ' Anholt'
  },
  {
    postalCode: '8600 ',
    city: ' Silkeborg'
  },
  {
    postalCode: '8620 ',
    city: ' Kjellerup'
  },
  {
    postalCode: '8632 ',
    city: ' Lemming'
  },
  {
    postalCode: '8641 ',
    city: ' Sorring'
  },
  {
    postalCode: '8643 ',
    city: ' Ans By'
  },
  {
    postalCode: '8653 ',
    city: ' Them'
  },
  {
    postalCode: '8654 ',
    city: ' Bryrup'
  },
  {
    postalCode: '8660 ',
    city: ' Skanderborg'
  },
  {
    postalCode: '8670 ',
    city: ' Låsby'
  },
  {
    postalCode: '8680 ',
    city: ' Ry'
  },
  {
    postalCode: '8700 ',
    city: ' Horsens'
  },
  {
    postalCode: '8721 ',
    city: ' Daugård'
  },
  {
    postalCode: '8722 ',
    city: ' Hedensted'
  },
  {
    postalCode: '8723 ',
    city: ' Løsning'
  },
  {
    postalCode: '8732 ',
    city: ' Hovedgård'
  },
  {
    postalCode: '8740 ',
    city: ' Brædstrup'
  },
  {
    postalCode: '8751 ',
    city: ' Gedved'
  },
  {
    postalCode: '8752 ',
    city: ' Østbirk'
  },
  {
    postalCode: '8762 ',
    city: ' Flemming'
  },
  {
    postalCode: '8763 ',
    city: ' Rask Mølle'
  },
  {
    postalCode: '8765 ',
    city: ' Klovborg'
  },
  {
    postalCode: '8766 ',
    city: ' Nørre Snede'
  },
  {
    postalCode: '8781 ',
    city: ' Stenderup'
  },
  {
    postalCode: '8783 ',
    city: ' Hornsyld'
  },
  {
    postalCode: '8800 ',
    city: ' Viborg'
  },
  {
    postalCode: '8830 ',
    city: ' Tjele'
  },
  {
    postalCode: '8831 ',
    city: ' Løgstrup'
  },
  {
    postalCode: '8832 ',
    city: ' Skals'
  },
  {
    postalCode: '8840 ',
    city: ' Rødkærsbro'
  },
  {
    postalCode: '8850 ',
    city: ' Bjerringbro'
  },
  {
    postalCode: '8860 ',
    city: ' Ulstrup'
  },
  {
    postalCode: '8870 ',
    city: ' Langå'
  },
  {
    postalCode: '8881 ',
    city: ' Thorsø'
  },
  {
    postalCode: '8882 ',
    city: ' Fårvang'
  },
  {
    postalCode: '8883 ',
    city: ' Gjern'
  },
  {
    postalCode: '8900 ',
    city: ' Randers C'
  },
  {
    postalCode: '8920 ',
    city: ' Randers NV'
  },
  {
    postalCode: '8930 ',
    city: ' Randers NØ'
  },
  {
    postalCode: '8940 ',
    city: ' Randers SV'
  },
  {
    postalCode: '8960 ',
    city: ' Randers SØ'
  },
  {
    postalCode: '8950 ',
    city: ' Ørsted'
  },
  {
    postalCode: '8961 ',
    city: ' Allingåbro'
  },
  {
    postalCode: '8963 ',
    city: ' Auning'
  },
  {
    postalCode: '8970 ',
    city: ' Havndal'
  },
  {
    postalCode: '8981 ',
    city: ' Spentrup'
  },
  {
    postalCode: '8983 ',
    city: ' Gjerlev Jylland'
  },
  {
    postalCode: '8990 ',
    city: ' Fårup'
  },
  {
    postalCode: '9000 ',
    city: ' Aalborg'
  },
  {
    postalCode: '9200 ',
    city: ' Aalborg SV'
  },
  {
    postalCode: '9210 ',
    city: ' Aalborg SØ'
  },
  {
    postalCode: '9220 ',
    city: ' Aalborg Øst'
  },
  {
    postalCode: '9230 ',
    city: ' Svenstrup Jylland'
  },
  {
    postalCode: '9240 ',
    city: ' Nibe'
  },
  {
    postalCode: '9260 ',
    city: ' Gistrup'
  },
  {
    postalCode: '9270 ',
    city: ' Klarup'
  },
  {
    postalCode: '9280 ',
    city: ' Storvorde'
  },
  {
    postalCode: '9293 ',
    city: ' Kongerslev'
  },
  {
    postalCode: '9300 ',
    city: ' Sæby'
  },
  {
    postalCode: '9310 ',
    city: ' Vodskov'
  },
  {
    postalCode: '9320 ',
    city: ' Hjallerup'
  },
  {
    postalCode: '9330 ',
    city: ' Dronninglund'
  },
  {
    postalCode: '9340 ',
    city: ' Asaa'
  },
  {
    postalCode: '9352 ',
    city: ' Dybvad'
  },
  {
    postalCode: '9362 ',
    city: ' Gandrup'
  },
  {
    postalCode: '9370 ',
    city: ' Hals'
  },
  {
    postalCode: '9380 ',
    city: ' Vestbjerg'
  },
  {
    postalCode: '9381 ',
    city: ' Sulsted'
  },
  {
    postalCode: '9382 ',
    city: ' Tylstrup'
  },
  {
    postalCode: '9400 ',
    city: ' Nørresundby'
  },
  {
    postalCode: '9430 ',
    city: ' Vadum'
  },
  {
    postalCode: '9440 ',
    city: ' Aabybro'
  },
  {
    postalCode: '9460 ',
    city: ' Brovst'
  },
  {
    postalCode: '9480 ',
    city: ' Løkken',
    undefined: 'Vrå'
  },
  {
    postalCode: '9490 ',
    city: ' Pandrup'
  },
  {
    postalCode: '9492 ',
    city: ' Blokhus'
  },
  {
    postalCode: '9493 ',
    city: ' Saltum'
  },
  {
    postalCode: '9500 ',
    city: ' Hobro'
  },
  {
    postalCode: '9510 ',
    city: ' Arden'
  },
  {
    postalCode: '9520 ',
    city: ' Skørping'
  },
  {
    postalCode: '9530 ',
    city: ' Støvring'
  },
  {
    postalCode: '9541 ',
    city: ' Suldrup'
  },
  {
    postalCode: '9550 ',
    city: ' Mariager'
  },
  {
    postalCode: '9560 ',
    city: ' Hadsund'
  },
  {
    postalCode: '9574 ',
    city: ' Bælum'
  },
  {
    postalCode: '9575 ',
    city: ' Terndrup'
  },
  {
    postalCode: '9600 ',
    city: ' Aars'
  },
  {
    postalCode: '9610 ',
    city: ' Nørager'
  },
  {
    postalCode: '9620 ',
    city: ' Aalestrup'
  },
  {
    postalCode: '9631 ',
    city: ' Gedsted'
  },
  {
    postalCode: '9632 ',
    city: ' Møldrup'
  },
  {
    postalCode: '9640 ',
    city: ' Farsø'
  },
  {
    postalCode: '9670 ',
    city: ' Løgstør'
  },
  {
    postalCode: '9681 ',
    city: ' Ranum'
  },
  {
    postalCode: '9690 ',
    city: ' Fjerritslev'
  },
  {
    postalCode: '9700 ',
    city: ' Brønderslev'
  },
  {
    postalCode: '9740 ',
    city: ' Jerslev Jylland'
  },
  {
    postalCode: '9750 ',
    city: ' Østervrå'
  },
  {
    postalCode: '9760 ',
    city: ' Vrå'
  },
  {
    postalCode: '9800 ',
    city: ' Hjørring'
  },
  {
    postalCode: '9830 ',
    city: ' Tårs'
  },
  {
    postalCode: '9850 ',
    city: ' Hirtshals'
  },
  {
    postalCode: '9870 ',
    city: ' Sindal'
  },
  {
    postalCode: '9881 ',
    city: ' Bindslev'
  },
  {
    postalCode: '9900 ',
    city: ' Frederikshavn'
  },
  {
    postalCode: '9940 ',
    city: ' Læsø'
  },
  {
    postalCode: '9970 ',
    city: ' Strandby'
  },
  {
    postalCode: '9981 ',
    city: ' Jerup'
  },
  {
    postalCode: '9982 ',
    city: ' Ålbæk'
  },
  {
    postalCode: '9990 ',
    city: ' Skagen'
  }
]
