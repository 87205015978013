import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Button, Input } from 'reactstrap'
import FormField from 'Components/formField'
import moment from 'moment'

let headers = {
  // UserID: 'BrugerID',
  userName: 'Bruger Navn',
  userUID: 'UID',
  userAccess: 'Status',
  brandName: 'Brand',
  userPhone: 'Mobile',
  userEmail: 'Email',
  userCompany: 'Firma',
  userAddress: 'Adresse',
  userZipcode: 'Postnr.',
  userCity: 'By',
  walletAmountPoints: 'Points added',
  walletNote: 'Point note',
  walletType: 'Wallet Type',
  timeInsert: 'Date',
  orderAmountPoints: 'Order Points',
  orderlineTitle: 'Product',
  taxPrice: 'Tax value DKK',
  pointSummary: 'Point Summary',
  pointRatio: 'Point Ratio'
}

const TaxOverview = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear() - 1)
  const [BrandID, setBrandID] = useState(null)
  const [brands, setBrands] = useState([])

  function fetchOrderTaxOverview() {
    setLoading(true)
    Proxies.GET('orders/getTaxOverview', { year, BrandID })
      .then(response => {
        setLoading(false)
        if (response) {
          setData(response)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    Proxies.GET('menus/listBrands').then(responseJSON => {
      setBrands(responseJSON)
    })
    setLoading(false)
  }, [])

  return (
    <React.Fragment>
      <CollapsibleCard header={<strong>Ordrer</strong>} startOpen={true}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField field={'BrandID'} name='Brand'>
            <Input
              type='select'
              name='BrandID'
              value={BrandID}
              onChange={e => {
                setBrandID(e.target.value)
              }}
            >
              <option selected='true' disabled={true} value={''}>
                Select Brand
              </option>
              {brands &&
                !!brands.length &&
                brands.map(e => {
                  return (
                    <option value={e.BrandID} key={e.BrandID}>
                      {e.BrandID} - {e.brandName}
                    </option>
                  )
                })}
            </Input>
          </FormField>
          <FormField name='Year' rows={5} field='year'>
            <Input type='number' onChange={e => setYear(e.target.value)} name='year' value={year} />
          </FormField>
        </Form>
        <div style={{ flexDirection: 'row', marginBottom: 32, paddingHorizontal: 16 }}>
          <Button disabled={!year || !BrandID} style={{ marginRight: 16 }} onClick={() => fetchOrderTaxOverview()}>
            Search
          </Button>
        </div>
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : data && !data.length ? (
          <p>No Orders in the selected year.</p>
        ) : data?.length ? (
          <DynamicTable
            data={data}
            headers={headers}
            options={{
              fieldFunctions: {
                timeInsert: e => {
                  return !e.timeInsert ? '-' : moment(e.timeInsert).format('DD-MM-YYYY hh:mm')
                }
              }
            }}
          />
        ) : null}
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default TaxOverview
