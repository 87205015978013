import React from 'react'
import { Component } from 'reflux'
import { AddressActions, AddressStore, STATUS } from 'Stores/addressStore'
import { Button, Form, Input, Row, Col, Label } from 'reactstrap'
import _ from 'lodash'
import CollapsibleCard from 'Components/collapsibleCard'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HofStore, HofActions } from 'Stores/hofStore'
import { LoginStore } from 'Stores/loginStore'

export default class AddressForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [AddressStore, HofStore, LoginStore]
    this.storeKeys = ['loadingState', 'addressDetails', 'brands', 'settings']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }
  componentDidMount() {
    if (this.state.brands && !this.state.brands.length) {
      HofActions.getBrands()
    }
  }
  resetAddress = () => {
    if (_.get(this.props, 'match.params.AddressID')) {
      AddressActions.getAddressDetails(this.props.match.params.AddressID)
    } else {
      AddressActions.createEmptyAddress()
    }
  }
  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isCompanyNameValid: this.state.addressDetails.companyName ? true : false,
      isAddressValid: this.state.addressDetails.addressLine ? true : false,
      isZipValid: this.state.addressDetails.zip ? true : false,
      isCityValid: this.state.addressDetails.city ? true : false
    }

    this.setState({ fieldValidation })

    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }
  saveAddress = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      AddressActions.saveAddress()
    }
  }
  render() {
    if (this.state.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/address' />
    }
    if (this.state.loadingState === STATUS.FETCHING_ADDRESS_DETAILS) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>{_.get(this.props, 'match.params.AddressID') ? 'Detaljer' : 'Opret'}</strong>}>
        <Form>
          {this.state.settings &&
            this.state.settings.BrandID === 9 &&
            this.state.settings.userRole.toLowerCase() === 'system' &&
            this.state.settings.userPrivilege.toLowerCase() === 'manage' && (
              <Row className='mb-2'>
                <Col xs='2'>
                  <Label for='BrandID'>Brand</Label>
                </Col>
                <Col xs='10'>
                  <Input type='select' name='BrandID' value={this.state.addressDetails.BrandID} onChange={AddressActions.updateAddressField}>
                    <option value=''>Select Brand</option>
                    {this.state.brands &&
                      !!this.state.brands.length &&
                      this.state.brands.map(e => {
                        return (
                          <option value={e.BrandID} key={e.BrandID}>
                            {e.brandName} - {e.BrandID}
                          </option>
                        )
                      })}
                  </Input>
                </Col>
              </Row>
            )}
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='companyName'>Firma Navn</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={this.state.addressDetails.companyName}
                name='companyName'
                id='companyName'
                className={this.state.addressDetails.companyName ? 'is-valid' : 'is-invalid'}
                onChange={e => {
                  AddressActions.updateAddressField(e), this.validateForm()
                }}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='address'>Adresse</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={this.state.addressDetails.addressLine}
                name='addressLine'
                id='addressLine'
                className={this.state.addressDetails.addressLine ? 'is-valid' : 'is-invalid'}
                onChange={AddressActions.updateAddressField}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='zip'>Postnr.</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={this.state.addressDetails.zip}
                name='zip'
                id='zip'
                className={this.state.addressDetails.zip ? 'is-valid' : 'is-invalid'}
                onChange={AddressActions.updateAddressField}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='city'>By</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={this.state.addressDetails.city}
                name='city'
                id='city'
                className={this.state.addressDetails.city ? 'is-valid' : 'is-invalid'}
                onChange={AddressActions.updateAddressField}
              />
            </Col>
          </Row>
          <Button onClick={this.saveAddress} disabled={this.state.loadingState === STATUS.IS_SAVING}>
            {this.state.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={this.resetAddress} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    )
  }
}
