import React from 'react'
import styled from 'styled-components'
import { ResponsiveBar } from '@nivo/bar'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colorMap } from 'Components/dashboard/colorMap.js'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import DataNotFound from 'Components/dashboard/DataNotFound'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

const KamPaginationDiv = styled.div`
  ul {
    justify-content: space-between;
  }
  ul li {
    position: relative;
    top: -280px;
  }
`
const ChartWrap = styled.div`
  height: 450px;
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: relative;
  font-size: 30px;
  float: right;
  bottom: ${props => (props.bottomcss ? props.bottomcss : '482px')};
  margin-right: 40px !important;
`
const ErrorChartWrap = styled.div`
  height: 200px;
`

export default class SantanderKAMGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startIndex: 0,
      endIndex: 14,
      currentPage: 0
    }
  }

  pagination = (e, index) => {
    e.preventDefault()
    let startIndex = index !== 0 ? index * 14 : 0
    let endIndex = startIndex + 14
    this.setState({
      startIndex,
      endIndex,
      currentPage: index
    })
  }

  usersDropdown = () => {
    return (
      <UncontrolledDropdown setActiveFromChild>
        <DropdownToggle style={{ margin: '15px 30px' }}>Vælg KAM</DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: data => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: 'auto',
                    maxHeight: '200px'
                  }
                }
              }
            }
          }}
        >
          {this.props.dropdownData &&
            !!this.props.dropdownData.length &&
            this.props.dropdownData.map((item, index) => {
              return (
                <DropdownItem onClick={() => this.getMerchendaiseProducts(item)} value={item} key={index}>
                  {item.user}
                </DropdownItem>
              )
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  getMerchendaiseProducts(item) {
    if (item) {
      this.setState({
        startIndex: 0,
        endIndex: 14,
        currentPage: 0
      })
      this.props.dropdownOnClick(item)
    }
  }

  render() {
    const { startIndex, endIndex, currentPage } = this.state
    const { data, keys, indexBy, longestName, pagesCount, legends, showIcon, cursor, axisBottom, axisLeft, colors } = this.props
    return (
      <React.Fragment>
        {this.props.showDropdown && <this.usersDropdown />}
        <DashboardComponent header={this.props.header} state={this.props.state}>
          {this.props.state === DASHBOARD_COMPONENT_STATE.SUCCESS && (
            <>
              {this.props.data && !!this.props.data.length ? (
                <ChartWrap style={cursor} onClick={e => this.props.getTotalKamDetails && this.props.getTotalKamDetails(e)}>
                  <ResponsiveBar
                    data={data.length > 15 ? data.slice(startIndex, endIndex) : data}
                    keys={keys}
                    indexBy={indexBy}
                    groupMode='grouped'
                    margin={
                      this.props.margin
                        ? this.props.margin
                        : { top: 50, right: 40, bottom: longestName > 36 ? longestName * 4 : longestName * 5, left: 70 }
                    }
                    padding={this.props.padding ? this.props.padding : 0.3}
                    colors={({ id }) => (colors ? colors[id] : colorMap[`${id}Color`])}
                    axisBottom={{
                      ...{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -50
                      },
                      ...axisBottom
                    }}
                    axisLeft={{
                      ...{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0
                      },
                      ...axisLeft
                    }}
                    onClick={(v, e) => this.props.onClick && this.props.onClick(e, v)}
                    theme={{
                      axis: { legend: { text: { fontWeight: 700, fontFamily: '"Open Sans", sans-serif' } } }
                    }}
                    enableLabel={false}
                    labelSkipWidth={40}
                    labelSkipHeight={16}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    tooltipFormat={value => new Intl.NumberFormat().format(value)}
                    legends={[
                      {
                        ...{
                          dataFrom: 'keys',
                          anchor: 'top',
                          direction: 'row',
                          justify: false,
                          translateX: -10,
                          translateY: -30,
                          itemsSpacing: 10,
                          itemWidth: 50,
                          itemHeight: 20,
                          itemDirection: 'left-to-right',
                          itemOpacity: 0.85,
                          symbolSize: 15
                        },
                        ...legends
                      }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    layout={this.props.layout ? this.props.layout : 'vertical'}
                    enableGridX={this.props.enableGridX && this.props.enableGridX}
                    enableGridY={this.props.enableGridY && this.props.enableGridY}
                  />
                </ChartWrap>
              ) : (
                <ErrorChartWrap>
                  <DataNotFound />
                </ErrorChartWrap>
              )}
              {!!data.length && data.length > 15 && (
                <KamPaginationDiv>
                  <Pagination size='sm'>
                    <PaginationItem disabled={currentPage <= 0}>
                      <PaginationLink onClick={e => this.pagination(e, currentPage - 1)} previous href='#' />
                    </PaginationItem>
                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink onClick={e => this.pagination(e, currentPage + 1)} next href='#' />
                    </PaginationItem>
                  </Pagination>
                </KamPaginationDiv>
              )}
              {showIcon && (
                <StyledFontAwesomeIcon
                  bottomcss={this.props.iconcss}
                  onClick={e => this.props.getTotalKamDetails && this.props.getTotalKamDetails(e)}
                  fixedWidth
                  icon='list-ol'
                />
              )}
            </>
          )}
        </DashboardComponent>
      </React.Fragment>
    )
  }
}
