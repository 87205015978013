import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import 'moment/locale/da'
import { InvoiceStore, InvoiceActions } from 'Stores/invoiceStore'
import { toast } from 'react-toastify'
import { LoginStore } from '../../stores/loginStore'
import { Row } from 'reactstrap'

export default class FreightElements extends Component {
  constructor(props) {
    super(props)
    this.stores = [InvoiceStore, LoginStore]
    this.storeKeys = ['BrandID', 'fetchingFreightElementsError', 'fetchingFreightElements', 'freightElements', 'fetchingInvoiceLine', 'invoiceLine']
  }
  componentDidMount() {
    if (this.state.BrandID === 9) {
      InvoiceActions.getAdminInvoiceElements(this.props.match.params.InvoiceID, 1)
    } else {
      InvoiceActions.getInvoiceElements(this.props.match.params.InvoiceID)
    }
  }
  componentDidUpdate() {
    if (this.state.fetchingFreightElementsError) {
      toast.error('Fetching failed', {
        autoClose: 5000,
        onClose: () => {
          InvoiceActions.clearToast()
        }
      })
    }
  }
  render() {
    return (
      <Row>
        <CollapsibleCard
          startOpen
          header={
            <React.Fragment>
              <strong>Weight Overview</strong>{' '}
            </React.Fragment>
          }
        >
          {this.state.fetchingInvoiceLine ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <DynamicTable
              responsive
              size='lg'
              data={this.state.invoiceLine.weightOverview}
              headers={{
                country: 'Country Code',
                orderWeightLessThan5: 'Weight 0-5 KG',
                orderWeight5And10: 'Weight 5-10 KG',
                orderWeight10And15: 'Weight 10-15 KG',
                orderWeight15And20: 'Weight 15-20 KG',
                orderWeightMoreThan20: 'Weight > 20 KG',
                allWeights: 'Total number of parcels'
              }}
              options={{}}
            />
          )}
        </CollapsibleCard>
        <CollapsibleCard
          startOpen
          header={
            <React.Fragment>
              <strong>Linier</strong>{' '}
            </React.Fragment>
          }
        >
          {this.state.fetchingFreightElements ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <DynamicTable
              responsive
              size='sm'
              data={this.state.freightElements}
              headers={
                this.state.BrandID === 9
                  ? {
                      OrderlineID: '#',
                      ConfirmationID: 'Confirmation ID',
                      OrderID: 'Ordre ID',
                      orderType: 'Order Type',
                      UserID: 'Bruger ID',
                      userName: 'Bruger',
                      userUID: 'Unikt ID',
                      userCompany: 'Virksomhed',
                      orderlineTitle: 'Vare',
                      orderAmountPoints: 'Point',
                      orderPointRatio: 'Ratio',
                      orderlineProductPrice: 'Basis',
                      orderCurrency: 'Valuta',
                      timeInsert: 'Oprettet',
                      timeShipped: 'Afsendt',
                      vaultSlug: 'Vault Slug',
                      orderlineWeight: 'Weight',
                      orderParcels: 'Parcels'
                    }
                  : {
                      OrderlineID: '#',
                      ConfirmationID: 'Confirmation ID',
                      OrderID: 'Ordre ID',
                      UserID: 'Bruger ID',
                      userName: 'Bruger',
                      userUID: 'Unikt ID',
                      userCompany: 'Virksomhed',
                      timeShipped: 'Afsendt',
                      vaultSlug: 'Vault Slug',
                      orderlineWeight: 'Weight',
                      orderParcels: 'Parcels'
                    }
              }
              options={{
                fieldFunctions: {
                  ConfirmationID: e => {
                    return e.ConfirmationID
                  },
                  OrderID: e => {
                    return e.OrderID
                  },
                  orderType: e => {
                    return e.orderCustomOrder ? 'Custom' : 'Normal'
                  },
                  UserID: e => {
                    return e.UserID
                  },
                  userName: e => {
                    return e.userName
                  },
                  userUID: e => {
                    return e.userUID
                  },
                  userCompany: e => {
                    return e.userCompany
                  },
                  timeInsert: e => {
                    return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm') : ''
                  },
                  timeShipped: e => {
                    return e.timeShipped ? moment(e.timeShipped).format('DD-MM-YYYY HH:mm') : ''
                  }
                },
                fieldStringFunctions: {
                  timeInsert: e => {
                    return moment(e.timeInsert).format('x')
                  },
                  timeShipped: e => {
                    return moment(e.timeShipped).format('x')
                  }
                }
              }}
            />
          )}
        </CollapsibleCard>
      </Row>
    )
  }
}
