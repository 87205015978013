import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/customerSalesRewardActions'

export const CustomerSalesRewardSlice = createSlice({
  name: 'customerSalesReward',
  initialState: {
    overviewLoadingState: false,
    customerSalesRewards: [],
    customerProducts: [],
    customerSalesRewardDetails: {
      CustomerProductID: '',
      CustomerSalesRewardID: ''
    },
    loadingState: false,
    showActiveCustomerSalesRewards: true,
    showInActiveCustomerSalesRewards: false,
    customerSalesRewardFilterValue: 'active'
  },
  reducers: {
    fetchCustomerSalesRewardsRequest: state => {
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_SALES_REWARDS
    },
    fetchCustomerSalesRewardsFailed: state => {
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_SALES_REWARDS_FAILED
    },
    fetchCustomerSalesRewardsSuccess: (state, action) => {
      state.customerSalesRewards = action.payload
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_SALES_REWARDS_SUCCESS
    },
    fetchCustomerProductsSuccess: (state, action) => {
      state.customerProducts = action.payload
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_PRODUCTS_SUCCESS
    },
    fetchCustomerSalesRewardDetailsRequest: state => {
      state.loadingState = STATUS.FETCHING_CUSTOMER_SALES_REWARD_DETAILS
    },
    fetchCustomerSalesRewardDetailsFailed: state => {
      state.loadingState = STATUS.FETCHING_CUSTOMER_SALES_REWARD_DETAILS_FAILED
    },
    fetchCustomerSalesRewardDetailsSuccess: (state, action) => {
      state.loadingState = STATUS.FETCHING_CUSTOMER_SALES_REWARD_DETAILS_SUCCESS
      state.customerSalesRewardDetails = action.payload
    },
    CustomerSalesRewardCreationSuccess: (state, action) => {
      state.loadingState = action.payload
      state.loadingState = STATUS.CUSTOMER_SALES_REWARD_SUCCESS
    },
    CustomerSalesRewardCreationFailure: state => {
      state.loadingState = STATUS.CUSTOMER_SALES_REWARD_FAILED
    },
    isSavingCustomerSalesReward: state => {
      state.loadingState = STATUS.IS_SAVING
    },
    createEmptyCustomerSalesReward: state => {
      state.customerSalesRewardDetails = {
        rewardPoints: 0,
        CustomerProductID: '',
        productRatio: ''
      }
    },
    clearToast: (state, action) => {
      state.loadingState = action.payload ? action.payload : null
      state.overviewLoadingState = false
    },
    updateCustomerSalesRewardField: (state, action) => {
      return {
        ...state,
        customerSalesRewardDetails: { ...state.customerSalesRewardDetails, [action.payload.name]: action.payload.value }
      }
    },
    removeCustomerSalesRewardFailure: state => {
      state.overviewLoadingState = STATUS.DELETE_CUSTOMER_SALES_REWARD_FAILED
    },
    removeCustomerSalesRewardSuccess: state => {
      state.overviewLoadingState = STATUS.DELETE_CUSTOMER_SALES_REWARD_SUCCESS
    },
    toggleActiveCustomerSalesReward: state => {
      let value = !state.showActiveCustomerSalesRewards ? 'active' : 'both'
      state.showActiveCustomerSalesRewards = !state.showActiveCustomerSalesRewards
      state.customerSalesRewardFilterValue = value
      state.showInActiveCustomerSalesRewards = false
    },
    toggleInActiveCustomerSalesReward: state => {
      let value = state.showInActiveCustomerSalesRewards ? 'both' : 'inactive'
      state.showInActiveCustomerSalesRewards = !state.showInActiveCustomerSalesRewards
      state.customerSalesRewardFilterValue = value
      state.showActiveCustomerSalesRewards = false
    }
  }
})

export const {
  fetchCustomerSalesRewardsRequest,
  fetchCustomerSalesRewardsFailed,
  fetchCustomerSalesRewardsSuccess,
  fetchCustomerProductsSuccess,
  fetchCustomerSalesRewardDetailsRequest,
  fetchCustomerSalesRewardDetailsFailed,
  fetchCustomerSalesRewardDetailsSuccess,
  CustomerSalesRewardCreationSuccess,
  CustomerSalesRewardCreationFailure,
  toggleActiveCustomerSalesReward,
  toggleInActiveCustomerSalesReward,
  removeCustomerSalesRewardFailure,
  resetCustomerSalesRewardsDetails,
  isSavingCustomerSalesReward,
  createEmptyCustomerSalesReward,
  clearToast,
  updateCustomerSalesRewardField,
  removeCustomerSalesRewardSuccess
} = CustomerSalesRewardSlice.actions

export default CustomerSalesRewardSlice.reducer
