import React, { Fragment, useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, Modal, ModalHeader, UncontrolledTooltip as Tooltip } from 'reactstrap'
import moment from 'moment'

const CompetitionOverview = () => {
  const [competitions, setCompetitions] = useState([])
  const [loading, setLoading] = useState(false)
  const [competitionFilterValue, setCompetitionFilterValue] = useState('active')
  const [modelOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    getCompetitions()
  }, [])

  function getCompetitions() {
    Proxies.GET('competitionv2/pool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setCompetitions(responseJSON)
      }
      setLoading(false)
    })
  }

  function RemoveCompetition(CompetitionID) {
    Proxies.POST('competitionv2/removeComp', { CompetitionID: Number(CompetitionID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getCompetitions()
      }
    })
  }

  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Konkurrencer
            <Button tag={Link} to={'competition/new'} style={{ marginLeft: '16px' }}>
              Opret ny
            </Button>
            <ButtonGroup className='float-right'>
              <Button
                outline
                color='success'
                onClick={() => setCompetitionFilterValue(competitionFilterValue === 'active' ? 'both' : 'active')}
                active={competitionFilterValue === 'active'}
              >
                Active
              </Button>
              <Button
                outline
                color='danger'
                onClick={() => setCompetitionFilterValue(competitionFilterValue === 'inactive' ? 'both' : 'inactive')}
                active={competitionFilterValue === 'inactive'}
              >
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              fieldFunctions: {
                startDate: e => {
                  return moment(e.startDate).format('DD-MM-YYYY')
                },
                endDate: e => {
                  return moment(e.endDate).format('DD-MM-YYYY')
                },
                numUsers: e => {
                  if (e.numDecUsers > 0) {
                    return `${e.numUsers}/${e.numDecUsers}`
                  } else {
                    return e.numUsers
                  }
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'view' + e.CompetitionID}>Vis</Tooltip>
                      <Button
                        tag={Link}
                        to={{ pathname: '/competition/' + e.CompetitionID + '/competitionUsers', state: e }}
                        id={'view' + e.CompetitionID}
                      >
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                      <Button
                        onClick={() => {
                          e.status !== 'READY' && setModalOpen(true)
                        }}
                        tag={Link}
                        to={e.status === 'READY' && '/competition/' + e.CompetitionID}
                        id={'edit' + e.CompetitionID}
                      >
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      <Button
                        id={'delete' + e.CompetitionID}
                        color='danger'
                        onClick={() => {
                          if (confirm('Are you sure you want to delete?')) {
                            RemoveCompetition(e.CompetitionID)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              CompetitionID: '#',
              title: 'Title',
              startDate: 'Aktiveringsdato',
              endDate: 'Deaktiveringsdato',
              status: 'Status',
              numUsers: 'Antal deltagere'
            }}
            data={
              competitions && competitionFilterValue !== 'both' && !!competitions.length
                ? competitions.filter(competition => competition.removed === (competitionFilterValue === 'inactive' ? 1 : 0))
                : competitions
            }
          />
        )}
      </CollapsibleCard>
      <Modal className='customization__modal' isOpen={modelOpen}>
        <ModalHeader toggle={() => setModalOpen(!modelOpen)}>Konkurrencen er allerede startet</ModalHeader>
      </Modal>
    </>
  )
}

export default CompetitionOverview
