import React, { Component } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input, FormGroup, Label, Col, Row } from 'reactstrap'
import _ from 'lodash'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { STATUS, getCustomerSalesRewardDetails, saveCustomerSalesReward, getCustomerProducts } from 'Reducers/actions/customerSalesRewardActions.js'
import { clearToast, createEmptyCustomerSalesReward, updateCustomerSalesRewardField } from 'Reducers/customerSalesRewardReducer'
import { connect } from 'react-redux'

class CustomerSalesRewardForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false,
      editMode: _.get(this.props, 'match.params.CustomerSalesRewardID') ? true : false
    }
  }

  componentDidMount() {
    this.props.getCustomerProducts()
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.CustomerSalesRewardID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {}, editMode: true })
      this.props.getCustomerSalesRewardDetails(this.props.match.params.CustomerSalesRewardID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.createEmptyCustomerSalesReward()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isPointsValid: this.props.customerSalesRewardDetails.rewardPoints || this.props.customerSalesRewardDetails.productRatio ? true : false,
      // isDescriptionValid: this.props.customerSalesRewardDetails.moduleDescription ? true : false,
      isCustomerProductUIDValid: this.props.customerSalesRewardDetails.CustomerProductID ? true : false
    }

    this.setState({ fieldValidation })
    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveCustomerSalesRewardForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      this.props.saveCustomerSalesReward(this.props.customerSalesRewardDetails)
    }
  }

  render() {
    if (this.props.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/customersalesrewards' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Produkt Belønning</strong>}>
          <Form>
            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='CompanyID'> Klient produkter </Label>
                </Col>
                <Col xs='9'>
                  {!this.state.editMode ? (
                    <Input
                      required
                      type='select'
                      name='CustomerProductID'
                      value={Number(this.props.customerSalesRewardDetails.CustomerProductID)}
                      onChange={e => {
                        this.props.updateCustomerSalesRewardField({ name: e.target.name, value: Number(e.target.value) })
                        this.validateForm()
                      }}
                    >
                      <option value=''>Click to Select</option>
                      {this.props.customerProducts.map((e, i) => {
                        return (
                          <option value={Number(e.CustomerProductID)} key={i}>
                            {e.CustomerProductID} - {e.moduleName}
                          </option>
                        )
                      })}
                    </Input>
                  ) : (
                    <span>
                      {this.props.customerSalesRewardDetails && this.props.customerSalesRewardDetails.customerProduct
                        ? `${this.props.customerSalesRewardDetails.customerProduct.moduleName} - ${this.props.customerSalesRewardDetails.customerProduct.productUID}`
                        : ''}
                    </span>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormField
              data={this.props.customerSalesRewardDetails}
              field={'rewardPoints'}
              type='number'
              name='Belønning point'
              min='0'
              step='1'
              onChange={e => {
                this.props.updateCustomerSalesRewardField({ name: e.target.name, value: Number(e.target.value) })
                this.props.updateCustomerSalesRewardField({ name: 'productRatio', value: null })
                this.validateForm()
              }}
            />
            <FormField
              data={this.props.customerSalesRewardDetails}
              field={'productRatio'}
              type='number'
              name='Product Ratio'
              onChange={e => {
                this.props.updateCustomerSalesRewardField({ name: e.target.name, value: e.target.value })
                this.props.updateCustomerSalesRewardField({ name: 'rewardPoints', value: 0 })
                this.validateForm()
              }}
            />
            <FormField
              className='zero-margin-left'
              name='Aktiv'
              field='isActive'
              data={this.props.customerSalesRewardDetails}
              onChange={e => {
                this.props.updateCustomerSalesRewardField({ name: e.target.name, value: Number(e.target.value) })
                this.validateForm()
              }}
              type='checkbox'
              checkedValue={Number(1)}
              unCheckedValue={Number(0)}
            />
            {this.state.editMode && (
              <Row>
                <Col xs='3'>
                  <Label for='CompanyID'> Created By </Label>
                </Col>
                <Col xs='9'>
                  <span>
                    {this.props.customerSalesRewardDetails && this.props.customerSalesRewardDetails.createdBy
                      ? this.props.customerSalesRewardDetails.createdBy.userName
                      : ''}
                  </span>
                </Col>
              </Row>
            )}
            {this.state.editMode && (
              <Row>
                <Col xs='3'>
                  <Label for='CompanyID'> Last Update By </Label>
                </Col>
                <Col xs='9'>
                  <span>
                    {this.props.customerSalesRewardDetails && this.props.customerSalesRewardDetails.lastUpdateBy
                      ? this.props.customerSalesRewardDetails.lastUpdateBy.userName
                      : ''}
                  </span>
                </Col>
              </Row>
            )}
            <Button onClick={this.saveCustomerSalesRewardForm} disabled={this.props.loadingState === STATUS.IS_SAVING}>
              {this.props.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  loadingState: state.customerSalesReward.loadingState,
  customerSalesRewardDetails: state.customerSalesReward.customerSalesRewardDetails,
  customerProducts: state.customerSalesReward.customerProducts
})

export default connect(mapStateToProps, {
  getCustomerSalesRewardDetails,
  clearToast,
  createEmptyCustomerSalesReward,
  updateCustomerSalesRewardField,
  saveCustomerSalesReward,
  getCustomerProducts
})(CustomerSalesRewardForm)
