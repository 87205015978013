import React from 'react'
import styled from 'styled-components'
import { Button, Row } from 'reactstrap'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'

const StyledFromDateDiv = styled.div`
    display: inline-block;
    float: left;
    padding: 5px 17px 5px 0px;
}
`
const StyledWrapper = styled.div`
  .react-datepicker {
    border: none !important;
  }
  .react-datepicker__header {
    background-color: white !important;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    margin-bottom: 10px !important;
  }
`
const StyledToDateDiv = styled.div`
    margin-left: 20px;
    display: inline-block;
    padding: 5px 17px 5px 0px;
}
`

const TimePicker = ({ startDate, endDate, setStartDate, setEndDate, getFilterDetails }) => {
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: 10,
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
        }}
      >
        {startDate ? (
          <StyledFromDateDiv>
            <p className='text-center'>
              <strong>Fra</strong>
            </p>
            <StyledWrapper>
              <ReactDatePicker
                showMonthDropdown
                fixedHeight
                showYearDropdown
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                onMonthChange={e => setStartDate(e.format())}
                onYearChange={e => setStartDate(e.format())}
                style={{ border: 'none' }}
                name='startDate'
                selected={moment(startDate)}
                inline
                onSelect={e => setStartDate(e.format())}
              />
            </StyledWrapper>
          </StyledFromDateDiv>
        ) : null}
        <StyledToDateDiv>
          <p className='text-center'>
            <strong>Til</strong>
          </p>
          <StyledWrapper>
            <ReactDatePicker
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              fixedHeight
              style={{ border: 'none' }}
              name='endDate'
              selected={moment(endDate)}
              inline
              onSelect={e => setEndDate(e.format())}
              onMonthChange={e => setEndDate(e.format())}
              onYearChange={e => setEndDate(e.format())}
            />
          </StyledWrapper>
        </StyledToDateDiv>
        <Row>
          <Button style={{ margin: '4px 25px' }} onClick={() => getFilterDetails()}>
            Hent Data
          </Button>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default TimePicker
