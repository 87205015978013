import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { getAnnouncements, STATUS, deleteAnnouncement } from 'Reducers/actions/announcementActions'
import { connect } from 'react-redux'
import { clearToast, toggleActiveAnnouncement, toggleInActiveAnnouncement } from 'Reducers/announcementReducer'
import moment from 'moment'

class AnnouncementsOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getAnnouncements()
  }

  componentDidUpdate() {
    if (this.props.overviewLoadingState === STATUS.DELETE_ANNOUNCEMENT_SUCCESS) {
      this.props.getAnnouncements()
    }
  }

  render() {
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Announcements
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={this.props.toggleActiveAnnouncement} active={this.props.showActiveAnnouncements}>
                Active
              </Button>
              <Button outline color='danger' onClick={this.props.toggleInActiveAnnouncement} active={this.props.showInActiveAnnouncements}>
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {this.props.overviewLoadingState === STATUS.LOADING_ANNOUNCEMENTS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'AnnouncementID'
              },
              fieldFunctions: {
                isActive: e => {
                  return e.isActive ? 'Y' : 'N'
                },
                timeStart: e => {
                  return moment(e.timeStart).format('DD-MM-YYYY')
                },
                timeEnd: e => {
                  return e.timeEnd ? moment(e.timeEnd).format('DD-MM-YYYY') : ''
                },
                brand: e => {
                  return e.brand ? e.brand.brandName : ''
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.AnnouncementID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/announcements/' + e.AnnouncementID} id={'edit' + e.AnnouncementID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'deactivate' + e.AnnouncementID}>Deaktivér</Tooltip>
                          <Button
                            id={'deactivate' + e.AnnouncementID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne Announcement?')) {
                                this.props.deleteAnnouncement({ AnnouncementID: e.AnnouncementID, removed: 1 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-check' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip target={'activate' + e.AnnouncementID}>Aktivér</Tooltip>
                          <Button
                            id={'activate' + e.AnnouncementID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du aktivere denne Announcement?')) {
                                this.props.deleteAnnouncement({ AnnouncementID: e.AnnouncementID, removed: 0 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              AnnouncementID: '#',
              brand: 'Brand',
              message: 'Message',
              messageType: 'Type',
              timeStart: 'Start Date',
              timeEnd: 'End Date',
              isActive: 'Active'
            }}
            data={
              this.props.announcements && this.props.announcementFilterValue !== 'both' && !!this.props.announcements.length
                ? this.props.announcements.filter(
                    announcement => announcement.removed === (this.props.announcementFilterValue === 'inactive' ? 1 : 0)
                  )
                : this.props.announcements
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({
  showInActiveAnnouncements: state.announcement.showInActiveAnnouncements,
  showActiveAnnouncements: state.announcement.showActiveAnnouncements,
  announcements: state.announcement.announcements,
  overviewLoadingState: state.announcement.overviewLoadingState,
  announcementFilterValue: state.announcement.announcementFilterValue
})

export default connect(mapStateToProps, { getAnnouncements, deleteAnnouncement, clearToast, toggleActiveAnnouncement, toggleInActiveAnnouncement })(
  AnnouncementsOverview
)
