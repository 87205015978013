import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Modal, ModalBody, ModalHeader } from 'reactstrap'

const CampaignUsers = props => {
  const { NotificationCampaignID } = props.match.params
  const [loading, setLoading] = useState(false)
  const [campaignUsers, setCampaignUsers] = useState([])
  const [userIDs, setUserIDs] = useState(false)
  const [emailPreviews, setEmailPreviews] = useState({ emailSubject: '', emailBody: { default: '' } })
  const [showModal, setShowModal] = useState(false)
  const [showPreviewForUser, setShowPreviewForUser] = useState('default')

  const fetchCampaignUsers = NotificationCampaignID => {
    setLoading(true)
    Proxies.GET('/notificationcampaign/report', { NotificationCampaignID }).then(responseJSON => {
      setCampaignUsers(responseJSON.error || !responseJSON.length ? [] : responseJSON[0].users)
      setLoading(false)
    })
  }

  const fetchEmailPreviews = (UserIDs = []) => {
    Proxies.POST('/notificationcampaign/prepareCampaignEmailPreview', { NotificationCampaignID, UserIDs }).then(responseJSON => {
      if (responseJSON.error) return
      setEmailPreviews(responseJSON)
    })
  }

  useEffect(() => {
    fetchCampaignUsers(NotificationCampaignID)
  }, [NotificationCampaignID])

  useEffect(() => {
    const allUsers = campaignUsers.map(item => item.UserID)
    setUserIDs(allUsers)
  }, [campaignUsers])

  useEffect(() => {
    fetchEmailPreviews(userIDs)
  }, [userIDs])

  return (
    <>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)} size='xl '>
        <ModalHeader toggle={() => setShowModal(false)}>Email subject: {emailPreviews.emailSubject || 'Email Preview'}</ModalHeader>
        <ModalBody style={{ padding: 0 }}>
          <div dangerouslySetInnerHTML={{ __html: emailPreviews.emailBody[showPreviewForUser] || emailPreviews.emailBody.default || '' }}></div>
        </ModalBody>
      </Modal>
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Kampagnen bruggere
            <Button tag={Link} to={'/campaign'} style={{ marginLeft: '16px', float: 'right' }}>
              <FontAwesomeIcon icon='arrow-left' /> Tilbage til oversigt
            </Button>
          </strong>
        }
      >
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'preview' + e.UserID}>Preview</Tooltip>
                      <Button
                        onClick={() => {
                          setShowModal(true)
                          setShowPreviewForUser(e.UserID)
                        }}
                        id={'preview' + e.UserID}
                      >
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              UserID: 'User ID',
              userName: 'User Name',
              userEmail: 'Kontaktinformation',
              userPhone: 'User Mobile'
            }}
            data={campaignUsers}
          />
        )}
      </CollapsibleCard>
    </>
  )
}

export default CampaignUsers
