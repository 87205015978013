import Proxies from 'Components/proxies'
import {
  fetchCustomerProductsRequest,
  fetchCustomerProductsFailed,
  fetchCustomerProductsSuccess,
  fetchCustomerProductDetailsRequest,
  fetchCustomerProductDetailsFailed,
  fetchCustomerProductDetailsSuccess,
  customerProductCreationSuccess,
  customerProductCreationFailure,
  isSavingCustomerProduct,
  removeCustomerProductFailure,
  removeCustomerProductSuccess
} from 'Reducers/customerProductReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  FETCHING_CUSTOMER_PRODUCTS: null,
  FETCHING_CUSTOMER_PRODUCTS_SUCCESS: null,
  FETCHING_CUSTOMER_PRODUCTS_FAILED: null,
  FETCHING_CUSTOMER_PRODUCT_DETAILS: null,
  FETCHING_CUSTOMER_PRODUCT_DETAILS_FAILED: null,
  FETCHING_CUSTOMER_PRODUCT_DETAILS_SUCCESS: null,
  CUSTOMER_PRODUCT_SUCCESS: null,
  CUSTOMER_PRODUCT_FAILED: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  DELETE_CUSTOMER_PRODUCT_FAILED: null,
  DELETE_CUSTOMER_PRODUCT_SUCCESS: null
})

export const getCustomerProducts = data => dispatch => {
  dispatch(fetchCustomerProductsRequest())
  Proxies.GET('customerproducts/pool', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchCustomerProductsFailed())
    } else {
      dispatch(fetchCustomerProductsSuccess(responseJSON))
    }
  })
}

export const getCustomerProductDetails = CustomerProductID => dispatch => {
  dispatch(fetchCustomerProductDetailsRequest())
  Proxies.GET('customerproducts/fetch', { CustomerProductID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchCustomerProductDetailsFailed())
    } else {
      dispatch(fetchCustomerProductDetailsSuccess(responseJSON))
    }
  })
}

export const saveCustomerProduct = data => dispatch => {
  dispatch(isSavingCustomerProduct())
  Proxies.POST('customerproducts/replace', { ...data }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(customerProductCreationFailure())
    } else {
      dispatch(customerProductCreationSuccess(responseJSON))
    }
  })
}

export const deleteCustomerProduct = data => dispatch => {
  dispatch(isSavingCustomerProduct())
  Proxies.POST('customerproducts/remove', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(removeCustomerProductFailure())
    } else {
      dispatch(removeCustomerProductSuccess())
    }
  })
}
