import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { AccessActions, AccessStore, STATE } from 'Stores/accessStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

export default class ValidationCodeList extends Component {
  constructor(props) {
    super(props)
    this.stores = [AccessStore]
    this.storeKeys = ['loadingState', 'codes']
  }

  componentDidMount() {
    AccessActions.getValidationCodes()
  }

  componentDidUpdate() {
    if (this.state.loadingState === STATE.FETCHING_VALIDATION_CODES_FAILED) {
      toast.error('FETCHING VALIDATION CODES FAILED', {
        autoClose: 7500,
        onClose: () => {
          AccessActions.clearToast()
        }
      })
      AccessActions.clearToast()
    }
  }

  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Access</strong>}>
        {this.state.loadingState === STATE.FETCHING_VALIDATION_CODES ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            data={this.state.codes}
            options={{
              startSort: {
                dir: 'DESC',
                column: 'AccessID'
              },
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD HH:mm:ss') : ''
                }
              },
              fieldStringFunctions: {
                timeInsert: e => {
                  return moment(e.timeInsert).format('x')
                }
              }
            }}
          />
        )}
      </CollapsibleCard>
    )
  }
}
