import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { createEmptyCustomerSalesReward, clearToast } from 'Reducers/customerSalesRewardReducer'
import { STATUS } from 'Reducers/actions/customerSalesRewardActions'
import { connect } from 'react-redux'
import CustomerSalesRewardForm from 'Modules/customerSalesReward/customerSalesRewardForm'

class CustomerSalesRewardCreate extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.createEmptyCustomerSalesReward()
  }
  componentDidUpdate() {
    if (this.props.loadingState === STATUS.CUSTOMER_SALES_REWARD_SUCCESS) {
      toast.success('Successfully Created', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast(STATUS.IS_REDIRECT)
    }
    if (this.props.loadingState === STATUS.CUSTOMER_SALES_REWARD_FAILED) {
      toast.error('Error while saving CustomerSalesReward', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <CustomerSalesRewardForm {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ loadingState: state.customerSalesReward.loadingState })

export default connect(mapStateToProps, { createEmptyCustomerSalesReward, clearToast })(CustomerSalesRewardCreate)
