import Proxies from 'Components/proxies'
const { POST } = Proxies
import { setReportsData, resetUserReportsData } from 'Reducers/reportsReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  FETCHING_USER_REPORTS_SUCCESS: null,
  FETCHING_USER_REPORTS_FAILED: null
})

export const getUserReports = data => dispatch => {
  POST('user/usersMonthlyReport', { BrandID: Number(data.BrandID), year: data.year }).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetUserReportsData())
      } else {
        dispatch(setReportsData(responseJSON.monthlyUsers))
      }
    }
  })
}
