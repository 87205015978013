import React from 'react'
import { Card, CardHeader, CardBody, Collapse, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class CollapsibleCard extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    let cardCollapsed = true
    if (props.startOpen) {
      cardCollapsed = false
    }
    this.state = {
      cardCollapsed: cardCollapsed,
      tooltipOpen: false
    }
  }
  toggleCard(callback) {
    let currentCardState = this.state.cardCollapsed
    this.setState({ cardCollapsed: !currentCardState })
    callback(currentCardState)
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }
  render() {
    let { cardProps, headerProps, iconProps, bodyProps, onCardOpen } = this.props
    if (!onCardOpen) {
      onCardOpen = () => {
        return false
      }
    }
    return (
      <Card {...cardProps}>
        <CardHeader {...headerProps}>
          <UncontrolledTooltip isOpen={this.state.tooltipOpen} target='foldable' toggle={this.toggle}>
            Fold ud/luk
          </UncontrolledTooltip>
          <FontAwesomeIcon
            {...iconProps}
            id='foldable'
            className='mr-3'
            fixedWidth
            icon={this.state.cardCollapsed ? 'folder' : 'folder-open'}
            onClick={() => {
              this.toggleCard(onCardOpen)
              if (this.props && this.props.onClick) {
                this.props.onClick()
              }
            }}
          />
          {this.props.header}
        </CardHeader>
        <Collapse isOpen={!this.state.cardCollapsed}>
          <CardBody {...bodyProps}>{this.props.children}</CardBody>
        </Collapse>
      </Card>
    )
  }
}
