import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from 'reactstrap'

const FontList = () => {
  const [fonts, setFonts] = useState([])
  const [loading, setLoading] = useState(false)
  const [fontFilterValue, setFontFilterValue] = useState('active')

  useEffect(() => {
    setLoading(true)
    getFonts()
  }, [])

  function getFonts() {
    Proxies.GET('/Font/pool').then(responseJSON => {
      if (!responseJSON.error) {
        setFonts(responseJSON)
      }
      setLoading(false)
    })
  }

  function RemoveFont(FontID) {
    Proxies.POST('Font/removeFont', { FontID }).then(responseJSON => {
      if (!responseJSON.error) {
        getFonts()
      }
    })
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Skrifttyper
          <Button tag={Link} to={'font/new'} style={{ marginLeft: '16px' }}>
            Opret ny
          </Button>
          <ButtonGroup className='float-right'>
            <Button
              outline
              color='success'
              onClick={() => setFontFilterValue(fontFilterValue === 'active' ? 'both' : 'active')}
              active={fontFilterValue === 'active'}
            >
              Active
            </Button>
            <Button
              outline
              color='danger'
              onClick={() => setFontFilterValue(fontFilterValue === 'inactive' ? 'both' : 'inactive')}
              active={fontFilterValue === 'inactive'}
            >
              In-Active
            </Button>
          </ButtonGroup>
        </strong>
      }
    >
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'name'
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Button tag={Link} to={'/font/' + e.FontID} id={'edit' + e.FontID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Button
                      id={'delete' + e.FontID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Are you sure you want to delete?')) {
                          RemoveFont(e.FontID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            FontID: '#',
            fontName: 'Skrifttypenavn'
          }}
          data={
            fonts && fontFilterValue !== 'both' && !!fonts.length
              ? fonts.filter(font => font.removed === (fontFilterValue === 'inactive' ? 1 : 0))
              : fonts
          }
        />
      )}
    </CollapsibleCard>
  )
}

export default FontList
