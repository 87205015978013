import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, FormGroup, Row, Col, Label, Input } from 'reactstrap'
import _ from 'lodash'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { STATUS, getAnnouncementDetails, saveAnnouncement } from 'Reducers/actions/announcementActions'
import { clearToast, createEmptyAnnouncement, updateAnnouncementField } from 'Reducers/announcementReducer'
import { connect } from 'react-redux'

class AnnouncementForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.AnnouncementID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.getAnnouncementDetails(this.props.match.params.AnnouncementID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.createEmptyAnnouncement()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isMessageValid: this.props.announcementDetails.message && this.props.announcementDetails.message.length <= 256 ? true : false
    }

    this.setState({ fieldValidation })
    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveAnnouncementForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      this.props.saveAnnouncement(this.props.announcementDetails)
    }
  }

  render() {
    if (this.props.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/announcements' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Announcement</strong>}>
          <Form>
            <FormGroup>
              {this.props.BrandID === 9 && (
                <Row>
                  <Col xs='3'>
                    <Label for='BrandID'> Brand ID </Label>
                  </Col>
                  <Col xs='6'>
                    <Input
                      className={this.props.announcementDetails.BrandID ? 'is-valid' : 'is-invalid'}
                      required
                      type='select'
                      name='BrandID'
                      value={this.props.announcementDetails.BrandID ? Number(this.props.announcementDetails.BrandID) : ''}
                      id='BrandID'
                      onChange={e => {
                        this.props.updateAnnouncementField({ name: e.target.name, value: e.target.value })
                        this.validateForm()
                      }}
                    >
                      <option value=''>Click to Select</option>
                      {this.props.brands.map((e, i) => {
                        return (
                          <option value={Number(e.BrandID)} key={i}>
                            {e.BrandID} - {e.brandName}
                          </option>
                        )
                      })}
                    </Input>
                  </Col>
                </Row>
              )}
            </FormGroup>
            <FormField
              required
              data={this.props.announcementDetails}
              field={'message'}
              name='message'
              type='textarea'
              onChange={e => {
                this.props.updateAnnouncementField({ name: e.target.name, value: e.target.value })
                this.validateForm()
              }}
            />
            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='messageType'> Type </Label>
                </Col>
                <Col xs='3'>
                  <Input
                    type='select'
                    name='messageType'
                    value={this.props.announcementDetails.messageType ? this.props.announcementDetails.messageType : 'Info'}
                    id='messageType'
                    onChange={e => {
                      this.props.updateAnnouncementField({ name: e.target.name, value: e.target.value })
                    }}
                  >
                    <option value='Info'>Info</option>
                    <option value='Warning'>Warning</option>
                    <option value='System'>System</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormField
              name='Active'
              field='isActive'
              data={this.props.announcementDetails}
              onChange={e => {
                this.props.updateAnnouncementField({ name: e.target.name, value: e.target.value })
              }}
              type='checkbox'
              checkedValue='1'
              unCheckedValue='0'
            />
            <FormField
              name='Vises fra'
              field='timeStart'
              type='date'
              data={this.props.announcementDetails}
              onChange={e => {
                this.props.updateAnnouncementField({ name: e.target.name, value: e.target.value })
                this.validateForm()
              }}
            />
            <FormField
              name='Vises til'
              field='timeEnd'
              type='date'
              data={this.props.announcementDetails}
              onChange={e => {
                this.props.updateAnnouncementField({ name: e.target.name, value: e.target.value })
                this.validateForm()
              }}
            />
            <Button onClick={this.saveAnnouncementForm} disabled={this.props.loadingState === STATUS.IS_SAVING}>
              {this.props.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  loadingState: state.announcement.loadingState,
  announcementDetails: state.announcement.announcementDetails,
  brands: state.announcement.brands,
  BrandID: state.login.settings.BrandID
})

export default connect(mapStateToProps, { getAnnouncementDetails, clearToast, createEmptyAnnouncement, updateAnnouncementField, saveAnnouncement })(
  AnnouncementForm
)
