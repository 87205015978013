import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { createEmptyCustomerProduct, clearToast } from 'Reducers/customerProductReducer'
import { STATUS } from 'Reducers/actions/customerProductActions'
import { connect } from 'react-redux'
import CustomerProductForm from 'Modules/customerProduct/customerProductForm'

class CustomerProductCreate extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.createEmptyCustomerProduct()
  }
  componentDidUpdate() {
    if (this.props.loadingState === STATUS.CUSTOMER_PRODUCT_SUCCESS) {
      toast.success('Successfully Created', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast(STATUS.IS_REDIRECT)
    }
    if (this.props.loadingState === STATUS.CUSTOMER_PRODUCT_FAILED) {
      toast.error('Error while saving CustomerProduct', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <CustomerProductForm {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ loadingState: state.customerProduct.loadingState })

export default connect(mapStateToProps, { createEmptyCustomerProduct, clearToast })(CustomerProductCreate)
