import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup } from 'reactstrap'
import { Link } from 'react-router-dom'

function PointExpireOverview() {
  const [pointExpireList, setPointExpireList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getPointExpireList()
  }, [])

  function getPointExpireList() {
    Proxies.GET('user/pointExpireUser').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setPointExpireList(responseJSON)
      }
      setLoading(false)
    })
  }

  return (
    <>
      <CollapsibleCard startOpen header={<strong>Point Expire Overview</strong>}>
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Button tag={Link} to={'/pointExpire/' + e.UserID + '/communication'} id={'view' + e.UserID}>
                        <FontAwesomeIcon icon='envelope' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              UserID: 'ID',
              userName: 'Navn',
              userUID: 'Uid',
              userPhone: 'Telefon',
              userEmail: 'Email',
              expiringOneMonth: 'Denne måned',
              expiringTwoMonth: 'Næste måned',
              expiringThreeMonth: '3 måneder'
            }}
            data={pointExpireList && !!pointExpireList.length && pointExpireList}
          />
        )}
      </CollapsibleCard>
    </>
  )
}

export default PointExpireOverview
