import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { AddressActions, AddressStore, STATUS } from 'Stores/addressStore'
import AddressForm from 'Modules/addresses/addressForm'

export default class AddressEdit extends Component {
  constructor(props) {
    super(props)
    this.stores = [AddressStore]
    this.storeKeys = ['loadingState']
  }
  componentDidMount() {
    AddressActions.getAddressDetails(this.props.match.params.AddressID)
  }
  componentDidUpdate() {
    if (this.state.loadingState === STATUS.ADDRESS_SUCCESS) {
      toast.success('Address Updated', {
        autoClose: 3000,
        onClose: () => {
          AddressActions.clearToast()
        }
      })
      AddressActions.clearToast(STATUS.IS_REDIRECT)
    }
    if (this.state.loadingState === STATUS.ADDRESS_FAILED) {
      toast.error('Address Update Failed', {
        autoClose: 3000,
        onClose: () => {
          AddressActions.clearToast()
        }
      })
      AddressActions.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <AddressForm match={this.props.match} />
      </React.Fragment>
    )
  }
}
