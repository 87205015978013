import { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import React from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Input } from 'reactstrap'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'

export default function DepartmentForm(props) {
  const [loading, setLoading] = useState(false)
  const { DepartmentID } = props.match.params
  const [departmentName, setDepartmentName] = useState('')
  const [departmentUID, setDepartmentUID] = useState('')
  const [selectedVault, setSelectedVault] = useState('')
  const [department, setDepartment] = useState({})
  const [status, setStatus] = useState('LOADING')
  const [vaults, setVaults] = useState([])
  const [supervisors, setSupervisors] = useState([])
  const [selectedSupervisor, setSelectedSupervisor] = useState('')

  function resetData() {
    setDepartmentName(department.departmentName ? department.departmentName : '')
    setDepartmentUID(department.departmentUID ? department.departmentUID : '')
  }

  function saveDepartment() {
    let supervisor = supervisors.find(i => i.id === selectedSupervisor)
    let vault = vaults.find(i => i.VaultID === parseInt(selectedVault))
    Proxies.POST('departments/replace', {
      DepartmentID: department.DepartmentID,
      departmentName,
      departmentUID,
      supervisorName: supervisor ? supervisor.userSupervisor : undefined,
      supervisorUID: supervisor ? supervisor.userSupervisorUID : undefined,
      vaultName: vault ? vault.vaultName : undefined,
      VaultID: vault ? vault.VaultID : undefined
    }).then(responseJSON => {
      if (responseJSON.error) {
        setStatus('FAILED')
        toast.error('Error while saving department', {
          autoClose: 5000,
          onClose: () => {
            setStatus('LOADING')
          }
        })
      } else {
        setStatus('SUCCESS')
        toast.success(DepartmentID ? 'Successfully Updated' : 'Successfully Created', {
          autoClose: 5000,
          onClose: () => {
            setStatus('LOADING')
          }
        })
      }
    })
  }

  useEffect(() => {
    if (DepartmentID) {
      setLoading(true)
      Proxies.GET('departments/fetch', { DepartmentID }).then(responseJSON => {
        if (responseJSON.error) {
          // console.log(responseJSON.error)
        } else {
          setDepartment(responseJSON)
          setDepartmentName(responseJSON.departmentName)
          setDepartmentUID(responseJSON.departmentUID)
        }
        setLoading(false)
      })
    }

    Proxies.GET('vaults/pool').then(responseJSON => {
      if (responseJSON) {
        setVaults(responseJSON)
      }
    })
    Proxies.GET('supervisors/brandSupervisors').then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        responseJSON = responseJSON.map(item => {
          item.id = item.userSupervisor.replaceAll(' ', '') + item.userSupervisorUID
          return item
        })
        setSupervisors(responseJSON)
      }
    })
  }, [])

  useEffect(() => {
    if (department.VaultID) {
      setSelectedVault(department.VaultID)
    }
  }, [vaults])

  useEffect(() => {
    if (department.supervisorUID) {
      setSelectedSupervisor(`${department.supervisorName.replaceAll(' ', '')}${department.supervisorUID}`)
    }
  }, [supervisors])

  if (status === 'SUCCESS') {
    return <Redirect to='/department' />
  }
  return (
    <CollapsibleCard startOpen={true} header={<strong>Department</strong>}>
      <Form>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Department Name</label>
          </Col>
          <Col xs='10'>
            <Input type='text' value={departmentName} name='departmentName' id='departmentName' onChange={e => setDepartmentName(e.target.value)} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Department UID</label>
          </Col>
          <Col xs='10'>
            <Input type='text' value={departmentUID} name='departmentUID' id='departmentUID' onChange={e => setDepartmentUID(e.target.value)} />
          </Col>
        </Row>
        {supervisors.length ? (
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Supervisor</label>
            </Col>
            <Col xs='10'>
              <Input type='select' value={selectedSupervisor} name='supervisor' id='supervisor' onChange={e => setSelectedSupervisor(e.target.value)}>
                <option value={''} disabled>
                  Select supervisor
                </option>
                {supervisors.map((supervisor, index) => {
                  return (
                    <option key={index} value={supervisor.id}>
                      {supervisor.userSupervisor} ({supervisor.userSupervisorUID})
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
        ) : null}
        {vaults.length ? (
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Vault</label>
            </Col>
            <Col xs='10'>
              <Input type='select' value={selectedVault} name='vaultName' id='vaultName' onChange={e => setSelectedVault(Number(e.target.value))}>
                <option value={''} disabled>
                  Select vault
                </option>
                {vaults.map(vault => {
                  return (
                    <option key={vault.VaultID} value={vault.VaultID}>
                      {vault.vaultName}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </Row>
        ) : null}
        <Button onClick={saveDepartment} disabled={loading}>
          {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button department='danger' onClick={resetData} className='ml-2'>
          Nulstil
        </Button>
      </Form>
    </CollapsibleCard>
  )
}
