import React from 'react'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import BarChart from 'Components/dashboard/charts/BarChart.js'
import styled from 'styled-components'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import _ from 'lodash'

const BarChartWithSpacer = styled(BarChart)`
  margin-top: 28px; // Spacer while what the dashboard is settles, maybe a better solution is needed later
`

export default function PointsUsedYearlyTotals() {
  const [data, loadingState] = useDashboardData('reports/wallet-points-spent')

  return (
    <DashboardComponent header={'Brugte point pr. år'} state={loadingState}>
      {loadingState === DASHBOARD_COMPONENT_STATE.SUCCESS && (
        <BarChartWithSpacer
          data={_.map(_.get(data, 'yearlyResults'), ({ year, spent }, index) => ({
            year: index === 0 ? `År til dato ${year}` : `Ultimo ${year}`,
            Brugte: spent
          }))}
          keys={['Brugte']}
          indexBy={'year'}
          bottomTickRotation={-20}
          legends={[]}
        />
      )}
    </DashboardComponent>
  )
}
