import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'

function CPROverview() {
  const [cprList, setCPRList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getCPRList()
  }, [])

  function getCPRList() {
    Proxies.GET('/user/getUsersWithCPRInfo').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setCPRList(responseJSON)
      }
      setLoading(false)
    })
  }

  function ResetCPR(UserID) {
    Proxies.POST('/user/resetUserCPR', { UserID: Number(UserID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        toast.success('CPR reset successful', {
          autoClose: 5000
        })
        getCPRList()
      }
    })
  }

  return (
    <>
      <CollapsibleCard startOpen header={<strong>CPR Overview</strong>}>
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              fieldFunctions: {
                cprExists: e => {
                  return e.cprExists === 1 ? 'Y' : 'N'
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'reset' + e.UserID}>Reset</Tooltip>
                      <Button
                        id={'reset' + e.UserID}
                        onClick={() => {
                          if (confirm('Are you sure you want to reset?')) {
                            ResetCPR(e.UserID)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='retweet' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              UserID: 'ID',
              userName: 'Navn',
              userCompany: 'Virksomhed',
              userUID: 'Uid',
              userPhone: 'Telefon',
              userEmail: 'Email',
              userAccess: 'Adgang',
              cprExists: 'CPR'
            }}
            data={cprList && !!cprList.length && cprList}
          />
        )}
      </CollapsibleCard>
    </>
  )
}

export default CPROverview
