import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input } from 'reactstrap'
import _ from 'lodash'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { STATUS, getCustomerProductDetails, saveCustomerProduct } from 'Reducers/actions/customerProductActions'
import { clearToast, createEmptyCustomerProduct, updateCustomerProductField } from 'Reducers/customerProductReducer'
import { connect } from 'react-redux'
import Proxies from 'Components/proxies'

class customerProductForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false,
      smartgroups: []
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.CustomerProductID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.getCustomerProductDetails(this.props.match.params.CustomerProductID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      this.props.createEmptyCustomerProduct()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.props.customerProductDetails.moduleName ? true : false,
      isDescriptionValid: this.props.customerProductDetails.moduleDescription ? true : false,
      isProductUIDValid: this.props.customerProductDetails.productUID ? true : false
    }

    this.setState({ fieldValidation })
    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveCustomerProductForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      this.props.saveCustomerProduct(this.props.customerProductDetails)
    }
  }

  componentDidMount() {
    Proxies.GET('groups').then(responseJSON => {
      if (responseJSON && responseJSON.length) {
        this.setState({ smartgroups: responseJSON })
      }
    })
  }

  render() {
    if (this.props.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/customerproducts' />
    }

    let groups = []
    const GroupsList = this.state.smartgroups
    let Groups = this.props.customerProductDetails.groups ? this.props.customerProductDetails.groups.concat([]) : []
    if (GroupsList && GroupsList.length) {
      GroupsList.forEach((e, i) => {
        let isCheckedData = Groups.find(s => {
          return s.GroupID === e.GroupID
        })
        let isChecked = isCheckedData !== undefined
        if (isChecked) {
          Groups = Groups.filter(s => {
            return s.GroupID !== e.GroupID
          })
        }
        groups.push(
          <React.Fragment key={i}>
            <Input
              type='checkbox'
              checked={isChecked}
              name={e.GroupID}
              onChange={checkbox => {
                let GroupID = Number(checkbox.target.name)
                let checked = checkbox.target.checked
                let GroupsArray
                if (checked) {
                  let thisGroup = this.state.smartgroups.find(e => {
                    return e.GroupID === GroupID
                  })
                  if (this.props.customerProductDetails.groups) {
                    GroupsArray = [...this.props.customerProductDetails.groups, thisGroup]
                  } else {
                    GroupsArray = [thisGroup]
                  }
                } else {
                  GroupsArray = this.props.customerProductDetails.groups.filter(e => {
                    return e.GroupID !== GroupID
                  })
                }
                this.props.updateCustomerProductField({ name: 'groups', value: GroupsArray })
              }}
              label={e.moduleName}
            />{' '}
            {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    if (Groups.length > 0) {
      groups.push(
        <React.Fragment key={'overflow'}>
          Følgende grupper skal gendannes via databasen hvis de slettes
          <br />
        </React.Fragment>
      )
      Groups.forEach((e, i) => {
        groups.push(
          <React.Fragment key={'overflow' + i}>
            <Input
              type='checkbox'
              checked={true}
              name={e.GroupID}
              onChange={checkbox => {
                let GroupID = checkbox.target.name
                let checked = checkbox.target.checked
                let GroupsArray
                if (checked) {
                  let thisGroup = this.state.smartgroups.find(e => {
                    return e.GroupID === GroupID
                  })
                  if (this.props.customerProductDetails.groups) {
                    GroupsArray = [...this.props.customerProductDetails.groups, thisGroup]
                  } else {
                    GroupsArray = [thisGroup]
                  }
                } else {
                  GroupsArray = this.props.customerProductDetails.groups.filter(e => {
                    return e.GroupID !== GroupID
                  })
                }
                this.props.updateCustomerProductField({ name: 'groups', value: GroupsArray })
              }}
              label={e.moduleName}
            />{' '}
            {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }

    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Klient Produkt</strong>}>
          <Form>
            <FormField
              required
              data={this.props.customerProductDetails}
              field={'moduleName'}
              name='Navn'
              onChange={e => {
                this.props.updateCustomerProductField({ name: e.target.name, value: e.target.value })
                this.validateForm()
              }}
            />
            <FormField
              required
              data={this.props.customerProductDetails}
              field={'moduleDescription'}
              name='Beskrivelse'
              onChange={e => {
                this.props.updateCustomerProductField({ name: e.target.name, value: e.target.value })
                this.validateForm()
              }}
            />
            <FormField
              data={this.props.customerProductDetails}
              required
              type='number'
              field={'productUID'}
              name='ProduktUID'
              onChange={e => {
                this.props.updateCustomerProductField({ name: e.target.name, value: Number(e.target.value) })
              }}
            />
            <FormField name='Grupper' field='groups' data={{}}>
              {groups}
            </FormField>
            <Button onClick={this.saveCustomerProductForm} disabled={this.props.loadingState === STATUS.IS_SAVING}>
              {this.props.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  loadingState: state.customerProduct.loadingState,
  customerProductDetails: state.customerProduct.customerProductDetails
})

export default connect(mapStateToProps, {
  getCustomerProductDetails,
  clearToast,
  createEmptyCustomerProduct,
  updateCustomerProductField,
  saveCustomerProduct
})(customerProductForm)
