import Proxies from 'Components/proxies'
import {
  fetchCustomerSalesRewardsRequest,
  fetchCustomerSalesRewardsFailed,
  fetchCustomerSalesRewardsSuccess,
  fetchCustomerSalesRewardDetailsRequest,
  fetchCustomerSalesRewardDetailsFailed,
  fetchCustomerSalesRewardDetailsSuccess,
  CustomerSalesRewardCreationSuccess,
  CustomerSalesRewardCreationFailure,
  isSavingCustomerSalesReward,
  removeCustomerSalesRewardFailure,
  removeCustomerSalesRewardSuccess,
  fetchCustomerProductsSuccess
} from 'Reducers/customerSalesRewardReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  FETCHING_CUSTOMER_SALES_REWARDS: null,
  FETCHING_CUSTOMER_SALES_REWARDS_SUCCESS: null,
  FETCHING_CUSTOMER_SALES_REWARDS_FAILED: null,
  FETCHING_CUSTOMER_SALES_REWARD_DETAILS: null,
  FETCHING_CUSTOMER_SALES_REWARD_DETAILS_FAILED: null,
  FETCHING_CUSTOMER_SALES_REWARD_DETAILS_SUCCESS: null,
  CUSTOMER_SALES_REWARD_SUCCESS: null,
  CUSTOMER_SALES_REWARD_FAILED: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  DELETE_CUSTOMER_SALES_REWARD_FAILED: null,
  DELETE_CUSTOMER_SALES_REWARD_SUCCESS: null,
  FETCHING_CUSTOMER_PRODUCTS_SUCCESS: null
})

export const getCustomerSalesRewards = data => dispatch => {
  dispatch(fetchCustomerSalesRewardsRequest())
  Proxies.GET('CustomerSalesRewards/pool', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchCustomerSalesRewardsFailed())
    } else {
      dispatch(fetchCustomerSalesRewardsSuccess(responseJSON))
    }
  })
}

export const getCustomerProducts = data => dispatch => {
  // dispatch(fetchCustomerSalesRewardsRequest())
  Proxies.GET('CustomerSalesRewards/products', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchCustomerSalesRewardsFailed())
    } else {
      dispatch(fetchCustomerProductsSuccess(responseJSON))
    }
  })
}

export const getCustomerSalesRewardDetails = CustomerSalesRewardID => dispatch => {
  dispatch(fetchCustomerSalesRewardDetailsRequest())
  Proxies.GET('CustomerSalesRewards/fetch', { CustomerSalesRewardID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchCustomerSalesRewardDetailsFailed())
    } else {
      dispatch(fetchCustomerSalesRewardDetailsSuccess(responseJSON))
    }
  })
}

export const saveCustomerSalesReward = data => dispatch => {
  dispatch(isSavingCustomerSalesReward())
  Proxies.POST('CustomerSalesRewards/replace', { ...data }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(CustomerSalesRewardCreationFailure())
    } else {
      dispatch(CustomerSalesRewardCreationSuccess(responseJSON))
    }
  })
}

export const deleteCustomerSalesReward = data => dispatch => {
  dispatch(isSavingCustomerSalesReward())
  Proxies.POST('CustomerSalesRewards/remove', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(removeCustomerSalesRewardFailure())
    } else {
      dispatch(removeCustomerSalesRewardSuccess())
    }
  })
}
