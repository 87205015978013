import React, { useEffect } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { STATUS, getUserReports } from 'Reducers/actions/reportsActions'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Input } from 'reactstrap'
import FormField from 'Components/formField'
import { resetReportsData, clearToast, updateReportsContent } from 'Reducers/reportsReducer'
import { toast } from 'react-toastify'
import { getBrands } from 'Reducers/actions/announcementActions'
import DynamicTable from 'Components/dynamicTable'

export default class UserReports extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <UserReportsComponent />
      </React.Fragment>
    )
  }
}

function UserReportsComponent() {
  const dispatch = useDispatch()
  const reportDetails = useSelector(state => state.reports.reportDetails)
  const userReports = useSelector(state => state.reports.userReports)
  const loadingState = useSelector(state => state.reports.loadingState)
  const brands = useSelector(state => state.announcement.brands)

  useEffect(() => {
    dispatch(resetReportsData())
    dispatch(getBrands())
    return () => {
      dispatch(resetReportsData())
    }
  }, [])

  useEffect(() => {
    if (loadingState === STATUS.FETCHING_USER_REPORTS_FAILED) {
      toast.error('Error while fetching User Reports', {
        autoClose: 7500,
        onClose: () => {
          dispatch(clearToast())
        }
      })
      dispatch(clearToast())
    }
  }, [loadingState])

  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>User Reports</strong>{' '}
          </React.Fragment>
        }
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField field={'BrandID'} name='Brand'>
            <Input
              type='select'
              name='BrandID'
              value={reportDetails.BrandID}
              onChange={e => {
                dispatch(updateReportsContent({ name: e.target.name, value: e.target.value }))
              }}
            >
              <option selected='true' disabled={true} value={''}>
                Select Brand
              </option>
              {brands &&
                !!brands.length &&
                brands.map(e => {
                  return (
                    <option value={e.BrandID} key={e.BrandID}>
                      {e.BrandID} - {e.brandName}
                    </option>
                  )
                })}
            </Input>
          </FormField>
          <FormField
            name='year'
            rows={5}
            onChange={e => {
              dispatch(updateReportsContent({ name: e.target.name, value: e.target.value }))
            }}
            field='year'
            data={reportDetails}
          />
        </Form>
        <Button
          onClick={() => {
            dispatch(getUserReports(reportDetails))
          }}
        >
          Search
        </Button>
        <div style={{ marginTop: '40px' }}>{userReports && <DynamicTable responsive size='sm' data={userReports} />}</div>
      </CollapsibleCard>
    </>
  )
}
