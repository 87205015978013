import React, { useEffect, useState } from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Row } from 'reactstrap'
import Styled from 'styled-components'
import XLSX from 'xlsx'
import moment from 'moment'
import { STATUS, getBatchElements, getBatchDetails, insertBatch } from 'Reducers/actions/dataImportActions'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { resetBatchInsertStates } from 'Reducers/dataImportReducer'
import _ from 'lodash'

const StyledError = Styled(Row)`
color: ${props => (props && props.isWarning ? '#E08A1E' : 'red')};
margin-left: 0px !important;
margin-right: 0px !important;`

export default class ImportsBatchPreview extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <BatchPreview props={this.props} />
      </React.Fragment>
    )
  }
}

function BatchPreview({ props }) {
  const dispatch = useDispatch()
  const batchElements = useSelector(state => state.dataImport.batchElements)
  const loadingStatus = useSelector(state => state.dataImport.fetchingBatchElements)
  const batchInsertStatus = useSelector(state => state.dataImport.batchInsertStatus)
  const batchInsertError = useSelector(state => state.dataImport.batchInsertError)
  const batchBeingProcessed = useSelector(state => state.dataImport.batchBeingProcessed)
  const batchDetails = useSelector(state => state.dataImport.batchDetails)
  const [headers, setHeaders] = useState({})
  const [options, setOptions] = useState({})
  const platformData = useSelector(state => state.login.settings)
  const [mappedFileHeaders, setMappedFileHeaders] = useState({})
  const [errorLinesData, setErrorLinesData] = useState([])

  const defaultPointsImportHeaders = [
    { dataColumnName: 'walletUserUID', columnName: 'UID', DatacolumnID: 101, dataType: 'string', ColumnNo: 1 },
    { dataColumnName: 'walletAmountPoints', columnName: 'Point', DatacolumnID: 3, dataType: 'number', ColumnNo: 2 },
    { dataColumnName: 'walletNote', columnName: 'Reference', DatacolumnID: 13, dataType: 'string', ColumnNo: 3 },
    { dataColumnName: 'timeInsert', columnName: 'Dato', DatacolumnID: 127, dataType: 'date', ColumnNo: 4 },
    { dataColumnName: 'VaultID', columnName: 'Vault (hvis aktuel)', DatacolumnID: 1610, dataType: 'string', ColumnNo: 5 }
  ]
  const defaultUsersImportHeaders = [
    { dataColumnName: 'userName', columnName: 'Navn (for- og efternavn)', dataType: 'string', DatacolumnID: 6, ColumnNo: 1 },
    { dataColumnName: 'userUID', columnName: 'UID (Unikt ID)', dataType: 'string', DatacolumnID: 7, ColumnNo: 2 },
    { dataColumnName: 'userPhone', columnName: 'Mobil', dataType: 'number', DatacolumnID: 1, ColumnNo: 3 },
    { dataColumnName: 'userEmail', columnName: 'Email', dataType: 'email', DatacolumnID: 2, ColumnNo: 4 },
    { dataColumnName: 'userCompany', columnName: 'Firma', dataType: 'string', DatacolumnID: 9, ColumnNo: 5 },
    { dataColumnName: 'userDepartment', columnName: 'Afdeling', dataType: 'string', DatacolumnID: 5, ColumnNo: 6 },
    { dataColumnName: 'userAddress', columnName: 'Adresse', dataType: 'string', DatacolumnID: 8, ColumnNo: 7 },
    { dataColumnName: 'userZipcode', columnName: 'Postnr.', dataType: 'number', DatacolumnID: 10, ColumnNo: 8 },
    { dataColumnName: 'userCity', columnName: 'By', dataType: 'string', DatacolumnID: 15, ColumnNo: 9 },
    { dataColumnName: 'userCompanyUID', columnName: 'Firma CVR', dataType: 'number', DatacolumnID: 12, ColumnNo: 10 },
    { dataColumnName: 'userSupervisorUID', columnName: 'KAM nr.', dataType: 'string', DatacolumnID: 125, ColumnNo: 11 },
    { dataColumnName: 'userSupervisor', columnName: 'KAM navn', dataType: 'string', DatacolumnID: 1512, ColumnNo: 12 },
    { dataColumnName: 'userGroup', columnName: 'Gruppe (hvis aktuel)', dataType: 'string', DatacolumnID: 1513, ColumnNo: 13 },
    { dataColumnName: 'defaultVaultID', columnName: 'Vault (hvis aktuel)', dataType: 'string', DatacolumnID: 1611, ColumnNo: 14 }
  ]

  const defaultOrdersImportHeaders = [
    { dataColumnName: 'orderConsigneeName', columnName: 'Navn', DatacolumnID: 1703, dataType: 'string', ColumnNo: 1 },
    { dataColumnName: 'userUID', columnName: 'UID', DatacolumnID: 1704, dataType: 'string', ColumnNo: 2 },
    { dataColumnName: 'orderConsigneeEmail', columnName: 'Email', DatacolumnID: 1705, dataType: 'email', ColumnNo: 3 },
    { dataColumnName: 'orderConsigneePhone', columnName: 'Mobil', DatacolumnID: 1706, dataType: 'number', ColumnNo: 4 },
    { dataColumnName: 'orderConsignee', columnName: 'Firma', DatacolumnID: 1707, dataType: 'string', ColumnNo: 5 },
    { dataColumnName: 'orderConsigneeAddress1', columnName: 'Adresse', DatacolumnID: 1708, dataType: 'string', ColumnNo: 6 },
    { dataColumnName: 'orderConsigneeZipcode', columnName: 'Postnr', DatacolumnID: 1709, dataType: 'number', ColumnNo: 7 },
    { dataColumnName: 'orderConsigneeCity', columnName: 'By', DatacolumnID: 1710, dataType: 'string', ColumnNo: 8 },
    { dataColumnName: 'orderConsigneeCountry', columnName: 'Land', DatacolumnID: 1711, dataType: 'string', ColumnNo: 9 },
    { dataColumnName: 'orderlineTitle', columnName: 'Produkt', DatacolumnID: 1712, dataType: 'string', ColumnNo: 10 },
    { dataColumnName: 'variationTitle', columnName: 'Variation titel', DatacolumnID: 1713, dataType: 'string', ColumnNo: 11 },
    { dataColumnName: 'variationUID', columnName: 'VariationId', DatacolumnID: 1714, dataType: 'string', ColumnNo: 12 },
    { dataColumnName: 'ProductID', columnName: 'ProductID', DatacolumnID: 1715, dataType: 'number', ColumnNo: 13 }
  ]
  let title = props.DatasetID === 1 ? 'userImportHeaders' : props.DatasetID === 2 ? 'pointImportHeaders' : 'orderImportHeaders'
  let customHeaders =
    platformData && platformData.features && platformData.features[title] && platformData.features[title].length > 0
      ? platformData.features[title]
      : props.DatasetID === 1
      ? defaultUsersImportHeaders
      : props.DatasetID === 2
      ? defaultPointsImportHeaders
      : defaultOrdersImportHeaders
  let standardTemplateHeaders =
    props.DatasetID === 1 ? defaultUsersImportHeaders : props.DatasetID === 2 ? defaultPointsImportHeaders : defaultOrdersImportHeaders
  let fileHeaders = customHeaders ? _.sortBy(customHeaders, 'ColumnNo') : _.sortBy(standardTemplateHeaders, 'ColumnNo')

  useEffect(() => {
    dispatch(getBatchDetails(Number(props.match.params.BatchID)))
    dispatch(getBatchElements(Number(props.match.params.BatchID)))
    props.DatasetID === 1 ? setUserOptions() : props.DatasetID === 2 ? setWalletOptions() : setOrderOptions()
  }, [])

  useEffect(() => {
    if (loadingStatus === STATUS.LOADING_BATCH_DETAILS_FAILED) {
      toast.error('Fetching Batch Elements Failed', {
        autoClose: 3000
      })
    }
  }, [loadingStatus])

  useEffect(() => {
    if (batchInsertStatus === STATUS.INSERT_BATCH_FAILURE) {
      toast.error('BATCH INSERTION FAILED : ' + batchInsertError, {
        autoClose: 3000
      })
      dispatch(resetBatchInsertStates())
    }
    if (batchInsertStatus === STATUS.INSERT_BATCH_SUCCESS) {
      toast.success('BATCH INSERTED SUCCESSFULY', {
        autoClose: 3000
      })
      dispatch(resetBatchInsertStates())
    }
  }, [batchInsertStatus, batchInsertError])

  const setUserOptions = () => {
    setOptions({
      fieldFunctions: {
        userUID: e => {
          if (e.userUIDError) {
            return (
              <React.Fragment>
                <StyledError>{`${e.userUID} (${e.userUIDError})`}</StyledError>
              </React.Fragment>
            )
          } else if (e.userUIDWarning) {
            return (
              <React.Fragment>
                <StyledError isWarning={true}>{`${e.userUID} (${e.userUIDWarning})`}</StyledError>
              </React.Fragment>
            )
          } else {
            return <React.Fragment>{e.userUID}</React.Fragment>
          }
        }
      },
      fieldStringFunctions: {
        userUID: e => {
          return e.userUID
        }
      },
      fieldExportFunctions: {
        userUID: e => {
          return e.userUIDError ? `${e.userUID} (${e.userUIDError})` : e.userUIDWarning ? `${e.userUID} (${e.userUIDWarning})` : e.userUID
        }
      }
    })
    setHeaders({
      userName: 'Navn',
      userUID: 'UID (Unikt ID)',
      userPhone: 'Mobil',
      userEmail: 'Email',
      userCompany: 'Firma',
      userDepartment: 'Afdeling',
      userAddress: 'Adresse',
      userZipcode: 'Postnr.',
      userCity: 'By',
      userCompanyUID: 'Firma CVR',
      userSupervisorUID: 'KAM nr.',
      userSupervisor: 'KAM navn',
      userGroup: 'Gruppe (hvis aktuel)',
      defaultVaultID: 'Vault (hvis aktuel)'
    })
  }

  const setWalletOptions = () => {
    setOptions({
      fieldFunctions: {
        timeInsert: e => {
          return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        walletAmountPoints: e => {
          if (e.walletAmountPointsError) {
            return (
              <React.Fragment>
                <StyledError>{`${e.walletAmountPoints} (${e.walletAmountPointsError})`}</StyledError>
              </React.Fragment>
            )
          } else {
            return <React.Fragment>{e.walletAmountPoints}</React.Fragment>
          }
        },
        walletUserUID: e => {
          if (e.walletUserUIDError) {
            return (
              <React.Fragment>
                <StyledError>{`${e.walletUserUID} (${e.walletUserUIDError})`}</StyledError>
              </React.Fragment>
            )
          } else {
            return <React.Fragment>{e.walletUserUID}</React.Fragment>
          }
        }
      },
      fieldStringFunctions: {
        timeInsert: e => {
          return moment(e.timeInsert).format('x')
        },
        walletAmountPoints: e => {
          return e.walletAmountPoints
        },
        walletUserUID: e => {
          return e.walletUserUID
        }
      },
      fieldExportFunctions: {
        timeInsert: e => {
          return moment(e.timeInsert).format('YYYY-MM-DD HH:mm:ss')
        },
        walletAmountPoints: e => {
          return e.walletAmountPointsError ? `${e.walletAmountPoints} (${e.walletAmountPointsError})` : e.walletAmountPoints
        },
        walletUserUID: e => {
          return e.walletUserUIDError ? `${e.walletUserUID} (${e.walletUserUIDError})` : e.walletUserUID
        }
      }
    })
    setHeaders({
      walletUserUID: 'UID',
      walletAmountPoints: 'Point',
      walletNote: 'Note',
      timeInsert: 'Dato',
      VaultID: 'Vault'
    })
  }

  const setOrderOptions = () => {
    setOptions({
      fieldFunctions: {
        userUID: e => {
          if (e.userUIDError) {
            return (
              <React.Fragment>
                <StyledError>{`${e.userUID} (${e.userUIDError})`}</StyledError>
              </React.Fragment>
            )
          } else {
            return <React.Fragment>{e.userUID}</React.Fragment>
          }
        },
        ProductID: e => {
          if (e.ProductIDError) {
            return (
              <React.Fragment>
                <StyledError>{`${e.ProductID} (${e.ProductIDError})`}</StyledError>
              </React.Fragment>
            )
          } else {
            return <React.Fragment>{e.ProductID}</React.Fragment>
          }
        },
        variationUID: e => {
          if (e.variationUIDError) {
            return (
              <React.Fragment>
                <StyledError>{`${e.variationUID} (${e.variationUIDError})`}</StyledError>
              </React.Fragment>
            )
          } else {
            return <React.Fragment>{e.variationUID}</React.Fragment>
          }
        }
      },
      fieldStringFunctions: {
        timeInsert: e => {
          return moment(e.timeInsert).format('x')
        },
        userUID: e => {
          return e.userUID
        },
        ProductID: e => e.ProductID,
        variationUID: e => e.variationUID
      },
      fieldExportFunctions: {
        timeInsert: e => {
          return moment(e.timeInsert).format('DD/MM/YYYY')
        },
        userUID: e => {
          return e.userUIDError ? `${e.userUID} (${e.userUIDError})` : e.userUID
        },
        ProductID: e => (e.ProductIDError ? `${e.ProductID} (${e.ProductIDError})` : e.ProductID),
        variationUID: e => (e.variationUIDError ? `${e.variationUID} (${e.variationUIDError})` : e.variationUID)
      }
    })
    setHeaders({
      orderConsigneeName: 'Navn',
      userUID: 'UID',
      orderConsigneeEmail: 'Email',
      orderConsigneePhone: 'Mobil',
      orderConsignee: 'Firma',
      orderConsigneeAddress1: 'Adresse',
      orderConsigneeZipcode: 'Postnr.',
      orderConsigneeCity: 'By',
      orderConsigneeCountry: 'Land',
      orderlineTitle: 'Produkt.',
      variationTitle: 'Variation titel',
      variationUID: 'VariationId',
      ProductID: 'ProductID'
    })
  }

  const getErrorLineRecords = elements => {
    elements = elements.filter(batch => {
      let res = Object.keys(batch).filter(item => {
        if (`${item}Error` in batch) {
          batch[item] = batch[item].trim() + '(' + batch[item + 'Error'] + ')'
          delete batch[item + 'Error']
          return item
        }
      })
      return res.length > 0 && batch
    })
    setErrorLinesData(elements)
    return elements
  }

  const mapFileHeaders = () => {
    let data = {}
    fileHeaders.map(column => {
      let record = standardTemplateHeaders.filter(item => item.DatacolumnID === column.DatacolumnID)
      if (record && record.length) {
        if (data[record[0].dataColumnName]) {
          // if two or more columns have same DataColumnID
          data[record[0].dataColumnName] = [data[record[0].dataColumnName], column['columnName']]
        } else {
          data[record[0].dataColumnName] = column['columnName']
        }
      } else {
        data[column['columnName']] = column['columnName']
      }
    })
    setMappedFileHeaders(data)
    return data
  }
  const downloadErrorLines = () => {
    let xlData = []
    let elements = errorLinesData.length > 0 ? errorLinesData : getErrorLineRecords(JSON.parse(JSON.stringify(batchElements)))
    let headers = Object.keys(mappedFileHeaders).length > 0 ? mappedFileHeaders : mapFileHeaders()
    if (elements && elements.length > 0) {
      elements.map((batch, index) => {
        let batchRecord = mapRecord(batch, headers)
        index === 0 && xlData.push(Object.keys(batchRecord))
        xlData.push(Object.values(batchRecord))
      })
    } else {
      xlData.push(Object.values(Object.values(headers)))
    }
    let worksheet = XLSX.utils.aoa_to_sheet(xlData)
    let workbook = new XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'download')
    XLSX.writeFile(workbook, 'error_lines.xlsx')
  }

  const mapRecord = (batch, mappedFileHeaders) => {
    let data = {}
    Object.keys(mappedFileHeaders).map(item => {
      if (Array.isArray(mappedFileHeaders[item])) {
        let value = batch[item].split(',')
        mappedFileHeaders[item].map((rec, index) => {
          data[rec] = value[index]
        })
      } else {
        data[mappedFileHeaders[item]] = batch[item]
      }
    })
    return data
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Batch Preview
          <span style={{ float: 'right' }}>
            <Button
              style={{ marginRight: '5px' }}
              disabled={
                (batchElements && !batchElements.length) || batchDetails.batchFlowStatus === 'INSERTED' || batchDetails.batchFlowStatus === 'MATCHED'
              }
              onClick={() => dispatch(insertBatch(Number(props.match.params.BatchID), Number(props.DatasetID), true))}
            >
              Insert
              {batchDetails.BatchID === batchBeingProcessed && batchInsertStatus === STATUS.INSERT_BATCH_PROGRESS && (
                <FontAwesomeIcon icon='spinner' />
              )}
            </Button>
            <Button
              disabled={loadingStatus === STATUS.LOADING_BATCH_DETAILS_FAILED || (batchElements && !batchElements.length)}
              onClick={downloadErrorLines}
            >
              Download fejlliste
            </Button>
          </span>
        </strong>
      }
    >
      {loadingStatus === STATUS.LOADING_BATCH_DETAILS && <FontAwesomeIcon icon='spinner' spin />}
      {loadingStatus === STATUS.LOADING_BATCH_DETAILS_SUCCESS && <DynamicTable data={batchElements} headers={headers} options={options} />}
    </CollapsibleCard>
  )
}
