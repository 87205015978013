import React, { useEffect } from 'react'
import XLSX from 'xlsx'
import { Button, Input, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleCard from 'Components/collapsibleCard'
import { useSelector, useDispatch } from 'react-redux'
import DynamicTable from 'Components/dynamicTable'
import { dataParse, setInputHeaders, batchCreationStatusReset, resetImportForm } from 'Reducers/dataImportReducer'
import { useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { DatasetStore } from 'Stores/datasetStore'
import { postalCodesDK } from 'Util/denmarkPostalCodes.js'

const UID_COL = 7
const NAME_COL = 6
const ZIP_COL = 10
var importFile = null
var importFileData = null
export default class ImportUsers extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <ImportUsersComponent props={this.props} />
      </React.Fragment>
    )
  }
}

function saveBatch(batchObj) {
  let dataset = new DatasetStore()
  dataset.saveBatch(batchObj)
}

function ImportUsersComponent({ props }) {
  const platformData = useSelector(state => state.login.settings)
  const parsedData = useSelector(state => state.dataImport.parsedData)
  const inputHeaders = useSelector(state => state.dataImport.inputHeaders)
  const dispatch = useDispatch()
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false)
  let options = {}
  let validationSuccess = false
  const regExp = /.*\(.*\).*/
  const batchComment = `Manual Import by ${platformData.userName}`
  const batchCreationSuccessful = useSelector(state => state.dataImport.batchCreationSuccessful)
  const batchCreationFailed = useSelector(state => state.dataImport.batchCreationFailed)
  const postalCodesList = postalCodesDK.map(item => Number(item.postalCode))

  const defaultUsersImportHeaders = [
    { columnName: 'Navn (for- og efternavn)', dataType: 'string', DatacolumnID: 6, ColumnNo: 1 },
    { columnName: 'UID (Unikt ID)', dataType: 'string', DatacolumnID: 7, ColumnNo: 2 },
    { columnName: 'Mobil', dataType: 'number', DatacolumnID: 1, ColumnNo: 3 },
    { columnName: 'Email', dataType: 'email', DatacolumnID: 2, ColumnNo: 4 },
    { columnName: 'Firma', dataType: 'string', DatacolumnID: 9, ColumnNo: 5 },
    { columnName: 'Afdeling', dataType: 'string', DatacolumnID: 5, ColumnNo: 6 },
    { columnName: 'Adresse', dataType: 'string', DatacolumnID: 8, ColumnNo: 7 },
    { columnName: 'Postnr.', dataType: 'number', DatacolumnID: 10, ColumnNo: 8 },
    { columnName: 'By', dataType: 'string', DatacolumnID: 15, ColumnNo: 9 },
    { columnName: 'Firma CVR', dataType: 'number', DatacolumnID: 12, ColumnNo: 10 },
    { columnName: 'KAM nr.', dataType: 'string', DatacolumnID: 125, ColumnNo: 11 },
    { columnName: 'KAM navn', dataType: 'string', DatacolumnID: 1512, ColumnNo: 12 },
    { columnName: 'Gruppe (hvis aktuel)', dataType: 'string', DatacolumnID: 1513, ColumnNo: 13 },
    { columnName: 'Vault (hvis aktuel)', dataType: 'string', DatacolumnID: 1611, ColumnNo: 14 }
  ]

  const customHeaders =
    platformData && platformData.features && platformData.features.userImportHeaders && platformData.features.userImportHeaders.length > 0
      ? platformData.features.userImportHeaders
      : null

  const headers = customHeaders ? customHeaders : defaultUsersImportHeaders
  const fileHeaders = customHeaders ? customHeaders.map(item => item.columnName) : defaultUsersImportHeaders.map(item => item.columnName)

  useEffect(() => {
    dispatch(resetImportForm())
  }, [])

  useEffect(() => {
    if (validationSuccess) {
      setSubmitButtonEnabled(true)
    } else {
      setSubmitButtonEnabled(false)
    }
    if (batchCreationSuccessful === true) {
      toast.success('Batch created successfully', {
        autoClose: 3000
      })
      dispatch(batchCreationStatusReset())
      props.history.push('/import/users')
    }
    if (batchCreationFailed === true) {
      toast.error('Batch creation Failed. Please try again', {
        autoClose: 3000
      })
      dispatch(batchCreationStatusReset())
    }
  })

  const downloadEXCEL = () => {
    let xlData = []
    xlData.push(fileHeaders)
    let worksheet = XLSX.utils.aoa_to_sheet(xlData)
    let workbook = new XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'download')
    XLSX.writeFile(workbook, 'Users-Import-template.xlsx')
  }

  const parseCsvString = (result, batchData) => {
    let records = result.split('\n')
    let csvDataString = ''
    records.map(item => {
      let data = item.trim()
      let csv = data.replaceAll(';', '').trim()
      if (csv.length > 1) {
        csvDataString = csvDataString + item + '\n'
      }
    })
    batchData.batchFiles[0].csvDataString = csvDataString
    saveBatch(batchData) // -- api call
  }

  const prepareBatchOptions = () => {
    let columns = []
    headers.map(item => {
      let obj = {
        DatacolumnID: item.DatacolumnID,
        csvColumnDefault: '',
        csvColumnMatchExpression: '',
        csvColumnMatchValue: '',
        csvColumnNo: `${item.ColumnNo}`
      }
      if (item.DatacolumnID === UID_COL) {
        obj.primarykey = true
      }
      columns.push(obj)
    })

    return {
      skipHeader: true,
      columns: columns
    }
  }

  const identifyBatchRole = () => {
    const brandsThatUseClientRole = [1, 2, 3, 4, 5]
    if (brandsThatUseClientRole.includes(platformData.BrandID)) {
      return 'Client'
    } else {
      return 'Brand'
    }
  }

  const initiateBatchCreation = (sheetData = parsedData, file = importFile, data = importFileData) => {
    if (sheetData && file && data) {
      let csvContent = ''
      let batchData = {}
      batchData.DatasetID = 1 // Users
      batchData.batchComment = batchComment
      batchData.batchEncoding = 'UTF-8'
      batchData.batchOptions = prepareBatchOptions()
      batchData.batchRole = identifyBatchRole()
      batchData.skipHeader = 'on'
      batchData.uniqueConstraint = 'userUID'
      sheetData.forEach(function(infoArray, index) {
        let dataString = infoArray.join(';')
        csvContent += index < sheetData.length ? dataString + '\n' : dataString
      })
      batchData.batchFiles = {
        File: file,
        contentType: file.type,
        fileExtension: file.type.split('/')[1],
        name: file.name
      }
      batchData.batchFiles.csvDataString = data
      batchData.batchFiles = [batchData.batchFiles]
      parseCsvString(csvContent, batchData)
    }
  }

  const handleSubmit = () => {
    initiateBatchCreation()
  }

  const handleExcelUpload = e => {
    e.preventDefault()
    let files = e.target.files
    importFile = files[0]
    let reader = new FileReader()
    reader.onload = function(e) {
      importFileData = e.target.result
      let readedData = XLSX.read(importFileData, { type: 'binary' })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]
      const sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 })
      let fileValidation = []
      let errorMessage = null
      if (sheetData && sheetData.length > 0) {
        if (JSON.stringify(fileHeaders) !== JSON.stringify(sheetData[0])) {
          fileValidation = sheetData[0].map(item =>
            fileHeaders.some(header => header.includes(regExp.test(item) ? item.split('(')[0].trim() : item.trim()))
          )
        }
        if (sheetData.length < 2) {
          errorMessage =
            (fileValidation && !fileValidation.includes(false)) || !fileValidation.length
              ? 'Data mangler. Venligst upload ny korrekt fil med data.'
              : null
        }
      } else {
        errorMessage = 'Filen er tom. Venligst upload ny korrekt fil med data.'
      }
      if ((fileValidation && fileValidation.includes(false)) || errorMessage) {
        toast.error(errorMessage ? errorMessage : 'Forkert fil skabelon. Venligst upload korrekt skabelon', {
          autoClose: 3000
        })
        dispatch(batchCreationStatusReset())
        return
      }
      dispatch(dataParse(sheetData))
    }
    reader.readAsBinaryString(importFile)
  }

  const prepareTableData = (sheetData = parsedData) => {
    let tableData = []
    if (sheetData && sheetData.length) {
      let inputfileHeaders = sheetData[0]
      if (inputfileHeaders.length) {
        dispatch(setInputHeaders(inputfileHeaders))
        sheetData.forEach((item, i) => {
          let obj = {
            rowNo: i + 1
          }
          inputHeaders.forEach((val, index) => {
            if (item[index] && item[index].toString().trim() !== '') {
              obj[val] = item[index]
            }
          })
          if (i > 0 && Object.keys(obj).length > 1) {
            // check for blank space in file headers and trim
            const trimmedRow = Object.entries(obj).reduce((acc, curr) => {
              let [key, value] = curr
              acc[typeof key === 'string' ? key.trim() : key] = typeof value === 'string' ? value.trim() : value
              return acc
            }, {})
            tableData.push(trimmedRow)
          }
        })
        tableData = validateTableData(tableData)
        return tableData
      }
    }
    return []
  }

  const validateTableData = tableData => {
    validationSuccess = true
    let columns = customHeaders ? customHeaders : defaultUsersImportHeaders
    for (let row of tableData) {
      row.error = []
      for (let column of columns) {
        let columnName =
          column.columnName in row
            ? column.columnName
            : regExp.test(column.columnName)
            ? inputHeaders.includes(column.columnName.split('(')[0].trim())
              ? column.columnName.split('(')[0].trim()
              : column.columnName
            : column.columnName
        const cellData = row[columnName]

        // Mandatory validations
        if (column.DatacolumnID === UID_COL) {
          if (!cellData) {
            validationSuccess = false
            if (!(columnName in row)) {
              row[columnName] = null
            }
            row.error.push(columnName)
          }
          if (cellData) {
            const UIDList = tableData.filter(item => item[columnName] === cellData)
            if (UIDList.length > 1) {
              validationSuccess = false
              if (!(columnName in row)) {
                row[columnName] = null
              }
              row.error.push(columnName)
            }
          }
        }
        if (column.DatacolumnID === NAME_COL && !cellData) {
          validationSuccess = false
          if (!(columnName in row)) {
            row[columnName] = null
          }
          row.error.push(columnName)
        }
        let date = null

        switch (column.dataType) {
          case 'number':
            if (cellData && (cellData === 0 || isNaN(cellData))) {
              validationSuccess = false
              row.error.push(columnName)
            }
            if (column.DatacolumnID === ZIP_COL && cellData) {
              if (!postalCodesList.includes(Number(cellData))) {
                validationSuccess = false
                row.error.push(columnName)
              }
            }
            break
          case 'email':
            if (
              cellData &&
              !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(cellData)
            ) {
              validationSuccess = false
              row.error.push(columnName)
            }
            break
          case 'date':
            if (cellData) {
              date = moment(cellData, 'DD.MM.YYYY')
              if (!date || !date.isValid()) {
                date = moment(cellData, 'DD-MM-YYYY')
                if (!date.isValid()) {
                  validationSuccess = false
                  row.error.push(columnName)
                }
              }
            }
            break
          default:
            break
        }
      }
    }
    return tableData
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Upload bruger fil
          <span className='float-right'>
            <Button
              className='margin-5'
              onClick={() => {
                downloadEXCEL()
              }}
            >
              Download tomt Excel ark til oprettelse <FontAwesomeIcon icon='file-excel' />
            </Button>
          </span>
        </strong>
      }
    >
      <label className='select-file-button margin-5' htmlFor='fileupload'>
        Vælg Excel/CSV fil til upload <FontAwesomeIcon icon='file-excel' />
      </label>
      <Input
        onChange={event => {
          handleExcelUpload(event)
        }}
        onClick={event => {
          event.target.value = null
        }}
        id='fileupload'
        type='file'
        className='display-none'
      />
      <Button disabled={!submitButtonEnabled} onClick={handleSubmit}>
        Upload Excel/CSV
      </Button>
      {parsedData && parsedData.length && !submitButtonEnabled && (
        <React.Fragment>
          <Row className='ml-2 mb-2' style={{ color: 'red' }}>
            Fremhævede celler mangler enten obligatoriske værdier eller har forkert format. Venligst se nedenfor og upload en ny tilrettet fil.
          </Row>
          <Row className='ml-2'>Nogle eksempler på korrekte data:</Row>
          <Row className='ml-5'>&#8226; UID - Feltet må ikke være blankt ( 123 / 123test / 123@test.com )</Row>
          <Row className='ml-5'>&#8226; Navn - Feltet må ikke være blankt </Row>
          <Row className='ml-5'>&#8226; Mobil - Feltet skal være et tal </Row>
          <Row className='ml-5 mb-3'>&#8226; Email - Feltet må ikke være blankt ( abc123@test.com ) </Row>
        </React.Fragment>
      )}
      <DynamicTable data={prepareTableData(parsedData)} options={options} headers={['rowNo'].concat(...inputHeaders.map(header => header.trim()))} />
    </CollapsibleCard>
  )
}
