import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input, Row, Col, FormGroup, Label } from 'reactstrap'
import _ from 'lodash'
import { CategoryStore, CategoryActions, STATUS } from 'Stores/categoryStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { HofActions, HofStore } from 'Stores/hofStore'

const categoryTaxonomies = ['CATEGORY', 'MANUFACTURER', 'PRODUCT', 'POST', 'TAG']

export default class CategoryForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [CategoryStore, HofStore]
    this.storeKeys = ['loadingState', 'categoryDetails', 'brands', 'file', 'isUploading', 'subCategories', 'languages']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  componentDidMount() {
    HofActions.getBrands()
    CategoryActions.getSubCategories()
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.CategoryID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      CategoryActions.getCategoryDetails(this.props.match.params.CategoryID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      CategoryActions.createEmptyCategory()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isCategoryNameValid: this.state.categoryDetails.categoryName ? true : false,
      isCategoryTaxonomyValid: this.state.categoryDetails.categoryTaxonomy ? true : false,
      isBrandIDValid: this.state.categoryDetails.BrandID ? true : false
    }

    this.setState({ fieldValidation })

    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveCategoryForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      CategoryActions.saveCategory()
    }
  }

  displayTextTaxonomy(taxonomy) {
    switch (taxonomy.toLowerCase()) {
      case 'manufacturer':
        return 'BRAND'
      default:
        return taxonomy
    }
  }

  render() {
    if (this.state.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/category' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Category</strong>}>
          <Form>
            <FormField field={'BrandID'} name='Brand' required>
              <Input
                type='select'
                className={this.state.categoryDetails.BrandID ? 'is-valid' : 'is-invalid'}
                name='BrandID'
                value={this.state.categoryDetails.BrandID}
                onChange={e => {
                  CategoryActions.updateCategoryField(e)
                  this.validateForm()
                  CategoryActions.getLanguages(e.target.value)
                }}
              >
                <option value=''>Select Brand</option>
                {this.state.brands &&
                  !!this.state.brands.length &&
                  this.state.brands.map(e => {
                    return (
                      <option value={e.BrandID} key={e.BrandID}>
                        {e.brandName}
                      </option>
                    )
                  })}
              </Input>
            </FormField>
            <FormField
              required
              data={this.state.categoryDetails}
              field={'categoryName'}
              name='Name'
              onChange={e => {
                CategoryActions.updateCategoryField(e)
                this.validateForm()
              }}
            />
            <FormField field={'categoryTaxonomy'} name='Taxonomy' required>
              <Input
                type='select'
                name='categoryTaxonomy'
                className={this.state.categoryDetails.categoryTaxonomy ? 'is-valid' : 'is-invalid'}
                value={this.state.categoryDetails.categoryTaxonomy}
                onChange={CategoryActions.updateCategoryField}
              >
                <option value=''>Select Taxonomy</option>
                {categoryTaxonomies.map((taxonomy, index) => {
                  return (
                    <option value={taxonomy} key={index}>
                      {this.displayTextTaxonomy(taxonomy)}
                    </option>
                  )
                })}
              </Input>
            </FormField>
            {!!this.state.categoryDetails &&
              !!this.state.categoryDetails.categoryTaxonomy &&
              this.state.categoryDetails.categoryTaxonomy.toUpperCase() === 'PRODUCT' && (
                <Row style={{ margin: '10px 0' }}>
                  <Col xs={3} style={{ padding: 0 }}>
                    Sub-Categories
                  </Col>
                  <Col xs={9}>
                    <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                      {this.state.subCategories.map(sc => (
                        <>
                          <Button
                            onClick={() => CategoryActions.toggleLinkedSubCategory(sc)}
                            color={
                              this.state.categoryDetails.linkedSubCategoryIDs &&
                              this.state.categoryDetails.linkedSubCategoryIDs.includes(sc.SubCategoryID)
                                ? 'secondary'
                                : 'light'
                            }
                          >
                            {sc.subCategoryName}
                          </Button>
                        </>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
            <FormField
              name='Image'
              field='upload'
              type='file'
              data={{ upload: '' }}
              noValue={this.state.categoryDetails.imageURL !== ''}
              onChange={CategoryActions.uploadCategoryImage}
              disabled={this.state.isUploading}
              accept='image/*'
            />
            <Row>
              <Col md={3} />
              <Col>
                {this.state.categoryDetails && this.state.categoryDetails.imageURL && (
                  <img
                    src={this.state.categoryDetails.imageURL}
                    alt={this.state.categoryDetails.imageURL}
                    style={{
                      height: '15vw',
                      width: '15vw',
                      objectFit: 'cover',
                      marginBottom: '10px',
                      display: this.state.categoryDetails.imageURL ? 'inline-block' : 'none'
                    }}
                  />
                )}
              </Col>
            </Row>
            {!!this.state.languages && !!(this.state.languages.length > 1) && (
              <FormGroup row>
                <Label for='textColor' sm={3}>
                  Translations
                </Label>
                <Col sm={9}>
                  {!!this.state.categoryDetails &&
                    !!this.state.categoryDetails.translations &&
                    !!this.state.categoryDetails.translations.length &&
                    this.state.categoryDetails.translations.map((translation, inx) => {
                      return (
                        !translation.removed && (
                          <div key={inx}>
                            <Row>
                              <Col sm={11}>
                                <Input
                                  type='select'
                                  name='LanguageID'
                                  value={translation.LanguageID ? Number(translation.LanguageID) : ''}
                                  id='LanguageID'
                                  onChange={e => CategoryActions.updateData(e, inx, 'translations')}
                                >
                                  <option value='' disabled>
                                    Click to Select
                                  </option>
                                  {!!this.state.languages &&
                                    !!this.state.languages.length &&
                                    this.state.languages.map((e, i) => {
                                      return (
                                        <option value={Number(e.LanguageID)} key={i}>
                                          {e.languageName}
                                        </option>
                                      )
                                    })}
                                </Input>
                              </Col>
                              <Col sm={1}>
                                <Button onClick={() => CategoryActions.removeTranslation(inx)} color={'danger'}>
                                  <FontAwesomeIcon icon={'minus'} />
                                </Button>
                              </Col>
                            </Row>
                            <br></br>
                            <Row>
                              <Label for='textColor' sm={2}>
                                Title
                              </Label>
                              <Col sm={9}>
                                <Input
                                  name='categoryName'
                                  value={translation.categoryName}
                                  type='text'
                                  onChange={e => CategoryActions.updateData(e, inx, 'translations')}
                                />
                              </Col>
                              <Col sm={1}>
                                <Button onClick={CategoryActions.addTranslation}>
                                  <FontAwesomeIcon icon='plus' />
                                </Button>
                              </Col>
                            </Row>
                            <br></br>
                          </div>
                        )
                      )
                    })}
                </Col>
              </FormGroup>
            )}
            <Button onClick={this.saveCategoryForm} disabled={this.state.loadingState === STATUS.IS_SAVING}>
              {this.state.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
