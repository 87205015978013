/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navConfig: PropTypes.any,
  navFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isOpen: PropTypes.bool,
  staticContext: PropTypes.any,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
}

const defaultProps = {
  tag: 'nav',
  navConfig: {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        badge: { variant: 'info', text: 'NEW' }
      }
    ]
  },
  isOpen: false
}

class AppSidebarNav extends Component {
  constructor(props) {
    super(props)
    ;(this.isMenuListSelected = false), (this.selectedMenuURL = ''), (this.isVerified = false)
    this.handleClick = this.handleClick.bind(this)
    this.activeRoute = this.activeRoute.bind(this)
    this.hideMobile = this.hideMobile.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    e.target.parentElement.classList.toggle('open')
  }

  activeRoute(routeName, props, subRoutes) {
    if (!this.isVerified) {
      this.setScrollTop()
    }
    if (subRoutes && subRoutes.length) {
      let value = subRoutes.filter(item => {
        if (props.location.pathname.indexOf(item) > -1) {
          return item
        }
      })
      if (value && value.length) {
        return 'nav-item nav-dropdown open'
      }
    }
    return props.location && props.location.pathname.indexOf(routeName) === 0 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown'
  }

  setScrollTop = () => {
    let isMenuListSelected = false
    let url = ''
    if (this.props && this.props.navConfig) {
      this.props.navConfig.items.map(item => {
        if (item.subRoutes && item.subRoutes.length > 3) {
          item.subRoutes.filter(path => {
            if (this.props.location.pathname.indexOf(path) > -1) {
              isMenuListSelected = true
              url = item.url
            }
          })
        }
      })
      if (isMenuListSelected && !this.isMenuListSelected) {
        this.isMenuListSelected = true
        this.selectedMenuUrl = url
      }
      if (this.isMenuListSelected && this._scrollRef) {
        if (isMenuListSelected) {
          if (this.selectedMenuUrl !== url) {
            this.selectedMenuUrl = url
            let elDistanceToTop = window.pageYOffset + this._scrollRef.getBoundingClientRect().top
            this._scrollRef.scrollTop = elDistanceToTop
          }
        } else {
          this.isMenuListSelected = false
          let elDistanceToTop = window.pageYOffset + this._scrollRef.getBoundingClientRect().top
          this._scrollRef.scrollTop = elDistanceToTop
        }
      }
      this.isVerified = true
    }
  }

  hideMobile() {
    this.isVerified = false
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show')
    }
  }

  // nav list
  navList(items) {
    return items.map((item, index) => this.navType(item, index))
  }

  // nav type
  navType(item, idx) {
    return item.title
      ? this.navTitle(item, idx)
      : item.divider
      ? this.navDivider(item, idx)
      : item.label
      ? this.navLabel(item, idx)
      : item.children
      ? this.navDropdown(item, idx)
      : this.navItem(item, idx)
  }

  // nav list section title
  navTitle(title, key) {
    const classes = classNames('nav-title', title.class)
    return (
      <li key={key} className={classes}>
        {this.navWrapper(title)}{' '}
      </li>
    )
  }

  // simple wrapper for nav-title item
  navWrapper(item) {
    return item.wrapper && item.wrapper.element ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name) : item.name
  }

  // nav list divider
  navDivider(divider, key) {
    const classes = classNames('divider', divider.class)
    return <li key={key} className={classes} />
  }

  // nav label with nav link
  navLabel(item, key) {
    const classes = {
      item: classNames('hidden-cn', item.class),
      link: classNames('nav-label', item.class ? item.class : ''),
      icon: classNames(
        'nav-icon',
        !item.icon ? 'fa fa-circle' : item.icon,
        item.label.variant ? `text-${item.label.variant}` : '',
        item.label.class ? item.label.class : ''
      )
    }
    return this.navLink(item, key, classes)
  }

  // nav dropdown
  navDropdown(item, key) {
    let liClass = classNames(this.activeRoute(item.url, this.props, item.subRoutes), item.class)
    let linkClass = classNames('nav-link nav-dropdown-toggle', item.class)
    let icon = null
    if (typeof item.icon == 'object') {
      icon = item.icon
    } else {
      icon = <i className={classNames('nav-icon', item.icon)} />
    }
    return (
      <li key={key} className={liClass}>
        <NavLink to={item.url} className={linkClass} onClick={this.handleClick}>
          {icon}
          {item.name}
        </NavLink>
        <ul className='nav-dropdown-items' style={item.hasChildMenu && { paddingLeft: '1rem' }}>
          {this.navList(item.children)}
        </ul>
      </li>
    )
  }

  // nav item with nav link
  navItem(item, key) {
    const classes = {
      item: classNames(item.class),
      link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
      icon: classNames('nav-icon', item.icon)
    }
    return this.navLink(item, key, classes)
  }

  // nav link
  navLink(item, key, classes) {
    const url = item.url ? item.url : ''
    let icon = <i className={classes.icon} />
    if (typeof item.icon == 'object') {
      icon = item.icon
    }
    return (
      <NavItem key={key} className={classes.item}>
        {this.isExternal(url) ? (
          <RsNavLink href={url} className={classes.link} active>
            {icon}
            {item.name}
            {this.navBadge(item.badge)}
          </RsNavLink>
        ) : (
          <NavLink exact={item.exact ? item.exact : false} to={url} className={classes.link} activeClassName='active' onClick={this.hideMobile}>
            {icon}
            {item.name}
            {this.navBadge(item.badge)}
          </NavLink>
        )}
      </NavItem>
    )
  }

  // badge addon to NavItem
  navBadge(badge) {
    if (badge) {
      const classes = classNames(badge.class)
      return (
        <Badge className={classes} color={badge.variant}>
          {badge.text}
        </Badge>
      )
    }
    return null
  }

  isExternal(url) {
    const link = url ? url.substring(0, 4) : ''
    return link === 'http'
  }

  render() {
    const { className, children, navConfig, ...attributes } = this.props

    delete attributes.isOpen
    delete attributes.staticContext
    delete attributes.Tag

    const navClasses = classNames(className, 'sidebar-nav')

    // sidebar-nav root
    return (
      <PerfectScrollbar containerRef={ref => (this._scrollRef = ref)} className={navClasses} {...attributes} options={{ suppressScrollX: true }}>
        <Nav>{children || this.navList(navConfig.items)}</Nav>
      </PerfectScrollbar>
    )
  }
}

AppSidebarNav.propTypes = propTypes
AppSidebarNav.defaultProps = defaultProps

export default AppSidebarNav
