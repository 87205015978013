import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup } from 'reactstrap'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import { STATUS } from '../../components/dashboard/useDashboardData'
import { Link } from 'react-router-dom'

export default class KAMCompanyOverview extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <SantanderKAMCompanyOverview props={this.props} />
      </React.Fragment>
    )
  }
}

function SantanderKAMCompanyOverview(data) {
  if (!data.props.match || !data.props.match.params) {
    return null
  }
  const [kamCompanyData, loadingStatus] = useDashboardData('supervisors/admin-santanderCvr', { kamUID: data.props.match.params.kamUID })

  return (
    <CollapsibleCard startOpen header={<strong>KAM Company Oversigt</strong>}>
      {loadingStatus === STATUS.LOADING && <FontAwesomeIcon icon='spinner' spin />}
      {loadingStatus === STATUS.SUCCESS && (
        <DynamicTable
          data={kamCompanyData}
          headers={{
            Dealer_ID: 'Forhandler ID',
            Dealer_CVR: 'CVR',
            Dealer_Navn: 'Navn',
            Closed: 'Lukket',
            Salesclub: 'Adgang til salesclub'
          }}
          options={{
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Button tag={Link} to={'/kams/' + data.props.match.params.kamUID + '/cvr/' + e.Dealer_CVR + '/' + e.Dealer_ID}>
                      Detaljer
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      )}
    </CollapsibleCard>
  )
}
