import React from 'react'
import { Component } from 'reflux'
import strip_tags from 'Components/striptags'
import { Input, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

class SearchableDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      filteredList: []
    }
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }))
    if (this.props.data.filterText && this.state.dropdownOpen) {
      const groupsFilter = this.props.list.filter(item =>
        item[this.props.dropdown.displayName].toLowerCase().includes(this.props.data.filterText.toLowerCase())
      )
      this.setState({ filteredList: groupsFilter, dropdownOpen: false })
    }
  }

  filterList = field => {
    const name = field.target.name
    const value = strip_tags(field.target.value)
    const dataDetails = this.props.data
    dataDetails[name] = value
    if (name === 'filterText') {
      const groupsFilter = value
        ? this.props.list.filter(item => item[this.props.dropdown.displayName].toLowerCase().includes(value.toLowerCase()))
        : this.props.list
      this.setState({ filteredList: groupsFilter, dropdownOpen: true })
    }
    this.props.setDataFn(dataDetails)
  }

  updateSelectedValue = field => {
    const dataDetails = this.props.data
    const value = JSON.parse(field.target.value)
    if (field.target && value) {
      dataDetails.GroupID = parseInt(value[this.props.dropdown.value])
      dataDetails.filterText = value[this.props.dropdown.displayName]
      this.props.setDataFn(dataDetails)
    }
    if (this.props.callBackFn) {
      this.props.callBackFn()
    }
  }
  render() {
    let groupsList = []
    this.props.data.filterText && this.props.data.filterText.length
      ? (groupsList = this.state.filteredList.map((e, i) => {
          return (
            <DropdownItem tag='button' onClick={this.updateSelectedValue} value={JSON.stringify(e)} key={i}>
              {e[this.props.dropdown.displayName]}
            </DropdownItem>
          )
        }))
      : (groupsList = this.props.list.map((e, i) => {
          return (
            <DropdownItem tag='button' onClick={this.updateSelectedValue} value={JSON.stringify(e)} key={i}>
              {e[this.props.dropdown.displayName]}
            </DropdownItem>
          )
        }))
    return (
      <React.Fragment>
        <Dropdown nav={true} style={{ listStyleType: 'none' }} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} className='navItem'>
          <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={this.toggleDropdown}>
            <Input
              type='text'
              autoComplete='off'
              style={{ marginBottom: '0.5rem' }}
              value={this.props.data.filterText}
              id='filterText'
              name='filterText'
              onChange={this.filterList}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                }
              }}
              placeholder='Klik for at vælge'
              disabled={this.props.disabled}
            ></Input>
          </DropdownToggle>
          <DropdownMenu
            className='groupsDropdown'
            size='sm'
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: data => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: 'auto',
                      maxHeight: 250,
                      transform: 'none',
                      top: '35px',
                      zIndex: '1000'
                    }
                  }
                }
              }
            }}
          >
            {groupsList}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}
export default SearchableDropdown
