import React, { useEffect } from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { fetchUserObfuscationSchedule, preventUserObfuscation } from 'Reducers/actions/dataRetentionActions'
import { updateSelectedUserIDs, setSelectedUserIDs, updateSelectedUserIDsCheckbox } from 'Reducers/dataRetentionReducer'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Input, Button } from 'reactstrap'
import { useState } from 'react'

export default class UserObfuscationSchedule extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <UserObfuscationScheduleComponent />
      </React.Fragment>
    )
  }
}

function UserObfuscationScheduleComponent() {
  const dispatch = useDispatch()
  const [isAllChecked, setAllChecked] = useState(false)
  const [advanceSearchFilters, setadvanceSearchFilters] = useState([])
  let userObfuscationSchedulePoolRedux = useSelector(state => state.dataRetention.userObfuscationSchedulePool)
  let userObfuscationSchedulePool = userObfuscationSchedulePoolRedux.slice()
  let selectedUserIDs = useSelector(state => state.dataRetention.selectedUserIDs)
  useEffect(() => {
    dispatch(fetchUserObfuscationSchedule())
  }, [])

  const selectAllUsers = () => {
    if (!isAllChecked) {
      const UserIDs = userObfuscationSchedulePoolRedux.map(item => item.UserID)
      for (const id of UserIDs) {
        dispatch(updateSelectedUserIDs(id))
        dispatch(updateSelectedUserIDsCheckbox(id))
      }

      setAllChecked(true)
    } else {
      dispatch(setSelectedUserIDs([]))
      setAllChecked(false)
    }
  }

  const preventObfuscation = () => {
    let UserIDs = []
    UserIDs = selectedUserIDs
    dispatch(preventUserObfuscation(UserIDs))
  }

  const updateSelection = UserID => {
    dispatch(updateSelectedUserIDs(UserID))
    dispatch(updateSelectedUserIDsCheckbox(UserID))
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <React.Fragment>
          <strong>List of Users</strong>{' '}
          <Button className='float-right' onClick={() => preventObfuscation()}>
            Save selected users from obfuscation
          </Button>
        </React.Fragment>
      }
    >
      <DynamicTable
        responsive
        size='sm'
        options={{
          isAllChecked: isAllChecked,
          preserveAllChecked: selectAllUsers,
          advanceSearchColumns: [
            { key: 'UserID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
            { key: 'userUID', name: 'UID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
            { key: 'userName', name: 'Name', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
            { key: 'BrandID', name: 'Brand ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
            { key: 'brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
            { key: 'timeScheduled', name: 'Scheduled time', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] }
          ],
          advanceSearchFilters: advanceSearchFilters,
          preserveAdvanceSearch: setadvanceSearchFilters,
          startSort: {
            dir: 'DESC',
            column: 'UserID'
          },
          fieldFunctions: {
            timeScheduled: e => {
              return e.timeScheduled ? moment(e.timeScheduled).format('DD-MM-YYYY') : ''
            },
            UserID: e => {
              return (
                <>
                  <Input
                    type='checkbox'
                    id={'checkbox' + e.UserID}
                    name={'checkbox' + e.UserID}
                    defaultChecked={selectedUserIDs.includes(e.UserID)}
                    onChange={() => {
                      updateSelection(e.UserID)
                    }}
                  />
                  {e.UserID}
                </>
              )
            }
          }
        }}
        headers={{
          UserID: 'UserID',
          userUID: 'userUID',
          userName: 'User Name',
          BrandID: 'Brand ID',
          brandName: 'Brand Name',
          userAccess: 'User Access',
          lastLogin: 'Last Login',
          timeScheduled: 'Scheduled Date'
        }}
        data={[...userObfuscationSchedulePool]}
      />
    </CollapsibleCard>
  )
}
