/* eslint-disable eqeqeq,no-prototype-builtins */
import React from 'react'
import { Component } from 'reflux'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'moment/locale/da'
import moment from 'moment'
import { CVR2Actions, CVR2Store } from 'Stores/cvr2Store'

export default class CVR2 extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (Object.keys(this.props.match.params).length == 0) {
      if (this.props.create) {
        return <Create {...this.props} />
      }
      return <Default {...this.props} />
    }
    if (this.props.match.params.CompetitionID) {
      return <Competition {...this.props} />
    }
    return null
  }
}

class Default extends Component {
  constructor(props) {
    super(props)
    this.store = CVR2Store
    this.storeKeys = ['CVR2Competitions', 'competitionError']
  }
  componentDidMount() {
    CVR2Actions.getCompetitions()
  }
  componentDidUpdate() {
    if (this.state.competitionError) {
      toast.error('Der opstod en fejl da vi forsøgte at hente konkurrencer.', { onClose: CVR2Actions.clearCompetitionError })
    }
  }
  render() {
    let competitions = Array.isArray(this.state.CVR2Competitions) ? this.state.CVR2Competitions : [this.state.CVR2Competitions]
    return (
      <React.Fragment>
        <ListGroup>
          {competitions.map((e, key) => {
            return (
              <ListGroupItem to={'/cvr2/' + e.CompetitionID} tag={Link} key={key}>
                {e.moduleName}
                <Badge className='float-right' pill>
                  {e.status}
                </Badge>
              </ListGroupItem>
            )
          })}
          <ListGroupItem onClick={CVR2Actions.clearCompetition} tag={Link} to={'/cvr2/create'}>
            Opret ny
          </ListGroupItem>
        </ListGroup>
      </React.Fragment>
    )
  }
}

class Create extends Component {
  constructor(props) {
    super(props)
    this.store = CVR2Store
  }
  componentWillMount() {
    super.componentWillMount()
    CVR2Actions.clearCompetition()
  }
  render() {
    if (this.state.CVR2Competition.CompetitionID) {
      return <Redirect to={'/cvr2/' + this.state.CVR2Competition.CompetitionID} />
    }
    return (
      <React.Fragment>
        <Card>
          <CardHeader>
            <strong>Info om konkurrencen</strong>
          </CardHeader>
          <CardBody>
            <CompetitionForm />
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

class Competition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processCollapsed: true,
      challengesCollapsed: true,
      dealersCollapsed: true,
      dealerCleanCollapsed: true,
      filterChallenges: ''
    }
    this.store = CVR2Store
  }
  componentDidUpdate() {
    if (this.state.competitionError) {
      toast.error('Der opstod en fejl da vi forsøgte at hente konkurrencen.', { onClose: CVR2Actions.clearCompetitionError })
    }
  }
  componentDidMount() {
    CVR2Actions.getCompetition(this.props.match.params.CompetitionID)
  }
  toggleChallenges() {
    this.setState({ challengesCollapsed: !this.state.challengesCollapsed })
  }
  toggleProcess() {
    this.setState({ processCollapsed: !this.state.processCollapsed })
  }
  toggleDealers() {
    if (!this.state.dealers || !Array.isArray(this.state.dealers) || this.state.dealers.length == 0) {
      CVR2Actions.getCompetitionCompanies()
    }
    this.setState({ dealersCollapsed: !this.state.dealersCollapsed })
  }
  toggleDealerClean() {
    if (!this.state.dealerClean || !Array.isArray(this.state.dealerClean) || this.state.dealerClean.length == 0) {
      CVR2Actions.getDealerClean()
    }
    this.setState({ dealerCleanCollapsed: !this.state.dealerCleanCollapsed })
  }
  render() {
    let progress = Array.isArray(this.state.progress) ? this.state.progress : [this.state.progress]
    let challenges = Array.isArray(this.state.challenges) ? this.state.challenges : [this.state.challenges]
    challenges = challenges.filter(e => {
      return e.userCompanyUID ? e.userCompanyUID.toString().indexOf(this.state.filterChallenges) !== -1 : true
    })
    return (
      <React.Fragment>
        <Card>
          <CardHeader>
            <strong>Info om konkurrencen</strong>
          </CardHeader>
          <CardBody>
            <CompetitionForm
              disabled={moment()
                .endOf('day')
                .isAfter(this.state.CVR2Competition.timeStart)}
            />
          </CardBody>
        </Card>
        {!moment()
          .endOf('day')
          .isAfter(this.state.CVR2Competition.timeStart) && (
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <FontAwesomeIcon
                    fixedWidth
                    icon={this.state.dealersCollapsed ? 'align-justify' : 'times'}
                    onClick={this.toggleDealers.bind(this)}
                  />{' '}
                  <strong>Mulige deltagere</strong>
                </Col>
                <Col>
                  {!this.state.dealersCollapsed && (
                    <Button
                      disabled={Object.keys(this.state.checkboxes).length == 0}
                      onClick={e => {
                        e.preventDefault()
                        CVR2Actions.sendInvites()
                      }}
                    >
                      Tilføj brugere til konkurrencen
                    </Button>
                  )}
                </Col>
                <Col>
                  {!this.state.dealersCollapsed && (
                    <InputGroup className='float-right'>
                      <Input
                        type='text'
                        id='filter'
                        name='filter'
                        placeholder='Filtrer'
                        value={this.state.filter}
                        onChange={e => {
                          CVR2Actions.updateFilter(e.target.value)
                        }}
                      />
                      <InputGroupAddon addonType='append'>
                        <InputGroupText>
                          <FontAwesomeIcon icon='search' />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={!this.state.dealersCollapsed}>
              <CardBody>
                <CompetitionDealers redraw={this.state.dealers.length} />
              </CardBody>
            </Collapse>
          </Card>
        )}
        <Card>
          <CardHeader>
            <FontAwesomeIcon fixedWidth icon={this.state.processCollapsed ? 'align-justify' : 'times'} onClick={this.toggleProcess.bind(this)} />{' '}
            <strong>Status</strong>
          </CardHeader>
          <Collapse isOpen={!this.state.processCollapsed}>
            <CardBody>
              <ListGroup>
                {progress.map((e, key) => {
                  return (
                    <ListGroupItem key={key}>
                      <Badge pill>{e.rank}</Badge> {e.elementTitle} ({e.CVR}) <Badge pill>{e.ruleComputedValue}</Badge>
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            </CardBody>
          </Collapse>
        </Card>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <FontAwesomeIcon
                  fixedWidth
                  icon={this.state.challengesCollapsed ? 'align-justify' : 'times'}
                  onClick={this.toggleChallenges.bind(this)}
                />{' '}
                <strong>Deltagere</strong>
              </Col>
              <Col>
                {!this.state.challengesCollapsed && (
                  <InputGroup className='float-right'>
                    <Input
                      type='text'
                      id='filter'
                      name='filter'
                      placeholder='Filtrer'
                      value={this.state.filterChallenges}
                      onChange={e => {
                        this.setState({ filterChallenges: e.target.value })
                      }}
                    />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText>
                        <FontAwesomeIcon icon='search' />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                )}
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={!this.state.challengesCollapsed}>
            <CardBody>
              <ListGroup>
                {challenges.map((e, key) => {
                  return (
                    <ListGroupItem key={key}>
                      {e.userName}, {e.userCompany} ({e.userCompanyUID}) <Badge color='success'>{e.userSupervisor}</Badge>{' '}
                      {!moment()
                        .endOf('day')
                        .isAfter(this.state.CVR2Competition.timeStart) && (
                        <Button
                          color='danger'
                          onClick={b => {
                            CVR2Actions.removeChallenge(e.rawChallenge)
                            b.preventDefault()
                          }}
                        >
                          Slet
                        </Button>
                      )}
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            </CardBody>
          </Collapse>
        </Card>
        <Card>
          <CardHeader>
            <FontAwesomeIcon
              fixedWidth
              icon={this.state.dealerCleanCollapsed ? 'align-justify' : 'times'}
              onClick={this.toggleDealerClean.bind(this)}
            />{' '}
            <strong>Virksomheder</strong>
          </CardHeader>
          <Collapse isOpen={!this.state.dealerCleanCollapsed}>
            <CardBody>
              <ListGroup>
                {this.state.dealerClean.map((elm, key) => {
                  return (
                    <ListGroupItem key={key}>
                      <Label htmlFor={elm.DealerCVR}>{elm.DealerCVR}</Label>
                      <Input
                        type='text'
                        value={elm.dealerName}
                        name={elm.DealerCVR}
                        id={elm.DealerCVR}
                        onChange={e => {
                          CVR2Actions.updateDealerCleanField(e)
                        }}
                      />
                    </ListGroupItem>
                  )
                })}
                {this.state.dealerClean.length > 0 && (
                  <ListGroupItem>
                    <Button onClick={CVR2Actions.saveDealerClean}>Opdater CVR navne</Button>
                  </ListGroupItem>
                )}
              </ListGroup>
            </CardBody>
          </Collapse>
        </Card>
      </React.Fragment>
    )
  }
}

class CompetitionDealers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dealerEmployeesOpen: {}
    }
    this.store = CVR2Store
    this.storeKeys = ['dealers', 'dealerUsers', 'filter']
  }
  toggleDealerEmployees(dealerID) {
    let dealerEmployeesOpen = this.state.dealerEmployeesOpen
    if (!this.state.dealerUsers[dealerID]) {
      CVR2Actions.getCompetitionUsers(dealerID)
    }
    dealerEmployeesOpen[dealerID] = dealerEmployeesOpen[dealerID] ? false : true
    this.setState({ dealerEmployeesOpen: dealerEmployeesOpen })
  }
  render() {
    if (!this.state.dealers || !Array.isArray(this.state.dealers) || this.state.dealers.length == 0) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    let dealers = this.state.dealers
    if (this.state.filter && Array.isArray(dealers) && dealers.length > 0) {
      let filter = this.state.filter.toLowerCase()
      dealers = dealers.filter(elm => {
        let Dealer_Navn = elm.Dealer_Navn.toLowerCase().indexOf(filter) !== -1
        let Dealer_CVR = elm.Dealer_CVR.toLowerCase().indexOf(filter) !== -1
        return Dealer_Navn || Dealer_CVR
      })
    }
    return (
      <ListGroup>
        {dealers.map((e, key) => {
          //if(!this.state.dealerEmployeesOpen[e.Dealer_ID] || this.state.dealerEmployeesOpen[e.Dealer_ID].length == 0) {return null}
          return (
            <ListGroupItem key={key}>
              <FontAwesomeIcon
                fixedWidth
                icon={this.state.dealerEmployeesOpen[e.Dealer_ID] ? 'times' : 'align-justify'}
                onClick={() => {
                  this.toggleDealerEmployees(e.Dealer_ID)
                }}
              />
              {e.Closed == 'Y' && <Badge color='danger'>LUKKET</Badge>}
              {e.Dealer_Navn} ({e.Dealer_CVR})
              <Collapse isOpen={this.state.dealerEmployeesOpen[e.Dealer_ID]}>
                <CompetitionUsers
                  id={e.Dealer_ID}
                  users={this.state.dealerUsers.hasOwnProperty(e.Dealer_ID) ? this.state.dealerUsers[e.Dealer_ID] : null}
                />
              </Collapse>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    )
  }
}

class CompetitionUsers extends Component {
  constructor(props) {
    super(props)
    this.store = CVR2Store
    this.storeKeys = ['challenges', 'checkboxes']
  }
  render() {
    if (this.props.users) {
      let Dealer_ID = this.props.Dealer_ID
      let users = []
      let allChecked = true
      let list = this.props.users.map((e, key) => {
        if (!this.state.checkboxes.hasOwnProperty(e.UserID)) {
          allChecked = false
        }
        users.push(e.UserID)
        return (
          <ListGroupItem key={key}>
            <Input
              className='form-check-input'
              type='checkbox'
              id={'checkbox' + e.UserID}
              name={'checkbox' + e.UserID}
              value={true}
              checked={this.state.checkboxes[e.UserID] ? true : false}
              onChange={() => {
                CVR2Actions.switchUserCheck(e.UserID)
              }}
            />
            <Label check htmlFor={'checkbox' + e.UserID}>
              {e.userName}
            </Label>
          </ListGroupItem>
        )
      })
      return (
        <ListGroup>
          {users.length > 0 && (
            <ListGroupItem>
              <Input
                className='form-check-input'
                type='checkbox'
                id={'checkbox' + Dealer_ID}
                name={'checkbox' + Dealer_ID}
                value={true}
                checked={allChecked ? true : false}
                onChange={e => {
                  CVR2Actions.switchAllUsers(users, e)
                }}
              />
              <Label check htmlFor={'checkbox' + Dealer_ID}>
                Hele virksomheden
              </Label>
            </ListGroupItem>
          )}
          {list}
        </ListGroup>
      )
    }
    return null
  }
}

class CompetitionForm extends Component {
  constructor(props) {
    super(props)
    this.store = CVR2Store
    this.storeKeys = ['CVR2Competition', 'redirectOnDelete']
  }
  render() {
    if (this.state.redirectOnDelete) {
      return <Redirect to='.' />
    }
    let disabled = this.props.disabled ? this.props.disabled : false
    let competition = this.state.CVR2Competition
    return (
      <Form
        onSubmit={e => {
          CVR2Actions.createCompetition()
          e.preventDefault()
          return false
        }}
      >
        <FormGroup>
          <Label htmlFor='moduleName'>Navn</Label>
          <Input disabled={disabled} value={competition.moduleName} name='moduleName' id='moduleName' onChange={CVR2Actions.updateCompetitionField} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor='moduleDescription'>Beskrivelse</Label>
          <Input
            disabled={disabled}
            type='textarea'
            value={competition.moduleDescription}
            name='moduleDescription'
            id='moduleDescription'
            onChange={CVR2Actions.updateCompetitionField}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor='timeStart'>Start</Label>
          <DatePicker
            className='form-control'
            disabled={disabled}
            minDate={moment().add(1, 'days')}
            selected={moment(competition.timeStart)}
            dateFormat={'DD/MM/YYYY'}
            viewMode={'days'}
            name='timeStart'
            id='timeStart'
            onChange={e => {
              CVR2Actions.updateCompetitionField(e, 'timeStart')
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor='timeEnd'>Slut</Label>
          <DatePicker
            className='form-control'
            disabled={disabled}
            minDate={moment(competition.timeStart).add(1, 'days')}
            selected={moment(competition.timeEnd)}
            dateFormat={'DD/MM/YYYY'}
            viewMode={'days'}
            name='timeEnd'
            id='timeEnd'
            onChange={e => {
              CVR2Actions.updateCompetitionField(e, 'timeEnd')
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor='wager'>Pulje</Label>
          <Input
            disabled={disabled}
            type='number'
            min={0}
            value={competition.wager}
            name='wager'
            id='wager'
            onChange={CVR2Actions.updateCompetitionField}
          />
        </FormGroup>
        {!disabled && (
          <Button
            type='button'
            onClick={e => {
              CVR2Actions.createCompetition()
              e.preventDefault()
            }}
          >
            {competition.CompetitionID ? 'Opdater' : 'Opret'} konkurrence
          </Button>
        )}
        {!disabled && competition.CompetitionID && (
          <Button
            onClick={() => {
              if (confirm('Vil du slette denne konkurrence?')) {
                CVR2Actions.deleteCompetition()
              }
            }}
            color='danger'
          >
            Slet konkurrence
          </Button>
        )}
      </Form>
    )
  }
}
