/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
import { uniq } from 'Components/functions'
import moment from 'moment'

const CVR2Actions = Reflux.createActions([
  'clearCompetitionError',
  'getCompetition',
  'getCompetitions',
  'getCompetitionCompanies',
  'getCompetitionUsers',
  'createCompetition',
  'clearCompetition',
  'updateCompetitionField',
  'challengeUser',
  'removeChallenge',
  'updateFilter',
  'switchUserCheck',
  'switchAllUsers',
  'sendInvites',
  'getDealerClean',
  'updateDealerCleanField',
  'saveDealerClean',
  'deleteCompetition'
])

class CVR2Store extends Reflux.Store {
  /* PRIVATE FUNCTIONS */
  constructor(props) {
    super(props)
    this.listenables = CVR2Actions
    this.timeFix = { hour: 12, minute: 0, second: 0, millisecond: 0 }
    this.state = this.emptyState()
  }
  emptyState() {
    return {
      challenges: [],
      checkboxes: {},
      competitionDetails: null,
      competitionError: false,
      CVR2Competition: this.emptyCompetition(),
      CVR2Competitions: [],
      dealerClean: [],
      dealerCVRs: [],
      dealers: [],
      dealerUsers: {},
      filter: '',
      loading: false,
      progress: [],
      redirectOnDelete: false
    }
  }
  emptyCompetition() {
    return {
      CompetitionID: null,
      id: null,
      moduleDescription: '',
      moduleName: '',
      poolOrder: 0,
      status: 'READY',
      timeEnd: moment()
        .add(2, 'days')
        .set(this.timeFix)
        .utc()
        .set(this.timeFix)
        .format(),
      timeStart: moment()
        .add(1, 'days')
        .set(this.timeFix)
        .utc()
        .set(this.timeFix)
        .format(),
      type: 'CVR',
      wager: 0
    }
  }
  fixTimes(elm) {
    elm.timeStart = moment(elm.timeStart)
      .set(this.timeFix)
      .format()
    elm.timeEnd = moment(elm.timeEnd)
      .set(this.timeFix)
      .format()
    return elm
  }
  getDealers() {
    if (
      Reflux.GlobalState.LoginStore &&
      Reflux.GlobalState.LoginStore.user &&
      Reflux.GlobalState.LoginStore.user.abilities &&
      Reflux.GlobalState.LoginStore.user.abilities.indexOf('MANAGE') != -1
    ) {
      return Proxies.dealerPool()
    }
    return Proxies.dealerMine()
  }
  getUsers(Dealer_ID = null) {
    let filter = {
      BrandID: Reflux.GlobalState.LoginStore.user.BrandID,
      removed: 0,
      userAccess: 'APPROVED'
    }
    if (Dealer_ID) {
      let dealer = this.state.dealers.find(d => {
        return d.Dealer_ID == Dealer_ID
      })
      if (dealer) {
        if (dealer.sellers && dealer.sellers.length) {
          let or = []
          or.push({ userUID: { inq: dealer.sellers } })
          or.push({ userDepartmentUID: Dealer_ID })
          filter.or = or
        } else {
          filter.userDepartmentUID = Dealer_ID
        }
      } else {
        filter.userDepartmentUID = Dealer_ID
      }
    }
    return Proxies.userPool(filter)
  }
  getCompetitionProcess(CompetitionID) {
    Proxies.competitionProcess(CompetitionID).then(responseJSON => {
      if (responseJSON) {
        this.setState({ progress: this.fixTimes(responseJSON) })
      }
    })
    let UserIDs = []
    return Proxies.getChallengesByCompetitionID(CompetitionID).then(responseJSON => {
      if (responseJSON) {
        if (!Array.isArray(responseJSON)) {
          responseJSON = [responseJSON]
        }
        let nextState = { challenges: responseJSON, checkboxes: {}, dealerCVRs: [] }
        responseJSON.map(elm => {
          UserIDs.push(elm.TargetUserID)
          nextState.checkboxes[elm.TargetUserID] = true
        })
        Proxies.userPool({ UserID: { inq: UserIDs } }).then(responseJSON => {
          if (responseJSON) {
            responseJSON.forEach(elm => {
              nextState.dealerCVRs.push(elm.userCompanyUID)
            })
            nextState.dealerCVRs = uniq(nextState.dealerCVRs)
            nextState.challenges.forEach(elm => {
              let thisUser = responseJSON.find(e => {
                return e.UserID == elm.TargetUserID
              })
              let tmpObject = Object.assign({}, elm)
              Object.assign(elm, { rawChallenge: tmpObject })
              Object.assign(elm, thisUser)
            })
            this.setState(nextState)
            this.getDealerClean()
          }
        })
        this.setState(nextState)
        return responseJSON
      }
    })
  }
  cleanDealer(name) {
    return name.replace(/\([^)]*\)*/g, '').replace('LUKKET', '')
  }
  /* PUBLIC FUNCTIONS*/
  clearCompetitionError() {
    this.setState({ competitionError: false })
  }
  getCompetition(CompetitionID) {
    Proxies.competitionFetch(CompetitionID).then(responseJSON => {
      if (responseJSON) {
        let nextState = { CVR2Competition: responseJSON }
        this.getCompetitionProcess(CompetitionID)
        this.setState(nextState)
        return true
      }
      this.setState({ competitionError: true })
      return false
    })
  }
  getCompetitions() {
    this.setState(this.emptyState())
    Proxies.competitionMine().then(responseJSON => {
      if (responseJSON) {
        responseJSON = responseJSON
          .filter(elm => {
            return elm.type != 'regular'
          })
          .map(elm => {
            return this.fixTimes(elm)
          })
        this.setState({ CVR2Competitions: responseJSON })
        return true
      }
      this.setState({ competitionError: true })
      return false
    })
  }
  getCompetitionCompanies() {
    let nextState = { dealers: [], dealerUsers: {} }
    this.getDealers().then(responseJSON => {
      if (responseJSON) {
        responseJSON = responseJSON
          .filter(elm => {
            return elm.Salesclub !== 'N' && elm.Closed === 'N'
          })
          .map(elm => {
            elm.Dealer_Navn = this.cleanDealer(elm.Dealer_Navn)
            return elm
          })
        nextState.dealers = responseJSON
        this.setState(nextState)
      }
    })
  }
  getCompetitionUsers(Dealer_ID) {
    let nextState = { dealerUsers: this.state.dealerUsers }
    nextState.dealerUsers[Dealer_ID] = false
    this.getUsers(Dealer_ID).then(responseJSON => {
      if (responseJSON) {
        nextState.dealerUsers[Dealer_ID] = responseJSON
      }
      this.setState(nextState)
    })
  }
  createCompetition() {
    let id = this.state.CVR2Competition.CompetitionID
      ? this.state.CVR2Competition.CompetitionID
      : this.state.CVR2Competition.id
      ? this.state.CVR2Competition.id
      : false
    let competition = this.state.CVR2Competition
    if (!competition.CompetitionID) {
      delete competition.CompetitionID
    }
    if (!competition.id) {
      delete competition.id
    }
    competition.type = 'CVR'
    if (id) {
      Proxies.competitionUpdate(competition, id).then(responseJSON => {
        if (responseJSON) {
          this.setState({ CVR2Competition: responseJSON })
        }
      })
    } else {
      Proxies.competitionCreate(competition).then(responseJSON => {
        if (responseJSON) {
          this.setState({ CVR2Competition: responseJSON })
        }
      })
    }
  }
  clearCompetition() {
    this.setState(this.emptyState())
  }
  updateCompetitionField(e, field) {
    let competition = this.state.CVR2Competition
    if (field && e._isAMomentObject) {
      competition[field] = e.set(this.timeFix).format()
      if (field == 'timeStart') {
        if (moment(competition.timeEnd).isBefore(e)) {
          competition.timeEnd = e
            .add(1, 'day')
            .set(this.timeFix)
            .format()
        }
      } else if (field == 'timeEnd') {
        if (moment(competition.timeStart).isAfter(e)) {
          competition.timeStart = e
            .add(-1, 'day')
            .set(this.timeFix)
            .format()
        }
      }
    } else {
      if (e.target.name == 'wager') {
        competition[e.target.name] = e.target.valueAsNumber
      } else {
        competition[e.target.name] = e.target.value
      }
    }
    this.setState({ CVR2Competition: competition })
  }
  challengeUser(UserID = null) {
    if (this.state.CVR2Competition.CompetitionID && UserID) {
      Proxies.challengeCreate({
        UserID: Reflux.GlobalState.LoginStore.UserID,
        TargetUserID: parseInt(UserID),
        CompetitionID: this.state.CVR2Competition.CompetitionID,
        status: 'ACCEPTED',
        Locked: true
      }).then(() => {
        this.getCompetitionProcess(this.state.CVR2Competition.CompetitionID)
      })
    }
  }
  removeChallenge(challenge = null) {
    if (challenge) {
      Proxies.challengeRemove(challenge).then(() => {
        this.getCompetitionProcess(this.state.CVR2Competition.CompetitionID)
      })
    }
  }
  updateFilter(text = '') {
    this.setState({ filter: text })
  }
  switchUserCheck(UserID) {
    let checkboxes = this.state.checkboxes
    // eslint-disable-next-line
    if (checkboxes.hasOwnProperty(UserID)) {
      delete checkboxes[UserID]
    } else {
      checkboxes[UserID] = true
    }
    this.setState({ checkboxes: checkboxes })
  }
  switchAllUsers(Users, e) {
    let checkboxes = this.state.checkboxes
    if (Array.isArray(Users)) {
      Users.forEach(UserID => {
        // eslint-disable-next-line
        if (!checkboxes.hasOwnProperty(UserID) && e.target.checked) {
          checkboxes[UserID] = true
          // eslint-disable-next-line
        } else if (checkboxes.hasOwnProperty(UserID) && !e.target.checked) {
          delete checkboxes[UserID]
        }
      })
    }
    this.setState({ checkboxes: checkboxes })
  }
  sendInvites() {
    let deleteChallenges = []
    let createChallenges = []
    let promises = []
    let checkboxes = this.state.checkboxes
    let challenges = this.state.challenges
    for (let box in checkboxes) {
      // eslint-disable-next-line
      if (!checkboxes.hasOwnProperty(box)) {
        continue
      }
      let found = challenges.find(elm => {
        return elm.TargetUserID == box
      })
      if (found) {
        deleteChallenges.push(found)
      } else {
        createChallenges.push(box)
      }
    }
    deleteChallenges = deleteChallenges.filter(elm => {
      return elm
    })
    createChallenges = createChallenges.filter(elm => {
      return elm
    })
    //console.log("deleteChallenges", deleteChallenges)
    //console.log("createChallenges", createChallenges)
    /*
		if(deleteChallenges.length > 0) {
			deleteChallenges.forEach((challenge) => {
				challenge.removed = 1
				promises.push(
					Proxies.challengeRemove(challenge)
				)
			})
		}
		*/
    if (createChallenges.length > 0) {
      createChallenges.forEach(UserID => {
        promises.push(
          Proxies.challengeCreate({
            UserID: Reflux.GlobalState.LoginStore.user.UserID,
            BrandID: Reflux.GlobalState.LoginStore.user.BrandID,
            TargetUserID: parseInt(UserID),
            CompetitionID: this.state.CVR2Competition.CompetitionID,
            status: 'ACCEPTED',
            Locked: true
          })
        )
      })
    }
    if (promises.length > 0) {
      this.setState({ loading: true })
      Promise.all(promises).then(() => {
        this.setState({ loading: false })
        this.getCompetitionProcess(this.state.CVR2Competition.CompetitionID).then(() => {
          this.getDealerClean()
        })
      })
    }
  }
  getDealerClean() {
    if (this.state.dealerCVRs.length > 0) {
      Proxies.dealersPool({ DealerCVR: { inq: this.state.dealerCVRs }, BrandID: Reflux.GlobalState.LoginStore.user.BrandID, removed: 0 }).then(
        responseJSON => {
          if (responseJSON) {
            let dealerClean = responseJSON
            if (dealerClean.length !== this.state.dealerCVRs.length) {
              let missingCVRs = this.state.dealerCVRs
              let cleanObject = {
                BrandID: Reflux.GlobalState.LoginStore.user.BrandID,
                DealerCVR: 0,
                dealerName: '',
                dealerNameShort: ''
              }
              dealerClean.map(elm => {
                missingCVRs = missingCVRs.filter(e => e != parseInt(elm.DealerCVR, 10))
              })
              missingCVRs.forEach(elm => {
                let dealerName = this.cleanDealer(this.state.challenges.find(e => e.userCompanyUID === elm).userCompany)
                dealerClean.push(Object.assign({}, cleanObject, { DealerCVR: elm, dealerName: dealerName }))
              })
            }
            this.setState({ dealerClean: dealerClean })
          }
        }
      )
    }
  }
  updateDealerCleanField(e) {
    let dealerClean = this.state.dealerClean
    let dealerCVR = e.target.name
    let dealerName = e.target.value
    dealerClean = dealerClean.map(elm => {
      if (elm.DealerCVR == dealerCVR) {
        elm.dealerName = dealerName
      }
      return elm
    })
    this.setState({ dealerClean: dealerClean })
  }
  saveDealerClean() {
    this.setState({ loading: true })
    Promise.all(
      this.state.dealerClean.map(elm => {
        return Proxies.dealersSave(elm)
      })
    ).then(() => {
      this.getDealerClean()
      this.setState({ loading: false })
    })
  }
  deleteCompetition() {
    if (this.state.CVR2Competition.CompetitionID) {
      Proxies.competitionRemove(this.state.CVR2Competition.CompetitionID).then(() => {
        this.setState(Object.assign({}, this.emptyState(), { redirectOnDelete: true }))
      })
    }
  }
}

export { CVR2Actions, CVR2Store }
