import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import 'moment/locale/da'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { InvoiceStore, InvoiceActions } from 'Stores/invoiceStore'
import { LoginStore } from '../../stores/loginStore'

export default class FreightBrandOverview extends Component {
  constructor(props) {
    super(props)
    this.stores = [InvoiceStore, LoginStore]
    this.storeKeys = ['BrandID', 'invoiceBrandsForFreightOverview', 'fetchingBrands']
  }
  componentDidMount() {
    if (this.state.invoiceBrandsForFreightOverview && !this.state.invoiceBrandsForFreightOverview.length) {
      InvoiceActions.getBrands({ isFreightOverview: true })
    }
  }
  render() {
    if (this.state.BrandID !== 9) {
      return <Redirect to={{ pathname: this.props.location.pathname + '/' + this.state.BrandID }} />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Fragt</strong>{' '}
          </React.Fragment>
        }
      >
        {this.state.fetchingBrands ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            data={this.state.invoiceBrandsForFreightOverview}
            headers={{
              brandName: 'Brand'
            }}
            options={{
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'view' + e.BrandID}>Vis</Tooltip>
                      <Button tag={Link} to={`${this.props.location.pathname}/` + e.BrandID} id={'view' + e.BrandID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
          />
        )}
      </CollapsibleCard>
    )
  }
}
