/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { DatasetStore, DatasetActions, STATUS } from 'Stores/datasetStore'
import DatasetForm from './datasetForm'

export default class DatasetDetails extends Component {
  constructor(props) {
    super(props)
    this.columnsList = []
    this.stores = [DatasetStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    DatasetActions.getDataset(this.props.match.params.DatasetID)
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.DatasetID != prevProps.match.params.DatasetID) {
      DatasetActions.getDataset(this.props.match.params.DatasetID)
    }
    if (this.state.status === STATUS.FETCHING_DATASET_ERROR) {
      toast.error('FETCHING DATASET FAILED', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearToast()
        }
      })
      DatasetActions.clearToast()
    }
    if (this.state.status === STATUS.DATASET_ERROR) {
      toast.error('DATASET UPDATE FAILED', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearToast()
        }
      })
      DatasetActions.clearToast()
    }

    if (this.state.status === STATUS.DATASET_SUCCESS) {
      toast.success('DATASET UPDATE SUCCESSFUL', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearToast()
        }
      })
      DatasetActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <DatasetForm {...this.props} />
      </React.Fragment>
    )
  }
}
