import React, { useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import styled from 'styled-components'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import { colorMap } from 'Components/dashboard/colorMap.js'
import DataNotFound from 'Components/dashboard/DataNotFound'

const ChartWrap = styled.div`
  height: 327px;
`
export default function TotalPointsChart({ header, state, data, legends, applyFilters }) {
  const [filter, setFilter] = useState(null)
  const filteredData = []
  if (filter && applyFilters) {
    data.map(element => {
      filteredData.push({ id: element.id, label: element.label, value: element.label === filter ? element.value : 0 })
      return element
    })
  }
  return (
    <DashboardComponent header={header} state={state}>
      {state === DASHBOARD_COMPONENT_STATE.SUCCESS && (
        <ChartWrap>
          {data && data.length ? (
            <ResponsivePie
              data={filter && applyFilters ? filteredData : data}
              margin={{ top: 85, right: 80, bottom: 40, left: 80 }}
              innerRadius={0}
              padAngle={0.7}
              cornerRadius={3}
              colors={({ id }) => colorMap[`${id}Color`]}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              radialLabel={function(e) {
                return e.id + ' (' + new Intl.NumberFormat().format(e.value) + ')'
              }}
              radialLabelsSkipAngle={10}
              radialLabelsTextXOffset={6}
              radialLabelsTextColor='#333333'
              radialLabelsLinkOffset={0}
              radialLabelsLinkDiagonalLength={16}
              radialLabelsLinkHorizontalLength={24}
              radialLabelsLinkStrokeWidth={1}
              radialLabelsLinkColor={{ from: 'color' }}
              enableSlicesLabels={false}
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor='#333333'
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              tooltipFormat={value => new Intl.NumberFormat().format(value)}
              legends={
                legends || [
                  {
                    anchor: 'top',
                    direction: 'row',
                    translateY: -50,
                    translateX: 10,
                    itemWidth: 80,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'circle',
                    ...(applyFilters && {
                      onClick: d => {
                        filter === d.label ? setFilter(null) : setFilter(d.label)
                      }
                    })
                  }
                ]
              }
            />
          ) : (
            <DataNotFound />
          )}
        </ChartWrap>
      )}
    </DashboardComponent>
  )
}
