import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'

export default class Imports extends React.Component {
    constructor(props) {
      super(props)
    }
    render() {
      return (
        <React.Fragment>
          <ImportsComponent props={this.props} />
        </React.Fragment>
      )
    }
  }
  
  function ImportsComponent({ props }) {
    return (
        <CollapsibleCard startOpen header={<strong>Imports</strong>}>
            <DynamicTable
          responsive
          size='sm'
          options={{
            fieldFunctions: {},
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'import' + e.moduleName}>Import</Tooltip>
                    <Button tag={Link} to={'/import/' + e.path} id={'import' + e.moduleName}>
                      <FontAwesomeIcon icon='file-import' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            moduleName: 'Navn'
          }}
          data={[{moduleName: 'Points', path: 'points'}, {moduleName: 'Brugere', path: 'users'}]}
        />
        </CollapsibleCard>
    )
  }