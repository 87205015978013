import React from 'react'
import { Component } from 'reflux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from 'reactstrap'
import classnames from 'classnames'
import CollapsibleCard from 'Components/collapsibleCard'
import DatePicker from 'react-datepicker'
import DynamicTable from 'Components/dynamicTable'
import LineChart from 'Components/dashboard/charts/LineChart'
import { KamActions, KamStore } from 'Stores/jyskeKamStore'
import moment from 'moment'
import { Line } from 'react-chartjs-2'

const StyledFromDateDiv = styled.div`
    display: inline-block;
    float: left;
    padding: 5px 17px 5px 0px;
}
`
const StyledToDateDiv = styled.div`
    margin-left: 20px;
    display: inline-block;
    padding: 5px 17px 5px 0px;
}
`

const StyledNavLink = styled(NavLink)`
  color: green !important;
  padding: 10px 20px;
`

const StyledTabContent = styled(TabContent)`
  background: none;
`

const StyledWrapper = styled.div`
  .react-datepicker {
    border: none !important;
  }
  .react-datepicker__header {
    background-color: white !important;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    margin-bottom: 10px !important;
  }
`
const ChartWrap = styled.div`
  height: 300px;
`

const StyledCompanyParagraph = styled.p`
  text-align: center !important;
  padding: 0px;
  margin: 0px 0px 0px 50px;
  color: #57595a;
`

const StyledAlert = styled(Alert)`
  display: inline-block !important;
  margin: 0px 0px 15px 7px;
`

export default class JyskeDashboardComponent extends Component {
  constructor(props) {
    super(props)
    this.store = KamStore
  }

  componentDidMount() {
    KamActions.getAllUsers()
    KamActions.getAllCompanies()
  }

  DataNotFound = () => {
    return (
      <p>
        <strong>No Data Found</strong>
      </p>
    )
  }

  TimePeriod = () => {
    return (
      <React.Fragment>
        <StyledFromDateDiv>
          <p className='text-center'>
            <strong>Fra</strong>
          </p>
          <StyledWrapper>
            <DatePicker
              showMonthDropdown
              fixedHeight
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              style={{ border: 'none' }}
              name='startDate'
              selected={moment(this.state.startDate)}
              inline
              onSelect={e => KamActions.updateDate(e, 'startDate')}
            />
          </StyledWrapper>
        </StyledFromDateDiv>
        <StyledToDateDiv>
          <p className='text-center'>
            <strong>Til</strong>
          </p>
          <StyledWrapper>
            <DatePicker
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              fixedHeight
              style={{ border: 'none' }}
              name='endDate'
              selected={moment(this.state.endDate)}
              inline
              onSelect={e => KamActions.updateDate(e, 'endDate')}
            />
          </StyledWrapper>
        </StyledToDateDiv>
        <Row>
          <Button style={{ margin: '4px 25px' }} onClick={() => KamActions.getData()}>
            Hent Data
          </Button>
        </Row>
      </React.Fragment>
    )
  }
  render() {
    let userGraph = (
      <LineChart
        data={[{ id: this.state.selectedUser ? this.state.selectedUser : 'Vælg Brugere', data: [] }]}
        emptyGraph={true}
        axisLeft={{ tickValues: 10 }}
      />
    )
    const { activeTab } = this.state
    let graphBaseData = {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              suggestedMin: 1
            }
          }
        ]
      },
      animation: false
    }
    if (this.state.overviewFilterUser) {
      if (this.state.overviewUserData && this.state.overviewUserData.length > 0 && this.state.overviewUsers && !!this.state.overviewUsers.length) {
        let graphData = {
          labels: this.state.overviewUserData.map(e => {
            return `${e.Day}/${e.Month}/${e.Year}`
          }),
          datasets: [
            {
              label: this.state.selectedUser,
              borderColor: '#3e95cd',
              fill: false,
              data: this.state.overviewUserData.map(e => {
                return e.amount
              })
            }
          ]
        }
        userGraph = <Line data={graphData} options={graphBaseData} height={300} width={200} />
      }
    }
    let companyGraph = (
      <LineChart
        data={[{ id: this.state.selectedCompany ? this.state.selectedCompany : 'Vælg Virksomhed', data: [] }]}
        emptyGraph={true}
        axisLeft={{ tickValues: 10 }}
      />
    )

    if (this.state.overviewFilterCompany) {
      if (
        this.state.overviewCompanyData &&
        this.state.overviewCompanyData.length > 0 &&
        this.state.overviewUsers &&
        !!this.state.overviewUsers.length
      ) {
        let graphData = {
          labels: this.state.overviewCompanyData.map(e => {
            return `${e.Day}/${e.Month}/${e.Year}`
          }),
          datasets: [
            {
              label: this.state.selectedCompany,
              borderColor: '#3e95cd',
              fill: false,
              data: this.state.overviewCompanyData.map(e => {
                return e.amount
              })
            }
          ]
        }
        companyGraph = <Line data={graphData} options={graphBaseData} height={300} width={200} />
      }
    }
    let userOverview = this.state.overviewUsers
    if (this.state.isCompanyFilter) {
      userOverview = userOverview.filter(e => {
        return e.cvr === this.state.overviewFilterCompany
      })
    }
    if (this.state.isUserFilter) {
      userOverview = userOverview.filter(e => {
        return e.KAM === this.state.selectedKam
      })
    }
    if (this.state.isCVRFilter) {
      userOverview = userOverview.filter(e => {
        return e.cvr === this.state.selectedCVR
      })
    }
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <StyledNavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    KamActions.changeTab('1')
                  }}
                >
                  <FontAwesomeIcon icon='table' />
                  <span style={{ marginLeft: '10px', padding: '0px 5px' }}>Overblik</span>
                </StyledNavLink>
              </NavItem>
              <NavItem>
                <StyledNavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    KamActions.changeTab('2')
                  }}
                >
                  <FontAwesomeIcon icon='chart-pie' />
                  <span style={{ marginLeft: '10px' }}>Adfærd</span>
                </StyledNavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <div>
          <StyledTabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <Row>
                <Col sm='12'>
                  <CollapsibleCard style={{ display: 'block' }} header={<strong>Vælg periode</strong>} startOpen={'true'}>
                    <this.TimePeriod />
                  </CollapsibleCard>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <CollapsibleCard header={<strong>Statistik</strong>} startOpen={this.state.overviewUsers.length > 0 ? true : false}>
                    <Row>
                      <Col xs={6}>
                        <UncontrolledDropdown setActiveFromChild>
                          <DropdownToggle>
                            Vælg Virksomhed {this.state.fetchOverviewData.fetchCompanySales && <FontAwesomeIcon icon='spinner' spin />}
                          </DropdownToggle>
                          <DropdownMenu
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: 'auto',
                                      maxHeight: '200px'
                                    }
                                  }
                                }
                              }
                            }}
                          >
                            {this.state.kamCompanies &&
                              !!this.state.kamCompanies.length &&
                              this.state.kamCompanies.map((item, index) => {
                                return (
                                  <DropdownItem onClick={() => KamActions.getCompanySales(item)} value={item.userCompanyUID} key={index}>
                                    {item.userCompany}
                                  </DropdownItem>
                                )
                              })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <StyledCompanyParagraph>
                          <strong>{this.props.company},Virksomhed</strong>
                        </StyledCompanyParagraph>
                        <ChartWrap>{companyGraph}</ChartWrap>
                      </Col>
                      <Col xs={6}>
                        <UncontrolledDropdown setActiveFromChild>
                          <DropdownToggle>
                            Vælg Bruger {this.state.fetchOverviewData.fetchUserSales && <FontAwesomeIcon icon='spinner' spin />}
                          </DropdownToggle>
                          <DropdownMenu
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: 'auto',
                                      maxHeight: '200px'
                                    }
                                  }
                                }
                              }
                            }}
                          >
                            {this.state.kamUsers &&
                              !!this.state.kamUsers.length &&
                              this.state.kamUsers.map((item, index) => {
                                return (
                                  <DropdownItem onClick={() => KamActions.getUserSales(item)} value={item.UserID} key={index}>
                                    {item.userName}
                                  </DropdownItem>
                                )
                              })}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <StyledCompanyParagraph>
                          <strong>{this.props.company},Brugere</strong>
                        </StyledCompanyParagraph>
                        <ChartWrap>{userGraph}</ChartWrap>
                      </Col>
                    </Row>
                  </CollapsibleCard>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <CollapsibleCard
                    header={<strong>Brugere {this.state.fetchOverviewData.fetchUsers && <FontAwesomeIcon icon='spinner' spin />} </strong>}
                    startOpen={this.state.overviewUsers && this.state.overviewUsers.length > 0 ? true : false}
                  >
                    {this.state.isCompanyFilter && (
                      <StyledAlert color='success' isOpen={this.state.isCompanyFilter} toggle={() => KamActions.clearFilter('company')}>
                        {this.state.selectedCompanyName}
                      </StyledAlert>
                    )}

                    {this.state.isUserFilter && (
                      <StyledAlert color='success' isOpen={this.state.isUserFilter} toggle={() => KamActions.clearFilter('kam')}>
                        {this.state.selectedKam}
                      </StyledAlert>
                    )}
                    {this.state.isCVRFilter && (
                      <StyledAlert color='success' isOpen={this.state.isCVRFilter} toggle={() => KamActions.clearFilter('cvr')}>
                        {this.state.selectedCVR}
                      </StyledAlert>
                    )}
                    <DynamicTable
                      customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                      responsive
                      size='sm'
                      headers={{
                        ID: 'Bruger ID',
                        active: 'Aktiv bruger',
                        KAM: 'KAM',
                        name: 'Navn',
                        company: 'Virksomhed',
                        cvr: 'CVR',
                        postsReadInPeriod: 'Antal poster læst i perioden',
                        postsReadTotal: 'Totalt antal poster læst',
                        postsInPeriod: 'Antal poster i perioden',
                        postsTotal: 'Total antal poster',
                        pointsEarned: 'Optjente point',
                        pointsSpend: 'Brugte point',
                        pointsTotal: 'Total antal point'
                      }}
                      options={{
                        fieldFunctions: {
                          KAM: e => {
                            return (
                              <span style={{ color: '#51b89b', cursor: 'pointer' }} onClick={() => KamActions.filterCompany(e, 'kam')}>
                                {e.KAM}
                              </span>
                            )
                          },
                          cvr: e => {
                            return (
                              <span style={{ color: '#51b89b', cursor: 'pointer' }} onClick={() => KamActions.filterCompany(e, 'cvr')}>
                                {e.cvr}
                              </span>
                            )
                          },
                          company: e => {
                            return (
                              <span style={{ color: '#51b89b', cursor: 'pointer' }} onClick={() => KamActions.filterCompany(e, 'company')}>
                                {e.company}
                              </span>
                            )
                          },
                          active: e => {
                            return e.active === 0 ? 'Nej' : 'ja'
                          }
                        }
                      }}
                      data={userOverview}
                    />
                  </CollapsibleCard>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId='2'>
              <React.Fragment>
                <CollapsibleCard style={{ display: 'block' }} header={<strong>Vælg periode</strong>} startOpen={true}>
                  <this.TimePeriod />
                </CollapsibleCard>
                <CollapsibleCard
                  header={
                    <strong>
                      Nye poster i den valgte periode {this.state.fetchBehaviourData.fetchBehaviourPosts && <FontAwesomeIcon icon='spinner' spin />}{' '}
                    </strong>
                  }
                  startOpen={this.state.behaviourPosts.length > 0 ? true : false}
                >
                  <DynamicTable
                    customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                    responsive
                    size='sm'
                    data={this.state.behaviourPosts}
                    headers={{
                      elementTitle: 'Post',
                      postTimeInsert: 'Post Dato',
                      blog: 'Blog'
                    }}
                    options={{
                      fieldFunctions: {
                        blog: e => {
                          let rVal = e.blogTitle
                          if (e.removedBlog === '1') {
                            rVal = rVal + ' (slettet)'
                          }
                          return rVal
                        },
                        postTimeInsert: e => {
                          return e.postTimeInsert ? moment(e.postTimeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                        }
                      },
                      fieldStringFunctions: {
                        postTimeInsert: e => {
                          return moment(e.postTimeInsert).format('x')
                        }
                      }
                    }}
                  />
                </CollapsibleCard>
                <CollapsibleCard
                  header={
                    <strong>
                      Poster læst i den valgte periode{' '}
                      {this.state.fetchBehaviourData.fetchBehaviourPostsRead && <FontAwesomeIcon icon='spinner' spin />}
                    </strong>
                  }
                  startOpen={this.state.behaviourPostsRead.length > 0 ? true : false}
                >
                  <DynamicTable
                    customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                    responsive
                    size='sm'
                    data={this.state.behaviourPostsRead}
                    headers={{
                      UserID: 'Bruger ID',
                      userName: 'Navn',
                      userCompany: 'Virksomhed',
                      userCompanyUID: 'CVR',
                      elementTitle: 'Post',
                      postTimeInsert: 'Post dato',
                      eventTimeInsert: 'Read dato'
                    }}
                    options={{
                      fieldFunctions: {
                        postTimeInsert: e => {
                          return e.postTimeInsert ? moment(e.postTimeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                        },
                        eventTimeInsert: e => {
                          return e.eventTimeInsert ? moment(e.eventTimeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                        }
                      },
                      fieldStringFunctions: {
                        postTimeInsert: e => {
                          return moment(e.postTimeInsert).format('x')
                        },
                        eventTimeInsert: e => {
                          return moment(e.eventTimeInsert).format('x')
                        }
                      }
                    }}
                  />
                </CollapsibleCard>
                <CollapsibleCard
                  header={
                    <strong>Aktive Brugere {this.state.fetchBehaviourData.fetchActiveUsers && <FontAwesomeIcon icon='spinner' spin />} </strong>
                  }
                  startOpen={this.state.behaviourActiveUsers.length > 0 ? true : false}
                >
                  <DynamicTable
                    headers={{
                      UserID: 'Bruger ID',
                      brandName: 'Brand navn',
                      userName: 'Navn',
                      userCompany: 'Virksomhed',
                      userEmail: 'Email',
                      userPhone: 'Tlf.nr'
                    }}
                    customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                    data={this.state.behaviourActiveUsers}
                  />
                </CollapsibleCard>
                <CollapsibleCard
                  header={<strong>Likes {this.state.fetchBehaviourData.fetchLikes && <FontAwesomeIcon icon='spinner' spin />}</strong>}
                  startOpen={this.state.behaviourLikes.length > 0 ? true : false}
                >
                  <DynamicTable
                    customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                    headers={{
                      userName: 'Navn',
                      userEmail: 'Email',
                      userCompany: 'Virksomhed',
                      elementTitle: 'Titel',
                      timeInsert: 'Dato'
                    }}
                    data={this.state.behaviourLikes}
                    options={{
                      fieldFunctions: {
                        timeInsert: e => {
                          return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                        }
                      },
                      fieldStringFunctions: {
                        timeInsert: e => {
                          return moment(e.timeInsert).format('x')
                        }
                      }
                    }}
                  />
                </CollapsibleCard>
              </React.Fragment>
            </TabPane>
          </StyledTabContent>
        </div>
      </React.Fragment>
    )
  }
}
