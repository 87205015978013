import React from 'react'
import { Component } from 'reflux'
import 'moment/locale/da'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { KamActions, KamStore } from 'Stores/santanderKamStore'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { LoginStore } from 'Stores/loginStore'
import moment from 'moment'

let headers = {
  userSupervisor: 'KAM Navn',
  userName: 'Bruger Navn',
  userAccess: 'Adgang',
  userCompany: 'Virksomhed',
  userUID: 'Unikt ID',
  userPhone: 'Telefon',
  userEmail: 'Email',
  userStatus: 'Bruger Status',
  userCompanyUID: 'DealerCVR',
  userDepartmentUID: 'DealerID'
}

export default class KamDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [KamStore, LoginStore]
    this.storeKeys = [
      'settings',
      'kamUsers',
      'activeUsers',
      'inActiveUsers',
      'isGettingData',
      'viewMode',
      'kamWalletTransactions',
      'kamCategoryDetails',
      'kamProductDetails'
    ]
  }

  componentDidMount() {
    if (_.get(this.props, 'match.params.SID')) {
      if (_.get(this.props, 'location.search')) {
        const search = this.props.location.search.split('=')
        switch (search[0]) {
          case '?supervisor':
            headers = _.omit(headers, ['userStatus'])
            KamActions.getUsersData(this.props.match.params.SID)
            break
          case '?user':
            KamActions.getKamWalletTransactions({ KAM_UID: this.props.match.params.SID })
            break
          case '?company':
            headers = _.omit(headers, ['userStatus', 'userSupervisor'])
            KamActions.getCompanyUser(this.props.match.params.SID, this.state.settings.userName)
            break
          case '?product':
            KamActions.getProductDetails({ KAM_UID: this.props.match.params.SID, ProductID: search[1] })
            break
          case '?userUID':
            KamActions.getProductDetails({ KAM_UID: search[1] })
            break
        }
      } else {
        switch (this.props.match.params.SID) {
          case 'all-kam-users':
            headers.lastLogin = 'Last Login'
            KamActions.getAllKamUsers()
            break
          case 'all-product-categories':
            KamActions.getCategoryDetails()
            break
          case 'all-kam-users-wallet-transactions':
            KamActions.getKamWalletTransactions()
            break
          case 'all-kam-company-users':
            headers = _.omit(headers, ['userSupervisor'])
            KamActions.getAllKamCompanyUsers(this.state.settings.userName)
            break
          default:
            KamActions.getCategoryDetails({ category: this.props.match.params.SID.split('-').join(' ') })
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.state.err) {
      toast.error('Unable to load KAM Details', {
        autoClose: 7500,
        onClose: () => {
          KamActions.clearToast()
        }
      })
    }
  }

  render() {
    if (this.state.isGettingData) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        {this.state.viewMode === 'all' && (
          <CollapsibleCard header={<strong>Brugere</strong>} startOpen={this.state.kamUsers.length > 0 ? true : false}>
            <DynamicTable
              data={this.state.kamUsers}
              headers={headers}
              options={{
                fieldFunctions: {
                  lastLogin: e => {
                    return e.lastLogin ? moment(e.lastLogin).format('DD-MM-YYYY hh:mm a') : ''
                  }
                },
                fieldStringFunctions: {
                  lastLogin: e => {
                    return moment(e.lastLogin).format('x')
                  }
                }
              }}
            />
          </CollapsibleCard>
        )}
        {this.state.viewMode === 'single' && (
          <React.Fragment>
            <CollapsibleCard header={<strong>Aktiv Brugere</strong>} startOpen={this.state.activeUsers.length > 0 ? true : false}>
              {' '}
              <DynamicTable data={this.state.activeUsers} headers={headers} />
            </CollapsibleCard>
            <CollapsibleCard header={<strong>Inaktive Brugere</strong>} startOpen={this.state.inActiveUsers.length > 0 ? true : false}>
              <DynamicTable data={this.state.inActiveUsers} headers={headers} />
            </CollapsibleCard>
          </React.Fragment>
        )}
        {this.state.viewMode === 'kamTransactions' && (
          <React.Fragment>
            <CollapsibleCard header={<strong>KAM Transaction Details</strong>} startOpen={this.state.kamWalletTransactions.length > 0 ? true : false}>
              <DynamicTable
                data={this.state.kamWalletTransactions}
                headers={{
                  userName: 'Navn',
                  userCompany: 'Virksomhed',
                  userUID: 'Unikt ID',
                  userEmail: 'Email',
                  userPhone: 'Telefon',
                  walletAmountPoints: 'Point',
                  timeInsert: 'Dato'
                }}
                options={{
                  fieldFunctions: {
                    timeInsert: e => {
                      return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
                    }
                  },
                  fieldStringFunctions: {
                    timeInsert: e => {
                      return moment(e.timeInsert).format('x')
                    }
                  }
                }}
              />
            </CollapsibleCard>
          </React.Fragment>
        )}
        {this.state.viewMode === 'kamCategoryDetails' && (
          <React.Fragment>
            <CollapsibleCard header={<strong> Category Product Overview</strong>} startOpen={this.state.kamCategoryDetails.length > 0 ? true : false}>
              <DynamicTable
                data={this.state.kamCategoryDetails}
                headers={{
                  category: 'Category',
                  productName: 'Product Name',
                  productPrice: 'Price',
                  totalCount: 'Total Count',
                  yearCount: 'Year Count',
                  monthCount: 'Month Count',
                  quarterCount: 'Quarter Count'
                }}
              />
            </CollapsibleCard>
          </React.Fragment>
        )}
        {this.state.viewMode === 'kamProductDetails' && (
          <React.Fragment>
            <CollapsibleCard header={<strong>KAM Product Details</strong>} startOpen={this.state.kamProductDetails.length > 0 ? true : false}>
              <DynamicTable
                data={this.state.kamProductDetails}
                headers={{
                  KAM: 'KAM',
                  Catalog: 'Category',
                  Forhandler: 'Forhandler',
                  Forhandler_By: 'Forhandler_By',
                  Forhandler_Postnr: 'Forhandler Postnr',
                  Forhandler_adresse: 'Forhandler adresse',
                  KAM_or_ATT_Name: 'KAM/ATT_Name',
                  OrderID: 'OrderID',
                  OrderlineID: 'OrderlineID',
                  Product: 'Product',
                  ProductID: 'ProductID',
                  VariationId: 'VariationId',
                  shippedDate: 'Shipped Date',
                  orderDate: 'Order Date',
                  orderStatus: 'Order Status',
                  productPrice: 'Product Price'
                }}
                options={{
                  fieldFunctions: {
                    shippedDate: e => {
                      return e.shippedDate
                    },
                    orderDate: e => {
                      return e.orderDate
                    }
                  },
                  fieldStringFunctions: {
                    shippedDate: e => {
                      return moment(e.shippedDate).format('x')
                    },
                    orderDate: e => {
                      return moment(e.orderDate).format('x')
                    }
                  }
                }}
              />
            </CollapsibleCard>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
