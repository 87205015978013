import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input } from 'reactstrap'
import _ from 'lodash'
import { StatActions, StatStore, STATE } from 'Stores/statStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { LoginStore } from 'Stores/loginStore'
import { HofStore, HofActions } from '../../stores/hofStore'

export default class StatForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [StatStore, LoginStore, HofStore]
    this.storeKeys = ['settings', 'loadingState', 'statDetails', 'brands', 'isAdminAccess']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      StatActions.giveAccess()
      HofActions.getBrands()
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.StatID') || _.get(this.props, 'location.search')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      const copy = _.get(this.props, 'location.search') ? true : false
      const StatID = _.get(this.props, 'location.search') ? this.props.location.search.split('=')[1] : this.props.match.params.StatID
      StatActions.getStatDetails(Number(StatID), copy)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      StatActions.createEmptyStat()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isModuleNameValid: this.state.statDetails.moduleName ? true : false,
      isModuleQueryValid: this.state.statDetails.moduleQuery ? true : false,
      isBrandValid: this.state.statDetails.BrandID !== '' && this.state.statDetails.BrandID >= 0 ? true : false
    }

    this.setState({ fieldValidation })

    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveStatForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      StatActions.saveStat()
    }
  }

  render() {
    if (this.state.loadingState === STATE.IS_REDIRECT) {
      return <Redirect to='/stats' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Stat</strong>}>
          <Form>
            {this.state.isAdminAccess && (
              <FormField field={'BrandID'} name='Brand' required>
                <Input
                  type='select'
                  name='BrandID'
                  value={this.state.statDetails.BrandID}
                  className={this.state.statDetails.BrandID >= 0 ? 'is-valid' : 'is-invalid'}
                  onChange={e => {
                    StatActions.updateStatField(e)
                    this.validateForm()
                  }}
                >
                  <option value=''>Select Brand</option>
                  <option value={0}>All Brands</option>
                  {this.state.brands &&
                    !!this.state.brands.length &&
                    this.state.brands.map(e => {
                      return (
                        <option value={e.BrandID} key={e.BrandID}>
                          {e.brandName}
                        </option>
                      )
                    })}
                </Input>
              </FormField>
            )}
            <FormField
              required
              data={this.state.statDetails}
              field={'moduleName'}
              name='Module Name'
              onChange={e => {
                StatActions.updateStatField(e)
                this.validateForm()
              }}
            />
            <FormField
              type='textarea'
              rows={3}
              data={this.state.statDetails}
              field={'moduleDescription'}
              name='Module Description'
              onChange={e => {
                StatActions.updateStatField(e)
              }}
            />
            <FormField
              required
              type='textarea'
              rows={5}
              data={this.state.statDetails}
              field={'moduleQuery'}
              name='Module Query'
              onChange={e => {
                StatActions.updateStatField(e)
                this.validateForm()
              }}
            />
            <FormField
              type='number'
              data={this.state.statDetails}
              field={'poolOrder'}
              name='Pool Order'
              onChange={e => {
                StatActions.updateStatField(e)
              }}
            />
            <Button
              onClick={e => {
                this.saveStatForm(e)
              }}
              disabled={this.state.loadingState === STATE.IS_SAVING}
            >
              {this.state.loadingState === STATE.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
