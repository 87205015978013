import React from 'react'
import { Component } from 'reflux'
import { DatasetStore, DatasetActions, STATUS } from 'Stores/datasetStore'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'

export default class AdminDatasetElements extends Component {
  constructor(props) {
    super(props)
    this.stores = [DatasetStore]
    this.storeKeys = [
      'datasetElements',
      'elementsCount',
      'start',
      'limit',
      'status',
      'showActiveElements',
      'showInActiveElements',
      'datasetElementsFilter'
    ]
  }

  componentDidMount() {
    if (this.state.status !== STATUS.FETCHING_DATASET_ELEMENTS) {
      DatasetActions.getDatasetElements(Number(this.props.match.params.DatasetID))
    }
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_DATASET_ELEMENTS_FAILED) {
      toast.error('FETCHING ELEMENTS FAILED', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearListToast()
        }
      })
      DatasetActions.clearListToast()
    }
  }

  render() {
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Elements
            {this.state.datasetElements && !!this.state.datasetElements.length && (
              <React.Fragment>
                <ButtonGroup className='float-right'>
                  <Button outline color='success' onClick={DatasetActions.toggleActiveElements} active={this.state.showActiveElements}>
                    Active
                  </Button>
                  <Button outline color='danger' onClick={DatasetActions.toggleInActiveElements} active={this.state.showInActiveElements}>
                    In-Active
                  </Button>
                </ButtonGroup>
                {this.state.elementsCount > this.state.limit && (
                  <ButtonGroup className='float-right' style={{ marginRight: '10px' }}>
                    {this.state.start !== 0 && (
                      <Button
                        disabled={this.state.status === STATUS.FETCHING_DATASET_ELEMENTS}
                        outline
                        color='primary'
                        onClick={() => DatasetActions.setLimit('previous', this.props.match.params.DatasetID)}
                        active={this.state.showPreviousRecords}
                      >
                        Prev {this.state.limit}
                      </Button>
                    )}
                    {this.state.start + this.state.limit < this.state.elementsCount && (
                      <Button
                        outline
                        color='primary'
                        disabled={this.state.status === STATUS.FETCHING_DATASET_ELEMENTS}
                        onClick={() => DatasetActions.setLimit('next', this.props.match.params.DatasetID)}
                        active={this.state.showNextRecords}
                      >
                        Next {this.state.limit}
                      </Button>
                    )}
                  </ButtonGroup>
                )}
              </React.Fragment>
            )}
          </strong>
        }
      >
        {this.state.status === STATUS.FETCHING_DATASET_ELEMENTS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              fieldFunctions: {},
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'delete' + e.CustomID}>Deaktivér</Tooltip>
                          <Button
                            id={'delete' + e.CustomID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne Element?')) {
                                DatasetActions.removeDatasetElement(Number(e.CustomID), Number(this.props.match.params.DatasetID), e.removed)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-check' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip target={'enable' + e.CustomID}>Aktivér</Tooltip>
                          <Button
                            id={'enable' + e.CustomID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du aktivere denne Element?')) {
                                DatasetActions.removeDatasetElement(Number(e.CustomID), Number(this.props.match.params.DatasetID), e.removed)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            data={
              this.state.datasetElements && !!this.state.datasetElements.length && this.state.datasetElementsFilter !== 'both'
                ? this.state.datasetElements.filter(element => element.removed === (this.state.datasetElementsFilter === 'inactive' ? 1 : 0))
                : this.state.datasetElements
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}
