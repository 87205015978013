import React from 'react'
import { Component } from 'reflux'
import { CommunicationStore, CommunicationActions, STATUS } from 'Stores/communicationStore'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'
import { LoginStore } from 'Stores/loginStore'
import moment from 'moment'

export default class CommunicationList extends Component {
  constructor(props) {
    super(props)
    this.stores = [CommunicationStore, LoginStore]
    this.storeKeys = ['communicationList', 'isMSBAdmin', 'settings', 'status', 'advanceSearchFilters']
    this.overviewHeaders = {
      CommunicationID: 'CommunicationID',
      userUID: 'User UID',
      userName: 'User Name',
      communicationChannel: 'Communication Channel',
      communicationRecipient: 'Communication Recipient',
      communicationType: 'Communication Type',
      communicationSubject: 'Communication Subject',
      timeInsert: 'Date'
    }
  }

  componentDidMount() {
    const type = this.props.type ? this.props.type : ''
    if (
      this.state.settings &&
      this.state.settings.userRole.toLowerCase() === 'system' &&
      this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
      this.state.settings.BrandID === 9
    ) {
      this.overviewHeaders.BrandID = 'Brand ID'
      this.overviewHeaders.brandName = 'Brand Name'
      CommunicationActions.giveAccess()
    }
    CommunicationActions.getCommunications(type)
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_COMMUNICATIONS_FAILED) {
      toast.error('FETCHING COMMUNICATIONS FAILED', {
        autoClose: 7500,
        onClose: () => {
          CommunicationActions.clearToast()
        }
      })
      CommunicationActions.clearToast()
    }
  }

  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Kommunikation</strong>}>
        {this.state.status === STATUS.FETCHING_COMMUNICATIONS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'CommunicationID'
              },
              advanceSearchFilters: this.state.advanceSearchFilters,
              advanceSearchColumns: [
                { key: 'CommunicationID', name: 'Communication ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
                { key: 'BrandID', name: 'BrandID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
                { key: 'brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT'] },
                { key: 'userUID', name: 'UserUID', operators: ['LIKE', 'EXACT'] },
                { key: 'userName', name: 'User Name', operators: ['LIKE', 'EXACT'] },
                { key: 'communicationChannel', name: 'Communication Channel', operators: ['LIKE', 'EXACT'] },
                { key: 'communicationRecipient', name: 'Communication Recipient', operators: ['LIKE', 'EXACT'] },
                {
                  key: 'communicationType',
                  name: 'Communication Type',
                  operators: ['LIKE', 'EXACT'],
                  hide: this.props.type && this.props.type === 'point' ? true : false
                },
                { key: 'communicationSubject', name: 'Communication Subject', operators: ['LIKE', 'EXACT'] },
                { key: 'timeInsert', name: 'Date', operators: ['LIKE', 'EXACT'] }
              ],
              preserveAdvanceSearch: CommunicationActions.preserveAdvanceSearch,
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD HH:mm:ss') : ''
                },
                fieldStringFunctions: {
                  timeInsert: e => {
                    return moment(e.timeInsert).format('x')
                  }
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'view' + e.CommunicationID}>Vis</Tooltip>
                      <Button
                        tag={Link}
                        to={
                          '/communication/' +
                          (this.props.type && this.props.type === 'point' ? 'points/' : '') +
                          e.CommunicationID +
                          (this.props.type && this.props.type === 'point' ? '/message' : '/elements')
                        }
                        id={'view' + e.CommunicationID}
                      >
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={this.overviewHeaders}
            data={this.state.communicationList}
          />
        )}
      </CollapsibleCard>
    )
  }
}
