import Proxies from 'Components/proxies'
import {
  fetchCustomerSalesClaimsRequest,
  fetchCustomerSalesClaimsFailed,
  fetchCustomerSalesClaimsSuccess,
  isSavingCustomerSalesClaim,
  statusCustomerSalesClaimFailure,
  statusCustomerSalesClaimSuccess
} from 'Reducers/customerSalesClaimReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  LOADING_CUSTOMER_SALES_CLAIMS: null,
  SATUS_CUSTOMER_SALES_CLAIM_FAILED: null,
  STATUS_CUSTOMER_SALES_CLAIM_SUCCESS: null
})

export const getCustomerSalesClaims = data => dispatch => {
  dispatch(fetchCustomerSalesClaimsRequest())
  Proxies.GET('CustomerSalesClaims/admin-pool', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchCustomerSalesClaimsFailed())
    } else {
      dispatch(fetchCustomerSalesClaimsSuccess(responseJSON))
    }
  })
}

export const statusCustomerSalesClaim = data => dispatch => {
  dispatch(isSavingCustomerSalesClaim())
  Proxies.POST('CustomerSalesClaims/change-status', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(statusCustomerSalesClaimFailure())
    } else {
      dispatch(statusCustomerSalesClaimSuccess())
    }
  })
}
