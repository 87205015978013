import React from 'react'
import { Component } from 'reflux'
import { BatchStore, BatchActions, STATUS } from 'Stores/batchStore'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment/locale/da'

export default class AdminBatchList extends Component {
  constructor(props) {
    super(props)
    this.stores = [BatchStore]
    this.storeKeys = [
      'status',
      'batchList',
      'deleteBatchID',
      'batchSaving',
      'BatchID',
      'removedBatchRecords',
      'batchInsertError',
      'showActiveBatches',
      'showInActiveBatches',
      'batchFilter'
    ]
  }
  componentDidMount() {
    BatchActions.getTotalBatchList()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_TOTAL_BATCHES_FAILED) {
      toast.error('FETCHING BATCHES FAILED', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
      BatchActions.clearToast()
    }
  }
  render() {
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Batches
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={BatchActions.toggleActiveBatch} active={this.state.showActiveBatches}>
                Active
              </Button>
              <Button outline color='danger' onClick={BatchActions.toggleInActiveBatch} active={this.state.showInActiveBatches}>
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {this.state.status === STATUS.FETCHING_TOTAL_BATCHES ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'BatchID'
              },
              advanceSearchFilters: this.state.advanceSearchFilters,
              advanceSearchColumns: [
                { key: 'BatchID', name: 'Batch ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
                { key: 'BrandID', name: 'Brand ID', operators: ['LIKE', 'EXACT'] },
                { key: 'brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT'] },
                { key: 'userName', name: 'User Name', operators: ['LIKE', 'EXACT'] },
                { key: 'DatasetID', name: 'DatasetID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
                { key: 'datasetTableName', name: 'Table', operators: ['LIKE', 'EXACT'] },
                { key: 'batchFlowStatus', name: 'Status', operators: ['LIKE', 'EXACT'] },
                { key: 'batchComment', name: 'Note', operators: ['LIKE', 'EXACT'] },
                { key: 'batchRole', name: 'Batch Role', operators: ['LIKE', 'EXACT'] },
                { key: 'batchEncoding', name: 'Batch Encoding', operators: ['LIKE', 'EXACT'] },
                { key: 'timeInsert', name: 'Date Insert', operators: ['LIKE'] },
                { key: 'timeUpdate', name: 'Date Changed', operators: ['LIKE'] }
              ],
              preserveAdvanceSearch: BatchActions.preserveAdvanceSearch,
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD HH:mm:ss') : ''
                },
                timeUpdate: e => {
                  return e.timeUpdate ? moment(e.timeUpdate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
              },
              fieldStringFunctions: {
                timeInsert: e => {
                  return moment(e.timeInsert).format('x')
                },
                timeUpdate: e => {
                  return moment(e.timeUpdate).format('x')
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'deactivate' + e.BatchID}>Deaktivér</Tooltip>
                          <Button
                            id={'deactivate' + e.BatchID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne brand?')) {
                                BatchActions.removeBatch(e.BatchID, e.removed)
                              }
                            }}
                          >
                            {e.BatchID === this.state.deleteBatchID && this.state.status === STATUS.REMOVING_BATCH ? (
                              <FontAwesomeIcon icon='spinner' />
                            ) : (
                              <FontAwesomeIcon icon='user-check' />
                            )}
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip color='danger' target={'active' + e.BatchID}>
                            Aktivér
                          </Tooltip>
                          <Button
                            color='danger'
                            id={'active' + e.BatchID}
                            onClick={() => {
                              if (confirm('Vil du aktivere denne brand?')) {
                                BatchActions.removeBatch(e.BatchID, e.removed)
                              }
                            }}
                          >
                            {e.BatchID === this.state.deleteBatchID && this.state.status === STATUS.REMOVING_BATCH ? (
                              <FontAwesomeIcon icon='spinner' />
                            ) : (
                              <FontAwesomeIcon icon='user-slash' />
                            )}
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              BatchID: 'Batch ID',
              BrandID: 'Brand ID',
              brandName: 'Brand Name',
              userName: 'User Name',
              DatasetID: 'Dataset ID',
              datasetTableName: 'Table',
              batchFlowStatus: 'Status',
              batchComment: 'Note',
              batchRole: 'Batch Role',
              batchEncoding: 'Batch Encoding',
              timeInsert: 'Date Insert',
              timeUpdate: 'Date Changed'
            }}
            data={
              this.state.batchList && this.state.batchList && this.state.batchFilter !== 'both'
                ? this.state.batchList.filter(batch => batch.removed === (this.state.batchFilter === 'inactive' ? 1 : 0))
                : this.state.batchList
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}
