import React from 'react'
import { Component } from 'reflux'
import { DatasetStore, DatasetActions, STATUS } from 'Stores/datasetStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import { Button, Form, Input, Label, Row, Col, Table } from 'reactstrap'
import _ from 'lodash'

const indexList = ['NONE', 'INDEX', 'UNIQUE', 'PRIMARY']
const formatList = [
  'str',
  'varchar',
  'int',
  'uint',
  'abs',
  'float',
  'boolean',
  'class',
  'label',
  'upper',
  'lower',
  'phone',
  'email',
  'currency',
  'points',
  'token',
  'time',
  'hex',
  'ID',
  'slug',
  'firstName',
  'lastName',
  'url'
]
const clauseList = ['NONE', 'MATCH', 'PULL', 'PUSH']
export default class DatasetForm extends Component {
  constructor(props) {
    super(props)
    this.clauseDatacolumns = []
    this.stores = [DatasetStore]
    this.storeKeys = ['datasets', 'datasetDetails', 'status']
  }
  componentDidMount() {
    DatasetActions.getDatasets()
  }
  resetDataset = () => {
    if (_.get(this.props, 'match.params.DatasetID')) {
      DatasetActions.getDataset(this.props.match.params.DatasetID)
    } else {
      DatasetActions.createEmptyDataset()
    }
  }
  addColumn = () => {
    DatasetActions.addDatasetColumn({
      datacolumnName: '',
      columnName: '',
      datacolumnArguments: '',
      datacolumnDefault: '',
      datacolumnIndex: '',
      datacolumnFormat: '',
      datacolumnClause: '',
      datacolumnClauseDatacolumnID: 0
    })
  }
  importCSV = event => {
    let file = event.target.files[event.target.files.length - 1]
    var reader = new FileReader()
    reader.onload = function(e) {
      let result = e.target.result
      DatasetActions.mapDatasetColumns(result)
    }
    reader.readAsText(file)
  }
  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/dataset' />
    }
    if (this.state.status === STATUS.FETCHING_DATASET) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    if (this.state.datasets && this.state.datasets.length) {
      this.state.datasets.map(item => {
        this.clauseDatacolumns = this.clauseDatacolumns.concat(item.columns)
      })
    }

    return (
      <CollapsibleCard startOpen header={<strong>{_.get(this.props, 'match.params.DatasetID') ? 'Detaljer' : 'Opret'}</strong>}>
        <Form>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='name'>Navn</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={this.state.datasetDetails.moduleName}
                name='moduleName'
                id='moduleName'
                onChange={DatasetActions.updateDataset}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='description'>Description</Label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                value={this.state.datasetDetails.moduleDescription}
                name='moduleDescription'
                id='moduleDescription'
                onChange={DatasetActions.updateDataset}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <label>Import from File (Optional)</label>
            </Col>
            <Col xs='3'>
              <Input type='file' name='csvdata' onClick={e => (e.target.value = null)} onChange={this.importCSV}></Input>{' '}
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs='2'>
              <Label for='name'>Columns</Label>
            </Col>
            <Col xs='10'>
              <Table responsive size='sm'>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Name</th>
                    <th>Column Name</th>
                    <th>Format</th>
                    <th>Arguments</th>
                    <th>Default</th>
                    <th>Clause</th>
                    <th>Clause Column</th>
                    <th>
                      <Button onClick={this.addColumn}>
                        <FontAwesomeIcon icon='plus' />
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!this.state.datasetDetails.columns.length &&
                    this.state.datasetDetails.columns.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Input
                              type='select'
                              name='datacolumnIndex'
                              value={item.datacolumnIndex}
                              onChange={t => DatasetActions.updateDatasetColumn(t, key)}
                            >
                              <option value='' disabled>
                                Click to Select
                              </option>
                              {indexList.map((e, i) => {
                                return (
                                  <option value={e} key={i}>
                                    {e}
                                  </option>
                                )
                              })}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type='text'
                              value={item.datacolumnName}
                              name='datacolumnName'
                              onChange={t => DatasetActions.updateDatasetColumn(t, key)}
                            />
                          </td>
                          <td>
                            <Input type='text' value={item.columnName} name='columnName' onChange={t => DatasetActions.updateDatasetColumn(t, key)} />
                          </td>
                          <td>
                            <Input
                              type='select'
                              name='datacolumnFormat'
                              value={item.datacolumnFormat}
                              onChange={t => DatasetActions.updateDatasetColumn(t, key)}
                            >
                              <option value='' disabled>
                                Click to Select
                              </option>
                              {formatList.map((e, i) => {
                                return (
                                  <option value={e} key={i}>
                                    {e}
                                  </option>
                                )
                              })}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type='text'
                              value={item.datacolumnArguments}
                              name='datacolumnArguments'
                              onChange={t => DatasetActions.updateDatasetColumn(t, key)}
                            />
                          </td>
                          <td>
                            <Input
                              type='text'
                              value={item.datacolumnDefault}
                              name='datacolumnDefault'
                              onChange={t => DatasetActions.updateDatasetColumn(t, key)}
                            />
                          </td>
                          <td>
                            <Input
                              type='select'
                              name='datacolumnClause'
                              value={item.datacolumnClause}
                              onChange={t => DatasetActions.updateDatasetColumn(t, key)}
                            >
                              <option value='' disabled>
                                Click to Select
                              </option>
                              {clauseList.map((e, i) => {
                                return (
                                  <option value={e} key={i}>
                                    {e}
                                  </option>
                                )
                              })}
                            </Input>
                          </td>
                          <td>
                            <Input
                              type='select'
                              name='datacolumnClauseDatacolumnID'
                              value={item.datacolumnClauseDatacolumnID}
                              onChange={t => DatasetActions.updateDatasetColumn(t, key)}
                            >
                              <option value={0} disabled>
                                Click to Select
                              </option>
                              {this.clauseDatacolumns.map((e, i) => {
                                return (
                                  <option value={e.DatacolumnID} key={i}>
                                    {e.datacolumnName}
                                  </option>
                                )
                              })}
                            </Input>
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                DatasetActions.removeDatasetColumn(key)
                              }}
                            >
                              <FontAwesomeIcon icon='minus' />
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Button onClick={DatasetActions.saveDataset} disabled={this.state.status === STATUS.SAVING}>
            {this.state.status === STATUS.SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={this.resetDataset} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    )
  }
}
