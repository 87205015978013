/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import _ from 'lodash'
import keyMirror from 'keymirror'
import strip_tags from 'Components/striptags'
import { csvToArray, delimiter } from '../util/generic'
import { toast } from 'react-toastify'

export const STATUS = keyMirror({
  FETCHING_LANGUAGE: null,
  FETCHING_LANGUAGE_SUCCESS: null,
  FETCHING_LANGUAGE_ERROR: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  TAKING_INPUT: null,
  FETCHING_TRANSLATIONS: null,
  FETCHING_TRANSLATIONS_FAILED: null,
  FETCHING_TRANSLATIONS_SUCCESS: null,
  DELETE_TRANSLATION_SUCCESS: null,
  DELETE_TRANSACTIONS_FAILED: null,
  FETCHING_TRANSLATION_DETAILS: null,
  FETCHING_TRANSLATION_DETAILS_ERROR: null,
  TRANSLATION_SUCCESS: null,
  TRANSLATION_FAILED: null
})
export const TranslationActions = Reflux.createActions([
  'getLanguages',
  'getTranslations',
  'getRawTranslations',
  'enableImportField',
  'onFileUploadChange',
  'removeTranslation',
  'getTranslationDetail',
  'createEmptyTranslation',
  'saveTranslation',
  'updateTranslationField',
  'updateData',
  'clearToast'
])

export class TranslationStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = TranslationActions
    this.state = {
      status: null,
      showUploadField: false,
      translations: [],
      translationsForExport: [],
      translationDetails: {
        translationKey: '',
        phrases: [
          {
            LanguageID: '',
            translationComment: '',
            translationValue: ''
          }
        ]
      },
      languages: []
    }
  }

  getLanguages() {
    this.setState({ status: STATUS.FETCHING_LANGUAGE })
    GET('schemas/languages').then(responseJSON => {
      let nextState = {
        languages: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_LANGUAGE_ERROR
      } else {
        nextState.languages = _.sortBy(responseJSON, 'LanguageID')
        nextState.status = STATUS.FETCHING_LANGUAGE_SUCCESS
      }
      this.setState(nextState)
    })
  }

  getTranslations() {
    this.setState({ status: STATUS.FETCHING_TRANSLATIONS })
    GET('platform/get-translations').then(responseJSON => {
      let nextState = {
        translations: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_TRANSLATIONS_FAILED
      } else {
        nextState.translations = responseJSON
        nextState.status = STATUS.FETCHING_TRANSLATIONS_SUCCESS
      }
      this.setState(nextState)
    })
  }

  getRawTranslations() {
    this.setState({ status: STATUS.FETCHING_TRANSLATIONS })
    GET('platform/get-translations', { raw: true }).then(responseJSON => {
      let nextState = {
        translationsForExport: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_TRANSLATIONS_FAILED
      } else {
        let exportData = []
        responseJSON.forEach(item => {
          let transExists = exportData.filter(td => item.translationKey === td.translationKey)
          let obj = {}
          if (transExists.length) {
            obj = transExists[0]
            obj.en = item.languageKey === 'en' ? item.translationValue : obj.en || ''
            obj.dk = item.languageKey === 'dk' ? item.translationValue : obj.dk || ''
            const rest = exportData.filter(td => td.TranslationID !== item.TranslationID)
            exportData = [...rest, obj]
          } else {
            obj = {
              TranslationID: item.TranslationID,
              translationKey: item.translationKey,
              en: item.languageKey === 'en' ? item.translationValue : '',
              dk: item.languageKey === 'dk' ? item.translationValue : ''
            }
            exportData.push(obj)
          }
        })
        nextState.translationsForExport = exportData.sort((a, b) => b.TranslationID - a.TranslationID)
        nextState.status = STATUS.FETCHING_TRANSLATIONS_SUCCESS
      }
      this.setState(nextState)
    })
  }

  enableImportField() {
    this.setState({ showUploadField: true })
  }
  disableImportField() {
    this.setState({ showUploadField: false })
  }
  onFileUploadChange(event) {
    const file = event.target.files[0]
    this.readFile(file, this.bulkUpdateTranslations)
  }

  bulkUpdateTranslations(fileData = null) {
    if (!fileData) return
    const delimiterChar = delimiter(fileData)
    const translations = csvToArray(fileData, delimiterChar)
    POST('platform/bulkUpdateTranslations', { translations }).then(responseJSON => {
      if (responseJSON.error) {
        toast.error('BULK-UPDATE TRANSLATIONS FAILED', {
          autoClose: 7500,
          onClose: () => {
            TranslationActions.clearToast()
            window.location.reload()
          }
        })
      } else {
        window.location.reload()
      }
    })
  }

  readFile = (file, callback = null) => {
    var reader = new FileReader()
    reader.onload = function(e) {
      let result = e.target.result
      if (callback) callback(result)
    }
    reader.readAsText(file)
  }

  removeTranslation(PhraseID) {
    if (PhraseID) {
      POST('platform/remove-translation', { PhraseID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATUS.DELETE_TRANSACTIONS_FAILED })
        } else {
          this.setState({ status: STATUS.DELETE_TRANSLATION_SUCCESS })
          this.getTranslations()
        }
      })
    }
  }

  createEmptyTranslation() {
    this.setState({
      translationDetails: {
        translationKey: '',
        phrases: [
          {
            LanguageID: '',
            translationComment: '',
            translationValue: ''
          }
        ]
      }
    })
  }

  getTranslationDetail(TranslationID) {
    this.setState({ status: STATUS.FETCHING_TRANSLATION_DETAILS })
    GET('platform/fetch-translation', { TranslationID }).then(responseJSON => {
      let nextState = {
        translationDetails: {}
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_TRANSLATION_DETAILS_ERROR
      } else {
        nextState.translationDetails = responseJSON
        nextState.status = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  saveTranslation() {
    if (this.state.translationDetails.translationKey != '' && !!this.state.translationDetails.phrases.length) {
      this.setState({ status: STATUS.IS_SAVING })
      POST('platform/upsert-translation', this.state.translationDetails).then(responseJSON => {
        let nextState = {
          translationDetails: this.state.translationDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.TRANSLATION_FAILED
          } else {
            nextState.status = STATUS.TRANSLATION_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }

  updateTranslationField(field) {
    let value = strip_tags(field.target.value)
    let translationDetails = this.state.translationDetails
    translationDetails[field.target.name] = field.target.name === 'LanguageID' ? Number(value) : value
    this.setState({ translationDetails })
  }

  updateData(field, index, type) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let translationDetails = this.state.translationDetails
    translationDetails[type][index][fieldName] = fieldValue

    this.setState({ translationDetails })
  }

  clearToast(status) {
    status ? this.setState({ status }) : this.setState({ status: null })
  }
}
