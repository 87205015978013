export const ids = [2, 15152, 18393, 24814, 24872, 84644, 99472]

export function isOurID(id) {
  id = Number.parseInt(id, 10)
  // eslint-disable-next-line
  if (id === NaN) {
    return false
  }
  if (ids.indexOf(id) !== -1) {
    return true
  }
  return false
}
