import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { EnvelopeStore, EnvelopeActions } from 'Stores/envelopeStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { Button, Form, FormGroup, Label, Col, CustomInput, Row } from 'reactstrap'
import FormField from 'Components/formField'
import { LoginStore } from 'Stores/loginStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class PointsEnvelope extends Component {
  constructor(props) {
    super(props)
    this.stores = [EnvelopeStore, LoginStore]
    this.storeKeys = [
      'settings',
      'pointUpload',
      'isSavingPointUploadCustomisation',
      'isSavingPointInsertCustomisation',
      'pointInsert',
      'isSavingPointCustomisation',
      'savingPointCustomisationSuccess',
      'savingPointCustomisationError',
      'negativePointUpload',
      'isSavingNegativePointUploadCustomisation'
    ]
  }
  componentDidMount() {
    EnvelopeActions.getPointMessageDetails(this.state.settings.BrandID)
  }
  componentDidUpdate() {
    if (this.state.savingPointCustomisationSuccess) {
      toast.success('Successfully Saved', {
        autoClose: 7500,
        onClose: () => {
          EnvelopeActions.clearPointsMessageToast()
        }
      })
      EnvelopeActions.clearPointsMessageToast()
    }
    if (this.state.savingPointCustomisationError) {
      toast.error('Error', {
        autoClose: 7500,
        onClose: () => {
          EnvelopeActions.clearPointsMessageToast()
        }
      })
      EnvelopeActions.clearPointsMessageToast()
    }
  }
  render() {
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Point besked</strong>}>
          <Form>
            <FormGroup>
              <Row>
                <Col xs='auto'>
                  <Label for='enableAutomaticMessagePointUpload'>Send besked til brugere når der uploades point:</Label>
                </Col>
                <Col>
                  <CustomInput
                    type='switch'
                    checked={this.state.pointUpload.enableAutomaticMessagePointUpload}
                    id='enableAutomaticMessagePointUpload'
                    name='enableAutomaticMessagePointUpload'
                    onChange={e => EnvelopeActions.updatePointsMessageField(e, 'pointUpload')}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormField
              type='textarea'
              data={this.state.pointUpload}
              field={'messageTextPointUpload'}
              name='Besked der skal sendes:'
              onChange={e => EnvelopeActions.updatePointsMessageField(e, 'pointUpload')}
            />
            <Button onClick={() => EnvelopeActions.savePointMessage('pointUpload')}>
              Gem {this.state.isSavingPointUploadCustomisation && <FontAwesomeIcon icon='spinner' spin />}
            </Button>
            <Button color='danger' onClick={() => EnvelopeActions.resetPointsData('pointUpload')} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
        <CollapsibleCard startOpen={true} header={<strong>Point besked</strong>}>
          <Form>
            <FormGroup>
              <Row>
                <Col xs='auto'>
                  <Label for='enableAutomaticMessageManualPointInsert'>
                    Send besked til brugere når der indsættes point manuelt i brugerens wallet:
                  </Label>
                </Col>
                <Col>
                  <CustomInput
                    type='switch'
                    checked={this.state.pointInsert.enableAutomaticMessageManualPointInsert}
                    id='enableAutomaticMessageManualPointInsert'
                    name='enableAutomaticMessageManualPointInsert'
                    onChange={e => EnvelopeActions.updatePointsMessageField(e, 'pointInsert')}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormField
              type='textarea'
              data={this.state.pointInsert}
              field={'messageTextManualPointInsert'}
              name='Besked der skal sendes:'
              onChange={e => EnvelopeActions.updatePointsMessageField(e, 'pointInsert')}
            />
            <Button onClick={() => EnvelopeActions.savePointMessage('pointInsert')}>
              Gem {this.state.isSavingPointInsertCustomisation && <FontAwesomeIcon icon='spinner' spin />}
            </Button>
            <Button color='danger' onClick={() => EnvelopeActions.resetPointsData('pointInsert')} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
        <CollapsibleCard startOpen={true} header={<strong>Point besked</strong>}>
          <Form>
            <FormGroup>
              <Row>
                <Col xs='auto'>
                  <Label for='enableAutomaticMessageNegativePointUpload'>Send besked til brugere når der uploades negative point:</Label>
                </Col>
                <Col>
                  <CustomInput
                    type='switch'
                    checked={this.state.negativePointUpload.enableAutomaticMessageNegativePointUpload}
                    id='enableAutomaticMessageNegativePointUpload'
                    name='enableAutomaticMessageNegativePointUpload'
                    onChange={e => EnvelopeActions.updatePointsMessageField(e, 'negativePointUpload')}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormField
              type='textarea'
              data={this.state.negativePointUpload}
              field={'messageTextNegativePointUpload'}
              name='Besked der skal sendes:'
              onChange={e => EnvelopeActions.updatePointsMessageField(e, 'negativePointUpload')}
            />
            <Button onClick={() => EnvelopeActions.savePointMessage('negativePointUpload')}>
              Gem {this.state.isSavingNegativePointUploadCustomisation && <FontAwesomeIcon icon='spinner' spin />}
            </Button>
            <Button color='danger' onClick={() => EnvelopeActions.resetPointsData('negativePointUpload')} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
