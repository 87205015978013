import React from 'react'
import { Component } from 'reflux'
import { DatasetStore, DatasetActions, STATUS } from 'Stores/datasetStore'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'

export default class AdminDatasetList extends Component {
  constructor(props) {
    super(props)
    this.stores = [DatasetStore]
    this.storeKeys = ['adminDatasets', 'status', 'datasetFilter', 'showActiveDatasets', 'showInActiveDatasets']
  }

  componentDidMount() {
    if (this.state.status !== STATUS.FETCHING_ADMIN_DATASETS) {
      DatasetActions.getAdminDatasets()
    }
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_ADMIN_DATASETS_FAILED) {
      toast.error('FETCHING X-FILES FAILED', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearListToast()
        }
      })
      DatasetActions.clearListToast()
    }
  }

  render() {
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            X-Files
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={DatasetActions.toggleActiveDataset} active={this.state.showActiveDatasets}>
                Active
              </Button>
              <Button outline color='danger' onClick={DatasetActions.toggleInActiveDataset} active={this.state.showInActiveDatasets}>
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {this.state.status === STATUS.FETCHING_ADMIN_DATASETS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'DatasetID'
              },
              fieldFunctions: {
                brand: e => {
                  return e.brand ? e.brand.brandName : ''
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'batch' + e.DatasetID}>Vis Elements</Tooltip>
                      <Button tag={Link} to={'/x-files/' + e.DatasetID + '/elements'} id={'batch' + e.DatasetID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'delete' + e.DatasetID}>Deaktivér</Tooltip>
                          <Button
                            id={'delete' + e.DatasetID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne X-File?')) {
                                DatasetActions.removeDataset(e.DatasetID, e.removed)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-check' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip target={'enable' + e.DatasetID}>Aktivér</Tooltip>
                          <Button
                            id={'enable' + e.DatasetID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du aktivere denne X-File?')) {
                                DatasetActions.removeDataset(e.DatasetID, e.removed)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              DatasetID: 'Dataset ID',
              BrandID: 'Brand ID',
              brand: 'Brand Name',
              datasetTableName: 'Table',
              moduleName: 'Navn',
              moduleDescription: 'Beskrivelse',
              tableName: 'Titel',
              timeInsert: 'Dato'
            }}
            data={
              this.state.adminDatasets && !!this.state.adminDatasets.length && this.state.datasetFilter !== 'both'
                ? this.state.adminDatasets.filter(dataset => dataset.removed === (this.state.datasetFilter === 'inactive' ? 1 : 0))
                : this.state.adminDatasets
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}
