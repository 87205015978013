import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { createEmptyAnnouncement, clearToast } from 'Reducers/announcementReducer'
import { STATUS, getBrands } from 'Reducers/actions/announcementActions'
import { connect } from 'react-redux'
import AnnouncementForm from 'Modules/announcement/announcementForm'

class AnnouncementCreate extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.createEmptyAnnouncement()
    this.props.getBrands()
  }
  componentDidUpdate() {
    if (this.props.loadingState === STATUS.ANNOUNCEMENT_SUCCESS) {
      toast.success('Successfully Created', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast(STATUS.IS_REDIRECT)
    }
    if (this.props.loadingState === STATUS.ANNOUNCEMENT_FAILED) {
      toast.error('Error while saving Announcement', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <AnnouncementForm {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ loadingState: state.announcement.loadingState })

export default connect(mapStateToProps, { createEmptyAnnouncement, clearToast, getBrands })(AnnouncementCreate)
