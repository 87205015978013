import React, { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label } from 'reactstrap'
import { toast } from 'react-toastify'

const TranslatableFieldsBlock = props => {
  const { languageName, LanguageID, title, description, placeholderStrings, displayButtons, onChangeCallback } = props

  const [fieldTitle, setFieldTitle] = useState(title || '')
  const [fieldDescription, setFieldDescription] = useState(description || '')

  const copyTextToClipboard = value => {
    if (!navigator.clipboard) {
      // Clipboard API not available
      return
    }
    if (window.getSelection().toString() && this.props.options.copyHightlighText) {
      return
    }
    try {
      navigator.clipboard.writeText(value)
      toast.success('Kopieret: ' + value, {
        autoClose: 3000
      })
    } catch (err) {
      // console.error('Failed to copy!', err)
    }
  }

  useEffect(() => {
    setFieldTitle(title)
    setFieldDescription(description)
  }, [title, description, LanguageID])

  useEffect(() => {
    onChangeCallback({
      title: fieldTitle,
      content: fieldDescription,
      LanguageID
    })
  }, [fieldDescription, fieldTitle])

  return (
    <>
      <strong>{languageName}</strong>
      <FormGroup row>
        <Col sm={12}>
          {!!placeholderStrings?.length && (
            <>
              <em>Placeholders you can click to copy and use it:</em>
              <br />
              {placeholderStrings.map((i, x) => (
                <em key={x} className='placeholderString' onClick={event => copyTextToClipboard(event.target.innerText)}>
                  {' '}
                  {i}{' '}
                </em>
              ))}
              <p />
            </>
          )}
        </Col>
        <Label for='fieldTitle' sm={2}>
          Title
        </Label>
        <Col sm={10}>
          <Input
            formNoValidate
            type='textarea'
            rows='2'
            name='fieldTitle'
            id='fieldTitle'
            placeholder='Title'
            value={fieldTitle}
            onChange={e => {
              setFieldTitle(e.target.value)
            }}
          />
        </Col>
        <Label for='Description' sm={2}>
          Description
        </Label>
        <Col sm={10} style={{ marginTop: '10px' }}>
          <Input
            formNoValidate
            type='textarea'
            rows='10'
            name='fieldDescription'
            id='fieldDescription'
            placeholder='Description'
            value={fieldDescription}
            onChange={e => {
              setFieldDescription(e.target.value)
            }}
          />
        </Col>
        {!!displayButtons && (
          <div style={{ display: 'flex', flex: 0.95, justifydescription: 'flex-end', width: '90%', marginTop: 20 }}>
            <Button onClick={() => {}} disabled={false}>
              Save
            </Button>
            <Button onClick={() => {}} disabled={false} color='danger' className='ml-2'>
              Reset
            </Button>
          </div>
        )}
      </FormGroup>
    </>
  )
}

export default TranslatableFieldsBlock
