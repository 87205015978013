import './scss/style.sass'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import { Component } from 'reflux'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
require('./config')
import Login from 'Modules/login'
import ErrorBoundary from 'Components/errorBoundary'
import Layout from 'Components/layout'
import FourOhFour from 'Modules/404'
import SSO from 'Modules/_sso'
import { LoginStore } from 'Stores/loginStore'
import { generateRoutes } from 'Components/moduleGenerator'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import store from 'Reducers/store'

class Site extends Component {
  constructor(props) {
    super(props)
    this.stores = [LoginStore]
    this.storeKeys = ['sessionVerified', 'sessionKey', 'user', 'settings']
  }
  render() {
    if (this.state.sessionVerified) {
      let routes = generateRoutes(this.state.settings)
      return (
        <BrowserRouter>
          <Route
            component={props => (
              <Layout {...props} settings={this.state.settings}>
                <Switch>
                  {routes
                    .filter(e => {
                      return !e.deleteFromRoutes
                    })
                    .map((route, i) => (
                      <RouteWithSubRoutes key={i} {...route} />
                    ))}
                  <Route path='/sso' component={props => <SSO {...props} />} />
                  <Route component={FourOhFour} />
                </Switch>
              </Layout>
            )}
          />
        </BrowserRouter>
      )
    }
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/sso' component={props => <SSO {...props} />} />
          <Route component={Login} />
        </Switch>
      </BrowserRouter>
    )
  }
}

const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    exact={route.exact ? route.exact : false}
    render={props => (
      <ErrorBoundary>
        <route.component {...props} routes={route.routes} {...route.props} />
      </ErrorBoundary>
    )}
  />
)

const HotReloadSite = hot(module)(Site)

ReactDOM.render(
  <Provider store={store}>
    <HotReloadSite />
  </Provider>,
  document.getElementById('upgrader-app')
)
