import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DynamicTable from 'Components/dynamicTable'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import 'moment/locale/da'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { CampaignActions, CampaignStore, STATUS } from 'Stores/campaignStore'

export default class CampaignList extends Component {
  constructor(props) {
    super(props)
    this.stores = [CampaignStore]
    this.storeKeys = ['campaigns', 'status', 'campaignCount', 'campaignID']
  }
  componentDidMount() {
    CampaignActions.getCampaigns()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_CAMPAIGNS_ERROR) {
      toast.error('Fetching campaigns failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearListToast()
        }
      })
    }
    if (this.state.status === STATUS.RUN_CAMPAIGN_ERROR) {
      toast.error('Run Campaign Failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearListToast()
        }
      })
    }
    if (this.state.status === STATUS.RUN_CAMPAIGN_SUCCESS) {
      const count = this.state.campaignCount
      toast.success('Campaign Processed Count ' + count, {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearListToast()
        }
      })
    }
    if (this.state.status === STATUS.RUN_CAMPAIGN_USER_ERROR) {
      toast.error('No users in Group', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearListToast()
        }
      })
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_CAMPAIGNS) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Kampagner</strong>}>
        <DynamicTable
          responsive
          size='sm'
          data={this.state.campaigns}
          headers={{
            CampaignID: '#',
            macroName: 'Navn',
            macroDescription: 'Beskrivelse',
            status: 'Status',
            timeStart: 'Start Dato',
            timeEnd: 'Slut dato',
            campaignType: 'Type',
            compoundCampaignMinValue: 'Min',
            compoundCampaignMaxValue: 'Max'
          }}
          options={{
            startSort: {
              column: 'CampaignID',
              dir: 'DESC'
            },
            fieldFunctions: {
              compoundCampaignMinValue: e => {
                if (e.compoundCampaignMinValue > 0) {
                  return Number(e.compoundCampaignMinValue) + 1
                }
              },
              timeStart: e => {
                return e.timeStart ? moment.parseZone(e.timeStart).format('DD-MM-YYYY') : ''
              },
              timeEnd: e => {
                return e.timeEnd ? moment.parseZone(e.timeEnd).format('DD-MM-YYYY') : ''
              },
              status: e => {
                switch (e.status) {
                  case 'SAVED':
                    return <span>Gemt</span>

                  case 'PENDING':
                    return <span>Afventer godkendelse</span>

                  case 'ACCEPTED':
                    return <span>Godkendt</span>

                  case 'REJECTED':
                    return <span>Afvist</span>

                  case 'CLOSED':
                    return <span>Lukket</span>

                  case 'STARTED':
                    return <span>Startet</span>

                  default:
                    return e.status
                }
              }
            },
            fieldStringFunctions: {
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.CampaignID}>{this.props.isKam ? 'Vis opsætning' : 'Rediger'}</Tooltip>
                    <Button tag={Link} to={(this.props.isKam ? '/kam/campaigns/' : '/macros/campaigns/') + e.CampaignID} id={'edit' + e.CampaignID}>
                      {this.props.isKam && <FontAwesomeIcon icon={['fab', 'readme']} />}
                      {!this.props.isKam && <FontAwesomeIcon icon='edit' />}
                    </Button>
                    <Tooltip target={'preview' + e.CampaignID}>Vis resultat</Tooltip>
                    <Button
                      tag={Link}
                      color='info'
                      to={(this.props.isKam ? '/kam/campaigns/' : '/macros/campaigns/') + e.CampaignID + '/preview'}
                      id={'preview' + e.CampaignID}
                    >
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    {!this.props.isKam && (
                      <>
                        <Tooltip target={'run' + e.CampaignID}>Run</Tooltip>
                        <Button
                          id={'run' + e.CampaignID}
                          onClick={() => CampaignActions.runCampaign(e.CampaignID)}
                          disabled={this.state.status === STATUS.IS_SAVING || e.disabled}
                        >
                          {this.state.campaignID === e.CampaignID && this.state.status === STATUS.IS_SAVING ? (
                            <FontAwesomeIcon icon='spinner' spin />
                          ) : (
                            <FontAwesomeIcon icon='history' />
                          )}
                        </Button>
                      </>
                    )}
                    <Tooltip target={'delete' + e.CampaignID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.CampaignID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne campaign?')) {
                          CampaignActions.deleteCampaign(e.CampaignID)
                        }
                      }}
                      disabled={moment(e.timeStart).isBefore(moment())}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
