export const postalCodesFI = [
  { postalCode: '00002', city: 'Helsinki' },
  { postalCode: '00003', city: 'Helsinki' },
  { postalCode: '00004', city: 'Helsinki' },
  { postalCode: '00006', city: 'Helsinki' },
  { postalCode: '00007', city: 'Helsinki' },
  { postalCode: '00010', city: 'Helsinki' },
  { postalCode: '00100', city: 'Helsinki' },
  { postalCode: '00101', city: 'Helsinki' },
  { postalCode: '00120', city: 'Helsinki' },
  { postalCode: '00121', city: 'Helsinki' },
  { postalCode: '00130', city: 'Helsinki' },
  { postalCode: '00131', city: 'Helsinki' },
  { postalCode: '00140', city: 'Helsinki' },
  { postalCode: '00141', city: 'Helsinki' },
  { postalCode: '00150', city: 'Helsinki' },
  { postalCode: '00151', city: 'Helsinki' },
  { postalCode: '00160', city: 'Helsinki' },
  { postalCode: '00161', city: 'Helsinki' },
  { postalCode: '00170', city: 'Helsinki' },
  { postalCode: '00171', city: 'Helsinki' },
  { postalCode: '00180', city: 'Helsinki' },
  { postalCode: '00181', city: 'Helsinki' },
  { postalCode: '00190', city: 'Helsinki' },
  { postalCode: '00191', city: 'Helsinki' },
  { postalCode: '00200', city: 'Helsinki' },
  { postalCode: '00201', city: 'Helsinki' },
  { postalCode: '00210', city: 'Helsinki' },
  { postalCode: '00211', city: 'Helsinki' },
  { postalCode: '00230', city: 'Helsinki' },
  { postalCode: '00231', city: 'Helsinki' },
  { postalCode: '00240', city: 'Helsinki' },
  { postalCode: '00241', city: 'Helsinki' },
  { postalCode: '00250', city: 'Helsinki' },
  { postalCode: '00251', city: 'Helsinki' },
  { postalCode: '00260', city: 'Helsinki' },
  { postalCode: '00270', city: 'Helsinki' },
  { postalCode: '00271', city: 'Helsinki' },
  { postalCode: '00280', city: 'Helsinki' },
  { postalCode: '00281', city: 'Helsinki' },
  { postalCode: '00290', city: 'Helsinki' },
  { postalCode: '00300', city: 'Helsinki' },
  { postalCode: '00301', city: 'Helsinki' },
  { postalCode: '00310', city: 'Helsinki' },
  { postalCode: '00320', city: 'Helsinki' },
  { postalCode: '00321', city: 'Helsinki' },
  { postalCode: '00330', city: 'Helsinki' },
  { postalCode: '00331', city: 'Helsinki' },
  { postalCode: '00340', city: 'Helsinki' },
  { postalCode: '00341', city: 'Helsinki' },
  { postalCode: '00350', city: 'Helsinki' },
  { postalCode: '00351', city: 'Helsinki' },
  { postalCode: '00360', city: 'Helsinki' },
  { postalCode: '00370', city: 'Helsinki' },
  { postalCode: '00371', city: 'Helsinki' },
  { postalCode: '00380', city: 'Helsinki' },
  { postalCode: '00381', city: 'Helsinki' },
  { postalCode: '00390', city: 'Helsinki' },
  { postalCode: '00391', city: 'Helsinki' },
  { postalCode: '00400', city: 'Helsinki' },
  { postalCode: '00401', city: 'Helsinki' },
  { postalCode: '00410', city: 'Helsinki' },
  { postalCode: '00411', city: 'Helsinki' },
  { postalCode: '00420', city: 'Helsinki' },
  { postalCode: '00421', city: 'Helsinki' },
  { postalCode: '00430', city: 'Helsinki' },
  { postalCode: '00431', city: 'Helsinki' },
  { postalCode: '00440', city: 'Helsinki' },
  { postalCode: '00441', city: 'Helsinki' },
  { postalCode: '00500', city: 'Helsinki' },
  { postalCode: '00501', city: 'Helsinki' },
  { postalCode: '00510', city: 'Helsinki' },
  { postalCode: '00511', city: 'Helsinki' },
  { postalCode: '00520', city: 'Helsinki' },
  { postalCode: '00521', city: 'Helsinki' },
  { postalCode: '00530', city: 'Helsinki' },
  { postalCode: '00531', city: 'Helsinki' },
  { postalCode: '00550', city: 'Helsinki' },
  { postalCode: '00551', city: 'Helsinki' },
  { postalCode: '00560', city: 'Helsinki' },
  { postalCode: '00561', city: 'Helsinki' },
  { postalCode: '00570', city: 'Helsinki' },
  { postalCode: '00571', city: 'Helsinki' },
  { postalCode: '00580', city: 'Helsinki' },
  { postalCode: '00581', city: 'Helsinki' },
  { postalCode: '00600', city: 'Helsinki' },
  { postalCode: '00601', city: 'Helsinki' },
  { postalCode: '00610', city: 'Helsinki' },
  { postalCode: '00611', city: 'Helsinki' },
  { postalCode: '00620', city: 'Helsinki' },
  { postalCode: '00621', city: 'Helsinki' },
  { postalCode: '00630', city: 'Helsinki' },
  { postalCode: '00640', city: 'Helsinki' },
  { postalCode: '00641', city: 'Helsinki' },
  { postalCode: '00650', city: 'Helsinki' },
  { postalCode: '00660', city: 'Helsinki' },
  { postalCode: '00661', city: 'Helsinki' },
  { postalCode: '00670', city: 'Helsinki' },
  { postalCode: '00671', city: 'Helsinki' },
  { postalCode: '00680', city: 'Helsinki' },
  { postalCode: '00681', city: 'Helsinki' },
  { postalCode: '00690', city: 'Helsinki' },
  { postalCode: '00691', city: 'Helsinki' },
  { postalCode: '00700', city: 'Helsinki' },
  { postalCode: '00701', city: 'Helsinki' },
  { postalCode: '00710', city: 'Helsinki' },
  { postalCode: '00711', city: 'Helsinki' },
  { postalCode: '00720', city: 'Helsinki' },
  { postalCode: '00721', city: 'Helsinki' },
  { postalCode: '00730', city: 'Helsinki' },
  { postalCode: '00731', city: 'Helsinki' },
  { postalCode: '00740', city: 'Helsinki' },
  { postalCode: '00741', city: 'Helsinki' },
  { postalCode: '00750', city: 'Helsinki' },
  { postalCode: '00751', city: 'Helsinki' },
  { postalCode: '00760', city: 'Helsinki' },
  { postalCode: '00761', city: 'Helsinki' },
  { postalCode: '00770', city: 'Helsinki' },
  { postalCode: '00771', city: 'Helsinki' },
  { postalCode: '00780', city: 'Helsinki' },
  { postalCode: '00781', city: 'Helsinki' },
  { postalCode: '00800', city: 'Helsinki' },
  { postalCode: '00801', city: 'Helsinki' },
  { postalCode: '00810', city: 'Helsinki' },
  { postalCode: '00811', city: 'Helsinki' },
  { postalCode: '00819', city: 'Helsinki' },
  { postalCode: '00820', city: 'Helsinki' },
  { postalCode: '00821', city: 'Helsinki' },
  { postalCode: '00830', city: 'Helsinki' },
  { postalCode: '00831', city: 'Helsinki' },
  { postalCode: '00840', city: 'Helsinki' },
  { postalCode: '00841', city: 'Helsinki' },
  { postalCode: '00850', city: 'Helsinki' },
  { postalCode: '00851', city: 'Helsinki' },
  { postalCode: '00860', city: 'Helsinki' },
  { postalCode: '00861', city: 'Helsinki' },
  { postalCode: '00870', city: 'Helsinki' },
  { postalCode: '00880', city: 'Helsinki' },
  { postalCode: '00881', city: 'Helsinki' },
  { postalCode: '00900', city: 'Helsinki' },
  { postalCode: '00901', city: 'Helsinki' },
  { postalCode: '00910', city: 'Helsinki' },
  { postalCode: '00911', city: 'Helsinki' },
  { postalCode: '00920', city: 'Helsinki' },
  { postalCode: '00921', city: 'Helsinki' },
  { postalCode: '00930', city: 'Helsinki' },
  { postalCode: '00931', city: 'Helsinki' },
  { postalCode: '00940', city: 'Helsinki' },
  { postalCode: '00941', city: 'Helsinki' },
  { postalCode: '00950', city: 'Helsinki' },
  { postalCode: '00951', city: 'Helsinki' },
  { postalCode: '00960', city: 'Helsinki' },
  { postalCode: '00970', city: 'Helsinki' },
  { postalCode: '00971', city: 'Helsinki' },
  { postalCode: '00980', city: 'Helsinki' },
  { postalCode: '00981', city: 'Helsinki' },
  { postalCode: '00990', city: 'Helsinki' },
  { postalCode: '01002', city: 'Vantaa' },
  { postalCode: '01003', city: 'Vantaa' },
  { postalCode: '01009', city: 'Veikkaus' },
  { postalCode: '01019', city: 'Ivo' },
  { postalCode: '01100', city: 'Itäsalmi' },
  { postalCode: '01101', city: 'Itäsalmi' },
  { postalCode: '01120', city: 'Västerskog' },
  { postalCode: '01121', city: 'Västerskog' },
  { postalCode: '01130', city: 'Gumbostrand' },
  { postalCode: '01150', city: 'Söderkulla' },
  { postalCode: '01151', city: 'Söderkulla' },
  { postalCode: '01180', city: 'Kalkkiranta' },
  { postalCode: '01190', city: 'Sipoo-Box' },
  { postalCode: '01200', city: 'Vantaa' },
  { postalCode: '01201', city: 'Vantaa' },
  { postalCode: '01230', city: 'Vantaa' },
  { postalCode: '01231', city: 'Vantaa' },
  { postalCode: '01260', city: 'Vantaa' },
  { postalCode: '01261', city: 'Vantaa' },
  { postalCode: '01280', city: 'Vantaa' },
  { postalCode: '01300', city: 'Vantaa' },
  { postalCode: '01301', city: 'Vantaa' },
  { postalCode: '01305', city: 'Vantaa 30/Yritysposti' },
  { postalCode: '01350', city: 'Vantaa' },
  { postalCode: '01351', city: 'Vantaa' },
  { postalCode: '01360', city: 'Vantaa' },
  { postalCode: '01361', city: 'Vantaa' },
  { postalCode: '01370', city: 'Vantaa' },
  { postalCode: '01380', city: 'Vantaa' },
  { postalCode: '01390', city: 'Vantaa' },
  { postalCode: '01391', city: 'Vantaa' },
  { postalCode: '01400', city: 'Vantaa' },
  { postalCode: '01401', city: 'Vantaa' },
  { postalCode: '01420', city: 'Vantaa' },
  { postalCode: '01450', city: 'Vantaa' },
  { postalCode: '01451', city: 'Vantaa' },
  { postalCode: '01480', city: 'Vantaa' },
  { postalCode: '01490', city: 'Vantaa' },
  { postalCode: '01510', city: 'Vantaa' },
  { postalCode: '01511', city: 'Vantaa' },
  { postalCode: '01520', city: 'Vantaa' },
  { postalCode: '01530', city: 'Vantaa' },
  { postalCode: '01531', city: 'Vantaa' },
  { postalCode: '01560', city: 'Maantiekylä' },
  { postalCode: '01590', city: 'Maisala' },
  { postalCode: '01600', city: 'Vantaa' },
  { postalCode: '01601', city: 'Vantaa' },
  { postalCode: '01610', city: 'Vantaa' },
  { postalCode: '01611', city: 'Vantaa' },
  { postalCode: '01620', city: 'Vantaa' },
  { postalCode: '01621', city: 'Vantaa' },
  { postalCode: '01630', city: 'Vantaa' },
  { postalCode: '01640', city: 'Vantaa' },
  { postalCode: '01641', city: 'Vantaa' },
  { postalCode: '01650', city: 'Vantaa' },
  { postalCode: '01651', city: 'Vantaa' },
  { postalCode: '01660', city: 'Vantaa' },
  { postalCode: '01661', city: 'Vantaa' },
  { postalCode: '01670', city: 'Vantaa' },
  { postalCode: '01671', city: 'Vantaa' },
  { postalCode: '01680', city: 'Vantaa' },
  { postalCode: '01690', city: 'Vantaa' },
  { postalCode: '01700', city: 'Vantaa' },
  { postalCode: '01710', city: 'Vantaa' },
  { postalCode: '01711', city: 'Vantaa' },
  { postalCode: '01720', city: 'Vantaa' },
  { postalCode: '01721', city: 'Vantaa' },
  { postalCode: '01730', city: 'Vantaa' },
  { postalCode: '01731', city: 'Vantaa' },
  { postalCode: '01740', city: 'Vantaa' },
  { postalCode: '01741', city: 'Vantaa' },
  { postalCode: '01750', city: 'Vantaa' },
  { postalCode: '01760', city: 'Vantaa' },
  { postalCode: '01770', city: 'Vantaa' },
  { postalCode: '01800', city: 'Klaukkala' },
  { postalCode: '01801', city: 'Klaukkala' },
  { postalCode: '01810', city: 'Luhtajoki' },
  { postalCode: '01820', city: 'Klaukkala' },
  { postalCode: '01830', city: 'Lepsämä' },
  { postalCode: '01850', city: 'Haikala' },
  { postalCode: '01860', city: 'Perttula' },
  { postalCode: '01900', city: 'Nurmijärvi' },
  { postalCode: '01901', city: 'Nurmijärvi' },
  { postalCode: '01940', city: 'Palojoki' },
  { postalCode: '02002', city: 'Espoo' },
  { postalCode: '02003', city: 'Espoo' },
  { postalCode: '02007', city: 'Espoo' },
  { postalCode: '02010', city: 'Tapiola' },
  { postalCode: '02015', city: 'Tkk' },
  { postalCode: '02020', city: 'Metsä' },
  { postalCode: '02044', city: 'Vtt' },
  { postalCode: '02050', city: 'Radiolinja' },
  { postalCode: '02100', city: 'Espoo' },
  { postalCode: '02101', city: 'Espoo' },
  { postalCode: '02110', city: 'Espoo' },
  { postalCode: '02111', city: 'Espoo' },
  { postalCode: '02120', city: 'Espoo' },
  { postalCode: '02130', city: 'Espoo' },
  { postalCode: '02131', city: 'Espoo' },
  { postalCode: '02140', city: 'Espoo' },
  { postalCode: '02141', city: 'Espoo' },
  { postalCode: '02150', city: 'Espoo' },
  { postalCode: '02151', city: 'Espoo' },
  { postalCode: '02160', city: 'Espoo' },
  { postalCode: '02170', city: 'Espoo' },
  { postalCode: '02171', city: 'Espoo' },
  { postalCode: '02180', city: 'Espoo' },
  { postalCode: '02181', city: 'Espoo' },
  { postalCode: '02200', city: 'Espoo' },
  { postalCode: '02201', city: 'Espoo' },
  { postalCode: '02210', city: 'Espoo' },
  { postalCode: '02211', city: 'Espoo' },
  { postalCode: '02230', city: 'Espoo' },
  { postalCode: '02231', city: 'Espoo' },
  { postalCode: '02240', city: 'Espoo' },
  { postalCode: '02241', city: 'Espoo' },
  { postalCode: '02260', city: 'Espoo' },
  { postalCode: '02261', city: 'Espoo' },
  { postalCode: '02270', city: 'Espoo' },
  { postalCode: '02271', city: 'Espoo' },
  { postalCode: '02280', city: 'Espoo' },
  { postalCode: '02281', city: 'Espoo' },
  { postalCode: '02290', city: 'Espoo' },
  { postalCode: '02300', city: 'Espoo' },
  { postalCode: '02320', city: 'Espoo' },
  { postalCode: '02321', city: 'Espoo' },
  { postalCode: '02330', city: 'Espoo' },
  { postalCode: '02340', city: 'Espoo' },
  { postalCode: '02341', city: 'Espoo' },
  { postalCode: '02360', city: 'Espoo' },
  { postalCode: '02361', city: 'Espoo' },
  { postalCode: '02380', city: 'Espoo' },
  { postalCode: '02400', city: 'Kirkkonummi' },
  { postalCode: '02401', city: 'Kirkkonummi' },
  { postalCode: '02410', city: 'Kirkkonummi' },
  { postalCode: '02411', city: 'Kirkkonummi' },
  { postalCode: '02420', city: 'Jorvas' },
  { postalCode: '02421', city: 'Jorvas' },
  { postalCode: '02430', city: 'Masala' },
  { postalCode: '02431', city: 'Masala' },
  { postalCode: '02440', city: 'Luoma' },
  { postalCode: '02460', city: 'Kantvik' },
  { postalCode: '02470', city: 'Upinniemi' },
  { postalCode: '02471', city: 'Upinniemi' },
  { postalCode: '02490', city: 'Pikkala' },
  { postalCode: '02510', city: 'Oitmäki' },
  { postalCode: '02520', city: 'Lapinkylä' },
  { postalCode: '02540', city: 'Kylmälä' },
  { postalCode: '02550', city: 'Evitskog' },
  { postalCode: '02570', city: 'Siuntio kk' },
  { postalCode: '02580', city: 'Siuntio as' },
  { postalCode: '02581', city: 'Siuntio' },
  { postalCode: '02590', city: 'Lappers' },
  { postalCode: '02600', city: 'Espoo' },
  { postalCode: '02601', city: 'Espoo' },
  { postalCode: '02610', city: 'Espoo' },
  { postalCode: '02611', city: 'Espoo' },
  { postalCode: '02620', city: 'Espoo' },
  { postalCode: '02621', city: 'Espoo' },
  { postalCode: '02630', city: 'Espoo' },
  { postalCode: '02631', city: 'Espoo' },
  { postalCode: '02660', city: 'Espoo' },
  { postalCode: '02661', city: 'Espoo' },
  { postalCode: '02680', city: 'Espoo' },
  { postalCode: '02700', city: 'Kauniainen' },
  { postalCode: '02701', city: 'Kauniainen' },
  { postalCode: '02710', city: 'Espoo' },
  { postalCode: '02711', city: 'Espoo' },
  { postalCode: '02720', city: 'Espoo' },
  { postalCode: '02721', city: 'Espoo' },
  { postalCode: '02730', city: 'Espoo' },
  { postalCode: '02731', city: 'Espoo' },
  { postalCode: '02740', city: 'Espoo' },
  { postalCode: '02741', city: 'Espoo' },
  { postalCode: '02750', city: 'Espoo' },
  { postalCode: '02760', city: 'Espoo' },
  { postalCode: '02761', city: 'Espoo' },
  { postalCode: '02770', city: 'Espoo' },
  { postalCode: '02771', city: 'Espoo' },
  { postalCode: '02780', city: 'Espoo' },
  { postalCode: '02781', city: 'Espoo' },
  { postalCode: '02810', city: 'Espoo' },
  { postalCode: '02811', city: 'Espoo' },
  { postalCode: '02820', city: 'Espoo' },
  { postalCode: '02821', city: 'Espoo' },
  { postalCode: '02860', city: 'Espoo' },
  { postalCode: '02861', city: 'Espoo' },
  { postalCode: '02880', city: 'Veikkola' },
  { postalCode: '02881', city: 'Veikkola' },
  { postalCode: '02920', city: 'Espoo' },
  { postalCode: '02921', city: 'Espoo' },
  { postalCode: '02940', city: 'Espoo' },
  { postalCode: '02941', city: 'Espoo' },
  { postalCode: '02970', city: 'Espoo' },
  { postalCode: '02971', city: 'Espoo' },
  { postalCode: '02980', city: 'Espoo' },
  { postalCode: '02981', city: 'Espoo' },
  { postalCode: '03007', city: 'Nummela' },
  { postalCode: '03100', city: 'Nummela' },
  { postalCode: '03101', city: 'Nummela' },
  { postalCode: '03150', city: 'Huhmari' },
  { postalCode: '03220', city: 'Tervalampi' },
  { postalCode: '03250', city: 'Ojakkala' },
  { postalCode: '03300', city: 'Otalampi' },
  { postalCode: '03320', city: 'Selki' },
  { postalCode: '03400', city: 'Vihti' },
  { postalCode: '03401', city: 'Vihti' },
  { postalCode: '03430', city: 'Jokikunta' },
  { postalCode: '03600', city: 'Karkkila' },
  { postalCode: '03601', city: 'Karkkila' },
  { postalCode: '03607', city: 'Karkkila' },
  { postalCode: '03620', city: 'Karkkila' },
  { postalCode: '03621', city: 'Karkkila' },
  { postalCode: '03710', city: 'Uusitalo' },
  { postalCode: '03790', city: 'Vihtijärvi' },
  { postalCode: '03810', city: 'Ikkala' },
  { postalCode: '03850', city: 'Pusula' },
  { postalCode: '03870', city: 'Hyönölä' },
  { postalCode: '03999', city: 'Helsinki' },
  { postalCode: '04110', city: 'Nikkilän Sairaala' },
  { postalCode: '04130', city: 'Sipoo' },
  { postalCode: '04131', city: 'Sipoo' },
  { postalCode: '04150', city: 'Martinkylä' },
  { postalCode: '04170', city: 'Paippinen' },
  { postalCode: '04200', city: 'Kerava' },
  { postalCode: '04201', city: 'Kerava' },
  { postalCode: '04207', city: 'Kerava' },
  { postalCode: '04220', city: 'Kerava' },
  { postalCode: '04221', city: 'Kerava' },
  { postalCode: '04230', city: 'Kerava' },
  { postalCode: '04231', city: 'Kerava' },
  { postalCode: '04240', city: 'Talma' },
  { postalCode: '04250', city: 'Kerava' },
  { postalCode: '04251', city: 'Kerava' },
  { postalCode: '04260', city: 'Kerava' },
  { postalCode: '04261', city: 'Kerava' },
  { postalCode: '04300', city: 'Tuusula' },
  { postalCode: '04301', city: 'Tuusula' },
  { postalCode: '04310', city: 'Tuusula' },
  { postalCode: '04311', city: 'Tuusula' },
  { postalCode: '04320', city: 'Tuusula' },
  { postalCode: '04321', city: 'Riihikallio' },
  { postalCode: '04330', city: 'Lahela' },
  { postalCode: '04340', city: 'Tuusula' },
  { postalCode: '04350', city: 'Nahkela' },
  { postalCode: '04360', city: 'Tuusula' },
  { postalCode: '04370', city: 'Rusutjärvi' },
  { postalCode: '04380', city: 'Tuusula' },
  { postalCode: '04390', city: 'Jäniksenlinna' },
  { postalCode: '04400', city: 'Järvenpää' },
  { postalCode: '04401', city: 'Järvenpää' },
  { postalCode: '04407', city: 'Järvenpää' },
  { postalCode: '04410', city: 'Järvenpää' },
  { postalCode: '04420', city: 'Järvenpää' },
  { postalCode: '04430', city: 'Järvenpää' },
  { postalCode: '04431', city: 'Järvenpää' },
  { postalCode: '04440', city: 'Järvenpää' },
  { postalCode: '04441', city: 'Järvenpää' },
  { postalCode: '04460', city: 'Nummenkylä' },
  { postalCode: '04480', city: 'Haarajoki' },
  { postalCode: '04500', city: 'Kellokoski' },
  { postalCode: '04501', city: 'Kellokoski' },
  { postalCode: '04530', city: 'Ohkola' },
  { postalCode: '04600', city: 'Mäntsälä' },
  { postalCode: '04601', city: 'Mäntsälä' },
  { postalCode: '04630', city: 'Sääkskylä' },
  { postalCode: '04660', city: 'Numminen' },
  { postalCode: '04680', city: 'Hirvihaara' },
  { postalCode: '04740', city: 'Sälinkää' },
  { postalCode: '04770', city: 'Sahakylä' },
  { postalCode: '04820', city: 'Kaukalampi' },
  { postalCode: '04840', city: 'Hautjärvi' },
  { postalCode: '04920', city: 'Saarentaus' },
  { postalCode: '04940', city: 'Levanto' },
  { postalCode: '05100', city: 'Röykkä' },
  { postalCode: '05130', city: 'Röykän Sairaala' },
  { postalCode: '05131', city: 'Röykän Sairaala' },
  { postalCode: '05200', city: 'Rajamäki' },
  { postalCode: '05201', city: 'Rajamäki' },
  { postalCode: '05250', city: 'Kiljava' },
  { postalCode: '05310', city: 'Raala' },
  { postalCode: '05400', city: 'Jokela' },
  { postalCode: '05401', city: 'Jokela' },
  { postalCode: '05430', city: 'Nuppulinna' },
  { postalCode: '05450', city: 'Nukari' },
  { postalCode: '05451', city: 'Nukari' },
  { postalCode: '05460', city: 'Hyvinkää' },
  { postalCode: '05470', city: 'Hyvinkää' },
  { postalCode: '05510', city: 'Hyvinkää' },
  { postalCode: '05620', city: 'Hyvinkää' },
  { postalCode: '05720', city: 'Hyvinkää' },
  { postalCode: '05800', city: 'Hyvinkää' },
  { postalCode: '05801', city: 'Hyvinkää' },
  { postalCode: '05807', city: 'Hyvinkää 7' },
  { postalCode: '05820', city: 'Hyvinkää' },
  { postalCode: '05821', city: 'Hyvinkää' },
  { postalCode: '05830', city: 'Hyvinkää' },
  { postalCode: '05831', city: 'Hyvinkää' },
  { postalCode: '05840', city: 'Hyvinkää' },
  { postalCode: '05841', city: 'Hyvinkää' },
  { postalCode: '05850', city: 'Hyvinkää' },
  { postalCode: '05860', city: 'Hyvinkää' },
  { postalCode: '05870', city: 'Salpakangas' },
  { postalCode: '05880', city: 'Hyvinkää' },
  { postalCode: '05881', city: 'Hyvinkää' },
  { postalCode: '05900', city: 'Hyvinkää' },
  { postalCode: '05901', city: 'Hyvinkää' },
  { postalCode: '05950', city: 'Hyvinkää' },
  { postalCode: '05999', city: 'Hyvinkää' },
  { postalCode: '06100', city: 'Porvoo' },
  { postalCode: '06101', city: 'Porvoo' },
  { postalCode: '06121', city: 'Vantaa' },
  { postalCode: '06150', city: 'Porvoo' },
  { postalCode: '06151', city: 'Porvoo' },
  { postalCode: '06200', city: 'Porvoo' },
  { postalCode: '06400', city: 'Porvoo' },
  { postalCode: '06401', city: 'Porvoo' },
  { postalCode: '06450', city: 'Porvoo' },
  { postalCode: '06451', city: 'Porvoo' },
  { postalCode: '06500', city: 'Porvoo' },
  { postalCode: '06530', city: 'Kerkkoo' },
  { postalCode: '06550', city: 'Hamari' },
  { postalCode: '06650', city: 'Hamari' },
  { postalCode: '06750', city: 'Tolkkinen' },
  { postalCode: '06820', city: 'Kulloo' },
  { postalCode: '06830', city: 'Kulloonkylä' },
  { postalCode: '06831', city: 'Kulloby' },
  { postalCode: '06850', city: 'Kulloo' },
  { postalCode: '06880', city: 'Kärrby' },
  { postalCode: '06881', city: 'Kärrby' },
  { postalCode: '06950', city: 'Emäsalo' },
  { postalCode: '06951', city: 'Emsalö' },
  { postalCode: '07110', city: 'Hinthaara' },
  { postalCode: '07130', city: 'Anttila' },
  { postalCode: '07150', city: 'Laukkoski' },
  { postalCode: '07170', city: 'Pornainen' },
  { postalCode: '07171', city: 'Pornainen' },
  { postalCode: '07190', city: 'Halkia' },
  { postalCode: '07220', city: 'Kaarenkylä' },
  { postalCode: '07230', city: 'Monninkylä' },
  { postalCode: '07231', city: 'Monninkylä' },
  { postalCode: '07280', city: 'Ilola' },
  { postalCode: '07281', city: 'Ilola' },
  { postalCode: '07310', city: 'Sannäs' },
  { postalCode: '07320', city: 'Jakari' },
  { postalCode: '07340', city: 'Epoo' },
  { postalCode: '07350', city: 'Gäddrag' },
  { postalCode: '07360', city: 'Tirmo' },
  { postalCode: '07370', city: 'Pellinki' },
  { postalCode: '07380', city: 'Österby' },
  { postalCode: '07390', city: 'Suurpellinki' },
  { postalCode: '07410', city: 'Krċkö' },
  { postalCode: '07450', city: 'Voolahti' },
  { postalCode: '07451', city: 'Voolahti' },
  { postalCode: '07500', city: 'Askola' },
  { postalCode: '07510', city: 'Vakkola' },
  { postalCode: '07530', city: 'Onkimaa' },
  { postalCode: '07540', city: 'Korttia' },
  { postalCode: '07550', city: 'Torpinkylä' },
  { postalCode: '07560', city: 'Pukkila' },
  { postalCode: '07565', city: 'Kantele' },
  { postalCode: '07580', city: 'Tiilää' },
  { postalCode: '07590', city: 'Huuvari' },
  { postalCode: '07600', city: 'Myrskylä' },
  { postalCode: '07601', city: 'Myrskylä' },
  { postalCode: '07680', city: 'Juornaankylä' },
  { postalCode: '07690', city: 'Kankkila' },
  { postalCode: '07700', city: 'Koskenkylän Saha' },
  { postalCode: '07701', city: 'Koskenkylän Saha' },
  { postalCode: '07720', city: 'Malmgċrd' },
  { postalCode: '07740', city: 'Gammelby' },
  { postalCode: '07750', city: 'Isnäs' },
  { postalCode: '07780', city: 'Härkäpää' },
  { postalCode: '07800', city: 'Lapinjärvi' },
  { postalCode: '07810', city: 'Ingermaninkylä' },
  { postalCode: '07820', city: 'Porlammi' },
  { postalCode: '07830', city: 'Pukaro' },
  { postalCode: '07840', city: 'Lindkoski' },
  { postalCode: '07850', city: 'Heikinkylä' },
  { postalCode: '07870', city: 'Skinnarby' },
  { postalCode: '07880', city: 'Liljendal' },
  { postalCode: '07881', city: 'Liljendal' },
  { postalCode: '07890', city: 'Mickelspiltom' },
  { postalCode: '07899', city: 'Loviisa' },
  { postalCode: '07900', city: 'Loviisa' },
  { postalCode: '07901', city: 'Loviisa' },
  { postalCode: '07910', city: 'Valko' },
  { postalCode: '07920', city: 'Loviisa' },
  { postalCode: '07930', city: 'Pernaja' },
  { postalCode: '07940', city: 'Loviisa' },
  { postalCode: '07945', city: 'Kuggom' },
  { postalCode: '07955', city: 'Tesjoki' },
  { postalCode: '07960', city: 'Ahvenkoski' },
  { postalCode: '07970', city: 'Ruotsinpyhtää' },
  { postalCode: '07980', city: 'Kuninkaankylä' },
  { postalCode: '07990', city: 'Ruotsinkylä' },
  { postalCode: '07999', city: 'Porvoo' },
  { postalCode: '08007', city: 'Lohja' },
  { postalCode: '08100', city: 'Lohja' },
  { postalCode: '08101', city: 'Lohja' },
  { postalCode: '08150', city: 'Lohja' },
  { postalCode: '08151', city: 'Lohja' },
  { postalCode: '08200', city: 'Lohja' },
  { postalCode: '08201', city: 'Lohja' },
  { postalCode: '08350', city: 'Routio' },
  { postalCode: '08360', city: 'Kisakallio' },
  { postalCode: '08450', city: 'Hormajärvi' },
  { postalCode: '08480', city: 'Karstu' },
  { postalCode: '08500', city: 'Lohja As' },
  { postalCode: '08501', city: 'Lohja As' },
  { postalCode: '08550', city: 'Lohja' },
  { postalCode: '08680', city: 'Muijala' },
  { postalCode: '08681', city: 'Muijala' },
  { postalCode: '08700', city: 'Virkkala' },
  { postalCode: '08701', city: 'Virkkala' },
  { postalCode: '08720', city: 'Virkkala' },
  { postalCode: '08800', city: 'Kirkniemi' },
  { postalCode: '09120', city: 'Karjalohja' },
  { postalCode: '09140', city: 'Risteys' },
  { postalCode: '09220', city: 'Sammatti' },
  { postalCode: '09430', city: 'Saukkola' },
  { postalCode: '09520', city: 'Millola' },
  { postalCode: '09630', city: 'Koisjärvi' },
  { postalCode: '09710', city: 'Hyrsynummi' },
  { postalCode: '09810', city: 'Nummi' },
  { postalCode: '09930', city: 'Leppäkorpi' },
  { postalCode: '09999', city: 'Lohja' },
  { postalCode: '10120', city: 'Tähtelä' },
  { postalCode: '10140', city: 'Päivölä' },
  { postalCode: '10160', city: 'Degerby Ul' },
  { postalCode: '10161', city: 'Degerby' },
  { postalCode: '10210', city: 'Inkoo' },
  { postalCode: '10211', city: 'Inkoo' },
  { postalCode: '10230', city: 'Inkoo As' },
  { postalCode: '10250', city: 'Fagervik' },
  { postalCode: '10270', city: 'Barösund' },
  { postalCode: '10300', city: 'Karjaa' },
  { postalCode: '10301', city: 'Karjaa' },
  { postalCode: '10320', city: 'Karjaa' },
  { postalCode: '10330', city: 'Pinjainen' },
  { postalCode: '10350', city: 'Meltolan Sairaala' },
  { postalCode: '10360', city: 'Mustio' },
  { postalCode: '10380', city: 'Kaunislahti' },
  { postalCode: '10410', city: 'Ċminnefors' },
  { postalCode: '10411', city: 'Ċminnefors' },
  { postalCode: '10420', city: 'Pohjankuru' },
  { postalCode: '10421', city: 'Pohjankuru' },
  { postalCode: '10440', city: 'Bollsta' },
  { postalCode: '10441', city: 'Bollsta' },
  { postalCode: '10470', city: 'Fiskari' },
  { postalCode: '10480', city: 'Antskog' },
  { postalCode: '10520', city: 'Tenhola' },
  { postalCode: '10570', city: 'Bromarv' },
  { postalCode: '10600', city: 'Tammisaari' },
  { postalCode: '10601', city: 'Tammisaari' },
  { postalCode: '10620', city: 'Högbacka' },
  { postalCode: '10640', city: 'Dragsvik' },
  { postalCode: '10641', city: 'Dragsvik' },
  { postalCode: '10650', city: 'Tammisaari' },
  { postalCode: '10651', city: 'Tammisaari' },
  { postalCode: '10660', city: 'Tammisaari' },
  { postalCode: '10680', city: 'Skogby' },
  { postalCode: '10710', city: 'Snappertuna' },
  { postalCode: '10820', city: 'Lappohja' },
  { postalCode: '10850', city: 'Tvärminne' },
  { postalCode: '10900', city: 'Hanko' },
  { postalCode: '10901', city: 'Hanko' },
  { postalCode: '10940', city: 'Hangonkylä' },
  { postalCode: '10960', city: 'Hanko Pohjoinen' },
  { postalCode: '10961', city: 'Hanko Pohjoinen' },
  { postalCode: '11007', city: 'Riihimäki' },
  { postalCode: '11100', city: 'Riihimäki' },
  { postalCode: '11101', city: 'Riihimäki' },
  { postalCode: '11110', city: 'Riihimäki' },
  { postalCode: '11111', city: 'Riihimäki' },
  { postalCode: '11120', city: 'Riihimäki' },
  { postalCode: '11130', city: 'Riihimäki' },
  { postalCode: '11131', city: 'Riihimäki' },
  { postalCode: '11310', city: 'Riihimäki' },
  { postalCode: '11311', city: 'Riihimäki' },
  { postalCode: '11710', city: 'Riihimäki' },
  { postalCode: '11711', city: 'Riihimäki' },
  { postalCode: '11910', city: 'Riihimäki' },
  { postalCode: '12100', city: 'Oitti' },
  { postalCode: '12101', city: 'Oitti' },
  { postalCode: '12130', city: 'Mommila' },
  { postalCode: '12170', city: 'Hietoinen' },
  { postalCode: '12210', city: 'Hausjärvi' },
  { postalCode: '12240', city: 'Hikiä' },
  { postalCode: '12310', city: 'Ryttylä' },
  { postalCode: '12350', city: 'Turkhauta' },
  { postalCode: '12380', city: 'Leppäkoski' },
  { postalCode: '12400', city: 'Tervakoski' },
  { postalCode: '12401', city: 'Tervakoski' },
  { postalCode: '12450', city: 'Vähikkälä' },
  { postalCode: '12520', city: 'Kormu' },
  { postalCode: '12540', city: 'Launonen' },
  { postalCode: '12600', city: 'Läyliäinen' },
  { postalCode: '12630', city: 'Sajaniemi' },
  { postalCode: '12640', city: 'Jokiniemi' },
  { postalCode: '12700', city: 'Loppi' },
  { postalCode: '12701', city: 'Loppi' },
  { postalCode: '12706', city: 'Marskinmaja' },
  { postalCode: '12750', city: 'Pilpala' },
  { postalCode: '12820', city: 'Räyskälä' },
  { postalCode: '12920', city: 'Topeno' },
  { postalCode: '12950', city: 'Sokala' },
  { postalCode: '12999', city: 'Riihimäki' },
  { postalCode: '13007', city: 'Hämeenlinna' },
  { postalCode: '13100', city: 'Hämeenlinna' },
  { postalCode: '13101', city: 'Hämeenlinna' },
  { postalCode: '13103', city: 'Jean sibeliuksen syntymäkoti' },
  { postalCode: '13110', city: 'Hämeenlinna' },
  { postalCode: '13111', city: 'Hämeenlinna' },
  { postalCode: '13130', city: 'Hämeenlinna' },
  { postalCode: '13131', city: 'Hämeenlinna' },
  { postalCode: '13160', city: 'Hämeenlinna' },
  { postalCode: '13200', city: 'Hämeenlinna' },
  { postalCode: '13201', city: 'Hämeenlinna' },
  { postalCode: '13210', city: 'Hämeenlinna' },
  { postalCode: '13211', city: 'Hämeenlinna' },
  { postalCode: '13220', city: 'Hämeenlinna' },
  { postalCode: '13230', city: 'Aulanko' },
  { postalCode: '13250', city: 'Hämeenlinna' },
  { postalCode: '13270', city: 'Hämeenlinna' },
  { postalCode: '13300', city: 'Hämeenlinna' },
  { postalCode: '13330', city: 'Harviala' },
  { postalCode: '13400', city: 'Hämeenlinna' },
  { postalCode: '13430', city: 'Hämeenlinna' },
  { postalCode: '13500', city: 'Hämeenlinna' },
  { postalCode: '13501', city: 'Hämeenlinna' },
  { postalCode: '13530', city: 'Hämeenlinna' },
  { postalCode: '13600', city: 'Hämeenlinna' },
  { postalCode: '13700', city: 'Parolannummi' },
  { postalCode: '13701', city: 'Parolannummi' },
  { postalCode: '13706', city: 'Parolan panssarimuseo' },
  { postalCode: '13720', city: 'Parola' },
  { postalCode: '13721', city: 'Parola' },
  { postalCode: '13800', city: 'Katinala' },
  { postalCode: '13806', city: 'Hattulan vanha kirkko' },
  { postalCode: '13880', city: 'Hattula' },
  { postalCode: '13900', city: 'Pekola' },
  { postalCode: '13930', city: 'Papinsalmi' },
  { postalCode: '14140', city: 'Jokimaa' },
  { postalCode: '14200', city: 'Turenki' },
  { postalCode: '14201', city: 'Turenki' },
  { postalCode: '14240', city: 'Janakkala' },
  { postalCode: '14300', city: 'Renko' },
  { postalCode: '14301', city: 'Renko' },
  { postalCode: '14330', city: 'Kaloinen' },
  { postalCode: '14370', city: 'Lukana' },
  { postalCode: '14430', city: 'Vuohiniemi' },
  { postalCode: '14450', city: 'Rimmilä' },
  { postalCode: '14470', city: 'Eteläinen' },
  { postalCode: '14500', city: 'Iittala' },
  { postalCode: '14501', city: 'Iittala' },
  { postalCode: '14530', city: 'Leteensuo' },
  { postalCode: '14580', city: 'Kuurila' },
  { postalCode: '14610', city: 'Lepaa' },
  { postalCode: '14620', city: 'Tyrväntö' },
  { postalCode: '14680', city: 'Alvettula' },
  { postalCode: '14690', city: 'Torvoila' },
  { postalCode: '14700', city: 'Hauho' },
  { postalCode: '14701', city: 'Hauho' },
  { postalCode: '14760', city: 'Kokkila' },
  { postalCode: '14770', city: 'Eteläinen' },
  { postalCode: '14810', city: 'Pohjoinen' },
  { postalCode: '14820', city: 'Tuulos' },
  { postalCode: '14840', city: 'Sairiala' },
  { postalCode: '14870', city: 'Juttila' },
  { postalCode: '14930', city: 'Sappee' },
  { postalCode: '14960', city: 'Puutikkala' },
  { postalCode: '14980', city: 'Kuohijoki' },
  { postalCode: '14999', city: 'Hämeenlinna' },
  { postalCode: '15007', city: 'Lahti' },
  { postalCode: '15100', city: 'Lahti' },
  { postalCode: '15101', city: 'Lahti' },
  { postalCode: '15110', city: 'Lahti' },
  { postalCode: '15111', city: 'Lahti' },
  { postalCode: '15140', city: 'Lahti' },
  { postalCode: '15141', city: 'Lahti' },
  { postalCode: '15150', city: 'Lahti' },
  { postalCode: '15151', city: 'Lahti' },
  { postalCode: '15170', city: 'Lahti' },
  { postalCode: '15171', city: 'Lahti' },
  { postalCode: '15200', city: 'Lahti' },
  { postalCode: '15201', city: 'Lahti' },
  { postalCode: '15210', city: 'Lahti' },
  { postalCode: '15211', city: 'Lahti' },
  { postalCode: '15230', city: 'Lahti' },
  { postalCode: '15240', city: 'Lahti' },
  { postalCode: '15241', city: 'Lahti' },
  { postalCode: '15250', city: 'Lahti' },
  { postalCode: '15251', city: 'Lahti' },
  { postalCode: '15270', city: 'Kukkila' },
  { postalCode: '15300', city: 'Lahti' },
  { postalCode: '15320', city: 'Lahti' },
  { postalCode: '15321', city: 'Lahti' },
  { postalCode: '15340', city: 'Lahti' },
  { postalCode: '15440', city: 'Villähde' },
  { postalCode: '15460', city: 'Mäkelä' },
  { postalCode: '15500', city: 'Lahti' },
  { postalCode: '15501', city: 'Lahti' },
  { postalCode: '15520', city: 'Lahti' },
  { postalCode: '15540', city: 'Villähde' },
  { postalCode: '15541', city: 'Villähde' },
  { postalCode: '15550', city: 'Nastola 2' },
  { postalCode: '15551', city: 'Nastola 2' },
  { postalCode: '15560', city: 'Nastola' },
  { postalCode: '15561', city: 'Nastola' },
  { postalCode: '15570', city: 'Nastola Kk' },
  { postalCode: '15580', city: 'Ruuhijärvi' },
  { postalCode: '15610', city: 'Lahti' },
  { postalCode: '15611', city: 'Lahti' },
  { postalCode: '15650', city: 'Lahti' },
  { postalCode: '15680', city: 'Lahti' },
  { postalCode: '15700', city: 'Lahti' },
  { postalCode: '15701', city: 'Lahti' },
  { postalCode: '15780', city: 'Lahti' },
  { postalCode: '15781', city: 'Lahti' },
  { postalCode: '15800', city: 'Lahti' },
  { postalCode: '15801', city: 'Lahti' },
  { postalCode: '15810', city: 'Lahti' },
  { postalCode: '15820', city: 'Lahti' },
  { postalCode: '15830', city: 'Lahti' },
  { postalCode: '15840', city: 'Lahti' },
  { postalCode: '15850', city: 'Lahti' },
  { postalCode: '15851', city: 'Lahti' },
  { postalCode: '15860', city: 'Hollola 2' },
  { postalCode: '15861', city: 'Hollola' },
  { postalCode: '15870', city: 'Hollola' },
  { postalCode: '15871', city: 'Hollola' },
  { postalCode: '15880', city: 'Hollola 3' },
  { postalCode: '15881', city: 'Hollola 3' },
  { postalCode: '15900', city: 'Lahti' },
  { postalCode: '15901', city: 'Lahti' },
  { postalCode: '15950', city: 'Lahti' },
  { postalCode: '15951', city: 'Lahti' },
  { postalCode: '15980', city: 'Messilä' },
  { postalCode: '16100', city: 'Uusikylä' },
  { postalCode: '16101', city: 'Uusikylä' },
  { postalCode: '16160', city: 'Okkeri' },
  { postalCode: '16200', city: 'Artjärvi' },
  { postalCode: '16201', city: 'Artjärvi' },
  { postalCode: '16210', city: 'Hiitelä' },
  { postalCode: '16230', city: 'Artjärvi Kk' },
  { postalCode: '16260', city: 'Hietana' },
  { postalCode: '16270', city: 'Villikkala' },
  { postalCode: '16280', city: 'Kuivanto' },
  { postalCode: '16300', city: 'Orimattila' },
  { postalCode: '16301', city: 'Orimattila' },
  { postalCode: '16310', city: 'Virenoja' },
  { postalCode: '16320', city: 'Pennala' },
  { postalCode: '16330', city: 'Heinämaa' },
  { postalCode: '16350', city: 'Niinikoski' },
  { postalCode: '16380', city: 'Riihioja' },
  { postalCode: '16390', city: 'Pakaa' },
  { postalCode: '16450', city: 'Mallusjoki' },
  { postalCode: '16500', city: 'Herrala' },
  { postalCode: '16510', city: 'Luhtikylä' },
  { postalCode: '16540', city: 'Mertie' },
  { postalCode: '16560', city: 'Tienmutka' },
  { postalCode: '16600', city: 'Järvelä' },
  { postalCode: '16601', city: 'Järvelä' },
  { postalCode: '16610', city: 'Kärkölä' },
  { postalCode: '16630', city: 'Tennilä' },
  { postalCode: '16670', city: 'Lappila' },
  { postalCode: '16710', city: 'Hollola' },
  { postalCode: '16730', city: 'Kutajärvi' },
  { postalCode: '16740', city: 'Sairakkala' },
  { postalCode: '16790', city: 'Manskivi' },
  { postalCode: '16800', city: 'Hämeenkoski' },
  { postalCode: '16801', city: 'Hämeenkoski' },
  { postalCode: '16900', city: 'Lammi' },
  { postalCode: '16901', city: 'Lammi' },
  { postalCode: '16940', city: 'Lieso' },
  { postalCode: '16960', city: 'Iso-Evo' },
  { postalCode: '16970', city: 'Evo' },
  { postalCode: '16980', city: 'Ronni' },
  { postalCode: '16999', city: 'Lahti' },
  { postalCode: '17110', city: 'Kalliola' },
  { postalCode: '17120', city: 'Paimela' },
  { postalCode: '17130', city: 'Vesivehmaa' },
  { postalCode: '17150', city: 'Urajärvi' },
  { postalCode: '17200', city: 'Vääksy 2' },
  { postalCode: '17201', city: 'Vääksy' },
  { postalCode: '17210', city: 'Vuorenmylly' },
  { postalCode: '17220', city: 'Pietilä' },
  { postalCode: '17240', city: 'Kalkkinen' },
  { postalCode: '17300', city: 'Vääksy' },
  { postalCode: '17301', city: 'Vääksy' },
  { postalCode: '17320', city: 'Asikkala' },
  { postalCode: '17410', city: 'Viitaila' },
  { postalCode: '17430', city: 'Kurhila' },
  { postalCode: '17440', city: 'Vähimaa' },
  { postalCode: '17450', city: 'Iso-Äiniö' },
  { postalCode: '17470', city: 'Maakeski' },
  { postalCode: '17500', city: 'Padasjoki' },
  { postalCode: '17501', city: 'Padasjoki' },
  { postalCode: '17510', city: 'Nyystölä' },
  { postalCode: '17530', city: 'Arrakoski' },
  { postalCode: '17610', city: 'Auttoinen' },
  { postalCode: '17630', city: 'Vesijako' },
  { postalCode: '17710', city: 'Torittu' },
  { postalCode: '17740', city: 'Kasiniemi' },
  { postalCode: '17780', city: 'Harmoinen' },
  { postalCode: '17800', city: 'Kuhmoinen' },
  { postalCode: '17801', city: 'Kuhmoinen' },
  { postalCode: '17840', city: 'Harjunsalmi' },
  { postalCode: '17850', city: 'Pihlajakoski' },
  { postalCode: '17870', city: 'Ruolahti' },
  { postalCode: '17930', city: 'Päijälä' },
  { postalCode: '17950', city: 'Kylämä' },
  { postalCode: '17970', city: 'Puukkoinen' },
  { postalCode: '18100', city: 'Heinola' },
  { postalCode: '18101', city: 'Heinola' },
  { postalCode: '18108', city: 'Heinola leirintä' },
  { postalCode: '18111', city: 'Heinola' },
  { postalCode: '18120', city: 'Heinola' },
  { postalCode: '18130', city: 'Heinola' },
  { postalCode: '18150', city: 'Heinola' },
  { postalCode: '18200', city: 'Heinola' },
  { postalCode: '18300', city: 'Heinola Kk' },
  { postalCode: '18600', city: 'Myllyoja' },
  { postalCode: '19100', city: 'Vierumäki' },
  { postalCode: '19110', city: 'Vierumäki' },
  { postalCode: '19111', city: 'Vierumäki' },
  { postalCode: '19120', city: 'Vierumäki' },
  { postalCode: '19150', city: 'Vuolenkoski' },
  { postalCode: '19160', city: 'Huutotöyry' },
  { postalCode: '19210', city: 'Lusi' },
  { postalCode: '19220', city: 'Vahteristo' },
  { postalCode: '19230', city: 'Onkiniemi' },
  { postalCode: '19250', city: 'Syrjäkoski' },
  { postalCode: '19260', city: 'Paaso' },
  { postalCode: '19310', city: 'Karilanmaa' },
  { postalCode: '19320', city: 'Nikkaroinen' },
  { postalCode: '19340', city: 'Ravioskorpi' },
  { postalCode: '19350', city: 'Ojankorva' },
  { postalCode: '19370', city: 'Nuoramoinen' },
  { postalCode: '19380', city: 'Marjakylä' },
  { postalCode: '19410', city: 'Kuortti' },
  { postalCode: '19420', city: 'Mansikkamäki' },
  { postalCode: '19430', city: 'Pertunmaa' },
  { postalCode: '19460', city: 'Ruorasmäki' },
  { postalCode: '19470', city: 'Lihavanpää' },
  { postalCode: '19480', city: 'Hartosenpää' },
  { postalCode: '19510', city: 'Lepsala' },
  { postalCode: '19520', city: 'Salajärvi' },
  { postalCode: '19530', city: 'Kuivajärvi' },
  { postalCode: '19540', city: 'Koitti' },
  { postalCode: '19560', city: 'Nokka' },
  { postalCode: '19600', city: 'Hartola' },
  { postalCode: '19601', city: 'Hartola' },
  { postalCode: '19610', city: 'Murakka' },
  { postalCode: '19620', city: 'Pohela' },
  { postalCode: '19630', city: 'Kalhonkylä' },
  { postalCode: '19650', city: 'Joutsa' },
  { postalCode: '19651', city: 'Joutsa' },
  { postalCode: '19670', city: 'Mieskonmäki' },
  { postalCode: '19700', city: 'Sysmä' },
  { postalCode: '19701', city: 'Sysmä' },
  { postalCode: '19710', city: 'Suopelto' },
  { postalCode: '19730', city: 'Särkilampi' },
  { postalCode: '19740', city: 'Liikola' },
  { postalCode: '19770', city: 'Valittula' },
  { postalCode: '19810', city: 'Siltasuo' },
  { postalCode: '19850', city: 'Putkijärvi' },
  { postalCode: '19910', city: 'Tammijärvi' },
  { postalCode: '19920', city: 'Pappinen' },
  { postalCode: '19950', city: 'Luhanka' },
  { postalCode: '19970', city: 'Putkilahti' },
  { postalCode: '19980', city: 'Oittila' },
  { postalCode: '19999', city: 'Heinola' },
  { postalCode: '20002', city: 'Turku' },
  { postalCode: '20003', city: 'Turku' },
  { postalCode: '20007', city: 'Turku' },
  { postalCode: '20014', city: 'Turun Yliopisto' },
  { postalCode: '20075', city: 'Sampo' },
  { postalCode: '20100', city: 'Turku' },
  { postalCode: '20101', city: 'Turku' },
  { postalCode: '20110', city: 'Turku' },
  { postalCode: '20111', city: 'Turku' },
  { postalCode: '20120', city: 'Turku' },
  { postalCode: '20140', city: 'Turku' },
  { postalCode: '20141', city: 'Turku' },
  { postalCode: '20173', city: 'Turun linna' },
  { postalCode: '20200', city: 'Turku' },
  { postalCode: '20201', city: 'Turku' },
  { postalCode: '20210', city: 'Turku' },
  { postalCode: '20211', city: 'Turku' },
  { postalCode: '20240', city: 'Turku' },
  { postalCode: '20241', city: 'Turku' },
  { postalCode: '20250', city: 'Turku' },
  { postalCode: '20251', city: 'Turku' },
  { postalCode: '20260', city: 'Raisio' },
  { postalCode: '20270', city: 'Ihala' },
  { postalCode: '20300', city: 'Turku' },
  { postalCode: '20301', city: 'Turku' },
  { postalCode: '20310', city: 'Turku' },
  { postalCode: '20311', city: 'Turku' },
  { postalCode: '20320', city: 'Turku' },
  { postalCode: '20321', city: 'Turku' },
  { postalCode: '20340', city: 'Turku' },
  { postalCode: '20350', city: 'Turku' },
  { postalCode: '20351', city: 'Turku' },
  { postalCode: '20360', city: 'Turku' },
  { postalCode: '20361', city: 'Turku' },
  { postalCode: '20380', city: 'Turku' },
  { postalCode: '20381', city: 'Turku' },
  { postalCode: '20400', city: 'Turku' },
  { postalCode: '20460', city: 'Turku' },
  { postalCode: '20500', city: 'Turku' },
  { postalCode: '20501', city: 'Turku' },
  { postalCode: '20510', city: 'Turku' },
  { postalCode: '20520', city: 'Turku' },
  { postalCode: '20521', city: 'Turku' },
  { postalCode: '20540', city: 'Turku' },
  { postalCode: '20541', city: 'Turku' },
  { postalCode: '20600', city: 'Turku' },
  { postalCode: '20601', city: 'Turku' },
  { postalCode: '20610', city: 'Turku' },
  { postalCode: '20611', city: 'Turku' },
  { postalCode: '20640', city: 'Ravattula' },
  { postalCode: '20660', city: 'Littoinen' },
  { postalCode: '20661', city: 'Littoinen' },
  { postalCode: '20700', city: 'Turku' },
  { postalCode: '20701', city: 'Turku' },
  { postalCode: '20703', city: 'Turku-luostarinmäki' },
  { postalCode: '20720', city: 'Turku' },
  { postalCode: '20740', city: 'Turku' },
  { postalCode: '20741', city: 'Turku' },
  { postalCode: '20750', city: 'Turku' },
  { postalCode: '20751', city: 'Turku' },
  { postalCode: '20760', city: 'Piispanristi' },
  { postalCode: '20761', city: 'Piispanristi' },
  { postalCode: '20780', city: 'Kaarina' },
  { postalCode: '20781', city: 'Kaarina' },
  { postalCode: '20800', city: 'Turku' },
  { postalCode: '20801', city: 'Turku' },
  { postalCode: '20810', city: 'Turku' },
  { postalCode: '20811', city: 'Turku' },
  { postalCode: '20840', city: 'Turku' },
  { postalCode: '20880', city: 'Turku' },
  { postalCode: '20881', city: 'Turku' },
  { postalCode: '20900', city: 'Turku' },
  { postalCode: '20901', city: 'Turku' },
  { postalCode: '20960', city: 'Turku' },
  { postalCode: '20990', city: 'Turku' },
  { postalCode: '21003', city: 'Raisio' },
  { postalCode: '21100', city: 'Naantali' },
  { postalCode: '21101', city: 'Naantali' },
  { postalCode: '21110', city: 'Naantali' },
  { postalCode: '21111', city: 'Naantali' },
  { postalCode: '21120', city: 'Raisio' },
  { postalCode: '21130', city: 'Poikko' },
  { postalCode: '21140', city: 'Rymättylä' },
  { postalCode: '21150', city: 'Röölä' },
  { postalCode: '21160', city: 'Merimasku' },
  { postalCode: '21180', city: 'Livonsaari' },
  { postalCode: '21195', city: 'Velkua' },
  { postalCode: '21200', city: 'Raisio' },
  { postalCode: '21201', city: 'Raisio' },
  { postalCode: '21207', city: 'Raisio' },
  { postalCode: '21210', city: 'Raisio' },
  { postalCode: '21211', city: 'Raisio' },
  { postalCode: '21220', city: 'Raisio' },
  { postalCode: '21230', city: 'Lemu' },
  { postalCode: '21240', city: 'Askainen' },
  { postalCode: '21250', city: 'Masku' },
  { postalCode: '21251', city: 'Masku' },
  { postalCode: '21260', city: 'Raisio' },
  { postalCode: '21270', city: 'Nousiainen' },
  { postalCode: '21271', city: 'Nousiainen' },
  { postalCode: '21280', city: 'Raisio' },
  { postalCode: '21290', city: 'Rusko' },
  { postalCode: '21291', city: 'Rusko' },
  { postalCode: '21310', city: 'Vahto' },
  { postalCode: '21330', city: 'Paattinen' },
  { postalCode: '21340', city: 'Tortinmäki' },
  { postalCode: '21350', city: 'Ilmarinen' },
  { postalCode: '21360', city: 'Lieto As' },
  { postalCode: '21370', city: 'Aura Kk' },
  { postalCode: '21380', city: 'Aura' },
  { postalCode: '21381', city: 'Aura' },
  { postalCode: '21390', city: 'Uusikartano' },
  { postalCode: '21410', city: 'Vanhalinna' },
  { postalCode: '21420', city: 'Lieto' },
  { postalCode: '21421', city: 'Lieto' },
  { postalCode: '21430', city: 'Yliskulma' },
  { postalCode: '21450', city: 'Tarvasjoki' },
  { postalCode: '21470', city: 'Suurila' },
  { postalCode: '21480', city: 'Prunkila' },
  { postalCode: '21490', city: 'Marttila' },
  { postalCode: '21500', city: 'Piikkiö' },
  { postalCode: '21501', city: 'Piikkiö' },
  { postalCode: '21510', city: 'Hevonpää' },
  { postalCode: '21520', city: 'Naskarla' },
  { postalCode: '21530', city: 'Paimio' },
  { postalCode: '21531', city: 'Paimio' },
  { postalCode: '21540', city: 'Preitilä' },
  { postalCode: '21555', city: 'Taatila' },
  { postalCode: '21560', city: 'Ollila' },
  { postalCode: '21570', city: 'Sauvo' },
  { postalCode: '21571', city: 'Sauvo' },
  { postalCode: '21590', city: 'Karuna' },
  { postalCode: '21600', city: 'Parainen' },
  { postalCode: '21601', city: 'Parainen' },
  { postalCode: '21610', city: 'Kirjala' },
  { postalCode: '21620', city: 'Kuusisto' },
  { postalCode: '21630', city: 'Lielahti Tl' },
  { postalCode: '21650', city: 'Lillandet' },
  { postalCode: '21660', city: 'Nauvo' },
  { postalCode: '21661', city: 'Nauvo' },
  { postalCode: '21670', city: 'Mattnäs' },
  { postalCode: '21680', city: 'Nötö' },
  { postalCode: '21710', city: 'Korppoo' },
  { postalCode: '21711', city: 'Korppoo' },
  { postalCode: '21720', city: 'Korpoström' },
  { postalCode: '21740', city: 'Utö' },
  { postalCode: '21750', city: 'Norrskata' },
  { postalCode: '21760', city: 'Houtskär' },
  { postalCode: '21770', city: 'Mossala' },
  { postalCode: '21780', city: 'Nċtö' },
  { postalCode: '21800', city: 'Kyrö' },
  { postalCode: '21801', city: 'Kyrö' },
  { postalCode: '21820', city: 'Kumila' },
  { postalCode: '21830', city: 'Rahkio' },
  { postalCode: '21840', city: 'Karinainen' },
  { postalCode: '21860', city: 'Auvainen' },
  { postalCode: '21870', city: 'Riihikoski' },
  { postalCode: '21871', city: 'Riihikoski' },
  { postalCode: '21880', city: 'Pöytyä' },
  { postalCode: '21890', city: 'Haveri' },
  { postalCode: '21900', city: 'Yläne' },
  { postalCode: '21901', city: 'Yläne' },
  { postalCode: '21930', city: 'Uusikartano' },
  { postalCode: '21970', city: 'Kolvaa' },
  { postalCode: '21999', city: 'Turku' },
  { postalCode: '22100', city: 'Mariehamn' },
  { postalCode: '22101', city: 'Mariehamn' },
  { postalCode: '22120', city: 'Maarianhamina' },
  { postalCode: '22130', city: 'Gottby' },
  { postalCode: '22131', city: 'Gottby' },
  { postalCode: '22140', city: 'Mariehamn' },
  { postalCode: '22150', city: 'Jomala' },
  { postalCode: '22151', city: 'Jomala' },
  { postalCode: '22160', city: 'Mariehamn' },
  { postalCode: '22220', city: 'Emkarby' },
  { postalCode: '22240', city: 'Hammarland' },
  { postalCode: '22260', city: 'Eckerö' },
  { postalCode: '22270', city: 'Eckerö' },
  { postalCode: '22271', city: 'Storby' },
  { postalCode: '22310', city: 'Pċlsböle' },
  { postalCode: '22320', city: 'Ödkarby' },
  { postalCode: '22330', city: 'Tjudö' },
  { postalCode: '22340', city: 'Geta' },
  { postalCode: '22410', city: 'Godby' },
  { postalCode: '22411', city: 'Godby' },
  { postalCode: '22430', city: 'Saltvik' },
  { postalCode: '22520', city: 'Kastelholm' },
  { postalCode: '22530', city: 'Sund' },
  { postalCode: '22540', city: 'Bomarsund' },
  { postalCode: '22550', city: 'Vċrdö' },
  { postalCode: '22610', city: 'Lemland' },
  { postalCode: '22630', city: 'Lumparland' },
  { postalCode: '22710', city: 'Föglö' },
  { postalCode: '22720', city: 'Sottunga' },
  { postalCode: '22730', city: 'Kökar' },
  { postalCode: '22810', city: 'Seglinge' },
  { postalCode: '22820', city: 'Kumlinge' },
  { postalCode: '22830', city: 'Enklinge' },
  { postalCode: '22840', city: 'Lappoby-Ċland' },
  { postalCode: '22910', city: 'Torsholma' },
  { postalCode: '22920', city: 'Brändö-Ċland' },
  { postalCode: '22930', city: 'Fiskö' },
  { postalCode: '22940', city: 'Ċva' },
  { postalCode: '22950', city: 'Jurmo-Ċland' },
  { postalCode: '22999', city: 'Mariehamn' },
  { postalCode: '23003', city: 'Uusikaupunki' },
  { postalCode: '23100', city: 'Mynämäki' },
  { postalCode: '23101', city: 'Mynämäki' },
  { postalCode: '23110', city: 'Mynämäki As' },
  { postalCode: '23120', city: 'Mietoinen' },
  { postalCode: '23140', city: 'Hietamäki' },
  { postalCode: '23170', city: 'Kivikylä' },
  { postalCode: '23200', city: 'Vinkkilä' },
  { postalCode: '23201', city: 'Vinkkilä' },
  { postalCode: '23210', city: 'Vehmaa' },
  { postalCode: '23240', city: 'Ennyinen' },
  { postalCode: '23260', city: 'Rautila' },
  { postalCode: '23310', city: 'Taivassalo' },
  { postalCode: '23311', city: 'Taivassalo' },
  { postalCode: '23360', city: 'Kustavi' },
  { postalCode: '23390', city: 'Iniö' },
  { postalCode: '23450', city: 'Lokalahti' },
  { postalCode: '23451', city: 'Lokalahti' },
  { postalCode: '23500', city: 'Uusikaupunki' },
  { postalCode: '23501', city: 'Uusikaupunki' },
  { postalCode: '23520', city: 'Uusikaupunki' },
  { postalCode: '23530', city: 'Uusikaupunki' },
  { postalCode: '23600', city: 'Kalanti' },
  { postalCode: '23601', city: 'Kalanti' },
  { postalCode: '23660', city: 'Kalanti As' },
  { postalCode: '23800', city: 'Laitila' },
  { postalCode: '23801', city: 'Laitila' },
  { postalCode: '23840', city: 'Soukainen' },
  { postalCode: '23880', city: 'Suontaka' },
  { postalCode: '23910', city: 'Raulio' },
  { postalCode: '23930', city: 'Pyhämaa' },
  { postalCode: '23940', city: 'Eilu' },
  { postalCode: '23950', city: 'Pyhäranta' },
  { postalCode: '23960', city: 'Santtio' },
  { postalCode: '24003', city: 'Salo' },
  { postalCode: '24007', city: 'Salo' },
  { postalCode: '24100', city: 'Salo' },
  { postalCode: '24101', city: 'Salo' },
  { postalCode: '24105', city: 'Salo 10/Yritysposti' },
  { postalCode: '24130', city: 'Salo' },
  { postalCode: '24240', city: 'Salo' },
  { postalCode: '24241', city: 'Salo' },
  { postalCode: '24260', city: 'Salo' },
  { postalCode: '24261', city: 'Salo' },
  { postalCode: '24280', city: 'Salo' },
  { postalCode: '24520', city: 'Inkere' },
  { postalCode: '24540', city: 'Romsila' },
  { postalCode: '24800', city: 'Halikko' },
  { postalCode: '24801', city: 'Halikko' },
  { postalCode: '24910', city: 'Halikko As' },
  { postalCode: '25003', city: 'Salo' },
  { postalCode: '25110', city: 'Kruusila' },
  { postalCode: '25130', city: 'Muurla' },
  { postalCode: '25160', city: 'Tuohittu' },
  { postalCode: '25170', city: 'Kotalato' },
  { postalCode: '25180', city: 'Romsila' },
  { postalCode: '25190', city: 'Inkere' },
  { postalCode: '25191', city: 'Inkere' },
  { postalCode: '25210', city: 'Vartsala' },
  { postalCode: '25230', city: 'Angelniemi' },
  { postalCode: '25240', city: 'Hajala' },
  { postalCode: '25250', city: 'Märynummi' },
  { postalCode: '25260', city: 'Vaskio' },
  { postalCode: '25310', city: 'Impola' },
  { postalCode: '25320', city: 'Raatala' },
  { postalCode: '25330', city: 'Kuusjoki' },
  { postalCode: '25340', city: 'Kanunki' },
  { postalCode: '25350', city: 'Häntälä' },
  { postalCode: '25360', city: 'Pertteli' },
  { postalCode: '25370', city: 'Hiisi' },
  { postalCode: '25380', city: 'Rekijoki' },
  { postalCode: '25385', city: 'Hirvelä' },
  { postalCode: '25390', city: 'Kiikala' },
  { postalCode: '25391', city: 'Kiikala' },
  { postalCode: '25410', city: 'Suomusjärvi' },
  { postalCode: '25420', city: 'Lahnajärvi' },
  { postalCode: '25430', city: 'Kettula' },
  { postalCode: '25450', city: 'Kurkela' },
  { postalCode: '25460', city: 'Toija' },
  { postalCode: '25461', city: 'Toija' },
  { postalCode: '25470', city: 'Kisko' },
  { postalCode: '25500', city: 'Perniö' },
  { postalCode: '25501', city: 'Perniö' },
  { postalCode: '25520', city: 'Perniö As' },
  { postalCode: '25530', city: 'Ervelä' },
  { postalCode: '25540', city: 'Knaapila' },
  { postalCode: '25550', city: 'Aijala' },
  { postalCode: '25560', city: 'Koski As' },
  { postalCode: '25570', city: 'Teijo' },
  { postalCode: '25610', city: 'Ylönkylä' },
  { postalCode: '25630', city: 'Särkisalo' },
  { postalCode: '25640', city: 'Förby' },
  { postalCode: '25650', city: 'Strömma' },
  { postalCode: '25660', city: 'Mathildedal' },
  { postalCode: '25690', city: 'Grċböle' },
  { postalCode: '25700', city: 'Kemiö' },
  { postalCode: '25701', city: 'Kemiö' },
  { postalCode: '25710', city: 'Vreta' },
  { postalCode: '25730', city: 'Mjösund' },
  { postalCode: '25760', city: 'Kiila' },
  { postalCode: '25770', city: 'Reku' },
  { postalCode: '25780', city: 'Pedersċ' },
  { postalCode: '25810', city: 'Mark' },
  { postalCode: '25820', city: 'Vestlax' },
  { postalCode: '25830', city: 'Västanfjärd' },
  { postalCode: '25840', city: 'Nivelax' },
  { postalCode: '25860', city: 'Björkboda' },
  { postalCode: '25870', city: 'Dragsfjärd' },
  { postalCode: '25900', city: 'Taalintehdas' },
  { postalCode: '25901', city: 'Taalintehdas' },
  { postalCode: '25910', city: 'Vänoxa' },
  { postalCode: '25930', city: 'Kasnäs' },
  { postalCode: '25940', city: 'Hiittinen' },
  { postalCode: '25950', city: 'Rosala' },
  { postalCode: '25960', city: 'Högsċra' },
  { postalCode: '25999', city: 'Salo' },
  { postalCode: '26003', city: 'Rauma' },
  { postalCode: '26007', city: 'Rauma' },
  { postalCode: '26100', city: 'Rauma' },
  { postalCode: '26101', city: 'Rauma' },
  { postalCode: '26130', city: 'Rauma' },
  { postalCode: '26131', city: 'Rauma' },
  { postalCode: '26200', city: 'Rauma' },
  { postalCode: '26201', city: 'Rauma' },
  { postalCode: '26410', city: 'Kaaro' },
  { postalCode: '26411', city: 'Kaaro' },
  { postalCode: '26510', city: 'Rauma' },
  { postalCode: '26511', city: 'Rauma' },
  { postalCode: '26560', city: 'Kolla' },
  { postalCode: '26660', city: 'Rauma' },
  { postalCode: '26661', city: 'Rauma' },
  { postalCode: '26700', city: 'Hinnerjoki' },
  { postalCode: '26720', city: 'Monnanummi' },
  { postalCode: '26740', city: 'Vasarainen' },
  { postalCode: '26820', city: 'Rauma' },
  { postalCode: '26840', city: 'Kortela' },
  { postalCode: '26910', city: 'Unaja' },
  { postalCode: '26930', city: 'Vermuntila' },
  { postalCode: '26950', city: 'Voiluoto' },
  { postalCode: '27003', city: 'Rauma' },
  { postalCode: '27100', city: 'Eurajoki' },
  { postalCode: '27101', city: 'Eurajoki' },
  { postalCode: '27110', city: 'Irjanne' },
  { postalCode: '27130', city: 'Eurajoki As' },
  { postalCode: '27150', city: 'Lapijoki' },
  { postalCode: '27160', city: 'Olkiluoto' },
  { postalCode: '27170', city: 'Kuivalahti' },
  { postalCode: '27210', city: 'Kullanperä' },
  { postalCode: '27220', city: 'Ala-Kieri' },
  { postalCode: '27230', city: 'Lappi' },
  { postalCode: '27231', city: 'Lappi' },
  { postalCode: '27240', city: 'Karhunselkä' },
  { postalCode: '27250', city: 'Kodiksami' },
  { postalCode: '27260', city: 'Kuolimaa' },
  { postalCode: '27310', city: 'Kodisjoki' },
  { postalCode: '27320', city: 'Ihode' },
  { postalCode: '27340', city: 'Reila' },
  { postalCode: '27400', city: 'Kiukainen' },
  { postalCode: '27401', city: 'Kiukainen' },
  { postalCode: '27420', city: 'Panelia As' },
  { postalCode: '27430', city: 'Panelia' },
  { postalCode: '27440', city: 'Saarenmaa' },
  { postalCode: '27450', city: 'Harola' },
  { postalCode: '27500', city: 'Kauttua' },
  { postalCode: '27501', city: 'Kauttua' },
  { postalCode: '27510', city: 'Eura' },
  { postalCode: '27511', city: 'Eura' },
  { postalCode: '27600', city: 'Hinnerjoki' },
  { postalCode: '27640', city: 'Honkilahti Kk' },
  { postalCode: '27650', city: 'Honkilahti' },
  { postalCode: '27670', city: 'Mannila' },
  { postalCode: '27710', city: 'Köyliö' },
  { postalCode: '27711', city: 'Köyliö' },
  { postalCode: '27730', city: 'Tuiskula' },
  { postalCode: '27740', city: 'Voitoinen' },
  { postalCode: '27750', city: 'Yttilä' },
  { postalCode: '27751', city: 'Yttilä' },
  { postalCode: '27800', city: 'Säkylä' },
  { postalCode: '27801', city: 'Säkylä' },
  { postalCode: '27820', city: 'Iso-Vimma' },
  { postalCode: '27821', city: 'Iso-Vimma' },
  { postalCode: '27840', city: 'Kakkuri' },
  { postalCode: '27860', city: 'Vuori' },
  { postalCode: '27910', city: 'Huovinrinne' },
  { postalCode: '27911', city: 'Huovinrinne' },
  { postalCode: '27920', city: 'Otajoki' },
  { postalCode: '27999', city: 'Rauma' },
  { postalCode: '28003', city: 'Pori' },
  { postalCode: '28007', city: 'Pori' },
  { postalCode: '28100', city: 'Pori' },
  { postalCode: '28101', city: 'Pori' },
  { postalCode: '28120', city: 'Pori' },
  { postalCode: '28130', city: 'Pori' },
  { postalCode: '28131', city: 'Pori' },
  { postalCode: '28190', city: 'Pori' },
  { postalCode: '28191', city: 'Pori' },
  { postalCode: '28200', city: 'Pori' },
  { postalCode: '28201', city: 'Pori' },
  { postalCode: '28220', city: 'Pori' },
  { postalCode: '28221', city: 'Pori' },
  { postalCode: '28230', city: 'Pori' },
  { postalCode: '28240', city: 'Pori' },
  { postalCode: '28260', city: 'Harjunpää' },
  { postalCode: '28280', city: 'Pori' },
  { postalCode: '28300', city: 'Pori' },
  { postalCode: '28330', city: 'Pori' },
  { postalCode: '28331', city: 'Pori' },
  { postalCode: '28360', city: 'Pori' },
  { postalCode: '28361', city: 'Pori' },
  { postalCode: '28370', city: 'Pori' },
  { postalCode: '28371', city: 'Pori' },
  { postalCode: '28400', city: 'Ulvila' },
  { postalCode: '28401', city: 'Ulvila' },
  { postalCode: '28430', city: 'Pori' },
  { postalCode: '28431', city: 'Pori' },
  { postalCode: '28450', city: 'Vanha-Ulvila' },
  { postalCode: '28451', city: 'Vanha-Ulvila' },
  { postalCode: '28500', city: 'Pori' },
  { postalCode: '28501', city: 'Pori' },
  { postalCode: '28540', city: 'Pori' },
  { postalCode: '28560', city: 'Pori' },
  { postalCode: '28580', city: 'Pori' },
  { postalCode: '28600', city: 'Pori' },
  { postalCode: '28601', city: 'Pori' },
  { postalCode: '28610', city: 'Pori' },
  { postalCode: '28611', city: 'Pori' },
  { postalCode: '28640', city: 'Pori' },
  { postalCode: '28660', city: 'Pori' },
  { postalCode: '28760', city: 'Pori' },
  { postalCode: '28800', city: 'Pori' },
  { postalCode: '28801', city: 'Pori' },
  { postalCode: '28840', city: 'Pori' },
  { postalCode: '28850', city: 'Yyteri' },
  { postalCode: '28880', city: 'Pori' },
  { postalCode: '28881', city: 'Pori' },
  { postalCode: '28900', city: 'Pori' },
  { postalCode: '29100', city: 'Luvia' },
  { postalCode: '29101', city: 'Luvia' },
  { postalCode: '29120', city: 'Verkkoranta' },
  { postalCode: '29180', city: 'Peränkylä' },
  { postalCode: '29200', city: 'Harjavalta' },
  { postalCode: '29201', city: 'Harjavalta' },
  { postalCode: '29210', city: 'Merstola' },
  { postalCode: '29220', city: 'Killanmaa' },
  { postalCode: '29230', city: 'Satalinna' },
  { postalCode: '29250', city: 'Nakkila' },
  { postalCode: '29251', city: 'Nakkila' },
  { postalCode: '29270', city: 'Hormisto' },
  { postalCode: '29280', city: 'Järvimaa' },
  { postalCode: '29310', city: 'Kaasmarkku' },
  { postalCode: '29320', city: 'Leineperi' },
  { postalCode: '29330', city: 'Levanpelto' },
  { postalCode: '29340', city: 'Kullaa' },
  { postalCode: '29350', city: 'Palus' },
  { postalCode: '29360', city: 'Koskenranta' },
  { postalCode: '29570', city: 'Söörmarkku' },
  { postalCode: '29580', city: 'Kellahti' },
  { postalCode: '29600', city: 'Noormarkku' },
  { postalCode: '29601', city: 'Noormarkku' },
  { postalCode: '29630', city: 'Pomarkku' },
  { postalCode: '29631', city: 'Pomarkku' },
  { postalCode: '29640', city: 'Honkakoski' },
  { postalCode: '29670', city: 'Rudanmaa' },
  { postalCode: '29680', city: 'Lassila' },
  { postalCode: '29700', city: 'Ahlainen' },
  { postalCode: '29720', city: 'Lamppi' },
  { postalCode: '29740', city: 'Etelämaa' },
  { postalCode: '29750', city: 'Pohjansaha' },
  { postalCode: '29760', city: 'Köörtilä' },
  { postalCode: '29790', city: 'Tuorila' },
  { postalCode: '29810', city: 'Siikainen' },
  { postalCode: '29811', city: 'Siikainen' },
  { postalCode: '29830', city: 'Sammi' },
  { postalCode: '29850', city: 'Hirvivuori' },
  { postalCode: '29860', city: 'Otamo' },
  { postalCode: '29870', city: 'Keskukylä' },
  { postalCode: '29880', city: 'Leväsjoki' },
  { postalCode: '29890', city: 'Saarineva' },
  { postalCode: '29900', city: 'Merikarvia' },
  { postalCode: '29901', city: 'Merikarvia' },
  { postalCode: '29910', city: 'Trolssi' },
  { postalCode: '29920', city: 'Riispyy' },
  { postalCode: '29930', city: 'Kasala' },
  { postalCode: '29940', city: 'Kuvaskangas' },
  { postalCode: '29960', city: 'Koittankoski' },
  { postalCode: '29970', city: 'Lautjärvi' },
  { postalCode: '29999', city: 'Pori' },
  { postalCode: '30003', city: 'Forssa' },
  { postalCode: '30007', city: 'Forssa' },
  { postalCode: '30100', city: 'Forssa' },
  { postalCode: '30101', city: 'Forssa' },
  { postalCode: '30300', city: 'Forssa' },
  { postalCode: '30420', city: 'Forssa' },
  { postalCode: '30421', city: 'Forssa' },
  { postalCode: '30630', city: 'Latovainio' },
  { postalCode: '30740', city: 'Forssa' },
  { postalCode: '30760', city: 'Koijärvi' },
  { postalCode: '30920', city: 'Riihivalkama' },
  { postalCode: '30940', city: 'Letku' },
  { postalCode: '30980', city: 'Torro' },
  { postalCode: '31003', city: 'Forssa' },
  { postalCode: '31100', city: 'Forssa' },
  { postalCode: '31110', city: 'Matku' },
  { postalCode: '31120', city: 'Raitoo' },
  { postalCode: '31130', city: 'Koijärvi' },
  { postalCode: '31140', city: 'Menonen' },
  { postalCode: '31150', city: 'Hanhisuo' },
  { postalCode: '31160', city: 'Nuutajärvi' },
  { postalCode: '31170', city: 'Savijoki' },
  { postalCode: '31230', city: 'Susikas' },
  { postalCode: '31240', city: 'Lautaporras' },
  { postalCode: '31250', city: 'Teuro' },
  { postalCode: '31300', city: 'Tammela' },
  { postalCode: '31301', city: 'Tammela' },
  { postalCode: '31310', city: 'Mustiala' },
  { postalCode: '31320', city: 'Tammelasaari' },
  { postalCode: '31330', city: 'Lepistö' },
  { postalCode: '31340', city: 'Porras' },
  { postalCode: '31350', city: 'Liesjärvi' },
  { postalCode: '31360', city: 'Honka' },
  { postalCode: '31370', city: 'Eerikkilä' },
  { postalCode: '31380', city: 'Letku' },
  { postalCode: '31400', city: 'Somero' },
  { postalCode: '31401', city: 'Somero' },
  { postalCode: '31410', city: 'Ylöpirtti' },
  { postalCode: '31430', city: 'Kultela' },
  { postalCode: '31450', city: 'Häntälä' },
  { postalCode: '31460', city: 'Hirsjärvi' },
  { postalCode: '31461', city: 'Hirsjärvi' },
  { postalCode: '31470', city: 'Somerniemi' },
  { postalCode: '31480', city: 'Kaskisto' },
  { postalCode: '31490', city: 'Terttilä' },
  { postalCode: '31500', city: 'Koski Tl' },
  { postalCode: '31501', city: 'Koski Tl' },
  { postalCode: '31520', city: 'Pitkäjärvi' },
  { postalCode: '31530', city: 'Pyöli' },
  { postalCode: '31560', city: 'Ollila' },
  { postalCode: '31570', city: 'Koivukylä' },
  { postalCode: '31580', city: 'Vähä-Ollila' },
  { postalCode: '31600', city: 'Jokioinen' },
  { postalCode: '31601', city: 'Jokioinen' },
  { postalCode: '31610', city: 'Vaulammi' },
  { postalCode: '31620', city: 'Latovainio' },
  { postalCode: '31630', city: 'Minkiö' },
  { postalCode: '31640', city: 'Humppila' },
  { postalCode: '31641', city: 'Humppila' },
  { postalCode: '31650', city: 'Venäjänkangas' },
  { postalCode: '31660', city: 'Murto' },
  { postalCode: '31670', city: 'Holkeri' },
  { postalCode: '31700', city: 'Urjala As' },
  { postalCode: '31720', city: 'Urjalankylä' },
  { postalCode: '31730', city: 'Honkola' },
  { postalCode: '31750', city: 'Kehro' },
  { postalCode: '31760', city: 'Urjala' },
  { postalCode: '31761', city: 'Urjala' },
  { postalCode: '31810', city: 'Oriniemi' },
  { postalCode: '31830', city: 'Halkivaha' },
  { postalCode: '31850', city: 'Koskioinen' },
  { postalCode: '31860', city: 'Tursa' },
  { postalCode: '31900', city: 'Punkalaidun' },
  { postalCode: '31901', city: 'Punkalaidun' },
  { postalCode: '31910', city: 'Haviokoski' },
  { postalCode: '31930', city: 'Vanttila' },
  { postalCode: '31940', city: 'Jalasjoki' },
  { postalCode: '31950', city: 'Sadonmaa' },
  { postalCode: '31960', city: 'Orisuo' },
  { postalCode: '31970', city: 'Kanteenmaa' },
  { postalCode: '31999', city: 'Forssa' },
  { postalCode: '32003', city: 'Forssa' },
  { postalCode: '32100', city: 'Ypäjä' },
  { postalCode: '32101', city: 'Ypäjä' },
  { postalCode: '32110', city: 'Palikkala' },
  { postalCode: '32140', city: 'Ypäjä As' },
  { postalCode: '32200', city: 'Loimaa' },
  { postalCode: '32201', city: 'Loimaa' },
  { postalCode: '32210', city: 'Loimaa Kk' },
  { postalCode: '32211', city: 'Loimaa kk' },
  { postalCode: '32250', city: 'Kojonkulma' },
  { postalCode: '32260', city: 'Kojonperä' },
  { postalCode: '32270', city: 'Metsämaa' },
  { postalCode: '32280', city: 'Metsäkorpi' },
  { postalCode: '32300', city: 'Mellilä' },
  { postalCode: '32301', city: 'Mellilä' },
  { postalCode: '32320', city: 'Hyry' },
  { postalCode: '32410', city: 'Niinijoki' },
  { postalCode: '32420', city: 'Haaroinen' },
  { postalCode: '32430', city: 'Ylhäinen' },
  { postalCode: '32440', city: 'Alastaro' },
  { postalCode: '32441', city: 'Alastaro' },
  { postalCode: '32450', city: 'Tammiainen' },
  { postalCode: '32500', city: 'Oripää' },
  { postalCode: '32501', city: 'Oripää' },
  { postalCode: '32520', city: 'Latva' },
  { postalCode: '32560', city: 'Virttaa' },
  { postalCode: '32610', city: 'Vampula' },
  { postalCode: '32611', city: 'Vampula' },
  { postalCode: '32620', city: 'Rutava' },
  { postalCode: '32650', city: 'Kukonharja' },
  { postalCode: '32700', city: 'Huittinen' },
  { postalCode: '32701', city: 'Huittinen' },
  { postalCode: '32710', city: 'Sampu' },
  { postalCode: '32730', city: 'Keikyä' },
  { postalCode: '32740', city: 'Äetsä' },
  { postalCode: '32741', city: 'Äetsä' },
  { postalCode: '32760', city: 'Suttila' },
  { postalCode: '32770', city: 'Huhtamo' },
  { postalCode: '32800', city: 'Kokemäki' },
  { postalCode: '32801', city: 'Kokemäki' },
  { postalCode: '32810', city: 'Peipohja' },
  { postalCode: '32830', city: 'Riste' },
  { postalCode: '32860', city: 'Korkeaoja' },
  { postalCode: '32899', city: 'Huittinen' },
  { postalCode: '32910', city: 'Kauvatsa As' },
  { postalCode: '32920', city: 'Kauvatsa' },
  { postalCode: '32999', city: 'Loimaa' },
  { postalCode: '33002', city: 'Tampere' },
  { postalCode: '33003', city: 'Tampere' },
  { postalCode: '33007', city: 'Tampere' },
  { postalCode: '33009', city: 'Tampere' },
  { postalCode: '33014', city: 'Tampereen yliopisto' },
  { postalCode: '33100', city: 'Tampere' },
  { postalCode: '33101', city: 'Tampere' },
  { postalCode: '33180', city: 'Tampere' },
  { postalCode: '33191', city: 'Tampere' },
  { postalCode: '33200', city: 'Tampere' },
  { postalCode: '33201', city: 'Tampere' },
  { postalCode: '33210', city: 'Tampere' },
  { postalCode: '33211', city: 'Tampere' },
  { postalCode: '33230', city: 'Tampere' },
  { postalCode: '33231', city: 'Tampere' },
  { postalCode: '33240', city: 'Tampere' },
  { postalCode: '33250', city: 'Tampere' },
  { postalCode: '33251', city: 'Tampere' },
  { postalCode: '33270', city: 'Tampere' },
  { postalCode: '33271', city: 'Tampere' },
  { postalCode: '33300', city: 'Tampere' },
  { postalCode: '33310', city: 'Tampere' },
  { postalCode: '33311', city: 'Tampere' },
  { postalCode: '33330', city: 'Tampere' },
  { postalCode: '33331', city: 'Tampere' },
  { postalCode: '33340', city: 'Tampere' },
  { postalCode: '33341', city: 'Tampere' },
  { postalCode: '33380', city: 'Pitkäniemi' },
  { postalCode: '33400', city: 'Tampere' },
  { postalCode: '33401', city: 'Tampere' },
  { postalCode: '33410', city: 'Tampere' },
  { postalCode: '33411', city: 'Tampere' },
  { postalCode: '33420', city: 'Tampere' },
  { postalCode: '33430', city: 'Vuorentausta' },
  { postalCode: '33431', city: 'Vuorentausta' },
  { postalCode: '33450', city: 'Siivikkala' },
  { postalCode: '33470', city: 'Ylöjärvi' },
  { postalCode: '33471', city: 'Ylöjärvi' },
  { postalCode: '33480', city: 'Ylöjärvi' },
  { postalCode: '33481', city: 'Ylöjärvi' },
  { postalCode: '33500', city: 'Tampere' },
  { postalCode: '33501', city: 'Tampere' },
  { postalCode: '33520', city: 'Tampere' },
  { postalCode: '33521', city: 'Tampere' },
  { postalCode: '33530', city: 'Tampere' },
  { postalCode: '33531', city: 'Tampere' },
  { postalCode: '33540', city: 'Tampere' },
  { postalCode: '33541', city: 'Tampere' },
  { postalCode: '33560', city: 'Tampere' },
  { postalCode: '33561', city: 'Tampere' },
  { postalCode: '33580', city: 'Tampere' },
  { postalCode: '33581', city: 'Tampere' },
  { postalCode: '33610', city: 'Tampere' },
  { postalCode: '33611', city: 'Tampere' },
  { postalCode: '33680', city: 'Tampere' },
  { postalCode: '33700', city: 'Tampere' },
  { postalCode: '33701', city: 'Tampere' },
  { postalCode: '33710', city: 'Tampere' },
  { postalCode: '33711', city: 'Tampere' },
  { postalCode: '33720', city: 'Tampere' },
  { postalCode: '33721', city: 'Tampere' },
  { postalCode: '33730', city: 'Tampere' },
  { postalCode: '33731', city: 'Tampere' },
  { postalCode: '33800', city: 'Tampere' },
  { postalCode: '33801', city: 'Tampere' },
  { postalCode: '33820', city: 'Tampere' },
  { postalCode: '33821', city: 'Tampere' },
  { postalCode: '33840', city: 'Tampere' },
  { postalCode: '33841', city: 'Tampere' },
  { postalCode: '33850', city: 'Tampere' },
  { postalCode: '33851', city: 'Tampere' },
  { postalCode: '33880', city: 'Lempäälä' },
  { postalCode: '33881', city: 'Sääksjärvi' },
  { postalCode: '33900', city: 'Tampere' },
  { postalCode: '33901', city: 'Tampere' },
  { postalCode: '33920', city: 'Pirkkala' },
  { postalCode: '33940', city: 'Pirkkala' },
  { postalCode: '33950', city: 'Pirkkala' },
  { postalCode: '33960', city: 'Pirkkala' },
  { postalCode: '33961', city: 'Pirkkala' },
  { postalCode: '33970', city: 'Pirkkala' },
  { postalCode: '33980', city: 'Pirkkala' },
  { postalCode: '34110', city: 'Lakiala' },
  { postalCode: '34111', city: 'Lakiala' },
  { postalCode: '34120', city: 'Peräsilta' },
  { postalCode: '34130', city: 'Ylinen' },
  { postalCode: '34140', city: 'Mutala' },
  { postalCode: '34150', city: 'Kyrönlahti' },
  { postalCode: '34180', city: 'Länsi-Teisko' },
  { postalCode: '34240', city: 'Kämmenniemi' },
  { postalCode: '34260', city: 'Terälahti' },
  { postalCode: '34270', city: 'Velaatta' },
  { postalCode: '34300', city: 'Kuru' },
  { postalCode: '34301', city: 'Kuru' },
  { postalCode: '34320', city: 'Parkkuu' },
  { postalCode: '34330', city: 'Poikelus' },
  { postalCode: '34410', city: 'Murole' },
  { postalCode: '34420', city: 'Muroleen Kanava' },
  { postalCode: '34430', city: 'Kekkonen' },
  { postalCode: '34450', city: 'Jäminkipohja' },
  { postalCode: '34451', city: 'Jäminkipohja' },
  { postalCode: '34510', city: 'Luode' },
  { postalCode: '34530', city: 'Länsi-Aure' },
  { postalCode: '34550', city: 'Itä-Aure' },
  { postalCode: '34600', city: 'Ruovesi' },
  { postalCode: '34601', city: 'Ruovesi' },
  { postalCode: '34610', city: 'Ruhala' },
  { postalCode: '34620', city: 'Nenonen' },
  { postalCode: '34640', city: 'Mustajärvi' },
  { postalCode: '34710', city: 'Vaskivesi' },
  { postalCode: '34730', city: 'Vaskuu' },
  { postalCode: '34740', city: 'Koro' },
  { postalCode: '34770', city: 'Kurjenkylä' },
  { postalCode: '34800', city: 'Virrat' },
  { postalCode: '34801', city: 'Virrat' },
  { postalCode: '34850', city: 'Jäähdyspohja' },
  { postalCode: '34870', city: 'Visuvesi' },
  { postalCode: '34910', city: 'Äijänneva' },
  { postalCode: '34930', city: 'Liedenpohja' },
  { postalCode: '34950', city: 'Ohtola' },
  { postalCode: '34970', city: 'Toisvesi' },
  { postalCode: '34980', city: 'Killinkoski' },
  { postalCode: '34999', city: 'Tampere' },
  { postalCode: '35100', city: 'Orivesi As' },
  { postalCode: '35220', city: 'Eräjärvi' },
  { postalCode: '35240', city: 'Ristakallio' },
  { postalCode: '35260', city: 'Hoivamäki' },
  { postalCode: '35270', city: 'Västilä' },
  { postalCode: '35300', city: 'Orivesi' },
  { postalCode: '35301', city: 'Orivesi' },
  { postalCode: '35320', city: 'Hirsilä' },
  { postalCode: '35400', city: 'Längelmäki' },
  { postalCode: '35420', city: 'Talviainen' },
  { postalCode: '35480', city: 'Längelmäki' },
  { postalCode: '35500', city: 'Korkeakoski' },
  { postalCode: '35501', city: 'Korkeakoski' },
  { postalCode: '35520', city: 'Pirttikangas' },
  { postalCode: '35530', city: 'Lyly' },
  { postalCode: '35531', city: 'Lyly' },
  { postalCode: '35540', city: 'Juupajoki' },
  { postalCode: '35550', city: 'Salokunta' },
  { postalCode: '35600', city: 'Halli' },
  { postalCode: '35601', city: 'Halli' },
  { postalCode: '35610', city: 'Pihlaisto' },
  { postalCode: '35630', city: 'Kuorevesi' },
  { postalCode: '35700', city: 'Vilppula' },
  { postalCode: '35701', city: 'Vilppula' },
  { postalCode: '35740', city: 'Ylä-Vääri' },
  { postalCode: '35750', city: 'Väärinmaja' },
  { postalCode: '35800', city: 'Mänttä' },
  { postalCode: '35801', city: 'Mänttä' },
  { postalCode: '35807', city: 'Mänttä' },
  { postalCode: '35820', city: 'Mänttä' },
  { postalCode: '35821', city: 'Mänttä' },
  { postalCode: '35830', city: 'Mänttä' },
  { postalCode: '35850', city: 'Mustalahti' },
  { postalCode: '35860', city: 'Raja-Aho' },
  { postalCode: '35930', city: 'Tamminiemi' },
  { postalCode: '35990', city: 'Kolho' },
  { postalCode: '36100', city: 'Kangasala As' },
  { postalCode: '36110', city: 'Ruutana' },
  { postalCode: '36111', city: 'Ruutana' },
  { postalCode: '36120', city: 'Suinula' },
  { postalCode: '36160', city: 'Siitama' },
  { postalCode: '36200', city: 'Kangasala' },
  { postalCode: '36201', city: 'Kangasala' },
  { postalCode: '36220', city: 'Kangasala 2' },
  { postalCode: '36221', city: 'Kangasala 2' },
  { postalCode: '36240', city: 'Kangasala 4' },
  { postalCode: '36241', city: 'Kangasala' },
  { postalCode: '36270', city: 'Kangasala' },
  { postalCode: '36280', city: 'Pikonlinna' },
  { postalCode: '36340', city: 'Tohkala' },
  { postalCode: '36420', city: 'Sahalahti' },
  { postalCode: '36430', city: 'Sahalahti' },
  { postalCode: '36450', city: 'Salmentaka' },
  { postalCode: '36480', city: 'Pohjalahti' },
  { postalCode: '36520', city: 'Raikku' },
  { postalCode: '36570', city: 'Kaivanto' },
  { postalCode: '36600', city: 'Pälkäne' },
  { postalCode: '36601', city: 'Pälkäne' },
  { postalCode: '36640', city: 'Iltasmäki' },
  { postalCode: '36660', city: 'Laitikkala' },
  { postalCode: '36720', city: 'Aitoo' },
  { postalCode: '36760', city: 'Luopioinen' },
  { postalCode: '36761', city: 'Luopioinen' },
  { postalCode: '36810', city: 'Kuhmalahti' },
  { postalCode: '36840', city: 'Pohja' },
  { postalCode: '36880', city: 'Vehkajärvi' },
  { postalCode: '36910', city: 'Rautajärvi' },
  { postalCode: '36930', city: 'Kyynärö' },
  { postalCode: '37007', city: 'Nokia' },
  { postalCode: '37100', city: 'Nokia' },
  { postalCode: '37101', city: 'Nokia' },
  { postalCode: '37105', city: 'Nokia 10/PSP' },
  { postalCode: '37120', city: 'Nokia' },
  { postalCode: '37121', city: 'Nokia' },
  { postalCode: '37130', city: 'Nokia' },
  { postalCode: '37140', city: 'Nokia' },
  { postalCode: '37141', city: 'Nokia' },
  { postalCode: '37150', city: 'Nokia' },
  { postalCode: '37180', city: 'Sarkola' },
  { postalCode: '37200', city: 'Siuro' },
  { postalCode: '37210', city: 'Kulovesi' },
  { postalCode: '37240', city: 'Linnavuori' },
  { postalCode: '37241', city: 'Linnavuori' },
  { postalCode: '37310', city: 'Tottijärvi' },
  { postalCode: '37350', city: 'Rämsöö' },
  { postalCode: '37370', city: 'Narva' },
  { postalCode: '37380', city: 'Krääkkiö' },
  { postalCode: '37420', city: 'Valkkinen' },
  { postalCode: '37450', city: 'Onkemäki' },
  { postalCode: '37470', city: 'Vesilahti' },
  { postalCode: '37500', city: 'Lempäälä' },
  { postalCode: '37501', city: 'Lempäälä' },
  { postalCode: '37530', city: 'Lempäälä' },
  { postalCode: '37550', city: 'Lempäälä' },
  { postalCode: '37551', city: 'Moisio' },
  { postalCode: '37560', city: 'Lempäälä' },
  { postalCode: '37600', city: 'Valkeakoski' },
  { postalCode: '37601', city: 'Valkeakoski' },
  { postalCode: '37607', city: 'Valkeakoski' },
  { postalCode: '37620', city: 'Valkeakoski' },
  { postalCode: '37630', city: 'Valkeakoski' },
  { postalCode: '37631', city: 'Valkeakoski' },
  { postalCode: '37640', city: 'Valkeakoski' },
  { postalCode: '37641', city: 'Valkeakoski' },
  { postalCode: '37650', city: 'Valkeakoski' },
  { postalCode: '37680', city: 'Valkeakoski' },
  { postalCode: '37700', city: 'Sääksmäki' },
  { postalCode: '37720', city: 'Ritvala' },
  { postalCode: '37740', city: 'Haukila' },
  { postalCode: '37770', city: 'Tarttila' },
  { postalCode: '37800', city: 'Toijala' },
  { postalCode: '37801', city: 'Toijala' },
  { postalCode: '37807', city: 'Toijala' },
  { postalCode: '37820', city: 'Nahkiala' },
  { postalCode: '37830', city: 'Viiala' },
  { postalCode: '37831', city: 'Viiala' },
  { postalCode: '37850', city: 'Metsäkansa' },
  { postalCode: '37860', city: 'Kärjenniemi' },
  { postalCode: '37900', city: 'Kylmäkoski As' },
  { postalCode: '37910', city: 'Kylmäkoski' },
  { postalCode: '37911', city: 'Kylmäkoski' },
  { postalCode: '37930', city: 'Tuomala' },
  { postalCode: '37960', city: 'Sotkia' },
  { postalCode: '38100', city: 'Karkku' },
  { postalCode: '38110', city: 'Nohkua' },
  { postalCode: '38120', city: 'Kutala' },
  { postalCode: '38130', city: 'Ellivuori' },
  { postalCode: '38140', city: 'Kärppälä' },
  { postalCode: '38150', city: 'Heinoo' },
  { postalCode: '38200', city: 'Vammala' },
  { postalCode: '38201', city: 'Vammala' },
  { postalCode: '38210', city: 'Vammala' },
  { postalCode: '38211', city: 'Vammala' },
  { postalCode: '38220', city: 'Stormi' },
  { postalCode: '38230', city: 'Lantula' },
  { postalCode: '38250', city: 'Roismala' },
  { postalCode: '38270', city: 'Sammaljoki' },
  { postalCode: '38280', city: 'Illo' },
  { postalCode: '38300', city: 'Kiikka' },
  { postalCode: '38301', city: 'Kiikka' },
  { postalCode: '38320', city: 'Kiimajärvi' },
  { postalCode: '38350', city: 'Jaara' },
  { postalCode: '38360', city: 'Kiikoinen' },
  { postalCode: '38370', city: 'Tervahauta' },
  { postalCode: '38420', city: 'Häijää' },
  { postalCode: '38460', city: 'Mouhijärvi' },
  { postalCode: '38470', city: 'Hyynilä' },
  { postalCode: '38490', city: 'Haukijärvi' },
  { postalCode: '38510', city: 'Suodenniemi' },
  { postalCode: '38511', city: 'Suodenniemi' },
  { postalCode: '38540', city: 'Putaja' },
  { postalCode: '38600', city: 'Lavia' },
  { postalCode: '38601', city: 'Lavia' },
  { postalCode: '38610', city: 'Riuttala' },
  { postalCode: '38650', city: 'Yli-Putto' },
  { postalCode: '38660', city: 'Haunia' },
  { postalCode: '38670', city: 'Kalliala' },
  { postalCode: '38680', city: 'Tuunajärvi' },
  { postalCode: '38690', city: 'Tuomisaari' },
  { postalCode: '38700', city: 'Kankaanpää' },
  { postalCode: '38701', city: 'Kankaanpää' },
  { postalCode: '38707', city: 'Kankaanpää 7' },
  { postalCode: '38710', city: 'Kankaanpää' },
  { postalCode: '38711', city: 'Kankaanpää' },
  { postalCode: '38720', city: 'Vihteljärvi' },
  { postalCode: '38740', city: 'Hapuoja' },
  { postalCode: '38750', city: 'Veneskoski' },
  { postalCode: '38760', city: 'Venesjärvi' },
  { postalCode: '38770', city: 'Lohikko' },
  { postalCode: '38800', city: 'Jämijärvi' },
  { postalCode: '38801', city: 'Jämijärvi' },
  { postalCode: '38840', city: 'Niinisalo' },
  { postalCode: '38841', city: 'Niinisalo' },
  { postalCode: '38860', city: 'Suurimaa' },
  { postalCode: '38910', city: 'Ala-Honkajoki' },
  { postalCode: '38920', city: 'Vatajankoski' },
  { postalCode: '38950', city: 'Honkajoki' },
  { postalCode: '38951', city: 'Honkajoki' },
  { postalCode: '38970', city: 'Lauhala' },
  { postalCode: '39100', city: 'Hämeenkyrö' },
  { postalCode: '39101', city: 'Hämeenkyrö' },
  { postalCode: '39110', city: 'Simuna' },
  { postalCode: '39120', city: 'Mahnala' },
  { postalCode: '39130', city: 'Sasi' },
  { postalCode: '39150', city: 'Pinsiö' },
  { postalCode: '39160', city: 'Julkujärvi' },
  { postalCode: '39170', city: 'Jumesniemi' },
  { postalCode: '39180', city: 'Herttuala' },
  { postalCode: '39195', city: 'Kaipio' },
  { postalCode: '39200', city: 'Kyröskoski' },
  { postalCode: '39201', city: 'Kyröskoski' },
  { postalCode: '39220', city: 'Kostula' },
  { postalCode: '39230', city: 'Osara' },
  { postalCode: '39290', city: 'Vesajärvi' },
  { postalCode: '39310', city: 'Viljakkala' },
  { postalCode: '39340', city: 'Karhe' },
  { postalCode: '39360', city: 'Komi' },
  { postalCode: '39380', city: 'Lavajärvi' },
  { postalCode: '39410', city: 'Luhalahti' },
  { postalCode: '39420', city: 'Iso-Röyhiö' },
  { postalCode: '39430', city: 'Röyhiö' },
  { postalCode: '39450', city: 'Sisättö' },
  { postalCode: '39500', city: 'Ikaalinen' },
  { postalCode: '39501', city: 'Ikaalinen' },
  { postalCode: '39510', city: 'Ikaalinen' },
  { postalCode: '39520', city: 'Sikuri' },
  { postalCode: '39530', city: 'Kilvakkala' },
  { postalCode: '39540', city: 'Jylli' },
  { postalCode: '39550', city: 'Myllykarttu' },
  { postalCode: '39580', city: 'Riitiala' },
  { postalCode: '39590', city: 'Tevaniemi' },
  { postalCode: '39610', city: 'Kovelahti' },
  { postalCode: '39620', city: 'Kovesjoki' },
  { postalCode: '39660', city: 'Lapinneva' },
  { postalCode: '39700', city: 'Parkano' },
  { postalCode: '39701', city: 'Parkano' },
  { postalCode: '39710', city: 'Kylmäkoski' },
  { postalCode: '39750', city: 'Kuivasjärvi' },
  { postalCode: '39770', city: 'Aureskoski' },
  { postalCode: '39780', city: 'Alaskylä' },
  { postalCode: '39810', city: 'Linnankylä' },
  { postalCode: '39820', city: 'Kihniö' },
  { postalCode: '39821', city: 'Kihniö' },
  { postalCode: '39880', city: 'Kihniö As' },
  { postalCode: '39910', city: 'Tuulenkylä' },
  { postalCode: '39920', city: 'Suomijärvi' },
  { postalCode: '39930', city: 'Karvia' },
  { postalCode: '39931', city: 'Karvia' },
  { postalCode: '39940', city: 'Kantti' },
  { postalCode: '39960', city: 'Sarvela' },
  { postalCode: '39965', city: 'Karviankylä' },
  { postalCode: '39980', city: 'Sara' },
  { postalCode: '39990', city: 'Alkkia' },
  { postalCode: '40003', city: 'Jyväskylä' },
  { postalCode: '40007', city: 'Jyväskylä' },
  { postalCode: '40063', city: 'Laskunet' },
  { postalCode: '40100', city: 'Jyväskylä' },
  { postalCode: '40101', city: 'Jyväskylä' },
  { postalCode: '40109', city: 'Jyväskylä' },
  { postalCode: '40200', city: 'Jyväskylä' },
  { postalCode: '40220', city: 'Keski-Palokka' },
  { postalCode: '40250', city: 'Jyväskylä' },
  { postalCode: '40251', city: 'Jyväskylä' },
  { postalCode: '40270', city: 'Palokka' },
  { postalCode: '40271', city: 'Palokka' },
  { postalCode: '40320', city: 'Jyväskylä' },
  { postalCode: '40321', city: 'Jyväskylä' },
  { postalCode: '40340', city: 'Jyväskylä' },
  { postalCode: '40341', city: 'Jyväskylä' },
  { postalCode: '40350', city: 'Jyväskylä' },
  { postalCode: '40351', city: 'Jyväskylä' },
  { postalCode: '40400', city: 'Jyväskylä' },
  { postalCode: '40401', city: 'Jyväskylä' },
  { postalCode: '40420', city: 'Jyskä' },
  { postalCode: '40450', city: 'Jyväskylä' },
  { postalCode: '40500', city: 'Jyväskylä' },
  { postalCode: '40520', city: 'Jyväskylä' },
  { postalCode: '40521', city: 'Jyväskylä' },
  { postalCode: '40530', city: 'Jyväskylä' },
  { postalCode: '40531', city: 'Jyväskylä' },
  { postalCode: '40600', city: 'Jyväskylä' },
  { postalCode: '40620', city: 'Jyväskylä' },
  { postalCode: '40621', city: 'Jyväskylä' },
  { postalCode: '40630', city: 'Jyväskylä' },
  { postalCode: '40631', city: 'Jyväskylä' },
  { postalCode: '40640', city: 'Jyväskylä' },
  { postalCode: '40660', city: 'Jyväskylä' },
  { postalCode: '40700', city: 'Jyväskylä' },
  { postalCode: '40701', city: 'Jyväskylä' },
  { postalCode: '40720', city: 'Jyväskylä' },
  { postalCode: '40740', city: 'Jyväskylä' },
  { postalCode: '40800', city: 'Vaajakoski' },
  { postalCode: '40801', city: 'Vaajakoski' },
  { postalCode: '40820', city: 'Haapaniemi' },
  { postalCode: '40900', city: 'Säynätsalo' },
  { postalCode: '40930', city: 'Kinkomaa' },
  { postalCode: '40931', city: 'Kinkomaa' },
  { postalCode: '40950', city: 'Muurame' },
  { postalCode: '40951', city: 'Muurame' },
  { postalCode: '40957', city: 'Muurame 7' },
  { postalCode: '41120', city: 'Puuppola' },
  { postalCode: '41140', city: 'Kuikka' },
  { postalCode: '41160', city: 'Tikkakoski' },
  { postalCode: '41161', city: 'Tikkakoski' },
  { postalCode: '41180', city: 'Vehniä' },
  { postalCode: '41210', city: 'Jokihaara' },
  { postalCode: '41220', city: 'Höytiä' },
  { postalCode: '41230', city: 'Uurainen' },
  { postalCode: '41231', city: 'Uurainen' },
  { postalCode: '41240', city: 'Kyynämöinen' },
  { postalCode: '41260', city: 'Hoikankylä' },
  { postalCode: '41270', city: 'Lannevesi' },
  { postalCode: '41290', city: 'Kangashäkki' },
  { postalCode: '41310', city: 'Leppävesi' },
  { postalCode: '41315', city: 'Tiituspohja' },
  { postalCode: '41325', city: 'Laukkavirta' },
  { postalCode: '41330', city: 'Vihtavuori' },
  { postalCode: '41331', city: 'Vihtavuori' },
  { postalCode: '41340', city: 'Laukaa' },
  { postalCode: '41341', city: 'Laukaa' },
  { postalCode: '41350', city: 'Laukaa As' },
  { postalCode: '41360', city: 'Valkola' },
  { postalCode: '41370', city: 'Kuusa' },
  { postalCode: '41390', city: 'Äijälä' },
  { postalCode: '41400', city: 'Lievestuore' },
  { postalCode: '41401', city: 'Lievestuore' },
  { postalCode: '41410', city: 'Kankainen' },
  { postalCode: '41420', city: 'Taulu' },
  { postalCode: '41440', city: 'Ruuhimäki' },
  { postalCode: '41450', city: 'Leppälahti' },
  { postalCode: '41460', city: 'Iso-Oksala' },
  { postalCode: '41470', city: 'Saarilahti' },
  { postalCode: '41480', city: 'Simunankoski' },
  { postalCode: '41490', city: 'Niemisjärvi' },
  { postalCode: '41500', city: 'Hankasalmi As' },
  { postalCode: '41501', city: 'Hankasalmi As' },
  { postalCode: '41520', city: 'Hankasalmi' },
  { postalCode: '41521', city: 'Hankasalmi' },
  { postalCode: '41530', city: 'Veneranta' },
  { postalCode: '41540', city: 'Ristimäki' },
  { postalCode: '41550', city: 'Hannula' },
  { postalCode: '41560', city: 'Säkinmäki' },
  { postalCode: '41580', city: 'Sauvamäki' },
  { postalCode: '41585', city: 'Hanka' },
  { postalCode: '41630', city: 'Oravasaari' },
  { postalCode: '41660', city: 'Toivakka' },
  { postalCode: '41661', city: 'Toivakka' },
  { postalCode: '41680', city: 'Särkiharju' },
  { postalCode: '41710', city: 'Rutalahti' },
  { postalCode: '41730', city: 'Kivisuo' },
  { postalCode: '41750', city: 'Havumäki' },
  { postalCode: '41770', city: 'Leivonmäki' },
  { postalCode: '41800', city: 'Korpilahti' },
  { postalCode: '41801', city: 'Korpilahti' },
  { postalCode: '41820', city: 'Saakoski' },
  { postalCode: '41840', city: 'Moksi' },
  { postalCode: '41860', city: 'Rannila' },
  { postalCode: '41870', city: 'Putkilahti' },
  { postalCode: '41880', city: 'Oittila' },
  { postalCode: '41900', city: 'Petäjävesi' },
  { postalCode: '41901', city: 'Petäjävesi' },
  { postalCode: '41920', city: 'Kintaus' },
  { postalCode: '41925', city: 'Ylä-Kintaus' },
  { postalCode: '41930', city: 'Kuohu' },
  { postalCode: '41940', city: 'Vesanka' },
  { postalCode: '41945', city: 'Nyrölä' },
  { postalCode: '41970', city: 'Huttula' },
  { postalCode: '41980', city: 'Kuivasmäki' },
  { postalCode: '41999', city: 'Jyväskylä' },
  { postalCode: '42100', city: 'Jämsä' },
  { postalCode: '42101', city: 'Jämsä' },
  { postalCode: '42120', city: 'Jämsä' },
  { postalCode: '42140', city: 'Juokslahti' },
  { postalCode: '42220', city: 'Kaipola' },
  { postalCode: '42250', city: 'Alhojärvi' },
  { postalCode: '42280', city: 'Partalankoski' },
  { postalCode: '42300', city: 'Jämsänkoski' },
  { postalCode: '42301', city: 'Jämsänkoski' },
  { postalCode: '42330', city: 'Haavisto' },
  { postalCode: '42440', city: 'Koskenpää' },
  { postalCode: '42490', city: 'Niemisjärvi' },
  { postalCode: '42520', city: 'Asunta' },
  { postalCode: '42540', city: 'Kaleton' },
  { postalCode: '42541', city: 'Kaleton' },
  { postalCode: '42560', city: 'Pohjoisjärvi' },
  { postalCode: '42600', city: 'Multia' },
  { postalCode: '42601', city: 'Multia' },
  { postalCode: '42660', city: 'Sahrajärvi' },
  { postalCode: '42700', city: 'Keuruu' },
  { postalCode: '42701', city: 'Keuruu' },
  { postalCode: '42720', city: 'Keuruu' },
  { postalCode: '42721', city: 'Keuruu' },
  { postalCode: '42730', city: 'Ketvelniemi' },
  { postalCode: '42760', city: 'Keurusselkä' },
  { postalCode: '42790', city: 'Liesjärvi' },
  { postalCode: '42800', city: 'Haapamäki' },
  { postalCode: '42801', city: 'Haapamäki' },
  { postalCode: '42820', city: 'Riiho' },
  { postalCode: '42830', city: 'Ylä-Kolkki' },
  { postalCode: '42840', city: 'Kolkki' },
  { postalCode: '42850', city: 'Pohjaslahti' },
  { postalCode: '42870', city: 'Kotala' },
  { postalCode: '42899', city: 'Haapamäki' },
  { postalCode: '42910', city: 'Pihlajavesi' },
  { postalCode: '42930', city: 'Katajamäki' },
  { postalCode: '42999', city: 'Jämsä' },
  { postalCode: '43100', city: 'Saarijärvi' },
  { postalCode: '43101', city: 'Saarijärvi' },
  { postalCode: '43130', city: 'Tarvaala' },
  { postalCode: '43140', city: 'Summassaari' },
  { postalCode: '43170', city: 'Häkkilä' },
  { postalCode: '43220', city: 'Mahlu' },
  { postalCode: '43240', city: 'Lehtola' },
  { postalCode: '43250', city: 'Kolkanlahti' },
  { postalCode: '43270', city: 'Kalmari' },
  { postalCode: '43300', city: 'Kannonkoski' },
  { postalCode: '43340', city: 'Kannonjärvi' },
  { postalCode: '43380', city: 'Vuoskoski' },
  { postalCode: '43390', city: 'Lokakylä' },
  { postalCode: '43410', city: 'Soidinmäki' },
  { postalCode: '43420', city: 'Kukko' },
  { postalCode: '43430', city: 'Väätäiskylä' },
  { postalCode: '43440', city: 'Pylkönmäki' },
  { postalCode: '43480', city: 'Pääjärvi' },
  { postalCode: '43490', city: 'Kangasaho' },
  { postalCode: '43500', city: 'Karstula' },
  { postalCode: '43501', city: 'Karstula' },
  { postalCode: '43520', city: 'Kiminki' },
  { postalCode: '43540', city: 'Rantakylä' },
  { postalCode: '43560', city: 'Vahanka' },
  { postalCode: '43610', city: 'Aho' },
  { postalCode: '43640', city: 'Humppi' },
  { postalCode: '43660', city: 'Vastinki' },
  { postalCode: '43680', city: 'Takkala' },
  { postalCode: '43700', city: 'Kyyjärvi' },
  { postalCode: '43701', city: 'Kyyjärvi' },
  { postalCode: '43720', city: 'Kumpulainen' },
  { postalCode: '43740', city: 'Oikari' },
  { postalCode: '43800', city: 'Kivijärvi' },
  { postalCode: '43801', city: 'Kivijärvi' },
  { postalCode: '43840', city: 'Sompala' },
  { postalCode: '43900', city: 'Kinnula' },
  { postalCode: '43901', city: 'Kinnula' },
  { postalCode: '43940', city: 'Muhola' },
  { postalCode: '43960', city: 'Saarensalmi' },
  { postalCode: '44007', city: 'Äänekoski' },
  { postalCode: '44100', city: 'Äänekoski' },
  { postalCode: '44101', city: 'Äänekoski' },
  { postalCode: '44120', city: 'Äänekoski' },
  { postalCode: '44150', city: 'Äänekoski' },
  { postalCode: '44151', city: 'Äänekoski' },
  { postalCode: '44160', city: 'Huutomäki' },
  { postalCode: '44170', city: 'Hietama' },
  { postalCode: '44190', city: 'Parantala' },
  { postalCode: '44200', city: 'Suolahti' },
  { postalCode: '44201', city: 'Suolahti' },
  { postalCode: '44220', city: 'Suolahti' },
  { postalCode: '44250', city: 'Ääne-Koivisto' },
  { postalCode: '44260', city: 'Vihijärvi' },
  { postalCode: '44280', city: 'Sumiainen' },
  { postalCode: '44300', city: 'Konnevesi' },
  { postalCode: '44320', city: 'Sirkkamäki' },
  { postalCode: '44330', city: 'Hytölä' },
  { postalCode: '44350', city: 'Istunmäki' },
  { postalCode: '44370', city: 'Mäntymäki' },
  { postalCode: '44400', city: 'Konginkangas' },
  { postalCode: '44401', city: 'Konginkangas' },
  { postalCode: '44420', city: 'Liimattala' },
  { postalCode: '44440', city: 'Räihä' },
  { postalCode: '44460', city: 'Kalaniemi' },
  { postalCode: '44480', city: 'Niinilahti' },
  { postalCode: '44500', city: 'Viitasaari' },
  { postalCode: '44501', city: 'Viitasaari' },
  { postalCode: '44520', city: 'Jurvansalo' },
  { postalCode: '44530', city: 'Ilmolahti' },
  { postalCode: '44550', city: 'Ruuponsaari' },
  { postalCode: '44580', city: 'Huopanankoski' },
  { postalCode: '44590', city: 'Vuorilahti' },
  { postalCode: '44610', city: 'Kärnä' },
  { postalCode: '44630', city: 'Kumpumäki' },
  { postalCode: '44640', city: 'Kymönkoski' },
  { postalCode: '44670', city: 'Suovanlahti' },
  { postalCode: '44680', city: 'Lahnanen' },
  { postalCode: '44690', city: 'Haarala' },
  { postalCode: '44720', city: 'Pasala' },
  { postalCode: '44730', city: 'Löytänä' },
  { postalCode: '44740', city: 'Keitelepohja' },
  { postalCode: '44760', city: 'Keihärinkoski' },
  { postalCode: '44770', city: 'Valkeisjärvi' },
  { postalCode: '44790', city: 'Kotvala' },
  { postalCode: '44800', city: 'Pihtipudas' },
  { postalCode: '44801', city: 'Pihtipudas' },
  { postalCode: '44820', city: 'Niemenharju' },
  { postalCode: '44860', city: 'Alvajärvi' },
  { postalCode: '44880', city: 'Muurasjärvi' },
  { postalCode: '44890', city: 'Kojola' },
  { postalCode: '44910', city: 'Elämäjärvi' },
  { postalCode: '44920', city: 'Peninki' },
  { postalCode: '44950', city: 'Kärväsjärvi' },
  { postalCode: '44970', city: 'Korppinen' },
  { postalCode: '45007', city: 'Kouvola' },
  { postalCode: '45100', city: 'Kouvola' },
  { postalCode: '45101', city: 'Kouvola' },
  { postalCode: '45120', city: 'Kouvola' },
  { postalCode: '45121', city: 'Kouvola' },
  { postalCode: '45130', city: 'Kouvola' },
  { postalCode: '45131', city: 'Kouvola' },
  { postalCode: '45140', city: 'Kouvola' },
  { postalCode: '45141', city: 'Kouvola' },
  { postalCode: '45150', city: 'Kouvola' },
  { postalCode: '45151', city: 'Kouvola' },
  { postalCode: '45160', city: 'Kouvola' },
  { postalCode: '45161', city: 'Kouvola' },
  { postalCode: '45200', city: 'Kouvola' },
  { postalCode: '45201', city: 'Kouvola' },
  { postalCode: '45250', city: 'Kymenlaakson lajittelukeskus' },
  { postalCode: '45251', city: 'Kouvola' },
  { postalCode: '45330', city: 'Harju' },
  { postalCode: '45360', city: 'Valkeala' },
  { postalCode: '45361', city: 'Valkeala' },
  { postalCode: '45370', city: 'Valkeala' },
  { postalCode: '45371', city: 'Valkeala' },
  { postalCode: '45410', city: 'Utti' },
  { postalCode: '45411', city: 'Utti' },
  { postalCode: '45460', city: 'Savero' },
  { postalCode: '45540', city: 'Lyöttilä' },
  { postalCode: '45610', city: 'Koria' },
  { postalCode: '45611', city: 'Koria' },
  { postalCode: '45700', city: 'Kuusankoski' },
  { postalCode: '45701', city: 'Kuusankoski' },
  { postalCode: '45720', city: 'Kuusankoski' },
  { postalCode: '45721', city: 'Kuusankoski' },
  { postalCode: '45730', city: 'Kuusankoski' },
  { postalCode: '45740', city: 'Kuusankoski' },
  { postalCode: '45741', city: 'Kuusankoski' },
  { postalCode: '45750', city: 'Sairaalamäki' },
  { postalCode: '45810', city: 'Pilkanmaa' },
  { postalCode: '45910', city: 'Voikkaa' },
  { postalCode: '45911', city: 'Voikkaa' },
  { postalCode: '45940', city: 'Oravala' },
  { postalCode: '46110', city: 'Tuohikotti' },
  { postalCode: '46140', city: 'Vekaranjärvi' },
  { postalCode: '46141', city: 'Vekaranjärvi' },
  { postalCode: '46230', city: 'Aro' },
  { postalCode: '46250', city: 'Heinistö' },
  { postalCode: '46310', city: 'Toikkala' },
  { postalCode: '46350', city: 'Inkerilä' },
  { postalCode: '46400', city: 'Kaipiainen' },
  { postalCode: '46430', city: 'Tirva' },
  { postalCode: '46450', city: 'Enäjärvi' },
  { postalCode: '46530', city: 'Kannuskoski' },
  { postalCode: '46550', city: 'Kaitjärvi' },
  { postalCode: '46570', city: 'Saaramaa' },
  { postalCode: '46610', city: 'Hirvelänkylä' },
  { postalCode: '46710', city: 'Sippola' },
  { postalCode: '46730', city: 'Ruotila' },
  { postalCode: '46750', city: 'Liikkala' },
  { postalCode: '46800', city: 'Anjalankoski' },
  { postalCode: '46801', city: 'Anjalankoski' },
  { postalCode: '46810', city: 'Anjalankoski' },
  { postalCode: '46860', city: 'Anjalankoski' },
  { postalCode: '46861', city: 'Anjalankoski' },
  { postalCode: '46900', city: 'Anjalankoski' },
  { postalCode: '46901', city: 'Anjalankoski' },
  { postalCode: '46907', city: 'Anjalankoski 7' },
  { postalCode: '46910', city: 'Anjalankoski' },
  { postalCode: '46920', city: 'Anjalankoski' },
  { postalCode: '46930', city: 'Huruksela' },
  { postalCode: '46950', city: 'Ahvio' },
  { postalCode: '46960', city: 'Muhniemi' },
  { postalCode: '46999', city: 'Kouvola' },
  { postalCode: '47110', city: 'Löytty' },
  { postalCode: '47150', city: 'Raussila' },
  { postalCode: '47200', city: 'Elimäki' },
  { postalCode: '47201', city: 'Elimäki' },
  { postalCode: '47210', city: 'Rahikkala' },
  { postalCode: '47230', city: 'Teuroinen' },
  { postalCode: '47240', city: 'Lakiasuo' },
  { postalCode: '47260', city: 'Ratula' },
  { postalCode: '47310', city: 'Haapa-Kimola' },
  { postalCode: '47350', city: 'Kimonkylä' },
  { postalCode: '47400', city: 'Kausala' },
  { postalCode: '47401', city: 'Kausala' },
  { postalCode: '47440', city: 'Valkama' },
  { postalCode: '47450', city: 'Perheniemi' },
  { postalCode: '47460', city: 'Hiisiö' },
  { postalCode: '47490', city: 'Mankala' },
  { postalCode: '47520', city: 'Iitti' },
  { postalCode: '47540', city: 'Lyöttilä' },
  { postalCode: '47610', city: 'Kymentaka' },
  { postalCode: '47650', city: 'Kimola' },
  { postalCode: '47710', city: 'Jaala' },
  { postalCode: '47760', city: 'Huhdasjärvi' },
  { postalCode: '47810', city: 'Selänpää' },
  { postalCode: '47830', city: 'Hasula' },
  { postalCode: '47850', city: 'Verla' },
  { postalCode: '47900', city: 'Vuohijärvi' },
  { postalCode: '47901', city: 'Vuohijärvi' },
  { postalCode: '47910', city: 'Hillosensalmi' },
  { postalCode: '48007', city: 'Kotka' },
  { postalCode: '48100', city: 'Kotka' },
  { postalCode: '48101', city: 'Kotka' },
  { postalCode: '48130', city: 'Kotka' },
  { postalCode: '48180', city: 'Kuutsalo' },
  { postalCode: '48200', city: 'Kotka' },
  { postalCode: '48201', city: 'Kotka' },
  { postalCode: '48210', city: 'Kotka' },
  { postalCode: '48220', city: 'Kotka' },
  { postalCode: '48221', city: 'Kotka' },
  { postalCode: '48223', city: 'Langinkosken kalastusmaja' },
  { postalCode: '48230', city: 'Kotka' },
  { postalCode: '48231', city: 'Kotka' },
  { postalCode: '48251', city: 'Kotka' },
  { postalCode: '48300', city: 'Kotka' },
  { postalCode: '48310', city: 'Kotka' },
  { postalCode: '48350', city: 'Kotka' },
  { postalCode: '48351', city: 'Kotka' },
  { postalCode: '48400', city: 'Kotka' },
  { postalCode: '48401', city: 'Kotka' },
  { postalCode: '48410', city: 'Kotka' },
  { postalCode: '48600', city: 'Karhula' },
  { postalCode: '48601', city: 'Karhula' },
  { postalCode: '48700', city: 'Kyminlinna' },
  { postalCode: '48701', city: 'Kyminlinna' },
  { postalCode: '48710', city: 'Karhula' },
  { postalCode: '48720', city: 'Kymi' },
  { postalCode: '48750', city: 'Kotka' },
  { postalCode: '48770', city: 'Karhula' },
  { postalCode: '48771', city: 'Karhula' },
  { postalCode: '48800', city: 'Karhula' },
  { postalCode: '48810', city: 'Karhula' },
  { postalCode: '48900', city: 'Sunila' },
  { postalCode: '48901', city: 'Kotka' },
  { postalCode: '48910', city: 'Kotka' },
  { postalCode: '48911', city: 'Kotka' },
  { postalCode: '48920', city: 'Halla' },
  { postalCode: '48930', city: 'Tiutinen' },
  { postalCode: '49200', city: 'Heinlahti' },
  { postalCode: '49210', city: 'Huutjärvi' },
  { postalCode: '49220', city: 'Siltakylä' },
  { postalCode: '49240', city: 'Purola' },
  { postalCode: '49270', city: 'Pyhtää' },
  { postalCode: '49290', city: 'Vastila' },
  { postalCode: '49300', city: 'Tavastila' },
  { postalCode: '49340', city: 'Juurikorpi' },
  { postalCode: '49400', city: 'Hamina' },
  { postalCode: '49401', city: 'Hamina' },
  { postalCode: '49407', city: 'Hamina' },
  { postalCode: '49410', city: 'Poitsila' },
  { postalCode: '49411', city: 'Poitsila' },
  { postalCode: '49420', city: 'Hamina' },
  { postalCode: '49460', city: 'Hamina' },
  { postalCode: '49461', city: 'Hamina' },
  { postalCode: '49470', city: 'Kivelä' },
  { postalCode: '49480', city: 'Summa' },
  { postalCode: '49490', city: 'Neuvoton' },
  { postalCode: '49510', city: 'Husula' },
  { postalCode: '49520', city: 'Reitkalli' },
  { postalCode: '49540', city: 'Metsäkylä' },
  { postalCode: '49570', city: 'Kannusjärvi' },
  { postalCode: '49610', city: 'Sivatti' },
  { postalCode: '49630', city: 'Vehkajoki' },
  { postalCode: '49640', city: 'Paijärvi' },
  { postalCode: '49660', city: 'Pyhältö' },
  { postalCode: '49700', city: 'Miehikkälä' },
  { postalCode: '49701', city: 'Miehikkälä' },
  { postalCode: '49720', city: 'Hurttala' },
  { postalCode: '49730', city: 'Muurikkala' },
  { postalCode: '49750', city: 'Kalliokoski' },
  { postalCode: '49760', city: 'Suur-Miehikkälä' },
  { postalCode: '49770', city: 'Salo-Miehikkälä' },
  { postalCode: '49780', city: 'Onkamaa' },
  { postalCode: '49818', city: 'Hamina leirintä' },
  { postalCode: '49840', city: 'Mäntlahti' },
  { postalCode: '49860', city: 'Klamila' },
  { postalCode: '49899', city: 'Hamina' },
  { postalCode: '49900', city: 'Virolahti' },
  { postalCode: '49901', city: 'Virolahti' },
  { postalCode: '49930', city: 'Vaalimaa' },
  { postalCode: '49950', city: 'Virolahti Kk' },
  { postalCode: '49960', city: 'Ala-Pihlaja' },
  { postalCode: '49980', city: 'Ravijoki' },
  { postalCode: '49999', city: 'Kotka' },
  { postalCode: '50007', city: 'Mikkeli' },
  { postalCode: '50100', city: 'Mikkeli' },
  { postalCode: '50101', city: 'Mikkeli' },
  { postalCode: '50120', city: 'Mikkeli' },
  { postalCode: '50130', city: 'Mikkeli' },
  { postalCode: '50131', city: 'Mikkeli' },
  { postalCode: '50150', city: 'Mikkeli' },
  { postalCode: '50151', city: 'Mikkeli' },
  { postalCode: '50160', city: 'Mikkeli' },
  { postalCode: '50170', city: 'Mikkeli' },
  { postalCode: '50171', city: 'Mikkeli' },
  { postalCode: '50180', city: 'Mikkeli' },
  { postalCode: '50190', city: 'Mikkeli' },
  { postalCode: '50350', city: 'Norola' },
  { postalCode: '50500', city: 'Mikkeli' },
  { postalCode: '50501', city: 'Mikkeli' },
  { postalCode: '50520', city: 'Mikkeli' },
  { postalCode: '50600', city: 'Mikkeli' },
  { postalCode: '50670', city: 'Otava' },
  { postalCode: '50770', city: 'Korpikoski' },
  { postalCode: '50970', city: 'Ihastjärvi' },
  { postalCode: '51130', city: 'Vanhamäki' },
  { postalCode: '51200', city: 'Kangasniemi' },
  { postalCode: '51201', city: 'Kangasniemi' },
  { postalCode: '51260', city: 'Tahkomäki' },
  { postalCode: '51270', city: 'Kutemajärvi' },
  { postalCode: '51310', city: 'Levä' },
  { postalCode: '51330', city: 'Kortesalmi' },
  { postalCode: '51335', city: 'Kaihlamäki' },
  { postalCode: '51340', city: 'Hännilä' },
  { postalCode: '51350', city: 'Paappala' },
  { postalCode: '51360', city: 'Koivula' },
  { postalCode: '51380', city: 'Synsiö' },
  { postalCode: '51420', city: 'Harjumaa' },
  { postalCode: '51430', city: 'Läsäkoski' },
  { postalCode: '51440', city: 'Vuojalahti' },
  { postalCode: '51450', city: 'Pajulankylä' },
  { postalCode: '51460', city: 'Luusniemi' },
  { postalCode: '51520', city: 'Hiirola' },
  { postalCode: '51540', city: 'Kalvitsa' },
  { postalCode: '51600', city: 'Haukivuori' },
  { postalCode: '51601', city: 'Haukivuori' },
  { postalCode: '51620', city: 'Pitkäaho' },
  { postalCode: '51670', city: 'Nykälä' },
  { postalCode: '51720', city: 'Rahula' },
  { postalCode: '51730', city: 'Asila' },
  { postalCode: '51740', city: 'Huuhanaho' },
  { postalCode: '51760', city: 'Nuutilanmäki' },
  { postalCode: '51770', city: 'Pekurila' },
  { postalCode: '51780', city: 'Kuosmala' },
  { postalCode: '51810', city: 'Maivala' },
  { postalCode: '51820', city: 'Hatsola' },
  { postalCode: '51830', city: 'Näärinki' },
  { postalCode: '51840', city: 'Nevaintausta' },
  { postalCode: '51850', city: 'Vuorenmaa' },
  { postalCode: '51860', city: 'Narila' },
  { postalCode: '51880', city: 'Koikkala' },
  { postalCode: '51890', city: 'Risulahti' },
  { postalCode: '51900', city: 'Juva' },
  { postalCode: '51901', city: 'Juva' },
  { postalCode: '51930', city: 'Paatela' },
  { postalCode: '51940', city: 'Härkälä' },
  { postalCode: '51960', city: 'Palokangas' },
  { postalCode: '51980', city: 'Lauteala' },
  { postalCode: '51999', city: 'Mikkeli' },
  { postalCode: '52100', city: 'Anttola' },
  { postalCode: '52110', city: 'Majavesi' },
  { postalCode: '52140', city: 'Ylivesi' },
  { postalCode: '52150', city: 'Hauhala' },
  { postalCode: '52200', city: 'Puumala' },
  { postalCode: '52201', city: 'Puumala' },
  { postalCode: '52230', city: 'Hurissalo' },
  { postalCode: '52240', city: 'Ruokotaipale' },
  { postalCode: '52270', city: 'Ryhälä' },
  { postalCode: '52300', city: 'Ristiina' },
  { postalCode: '52301', city: 'Ristiina' },
  { postalCode: '52320', city: 'Vitsiälä' },
  { postalCode: '52330', city: 'Heimari' },
  { postalCode: '52340', city: 'Hangastenmaa' },
  { postalCode: '52350', city: 'Kalmo' },
  { postalCode: '52360', city: 'Someenjärvi' },
  { postalCode: '52370', city: 'Suurlahti' },
  { postalCode: '52420', city: 'Pellosniemi' },
  { postalCode: '52510', city: 'Hietanen' },
  { postalCode: '52520', city: 'Syväsmäki' },
  { postalCode: '52550', city: 'Hirvensalmi' },
  { postalCode: '52551', city: 'Hirvensalmi' },
  { postalCode: '52580', city: 'Hämeenmäki' },
  { postalCode: '52610', city: 'Tuukkala' },
  { postalCode: '52620', city: 'Kuitula' },
  { postalCode: '52650', city: 'Pärnämäki' },
  { postalCode: '52660', city: 'Marjotaipale' },
  { postalCode: '52700', city: 'Mäntyharju' },
  { postalCode: '52701', city: 'Mäntyharju' },
  { postalCode: '52710', city: 'Mäntyharju Kk' },
  { postalCode: '52720', city: 'Karankamäki' },
  { postalCode: '52730', city: 'Koirakivi' },
  { postalCode: '52740', city: 'Tuustaipale' },
  { postalCode: '52760', city: 'Mynttilä' },
  { postalCode: '52770', city: 'Ollikkala' },
  { postalCode: '52780', city: 'Kuomiokoski' },
  { postalCode: '52810', city: 'Varpanen' },
  { postalCode: '52830', city: 'Suomenniemi' },
  { postalCode: '52850', city: 'Halmeniemi' },
  { postalCode: '52870', city: 'Kinni' },
  { postalCode: '52890', city: 'Käävänkylä' },
  { postalCode: '52920', city: 'Voikoski' },
  { postalCode: '52940', city: 'Hillosensalmi' },
  { postalCode: '52960', city: 'Nurmaa' },
  { postalCode: '52970', city: 'Pankalahti' },
  { postalCode: '52980', city: 'Ahvenainen' },
  { postalCode: '53007', city: 'Lappeenranta' },
  { postalCode: '53100', city: 'Lappeenranta' },
  { postalCode: '53101', city: 'Lappeenranta' },
  { postalCode: '53130', city: 'Lappeenranta' },
  { postalCode: '53200', city: 'Lappeenranta' },
  { postalCode: '53300', city: 'Lappeenranta' },
  { postalCode: '53301', city: 'Lappeenranta' },
  { postalCode: '53400', city: 'Lappeenranta' },
  { postalCode: '53420', city: 'Lappeenranta' },
  { postalCode: '53440', city: 'Lappeenranta' },
  { postalCode: '53500', city: 'Lappeenranta' },
  { postalCode: '53501', city: 'Lappeenranta' },
  { postalCode: '53600', city: 'Lappeenranta' },
  { postalCode: '53601', city: 'Lappeenranta' },
  { postalCode: '53650', city: 'Lappeenranta' },
  { postalCode: '53800', city: 'Lappeenranta' },
  { postalCode: '53810', city: 'Lappeenranta' },
  { postalCode: '53830', city: 'Lappeenranta' },
  { postalCode: '53850', city: 'Lappeenranta' },
  { postalCode: '53851', city: 'Lappeenranta' },
  { postalCode: '53900', city: 'Lappeenranta' },
  { postalCode: '53901', city: 'Lappeenranta' },
  { postalCode: '53920', city: 'Lappeenranta' },
  { postalCode: '53950', city: 'Lappeenranta' },
  { postalCode: '54100', city: 'Joutseno' },
  { postalCode: '54101', city: 'Joutseno' },
  { postalCode: '54110', city: 'Lampikangas' },
  { postalCode: '54120', city: 'Pulp' },
  { postalCode: '54160', city: 'Ala-Joutseno' },
  { postalCode: '54180', city: 'Kähärilä' },
  { postalCode: '54190', city: 'Konnunsuo' },
  { postalCode: '54230', city: 'Nuijamaa' },
  { postalCode: '54250', city: 'Rapattila' },
  { postalCode: '54270', city: 'Vainikkala' },
  { postalCode: '54310', city: 'Hytti' },
  { postalCode: '54330', city: 'Simola' },
  { postalCode: '54340', city: 'Raippo' },
  { postalCode: '54380', city: 'Tani' },
  { postalCode: '54390', city: 'Pulsa' },
  { postalCode: '54410', city: 'Ylämaa' },
  { postalCode: '54430', city: 'Hujakkala' },
  { postalCode: '54440', city: 'Väkevälä' },
  { postalCode: '54450', city: 'Nurmela' },
  { postalCode: '54460', city: 'Ylijärvi' },
  { postalCode: '54480', city: 'Nokkala' },
  { postalCode: '54490', city: 'Ahomäki' },
  { postalCode: '54500', city: 'Taavetti' },
  { postalCode: '54501', city: 'Taavetti' },
  { postalCode: '54510', city: 'Uro' },
  { postalCode: '54530', city: 'Luumäki' },
  { postalCode: '54550', city: 'Suo-Anttila' },
  { postalCode: '54580', city: 'Somerharju' },
  { postalCode: '54610', city: 'Ellola' },
  { postalCode: '54620', city: 'Munteenkylä' },
  { postalCode: '54630', city: 'Viuhkola' },
  { postalCode: '54640', city: 'Pentti' },
  { postalCode: '54670', city: 'Hämäläinen' },
  { postalCode: '54710', city: 'Lemi' },
  { postalCode: '54711', city: 'Lemi' },
  { postalCode: '54715', city: 'Nisola' },
  { postalCode: '54720', city: 'Laksiainen' },
  { postalCode: '54740', city: 'Uimi' },
  { postalCode: '54750', city: 'Välijoki' },
  { postalCode: '54770', city: 'Heituinlahti' },
  { postalCode: '54800', city: 'Savitaipale' },
  { postalCode: '54801', city: 'Savitaipale' },
  { postalCode: '54820', city: 'Kotimäki' },
  { postalCode: '54840', city: 'Telamäki' },
  { postalCode: '54850', city: 'Kuukanniemi' },
  { postalCode: '54880', city: 'Partakoski' },
  { postalCode: '54915', city: 'Saimaanharju' },
  { postalCode: '54920', city: 'Taipalsaari' },
  { postalCode: '54921', city: 'Taipalsaari' },
  { postalCode: '54930', city: 'Levänen' },
  { postalCode: '54940', city: 'Solkei' },
  { postalCode: '54950', city: 'Pettilä' },
  { postalCode: '54960', city: 'Vehkataipale' },
  { postalCode: '54980', city: 'Saimaanranta' },
  { postalCode: '54990', city: 'Suur-Saimaa' },
  { postalCode: '54999', city: 'Lappeenranta' },
  { postalCode: '55007', city: 'Imatra' },
  { postalCode: '55100', city: 'Imatra' },
  { postalCode: '55101', city: 'Imatra' },
  { postalCode: '55120', city: 'Imatra' },
  { postalCode: '55121', city: 'Imatra' },
  { postalCode: '55300', city: 'Rauha' },
  { postalCode: '55320', city: 'Rauha' },
  { postalCode: '55330', city: 'Tiuruniemi' },
  { postalCode: '55400', city: 'Imatra' },
  { postalCode: '55420', city: 'Imatra' },
  { postalCode: '55510', city: 'Imatra' },
  { postalCode: '55610', city: 'Imatra' },
  { postalCode: '55611', city: 'Imatra' },
  { postalCode: '55700', city: 'Imatra' },
  { postalCode: '55800', city: 'Imatra' },
  { postalCode: '55801', city: 'Imatra' },
  { postalCode: '55910', city: 'Imatra' },
  { postalCode: '55950', city: 'Imatra' },
  { postalCode: '56100', city: 'Ruokolahti' },
  { postalCode: '56101', city: 'Ruokolahti' },
  { postalCode: '56120', city: 'Salosaari' },
  { postalCode: '56130', city: 'Savilahti' },
  { postalCode: '56140', city: 'Äitsaari' },
  { postalCode: '56210', city: 'Virmutjoki' },
  { postalCode: '56250', city: 'Erä' },
  { postalCode: '56310', city: 'Syyspohja' },
  { postalCode: '56330', city: 'Utula' },
  { postalCode: '56350', city: 'Kyläniemi' },
  { postalCode: '56370', city: 'Hauklappi' },
  { postalCode: '56410', city: 'Kaiturinpää' },
  { postalCode: '56440', city: 'Pohja-Lankila' },
  { postalCode: '56510', city: 'Puntala' },
  { postalCode: '56550', city: 'Niska-Pietilä' },
  { postalCode: '56610', city: 'Rautjärvi' },
  { postalCode: '56620', city: 'Untamo' },
  { postalCode: '56640', city: 'Purnujärvi' },
  { postalCode: '56710', city: 'Miettilä' },
  { postalCode: '56730', city: 'Laikko' },
  { postalCode: '56800', city: 'Simpele' },
  { postalCode: '56801', city: 'Simpele' },
  { postalCode: '56810', city: 'Simpele As' },
  { postalCode: '56830', city: 'Änkilä' },
  { postalCode: '56999', city: 'Imatra' },
  { postalCode: '57007', city: 'Savonlinna' },
  { postalCode: '57100', city: 'Savonlinna' },
  { postalCode: '57101', city: 'Savonlinna' },
  { postalCode: '57120', city: 'Savonlinna' },
  { postalCode: '57130', city: 'Savonlinna' },
  { postalCode: '57131', city: 'Savonlinna' },
  { postalCode: '57150', city: 'Olavinlinna' },
  { postalCode: '57170', city: 'Savonlinna' },
  { postalCode: '57200', city: 'Savonlinna' },
  { postalCode: '57201', city: 'Savonlinna' },
  { postalCode: '57210', city: 'Savonlinna' },
  { postalCode: '57220', city: 'Savonlinna' },
  { postalCode: '57230', city: 'Savonlinna' },
  { postalCode: '57231', city: 'Savonlinna' },
  { postalCode: '57310', city: 'Savonlinna' },
  { postalCode: '57510', city: 'Savonlinna' },
  { postalCode: '57600', city: 'Savonlinna' },
  { postalCode: '57710', city: 'Savonlinna' },
  { postalCode: '57810', city: 'Savonlinna' },
  { postalCode: '58130', city: 'Oravi' },
  { postalCode: '58140', city: 'Ahvensalmi' },
  { postalCode: '58150', city: 'Makkola' },
  { postalCode: '58160', city: 'Karvila' },
  { postalCode: '58170', city: 'Simanala' },
  { postalCode: '58175', city: 'Enonkoski' },
  { postalCode: '58180', city: 'Ihamaniemi' },
  { postalCode: '58200', city: 'Kerimäki' },
  { postalCode: '58201', city: 'Kerimäki' },
  { postalCode: '58220', city: 'Louhi' },
  { postalCode: '58240', city: 'Seikanlampi' },
  { postalCode: '58250', city: 'Hautalahti' },
  { postalCode: '58260', city: 'Kumpuranta' },
  { postalCode: '58300', city: 'Savonranta' },
  { postalCode: '58320', city: 'Raikuu' },
  { postalCode: '58350', city: 'Villala' },
  { postalCode: '58360', city: 'Säimen' },
  { postalCode: '58390', city: 'Rönkönvaara' },
  { postalCode: '58410', city: 'Haapakallio' },
  { postalCode: '58420', city: 'Silvola' },
  { postalCode: '58430', city: 'Kulennoinen' },
  { postalCode: '58440', city: 'Päivinen' },
  { postalCode: '58450', city: 'Punkaharju' },
  { postalCode: '58451', city: 'Punkuharju' },
  { postalCode: '58500', city: 'Punkaharju' },
  { postalCode: '58501', city: 'Punkaharju' },
  { postalCode: '58520', city: 'Hiukkajoki' },
  { postalCode: '58550', city: 'Putikko' },
  { postalCode: '58570', city: 'Ruhvana' },
  { postalCode: '58580', city: 'Vuoriniemi' },
  { postalCode: '58610', city: 'Telakanava' },
  { postalCode: '58620', city: 'Lohilahti' },
  { postalCode: '58630', city: 'Lohikoski' },
  { postalCode: '58640', city: 'Lieviskä' },
  { postalCode: '58650', city: 'Kiviapaja' },
  { postalCode: '58680', city: 'Piojärvi' },
  { postalCode: '58690', city: 'Ala-Särkilahti' },
  { postalCode: '58700', city: 'Sulkava' },
  { postalCode: '58701', city: 'Sulkava' },
  { postalCode: '58720', city: 'Kaartilankoski' },
  { postalCode: '58740', city: 'Kyrsyä' },
  { postalCode: '58770', city: 'Pihlajalahti' },
  { postalCode: '58810', city: 'Kallislahti' },
  { postalCode: '58830', city: 'Parkumäki' },
  { postalCode: '58840', city: 'Päiviälä' },
  { postalCode: '58850', city: 'Hiltula' },
  { postalCode: '58900', city: 'Rantasalmi' },
  { postalCode: '58901', city: 'Rantasalmi' },
  { postalCode: '58910', city: 'Rantasalmi As' },
  { postalCode: '58920', city: 'Kolkontaipale' },
  { postalCode: '58940', city: 'Tuusmäki' },
  { postalCode: '58999', city: 'Savonlinna' },
  { postalCode: '59100', city: 'Parikkala' },
  { postalCode: '59101', city: 'Parikkala' },
  { postalCode: '59110', city: 'Tiviä' },
  { postalCode: '59130', city: 'Koitsanlahti' },
  { postalCode: '59210', city: 'Melkoniemi' },
  { postalCode: '59220', city: 'Peruspohja' },
  { postalCode: '59310', city: 'Särkisalmi' },
  { postalCode: '59320', city: 'Haapaoja' },
  { postalCode: '59410', city: 'Kirjavala' },
  { postalCode: '59420', city: 'Kesusmaa' },
  { postalCode: '59510', city: 'Saari' },
  { postalCode: '59511', city: 'Saari' },
  { postalCode: '59520', city: 'Saari' },
  { postalCode: '59530', city: 'Mikkolanniemi' },
  { postalCode: '59610', city: 'Tarnala' },
  { postalCode: '59620', city: 'Rajavaara' },
  { postalCode: '59710', city: 'Kummunkylä' },
  { postalCode: '59720', city: 'Niukkala' },
  { postalCode: '59730', city: 'Uukuniemi' },
  { postalCode: '59800', city: 'Kesälahti' },
  { postalCode: '59801', city: 'Kesälahti' },
  { postalCode: '59810', city: 'Suurikylä' },
  { postalCode: '59820', city: 'Purujärvi' },
  { postalCode: '59830', city: 'Totkiniemi' },
  { postalCode: '59910', city: 'Luostarinen' },
  { postalCode: '59999', city: 'Parikkala' },
  { postalCode: '60007', city: 'Seinäjoki' },
  { postalCode: '60100', city: 'Seinäjoki' },
  { postalCode: '60101', city: 'Seinäjoki' },
  { postalCode: '60110', city: 'Seinäjoki' },
  { postalCode: '60120', city: 'Seinäjoki' },
  { postalCode: '60150', city: 'Seinäjoki' },
  { postalCode: '60200', city: 'Seinäjoki' },
  { postalCode: '60201', city: 'Seinäjoki' },
  { postalCode: '60220', city: 'Seinäjoki' },
  { postalCode: '60280', city: 'Seinäjoki' },
  { postalCode: '60320', city: 'Seinäjoki' },
  { postalCode: '60420', city: 'Seinäjoki' },
  { postalCode: '60450', city: 'Munakka' },
  { postalCode: '60510', city: 'Hyllykallio' },
  { postalCode: '60511', city: 'Hyllykallio' },
  { postalCode: '60530', city: 'Kivisaari' },
  { postalCode: '60550', city: 'Nurmo' },
  { postalCode: '60551', city: 'Nurmo' },
  { postalCode: '60560', city: 'Halkosaari' },
  { postalCode: '60601', city: 'Jalasjärvi' },
  { postalCode: '60640', city: 'Isokoski' },
  { postalCode: '60720', city: 'Tuomikylä' },
  { postalCode: '60760', city: 'Pojanluoma' },
  { postalCode: '60800', city: 'Ilmajoki' },
  { postalCode: '60801', city: 'Ilmajoki' },
  { postalCode: '61100', city: 'Peräseinäjoki' },
  { postalCode: '61101', city: 'Peräseinäjoki' },
  { postalCode: '61110', city: 'Vuolle' },
  { postalCode: '61120', city: 'Louko' },
  { postalCode: '61140', city: 'Pasto' },
  { postalCode: '61150', city: 'Sydänmaa' },
  { postalCode: '61160', city: 'Koura' },
  { postalCode: '61170', city: 'Sääskiniemi' },
  { postalCode: '61180', city: 'Haapaluoma' },
  { postalCode: '61220', city: 'Myllysalo' },
  { postalCode: '61230', city: 'Luopa' },
  { postalCode: '61240', city: 'Opas' },
  { postalCode: '61250', city: 'Jalasto' },
  { postalCode: '61270', city: 'Luopajärvi' },
  { postalCode: '61280', city: 'Jokipii' },
  { postalCode: '61290', city: 'Taivalmaa' },
  { postalCode: '61300', city: 'Kurikka' },
  { postalCode: '61301', city: 'Kurikka' },
  { postalCode: '61310', city: 'Panttila' },
  { postalCode: '61330', city: 'Koskenkorva' },
  { postalCode: '61331', city: 'Koskenkorva' },
  { postalCode: '61340', city: 'Nopankylä' },
  { postalCode: '61350', city: 'Huissi' },
  { postalCode: '61360', city: 'Mieto' },
  { postalCode: '61370', city: 'Lohiluoma' },
  { postalCode: '61380', city: 'Polvenkylä' },
  { postalCode: '61400', city: 'Ylistaro' },
  { postalCode: '61401', city: 'Ylistaro' },
  { postalCode: '61410', city: 'Ylistaro As' },
  { postalCode: '61430', city: 'Isorehto' },
  { postalCode: '61440', city: 'Untamala' },
  { postalCode: '61450', city: 'Kylänpää' },
  { postalCode: '61460', city: 'Hanhikoski' },
  { postalCode: '61470', city: 'Kitinoja' },
  { postalCode: '61480', city: 'Tenkkula' },
  { postalCode: '61500', city: 'Isokyrö' },
  { postalCode: '61501', city: 'Isokyrö' },
  { postalCode: '61510', city: 'Ruusupuro' },
  { postalCode: '61520', city: 'Lehmäjoki' },
  { postalCode: '61540', city: 'Akkaansilta' },
  { postalCode: '61550', city: 'Orismala' },
  { postalCode: '61560', city: 'Orisberg' },
  { postalCode: '61600', city: 'Jalasjärvi' },
  { postalCode: '61601', city: 'Jalasjärvi' },
  { postalCode: '61620', city: 'Komsi' },
  { postalCode: '61630', city: 'Hirvijärvi' },
  { postalCode: '61640', city: 'Ala-Valli' },
  { postalCode: '61650', city: 'Kalakoski' },
  { postalCode: '61660', city: 'Kantola' },
  { postalCode: '61670', city: 'Kulma' },
  { postalCode: '61680', city: 'Mantila' },
  { postalCode: '61710', city: 'Pentinmäki' },
  { postalCode: '61720', city: 'Koskue' },
  { postalCode: '61730', city: 'Yli-Valli' },
  { postalCode: '61760', city: 'Ilvesjoki' },
  { postalCode: '61800', city: 'Kauhajoki' },
  { postalCode: '61801', city: 'Kauhajoki' },
  { postalCode: '61810', city: 'Luomankylä' },
  { postalCode: '61820', city: 'Kainasto' },
  { postalCode: '61840', city: 'Norinkylä' },
  { postalCode: '61850', city: 'Kauhajoki As' },
  { postalCode: '61851', city: 'Kauhajoki as' },
  { postalCode: '61860', city: 'Harja' },
  { postalCode: '61880', city: 'Ikkeläjärvi' },
  { postalCode: '61910', city: 'Nummijärvi' },
  { postalCode: '61920', city: 'Nummikoski' },
  { postalCode: '61940', city: 'Hyyppä' },
  { postalCode: '61950', city: 'Juonikylä' },
  { postalCode: '61960', city: 'Kyrönlatva' },
  { postalCode: '61980', city: 'Päntäne' },
  { postalCode: '61999', city: 'Seinäjoki' },
  { postalCode: '62100', city: 'Lapua' },
  { postalCode: '62101', city: 'Lapua' },
  { postalCode: '62110', city: 'Yli-Hella' },
  { postalCode: '62130', city: 'Hellanmaa' },
  { postalCode: '62140', city: 'Ala-Hella' },
  { postalCode: '62150', city: 'Rintakangas' },
  { postalCode: '62160', city: 'Karhunkylä' },
  { postalCode: '62165', city: 'Tiistenjoki' },
  { postalCode: '62170', city: 'Lakaluoma' },
  { postalCode: '62175', city: 'Ylikylä' },
  { postalCode: '62185', city: 'Mäki-Paavola' },
  { postalCode: '62190', city: 'Ruha' },
  { postalCode: '62195', city: 'Karhunmäki' },
  { postalCode: '62200', city: 'Kauhava' },
  { postalCode: '62201', city: 'Kauhava' },
  { postalCode: '62210', city: 'Kauhava Kk' },
  { postalCode: '62220', city: 'Pernaa' },
  { postalCode: '62230', city: 'Jylhä' },
  { postalCode: '62240', city: 'Huhmarkoski' },
  { postalCode: '62260', city: 'Hirvijoki' },
  { postalCode: '62270', city: 'Halmesmäki' },
  { postalCode: '62280', city: 'Mustamaa' },
  { postalCode: '62290', city: 'Hirvikylä' },
  { postalCode: '62295', city: 'Kauhajärvi' },
  { postalCode: '62300', city: 'Härmä' },
  { postalCode: '62301', city: 'Härmä' },
  { postalCode: '62310', city: 'Voltti' },
  { postalCode: '62320', city: 'Huhtamäki' },
  { postalCode: '62330', city: 'Vakkuri' },
  { postalCode: '62340', city: 'Kuoppa' },
  { postalCode: '62350', city: 'Ekola' },
  { postalCode: '62360', city: 'Kivihuhta' },
  { postalCode: '62370', city: 'Kangasto' },
  { postalCode: '62375', city: 'Ylihärmä' },
  { postalCode: '62380', city: 'Kosolankylä' },
  { postalCode: '62390', city: 'Vesiluoma' },
  { postalCode: '62395', city: 'Petterinmäki' },
  { postalCode: '62410', city: 'Rintala' },
  { postalCode: '62420', city: 'Kortesjärvi' },
  { postalCode: '62421', city: 'Kortesjärvi' },
  { postalCode: '62430', city: 'Peltotupa' },
  { postalCode: '62435', city: 'Pirttinen' },
  { postalCode: '62440', city: 'Perkiömäki' },
  { postalCode: '62470', city: 'Purmojärvi' },
  { postalCode: '62500', city: 'Evijärvi' },
  { postalCode: '62501', city: 'Evijärvi' },
  { postalCode: '62510', city: 'Kuoppa-Aho' },
  { postalCode: '62520', city: 'Kautiainen' },
  { postalCode: '62530', city: 'Lepistönmäki' },
  { postalCode: '62540', city: 'Vasikka-Aho' },
  { postalCode: '62570', city: 'Särkilä' },
  { postalCode: '62600', city: 'Lappajärvi' },
  { postalCode: '62601', city: 'Lappajärvi' },
  { postalCode: '62610', city: 'Tarvola' },
  { postalCode: '62620', city: 'Ala-Seppä' },
  { postalCode: '62630', city: 'Karvala' },
  { postalCode: '62640', city: 'Savonkylä' },
  { postalCode: '62660', city: 'Itäkylä' },
  { postalCode: '62710', city: 'Kurejoki' },
  { postalCode: '62720', city: 'Sissala' },
  { postalCode: '62730', city: 'Haukkala' },
  { postalCode: '62740', city: 'Koskenvarsi' },
  { postalCode: '62750', city: 'Saukonkylä' },
  { postalCode: '62760', city: 'Menkijärvi' },
  { postalCode: '62800', city: 'Vimpeli' },
  { postalCode: '62801', city: 'Vimpeli' },
  { postalCode: '62810', city: 'Savonjoki' },
  { postalCode: '62820', city: 'Teerineva' },
  { postalCode: '62830', city: 'Luoma-Aho' },
  { postalCode: '62840', city: 'Lakaniemi' },
  { postalCode: '62850', city: 'Pokela' },
  { postalCode: '62860', city: 'Sahi' },
  { postalCode: '62870', city: 'Vinni' },
  { postalCode: '62880', city: 'Sääksvesi' },
  { postalCode: '62900', city: 'Alajärvi' },
  { postalCode: '62901', city: 'Alajärvi' },
  { postalCode: '62920', city: 'Levijoki' },
  { postalCode: '62940', city: 'Hoisko' },
  { postalCode: '62950', city: 'Paalijärvi' },
  { postalCode: '62990', city: 'Möksy' },
  { postalCode: '63100', city: 'Kuortane' },
  { postalCode: '63101', city: 'Kuortane' },
  { postalCode: '63120', city: 'Leppälänkylä' },
  { postalCode: '63130', city: 'Mäyry' },
  { postalCode: '63140', city: 'Länsiranta' },
  { postalCode: '63150', city: 'Ruona' },
  { postalCode: '63160', city: 'Salmi' },
  { postalCode: '63170', city: 'Kuortaneen Urheiluopisto' },
  { postalCode: '63210', city: 'Niinimaa' },
  { postalCode: '63230', city: 'Lentilä' },
  { postalCode: '63250', city: 'Rantala' },
  { postalCode: '63300', city: 'Alavus' },
  { postalCode: '63301', city: 'Alavus' },
  { postalCode: '63320', city: 'Kattelus' },
  { postalCode: '63330', city: 'Pahajoki' },
  { postalCode: '63340', city: 'Sapsalampi' },
  { postalCode: '63350', city: 'Sulkavankylä' },
  { postalCode: '63355', city: 'Seinäjärvi' },
  { postalCode: '63360', city: 'Kuivaskylä' },
  { postalCode: '63370', city: 'Taipalus' },
  { postalCode: '63400', city: 'Alavus As' },
  { postalCode: '63401', city: 'Alavus As' },
  { postalCode: '63410', city: 'Rantatöysä' },
  { postalCode: '63430', city: 'Kätkänjoki' },
  { postalCode: '63450', city: 'Löyä' },
  { postalCode: '63500', city: 'Lehtimäki' },
  { postalCode: '63501', city: 'Lehtimäki' },
  { postalCode: '63510', city: 'Lehtimäki Kk' },
  { postalCode: '63530', city: 'Länsikylä' },
  { postalCode: '63540', city: 'Hernesmaa' },
  { postalCode: '63550', city: 'Suninsalmi' },
  { postalCode: '63600', city: 'Töysä' },
  { postalCode: '63601', city: 'Töysä' },
  { postalCode: '63610', city: 'Tuuri' },
  { postalCode: '63630', city: 'Kitula' },
  { postalCode: '63640', city: 'Ritola' },
  { postalCode: '63660', city: 'Ähtärinranta' },
  { postalCode: '63680', city: 'Hakojärvi' },
  { postalCode: '63700', city: 'Ähtäri' },
  { postalCode: '63701', city: 'Ähtäri' },
  { postalCode: '63706', city: 'Ähtärin eläinpuisto' },
  { postalCode: '63760', city: 'Vääräkoski' },
  { postalCode: '63770', city: 'Peränne' },
  { postalCode: '63780', city: 'Alastaipale' },
  { postalCode: '63800', city: 'Soini' },
  { postalCode: '63801', city: 'Soini' },
  { postalCode: '63810', city: 'Laasalanmäki' },
  { postalCode: '63830', city: 'Keisala' },
  { postalCode: '63880', city: 'Kukonkylä' },
  { postalCode: '63890', city: 'Hautakylä' },
  { postalCode: '63900', city: 'Myllymäki' },
  { postalCode: '63910', city: 'Inhan Tehtaat' },
  { postalCode: '63920', city: 'Inha' },
  { postalCode: '63921', city: 'Inha' },
  { postalCode: '63950', city: 'Vehunkylä' },
  { postalCode: '64100', city: 'Kristiinankaupunki' },
  { postalCode: '64101', city: 'Kristiinankaupunki' },
  { postalCode: '64120', city: 'Kristiinankaupunki' },
  { postalCode: '64140', city: 'Tiukka' },
  { postalCode: '64170', city: 'Lċlby' },
  { postalCode: '64200', city: 'Närpes' },
  { postalCode: '64201', city: 'Närpes' },
  { postalCode: '64210', city: 'Kalax' },
  { postalCode: '64220', city: 'Yttermark' },
  { postalCode: '64230', city: 'Närpes St' },
  { postalCode: '64240', city: 'Böle' },
  { postalCode: '64250', city: 'Pjelax' },
  { postalCode: '64260', city: 'Kaskinen' },
  { postalCode: '64261', city: 'Kaskinen' },
  { postalCode: '64300', city: 'Lapväärtti' },
  { postalCode: '64301', city: 'Lappfjärd' },
  { postalCode: '64310', city: 'Perus' },
  { postalCode: '64320', city: 'Dagsmark' },
  { postalCode: '64330', city: 'Ohriluoma' },
  { postalCode: '64340', city: 'Karikannas' },
  { postalCode: '64350', city: 'Karijoki' },
  { postalCode: '64351', city: 'Karijoki' },
  { postalCode: '64360', city: 'Ylimaa' },
  { postalCode: '64370', city: 'Myrkky' },
  { postalCode: '64420', city: 'Uttermossa' },
  { postalCode: '64430', city: 'Pakankylä' },
  { postalCode: '64440', city: 'Metsälä' },
  { postalCode: '64450', city: 'Kallträsk' },
  { postalCode: '64460', city: 'Härkmeri' },
  { postalCode: '64470', city: 'Dalen' },
  { postalCode: '64480', city: 'Skaftung' },
  { postalCode: '64490', city: 'Siipyy' },
  { postalCode: '64510', city: 'Nämpnäs' },
  { postalCode: '64530', city: 'Norrnäs' },
  { postalCode: '64550', city: 'Rangsby' },
  { postalCode: '64610', city: 'Övermark' },
  { postalCode: '64611', city: 'Övermark' },
  { postalCode: '64700', city: 'Teuva' },
  { postalCode: '64701', city: 'Teuva' },
  { postalCode: '64720', city: 'Perälä' },
  { postalCode: '64740', city: 'Horo' },
  { postalCode: '64750', city: 'Kauppila' },
  { postalCode: '64760', city: 'Peltola' },
  { postalCode: '64770', city: 'Äystö' },
  { postalCode: '64810', city: 'Vanhakylä' },
  { postalCode: '64820', city: 'Kärjenkoski' },
  { postalCode: '64830', city: 'Villamo' },
  { postalCode: '64840', city: 'Heikkilänjoki' },
  { postalCode: '64850', city: 'Möykky' },
  { postalCode: '64900', city: 'Isojoki' },
  { postalCode: '64901', city: 'Isojoki' },
  { postalCode: '64920', city: 'Polvi' },
  { postalCode: '64930', city: 'Kodesjärvi' },
  { postalCode: '64960', city: 'Kaukoranta' },
  { postalCode: '64999', city: 'Kristiinankaupunki' },
  { postalCode: '65001', city: 'Vaasa' },
  { postalCode: '65007', city: 'Vaasa' },
  { postalCode: '65100', city: 'Vaasa' },
  { postalCode: '65101', city: 'Vaasa' },
  { postalCode: '65120', city: 'Vaasa' },
  { postalCode: '65130', city: 'Vaasa' },
  { postalCode: '65140', city: 'Vaasa' },
  { postalCode: '65170', city: 'Vaasa' },
  { postalCode: '65200', city: 'Vaasa' },
  { postalCode: '65201', city: 'Vaasa' },
  { postalCode: '65210', city: 'Vaasa' },
  { postalCode: '65230', city: 'Vaasa' },
  { postalCode: '65231', city: 'Vaasa' },
  { postalCode: '65240', city: 'Vasa' },
  { postalCode: '65250', city: 'Vaasa' },
  { postalCode: '65280', city: 'Vaasa' },
  { postalCode: '65290', city: 'Vaasa' },
  { postalCode: '65300', city: 'Vaasa' },
  { postalCode: '65301', city: 'Vaasa' },
  { postalCode: '65320', city: 'Vaasa' },
  { postalCode: '65321', city: 'Vaasa' },
  { postalCode: '65350', city: 'Vaasa' },
  { postalCode: '65351', city: 'Vaasa' },
  { postalCode: '65370', city: 'Vaasa' },
  { postalCode: '65380', city: 'Vaasa' },
  { postalCode: '65381', city: 'Vaasa' },
  { postalCode: '65410', city: 'Sundom' },
  { postalCode: '65430', city: 'Kronvik' },
  { postalCode: '65450', city: 'Sulva' },
  { postalCode: '65451', city: 'Sulva' },
  { postalCode: '65460', city: 'Tölby' },
  { postalCode: '65470', city: 'Rimal' },
  { postalCode: '65480', city: 'Vikby' },
  { postalCode: '65520', city: 'Helsingby' },
  { postalCode: '65540', city: 'Tuovila' },
  { postalCode: '65610', city: 'Mustasaari' },
  { postalCode: '65611', city: 'Mustasaari' },
  { postalCode: '65630', city: 'Karperö' },
  { postalCode: '65650', city: 'Koskö' },
  { postalCode: '65710', city: 'Singsby' },
  { postalCode: '65730', city: 'Jungsund' },
  { postalCode: '65760', city: 'Iskmo' },
  { postalCode: '65800', city: 'Raippaluoto' },
  { postalCode: '65801', city: 'Raippaluoto' },
  { postalCode: '65870', city: 'Björköby' },
  { postalCode: '65920', city: 'Norra Vallgrund' },
  { postalCode: '65930', city: 'Södra Vallgrund' },
  { postalCode: '65970', city: 'Söderudden' },
  { postalCode: '66100', city: 'Malax' },
  { postalCode: '66101', city: 'Malax' },
  { postalCode: '66140', city: 'Övermalax' },
  { postalCode: '66141', city: 'Övermalax' },
  { postalCode: '66160', city: 'Lċngċminne' },
  { postalCode: '66200', city: 'Korsnäs' },
  { postalCode: '66201', city: 'Korsnäs' },
  { postalCode: '66210', city: 'Molpe' },
  { postalCode: '66220', city: 'Bergö' },
  { postalCode: '66230', city: 'Korsbäck' },
  { postalCode: '66240', city: 'Petalax' },
  { postalCode: '66241', city: 'Petalax' },
  { postalCode: '66250', city: 'Nyby' },
  { postalCode: '66260', city: 'Svarvar' },
  { postalCode: '66270', city: 'Pörtom' },
  { postalCode: '66271', city: 'Pörtom' },
  { postalCode: '66275', city: 'Sidbäck' },
  { postalCode: '66280', city: 'Taklax' },
  { postalCode: '66290', city: 'Harrström' },
  { postalCode: '66295', city: 'Töjby' },
  { postalCode: '66300', city: 'Jurva' },
  { postalCode: '66301', city: 'Jurva' },
  { postalCode: '66320', city: 'Niemenkylä' },
  { postalCode: '66330', city: 'Hakko' },
  { postalCode: '66340', city: 'Sarvijoki' },
  { postalCode: '66350', city: 'Tainus' },
  { postalCode: '66360', city: 'Kesti' },
  { postalCode: '66370', city: 'Närvijoki' },
  { postalCode: '66400', city: 'Laihia' },
  { postalCode: '66401', city: 'Laihia' },
  { postalCode: '66410', city: 'Laihia As' },
  { postalCode: '66420', city: 'Ruto' },
  { postalCode: '66430', city: 'Vedenoja' },
  { postalCode: '66440', city: 'Tervajoki' },
  { postalCode: '66441', city: 'Tervajoki' },
  { postalCode: '66450', city: 'Jakkula' },
  { postalCode: '66460', city: 'Haapala' },
  { postalCode: '66470', city: 'Jukaja' },
  { postalCode: '66490', city: 'Jokisalo' },
  { postalCode: '66500', city: 'Vähäkyrö' },
  { postalCode: '66501', city: 'Vähäkyrö' },
  { postalCode: '66510', city: 'Merikaarto' },
  { postalCode: '66520', city: 'Veikkaala' },
  { postalCode: '66530', city: 'Koivulahti' },
  { postalCode: '66531', city: 'Kvevlax' },
  { postalCode: '66540', city: 'Petsmo' },
  { postalCode: '66550', city: 'Västerhankmo' },
  { postalCode: '66560', city: 'Österhankmo' },
  { postalCode: '66580', city: 'Kuni' },
  { postalCode: '66590', city: 'Vassor' },
  { postalCode: '66600', city: 'Vöyri' },
  { postalCode: '66601', city: 'Vöyri' },
  { postalCode: '66610', city: 'Rökiö' },
  { postalCode: '66620', city: 'Lotlax' },
  { postalCode: '66630', city: 'Kärklax' },
  { postalCode: '66640', city: 'Maxmo' },
  { postalCode: '66641', city: 'Maksamaa' },
  { postalCode: '66650', city: 'Tottesund' },
  { postalCode: '66660', city: 'Bertby' },
  { postalCode: '66670', city: 'Tuckur' },
  { postalCode: '66680', city: 'Kaurajärvi' },
  { postalCode: '66690', city: 'Rekipelto' },
  { postalCode: '66710', city: 'Kaitsor' },
  { postalCode: '66720', city: 'Karvat' },
  { postalCode: '66730', city: 'Oxkangar' },
  { postalCode: '66760', city: 'Särkimo' },
  { postalCode: '66800', city: 'Oravainen' },
  { postalCode: '66801', city: 'Oravais' },
  { postalCode: '66810', city: 'Kimo' },
  { postalCode: '66820', city: 'Komossa' },
  { postalCode: '66830', city: 'Oravaisten Tehdas' },
  { postalCode: '66831', city: 'Oravainen' },
  { postalCode: '66840', city: 'Pensala' },
  { postalCode: '66850', city: 'Jepua' },
  { postalCode: '66860', city: 'Kujala' },
  { postalCode: '66880', city: 'Ċvist' },
  { postalCode: '66900', city: 'Uusikaarlepyy' },
  { postalCode: '66901', city: 'Uusikaarlepyy' },
  { postalCode: '66910', city: 'Soklot' },
  { postalCode: '66930', city: 'Kovjoki' },
  { postalCode: '66950', city: 'Munsala' },
  { postalCode: '66970', city: 'Hirvlax' },
  { postalCode: '66980', city: 'Monċ' },
  { postalCode: '66999', city: 'Vaasa' },
  { postalCode: '67007', city: 'Kokkola' },
  { postalCode: '67100', city: 'Kokkola' },
  { postalCode: '67101', city: 'Kokkola' },
  { postalCode: '67200', city: 'Kokkola' },
  { postalCode: '67300', city: 'Kokkola' },
  { postalCode: '67400', city: 'Kokkola' },
  { postalCode: '67401', city: 'Kokkola' },
  { postalCode: '67410', city: 'Kokkola' },
  { postalCode: '67500', city: 'Rimmi' },
  { postalCode: '67600', city: 'Kokkola' },
  { postalCode: '67601', city: 'Kokkola' },
  { postalCode: '67700', city: 'Kokkola' },
  { postalCode: '67701', city: 'Kokkola' },
  { postalCode: '67800', city: 'Kokkola' },
  { postalCode: '67801', city: 'Kokkola' },
  { postalCode: '67900', city: 'Kokkola' },
  { postalCode: '67901', city: 'Kokkola' },
  { postalCode: '68100', city: 'Himanka' },
  { postalCode: '68101', city: 'Himanka' },
  { postalCode: '68150', city: 'Hillilä' },
  { postalCode: '68210', city: 'Marinkainen' },
  { postalCode: '68220', city: 'Karhi' },
  { postalCode: '68230', city: 'Lohtaja' },
  { postalCode: '68231', city: 'Lohtaja' },
  { postalCode: '68240', city: 'Ala-Viirre' },
  { postalCode: '68300', city: 'Kälviä' },
  { postalCode: '68301', city: 'Kälviä' },
  { postalCode: '68310', city: 'Suonperä' },
  { postalCode: '68320', city: 'Ruotsalo' },
  { postalCode: '68340', city: 'Joki-Vuolle' },
  { postalCode: '68370', city: 'Ullava' },
  { postalCode: '68380', city: 'Yli-Ullava' },
  { postalCode: '68390', city: 'Rahkonen' },
  { postalCode: '68410', city: 'Ala-Veteli' },
  { postalCode: '68420', city: 'Tast' },
  { postalCode: '68500', city: 'Kruunupyy' },
  { postalCode: '68501', city: 'Kruunupyy' },
  { postalCode: '68530', city: 'Lepplax' },
  { postalCode: '68550', city: 'Öja' },
  { postalCode: '68551', city: 'Öja' },
  { postalCode: '68555', city: 'Bosund' },
  { postalCode: '68560', city: 'Eugmo' },
  { postalCode: '68570', city: 'Larsmo' },
  { postalCode: '68571', city: 'Luoto' },
  { postalCode: '68580', city: 'Furuholmen' },
  { postalCode: '68600', city: 'Pietarsaari' },
  { postalCode: '68601', city: 'Pietarsaari' },
  { postalCode: '68607', city: 'Pietarsaari' },
  { postalCode: '68620', city: 'Pietarsaari' },
  { postalCode: '68630', city: 'Pietarsaari' },
  { postalCode: '68660', city: 'Pietarsaari' },
  { postalCode: '68690', city: 'Sundby' },
  { postalCode: '68700', city: 'Teerijärvi' },
  { postalCode: '68701', city: 'Teerijärvi' },
  { postalCode: '68710', city: 'Hästbacka' },
  { postalCode: '68730', city: 'Kortjärvi' },
  { postalCode: '68740', city: 'Högnabba' },
  { postalCode: '68750', city: 'Smċbönders' },
  { postalCode: '68800', city: 'Kolppi' },
  { postalCode: '68801', city: 'Kolppi' },
  { postalCode: '68810', city: 'Ytteresse' },
  { postalCode: '68820', city: 'Ähtävä' },
  { postalCode: '68830', city: 'Bäckby' },
  { postalCode: '68840', city: 'Nederlappfors' },
  { postalCode: '68850', city: 'Jeppo' },
  { postalCode: '68870', city: 'Edsevö' },
  { postalCode: '68910', city: 'Pännäinen' },
  { postalCode: '68911', city: 'Pännäinen' },
  { postalCode: '68920', city: 'Forsby' },
  { postalCode: '68930', city: 'Purmo' },
  { postalCode: '68940', city: 'Lillby' },
  { postalCode: '68970', city: 'Överpurmo' },
  { postalCode: '68999', city: 'Kokkola' },
  { postalCode: '69100', city: 'Kannus' },
  { postalCode: '69101', city: 'Kannus' },
  { postalCode: '69150', city: 'Eskola' },
  { postalCode: '69170', city: 'Yli-Kannus' },
  { postalCode: '69199', city: 'Kannus' },
  { postalCode: '69210', city: 'Roikola' },
  { postalCode: '69240', city: 'Väliviirre' },
  { postalCode: '69300', city: 'Toholampi' },
  { postalCode: '69301', city: 'Toholampi' },
  { postalCode: '69310', city: 'Laitala' },
  { postalCode: '69330', city: 'Määttälä' },
  { postalCode: '69340', city: 'Purontaka' },
  { postalCode: '69410', city: 'Sykäräinen' },
  { postalCode: '69420', city: 'Syri' },
  { postalCode: '69440', city: 'Lestijärvi' },
  { postalCode: '69450', city: 'Yli-Lesti' },
  { postalCode: '69510', city: 'Halsua' },
  { postalCode: '69540', city: 'Marjusaari' },
  { postalCode: '69550', city: 'Kanala' },
  { postalCode: '69570', city: 'Karvonen' },
  { postalCode: '69600', city: 'Kaustinen' },
  { postalCode: '69601', city: 'Kaustinen' },
  { postalCode: '69610', city: 'Nikula' },
  { postalCode: '69620', city: 'Salonkylä' },
  { postalCode: '69660', city: 'Köyhäjoki' },
  { postalCode: '69700', city: 'Veteli' },
  { postalCode: '69701', city: 'Veteli' },
  { postalCode: '69720', city: 'Torppa' },
  { postalCode: '69730', city: 'Tunkkari' },
  { postalCode: '69750', city: 'Sillanpää' },
  { postalCode: '69810', city: 'Isoharju' },
  { postalCode: '69820', city: 'Räyrinki' },
  { postalCode: '69830', city: 'Pulkkinen' },
  { postalCode: '69850', city: 'Patana' },
  { postalCode: '69910', city: 'Kivikangas' },
  { postalCode: '69920', city: 'Oksakoski' },
  { postalCode: '69950', city: 'Perho' },
  { postalCode: '69951', city: 'Perho' },
  { postalCode: '69970', city: 'Salamajärvi' },
  { postalCode: '69980', city: 'Möttönen' },
  { postalCode: '69999', city: 'Kannus' },
  { postalCode: '70007', city: 'Kuopio' },
  { postalCode: '70009', city: 'Kuopio' },
  { postalCode: '70100', city: 'Kuopio' },
  { postalCode: '70101', city: 'Kuopio' },
  { postalCode: '70110', city: 'Kuopio' },
  { postalCode: '70111', city: 'Kuopio' },
  { postalCode: '70150', city: 'Kuopio' },
  { postalCode: '70151', city: 'Kuopio' },
  { postalCode: '70200', city: 'Kuopio' },
  { postalCode: '70201', city: 'Kuopio' },
  { postalCode: '70210', city: 'Kuopio' },
  { postalCode: '70211', city: 'Kuopio' },
  { postalCode: '70240', city: 'Kuopio' },
  { postalCode: '70260', city: 'Kuopio' },
  { postalCode: '70280', city: 'Kuopio' },
  { postalCode: '70300', city: 'Kuopio' },
  { postalCode: '70301', city: 'Kuopio' },
  { postalCode: '70340', city: 'Kuopio' },
  { postalCode: '70341', city: 'Kuopio' },
  { postalCode: '70400', city: 'Kuopio' },
  { postalCode: '70408', city: 'Kuopio leirintä' },
  { postalCode: '70420', city: 'Kuopio' },
  { postalCode: '70421', city: 'Kuopio' },
  { postalCode: '70460', city: 'Kuopio' },
  { postalCode: '70461', city: 'Kuopio' },
  { postalCode: '70500', city: 'Kuopio' },
  { postalCode: '70501', city: 'Kuopio' },
  { postalCode: '70600', city: 'Kuopio' },
  { postalCode: '70601', city: 'Kuopio' },
  { postalCode: '70620', city: 'Kuopio' },
  { postalCode: '70621', city: 'Kuopio' },
  { postalCode: '70700', city: 'Kuopio' },
  { postalCode: '70701', city: 'Kuopio' },
  { postalCode: '70750', city: 'Kuopio' },
  { postalCode: '70780', city: 'Kuopio' },
  { postalCode: '70781', city: 'Kuopio' },
  { postalCode: '70800', city: 'Kuopio' },
  { postalCode: '70801', city: 'Siilinjärvi' },
  { postalCode: '70820', city: 'Kuopio' },
  { postalCode: '70821', city: 'Kuopio' },
  { postalCode: '70870', city: 'Hiltulanlahti' },
  { postalCode: '70871', city: 'Hiltulanlahti' },
  { postalCode: '70900', city: 'Toivala' },
  { postalCode: '70901', city: 'Toivala' },
  { postalCode: '70910', city: 'Vuorela' },
  { postalCode: '70911', city: 'Vuorela' },
  { postalCode: '70940', city: 'Jännevirta' },
  { postalCode: '70941', city: 'Jännevirta' },
  { postalCode: '71130', city: 'Kortejoki' },
  { postalCode: '71150', city: 'Vartiala' },
  { postalCode: '71160', city: 'Riistavesi' },
  { postalCode: '71161', city: 'Riistavesi' },
  { postalCode: '71170', city: 'Laukka-Aho' },
  { postalCode: '71200', city: 'Tuusniemi' },
  { postalCode: '71201', city: 'Tuusniemi' },
  { postalCode: '71210', city: 'Tuusjärvi' },
  { postalCode: '71240', city: 'Paakkila' },
  { postalCode: '71260', city: 'Juurikkamäki' },
  { postalCode: '71270', city: 'Ruskila' },
  { postalCode: '71280', city: 'Kosula' },
  { postalCode: '71310', city: 'Vehmersalmi' },
  { postalCode: '71320', city: 'Enonlahti' },
  { postalCode: '71330', city: 'Räsälä' },
  { postalCode: '71360', city: 'Litmaniemi' },
  { postalCode: '71380', city: 'Mustinlahti' },
  { postalCode: '71460', city: 'Paukarlahti' },
  { postalCode: '71470', city: 'Oravikoski' },
  { postalCode: '71480', city: 'Kurkimäki' },
  { postalCode: '71490', city: 'Airaksela' },
  { postalCode: '71520', city: 'Kaislastenlahti' },
  { postalCode: '71570', city: 'Syvänniemi' },
  { postalCode: '71580', city: 'Koskislahti' },
  { postalCode: '71590', city: 'Punnonmäki' },
  { postalCode: '71610', city: 'Salonkulma' },
  { postalCode: '71630', city: 'Riuttasenpää' },
  { postalCode: '71640', city: 'Talluskylä' },
  { postalCode: '71650', city: 'Hirvilahti' },
  { postalCode: '71660', city: 'Leinolanlahti' },
  { postalCode: '71670', city: 'Kurolanlahti' },
  { postalCode: '71680', city: 'Pulkonkoski' },
  { postalCode: '71690', city: 'Varpasmaa' },
  { postalCode: '71695', city: 'Jylänki' },
  { postalCode: '71720', city: 'Käärmelahti' },
  { postalCode: '71730', city: 'Kinnulanlahti' },
  { postalCode: '71740', city: 'Tavinsalmi' },
  { postalCode: '71745', city: 'Haatala' },
  { postalCode: '71750', city: 'Maaninka' },
  { postalCode: '71760', city: 'Ahkionlahti' },
  { postalCode: '71770', city: 'Vianta' },
  { postalCode: '71775', city: 'Tuovilanlahti' },
  { postalCode: '71780', city: 'Hovinmäki' },
  { postalCode: '71790', city: 'Leppäjoki' },
  { postalCode: '71800', city: 'Siilinjärvi' },
  { postalCode: '71801', city: 'Siilinjärvi' },
  { postalCode: '71807', city: 'Siilinjärvi' },
  { postalCode: '71810', city: 'Aappola' },
  { postalCode: '71820', city: 'Pöljä' },
  { postalCode: '71840', city: 'Kuuslahti' },
  { postalCode: '71850', city: 'Leppäkaarre' },
  { postalCode: '71860', city: 'Jälä' },
  { postalCode: '71870', city: 'Harjamäki' },
  { postalCode: '71880', city: 'Tarinaharju' },
  { postalCode: '71890', city: 'Hamula' },
  { postalCode: '71910', city: 'Alapitkä' },
  { postalCode: '71920', city: 'Pajujärvi' },
  { postalCode: '71930', city: 'Karvasalmi' },
  { postalCode: '71950', city: 'Lammasaho' },
  { postalCode: '71960', city: 'Lukkarila' },
  { postalCode: '71999', city: 'Kuopio' },
  { postalCode: '72100', city: 'Karttula' },
  { postalCode: '72101', city: 'Karttula' },
  { postalCode: '72140', city: 'Itä-Karttula' },
  { postalCode: '72210', city: 'Tervo' },
  { postalCode: '72211', city: 'Tervo' },
  { postalCode: '72220', city: 'Eliaksela' },
  { postalCode: '72300', city: 'Vesanto' },
  { postalCode: '72301', city: 'Vesanto' },
  { postalCode: '72310', city: 'Niinivesi' },
  { postalCode: '72330', city: 'Tiitilänkylä' },
  { postalCode: '72350', city: 'Pienola' },
  { postalCode: '72360', city: 'Närhilä' },
  { postalCode: '72380', city: 'Horontaipale' },
  { postalCode: '72400', city: 'Pielavesi' },
  { postalCode: '72401', city: 'Pielavesi' },
  { postalCode: '72430', city: 'Kumpula' },
  { postalCode: '72480', city: 'Panka' },
  { postalCode: '72490', city: 'Saarinen' },
  { postalCode: '72510', city: 'Jokijärvi' },
  { postalCode: '72530', city: 'Säviäntaipale' },
  { postalCode: '72550', city: 'Säviä' },
  { postalCode: '72570', city: 'Mäntylä' },
  { postalCode: '72600', city: 'Keitele' },
  { postalCode: '72601', city: 'Keitele' },
  { postalCode: '72630', city: 'Kuusela' },
  { postalCode: '72650', city: 'Rajala' },
  { postalCode: '72710', city: 'Vaaraslahti' },
  { postalCode: '72740', city: 'Laukkala' },
  { postalCode: '72810', city: 'Jylhänkylä' },
  { postalCode: '72840', city: 'Saarela' },
  { postalCode: '72880', city: 'Koivujoki' },
  { postalCode: '72930', city: 'Tossavanlahti' },
  { postalCode: '72950', city: 'Porttila' },
  { postalCode: '72980', city: 'Petäjäkylä' },
  { postalCode: '73100', city: 'Lapinlahti' },
  { postalCode: '73101', city: 'Lapinlahti' },
  { postalCode: '73110', city: 'Mäntylahti' },
  { postalCode: '73120', city: 'Nerkoo' },
  { postalCode: '73130', city: 'Taipale' },
  { postalCode: '73140', city: 'Onkilahti' },
  { postalCode: '73160', city: 'Horsmanmäki' },
  { postalCode: '73200', city: 'Varpaisjärvi' },
  { postalCode: '73201', city: 'Varpaisjärvi' },
  { postalCode: '73230', city: 'Syvärinpää' },
  { postalCode: '73250', city: 'Korpijärvi' },
  { postalCode: '73270', city: 'Karsanlahti' },
  { postalCode: '73300', city: 'Nilsiä' },
  { postalCode: '73301', city: 'Nilsiä' },
  { postalCode: '73310', city: 'Tahkovuori' },
  { postalCode: '73350', city: 'Haluna' },
  { postalCode: '73360', city: 'Pajulahti' },
  { postalCode: '73410', city: 'Pieksänkoski' },
  { postalCode: '73420', city: 'Tihvonjärvi' },
  { postalCode: '73460', city: 'Muuruvesi' },
  { postalCode: '73470', city: 'Västinniemi' },
  { postalCode: '73500', city: 'Juankoski' },
  { postalCode: '73501', city: 'Juankoski' },
  { postalCode: '73600', city: 'Kaavi' },
  { postalCode: '73601', city: 'Kaavi' },
  { postalCode: '73610', city: 'Rasilampi' },
  { postalCode: '73620', city: 'Kortteinen' },
  { postalCode: '73630', city: 'Kortteiskylä' },
  { postalCode: '73640', city: 'Sivakkavaara' },
  { postalCode: '73645', city: 'Niinivaara' },
  { postalCode: '73655', city: 'Onnivaara' },
  { postalCode: '73670', city: 'Luikonlahti' },
  { postalCode: '73710', city: 'Viitaniemi' },
  { postalCode: '73730', city: 'Losomäki' },
  { postalCode: '73750', city: 'Pisankoski' },
  { postalCode: '73770', city: 'Säyneinen' },
  { postalCode: '73810', city: 'Palonurmi' },
  { postalCode: '73820', city: 'Raatti' },
  { postalCode: '73830', city: 'Siikajärvi' },
  { postalCode: '73850', city: 'Ala-Luosta' },
  { postalCode: '73860', city: 'Pykälikkö' },
  { postalCode: '73880', city: 'Ala-Siikajärvi' },
  { postalCode: '73890', city: 'Aholansaari' },
  { postalCode: '73900', city: 'Rautavaara' },
  { postalCode: '73901', city: 'Rautavaara' },
  { postalCode: '73970', city: 'Riitasalo' },
  { postalCode: '73990', city: 'Kangaslahti' },
  { postalCode: '74007', city: 'Iisalmi' },
  { postalCode: '74100', city: 'Iisalmi' },
  { postalCode: '74101', city: 'Iisalmi' },
  { postalCode: '74120', city: 'Iisalmi' },
  { postalCode: '74121', city: 'Iisalmi' },
  { postalCode: '74130', city: 'Iisalmi' },
  { postalCode: '74131', city: 'Iisalmi' },
  { postalCode: '74160', city: 'Koljonvirta' },
  { postalCode: '74170', city: 'Soinlahti' },
  { postalCode: '74200', city: 'Vieremä' },
  { postalCode: '74210', city: 'Valkeiskylä' },
  { postalCode: '74230', city: 'Salahmi' },
  { postalCode: '74240', city: 'Marttisenjärvi' },
  { postalCode: '74250', city: 'Nissilä' },
  { postalCode: '74270', city: 'Kauppilanmäki' },
  { postalCode: '74285', city: 'Kainuunmäki' },
  { postalCode: '74300', city: 'Sonkajärvi' },
  { postalCode: '74301', city: 'Sonkajärvi' },
  { postalCode: '74340', city: 'Sukeva' },
  { postalCode: '74341', city: 'Sukeva' },
  { postalCode: '74345', city: 'Kalliosuo' },
  { postalCode: '74360', city: 'Sonkakoski' },
  { postalCode: '74380', city: 'Jyrkkä' },
  { postalCode: '74390', city: 'Laakajärvi' },
  { postalCode: '74420', city: 'Hernejärvi' },
  { postalCode: '74470', city: 'Paloinen' },
  { postalCode: '74490', city: 'Koirakoski' },
  { postalCode: '74510', city: 'Peltosalmi' },
  { postalCode: '74530', city: 'Nerohvirta' },
  { postalCode: '74540', city: 'Kotikylä' },
  { postalCode: '74550', city: 'Pörsänmäki' },
  { postalCode: '74560', city: 'Sourunsalo' },
  { postalCode: '74590', city: 'Kurenpolvi' },
  { postalCode: '74595', city: 'Runni' },
  { postalCode: '74610', city: 'Honkaranta' },
  { postalCode: '74620', city: 'Ryönänjoki' },
  { postalCode: '74630', city: 'Heinäkylä' },
  { postalCode: '74640', city: 'Sulkavanjärvi' },
  { postalCode: '74670', city: 'Rapakkojoki' },
  { postalCode: '74680', city: 'Myllyniemi' },
  { postalCode: '74700', city: 'Kiuruvesi' },
  { postalCode: '74701', city: 'Kiuruvesi' },
  { postalCode: '74720', city: 'Niemiskylä' },
  { postalCode: '74740', city: 'Aittojärvi' },
  { postalCode: '74770', city: 'Kalliokylä' },
  { postalCode: '74790', city: 'Paloharju' },
  { postalCode: '74840', city: 'Koppeloharju' },
  { postalCode: '74860', city: 'Turhala' },
  { postalCode: '74899', city: 'Kiuruvesi' },
  { postalCode: '74940', city: 'Remeskylä' },
  { postalCode: '74980', city: 'Tihilänkangas' },
  { postalCode: '74999', city: 'Iisalmi' },
  { postalCode: '75500', city: 'Nurmes' },
  { postalCode: '75501', city: 'Nurmes' },
  { postalCode: '75507', city: 'Nurmes 7' },
  { postalCode: '75520', city: 'Nurmes' },
  { postalCode: '75530', city: 'Nurmes 3' },
  { postalCode: '75531', city: 'Nurmes 3' },
  { postalCode: '75550', city: 'Bomba' },
  { postalCode: '75570', city: 'Paljakka' },
  { postalCode: '75650', city: 'Savikylä' },
  { postalCode: '75680', city: 'Ylä-Luosta' },
  { postalCode: '75690', city: 'Luostanlinna' },
  { postalCode: '75700', city: 'Valtimo' },
  { postalCode: '75701', city: 'Valtimo' },
  { postalCode: '75710', city: 'Karhunpää' },
  { postalCode: '75740', city: 'Pajukoski' },
  { postalCode: '75770', city: 'Puukari' },
  { postalCode: '75790', city: 'Rumo' },
  { postalCode: '75810', city: 'Hirvikoski' },
  { postalCode: '75840', city: 'Ylä-Valtimo' },
  { postalCode: '75850', city: 'Merilä' },
  { postalCode: '75860', city: 'Sivakka' },
  { postalCode: '75890', city: 'Saramo' },
  { postalCode: '75910', city: 'Imanne' },
  { postalCode: '75920', city: 'Ronkeli' },
  { postalCode: '75930', city: 'Petäiskylä' },
  { postalCode: '75940', city: 'Mujejärvi' },
  { postalCode: '75960', city: 'Kuokanvaara' },
  { postalCode: '75970', city: 'Kohtavaara' },
  { postalCode: '75990', city: 'Höljäkkä' },
  { postalCode: '76007', city: 'Pieksämäki' },
  { postalCode: '76100', city: 'Pieksämäki' },
  { postalCode: '76101', city: 'Pieksämäki' },
  { postalCode: '76120', city: 'Pieksämäki' },
  { postalCode: '76130', city: 'Pieksämäki' },
  { postalCode: '76131', city: 'Pieksämäki' },
  { postalCode: '76140', city: 'Pieksämäki' },
  { postalCode: '76150', city: 'Pieksämäki' },
  { postalCode: '76151', city: 'Pieksämäki' },
  { postalCode: '76280', city: 'Partaharju' },
  { postalCode: '76620', city: 'Pyhitty' },
  { postalCode: '76720', city: 'Meijerhovi' },
  { postalCode: '76780', city: 'Lamminmäki' },
  { postalCode: '76850', city: 'Naarajärvi' },
  { postalCode: '76851', city: 'Naarajärvi' },
  { postalCode: '76940', city: 'Nenonpelto' },
  { postalCode: '77110', city: 'Paltanen' },
  { postalCode: '77120', city: 'Venetmäki' },
  { postalCode: '77140', city: 'Huumala' },
  { postalCode: '77160', city: 'Ilmakka' },
  { postalCode: '77210', city: 'Niskamäki' },
  { postalCode: '77220', city: 'Neuvola' },
  { postalCode: '77240', city: 'Halkokumpu' },
  { postalCode: '77320', city: 'Hurskaala' },
  { postalCode: '77330', city: 'Virtasalmi' },
  { postalCode: '77340', city: 'Hällinmäki' },
  { postalCode: '77350', city: 'Montola' },
  { postalCode: '77360', city: 'Väisälä' },
  { postalCode: '77380', city: 'Kantala' },
  { postalCode: '77430', city: 'Siikamäki' },
  { postalCode: '77460', city: 'Maavesi' },
  { postalCode: '77480', city: 'Savuniemi' },
  { postalCode: '77520', city: 'Haapakoski' },
  { postalCode: '77540', city: 'Kotamäki' },
  { postalCode: '77570', city: 'Jäppilä' },
  { postalCode: '77580', city: 'Tihusniemi' },
  { postalCode: '77600', city: 'Suonenjoki' },
  { postalCode: '77601', city: 'Suonenjoki' },
  { postalCode: '77630', city: 'Lempyy' },
  { postalCode: '77690', city: 'Suontee' },
  { postalCode: '77700', city: 'Rautalampi' },
  { postalCode: '77701', city: 'Rautalampi' },
  { postalCode: '77760', city: 'Sulasalmi' },
  { postalCode: '77770', city: 'Myhinpää' },
  { postalCode: '77777', city: 'Korvatunturi' },
  { postalCode: '77800', city: 'Iisvesi' },
  { postalCode: '77899', city: 'Suonenjoki' },
  { postalCode: '77910', city: 'Vaajasalmi' },
  { postalCode: '77930', city: 'Kerkonjoensuu' },
  { postalCode: '77960', city: 'Pakarila' },
  { postalCode: '77980', city: 'Istunmäki' },
  { postalCode: '77999', city: 'Pieksämäki' },
  { postalCode: '78007', city: 'Varkaus' },
  { postalCode: '78200', city: 'Varkaus' },
  { postalCode: '78201', city: 'Varkaus' },
  { postalCode: '78210', city: 'Varkaus' },
  { postalCode: '78211', city: 'Varkaus' },
  { postalCode: '78250', city: 'Varkaus' },
  { postalCode: '78251', city: 'Varkaus' },
  { postalCode: '78300', city: 'Varkaus' },
  { postalCode: '78310', city: 'Varkaus' },
  { postalCode: '78400', city: 'Varkaus' },
  { postalCode: '78480', city: 'Timola' },
  { postalCode: '78500', city: 'Varkaus' },
  { postalCode: '78610', city: 'Varkaus' },
  { postalCode: '78710', city: 'Varkaus' },
  { postalCode: '78850', city: 'Varkaus' },
  { postalCode: '78870', city: 'Varkaus' },
  { postalCode: '78880', city: 'Kuvansi' },
  { postalCode: '78890', city: 'Akonlahti' },
  { postalCode: '78900', city: 'Varkaus' },
  { postalCode: '79100', city: 'Leppävirta' },
  { postalCode: '79101', city: 'Leppävirta' },
  { postalCode: '79130', city: 'Sorsakoski' },
  { postalCode: '79140', city: 'Kotalahti' },
  { postalCode: '79150', city: 'Konnuslahti' },
  { postalCode: '79160', city: 'Saamaiskylä' },
  { postalCode: '79180', city: 'Tuppurinmäki' },
  { postalCode: '79190', city: 'Valkeamäki' },
  { postalCode: '79230', city: 'Moninmäki' },
  { postalCode: '79255', city: 'Kurjala' },
  { postalCode: '79265', city: 'Puponmäki' },
  { postalCode: '79290', city: 'Leppämäki' },
  { postalCode: '79330', city: 'Näädänmaa' },
  { postalCode: '79340', city: 'Huovilansalmi' },
  { postalCode: '79350', city: 'Itä-Soisalo' },
  { postalCode: '79400', city: 'Kuopio' },
  { postalCode: '79410', city: 'Niinimäki' },
  { postalCode: '79480', city: 'Kangaslampi' },
  { postalCode: '79510', city: 'Kurenlahti' },
  { postalCode: '79520', city: 'Viljolahti' },
  { postalCode: '79530', city: 'Hevonlahti' },
  { postalCode: '79600', city: 'Joroinen' },
  { postalCode: '79601', city: 'Joroinen' },
  { postalCode: '79620', city: 'Huutokoski' },
  { postalCode: '79630', city: 'Kolma' },
  { postalCode: '79650', city: 'Kerisalonsaari' },
  { postalCode: '79660', city: 'Kerisalo' },
  { postalCode: '79680', city: 'Lahnalahti' },
  { postalCode: '79690', city: 'Kaitainen' },
  { postalCode: '79700', city: 'Heinävesi' },
  { postalCode: '79701', city: 'Heinävesi' },
  { postalCode: '79710', city: 'Heinävesi As' },
  { postalCode: '79720', city: 'Säynetkoski' },
  { postalCode: '79750', city: 'Koivumäki' },
  { postalCode: '79770', city: 'Rummukkala' },
  { postalCode: '79780', city: 'Malkkila' },
  { postalCode: '79810', city: 'Karvion Kanava' },
  { postalCode: '79820', city: 'Varistaival' },
  { postalCode: '79830', city: 'Palokki' },
  { postalCode: '79850', city: 'Uusi-Valamo' },
  { postalCode: '79860', city: 'Suuraho' },
  { postalCode: '79870', city: 'Juojärvi' },
  { postalCode: '79880', city: 'Lepikkomäki' },
  { postalCode: '79885', city: 'Eteläpetruma' },
  { postalCode: '79895', city: 'Sarvikumpu' },
  { postalCode: '79899', city: 'Leppävirta' },
  { postalCode: '79910', city: 'Kerma' },
  { postalCode: '79940', city: 'Vihtari' },
  { postalCode: '79970', city: 'Poikiinaho' },
  { postalCode: '79999', city: 'Varkaus' },
  { postalCode: '80007', city: 'Joensuu' },
  { postalCode: '80100', city: 'Joensuu' },
  { postalCode: '80101', city: 'Joensuu' },
  { postalCode: '80110', city: 'Joensuu' },
  { postalCode: '80120', city: 'Joensuu' },
  { postalCode: '80130', city: 'Joensuu' },
  { postalCode: '80131', city: 'Joensuu' },
  { postalCode: '80140', city: 'Joensuu' },
  { postalCode: '80141', city: 'Joensuu' },
  { postalCode: '80150', city: 'Joensuu' },
  { postalCode: '80160', city: 'Joensuu' },
  { postalCode: '80161', city: 'Joensuu' },
  { postalCode: '80170', city: 'Joensuu' },
  { postalCode: '80200', city: 'Joensuu' },
  { postalCode: '80201', city: 'Joensuu' },
  { postalCode: '80210', city: 'Joensuu' },
  { postalCode: '80220', city: 'Joensuu' },
  { postalCode: '80230', city: 'Joensuu' },
  { postalCode: '80260', city: 'Joensuu' },
  { postalCode: '80261', city: 'Joensuu' },
  { postalCode: '80330', city: 'Reijola' },
  { postalCode: '80400', city: 'Ylämylly' },
  { postalCode: '80401', city: 'Ylämylly' },
  { postalCode: '80410', city: 'Jyri' },
  { postalCode: '80411', city: 'Jyri' },
  { postalCode: '80420', city: 'Honkalampi' },
  { postalCode: '80510', city: 'Onttola' },
  { postalCode: '80511', city: 'Onttola' },
  { postalCode: '80580', city: 'Kunnasniemi' },
  { postalCode: '80710', city: 'Lehmo' },
  { postalCode: '80770', city: 'Kontiolahti' },
  { postalCode: '80780', city: 'Kontioniemi' },
  { postalCode: '80790', city: 'Kontioranta' },
  { postalCode: '80791', city: 'Kontioranta' },
  { postalCode: '80850', city: 'Paihola' },
  { postalCode: '80910', city: 'Kulho' },
  { postalCode: '81100', city: 'Kontiolahti' },
  { postalCode: '81101', city: 'Kontiolahti' },
  { postalCode: '81120', city: 'Katajaranta' },
  { postalCode: '81130', city: 'Riihiaho' },
  { postalCode: '81140', city: 'Hovi' },
  { postalCode: '81150', city: 'Riihikangas' },
  { postalCode: '81160', city: 'Romppala' },
  { postalCode: '81190', city: 'Heraniemi' },
  { postalCode: '81200', city: 'Eno' },
  { postalCode: '81201', city: 'Eno' },
  { postalCode: '81210', city: 'Louhioja' },
  { postalCode: '81220', city: 'Jakokoski' },
  { postalCode: '81230', city: 'Mönni' },
  { postalCode: '81235', city: 'Lehtoi' },
  { postalCode: '81260', city: 'Ahveninen' },
  { postalCode: '81270', city: 'Paukkaja' },
  { postalCode: '81280', city: 'Uimaharju' },
  { postalCode: '81281', city: 'Uimaharju' },
  { postalCode: '81290', city: 'Ukkola' },
  { postalCode: '81295', city: 'Haapalahti' },
  { postalCode: '81320', city: 'Kuismavaara' },
  { postalCode: '81330', city: 'Luhtapohja' },
  { postalCode: '81350', city: 'Tokrajärvi' },
  { postalCode: '81360', city: 'Sarvinki' },
  { postalCode: '81390', city: 'Revonkylä' },
  { postalCode: '81420', city: 'Tyrjänsaari' },
  { postalCode: '81430', city: 'Kivilahti' },
  { postalCode: '81450', city: 'Huhus' },
  { postalCode: '81460', city: 'Käenkoski' },
  { postalCode: '81470', city: 'Naarva' },
  { postalCode: '81560', city: 'Vuonisjärvi' },
  { postalCode: '81570', city: 'Kelvä' },
  { postalCode: '81580', city: 'Lapalie' },
  { postalCode: '81590', city: 'Vuonislahti' },
  { postalCode: '81640', city: 'Sikovaara' },
  { postalCode: '81650', city: 'Hattuvaara' },
  { postalCode: '81660', city: 'Lehmikylä' },
  { postalCode: '81700', city: 'Lieksa' },
  { postalCode: '81701', city: 'Lieksa' },
  { postalCode: '81707', city: 'Lieksa' },
  { postalCode: '81720', city: 'Lieksa' },
  { postalCode: '81730', city: 'Lieksa' },
  { postalCode: '81750', city: 'Pankakoski' },
  { postalCode: '81770', city: 'Tiensuu' },
  { postalCode: '81810', city: 'Jamali' },
  { postalCode: '81820', city: 'Kylänlahti' },
  { postalCode: '81840', city: 'Viekki' },
  { postalCode: '81850', city: 'Mätäsvaara' },
  { postalCode: '81860', city: 'Viekijärvi' },
  { postalCode: '81899', city: 'Lieksa' },
  { postalCode: '81950', city: 'Pankajärvi' },
  { postalCode: '81970', city: 'Jongunjoki' },
  { postalCode: '81999', city: 'Joensuu' },
  { postalCode: '82110', city: 'Heinävaara' },
  { postalCode: '82120', city: 'Keskijärvi' },
  { postalCode: '82140', city: 'Kiihtelysvaara' },
  { postalCode: '82150', city: 'Palo' },
  { postalCode: '82160', city: 'Oskola' },
  { postalCode: '82165', city: 'Oskolankoski' },
  { postalCode: '82170', city: 'Uskali' },
  { postalCode: '82180', city: 'Huhtilampi' },
  { postalCode: '82190', city: 'Viesimo' },
  { postalCode: '82200', city: 'Hammaslahti' },
  { postalCode: '82201', city: 'Hammaslahti' },
  { postalCode: '82210', city: 'Suhmura' },
  { postalCode: '82220', city: 'Niittylahti' },
  { postalCode: '82250', city: 'Repola' },
  { postalCode: '82255', city: 'Kuusvaara' },
  { postalCode: '82270', city: 'Karjakumpu' },
  { postalCode: '82290', city: 'Nieminen' },
  { postalCode: '82300', city: 'Rääkkylä' },
  { postalCode: '82301', city: 'Rääkkylä' },
  { postalCode: '82310', city: 'Oravisalo' },
  { postalCode: '82315', city: 'Varpasalo' },
  { postalCode: '82320', city: 'Hypönniemi' },
  { postalCode: '82335', city: 'Rasivaara' },
  { postalCode: '82350', city: 'Tikkala' },
  { postalCode: '82360', city: 'Onkamo' },
  { postalCode: '82361', city: 'Onkamo' },
  { postalCode: '82380', city: 'Tolosenmäki' },
  { postalCode: '82395', city: 'Haarajärvi' },
  { postalCode: '82430', city: 'Puhos' },
  { postalCode: '82435', city: 'Kokkoaho' },
  { postalCode: '82440', city: 'Puhossalo' },
  { postalCode: '82450', city: 'Kontiovaara' },
  { postalCode: '82460', city: 'Hummovaara' },
  { postalCode: '82470', city: 'Leinovaara' },
  { postalCode: '82490', city: 'Heinoniemi' },
  { postalCode: '82500', city: 'Kitee' },
  { postalCode: '82501', city: 'Kitee' },
  { postalCode: '82510', city: 'Kiteenlahti' },
  { postalCode: '82530', city: 'Ruunamäki' },
  { postalCode: '82580', city: 'Juurikka' },
  { postalCode: '82590', city: 'Närsäkkälä' },
  { postalCode: '82600', city: 'Tohmajärvi' },
  { postalCode: '82601', city: 'Tohmajärvi' },
  { postalCode: '82610', city: 'Tohmajärvi As' },
  { postalCode: '82650', city: 'Patsola' },
  { postalCode: '82655', city: 'Värtsilä' },
  { postalCode: '82660', city: 'Uusi-Värtsilä' },
  { postalCode: '82670', city: 'Kaurila' },
  { postalCode: '82675', city: 'Niirala' },
  { postalCode: '82685', city: 'Littilä' },
  { postalCode: '82710', city: 'Kovero' },
  { postalCode: '82720', city: 'Huosiovaara' },
  { postalCode: '82725', city: 'Konnunniemi' },
  { postalCode: '82730', city: 'Tuupovaara' },
  { postalCode: '82731', city: 'Tuupovaara' },
  { postalCode: '82750', city: 'Öllölä' },
  { postalCode: '82760', city: 'Hoilola' },
  { postalCode: '82780', city: 'Saarilampi' },
  { postalCode: '82810', city: 'Heinäaho' },
  { postalCode: '82815', city: 'Marjovaara' },
  { postalCode: '82820', city: 'Maukkula' },
  { postalCode: '82830', city: 'Haukivaara' },
  { postalCode: '82840', city: 'Pirttiaho' },
  { postalCode: '82850', city: 'Kinnasniemi' },
  { postalCode: '82855', city: 'Kinnas' },
  { postalCode: '82865', city: 'Kokinvaara' },
  { postalCode: '82870', city: 'Luutalahti' },
  { postalCode: '82880', city: 'Mannervaara' },
  { postalCode: '82900', city: 'Ilomantsi' },
  { postalCode: '82901', city: 'Ilomantsi' },
  { postalCode: '82915', city: 'Sonkaja' },
  { postalCode: '82950', city: 'Kuikkalampi' },
  { postalCode: '82960', city: 'Lehtovaara' },
  { postalCode: '82967', city: 'Hattu' },
  { postalCode: '82980', city: 'Möhkö' },
  { postalCode: '82995', city: 'Nehvonniemi' },
  { postalCode: '82997', city: 'Mutalahti' },
  { postalCode: '83100', city: 'Liperi' },
  { postalCode: '83101', city: 'Liperi' },
  { postalCode: '83130', city: 'Salokylä' },
  { postalCode: '83140', city: 'Puromäki' },
  { postalCode: '83150', city: 'Roukalahti' },
  { postalCode: '83160', city: 'Tutjunniemi' },
  { postalCode: '83250', city: 'Rinteelä' },
  { postalCode: '83320', city: 'Risti' },
  { postalCode: '83330', city: 'Kaatamo' },
  { postalCode: '83340', city: 'Kaarnalampi' },
  { postalCode: '83350', city: 'Ristinpohja' },
  { postalCode: '83400', city: 'Viinijärvi' },
  { postalCode: '83430', city: 'Käsämä' },
  { postalCode: '83450', city: 'Vaivio' },
  { postalCode: '83460', city: 'Harinjärvi' },
  { postalCode: '83480', city: 'Ahonkylä' },
  { postalCode: '83500', city: 'Outokumpu' },
  { postalCode: '83501', city: 'Outokumpu' },
  { postalCode: '83510', city: 'Sievi as.' },
  { postalCode: '83520', city: 'Outokumpu' },
  { postalCode: '83540', city: 'Maarianvaara' },
  { postalCode: '83550', city: 'Kokonvaara' },
  { postalCode: '83620', city: 'Viuruniemi' },
  { postalCode: '83630', city: 'Kuusjärvi' },
  { postalCode: '83660', city: 'Varislahti' },
  { postalCode: '83700', city: 'Polvijärvi' },
  { postalCode: '83701', city: 'Polvijärvi' },
  { postalCode: '83710', city: 'Suojapuro' },
  { postalCode: '83720', city: 'Kuorevaara' },
  { postalCode: '83750', city: 'Sotkuma' },
  { postalCode: '83760', city: 'Sola' },
  { postalCode: '83780', city: 'Horsmanaho' },
  { postalCode: '83815', city: 'Haaralanniemi' },
  { postalCode: '83820', city: 'Mutkanvaara' },
  { postalCode: '83825', city: 'Kinahmo' },
  { postalCode: '83830', city: 'Hukkala' },
  { postalCode: '83835', city: 'Ruvaslahti' },
  { postalCode: '83840', city: 'Saarivaara' },
  { postalCode: '83845', city: 'Sammakkovaara' },
  { postalCode: '83855', city: 'Martonvaara' },
  { postalCode: '83865', city: 'Timovaara' },
  { postalCode: '83870', city: 'Polvela' },
  { postalCode: '83875', city: 'Petrovaara' },
  { postalCode: '83880', city: 'Kajoo' },
  { postalCode: '83900', city: 'Juuka' },
  { postalCode: '83901', city: 'Juuka' },
  { postalCode: '83907', city: 'Juuka 7' },
  { postalCode: '83910', city: 'Paalasmaa' },
  { postalCode: '83915', city: 'Vihtasuo' },
  { postalCode: '83940', city: 'Nunnanlahti' },
  { postalCode: '83950', city: 'Ahmovaara' },
  { postalCode: '83955', city: 'Kopravaara' },
  { postalCode: '83960', city: 'Koli' },
  { postalCode: '83965', city: 'Loma-Koli' },
  { postalCode: '83970', city: 'Ylä-Koli' },
  { postalCode: '83980', city: 'Kuhnusta' },
  { postalCode: '83985', city: 'Tuopanjoki' },
  { postalCode: '84007', city: 'Ylivieska' },
  { postalCode: '84100', city: 'Ylivieska' },
  { postalCode: '84101', city: 'Ylivieska' },
  { postalCode: '84320', city: 'Ylivieska' },
  { postalCode: '84330', city: 'Ylivieska' },
  { postalCode: '84460', city: 'Ylivieska' },
  { postalCode: '84540', city: 'Ylivieska' },
  { postalCode: '84650', city: 'Ylivieska' },
  { postalCode: '84770', city: 'Ylivieska' },
  { postalCode: '84880', city: 'Ylivieska' },
  { postalCode: '85100', city: 'Kalajoki' },
  { postalCode: '85101', city: 'Kalajoki' },
  { postalCode: '85106', city: 'Kalajoen hiekkasärkät' },
  { postalCode: '85110', city: 'Vasankari' },
  { postalCode: '85120', city: 'Metsä' },
  { postalCode: '85140', city: 'Tynkä' },
  { postalCode: '85150', city: 'Typpö' },
  { postalCode: '85160', city: 'Rautio' },
  { postalCode: '85180', city: 'Rahja' },
  { postalCode: '85200', city: 'Alavieska' },
  { postalCode: '85201', city: 'Alavieska' },
  { postalCode: '85210', city: 'Kähtävä' },
  { postalCode: '85230', city: 'Talus' },
  { postalCode: '85240', city: 'Somerkylä' },
  { postalCode: '85310', city: 'Sievi As' },
  { postalCode: '85320', city: 'Markkula' },
  { postalCode: '85340', city: 'Jyrinki' },
  { postalCode: '85410', city: 'Sievi' },
  { postalCode: '85411', city: 'Sievi' },
  { postalCode: '85430', city: 'Koivisto' },
  { postalCode: '85450', city: 'Joenkylä' },
  { postalCode: '85470', city: 'Kiiskilampi' },
  { postalCode: '85500', city: 'Nivala' },
  { postalCode: '85501', city: 'Nivala' },
  { postalCode: '85520', city: 'Korpiranta' },
  { postalCode: '85530', city: 'Haikara' },
  { postalCode: '85540', city: 'Mönkö' },
  { postalCode: '85560', city: 'Ainastalo' },
  { postalCode: '85580', city: 'Karvoskylä' },
  { postalCode: '85620', city: 'Sarja' },
  { postalCode: '85630', city: 'Sarjanahde' },
  { postalCode: '85640', city: 'Maliskylä' },
  { postalCode: '85660', city: 'Ruuskankylä' },
  { postalCode: '85710', city: 'Parkkila' },
  { postalCode: '85730', city: 'Tulppo' },
  { postalCode: '85800', city: 'Haapajärvi' },
  { postalCode: '85801', city: 'Haapajärvi' },
  { postalCode: '85820', city: 'Oksava' },
  { postalCode: '85840', city: 'Kuona' },
  { postalCode: '85860', city: 'Kumiseva' },
  { postalCode: '85880', city: 'Kalakangas' },
  { postalCode: '85900', city: 'Reisjärvi' },
  { postalCode: '85901', city: 'Reisjärvi' },
  { postalCode: '85920', city: 'Tolpankangas' },
  { postalCode: '85930', city: 'Kangaskylä' },
  { postalCode: '85940', city: 'Räisälänmäki' },
  { postalCode: '85980', city: 'Köyhänperä' },
  { postalCode: '85999', city: 'Ylivieska' },
  { postalCode: '86100', city: 'Pyhäjoki' },
  { postalCode: '86101', city: 'Pyhäjoki' },
  { postalCode: '86110', city: 'Parhalahti' },
  { postalCode: '86160', city: 'Pirttikoski' },
  { postalCode: '86170', city: 'Yppäri' },
  { postalCode: '86210', city: 'Petäjäskoski' },
  { postalCode: '86220', city: 'Merijärvi' },
  { postalCode: '86230', city: 'Ylipää' },
  { postalCode: '86240', city: 'Pyhänkoski' },
  { postalCode: '86300', city: 'Oulainen' },
  { postalCode: '86301', city: 'Oulainen' },
  { postalCode: '86310', city: 'Oulaskangas' },
  { postalCode: '86350', city: 'Kilpua' },
  { postalCode: '86360', city: 'Ilveskorpi' },
  { postalCode: '86400', city: 'Vihanti' },
  { postalCode: '86401', city: 'Vihanti' },
  { postalCode: '86440', city: 'Lampinsaari' },
  { postalCode: '86460', city: 'Alpua' },
  { postalCode: '86470', city: 'Lumimetsä' },
  { postalCode: '86480', city: 'Karhukangas' },
  { postalCode: '86510', city: 'Matkaniva' },
  { postalCode: '86530', city: 'Annonen' },
  { postalCode: '86550', city: 'Mieluskylä' },
  { postalCode: '86600', city: 'Haapavesi' },
  { postalCode: '86601', city: 'Haapavesi' },
  { postalCode: '86610', city: 'Humaloja' },
  { postalCode: '86630', city: 'Ainali' },
  { postalCode: '86650', city: 'Kytökylä' },
  { postalCode: '86660', city: 'Joutenniva' },
  { postalCode: '86680', city: 'Vatjusjärvi' },
  { postalCode: '86690', city: 'Karsikas' },
  { postalCode: '86710', city: 'Kärsämäki' },
  { postalCode: '86711', city: 'Kärsämäki' },
  { postalCode: '86750', city: 'Saviselkä' },
  { postalCode: '86760', city: 'Miiluranta' },
  { postalCode: '86770', city: 'Luomajoki' },
  { postalCode: '86790', city: 'Venetpalo' },
  { postalCode: '86800', city: 'Pyhäsalmi' },
  { postalCode: '86801', city: 'Pyhäsalmi' },
  { postalCode: '86810', city: 'Pyhäjärvi' },
  { postalCode: '86820', city: 'Lamminaho' },
  { postalCode: '86840', city: 'Kätkytniemi' },
  { postalCode: '86850', city: 'Hiidenniemi' },
  { postalCode: '86870', city: 'Vesikoski' },
  { postalCode: '86900', city: 'Pyhäkumpu' },
  { postalCode: '86920', city: 'Kuusenmäki' },
  { postalCode: '86980', city: 'Väliaho' },
  { postalCode: '86999', city: 'Oulainen' },
  { postalCode: '87007', city: 'Kajaani' },
  { postalCode: '87100', city: 'Kajaani' },
  { postalCode: '87101', city: 'Kajaani' },
  { postalCode: '87140', city: 'Kajaani' },
  { postalCode: '87150', city: 'Kajaani' },
  { postalCode: '87200', city: 'Kajaani' },
  { postalCode: '87250', city: 'Kajaani' },
  { postalCode: '87300', city: 'Kajaani' },
  { postalCode: '87400', city: 'Kajaani' },
  { postalCode: '87401', city: 'Kajaani' },
  { postalCode: '87500', city: 'Kajaani' },
  { postalCode: '87600', city: 'Kajaani' },
  { postalCode: '87601', city: 'Kajaani' },
  { postalCode: '87700', city: 'Kajaani' },
  { postalCode: '87701', city: 'Kajaani' },
  { postalCode: '87750', city: 'Kajaani' },
  { postalCode: '87830', city: 'Nakertaja' },
  { postalCode: '87850', city: 'Paltaniemi' },
  { postalCode: '87910', city: 'Linnantaus' },
  { postalCode: '87930', city: 'Salmijärvi' },
  { postalCode: '87950', city: 'Kuluntalahti' },
  { postalCode: '87970', city: 'Jormua' },
  { postalCode: '88110', city: 'Korholanmäki' },
  { postalCode: '88120', city: 'Tuhkakylä' },
  { postalCode: '88200', city: 'Otanmäki' },
  { postalCode: '88201', city: 'Otanmäki' },
  { postalCode: '88210', city: 'Vuottolahti' },
  { postalCode: '88250', city: 'Kytökoski' },
  { postalCode: '88260', city: 'Saaresmäki' },
  { postalCode: '88270', city: 'Vuolijoki' },
  { postalCode: '88300', city: 'Paltamo' },
  { postalCode: '88301', city: 'Paltamo' },
  { postalCode: '88310', city: 'Melalahti' },
  { postalCode: '88320', city: 'Hakasuo' },
  { postalCode: '88340', city: 'Manamansalo' },
  { postalCode: '88350', city: 'Kivesjärvi' },
  { postalCode: '88380', city: 'Mieslahti' },
  { postalCode: '88400', city: 'Ristijärvi' },
  { postalCode: '88401', city: 'Ristijärvi' },
  { postalCode: '88420', city: 'Uva' },
  { postalCode: '88440', city: 'Jokikylä' },
  { postalCode: '88460', city: 'Hiisijärvi' },
  { postalCode: '88470', city: 'Kontiomäki' },
  { postalCode: '88490', city: 'Pohjavaara' },
  { postalCode: '88530', city: 'Paakki' },
  { postalCode: '88600', city: 'Sotkamo' },
  { postalCode: '88601', city: 'Sotkamo' },
  { postalCode: '88607', city: 'Sotkamo' },
  { postalCode: '88610', city: 'Vuokatti' },
  { postalCode: '88615', city: 'Vuokatin Urheiluopisto' },
  { postalCode: '88620', city: 'Korholanmäki' },
  { postalCode: '88640', city: 'Ontojoki' },
  { postalCode: '88670', city: 'Juurikkalahti' },
  { postalCode: '88690', city: 'Maanselkä' },
  { postalCode: '88730', city: 'Yli-Vieksi' },
  { postalCode: '88740', city: 'Härmänkylä' },
  { postalCode: '88760', city: 'Iivantiira' },
  { postalCode: '88820', city: 'Katerma' },
  { postalCode: '88899', city: 'Kuhmo' },
  { postalCode: '88900', city: 'Kuhmo' },
  { postalCode: '88901', city: 'Kuhmo' },
  { postalCode: '88910', city: 'Pajakkakylä' },
  { postalCode: '88920', city: 'Vartius' },
  { postalCode: '88925', city: 'Kostamus' },
  { postalCode: '88930', city: 'Lentiira' },
  { postalCode: '88940', city: 'Kuumu' },
  { postalCode: '88950', city: 'Kalliojoki' },
  { postalCode: '88999', city: 'Kajaani' },
  { postalCode: '89110', city: 'Törmämäki' },
  { postalCode: '89140', city: 'Kotila' },
  { postalCode: '89200', city: 'Puolanka' },
  { postalCode: '89201', city: 'Puolanka' },
  { postalCode: '89210', city: 'Puokiovaara' },
  { postalCode: '89320', city: 'Joukokylä' },
  { postalCode: '89330', city: 'Suolijärvi' },
  { postalCode: '89350', city: 'Lylykylä' },
  { postalCode: '89400', city: 'Hyrynsalmi' },
  { postalCode: '89401', city: 'Hyrynsalmi' },
  { postalCode: '89430', city: 'Hoikka' },
  { postalCode: '89440', city: 'Sakara' },
  { postalCode: '89540', city: 'Moisiovaara' },
  { postalCode: '89560', city: 'Karhuvaara' },
  { postalCode: '89600', city: 'Suomussalmi' },
  { postalCode: '89601', city: 'Ämmänsaari' },
  { postalCode: '89605', city: 'Siikaranta' },
  { postalCode: '89607', city: 'Ämmänsaari' },
  { postalCode: '89610', city: 'Jumaliskylä' },
  { postalCode: '89620', city: 'Pitämä' },
  { postalCode: '89640', city: 'Pesiökylä' },
  { postalCode: '89660', city: 'Takalo' },
  { postalCode: '89670', city: 'Näljänkä' },
  { postalCode: '89680', city: 'Vaaranniva' },
  { postalCode: '89730', city: 'Ahjola' },
  { postalCode: '89740', city: 'Kianta' },
  { postalCode: '89750', city: 'Haapovaara' },
  { postalCode: '89760', city: 'Piispajärvi' },
  { postalCode: '89770', city: 'Peranka' },
  { postalCode: '89780', city: 'Kaikkonen' },
  { postalCode: '89800', city: 'Suomussalmi kk' },
  { postalCode: '89830', city: 'Ala-Vuokki' },
  { postalCode: '89840', city: 'Yli-Vuokki' },
  { postalCode: '89920', city: 'Ruhtinansalmi' },
  { postalCode: '89999', city: 'Suomussalmi' },
  { postalCode: '90003', city: 'Oulu' },
  { postalCode: '90007', city: 'Oulu' },
  { postalCode: '90009', city: 'Oulu' },
  { postalCode: '90014', city: 'Oulun yliopisto' },
  { postalCode: '90015', city: 'Oulun kaupunki' },
  { postalCode: '90029', city: 'Oys' },
  { postalCode: '90100', city: 'Oulu' },
  { postalCode: '90101', city: 'Oulu' },
  { postalCode: '90120', city: 'Oulu' },
  { postalCode: '90121', city: 'Oulu' },
  { postalCode: '90140', city: 'Oulu' },
  { postalCode: '90141', city: 'Oulu' },
  { postalCode: '90150', city: 'Oulu' },
  { postalCode: '90151', city: 'Oulu' },
  { postalCode: '90160', city: 'Oulu' },
  { postalCode: '90210', city: 'Oulu' },
  { postalCode: '90220', city: 'Oulu' },
  { postalCode: '90221', city: 'Oulu' },
  { postalCode: '90230', city: 'Oulu' },
  { postalCode: '90231', city: 'Oulu' },
  { postalCode: '90240', city: 'Oulu' },
  { postalCode: '90241', city: 'Oulu' },
  { postalCode: '90250', city: 'Oulu' },
  { postalCode: '90251', city: 'Oulu' },
  { postalCode: '90310', city: 'Oulu' },
  { postalCode: '90360', city: 'Oulu' },
  { postalCode: '90400', city: 'Oulu' },
  { postalCode: '90401', city: 'Oulu' },
  { postalCode: '90420', city: 'Oulu' },
  { postalCode: '90440', city: 'Kempele' },
  { postalCode: '90441', city: 'Kempele' },
  { postalCode: '90450', city: 'Kempele' },
  { postalCode: '90451', city: 'Kempele' },
  { postalCode: '90460', city: 'Oulunsalo' },
  { postalCode: '90461', city: 'Oulunsalo' },
  { postalCode: '90470', city: 'Varjakka' },
  { postalCode: '90480', city: 'Hailuoto' },
  { postalCode: '90500', city: 'Oulu' },
  { postalCode: '90501', city: 'Oulu' },
  { postalCode: '90508', city: 'Oulu leirintä' },
  { postalCode: '90520', city: 'Oulu' },
  { postalCode: '90521', city: 'Oulu' },
  { postalCode: '90530', city: 'Oulu' },
  { postalCode: '90540', city: 'Oulu' },
  { postalCode: '90550', city: 'Oulu' },
  { postalCode: '90551', city: 'Oulu' },
  { postalCode: '90560', city: 'Oulu' },
  { postalCode: '90570', city: 'Oulu' },
  { postalCode: '90571', city: 'Oulu' },
  { postalCode: '90580', city: 'Oulu' },
  { postalCode: '90581', city: 'Oulu' },
  { postalCode: '90600', city: 'Oulu' },
  { postalCode: '90630', city: 'Oulu' },
  { postalCode: '90631', city: 'Oulu' },
  { postalCode: '90650', city: 'Oulu' },
  { postalCode: '90651', city: 'Oulu' },
  { postalCode: '90740', city: 'Lapinkangas' },
  { postalCode: '90800', city: 'Oulu' },
  { postalCode: '90810', city: 'Kiviniemi' },
  { postalCode: '90820', city: 'Kello' },
  { postalCode: '90821', city: 'Kello' },
  { postalCode: '90830', city: 'Haukipudas' },
  { postalCode: '90831', city: 'Haukipudas' },
  { postalCode: '90840', city: 'Haukipudas' },
  { postalCode: '90850', city: 'Martinniemi' },
  { postalCode: '90860', city: 'Halosenniemi' },
  { postalCode: '90900', city: 'Kiiminki' },
  { postalCode: '90901', city: 'Kiiminki' },
  { postalCode: '90910', city: 'Kontio' },
  { postalCode: '90940', city: 'Jääli' },
  { postalCode: '90941', city: 'Jääli' },
  { postalCode: '91100', city: 'Ii' },
  { postalCode: '91101', city: 'Ii' },
  { postalCode: '91108', city: 'Ii leirintä' },
  { postalCode: '91110', city: 'Ii As' },
  { postalCode: '91140', city: 'Olhava' },
  { postalCode: '91150', city: 'Yli-Olhava' },
  { postalCode: '91200', city: 'Yli-Ii' },
  { postalCode: '91201', city: 'Yli-ii' },
  { postalCode: '91210', city: 'Jakkukylä' },
  { postalCode: '91240', city: 'Tannila' },
  { postalCode: '91260', city: 'Pahkakoski' },
  { postalCode: '91270', city: 'Räinä' },
  { postalCode: '91300', city: 'Ylikiiminki' },
  { postalCode: '91310', city: 'Arkala' },
  { postalCode: '91370', city: 'Vepsänkylä' },
  { postalCode: '91410', city: 'Jokirinne' },
  { postalCode: '91430', city: 'Leppiniemi' },
  { postalCode: '91500', city: 'Muhos' },
  { postalCode: '91501', city: 'Muhos' },
  { postalCode: '91510', city: 'Rova' },
  { postalCode: '91560', city: 'Kylmälänkylä' },
  { postalCode: '91600', city: 'Utajärvi' },
  { postalCode: '91601', city: 'Utajärvi' },
  { postalCode: '91620', city: 'Sanginkylä' },
  { postalCode: '91630', city: 'Juorkuna' },
  { postalCode: '91640', city: 'Särkijärvi' },
  { postalCode: '91660', city: 'Ahmas' },
  { postalCode: '91670', city: 'Rokua' },
  { postalCode: '91700', city: 'Vaala' },
  { postalCode: '91701', city: 'Vaala' },
  { postalCode: '91710', city: 'Jylhämä' },
  { postalCode: '91720', city: 'Oterma' },
  { postalCode: '91730', city: 'Kankari' },
  { postalCode: '91740', city: 'Jaalanka' },
  { postalCode: '91750', city: 'Liminpuro' },
  { postalCode: '91760', city: 'Säräisniemi' },
  { postalCode: '91780', city: 'Neittävä' },
  { postalCode: '91790', city: 'Nuojua' },
  { postalCode: '91800', city: 'Tyrnävä' },
  { postalCode: '91801', city: 'Tyrnävä' },
  { postalCode: '91810', city: 'Ängeslevä' },
  { postalCode: '91900', city: 'Liminka' },
  { postalCode: '91901', city: 'Liminka' },
  { postalCode: '91910', city: 'Tupos' },
  { postalCode: '91930', city: 'Ala-Temmes' },
  { postalCode: '91950', city: 'Temmes' },
  { postalCode: '91980', city: 'Lumijoki' },
  { postalCode: '91981', city: 'Lumijoki' },
  { postalCode: '91999', city: 'Oulu' },
  { postalCode: '92007', city: 'Raahe' },
  { postalCode: '92100', city: 'Raahe' },
  { postalCode: '92101', city: 'Raahe' },
  { postalCode: '92120', city: 'Raahe' },
  { postalCode: '92130', city: 'Raahe' },
  { postalCode: '92140', city: 'Pattijoki' },
  { postalCode: '92150', city: 'Raahe' },
  { postalCode: '92160', city: 'Saloinen' },
  { postalCode: '92170', city: 'Raahensalo' },
  { postalCode: '92180', city: 'Lapaluoto' },
  { postalCode: '92210', city: 'Arkkukari' },
  { postalCode: '92220', city: 'Piehinki' },
  { postalCode: '92230', city: 'Mattilanperä' },
  { postalCode: '92240', city: 'Lasikangas' },
  { postalCode: '92250', city: 'Äyrinmäki' },
  { postalCode: '92260', city: 'Kopsa' },
  { postalCode: '92320', city: 'Siikajoki' },
  { postalCode: '92330', city: 'Karinkanta' },
  { postalCode: '92350', city: 'Revonlahti' },
  { postalCode: '92400', city: 'Ruukki' },
  { postalCode: '92401', city: 'Ruukki' },
  { postalCode: '92430', city: 'Paavola' },
  { postalCode: '92440', city: 'Saarikoski' },
  { postalCode: '92450', city: 'Luohua' },
  { postalCode: '92470', city: 'Tuomioja' },
  { postalCode: '92500', city: 'Rantsila' },
  { postalCode: '92501', city: 'Rantsila' },
  { postalCode: '92520', city: 'Sipola' },
  { postalCode: '92530', city: 'Mankila' },
  { postalCode: '92600', city: 'Pulkkila' },
  { postalCode: '92601', city: 'Pulkkila' },
  { postalCode: '92610', city: 'Vorna' },
  { postalCode: '92620', city: 'Piippola' },
  { postalCode: '92640', city: 'Leskelä' },
  { postalCode: '92650', city: 'Laakkola' },
  { postalCode: '92700', city: 'Kestilä' },
  { postalCode: '92701', city: 'Kestilä' },
  { postalCode: '92710', city: 'Kestilä' },
  { postalCode: '92810', city: 'Pelsonsuo' },
  { postalCode: '92830', city: 'Veneheitto' },
  { postalCode: '92910', city: 'Tavastkenkä' },
  { postalCode: '92920', city: 'Ahokylä' },
  { postalCode: '92930', city: 'Pyhäntä' },
  { postalCode: '92931', city: 'Pyhäntä' },
  { postalCode: '93100', city: 'Pudasjärvi' },
  { postalCode: '93101', city: 'Pudasjärvi' },
  { postalCode: '93117', city: 'Pudasjärvi' },
  { postalCode: '93140', city: 'Kipinä' },
  { postalCode: '93145', city: 'Kollaja' },
  { postalCode: '93150', city: 'Uutela' },
  { postalCode: '93160', city: 'Hetejärvi' },
  { postalCode: '93170', city: 'Yli-Kollaja' },
  { postalCode: '93180', city: 'Ikosenniemi' },
  { postalCode: '93187', city: 'Ala-Siurua' },
  { postalCode: '93190', city: 'Yli-Siurua' },
  { postalCode: '93195', city: 'Kokkokylä' },
  { postalCode: '93220', city: 'Livo' },
  { postalCode: '93225', city: 'Pärjänsuo' },
  { postalCode: '93240', city: 'Rytinki' },
  { postalCode: '93250', city: 'Sarajärvi' },
  { postalCode: '93270', city: 'Sotkajärvi' },
  { postalCode: '93277', city: 'Iinattijärvi' },
  { postalCode: '93280', city: 'Syöte' },
  { postalCode: '93290', city: 'Pintamo' },
  { postalCode: '93320', city: 'Ervasti' },
  { postalCode: '93340', city: 'Siivikko' },
  { postalCode: '93350', city: 'Jaurakkajärvi' },
  { postalCode: '93390', city: 'Puhoskylä' },
  { postalCode: '93400', city: 'Taivalkoski' },
  { postalCode: '93401', city: 'Taivalkoski' },
  { postalCode: '93420', city: 'Jurmu' },
  { postalCode: '93440', city: 'Loukusa' },
  { postalCode: '93470', city: 'Inget' },
  { postalCode: '93540', city: 'Tyrövaara' },
  { postalCode: '93590', city: 'Vanhala' },
  { postalCode: '93600', city: 'Kuusamo' },
  { postalCode: '93601', city: 'Kuusamo' },
  { postalCode: '93607', city: 'Kuusamo' },
  { postalCode: '93608', city: 'Kuusamo leirintä' },
  { postalCode: '93620', city: 'Kuusamo' },
  { postalCode: '93630', city: 'Kuoliovaara' },
  { postalCode: '93640', city: 'Penttilänvaara' },
  { postalCode: '93650', city: 'Purnuvaara' },
  { postalCode: '93670', city: 'Haataja' },
  { postalCode: '93680', city: 'Vasaraperä' },
  { postalCode: '93690', city: 'Rönni' },
  { postalCode: '93695', city: 'Mustosenvaara' },
  { postalCode: '93720', city: 'Kemilä' },
  { postalCode: '93730', city: 'Poussu' },
  { postalCode: '93760', city: 'Murtovaara' },
  { postalCode: '93770', city: 'Kurvisenkylä' },
  { postalCode: '93780', city: 'Teeriranta' },
  { postalCode: '93790', city: 'Irni' },
  { postalCode: '93820', city: 'Aikkila' },
  { postalCode: '93825', city: 'Rukatunturi' },
  { postalCode: '93830', city: 'Kitka' },
  { postalCode: '93840', city: 'Patoniemi' },
  { postalCode: '93850', city: 'Käylä' },
  { postalCode: '93855', city: 'Liikasenvaara' },
  { postalCode: '93860', city: 'Ruvanaho' },
  { postalCode: '93875', city: 'Jyrävä' },
  { postalCode: '93880', city: 'Takkusalmi' },
  { postalCode: '93899', city: 'Pudasjärvi' },
  { postalCode: '93920', city: 'Määttälänvaara' },
  { postalCode: '93940', city: 'Vuotunki' },
  { postalCode: '93980', city: 'Heikkilänniemi' },
  { postalCode: '93990', city: 'Kärpänkylä' },
  { postalCode: '93999', city: 'Kuusamo' },
  { postalCode: '94007', city: 'Kemi' },
  { postalCode: '94100', city: 'Kemi' },
  { postalCode: '94101', city: 'Kemi' },
  { postalCode: '94130', city: 'Kemi' },
  { postalCode: '94200', city: 'Kemi' },
  { postalCode: '94300', city: 'Kemi' },
  { postalCode: '94400', city: 'Keminmaa' },
  { postalCode: '94401', city: 'Keminmaa' },
  { postalCode: '94430', city: 'Kaakamo' },
  { postalCode: '94450', city: 'Keminmaa 2' },
  { postalCode: '94451', city: 'Keminmaa 2' },
  { postalCode: '94500', city: 'Lautiosaari' },
  { postalCode: '94600', city: 'Kemi' },
  { postalCode: '94601', city: 'Kemi' },
  { postalCode: '94700', city: 'Kemi' },
  { postalCode: '94701', city: 'Kemi' },
  { postalCode: '94720', city: 'Kemi' },
  { postalCode: '94800', city: 'Kemi' },
  { postalCode: '94830', city: 'Kemi' },
  { postalCode: '94831', city: 'Kemi' },
  { postalCode: '94900', city: 'Kemi' },
  { postalCode: '95100', city: 'Kuivaniemi' },
  { postalCode: '95101', city: 'Kuivaniemi' },
  { postalCode: '95110', city: 'Kuivaniemi Kk' },
  { postalCode: '95120', city: 'Myllykangas' },
  { postalCode: '95130', city: 'Hyryoja' },
  { postalCode: '95140', city: 'Luola-Aapa' },
  { postalCode: '95160', city: 'Oijärvi' },
  { postalCode: '95170', city: 'Lamminperä' },
  { postalCode: '95180', city: 'Ruohola' },
  { postalCode: '95200', city: 'Simo' },
  { postalCode: '95201', city: 'Simo' },
  { postalCode: '95210', city: 'Filpus' },
  { postalCode: '95220', city: 'Simoniemi' },
  { postalCode: '95225', city: 'Viantie' },
  { postalCode: '95230', city: 'Maksniemi' },
  { postalCode: '95240', city: 'Vilmi' },
  { postalCode: '95250', city: 'Soikko' },
  { postalCode: '95255', city: 'Alaniemi' },
  { postalCode: '95260', city: 'Tainionjoki' },
  { postalCode: '95270', city: 'Yli-Kärppä' },
  { postalCode: '95280', city: 'Näverrys' },
  { postalCode: '95285', city: 'Yli-Hosio' },
  { postalCode: '95290', city: 'Leppiaho' },
  { postalCode: '95295', city: 'Rovastinaho' },
  { postalCode: '95300', city: 'Tervola' },
  { postalCode: '95301', city: 'Tervola' },
  { postalCode: '95310', city: 'Liedakkala' },
  { postalCode: '95315', city: 'Törmä' },
  { postalCode: '95325', city: 'Paakkola' },
  { postalCode: '95330', city: 'Kaisajoki' },
  { postalCode: '95335', city: 'Varejoki' },
  { postalCode: '95340', city: 'Loue' },
  { postalCode: '95350', city: 'Peura' },
  { postalCode: '95355', city: 'Koivu' },
  { postalCode: '95360', city: 'Ilmola' },
  { postalCode: '95365', city: 'Maula' },
  { postalCode: '95370', city: 'Itäkoski' },
  { postalCode: '95375', city: 'Yli-Paakkola' },
  { postalCode: '95400', city: 'Tornio' },
  { postalCode: '95401', city: 'Tornio' },
  { postalCode: '95407', city: 'Tornio' },
  { postalCode: '95410', city: 'Kiviranta' },
  { postalCode: '95420', city: 'Tornio' },
  { postalCode: '95421', city: 'Tornio' },
  { postalCode: '95430', city: 'Tornio' },
  { postalCode: '95440', city: 'Kyläjoki' },
  { postalCode: '95450', city: 'Tornio' },
  { postalCode: '95470', city: 'Pirkkiö' },
  { postalCode: '95490', city: 'Röyttä' },
  { postalCode: '95500', city: 'Vojakkala' },
  { postalCode: '95520', city: 'Kukkola' },
  { postalCode: '95530', city: 'Karunki' },
  { postalCode: '95540', city: 'Korpikylä' },
  { postalCode: '95560', city: 'Aapajoki' },
  { postalCode: '95565', city: 'Kourilehto' },
  { postalCode: '95580', city: 'Apt 4' },
  { postalCode: '95590', city: 'Arpela' },
  { postalCode: '95600', city: 'Ylitornio' },
  { postalCode: '95601', city: 'Ylitornio' },
  { postalCode: '95610', city: 'Kainuunkylä' },
  { postalCode: '95615', city: 'Pekanpää' },
  { postalCode: '95620', city: 'Aavasaksa' },
  { postalCode: '95630', city: 'Kuivakangas' },
  { postalCode: '95635', city: 'Kaulinranta' },
  { postalCode: '95640', city: 'Juoksenki' },
  { postalCode: '95645', city: 'Turtola' },
  { postalCode: '95655', city: 'Etelä-Portimojärvi' },
  { postalCode: '95660', city: 'Kantomaanpää' },
  { postalCode: '95670', city: 'Pessalompolo' },
  { postalCode: '95675', city: 'Meltosjärvi' },
  { postalCode: '95680', city: 'Lohijärvi' },
  { postalCode: '95690', city: 'Mellakoski' },
  { postalCode: '95700', city: 'Pello' },
  { postalCode: '95701', city: 'Pello' },
  { postalCode: '95710', city: 'Naamijoki' },
  { postalCode: '95720', city: 'Jarhoinen' },
  { postalCode: '95730', city: 'Väylänpää' },
  { postalCode: '95750', city: 'Orajärvi' },
  { postalCode: '95760', city: 'Konttajärvi' },
  { postalCode: '95770', city: 'Lankojärvi' },
  { postalCode: '95780', city: 'Sirkkakoski' },
  { postalCode: '95790', city: 'Lampsijärvi' },
  { postalCode: '95793', city: 'Pello' },
  { postalCode: '95800', city: 'Sieppijärvi' },
  { postalCode: '95810', city: 'Sammalvaara' },
  { postalCode: '95830', city: 'Vaattojärvi' },
  { postalCode: '95840', city: 'Venejärvenkylä' },
  { postalCode: '95850', city: 'Ruokojärvi' },
  { postalCode: '95860', city: 'Pasmajärvi' },
  { postalCode: '95900', city: 'Kolari' },
  { postalCode: '95901', city: 'Kolari' },
  { postalCode: '95910', city: 'Lappea' },
  { postalCode: '95920', city: 'Saaripudas' },
  { postalCode: '95930', city: 'Äkäsjoensuu' },
  { postalCode: '95940', city: 'Tapojärvi' },
  { postalCode: '95950', city: 'Kihlanki' },
  { postalCode: '95970', city: 'Äkäslompolo' },
  { postalCode: '95971', city: 'Äkäslompolo' },
  { postalCode: '95980', city: 'Ylläsjärvi' },
  { postalCode: '95990', city: 'Kurtakko' },
  { postalCode: '95999', city: 'Kemi' },
  { postalCode: '96007', city: 'Rovaniemi' },
  { postalCode: '96100', city: 'Rovaniemi' },
  { postalCode: '96101', city: 'Rovaniemi' },
  { postalCode: '96190', city: 'Rovaniemi' },
  { postalCode: '96200', city: 'Rovaniemi' },
  { postalCode: '96201', city: 'Rovaniemi' },
  { postalCode: '96300', city: 'Rovaniemi' },
  { postalCode: '96301', city: 'Rovaniemi' },
  { postalCode: '96320', city: 'Rovaniemi' },
  { postalCode: '96400', city: 'Rovaniemi' },
  { postalCode: '96420', city: 'Rovaniemi' },
  { postalCode: '96440', city: 'Rovaniemi' },
  { postalCode: '96441', city: 'Rovaniemi' },
  { postalCode: '96460', city: 'Rovaniemi' },
  { postalCode: '96500', city: 'Rovaniemi' },
  { postalCode: '96580', city: 'Rovaniemi' },
  { postalCode: '96600', city: 'Rovaniemi' },
  { postalCode: '96700', city: 'Rovaniemi' },
  { postalCode: '96800', city: 'Rovaniemi' },
  { postalCode: '96900', city: 'Saarenkylä' },
  { postalCode: '96910', city: 'Rovaniemi' },
  { postalCode: '96911', city: 'Rovaniemi' },
  { postalCode: '96930', city: 'Napapiiri Rovaniemi' },
  { postalCode: '96960', city: 'Rovaniemi' },
  { postalCode: '96961', city: 'Rovaniemi' },
  { postalCode: '97110', city: 'Rautionsaari' },
  { postalCode: '97130', city: 'Hirvas' },
  { postalCode: '97140', city: 'Muurola' },
  { postalCode: '97145', city: 'Totonvaara' },
  { postalCode: '97160', city: 'Petäjäinen' },
  { postalCode: '97170', city: 'Jaatila' },
  { postalCode: '97190', city: 'Pisa' },
  { postalCode: '97220', city: 'Sinettä' },
  { postalCode: '97240', city: 'Sonka' },
  { postalCode: '97250', city: 'Raanujärvi' },
  { postalCode: '97270', city: 'Romakkaniemi' },
  { postalCode: '97280', city: 'Tapio' },
  { postalCode: '97290', city: 'Marraskoski' },
  { postalCode: '97310', city: 'Patokoski' },
  { postalCode: '97320', city: 'Marrasjärvi' },
  { postalCode: '97330', city: 'Rattosjärvi' },
  { postalCode: '97335', city: 'Ruuhivaara' },
  { postalCode: '97340', city: 'Meltaus' },
  { postalCode: '97350', city: 'Jääskö' },
  { postalCode: '97360', city: 'Perttaus' },
  { postalCode: '97370', city: 'Unarin-Luusua' },
  { postalCode: '97380', city: 'Kukasranta' },
  { postalCode: '97390', city: 'Kierinki' },
  { postalCode: '97420', city: 'Lohiniva' },
  { postalCode: '97430', city: 'Taapajärvi' },
  { postalCode: '97440', city: 'Helppi' },
  { postalCode: '97450', city: 'Tuuliharju' },
  { postalCode: '97460', city: 'Paloselkä' },
  { postalCode: '97470', city: 'Alakylä' },
  { postalCode: '97510', city: 'Vikajärvi' },
  { postalCode: '97520', city: 'Nampa' },
  { postalCode: '97530', city: 'Yli-Nampa' },
  { postalCode: '97540', city: 'Tiainen' },
  { postalCode: '97560', city: 'Käyrämö' },
  { postalCode: '97580', city: 'Misi' },
  { postalCode: '97590', city: 'Raajärvi' },
  { postalCode: '97610', city: 'Oikarainen' },
  { postalCode: '97615', city: 'Tennirova' },
  { postalCode: '97620', city: 'Viiri' },
  { postalCode: '97625', city: 'Vanttauskoski' },
  { postalCode: '97630', city: 'Pekkala' },
  { postalCode: '97635', city: 'Juuniemi' },
  { postalCode: '97645', city: 'Juujärvi' },
  { postalCode: '97650', city: 'Juotas' },
  { postalCode: '97655', city: 'Autti' },
  { postalCode: '97660', city: 'Piittisjärvi' },
  { postalCode: '97665', city: 'Pohjasperä' },
  { postalCode: '97667', city: 'Putkimäki' },
  { postalCode: '97670', city: 'Kivitaipale' },
  { postalCode: '97675', city: 'Narkaus' },
  { postalCode: '97680', city: 'Saari-Kämä' },
  { postalCode: '97685', city: 'Siika-Kämä' },
  { postalCode: '97690', city: 'Sulaoja' },
  { postalCode: '97700', city: 'Ranua' },
  { postalCode: '97701', city: 'Ranua' },
  { postalCode: '97715', city: 'Raiskio' },
  { postalCode: '97720', city: 'Yli-Simo' },
  { postalCode: '97740', city: 'Aitakumpu' },
  { postalCode: '97745', city: 'Rousamo' },
  { postalCode: '97750', city: 'Kuha' },
  { postalCode: '97755', city: 'Sääskilahti' },
  { postalCode: '97760', city: 'Saariharju' },
  { postalCode: '97765', city: 'Impiö' },
  { postalCode: '97770', city: 'Petäjäjärvi' },
  { postalCode: '97780', city: 'Kuukasjärvi' },
  { postalCode: '97785', city: 'Kelankylä' },
  { postalCode: '97810', city: 'Suorsa' },
  { postalCode: '97815', city: 'Pernu' },
  { postalCode: '97820', city: 'Perä-Posio' },
  { postalCode: '97840', city: 'Lehtiniemi' },
  { postalCode: '97850', city: 'Ristilä' },
  { postalCode: '97860', city: 'Aittaniemi' },
  { postalCode: '97870', city: 'Jumisko' },
  { postalCode: '97880', city: 'Nolimo' },
  { postalCode: '97890', city: 'Karjalaisenniemi' },
  { postalCode: '97895', city: 'Mourujärvi' },
  { postalCode: '97900', city: 'Posio' },
  { postalCode: '97901', city: 'Posio' },
  { postalCode: '97920', city: 'Suonnankylä' },
  { postalCode: '97925', city: 'Tolva' },
  { postalCode: '97940', city: 'Lohiranta' },
  { postalCode: '97950', city: 'Säikkä' },
  { postalCode: '97960', city: 'Kuloharju' },
  { postalCode: '97965', city: 'Kynsilä' },
  { postalCode: '97970', city: 'Sirniö' },
  { postalCode: '97980', city: 'Anetjärvi' },
  { postalCode: '97999', city: 'Rovaniemi' },
  { postalCode: '98007', city: 'Kemijärvi' },
  { postalCode: '98100', city: 'Kemijärvi' },
  { postalCode: '98101', city: 'Kemijärvi' },
  { postalCode: '98120', city: 'Kemijärvi' },
  { postalCode: '98121', city: 'Kemijärvi' },
  { postalCode: '98220', city: 'Karsimus' },
  { postalCode: '98230', city: 'Luusua' },
  { postalCode: '98260', city: 'Kuusivaara' },
  { postalCode: '98280', city: 'Sammale' },
  { postalCode: '98310', city: 'Kemijärvi' },
  { postalCode: '98330', city: 'Javarus' },
  { postalCode: '98350', city: 'Tapionniemi' },
  { postalCode: '98360', city: 'Vuostimo' },
  { postalCode: '98370', city: 'Vuostimojärvi' },
  { postalCode: '98390', city: 'Autioniemi' },
  { postalCode: '98400', city: 'Isokylä' },
  { postalCode: '98420', city: 'Ryti-Lehtola' },
  { postalCode: '98440', city: 'Kallaanvaara' },
  { postalCode: '98450', city: 'Kostamo' },
  { postalCode: '98470', city: 'Oinaankylä' },
  { postalCode: '98500', city: 'Pelkosenniemi' },
  { postalCode: '98510', city: 'Saunavaara' },
  { postalCode: '98520', city: 'Saukkoaapa' },
  { postalCode: '98530', city: 'Pyhätunturi' },
  { postalCode: '98540', city: 'Kultakero' },
  { postalCode: '98550', city: 'Suvanto' },
  { postalCode: '98560', city: 'Kairala' },
  { postalCode: '98570', city: 'Aapajärvi' },
  { postalCode: '98580', city: 'Luiro' },
  { postalCode: '98600', city: 'Kursu' },
  { postalCode: '98620', city: 'Pahkakumpu' },
  { postalCode: '98630', city: 'Ahvenselkä' },
  { postalCode: '98660', city: 'Salmivaara' },
  { postalCode: '98710', city: 'Joutsijärvi' },
  { postalCode: '98720', city: 'Suomulahti' },
  { postalCode: '98730', city: 'Tonkopuro' },
  { postalCode: '98740', city: 'Morottaja' },
  { postalCode: '98760', city: 'Hirvasvaara' },
  { postalCode: '98770', city: 'Aholanvaara' },
  { postalCode: '98780', city: 'Maaninkavaara' },
  { postalCode: '98790', city: 'Paloperä' },
  { postalCode: '98800', city: 'Savukoski' },
  { postalCode: '98810', city: 'Lunkkaus' },
  { postalCode: '98820', city: 'Värriö' },
  { postalCode: '98830', city: 'Martti' },
  { postalCode: '98840', city: 'Ruuvaoja' },
  { postalCode: '98850', city: 'Kuosku' },
  { postalCode: '98860', city: 'Nousu' },
  { postalCode: '98900', city: 'Salla' },
  { postalCode: '98901', city: 'Salla' },
  { postalCode: '98920', city: 'Kelloselkä' },
  { postalCode: '98930', city: 'Kotalankylä' },
  { postalCode: '98950', city: 'Saija' },
  { postalCode: '98960', city: 'Naruska' },
  { postalCode: '98970', city: 'Onkamojärvi' },
  { postalCode: '98980', city: 'Kallunki' },
  { postalCode: '98995', city: 'Hautajärvi' },
  { postalCode: '98999', city: 'Kemijärvi' },
  { postalCode: '99100', city: 'Kittilä' },
  { postalCode: '99101', city: 'Kittilä' },
  { postalCode: '99110', city: 'Kaukonen' },
  { postalCode: '99120', city: 'Kallo' },
  { postalCode: '99130', city: 'Sirkka' },
  { postalCode: '99131', city: 'Sirkka' },
  { postalCode: '99135', city: 'Rauhala' },
  { postalCode: '99140', city: 'Köngäs' },
  { postalCode: '99150', city: 'Tepasto' },
  { postalCode: '99160', city: 'Lompolo' },
  { postalCode: '99170', city: 'Pulju' },
  { postalCode: '99180', city: 'Rautusjärvi' },
  { postalCode: '99190', city: 'Hanhimaa' },
  { postalCode: '99195', city: 'Pokka' },
  { postalCode: '99210', city: 'Pitkäkumpu' },
  { postalCode: '99220', city: 'Vittakumpu' },
  { postalCode: '99230', city: 'Jeesiöjärvi' },
  { postalCode: '99240', city: 'Kuivasalmi' },
  { postalCode: '99250', city: 'Kiistala' },
  { postalCode: '99270', city: 'Hormakumpu' },
  { postalCode: '99280', city: 'Tepsa' },
  { postalCode: '99290', city: 'Kelontekemä' },
  { postalCode: '99300', city: 'Muonio' },
  { postalCode: '99301', city: 'Muonio' },
  { postalCode: '99310', city: 'Yli-Muonio' },
  { postalCode: '99320', city: 'Kätkäsuvanto' },
  { postalCode: '99330', city: 'Pallastunturi' },
  { postalCode: '99340', city: 'Raattama' },
  { postalCode: '99360', city: 'Kangosjärvi' },
  { postalCode: '99380', city: 'Jerisjärvi' },
  { postalCode: '99400', city: 'Enontekiö' },
  { postalCode: '99401', city: 'Enontekiö' },
  { postalCode: '99410', city: 'Vuontisjärvi' },
  { postalCode: '99420', city: 'Peltovuoma' },
  { postalCode: '99430', city: 'Nunnanen' },
  { postalCode: '99440', city: 'Leppäjärvi' },
  { postalCode: '99450', city: 'Palojoensuu' },
  { postalCode: '99460', city: 'Kuttanen' },
  { postalCode: '99470', city: 'Karesuvanto' },
  { postalCode: '99490', city: 'Kilpisjärvi' },
  { postalCode: '99510', city: 'Raudanjoki' },
  { postalCode: '99520', city: 'Seipäjärvi' },
  { postalCode: '99530', city: 'Vuojärvi' },
  { postalCode: '99540', city: 'Torvinen' },
  { postalCode: '99550', city: 'Aska' },
  { postalCode: '99555', city: 'Luosto' },
  { postalCode: '99560', city: 'Orakylä' },
  { postalCode: '99600', city: 'Sodankylä' },
  { postalCode: '99601', city: 'Sodankylä' },
  { postalCode: '99607', city: 'Sodankylä' },
  { postalCode: '99610', city: 'Siurunmaa' },
  { postalCode: '99620', city: 'Kelujärvi' },
  { postalCode: '99640', city: 'Tanhua' },
  { postalCode: '99645', city: 'Lokka' },
  { postalCode: '99650', city: 'Sattanen' },
  { postalCode: '99655', city: 'Sattasvaara' },
  { postalCode: '99660', city: 'Kersilö' },
  { postalCode: '99665', city: 'Moskuvaara' },
  { postalCode: '99670', city: 'Petkula' },
  { postalCode: '99680', city: 'Yli-kitinen' },
  { postalCode: '99690', city: 'Vuotso' },
  { postalCode: '99695', city: 'Tankavaara' },
  { postalCode: '99710', city: 'Vaalajärvi' },
  { postalCode: '99720', city: 'Riipi' },
  { postalCode: '99740', city: 'Syväjärvi' },
  { postalCode: '99750', city: 'Sassali' },
  { postalCode: '99760', city: 'Uimaniemi' },
  { postalCode: '99770', city: 'Jeesiö' },
  { postalCode: '99790', city: 'Jääkärinkangas' },
  { postalCode: '99800', city: 'Ivalo' },
  { postalCode: '99801', city: 'Ivalo' },
  { postalCode: '99820', city: 'Kaunispää' },
  { postalCode: '99830', city: 'Saariselkä' },
  { postalCode: '99831', city: 'Saariselkä' },
  { postalCode: '99835', city: 'Laanila' },
  { postalCode: '99860', city: 'Nellim' },
  { postalCode: '99870', city: 'Inari' },
  { postalCode: '99871', city: 'Inari' },
  { postalCode: '99880', city: 'Menesjärvi' },
  { postalCode: '99885', city: 'Lemmenjoki' },
  { postalCode: '99890', city: 'Lisma' },
  { postalCode: '99910', city: 'Kaamanen' },
  { postalCode: '99920', city: 'Partakko' },
  { postalCode: '99930', city: 'Sevettijärvi' },
  { postalCode: '99940', city: 'Näätämö' },
  { postalCode: '99941', city: 'Näätämö' },
  { postalCode: '99950', city: 'Karigasniemi' },
  { postalCode: '99951', city: 'Karigasniemi' },
  { postalCode: '99960', city: 'Outakoski' },
  { postalCode: '99970', city: 'Nuvvus' },
  { postalCode: '99980', city: 'Utsjoki' },
  { postalCode: '99981', city: 'Utsjoki' },
  { postalCode: '99990', city: 'Nuorgam' },
  { postalCode: '99999', city: 'Korvatunturi' }
]
