import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleCard from 'Components/collapsibleCard'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import { StyledDropdown } from '../campaigns/SantanderCampaignUserSelect'
import moment from 'moment'
import DatePicker from 'react-datepicker'

const Top8Form = props => {
  const [top8, setTop8] = useState({
    endDate: moment().format(),
    startDate: moment().format()
  })
  const [brands, setBrands] = useState([])
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('LOADING')

  const { Top8ID } = props.match.params

  const fetchBrands = () => {
    Proxies.GET('brands/getAssociatedBrands').then(responseJSON => {
      if (responseJSON.error) return setBrands([])
      let allbrands = responseJSON.map(item => ({ BrandID: item.BrandID, brandName: item.brandName }))
      allbrands.sort((a, b) => a.brandName.localeCompare(b.brandName))
      setBrands(allbrands)
    })
  }

  const fetchProducts = () => {
    setLoading(true)
    Proxies.GET('products/pool').then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) return setBrands([])
      let allProducts = responseJSON.map(item => ({ ProductID: item.ProductID, elementTitle: item.elementTitle }))
      allProducts.sort((a, b) => a.elementTitle.localeCompare(b.elementTitle))
      setProducts(allProducts)
    })
  }

  const handleChange = e => {
    setTop8({
      ...top8,
      [e.target.name]:
        e.target.type === 'checkbox' || e.target.type === 'radio'
          ? e.target.checked
          : e.target.type === 'number' || e.target.type === 'select-one'
          ? Number(e.target.value)
          : e.target.value
    })
  }

  const updateBrands = item => {
    let selectedBrand = brands.map(brand => {
      if (Number(brand.BrandID) === Number(item.value)) brand.checked = item.checked
      return brand
    })
    setBrands(selectedBrand)
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleChange({ target: { name: 'BrandIDs', value: BrandIDs } })
  }

  const updateProducts = item => {
    let selectedProduct = products.map(product => {
      if (Number(product.ProductID) === Number(item.value)) product.checked = item.checked === true && top8?.ProductIDs?.length < 8 ? true : false
      return product
    })
    setProducts(selectedProduct)
    let ProductIDs = selectedProduct.filter(e => !!e.checked).map(e => e.ProductID)
    handleChange({ target: { name: 'ProductIDs', value: ProductIDs } })
  }

  const fetchTop8 = () => {
    setLoading(true)
    Proxies.GET('Top8s/fetch', { Top8ID }).then(responseJSON => {
      if (!responseJSON.error) {
        setTop8(responseJSON)
      }
    })
  }

  const replaceTop8 = () => {
    Proxies.POST(`Top8s/replace`, top8).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('Top8 updated successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(
          `BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another Tax Campaign Highlight`,
          {
            autoClose: 7000
          }
        )
      }
      setStatus('SUCCESS')
    })
  }

  const submitTop8 = () => {
    Proxies.POST(`Top8s/saveMultiple`, [top8]).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('Top8 created successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(
          `BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another Tax Campaign Highlight`,
          {
            autoClose: 7000
          }
        )
      }
      setStatus('SUCCESS')
    })
  }

  useEffect(() => {
    fetchBrands()
    fetchProducts()
    if (Top8ID) {
      fetchTop8()
    }
  }, [])

  const editBrandIDS = item => {
    let selectedBrand = brands.map(brand => {
      for (let i = 0; i < item?.length; i++) {
        if (Number(brand.BrandID) === Number(item[i])) {
          brand.checked = true
          break
        }
      }
      return brand
    })
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleChange({ target: { name: 'BrandIDs', value: BrandIDs } })
  }

  const editProductIDS = item => {
    let selectedProduct = products.map(product => {
      for (let i = 0; i < item?.length; i++) {
        if (Number(product.ProductID) === Number(item[i])) {
          product.checked = true
          break
        }
      }
      return product
    })
    let ProductIDs = selectedProduct.filter(e => !!e.checked).map(e => e.ProductID)
    handleChange({ target: { name: 'ProductIDs', value: ProductIDs } })
  }

  useEffect(() => {
    if (!!Top8ID && brands.length > 0) {
      return editBrandIDS(top8.BrandIDs)
    }
  }, [brands])
  useEffect(() => {
    if (!!Top8ID && products.length > 0) {
      return editProductIDS(top8.ProductIDs)
    }
  }, [products])

  if (status === 'SUCCESS') {
    return <Redirect to='/top8' />
  }

  return loading ? (
    <FontAwesomeIcon icon='spinner' spin />
  ) : (
    <CollapsibleCard startOpen header={<strong>Top 8</strong>}>
      <Form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <FormGroup>
          <Row>
            <Col xs='2'>
              <Label for='BrandID'> Brands </Label>
            </Col>
            <Col xs='10'>
              <StyledDropdown
                required={true}
                data={
                  !brands.length
                    ? {}
                    : {
                        label: 'All',
                        value: 'all',
                        expanded: true,
                        children: brands.map(item => ({
                          label: `${item.BrandID} - ${item.brandName}`,
                          value: item.BrandID,
                          checked: item.checked
                        }))
                      }
                }
                onChange={item => updateBrands(item)}
                keepTreeOnSearch={true}
                keepChildrenOnSearch={true}
                showPartiallySelected={true}
                texts={{
                  placeholder: !brands.length ? 'Ingen brand fundet' : 'Vælg brand',
                  noMatches: 'Ingen brand fundet',
                  labelRemove: 'Fjern'
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs='2'>
              <Label for='ProductID'>Products</Label>
            </Col>
            <Col xs='10'>
              <StyledDropdown
                required={true}
                data={
                  !products.length
                    ? {}
                    : {
                        label: 'All',
                        value: 'all',
                        expanded: true,
                        children: products.map(item => ({
                          label: `${item.ProductID} - ${item.elementTitle}`,
                          value: item.ProductID,
                          checked: item.checked
                        }))
                      }
                }
                onChange={item => updateProducts(item)}
                keepTreeOnSearch={true}
                keepChildrenOnSearch={true}
                showPartiallySelected={true}
                texts={{
                  placeholder: !products.length ? 'Intet produkt fundet' : 'Vælg produkt',
                  noMatches: 'Intet produkt fundet',
                  labelRemove: 'Fjern'
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Start Date</label>
          </Col>
          <Col xs='9'>
            <DatePicker
              className='form-control'
              minDate={moment().add(1, 'days')}
              selected={moment(top8.startDate)}
              dateFormat={'DD/MM/YYYY'}
              viewMode={'days'}
              name='startDate'
              id='startDate'
              onChange={e => {
                handleChange({ target: { name: 'startDate', value: e.format() } })
              }}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>End Date</label>
          </Col>
          <Col xs='10'>
            <DatePicker
              className='form-control'
              minDate={moment().add(1, 'days')}
              selected={moment(top8.endDate)}
              dateFormat={'DD/MM/YYYY'}
              viewMode={'days'}
              name='endDate'
              id='endDate'
              onChange={e => {
                handleChange({ target: { name: 'endDate', value: e.format() } })
              }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            Top8ID ? replaceTop8() : submitTop8()
          }}
        >
          Gem
        </Button>
      </Form>
    </CollapsibleCard>
  )
}

export default Top8Form
