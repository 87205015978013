/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { POST } = Proxies
import strip_tags from 'Components/striptags'
import keyMirror from 'keymirror'
export const STATUS = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  FETCHING_TOKENS: null,
  FETCHING_TOKENS_FAILED: null,
  GENERATE_HASH_KEY_FAILED: null
})

export const SettingsAction = createActions([
  'clearToast',
  'getTokens',
  'generateHash',
  'updatePayload',
  'EmptyPayload',
  'toggleText',
  'copyToClipboard',
  'setTooltip',
  'resetTokens'
])

export class SettingsStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = SettingsAction
    this.timeoutToken
    this.timeoutSalt
    this.state = {
      companyTokenString: '',
      companySaltString: '',
      payload: { algorithm: 'sha256' },
      loadingState: '',
      generatedHashkey: '',
      showTooltip: {
        companyToken: false,
        companySalt: false
      },
      showText: {
        companyToken: false,
        companySalt: false
      }
    }
  }
  clearToast(loadingState) {
    loadingState ? this.setState({ loadingState }) : this.setState({ loadingState: null })
  }
  EmptyPayload() {
    this.setState({
      payload: {}
    })
  }
  getTokens(type, nextState) {
    if (this.state.loadingState === STATUS.FETCHING_TOKENS) {
      return false
    }
    this.setState({ loadingState: STATUS.FETCHING_TOKENS })

    POST('companies/fetch-tokens', { tokenType: type }).then(responseJSON => {
      if (responseJSON.error) {
        nextState.loadingState = STATUS.FETCHING_TOKENS_FAILED
      } else {
        if (type === 'companyToken') {
          nextState.companyTokenString = responseJSON[type]
        } else if (type === 'companySalt') {
          nextState.companySaltString = responseJSON[type]
        }
        nextState.loadingState = STATUS.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }
  generateHash() {
    if (this.state.payload && Object.keys(this.state.payload).length) {
      this.setState({ loadingState: STATUS.IS_SAVING })
      const payload = { hashBody: JSON.parse(this.state.payload.hashBody), algorithm: this.state.payload.algorithm }
      POST('companies/calculate-hash', payload).then(responseJSON => {
        let nextState = {
          payload: this.state.payload
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.loadingState = STATUS.GENERATE_HASH_KEY_FAILED
          } else {
            nextState.generatedHashkey = responseJSON
          }
        }
        this.setState(nextState)
      })
    }
  }
  toggleText(data) {
    this.setTooltip()
    let nextState = {
      companyTokenString: this.state.companyTokenString,
      companySaltString: this.state.companySaltString,
      showText: this.state.showText
    }
    nextState.showText[data] = !nextState.showText[data]
    if (nextState.showText[data]) {
      this.getTokens(data, nextState)
      if (data === 'companyToken') {
        if (this.timeoutToken) {
          clearTimeout(this.timeoutToken)
        }
        this.timeoutToken = setTimeout(() => {
          nextState.showText[data] = false
          this.setState({ nextState })
        }, 60000)
      }
      if (data === 'companySalt') {
        if (this.timeoutSalt) {
          clearTimeout(this.timeoutSalt)
        }
        this.timeoutSalt = setTimeout(() => {
          nextState.showText[data] = false
          this.setState({ nextState })
        }, 60000)
      }
    } else {
      this.setState({ nextState })
    }
  }

  copyToClipboard(id, value) {
    this.setTooltip()
    let showTooltip = this.state.showTooltip
    var range = document.createElement('textarea')
    document.body.appendChild(range)
    range.value = value
    range.select()
    document.execCommand('copy')
    document.body.removeChild(range)
    showTooltip[id] = true
    this.setState({ showTooltip })
  }

  setTooltip() {
    this.setState({ showTooltip: { companySalt: false, companyToken: false } })
  }

  updatePayload(field) {
    let value = strip_tags(field.target.value)
    let payload = this.state.payload
    payload[field.target.name] = value
    this.setState({ payload })
  }

  resetTokens() {
    this.setState({ companySaltString: '', companyTokenString: '', showText: { companyToken: false, companySalt: false } })
  }
}
