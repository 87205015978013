import React, { useEffect } from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { fetchOrderObfuscationSchedule, preventOrderObfuscation } from 'Reducers/actions/dataRetentionActions'
import { updateSelectedOrderIDs, setSelectedOrderIDs, updateSelectedOrderIDsCheckbox } from 'Reducers/dataRetentionReducer'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Input, Button } from 'reactstrap'
import { useState } from 'react'

export default class OrderObfuscationSchedule extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <OrderObfuscationScheduleComponent />
      </React.Fragment>
    )
  }
}

function OrderObfuscationScheduleComponent() {
  const dispatch = useDispatch()
  const [isAllChecked, setAllChecked] = useState(false)
  // const [advanceSearchFilters, setadvanceSearchFilters] = useState([])
  let orderObfuscationSchedulePoolRedux = useSelector(state => state.dataRetention.orderObfuscationSchedulePool)
  let orderObfuscationSchedulePool = orderObfuscationSchedulePoolRedux.slice()
  let selectedOrderIDs = useSelector(state => state.dataRetention.selectedOrderIDs)
  useEffect(() => {
    dispatch(fetchOrderObfuscationSchedule())
  }, [])

  const selectAllOrders = () => {
    if (!isAllChecked) {
      const OrderIDs = orderObfuscationSchedulePoolRedux.map(item => item.OrderID)
      for (const id of OrderIDs) {
        dispatch(updateSelectedOrderIDs(id))
        dispatch(updateSelectedOrderIDsCheckbox(id))
      }

      setAllChecked(true)
    } else {
      dispatch(setSelectedOrderIDs([]))
      setAllChecked(false)
    }
  }

  const preventObfuscation = () => {
    let OrderIDs = []
    OrderIDs = selectedOrderIDs
    dispatch(preventOrderObfuscation(OrderIDs))
  }

  const updateSelection = OrderID => {
    dispatch(updateSelectedOrderIDs(OrderID))
    dispatch(updateSelectedOrderIDsCheckbox(OrderID))
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <React.Fragment>
          <strong>List of Orders</strong>{' '}
          <Button
            className='float-right'
            onClick={() => (confirm('Are you sure to remove the obfuscation scheduled for the selected orders?') ? preventObfuscation() : null)}
          >
            Save selected orders from obfuscation
          </Button>
        </React.Fragment>
      }
    >
      <DynamicTable
        responsive
        size='sm'
        options={{
          isAllChecked: isAllChecked,
          preserveAllChecked: selectAllOrders,
          // advanceSearchColumns: [
          //   { key: 'OrderID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
          //   { key: 'orderUID', name: 'UID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
          //   { key: 'orderName', name: 'Name', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
          //   { key: 'BrandID', name: 'Brand ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
          //   { key: 'brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
          //   { key: 'timeScheduled', name: 'Scheduled time', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] }
          // ],
          // advanceSearchFilters: advanceSearchFilters,
          // preserveAdvanceSearch: setadvanceSearchFilters,
          startSort: {
            dir: 'ASC',
            column: 'timeScheduled'
          },
          fieldFunctions: {
            timeScheduled: e => {
              return e.timeScheduled ? moment(e.timeScheduled).format('DD-MM-YYYY') : ''
            },
            OrderID: e => {
              return (
                <>
                  <Input
                    type='checkbox'
                    id={'checkbox' + e.OrderID}
                    name={'checkbox' + e.OrderID}
                    checked={selectedOrderIDs.includes(e.OrderID)}
                    onChange={() => {
                      updateSelection(e.OrderID)
                    }}
                  />
                  {e.OrderID}
                </>
              )
            }
          }
        }}
        headers={{
          OrderID: 'OrderID',
          brandName: 'Brand Name',
          originBrandID: 'Brand ID',
          orderConsignee: 'User',
          UserID: 'User ID',
          timeScheduled: 'Scheduled Date'
        }}
        data={[...orderObfuscationSchedulePool]}
      />
    </CollapsibleCard>
  )
}
