/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import { Button, ButtonGroup, Col, UncontrolledTooltip as Tooltip, Form, Input, Label, Row, FormGroup } from 'reactstrap'
import FormField from 'Components/formField'
import { BlogStore, BlogActions } from 'Stores/blogStore'
import { LoginStore } from 'Stores/loginStore'

const Blog = props => {
  if (props.create) {
    return <BlogDetails {...props} />
  }
  if (props.match && props.match.params && props.match.params.BlogID) {
    return <BlogDetails {...props} />
  }
  return <BlogList {...props} />
}

export default Blog

class BlogList extends Component {
  overviewHeaders = {
    BlogID: '#',
    moduleName: 'Navn',
    moduleDescription: 'Beskrivelse',
    poolOrder: 'Pool Order',
    //moduleFeatured:"I Feature element",
    groups: 'Grupper'
  }
  constructor(props) {
    super(props)
    this.stores = [BlogStore, LoginStore]
    this.advanceSearchColumns = [
      { key: 'BlogID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'moduleName', name: 'Navn', operators: ['LIKE', 'EXACT'] },
      { key: 'moduleDescription', name: 'Description', operators: ['LIKE', 'EXACT'] },
      { key: 'poolOrder', name: 'Pool Order', operators: ['LIKE', 'EXACT'] },
      { key: 'BrandID', name: 'BrandID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'BrandName', name: 'Brand Name', operators: ['LIKE', 'EXACT'] },
      { key: 'groups', name: 'Groups', operators: ['IN'], operator: 'IN' }
    ]
  }
  componentDidMount() {
    if (this.state.blogs.length == 0) {
      BlogActions.getBlogs()
    }
    if (
      this.state.settings &&
      this.state.settings.userRole.toLowerCase() === 'system' &&
      this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
      this.state.settings.BrandID === 9
    ) {
      this.overviewHeaders.BrandID = 'Brand ID'
      this.overviewHeaders.BrandName = 'Brand Name'
    }
  }
  render() {
    if (this.state.fetchingBlogs) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Blogs
            {this.state.settings &&
              this.state.settings.userRole.toLowerCase() === 'system' &&
              this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
              this.state.settings.BrandID === 9 && (
                <ButtonGroup className='float-right'>
                  <Button outline color='success' onClick={BlogActions.toggleActiveBlog} active={this.state.showActiveBlogs}>
                    Active
                  </Button>
                  <Button outline color='danger' onClick={BlogActions.toggleInActiveBlog} active={this.state.showInActiveBlogs}>
                    In-Active
                  </Button>
                </ButtonGroup>
              )}
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={
            this.state.blogs &&
            !!this.state.blogs.length &&
            this.state.settings &&
            this.state.settings.userRole.toLowerCase() === 'system' &&
            this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
            this.state.settings.BrandID === 9 &&
            this.state.blogFilterValue !== 'both'
              ? this.state.blogs.filter(blog => blog.removed === (this.state.blogFilterValue === 'inactive' ? 1 : 0))
              : this.state.blogs
          }
          headers={this.overviewHeaders}
          options={{
            ...(this.state.settings &&
              this.state.settings.userRole.toLowerCase() === 'system' &&
              this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
              this.state.settings.BrandID === 9 && {
                advanceSearchFilters: this.state.advanceSearchFilters,
                advanceSearchColumns: this.advanceSearchColumns,
                preserveAdvanceSearch: BlogActions.preserveAdvanceSearch
              }),
            startSort: {
              column: 'BlogID'
            },
            unsortable: ['groups'],
            fieldStringFunctions: {
              groups: e => {
                let groups = e.groups ? e.groups : []
                let groupNames = groups.map(item => {
                  return item.moduleName
                })
                return groupNames.toString()
              }
            },
            fieldFunctions: {
              groups: e => {
                let groups = e.groups ? e.groups : []
                return (
                  <React.Fragment>
                    {groups.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          {e.moduleName}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )
              }
            },
            fieldExportFunctions: {
              groups: e => {
                let groups = e.groups ? e.groups : []
                let acc = []
                {
                  groups &&
                    groups.map(e => {
                      acc.push(e.moduleName)
                    })
                }
                return acc.join('\n')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.BlogID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/blog/' + e.BlogID} id={'edit' + e.BlogID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>

                    <Tooltip target={'copy' + e.BlogID}>Kopier</Tooltip>
                    <Button
                      id={'copy' + e.BlogID}
                      onClick={() => {
                        BlogActions.copyBlog(e, this.props)
                      }}
                    >
                      <FontAwesomeIcon icon='copy' />
                    </Button>

                    <Tooltip target={'posts' + e.BlogID}>Poster</Tooltip>
                    <Button tag={Link} to={'/blog/' + e.BlogID + '/posts'} id={'posts' + e.BlogID}>
                      <FontAwesomeIcon icon='newspaper' />
                    </Button>

                    {e.removed === 0 && (
                      <React.Fragment>
                        <Tooltip target={'delete' + e.BlogID}>Deaktivér</Tooltip>
                        <Button
                          id={'delete' + e.BlogID}
                          onClick={() => {
                            if (confirm('Vil du deaktivere denne blog?')) {
                              BlogActions.deleteBlog(e.BlogID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-check' />
                        </Button>
                      </React.Fragment>
                    )}
                    {e.removed === 1 && (
                      <React.Fragment>
                        <Tooltip target={'enable' + e.BlogID}>Aktivér</Tooltip>
                        <Button
                          id={'enable' + e.BlogID}
                          color='danger'
                          onClick={() => {
                            if (confirm('Vil du aktivere denne blog?')) {
                              BlogActions.enableBlog(e.BlogID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-slash' />
                        </Button>
                      </React.Fragment>
                    )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}

class BlogDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [BlogStore, LoginStore]
    this.resetBlog = this.resetBlog.bind(this)
  }
  componentWillMount() {
    super.componentWillMount()
    this.resetBlog()
  }
  resetBlog() {
    if (this.props.create) {
      let queryString = window.location.search
      let params = new URLSearchParams(queryString)
      let cloneFromBlogID = params.get('copy')
      if (cloneFromBlogID) {
        BlogActions.getBlogDetails(cloneFromBlogID, true)
      } else {
        BlogActions.createEmptyBlog()
      }
    } else {
      BlogActions.getBlogDetails(this.props.match.params.BlogID)
    }
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.userRole.toLowerCase() === 'system' &&
      this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
      this.state.settings.BrandID === 9
    ) {
      BlogActions.getBrands()
    } else if (this.state.groups.length == 0 && !this.state.fetchingGroups) {
      BlogActions.getGroups()
    }
  }
  componentDidUpdate() {
    if (this.state.showBlogError) {
      if (this.props.create) {
        toast.error('Der opstod en fejl i oprettelsen af bloggen', {
          autoClose: 7500,
          onClose: () => {
            BlogActions.clearToast()
          }
        })
      } else {
        toast.error('Der opstod en fejl ved opdateringen af bloggen', {
          autoClose: 7500,
          onClose: () => {
            BlogActions.clearToast()
          }
        })
      }
      BlogActions.clearToast()
    }
    if (this.state.showBlogSuccess) {
      if (this.props.create) {
        toast.success('Bloggen er oprettet', {
          autoClose: 7500,
          onClose: () => {
            BlogActions.clearToast()
          }
        })
      } else {
        toast.success('Dine ændringer er gemt', {
          autoClose: 7500,
          onClose: () => {
            BlogActions.clearToast()
          }
        })
      }
      BlogActions.clearToast()
      if (this.BlogDetailRef) {
        window.scrollTo(0, this.BlogDetailRef.offsetTop)
      }
    }
  }
  render() {
    if (this.state.fetchingBlogs) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    /** below lines interfere with clone-blog feature */
    // if (this.state.blogDetails.BlogID && this.props.create) {
    //   return <Redirect to={'/blog/' + this.state.blogDetails.BlogID} />
    // }
    let groups = []
    const groupList =
      this.state.filteredGroupData && this.state.blogDetails.groupFilterText && this.state.blogDetails.groupFilterText.length
        ? this.state.filteredGroupData
        : this.state.groups
    let blogGroups = this.state.blogDetails.groups ? this.state.blogDetails.groups.concat([]) : []
    groupList.forEach((e, i) => {
      let isCheckedData = blogGroups.find(s => {
        return s.GroupID == e.GroupID
      })
      let isChecked = isCheckedData !== undefined
      if (isChecked) {
        blogGroups = blogGroups.filter(s => {
          return s.GroupID != e.GroupID
        })
      }
      groups.push(
        <React.Fragment key={i}>
          <Input type='checkbox' checked={isChecked} name={e.GroupID} onChange={BlogActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
          <br />
        </React.Fragment>
      )
    })
    if (blogGroups.length > 0) {
      groups.push(
        <React.Fragment key={'overflow'}>
          Følgende grupper skal gendannes via databasen hvis de slettes
          <br />
        </React.Fragment>
      )
      blogGroups.forEach((e, i) => {
        groups.push(
          <React.Fragment key={'overflow' + i}>
            <Input type='checkbox' checked={true} name={e.GroupID} onChange={BlogActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    return (
      <CollapsibleCard startOpen header={<strong>{this.props.create ? 'Opret' : 'Detaljer'}</strong>} ref={ref => (this.BlogDetailRef = ref)}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField name='Navn' field='moduleName' data={this.state.blogDetails} onChange={BlogActions.updateBlogField} required />
          <FormField name='Beskrivelse' field='moduleDescription' data={this.state.blogDetails} onChange={BlogActions.updateBlogField} />
          <FormGroup>
            <Row>
              <Col xs='3'>
                <Label for='blogType'> Type </Label>
              </Col>
              <Col xs='6'>
                <Input
                  type='select'
                  name='blogType'
                  value={this.state.blogDetails.blogType ? this.state.blogDetails.blogType : 'NEWS'}
                  id='blogType'
                  onChange={BlogActions.updateBlogField}
                >
                  {this.state.blogTypes.map((e, i) => {
                    return (
                      <option value={e} key={i}>
                        {e}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormField
            className='zero-margin-left'
            name='Featured'
            field='moduleFeatured'
            data={this.state.blogDetails}
            onChange={BlogActions.updateBlogField}
            type='checkbox'
            checkedValue={Number(1)}
            unCheckedValue={Number(0)}
          />
          {this.state.settings &&
            this.state.settings.userRole.toLowerCase() === 'system' &&
            this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
            this.state.settings.BrandID === 9 && (
              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='BrandID'> Brand ID </Label>
                  </Col>
                  <Col xs='6'>
                    <Input
                      className={this.state.blogDetails.BrandID ? 'is-valid' : 'is-invalid'}
                      required
                      type='select'
                      name='BrandID'
                      value={this.state.blogDetails.BrandID ? this.state.blogDetails.BrandID : ''}
                      id='BrandID'
                      onChange={e => {
                        BlogActions.updateBlogField(e)
                        BlogActions.getLanguages(e.target.value)
                      }}
                    >
                      {!this.state.blogDetails.BrandID && <option value=''>Click to Select</option>}
                      {this.state.brands.map((e, i) => {
                        return (
                          <option value={e.BrandID} key={i}>
                            {e.BrandID} - {e.brandName}
                          </option>
                        )
                      })}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            )}
          {!!this.state.blogDetails.BrandID && !!this.state.languages && !!(this.state.languages.length > 1) && (
            <FormGroup row>
              <Label for='textColor' sm={3}>
                Translations
              </Label>
              <Col sm={9}>
                {!!this.state.blogDetails &&
                  !!this.state.blogDetails.translations &&
                  !!this.state.blogDetails.translations.length &&
                  this.state.blogDetails.translations.map((translation, inx) => {
                    return (
                      !translation.removed && (
                        <div key={inx}>
                          <Row>
                            <Col sm={11}>
                              <Input
                                type='select'
                                name='LanguageID'
                                value={translation.LanguageID ? Number(translation.LanguageID) : ''}
                                id='LanguageID'
                                onChange={e => BlogActions.updateData(e, inx, 'translations')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {!!this.state.languages &&
                                  !!this.state.languages.length &&
                                  this.state.languages.map((e, i) => {
                                    return (
                                      <option value={Number(e.LanguageID)} key={i}>
                                        {e.languageName}
                                      </option>
                                    )
                                  })}
                              </Input>
                            </Col>
                            <Col sm={1}>
                              <Button onClick={() => BlogActions.removeTranslation(inx)} color={'danger'}>
                                <FontAwesomeIcon icon={'minus'} />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Label for='textColor' sm={2}>
                              Navn
                            </Label>
                            <Col sm={9}>
                              <Input
                                name='moduleName'
                                value={translation.moduleName}
                                type='text'
                                onChange={e => BlogActions.updateData(e, inx, 'translations')}
                              />
                            </Col>
                            <Col sm={1}>
                              <Button onClick={BlogActions.addTranslation}>
                                <FontAwesomeIcon icon='plus' />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Label for='textColor' sm={2}>
                              Beskrivelse
                            </Label>
                            <Col sm={10}>
                              <Input
                                name='moduleDescription'
                                type='textarea'
                                value={translation.moduleDescription}
                                onChange={e => BlogActions.updateData(e, inx, 'translations')}
                              />
                            </Col>
                          </Row>
                          <br></br>
                        </div>
                      )
                    )
                  })}
              </Col>
            </FormGroup>
          )}
          <FormField
            fieldWidth={6}
            type='number'
            name='Pool Order'
            field='poolOrder'
            data={this.state.blogDetails}
            onChange={BlogActions.updateBlogField}
          />

          {groups.length > 0 && (
            <FormField name='Grupper' field='groups' data={{}}>
              <Row>
                <Col xs='2'>
                  <Label for='groupFilterHead'>Søg Grupper</Label>
                </Col>
                <Col xs='5'>
                  <Input
                    type='text'
                    value={this.state.blogDetails.groupFilterText}
                    name='groupFilterText'
                    id='groupFilterText'
                    onChange={BlogActions.updateBlogField}
                  />
                </Col>
              </Row>
              <React.Fragment>
                <Col className='max-height-300 overflow-y-scroll padding-30 margin-top-30'>{groups}</Col>
              </React.Fragment>
            </FormField>
          )}
          <Button onClick={BlogActions.saveBlog} disabled={this.state.isSaving}>
            {this.state.isSaving ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={this.resetBlog} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    )
  }
}
