import { createAction } from '@reduxjs/toolkit'
import Proxies from 'Components/proxies'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  LOADING_DATA: null,
  LOADING_DATA_SUCCESS: null,
  LOADING_DATA_FAILURE: null,
  KAM_CVR_USERS_LOADING_DATA: null,
  KAM_CVR_USERS_LOADING_DATA_SUCCESS: null,
  KAM_CVR_USERS_LOADING_DATA_FAILURE: null,
  KAM_COMPANY_PRODUCTS_LOADING: null,
  KAM_COMPANY_PRODUCTS_LOADING_SUCCESS: null,
  KAM_COMPANY_PRODUCTS_LOADING_FAILURE: null
})

export const fetchKamProductDetailsRequest = createAction('fetchKamProductDetailsRequest')
export const fetchKamProductDetailsSuccess = createAction('fetchKamProductDetailsSuccess')
export const fetchKamProductDetailsFailure = createAction('fetchKamProductDetailsFailure')
export const fetchKamProductDetailsInitialState = createAction('fetchKamProductDetailsInitialState')
export const fetchKamCvrUsersRequest = createAction('fetchKamCvrUsersRequest')
export const fetchKamCvrUsersRequestSuccess = createAction('fetchKamCvrUsersRequestSuccess')
export const fetchKamCvrUsersRequestFailure = createAction('fetchKamCvrUsersRequestFailure')
export const fetchKamCompanyProductsRequest = createAction('fetchKamCompanyProductsRequest')
export const fetchKamCompanyProductsSuccess = createAction('fetchKamCompanyProductsSuccess')
export const fetchKamCompanyProductsFailure = createAction('fetchKamCompanyProductsFailure')

// get kam product Details
export const fetchKamProductDetails = (data = {}) => (dispatch, getState) => {
  const { status } = getState().kamCompany
  if (status !== STATUS.LOADING_DATA && data && Object.keys(data).length) {
    dispatch(fetchKamProductDetailsRequest())
    Proxies.GET('supervisors/admin-santanderProductDetails', data).then(responseJSON => {
      if (responseJSON.error) {
        dispatch(fetchKamProductDetailsFailure())
      } else if (responseJSON.length) {
        dispatch(fetchKamProductDetailsSuccess(responseJSON))
      } else {
        dispatch(fetchKamProductDetailsSuccess(responseJSON))
      }
    })
  } else {
    dispatch(fetchKamProductDetailsInitialState([]))
  }
}

//get company users data
export const fetchKamUsersData = (data = {}) => (dispatch, getState) => {
  const { loadingStatus } = getState().kamCompany
  if (loadingStatus !== STATUS.KAM_COMPANY_PRODUCTS_LOADING && data && Object.keys(data).length) {
    dispatch(fetchKamCvrUsersRequest())
    Proxies.GET('supervisors/admin-santanderCvrUsers', data).then(responseJSON => {
      if (responseJSON.error) {
        dispatch(fetchKamCvrUsersRequestFailure())
      } else if (responseJSON.length) {
        dispatch(fetchKamCvrUsersRequestSuccess(responseJSON))
      } else {
        dispatch(fetchKamCvrUsersRequestSuccess(responseJSON))
      }
    })
  }
}

//get company products data
export const fetchKamCompanyProductsData = (data = {}) => (dispatch, getState) => {
  const { productsLoadingStatus } = getState().kamCompany
  if (productsLoadingStatus !== STATUS.KAM_CVR_USERS_LOADING_DATA && data && Object.keys(data).length) {
    dispatch(fetchKamCompanyProductsRequest())
    Proxies.GET('supervisors/admin-santanderProducts', data).then(responseJSON => {
      if (responseJSON.error) {
        dispatch(fetchKamCompanyProductsFailure())
      } else if (responseJSON.length) {
        dispatch(fetchKamCompanyProductsSuccess(responseJSON))
      } else {
        dispatch(fetchKamCompanyProductsSuccess(responseJSON))
      }
    })
  }
}
