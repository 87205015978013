import React from 'react'
import LanguageForm from 'Modules/translations/LanguageForm'

const LanguageCreate = props => {
  const { LanguageID } = props.match.params

  return (
    <React.Fragment>
      <LanguageForm LanguageID={LanguageID} />
    </React.Fragment>
  )
}

export default LanguageCreate
