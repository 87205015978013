/* eslint-disable eqeqeq */
import Reflux, { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'

export const BlogActions = createActions([
  'getBlogs',
  'getBlogDetails',
  'createEmptyBlog',
  'clearBlog',
  'getGroups',
  'updateBlogField',
  'toggleGroup',
  'saveBlog',
  'deleteBlog',
  'enableBlog',
  'clearToast',
  'getBrands',
  'copyBlog',
  'preserveAdvanceSearch',
  'toggleInActiveBlog',
  'toggleActiveBlog',
  'updateData',
  'getLanguages',
  'removeTranslation',
  'addTranslation'
])

export class BlogStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = BlogActions
    this.state = {
      blogs: [],
      blogDetails: {
        groupFilterText: '',
        blogType: 'NEWS',
        translations: [
          {
            LanguageID: null,
            moduleName: '',
            moduleDescription: ''
          }
        ]
      },
      isSaving: false,
      fetchingBlogs: false,
      groups: [],
      fetchingGroups: false,
      fetchingBrands: false,
      showBlogError: false,
      showBlogSuccess: false,
      filteredGroupData: [],
      brands: [],
      advanceSearchFilters: [],
      showActiveBlogs: true,
      showInActiveBlogs: false,
      blogFilterValue: 'active',
      blogTypes: ['NEWS', 'FAQ', 'TRADE', 'PRODUCT', 'INFORMATION', 'TAX CAMPAIGN'],
      languages: []
    }
  }
  clearToast() {
    this.setState({ showBlogError: false, showBlogSuccess: false })
  }
  createEmptyBlog() {
    this.setState({ blogDetails: { blogType: 'NEWS' }, groups: [] })
  }
  clearBlog() {
    this.setState({ blogDetails: { blogType: 'NEWS' } })
  }
  getBrands() {
    GET('menus/listBrands').then(responseJSON => {
      let nextState = {
        brands: []
      }
      if (!responseJSON.error) {
        for (const iterator of responseJSON) {
          nextState.brands.push({ BrandID: iterator['BrandID'], brandName: iterator['brandName'] })
        }
      }
      this.setState(nextState)
    })
  }
  getGroups() {
    if (this.state.fetchingGroups) {
      return false
    }
    this.setState({ fetchingGroups: true })
    const BrandID = this.state.blogDetails.BrandID ? this.state.blogDetails.BrandID : null
    if (BrandID && Reflux.GlobalState.LoginStore.settings && Reflux.GlobalState.LoginStore.settings.BrandID === 9) {
      GET('groups/fetch-groups', { BrandID }).then(responseJSON => {
        let nextState = {
          groups: [],
          fetchingGroups: false
        }
        if (responseJSON) {
          nextState.groups = responseJSON
        }
        this.setState(nextState)
      })
    } else {
      GET('schemas/groups').then(responseJSON => {
        let nextState = {
          groups: [],
          fetchingGroups: false
        }
        if (responseJSON) {
          nextState.groups = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  getBlogs() {
    this.setState({ fetchingBlogs: true })
    GET('blogs/adminPool').then(responseJSON => {
      let nextState = {
        blogs: [],
        fetchingBlogs: false
      }
      if (responseJSON) {
        responseJSON = responseJSON.filter(e => {
          return !isNaN(e.BlogID)
        })
        nextState.blogs = responseJSON
      }
      this.setState(nextState)
    })
  }
  getBlogDetails(BlogID, copy = false) {
    this.setState({ fetchingBlogs: true })
    GET('blogs/fetch', { BlogID: BlogID }).then(responseJSON => {
      let nextState = {
        blogDetails: {},
        fetchingBlogs: false
      }
      if (responseJSON) {
        if (responseJSON.translations && !responseJSON.translations.length) {
          responseJSON.translations = [
            {
              LanguageID: null,
              moduleName: '',
              moduleDescription: ''
            }
          ]
        }
        nextState.blogDetails = responseJSON
        this.getLanguages(responseJSON.BrandID)
      }
      if (copy) {
        nextState.blogDetails.BlogID = null
      }
      this.setState(nextState)
      this.getGroups()
    })
  }
  copyBlog(blog, props) {
    props.history.push(props.location.pathname + '/new?copy=' + blog.BlogID)
  }

  getLanguages(BrandID) {
    GET('brands/brand-languages', { BrandIDs: [BrandID] }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        this.setState({ languages: responseJSON })
      }
    })
  }

  removeTranslation = i => {
    let translations = this.state.blogDetails.translations
    if (translations[i].BlogTranslationID) {
      translations[i].removed = 1
      if (translations.length === 1) {
        translations.push({
          LanguageID: null,
          moduleName: '',
          moduleDescription: ''
        })
      }
    } else {
      translations.splice(i, 1)
      if (!translations.length) {
        translations.push({
          LanguageID: null,
          moduleName: '',
          moduleDescription: ''
        })
      } else if (translations.filter(translation => translation.BlogTranslationID).length) {
        translations.push({
          LanguageID: null,
          moduleName: '',
          moduleDescription: ''
        })
      }
    }
    this.setState({ blogDetails: { ...this.state.blogDetails, translations: translations } })
  }

  addTranslation = () => {
    let translations = this.state.blogDetails.translations
    translations.push({ moduleName: '', moduleDescription: '', LanguageID: null })
    this.setState({ blogDetails: { ...this.state.blogDetails, translations: translations } })
  }

  updateData(field, index, type) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let blogDetails = this.state.blogDetails
    let column = blogDetails[type][index]

    if (fieldName === 'LanguageID' && type === 'translations') {
      fieldValue = Number(fieldValue)
    }

    if (!column[fieldName]) {
      column[fieldName] = ''
    }

    column[fieldName] = fieldValue

    this.setState({ blogDetails })
  }

  updateBlogField(field) {
    let name = field.target.name
    let value = strip_tags(field.target.value)
    if (name === 'BrandID' || name === 'poolOrder' || name === 'moduleFeatured') {
      value = Number(value)
    }
    let blogDetails = this.state.blogDetails
    blogDetails[name] = value
    if (name === 'groupFilterText') {
      const groupsFilter = value ? this.state.groups.filter(item => item.moduleName.toLowerCase().includes(value.toLowerCase())) : this.state.groups
      this.setState({ filteredGroupData: groupsFilter })
    }
    this.setState({ blogDetails: blogDetails })

    // Refresh Groups based on selected Brand
    if (name === 'BrandID') {
      this.getGroups()
    }
  }
  toggleGroup(checkbox) {
    let GroupID = checkbox.target.name
    let checked = checkbox.target.checked
    let blogDetails = this.state.blogDetails
    if (checked) {
      let thisGroup = this.state.groups.find(e => {
        return e.GroupID == GroupID
      })
      if (blogDetails.groups) {
        blogDetails.groups.push(thisGroup)
      } else {
        blogDetails.groups = [thisGroup]
      }
    } else {
      blogDetails.groups = blogDetails.groups.filter(e => {
        return e.GroupID != GroupID
      })
    }
    this.setState({ blogDetails: blogDetails })
  }
  saveBlog() {
    if (this.state.blogDetails.moduleName != '') {
      this.setState({ isSaving: true })
      if (this.state.blogDetails.translations) {
        this.state.blogDetails.translations = this.state.blogDetails.translations.filter(
          translation => translation.LanguageID !== null && translation.moduleName !== ''
        )
      }
      POST('blogs/replace', this.state.blogDetails).then(responseJSON => {
        let nextState = {
          isSaving: false,
          blogDetails: {},
          blogs: this.state.blogs,
          showBlogSuccess: false,
          showBlogError: false
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.showBlogError = true
          } else {
            nextState.blogDetails = responseJSON
            if (nextState.blogs.length > 0) {
              let found = false
              for (let i in nextState.blogs) {
                if (nextState.blogs[i].BlogID == responseJSON.BlogID) {
                  nextState.blogs[i] = responseJSON
                  found = true
                }
              }
              if (!found) {
                nextState.blogs.push(responseJSON)
              }
            }
            nextState.showBlogSuccess = true
          }
        }
        this.setState(nextState)
        this.getBlogs()
      })
    }
  }
  deleteBlog(BlogID = null) {
    if (BlogID) {
      POST('blogs/remove', { BlogID: BlogID }).then(() => {
        this.getBlogs()
      })
    }
  }
  enableBlog(BlogID = null) {
    if (BlogID) {
      POST('blogs/enable', { BlogID: BlogID }).then(() => {
        this.getBlogs()
      })
    }
  }
  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
  toggleActiveBlog() {
    let value = !this.state.showActiveBlogs ? 'active' : 'both'
    this.setState({ showActiveBlogs: !this.state.showActiveBlogs, blogFilterValue: value, showInActiveBlogs: false })
  }
  toggleInActiveBlog() {
    let value = this.state.showInActiveBlogs ? 'both' : 'inactive'
    this.setState({ showInActiveBlogs: !this.state.showInActiveBlogs, blogFilterValue: value, showActiveBlogs: false })
  }
}
