import React from 'react'
import { Component } from 'reflux'
import { BatchStore, BatchActions, STATUS } from 'Stores/batchStore'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import 'moment/locale/da'

export default class BatchList extends Component {
  constructor(props) {
    super(props)
    this.stores = [BatchStore]
    this.storeKeys = ['status', 'batches', 'batchSaving', 'BatchID', 'removedBatchRecords', 'batchInsertError']
  }
  componentDidMount() {
    if (this.state.status !== STATUS.FETCHING_BATCHES) {
      BatchActions.getBatches(this.props.match.params.DatasetID)
    }
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.BATCHES_ERROR) {
      toast.error('NO BATCHES FOUND', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
      BatchActions.clearToast()
    }
    if (this.state.status === STATUS.BATCH_INSERT_ERROR) {
      toast.error('BATCH INSERT FAILED: ' + this.state.batchInsertError, {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
      BatchActions.clearToast()
    }
    if (this.state.status === STATUS.BATCH_INSERT_SUCCESS) {
      toast.success('BATCH INSERT SUCCESSFUL', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
      BatchActions.clearToast()
    }
    if (this.state.status === STATUS.BATCH_DELETE_ERROR) {
      toast.error('BATCH DELETE FAILED', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.BATCH_DELETE_SUCCESS) {
      toast.success('BATCH DELETE SUCCESSFUL', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.REMOVE_BATCH_CONTENT_SUCCESS) {
      toast.success(`${this.state.removedBatchRecords} records succesfully`, {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
      BatchActions.getBatches(this.props.match.params.DatasetID)
      BatchActions.clearToast()
    }
    if (this.state.status === STATUS.REMOVE_BATCH_CONTENT_FAILED) {
      toast.error('REMOVE BATCH CONTENT FAILED', {
        autoClose: 7500,
        onClose: () => {
          BatchActions.clearToast()
        }
      })
      BatchActions.clearToast()
    }
  }
  render() {
    if (this.state.status === STATUS.FETCHING_BATCHES) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    const headers =
      Number(this.props.match.params.DatasetID) === 2
        ? {
            BatchID: 'Batch ID',
            batchFlowStatus: 'Status',
            batchComment: 'Note',
            pointsInserted: 'Indsatte',
            pointsNotInserted: 'Ikke indsatte',
            timeInsert: 'Date Insert',
            timeUpdate: 'Date Changed'
          }
        : {
            BatchID: 'Batch ID',
            batchFlowStatus: 'Status',
            batchComment: 'Note',
            timeInsert: 'Date Insert',
            timeUpdate: 'Date Changed'
          }

    return (
      <CollapsibleCard startOpen header={<strong>Batches</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'BatchID'
            },
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
              },
              timeUpdate: e => {
                return e.timeUpdate ? moment(e.timeUpdate).format('DD-MM-YYYY') : ''
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              },
              timeUpdate: e => {
                return moment(e.timeUpdate).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.BatchID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/dataset/' + this.props.match.params.DatasetID + '/batch/edit/' + e.BatchID} id={'edit' + e.BatchID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'preview' + e.BatchID}>Vis</Tooltip>
                    <Button
                      tag={Link}
                      to={'/dataset/' + this.props.match.params.DatasetID + '/batch/' + e.BatchID + '/preview'}
                      id={'preview' + e.BatchID}
                    >
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    <Tooltip target={'delete' + e.BatchID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.BatchID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne batch?')) {
                          BatchActions.deleteBatch(e.BatchID, this.props.match.params.DatasetID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                    <Tooltip target={'insert' + e.BatchID}>Insert</Tooltip>
                    <Button
                      disabled={e.batchFlowStatus === 'INSERTED' || e.batchFlowStatus === 'MATCHED'}
                      id={'insert' + e.BatchID}
                      onClick={() => BatchActions.insertBatch(e.BatchID, this.props.match.params.DatasetID)}
                    >
                      {e.BatchID === this.state.BatchID && this.state.batchSaving ? (
                        <FontAwesomeIcon icon='spinner' />
                      ) : (
                        <FontAwesomeIcon icon='compress' />
                      )}
                    </Button>
                    <Tooltip target={'copy' + e.BatchID}>Copy</Tooltip>
                    <Button tag={Link} to={'/dataset/' + this.props.match.params.DatasetID + '/copy/' + e.BatchID} id={'copy' + e.BatchID}>
                      <FontAwesomeIcon icon='copy' />
                    </Button>
                    {e.batchRemoved === 0 && (
                      <React.Fragment>
                        <Tooltip target={'removeBatchData' + e.BatchID}>Deaktivere Batch</Tooltip>
                        <Button
                          disabled={e.batchRemoved}
                          id={'removeBatchData' + e.BatchID}
                          color='success'
                          onClick={() => {
                            if (confirm('Do you want to  delete batch content?')) {
                              BatchActions.removeBatchContent(e.BatchID, 1)
                            }
                          }}
                        >
                          {e.BatchID === this.state.BatchID && this.state.removeBatchData ? (
                            <FontAwesomeIcon icon='spinner' />
                          ) : (
                            <FontAwesomeIcon icon='check-circle' />
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                    {e.batchRemoved === 1 && (
                      <React.Fragment>
                        <Tooltip target={'removeBatchData' + e.BatchID}>Actiavte Batch</Tooltip>
                        <Button
                          id={'removeBatchData' + e.BatchID}
                          color='danger'
                          onClick={() => {
                            if (confirm('Do you want to actiavte batch content?')) {
                              BatchActions.removeBatchContent(e.BatchID, 0)
                            }
                          }}
                        >
                          {e.BatchID === this.state.BatchID && this.state.removeBatchData ? (
                            <FontAwesomeIcon icon='spinner' />
                          ) : (
                            <FontAwesomeIcon icon='eraser' />
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={headers}
          data={this.state.batches}
        />
      </CollapsibleCard>
    )
  }
}
