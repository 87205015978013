import React from 'react'
import CampaignCreate from 'Modules/campaigns/campaignCreate.js'
import CampaignList from 'Modules/campaigns/campaignList.js'
import CampaignEdit from 'Modules/campaigns/campaignEdit.js'

export function SantanderCreateKamCampaign() {
  return <CampaignCreate isKam={true} />
}

export function SantanderKamCampaignView() {
  return <CampaignList isKam={true} />
}

export function SantanderKamEditCampaign({ match }) {
  return <CampaignEdit isKam={true} readOnly={true} match={match} />
}
