export const operatorsList = [
  { name: 'Mindre end', value: 'LOWER' },
  { name: 'Større end', value: 'HIGHER' },
  { name: 'Lig med', value: 'EQUAL' },
  { name: 'Ikke lig med', value: 'NOT_EQUAL' },
  { name: 'Indeholder', value: 'LIKE' },
  { name: 'Indeholder ikke', value: 'NOT_LIKE' }
]

export const operatorsIntegerField = [
  { name: 'Mindre end', value: 'LOWER' },
  { name: 'Større end', value: 'HIGHER' },
  { name: 'Lig med', value: 'EQUAL' },
  { name: 'Ikke lig med', value: 'NOT_EQUAL' },
  { name: 'Mellem', value: 'BETWEEN' }
]

export const operatorsDateField = [
  { name: 'Mindre end', value: 'LOWER' },
  { name: 'Større end', value: 'HIGHER' },
  { name: 'Mellem', value: 'BETWEEN' }
]

export const operatorsIDField = [
  { name: 'Lig med', value: 'EQUAL' },
  { name: 'Ikke lig med', value: 'NOT_EQUAL' }
]
