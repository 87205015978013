import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { HofActions, HofStore, STATE } from 'Stores/hofStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class HOFElements extends Component {
  constructor(props) {
    super(props)
    this.stores = [HofStore]
    this.storeKeys = ['status', 'hofElements']
  }
  componentDidMount() {
    HofActions.getHOFElements(this.props.match.params.HOFID)
  }
  componentDidUpdate() {
    if (this.state.status === STATE.FETCHING_HOF_ELEMENTS_FAILED) {
      toast.error('FETCHING HOF ELEMENTS FAILED', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATE.FETCHING_HOF_ELEMENTS) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>Hall Of Fame Details</strong>}>
          <DynamicTable responsive size='sm' data={this.state.hofElements} />
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
