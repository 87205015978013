/* eslint-disable eqeqeq */
import React from 'react'
import { Redirect } from 'react-router-dom'
import Proxies from 'Components/proxies'

export default class SSO extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      verificationDone: false
    }
  }

  componentWillMount() {
    let data = this.QueryStringToJSON(this.props.location.search)
    Proxies.ssoValidate(data).then(responseJson => {
      if (responseJson && responseJson.status > 0) {
        let sessionBrandSlug = responseJson.package.brand.brandSlug
        let currentBrandSlug = this.getBrandSlug()
        if (sessionBrandSlug == currentBrandSlug) {
          sessionStorage.clear()
          localStorage.setItem('session', data.token)
          this.setState({ verificationDone: true })
        } else {
          if (window.location.hostname.indexOf('localhost') != -1) {
            window.location.replace('http://' + sessionBrandSlug + '.localhost:3010' + this.props.location.pathname + this.props.location.search)
          } else {
            window.location.replace(
              'https://' + sessionBrandSlug + '.' + this.getDomainName() + this.props.location.pathname + this.props.location.search
            )
          }
        }
      } else {
        this.setState({ verificationDone: true })
      }
    })
  }

  getBrandSlug() {
    let urlBase = window.location.hostname.split('.')[0].toLowerCase()
    if (urlBase.indexOf('-') != -1) {
      let theseOptions = urlBase.split('-')
      urlBase = theseOptions.shift()
    }
    return urlBase
  }

  getDomainName() {
    let urlBase = window.location.hostname.split('.')
    if (urlBase.length > 2) {
      urlBase.slice(0, 1)
    }
    return urlBase.join('.')
  }

  QueryStringToJSON(data = location.search) {
    let pairs = data.slice(1).split('&')

    let result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })

    return JSON.parse(JSON.stringify(result))
  }

  render() {
    if (!this.state.verificationDone) {
      return null
    }
    return <Redirect to='/' />
  }
}
