import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchProductHistory, STATUS } from 'Reducers/actions/stockActions'
import { connect } from 'react-redux'
import moment from 'moment'
import Styled from 'styled-components'
import { Row } from 'reactstrap'

const StyledError = Styled(Row)`
color: ${props => (props && props.isWarning ? '#E08A1E' : 'red')};
margin-left: 0px !important;
margin-right: 0px !important;`

class ProductHistoryOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.fetchProductHistory(this.props.match.params.ProductID)
  }

  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Produkt historie</strong>}>
        {this.props.fetchingProductHistory === STATUS.LOADING_PRODUCT_HISTORY ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'ProductEventID'
              },
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD HH:mm:ss') : ''
                },
                userName: e => {
                  return e.user ? e.user.userName : ''
                },
                variationTitle: e => {
                  return e.variation ? e.variation.variationTitle : ''
                },
                productName: e => {
                  return e.product ? e.product.elementTitle : ''
                },
                eventValue: e => {
                  if (e.eventValue <= 0) {
                    return (
                      <React.Fragment>
                        <StyledError>{e.eventValue}</StyledError>
                      </React.Fragment>
                    )
                  } else {
                    return e.eventValue
                  }
                },
                eventTrigger: e => {
                  switch (e.eventTrigger.toLowerCase()) {
                    case 'manual':
                      return 'Manuel'
                    case 'sale':
                      return 'Order'
                    case 'refund':
                      return 'Refundering'
                  }
                },
                catalogs: e => {
                  if (e.catalogs && e.catalogs.length) {
                    const reducedCatalogs = e.catalogs.reduce((acc, cur, idx) => {
                      acc.push(cur.moduleName)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedCatalogs.length > 0) {
                      reducedCatalogs.pop()
                    }
                    return reducedCatalogs
                  }
                }
              },
              fieldStringFunctions: {
                timeInsert: e => {
                  return moment(e.timeInsert).format('x')
                },
                eventValue: e => {
                  return e.eventValue
                },
                catalogs: e => {
                  let eVar = e.catalogs ? e.catalogs : []
                  let catalogs = eVar.map(v => {
                    return v.moduleName
                  })
                  return catalogs.toString()
                }
              },
              fieldExportFunctions: {
                eventValue: e => {
                  return e.eventValue
                },
                catalogs: e => {
                  let eVar = e.catalogs ? e.catalogs : []
                  let catalogs = eVar.map(v => {
                    return v.moduleName
                  })
                  return catalogs.toString()
                }
              }
            }}
            headers={{
              ProductEventID: '#',
              ProductID: 'Produkt ID',
              VariationID: 'Variation ID',
              productName: 'Produkt Navn',
              variationTitle: 'Variation',
              userName: 'Bruger',
              eventDescription: 'Beskrivelse',
              eventTrigger: 'Event',
              eventValue: 'Antal',
              catalogs: 'Katalog',
              timeInsert: 'Dato'
            }}
            data={[...this.props.productHistory]}
          />
        )}
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({ productHistory: state.stock.productHistory, fetchingProductHistory: state.stock.fetchingProductHistory })

export default connect(mapStateToProps, { fetchProductHistory })(ProductHistoryOverview)
