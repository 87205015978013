import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { TranslationActions, TranslationStore, STATUS } from 'Stores/translationStore'
import TranslationForm from 'Modules/translations/translationForm'

export default class TranslationEdit extends Component {
  constructor(props) {
    super(props)
    this.stores = [TranslationStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    TranslationActions.getTranslationDetail(this.props.match.params.PhraseID)
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_TRANSLATION_DETAILS_ERROR) {
      toast.error('FETCHING TRANSLATION FAILED', {
        autoClose: 7500,
        onClose: () => {
          TranslationActions.clearToast()
        }
      })
      TranslationActions.clearToast()
    }

    if (this.state.status === STATUS.TRANSLATION_FAILED) {
      toast.error('UPDATE TRANSLATION FAILED', {
        autoClose: 3000,
        onClose: () => {
          TranslationActions.clearToast()
        }
      })
      TranslationActions.clearToast()
    }
    if (this.state.status === STATUS.TRANSLATION_SUCCESS) {
      toast.success('UPDATE TRANSLATION SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          TranslationActions.clearToast()
        }
      })
      TranslationActions.clearToast(STATUS.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <TranslationForm {...this.props} />
      </React.Fragment>
    )
  }
}
