import React, { useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies

const WalletActivationList = props => {
  const [fetchingWalletsInfo, setFetchingWalletsInfo] = useState(false)
  const [walletActivation, setWalletActivation] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [modalBody, setModalBody] = useState('')
  const isEditable = !!props.edit

  const fetchWallets = () => {
    setFetchingWalletsInfo(true)
    GET('walletactivation/pool').then(responseJSON => {
      if (responseJSON) {
        setWalletActivation(responseJSON)
      }
      setFetchingWalletsInfo(false)
    })
  }

  const updateStatus = (status, WalletActivationID) => {
    POST('walletactivation/updateStatus', { WalletActivationIDs: [WalletActivationID], status }).then(responseJSON => {
      if (!responseJSON.error) {
        fetchWallets()
      }
    })
    resetModal()
  }

  const resetModal = () => {
    setOpenModal(false)
    setSelectedItem(null)
    setSelectedStatus(null)
    setModalBody('')
  }

  const handleStatusChange = (status, item) => {
    setOpenModal(true)
    if (status === 'ACTIVATED') {
      const userWallets = walletActivation.filter(i => i.UserID === item.UserID)
      const balanceAmount = userWallets
        .filter(s => s.status === 'WAITING' || s.status === 'COMPENSATION')
        .reduce((sum, s) => sum + s.walletAmountPoints, 0)
      if (balanceAmount > 0) {
        setSelectedStatus(status)
        setSelectedItem(item)
        if (item.walletAmountPoints <= balanceAmount) {
          setModalBody('Vil du aktivere denne Wallet?')
        } else {
          setModalBody(`Du kan kun aktivere ${balanceAmount} point p.g.a. udligningspoint. Ønsker du at aktivere ${balanceAmount} point?`)
        }
      } else {
        setModalBody(`Din sum af point til aktivering er 0 p.g.a. udligningspoint og du kan derfor ikke aktivere flere point.`)
      }
    } else if (status === 'WAITING') {
      setSelectedStatus(status)
      setSelectedItem(item)
      setModalBody('Vil du deaktivere denne Wallet?')
    }
  }

  useEffect(() => {
    fetchWallets()
  }, [])

  return (
    <React.Fragment>
      {openModal && (
        <Modal isOpen={openModal} toggle={() => resetModal()} size='md'>
          <ModalHeader toggle={() => resetModal()}>Change Status</ModalHeader>
          <ModalBody>
            <strong>{modalBody}</strong>
          </ModalBody>
          <ModalFooter>
            {!!selectedStatus && <Button onClick={() => updateStatus(selectedStatus, selectedItem.WalletActivationID)}>Ja</Button>}
            <Button color='danger' onClick={() => resetModal()}>
              {selectedStatus ? 'Nej' : 'Ok'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <CollapsibleCard startOpen header={<strong>Wallet Activation</strong>}>
        {fetchingWalletsInfo ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            data={walletActivation}
            headers={{
              WalletActivationID: 'WalletActivationID',
              BatchID: 'Batch',
              UserID: 'UserID',
              userName: 'Name',
              currentUserAccess: 'Current Access',
              userUID: 'UserUID',
              walletNote: 'Note',
              compensationNote: 'Compensation',
              walletAmountPoints: 'Points',
              status: 'Status',
              statusBy: 'Last Changed By',
              VaultID: 'VaultID',
              timeInsert: 'Date',
              userAccess: 'Access'
            }}
            options={{
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
                },
                statusBy: e => {
                  return e.statusBy && e.statusBy.userName ? e.statusBy.userName : ''
                }
              },
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              },
              addField: e => {
                return (
                  <td>
                    {isEditable && (
                      <ButtonGroup>
                        {e.status === 'ACTIVATED' && (
                          <React.Fragment>
                            <Tooltip target={'delete' + e.WalletActivationID}>Deaktivér</Tooltip>
                            <Button color='danger' id={'delete' + e.WalletActivationID} onClick={() => handleStatusChange('WAITING', e)}>
                              <FontAwesomeIcon icon='user-slash' />
                            </Button>
                          </React.Fragment>
                        )}
                        {e.status === 'WAITING' && (
                          <React.Fragment>
                            <Tooltip target={'delete' + e.WalletActivationID}>Aktivér</Tooltip>
                            <Button
                              disabled={e.userAccess === 'DECLINED'}
                              id={'delete' + e.WalletActivationID}
                              onClick={() => handleStatusChange('ACTIVATED', e)}
                            >
                              <FontAwesomeIcon icon='user-check' />
                            </Button>
                          </React.Fragment>
                        )}
                      </ButtonGroup>
                    )}
                  </td>
                )
              }
            }}
          />
        )}
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default WalletActivationList
