import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  LOADING: null,
  SUCCESS: null,
  FAILED: null
})

export const WalletPointStatsActions = createActions(['getWalletPointStats'])

export class WalletPointStatsStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = WalletPointStatsActions
    this.state = {
      walletPointsStats: null,
      status: null
    }
  }

  getWalletPointStats() {
    if (!this.state.status || this.state.status === STATUS.FAILED) {
      this.setState({ status: STATUS.LOADING })
      Proxies.GET('reports/wallet-points-stats')
        .then(responseJSON => {
          if (responseJSON.error) {
            this.setState({ status: STATUS.FAILED })
          } else {
            this.setState({ status: STATUS.SUCCESS, walletPointsStats: responseJSON[0] })
          }
        })
        .catch(() => {
          this.setState({ status: STATUS.FAILED })
        })
    }
  }
}
