/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import ViewBrands from 'Modules/brand/viewBrand'
import BrandForm from 'Modules/brand/brandForm'
import { BrandActions, BrandStore, STATUS } from 'Stores/brandStore'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'

const Brand = props => {
  if (props.create) {
    return <BrandCreate {...props} />
  }
  if (props.overview) {
    return <BrandOverview {...props} />
  }
  if (props.brandedit) {
    return <BrandEdit {...props} />
  }
}
export default Brand

class BrandCreate extends Component {
  constructor() {
    super()
    this.store = BrandStore
  }

  componentDidMount() {
    BrandActions.getClients()
    BrandActions.resetForm()
    if (_.get(this.props, 'location.search')) {
      let params = new URLSearchParams(this.props.location.search)
      params.has('copy') && BrandActions.getBrandDetails(Number(params.get('copy')), true)
      BrandActions.getBrandtncCustomisation(Number(params.get('copy')), true)
    } else {
      BrandActions.getBrandtncCustomisation()
    }
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_CLIENTS_FAILED) {
      toast.error('Hentning af infomation mislykkes', {
        autoClose: 3000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }

    if (this.state.status === STATUS.BRAND_SUCCESS) {
      toast.success('Oprettelsen af et brand lykkes', {
        autoClose: 7000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast(STATUS.IS_REDIRECT)
    }

    if (this.state.status === STATUS.BRAND_FAILED) {
      toast.error('Oprettelsen af et brand mislykkes - Anvend et andet slug navn', {
        autoClose: 7000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.IS_REDIRECT) {
      return <Redirect to='/brand' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>Opret Brand</strong>}>
          <BrandForm {...this.props} />
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}

class BrandOverview extends Component {
  constructor() {
    super()
    this.store = BrandStore
  }
  render() {
    return (
      <React.Fragment>
        <ViewBrands {...this.props} />
      </React.Fragment>
    )
  }
}

class BrandEdit extends Component {
  constructor() {
    super()
    this.store = BrandStore
  }

  componentDidMount() {
    BrandActions.getClients()
    BrandActions.getBrandDetails(this.props.match.params.BrandID)
    BrandActions.getBrandCustomisations(this.props.match.params.BrandID)
    BrandActions.getBrandtncCustomisation(this.props.match.params.BrandID)
    BrandActions.getBrandCookieCustomisation(this.props.match.params.BrandID)
    BrandActions.getWalletActivationCustomisation(this.props.match.params.BrandID)
    BrandActions.getSendPointsCustomisation(this.props.match.params.BrandID)
    BrandActions.getProfileCustomisation(this.props.match.params.BrandID)
    BrandActions.getOpeningHoursCustomisation(this.props.match.params.BrandID)
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.BRAND_FAILED) {
      toast.error('Brand blev ikke opdateret', {
        autoClose: 3000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }
    if (this.state.status === STATUS.BRAND_SUCCESS) {
      toast.success('Brand er opdateret', {
        autoClose: 3000,
        onClose: () => {
          BrandActions.clearToast()
        }
      })
      BrandActions.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>Redigere Brand</strong>}>
          <BrandForm {...this.props} />
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
