/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
import { getSettings } from 'Reducers/actions/loginActions'
import store from 'Reducers/store'

const LoginActions = Reflux.createActions([
  'authenticationRequested',
  'phoneNumberUpdated',
  'tokenUpdated',
  'tokenValidationRequested',
  'validate',
  { signOut: { children: ['completed'] } },
  'termsAccepted',
  { setupWeb: { children: ['completed'] } }
])

class LoginStore extends Reflux.Store {
  constructor() {
    super()
    this.listenables = LoginActions
    let sessionKey = localStorage.getItem('session') ? localStorage.getItem('session') : null
    this.state = {
      phoneNumber: '',
      token: '',
      showPhoneNumberInput: true,
      sessionKey: sessionKey,
      sessionVerified: false,
      BrandID: null,
      brandData: null,
      loginErrorNoUser: false,
      loginErrorUniqueUser: false,
      tokenError: false,
      brandAccessTermsVerified: true,
      brandAccessTermsURL: null,
      settings: {},
      validatorHasRun: false,
      user: {}
    }
    this.isRunningSetup = false
    this.setupWeb()
  }

  getBrandSlug() {
    if (window.location.hostname == 'localhost') {
      return 'upgradertest'
    }
    let urlBase = window.location.hostname.split('.')[0].toLowerCase()
    if (urlBase.indexOf('-') != -1) {
      let theseOptions = urlBase.split('-')
      urlBase = theseOptions.shift()
    }
    return urlBase
  }
  phoneNumberUpdated(phoneNumber) {
    this.setState({ phoneNumber: phoneNumber, loginErrorNoUser: false, loginErrorUniqueUser: false })
  }

  authenticationRequested() {
    Proxies.sessionGrant(this.state.phoneNumber).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        this.setState({
          showPhoneNumberInput: false
        })
      } else {
        if (responseJSON.error && responseJSON.error.message === 'NOT_UNIQUE_USER') {
          this.setState({ loginErrorUniqueUser: true })
        } else {
          this.setState({ loginErrorNoUser: true })
        }
      }
    })
  }

  tokenUpdated(token) {
    if (token.length > 6) return

    this.setState({ token: token, tokenError: false })
    if (token.length === 6) {
      this.tokenValidationRequested()
    }
  }

  tokenValidationRequested() {
    Proxies.sessionAccess(this.state.phoneNumber, this.state.token).then(responseJSON => {
      if (responseJSON.error) {
        this.setState({ tokenError: true })
      } else {
        let termsAccepted = Boolean(parseInt(responseJSON.user.userAccessTerms))
        let nextState = {
          sessionKey: responseJSON.token,
          sessionVerified: false,
          brandAccessTermsVerified: false,
          brandAccessTermsURL: null
        }
        sessionStorage.setItem('user', JSON.stringify(responseJSON))
        if (responseJSON.brand && responseJSON.brand.brandAccessTermsURL) {
          nextState.brandAccessTermsURL = responseJSON.brand.brandAccessTermsURL
          nextState.brandAccessTermsVerified = termsAccepted

          if (termsAccepted) {
            localStorage.setItem('session', responseJSON.token)
            nextState.sessionToken = responseJSON.token
            nextState.sessionVerified = true
            nextState.showVerifyTerms = true
          }
        } else {
          localStorage.setItem('session', responseJSON.token)
          nextState.sessionVerified = true
          nextState.brandAccessTermsVerified = true
        }
        this.setState(nextState)
        this.setupWeb(null, true)
      }
    })
  }

  signOut() {
    return Proxies.sessionClear().finally(() => {
      localStorage.clear()
      sessionStorage.clear()
      location.href = '/'
    })
  }

  validate() {
    if (this.state.sessionVerified) {
      return true
    }
    if (localStorage.getItem('session')) {
      Proxies.sessionValidate().then(
        responseJSON => {
          if (responseJSON && responseJSON.error && responseJSON.error.message == 'SESSION_INVALID') {
            LoginActions.signOut()
            return false
          }
          if (responseJSON) {
            let nextState = {
              sessionVerified: true,
              brandAccessTermsVerified: false,
              brandAccessTermsURL: null,
              validatorHasRun: true,
              user: responseJSON.user
            }
            sessionStorage.setItem('user', JSON.stringify(responseJSON))
            if (responseJSON.brand && responseJSON.brand.brandAccessTermsURL) {
              nextState.brandAccessTermsURL = responseJSON.brand.brandAccessTermsURL
              nextState.brandAccessTermsVerified = Boolean(parseInt(responseJSON.user.userAccessTerms))
            } else {
              nextState.brandAccessTermsVerified = true
            }
            this.setState(nextState)
            return true
          }
          LoginActions.signOut()
        },
        () => {
          LoginActions.signOut()
        }
      )
    }
    this.setState({ validatorHasRun: true })
    return false
  }

  termsAccepted() {
    Proxies.verifyAccessTerms(this.state.sessionKey).then(responseJSON => {
      if (responseJSON) {
        let nextState = { brandAccessTermsVerified: true, sessionVerified: true }
        localStorage.setItem('session', this.state.sessionKey)
        this.setState(nextState)
      }
    })
  }
  setupWeb(data = null, override = false) {
    if ((this.isRunningSetup || this.state.settings.BrandID) && !override) {
      return false
    }
    this.isRunningSetup = true
    if (!data || !data.brandSlug) {
      if (!data) {
        data = {}
      }
      let thisBrandSlug = this.getBrandSlug()
      data.brandSlug = thisBrandSlug
    }
    return Proxies.platformSetup(data).then(
      responseJSON => {
        let brand = {
          BrandID: responseJSON.platform.BrandID,
          brandName: responseJSON.platform.brandName
        }
        if (!brand.BrandID) {
          this.setState({ brandData: 'NOBRAND' })
        } else {
          localStorage.setItem('BrandID', brand.BrandID)
          document.title = brand.brandName
          this.setState({ BrandID: brand.BrandID, brandData: brand })
        }
        if (responseJSON && responseJSON.platform && responseJSON.platform.faviconImageURL) {
          let favicon = document.getElementById('favicon')
          favicon.href = responseJSON.platform.faviconImageURL
        }
        this.setState({ settings: responseJSON.platform })
        this.validate()
        LoginActions.setupWeb.completed(responseJSON.platform)
        store.dispatch(getSettings(responseJSON.platform))
        this.isRunningSetup = false
        return responseJSON.platform
      },
      () => {
        let brandSlug = this.getBrandSlug()
        setTimeout(() => {
          this.setupWeb({ brandSlug })
        }, 500)
        this.isRunningSetup = false
      }
    )
  }
}

LoginStore.id = 'LoginStore'

export { LoginStore, LoginActions }
