import React, { useEffect, useState } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import styled from 'styled-components'
import _ from 'lodash'
import Proxies from 'Components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import keyMirror from 'keymirror'
import { CampaignActions } from 'Stores/campaignStore.js'
const USER_LOADING_STATES = keyMirror({
  LOADING: null,
  ERROR: null,
  USERS_LOADED: null
})

export const StyledDropdown = styled(DropdownTreeSelect)`
  & .dropdown-trigger {
    border-radius: 0.25rem;
  }

  & .node {
    position: relative;
    margin-left: 25px;
  }

  & .node.tree:last-child {
    margin-bottom: 10px;
  }

  & .node label {
    display: inline;
    margin-bottom: 0;
  }

  & .toggle {
    position: absolute;
    top: -3px;
    left: -20px;
    font-size: 25px;
    font-weight: 900;
  }

  & .toggle.expanded {
    top: -5px;
    left: -17px;
  }

  & a:hover {
    text-decoration: none;
  }

  & .dropdown-content {
    max-height: 400px;
    overflow-y: scroll;
  }
`

const mapUsersToSelectableObject = users => {
  const result = users.reduce((accumulator, user) => {
    const company = accumulator[user.userCompanyUID] || {
      label: user.userCompany ? `${user.userCompany} - ${user.userCompanyUID}` : 'Ikke tilknyttet firma',
      companyUID: user.userCompanyUID,
      children: []
    }

    company.children.push({
      label: user.userName,
      UserID: user.UserID
    })
    accumulator[user.userCompanyUID] = company

    return accumulator
  }, {})

  return _.map(result, company => company) // Remove wrapper object and return array of object content
}

function SantanderCampaignUserSelect() {
  const [users, setUsers] = useState(null)
  const [treeSelectData, setTreeSelectData] = useState(null)
  const [loadingState, setLoadingState] = useState(USER_LOADING_STATES.LOADING)

  useEffect(() => {
    // isSubscribed: Prevent memory leak warning from react, due to our sturcture calling components twice on load
    let isSubscribed = true
    Proxies.santanderKAMUsers().then(response => {
      if (!isSubscribed) return null
      if (response.error) {
        setLoadingState(USER_LOADING_STATES.ERROR)
      } else {
        setUsers(response)
        setTreeSelectData(mapUsersToSelectableObject(response))
        setLoadingState(USER_LOADING_STATES.USERS_LOADED)
      }
    })

    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    const unsubscribe = CampaignActions.resetMacro.listen(() => {
      resetUserSelectInput()
    })

    return () => {
      unsubscribe()
    }
  })

  const onChange = (currentNode, selectedNodes) => {
    const result = _.flatMap(selectedNodes, node => {
      if (node.companyUID) {
        return users.filter(user => user.userCompanyUID === node.companyUID)
      } else {
        return users.find(user => user.UserID === node.UserID)
      }
    })

    setTimeout(() => {
      // Hacky solution, but if the reflux action is on the callstack the onChange handling from
      // tree select breaks. Not entirely sure why. But breaking the callstack solves the issue
      CampaignActions.updateSelectedUsers(result)
    })
  }

  const resetUserSelectInput = () => {
    const uncheckedNodes = _.map(treeSelectData, node => {
      node.checked = false
      return node
    })
    setTreeSelectData(uncheckedNodes)
  }

  return (
    <>
      {loadingState === USER_LOADING_STATES.ERROR && <p className='text-danger'>Bruger listen kunne ikke hentes, prøv at genindlæs siden.</p>}
      {loadingState === USER_LOADING_STATES.LOADING && <FontAwesomeIcon icon='spinner' spin />}
      {loadingState === USER_LOADING_STATES.USERS_LOADED && (
        <StyledDropdown
          data={treeSelectData}
          onChange={onChange}
          keepTreeOnSearch={true}
          keepChildrenOnSearch={true}
          showPartiallySelected={true}
          texts={{
            placeholder: 'Vælg brugere',
            noMatches: 'Ingen brugere fundet',
            labelRemove: 'Fjern'
          }}
        />
      )}
    </>
  )
}

export default React.memo(props => <SantanderCampaignUserSelect {...props} />)
