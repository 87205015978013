import React from 'react'
import Reflux from 'reflux'
import { CampaignActions } from 'Stores/campaignStore'
import { Button, Input, Col, Row, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default class CreateProductModal extends Reflux.Component {
  render() {
    return (
      <Modal isOpen={this.props.showModal} toggle={CampaignActions.closeProduct} size='lg'>
        <ModalHeader toggle={CampaignActions.closeProduct}>Opret Produkt</ModalHeader>
        <ModalBody>
          <Row className='my-2'>
            <Col xs='2'>Navn</Col>
            <Col xs='10'>
              <Input
                type='text'
                required
                value={this.props.productDetails.moduleName}
                name='moduleName'
                id='moduleName'
                onChange={CampaignActions.updateProduct}
              />
            </Col>
          </Row>
          <Row>
            <Col xs='2'>Beskrivelse</Col>
            <Col xs='10'>
              <Input
                type='text'
                required
                value={this.props.productDetails.moduleDescription}
                name='moduleDescription'
                id='moduleDescription'
                onChange={CampaignActions.updateProduct}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={CampaignActions.saveProduct}>Gem</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
