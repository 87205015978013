/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
import { UserActions } from 'Stores/userStore'

const CompanyWalletActions = Reflux.createActions([
  'getWalletData',
  'getWalletSum',
  'getUsers',
  'updateUserFilter',
  { transfer: { children: ['completed'] } },
  'updateAmount',
  'updateMessage',
  'clearToast'
])

class CompanyWalletStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = CompanyWalletActions
    this.state = {
      companyWalletSum: null,
      users: null,
      userFilter: '',
      usersLoading: false,
      wallet: [],
      walletLoading: false,
      amount: '',
      transferMessage: '',
      transferringPoints: false,
      showSuccessToast: false,
      showErrorToast: false
    }
  }
  clearToast() {
    this.setState({ showSuccessToast: false, showErrorToast: false })
  }
  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
    })
  }
  getWalletSum() {
    Proxies.companyWalletSum().then(responseJSON => {
      if (responseJSON) {
        this.setState({ companyWalletSum: responseJSON[0].total })
      }
    })
  }
  getWalletData() {
    this.setState({ walletLoading: true })
    let nextState = { walletLoading: false, wallet: [] }
    Proxies.companyWallet({ removed: 0 }).then(responseJSON => {
      if (responseJSON) {
        nextState.wallet = responseJSON
      }
      this.setState(nextState)
    })
  }
  getUsers() {
    if (this.state.userFilter.length < 4) {
      return false
    }
    this.setState({ usersLoading: true })
    let baseFilter = { BrandID: Reflux.GlobalState.LoginStore.user.BrandID, removed: 0, userAccess: 'APPROVED' }
    let filterOptions = ['userName', 'userCompany', 'userSupervisor', 'userUID', 'userCompanyUID', 'userSupervisorUID', 'userEmail']
    let responseUsers = []
    let promises = []
    for (let filterField of filterOptions) {
      let filter = {}
      filter[filterField] = { like: '%' + this.state.userFilter + '%' }
      Object.assign(filter, baseFilter)
      promises.push(Proxies.userPool(filter))
    }
    let nextState = { users: null, usersLoading: false }
    Promise.all(promises).then(results => {
      for (let result of results) {
        if (!result) {
          continue
        }
        responseUsers = responseUsers.concat(result)
      }
      responseUsers = this.removeDuplicates(responseUsers, 'UserID')
      nextState.users = responseUsers
      this.setState(nextState)
    })
  }
  updateUserFilter(text) {
    this.setState({ userFilter: text })
    this.getUsers()
  }
  updateAmount(amount) {
    this.setState({ amount: parseInt(amount) })
  }
  updateMessage(transferMessage) {
    this.setState({ transferMessage: transferMessage })
  }
  transfer(UserID) {
    this.setState({ transferringPoints: true })
    let transferData = {
      receiver: UserID,
      amount: this.state.amount,
      message: this.state.transferMessage
    }
    Proxies.companyWalletTransfer(transferData).then(responseJSON => {
      let nextState = {
        transferringPoints: false,
        showSuccessToast: false,
        showErrorToast: false,
        userFilter: this.state.userFilter,
        amount: this.state.amount,
        transferMessage: this.state.transferMessage
      }
      if (responseJSON && responseJSON.error) {
        nextState.showErrorToast = true
      } else if (responseJSON == 'SUCCESS') {
        nextState.showSuccessToast = true
        nextState.amount = 0
        ;(nextState.transferMessage = ''), (nextState.userFilter = '')
      }
      this.setState(nextState)
      this.getWalletSum()
      if (this.state.wallet.length > 0) {
        this.getWalletData()
      }
      CompanyWalletActions.transfer.completed()
    })
  }
  onTransferCompleted() {
    UserActions.getUsers()
  }
}

export { CompanyWalletActions, CompanyWalletStore }
