/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import keyMirror from 'keymirror'
export const STATUS = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  COMPANY_SUCCESS: null,
  COMPANY_FAILED: null,
  FETCHING_COMPANIES: null,
  FETCHING_COMPANIES_FAILED: null,
  FETCHING_COMPANIES_SUCCESS: null,
  FETCHING_COMPANY: null,
  FETCHING_COMPANY_FAILED: null,
  FETCHING_COMPANY_SUCCESS: null,
  IS_REDIRECT: null
})

export const CompanyActions = createActions([
  'clearToast',
  'createEmptyCompany',
  'getCompanies',
  'getCompanyDetails',
  'updateCompanyField',
  'saveCompany'
])

export class CompanyStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = CompanyActions
    this.state = {
      status: null,
      companyDetails: {
        companyName: '',
        companyNameShort: ''
      },
      companies: []
    }
  }
  clearToast(status) {
    status ? this.setState({ status }) : this.setState({ status: null })
  }
  createEmptyCompany() {
    this.setState({
      companyDetails: {
        companyName: '',
        companyNameShort: ''
      }
    })
  }
  getCompanies() {
    if (this.state.status === STATUS.FETCHING_COMPANIES) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_COMPANIES })
    GET('companies/pool').then(responseJSON => {
      let nextState = {
        companies: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_COMPANIES_FAILED
      } else {
        nextState.companies = responseJSON
        nextState.status = STATUS.FETCHING_COMPANIES_SUCCESS
      }
      this.setState(nextState)
    })
  }
  getCompanyDetails(CompanyID) {
    this.setState({ status: STATUS.FETCHING_COMPANY })
    GET('companies/fetch', { CompanyID }).then(responseJSON => {
      let nextState = {
        companyDetails: {}
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_COMPANY_FAILED
      } else {
        nextState.companyDetails = responseJSON
        nextState.status = STATUS.FETCHING_COMPANY_SUCCESS
      }
      this.setState(nextState)
    })
  }
  updateCompanyField(field) {
    let value = strip_tags(field.target.value)
    let companyDetails = this.state.companyDetails
    companyDetails[field.target.name] = value
    this.setState({ companyDetails })
  }
  saveCompany() {
    if (this.state.companyDetails.name != '' && this.state.companyDetails.shortName != '') {
      this.setState({ status: STATUS.IS_SAVING })
      POST('companies/replace', this.state.companyDetails).then(responseJSON => {
        let nextState = {
          companyDetails: this.state.companyDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.COMPANY_FAILED
          } else {
            nextState.companyDetails = responseJSON
            nextState.status = STATUS.COMPANY_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }
}
