import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { VaultStore, VaultActions, STATUS } from 'Stores/vaultStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Styled from 'styled-components'

const StyledButton = Styled(Button)`
&:disabled {
  background-color: #c8ced3 !important;
  border-color: #c8ced3 !important;
}
`

export default class VaultList extends Component {
  constructor(props) {
    super(props)
    this.stores = [VaultStore]
    this.storeKeys = ['loadingState', 'vaults']
  }

  componentDidMount() {
    VaultActions.getVaults()
  }

  componentDidUpdate() {
    if (this.state.loadingState === STATUS.FETCHING_VAULT_LIST_FAILED) {
      toast.error('FETCHING VAULTS FAILED', {
        autoClose: 7500,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast()
    }
    if (this.state.loadingState === STATUS.DELETE_VAULT_ERROR) {
      toast.error('VAULTS DELETE FAILED', {
        autoClose: 7500,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast()
    }
    if (this.state.loadingState === STATUS.DELETE_VAULT_SUCCESS) {
      toast.success('SUCCESSFULLY DELETED', {
        autoClose: 7500,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast()
      VaultActions.getVaults()
    }
    if (this.state.loadingState === STATUS.VAULT_SUCCESS) {
      toast.success('SUCCESSFULLY SET VAULT AS DEFAULT', {
        autoClose: 3000,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast()
      VaultActions.getVaults()
    }
  }

  render() {
    if (this.state.loadingState === STATUS.FETCHING_VAULT_LIST) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>VAULTS</strong>}>
        <DynamicTable
          responsive
          size='sm'
          data={this.state.vaults}
          headers={{
            VaultID: 'vault ID',
            BrandID: 'Brand ID',
            vaultName: 'Vault Name',
            vaultSlug: 'Vault Slug',
            timeInsert: 'Time Insert',
            timeUpdate: 'Time Update',
            isDefault: 'Default'
          }}
          options={{
            startSort: {
              dir: 'DESC',
              column: 'VaultID'
            },
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD') : ''
              },
              timeUpdate: e => {
                return e.timeUpdate ? moment(e.timeUpdate).format('YYYY-MM-DD') : ''
              },
              isDefault: e => {
                return e.isDefault === 1 ? 'Yes' : ''
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.VaultID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/vaults/' + e.VaultID} id={'edit' + e.VaultID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.VaultID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.VaultID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne vaults?')) {
                          VaultActions.deleteVault(e.VaultID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                    <Tooltip target={'default' + e.VaultID}>Set Default</Tooltip>
                    <StyledButton disabled={e.isDefault ? true : false} onClick={() => VaultActions.setToDefault(e)} id={'default' + e.VaultID}>
                      <FontAwesomeIcon icon='check-circle' />
                    </StyledButton>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
