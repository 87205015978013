import React from 'react'
import PointsQuarterAndMonthChart from 'Components/dashboard/charts/PointsQuarterAndMonthChart.js'
import _ from 'lodash'
import moment from 'moment'
import useDashboardData from 'Components/dashboard/useDashboardData.js'

export default function KamUsersPeriodGraph() {
  const [data, loadingState] = useDashboardData('supervisors/active-inactive-users-period')

  return (
    <PointsQuarterAndMonthChart
      header={'Brugere'}
      state={loadingState}
      monthlyData={_.map(
        _.mapValues(_.get(data, 'monthlyUsersIndex'), (value, key) => ({
          Month: moment(key, 'MM/YYYY').format('MMM YY'),
          Aktiv: value.active,
          Inaktiv: value.inactive
        }))
      )}
      quarterlyData={_.map(
        _.mapValues(_.get(data, 'quarterlyUsersIndex'), (value, key) => ({
          Month: moment(key, 'Q/YYYY').format('Qo[kvt.] YYYY'),
          Aktiv: value.active,
          Inaktiv: value.inactive
        }))
      )}
      keys={['Aktiv', 'Inaktiv']}
      indexBy={'Month'}
      axisLabel='Brugere'
    />
  )
}
