import React, { useState } from 'react'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import styled from 'styled-components'
import BarChart from 'Components/dashboard/charts/BarChart.js'
import keyMirror from 'keymirror'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TimePicker from '../../../util/TimePicker'
const SELECTED_DATA_VIEW = keyMirror({
  YEAR: null,
  QUARTER: null
})

const DataView = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DataViewSelector = styled.button`
  color: #000;
  border: 0;
  background: transparent;
  text-decoration: ${props => props.active && 'underline;'}
  font-weight: ${props => props.active && '700;'}
  
  &:hover,
  &:focus {    
    text-decoration: underline;
    outline: 0;
  }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: relative;
  font-size: 18px;
  float: right;
  bottom: '482px';
  margin-right: 40px !important;
`

export default function PointsQuarterAndMonthChart({
  header,
  state,
  monthlyData,
  calenderToggle,
  setFilterToggle,
  getFilterDetails,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  quarterlyData,
  showIcon,
  keys,
  indexBy,
  legends,
  axisLabel
}) {
  const [selectedDataView, setDataView] = useState(SELECTED_DATA_VIEW.YEAR)

  const changeDataView = dataView => {
    setDataView(dataView)
  }

  return (
    <DashboardComponent header={header} state={state}>
      {state === DASHBOARD_COMPONENT_STATE.SUCCESS && (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
            {showIcon && <StyledFontAwesomeIcon onClick={() => setFilterToggle()} fixedWidth icon='calendar' />}
            <DataView className='mb-1'>
              <DataViewSelector onClick={() => changeDataView(SELECTED_DATA_VIEW.YEAR)} active={selectedDataView === SELECTED_DATA_VIEW.YEAR}>
                År
              </DataViewSelector>
              <DataViewSelector onClick={() => changeDataView(SELECTED_DATA_VIEW.QUARTER)} active={selectedDataView === SELECTED_DATA_VIEW.QUARTER}>
                Kvartal
              </DataViewSelector>
            </DataView>
            {calenderToggle && (
              <div style={{ position: 'absolute', top: 30, zIndex: 1 }}>
                <TimePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  getFilterDetails={getFilterDetails}
                />
              </div>
            )}
          </div>
          <BarChart
            keys={keys}
            indexBy={indexBy}
            data={selectedDataView === SELECTED_DATA_VIEW.YEAR ? monthlyData : quarterlyData}
            bottomTickRotation={selectedDataView === SELECTED_DATA_VIEW.YEAR ? -40 : -20}
            legends={legends}
            axisLabel={axisLabel}
          />
        </>
      )}
    </DashboardComponent>
  )
}
