import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import SupplierForm from 'Modules/supplier/supplierForm'
import { clearToast } from 'Reducers/supplierReducer'
import { STATUS, getSupplierDetails } from 'Reducers/actions/supplierActions'
import { connect } from 'react-redux'

class SupplierEdit extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getSupplierDetails(this.props.match.params.SupplierID)
  }
  componentDidUpdate() {
    if (this.props.loadingState === STATUS.FETCHING_SUPPLIER_DETAILS_FAILED) {
      toast.error('FETCHING SUPPLIER FAILED', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }

    if (this.props.loadingState === STATUS.SUPPLIER_FAILED) {
      toast.error('UPDATE SUPPLIER FAILED', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
    if (this.props.loadingState === STATUS.SUPPLIER_SUCCESS) {
      toast.success('UPDATE SUPPLIER SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast(STATUS.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <SupplierForm {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ loadingState: state.supplier.loadingState })

export default connect(mapStateToProps, { getSupplierDetails, clearToast })(SupplierEdit)
