import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import '../scss/tagsInputs.scss'

export const TagsInput = props => {
  const [tags, setTags] = useState(props.tag)

  const removeTags = indexToRemove => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)])
    props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)])
  }

  const addTags = event => {
    let val = event.target.value.trim()
    if (val !== '' && val !== ',') {
      if (val[val.length - 1] === ',') {
        val = val.substr(0, val.length - 1)
      }
      setTags([...tags, val])
      props.selectedTags([...tags, val])
      event.target.value = ''
    }
  }

  const removeAllTags = () => {
    setTags([])
    props.selectedTags([])
  }

  useEffect(() => {
    setTags(props.tag)
  }, [props.tag])

  return (
    <div className='tags-input'>
      <ul id='tags'>
        {tags.map((tag, index) => (
          <li key={index} className='tag'>
            <span className='tag-title'>{tag}</span>
            <span className='tag-close-icon' onClick={() => removeTags(index)}>
              x
            </span>
          </li>
        ))}
      </ul>
      <Input
        disabled={props.disabled}
        type='text'
        onKeyUp={event => (event.key === 'Enter' || event.key === ',' ? addTags(event) : null)}
        placeholder='Press enter or comma to add tags'
      />
      <span className='removeall' onClick={() => removeAllTags()}>
        X
      </span>
    </div>
  )
}
