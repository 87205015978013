import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import { CatalogActions, CatalogStore, STATUS } from 'Stores/catalogStore'

export default class CatalogList extends Component {
  constructor(props) {
    super(props)
    this.stores = [CatalogStore]
    this.storeKeys = ['catalogs', 'status']
  }
  componentDidMount() {
    CatalogActions.getCatalogs()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_CATALOGS_ERROR) {
      toast.error('Fetching Catalogs Failed', {
        autoClose: 7500,
        onClose: () => {
          CatalogActions.clearToast()
        }
      })
      CatalogActions.clearToast()
    }
    if (this.state.status === STATUS.CATALOG_DELETE_ERROR) {
      toast.error('Delete Catalog Failed', {
        autoClose: 7500,
        onClose: () => {
          CatalogActions.clearToast()
        }
      })
      CatalogActions.clearToast()
    }
    if (this.state.status === STATUS.CATALOG_DELETE) {
      toast.success('Catalog Deleted', {
        autoClose: 7500,
        onClose: () => {
          CatalogActions.clearToast()
        }
      })
      CatalogActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_CATALOGS) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Catalogs</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'CatalogID'
            },
            fieldFunctions: {
              timeStart: e => {
                if (e.timeStart) {
                  return moment(e.timeStart).format('DD-MM-YYYY ')
                }
              },
              timeEnd: e => {
                if (e.timeEnd) {
                  return moment(e.timeEnd).format('DD-MM-YYYY')
                }
              }
            },
            fieldStringFunctions: {
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'view' + e.CatalogID}>Vis</Tooltip>
                    <Button tag={Link} to={'/products?CatalogID=' + e.CatalogID} id={'view' + e.CatalogID}>
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    <Tooltip target={'edit' + e.CatalogID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/catalogs/' + e.CatalogID} id={'edit' + e.CatalogID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.CatalogID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.CatalogID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne catalog?')) {
                          CatalogActions.deleteCatalog(e.CatalogID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            moduleName: 'Name',
            moduleDescription: 'Description',
            timeStart: 'Start Date',
            timeEnd: 'End Date',
            catalogType: 'Catalog Type',
            catalogAccessType: 'Catalog AccessType'
          }}
          data={this.state.catalogs}
        />
      </CollapsibleCard>
    )
  }
}
