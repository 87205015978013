import React from 'react'
import styled from 'styled-components'
import { Card, CardBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import keyMirror from 'keymirror'

const Header = styled.h4`
  text-align: center;
  margin-bottom: 0;
  padding-left: 15px;
`

const ErrorAndLoadingState = styled.div`
  text-align: center;
`

const Content = styled.div``

export const STATE = keyMirror({
  LOADING: null,
  FAILED: null,
  SUCCESS: null
})

export default function DashboardComponent({ children, header, state = STATE.LOADING }) {
  return (
    <Card className='position-relative'>
      <CardBody>
        <Header>{header}</Header>
        <Content>
          {state === STATE.SUCCESS && children}

          {state === STATE.LOADING && (
            <ErrorAndLoadingState className='mt-3'>
              <FontAwesomeIcon icon='spinner' size='2x' spin />
            </ErrorAndLoadingState>
          )}

          {state === STATE.FAILED && (
            <ErrorAndLoadingState className='mt-3'>
              <FontAwesomeIcon className='text-danger' icon='exclamation-circle' size='3x' />
              <p>Ukendt fejl, prøv at refresh siden</p>
            </ErrorAndLoadingState>
          )}
        </Content>
      </CardBody>
    </Card>
  )
}
