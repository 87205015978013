import React, { useEffect, useState, useRef, useCallback } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { Form, Button, FormGroup, Label, Col, Input, Row, UncontrolledTooltip } from 'reactstrap'
import 'react-image-crop/dist/ReactCrop.css'
import Proxies from 'Components/proxies'
import { StyledDropdown } from '../../campaigns/SantanderCampaignUserSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { getCroppedImg } from 'Modules/products/ProductImageUpload.js'
import TranslatableFieldsBlock from '../../translations/TranslatableFieldsBlock'

const { GET } = Proxies

const ShopForm = props => {
  const [loading, setLoading] = useState(false)
  const { event, idx, shops, setShops, ShopBannerID } = props
  const defaultCrop = { unit: '%', x: 0, y: 0, width: 100, aspect: 12 / 5 }
  const [shopDetails, setShopDetails] = useState({
    textColor: 'black'
  })
  const [brands, setBrands] = useState([])
  const [uploadedImage, setUploadedImage] = useState(null)
  const [uploadedVideo, setUploadedVideo] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadingVideo, setIsUploadingVideo] = useState(false)
  const [crop, setCrop] = useState(defaultCrop)
  const imgRef = useRef(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [origImg, setOrigImg] = useState(null)
  const [origVideo, setOrigVideo] = useState(null)
  const [uploadedImageURL, setUploadedImageURL] = useState(null)
  const [uploadedVideoURL, setUploadedVideoURL] = useState(null)
  const [selectedID, setSelectedID] = useState(ShopBannerID ? true : false)
  const [languages, setLanguages] = useState([])

  const fetchShop = ShopBannerID => {
    GET('shopbanners/fetch', { ShopBannerID }).then(responseJSON => {
      if (!responseJSON.error) {
        setShopDetails({
          ShopBannerID,
          BrandIDs: responseJSON.BrandIDs,
          title: responseJSON.title,
          content: responseJSON.content,
          ImageID: responseJSON.ImageID ? responseJSON.ImageID : null,
          isVideo: responseJSON.isVideo,
          videoSrc: responseJSON.videoSrc ? responseJSON.videoSrc : null,
          textColor: responseJSON.textColor,
          translations: responseJSON.translations.length ? responseJSON.translations : [{ title: '', LanguageID: '', content: '' }]
        })
        if (!!responseJSON.ImageID && !responseJSON.isVideo) {
          setUploadedImageURL(responseJSON.image[0])
        } else if (!!responseJSON.videoSrc && !!responseJSON.isVideo) {
          setUploadedVideoURL(responseJSON.videoSrc)
        }
        setShops([
          {
            ShopBannerID,
            BrandIDs: responseJSON.BrandIDs,
            title: responseJSON.title,
            content: responseJSON.content,
            ImageID: responseJSON.ImageID ? responseJSON.ImageID : null,
            isVideo: responseJSON.isVideo,
            videoSrc: responseJSON.videoSrc ? responseJSON.videoSrc : null,
            textColor: responseJSON.textColor,
            translations: responseJSON.translations.length ? responseJSON.translations : [{ title: '', LanguageID: '', content: '' }]
          }
        ])
        !!responseJSON && !!responseJSON.BrandIDs && !!responseJSON.BrandIDs.length && getLanguages(responseJSON.BrandIDs)
      }
    })
  }

  function getLanguages(BrandIDs) {
    if (!BrandIDs || !BrandIDs.length) return
    GET('brands/brand-languages', { BrandIDs }).then(responseJSON => {
      if (responseJSON.error) {
        // console.error(responseJSON.error)
      } else {
        setLanguages(responseJSON)
      }
    })
  }

  const editBrandIDS = item => {
    let selectedBrand = brands.map(brand => {
      for (let i = 0; i < item?.length; i++) {
        if (Number(brand.BrandID) === Number(item[i])) {
          brand.checked = true
          break
        }
      }
      return brand
    })
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleInputs('BrandIDs', BrandIDs)
    setSelectedID(false)
  }

  const fetchBrands = useCallback(() => {
    setLoading(true)
    GET('brands/getAssociatedBrands').then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) return setBrands([])
      let allbrands = responseJSON.map(item => ({ BrandID: item.BrandID, brandName: item.brandName }))
      allbrands.sort((a, b) => a.brandName.localeCompare(b.brandName))
      setBrands(allbrands)
    })
  }, [])

  const updateBrands = item => {
    let selectedBrand = brands.map(brand => {
      if (Number(brand.BrandID) === Number(item.value)) brand.checked = item.checked
      return brand
    })
    setBrands(selectedBrand)
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleInputs('BrandIDs', BrandIDs)
  }

  const onApprove = () => {
    getCroppedImg(imgRef.current, completedCrop, origImg.name).then(result => {
      setIsUploading(true)
      Proxies.uploadImage(result).then(responseJSON => {
        setShopDetails({ ...shopDetails, ImageID: responseJSON.ImageID })
        handleInputs('ImageID', responseJSON.ImageID)
        setUploadedImageURL(responseJSON)
        setUploadedImage(null)
        setIsUploading(false)
        setOrigImg(null)
        setCrop(defaultCrop)
        setCompletedCrop(null)
      })
    })
  }

  const onApproveVideo = () => {
    setIsUploadingVideo(true)
    Proxies.uploadVideo(origVideo).then(responseJSON => {
      setShopDetails({ ...shopDetails, videoSrc: responseJSON })
      handleInputs('videoSrc', responseJSON)
      setUploadedVideoURL(responseJSON)
      setUploadedVideo(null)
      setIsUploadingVideo(false)
      setOrigVideo(null)
    })
  }

  const handleVideoState = val => {
    setShopDetails({ ...shopDetails, isVideo: val })
    handleInputs('isVideo', val)
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  const uploadFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadedImageURL(null)
      const reader = new FileReader()
      reader.addEventListener('load', () => setUploadedImage(reader.result))
      const file = e.target.files[0]
      reader.readAsDataURL(file)
      setOrigImg(file)
    }
  }

  const uploadFileVideo = e => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadedVideoURL(null)
      const reader = new FileReader()
      reader.addEventListener('load', () => setUploadedVideo(reader.result))
      const file = e.target.files[0]
      reader.readAsDataURL(file)
      setOrigVideo(file)
    }
  }

  const handleInputs = (key, value) => {
    let res = { ...shops[idx], [key]: value }
    shops[idx] = res
    setShops([...shops])
  }

  const handleTextColor = val => {
    setShopDetails({ ...shopDetails, textColor: val })
    handleInputs('textColor', val)
  }

  const removeForm = () => {
    let removeIdx = shops.filter((e, id) => {
      if (id !== idx) return e
    })
    setShops(removeIdx)
  }

  useEffect(() => {
    fetchBrands()
  }, [])

  useEffect(() => {
    let allShops = shops
    allShops[idx] = shopDetails
    setShops(allShops)
  }, [shopDetails])

  /*
  const addTranslation = () => {
    let translations = shopDetails.translations
    translations.push({ title: '', LanguageID: '', content: '' })
    setShopDetails({ ...shopDetails, translations: translations })
  }

  const removeTranslation = i => {
    let translations = shopDetails.translations
    translations.splice(i, 1)
    setShopDetails({ ...shopDetails, translations: translations })
  }

  const updateData = (field, index, type) => {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let tempArray = shopDetails[type]
    tempArray[index][fieldName] = fieldValue
    setShopDetails({ ...shopDetails, [type]: tempArray })
  }
  */

  const getTitle = LanguageID => {
    if (!shopDetails) return
    if (!shopDetails.translations || !shopDetails.translations.length) {
      return shopDetails.title
    }
    return (
      shopDetails.translations.sort((a, b) => b.ShopBannerTranslationID - a.ShopBannerTranslationID).filter(l => l.LanguageID === LanguageID)[0]
        ?.title || ''
    )
  }

  const getContent = LanguageID => {
    if (!shopDetails) return
    if (!shopDetails.translations || !shopDetails.translations.length) {
      return shopDetails.content
    }
    return (
      shopDetails.translations.sort((a, b) => b.ShopBannerTranslationID - a.ShopBannerTranslationID).filter(l => l.LanguageID === LanguageID)[0]
        ?.content || ''
    )
  }

  const updateTranslatableFieldValues = translationData => {
    let currentValues = shopDetails.translations || []
    const otherLangValues = currentValues.filter(i => i.LanguageID !== translationData.LanguageID)
    currentValues = [...otherLangValues, translationData]
    setShopDetails({ ...shopDetails, translations: currentValues })
  }

  useEffect(() => {
    if (!!ShopBannerID && brands.length > 0 && selectedID) {
      return editBrandIDS(shopDetails.BrandIDs)
    }
    getLanguages(shops[idx].BrandIDs)
  }, [brands])

  useEffect(() => {
    ShopBannerID ? fetchShop(ShopBannerID) : null
  }, [ShopBannerID])

  useEffect(() => {
    setShopDetails(event)
  }, [event])

  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <React.Fragment>
            <strong>Shop</strong>{' '}
          </React.Fragment>
        }
      >
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <>
            <Form
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              {/*Brands*/}
              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='BrandID'> Brands </Label>
                  </Col>
                  <Col xs='9'>
                    <StyledDropdown
                      required={true}
                      data={
                        !brands.length
                          ? {}
                          : {
                              label: 'All',
                              value: 'all',
                              expanded: true,
                              children: brands.map(item => ({
                                label: `${item.BrandID} - ${item.brandName}`,
                                value: item.BrandID,
                                checked: item.checked
                              }))
                            }
                      }
                      onChange={item => updateBrands(item)}
                      keepTreeOnSearch={true}
                      keepChildrenOnSearch={true}
                      showPartiallySelected={true}
                      texts={{
                        placeholder: !brands.length ? 'Ingen brand fundet' : 'Vælg brand',
                        noMatches: 'Ingen brand fundet',
                        labelRemove: 'Fjern'
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <Row>
                {!!languages.length &&
                  languages.map(lang => (
                    <Col xs='6' key={`SHOP_TEXT_FIELDS_${lang.LanguageID}`}>
                      <TranslatableFieldsBlock
                        languageName={lang.languageName}
                        LanguageID={lang.LanguageID}
                        title={getTitle(lang.LanguageID)}
                        description={getContent(lang.LanguageID)}
                        onChangeCallback={e => updateTranslatableFieldValues(e)}
                      />
                    </Col>
                  ))}
              </Row>

              {/*Image*/}
              <FormGroup row>
                <Label for='Color' sm={3}>
                  <Label for='checked_image'>Image </Label>
                  <span>
                    <FontAwesomeIcon icon='info-circle' id='shopBannerImageTooltip' style={{ color: 'blue' }} />
                    <UncontrolledTooltip target='shopBannerImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
                  </span>
                </Label>
                <Col sm={7} style={{ display: 'flex', gap: '20px' }}>
                  <Input
                    id='checked_image'
                    name='upload_shop_file'
                    type='radio'
                    checked={!shopDetails.isVideo}
                    onChange={() => handleVideoState(false)}
                  />
                  <Input type='file' accept='image/*' onClick={e => (e.target.value = '')} onChange={uploadFile} disabled={isUploading} />
                </Col>
              </FormGroup>
              {uploadedImageURL && Object.keys(uploadedImageURL).length > 0 && (
                <Row>
                  <Col sm={3}></Col>
                  <Col sm={3}>
                    <img style={{ width: '100%', objectFit: 'contain' }} src={uploadedImageURL.imageOriginSource} alt={uploadedImageURL.imageName} />
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={3}></Col>
                <Col>
                  {uploadedImage && (
                    <>
                      <Col>
                        <ReactCrop
                          className='mt-2'
                          src={uploadedImage}
                          crop={crop}
                          onImageLoaded={onLoad}
                          onChange={c => setCrop(c)}
                          onComplete={c => setCompletedCrop(c)}
                        />
                      </Col>
                    </>
                  )}
                </Col>
              </Row>
              {origImg && (
                <Row>
                  <Col sm={3}></Col>
                  <Col className='mt-2'>
                    <Button onClick={onApprove}>{isUploading ? <FontAwesomeIcon icon='spinner' spin /> : 'Godkend'}</Button>
                  </Col>
                </Row>
              )}
              <br></br>

              {/*video*/}
              <FormGroup row>
                <Label for='Color' sm={3}>
                  <Label for='checked_video'>Video </Label>
                  <span>
                    <FontAwesomeIcon icon='info-circle' id='shopBannerVideoTooltip' style={{ color: 'blue' }} />
                    <UncontrolledTooltip target='shopBannerVideoTooltip'>Best Size for Video: 1440 x 700 </UncontrolledTooltip>
                  </span>
                </Label>
                <Col sm={7} style={{ display: 'flex', gap: '20px' }}>
                  <Input
                    id='checked_video'
                    name='upload_shop_file'
                    type='radio'
                    checked={shopDetails.isVideo}
                    onChange={() => handleVideoState(true)}
                  />
                  <Input type='file' accept='video/*' onClick={e => (e.target.value = '')} onChange={uploadFileVideo} disabled={isUploadingVideo} />
                </Col>
              </FormGroup>
              {uploadedVideoURL && Object.keys(uploadedVideoURL).length > 0 && (
                <Row>
                  <Col sm={3}></Col>
                  <Col>
                    <video controls className='mt-2' src={uploadedVideoURL} style={{ width: '33%' }} />
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={3}></Col>
                <Col>
                  {uploadedVideo && (
                    <>
                      <video controls className='mt-2' src={uploadedVideo} style={{ width: '33%' }} />
                    </>
                  )}
                </Col>
              </Row>
              {origVideo && (
                <Row>
                  <Col sm={3}></Col>
                  <Col className='mt-2'>
                    <Button onClick={onApproveVideo}>{isUploadingVideo ? <FontAwesomeIcon icon='spinner' spin /> : 'Godkend'}</Button>
                  </Col>
                </Row>
              )}
              <br></br>

              {/*Text color*/}
              <FormGroup row>
                <Label for='textColor' sm={3}>
                  Shop BannerTextColor
                </Label>
                <Col sm={3}>
                  <Col sm={7} style={{ display: 'flex', gap: '20px' }}>
                    <Label for='textColorWhite'>White</Label>
                    <Input
                      id='textColorWhite'
                      name='textColor'
                      type='checkbox'
                      checked={shopDetails.textColor === 'white' ? true : false}
                      onChange={e => handleTextColor(e.target.checked ? 'white' : 'black')}
                    />
                  </Col>
                  <Col sm={7} style={{ display: 'flex', gap: '20px' }}>
                    <Label for='textColorBlack'> Black</Label>
                    <Input
                      id='textColorBlack'
                      name='textColor'
                      type='checkbox'
                      checked={shopDetails.textColor === 'black' ? true : false}
                      onChange={e => handleTextColor(e.target.checked ? 'black' : 'white')}
                    />
                  </Col>
                </Col>
              </FormGroup>
            </Form>
            {!ShopBannerID ? (
              <div style={{ marginBottom: '5%' }}>
                <Button
                  className='float-right'
                  color='danger'
                  onClick={() => {
                    removeForm()
                  }}
                >
                  <FontAwesomeIcon icon='minus' />
                </Button>
              </div>
            ) : null}
          </>
        )}
      </CollapsibleCard>
    </>
  )
}

export default ShopForm
