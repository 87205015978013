import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'
export const STATUS = new keyMirror({
  FETCHING_BATCHES: null,
  BATCHES_SUCCESS: null,
  BATCHES_ERROR: null,
  FETCHING_BATCH_PREVIEW: null,
  BATCH_PREVIEW_SUCCESS: null,
  BATCH_PREVIEW_ERROR: null,
  BATCH_INSERT_SUCCESS: null,
  BATCH_INSERT_ERROR: null,
  BATCH_DELETE_SUCCESS: null,
  BATCH_DELETE_ERROR: null,
  REMOVE_BATCH_CONTENT_FAILED: null,
  REMOVE_BATCH_CONTENT_SUCCESS: null,
  FETCHING_TOTAL_BATCHES: null,
  FETCHING_TOTAL_BATCHES_FAILED: null,
  REMOVING_BATCH: null,
  REMOVE_BATCH_FAILED: null
})

export const BatchActions = createActions([
  'getBatches',
  'clearToast',
  'getBatchPreview',
  'deleteBatch',
  'insertBatch',
  'removeBatchContent',
  'getTotalBatchList',
  'preserveAdvanceSearch',
  'removeBatch',
  'toggleInActiveBatch',
  'toggleActiveBatch'
])

export class BatchStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = BatchActions
    this.state = {
      status: null,
      batches: [],
      batchPreview: [],
      currBatchID: null,
      batchSaving: false,
      BatchID: null,
      removeBatchData: false,
      removedBatchRecords: '',
      batchInsertError: '',
      batchList: [],
      advanceSearchFilters: [],
      deleteBatchID: null,
      showActiveBatches: true,
      showInActiveBatches: false,
      batchFilter: 'active'
    }
  }

  clearToast() {
    this.setState({ status: null, removedBatchRecords: '' })
  }

  getBatches(DatasetID = null) {
    if (this.state.status === STATUS.FETCHING_BATCHES) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_BATCHES })
    GET('batch/pool', { DatasetID }).then(responseJSON => {
      let nextState = {
        batches: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.BATCHES_ERROR
        } else {
          nextState.status = STATUS.BATCHES_SUCCESS
          nextState.batches = responseJSON
        }
      }
      this.setState(nextState)
    })
  }

  getBatchPreview(BatchID = null) {
    if (this.state.status === STATUS.FETCHING_BATCH_PREVIEW) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_BATCH_PREVIEW, currBatchID: BatchID })
    GET('batch/preview', { BatchID }).then(responseJSON => {
      let nextState = {
        batchPreview: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.BATCH_PREVIEW_ERROR
        } else {
          nextState.status = STATUS.BATCH_PREVIEW_SUCCESS
          nextState.batchPreview = responseJSON.validatedCsvRecords
        }
      }
      this.setState(nextState)
    })
  }

  deleteBatch(BatchID = null, DatasetID = null) {
    if (BatchID) {
      POST('batch/remove', { BatchID: BatchID }).then(responseJSON => {
        let nextState = {}
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.BATCH_DELETE_ERROR
          } else {
            nextState.status = STATUS.BATCH_DELETE_SUCCESS
            this.getBatches(DatasetID)
          }
        }
        this.setState(nextState)
      })
    }
  }

  insertBatch(BatchID = null, DatasetID = null) {
    if (this.state.batchSaving) {
      return
    }
    if (BatchID) {
      this.setState({ batchSaving: true, BatchID: BatchID })
      POST('batch/insert', { BatchID: BatchID }).then(responseJSON => {
        let nextState = {
          batchSaving: false,
          BatchID: null,
          batchInsertError: ''
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.BATCH_INSERT_ERROR
            nextState.batchInsertError = responseJSON.error.message
          } else {
            nextState.status = STATUS.BATCH_INSERT_SUCCESS
            DatasetID && this.getBatches(DatasetID)
          }
        }
        this.setState(nextState)
      })
    }
  }

  removeBatchContent(BatchID = null, removed) {
    if (this.state.removeBatchData) {
      return
    }
    if (BatchID) {
      this.setState({ removeBatchData: true, BatchID: BatchID, removedBatchRecords: '' })
      POST('batch/remove-batch-content', { BatchID: BatchID, removed: removed }).then(responseJSON => {
        let nextState = {
          removeBatchData: false,
          BatchID: null,
          status: null
        }
        if (responseJSON && responseJSON.error) {
          nextState.status = STATUS.REMOVE_BATCH_CONTENT_FAILED
        } else {
          nextState.status = STATUS.REMOVE_BATCH_CONTENT_SUCCESS
          nextState.removedBatchRecords = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
  removeBatch(BatchID, removed) {
    if (BatchID) {
      this.setState({ deleteBatchID: BatchID, status: STATUS.REMOVING_BATCH })
      POST('batch/admin-remove-batch', { BatchID: BatchID, removed: !removed }).then(responseJSON => {
        let nextState = {
          status: null
        }
        if (responseJSON.error) {
          nextState.status = STATUS.REMOVE_BATCH_FAILED
        } else {
          this.getTotalBatchList()
        }
        this.setState(nextState)
      })
    }
  }
  toggleActiveBatch() {
    let value = !this.state.showActiveBatches ? 'active' : 'both'
    this.setState({ showActiveBatches: !this.state.showActiveBatches, batchFilter: value, showInActiveBatches: false })
  }
  toggleInActiveBatch() {
    let value = this.state.showInActiveBatches ? 'both' : 'inactive'
    this.setState({ showInActiveBatches: !this.state.showInActiveBatches, batchFilter: value, showActiveBatches: false })
  }
  getTotalBatchList() {
    if (this.state.status === STATUS.FETCHING_TOTAL_BATCHES) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_TOTAL_BATCHES })
    GET('batch/admin-pool').then(responseJSON => {
      let nextState = {
        batchList: [],
        status: null
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_TOTAL_BATCHES_FAILED
        } else {
          nextState.batchList = responseJSON
        }
      }
      this.setState(nextState)
    })
  }
}
