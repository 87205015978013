import React, { Fragment } from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import 'moment/locale/da'
import { Button, Form, Input, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import FormField from 'Components/formField'
import { CustomizationStore, CustomizationActions } from 'Stores/customizationStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoginStore } from 'Stores/loginStore'

export default class Customization extends Component {
  constructor(props) {
    super(props)
    this.stores = [CustomizationStore, LoginStore]
    this.storeKeys = ['isMSBAdmin', 'customizations', 'settings', 'showActiveCustomizations', 'showInActiveCustomizations', 'customizationFilter']
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.brandName === 'MSB' &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      CustomizationActions.giveAccess()
      CustomizationActions.getCustomizations()
    } else {
      CustomizationActions.getCustomizationsData(this.state.settings)
    }
  }
  render() {
    return (
      <Fragment>
        <CollapsibleCard
          header={
            <React.Fragment>
              <strong>Oversigt</strong>
              <div className='float-right'>
                <CustomizationEditor />
                {this.state.isMSBAdmin && (
                  <ButtonGroup style={{ marginLeft: '10px' }}>
                    <Button
                      outline
                      color='success'
                      onClick={CustomizationActions.toggleActiveCustomization}
                      active={this.state.showActiveCustomizations}
                    >
                      Active
                    </Button>
                    <Button
                      outline
                      color='danger'
                      onClick={CustomizationActions.toggleInActiveCustomization}
                      active={this.state.showInActiveCustomizations}
                    >
                      In-Active
                    </Button>
                  </ButtonGroup>
                )}
              </div>
            </React.Fragment>
          }
          startOpen
        >
          <DynamicTable
            data={
              this.state.isMSBAdmin && this.state.customizations && this.state.customizationFilter !== 'both' && !!this.state.customizations.length
                ? this.state.customizations.filter(customisation => customisation.removed === (this.state.customizationFilter === 'inactive' ? 1 : 0))
                : this.state.customizations
            }
            headers={['CustomisationID', 'title', 'description', 'CustomisedID', 'customisedType', 'customisationData']}
            options={{
              startSort: {
                dir: 'DESC',
                column: 'CustomisationID'
              },
              fieldFunctions: {
                customisationData: row => {
                  return <CustomizationDetails details={row} />
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      {this.state.isMSBAdmin && (
                        <React.Fragment>
                          <CustomizationEditor data={e} showCopyCustomisation={true} />
                          {e.removed === 0 && (
                            <React.Fragment>
                              <Tooltip target={'deactivate' + e.CustomisationID}>Deaktivér</Tooltip>
                              <Button
                                id={'deactivate' + e.CustomisationID}
                                onClick={() => {
                                  if (confirm('Vil du deaktivere denne customisation?')) {
                                    CustomizationActions.deleteCustomization(e.CustomisationID, e.removed)
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon='user-check' />
                              </Button>
                            </React.Fragment>
                          )}
                          {e.removed === 1 && (
                            <React.Fragment>
                              <Tooltip target={'actiavte' + e.CustomisationID}>Aktivér</Tooltip>
                              <Button
                                id={'actiavte' + e.CustomisationID}
                                color='danger'
                                onClick={() => {
                                  if (confirm('Vil du aktivere denne Customisayion?')) {
                                    CustomizationActions.deleteCustomization(e.CustomisationID, e.removed)
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon='user-slash' />
                              </Button>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
          />
        </CollapsibleCard>
      </Fragment>
    )
  }
}

class CustomizationDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }))
  }
  render() {
    let { details } = this.props
    let { CustomisationID, title, description, customisationData, CustomisedID, customisedType } = details
    return (
      <Fragment>
        <Modal className='customization__modal' isOpen={this.state.modalOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>
            {description}
            <br />
            <pre>{JSON.stringify(customisationData, undefined, 2)}</pre>
          </ModalBody>
          <ModalFooter>
            <CustomizationEditor
              CustomisationID={CustomisationID}
              title={title}
              description={description}
              customisationData={JSON.stringify(customisationData, undefined, 2)}
              CustomisedID={CustomisedID}
              customisedType={customisedType}
            />
            <Button color='secondary' onClick={this.toggle}>
              Luk
            </Button>
          </ModalFooter>
        </Modal>
        <Button onClick={this.toggle}>
          <FontAwesomeIcon icon='info-circle' />
        </Button>
      </Fragment>
    )
  }
}

class CustomizationEditor extends Component {
  constructor(props) {
    super(props)
    this.stores = [CustomizationStore, LoginStore]
    this.storeKeys = ['isMSBAdmin', 'settings', 'customisedTypes']
    this.state = {
      modalOpen: false,
      CustomisationID: null,
      title: '',
      description: '',
      customisationData: null,
      customisedType: ''
    }
    this.save = this.save.bind(this)
    this.toggle = this.toggle.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.updateField = this.updateField.bind(this)
    this.create = false
    this.copyCustomisation = false
  }
  toggle() {
    this.resetForm()
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }))
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.brandName === 'MSB' &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      CustomizationActions.giveAccess()
    }
    this.resetForm()
  }
  resetForm() {
    let nextState = {}
    if (this.props.CustomisationID) {
      let { CustomisationID, title, description, customisationData, CustomisedID, customisedType } = this.props
      nextState = {
        CustomisationID,
        title,
        description,
        customisationData,
        CustomisedID,
        customisedType
      }
    } else if (this.copyCustomisation) {
      let { title, description, customisationData, CustomisedID, customisedType } = this.props.data
      nextState = {
        CustomisationID: null,
        title,
        description,
        customisationData: JSON.stringify(customisationData, undefined, 2),
        customisedType,
        CustomisedID
      }
      this.create = true
    } else {
      nextState = {
        CustomisationID: null,
        title: '',
        description: '',
        customisationData: '{}',
        customisedType: 'Brand',
        CustomisedID: this.state.isMSBAdmin ? 0 : this.state.settings.BrandID
      }
      this.create = true
    }
    this.setState(nextState)
  }
  save() {
    let { CustomisationID, title, description, customisationData, customisedType, CustomisedID } = this.state
    if (!this.IsJsonString(customisationData)) {
      return false
    }
    if ((!CustomisationID && !this.create) || !title || !description) {
      return false
    }
    if (this.create) {
      CustomizationActions.createCostumization(title, description, customisationData, customisedType, CustomisedID, this.state.settings)
      this.toggle()
    } else {
      CustomizationActions.updateCostumization(
        CustomisationID,
        title,
        description,
        customisationData,
        customisedType,
        CustomisedID,
        this.state.settings
      )
    }
  }
  updateCustomisedID = field => {
    if (!this.state.isMSBAdmin) {
      let CustomisedID = null
      switch (field.target.value.toLowerCase()) {
        case 'company':
          CustomisedID = this.state.settings.CompanyID
          break
        case 'client':
          CustomisedID = this.state.settings.ClientID
          break
        case 'brand':
          CustomisedID = this.state.settings.BrandID
          break
      }
      this.setState({ CustomisedID, customisedType: field.target.value })
    } else {
      this.setState({ customisedType: field.target.value })
    }
  }
  updateField(field) {
    let name = field.target.name
    let value = field.target.value
    let state = this.state
    if (name === 'CustomisedID') {
      state[name] = parseInt(value)
    } else {
      state[name] = value
    }
    if (!this.state.isMSBAdmin && state.CustomisedID === 0 && state.customisedType === 'Brand') {
      state.CustomisedID = this.state.settings.BrandID
    }
    this.setState(state)
  }
  IsJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  render() {
    return (
      <Fragment>
        <Modal className='customization__modal' isOpen={this.state.modalOpen}>
          <ModalHeader toggle={this.toggle}>{this.create ? 'Opret' : 'Rediger'}</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <FormField name='Customised Type' field='customisedType' required>
                <Input type='select' name='customisedType' value={this.state.customisedType} id='customisedType' onChange={this.updateCustomisedID}>
                  {this.state.customisedTypes.map((e, i) => {
                    return (
                      <option value={e} key={i}>
                        {e}
                      </option>
                    )
                  })}
                </Input>
              </FormField>
              {this.state.isMSBAdmin && (
                <FormField name='Customised ID' field='CustomisedID' required type='number' data={this.state} onChange={this.updateField} />
              )}
              <FormField name='Navn' field='title' required data={this.state} onChange={this.updateField} />
              <FormField name='Beskrivelse' field='description' required data={this.state} onChange={this.updateField} />
              <FormField
                name='customisationData'
                field='customisationData'
                data={this.state}
                onChange={this.updateField}
                type='textarea'
                validateFunction={this.IsJsonString}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.save}>
              Gem
            </Button>
            <Button color='secondary' onClick={this.toggle}>
              Luk
            </Button>
          </ModalFooter>
        </Modal>
        {!this.create && !this.props.showCopyCustomisation && <Button onClick={this.toggle}>Rediger</Button>}
        {this.create && !this.props.showCopyCustomisation && <Button onClick={this.toggle}>Opret Customization</Button>}
        {this.props.showCopyCustomisation && (
          <React.Fragment>
            <Button
              onClick={() => {
                this.copyCustomisation = true
                this.toggle()
              }}
            >
              <FontAwesomeIcon icon='copy' />
            </Button>
          </React.Fragment>
        )}
      </Fragment>
    )
  }
}
