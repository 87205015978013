import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { CategoryStore, CategoryActions, STATUS } from 'Stores/categoryStore'
import DeleteModal from 'Modules/deleteModal'

export default class CategoryList extends Component {
  constructor(props) {
    super(props)
    this.stores = [CategoryStore]
    this.storeKeys = ['loadingState', 'categories', 'category', 'openModal']
  }

  componentDidMount() {
    CategoryActions.getCategories()
  }

  componentDidUpdate() {
    if (this.state.loadingState === STATUS.FETCHING_CATEGORY_LIST_FAILED) {
      toast.error('FETCHING CATEGORIES FAILED', {
        autoClose: 7500,
        onClose: () => {
          CategoryActions.clearToast()
        }
      })
      CategoryActions.clearToast()
    }
    if (this.state.loadingState === STATUS.DELETE_CATEGORY_FAILED) {
      toast.error('CATEGORY DELETE FAILED', {
        autoClose: 7500,
        onClose: () => {
          CategoryActions.clearToast()
        }
      })
      CategoryActions.clearToast()
    }
    if (this.state.loadingState === STATUS.DELETE_CATEGORY_SUCCESS) {
      toast.success('SUCCESSFULLY DELETED', {
        autoClose: 7500,
        onClose: () => {
          CategoryActions.clearToast()
        }
      })
      CategoryActions.clearToast()
      CategoryActions.getCategories()
    }
  }

  displayTextTaxonomy(taxonomy) {
    if (taxonomy && taxonomy.categoryTaxonomy) {
      switch (taxonomy.categoryTaxonomy.toLowerCase()) {
        case 'manufacturer':
          return (taxonomy.categoryTaxonomy = 'BRAND')
        default:
          return taxonomy.categoryTaxonomy
      }
    }
  }

  render() {
    if (this.state.loadingState === STATUS.FETCHING_CATEGORY_LIST) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Categories</strong>}>
        <DynamicTable
          responsive
          size='sm'
          data={this.state.categories}
          headers={{
            CategoryID: 'Category ID',
            BrandID: 'Brand ID',
            categoryName: 'Category Name',
            categoryTaxonomy: 'Category Taxonomy',
            timeInsert: 'Created',
            imageURL: 'Image'
          }}
          options={{
            startSort: {
              dir: 'DESC',
              column: 'CategoryID'
            },
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD') : ''
              },
              categoryTaxonomy: val => this.displayTextTaxonomy(val),
              imageURL: e => {
                return e.imageURL ? 'Y' : 'N'
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.CategoryID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/category/' + e.CategoryID} id={'edit' + e.CategoryID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.CategoryID}>Slet</Tooltip>
                    <Button id={'delete' + e.CategoryID} color='danger' onClick={() => CategoryActions.openDeleteModal(e)}>
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
        {this.state.openModal && this.state.category && (
          <DeleteModal
            openModal={this.state.openModal}
            data={this.state.category}
            modalHeader='Delete Catalog'
            modalBody='Do you want to delete the category?'
            saveModal={() => CategoryActions.deleteCategory(this.state.category.CategoryID)}
          />
        )}
      </CollapsibleCard>
    )
  }
}
