import { createAction } from '@reduxjs/toolkit'
import Proxies from 'Components/proxies'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  LOADING_KAM_ALL_USERS: null,
  LOADING_KAM_ALL_USERS_SUCCESS: null,
  LOADING_KAM_ALL_USERS_FAILURE: null,
  LOADING_KAM_ALL_COMPANIES: null,
  LOADING_KAM_ALL_COMPANIES_SUCCESS: null,
  LOADING_KAM_ALL_COMPANIES_FAILURE: null,
  LOADING_OVERVIEW_USERS: null,
  LOADING_OVERVIEW_USERS_SUCCESS: null,
  LOADING_OVERVIEW_USERS_FAILURE: null,
  LOADING_COMPANY_SALES: null,
  LOADING_COMPANY_SALES_SUCCESS: null,
  LOADING_COMPANY_SALES_FAILURE: null,
  LOADING_USER_SALES: null,
  LOADING_USER_SALES_SUCCESS: null,
  LOADING_USER_SALES_FAILURE: null,
  LOADING_NEW_POSTS: null,
  LOADING_NEW_POSTS_SUCCESS: null,
  LOADING_NEW_POSTS_FAILED: null,
  LOADING_READ_POSTS: null,
  LOADING_READ_POSTS_SUCCESS: null,
  LOADING_READ_POSTS_FAILED: null,
  LOADING_ACTIVE_USERS: null,
  LOADING_ACTIVE_USERS_SUCCESS: null,
  LOADING_ACTIVE_USERS_FAILURE: null,
  LOADING_LIKES: null,
  LOADING_LIKES_SUCCESS: null,
  LOADING_LIKES_FAILES: null
})
export const fetchInitialStateData = createAction('fetchAllUsersRequest')
export const fetchAllUsersRequest = createAction('fetchAllUsersRequest')
export const fetchAllUsersSuccess = createAction('fetchAllUsersSuccess')
export const fetchAllUsersFailure = createAction('fetchAllUsersFailure')

export const fetchAllCompaniesRequest = createAction('fetchAllCompaniesRequest')
export const fetchAllCompaniesSuccess = createAction('fetchAllCompaniesSuccess')
export const fetchAllCompaniesFailure = createAction('fetchAllCompaniesFailure')

export const fetchOverviewUsersRequest = createAction('fetchOverviewUsersRequest')
export const fetchOverviewUsersSuccess = createAction('fetchOverviewUsersSuccess')
export const fetchOverviewUsersFailure = createAction('fetchOverviewUsersFailure')

export const fetchCompanySalesRequest = createAction('fetchCompanySalesRequest')
export const fetchCompanySalesSuccess = createAction('fetchCompanySalesSuccess')
export const fetchCompanySalesFailure = createAction('fetchCompanySalesFailure')
export const fetchCompanySalesInitialState = createAction('fetchCompanySalesInitialState')

export const fetchUserSalesRequest = createAction('fetchUserSalesRequest')
export const fetchUserSalesSuccess = createAction('fetchUserSalesSuccess')
export const fetchUserSalesFailure = createAction('fetchUserSalesFailure')
export const fetchUserSalesInitialState = createAction('fetchUserSalesInitialState')

// Users Sales
export const fetchUserSales = data => (dispatch, getState) => {
  const { fetchingUserSales } = getState().kamDashboard
  if (fetchingUserSales !== STATUS.LOADING_USER_SALES) {
    dispatch(fetchUserSalesRequest())
    Proxies.GET('supervisors/admin-usersales', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchUserSalesFailure())
      } else {
        dispatch(fetchUserSalesSuccess(responseJSON))
      }
    })
  }
}

//Company Sales
export const fetchCompanySales = data => (dispatch, getState) => {
  const { fetchingCompanySales } = getState().kamDashboard
  if (fetchingCompanySales !== STATUS.LOADING_COMPANY_SALES) {
    dispatch(fetchCompanySalesRequest())
    Proxies.GET('supervisors/admin-companysales', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchCompanySalesFailure())
      } else {
        dispatch(fetchCompanySalesSuccess(responseJSON))
      }
    })
  }
}

//KAM ALL USERS
export const fetchAllUsers = data => (dispatch, getState) => {
  const { usersLoadingStatus } = getState().kamDashboard
  if (usersLoadingStatus !== STATUS.LOADING_KAM_ALL_USERS) {
    dispatch(fetchAllUsersRequest())
    Proxies.GET('supervisors/admin-allusers', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchAllUsersFailure())
      } else {
        dispatch(fetchAllUsersSuccess(responseJSON))
      }
    })
  }
}

// Fetching ALL COMPANIES
export const fetchAllCompanies = data => (dispatch, getState) => {
  const { companyLoadingStatus } = getState().kamDashboard
  if (companyLoadingStatus !== STATUS.LOADING_KAM_ALL_COMPANIES) {
    dispatch(fetchAllCompaniesRequest())
    Proxies.GET('supervisors/admin-allcompanies', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchAllCompaniesFailure())
      } else {
        if (responseJSON && responseJSON.length) {
          responseJSON = responseJSON.filter(company => company.userCompanyUID && company.userCompany && company)
        }
        dispatch(fetchAllCompaniesSuccess(responseJSON))
      }
    })
  }
}

//fetch Users
export const fetchUsers = data => (dispatch, getState) => {
  const { fetchOverviewUsers } = getState().kamDashboard
  if (fetchOverviewUsers !== STATUS.LOADING_OVERVIEW_USERS) {
    dispatch(fetchOverviewUsersRequest())
    Proxies.GET('supervisors/admin-users', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchOverviewUsersFailure())
      } else {
        dispatch(fetchOverviewUsersSuccess(responseJSON))
      }
    })
  }
}

export const resetUserSales = () => dispatch => {
  dispatch(fetchUserSalesInitialState())
}

export const resetCompanySales = () => dispatch => {
  dispatch(fetchCompanySalesInitialState())
}

export const fetchInitialState = () => dispatch => {
  dispatch(fetchInitialStateData())
}

export const fetchNewPostsRequest = createAction('fetchNewPostsRequest')
export const fetchNewPostsSuccess = createAction('fetchNewPostsSuccess')
export const fetchNewPostsFailure = createAction('fetchNewPostsFailure')

export const fetchReadPostsRequest = createAction('fetchReadPostsRequest')
export const fetchReadPostsSuccess = createAction('fetchReadPostsSuccess')
export const fetchReadPostsFailure = createAction('fetchReadPostsFailure')

export const fetchActiveUsersRequest = createAction('fetchActiveUsersRequest')
export const fetchActiveUsersSuccess = createAction('fetchActiveUsersSuccess')
export const fetchActiveUsersFailure = createAction('fetchActiveUsersFailure')

export const fetchLikesRequest = createAction('fetchLikesRequest')
export const fetchLikesSuccess = createAction('fetchLikesSuccess')
export const fetchLikesFailure = createAction('fetchLikesFailure')

export const fetchActiveUsers = data => (dispatch, getState) => {
  const { fetchingActiveUsers } = getState().kamDashboard
  if (fetchingActiveUsers !== STATUS.LOADING_ACTIVE_USERS) {
    dispatch(fetchActiveUsersRequest())
    Proxies.GET('supervisors/admin-active', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchActiveUsersFailure())
      } else {
        dispatch(fetchActiveUsersSuccess(responseJSON))
      }
    })
  }
}

export const fetchLikes = data => (dispatch, getState) => {
  const { fetchingLikes } = getState().kamDashboard
  if (fetchingLikes !== STATUS.LOADING_LIKES) {
    dispatch(fetchLikesRequest())
    Proxies.GET('supervisors/admin-likes', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchLikesFailure())
      } else {
        dispatch(fetchLikesSuccess(responseJSON))
      }
    })
  }
}

export const fetchReadPosts = data => (dispatch, getState) => {
  const { fetchingReadPosts } = getState().kamDashboard
  if (fetchingReadPosts !== STATUS.LOADING_READ_POSTS) {
    dispatch(fetchReadPostsRequest())
    Proxies.GET('supervisors/admin-postsread', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchReadPostsFailure())
      } else {
        dispatch(fetchReadPostsSuccess(responseJSON))
      }
    })
  }
}

export const fetchNewPosts = data => (dispatch, getState) => {
  const { fetchingNewPosts } = getState().kamDashboard
  if (fetchingNewPosts !== STATUS.LOADING_NEW_POSTS) {
    dispatch(fetchNewPostsRequest())
    Proxies.GET('supervisors/admin-posts', data).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(fetchNewPostsFailure())
      } else {
        dispatch(fetchNewPostsSuccess(responseJSON))
      }
    })
  }
}
