/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { Button } from 'reactstrap'
import FormField from 'Components/formField'
import JSZip from 'jszip'
import { download } from 'Components/download'

export default class VIB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      baseURL: 'http://vibeeclub.com/?qrsc=',
      codes: [],
      textData: '',
      isSaving: false
    }
    this.updateTextarea = this.updateTextarea.bind(this)
    this.updateBaseURL = this.updateBaseURL.bind(this)
  }
  updateTextarea(text) {
    let codes = []
    if (text) {
      codes = text.split(/(\r|\n)/g)
      codes = codes.filter(e => {
        return e && e.length > 5
      })
    }
    this.setState({ textData: text, codes: codes })
  }
  updateBaseURL(text) {
    this.setState({ baseURL: text })
  }
  saveCodes() {
    this.setState({ isSaving: true })
    let codes = this.state.codes
    let zip = new JSZip()
    let readyForDownload = 0
    codes.forEach(e => {
      let currUrl = 'https://chart.googleapis.com/chart?chs=300x300&cht=qr&choe=UTF-8&chl=' + encodeURI(this.state.baseURL + e)
      fetch(currUrl)
        .then(response => {
          if (response.ok) {
            return response.blob()
          }
        })
        .then(blob => {
          zip.file(e + '.png', blob)
          readyForDownload++
          if (readyForDownload == codes.length) {
            this.downloadFile(zip)
          }
        })
    })
  }
  downloadFile(zip) {
    zip.generateAsync({ type: 'blob' }).then(function(blob) {
      download(blob, 'codes.zip')
      this.setState({ isSaving: false })
    })
  }
  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Opret QR koder</strong>}>
        <FormField
          data={this.state}
          field={'baseURL'}
          name='URL'
          onChange={e => {
            this.updateBaseURL(e.target.value)
          }}
        />
        <FormField
          data={this.state}
          field={'textData'}
          name='Koder'
          onChange={e => {
            this.updateTextarea(e.target.value)
          }}
          type='textarea'
        />
        <Button onClick={this.saveCodes.bind(this)} disabled={this.state.isSaving}>
          Generer koder
        </Button>
      </CollapsibleCard>
    )
  }
}
