import { createSlice } from '@reduxjs/toolkit'
import {
  STATUS,
  fetchAllUsersRequest,
  fetchAllUsersSuccess,
  fetchAllUsersFailure,
  fetchAllCompaniesRequest,
  fetchAllCompaniesSuccess,
  fetchAllCompaniesFailure,
  fetchOverviewUsersRequest,
  fetchOverviewUsersSuccess,
  fetchOverviewUsersFailure,
  fetchCompanySalesRequest,
  fetchCompanySalesSuccess,
  fetchCompanySalesFailure,
  fetchUserSalesRequest,
  fetchUserSalesSuccess,
  fetchUserSalesFailure,
  fetchUserSalesInitialState,
  fetchCompanySalesInitialState,
  fetchInitialStateData,
  fetchNewPostsRequest,
  fetchNewPostsSuccess,
  fetchNewPostsFailure,
  fetchReadPostsRequest,
  fetchReadPostsSuccess,
  fetchReadPostsFailure,
  fetchActiveUsersRequest,
  fetchActiveUsersSuccess,
  fetchActiveUsersFailure,
  fetchLikesRequest,
  fetchLikesSuccess,
  fetchLikesFailure
} from 'Reducers/actions/kamCompanyDashboardActions'

const slice = createSlice({
  name: 'kamCompanyDashboardReducer',
  initialState: {
    usersLoadingStatus: null,
    companyLoadingStatus: null,
    fetchOverviewUsers: null,
    fetchingCompanySales: null,
    fetchingUserSales: null,
    kamUsers: [],
    kamCompanies: [],
    overviewUsers: [],
    companySales: [],
    userSales: [],
    fetchingNewPosts: null,
    newPosts: [],
    fetchingReadPosts: null,
    readPosts: [],
    fetchingActiveUsers: null,
    activeUsers: [],
    fetchingLikes: null,
    likes: []
  },
  reducers: {},
  extraReducers: {
    [fetchAllUsersRequest]: state => {
      state.usersLoadingStatus = STATUS.LOADING_KAM_ALL_USERS
    },
    [fetchAllUsersSuccess]: (state, action) => {
      state.kamUsers = action.payload
      state.usersLoadingStatus = STATUS.LOADING_KAM_ALL_USERS_SUCCESS
    },
    [fetchAllUsersFailure]: state => {
      state.usersLoadingStatus = STATUS.LOADING_KAM_ALL_USERS_FAILURE
    },
    [fetchAllCompaniesRequest]: state => {
      state.companyLoadingStatus = STATUS.LOADING_KAM_ALL_COMPANIES
    },
    [fetchAllCompaniesSuccess]: (state, action) => {
      state.kamCompanies = action.payload
      state.companyLoadingStatus = STATUS.LOADING_KAM_ALL_COMPANIES_SUCCESS
    },
    [fetchAllCompaniesFailure]: state => {
      state.companyLoadingStatus = STATUS.LOADING_KAM_ALL_COMPANIES_FAILURE
    },
    [fetchOverviewUsersRequest]: state => {
      state.overviewUsers = []
      state.fetchOverviewUsers = STATUS.LOADING_OVERVIEW_USERS
    },
    [fetchOverviewUsersSuccess]: (state, action) => {
      state.overviewUsers = action.payload
      state.fetchOverviewUsers = STATUS.LOADING_OVERVIEW_USERS_SUCCESS
    },
    [fetchOverviewUsersFailure]: state => {
      state.fetchOverviewUsers = STATUS.LOADING_OVERVIEW_USERS_FAILURE
    },
    [fetchCompanySalesRequest]: state => {
      state.companySales = []
      state.fetchingCompanySales = STATUS.LOADING_COMPANY_SALES
    },
    [fetchCompanySalesSuccess]: (state, action) => {
      state.companySales = action.payload
      state.fetchingCompanySales = STATUS.LOADING_COMPANY_SALES_SUCCESS
    },
    [fetchCompanySalesFailure]: state => {
      state.fetchingCompanySales = STATUS.LOADING_COMPANY_SALES_FAILURE
    },
    [fetchCompanySalesInitialState]: state => {
      state.companySales = []
      state.fetchingCompanySales = null
    },
    [fetchUserSalesRequest]: state => {
      state.userSales = []
      state.fetchingUserSales = STATUS.LOADING_USER_SALES
    },
    [fetchUserSalesSuccess]: (state, action) => {
      state.userSales = action.payload
      state.fetchingUserSales = STATUS.LOADING_USER_SALES_SUCCESS
    },
    [fetchUserSalesFailure]: state => {
      state.fetchingUserSales = STATUS.LOADING_USER_SALES_FAILURE
    },
    [fetchUserSalesInitialState]: state => {
      state.userSales = []
      state.fetchingUserSales = null
    },
    [fetchInitialStateData]: state => {
      state.usersLoadingStatus = null
      state.companyLoadingStatus = null
      state.fetchOverviewUsers = null
      state.fetchingCompanySales = null
      state.fetchingUserSales = null
      state.kamUsers = []
      state.kamCompanies = []
      state.overviewUsers = []
      state.companySales = []
      state.userSales = []
      state.fetchingNewPosts = null
      state.newPosts = []
      state.fetchingReadPosts = null
      state.readPosts = []
      state.fetchingActiveUsers = null
      state.activeUsers = []
      state.fetchingLikes = null
      state.likes = []
    },
    [fetchNewPostsRequest]: state => {
      state.newPosts = []
      state.fetchingNewPosts = STATUS.LOADING_NEW_POSTS
    },
    [fetchNewPostsSuccess]: (state, action) => {
      state.newPosts = action.payload
      state.fetchingNewPosts = STATUS.LOADING_NEW_POSTS_SUCCESS
    },
    [fetchNewPostsFailure]: state => {
      state.fetchingNewPosts = STATUS.LOADING_NEW_POSTS_FAILURE
    },
    [fetchReadPostsRequest]: state => {
      state.readPosts = []
      state.fetchingReadPosts = STATUS.LOADING_READ_POSTS
    },
    [fetchReadPostsSuccess]: (state, action) => {
      state.readPosts = action.payload
      state.fetchingReadPosts = STATUS.LOADING_READ_POSTS_SUCCESS
    },
    [fetchReadPostsFailure]: state => {
      state.fetchingReadPosts = STATUS.LOADING_READ_POSTS_FAILURE
    },
    [fetchActiveUsersRequest]: state => {
      state.activeUsers = []
      state.fetchingActiveUsers = STATUS.LOADING_ACTIVE_USERS
    },
    [fetchActiveUsersSuccess]: (state, action) => {
      state.activeUsers = action.payload
      state.fetchingActiveUsers = STATUS.LOADING_ACTIVE_USERS_SUCCESS
    },
    [fetchActiveUsersFailure]: state => {
      state.fetchingActiveUsers = STATUS.LOADING_ACTIVE_USERS_FAILURE
    },
    [fetchLikesRequest]: state => {
      state.likes = []
      state.fetchingLikes = STATUS.LOADING_LIKES
    },
    [fetchLikesSuccess]: (state, action) => {
      state.likes = action.payload
      state.fetchingLikes = STATUS.LOADING_LIKES_SUCCESS
    },
    [fetchLikesFailure]: state => {
      state.fetchingLikes = STATUS.LOADING_LIKES_FAILURE
    }
  }
})

export const getKamAllUsers = state => state.kamDashboard.kamUsers
export const getKamCompanies = state => state.kamDashboard.kamCompanies
export const getStatus = state => {
  return {
    usersLoadingStatus: state.kamDashboard.usersLoadingStatus,
    companyLoadingStatus: state.kamDashboard.companyLoadingStatus,
    fetchOverviewUsers: state.kamDashboard.fetchOverviewUsers,
    fetchingCompanySales: state.kamDashboard.fetchingCompanySales,
    fetchUserSales: state.kamDashboard.fetchingUserSales,
    fetchingLikes: state.kamDashboard.fetchingLikes,
    fetchingActiveUsers: state.kamDashboard.fetchingActiveUsers,
    fetchingReadPosts: state.kamDashboard.fetchingReadPosts,
    fetchingNewPosts: state.kamDashboard.fetchingNewPosts
  }
}
export const getNewPosts = state => state.kamDashboard.newPosts
export const getReadPosts = state => state.kamDashboard.readPosts
export const getActiveUsers = state => state.kamDashboard.activeUsers
export const getLikes = state => state.kamDashboard.likes
export default slice.reducer
