import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'
import moment from 'moment'
import 'moment/locale/da'

export const STATUS = keyMirror({
  FETCHING_CATALOGS: null,
  FETCHING_CATALOGS_ERROR: null,
  CATALOGS_FETCHED: null,
  CATALOG_DELETE_ERROR: null,
  TAKING_INPUT: null,
  CATALOG_SUCCESS: null,
  CATALOG_ERROR: null,
  FETCHING_CATALOG: null,
  FETCHING_CATALOG_ERROR: null,
  SAVING: null,
  REDIRECT: null,
  CATALOG_DELETE: null
})
export const CatalogActions = Reflux.createActions([
  'getCatalogs',
  'deleteCatalog',
  'clearToast',
  'createEmptyCatalog',
  'clearFormToast',
  'updateCatalogField',
  'saveCatalog',
  'getCatalogDetails'
])

export class CatalogStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = CatalogActions
    this.state = {
      status: null,
      catalogs: [],
      catalogName: null,
      catalogDetails: {
        moduleName: '',
        moduleDescription: '',
        catalogType: '',
        catalogAccessType: '',
        exposeAsLifestyle: '',
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: ''
      }
    }
  }
  getCatalogs() {
    if (this.state.status === STATUS.FETCHING_CATALOGS) {
      return
    }
    this.setState({ status: STATUS.FETCHING_CATALOGS })
    GET('catalog/loadCatalogs').then(responseJSON => {
      let nextState = {
        catalogs: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_CATALOGS_ERROR
        } else {
          nextState.catalogs = responseJSON
          nextState.status = STATUS.CATALOGS_FETCHED
        }
      }
      this.setState(nextState)
    })
  }

  clearToast() {
    this.setState({ status: null })
  }

  deleteCatalog(CatalogID = null) {
    if (CatalogID) {
      POST('catalog/remove', { CatalogID: CatalogID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATUS.CATALOG_DELETE_ERROR })
        } else {
          this.getCatalogs()
        }
      })
    }
  }
  createEmptyCatalog() {
    this.setState({
      catalogDetails: {
        moduleName: '',
        moduleDescription: '',
        catalogType: '',
        catalogAccessType: '',
        exposeAsLifestyle: '',
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: ''
      }
    })
  }
  clearFormToast(status) {
    status ? this.setState({ status }) : this.setState({ status: STATUS.TAKING_INPUT })
  }
  getCatalogDetails(CatalogID = null) {
    if (CatalogID) {
      this.setState({ status: STATUS.FETCHING_CATALOG })
      GET('catalog/fetch', { CatalogID: CatalogID }).then(responseJSON => {
        let nextState = {
          catalogDetails: {}
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.FETCHING_CATALOG_ERROR
          } else {
            if (responseJSON.timeEnd) {
              responseJSON.timeEnd = moment(responseJSON.timeEnd).format('YYYY-MM-DD')
            }
            responseJSON.timeStart = moment(responseJSON.timeStart).format('YYYY-MM-DD')
            nextState.catalogDetails = responseJSON
            nextState.status = STATUS.TAKING_INPUT
          }
        }
        this.setState(nextState)
      })
    }
  }
  updateCatalogField(field) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    if (fieldName === 'exposeAsLifestyle') {
      fieldValue = field.target.checked
    }
    let catalogDetails = this.state.catalogDetails
    catalogDetails[fieldName] = fieldValue
    this.setState({ catalogDetails })
  }
  saveCatalog() {
    if (this.state.catalogDetails.moduleName) {
      let catalogDetails = this.state.catalogDetails
      if (!catalogDetails.timeEnd) {
        catalogDetails.timeEnd = null
      }
      catalogDetails.exposeAsLifestyle = catalogDetails.exposeAsLifestyle ? 1 : 0
      this.setState({ status: STATUS.SAVING })
      POST('catalog/replace', catalogDetails).then(responseJSON => {
        let nextState = {
          catalogDetails: this.state.catalogDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.CATALOG_ERROR
          } else {
            nextState.catalogDetails = responseJSON
            nextState.status = STATUS.CATALOG_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }
}
