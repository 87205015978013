import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleCard from 'Components/collapsibleCard'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import Proxies from 'Components/proxies'
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Row, Col } from 'reactstrap'
import { ProductImageModal, ProductsModal } from '../Competition/CompetitionForm'
import DatePicker from 'react-datepicker'
import moment from 'moment'

function ChristmasCalendarDaysForm(props) {
  const [loading, setLoading] = useState(false)
  const { ChristmasDayID, CompetitionID } = props.match.params
  const [christmasCalendarDay, setChristmasCalenderDay] = useState({})
  const [status, setStatus] = useState('LOADING')
  const [productImage, setProductImage] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [answers, setAnswers] = useState({})
  const [isRightAnswer, setIsRightAnswer] = useState(null)
  const [showProductModal, setShowProductModal] = useState(false)
  const [showProductImageModal, setShowProductImageModal] = useState(false)

  function saveChristmasCalendar() {
    setLoading(true)
    if (productImage) {
      christmasCalendarDay.ImageID = productImage.ImageID
    } else {
      delete christmasCalendarDay.ImageID
    }

    if (selectedProduct) {
      christmasCalendarDay.ProductID = selectedProduct.ProductID
    } else {
      delete christmasCalendarDay.ProductID
    }

    let tempAnswerArr = []

    if (isRightAnswer) {
      for (let answer in answers) {
        if (answers[answer].answer === answers[`answer${isRightAnswer}`].answer) {
          answers[answer].isRightAnswer = true
        } else {
          answers[answer].isRightAnswer = false
        }
        ;(answers[answer].answer || answers[answer].ChristmasDayAnswerID) && tempAnswerArr.push(answers[answer])
      }
    } else {
      for (let answer in answers) {
        answers[answer].isRightAnswer = false(answers[answer].answer || answers[answer].ChristmasDayAnswerID) && tempAnswerArr.push(answers[answer])
      }
    }

    christmasCalendarDay.answers = tempAnswerArr

    christmasCalendarDay.CompetitionID = Number(CompetitionID)

    if (christmasCalendarDay.dayNumber) {
      christmasCalendarDay.dayNumber = Number(christmasCalendarDay.dayNumber)
    }

    Proxies.POST('competitionV2/saveChristmasDay', christmasCalendarDay).then(responseJSON => {
      if (responseJSON.error) {
        setStatus('FAILED')
      } else {
        setLoading(false)
        setStatus('SUCCESS')
      }
    })
  }

  const fetchChristmasCalendarDay = () => {
    Proxies.GET('competitionV2/fetchChristmasDay', { ChristmasDayID, CompetitionID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setChristmasCalenderDay(responseJSON)
        setProductImage(responseJSON.image)
        setSelectedProduct(responseJSON.product)
        let tempAnswer = {
          answer0: {
            answer: '',
            isRightAnswer: false
          },
          answer1: {
            answer: '',
            isRightAnswer: false
          },
          answer2: {
            answer: '',
            isRightAnswer: false
          }
        }
        let index = 0
        for (let answer of responseJSON.answers) {
          tempAnswer[`answer${index}`] = answer
          if (answer.isRightAnswer === 1) {
            setIsRightAnswer(`${index}`)
          }
          index++
        }
        setAnswers(tempAnswer)
      }
    })
  }

  const resetData = () => {
    if (ChristmasDayID) {
      fetchChristmasCalendarDay()
    } else {
      setChristmasCalenderDay({})
    }
  }

  const updateChristmasCalendarDay = ({ target }) => {
    if (target.name === 'imageTextColor') {
      target.value = target.id
    }
    setChristmasCalenderDay({ ...christmasCalendarDay, [target.name]: target.value })
  }

  const updateChristmasCalendarDayAnswers = ({ target }) => {
    setAnswers({ ...answers, [target.name]: { ...answers[target.name], answer: target.value } })
  }

  useEffect(() => {
    if (ChristmasDayID) {
      fetchChristmasCalendarDay(ChristmasDayID)
    }
  }, [])

  if (status === 'SUCCESS') {
    toast.success(ChristmasDayID ? 'Successfully Updated' : 'Successfully Created', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
    return <Redirect to={`/christmas-calendar/${CompetitionID}`} />
  }

  if (status === 'FAILED') {
    toast.error('Error while saving christmas calendar day', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
  }

  return (
    <CollapsibleCard startOpen={true} header={<strong>Dag</strong>}>
      <Form>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Dag</label>
          </Col>
          <Col xs='10'>
            <Input
              type='number'
              value={christmasCalendarDay.dayNumber ? christmasCalendarDay.dayNumber : ''}
              name='dayNumber'
              id='dayNumber'
              onChange={e => updateChristmasCalendarDay(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Date</label>
          </Col>
          <Col xs='10'>
            <DatePicker
              className='form-control'
              minDate={moment().add(1, 'days')}
              selected={christmasCalendarDay.christmasDayDate ? moment(christmasCalendarDay.christmasDayDate) : ''}
              dateFormat={'DD/MM/YYYY'}
              viewMode={'days'}
              name='christmasDayDate'
              id='christmasDayDate'
              onChange={e => {
                updateChristmasCalendarDay({ target: { name: 'christmasDayDate', value: e.format() } })
              }}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Description</label>
          </Col>
          <Col xs='10'>
            <Input
              type='text'
              value={christmasCalendarDay.description ? christmasCalendarDay.description : ''}
              name='description'
              id='description'
              onChange={e => updateChristmasCalendarDay(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Question</label>
          </Col>
          <Col xs='10'>
            <Input
              type='text'
              value={christmasCalendarDay.question ? christmasCalendarDay.question : ''}
              name='question'
              id='question'
              onChange={e => updateChristmasCalendarDay(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Answers</label>
          </Col>
          <Col xs='10'>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <Input
                type='radio'
                checked={isRightAnswer === '0' && true}
                name='answer0'
                value={0}
                id='answer0'
                onChange={e => setIsRightAnswer(e.target.value)}
                style={{ width: 16, height: 16 }}
              />
              <Input
                type='text'
                value={answers.answer0 ? answers.answer0.answer : ''}
                name='answer0'
                id='answer0'
                onChange={e => updateChristmasCalendarDayAnswers(e)}
              />
            </div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label></label>
          </Col>
          <Col xs='10'>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <Input
                type='radio'
                checked={isRightAnswer === '1' && true}
                name='answer1'
                value={1}
                id='answer1'
                onChange={e => setIsRightAnswer(e.target.value)}
                style={{ width: 16, height: 16 }}
              />
              <Input
                type='text'
                value={answers.answer1 ? answers.answer1.answer : ''}
                name='answer1'
                id='answer1'
                onChange={e => updateChristmasCalendarDayAnswers(e)}
              />
            </div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label></label>
          </Col>
          <Col xs='10'>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <Input
                type='radio'
                checked={isRightAnswer === '2' && true}
                name='answer2'
                value={2}
                id='answer2'
                onChange={e => setIsRightAnswer(e.target.value)}
                style={{ width: 16, height: 16 }}
              />
              <Input
                type='text'
                value={answers.answer2 ? answers.answer2.answer : ''}
                name='answer2'
                id='answer2'
                onChange={e => updateChristmasCalendarDayAnswers(e)}
              />
            </div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Product</label>
          </Col>
          <Col xs='10'>
            {selectedProduct ? <span style={{ marginRight: '8px' }}>{selectedProduct.elementTitle}</span> : null}
            <Button onClick={() => setShowProductModal(true)}>{selectedProduct ? 'Change Product' : 'Select Product'}</Button>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Product Image</label>
          </Col>
          <Col xs='10'>
            {productImage ? (
              <img
                src={productImage.imageOriginSource ? productImage.imageOriginSource : ''}
                alt={productImage.imageOriginSource ? productImage.imageOriginSource : ''}
                style={{
                  height: '120px',
                  width: '288px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: productImage && productImage.imageOriginSource ? 'inline-block' : 'none'
                }}
              />
            ) : null}
            <Button style={{ marginLeft: '16px' }} onClick={() => setShowProductImageModal(true)}>
              {productImage ? 'Change Product Image' : 'Select Product Image'}
            </Button>
          </Col>
        </Row>
        {showProductModal && (
          <ProductsModal
            showProductModal={showProductModal}
            closeModal={() => setShowProductModal(false)}
            onProductSelect={product => {
              setShowProductModal(false)
              setSelectedProduct(product)
            }}
          />
        )}
        {showProductImageModal && selectedProduct && (
          <ProductImageModal
            showProductImageModal={showProductImageModal}
            ProductID={selectedProduct.ProductID}
            closeModal={() => setShowProductImageModal(false)}
            onProductImageSelect={productImage => {
              setShowProductImageModal(false)
              setProductImage(productImage)
            }}
          />
        )}
        <Button onClick={saveChristmasCalendar} disabled={loading}>
          {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button competition='danger' onClick={resetData} className='ml-2'>
          Nulstil
        </Button>
      </Form>
    </CollapsibleCard>
  )
}

export default ChristmasCalendarDaysForm
