/* eslint-disable eqeqeq */
import React, { Fragment } from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import 'moment/locale/da'
import { Button, Form } from 'reactstrap'
import FormField from 'Components/formField'
import { LoginStore } from 'Stores/loginStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChristmasStore, ChristmasActions, CHRISTMAS_RECEIPT_STATUS } from 'Stores/christmasStore'
import styled from 'styled-components'
import moment from 'moment'

const StyledSendChristmasReceiptButton = styled(Button)`
  min-width: 220px;
`

export default class Christmas extends Component {
  constructor(props) {
    super(props)
    this.stores = [ChristmasStore, LoginStore]
  }
  componentDidMount() {
    ChristmasActions.getData()
  }

  isCurrentDateBeforeChristmasEndDate() {
    return moment().isBefore(moment(this.state.endDate))
  }

  render() {
    return (
      <Fragment>
        <CollapsibleCard header={<strong>Detaljer</strong>} startOpen>
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormField name='Slutdato' field='endDate' data={this.state} onChange={ChristmasActions.updateEndDate} type='date' required />
            <FormField name='Tekst' field='selectText' data={this.state} onChange={ChristmasActions.updateSelectText} type='textarea' required />
            <FormField name='Billede til headeren' field='christmasImage' data={this.state} onChange={ChristmasActions.updateChristmasImage} />
            <FormField
              name='Skal produkterne "pakkes ind"'
              field='wrappedPresents'
              data={this.state}
              onChange={ChristmasActions.updateWrappedPresents}
            />
            <FormField
              name='Julegave bekræftelses mail tekst'
              field='christmasReceiptText'
              data={this.state}
              onChange={ChristmasActions.updateChristmasReceiptText}
              type='textarea'
            />
          </Form>
          <Button onClick={ChristmasActions.saveData} disabled={this.state.endDate == '' || this.state.selectText == ''}>
            Gem
          </Button>
          <StyledSendChristmasReceiptButton
            className='ml-3'
            onClick={ChristmasActions.sendChristmasReceipt}
            disabled={
              !this.state.christmasReceiptText ||
              this.state.unsavedChristmasReceiptText ||
              this.state.christmasReceiptStatus === CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_SENDING ||
              this.state.christmasReceiptStatus === CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_SUCCESS ||
              this.state.settings.christmasReceiptSent ||
              this.isCurrentDateBeforeChristmasEndDate()
            }
          >
            {this.state.christmasReceiptStatus === CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_SENDING ? (
              <FontAwesomeIcon icon='spinner' spin />
            ) : (
              'Send julegave kvitteringer'
            )}
          </StyledSendChristmasReceiptButton>
          {this.state.christmasReceiptStatus === CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_FAILURE && (
            <p className='text-danger mt-3'>Der skete en ukendt fejl, refresh siden og prøv igen.</p>
          )}
          {(this.state.christmasReceiptStatus === CHRISTMAS_RECEIPT_STATUS.CHRISTMAS_RECEIPT_SUCCESS || this.state.settings.christmasReceiptSent) && (
            <p className='text-success mt-3'>Julegave kvitteringer er sendt.</p>
          )}
        </CollapsibleCard>
      </Fragment>
    )
  }
}
