import { createSlice } from '@reduxjs/toolkit'

export const dataRetentionSlice = createSlice({
  name: 'dataRetention',
  initialState: {
    userObfuscationSchedulePool: [],
    orderObfuscationSchedulePool: [],
    selectedUserIDs: [],
    selectedOrderIDs: [],
    isAllChecked: false
  },
  reducers: {
    setUserObfuscationSchedulePool: (state, action) => {
      state.userObfuscationSchedulePool = action.payload
      state.userObfuscationSchedulePool.forEach(user => {
        user.checked = false
      })
    },
    resetUserObfuscationSchedulePool: state => {
      state.userObfuscationSchedulePool = []
    },
    toggleAllChecked: state => {
      state.isAllChecked = !state.isAllChecked
      state.userObfuscationSchedulePool.forEach(item => {
        item.checked = false
      })
      state.orderObfuscationSchedulePool.forEach(item => {
        item.checked = false
      })
    },
    setSelectedUserIDs: (state, action) => {
      return {
        ...state,
        userObfuscationSchedulePool: state.userObfuscationSchedulePool.map(user => {
          return {
            ...user,
            checked: true
          }
        }),
        selectedUserIDs: action.payload
      }
    },
    updateSelectedUserIDs: (state, action) => {
      const index = state.selectedUserIDs.indexOf(action.payload)
      if (index < 0) {
        state.selectedUserIDs.push(action.payload)
      } else {
        state.selectedUserIDs.splice(index, 1)
      }
    },

    updateSelectedUserIDsCheckbox: (state, action) => {
      return {
        ...state,
        userObfuscationSchedulePool: state.userObfuscationSchedulePool.map(user =>
          user.UserID === action.payload
            ? {
                ...user,
                checked: !user.checked
              }
            : user
        )
      }
    },

    setOrderObfuscationSchedulePool: (state, action) => {
      state.orderObfuscationSchedulePool = action.payload
      state.orderObfuscationSchedulePool.forEach(order => {
        order.checked = false
      })
    },

    resetOrderObfuscationSchedulePool: state => {
      state.orderObfuscationSchedulePool = []
    },

    setSelectedOrderIDs: (state, action) => {
      return {
        ...state,
        orderObfuscationSchedulePool: state.orderObfuscationSchedulePool.map(order => {
          return {
            ...order,
            checked: true
          }
        }),
        selectedOrderIDs: action.payload
      }
    },

    updateSelectedOrderIDs: (state, action) => {
      const index = state.selectedOrderIDs.indexOf(action.payload)
      if (index < 0) {
        state.selectedOrderIDs.push(action.payload)
      } else {
        state.selectedOrderIDs.splice(index, 1)
      }
    },
    resetSelectedOrderIDs: state => {
      state.selectedOrderIDs = []
    },
    updateSelectedOrderIDsCheckbox: (state, action) => {
      return {
        ...state,
        orderObfuscationSchedulePool: state.orderObfuscationSchedulePool.map(order =>
          order.OrderID === action.payload
            ? {
                ...order,
                checked: !order.checked
              }
            : order
        )
      }
    }
  }
})
export const {
  setUserObfuscationSchedulePool,
  resetUserObfuscationSchedulePool,
  setSelectedUserIDs,
  addToSelectedUserIDs,
  updateSelectedUserIDs,
  updateSelectedUserIDsCheckbox,
  toggleAllChecked,
  setOrderObfuscationSchedulePool,
  resetOrderObfuscationSchedulePool,
  setSelectedOrderIDs,
  addToSelectedOrderIDs,
  updateSelectedOrderIDs,
  updateSelectedOrderIDsCheckbox,
  resetSelectedOrderIDs
} = dataRetentionSlice.actions
export default dataRetentionSlice.reducer
