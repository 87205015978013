import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET } = Proxies
import keyMirror from 'keymirror'
export const STATE = new keyMirror({
  FETCHING_VALIDATION_CODES: null,
  FETCHING_VALIDATION_CODES_FAILED: null,
  FETCHING_VALIDATION_CODES_SUCCESS: null
})
export const AccessActions = createActions(['getValidationCodes', 'clearToast'])

export class AccessStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = AccessActions
    this.state = {
      loadingState: false,
      codes: []
    }
  }
  getValidationCodes() {
    this.setState({ loadingState: STATE.FETCHING_VALIDATION_CODES })
    GET('accesses/pool').then(responseJSON => {
      let nextState = {
        codes: {}
      }
      if (responseJSON.error) {
        nextState.loadingState = STATE.FETCHING_VALIDATION_CODES_FAILED
      } else {
        nextState.codes = responseJSON
        nextState.loadingState = STATE.FETCHING_VALIDATION_CODES_SUCCESS
      }
      this.setState(nextState)
    })
  }
  clearToast() {
    this.setState({ loadingState: null })
  }
}
