import { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import React from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Input } from 'reactstrap'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'

export default function ColorsForm(props) {
  const [loading, setLoading] = useState(false)
  const { ColorID } = props.match.params
  const [name, setName] = useState('')
  const [hexCode, setHexCode] = useState('')
  const [color, setColor] = useState({})
  const [status, setStatus] = useState('LOADING')

  useEffect(() => {
    if (ColorID) {
      setLoading(true)
      Proxies.GET('colors/fetch', { ColorID }).then(responseJSON => {
        if (responseJSON.error) {
          // console.log(responseJSON.error)
        } else {
          setColor(responseJSON)
          setName(responseJSON.name)
          setHexCode(responseJSON.hexCode)
        }
        setLoading(false)
      })
    }
  }, [])

  function resetData() {
    setName(color.name ? color.name : '')
    setHexCode(color.hexCode ? color.hexCode : '')
  }

  function saveColor() {
    Proxies.POST('colors/replace', { ColorID: color.ColorID, name, hexCode }).then(responseJSON => {
      if (responseJSON.error) {
        setStatus('FAILED')
      } else {
        setStatus('SUCCESS')
      }
    })
  }

  if (status === 'SUCCESS') {
    toast.success(ColorID ? 'Successfully Updated' : 'Successfully Created', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
    return <Redirect to='/colors' />
  }

  if (status === 'FAILED') {
    toast.error('Error while saving color', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
  }

  return (
    <CollapsibleCard startOpen={true} header={<strong>Color</strong>}>
      <Form>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Name</label>
          </Col>
          <Col xs='10'>
            <Input type='text' value={name} name='name' id='name' onChange={e => setName(e.target.value)} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Pick color</label>
          </Col>
          <Col xs='5'>
            <Input type='color' value={hexCode} name='hexCode' id='hexCode' onChange={e => setHexCode(e.target.value)} />
          </Col>
          <Col xs='5'>
            <Input type='text' value={hexCode} name='hexCode' id='hexCode' onChange={e => setHexCode(e.target.value)} />
          </Col>
        </Row>
        <Button onClick={saveColor} disabled={loading}>
          {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button color='danger' onClick={resetData} className='ml-2'>
          Nulstil
        </Button>
      </Form>
    </CollapsibleCard>
  )
}
