import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ErrorMessageDiv = styled.div`
  text-align: center;
  position: relative;
  top: 50px;
`

export default function DataNotFound() {
  return (
    <ErrorMessageDiv>
      <FontAwesomeIcon className='text-danger' icon='exclamation-circle' size='6x' />
      <h4 className='mt-2'>No Data Found</h4>
    </ErrorMessageDiv>
  )
}
