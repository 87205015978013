import Proxies from 'Components/proxies'
import {
  setUserObfuscationSchedulePool,
  resetUserObfuscationSchedulePool,
  setOrderObfuscationSchedulePool,
  resetOrderObfuscationSchedulePool,
  setSelectedOrderIDs
} from 'Reducers/dataRetentionReducer'
import { toast } from 'react-toastify'

export const fetchUserObfuscationSchedule = () => dispatch => {
  Proxies.GET('DataRetentions/pool').then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetUserObfuscationSchedulePool())
      } else {
        if (responseJSON.length > 0) {
          responseJSON.forEach(user => {
            user.checked = false
          })
          dispatch(setUserObfuscationSchedulePool(responseJSON))
        } else {
          dispatch(setUserObfuscationSchedulePool([]))
        }
      }
    }
  })
}

export const preventUserObfuscation = data => dispatch => {
  if (data && data.length) {
    Proxies.POST('DataRetentions/preventObfuscation', { arrayOfUserIDs: data }).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        dispatch(resetUserObfuscationSchedulePool())
      } else {
        dispatch(fetchUserObfuscationSchedule())
      }
    })
  }
}

export const fetchOrderObfuscationSchedule = () => dispatch => {
  Proxies.GET('DataRetentions/pool/orders').then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetOrderObfuscationSchedulePool())
      } else {
        if (responseJSON.length > 0) {
          responseJSON.forEach(order => {
            order.checked = false
          })
          dispatch(setOrderObfuscationSchedulePool(responseJSON))
        } else {
          dispatch(setOrderObfuscationSchedulePool([]))
        }
      }
    }
  })
}

export const preventOrderObfuscation = data => dispatch => {
  if (data && data.length) {
    Proxies.POST('DataRetentions/preventOrderObfuscation', { arrayOfOrderIDs: data }).then(responseJSON => {
      if (responseJSON && responseJSON.error) {
        toast.error('Error: Operation failed with error - ' + JSON.stringify(responseJSON.error), {
          autoClose: 3000
        })
        dispatch(resetOrderObfuscationSchedulePool())
      } else {
        toast.success('Order obfuscation schedule removed', {
          autoClose: 3000
        })
        dispatch(fetchOrderObfuscationSchedule())
        dispatch(setSelectedOrderIDs([]))
      }
    })
  }
}
