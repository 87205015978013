import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col } from 'reactstrap'
import FormField from 'Components/formField'
import { toast } from 'react-toastify'
import { MacroStore, MacroActions, STATUS } from '../../stores/macroStore'
import SearchableDropdown from '../searchableDropdown'
import GroupElements from '../group'
import { UserActions } from 'Stores/userStore'
import { Redirect } from 'react-router-dom'

export default class MacroDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [MacroStore]
    this.storeKeys = ['groups', 'macroDetails', 'groupID', 'status', 'STATUS']
  }

  componentDidMount() {
    MacroActions.getGroups()
    MacroActions.createEmptyMacro()
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.GROUPS_ERROR) {
      toast.error('Error While Fetching Groups', {
        autoClose: 7500,
        onClose: () => {
          MacroActions.clearToast()
        }
      })
      MacroActions.clearToast()
    }
    if (this.state.status === STATUS.MACRO_ERROR) {
      toast.error('Der opstod en fejl i oprettelsen af makros', {
        autoClose: 7500,
        onClose: () => {
          MacroActions.clearToast()
        }
      })
      MacroActions.clearToast()
    }
    if (this.state.status === STATUS.MACRO_SUCCESS) {
      toast.success('Makros er oprettet', {
        autoClose: 7500,
        onClose: () => {
          MacroActions.clearToast()
        }
      })
      MacroActions.clearToast(STATUS.REDIRECT)
    }
  }

  resetMacro = () => {
    MacroActions.createEmptyMacro()
  }

  submitMacro = e => {
    e.preventDefault()
    MacroActions.saveMacro()
    UserActions.resetUsers() // Ensure users list is updated with new point assignments
  }

  render() {
    if (this.state.status === STATUS.GROUPS_FETCHING) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to={'/macros'} />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>Opret</strong>}>
          <Form onSubmit={this.submitMacro}>
            <Row>
              <Col xs='3'>
                <label>Gruppe</label>
              </Col>
              <Col xs='9'>
                {!!this.state.groups.length && (
                  <SearchableDropdown
                    setDataFn={MacroActions.setMacroData}
                    callBackFn={MacroActions.getGroupElements}
                    data={this.state.macroDetails}
                    list={this.state.groups}
                    dropdown={{ value: 'GroupID', displayName: 'moduleName' }}
                  ></SearchableDropdown>
                )}
              </Col>
            </Row>
            <FormField name='Point' field='walletAmountPoints' data={this.state.macroDetails} onChange={MacroActions.updateMacroField} />
            <FormField name='Note' field='walletNote' data={this.state.macroDetails} onChange={MacroActions.updateMacroField} />
            <Button type='submit'>{this.state.status === STATUS.MACRO_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Indsæt'}</Button>
            <Button color='danger' onClick={this.resetMacro} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
        {this.state.groupID && (
          <React.Fragment>
            <GroupElements groupID={this.state.groupID} elements='true' />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
