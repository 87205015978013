import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import 'moment/locale/da'
import SearchableDropdown from 'Modules/searchableDropdown'
import { Button, Form, Input, Label, Col, Row, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { CampaignActions, CampaignStore, STATUS } from 'Stores/campaignStore'
import _ from 'lodash'
import styled from 'styled-components'
import CreateProductModal from 'Modules/campaigns/CreateProductModal.js'
import SantanderCampaignUserSelect from 'Modules/campaigns/SantanderCampaignUserSelect.js'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import CampaignUserPreview from 'Modules/campaigns/CampaignUserPreview.js'
import cx from 'classnames'

const StyledTextLabel = styled.label`
  position: relative;
  top: 4px;
  left: 15px;
  margin-right: 10px;
`

const StyledCountTextLabel = styled.label`
  position: relative;
  top: 4px;
`
const defaultOperatorList = [
  { name: 'Ingen', value: 'NONE' },
  { name: 'Dobbelt', value: 'SET' },
  { name: 'Tilføj', value: 'ADD' },
  { name: 'Fratræk', value: 'SUBTRACT' },
  { name: 'Gange', value: 'MULTIPLY' },
  { name: 'Dividere', value: 'DIVIDE' }
]
const kamOperatorList = [
  { name: 'Ingen', value: 'NONE' },
  { name: 'Dobbelt', value: 'SET' },
  { name: 'Gange', value: 'MULTIPLY' }
]

const StyledLabel = styled.label`
  padding-top: 4px;
  font-weight: bold;
  display: block;
  text-align: center;
`
const StyledDiv = styled.div`
  height: auto;
  overflow: auto;
  max-height: 200px;
  padding: 0 30px;
  margin: 20px 0;
  display: inline-block;
`
const StyledCheckbox = styled(Input)`
  position: relative !important;
`

const StyledUserSelectWrap = styled.div`
  margin-left: 15px;
  max-width: 82.5%;
`

const StyledCreatedByCol = styled(Col)`
  font-size: 16px;
  font-weight: 700;
`

const StyledSelectAllCheckbox = styled.div`
  padding: 4px 30px !important;
`

export default class CampaignForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [CampaignStore]
    this.storeKeys = [
      'campaignDetails',
      'status',
      'groups',
      'datasets',
      'clauseDatacolumns',
      'products',
      'showModal',
      'productDetails',
      'filteredProducts',
      'hasAccessToCampaignStatus',
      'selectedUsers',
      'selectAll'
    ]
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }
  componentDidMount() {
    CampaignActions.getGroups()
    CampaignActions.getDatasets()
    CampaignActions.getProducts()
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.status === STATUS.GROUPS_ERROR) {
      toast.error('Fetching groups failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.DATASETS_ERROR) {
      toast.error('Fetching Datasets failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.MACRO_ERROR) {
      toast.error('saving macro failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.FETCHING_MACRO_ERROR) {
      toast.error('fetching macro failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.MACRO_SUCESS) {
      toast.success('Successfully saved', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.CAMPAIGN_STATUS_CHANGE_SUCCESS) {
      toast.success('Kampagne status opdateret', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.CAMPAIGN_STATUS_CHANGE_FAILURE) {
      toast.error('Fejl ved ændring af status', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }

    if (_.get(prevState, 'selectedUsers.length') !== _.get(this.state, 'selectedUsers.length')) {
      this.validateForm()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return
    const selectedProducts = this.state.products.map(pr => pr.checked)
    const fieldValidation = {
      isNameValid: !!this.state.campaignDetails.macroName,
      isDateValid: moment(this.state.campaignDetails.timeStart).isSameOrBefore(this.state.campaignDetails.timeEnd),
      isProductSelected: !this.props.isKam || !!selectedProducts.length,
      isReferenceValid: !this.props.isKam || !!this.state.campaignDetails.walletNote || !!this.state.campaignDetails.walletNoteColumns.length,
      isPointConfigurationValid:
        (this.state.campaignDetails.compoundCampaignMinValue &&
          (this.state.campaignDetails.compoundCampaignDoublePointCheckbox ||
            (this.state.campaignDetails.compoundCampaignSetPointCheckbox && this.state.campaignDetails.compoundCampaignSetPointValue))) ||
        this.state.campaignDetails.normalCampaignSetPointValue ||
        (this.state.campaignDetails.normalCampaignOperator && this.state.campaignDetails.normalCampaignOperatorValue) ||
        this.state.campaignDetails.normalCampaignOperator === 'SET',
      hasSelectedUsers: !this.props.isKam || !!_.get(this.state, 'selectedUsers.length')
    }

    if (this.state.campaignDetails.compoundCampaignMinValue) {
      if (this.state.campaignDetails.compoundCampaignMinValue && this.state.campaignDetails.compoundCampaignMinValue <= 1) {
        toast.error('Minimum Sælg value is 2', {
          autoClose: 7500,
          onClose: () => {
            CampaignActions.clearToast()
          }
        })
        return
      }
      fieldValidation.compoundCampaignMinValue = this.state.campaignDetails.compoundCampaignMinValue > 1 ? true : false
    }

    this.setState({ fieldValidation })
    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveCampaignForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      CampaignActions.saveCampaign(this.props.isKam)
    }
  }

  changeCampaignStatus = status => {
    CampaignActions.changeCampaignStatus(status)
  }

  resetMacro = () => {
    if (_.get(this.props, 'match.params.CampaignID')) {
      CampaignActions.getCampaignDetails(this.props.match.params.CampaignID)
    } else {
      CampaignActions.resetMacro()
    }
  }

  render() {
    if (this.state.status === STATUS.CAMPAIGN_STATUS_CHANGE_SUCCESS) {
      return this.props.isKam ? <Redirect to={'/kam/campaigns'} /> : <Redirect to={'/macros/campaigns'} />
    }

    if (!this.props.isCreateMode && !_.get(this.state, 'campaignDetails.CampaignID')) {
      return <FontAwesomeIcon icon='spinner' spin />
    }

    let productList = []
    let products =
      this.state.campaignDetails.productFilterText && this.state.campaignDetails.productFilterText.length
        ? this.state.filteredProducts
        : this.state.products
    const shouldShowUserSelect = this.props.isKam && !this.props.readOnly

    products.forEach((e, i) => {
      productList.push(
        <React.Fragment key={i}>
          <StyledCheckbox
            type='checkbox'
            checked={e.checked}
            name={e.moduleName}
            onChange={e => {
              CampaignActions.updateCampaignProduct(e)
              this.validateForm()
            }}
            label={e.moduleName}
            disabled={this.props.readOnly}
          />{' '}
          {e.moduleName}
          <br />
        </React.Fragment>
      )
    })
    let clauseDatacolumns = []
    if (!this.props.isKam) {
      this.state.clauseDatacolumns.forEach((col, i) => {
        clauseDatacolumns.push(
          <React.Fragment key={i}>
            <StyledCheckbox
              type='checkbox'
              checked={col.checked || false}
              name={col.datacolumnName}
              value={col.DatacolumnID}
              onChange={t => {
                CampaignActions.updateWalletNoteColumns(t)
                this.validateForm()
              }}
              disabled={this.props.readOnly}
            />{' '}
            {col.datacolumnName}
            <br />
          </React.Fragment>
        )
      })
    }

    const operatorList = this.props.isKam ? kamOperatorList : defaultOperatorList

    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>{_.get(this.props, 'match.params.CampaignID') ? 'Detaljer' : 'Opret'}</strong>}>
          <Form>
            {!this.props.isCreateMode && (
              <Row className='mb-2'>
                <Col xs='2'>
                  <Label>Oprettet af</Label>
                </Col>
                <StyledCreatedByCol xs='10'>{this.state.campaignDetails.user.userName}</StyledCreatedByCol>
              </Row>
            )}
            <Row>
              <Col xs='2'>
                <Label for='groupFilterHead'>Navn</Label>
              </Col>
              <Col xs='10'>
                <Input
                  type='text'
                  aria-label='Name'
                  value={this.state.campaignDetails.macroName}
                  name='macroName'
                  id='macroName'
                  invalid={this.state.fieldValidation.isNameValid === false}
                  onChange={e => {
                    CampaignActions.updateMacroField(e)
                    this.validateForm()
                  }}
                  disabled={this.props.readOnly}
                />
              </Col>
            </Row>
            <Row className='my-2'>
              <Col xs='2'>
                <Label for='description'>Beskrivelse</Label>
              </Col>
              <Col xs='10'>
                <Input
                  type='textarea'
                  value={this.state.campaignDetails.macroDescription}
                  name='macroDescription'
                  id='macroDescription'
                  onChange={CampaignActions.updateMacroField}
                  disabled={this.props.readOnly}
                />
              </Col>
            </Row>
            <Row className='my-2'>
              <Col xs='2'>
                <Label for='schedule'>Tidsplan</Label>
              </Col>
              <Col xs='10'>
                <Row>
                  <Col xs='6'>
                    <Input
                      type='date'
                      value={this.state.campaignDetails.timeStart}
                      name='timeStart'
                      id='timeStart'
                      onChange={e => {
                        CampaignActions.updateMacroField(e)
                        this.validateForm()
                      }}
                      disabled={this.props.readOnly}
                      invalid={this.state.fieldValidation.isDateValid === false}
                    />
                  </Col>
                  <Col xs='6'>
                    <Input
                      type='date'
                      value={this.state.campaignDetails.timeEnd}
                      name='timeEnd'
                      id='timeEnd'
                      onChange={e => {
                        CampaignActions.updateMacroField(e)
                        this.validateForm()
                      }}
                      disabled={this.props.readOnly}
                      invalid={this.state.fieldValidation.isDateValid === false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {shouldShowUserSelect && (
              <Row className='my-2 justify-content-start'>
                <Col xs='2'>
                  <label>Tilføj brugere</label>
                </Col>
                <StyledUserSelectWrap
                  className={cx({
                    border: this.state.fieldValidation.hasSelectedUsers === false,
                    rounded: this.state.fieldValidation.hasSelectedUsers === false,
                    'border-danger': this.state.fieldValidation.hasSelectedUsers === false,
                    'p-2': this.state.fieldValidation.hasSelectedUsers === false
                  })}
                >
                  <SantanderCampaignUserSelect />
                </StyledUserSelectWrap>
              </Row>
            )}
            {!shouldShowUserSelect && (
              <Row className='my-2'>
                <Col xs='2'>
                  <label>Gruppe</label>
                </Col>
                <Col xs='10'>
                  <Row>
                    <Col xs='12'>
                      {!!this.state.groups.length && (
                        <SearchableDropdown
                          setDataFn={CampaignActions.setCampaignData}
                          data={this.state.campaignDetails}
                          list={this.state.groups}
                          dropdown={{ value: 'GroupID', displayName: 'moduleName' }}
                          disabled={this.props.readOnly}
                        ></SearchableDropdown>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs='2'>Produkt</Col>
              <Col>
                <Row>
                  <Col xs='1'>
                    <Label for='groupFilterHead'>Søg Produkt</Label>
                  </Col>
                  <Col xs='10'>
                    <Input
                      type='text'
                      value={this.state.campaignDetails.productFilterText || ''}
                      name='productFilterText'
                      id='productFilterText'
                      onChange={CampaignActions.updateMacroField}
                    ></Input>
                  </Col>
                  {!this.props.isKam && (
                    <Col xs='1'>
                      <Tooltip target='createProduct'>Opret Produkt</Tooltip>
                      <Button className='float-right' id='createProduct' onClick={() => this.setState({ showModal: true })}>
                        <FontAwesomeIcon icon='plus' />
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs='2'></Col>
              <Col xs='10'>
                <StyledSelectAllCheckbox>
                  <Input
                    type='checkbox'
                    name='selectAll'
                    label='selectAll'
                    disabled={this.state.campaignDetails.productFilterText}
                    checked={this.state.selectAll}
                    onChange={e => {
                      CampaignActions.checkAllProducts(e)
                      this.validateForm()
                    }}
                  />
                  <strong>Vælg alt</strong>
                </StyledSelectAllCheckbox>
              </Col>
            </Row>
            <Row>
              <Col xs='2'></Col>
              <Col xs='10'>
                <StyledDiv
                  className={cx('rounded', {
                    border: this.state.fieldValidation.isProductSelected === false,
                    'border-danger': this.state.fieldValidation.isProductSelected === false
                  })}
                >
                  {productList}
                </StyledDiv>
              </Col>
            </Row>
            <Row className='my-2'>
              <Col xs='2'>
                <label>Reference</label>
              </Col>
              <Col xs='10'>
                <Row xs='12'>
                  <Col>
                    <Input
                      type='text'
                      value={this.state.campaignDetails.walletNote}
                      name='walletNote'
                      id='walletNote'
                      invalid={this.state.fieldValidation.isReferenceValid === false}
                      onChange={t => {
                        CampaignActions.updateMacroField(t)
                        this.validateForm()
                      }}
                      disabled={this.props.readOnly}
                    />
                  </Col>
                  <Col xs='2'>{!this.props.isKam && <StyledLabel>OG/ELLER</StyledLabel>}</Col>
                  <Col>{clauseDatacolumns}</Col>
                </Row>
              </Col>
            </Row>
            <Row className='my-2'>
              <Col className='ml-4'>
                <Input
                  type='checkbox'
                  name='isCompoundCampaign'
                  checked={this.state.campaignDetails.isCompoundCampaign}
                  id='isCompoundCampaign'
                  onChange={t => {
                    CampaignActions.updateMacroField(t)
                  }}
                />
                <h6>
                  <strong>Sammentælling af solgte produkter:</strong>
                </h6>
              </Col>
            </Row>
            <Row className='my-2'>
              <StyledTextLabel>Sælg minimum</StyledTextLabel>
              <Col xs={2}>
                <Input
                  type='number'
                  min={2}
                  name='compoundCampaignMinValue'
                  invalid={this.state.fieldValidation.isPointConfigurationValid === false}
                  value={this.state.campaignDetails.compoundCampaignMinValue}
                  id='compoundCampaignMinValue'
                  onChange={t => {
                    CampaignActions.updateMacroField(t)
                  }}
                  disabled={!this.state.campaignDetails.isCompoundCampaign}
                />
              </Col>
              <StyledCountTextLabel>og maksimum</StyledCountTextLabel>
              <Col xs={2}>
                <Input
                  type='number'
                  min={2}
                  name='compoundCampaignMaxValue'
                  invalid={this.state.fieldValidation.isPointConfigurationValid === false}
                  value={this.state.campaignDetails.compoundCampaignMaxValue}
                  id='compoundCampaignMaxValue'
                  onChange={t => {
                    CampaignActions.updateMacroField(t)
                  }}
                  disabled={!this.state.campaignDetails.isCompoundCampaign}
                />
              </Col>
              <Col xs='4'>
                <StyledCountTextLabel>kampagne produkter og opnå </StyledCountTextLabel>
              </Col>
            </Row>
            <Row className='my-2 ml-2'>
              <Col xs='1'>
                <Input
                  type='checkbox'
                  name='compoundCampaignDoublePointCheckbox'
                  invalid={this.state.fieldValidation.isPointConfigurationValid === false}
                  checked={this.state.campaignDetails.compoundCampaignDoublePointCheckbox}
                  id='compoundCampaignDoublePointCheckbox'
                  onChange={t => {
                    CampaignActions.updateMacroField(t)
                  }}
                  disabled={!this.state.campaignDetails.isCompoundCampaign}
                />
                <StyledCountTextLabel>dobbelt </StyledCountTextLabel>
              </Col>
              <Col xs='1'>
                <StyledCountTextLabel>ELLER </StyledCountTextLabel>
              </Col>
              <Col xs='1'>
                <Input
                  type='checkbox'
                  name='compoundCampaignSetPointCheckbox'
                  checked={this.state.campaignDetails.compoundCampaignSetPointCheckbox}
                  id='compoundCampaignSetPointCheckbox'
                  onChange={t => {
                    CampaignActions.updateMacroField(t)
                  }}
                  disabled={!this.state.campaignDetails.isCompoundCampaign}
                />
                <StyledCountTextLabel>point</StyledCountTextLabel>
              </Col>
              <Col xs='2' className='marginLeftNegative50'>
                <Input
                  type='number'
                  name='compoundCampaignSetPointValue'
                  invalid={this.state.fieldValidation.isPointConfigurationValid === false}
                  value={this.state.campaignDetails.compoundCampaignSetPointValue}
                  id='compoundCampaignSetPointValue'
                  onChange={t => {
                    CampaignActions.updateMacroField(t)
                  }}
                  disabled={!this.state.campaignDetails.isCompoundCampaign || !this.state.campaignDetails.compoundCampaignSetPointCheckbox}
                />
              </Col>
            </Row>
            <Row className='my-2'>
              <Col xs='5'>
                <p>Både min. og maks. antal er inkluderet.</p>
              </Col>
            </Row>
            <Row className='my-2'>
              <Col xs='5'>
                <h6>
                  <strong>Eller udfyld nedenstående:</strong>
                </h6>
              </Col>
            </Row>
            <Row className='my-2'>
              <Col xs='2'>
                <label>Point</label>
              </Col>
              <Col xs='10'>
                <Row xs='12'>
                  <Col xs='4'>
                    <Input
                      type='Number'
                      value={this.state.campaignDetails.normalCampaignSetPointValue}
                      name='normalCampaignSetPointValue'
                      id='normalCampaignSetPointValue'
                      invalid={this.state.fieldValidation.isPointConfigurationValid === false}
                      onChange={t => {
                        CampaignActions.updateMacroField(t)
                        this.validateForm()
                      }}
                      disabled={
                        this.props.readOnly ||
                        this.state.campaignDetails.isCompoundCampaign ||
                        this.state.campaignDetails.normalCampaignOperator !== 'NONE'
                      }
                    />
                  </Col>
                  <Col xs='1'>
                    <StyledLabel>ELLER</StyledLabel>
                  </Col>
                  <Col xs='4'>
                    <Input
                      type='select'
                      name='normalCampaignOperator'
                      value={this.state.campaignDetails.normalCampaignOperator}
                      invalid={this.state.fieldValidation.isPointConfigurationValid === false}
                      onChange={t => {
                        CampaignActions.updateMacroField(t)
                        this.validateForm()
                      }}
                      disabled={
                        this.props.readOnly || this.state.campaignDetails.isCompoundCampaign || this.state.campaignDetails.normalCampaignSetPointValue
                      }
                    >
                      <option value='' disabled>
                        Klik for at vælge
                      </option>
                      {operatorList.map((e, i) => {
                        return (
                          <option value={e.value} key={i}>
                            {e.name}
                          </option>
                        )
                      })}
                    </Input>
                  </Col>
                  <Col xs='3'>
                    <Input
                      type='number'
                      disabled={
                        this.state.campaignDetails.normalCampaignOperator === 'SET' || this.props.readOnly
                          ? true
                          : false || this.state.campaignDetails.isCompoundCampaign || this.state.campaignDetails.normalCampaignSetPointValue
                      }
                      invalid={this.state.fieldValidation.isPointConfigurationValid === false}
                      value={this.state.campaignDetails.normalCampaignOperatorValue}
                      name='normalCampaignOperatorValue'
                      id='normalCampaignOperatorValue'
                      onChange={t => {
                        CampaignActions.updateMacroField(t)
                        this.validateForm()
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {!this.props.readOnly && (
              <>
                <Button onClick={this.saveCampaignForm} disabled={this.state.status === STATUS.IS_SAVING}>
                  {this.state.status === STATUS.IS_SAVING ? (
                    <FontAwesomeIcon icon='spinner' spin />
                  ) : this.props.isKam ? (
                    'Send til godkendelse'
                  ) : (
                    'Gem'
                  )}
                </Button>
                {!this.props.isKam && (
                  <Button color='danger' onClick={this.resetMacro} className='ml-2'>
                    Nulstil
                  </Button>
                )}
              </>
            )}
            {this.state.campaignDetails.status === 'PENDING' &&
              this.state.status !== STATUS.CAMPAIGN_STATUS_CHANGE_PENDING &&
              this.state.hasAccessToCampaignStatus && (
                <>
                  <Button
                    onClick={() => {
                      this.changeCampaignStatus({ campaignId: this.props.match.params.CampaignID, status: 'ACCEPTED' })
                    }}
                    className='ml-2'
                  >
                    Godkend kampagne
                  </Button>
                  <Button
                    color='danger'
                    onClick={() => {
                      this.changeCampaignStatus({ campaignId: this.props.match.params.CampaignID, status: 'REJECTED' })
                    }}
                    className='ml-2'
                  >
                    Afvis kampagne
                  </Button>
                </>
              )}
          </Form>
        </CollapsibleCard>
        {this.state.campaignDetails.status && <CampaignUserPreview users={this.state.campaignDetails.users} />}
        {this.state.showModal && <CreateProductModal showModal={this.state.showModal} productDetails={this.state.productDetails} />}
      </React.Fragment>
    )
  }
}
