import React, { useState, useRef, useCallback } from 'react'
import { ProductActions } from 'Stores/productStore'
import { Row, Col, Input, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import ReactCrop from 'react-image-crop'
import Proxies from 'Components/proxies'
import 'react-image-crop/dist/ReactCrop.css'

const StyledContentRow = styled(Row)`
  border-bottom: 3px dashed #ddd;
`

export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        blob.name = fileName
        resolve(blob)
      },
      'image/png',
      1
    )
  })
}

export default function ProductImageUpload() {
  const defaultCrop = { unit: '%', width: 50, aspect: 0.74 }
  const [upImg, setUpImg] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [origImg, setOrigImg] = useState(null)
  const [crop, setCrop] = useState(defaultCrop)
  const [completedCrop, setCompletedCrop] = useState(null)
  const imgRef = useRef(null)

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      const file = e.target.files[0]
      reader.readAsDataURL(file)
      setOrigImg(file)
    }
  }

  const onApprove = () => {
    getCroppedImg(imgRef.current, completedCrop, origImg.name).then(result => {
      setIsUploading(true)
      Proxies.uploadImage(result).then(responseJSON => {
        ProductActions.fileUploaded(responseJSON)
        setUpImg(null)
        setIsUploading(false)
        setOrigImg(null)
        setCrop(defaultCrop)
        setCompletedCrop(null)
      })
    })
  }

  return (
    <>
      <Row>
        <Col xs='2'>
          <label>Attachments</label>
        </Col>
        <Col xs='2'>
          <Input
            type='file'
            accept='image/*'
            onClick={e => (e.target.value = '') /*To enable uploading same image twice in a row*/}
            onChange={onSelectFile}
            disabled={isUploading}
          />
        </Col>
        {upImg && (
          <Col className='mt-2' xs={{ size: 10, offset: 2 }}>
            <Button onClick={onApprove}>{isUploading ? <FontAwesomeIcon icon='spinner' spin /> : 'Godkend'}</Button>
          </Col>
        )}
      </Row>
      <StyledContentRow className='pb-3 mb-3'>
        {upImg && (
          <>
            <Col xs='2'></Col>
            <Col>
              <ReactCrop
                className='mt-2'
                src={upImg}
                crop={crop}
                onImageLoaded={onLoad}
                onChange={c => setCrop(c)}
                onComplete={c => setCompletedCrop(c)}
              />
            </Col>
          </>
        )}
      </StyledContentRow>
    </>
  )
}
