/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Form, Input, Row, Col } from 'reactstrap'
import FormField from 'Components/formField'
import { toast } from 'react-toastify'
import { EnvelopeStore, EnvelopeActions } from 'Stores/envelopeStore'
import moment from 'moment'
import SearchableDropdown from './searchableDropdown'
import styled from 'styled-components'

const StyledAnchor = styled.a`
  cursor: pointer !important;
`

const Envelope = props => {
  if (props.elements) {
    return <EnvelopeElements {...props} />
  }
  if (props.create) {
    return <EnvelopeDetails {...props} />
  }
  if (props.match && props.match.params && props.match.params.EnvelopeID) {
    return <EnvelopeDetails {...props} />
  }
  return <EnvelopeList {...props} />
}

export default Envelope

class EnvelopeList extends Component {
  constructor(props) {
    super(props)
    this.store = EnvelopeStore
  }
  componentDidMount() {
    if (this.state.envelopes.length == 0 && !this.state.fetchingEnvelopes) {
      EnvelopeActions.getEnvelopes()
    }
  }
  componentDidUpdate() {
    if (this.state.sendingEnvelopeSuccess) {
      toast.success('Sent all notifications successfully', {
        autoClose: 5000
      })
      EnvelopeActions.clearToast()
    }
    if (this.state.sendingEnvelopeError) {
      toast.error('Error in sending notifications', {
        autoClose: 5000
      })
      EnvelopeActions.clearToast()
    }
    if (this.state.deletingEnvelopeSuccess) {
      toast.success('Deleted envelope successfully', {
        autoClose: 5000
      })
      EnvelopeActions.clearToast()
    }
    if (this.state.deletingEnvelopeError) {
      toast.error('Error in deleting envelope', {
        autoClose: 5000
      })
      EnvelopeActions.clearToast()
    }
  }
  render() {
    if (this.state.fetchingEnvelopes) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Beskeder</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'EnvelopeID'
            },
            fieldFunctions: {
              group: e => {
                return (
                  <React.Fragment>
                    {e.group ? e.group.moduleName : ''}
                    <br />
                  </React.Fragment>
                )
              },
              envelopeApproved: columnVal => {
                if (columnVal.envelopeApproved === 1) {
                  return 'Ok'
                } else {
                  return (
                    <React.Fragment>
                      <Tooltip target={'approve' + columnVal.EnvelopeID}>Godkend</Tooltip>
                      <Button onClick={() => EnvelopeActions.approveEnvelope(columnVal.EnvelopeID)} id={'approve' + columnVal.EnvelopeID}>
                        {this.state.approvingEnvelope && this.state.currEnvelopeID === columnVal.EnvelopeID ? (
                          <FontAwesomeIcon icon='spinner' spin />
                        ) : (
                          <FontAwesomeIcon icon='thumbs-up' />
                        )}
                      </Button>
                    </React.Fragment>
                  )
                }
              },
              envelopeFrequency: e => {
                let frequency = e.envelopeFrequency
                switch (e.envelopeFrequency) {
                  case 'MINUTE':
                    frequency = 'MINUT'
                    break
                  case 'HOUR':
                    frequency = 'TIME'
                    break
                  case 'DAY':
                    frequency = 'DAG'
                    break
                  case 'WEEK':
                    frequency = 'UGE'
                    break
                  case 'MONTH':
                    frequency = 'MÅNED'
                    break
                  case 'YEAR':
                    frequency = 'År'
                    break
                }
                return frequency
              },
              envelopeMessage: e => {
                let acc = []
                if (!e.envelopeMessageReadMore && !e.envelopeMessageReadLess) {
                  return e.envelopeMessage
                }
                if (e.envelopeMessageReadMore && !e.envelopeMessageReadLess) {
                  acc.push(e.envelopeMessage.slice(0, 250) + '...')
                  acc.push(
                    <StyledAnchor key={e.EnvelopeID} onClick={() => EnvelopeActions.toggleQueryContent(e)}>
                      Show More
                    </StyledAnchor>
                  )
                  return acc
                }
                if (!e.envelopeMessageReadMore && e.envelopeMessageReadLess) {
                  acc.push(e.envelopeMessage)
                  acc.push(<br key={e.EnvelopeID} />)
                  acc.push(
                    <StyledAnchor key={e.EnvelopeID} onClick={() => EnvelopeActions.toggleQueryContent(e)}>
                      Show Less
                    </StyledAnchor>
                  )
                  return acc
                }
              }
            },
            fieldExportFunctions: {
              envelopeMessage: e => {
                return e.envelopeMessage
              },
              group: e => {
                return e.group ? e.group.moduleName : ''
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'elements' + e.EnvelopeID}>Vis</Tooltip>
                    <Button tag={Link} to={'/envelope/' + e.EnvelopeID + '/elements'} id={'elements' + e.EnvelopeID}>
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    <Tooltip target={'delete' + e.EnvelopeID}>Slet</Tooltip>
                    <Button
                      color='danger'
                      id={'delete' + e.EnvelopeID}
                      disabled={!e.envelopeApproved || (e.queue > 0 && e.delivered === 0) ? false : true}
                      onClick={() => {
                        if (confirm('Vil du slette denne besked?')) {
                          EnvelopeActions.deleteEnvelope(e.EnvelopeID)
                        }
                      }}
                    >
                      {this.state.deletingEnvelope && this.state.currEnvelopeID === e.EnvelopeID ? (
                        <FontAwesomeIcon icon='spinner' spin />
                      ) : (
                        <FontAwesomeIcon icon='trash' />
                      )}
                    </Button>
                    <Tooltip target={'send' + e.EnvelopeID}>Sende</Tooltip>
                    <Button
                      disabled={e.envelopeApproved && e.queue > 0 ? false : true}
                      id={'send' + e.EnvelopeID}
                      onClick={() => EnvelopeActions.sendEnvelope(e.EnvelopeID)}
                    >
                      {this.state.sendingEnvelope && this.state.currEnvelopeID === e.EnvelopeID ? (
                        <FontAwesomeIcon icon='spinner' spin />
                      ) : (
                        <FontAwesomeIcon icon='paper-plane' />
                      )}
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            EnvelopeID: '#',
            group: 'Gruppe',
            envelopeMessage: 'Indhold',
            envelopeFrequency: 'Frekvens',
            queue: 'Kø',
            failed: 'Fejl',
            delivered: 'Leveret',
            envelopeApproved: 'Sendt'
          }}
          data={this.state.envelopes}
        />
      </CollapsibleCard>
    )
  }
}

class EnvelopeDetails extends Component {
  constructor(props) {
    super(props)
    this.store = EnvelopeStore
    this.resetEnvelope = this.resetEnvelope.bind(this)
    this.frequency = [
      { name: 'MINUT', value: 'MINUTE' },
      { name: 'TIME', value: 'HOUR' },
      { name: 'DAG', value: 'DAY' },
      { name: 'UGE', value: 'WEEK' },
      { name: 'MÅNED', value: 'MONTH' },
      { name: 'ÅR', value: 'YEAR' }
    ]
  }
  componentWillMount() {
    super.componentWillMount()
    this.resetEnvelope()
    EnvelopeActions.getGroups()
  }
  resetEnvelope() {
    if (this.props.create) {
      EnvelopeActions.createEmptyEnvelope(this.frequency[0].value)
    }
  }
  componentDidUpdate() {
    if (this.state.showEnvelopeError) {
      if (this.props.create) {
        toast.error('Der opstod en fejl i oprettelsen af beskeden', {
          autoClose: 7500,
          onClose: () => {
            EnvelopeActions.clearToast()
          }
        })
      } else {
        toast.error('Der opstod en fejl ved opdateringen af beskeden', {
          autoClose: 7500,
          onClose: () => {
            EnvelopeActions.clearToast()
          }
        })
      }
      EnvelopeActions.clearToast()
    }
    if (this.state.showEnvelopeSuccess) {
      if (this.props.create) {
        toast.success('Beskeden er oprettet', {
          autoClose: 7500,
          onClose: () => {
            EnvelopeActions.clearToast()
          }
        })
      } else {
        toast.success('Dine ændringer er gemt', {
          autoClose: 7500,
          onClose: () => {
            EnvelopeActions.clearToast()
          }
        })
      }
      EnvelopeActions.clearToast()
    }
  }
  render() {
    if (this.state.fetchingGroups) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    if (this.state.envelopeDetails.EnvelopeID && this.props.create) {
      return <Redirect to={'/envelope/'} />
    }

    return (
      <CollapsibleCard startOpen header={<strong>{this.props.create ? 'Opret' : 'Detaljer'}</strong>}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <Row>
            <Col xs='3'>
              <label>Gruppe</label>
            </Col>
            <Col xs='9'>
              <SearchableDropdown
                setDataFn={EnvelopeActions.setEnvelopeData}
                data={this.state.envelopeDetails}
                list={this.state.groups}
                dropdown={{ value: 'GroupID', displayName: 'moduleName' }}
              ></SearchableDropdown>
            </Col>
          </Row>
          <FormField
            name='Indhold'
            field='envelopeMessage'
            type='textarea'
            data={this.state.envelopeDetails}
            onChange={EnvelopeActions.updateEnvelopeField}
          />
          <FormField name='Frekvens' field='notificationFrequency' data={this.state.envelopeDetails}>
            <Input type='select' name='notificationFrequency' id='notificationFrequency' onChange={EnvelopeActions.updateEnvelopeField}>
              {this.frequency.map((e, i) => {
                return (
                  <option value={e.value} key={i}>
                    {e.name}
                  </option>
                )
              })}
            </Input>
          </FormField>
          <Button onClick={EnvelopeActions.saveEnvelope} disabled={this.state.isSaving || this.state.envelopeDetails.envelopeApproved}>
            {this.state.isSaving ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={this.resetEnvelope} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    )
  }
}

class EnvelopeElements extends Component {
  constructor(props) {
    super(props)
    this.store = EnvelopeStore
  }
  componentDidMount() {
    if (!(this.state && this.state.fetchingElements)) {
      EnvelopeActions.getElements(this.props.match.params.EnvelopeID)
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.EnvelopeID != prevProps.match.params.EnvelopeID) {
      EnvelopeActions.getElements(this.props.match.params.EnvelopeID)
    }
  }
  render() {
    if (this.state.fetchingElements) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Besked detaljer</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'UserID'
            },
            fieldFunctions: {
              userName: e => {
                return e.user ? e.user.userName : ''
              },
              userCompany: e => {
                return e.user ? e.user.userCompany : ''
              },
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm') : ''
              },
              timeSend: e => {
                return e.timeSend ? moment(e.timeSend).format('DD-MM-YYYY HH:mm') : ''
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              },
              timeSend: e => {
                return moment(e.timeSend).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'delete' + e.NotificationID}>Slet</Tooltip>
                    <Button
                      color='danger'
                      id={'delete' + e.NotificationID}
                      onClick={() => {
                        if (confirm('Vil du slette denne notification?')) {
                          EnvelopeActions.deleteNotification(e.NotificationID, this.props.match.params.EnvelopeID)
                        }
                      }}
                    >
                      {this.state.deletingNotification && this.state.currNotificationID == e.NotificationID ? (
                        <FontAwesomeIcon icon='spinner' spin />
                      ) : (
                        <FontAwesomeIcon icon='trash' />
                      )}
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            UserID: '#',
            userName: 'Name',
            userCompany: 'Company',
            notificationGateway: 'Gateway',
            notificationRecipient: 'Recipient',
            timeInsert: 'Created',
            timeSend: 'Send'
          }}
          data={this.state.elements}
        />
      </CollapsibleCard>
    )
  }
}
