import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CampaignCollapsible from './CampaignCollapsible'
import CampaignForm from './campaignForm'

const CampaignReport = props => {
  const { NotificationCampaignID } = props.match.params
  const [notificationcampaign, setNotificationCampaign] = useState([])
  const [loading, setLoading] = useState(false)
  const [campaignData, setCampaignData] = useState({})
  const [collapse, setCollapse] = useState(null)

  useEffect(() => {
    setLoading(true)
    fetchcampaignData(NotificationCampaignID)
    fetchCampaignReport(NotificationCampaignID)
  }, [])

  const fetchcampaignData = NotificationCampaignID => {
    Proxies.GET('/notificationcampaign/fetch', { NotificationCampaignID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setCampaignData(responseJSON)
      }
      setLoading(false)
    })
  }

  const fetchCampaignReport = NotificationCampaignID => {
    setLoading(true)
    Proxies.GET('/notificationcampaign/report', { NotificationCampaignID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setNotificationCampaign(responseJSON)
      }
      setLoading(false)
    })
  }

  const [isOpen, setIsOpen] = useState(0)

  const toggle = inx => setIsOpen(prev => (inx === prev ? null : inx))

  return (
    <>
      <div style={{ fontSize: 28, fontWeight: 'bold' }}>Rapport for kampagnen “{campaignData.title}”</div>
      <CampaignForm match={props.match} report={true} />
      <div style={{ fontSize: 28, fontWeight: 'bold', marginBottom: 40 }}>Modtagere</div>
      <CollapsibleCard
        onClick={() => setCollapse(!collapse)}
        startOpen
        header={
          <strong>
            {collapse ? 'Fold kampagne ud' : 'Fold sammen'}
            {/* <Button className='float-right' tag={Link} to={'/campaign'} style={{ marginLeft: '16px' }}>
              <FontAwesomeIcon icon='arrow-left' />
              &nbsp;&nbsp;Tilbage til oversigt
            </Button> */}
          </strong>
        }
      >
        <div>
          {loading ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            notificationcampaign &&
            notificationcampaign.length &&
            notificationcampaign.map((item, inx) => {
              return (
                <div
                  key={inx}
                  style={{ borderBottom: notificationcampaign && notificationcampaign.length > 1 ? '2px solid #F7F7F7' : 'none', padding: 5 }}
                >
                  <CampaignCollapsible
                    campaignData={campaignData}
                    inx={inx}
                    itemData={item}
                    notificationcampaign={notificationcampaign}
                    isOpen={isOpen}
                    toggle={toggle}
                  />
                </div>
              )
            })
          )}
        </div>
      </CollapsibleCard>
      <div style={{ display: 'flex', margin: 'auto', marginTop: '50px', marginBottom: '50px', width: '100%', justifyContent: 'space-between' }}>
        <div style={{ textAlign: 'center', padding: '30px 10px', width: '33%', backgroundColor: '#ffffff' }}>
          <h3 style={{ fontWeight: 'bolder' }}>
            {(notificationcampaign && notificationcampaign[isOpen] && notificationcampaign[isOpen].users.length) || 0}
          </h3>
          <h6 style={{ fontWeight: 'bolder' }}>Modtagere i alt</h6>
        </div>
        <div style={{ textAlign: 'center', padding: '30px 10px', width: '33%', backgroundColor: '#ffffff' }}>
          <h3 style={{ fontWeight: 'bolder' }}>
            {(notificationcampaign &&
              notificationcampaign[isOpen] &&
              notificationcampaign[isOpen].users.filter(item => item.status === 'DELIVERED').length) ||
              0}
          </h3>
          <h6 style={{ fontWeight: 'bolder' }}>Leverede beskeder</h6>
        </div>
        <div style={{ textAlign: 'center', padding: '30px 10px', width: '33%', backgroundColor: '#ffffff' }}>
          <h3 style={{ fontWeight: 'bolder' }}>
            {(notificationcampaign &&
              notificationcampaign[isOpen] &&
              notificationcampaign[isOpen].users.filter(item => item.status === 'FAILED').length) ||
              0}
          </h3>
          <h6 style={{ fontWeight: 'bolder' }}>Uleverede beskeder</h6>
        </div>
      </div>
    </>
  )
}

export default CampaignReport
