import React from 'react'
import { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProductStore, ProductActions, STATUS } from 'Stores/productStore'
import styled from 'styled-components'
import { operatorsList } from 'Util/operators'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Input, Table, Row } from 'reactstrap'
import { toast } from 'react-toastify'

const StyledButton = styled(Button)`
  background: ${props => props.selected && '#716ACA'} !important;
`
const FilterButton = styled(Button)`
  position: absolute;
  right: 110px;
`
const productStatus = {
  AVAILABLE: 'Available',
  PREORDER: 'Pre order',
  BACKORDER: 'Back order',
  UNAVAILABLE: 'Unavailable'
}
const filterFields = {
  'Product.productStatus': 'Product Status',
  'Product.ProductID': 'Product ID',
  'Catalog.moduleName': 'Catalog Name',
  'Catalog.catalogType': 'Catalog Type',
  'Catalog.catalogAccessType': 'Catalog AccessType',
  'Supplier.supplierName': 'Supplier',
  'Product.manufacturer': 'Manufacturer',
  'Category.categoryName': 'Category Name',
  'Variation.variationTitle': 'Variation Name',
  'Variation.variationUID': 'Variation Number'
}
const catalogTypes = {
  TRANSACTION: 'Transaction',
  SUBSCRIPTION: 'Subscription',
  QUOTE: 'Quote',
  EVENT: 'Event',
  TRAINING: 'Training'
}
const catalogAccessTypes = {
  PRIVATE: 'Private',
  PROTECTED: 'Protected',
  PUBLIC: 'Public'
}

export default class ProductFilters extends Component {
  constructor(props) {
    super(props)
    this.store = ProductStore
    this.storeKeys = ['filters', 'status', 'catalogs', 'predefinedFilters', 'brands', 'suppliers']
  }
  componentDidMount() {
    ProductActions.getCategories()
    ProductActions.getSuppliers()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.CATALOGS_ERROR) {
      toast.error('Fetching Catalogs Failed', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
  }
  handleKeypress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      ProductActions.getProducts()
    }
  }
  render() {
    return (
      <Card>
        <CardHeader tag='h4'>Filters</CardHeader>
        <CardBody>
          <Table responsive size='sm'>
            <thead>
              <tr>
                <th xs='3'>Kilde</th>
                <th xs='3'>Operatør</th>
                <th xs='3'>Værdi</th>
                <th xs='3'>
                  <Button onClick={ProductActions.addFilter}>
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.filters.map((e, i) => {
                let filter = (
                  <Input
                    type='text'
                    onKeyDown={this.handleKeypress}
                    onChange={t => {
                      ProductActions.updateFilter(e, t)
                    }}
                    value={e.value}
                    name='value'
                  />
                )
                switch (e.column) {
                  case 'Product.productStatus':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          ProductActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value='' disabled>
                          Choose
                        </option>
                        {Object.keys(productStatus).map((status, index) => {
                          return (
                            <option key={index} value={status}>
                              {productStatus[status]}
                            </option>
                          )
                        })}
                      </Input>
                    )
                    break
                  case 'Catalog.moduleName':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          ProductActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value='' disabled>
                          Choose
                        </option>
                        {this.state.catalogs.map((catalog, index) => {
                          return (
                            <option key={index} value={catalog.moduleName}>
                              {catalog.moduleName}
                            </option>
                          )
                        })}
                      </Input>
                    )
                    break
                  case 'Catalog.catalogType':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          ProductActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value='' disabled>
                          Choose
                        </option>
                        {Object.keys(catalogTypes).map((catalog, index) => {
                          return (
                            <option key={index} value={catalog}>
                              {catalogTypes[catalog]}
                            </option>
                          )
                        })}
                      </Input>
                    )
                    break
                  case 'Catalog.catalogAccessType':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          ProductActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value='' disabled>
                          Choose
                        </option>
                        {Object.keys(catalogAccessTypes).map((catalog, index) => {
                          return (
                            <option key={index} value={catalog}>
                              {catalogAccessTypes[catalog]}
                            </option>
                          )
                        })}
                      </Input>
                    )
                    break
                  case 'Supplier.supplierName':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          ProductActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value='' disabled>
                          Choose
                        </option>
                        {this.state.suppliers.map((supplier, index) => {
                          return (
                            <option key={index} value={supplier.supplierName}>
                              {supplier.supplierName}
                            </option>
                          )
                        })}
                      </Input>
                    )
                    break
                  case 'Product.manufacturer':
                    filter = (
                      <Input
                        type='select'
                        onChange={t => {
                          ProductActions.updateFilter(e, t)
                        }}
                        value={e.value}
                        name='value'
                      >
                        <option value='' disabled>
                          Choose
                        </option>
                        {this.state.brands.map((s, is) => {
                          return (
                            <option key={is} value={s.categoryName}>
                              {s.categoryName}
                            </option>
                          )
                        })}
                      </Input>
                    )
                    break
                }
                let column = (
                  <Input
                    type='select'
                    onChange={t => {
                      ProductActions.updateFilter(e, t)
                    }}
                    value={e.column}
                    name='column'
                  >
                    <option value='' disabled>
                      Choose
                    </option>
                    {Object.keys(filterFields).map((s, is) => {
                      return (
                        <option key={is} value={s}>
                          {filterFields[s]}
                        </option>
                      )
                    })}
                  </Input>
                )
                let operator = (
                  <Input
                    type='select'
                    onChange={t => {
                      ProductActions.updateFilter(e, t)
                    }}
                    value={e.operator}
                    name='operator'
                  >
                    <option value='' disabled>
                      Choose
                    </option>
                    {operatorsList.map((s, is) => {
                      return (
                        <option key={is} value={s.value}>
                          {s.name}
                        </option>
                      )
                    })}
                  </Input>
                )
                return (
                  <tr key={i}>
                    <td>{column}</td>
                    <td>{operator}</td>
                    <td onKeyDown={this.handleKeypress}>{filter}</td>
                    <td>
                      <Button
                        onClick={() => {
                          ProductActions.removeFilter(i)
                        }}
                      >
                        <FontAwesomeIcon icon='minus' />
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <h6>Præ-definerede filtre</h6>
          <Row xs='10'>
            <ButtonGroup>
              {this.state.predefinedFilters.map((item, index) => {
                return (
                  <StyledButton
                    className='m-1'
                    key={index}
                    selected={item.selected}
                    onClick={() => {
                      ProductActions.applyPredefinedFilter(index)
                    }}
                  >
                    {item.name}
                  </StyledButton>
                )
              })}
            </ButtonGroup>
            <FilterButton onClick={ProductActions.getProducts}>Filtrer</FilterButton>
          </Row>
        </CardBody>
      </Card>
    )
  }
}
