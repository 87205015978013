import Reflux from 'reflux'

const translateString = str => {
  const { translations } = Reflux.GlobalState.LoginStore.settings
  if (translations && translations.length) {
    const match = translations.filter(trans => trans.translationKey === str)
    if (match.length) {
      return match[0].translationValue
    } else {
      return str
    }
  } else {
    return str
  }
}

export const translateData = data => {
  if (Array.isArray(data)) {
    return data.map(item => {
      Object.keys(item).forEach(key => {
        item[key] = translateString(item[key])
      })
      return item
    })
  } else {
    return translateString(data)
  }
}
