import { createSlice } from '@reduxjs/toolkit'

export const cronSlice = createSlice({
  name: 'cron',
  initialState: {
    cronPool: [],
    scheduleOptions: []
  },
  reducers: {
    setCronPool: (state, action) => {
      state.cronPool = action.payload
      state.scheduleOptions = action.payload
        .map(item => item.scheduleEnglish)
        .filter((item, pos, a) => a.indexOf(item) === pos)
        .sort()
    },
    resetCronPool: state => {
      state.cronPool = []
      state.scheduleOptions = []
    }
  }
})
export const { setCronPool, resetCronPool } = cronSlice.actions
export default cronSlice.reducer
