import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert
} from 'reactstrap'
import classnames from 'classnames'
import CollapsibleCard from 'Components/collapsibleCard'
import DatePicker from 'react-datepicker'
import DynamicTable from 'Components/dynamicTable'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllCompanies,
  fetchAllUsers,
  STATUS,
  fetchCompanySales,
  fetchUserSales,
  fetchUsers,
  fetchInitialState,
  fetchUserSalesInitialState,
  fetchCompanySalesInitialState,
  fetchActiveUsers,
  fetchLikes,
  fetchReadPosts,
  fetchNewPosts
} from 'Reducers/actions/kamCompanyDashboardActions'
import { getKamAllUsers, getKamCompanies, getStatus } from 'Reducers/kamCompanyDashboardReducer'

const StyledFromDateDiv = styled.div`
    display: inline-block;
    float: left;
    padding: 5px 17px 5px 0px;
}
`
const StyledToDateDiv = styled.div`
    margin-left: 20px;
    display: inline-block;
    padding: 5px 17px 5px 0px;
}
`

const StyledNavLink = styled(NavLink)`
  color: green !important;
  padding: 10px 20px;
`

const StyledTabContent = styled(TabContent)`
  background: none;
`

const StyledWrapper = styled.div`
  .react-datepicker {
    border: none !important;
  }
  .react-datepicker__header {
    background-color: white !important;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    margin-bottom: 10px !important;
  }
`
const ChartWrap = styled.div`
  height: 300px;
`
const StyledAlert = styled(Alert)`
  display: inline-block !important;
  margin: 0px 0px 15px 7px;
`
export default class KamCompanyDashboard extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <KamCompanyDashboardComponent props={this.props} />
      </React.Fragment>
    )
  }
}

function KamCompanyDashboardComponent({ props }) {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('1')
  const [startDate, setStartDate] = useState(moment('2017-01-01T12:00:00').format())
  const [endDate, setEndDate] = useState(moment().format())
  const kamUsers = useSelector(getKamAllUsers)
  const [kamUsersList, setKamUsersList] = useState(kamUsers)
  const [company, setCompany] = useState({})
  const [user, setUser] = useState({})
  const [isCompanyFilter, setCompanyFilter] = useState(false)
  const [isUserFilter, setUserFilter] = useState(false)
  const [isCVRFilter, setCVRFilter] = useState(false)
  const [companyName, setCompanyName] = useState()
  const [kam, setKam] = useState()
  const [CVR, setCVR] = useState()
  const kamCompaniesList = useSelector(getKamCompanies)
  const users = useSelector(state => state.kamDashboard.overviewUsers)
  const loadingState = useSelector(getStatus)
  const companySales = useSelector(state => state.kamDashboard.companySales)
  const userSales = useSelector(state => state.kamDashboard.userSales)
  const newPosts = useSelector(state => state.kamDashboard.newPosts)
  const readPosts = useSelector(state => state.kamDashboard.readPosts)
  const activeUsers = useSelector(state => state.kamDashboard.activeUsers)
  const likes = useSelector(state => state.kamDashboard.likes)

  useEffect(() => {
    dispatch(fetchInitialState())
    dispatch(fetchAllUsers(props.match.params.supervisorUID && { supervisorUID: props.match.params.supervisorUID }))
    dispatch(fetchAllCompanies(props.match.params.supervisorUID && { supervisorUID: props.match.params.supervisorUID }))
  }, [])

  useEffect(() => {
    if (kamUsers && kamUsers.length && !kamUsersList.length) {
      setKamUsersList(kamUsers)
    }
  }, [kamUsers])

  let graphBaseData = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMin: 0
          }
        }
      ]
    },
    animation: false
  }
  let companyGraphData = {
    labels:
      companySales && !!companySales.length && users && !!users.length
        ? companySales.map(e => {
            return `${e.Day}/${e.Month}/${e.Year}`
          })
        : [],
    datasets: [
      {
        label: `${'userCompany' in company ? company.userCompany : 'Vælg Virksomhed'}`,
        borderColor: '#3e95cd',
        fill: false,
        data:
          companySales && !!companySales.length && users && !!users.length
            ? companySales.map(e => {
                return e.amount
              })
            : []
      }
    ]
  }
  let userGraphData = {
    labels:
      userSales && userSales.length > 0 && users && !!users.length
        ? userSales.map(e => {
            return `${e.Day}/${e.Month}/${e.Year}`
          })
        : [],
    datasets: [
      {
        label: `${'userName' in user ? user.userName : 'Vælg Brugere'}`,
        borderColor: '#3e95cd',
        fill: false,
        data:
          userSales && userSales.length > 0 && users && !!users.length
            ? userSales.map(e => {
                return e.amount
              })
            : []
      }
    ]
  }
  let userOverview = [...users]
  //user Table filters
  if (isCompanyFilter) {
    userOverview = userOverview.filter(e => {
      return e.cvr === company.userCompanyUID
    })
  }
  if (isUserFilter) {
    userOverview = userOverview.filter(e => {
      return e.KAM === kam
    })
  }
  if (isCVRFilter) {
    userOverview = userOverview.filter(e => {
      return e.cvr === CVR
    })
  }
  const getCompanySales = data => {
    setCompany(data)
    let filteredUsers = kamUsers.filter(item => item.userCompanyUID === data.userCompanyUID)
    setKamUsersList(filteredUsers)
    dispatch(fetchCompanySales({ userCompanyUID: data.userCompanyUID, fromDate: startDate, toDate: endDate }))
  }
  const getUserSales = data => {
    setUser(data)
    dispatch(fetchUserSales({ UserID: data.UserID, fromDate: startDate, toDate: endDate }))
  }
  const getData = () => {
    if (activeTab === '1') {
      dispatch(
        fetchUsers({
          fromDate: startDate,
          toDate: endDate,
          ...(props.match.params.supervisorUID && { supervisorUID: props.match.params.supervisorUID })
        })
      )
    } else if (activeTab === '2') {
      dispatch(fetchActiveUsers(props.match.params.supervisorUID && { supervisorUID: props.match.params.supervisorUID }))
      dispatch(fetchLikes(props.match.params.supervisorUID && { supervisorUID: props.match.params.supervisorUID }))
      dispatch(
        fetchReadPosts({
          fromDate: startDate,
          toDate: endDate,
          ...(props.match.params.supervisorUID && { supervisorUID: props.match.params.supervisorUID })
        })
      )
      dispatch(
        fetchNewPosts({
          fromDate: startDate,
          toDate: endDate,
          ...(props.match.params.supervisorUID && { supervisorUID: props.match.params.supervisorUID })
        })
      )
    }
  }
  const TimePeriod = () => {
    return (
      <React.Fragment>
        <StyledFromDateDiv>
          <p className='text-center'>
            <strong>Fra</strong>
          </p>
          <StyledWrapper>
            <DatePicker
              showMonthDropdown
              fixedHeight
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              style={{ border: 'none' }}
              name='startDate'
              selected={moment(startDate)}
              inline
              onSelect={e => setStartDate(e.format())}
            />
          </StyledWrapper>
        </StyledFromDateDiv>
        <StyledToDateDiv>
          <p className='text-center'>
            <strong>Til</strong>
          </p>
          <StyledWrapper>
            <DatePicker
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              fixedHeight
              style={{ border: 'none' }}
              name='endDate'
              selected={moment(endDate)}
              inline
              onSelect={e => setEndDate(e.format())}
            />
          </StyledWrapper>
        </StyledToDateDiv>
        <Row>
          <Button style={{ margin: '4px 25px' }} onClick={getData}>
            Hent Data
          </Button>
        </Row>
      </React.Fragment>
    )
  }
  const clearFilter = data => {
    setUser({})
    setCompany({})
    dispatch(fetchUserSalesInitialState())
    dispatch(fetchCompanySalesInitialState())
    if (data === 'company') {
      setCompanyFilter(false)
      setCompanyName(null)
    }
    if (data === 'kam') {
      setKam(null)
      setUserFilter(false)
    }
    if (data === 'cvr') {
      setCVR(null)
      setCVRFilter(false)
    }
  }
  const applyFilter = (data, filter, event) => {
    event.preventDefault()
    if (filter === 'kam') {
      setUserFilter(true)
      setKam(data.KAM)
    } else if (filter === 'cvr') {
      setCVRFilter(true)
      setCVR(data.cvr)
    } else {
      setCompanyFilter(true)
      setCompanyName(data.company)
    }
    getCompanySales({ userCompanyUID: data.cvr, userCompany: data.company })
    getUserSales({ UserID: data.ID, userName: data.name })
  }
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <StyledNavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  setActiveTab('1')
                }}
              >
                <FontAwesomeIcon icon='table' />
                <span style={{ marginLeft: '10px', padding: '0px 5px' }}>Overblik</span>
              </StyledNavLink>
            </NavItem>
            <NavItem>
              <StyledNavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  setActiveTab('2')
                }}
              >
                <FontAwesomeIcon icon='chart-pie' />
                <span style={{ marginLeft: '10px' }}>Adfærd</span>
              </StyledNavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <div>
        <StyledTabContent activeTab={activeTab}>
          <TabPane tabId='1'>
            <Row>
              <Col sm='12'>
                <CollapsibleCard style={{ display: 'block' }} header={<strong>Vælg periode</strong>} startOpen={'true'}>
                  <TimePeriod />
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <CollapsibleCard header={<strong>Statistik</strong>} startOpen={users.length > 0 ? true : false}>
                  <Row>
                    <Col xs={6}>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle>
                          Vælg Virksomhed {loadingState.fetchCompanySales === STATUS.LOADING_COMPANY_SALES && <FontAwesomeIcon icon='spinner' spin />}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: '200px'
                                  }
                                }
                              }
                            }
                          }}
                        >
                          {kamCompaniesList &&
                            !!kamCompaniesList.length &&
                            kamCompaniesList.map((item, index) => {
                              return (
                                <DropdownItem onClick={() => getCompanySales(item)} value={item.userCompanyUID} key={index}>
                                  {item.userCompany}
                                </DropdownItem>
                              )
                            })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {/* <StyledCompanyParagraph>
                        <strong>{props.company},Virksomhed</strong>
                      </StyledCompanyParagraph> */}
                      <ChartWrap>
                        <Line data={companyGraphData} options={graphBaseData} height={300} width={200} />
                      </ChartWrap>
                    </Col>

                    <Col xs={6}>
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle>
                          Vælg Bruger {loadingState.fetchingUserSales === STATUS.LOADING_USER_SALES && <FontAwesomeIcon icon='spinner' spin />}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: '200px'
                                  }
                                }
                              }
                            }
                          }}
                        >
                          {kamUsersList &&
                            !!kamUsersList.length &&
                            kamUsersList.map((item, index) => {
                              return (
                                <DropdownItem onClick={() => getUserSales(item)} value={item.UserID} key={index}>
                                  {item.userName}
                                </DropdownItem>
                              )
                            })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {/* <StyledCompanyParagraph>
                        <strong>{props.company},Brugere</strong>
                      </StyledCompanyParagraph> */}
                      <ChartWrap>
                        <Line data={userGraphData} options={graphBaseData} height={300} width={200} />
                      </ChartWrap>
                    </Col>
                  </Row>
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <CollapsibleCard
                  header={
                    <strong>
                      Brugere{loadingState.fetchOverviewUsers === STATUS.LOADING_OVERVIEW_USERS && <FontAwesomeIcon icon='spinner' spin />}{' '}
                    </strong>
                  }
                  startOpen={users && !!users.length ? 'true' : 'false'}
                >
                  {isCompanyFilter && (
                    <StyledAlert color='success' isOpen={isCompanyFilter} toggle={() => clearFilter('company')}>
                      {companyName}
                    </StyledAlert>
                  )}

                  {isUserFilter && (
                    <StyledAlert color='success' isOpen={isUserFilter} toggle={() => clearFilter('kam')}>
                      {kam}
                    </StyledAlert>
                  )}
                  {isCVRFilter && (
                    <StyledAlert color='success' isOpen={isCVRFilter} toggle={() => clearFilter('cvr')}>
                      {CVR}
                    </StyledAlert>
                  )}
                  <DynamicTable
                    customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                    responsive
                    size='sm'
                    headers={{
                      ID: 'Bruger ID',
                      active: 'Aktiv bruger',
                      KAM: 'KAM',
                      name: 'Navn',
                      company: 'Virksomhed',
                      cvr: 'CVR',
                      postsReadInPeriod: 'Antal poster læst i perioden',
                      postsReadTotal: 'Totalt antal poster læst',
                      postsInPeriod: 'Antal poster i perioden',
                      postsTotal: 'Total antal poster',
                      pointsEarned: 'Optjente point',
                      pointsSpend: 'Brugte point',
                      pointsTotal: 'Total antal point'
                    }}
                    options={{
                      fieldExportFunctions: {
                        KAM: e => {
                          return e.KAM
                        },
                        cvr: e => {
                          return e.cvr
                        },
                        company: e => {
                          return e.company
                        },
                        active: e => {
                          return e.active === 0 ? 'Nej' : 'ja'
                        }
                      },
                      fieldStringFunctions: {
                        KAM: e => {
                          return e.KAM
                        },
                        cvr: e => {
                          return e.cvr
                        },
                        company: e => {
                          return e.company
                        },
                        active: e => {
                          return e.active === 0 ? 'Nej' : 'ja'
                        }
                      },
                      fieldFunctions: {
                        KAM: e => {
                          return (
                            <span style={{ color: '#51b89b', cursor: 'pointer' }} onClick={event => applyFilter(e, 'kam', event)}>
                              {e.KAM}
                            </span>
                          )
                        },
                        cvr: e => {
                          return (
                            <span style={{ color: '#51b89b', cursor: 'pointer' }} onClick={event => applyFilter(e, 'cvr', event)}>
                              {e.cvr}
                            </span>
                          )
                        },
                        company: e => {
                          return (
                            <span style={{ color: '#51b89b', cursor: 'pointer' }} onClick={event => applyFilter(e, 'company', event)}>
                              {e.company}
                            </span>
                          )
                        },
                        active: e => {
                          return e.active === 0 ? 'Nej' : 'ja'
                        }
                      }
                    }}
                    data={userOverview}
                  />
                </CollapsibleCard>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId='2'>
            <React.Fragment>
              <CollapsibleCard style={{ display: 'block' }} header={<strong>Vælg periode</strong>} startOpen={true}>
                <TimePeriod />
              </CollapsibleCard>
              <CollapsibleCard
                header={
                  <strong>
                    Nye poster i den valgte periode{' '}
                    {loadingState.fetchingNewPosts === STATUS.LOADING_NEW_POSTS && <FontAwesomeIcon icon='spinner' spin />}{' '}
                  </strong>
                }
                startOpen={newPosts.length > 0 ? true : false}
              >
                <DynamicTable
                  customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                  responsive
                  size='sm'
                  data={newPosts}
                  headers={{
                    elementTitle: 'Post',
                    postTimeInsert: 'Post Dato',
                    blog: 'Blog'
                  }}
                  options={{
                    fieldFunctions: {
                      blog: e => {
                        let rVal = e.blogTitle
                        if (e.removedBlog === '1') {
                          rVal = rVal + ' (slettet)'
                        }
                        return rVal
                      },
                      postTimeInsert: e => {
                        return e.postTimeInsert ? moment(e.postTimeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                      }
                    },
                    fieldStringFunctions: {
                      postTimeInsert: e => {
                        return moment(e.postTimeInsert).format('x')
                      }
                    }
                  }}
                />
              </CollapsibleCard>

              <CollapsibleCard
                header={
                  <strong>
                    Poster læst i den valgte periode{' '}
                    {loadingState.fetchingReadPosts === STATUS.LOADING_READ_POSTS && <FontAwesomeIcon icon='spinner' spin />}
                  </strong>
                }
                startOpen={readPosts.length > 0 ? true : false}
              >
                <DynamicTable
                  customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                  responsive
                  size='sm'
                  data={readPosts}
                  headers={{
                    UserID: 'Bruger ID',
                    userName: 'Navn',
                    userCompany: 'Virksomhed',
                    userCompanyUID: 'CVR',
                    elementTitle: 'Post',
                    postTimeInsert: 'Post dato',
                    eventTimeInsert: 'Read dato'
                  }}
                  options={{
                    fieldFunctions: {
                      postTimeInsert: e => {
                        return e.postTimeInsert ? moment(e.postTimeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                      },
                      eventTimeInsert: e => {
                        return e.eventTimeInsert ? moment(e.eventTimeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                      }
                    },
                    fieldStringFunctions: {
                      postTimeInsert: e => {
                        return moment(e.postTimeInsert).format('x')
                      },
                      eventTimeInsert: e => {
                        return moment(e.eventTimeInsert).format('x')
                      }
                    }
                  }}
                />
              </CollapsibleCard>

              <CollapsibleCard
                header={
                  <strong>
                    Aktive Brugere {loadingState.fetchingActiveUsers === STATUS.LOADING_ACTIVE_USERS && <FontAwesomeIcon icon='spinner' spin />}{' '}
                  </strong>
                }
                startOpen={activeUsers.length > 0 ? true : false}
              >
                <DynamicTable
                  headers={{
                    UserID: 'Bruger ID',
                    brandName: 'Brand navn',
                    userName: 'Navn',
                    userCompany: 'Virksomhed',
                    userEmail: 'Email',
                    userPhone: 'Tlf.nr'
                  }}
                  customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                  data={activeUsers}
                />
              </CollapsibleCard>
              <CollapsibleCard
                header={<strong>Likes {loadingState.fetchingLikes === STATUS.FETCHING_LIKES && <FontAwesomeIcon icon='spinner' spin />}</strong>}
                startOpen={likes.length > 0 ? true : false}
              >
                <DynamicTable
                  customcss={{ border: '1px solid rgba(0, 0, 0, 0.05)', whiteSpace: 'nowrap' }}
                  headers={{
                    userName: 'Navn',
                    userEmail: 'Email',
                    userCompany: 'Virksomhed',
                    elementTitle: 'Titel',
                    timeInsert: 'Dato'
                  }}
                  data={likes}
                  options={{
                    fieldFunctions: {
                      timeInsert: e => {
                        return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm:ss') : ''
                      }
                    },
                    fieldStringFunctions: {
                      timeInsert: e => {
                        return moment(e.timeInsert).format('x')
                      }
                    }
                  }}
                />
              </CollapsibleCard>
            </React.Fragment>
          </TabPane>
        </StyledTabContent>
      </div>
    </React.Fragment>
  )
}
