/* eslint-disable eqeqeq,no-prototype-builtins */
import moment from 'moment'
let thisVersion = 'ceta'
const versionRegExp = /^(20[0-9]{6,6})$/i
const webVersionRegExp = /^(alpha|beta|ceta|live)$/i

/* API DATA */

const generateUrlOptions = () => {
  let urlBase = window.location.hostname.split('.')[0].toLowerCase()
  let options = ['api']
  let hasVersion = false
  if (urlBase.indexOf('-') != -1) {
    let theseOptions = urlBase.split('-')
    theseOptions.shift()
    theseOptions.forEach(option => {
      if (!webVersionRegExp.test(option) && !(hasVersion && versionRegExp.test(option))) {
        options.push(option)
      }
      if (versionRegExp.test(option)) {
        hasVersion = true
      }
    })
  }
  if (!hasVersion) {
    options.push(thisVersion)
  }
  return options
}

const urlOptions = generateUrlOptions()

const cleanAPIData = data => {
  if (data.hasOwnProperty('status') && data.hasOwnProperty('package')) {
    if (data.status < 1) {
      return []
    }
    data = data.package
  } else if (Object.keys(data) == 1 && !Array.isArray(data)) {
    data = data[Object.keys(data)[0]]
  }
  return data
}

/* API 2 */

const generateAPI2URL = () => {
  if (process.env.API_TARGET) return process.env.API_TARGET
  if (process.env.ENV_TARGET === 'local') return 'http://localhost:9090/api/'
  if (process.env.ENV_TARGET === 'development') return 'http://dev.upgraderapi.dk/api/'
  return 'https://' + urlOptions.join('-') + '.upgraderapi.dk/'
}

const API2URL = generateAPI2URL()

const _fetch = (route, method, body, isQuery = false) => {
  if (!route) throw new Error('Route is undefined')
  let fullRoute = (sessionStorage.baseURL ? sessionStorage.baseURL : API2URL) + route
  let query = ''
  let opts = {
    method,
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
  }
  if (localStorage.getItem('session')) {
    if (body) {
      body.access_token = localStorage.getItem('session')
    } else {
      body = { access_token: localStorage.getItem('session') }
    }
    query = { access_token: localStorage.getItem('session') }
    opts.headers.access_token = localStorage.getItem('session')
  }
  let qs = require('qs')
  if (isQuery && body) {
    query = qs.stringify(body)
    body = undefined
  } else {
    query = qs.stringify(query)
  }
  fullRoute = fullRoute + '?' + query
  if (body) {
    Object.assign(opts, { body: JSON.stringify(body) })
  }
  return fetch(fullRoute, opts)
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        return response.text().then(text => {
          try {
            text = JSON.parse(text)
          } catch (err) {
            //
          }
          return text
        })
      }
    })
    .then(responseJSON => {
      return cleanAPIData(responseJSON)
    })
}

const GET = (route, query) => {
  return _fetch(route, 'GET', query, true)
}
const POST = (route, body) => {
  return _fetch(route, 'POST', body)
}
const PUT = (route, body) => {
  return _fetch(route, 'PUT', body)
}
const DELETE = (route, query) => {
  return _fetch(route, 'DELETE', query, true)
}

/* API 1 */

const generateBaseURL = () => {
  if (process.env.API_TARGET) return process.env.API_TARGET
  if (process.env.ENV_TARGET === 'local') return 'http://localhost:9090/api/'
  if (process.env.ENV_TARGET === 'development') return 'http://dev.upgraderapi.dk/api/'

  return 'https://' + urlOptions.join('-') + '.upgrader.club/'
}

const baseURL = generateBaseURL()

const _getJSON = (action, JSONpackage = {}, offSite = false, useActivity = false, isErrorReport = false, useFormData = false) => {
  let url = (sessionStorage.baseURL ? sessionStorage.baseURL : baseURL) + action
  let body = null
  if (useFormData) {
    body = new FormData()
    Object.keys(JSONpackage).forEach(key => {
      if (Array.isArray(JSONpackage[key])) {
        let arr = JSONpackage[key]
        arr.forEach(value => {
          body.append(key + '[]', value)
        })
      } else {
        body.append(key, JSONpackage[key])
      }
    })
    if (!isErrorReport) {
      localStorage.getItem('session') ? body.append('token', localStorage.getItem('session')) : {}
    }
  } else {
    if (!isErrorReport) {
      localStorage.getItem('session') ? (JSONpackage.token = localStorage.getItem('session')) : {}
    }
  }
  let activity = {
    method: 'POST',
    body: body ? body : JSON.stringify(JSONpackage)
  }
  if (offSite) {
    url = action
    if (useActivity == false) {
      activity = null
    }
  }
  return fetch(url, activity)
    .then(response => {
      return response.text()
    })
    .then(responseText => {
      try {
        let responseJSON = JSON.parse(responseText)
        if (responseJSON.status < 0) {
          if (offSite) {
            return responseJSON
          } else {
            if (!isErrorReport) {
              _postErrorReport(url, JSONpackage, responseText)
            }
            return false
          }
        } else if (responseJSON.status == 0 && responseJSON.privacy > 0) {
          alert(responseJSON.package.message ? responseJSON.package.message : responseJSON.message)
          return cleanAPIData(responseJSON)
        } else {
          return cleanAPIData(responseJSON)
        }
      } catch (e) {
        if (!isErrorReport) {
          _postErrorReport(url, JSONpackage, responseText)
        }
        return false
      }
    })
}

const _postErrorReport = () => {
  //if(!__DEV__) {
  //	_getJSON("https://api.upgrader.club/"+'system/log', {url:url,data:dataPackage,response:response}, true, true, true)
  //}
}

/* DATA */

const wrapFilter = (filter = null) => {
  if (filter) {
    filter = { where: filter }
    filter = JSON.stringify(filter)
    filter = { filter: filter }
  }
  return filter
}

let Proxies = { GET, POST, PUT, DELETE, _getJSON, wrapFilter }

// session stuff //
;(() => {
  // Asking for a key providing a phone number
  const sessionGrant = phoneNumber => {
    let BrandID = localStorage.getItem('BrandID') ? localStorage.getItem('BrandID') : 8
    if (!isNaN(BrandID)) {
      BrandID = parseInt(BrandID, 10)
    }
    return POST('sessions/grant', { BrandID, userPhone: phoneNumber })
  }
  Object.assign(Proxies, { sessionGrant })

  // Upon providing the key a session is created
  const sessionAccess = (phoneNumber, token) => {
    let BrandID = localStorage.getItem('BrandID') ? localStorage.getItem('BrandID') : 8
    if (!isNaN(BrandID)) {
      BrandID = parseInt(BrandID, 10)
    }
    let dato = new Date()
    if (
      !Date()
        .toString()
        .match(/\((.*)\)/)
    ) {
      dato = moment(dato.toISOString()).format('ZZ')
    } else {
      dato = Date()
        .toString()
        .match(/\((.*)\)/)
        .pop()
    }
    return POST('sessions/access', {
      BrandID,
      userPhone: phoneNumber,
      accessKey: token,
      deviceType: 'ADMIN',
      deviceAgentName: 'reactJS',
      deviceAgentVersion: '1.0.0',
      deviceTimezone: dato,
      deviceLanguage: window.navigator.language,
      deviceToken: '',
      applicationID: 'ADMIN'
    })
  }
  Object.assign(Proxies, { sessionAccess })

  // Signing out
  const sessionClear = () => {
    return POST('sessions/clear')
  }
  Object.assign(Proxies, { sessionClear })

  // Signing out
  const sessionValidate = () => {
    return GET('sessions/validate')
  }
  Object.assign(Proxies, { sessionValidate })

  const verifyAccessTerms = token => {
    return GET('profile/accept', { token: token })
  }
  Object.assign(Proxies, { verifyAccessTerms })

  const ssoValidate = (JSONpackage = {}) => {
    return GET('sessions/validate', JSONpackage, false, false, true)
  }
  Object.assign(Proxies, { ssoValidate })

  // Get Profile Data
  const profileData = () => {
    return GET('profile/data')
  }
  Object.assign(Proxies, { profileData })
})()

// competition stuff //
;(() => {
  const competitionMine = () => {
    return GET('competitions/mine')
  }
  Object.assign(Proxies, { competitionMine })

  const competitionFetch = CompetitionID => {
    return GET('competitions/' + CompetitionID)
  }
  Object.assign(Proxies, { competitionFetch })

  const competitionCreate = data => {
    return POST('competitions', data)
    //return _getJSON('competition/replace', data)
  }
  Object.assign(Proxies, { competitionCreate })

  const competitionUpdate = data => {
    //return PUT('competition/'+CompetitionID, data)
    return POST('competitions/replace', data)
  }
  Object.assign(Proxies, { competitionUpdate })

  const competitionProcess = CompetitionID => {
    return GET('competitions/process', { CompetitionID: CompetitionID })
  }
  Object.assign(Proxies, { competitionProcess })

  const competitionCompetitors = () => {
    return GET('competitions/')
  }
  Object.assign(Proxies, { competitionCompetitors })

  const getChallengesByCompetitionID = CompetitionID => {
    return GET('challenges/competition/' + CompetitionID)
  }
  Object.assign(Proxies, { getChallengesByCompetitionID })

  const challengeCreate = data => {
    return POST('competitions/challenge/', data)
  }
  Object.assign(Proxies, { challengeCreate })

  const challengeRemove = data => {
    Object.assign(data, { removed: 1 })
    return PUT('challenges', data)
  }
  Object.assign(Proxies, { challengeRemove })

  const competitionRemove = CompetitionID => {
    return POST('competitions/remove', { CompetitionID: CompetitionID })
  }
  Object.assign(Proxies, { competitionRemove })
})()

// user and seller stuff //
;(() => {
  const dealerPool = () => {
    return GET('dealers/pool')
  }
  Object.assign(Proxies, { dealerPool })

  const dealerMine = () => {
    return GET('dealer/mine')
  }
  Object.assign(Proxies, { dealerMine })

  const santanderKAMUsers = () => {
    return GET('supervisors/santanderKAMUsers')
  }
  Object.assign(Proxies, { santanderKAMUsers })

  const userPool = (filter = null) => {
    if (filter) {
      filter = wrapFilter(filter)
    }
    return GET('user', filter)
  }
  Object.assign(Proxies, { userPool })

  const dealersPool = (filter = null) => {
    if (filter) {
      filter = wrapFilter(filter)
    }
    return GET('dealers/filter', filter)
  }
  Object.assign(Proxies, { dealersPool })

  const dealersSave = dealer => {
    return PUT('dealers/save', dealer)
  }
  Object.assign(Proxies, { dealersSave })
})()

// company related stuff //
;(() => {
  const companyWallet = (filter = null) => {
    if (filter) {
      filter = wrapFilter(filter)
    }
    return GET('companywallets/elements', filter)
  }
  Object.assign(Proxies, { companyWallet })

  const companyWalletSum = () => {
    return GET('companywallets/saldo')
  }
  Object.assign(Proxies, { companyWalletSum })

  const companyWalletTransfer = data => {
    return PUT('companywallets/transfer', data)
  }
  Object.assign(Proxies, { companyWalletTransfer })
})()

/* image upload */
;(() => {
  const uploadImage = (file, BrandID) => {
    return new Promise(resolve => {
      if (Array.isArray(file)) {
        file = file[0]
      }
      if (!file) {
        resolve(false)
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        let fileName = file.originalName
        if (!fileName) {
          fileName = file.name
        }
        let _file = {
          File: file,
          base64String: reader.result,
          // biteArray: biteArray,
          contentType: file.type,
          fileExtension: file.type.split('/')[1],
          originalName: fileName,
          info: {
            width: null,
            height: null,
            size: file.size,
            format: file.type.split('/')[1]
          }
        }
        POST('image/upload', { $images: [_file], BrandID: BrandID }).then(responseJSON => {
          resolve(responseJSON)
        })
      }
      reader.onerror = function() {
        resolve(false)
      }
    })
  }

  const uploadVideo = file => {
    return new Promise(resolve => {
      GET('s3Files/getSignedUrlToUpload', { mimeType: file.type, fileExtension: file.type.split('/')[1] }).then(responseJSON => {
        fetch(responseJSON.uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type
          },
          body: file
        })
          .then(response => {
            if (response.status === 200 && response.ok === true) {
              resolve(responseJSON.fileUrl)
            }
          })
          .catch(() => {
            resolve(false)
          })
      })
    })
  }

  Object.assign(Proxies, { uploadImage, uploadVideo })
})()
;(() => {})()

/* brand info */
;(() => {
  const setupBrand = () => {
    return GET('setup/brands')
  }
  Object.assign(Proxies, { setupBrand })
})()

// Post data to the API
const postData = (endpoint = false, data = {}, useFormData = false, test = false) => {
  if (!endpoint) {
    return false
  }
  if (test) {
    return _getJSON('https://util.upgrader.club/' + endpoint, data, true, true, true, useFormData).then(responseJSON => {
      return responseJSON
    })
  }
  return _getJSON(endpoint, data, false, false, false, useFormData)
}
Object.assign(Proxies, { postData })

// Get Proxy data (only JSON)
const apiProxy = url => {
  if (!url) {
    return false
  }
  return _getJSON(url, {}, true)
}
Object.assign(Proxies, { apiProxy })

const platformSetup = data => {
  return GET('platform/setup', data)
}
Object.assign(Proxies, { platformSetup })

const getLanguageKeys = data => {
  return GET('brands/fetchBrandLanguages', data)
}
Object.assign(Proxies, { getLanguageKeys })

const getLanguages = data => {
  return GET('schemas/languages', data)
}
Object.assign(Proxies, { getLanguages })

export default Proxies
