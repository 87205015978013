import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { CompanyActions, CompanyStore, STATUS } from 'Stores/companyStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class CompanyList extends Component {
  constructor(props) {
    super(props)
    this.stores = [CompanyStore]
    this.storeKeys = ['status', 'companies']
  }

  componentDidMount() {
    CompanyActions.getCompanies()
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_COMPANIES_FAILED) {
      toast.error('FETCHING COMPANIES FAILED', {
        autoClose: 7500,
        onClose: () => {
          CompanyActions.clearToast()
        }
      })
      CompanyActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_COMPANIES) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Companies</strong>}>
        <DynamicTable
          responsive
          size='sm'
          data={this.state.companies}
          headers={{
            CompanyID: 'Company ID',
            AgentID: 'Agent ID',
            companyName: 'Company Name',
            companyNameShort: 'Company Name Short',
            companyToken: 'Token',
            companySalt: 'Salt',
            timeInsert: 'Created'
          }}
          options={{
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.CompanyID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/company/' + e.CompanyID} id={'edit' + e.CompanyID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
