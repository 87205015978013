/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import { Button, ButtonGroup, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import DatePicker from 'react-datepicker'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { KamActions, KamStore } from 'Stores/santanderKamStore'

export class KamOverview extends Component {
  constructor(props) {
    super(props)
    this.store = KamStore
  }
  componentDidMount() {
    KamActions.getCompanies()
  }
  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Firmaer</strong>}>
        <DynamicTable
          data={this.state.companies}
          headers={{
            Dealer_ID: 'Forhandler ID',
            Dealer_CVR: 'CVR',
            Dealer_Navn: 'Navn',
            Closed: 'Lukket',
            Salesclub: 'Adgang til salesclub'
          }}
          options={{
            preserveSearch: KamActions.preserveSearch,
            filterText: this.state.filterText,
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Button tag={Link} to={'/kam/cvr/' + e.Dealer_CVR + '/' + e.Dealer_ID}>
                      Detaljer
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}

export class KamCvrDetails extends Component {
  constructor(props) {
    super(props)
    this.store = KamStore
    this.state = {
      selectedSellerID: null
    }
    this.ProductRef = null
  }
  componentDidMount() {
    if (this.props && this.props.match && this.props.match.params && this.props.match.params.CVR) {
      KamActions.getCvrUsers(this.props.match.params.CVR, this.props.match.params.DealerID)
      KamActions.getCvrData(this.props.match.params.CVR, this.props.match.params.DealerID)
    }
  }
  selectSellerID = selectedSellerID => {
    this.setState({ selectedSellerID })
    if (selectedSellerID) {
      window.scrollTo(0, this.ProductRef.offsetTop)
    }
  }
  render() {
    let foundCompany = this.state.companies.find(e => {
      return e.Dealer_ID == this.props.match.params.DealerID
    })
    if (!foundCompany) {
      foundCompany = {}
    }
    let productData = null
    if (this.state.productData && this.state.productData.length) {
      productData = (
        <Modal isOpen toggle={KamActions.clearProductData} size='lg'>
          <ModalHeader toggle={KamActions.clearProductData}>Detaljer for : {this.state.productData[0].productName}</ModalHeader>
          <ModalBody>
            <DynamicTable
              data={this.state.productData}
              headers={{
                userName: 'Navn',
                antal: 'antal',
                fra: 'fra',
                til: 'til'
              }}
              options={{
                startSort: {
                  column: 'antal',
                  dir: 'desc'
                },
                fieldExportFunctions: {
                  fra: e => {
                    return moment(e.fra).format('DD/MM/YYYY')
                  },
                  til: e => {
                    return moment(e.til).format('DD/MM/YYYY')
                  }
                },
                fieldStringFunctions: {
                  fra: e => {
                    return moment(e.fra).format('x')
                  },
                  til: e => {
                    return moment(e.til).format('x')
                  }
                },
                fieldFunctions: {
                  fra: e => {
                    return moment(e.fra).format('DD/MM/YYYY')
                  },
                  til: e => {
                    return moment(e.til).format('DD/MM/YYYY')
                  }
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={KamActions.clearProductData}>Luk</Button>
          </ModalFooter>
        </Modal>
      )
    }
    return (
      <React.Fragment>
        {productData}
        <div>
          Detaljer for {foundCompany.Dealer_Navn}, {foundCompany.Dealer_adresse}, {foundCompany.Dealer_zip} {foundCompany.Dealer_by}
        </div>
        <CollapsibleCard header={<strong>Datofilter</strong>}>
          <Form
            onSubmit={e => {
              e.preventDefault()
              return false
            }}
            inline
          >
            <FormGroup>
              <Label htmlFor='startDate'>Start</Label>
              <DatePicker
                isClearable
                placeholderText='Vælg en dato at filtrere fra'
                className='form-control'
                maxDate={this.state.endDate ? moment(this.state.endDate).add(-1, 'days') : moment().add(0, 'days')}
                selected={this.state.startDate ? moment(this.state.startDate) : null}
                dateFormat={'DD/MM/YYYY'}
                viewMode={'days'}
                name='startDate'
                id='startDate'
                onChange={date => KamActions.setStartDate(this.props.match.params.CVR, date, this.props.match.params.DealerID)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='endDate'>Slut</Label>
              <DatePicker
                isClearable
                placeholderText='Vælg en dato at filtrere til'
                className='form-control'
                minDate={moment(this.state.startDate).add(1, 'days')}
                maxDate={moment().add(0, 'days')}
                selected={this.state.endDate ? moment(this.state.endDate) : null}
                dateFormat={'DD/MM/YYYY'}
                viewMode={'days'}
                name='endDate'
                id='endDate'
                onChange={date => KamActions.setEndDate(this.props.match.params.CVR, date, this.props.match.params.DealerID)}
              />
            </FormGroup>
          </Form>
        </CollapsibleCard>
        <CollapsibleCard header={<strong>Solgte produkter</strong>} startOpen ref={ref => (this.ProductRef = ref)}>
          Viser solgte produkter for{' '}
          {this.state.selectedSellerID ? (
            <React.Fragment>
              {
                this.state.users.find(e => {
                  return e.UserID == this.state.selectedSellerID
                }).userName
              }{' '}
              <Button
                onClick={() => {
                  this.selectSellerID()
                }}
              >
                Se hele virksomheden
              </Button>
            </React.Fragment>
          ) : (
            'hele virksomheden'
          )}
          <DynamicTable
            data={
              this.state.selectedSellerID
                ? this.state.details.singleUser.filter(e => {
                    return e.UserID == this.state.selectedSellerID
                  })
                : this.state.details.wholeCompany
            }
            headers={{
              productName: 'Produkt',
              antal: 'antal',
              fra: 'fra',
              til: 'til'
            }}
            options={{
              startSort: {
                column: 'antal',
                dir: 'desc'
              },
              fieldExportFunctions: {
                fra: e => {
                  return moment(e.fra).format('DD/MM/YYYY')
                },
                til: e => {
                  return moment(e.til).format('DD/MM/YYYY')
                }
              },
              fieldStringFunctions: {
                fra: e => {
                  return moment(e.fra).format('x')
                },
                til: e => {
                  return moment(e.til).format('x')
                }
              },
              fieldFunctions: {
                fra: e => {
                  return moment(e.fra).format('DD/MM/YYYY')
                },
                til: e => {
                  return moment(e.til).format('DD/MM/YYYY')
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          KamActions.getProductData(this.props.match.params.CVR, e.productName, this.props.match.params.DealerID)
                        }}
                      >
                        Detaljer
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
          />
        </CollapsibleCard>
        <CollapsibleCard header={<strong>Brugere</strong>}>
          <DynamicTable
            data={this.state.users}
            headers={{
              UserID: 'Bruger ID',
              userUID: 'Unikt ID',
              userName: 'Navn',
              userCompany: 'Virksomhed'
            }}
            options={{
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          this.selectSellerID(e.UserID)
                        }}
                      >
                        Detaljer
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
          />
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
