import React from 'react'
import { Component } from 'reflux'
import 'moment/locale/da'
import { KamStore, KamActions } from 'Stores/santanderKamStore'
import SantanderKAMGraph from 'Components/dashboard/SantanderKAMGraph'

export default class SantanderKAMSpentBudgetReport extends Component {
  constructor(props) {
    super(props)
    this.stores = [KamStore]
    this.storeKeys = ['loadingState', 'budgetSpent', 'spentKeys', 'currentYear', 'currentMonth']
  }

  render() {
    return (
      <React.Fragment>
        <SantanderKAMGraph
          header='Brugte point pr. KAM'
          state={this.state.loadingState}
          getTotalKamDetails={KamActions.getTotalKamDetails}
          axisBottom={{ onClick: e => KamActions.getKamDetails(e, this.state.budgetSpent) }}
          onClick={KamActions.getKamDetails}
          cursor={{ cursor: 'pointer' }}
          showIcon={true}
          data={this.state.budgetSpent}
          longestName={this.state.budgetSpent && !!this.state.budgetSpent.length && Math.max(...this.state.budgetSpent.map(el => el.user.length))}
          keys={this.state.spentKeys}
          indexBy={'user'}
          axisLeft={{ format: '~s' }}
          pagesCount={Math.ceil(this.state.budgetSpent.length / 14)}
          legends={[]}
          colors={{
            Total: '#1F78B4',
            [this.state.currentYear]: '#a6cee3',
            [this.state.currentMonth]: '#b2df8a'
          }}
        />
      </React.Fragment>
    )
  }
}
