import React, { useEffect, useState } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import moment from 'moment'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import { WalletPointStatsStore, WalletPointStatsActions, STATUS as WALLET_POINT_STATS_STATUS } from 'Stores/WalletPointStatsStore.js'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BREAKPOINTS } from 'Components/mq.js'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import { Button, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'
import Proxies from 'Components/proxies'

const ChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: ${BREAKPOINTS.md}) {
    flex-direction: row;
  }
`
const Header = styled.h4`
  text-align: center;
  margin-bottom: 0;
  padding-left: 15px;
  padding-top: 20px;
`

const PointTotalWrap = styled.div`
  display: flex;
  align-items: baseline;
`

const PointTotalValue = styled.div`
  font-weight: bold;
`

const CopyIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 16px;
  left: 16px;
  color: #28d094;

  &:hover {
    color: #1b966a;
    cursor: pointer;
  }

  &.clicked {
    color: #c8ced3;
  }
`

const MailIcon = styled(FontAwesomeIcon)`
  color: #28d094;
`

const SettingIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -5;
  left: 8px;
  color: #1b966a;
  width: 15px;
  height: 15px;
`

const LoadingIcon = styled(FontAwesomeIcon)`
  display: block !important;
  margin: auto !important;
`

export default class RefluxWrap extends Reflux.Component {
  constructor(props) {
    super(props)
    this.stores = [WalletPointStatsStore]
    this.storeKeys = ['walletPointsStats', 'status']
  }

  render() {
    return (
      <LastMonthTotalPoints
        {...this.props}
        isWalletActivation={this.props.isWalletActivation}
        walletPointsStats={this.state.walletPointsStats}
        walletPointsStatsStatus={this.state.status}
      />
    )
  }
}

function PointComponent({ title, pointValue }) {
  return (
    <PointTotalWrap>
      <h5>{title}</h5>
      <PointTotalValue className='ml-3'>{pointValue}</PointTotalValue>
    </PointTotalWrap>
  )
}

function LastMonthTotalPoints({ walletPointsStats, walletPointsStatsStatus, isWalletActivation }) {
  const [copyClicked, setCopyClicked] = useState(false)
  const [showBrandContactModal, setShowBrandContactModal] = useState(false)
  const [showSendEmailModal, setShowSendEmailModal] = useState(false)
  const [data, loadingState] = useDashboardData('reports/wallet-points-earned')

  useEffect(() => {
    WalletPointStatsActions.getWalletPointStats()
  }, [])

  const title =
    `${isWalletActivation ? 'Aktiverede point' : 'Point'} opsummering pr. ` +
    moment(_.get(walletPointsStats, 'period'), 'DD-MM-YYYY').format('DD/M YYYY')
  const titalEarnedTitle = 'Total points:'
  const totalEarned = new Intl.NumberFormat().format(_.get(walletPointsStats, 'totalEarned'))
  const totalSpent = new Intl.NumberFormat().format(_.get(walletPointsStats, 'totalSpent'))
  const totalSpentTitle = 'Brugte points:'
  const totalRemaining = new Intl.NumberFormat().format(_.get(walletPointsStats, 'totalRemaining'))
  const totalRemainingTitle = 'Ubrugte points:'
  const lastMonthTotalPointsTitle = 'Total point sidste måned:'
  const macroTitle = 'Macro:'
  const systemTitle = 'System:'
  const manuelTitle = 'Manuel:'
  let lastMonthTotalPoints = 0
  let lastMonthPointDetails = { macro: 0, manual: 0, system: 0 }
  let lastMonthPointsContent
  if (!data || loadingState === 'LOADING') {
    lastMonthPointsContent = <LoadingIcon icon='spinner' spin size='1x' />
  } else if (loadingState === 'SUCCESS' && data && data.monthlyResults) {
    const result = _.filter(data.monthlyResults, {
      month: moment()
        .subtract(1, 'month')
        .add(1, 'day')
        .format('M/YYYY')
    })
    if (result && result.length) {
      lastMonthPointDetails = Object.assign({}, result[0])
      lastMonthTotalPoints = new Intl.NumberFormat().format(
        Number(lastMonthPointDetails.macro) + Number(lastMonthPointDetails.system) + Number(lastMonthPointDetails.manual)
      )
      lastMonthPointDetails.system = new Intl.NumberFormat().format(lastMonthPointDetails.system)
      lastMonthPointDetails.manual = new Intl.NumberFormat().format(lastMonthPointDetails.manual)
      lastMonthPointDetails.macro = new Intl.NumberFormat().format(lastMonthPointDetails.macro)
    }
    lastMonthPointsContent = (
      <React.Fragment>
        <Header>
          {lastMonthTotalPointsTitle} {lastMonthTotalPoints}
        </Header>
        <ChartWrap className='pt-3'>
          <PointComponent title={systemTitle} pointValue={lastMonthPointDetails.system} />
          <PointComponent title={manuelTitle} pointValue={lastMonthPointDetails.manual} />
          <PointComponent title={macroTitle} pointValue={lastMonthPointDetails.macro} />
        </ChartWrap>
      </React.Fragment>
    )
  }

  const copyContentClick = () => {
    setCopyClicked(true)
    setTimeout(() => {
      setCopyClicked(false)
    }, 10000)
    navigator.clipboard.writeText(
      `${title}\n${titalEarnedTitle} ${totalEarned}\n${totalSpentTitle} ${totalSpent}\n${totalRemainingTitle} ${totalRemaining}\n\n${lastMonthTotalPointsTitle} ${lastMonthTotalPoints}\n${systemTitle} ${lastMonthPointDetails.system}\n${manuelTitle} ${lastMonthPointDetails.manual}\n${macroTitle} ${lastMonthPointDetails.macro}`
    )
  }

  let state, content
  if (!walletPointsStatsStatus || walletPointsStatsStatus === WALLET_POINT_STATS_STATUS.LOADING) {
    state = DASHBOARD_COMPONENT_STATE.LOADING
  } else if (walletPointsStatsStatus === WALLET_POINT_STATS_STATUS.FAILED) {
    state = DASHBOARD_COMPONENT_STATE.FAILED
  } else {
    state = DASHBOARD_COMPONENT_STATE.SUCCESS
    content = (
      <ChartWrap className='pt-3'>
        <PointComponent title={titalEarnedTitle} pointValue={totalEarned} />
        <PointComponent title={totalSpentTitle} pointValue={totalSpent} />
        <PointComponent title={totalRemainingTitle} pointValue={totalRemaining} />
      </ChartWrap>
    )
  }

  return (
    <DashboardComponent
      header={
        <span>
          {isWalletActivation ? 'Aktiverede point' : 'Point '} opsummering pr.{' '}
          {_.get(walletPointsStats, 'period') ? (
            moment(walletPointsStats.period, 'DD-MM-YYYY').format('DD/M YYYY')
          ) : (
            <FontAwesomeIcon icon='spinner' spin size='xs' />
          )}
        </span>
      }
      state={state}
    >
      {copyClicked && <CopyIcon className='clicked' icon={'check'} size='lg' title='Indhold kopieret' onClick={copyContentClick} />}
      {!copyClicked && <CopyIcon icon={'copy'} size='lg' title='Kopier indhold' onClick={copyContentClick} />}
      {!isWalletActivation && (
        <div style={{ cursor: 'pointer', position: 'absolute', top: 16, left: 45 }} onClick={() => setShowBrandContactModal(true)}>
          <div style={{ position: 'relative' }}>
            <MailIcon icon={'envelope'} size='lg' title='Mail indstilling' />
            <SettingIcon icon={'cog'} size='lg' title='Mail indstilling' />
          </div>
        </div>
      )}
      {loadingState === 'SUCCESS' && data && data.monthlyResults && !isWalletActivation ? (
        <div style={{ cursor: 'pointer', position: 'absolute', top: 16, left: 80 }} onClick={() => setShowSendEmailModal(true)}>
          <div style={{ position: 'relative' }}>
            <MailIcon icon={'envelope'} size='lg' title='Mail sende' />
            <SettingIcon icon={'share'} size='sm' title='Mail sende' />
          </div>
        </div>
      ) : null}
      {content}
      {lastMonthPointsContent}
      <ContactModal showBrandContactModal={showBrandContactModal} closeModal={() => setShowBrandContactModal(false)} />

      <ShowSendEmailModal
        emailBody={`Hermed opdaterede tal pr. ${
          walletPointsStats ? moment(walletPointsStats.period, 'DD-MM-YYYY').format('DD-MM-YYYY') : ''
        } fra SalesClub
Optjente\t\t\t\t\t\t\t ${totalEarned}
Brugte\t\t\t\t\t\t\t\t ${totalSpent}
Ubrugte\t\t\t\t\t\t\t\t ${totalRemaining}
Point tilskrevet automatisk i perioden ${moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('DD-MM-YYYY')} - ${moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('DD-MM-YYYY')}\t ${lastMonthPointDetails.system}
Manuelt tilskrevet point i perioden ${moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('DD-MM-YYYY')} - ${moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('DD-MM-YYYY')}\t ${lastMonthPointDetails.manual}
Macro tilskrevet point i perioden ${moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('DD-MM-YYYY')} - ${moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('DD-MM-YYYY')}\t ${lastMonthPointDetails.macro}`}
        showSendEmailModal={showSendEmailModal}
        closeModal={() => {
          setShowSendEmailModal(false)
        }}
      />
    </DashboardComponent>
  )
}

const ContactModal = ({ showBrandContactModal, closeModal }) => {
  const [contacts, setContacts] = useState([])

  const fetchBrandContactEmails = () => {
    Proxies.GET('BrandContactEmail/pool').then(responseJSON => {
      if (responseJSON) {
        setContacts(responseJSON)
      }
    })
  }

  const saveContacts = () => {
    Proxies.POST('BrandContactEmail/replace', { contacts }).then(responseJSON => {
      if (responseJSON.error) return setContacts([])
      fetchBrandContactEmails()
    })
  }

  useEffect(() => {
    if (showBrandContactModal) {
      fetchBrandContactEmails()
    }
  }, [showBrandContactModal])

  return (
    <Modal isOpen={showBrandContactModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Contacts</ModalHeader>
      <ModalBody style={{ maxHeight: '720px' }}>
        <Table className='mb-sm-0'>
          <thead>
            <tr>
              <th className='w-100'>Emails</th>
              <th>
                <Button onClick={() => setContacts([...contacts, { emailTo: '' }])}>
                  <FontAwesomeIcon icon='plus' />
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((item, key) => {
              return (
                <tr key={key}>
                  <td>
                    <Input
                      type='text'
                      name='emailTo'
                      value={item.emailTo}
                      id='emailTo'
                      onChange={t =>
                        setContacts(
                          contacts.map((contact, index) => {
                            if (key === index) {
                              contact.emailTo = t.target.value
                            }
                            return contact
                          })
                        )
                      }
                    ></Input>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        setContacts(
                          contacts.filter((contact, index) => {
                            if (index !== key) return contact
                          })
                        )
                      }}
                    >
                      <FontAwesomeIcon icon='minus' />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={saveContacts}>
          Gem
        </Button>
        <Button color='secondary' onClick={closeModal}>
          Luk
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const ShowSendEmailModal = ({ emailBody, closeModal, showSendEmailModal }) => {
  const [contacts, setContacts] = useState([])

  const fetchBrandContactEmails = () => {
    Proxies.GET('BrandContactEmail/pool').then(responseJSON => {
      if (responseJSON) {
        setContacts(responseJSON)
      }
    })
  }

  const sendEmailToContacts = () => {
    if (contacts.length) {
      Proxies.POST('BrandContactEmail/sendEmail', { emailBody })
      closeModal()
    }
  }

  useEffect(() => {
    fetchBrandContactEmails()
  }, [])

  return (
    <Modal isOpen={showSendEmailModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Send Email</ModalHeader>
      <ModalBody style={{ maxHeight: '720px' }}>
        <Col>
          <pre>{emailBody}</pre>
          <p>The above email will be sent to the following email addresses.</p>
          {contacts.map((contact, key) => {
            return <p key={key}>{contact.emailTo}</p>
          })}
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={sendEmailToContacts}>
          Ok
        </Button>
        <Button color='secondary' onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
