import Reflux from 'reflux'

import moment from 'moment'
import Proxies from 'Components/proxies'

export const KamActions = Reflux.createActions([
  'updateStartDate',
  'updateEndDate',
  'getUsers',
  'filterCompany',
  'filterUsers',
  'clearFilter',
  'getUserSales',
  'loadBehaviour',
  'changeTab',
  'getData',
  'updateDate',
  'getAllUsers',
  'getAllCompanies',
  'getCompanySales'
])

export class KamStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = KamActions
    this.state = {
      startDate: moment('2017-01-01T12:00:00').format(),
      endDate: moment().format(),
      overviewUsers: [],
      overviewFilterUser: null,
      overviewFilterCompany: null,
      overviewUserData: [],
      overviewCompanyData: [],
      behaviourPosts: [],
      behaviourPostsRead: [],
      behaviourActiveUsers: [],
      behaviourLikes: [],
      activeTab: '1',
      kamCompanies: [],
      kamUsers: [],
      selectedCompany: '',
      selectedUser: '',
      fetchOverviewData: {
        fetchUsers: false,
        fetchUserSales: false,
        fetchCompanySales: false
      },
      fetchBehaviourData: {
        fetchBehaviourPosts: false,
        fetchBehaviourPostsRead: false,
        fetchActiveUsers: false,
        fetchLikes: false
      },
      isUserFilter: false,
      isCompanyFilter: false,
      isCVRFilter: false,
      kamUsersList: [],
      selectedKam: '',
      selectedCVR: '',
      selectedCompanyName: ''
    }
  }
  updateOverview() {
    this.getUsers()
    this.getUserSales()
    this.getCompanySales()
  }
  formatMoment(input) {
    return moment(input).format('YYYY-MM-DD')
  }

  clearFilter(data) {
    let nextState = {
      overviewUserData: [],
      overviewCompanyData: [],
      selectedUser: '',
      selectedCompany: ''
    }
    if (data === 'company') {
      ;(nextState.isCompanyFilter = false), (nextState.selectedCompanyName = '')
    }
    if (data === 'kam') {
      ;(nextState.selectedKam = ''), (nextState.isUserFilter = false)
    }
    if (data === 'cvr') {
      ;(nextState.selectedCVR = ''), (nextState.isCVRFilter = false)
    }
    this.setState(nextState)
  }
  filterCompany(data, type) {
    let nextState = {
      overviewFilterCompany: data.cvr,
      overviewFilterUser: data.ID,
      selectedCompany: data.company,
      selectedUser: data.name
    }
    if (type === 'kam') {
      nextState.isUserFilter = true
      nextState.selectedKam = data.KAM
    } else if (type === 'cvr') {
      nextState.selectedCVR = data.cvr
      nextState.isCVRFilter = true
    } else {
      nextState.selectedCompanyName = data.company
      nextState.isCompanyFilter = true
    }
    this.setState(nextState)
    this.getCompanySales()
    this.getCompayUsers()
    this.getUserSales()
  }
  updatePage(page) {
    switch (page.toLowerCase()) {
      case 'overview':
        this.updateOverview()
        break
      case 'behaviour':
        this.loadBehaviour()
        break
    }
  }
  updateStartDate(e, page) {
    if (e._isAMomentObject) {
      this.setState({ startDate: e.format() })
      this.updatePage(page)
    }
  }
  updateEndDate(e, page) {
    if (e._isAMomentObject) {
      this.setState({ endDate: e.format() })
      this.updatePage(page)
    }
  }
  getUserSales(user) {
    let nextState = {
      overviewUserData: [],
      fetchOverviewData: this.state.fetchOverviewData
    }
    nextState.fetchOverviewData.fetchUserSales = true
    user && this.setState({ nextState, overviewFilterUser: user.UserID, selectedUser: user.userName })
    if (this.state.overviewFilterUser) {
      Proxies.GET('supervisors/usersales', {
        UserID: this.state.overviewFilterUser,
        fromDate: this.formatMoment(this.state.startDate),
        toDate: this.formatMoment(this.state.endDate)
      }).then(responseJSON => {
        nextState.fetchOverviewData.fetchUserSales = false
        if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
          nextState.overviewUserData = responseJSON
          this.setState(nextState)
        } else {
          this.setState(nextState)
        }
      })
    }
    this.setState(nextState)
  }
  getCompanySales(company) {
    let nextState = {
      overviewCompanyData: [],
      fetchOverviewData: this.state.fetchOverviewData
    }
    nextState.fetchOverviewData.fetchCompanySales = true
    company && this.setState({ nextState, overviewFilterCompany: company.userCompanyUID, selectedCompany: company.userCompany })
    if (this.state.overviewFilterCompany) {
      this.getCompayUsers()
      Proxies.GET('supervisors/companysales', {
        userCompanyUID: this.state.overviewFilterCompany,
        fromDate: this.formatMoment(this.state.startDate),
        toDate: this.formatMoment(this.state.endDate)
      }).then(responseJSON => {
        nextState.fetchOverviewData.fetchCompanySales = false
        if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
          nextState.overviewCompanyData = responseJSON
          this.setState(nextState)
        } else {
          this.setState(nextState)
        }
      })
    }
    this.setState(nextState)
  }
  loadBehaviour() {
    let nextState = {
      behaviourPosts: [],
      behaviourPostsRead: [],
      behaviourActiveUsers: [],
      behaviourLikes: [],
      fetchBehaviourData: {
        fetchBehaviourPosts: true,
        fetchBehaviourPostsRead: true,
        fetchActiveUsers: true,
        fetchLikes: true
      }
    }
    this.setState(nextState)
    Proxies.GET('supervisors/posts', {
      fromDate: this.formatMoment(this.state.startDate),
      toDate: this.formatMoment(this.state.endDate)
    }).then(responseJSON => {
      nextState.fetchBehaviourData.fetchBehaviourPosts = false
      if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
        nextState.behaviourPosts = responseJSON
        this.setState(nextState)
      } else {
        this.setState({ fetchBehaviourData: nextState.fetchBehaviourData })
      }
    })
    Proxies.GET('supervisors/postsread', {
      fromDate: this.formatMoment(this.state.startDate),
      toDate: this.formatMoment(this.state.endDate)
    }).then(responseJSON => {
      nextState.fetchBehaviourData.fetchBehaviourPostsRead = false
      if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
        nextState.behaviourPostsRead = responseJSON
        this.setState(nextState)
      } else {
        this.setState({ fetchBehaviourData: nextState.fetchBehaviourData })
      }
    })
    Proxies.GET('supervisors/active').then(responseJSON => {
      nextState.fetchBehaviourData.fetchActiveUsers = false
      if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
        nextState.behaviourActiveUsers = responseJSON
        this.setState(nextState)
      } else {
        this.setState({ fetchBehaviourData: nextState.fetchBehaviourData })
      }
    })
    Proxies.GET('supervisors/likes').then(responseJSON => {
      nextState.fetchBehaviourData.fetchLikes = false
      if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
        nextState.behaviourLikes = responseJSON
        this.setState(nextState)
      } else {
        this.setState({ fetchBehaviourData: nextState.fetchBehaviourData })
      }
    })
  }
  getUsers() {
    const fetchOverviewData = this.state.fetchOverviewData
    fetchOverviewData.fetchUsers = true
    this.setState({ fetchOverviewData })
    Proxies.GET('supervisors/users', {
      fromDate: this.formatMoment(this.state.startDate),
      toDate: this.formatMoment(this.state.endDate)
    }).then(responseJSON => {
      fetchOverviewData.fetchUsers = false
      if (responseJSON && Array.isArray(responseJSON) && responseJSON.length) {
        this.setState({ overviewUsers: responseJSON, openOverviewCard: true })
      }
      this.setState({ fetchOverviewData })
    })
  }

  getAllCompanies() {
    Proxies.GET('supervisors/allcompanies').then(responseJSON => {
      if (responseJSON && Array.isArray(responseJSON) && responseJSON.length) {
        this.setState({ kamCompanies: responseJSON })
      }
    })
  }

  getAllUsers() {
    Proxies.GET('supervisors/allusers').then(responseJSON => {
      if (responseJSON && Array.isArray(responseJSON) && responseJSON.length) {
        this.setState({ kamUsersList: responseJSON, kamUsers: responseJSON })
      }
    })
  }

  getCompayUsers() {
    let kamUsers = []
    kamUsers = this.state.kamUsersList.filter(item => item.userCompanyUID === this.state.overviewFilterCompany)
    this.setState({ kamUsers })
  }

  changeTab(activeTab) {
    this.setState({ activeTab })
  }

  updateDate(e, name) {
    if (e._isAMomentObject) {
      name === 'startDate' ? this.setState({ startDate: e.format() }) : this.setState({ endDate: e.format() })
    }
  }

  getData() {
    if (this.state.activeTab === '1') {
      this.getUsers()
    } else {
      this.loadBehaviour()
    }
  }
}
