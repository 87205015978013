import { Fragment, useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import React from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Input, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import DynamicTable from 'Components/dynamicTable'
import DatePicker from 'react-datepicker'

export default function CompetitionForm(props) {
  const [loading, setLoading] = useState(false)
  const { CompetitionID } = props.match.params
  const [competition, setCompetition] = useState({
    gdprText:
      'Jeg afgiver hermed samtykke til at mit navn og firmanavn må deles i klubben. Dette samtykke gælder indtil 3 måneder efter konkurrencen er slut.',
    emailTextWinner: ''
  })
  const [status, setStatus] = useState('LOADING')
  const [competitionImage, setCompetitionImage] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showProductModal, setShowProductModal] = useState(false)
  const [groups, setGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState(null)
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState(null)
  const [showProductImageModal, setShowProductImageModal] = useState(false)
  const [selectedProductImage, setSelectedProductImage] = useState(null)

  function saveCompetition() {
    if (competitionImage) {
      competition.ImageID = competitionImage.ImageID
    } else {
      delete competition.ImageID
    }
    if (selectedProduct) {
      competition.prizeProductID = selectedProduct.ProductID
      if (selectedProductImage) {
        competition.productImageID = selectedProductImage.ImageID
      }
    }
    if (selectedUsers) {
      competition.users = selectedUsers
    }
    if (!competition.prizeType) {
      competition.prizeType = 'POINT'
    }
    if (!competition.startDate) {
      competition.startDate = new Date()
    }
    if (!competition.endDate) {
      competition.endDate = new Date()
    }
    Proxies.POST('competitionV2/replace', competition).then(responseJSON => {
      if (responseJSON.error) {
        setStatus('FAILED')
      } else {
        setStatus('SUCCESS')
      }
    })
  }

  const uploadCompetitionImage = data => {
    let file = data.target.files[0]
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        setCompetitionImage(responseJSON)
        setCompetition({ ...competition, image: responseJSON })
      }
    })
  }

  const fetchCompetition = () => {
    setLoading(true)
    Proxies.GET('competitionV2/fetch', { CompetitionID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setCompetition(responseJSON)
        if (responseJSON.product) {
          setSelectedProduct(responseJSON.product)
          if (responseJSON.productImage) {
            setSelectedProductImage(responseJSON.productImage)
          }
        }
        if (responseJSON.users) {
          setSelectedUsers(responseJSON.users)
        }
      }
      setLoading(false)
    })
  }

  const resetData = () => {
    if (CompetitionID) {
      fetchCompetition()
    } else {
      setCompetition({})
    }
  }

  const updateCompetition = ({ target }) => {
    setCompetition({ ...competition, [target.name]: target.name === 'allowAllUsers' ? target.checked : target.value })
  }

  useEffect(() => {
    if (CompetitionID) {
      fetchCompetition(CompetitionID)
    }
    Proxies.GET('groups').then(responseJSON => {
      if (responseJSON && responseJSON.length) {
        setGroups(responseJSON)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedGroups && selectedGroups.length) {
      setShowUsersModal(true)
    }
  }, [selectedGroups])

  if (status === 'SUCCESS') {
    toast.success(CompetitionID ? 'Successfully Updated' : 'Successfully Created', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
    return <Redirect to='/competition' />
  }

  if (status === 'FAILED') {
    toast.error('Error while saving competition', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
  }

  return (
    <CollapsibleCard startOpen={true} header={<strong>Konkurrence</strong>}>
      <Form>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Name</label>
          </Col>
          <Col xs='10'>
            <Input type='text' value={competition.title} name='title' id='title' onChange={e => updateCompetition(e)} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Short Description</label>
          </Col>
          <Col xs='10'>
            <Input
              type='text'
              value={competition.shortDescription ? competition.shortDescription : ''}
              name='shortDescription'
              id='shortDescription'
              onChange={e => updateCompetition(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Detail Description</label>
          </Col>
          <Col xs='10'>
            <Input
              type='textarea'
              value={competition.detailDescription ? competition.detailDescription : ''}
              name='detailDescription'
              id='shortDescription'
              onChange={e => updateCompetition(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Start Date</label>
          </Col>
          <Col xs='10'>
            <DatePicker
              className='form-control'
              minDate={moment().add(1, 'days')}
              selected={moment(competition.startDate)}
              dateFormat={'DD/MM/YYYY'}
              viewMode={'days'}
              name='startDate'
              id='startDate'
              onChange={e => {
                updateCompetition({ target: { name: 'startDate', value: e.format() } })
              }}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>End Date</label>
          </Col>
          <Col xs='10'>
            <DatePicker
              className='form-control'
              minDate={moment().add(1, 'days')}
              selected={moment(competition.endDate)}
              dateFormat={'DD/MM/YYYY'}
              viewMode={'days'}
              name='endDate'
              id='endDate'
              onChange={e => {
                updateCompetition({ target: { name: 'endDate', value: e.format() } })
              }}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>GDPR Text</label>
          </Col>
          <Col xs='10'>
            <Input
              type='textarea'
              value={competition.gdprText ? competition.gdprText : ''}
              name='gdprText'
              id='gdprText'
              onChange={e => updateCompetition(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Number of Entries</label>
          </Col>
          <Col xs='10'>
            <Input
              type='number'
              value={competition.numEntries ? competition.numEntries : 1}
              name='numEntries'
              id='numEntries'
              onChange={e => updateCompetition(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Group</label>
          </Col>
          <Row xs='10'>
            {/* {selectedGroups ? <span style={{marginRight: '8px'}}>{selectedGroups.map(g => g.moduleName).join(', ')}</span> : null } */}
            <Input
              style={{ marginLeft: '26px' }}
              type='checkbox'
              id='allowAllUsers'
              name='allowAllUsers'
              checked={competition.allowAllUsers ? true : false}
              onChange={e => {
                if (e.target.checked) {
                  setSelectedUsers([])
                }
                updateCompetition(e)
              }}
            />
            <label style={{ marginLeft: '45px' }}>Allow all users</label>
            <Button disabled={competition.allowAllUsers} style={{ marginLeft: '16px' }} onClick={() => setShowUsersModal(true)}>
              Select Users
            </Button>
            <Button disabled={competition.allowAllUsers} style={{ marginLeft: '16px' }} onClick={() => setShowGroupModal(true)}>
              Select Group
            </Button>
            {selectedUsers && selectedUsers.length && !competition.allowAllUsers ? (
              <span style={{ marginLeft: '16px' }}>Selected {selectedUsers.length} users</span>
            ) : null}
          </Row>

          {showGroupModal && groups.length && (
            <GroupsModal
              showGroupModal={showGroupModal}
              groups={
                selectedGroups && selectedGroups.length
                  ? groups.map(g => {
                      const match = selectedGroups.find(s => s.GroupID === g.GroupID)
                      if (match) {
                        match.checked = true
                      }
                      return g
                    })
                  : groups
              }
              closeModal={() => setShowGroupModal(false)}
              onGroupSelect={groups => {
                setShowGroupModal(false)
                setSelectedGroups(groups)
              }}
            />
          )}

          {showUsersModal && (
            <UsersModal
              showUsersModal={showUsersModal}
              selUsers={selectedUsers}
              GroupIDs={selectedGroups && selectedGroups.length ? selectedGroups.map(g => g.GroupID) : []}
              closeModal={() => setShowUsersModal(false)}
              onUsersSelect={users => {
                setShowUsersModal(false)
                setSelectedUsers(users)
              }}
            />
          )}
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Winner Email Content</label>
          </Col>
          <Col xs='10'>
            <Input
              type='textarea'
              value={competition.emailTextWinner ? competition.emailTextWinner : ''}
              name='emailTextWinner'
              id='emailTextWinner'
              onChange={e => updateCompetition(e)}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Select Winner Strategy</label>
          </Col>
          <Col xs='10'>
            <Input type='select' value={competition.winningStrategy} name='winningStrategy' id='winningStrategy' onChange={e => updateCompetition(e)}>
              <option value='AUTO'>Auto</option>
              <option value='MANUAL'>Manual</option>
            </Input>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Prize Type</label>
          </Col>
          <Col xs='10'>
            <Input type='select' value={competition.prizeType} name='prizeType' id='prizeType' onChange={e => updateCompetition(e)}>
              <option value='POINT'>Point</option>
              <option value='PRODUCT'>Product</option>
              <option value='OTHER'>Other</option>
            </Input>
          </Col>
        </Row>
        {!competition.prizeType || competition.prizeType === 'POINT' ? (
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Prize Points</label>
            </Col>
            <Col xs='10'>
              <Input
                type='number'
                value={competition.prizePoints ? competition.prizePoints : 0}
                name='prizePoints'
                id='prizePoints'
                onChange={e => updateCompetition(e)}
              />
            </Col>
          </Row>
        ) : null}
        {competition.prizeType === 'PRODUCT' ? (
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Product</label>
            </Col>
            <Col xs='10'>
              {selectedProduct ? <span style={{ marginRight: '8px' }}>{selectedProduct.elementTitle}</span> : null}
              <Button onClick={() => setShowProductModal(true)}>{selectedProduct ? 'Change Product' : 'Select Product'}</Button>
            </Col>
          </Row>
        ) : null}
        {competition.prizeType === 'PRODUCT' && selectedProduct ? (
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Product Image</label>
            </Col>
            <Col xs='10'>
              {selectedProductImage ? (
                <img
                  src={selectedProductImage.imageOriginSource ? selectedProductImage.imageOriginSource : ''}
                  alt={selectedProductImage.imageOriginSource ? selectedProductImage.imageOriginSource : ''}
                  style={{
                    height: '120px',
                    width: '288px',
                    objectFit: 'cover',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: selectedProductImage && selectedProductImage.imageOriginSource ? 'inline-block' : 'none'
                  }}
                />
              ) : null}
              <Button style={{ marginLeft: '16px' }} onClick={() => setShowProductImageModal(true)}>
                {selectedProductImage ? 'Change Product Image' : 'Select Product Image'}
              </Button>
            </Col>
          </Row>
        ) : null}
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Pool Order</label>
          </Col>
          <Col xs='10'>
            <Input
              type='number'
              value={competition.poolOrder ? competition.poolOrder : 0}
              name='poolOrder'
              id='poolOrder'
              onChange={e => updateCompetition(e)}
            />
          </Col>
        </Row>
        {showProductModal && (
          <ProductsModal
            showProductModal={showProductModal}
            closeModal={() => setShowProductModal(false)}
            onProductSelect={product => {
              setShowProductModal(false)
              setSelectedProduct(product)
            }}
          />
        )}
        {showProductImageModal && selectedProduct && (
          <ProductImageModal
            showProductImageModal={showProductImageModal}
            ProductID={selectedProduct.ProductID}
            closeModal={() => setShowProductImageModal(false)}
            onProductImageSelect={productImage => {
              setShowProductImageModal(false)
              setSelectedProductImage(productImage)
            }}
          />
        )}
        <Row className='mb-3'>
          <Col xs='2'>
            <label>Image</label>
            <span>
              <FontAwesomeIcon icon='info-circle' id='profileImageTooltip' style={{ color: 'blue' }} />
              <UncontrolledTooltip target='profileImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
            </span>
          </Col>
          <Col xs='10'>
            <Input
              name='imageUrl'
              field='upload'
              type='file'
              data={{ upload: '' }}
              noValue={competition.image !== ''}
              onChange={e => {
                uploadCompetitionImage(e)
              }}
              accept='image/*'
            />
            <img
              src={competition.image && competition.image.imageOriginSource ? competition.image.imageOriginSource : ''}
              alt={competition.image && competition.image.imageOriginSource ? competition.image.imageOriginSource : ''}
              style={{
                height: '120px',
                width: '288px',
                objectFit: 'cover',
                marginTop: '10px',
                marginBottom: '10px',
                display: competition.image && competition.image.imageOriginSource ? 'inline-block' : 'none'
              }}
            />
          </Col>
        </Row>
        <Button onClick={saveCompetition} disabled={loading}>
          {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button competition='danger' onClick={resetData} className='ml-2'>
          Nulstil
        </Button>
      </Form>
    </CollapsibleCard>
  )
}

export const ProductsModal = ({ showProductModal, closeModal, onProductSelect }) => {
  const [catalogs, setCatalogs] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCatalog, setSelectedCatalog] = useState(null)
  const [catalogProducts, setCatalogProducts] = useState([])

  useEffect(() => {
    setLoading(true)
    Proxies.GET('catalog/client-pool').then(responseJSON => {
      setLoading(false)
      if (responseJSON.length) {
        setCatalogs(responseJSON)
        setSelectedCatalog(responseJSON[0].CatalogID)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedCatalog) {
      setLoading(true)
      Proxies.GET('catalog/elements', { CatalogID: selectedCatalog }).then(responseJSON => {
        setLoading(false)
        if (responseJSON.length) {
          setCatalogProducts(responseJSON)
        }
      })
    }
  }, [selectedCatalog])

  return (
    <Modal isOpen={showProductModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Select Product</ModalHeader>
      <ModalBody style={{ maxHeight: '720px', overflowY: 'scroll' }}>
        {loading ? (
          <FontAwesomeIcon icon='sync' spin />
        ) : (
          <>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Select Catalog</label>
              </Col>
              <Col xs='10'>
                <Input placeholder='Select one' type='select' onChange={e => setSelectedCatalog(e.target.value)}>
                  {catalogs.map(catalog => {
                    return (
                      <option key={catalog.CatalogID} value={catalog.CatalogID}>
                        {catalog.moduleName}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
            <DynamicTable
              headers={{
                ProductID: ' ',
                elementTitle: 'Title',
                productPrice: 'basePrice',
                productPriceAdjust: 'baseMarkup',
                productStatus: 'Status',
                supplier: 'Supplier',
                product: 'Category',
                manufacturer: 'Brand'
              }}
              data={catalogProducts && catalogProducts.length ? catalogProducts : []}
              responsive
              size='lg'
              options={{
                fieldFunctions: {
                  ProductID: e => {
                    return (
                      <Fragment>
                        <Input
                          className='order-selection'
                          type='checkbox'
                          id={'checkbox' + e.ProductID}
                          name={'checkbox' + e.ProductID}
                          checked={e.checked}
                          onChange={() => {
                            onProductSelect(e)
                          }}
                        />
                      </Fragment>
                    )
                  }
                }
              }}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Row>
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button style={{ marginLeft: '8px' }}>Ok</Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export const ProductImageModal = ({ showProductImageModal, closeModal, ProductID, onProductImageSelect }) => {
  const [images, setImages] = useState([])

  useEffect(() => {
    Proxies.GET('products/fetch', { ProductID }).then(responseJSON => {
      if (responseJSON && responseJSON.attachments) {
        setImages(responseJSON.attachments)
      }
    })
  })
  return (
    <Modal isOpen={showProductImageModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Select Product Image</ModalHeader>
      <ModalBody style={{ maxHeight: '720px', overflowY: 'scroll' }}>
        <Row>
          {images.map(image => {
            return (
              <Col key={image.ImageID} style={{ marginTop: '16px' }}>
                <img
                  key={image.ImageID}
                  src={image.imageOriginSource}
                  alt={image.imageName}
                  style={{ marginLeft: '16px', height: '15vw', width: '15vw', objectFit: 'cover' }}
                />
                <Input
                  type='checkbox'
                  id={'checkbox' + image.ImageID}
                  name={'checkbox' + image.ImageID}
                  checked={image.checked}
                  onChange={() => {
                    onProductImageSelect(image)
                  }}
                />
              </Col>
            )
          })}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export const GroupsModal = ({ showGroupModal, closeModal, groups, onGroupSelect }) => {
  return (
    <Modal isOpen={showGroupModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Select Group</ModalHeader>
      <ModalBody style={{ maxHeight: '720px', overflowY: 'scroll' }}>
        <DynamicTable
          headers={{
            GroupID: ' ',
            moduleName: 'Title',
            rules: 'Rules'
          }}
          data={groups && groups.length ? groups : []}
          responsive
          size='lg'
          options={{
            fieldFunctions: {
              GroupID: e => {
                return (
                  <Fragment>
                    <Input
                      className='order-selection'
                      type='checkbox'
                      id={'checkbox' + e.GroupID}
                      name={'checkbox' + e.GroupID}
                      checked={e.checked}
                      onChange={() => {
                        onGroupSelect([e])
                        // setSelectedGroups(groups.map(g =>{
                        //   if(g.GroupID === e.GroupID){
                        //     g.checked = event.target.checked
                        //   }
                        //   return g
                        // }).filter(g => g.checked === true))
                      }}
                    />
                  </Fragment>
                )
              },
              rules: e => {
                let rules = e.rules ? e.rules : []
                return (
                  <Fragment>
                    {rules.map((e, i) => {
                      return (
                        <Fragment key={i}>
                          {e.tableName} - {e.datacolumnName}
                          <br />
                        </Fragment>
                      )
                    })}
                  </Fragment>
                )
              }
            }
          }}
        />
      </ModalBody>
      {/* <ModalFooter>
        <Row>
          <Button onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button style={{marginLeft: '8px'}} onClick={() => onGroupSelect(selectedGroups)}>
            Ok
          </Button>
        </Row>
      </ModalFooter> */}
    </Modal>
  )
}

const UsersModal = ({ showUsersModal, closeModal, GroupIDs, onUsersSelect, selUsers }) => {
  const [selectedUsers, setSelectedUsers] = useState(selUsers || [])
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    setLoading(true)
    if (GroupIDs && GroupIDs.length) {
      let groupUsers = []
      for (let GroupID of GroupIDs) {
        Proxies.GET('groups/elements', { GroupID }).then(responseJSON => {
          setLoading(false)
          groupUsers = groupUsers.concat(responseJSON.filter(user => !users.find(u => u.UserID === user.UserID)))
          if (selUsers && selUsers.length) {
            groupUsers = groupUsers.map(user => {
              let match = selUsers.find(u => u.UserID === user.UserID)
              if (match) {
                user.checked = true
              }
              return user
            })
          }
          setUsers(groupUsers)
        })
      }
    } else {
      Proxies.GET('user/raw').then(responseJSON => {
        setLoading(false)
        responseJSON = responseJSON.map(item => {
          if (selUsers && selUsers.length) {
            let match = selUsers.find(u => u.UserID === item.UserID)
            if (match) {
              item.checked = true
            } else {
              item.checked = false
            }
          } else {
            item.checked = false
          }
          return item
        })
        setUsers(responseJSON)
      })
    }
  }, [])

  return (
    <Modal isOpen={showUsersModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Select Users</ModalHeader>
      <ModalBody style={{ maxHeight: '720px', overflowY: 'scroll' }}>
        {loading ? (
          <FontAwesomeIcon icon='sync' spin />
        ) : (
          <DynamicTable
            headers={{
              UserID: ' ',
              userName: 'Name',
              userEmail: 'Email',
              userPhone: 'Mobile'
            }}
            data={users && users.length ? users : []}
            responsive
            size='lg'
            options={{
              fieldFunctions: {
                UserID: e => {
                  return (
                    <Fragment>
                      <Input
                        className='order-selection'
                        type='checkbox'
                        id={'checkbox' + e.UserID}
                        name={'checkbox' + e.UserID}
                        checked={e.checked}
                        onChange={event => {
                          setSelectedUsers(
                            users
                              .map(u => {
                                if (u.UserID === e.UserID) {
                                  u.checked = event.target.checked
                                }
                                return u
                              })
                              .filter(u => u.checked === true)
                          )
                        }}
                      />
                    </Fragment>
                  )
                }
              }
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Row>
          <Button
            onClick={() => {
              setUsers(
                users.map(user => {
                  user.checked = true
                  return user
                })
              )
              setSelectedUsers(
                users.map(u => {
                  u.checked = true
                  return u
                })
              )
            }}
          >
            Select All
          </Button>
          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => {
              setUsers(
                users.map(user => {
                  user.checked = false
                  return user
                })
              )
              setSelectedUsers([])
            }}
          >
            Unselect All
          </Button>
          <Button style={{ marginLeft: '32px' }} onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button style={{ marginLeft: '8px' }} onClick={() => onUsersSelect(selectedUsers)}>
            Ok
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}
