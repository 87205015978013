import React, { useCallback, useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, TabContent, TabPane, Nav, NavItem, NavLink, Input, Label } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { brandNameAndColor } from '../../util/brandColor.js'

const CampaignOverview = props => {
  const [notificationcampaign, setNotificationCampaign] = useState([])
  const [notification, setNotification] = useState([])
  const [templatecampaign, setTemplateCampaign] = useState([])
  const [template, setTemplate] = useState([])
  const [activeTab, setActiveTab] = useState('1')
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [completedCampaigns, setCompletedCampaigns] = useState([])
  const [draftCampaign, setDraftCampaign] = useState([])
  const [kampagn, setKampagn] = useState(true)
  const [isSystemManageAccess, setIsSystemManageAccess] = useState(false)
  const platformData = useSelector(state => state.login.settings)
  const [brands, setBrands] = useState([])

  useEffect(() => {
    IsSystemManageAccess()
    getNotificationCampaign()
    getTemplateCampaign()
    fetchBrands()
  }, [])

  const fetchBrands = useCallback(() => {
    Proxies.GET('brands/getAssociatedBrands').then(responseJSON => {
      if (responseJSON.error) return setBrands([])
      return setBrands(responseJSON.map(item => ({ BrandID: item.BrandID, brandName: item.brandName })))
    })
  }, [])

  const IsSystemManageAccess = () => {
    let userprivilege = platformData && platformData.userPrivilege ? platformData.userPrivilege.toUpperCase() : null
    let userrole = platformData && platformData.userRole ? platformData.userRole.toUpperCase() : null
    let result = userprivilege === 'MANAGE' && userrole === 'SYSTEM'
    setIsSystemManageAccess(result)
  }

  function getNotificationCampaign() {
    setLoading1(true)
    Proxies.GET('notificationcampaign/pool').then(responseJSON => {
      if (!responseJSON.error) {
        setNotificationCampaign(responseJSON)
        setNotification(responseJSON)
        let tempCompleted = []
        let tempDraft = []
        responseJSON.map(e => {
          if (e.status === 'PUBLISHED') {
            tempCompleted.push(e)
          } else if (e.status === 'DRAFT') {
            tempDraft.push(e)
          }
        })
        setCompletedCampaigns(tempCompleted)
        setDraftCampaign(tempDraft)
      }
      setLoading1(false)
    })
  }

  function getTemplateCampaign() {
    setLoading2(true)
    Proxies.GET('notificationcampaigntemplate/pool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setTemplateCampaign(responseJSON)
        setTemplate(responseJSON)
      }
      setLoading2(false)
    })
  }

  function RemoveCampaign(campaignID) {
    Proxies.POST('notificationcampaign/removeCampaign', { NotificationCampaignID: Number(campaignID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getNotificationCampaign()
      }
    })
  }

  function copyCampaign(NotificationCampaignID) {
    Proxies.POST('notificationcampaign/duplicateCampaign', { NotificationCampaignID: Number(NotificationCampaignID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        window.location.assign(`/campaign/${responseJSON.NotificationCampaignID}`)
      }
    })
  }

  function CampaignRemoveTemplate(TemplateID) {
    Proxies.POST('notificationcampaigntemplate/removeTemplate', { NotificationCampaignTemplateID: Number(TemplateID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getTemplateCampaign()
      }
    })
  }

  function copyCampaignTemplate(NotificationCampaignTemplateID) {
    Proxies.POST('notificationcampaigntemplate/duplicateTemplate', { NotificationCampaignTemplateID: Number(NotificationCampaignTemplateID) }).then(
      responseJSON => {
        if (responseJSON.error) {
          // console.log(responseJSON.error)
        } else {
          window.location.assign(`/campaign/template/${responseJSON.NotificationCampaignTemplateID}`)
        }
      }
    )
  }

  function replaceIsLock(e) {
    let replacedobj = { ...e, metadata: JSON.parse(e.metadata), isLocked: !e.isLocked ? 1 : 0 }
    Proxies.POST('notificationcampaigntemplate/replace', { ...replacedobj }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getTemplateCampaign()
      }
    })
  }

  const filterDate = e => {
    let target = e.target.value
    let targetName = e.target.name
    if (target) {
      let dateFormat = moment(target).format('DD-MM-YYYY')
      if (targetName === 'notification') {
        let searchedData = notification.filter(
          data => moment(data.timeInsert).format('DD-MM-YYYY') === dateFormat || moment(data.publishDate).format('DD-MM-YYYY') === dateFormat
        )
        setNotificationCampaign(searchedData)
      } else {
        let searchedData = template.filter(
          data => moment(data.timeInsert).format('DD-MM-YYYY') === dateFormat || moment(data.timeUpdate).format('DD-MM-YYYY') === dateFormat
        )
        setTemplateCampaign(searchedData)
      }
    } else {
      if (targetName === 'notification') {
        setNotificationCampaign(notification)
      } else {
        setTemplateCampaign(template)
      }
    }
  }

  const filterBrand = e => {
    let target = e.target.value
    let targetName = e.target.name
    if (target && target !== 'Select Brand') {
      if (targetName === 'notification') {
        let searchedData = notification.filter(data => data.brand.brandName === target)
        setNotificationCampaign(searchedData)
      } else {
        let searchedData = template.filter(data => data.brand.brandName === target)
        setTemplateCampaign(searchedData)
      }
    } else {
      if (targetName === 'notification') {
        setNotificationCampaign(notification)
      } else {
        setTemplateCampaign(template)
      }
    }
  }

  useEffect(() => {
    setActiveTab(!!props.match.url && props.match.url === '/campaign/template' ? '2' : '1')
    setKampagn(!!props.match.url && props.match.url === '/campaign/template' ? false : true)
  }, [props.match.url])

  return (
    <CollapsibleCard
      startOpen
      header={
        kampagn ? (
          <strong>
            Oversigt over kampagner
            <Button className='float-right' tag={Link} to={'campaign/new'} style={{ marginLeft: '16px' }}>
              + Opret kampagne
            </Button>
          </strong>
        ) : (
          <strong>
            Template oversigt
            <Button
              className='float-right'
              tag={Link}
              to={!!props.match.url && props.match.url === '/campaign/template' ? 'template/new' : 'campaign/template/new'}
              style={{ marginLeft: '16px' }}
            >
              + Opret en ny template
            </Button>
          </strong>
        )
      }
    >
      <div>
        <Nav tabs style={{ width: '100%' }}>
          <div style={{ width: '50%' }}>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => {
                  setKampagn(true)
                  setActiveTab('1')
                }}
              >
                KAMPAGNEOVERSIGT
              </NavLink>
            </NavItem>
          </div>
          <div style={{ width: '50%' }}>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => {
                  setKampagn(false)
                  setActiveTab('2')
                }}
              >
                REDIGER OG TILFØJ TEMPLATES
              </NavLink>
            </NavItem>
          </div>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='1' style={{ backgroundColor: '#f2f3f8' }}>
            {loading1 ? (
              <FontAwesomeIcon icon='spinner' spin />
            ) : (
              <div>
                <div
                  style={{ display: 'flex', margin: 'auto', marginTop: '50px', marginBottom: '50px', width: '100%', justifyContent: 'space-between' }}
                >
                  <div style={{ textAlign: 'center', padding: '30px 10px', width: '33%', backgroundColor: '#ffffff' }}>
                    <h3 style={{ fontWeight: 'bolder' }}>{notificationcampaign?.length || 0}</h3>
                    <h6 style={{ fontWeight: 'bolder' }}>Oprettede kampagner i alt</h6>
                  </div>
                  <div style={{ textAlign: 'center', padding: '30px 10px', width: '33%', backgroundColor: '#ffffff' }}>
                    <h3 style={{ fontWeight: 'bolder' }}>{completedCampaigns?.length || 0}</h3>
                    <h6 style={{ fontWeight: 'bolder' }}>Afsluttede kampagner</h6>
                  </div>
                  <div style={{ textAlign: 'center', padding: '30px 10px', width: '33%', backgroundColor: '#ffffff' }}>
                    <h3 style={{ fontWeight: 'bolder' }}>{draftCampaign?.length || 0}</h3>
                    <h6 style={{ fontWeight: 'bolder' }}>Udkast</h6>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    margin: 'auto',
                    marginTop: '50px',
                    marginBottom: '20px',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Input className='mr-2 ml-2' type='select' name='notification' onChange={filterBrand}>
                      <option>Select Brand</option>
                      {brands &&
                        brands
                          .sort((a, b) => a.brandName.localeCompare(b.brandName))
                          .map((e, i) => (
                            <option value={e.brandName} key={i}>
                              {e.brandName}
                            </option>
                          ))}
                    </Input>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Label for='filterText'>Datosøg:</Label>
                    <Input style={{ marginLeft: 10 }} type='date' name='notification' id='dateSearch' onChange={filterDate} />
                  </div>
                </div>
                <DynamicTable
                  responsive
                  size='sm'
                  options={{
                    startSort: {
                      dir: 'DESC',
                      column: 'name'
                    },
                    fieldFunctions: {
                      timeInsert: e => {
                        return !e.timeInsert ? '-' : moment(e.timeInsert).format('DD-MM-YYYY')
                      },
                      publishDate: e => {
                        return !e.publishDate ? '-' : moment(e.publishDate).format('DD-MM-YYYY')
                      },
                      brand: e => {
                        return (
                          <p
                            style={{
                              backgroundColor: brandNameAndColor[e.brand.brandName],
                              color: '#ffffff',
                              borderRadius: '20px',
                              textAlign: 'center',
                              padding: '5px',
                              marginTop: '18px'
                            }}
                          >
                            {e.brand.brandName}
                          </p>
                        )
                      }
                    },
                    fieldExportFunctions: {
                      brand: e => e.brand.brandName
                    },
                    addField: e => {
                      return (
                        <td>
                          <ButtonGroup>
                            {e.status === 'PUBLISHED' && <Tooltip target={'view' + e.NotificationCampaignID}>Vis</Tooltip>}
                            <Tooltip target={'users' + e.NotificationCampaignID}>Brugere</Tooltip>
                            <Button
                              tag={Link}
                              to={{ pathname: '/campaign/' + e.NotificationCampaignID + '/users', state: e }}
                              id={'users' + e.NotificationCampaignID}
                            >
                              <FontAwesomeIcon icon='users' />
                            </Button>
                            {e.status === 'PUBLISHED' && (
                              <Button
                                tag={Link}
                                to={{ pathname: '/campaign/' + e.NotificationCampaignID + '/report', state: e }}
                                id={'view' + e.NotificationCampaignID}
                              >
                                <FontAwesomeIcon icon='eye' />
                              </Button>
                            )}

                            {e.status !== 'PUBLISHED' && <Tooltip target={'edit' + e.NotificationCampaignID}>Redigere</Tooltip>}
                            {e.status !== 'PUBLISHED' && (
                              <Button tag={Link} to={'/campaign/' + e.NotificationCampaignID} id={'edit' + e.NotificationCampaignID}>
                                <FontAwesomeIcon icon='edit' />
                              </Button>
                            )}

                            <Tooltip target={'copy' + e.NotificationCampaignID}>Kopier</Tooltip>
                            <Button
                              id={'copy' + e.NotificationCampaignID}
                              onClick={() => {
                                copyCampaign(e.NotificationCampaignID)
                              }}
                            >
                              <FontAwesomeIcon icon='copy' />
                            </Button>

                            {e.status !== 'PUBLISHED' && <Tooltip target={'delete' + e.NotificationCampaignID}>Slet</Tooltip>}
                            {e.status !== 'PUBLISHED' && (
                              <Button
                                id={'delete' + e.NotificationCampaignID}
                                color='danger'
                                onClick={() => {
                                  if (confirm('Are you sure you want to delete?')) {
                                    RemoveCampaign(e.NotificationCampaignID)
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon='trash' />
                              </Button>
                            )}
                          </ButtonGroup>
                          {!!e.isApprover && (
                            <ButtonGroup style={{ marginLeft: 20, marginTop: 2 }}>
                              <FontAwesomeIcon color='red' icon='exclamation-circle' />
                            </ButtonGroup>
                          )}
                        </td>
                      )
                    }
                  }}
                  headers={{
                    NotificationCampaignID: '#',
                    title: 'Title',
                    status: 'Status',
                    brand: 'Brand Name',
                    timeInsert: 'Created Date',
                    publishDate: 'Publish Date'
                  }}
                  data={notificationcampaign}
                />
              </div>
            )}
          </TabPane>

          <TabPane tabId='2' style={{ backgroundColor: '#f2f3f8' }}>
            {loading2 ? (
              <FontAwesomeIcon icon='spinner' spin />
            ) : (
              <div>
                <div
                  style={{
                    display: 'flex',
                    margin: 'auto',
                    marginTop: '50px',
                    marginBottom: '20px',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Input className='mr-2 ml-2' type='select' name='template' onChange={filterBrand}>
                      <option>Select Brand</option>
                      {brands &&
                        brands
                          .sort((a, b) => a.brandName.localeCompare(b.brandName))
                          .map((e, i) => (
                            <option value={e.brandName} key={i}>
                              {e.brandName}
                            </option>
                          ))}
                    </Input>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Label for='filterText'>Datosøg:</Label>
                    <Input style={{ width: '200px', marginLeft: 10 }} type='date' name='template' onChange={filterDate} />
                  </div>
                </div>
                <DynamicTable
                  responsive
                  size='sm'
                  options={{
                    startSort: {
                      dir: 'DESC',
                      column: 'NotificationCampaignTemplateID'
                    },
                    fieldFunctions: {
                      timeInsert: e => {
                        return !e.timeInsert ? '-' : moment(e.timeInsert).format('DD-MM-YYYY')
                      },
                      timeUpdate: e => {
                        return !e.timeUpdate ? '-' : moment(e.timeUpdate).format('DD-MM-YYYY')
                      },
                      brand: e => {
                        return (
                          <p
                            style={{
                              backgroundColor: brandNameAndColor[e.brand.brandName],
                              color: '#ffffff',
                              borderRadius: '20px',
                              textAlign: 'center',
                              padding: '5px',
                              marginTop: '18px'
                            }}
                          >
                            {e.brand.brandName}
                          </p>
                        )
                      }
                    },
                    fieldExportFunctions: {
                      brand: e => e.brand.brandName
                    },
                    addField: e => {
                      return (
                        <td>
                          <ButtonGroup>
                            {(e.isLocked === 1 || isSystemManageAccess) && (
                              <Tooltip target={'lock' + e.NotificationCampaignTemplateID}>{e.isLocked === 1 ? 'Locked' : 'Unlocked'}</Tooltip>
                            )}
                            {(e.isLocked === 1 || isSystemManageAccess) && (
                              <Button
                                onClick={() => {
                                  if (isSystemManageAccess) {
                                    replaceIsLock(e)
                                  }
                                }}
                                tag={Link}
                                to={'/campaign'}
                                id={'lock' + e.NotificationCampaignTemplateID}
                              >
                                {e.isLocked === 1 ? <FontAwesomeIcon icon='lock' /> : <FontAwesomeIcon icon='unlock' />}
                              </Button>
                            )}

                            {(!(e.isLocked === 1) || isSystemManageAccess) && (
                              <Tooltip target={'edit' + e.NotificationCampaignTemplateID}>Redigere</Tooltip>
                            )}
                            {(!(e.isLocked === 1) || isSystemManageAccess) && (
                              <Button
                                tag={Link}
                                to={'/campaign/template/' + e.NotificationCampaignTemplateID}
                                id={'edit' + e.NotificationCampaignTemplateID}
                              >
                                <FontAwesomeIcon icon='edit' />
                              </Button>
                            )}

                            <Tooltip target={'copy' + e.NotificationCampaignTemplateID}>Kopier</Tooltip>
                            <Button
                              id={'copy' + e.NotificationCampaignTemplateID}
                              onClick={() => {
                                copyCampaignTemplate(e.NotificationCampaignTemplateID)
                              }}
                            >
                              <FontAwesomeIcon icon='copy' />
                            </Button>

                            {(!(e.isLocked === 1) || isSystemManageAccess) && (
                              <Tooltip target={'delete' + e.NotificationCampaignTemplateID}>Slet</Tooltip>
                            )}
                            {(!(e.isLocked === 1) || isSystemManageAccess) && (
                              <Button
                                id={'delete' + e.NotificationCampaignTemplateID}
                                color='danger'
                                onClick={() => {
                                  if (confirm('Are you sure you want to delete?')) {
                                    CampaignRemoveTemplate(e.NotificationCampaignTemplateID)
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon='trash' />
                              </Button>
                            )}
                          </ButtonGroup>
                        </td>
                      )
                    }
                  }}
                  headers={{
                    NotificationCampaignTemplateID: '#',
                    name: 'Title',
                    brand: 'Brand Name',
                    timeInsert: 'Created Date',
                    timeUpdate: 'Last edited Date'
                  }}
                  data={templatecampaign}
                />
              </div>
            )}
          </TabPane>
        </TabContent>
      </div>
    </CollapsibleCard>
  )
}

export default CampaignOverview
