import React, { useState } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCrop from 'react-image-crop'
import Proxies from 'Components/proxies'
import 'react-image-crop/dist/ReactCrop.css'

export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width * scaleX
  canvas.height = crop.height * scaleY
  const ctx = canvas.getContext('2d')

  ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY)

  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        blob.name = fileName
        resolve(blob)
      },
      'image/jpeg',
      1
    )
  })
}

export default function CroppedImageUpload(props) {
  let { className, src, label, onComplete, initialCrop, id, originalImage, originalImageId } = props
  const defaultCrop = initialCrop || { aspect: 1.33, x: '50%', y: '50%' }
  const [isUploading, setIsUploading] = useState(false)
  const [crop, setCrop] = useState(defaultCrop)
  const [completedCrop, setCompletedCrop] = useState(null)

  const onApprove = () => {
    if (!originalImage) originalImage = document.getElementById(originalImageId)
    if (!originalImage) return
    getCroppedImg(originalImage, completedCrop, Date.now()).then(result => {
      setIsUploading(true)
      Proxies.uploadImage(result).then(responseJSON => {
        const { imageOriginSource } = responseJSON
        setIsUploading(false)
        setCrop(defaultCrop)
        if (!imageOriginSource) return
        onComplete(imageOriginSource)
      })
    })
  }

  return (
    <>
      <Row>
        {!!src && (
          <>
            <Col>
              <ReactCrop
                id={id || ''}
                className={className || ''}
                src={src}
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={c => setCompletedCrop(c)}
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col xs='12'>
          <label>{label || 'Image'}</label>
        </Col>
        {!!src && (
          <Col xs='12'>
            <Button onClick={onApprove}>{isUploading ? <FontAwesomeIcon icon='spinner' spin /> : 'Godkend'}</Button>
          </Col>
        )}
      </Row>
    </>
  )
}
