import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  FETCHING_ADDRESSES: null,
  FETCHING_ADDRESSES_FAILED: null,
  FETCHNG_ADDRESS_DETAILS: null,
  FETCHNG_ADDRESS_DETAILS_FAILED: null,
  TAKING_INPUT: null,
  IS_SAVING: null,
  ADDRESS_SUCCESS: null,
  ADDRESS_FAILED: null,
  ADDRESS_DELETE_FAILED: null,
  IS_REDIRECT: null
})
export const AddressActions = Reflux.createActions([
  'getAddresses',
  'getAddressDetails',
  'clearToast',
  'createEmptyAddress',
  'updateAddressField',
  'saveAddress',
  'deleteAddress',
  'toggleInActiveAddresses',
  'toggleActiveAddresses'
])

export class AddressStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = AddressActions
    this.state = {
      loadingState: null,
      addresses: [],
      addressDetails: {
        BrandID: '',
        companyName: '',
        addressLine: '',
        zip: '',
        city: ''
      },
      showActiveAddresses: true,
      showInActiveAddresses: false,
      addressFilter: 'active'
    }
  }
  getAddresses() {
    if (this.state.loadingState === STATUS.FETCHING_ADDRESSES) {
      return
    }
    this.setState({ loadingState: STATUS.FETCHING_ADDRESSES })
    GET('addresses/pool').then(responseJSON => {
      let nextState = {
        addresses: [],
        loadingState: null
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.loadingState = STATUS.FETCHING_ADDRESSES_FAILED
        } else {
          nextState.addresses = responseJSON
          nextState.loadingState = STATUS.TAKING_INPUT
        }
      }
      this.setState(nextState)
    })
  }
  deleteAddress(AddressID = null, removed) {
    if (AddressID) {
      POST('addresses/remove', { AddressID, removed: removed ? 0 : 1 }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ loadingState: STATUS.ADDRESS_DELETE_FAILED })
        } else {
          this.getAddresses()
        }
      })
    }
  }
  createEmptyAddress() {
    this.setState({
      addressDetails: {
        BrandID: '',
        companyName: '',
        addressLine: '',
        zip: '',
        city: ''
      }
    })
  }
  clearToast(loadingState) {
    loadingState ? this.setState({ loadingState }) : this.setState({ loadingState: null })
  }
  getAddressDetails(AddressID = null) {
    if (AddressID) {
      this.setState({ loadingState: STATUS.FETCHNG_ADDRESS_DETAILS })
      GET('addresses/fetch', { AddressID: AddressID }).then(responseJSON => {
        let nextState = {
          addressDetails: {}
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.loadingState = STATUS.FETCHNG_ADDRESS_DETAILS_FAILED
          } else {
            nextState.addressDetails = responseJSON
            nextState.addressDetails.address = responseJSON.addressLine
            nextState.loadingState = STATUS.TAKING_INPUT
          }
        }
        this.setState(nextState)
      })
    }
  }
  updateAddressField(field) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let addressDetails = this.state.addressDetails
    if (fieldName === 'BrandID') {
      fieldValue = Number(fieldValue)
    }
    addressDetails[fieldName] = fieldValue
    this.setState({ addressDetails })
  }
  saveAddress() {
    let addressDetails = this.state.addressDetails
    if (!addressDetails.BrandID) {
      delete addressDetails.BrandID
    }
    this.setState({ loadingState: STATUS.IS_SAVING })
    POST('addresses/replace', addressDetails).then(responseJSON => {
      let nextState = {
        addressDetails: this.state.addressDetails
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.loadingState = STATUS.ADDRESS_FAILED
        } else {
          nextState.addressDetails = responseJSON
          nextState.loadingState = STATUS.ADDRESS_SUCCESS
        }
      }
      this.setState(nextState)
    })
  }
  toggleActiveAddresses() {
    let value = !this.state.showActiveAddresses ? 'active' : 'both'
    this.setState({ showActiveAddresses: !this.state.showActiveAddresses, addressFilter: value, showInActiveAddresses: false })
  }
  toggleInActiveAddresses() {
    let value = this.state.showInActiveAddresses ? 'both' : 'inactive'
    this.setState({ showInActiveAddresses: !this.state.showInActiveAddresses, addressFilter: value, showActiveAddresses: false })
  }
}
