/*
	External libraries
	If unable to load, please install

	npm install --save es6-promise
	npm install --save isomorphic-fetch
*/

require('es6-promise').polyfill()
require('isomorphic-fetch')

/*
	Internal libraries
*/

require('./array')
require('./object')
require('./string')
