import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { ClientActions, ClientStore, STATUS } from 'Stores/clientStore'
import ClientForm from 'Modules/client/clientForm'

export default class ClientCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [ClientStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    ClientActions.getCompanies()
    ClientActions.createEmptyClient()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.CLIENT_FAILED) {
      toast.error('CLIENT CREATION FAILED', {
        autoClose: 7000,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast()
    }
    if (this.state.status === STATUS.CLIENT_SUCCESS) {
      toast.success('CREATED CLIENT SUCCESSFULLY', {
        autoClose: 7000,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <ClientForm />
      </React.Fragment>
    )
  }
}
