import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { SettingsAction, SettingsStore, STATUS } from 'Stores/settingsStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Button, UncontrolledTooltip as Tooltip, Row, Col, Form } from 'reactstrap'
import Styled from 'styled-components'
import FormField from 'Components/formField'

const StyledFontAwesomeIcon = Styled(FontAwesomeIcon)`
  margin-left: 5px !important;
  cursor: pointer !important;
`

const StyledRow = Styled(Row)`
margin-top: 20px !important;
`
export default class Settings extends Component {
  constructor(props) {
    super(props)
    this.stores = [SettingsStore]
    this.storeKeys = ['loadingState', 'companyTokenString', 'companySaltString', 'generatedHashkey', 'payload', 'showText', 'showTooltip']
  }

  componentDidMount() {
    if (this.state.companyTokenString || this.state.companySaltString) {
      SettingsAction.resetTokens()
    }
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_TOKENS_FAILED) {
      toast.error('FETCHING COMPANY TOKENS FAILED', {
        autoClose: 7500,
        onClose: () => {
          SettingsAction.clearToast()
        }
      })
      SettingsAction.clearToast()
    }
    if (this.state.status === STATUS.GENERATE_HASH_KEY_FAILED) {
      toast.error('PAYLOAD INSERT FAILED', {
        autoClose: 7500,
        onClose: () => {
          SettingsAction.clearToast()
        }
      })
      SettingsAction.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_TOKENS) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>Tokens </strong>}>
          <React.Fragment>
            <Row>
              <Col xs={3}>Company Token</Col>
              <Col>
                <p id='companyToken'>
                  {this.state.showText.companyToken
                    ? this.state.companyTokenString
                    : '****************************************************************'}
                  <Tooltip placement='right' isOpen={this.state.showTooltip.companyToken} target='companyToken' toggle={SettingsAction.setTooltip}>
                    Kopieret
                  </Tooltip>
                  <StyledFontAwesomeIcon
                    style={{ color: 'green' }}
                    icon={this.state.showText.companyToken ? 'eye-slash' : 'eye'}
                    onClick={() => {
                      SettingsAction.toggleText('companyToken')
                    }}
                  />
                  <StyledFontAwesomeIcon
                    style={{ color: 'green' }}
                    icon='copy'
                    onClick={() => {
                      SettingsAction.copyToClipboard('companyToken', this.state.companyTokenString)
                    }}
                  />
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>Company Salt</Col>
              <Col>
                <p id='companySalt'>
                  {this.state.showText.companySalt ? this.state.companySaltString : '********************************'}
                  <Tooltip placement='right' isOpen={this.state.showTooltip.companySalt} target='companySalt' toggle={SettingsAction.setTooltip}>
                    Kopieret
                  </Tooltip>
                  <StyledFontAwesomeIcon
                    style={{ color: 'green' }}
                    icon={this.state.showText.companySalt ? 'eye-slash' : 'eye'}
                    onClick={() => {
                      SettingsAction.toggleText('companySalt')
                    }}
                  />
                  <StyledFontAwesomeIcon
                    style={{ color: 'green' }}
                    icon='copy'
                    onClick={() => {
                      SettingsAction.copyToClipboard('companySalt', this.state.companySaltString)
                    }}
                  />
                </p>
              </Col>
            </Row>
          </React.Fragment>
        </CollapsibleCard>
        <CollapsibleCard startOpen header={<strong>Enter Payload</strong>}>
          <Form>
            <FormField name='Hash Algorithm' field='algorithm' required>
              <Input type='select' name='algorithm' value={this.state.payload.algorithm} id='algorithm' onChange={SettingsAction.updatePayload}>
                <option value='md5'>MD5</option>
                <option value='sha256'>SHA256</option>
              </Input>
            </FormField>
            <FormField
              type='textarea'
              rows={5}
              required
              data={this.state.payload}
              field={'hashBody'}
              name='Hash Body'
              onChange={e => {
                SettingsAction.updatePayload(e)
              }}
            />
            <Button onClick={() => SettingsAction.generateHash()}>Generate Hash</Button>
            {this.state.generatedHashkey && (
              <StyledRow>
                <Col xs={3}>
                  <p>Generated Hash Key</p>
                </Col>
                <Col>
                  <p>{this.state.generatedHashkey}</p>
                </Col>
              </StyledRow>
            )}
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
