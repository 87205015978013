/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST, wrapFilter, PUT } = Proxies
import strip_tags from 'Components/striptags'
import _ from 'lodash'

export const EnvelopeActions = createActions([
  'getEnvelopes',
  'createEmptyEnvelope',
  'clearEnvelope',
  'updateEnvelopeField',
  'saveEnvelope',
  'getElements',
  'approveEnvelope',
  'updateEnvelopeGroupField',
  'getGroups',
  'deleteNotification',
  'clearToast',
  'setEnvelopeData',
  'sendEnvelope',
  'deleteEnvelope',
  'toggleQueryContent',
  'updatePointsMessageField',
  'getPointMessageDetails',
  'savePointMessage',
  'clearPointsMessageToast',
  'resetPointsData'
])

export class EnvelopeStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = EnvelopeActions
    this.state = {
      currEnvelopeID: 0,
      envelopes: [],
      isSaving: false,
      fetchingEnvelopes: false,
      fetchingElements: false,
      elements: [],
      approvingEnvelope: false,
      groups: [],
      fetchingGroups: false,
      envelopeDetails: {
        filterText: ''
      },
      deleteNotification: false,
      currNotificationID: 0,
      showEnvelopeError: false,
      showEnvelopeSuccess: false,
      sendingEnvelope: false,
      sendingEnvelopeSuccess: false,
      sendingEnvelopeError: false,
      deletingEnvelope: false,
      deletingEnvelopeError: false,
      deletingEnvelopeSuccess: false,
      pointMessageDetails: {
        CustomisedID: '',
        customisationData: {
          enableAutomaticMessagePointUpload: false,
          messageTextPointUpload: '',
          enableAutomaticMessageManualPointInsert: false,
          messageTextManualPointInsert: '',
          enableAutomaticMessageNegativePointUpload: false,
          messageTextNegativePointUpload: ''
        },
        customisedType: 'Brand',
        description: 'Automatic message to Users on point upload',
        title: 'Automatic message to Users on point upload'
      },
      pointUpload: {
        enableAutomaticMessagePointUpload: false,
        messageTextPointUpload: ''
      },
      pointInsert: {
        enableAutomaticMessageManualPointInsert: false,
        messageTextManualPointInsert: ''
      },
      negativePointUpload: {
        enableAutomaticMessageNegativePointUpload: false,
        messageTextNegativePointUpload: ''
      },
      isSavingPointUploadCustomisation: false,
      isSavingPointInsertCustomisation: false,
      savingPointCustomisationSuccess: false,
      savingPointCustomisationError: false,
      isSavingNegativePointUploadCustomisation: false
    }
  }
  clearToast() {
    this.setState({
      showEnvelopeError: false,
      showEnvelopeSuccess: false,
      sendingEnvelopeError: false,
      sendingEnvelopeSuccess: false,
      deletingEnvelopeSuccess: false,
      deletingEnvelopeError: false
    })
  }
  createEmptyEnvelope(frequency) {
    this.setState({ envelopeDetails: { notificationFrequency: frequency, filterText: '' } })
  }
  clearEnvelope() {
    this.setState({ envelopeDetails: { filterText: '' } })
  }
  getEnvelopes() {
    this.setState({ fetchingEnvelopes: true })
    GET('envelopes/pool').then(responseJSON => {
      let nextState = {
        envelopes: [],
        fetchingEnvelopes: false
      }
      if (responseJSON) {
        responseJSON = responseJSON.filter(e => {
          return !isNaN(e.EnvelopeID)
        })
        responseJSON = responseJSON.filter(item => {
          item.envelopeMessage && item.envelopeMessage.length > 250 ? (item.envelopeMessageReadMore = true) : (item.envelopeMessageReadLess = false)
          return !isNaN(item.EnvelopeID)
        })
        nextState.envelopes = responseJSON
      }
      this.setState(nextState)
    })
  }
  updateEnvelopeGroupField(field) {
    let envelopeDetails = this.state.envelopeDetails
    if (field.target && field.target.value) {
      envelopeDetails.GroupID = parseInt(field.target.value)
      this.setState({ envelopeDetails: envelopeDetails })
    }
  }
  updateEnvelopeField(field) {
    let name = field.target.name
    let value = strip_tags(field.target.value)
    let envelopeDetails = this.state.envelopeDetails
    envelopeDetails[name] = value
    this.setState({ envelopeDetails: envelopeDetails })
  }
  saveEnvelope() {
    if (this.state.envelopeDetails.GroupID != '') {
      this.setState({ isSaving: true })
      POST('envelopes/compose', this.state.envelopeDetails).then(responseJSON => {
        let nextState = {
          isSaving: false,
          envelopeDetails: this.state.envelopeDetails,
          showEnvelopeSuccess: false,
          showEnvelopeError: false
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.showEnvelopeError = true
          } else {
            nextState.envelopeDetails = responseJSON
            nextState.showEnvelopeSuccess = true
          }
        }
        this.setState(nextState)
        this.getEnvelopes()
      })
    }
  }
  getElements(EnvelopeID = null) {
    this.setState({ fetchingElements: true })
    if (EnvelopeID) {
      GET('envelopes/elements', { EnvelopeID: EnvelopeID }).then(responseJSON => {
        let nextState = {
          elements: [],
          fetchingElements: false
        }
        if (responseJSON) {
          nextState.elements = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  getGroups() {
    if (this.state.fetchingGroups) {
      return false
    }
    this.setState({ fetchingGroups: true })
    GET('schemas/groups').then(responseJSON => {
      let nextState = {
        groups: [],
        fetchingGroups: false,
        envelopeDetails: this.state.envelopeDetails ? this.state.envelopeDetails : {}
      }
      if (responseJSON) {
        nextState.groups = responseJSON
        nextState.envelopeDetails.GroupID = nextState.groups.length ? nextState.groups[0].GroupID : 0
      }
      this.setState(nextState)
    })
  }
  approveEnvelope(EnvelopeID = null) {
    this.setState({ approvingEnvelope: true, currEnvelopeID: EnvelopeID })
    if (EnvelopeID) {
      POST('envelopes/approve', { EnvelopeID: EnvelopeID }).then(() => {
        let nextState = {
          approvingEnvelope: false,
          currEnvelopeID: 0
        }
        this.setState(nextState)
        this.getEnvelopes()
      })
    }
  }
  deleteNotification(NotificationID = null, EnvelopeID) {
    this.setState({ deletingNotification: true, currNotificationID: NotificationID })
    if (NotificationID) {
      POST('notifications/remove', { NotificationID: NotificationID }).then(() => {
        let nextState = {
          deletingNotification: false,
          currNotificationID: 0
        }
        this.setState(nextState)
        this.getElements(EnvelopeID)
      })
    }
  }

  setEnvelopeData(envelopeDetails) {
    this.setState({ envelopeDetails })
  }

  sendEnvelope(EnvelopeID) {
    if (EnvelopeID) {
      this.setState({ sendingEnvelope: true, currEnvelopeID: EnvelopeID })
      POST('notifications/send-notifications', { EnvelopeID }).then(responseJSON => {
        if (responseJSON) {
          this.setState({ sendingEnvelope: false, sendingEnvelopeSuccess: true, currEnvelopeID: 0 })
          this.getEnvelopes()
        } else {
          this.setState({ sendingEnvelope: false, sendingEnvelopeError: true, currEnvelopeID: 0 })
        }
      })
    }
  }

  deleteEnvelope(EnvelopeID = null) {
    if (EnvelopeID) {
      this.setState({ deletingEnvelope: true, currEnvelopeID: EnvelopeID })
      POST('envelopes/remove', { EnvelopeID }).then(responseJSON => {
        let nextState = {
          deletingEnvelope: false,
          currEnvelopeID: 0,
          deletingEnvelopeError: false,
          deletingEnvelopeSuccess: false
        }
        if (responseJSON && responseJSON.error) {
          nextState.deletingEnvelopeError = true
        } else {
          nextState.deletingEnvelopeSuccess = true
        }
        this.setState(nextState)
        this.getEnvelopes()
      })
    }
  }

  toggleQueryContent(e) {
    let envelopes = this.state.envelopes
    envelopes = envelopes.map(item => {
      if (item.EnvelopeID === e.EnvelopeID) {
        item.envelopeMessageReadMore = !item.envelopeMessageReadMore
        item.envelopeMessageReadLess = !item.envelopeMessageReadLess
      }
      return item
    })
    this.setState({ envelopes })
  }

  getPointMessageDetails(BrandID) {
    let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'Automatic message to Users on point upload' }
    GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
      let nextState = {
        pointMessageDetails: this.state.pointMessageDetails
      }
      if (!_.get(nextState.pointMessageDetails, 'CustomisedID')) {
        nextState.pointMessageDetails.CustomisedID = BrandID
      }
      if (responseJSON && !responseJSON.error && responseJSON.length) {
        responseJSON[0].customisationData = JSON.parse(responseJSON[0].customisationData)
        if (responseJSON[0].customisationData && responseJSON[0].customisationData.features) {
          responseJSON[0].customisationData = responseJSON[0].customisationData.features
        } else {
          if (_.isEmpty(responseJSON[0].customisationData)) {
            responseJSON[0].customisationData = this.state.pointMessageDetails.customisationData
          }
        }
        responseJSON[0].customisationData = _.merge(this.state.pointMessageDetails.customisationData, responseJSON[0].customisationData)
        this.setPointsMessageData(responseJSON[0])
        nextState.pointMessageDetails = responseJSON[0]
      }
      this.setState(nextState)
    })
  }
  updatePointsMessageField(field, title) {
    let data = this.state[title]
    let standardMessage = 'Der er indsat point på din konto. Gå ind på din profil og se mere'
    let value = field.target.value
    if (field.target.name === 'enableAutomaticMessagePointUpload') {
      value = field.target.checked
      if (!data.messageTextPointUpload) {
        data.messageTextPointUpload = field.target.checked === true ? standardMessage : ''
      }
    }
    if (field.target.name === 'enableAutomaticMessageManualPointInsert') {
      value = field.target.checked
      if (!data.messageTextManualPointInsert) {
        data.messageTextManualPointInsert = field.target.checked === true ? standardMessage : ''
      }
    }
    if (field.target.name === 'enableAutomaticMessageNegativePointUpload') {
      value = field.target.checked
      if (!data.messageTextNegativePointUpload) {
        data.messageTextNegativePointUpload =
          field.target.checked === true ? 'Der er trukket point fra din konto. Gå ind på din profil og se mere' : ''
      }
    }
    data[field.target.name] = value
    this.setState({ [title]: data })
  }

  savePointMessage(title) {
    let customisation = 'isSaving' + title[0].toUpperCase() + title.substr(1) + 'Customisation'
    this.setState({ [customisation]: true })
    let data = this.state.pointMessageDetails
    if (data.customisationData && typeof data.customisationData === 'string') {
      data.customisationData = JSON.parse(data.customisationData).features
    }
    data.customisationData = _.extend(data.customisationData, _.pick(this.state[title], _.keys(data.customisationData)))
    data.customisationData = JSON.stringify({ features: data.customisationData })
    PUT('customisationv2', data).then(responseJSON => {
      let nextState = {
        isSavingPointUploadCustomisation: false,
        isSavingPointInsertCustomisation: false,
        isSavingNegativePointUploadCustomisation: false
      }
      if (responseJSON && responseJSON.error) {
        nextState.savingPointCustomisationError = false
      } else {
        responseJSON.customisationData = JSON.parse(responseJSON.customisationData)
        if (responseJSON.customisationData && responseJSON.customisationData.features) {
          responseJSON.customisationData = responseJSON.customisationData.features
        }
        nextState.pointMessageDetails = responseJSON
        nextState.savingPointCustomisationSuccess = true
        this.setPointsMessageData(responseJSON)
      }
      this.setState(nextState)
    })
  }

  clearPointsMessageToast() {
    this.setState({ savingPointCustomisationSuccess: false, savingPointCustomisationError: false })
  }

  resetPointsData(title) {
    if (this.state.pointMessageDetails && this.state.pointMessageDetails.CustomisationID) {
      let pointMessageDetails = this.state.pointMessageDetails
      if (typeof pointMessageDetails.customisationData === 'string') {
        pointMessageDetails.customisationData = JSON.parse(pointMessageDetails.customisationData).features
      }
      this.setState({ [title]: _.extend(this.state[title], _.pick(pointMessageDetails.customisationData, _.keys(this.state[title]))) })
    } else {
      switch (title.toLowerCase()) {
        case 'pointupload':
          this.setState({ pointUpload: { enableAutomaticMessagePointUpload: false, messageTextPointUpload: '' } })
          break
        case 'pointinsert':
          this.setState({ pointInsert: { enableAutomaticMessageManualPointInsert: false, messageTextManualPointInsert: '' } })
          break
        case 'negativePointUpload':
          this.setState({
            negativePointUpload: {
              enableAutomaticMessageNegativePointUpload: false,
              messageTextNegativePointUpload: ''
            }
          })
          break
      }
    }
  }

  setPointsMessageData(data) {
    if (data && data.customisationData && Object.keys(data.customisationData).length) {
      let features = Object.assign({}, data.customisationData)
      let pointUpload = {
        enableAutomaticMessagePointUpload: features.enableAutomaticMessagePointUpload,
        messageTextPointUpload: features.messageTextPointUpload
      }
      let pointInsert = {
        enableAutomaticMessageManualPointInsert: features.enableAutomaticMessageManualPointInsert,
        messageTextManualPointInsert: features.messageTextManualPointInsert
      }
      let negativePointUpload = {
        enableAutomaticMessageNegativePointUpload: features.enableAutomaticMessageNegativePointUpload,
        messageTextNegativePointUpload: features.messageTextNegativePointUpload
      }
      this.setState({ pointInsert, pointUpload, negativePointUpload })
    }
  }
}
