/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import moment from 'moment'
import { translateData } from 'Util/translations'
import _ from 'lodash'

export const GroupActions = createActions([
  'getGroups',
  'getGroupDetails',
  'createEmptyGroup',
  'clearGroup',
  'updateGroupField',
  'saveGroup',
  'deleteGroup',
  'getDatasets',
  'getElements',
  'addRule',
  'removeRule',
  'updateDatacolumns',
  'clearRuleValueError',
  'clearToast',
  'previewGroup',
  'changeGroupStatus',
  'giveAccess',
  'toggleQueryContent',
  'getBrands',
  'copyGroup',
  'preserveAdvanceSearch',
  'toggleActiveGroup',
  'toggleInActiveGroup'
])

export class GroupStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = GroupActions
    this.state = {
      currGroupID: 0,
      groups: [],
      groupDetails: {},
      isSaving: false,
      fetchingGroups: false,
      datasets: [],
      fetchingDatasets: false,
      fetchingGroupDetails: false,
      fetchingElements: false,
      elements: [],
      groupRules: [],
      ruleValueEmpty: false,
      showGroupError: false,
      showGroupSuccess: false,
      isPreview: false,
      previewElements: [],
      fetchingPreview: false,
      isAdminAccess: '',
      showGroupDeleteMessage: false,
      brands: [],
      advanceSearchFilters: [],
      showActiveGroups: true,
      showInActiveGroups: false,
      groupFilterValue: 'active'
    }
  }
  clearToast() {
    this.setState({ showGroupDeleteMessage: false, showGroupError: false, showGroupSuccess: false })
  }
  createEmptyGroup() {
    this.setState({ groupDetails: {}, groupRules: [] })
  }
  clearGroup() {
    this.setState({ groupDetails: {}, groupRules: [] })
  }
  clearRuleValueError() {
    this.setState({ ruleValueEmpty: false })
  }
  addRule(operators) {
    let nextState = {
      groupRules: this.state.groupRules
    }
    nextState.groupRules.push({
      datasetTableName: this.state.datasets && this.state.datasets.length ? this.state.datasets[0].datasetTableName : null,
      selectedDatacolumns: this.state.datasets && this.state.datasets.length ? this.state.datasets[0].columns : [],
      DatacolumnID:
        this.state.datasets && this.state.datasets.length && this.state.datasets[0].columns && this.state.datasets[0].columns.length
          ? this.state.datasets[0].columns[0].DatacolumnID
          : null,
      ruleOperator: operators && operators.length ? operators[0].value : null
    })
    this.setState(nextState)
    this.restrictRule()
  }
  restrictRule() {
    let nextState = {
      groupRules: this.state.groupRules,
      datasets: this.state.datasets
    }
    if (nextState.groupRules.length && nextState.groupRules.length > 1 && this.state.datasets.length) {
      let firstGroupRule = nextState.groupRules[0]
      this.state.datasets.map(dataset => {
        if (firstGroupRule.datasetTableName !== 'User' && firstGroupRule.datasetTableName !== 'Wallet') {
          dataset.datasetTableName === firstGroupRule.datasetTableName ? (dataset.disabled = false) : (dataset.disabled = true)
        } else {
          if (firstGroupRule.datasetTableName === 'User' || firstGroupRule.datasetTableName === 'Wallet') {
            dataset.datasetTableName === 'User' || dataset.datasetTableName === 'Wallet' ? (dataset.disabled = false) : (dataset.disabled = true)
          }
        }
      })
    } else {
      this.state.datasets.map(dataset => (dataset.disabled = false))
    }
    this.setState(nextState)
  }
  removeRule(index) {
    let nextState = {
      groupRules: this.state.groupRules,
      datasets: this.state.datasets
    }
    nextState.groupRules.splice(index, 1)
    if (nextState.groupRules.length === 1) {
      nextState.datasets.filter(dataset => (dataset.disabled = false))
    }
    this.setState(nextState)
  }
  updateDatacolumns(element, index) {
    let nextState = {
      groupRules: this.state.groupRules
    }
    if (element && element.target) {
      let grouprule = nextState.groupRules[index]
      if (element.target.name == 'DatasetID') {
        let filteredDataset = this.state.datasets.filter(dataset => dataset.DatasetID == element.target.value)
        if (filteredDataset && filteredDataset.length) {
          grouprule.DatasetID = element.target.value
          grouprule.selectedDatacolumns = filteredDataset[0].columns
          grouprule.DatacolumnID = grouprule.selectedDatacolumns[0].DatacolumnID
          grouprule.datasetTableName = filteredDataset[0].datasetTableName
          grouprule.datacolumnName = grouprule.selectedDatacolumns[0].datacolumnName
        }
      } else if (element.target.name == 'DatacolumnID') {
        grouprule.DatacolumnID = element.target.value
        if (grouprule.selectedDatacolumns) {
          const match = grouprule.selectedDatacolumns.filter(item => item.DatacolumnID == grouprule.DatacolumnID)
          if (match && match.length) {
            grouprule.datacolumnName = match[0].datacolumnName
          }
        }
      } else if (element.target.name == 'Operator') {
        grouprule.ruleOperator = element.target.value
      } else if (element.target.name == 'GroupruleValue') {
        grouprule.ruleValue = element.target.value
      } else if (element.target.name == 'GroupruleValueLower') {
        grouprule.ruleValueLower = element.target.value
      } else if (element.target.name == 'GroupruleValueHigher') {
        grouprule.ruleValueHigher = element.target.value
      }
    }
    this.setState(nextState)
  }
  getDatasets() {
    if (this.state.fetchingDatasets) {
      return false
    }
    this.setState({ fetchingDatasets: true })
    GET('datasets/pool').then(responseJSON => {
      let nextState = {
        datasets: [],
        fetchingDatasets: false,
        selectedDatacolumns: []
      }
      if (responseJSON) {
        responseJSON = translateData(responseJSON)
        responseJSON.map(item => (item.disabled = false))
        nextState.datasets = responseJSON.filter(
          dataset =>
            dataset.datasetTableName === 'User' ||
            dataset.datasetTableName === 'Wallet' ||
            dataset.datasetTableName === 'WalletExt' ||
            dataset.datasetTableName === 'UserDepartment'
        )
        this.setupRules(this.state.groupDetails, responseJSON)
      }
      this.setState(nextState)
      this.restrictRule()
    })
  }
  setupRules(group = {}, datasets = []) {
    let nextState = {
      groupRules: []
    }
    if (group.rules) {
      nextState.groupRules = group.rules.map(rule => {
        let match = datasets.filter(dataset => dataset.datasetTableName == rule.datasetTableName)
        if (match.length) {
          rule.selectedDatacolumns = match[0].columns
        } else {
          rule.selectedDatacolumns = []
        }
        return rule
      })
    }
    this.setState(nextState)
    this.restrictRule()
  }
  getGroups() {
    this.setState({ fetchingGroups: true })
    GET('groups').then(responseJSON => {
      let nextState = {
        groups: [],
        fetchingGroups: false
      }
      if (responseJSON) {
        responseJSON = responseJSON.filter(item => {
          if (item.moduleQuery && item.moduleQuery.length > 100) {
            item.moduleQueryReadMore = true
            item.moduleQueryReadLess = false
          } else {
            item.moduleQueryReadMore = false
            item.moduleQueryReadLess = false
          }
          return !isNaN(item.GroupID)
        })
        responseJSON = translateData(responseJSON)
        nextState.groups = responseJSON
      }
      this.setState(nextState)
    })
  }
  getGroupDetails(GroupID, value) {
    this.setState({ fetchingGroupDetails: true, currGroupID: GroupID })
    GET('groups/' + GroupID, { GroupID: GroupID }).then(responseJSON => {
      let nextState = {
        groupDetails: {},
        fetchingGroupDetails: false
      }
      if (responseJSON && !responseJSON.error) {
        value && delete responseJSON.GroupID
        nextState.groupDetails = responseJSON
      }
      this.setupRules(nextState.groupDetails, this.state.datasets)
      this.setState(nextState)
    })
  }
  updateGroupField(field) {
    let name = field.target.name
    let value = strip_tags(field.target.value)
    let groupDetails = this.state.groupDetails
    groupDetails[name] = field.target.name === 'BrandID' ? Number(value) : value
    this.setState({ groupDetails: groupDetails })
  }
  saveGroup() {
    if (this.state.groupDetails.moduleName != '') {
      let rules = [...this.state.groupRules]
      for (let rule of this.state.groupRules) {
        if (!rule.ruleValue && !(rule.ruleValueLower && rule.ruleValueHigher)) {
          this.setState({ ruleValueEmpty: true })
          return
        }
        if (rule.ruleValueLower && rule.ruleValueHigher) {
          let newRule = { ...rule }
          rule.ruleOperator = 'LOWER'
          newRule.ruleOperator = 'HIGHER'
          if (rule.datacolumnName === 'timeInsert') {
            if (moment(rule.ruleValueLower).isAfter(moment(rule.ruleValueHigher))) {
              rule.ruleValue = rule.ruleValueLower
              newRule.ruleValue = rule.ruleValueHigher
            } else {
              rule.ruleValue = rule.ruleValueHigher
              newRule.ruleValue = rule.ruleValueLower
            }
          } else if (rule.datacolumnName === 'walletPointsEarned' || rule.datacolumnName === 'walletPointsSpent') {
            if (parseInt(rule.ruleValueLower) > parseInt(rule.ruleValueHigher)) {
              rule.ruleValue = rule.ruleValueLower
              newRule.ruleValue = rule.ruleValueHigher
            } else {
              rule.ruleValue = rule.ruleValueHigher
              newRule.ruleValue = rule.ruleValueLower
            }
          } else {
            rule.ruleValue = rule.ruleValueLower
            newRule.ruleValue = rule.ruleValueHigher
          }
          const index = rules.findIndex(
            r => r.datacolumnName === rule.datacolumnName && r.ruleOperator === rule.ruleOperator && r.ruleValue === rule.ruleValue
          )
          rules.splice(index + 1, 0, newRule)
        }
      }
      this.setState({ isSaving: true })
      this.state.groupDetails.rules = rules
      POST('groups/replace', this.state.groupDetails).then(responseJSON => {
        let nextState = {
          isSaving: false,
          groupDetails: {},
          groups: this.state.groups,
          showGroupSuccess: false,
          showGroupError: false
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.showGroupError = true
          } else {
            nextState.groupDetails = responseJSON
            this.setupRules(responseJSON, this.state.datasets)
            if (nextState.groups.length > 0) {
              let found = false
              for (let i in nextState.groups) {
                if (nextState.groups[i].GroupID == responseJSON.GroupID) {
                  nextState.groups[i] = responseJSON
                  found = true
                }
              }
              if (!found) {
                nextState.groups.push(responseJSON)
              }
            }
            nextState.showGroupSuccess = true
          }
        }
        this.setState(nextState)
      })
    }
  }
  deleteGroup(GroupID = null) {
    if (GroupID) {
      POST('groups/remove', { GroupID: GroupID }).then(() => {
        this.getGroups()
      })
    }
  }
  getElements(GroupID = null, BrandID) {
    this.setState({ fetchingElements: true })
    if (GroupID) {
      GET('groups/elements', { GroupID: GroupID, ...(BrandID && { BrandID: Number(BrandID) }) }).then(responseJSON => {
        let nextState = {
          elements: [],
          fetchingElements: false
        }
        if (responseJSON) {
          nextState.elements = responseJSON
        }
        this.setState(nextState)
      })
    }
  }
  previewGroup() {
    this.setState({ isPreview: true, fetchingPreview: true })
    let filter = { rules: this.state.groupRules }
    if (this.state.isAdminAccess) {
      filter.BrandID = this.state.groupDetails.BrandID
      if (this.state.groupDetails.moduleQuery) {
        filter.moduleQuery = this.state.groupDetails.moduleQuery
        delete filter.groupRules
      }
    }
    POST('groups/preview', filter).then(responseJSON => {
      let nextState = {
        previewElements: this.state.previewElements,
        fetchingPreview: false
      }
      if (responseJSON && !responseJSON.error) {
        nextState.previewElements = responseJSON
        this.setState(nextState)
      }
    })
  }
  changeGroupStatus(group, status) {
    let groupDetails = Object.assign(group)
    groupDetails.removed = status ? 1 : 0
    this.setState({ showGroupDeleteMessage: false })
    POST('groups/replace', groupDetails).then(responseJSON => {
      if (responseJSON.error) {
        this.setState({ showGroupDeleteMessage: true })
      } else {
        this.getGroups()
      }
    })
  }
  giveAccess(isAdminAccess) {
    this.setState({ isAdminAccess })
  }

  toggleQueryContent(e) {
    let groups = this.state.groups
    groups = groups.map(item => {
      if (item.GroupID === e.GroupID) {
        item.moduleQueryReadMore = !item.moduleQueryReadMore
        item.moduleQueryReadLess = !item.moduleQueryReadLess
      }
      return item
    })
    this.setState({ groups })
  }

  copyGroup(group, props) {
    props.history.push(props.location.pathname + '/new?copy=' + group.GroupID)
  }

  getBrands() {
    GET('schemas/allbrands').then(responseJSON => {
      let nextState = {
        brands: []
      }
      if (responseJSON && !responseJSON.error) {
        nextState.brands = _.sortBy(responseJSON, 'brandName')
      }
      this.setState(nextState)
    })
  }

  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
  toggleActiveGroup() {
    let value = !this.state.showActiveGroups ? 'active' : 'both'
    this.setState({ showActiveGroups: !this.state.showActiveGroups, groupFilterValue: value, showInActiveGroups: false })
  }
  toggleInActiveGroup() {
    let value = this.state.showInActiveGroups ? 'both' : 'inactive'
    this.setState({ showInActiveGroups: !this.state.showInActiveGroups, groupFilterValue: value, showActiveGroups: false })
  }
}
