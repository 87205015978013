import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Badge, Button, ButtonGroup } from 'reactstrap'
import styled from 'styled-components'

const StyledSpan = styled.span`
  white-space: nowrap !important;
`
const StyledAnchor = styled.a`
  cursor: pointer !important;
`

function PackageOverview() {
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(false)
  const [packagesFilterValue, setPackagesFilterValue] = useState('active')

  useEffect(() => {
    setLoading(true)
    getPackages()
  }, [])

  function getPackages() {
    Proxies.GET('productpackage/pool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setPackages(responseJSON)
      }
      setLoading(false)
    })
  }

  function RemovePackage(ProductPackageID) {
    Proxies.POST('productpackage/removeProductPackage', { ProductPackageID: Number(ProductPackageID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getPackages()
      }
    })
  }
  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Sampakning
            <ButtonGroup className='float-right'>
              <Button
                outline
                color='success'
                onClick={() => setPackagesFilterValue(packagesFilterValue === 'active' ? 'both' : 'active')}
                active={packagesFilterValue === 'active'}
              >
                Active
              </Button>
              <Button
                outline
                color='danger'
                onClick={() => setPackagesFilterValue(packagesFilterValue === 'inactive' ? 'both' : 'inactive')}
                active={packagesFilterValue === 'inactive'}
              >
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              fieldFunctions: {
                status: e => {
                  if (e.status === 'AVAILABLE') {
                    return <Badge color='primary'>{e.status}</Badge>
                  }
                  return <Badge>{e.status}</Badge>
                },
                products: e => {
                  if (e.products && e.products.length) {
                    const reducedProducts = e.products.reduce((acc, cur, idx) => {
                      acc.push(cur.elementTitle)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedProducts.length > 0) {
                      reducedProducts.pop()
                    }
                    return reducedProducts
                  }
                },
                basePrice: e => {
                  if (e.products && e.products.length) {
                    const reducedProducts = e.products.reduce((acc, cur, idx) => {
                      acc.push(cur.productPrice)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedProducts.length > 0) {
                      reducedProducts.pop()
                    }
                    return reducedProducts
                  }
                },
                baseMarkup: e => {
                  if (e.products && e.products.length) {
                    const reducedProducts = e.products.reduce((acc, cur, idx) => {
                      acc.push(cur.productPriceAdjust)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedProducts.length > 0) {
                      reducedProducts.pop()
                    }
                    return reducedProducts
                  }
                },
                supplier: e => {
                  if (e.products && e.products.length) {
                    const reducedProducts = e.products.reduce((acc, cur, idx) => {
                      acc.push(cur?.supplier?.supplierName)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedProducts.length > 0) {
                      reducedProducts.pop()
                    }
                    return reducedProducts
                  }
                },
                catalogs: e => {
                  if (e.catalogs && e.catalogs.length) {
                    const reducedCatalogs = e.catalogs.reduce((acc, cur, idx) => {
                      acc.push(cur.moduleName)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedCatalogs.length > 0) {
                      reducedCatalogs.pop()
                    }
                    return reducedCatalogs
                  }
                },
                tags: e => {
                  if (e.categories && e.categories.length) {
                    const reducedCategories = e.categories.reduce((acc, cur, idx) => {
                      if (cur.categoryTaxonomy === 'TAG') {
                        acc.push(cur.categoryName)
                        acc.push(<br key={idx} />)
                      }
                      return acc
                    }, [])
                    if (reducedCategories.length > 0) {
                      reducedCategories.pop()
                    }
                    return reducedCategories
                  }
                },
                categories: e => {
                  if (e.categories && e.categories.length) {
                    const reducedCategories = e.categories.reduce((acc, cur, idx) => {
                      if (cur.categoryTaxonomy === 'PRODUCT') {
                        acc.push(cur.categoryName)
                        acc.push(<br key={idx} />)
                      }
                      return acc
                    }, [])
                    if (reducedCategories.length > 0) {
                      reducedCategories.pop()
                    }
                    return reducedCategories
                  }
                },
                variations: e => {
                  if (e.products && e.products.length) {
                    let lengthOfVariation = 0
                    let reducedVariations = e.products.reduce((acc, cur, idx) => {
                      if (cur.variations && cur.variations.length) {
                        lengthOfVariation += cur.variations.length
                        if (idx <= 2 && idx !== e.products.length - 1) {
                          acc.push(cur.variations[0].variationTitle)
                          acc.push(<br key={idx} />)
                        } else if (idx === e.products.length - 1) {
                          acc.push(<StyledAnchor key={idx}>+{lengthOfVariation - 1} More</StyledAnchor>)
                        }
                      }
                      return acc
                    }, [])
                    return <StyledSpan>{reducedVariations}</StyledSpan>
                  }
                },
                variationIDs: e => {
                  if (e.products && e.products.length) {
                    let lengthOfVariation = 0
                    let reducedVariations = e.products.reduce((acc, cur, idx) => {
                      if (cur.variations && cur.variations.length) {
                        lengthOfVariation += cur.variations.length
                        if (idx <= 2 && idx !== e.products.length - 1) {
                          acc.push(cur.variations[0].variationUID)
                          acc.push(<br key={idx} />)
                        } else if (idx === e.products.length - 1) {
                          acc.push(<StyledAnchor key={idx}>+{lengthOfVariation - 1} More</StyledAnchor>)
                        }
                      }
                      return acc
                    }, [])
                    return <StyledSpan>{reducedVariations}</StyledSpan>
                  }
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Button tag={Link} to={'/packages/' + e.ProductPackageID} id={'edit' + e.ProductPackageID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      <Button
                        id={'delete' + e.ProductPackageID}
                        color='danger'
                        onClick={() => {
                          if (confirm('Are you sure you want to delete?')) {
                            RemovePackage(e.ProductPackageID)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              ProductPackageID: '#',
              title: 'Title',
              products: 'Products',
              supplier: 'Supplier',
              catalogs: 'Catalogs',
              categories: 'Categories',
              tags: 'Tags',
              basePrice: 'Base Price',
              baseMarkup: 'Base Markup',
              variations: 'Variations',
              variationIDs: 'Varenumre',
              status: 'Status'
            }}
            data={
              packages && packagesFilterValue !== 'both' && !!packages.length
                ? packages.filter(competition => competition.removed === (packagesFilterValue === 'inactive' ? 1 : 0))
                : packages
            }
          />
        )}
      </CollapsibleCard>
    </>
  )
}

export default PackageOverview
