import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  FormGroup,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip as Tooltip,
  Form,
  Row,
  Col,
  Label,
  Input
} from 'reactstrap'
import FormField from 'Components/formField'

import Proxies from 'Components/proxies'
const { GET, POST } = Proxies

export const SubCategories = () => {
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [showModalForm, setShowModalForm] = useState(false)
  const [selectedSubCategory, setSelectedSubCategory] = useState({})

  const fetchCategories = () => {
    GET('categories/adminPool').then(responseJSON => {
      const productCategories = responseJSON.filter(c => c.categoryTaxonomy && !!c.categoryName && c.categoryTaxonomy.toUpperCase() === 'PRODUCT')
      setCategories(productCategories.sort((a, b) => a.categoryName - b.categoryName))
    })
  }

  const getSubCategories = () => {
    GET('subcategories/adminPool').then(responseJSON => {
      setSubCategories(responseJSON)
    })
  }
  const deleteSubCategory = sc => {
    if (confirm(`Are you sure you want to delete the SubCategory - ${sc.subCategoryName}`)) {
      POST('SubCategories/delete', { SubCategoryID: sc.SubCategoryID }).then(() => getSubCategories())
    }
  }
  const showSubCategoryForm = (data = null) => {
    if (!data) return
    setSelectedSubCategory({ ...selectedSubCategory, ...data })
    setShowModalForm(true)
  }

  useEffect(fetchCategories, [])

  useEffect(() => {
    getSubCategories()
  }, [])

  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <strong>Sub-categories</strong>
            <Button onClick={() => setShowModalForm(true)}>Opret ny sub-category</Button>
          </div>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={subCategories}
          headers={{
            SubCategoryID: 'ID',
            subCategoryName: 'Name',
            imageURL: 'Image',
            linkedCategoryIDs: 'Categories'
          }}
          options={{
            fieldFunctions: {
              linkedCategoryIDs: sc => {
                const linkedCategoryIDs = sc.linkedCategoryIDs
                const categoriesArray = linkedCategoryIDs.map(cid => {
                  const category = categories.filter(c => c.CategoryID === cid)
                  return category && category.length ? category[0].categoryName : ''
                })
                return categoriesArray.join(', ')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.SubCategoryID}>Rediger</Tooltip>
                    <Button id={'edit' + e.SubCategoryID} onClick={() => showSubCategoryForm(e)}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.SubCategoryID}>Slet</Tooltip>
                    <Button id={'delete' + e.SubCategoryID} color='danger' onClick={() => deleteSubCategory(e)}>
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
      <Modal size='xl' centered isOpen={showModalForm} toggle={() => setShowModalForm(false)}>
        <ModalHeader toggle={() => setShowModalForm(false)}>
          {selectedSubCategory.SubCategoryID ? 'Edit sub-category' : 'New sub-category'}
        </ModalHeader>
        <ModalBody>
          <SubCategoryForm
            categories={categories}
            SubCategoryID={selectedSubCategory.SubCategoryID}
            close={(reload = false) => {
              setShowModalForm(false)
              if (reload) {
                getSubCategories()
                toast.success('Sub category creation/modification successful')
              }
            }}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export const SubCategoryForm = props => {
  const { SubCategoryID, close, categories } = props
  const emptyForm = {
    SubCategoryID: SubCategoryID || null,
    subCategoryName: '',
    imageURL: '',
    linkedCategoryIDs: [],
    translations: [
      {
        LanguageID: null,
        subCategoryName: ''
      }
    ]
  }
  const [subCategory, setSubCategory] = useState(emptyForm)
  const [languages, setLanguages] = useState([])

  const fetchSubCategoryDetail = () => {
    if (!SubCategoryID || isNaN(SubCategoryID)) return setSubCategory(emptyForm)
    GET('SubCategories/fetch', { SubCategoryID }).then(responseJSON => {
      responseJSON.translations =
        !!responseJSON.translations && !!responseJSON.translations.length
          ? responseJSON.translations
          : [
              {
                LanguageID: null,
                subCategoryName: ''
              }
            ]
      setSubCategory(responseJSON)
    })
  }

  const saveSubCategory = () => {
    if (!subCategory.SubCategoryID) delete subCategory.SubCategoryID
    POST('SubCategories/replace', subCategory)
      .then(responseJSON => {
        setSubCategory(responseJSON)
        if (close) return close(true)
      })
      .catch(() => {
        resetForm()
        toast.error('Sub category action failed')
      })
  }

  const uploadSubCategoryImage = data => {
    let file = data.target.files[0]
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON) {
        setSubCategory({ ...subCategory, imageURL: responseJSON.imageOriginSource ? responseJSON.imageOriginSource : '' })
      }
    })
  }

  const toggleLinkedCategory = category => {
    if (!category || !category.CategoryID) return
    let linkedCategoryIDs = subCategory.linkedCategoryIDs || []
    if (linkedCategoryIDs.includes(category.CategoryID)) {
      linkedCategoryIDs = linkedCategoryIDs.filter(cid => cid !== category.CategoryID)
    } else {
      linkedCategoryIDs.push(category.CategoryID)
    }
    setSubCategory({ ...subCategory, linkedCategoryIDs })
  }

  const resetForm = () => {
    setSubCategory(emptyForm)
    if (close) return close()
  }

  const getLanguages = () => {
    GET('brands/brand-languages', { BrandIDs: [9] }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setLanguages(responseJSON)
      }
    })
  }

  const removeTranslation = i => {
    let translations = subCategory.translations
    if (translations[i].SubCategoryTranslationID) {
      translations[i].removed = 1
      if (translations.length === 1) {
        translations.push({
          LanguageID: null,
          subCategoryName: ''
        })
      }
    } else {
      translations.splice(i, 1)
      if (!translations.length) {
        translations.push({
          LanguageID: null,
          subCategoryName: ''
        })
      } else if (translations.filter(translation => translation.SubCategoryTranslationID).length) {
        translations.push({
          LanguageID: null,
          subCategoryName: ''
        })
      }
    }
    setSubCategory({ ...subCategory, translations: translations })
  }

  const addTranslation = () => {
    let translations = subCategory.translations
    translations.push({ subCategoryName: '', LanguageID: null })
    setSubCategory({ ...subCategory, translations: translations })
  }

  const updateData = (field, index, type) => {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let subCategoryDetails = subCategory
    let column = subCategoryDetails[type]

    if (fieldName === 'LanguageID' && type === 'translations') {
      fieldValue = Number(fieldValue)
    }

    if (!column[index][fieldName]) {
      column[index][fieldName] = ''
    }

    column[index][fieldName] = fieldValue

    setSubCategory({ ...subCategory, [type]: column })
  }

  useEffect(() => {
    getLanguages()
  }, [])

  useEffect(fetchSubCategoryDetail, [SubCategoryID])

  return (
    <>
      <Form>
        <FormField
          required
          data={subCategory}
          field={'subCategoryName'}
          name='Name'
          onChange={e => {
            setSubCategory({ ...subCategory, subCategoryName: e.target.value })
          }}
        />
        <Row style={{ margin: '10px 0' }}>
          <Col xs={3} style={{ padding: 0 }}>
            Categories
          </Col>
          <Col xs={9}>
            <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
              {categories.map(c => (
                <>
                  <Button
                    onClick={() => toggleLinkedCategory(c)}
                    color={subCategory.linkedCategoryIDs.includes(c.CategoryID) ? 'secondary' : 'light'}
                  >
                    {c.categoryName}
                  </Button>
                </>
              ))}
            </div>
          </Col>
        </Row>
        <FormField
          name='Image'
          field='upload'
          type='file'
          data={{ upload: '' }}
          noValue={subCategory.imageURL !== ''}
          onChange={uploadSubCategoryImage}
          // disabled={isUploading}
          accept='image/*'
        />
        {!!subCategory.imageURL && (
          <div style={{ display: 'inline-flex', flexDirection: 'column', margin: '20px 0' }}>
            <img src={subCategory.imageURL} width={200} height={150} style={{ objectFit: 'contain', border: '0.5px solid #CCC' }} />
            <Button onClick={() => setSubCategory({ ...subCategory, imageURL: '' })}>Remove Image</Button>
          </div>
        )}
        <FormGroup row>
          <Label for='textColor' sm={3}>
            Translations
          </Label>
          <Col sm={9}>
            {!!subCategory &&
              !!subCategory.translations &&
              !!subCategory.translations.length &&
              subCategory.translations.map((translation, inx) => {
                return (
                  !translation.removed && (
                    <div key={inx}>
                      <Row>
                        <Col sm={11}>
                          <Input
                            type='select'
                            name='LanguageID'
                            value={translation.LanguageID ? translation.LanguageID : ''}
                            id='LanguageID'
                            onChange={e => updateData(e, inx, 'translations')}
                          >
                            <option value='' disabled>
                              Click to Select
                            </option>
                            {!!languages &&
                              !!languages.length &&
                              languages.map((e, i) => {
                                return (
                                  <option value={Number(e.LanguageID)} key={i}>
                                    {e.languageName}
                                  </option>
                                )
                              })}
                          </Input>
                        </Col>
                        <Col sm={1}>
                          <Button onClick={() => removeTranslation(inx)} color={'danger'}>
                            <FontAwesomeIcon icon={'minus'} />
                          </Button>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Label for='textColor' sm={2}>
                          Name
                        </Label>
                        <Col sm={9}>
                          <Input
                            name='subCategoryName'
                            value={translation.subCategoryName}
                            type='text'
                            onChange={e => updateData(e, inx, 'translations')}
                          />
                        </Col>
                        <Col sm={1}>
                          <Button onClick={addTranslation}>
                            <FontAwesomeIcon icon='plus' />
                          </Button>
                        </Col>
                      </Row>
                      <br></br>
                    </div>
                  )
                )
              })}
          </Col>
        </FormGroup>
        <Row>
          <Col>
            <Button onClick={saveSubCategory}>Gem</Button>
            <Button color='danger' onClick={resetForm} className='ml-2'>
              Nulstil
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
