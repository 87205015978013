import React from 'react'
import { Container } from 'reactstrap'
import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
  AppSidebarToggler
} from 'Components/coreui'
import { ToastContainer } from 'react-toastify'
import UserHeader from 'Components/userHeader.js'
import { generateMenu, generateRoutes } from 'Components/moduleGenerator'

class DefaultLayout extends React.Component {
  render() {
    let { children, settings, ...props } = this.props
    let routes = generateRoutes(settings)
    let navigation = generateMenu(settings)
    return (
      <div className='app'>
        <AppHeader fixed>
          <AppSidebarToggler className='d-lg-none' display='md' mobile />
          <AppSidebarToggler className='d-md-down-none' display='lg' />
          <UserHeader />
        </AppHeader>
        <div className='app-body'>
          <AppSidebar fixed display='lg' data-cy='sidebar.container'>
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className='main'>
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>{children}</Container>
          </main>
        </div>
        <AppFooter>
          <div />
        </AppFooter>
        <ToastContainer autoClose={false} />
      </div>
    )
  }
}

export default DefaultLayout
