import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { ClientActions, ClientStore, STATUS } from 'Stores/clientStore'
import ClientForm from 'Modules/client/clientForm'

export default class DatasetDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [ClientStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    ClientActions.getCompanies()
    ClientActions.getClientDetails(this.props.match.params.ClientID)
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_CLIENT_FAILED) {
      toast.error('FETCHING CLIENT FAILED', {
        autoClose: 7500,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast()
    }

    if (this.state.status === STATUS.CLIENT_FAILED) {
      toast.error('UPDATE CLIENT FAILED', {
        autoClose: 3000,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast()
    }
    if (this.state.status === STATUS.CLIENT_SUCCESS) {
      toast.success('UPDATE CLIENT SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          ClientActions.clearToast()
        }
      })
      ClientActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <ClientForm {...this.props} />
      </React.Fragment>
    )
  }
}
