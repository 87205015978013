import { createSlice } from '@reduxjs/toolkit'
import {
  fetchKamProductDetailsRequest,
  fetchKamProductDetailsSuccess,
  fetchKamProductDetailsFailure,
  STATUS,
  fetchKamProductDetailsInitialState,
  fetchKamCvrUsersRequest,
  fetchKamCvrUsersRequestSuccess,
  fetchKamCvrUsersRequestFailure,
  fetchKamCompanyProductsRequest,
  fetchKamCompanyProductsSuccess,
  fetchKamCompanyProductsFailure
} from './actions/kamCompanyActions'

const slice = createSlice({
  name: 'kamCompany',
  initialState: {
    status: null,
    productDetails: [],
    loadingStatus: null,
    kamCompanyDetailsUsers: [],
    productsLoadingStatus: null,
    kamCompanyDetailsProducts: []
  },
  reducers: {},
  extraReducers: {
    [fetchKamProductDetailsRequest]: state => {
      state.status = STATUS.LOADING_DATA
    },
    [fetchKamProductDetailsSuccess]: (state, action) => {
      state.productDetails = action.payload
      state.status = STATUS.LOADING_DATA_SUCCESS
    },
    [fetchKamProductDetailsFailure]: state => {
      state.status = STATUS.LOADING_DATA_FAILURE
    },
    [fetchKamProductDetailsInitialState]: state => {
      state.productDetails = []
      state.status = null
    },
    [fetchKamCvrUsersRequest]: state => {
      state.loadingStatus = STATUS.KAM_CVR_USERS_LOADING_DATA
    },
    [fetchKamCvrUsersRequestSuccess]: (state, action) => {
      state.kamCompanyDetailsUsers = action.payload
      state.loadingStatus = STATUS.KAM_CVR_USERS_LOADING_DATA_SUCCESS
    },
    [fetchKamCvrUsersRequestFailure]: state => {
      state.loadingStatus = STATUS.KAM_CVR_USERS_LOADING_DATA_FAILURE
    },
    [fetchKamCompanyProductsRequest]: state => {
      state.productsLoadingStatus = STATUS.KAM_COMPANY_PRODUCTS_LOADING
    },
    [fetchKamCompanyProductsSuccess]: (state, action) => {
      state.kamCompanyDetailsProducts = action.payload
      state.productsLoadingStatus = STATUS.KAM_COMPANY_PRODUCTS_LOADING_SUCCESS
    },
    [fetchKamCompanyProductsFailure]: state => {
      state.productsLoadingStatus = STATUS.KAM_COMPANY_PRODUCTS_LOADING_FAILURE
    }
  }
})

export const getKamProductDetails = state => state.kamCompany.productDetails
export const getStatus = state => state.kamCompany.status
export const getKamUserStatus = state => state.kamCompany.loadingStatus
export const getKamCompanyDetailsUsers = state => state.kamCompany.kamCompanyDetailsUsers

export default slice.reducer
