import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import 'react-image-crop/dist/ReactCrop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Proxies from 'Components/proxies'
import { Redirect } from 'react-router-dom'
import ShopForm from './shopForm'

const { POST } = Proxies

const Shops = props => {
  const [shops, setShops] = useState([
    {
      textColor: 'black'
    }
  ])
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [ShopBannerID, setShopBannerID] = useState(
    !!props.match.params && !isNaN(props.match.params.ShopBannerID) ? Number(props.match.params.ShopBannerID) : null
  )

  const resetForm = () => {
    setShops([{}])
  }

  const replaceShop = () => {
    setLoading(true)
    POST(`shopbanners/replace`, shops[0]).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('Shops updated successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(`BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another shop`, {
          autoClose: 7000
        })
      }
      setSuccess(true)
    })
  }

  const submitShop = () => {
    setLoading(true)
    POST(`shopbanners/saveMultiple`, shops).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('Shops created successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(`BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another shop`, {
          autoClose: 7000
        })
      }
      setSuccess(true)
    })
  }

  if (success) {
    return <Redirect to='/shops'></Redirect>
  }

  return (
    <>
      {shops.map((event, idx) => (
        <ShopForm
          key={idx}
          event={event}
          idx={idx}
          shops={shops}
          setShops={val => setShops(val)}
          ShopBannerID={ShopBannerID}
          setShopBannerID={setShopBannerID}
        />
      ))}
      <div style={{ marginBottom: '5%' }}>
        {!ShopBannerID && (
          <Button
            onClick={() => {
              setShops([...shops, { textColor: 'black' }])
            }}
          >
            + Add More
          </Button>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Button
          onClick={() => {
            ShopBannerID ? replaceShop() : submitShop()
          }}
        >
          {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button color='danger' onClick={() => resetForm()}>
          Nulstil
        </Button>
      </div>
    </>
  )
}

export default Shops
