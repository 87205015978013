import React, { useEffect, useState } from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { Component } from 'reflux'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import { Button, ButtonGroup, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { KamStore } from 'Stores/santanderKamStore'
import moment from 'moment'
import 'moment/locale/da'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchKamProductDetails, fetchKamUsersData, fetchKamCompanyProductsData, STATUS } from 'Reducers/actions/kamCompanyActions'
import { useDispatch, useSelector } from 'react-redux'

export default class KAMCompanyDetails extends Component {
  constructor(props) {
    super(props)
    this.store = KamStore
  }
  render() {
    return (
      <React.Fragment>
        <SantanderKAMCompanyDetails props={this.props} />
      </React.Fragment>
    )
  }
}

function SantanderKAMCompanyDetails(data) {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedSellerID, setSelectedSellerID] = useState()
  const dispatch = useDispatch()
  let productData = useSelector(state => state.kamCompany.productDetails)
  const loadingStatus = useSelector(state => state.kamCompany.loadingStatus)
  const loadingStatusProducts = useSelector(state => state.kamCompany.productsLoadingStatus)
  const kamCompanyDetailsUsers = useSelector(state => state.kamCompany.kamCompanyDetailsUsers)
  const kamCompanyDetailsProducts = useSelector(state => state.kamCompany.kamCompanyDetailsProducts)
  const [kamCompanyData] = useDashboardData('supervisors/admin-santanderCvr', { kamUID: data.props.match.params.kamUID })

  if (!data.props.match || !data.props.match.params) {
    return null
  }

  const clearDateFilter = () => {
    dispatch(fetchKamUsersData({ companyid: data.props.match.params.DealerID }))
    dispatch(fetchKamCompanyProductsData({ companyid: data.props.match.params.DealerID }))
  }

  useEffect(() => {
    clearDateFilter()
  }, [])

  function getProductData(cvr, productName, DealerID) {
    let postData = setAPIData({ productName, startDate, endDate }, DealerID, cvr)
    dispatch(fetchKamProductDetails(postData))
  }

  const setAPIData = (data, DealerID, cvr) => {
    let foundCompany = kamCompanyData.find(e => {
      return e.Dealer_ID === DealerID
    })
    if (data.startDate) {
      data.startdate = moment(data.startDate).format('YYYYMMDD')
    }
    if (data.endDate) {
      data.enddate = moment(data.endDate).format('YYYYMMDD')
    }
    if (foundCompany) {
      data.companyid = foundCompany.Dealer_ID
    } else {
      data.cvr = cvr
    }
    delete data.startDate
    delete data.endDate
    return data
  }
  const setStartDateFilter = (startDate, endDate) => {
    let postData = setAPIData({ startDate, endDate }, data.props.match.params.DealerID, data.props.match.params.CVR)
    dispatch(fetchKamUsersData(postData))
    dispatch(fetchKamCompanyProductsData(postData))
  }
  const setEndDateFilter = (startDate, endDate) => {
    let postData = setAPIData({ startDate, endDate }, data.props.match.params.DealerID, data.props.match.params.CVR)
    dispatch(fetchKamUsersData(postData))
    dispatch(fetchKamCompanyProductsData(postData))
  }
  let productDataModal = null
  if (productData && productData.length) {
    productDataModal = (
      <Modal
        isOpen
        toggle={() => {
          dispatch(fetchKamProductDetails())
        }}
        size='lg'
      >
        <ModalHeader
          toggle={() => {
            dispatch(fetchKamProductDetails())
          }}
        >
          Detaljer for : {productData[0].productName}
        </ModalHeader>
        <ModalBody>
          <DynamicTable
            data={productData}
            headers={{
              userName: 'Navn',
              antal: 'antal',
              fra: 'fra',
              til: 'til'
            }}
            options={{
              startSort: {
                // column: 'antal',
                dir: 'desc'
              },
              fieldExportFunctions: {
                fra: e => {
                  return moment(e.fra).format('DD/MM/YYYY')
                },
                til: e => {
                  return moment(e.til).format('DD/MM/YYYY')
                }
              },
              fieldStringFunctions: {
                fra: e => {
                  return moment(e.fra).format('x')
                },
                til: e => {
                  return moment(e.til).format('x')
                }
              },
              fieldFunctions: {
                fra: e => {
                  return moment(e.fra).format('DD/MM/YYYY')
                },
                til: e => {
                  return moment(e.til).format('DD/MM/YYYY')
                }
              }
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              dispatch(fetchKamProductDetails())
            }}
          >
            Luk
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
  return (
    <React.Fragment>
      {productDataModal}
      <div>{/* Detaljer for {foundCompany.Dealer_Navn}, {foundCompany.Dealer_adresse}, {foundCompany.Dealer_zip} {foundCompany.Dealer_by} */}</div>
      <CollapsibleCard header={<strong>Datofilter</strong>}>
        <Form
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
          inline
        >
          <FormGroup>
            <Label htmlFor='startDate'>Start</Label>
            <DatePicker
              isClearable
              placeholderText='Vælg en dato at filtrere fra'
              className='form-control'
              maxDate={endDate ? moment(endDate).add(-1, 'days') : moment().add(0, 'days')}
              selected={startDate ? moment(startDate) : null}
              dateFormat={'DD/MM/YYYY'}
              viewMode={'days'}
              name='startDate'
              id='startDate'
              onChange={date => {
                setStartDate(date)
                setStartDateFilter(date, endDate)
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor='endDate'>Slut</Label>
            <DatePicker
              isClearable
              placeholderText='Vælg en dato at filtrere til'
              className='form-control'
              minDate={moment(startDate).add(1, 'days')}
              maxDate={moment().add(0, 'days')}
              selected={endDate ? moment(endDate) : null}
              dateFormat={'DD/MM/YYYY'}
              viewMode={'days'}
              name='endDate'
              id='endDate'
              onChange={date => {
                setEndDate(date)
                setEndDateFilter(startDate, date)
              }}
            />
          </FormGroup>
          <Button
            onClick={() => {
              setStartDate()
              setEndDate()
              clearDateFilter()
            }}
          >
            Luk
          </Button>
        </Form>
      </CollapsibleCard>
      <CollapsibleCard header={<strong>Solgte produkter</strong>} startOpen>
        Viser solgte produkter for{' '}
        {selectedSellerID ? (
          <React.Fragment>
            {
              kamCompanyDetailsUsers.find(e => {
                return e.UserID === selectedSellerID
              }).userName
            }{' '}
            <Button
              onClick={() => {
                setSelectedSellerID()
              }}
            >
              Se hele virksomheden
            </Button>
          </React.Fragment>
        ) : (
          'hele virksomheden'
        )}
        {loadingStatusProducts === STATUS.KAM_COMPANY_PRODUCTS_LOADING && <FontAwesomeIcon icon='spinner' spin />}
        {loadingStatus === STATUS.KAM_CVR_USERS_LOADING_DATA_SUCCESS && (
          <DynamicTable
            data={
              kamCompanyDetailsProducts &&
              (selectedSellerID
                ? kamCompanyDetailsProducts.singleUser.filter(e => {
                    return e.UserID === selectedSellerID
                  })
                : kamCompanyDetailsProducts.wholeCompany)
            }
            headers={{
              productName: 'Produkt',
              antal: 'antal',
              fra: 'fra',
              til: 'til'
            }}
            options={{
              startSort: {
                // column: 'antal',
                dir: 'desc'
              },
              fieldExportFunctions: {
                fra: e => {
                  return moment(e.fra).format('DD/MM/YYYY')
                },
                til: e => {
                  return moment(e.til).format('DD/MM/YYYY')
                }
              },
              fieldStringFunctions: {
                fra: e => {
                  return moment(e.fra).format('x')
                },
                til: e => {
                  return moment(e.til).format('x')
                }
              },
              fieldFunctions: {
                fra: e => {
                  return moment(e.fra).format('DD/MM/YYYY')
                },
                til: e => {
                  return moment(e.til).format('DD/MM/YYYY')
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          getProductData(data.props.match.params.CVR, e.productName, data.props.match.params.DealerID)
                        }}
                      >
                        Detaljer
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
          />
        )}
      </CollapsibleCard>
      <CollapsibleCard header={<strong>Brugere</strong>}>
        <DynamicTable
          data={kamCompanyDetailsUsers}
          headers={{
            UserID: 'Bruger ID',
            userUID: 'Unikt ID',
            userName: 'Navn',
            userCompany: 'Virksomhed'
          }}
          options={{
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        setSelectedSellerID(e.UserID)
                      }}
                    >
                      Detaljer
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    </React.Fragment>
  )
}
