import Proxies from 'Components/proxies'
const { GET, PUT, wrapFilter } = Proxies
import {
  setCmsData,
  resetCmsData,
  resetShopCmsData,
  setBrandCmsData,
  setBrandShops,
  setShopProducts,
  cmsCreationFailure,
  setShopDetailsCmsData,
  cmsCreationSuccess,
  setShopCmsData,
  setHeaderConfigData,
  resetHeaderConfigData,
  setSavedHeaderConfigDetails,
  setClubCmsData,
  resetClubCmsData,
  setSavedClubCmsDetails
} from 'Reducers/cmsReducer'
import keyMirror from 'keymirror'
import { setShopCategories } from '../cmsReducer'

export const STATUS = keyMirror({
  CMS_SUCCESS: null,
  CMS_FAILED: null
})

export const fetchData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetCmsData())
        dispatch(setBrandCmsData({}))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setBrandCmsData(responseJSON[0]))
          dispatch(setCmsData(JSON.parse(responseJSON[0].customisationData).features))
        } else {
          dispatch(setCmsData({}))
          dispatch(setBrandCmsData({}))
        }
      }
    }
  })
}

export const fetchShops = () => dispatch => {
  GET('shops/pool').then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        // return console.error(responseJSON.error)
        return
      }
      const shops = responseJSON.filter(e => !isNaN(e.ShopID))
      if (shops.length) {
        dispatch(setBrandShops(shops.sort((a, b) => a.moduleName.localeCompare(b.moduleName))))
      }
    }
  })
}

export const fetchShopProducts = ShopID => dispatch => {
  if (!ShopID || isNaN(ShopID)) return
  GET(`shops/${ShopID}`).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) return

      const products = responseJSON.filter(e => !isNaN(e.ProductID))
      if (!products || !products.length) return
      const categories = []
      responseJSON.forEach(item => {
        if (!item.categories || !item.categories.length) return
        item.categories.forEach(category => {
          if (category.categoryTaxonomy !== 'PRODUCT') return
          categories.push(category)
        })
      })
      const uniqueCategories = [...new Map(categories.map(category => [category.CategoryID, category])).values()]
      dispatch(setShopCategories(uniqueCategories.sort((a, b) => a.categoryName.localeCompare(b.categoryName))))
      dispatch(setShopProducts(products))
    }
  })
}

export const fetchShopCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubShopContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetShopCmsData())
        dispatch(setShopCmsData({}))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setShopDetailsCmsData(responseJSON[0]))
          dispatch(setShopCmsData(JSON.parse(responseJSON[0].customisationData).features))
        } else {
          dispatch(setShopCmsData({}))
          dispatch(setShopDetailsCmsData({}))
        }
      }
    }
  })
}

export const fetchHeaderConfigDetails = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubHeaderConfiguration' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetHeaderConfigData())
        dispatch(setSavedHeaderConfigDetails({}))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedHeaderConfigDetails(responseJSON[0]))
          dispatch(setHeaderConfigData(JSON.parse(responseJSON[0].customisationData).features))
        } else {
          dispatch(resetHeaderConfigData({}))
          dispatch(setSavedHeaderConfigDetails({}))
        }
      }
    }
  })
}

export const saveData = (cmsData, settings, brandCmsData) => dispatch => {
  const baseData = {
    CustomisedID: settings.BrandID,
    customisedType: 'Brand',
    description: 'Upgrader club content for ' + settings.brandName,
    title: 'upgraderClubContentManagement',
    ...(brandCmsData && 'CustomisationID' in brandCmsData && { CustomisationID: brandCmsData.CustomisationID }),
    customisationData: JSON.stringify({ features: cmsData })
  }
  PUT('customisationv2', baseData).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetCmsData())
        dispatch(cmsCreationFailure())
      } else {
        dispatch(cmsCreationSuccess())
        if (responseJSON && Object.keys(responseJSON).length > 0) {
          dispatch(setBrandCmsData(responseJSON))
          dispatch(setCmsData(JSON.parse(responseJSON.customisationData).features))
        } else {
          dispatch(setCmsData({}))
        }
      }
    }
  })
}

export const saveShopBannerData = (cmsData, settings, shopCmsData) => dispatch => {
  const baseData = {
    CustomisedID: settings.BrandID,
    customisedType: 'Brand',
    description: 'Upgrader club Shop content for ' + settings.brandName,
    title: 'upgraderClubShopContentManagement',
    ...(shopCmsData && 'CustomisationID' in shopCmsData && { CustomisationID: shopCmsData.CustomisationID }),
    customisationData: JSON.stringify({ features: cmsData })
  }
  PUT('customisationv2', baseData).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetShopCmsData())
        dispatch(cmsCreationFailure())
      } else {
        dispatch(cmsCreationSuccess())
        if (responseJSON && Object.keys(responseJSON).length > 0) {
          dispatch(setShopDetailsCmsData(responseJSON))
          dispatch(setShopCmsData(JSON.parse(responseJSON.customisationData).features))
        } else {
          dispatch(setShopCmsData({}))
        }
      }
    }
  })
}

export const saveHeaderConfigData = (cmsData, settings, savedData) => dispatch => {
  const baseData = {
    CustomisedID: settings.BrandID,
    customisedType: 'Brand',
    description: `Upgraderclub header configuration ` + settings.brandName,
    title: `upgraderClubHeaderConfiguration`,
    ...(savedData && 'CustomisationID' in savedData && { CustomisationID: savedData.CustomisationID }),
    customisationData: JSON.stringify({ features: cmsData })
  }
  PUT('customisationv2', baseData).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetHeaderConfigData())
        dispatch(cmsCreationFailure({}))
      } else {
        dispatch(cmsCreationSuccess())
        if (responseJSON && Object.keys(responseJSON).length > 0) {
          dispatch(setSavedHeaderConfigDetails(responseJSON))
          dispatch(setHeaderConfigData(JSON.parse(responseJSON.customisationData).features))
        } else {
          dispatch(setHeaderConfigData({}))
        }
      }
    }
  })
}

export const fetchLoginPageCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubLoginPageContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'loginPageDetails' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedLoginPageDetails', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedLoginPageDetails', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'loginPageDetails', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

export const fetchFooterCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubFooterContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'footerDetails' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedFooterDetails', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedFooterDetails', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'footerDetails', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

export const fetchColorsCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubColorsContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'themeOverrides' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedthemeOverrides', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedthemeOverrides', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'themeOverrides', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

export const fetchhofCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubHallOfFameContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'hofDetails' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedhofDetails', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedhofDetails', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'hofDetails', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

export const fetchCountDownCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubCountDownContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'countDownDetails' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedCountDownDetails', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedCountDownDetails', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'countDownDetails', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

export const fetchProfileCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubProfileContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'profileDetails' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedProfileDetails', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedProfileDetails', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'profileDetails', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

export const fetchPersonalShopperCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubPersonal ShopperContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'personalShopperDetails' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedpersonalShopperDetails', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedpersonalShopperDetails', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'personalShopperDetails', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

export const fetchWishlistCustomisationData = BrandID => dispatch => {
  let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'upgraderClubWishlistContentManagement' }
  GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetClubCmsData({ fieldName: 'wishlistDetails' }))
        dispatch(setSavedClubCmsDetails({ fieldName: 'savedWishlistDetails', response: {} }))
      } else {
        if (responseJSON.length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: 'savedWishlistDetails', response: responseJSON[0] }))
          dispatch(setClubCmsData({ fieldName: 'wishlistDetails', response: JSON.parse(responseJSON[0].customisationData).features }))
        } else {
          dispatch(setClubCmsData({}))
          dispatch(setSavedClubCmsDetails({}))
        }
      }
    }
  })
}

const getFieldNames = title => {
  switch (title) {
    case 'LoginPage':
      return {
        fieldName: 'loginPageDetails',
        savedDetailsFieldName: 'savedLoginPageDetails'
      }
    case 'Footer':
      return {
        fieldName: 'footerDetails',
        savedDetailsFieldName: 'savedFooterDetails'
      }
    case 'Colors':
      return {
        fieldName: 'themeOverrides',
        savedDetailsFieldName: 'savedthemeOverrides'
      }
    case 'Profile':
      return {
        fieldName: 'profileDetails',
        savedDetailsFieldName: 'savedProfileDetails'
      }
    case 'Personal Shopper':
      return {
        fieldName: 'personalShopperDetails',
        savedDetailsFieldName: 'savedpersonalShopperDetails'
      }
    case 'Wishlist':
      return {
        fieldName: 'wishlistDetails',
        savedDetailsFieldName: 'savedWishlistDetails'
      }
  }
}

export const saveClubCMSData = (data, settings, savedCmsData, title) => dispatch => {
  const baseData = {
    CustomisedID: settings.BrandID,
    customisedType: 'Brand',
    description: `Upgrader club ${title ? title : ''} content for ` + settings.brandName,
    title: `upgraderClub${title ? title : ''}ContentManagement`,
    ...(savedCmsData && 'CustomisationID' in savedCmsData && { CustomisationID: savedCmsData.CustomisationID }),
    customisationData: JSON.stringify({ features: data })
  }
  PUT('customisationv2', baseData).then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(cmsCreationFailure({}))
      } else {
        dispatch(cmsCreationSuccess())
        if (responseJSON && Object.keys(responseJSON).length > 0) {
          dispatch(setSavedClubCmsDetails({ fieldName: getFieldNames(title).savedDetailsFieldName, response: responseJSON }))
          dispatch(setClubCmsData({ fieldName: getFieldNames(title).fieldName, response: JSON.parse(responseJSON.customisationData).features }))
        } else {
          dispatch(setClubCmsData({ fieldName: getFieldNames(title).fieldName, response: {} }))
        }
      }
    }
  })
}
