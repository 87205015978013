import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { HofActions, HofStore, STATE } from 'Stores/hofStore'
import HOFForm from 'Modules/hallOfFame/hofForm'
import _ from 'lodash'

export default class HOFCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [HofStore]
    this.storeKeys = ['status', 'hofDetails']
  }
  componentDidMount() {
    if (_.get(this.props, 'location.search')) {
      const search = this.props.location.search.split('=')
      HofActions.getHOFDetails(Number(search[1]), true)
    } else {
      HofActions.createEmptyHOF()
    }
  }
  componentDidUpdate() {
    if (this.state.status === STATE.HOF_SUCCESS) {
      toast.success('Successfully Created', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast(STATE.IS_REDIRECT)
    }
    if (this.state.status === STATE.HOF_FAILED) {
      toast.error('Error while saving HOF', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <HOFForm {...this.props} />
      </React.Fragment>
    )
  }
}
