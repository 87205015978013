import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { MenuActions, MenuStore, STATUS } from 'Stores/menuStore'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class MenuList extends Component {
  constructor(props) {
    super(props)
    this.stores = [MenuStore]
    this.storeKeys = ['status', 'menus', 'menuFilterValue', 'showActiveMenu', 'showInactiveMenu']
  }

  componentDidMount() {
    MenuActions.getMenus()
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_MENUS_FAILED) {
      toast.error('FETCHING MENUS FAILED', {
        autoClose: 7500,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }

    if (this.state.status === STATUS.DELETE_MENU_FAILED) {
      toast.error('DISABLING MENU FAILED', {
        autoClose: 7500,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }

    if (this.state.status === STATUS.ENABLE_MENU_FAILED) {
      toast.error('ENABLING MENU FAILED', {
        autoClose: 7500,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }

    if (this.state.status === STATUS.DELETE_MENU_SUCCESS) {
      toast.success('MENU DEACTIVATED', {
        autoClose: 7500,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }

    if (this.state.status === STATUS.ENABLE_MENU_SUCCESS) {
      toast.success('MENU ACTIVATED', {
        autoClose: 7500,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_MENUS) {
      ;<FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Menus{' '}
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={MenuActions.toggleActiveMenu} active={this.state.showActiveMenu}>
                Active
              </Button>
              <Button outline color='danger' onClick={MenuActions.toggleInActiveMenu} active={this.state.showInActiveMenu}>
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={
            this.state.menus && this.state.menuFilterValue !== 'both' && !!this.state.menus.length
              ? this.state.menus.filter(menu => menu.removed === (this.state.menuFilterValue === 'inactive' ? 1 : 0))
              : this.state.menus
          }
          headers={{
            MenuID: 'Menu ID',
            BrandID: 'Brand ID',
            poolName: 'Pool Name',
            menuTitle: 'Title',
            poolOrder: 'Order',
            timeStart: 'Start',
            timeEnd: 'End'
          }}
          options={{
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
              },
              timeStart: e => {
                return e.timeStart ? moment(e.timeStart).format('DD-MM-YYYY') : ''
              },
              timeEnd: e => {
                return e.timeEnd ? moment(e.timeEnd).format('DD-MM-YYYY') : ''
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              },
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.MenuID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/menu/' + e.MenuID} id={'edit' + e.MenuID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    {e.removed === 0 && (
                      <React.Fragment>
                        <Tooltip target={'delete' + e.MenuID}>Deaktivér</Tooltip>
                        <Button
                          id={'delete' + e.MenuID}
                          onClick={() => {
                            if (confirm('Vil du deaktivere denne menu?')) {
                              MenuActions.deleteMenu(e.MenuID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-check' />
                        </Button>
                      </React.Fragment>
                    )}
                    {e.removed === 1 && (
                      <React.Fragment>
                        <Tooltip target={'enable' + e.MenuID}>Aktivér</Tooltip>
                        <Button
                          color='danger'
                          id={'enable' + e.MenuID}
                          onClick={() => {
                            if (confirm('Vil du aktivere denne menu?')) {
                              MenuActions.enableMenu(e.MenuID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-slash' />
                        </Button>
                      </React.Fragment>
                    )}
                    <Tooltip target={'copy' + e.MenuID}>Kopier</Tooltip>
                    <Button
                      id={'copy' + e.MenuID}
                      onClick={() => {
                        MenuActions.copyMenu(e, this.props)
                      }}
                    >
                      <FontAwesomeIcon icon='copy' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
