/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import moment from 'moment'
import Proxies from 'Components/proxies'
import _ from 'lodash'
import 'moment/locale/da'
import { decodeHtmlEntities } from 'Util/decodeHtmlEntities'

export const KamActions = Reflux.createActions([
  'getCompanies',
  'getCvrData',
  'getCvrUsers',
  'getProductData',
  'clearProductData',
  'setStartDate',
  'setEndDate',
  'preserveSearch',
  'getUsersData',
  'clearToast',
  'getAllKamUsers',
  'getKamCompanies',
  'pagination',
  'getAllKamCompanyUsers',
  'getCompanyUser',
  'getMercheandiseShopBudget',
  'getKamWalletTransactions',
  'getMercheandiseProductCategories',
  'getCurrentDate',
  'getKamDetails',
  'getTotalKamDetails',
  'getMerchendaiseProducts',
  'getCategoryDetails',
  'getProductDetails'
])

export class KamStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = KamActions
    this.state = {
      isGettingData: false,
      companies: [],
      users: [],
      details: { wholeCompany: [], singleUser: [] },
      productData: [],
      err: null,
      startDate: null,
      endDate: null,
      currentCvr: undefined,
      filterText: '',
      kamUsers: [],
      activeUsers: [],
      inActiveUsers: [],
      kamCompanies: [],
      longestName: 0,
      currentPage: 0,
      pagesCount: 0,
      startIndex: 0,
      endIndex: 14,
      loadingState: false,
      viewMode: '',
      budgetData: [],
      currentMonth: '',
      currentYear: '',
      budgetKeys: [],
      spentKeys: [],
      budgetSpent: [],
      remainingBudget: [],
      kamWalletTransactions: [],
      kamProductCategories: [],
      currentQuarter: '',
      loadingCategory: false,
      showUserIDError: false,
      kamUsersList: [],
      kamProducts: [],
      selectedKam: '',
      productGraphKeys: [],
      loadingCompany: false,
      loadingProducts: false,
      kamCategoryDetails: [],
      kamProductDetails: []
    }
  }
  getCompanies(callback) {
    this.setState({ isGettingData: true, err: null })
    Proxies.GET('supervisors/santanderCvr')
      .then(companies => {
        if (companies) {
          this.setState({ companies, isGettingData: false })
          if (callback) {
            callback()
          }
        }
      })
      .catch(err => {
        this.setState({ isGettingData: false, err })
      })
  }
  getCvrUsers(cvr, DealerID) {
    if (!this.state.companies.length) {
      this.getCompanies(() => {
        this.getCvrUsers(cvr, DealerID)
      })
      return false
    }
    let foundCompany = this.state.companies.find(e => {
      return e.Dealer_ID == DealerID
    })
    const { startDate, endDate } = this.state
    this.setState({ isGettingData: true, err: null })
    if (foundCompany) {
      Proxies.GET('supervisors/santanderCvrUsers', {
        companyid: foundCompany.Dealer_ID,
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
      })
        .then(users => {
          if (users) {
            this.setState({ users, isGettingData: false })
          }
        })
        .catch(err => {
          this.setState({ isGettingData: false, err })
        })
    } else {
      Proxies.GET('supervisors/santanderCvrUsers', { cvr })
        .then(users => {
          if (users) {
            this.setState({ users, isGettingData: false })
          }
        })
        .catch(err => {
          this.setState({ isGettingData: false, err })
        })
    }
  }
  getCvrData(cvr, DealerID) {
    if (!this.state.companies.length) {
      this.getCompanies(() => {
        this.getCvrData(cvr, DealerID)
      })
      return false
    }
    if (!cvr && this.state.currentCvr) {
      cvr = this.state.currentCvr
    }
    let foundCompany = this.state.companies.find(e => {
      return e.Dealer_ID == DealerID
    })
    this.setState({ isGettingData: true, err: null, currentCvr: cvr })
    let postData = {}
    if (this.state.startDate) {
      postData.startdate = moment(this.state.startDate).format('YYYYMMDD')
    }
    if (this.state.endDate) {
      postData.enddate = moment(this.state.endDate).format('YYYYMMDD')
    }
    if (foundCompany) {
      postData.companyid = foundCompany.Dealer_ID
    } else {
      postData.cvr = cvr
    }
    Proxies.GET('supervisors/santanderProducts', postData)
      .then(details => {
        if (details) {
          this.setState({ details, isGettingData: false })
        }
      })
      .catch(err => {
        this.setState({ isGettingData: false, err })
      })
  }
  getProductData(cvr, productName, DealerID) {
    if (!this.state.companies.length) {
      this.getCompanies(() => {
        this.getCvrData(cvr, DealerID)
      })
      return false
    }
    let foundCompany = this.state.companies.find(e => {
      return e.Dealer_ID == DealerID
    })
    this.setState({ isGettingData: true, err: null })
    let postData = {
      productName
    }
    if (this.state.startDate) {
      postData.startdate = moment(this.state.startDate).format('YYYYMMDD')
    }
    if (this.state.endDate) {
      postData.enddate = moment(this.state.endDate).format('YYYYMMDD')
    }
    if (foundCompany) {
      postData.companyid = foundCompany.Dealer_ID
    } else {
      postData.cvr = cvr
    }
    Proxies.GET('supervisors/santanderProductDetails', postData)
      .then(productData => {
        if (productData) {
          this.setState({ productData, isGettingData: false })
        }
      })
      .catch(err => {
        this.setState({ isGettingData: false, err })
      })
  }
  clearProductData() {
    let productData = []
    this.setState({ productData })
  }
  setStartDate(cvr, startDate = null, DealerID) {
    this.setState({ startDate })
    this.getCvrData(cvr, DealerID)
    this.getCvrUsers(cvr, DealerID)
  }
  setEndDate(cvr, endDate = null, DealerID) {
    this.setState({ endDate })
    this.getCvrData(cvr, DealerID)
    this.getCvrUsers(cvr, DealerID)
  }
  preserveSearch(filterText) {
    this.setState({ filterText: filterText })
  }
  getUsersData(user) {
    this.setState({ isGettingData: true, err: null })
    Proxies.GET('reports/active-inactive-users-list-kam', { KAM_UID: user })
      .then(response => {
        if (response.error) {
          this.setState({ isGettingData: false, err: response.error })
        } else {
          this.setState({ viewMode: 'single', activeUsers: response.active, inActiveUsers: response.inactive, isGettingData: false })
        }
      })
      .catch(err => {
        this.setState({ viewMode: 'single', isGettingData: false, err })
      })
  }

  clearToast(data) {
    data ? this.setState(data) : this.setState({ err: null })
  }

  getAllKamUsers() {
    this.setState({ isGettingData: true, err: null })
    Proxies.GET('reports/active-inactive-users-list-all-kam')
      .then(response => {
        if (response.error) {
          this.setState({ isGettingData: false, err: response.error })
        } else {
          this.setState({ viewMode: 'all', kamUsers: response, isGettingData: false })
        }
      })
      .catch(err => {
        this.setState({ iviewMode: 'all', sGettingData: false, err })
      })
  }

  getKamCompanies() {
    let kamCompanies = []
    this.setState({ loadingCompany: 'LOADING', err: null })
    Proxies.GET('supervisors/active-inactive-users-company')
      .then(response => {
        if (response.error) {
          this.setState({ loadingCompany: 'FAILED', err: response.error })
        } else {
          Object.keys(response).map(item => {
            const company = item.length > 30 ? `${item.substring(0, 30)}...` : item
            kamCompanies.push({
              company: company,
              'Aktive Brugere': response[item].active,
              'Inaktive Brugere': response[item].inactive,
              companyId: response[item].userDepartmentUID
            })
          })
          kamCompanies = this.removeDuplicates(kamCompanies, 'company')
          this.setState({ loadingCompany: 'SUCCESS', kamCompanies: _.sortBy(kamCompanies, 'company') })
        }
      })
      .catch(err => {
        this.setState({ loadingCompany: 'FAILED', err })
      })
  }

  removeDuplicates(data, property) {
    const uniqueData = _.uniqBy(data, property)
    const records = uniqueData.length !== data.length && _.differenceWith(data, uniqueData, _.isEqual)
    if (records && records.length) {
      records.map(item => {
        item.company = `${item[property]}..`
        uniqueData.push(item)
      })
    }
    return uniqueData
  }

  pagination(e, index) {
    e.preventDefault()
    let startIndex = index !== 0 ? index * 14 : 0
    let endIndex = startIndex + 14
    this.setState({
      startIndex,
      endIndex,
      currentPage: index
    })
  }

  getAllKamCompanyUsers(kamName) {
    this.setState({ isGettingData: true, err: null })
    Proxies.GET('supervisors/active-inactive-users-list-company')
      .then(response => {
        if (response.error) {
          this.setState({ isGettingData: false, err: response.error })
        } else {
          response && response && response.map(item => (item.userSupervisor = kamName))
          this.setState({ viewMode: 'all', kamUsers: response, isGettingData: false })
        }
      })
      .catch(err => {
        this.setState({ viewMode: 'all', isGettingData: false, err })
      })
  }

  getCompanyUser(companyId, kamName) {
    this.setState({ isGettingData: true, err: null })
    Proxies.GET('supervisors/active-inactive-users-list-company', { companyid: companyId })
      .then(response => {
        if (response.error) {
          this.setState({ isGettingData: false, err: response.error })
        } else {
          response && response.active && response.active.map(item => (item.userSupervisor = kamName))
          response && response.inactive && response.inactive.map(item => (item.userSupervisor = kamName))
          this.setState({ viewMode: 'single', activeUsers: response.active, inActiveUsers: response.inactive, isGettingData: false })
        }
      })
      .catch(err => {
        this.setState({ viewMode: 'single', isGettingData: false, err })
      })
  }

  getMercheandiseShopBudget() {
    this.setState({ loadingState: 'LOADING', err: null })
    Proxies.GET('reports/merchandise-shop-budget-all-kams')
      .then(response => {
        if (response.error) {
          this.setState({ loadingState: 'FAILED' })
        } else {
          let budgetData = this.getKamBudgetDetails(response)
          let bugetSpent = this.getKamSpentDetails(response)
          let kamUsersList = Object.keys(response).map(item => {
            return { user: response[item].userName, KAM_UID: response[item].userUID }
          })
          let remainingBudget = this.getKamRemaningPoints(response)
          budgetData.data = this.removeDuplicates(budgetData.data, 'user')
          bugetSpent.data = this.removeDuplicates(bugetSpent.data, 'user')
          let nextState = {
            kamUsersList: _.sortBy(kamUsersList, 'user'),
            loadingState: 'SUCCESS',
            budgetKeys: budgetData.keys,
            budgetData: budgetData.data,
            budgetSpent: bugetSpent.data,
            spentKeys: bugetSpent.keys,
            remainingBudget
          }
          this.setState(nextState)
        }
      })
      .catch(err => {
        this.setState({ loadingState: 'FAILED', err })
      })
  }

  getKamBudgetDetails(data) {
    let budgetData = []
    let keys = []
    data &&
      Object.keys(data).map(item => {
        const userName = data[item].userName.length > 36 ? `${data[item].userName.substring(0, 36)}...` : data[item].userName
        var obj = {}
        if (data[item].totalBudget) {
          keys.push('Total')
          obj.Total = data[item].totalBudget
        }
        if (data[item].yearlyBudget) {
          keys.push(this.state.currentYear)
          obj[this.state.currentYear] = data[item].yearlyBudget
        }
        if (data[item].monthlyBudget) {
          keys.push(this.state.currentMonth)
          obj[this.state.currentMonth] = data[item].monthlyBudget
        }
        if (Object.keys(obj).length) {
          obj.user = userName
          obj.KAM_UID = data[item].userUID
          budgetData.push(obj)
        }
      })
    keys = _.uniq(keys)
    budgetData = _.sortBy(budgetData, ['user', 'Total', this.state.currentMonth, this.state.currentYear])
    return { data: budgetData, keys }
  }

  getKamSpentDetails(data) {
    let budgetSpent = []
    let keys = []
    data &&
      Object.keys(data).map(item => {
        const userName = data[item].userName.length > 36 ? `${data[item].userName.substring(0, 36)}...` : data[item].userName
        var obj = {}
        if (data[item].spentBudget) {
          keys.push('Total')
          obj.Total = data[item].spentBudget
        }
        if (data[item].yearlySpent) {
          keys.push(this.state.currentYear)
          obj[this.state.currentYear] = data[item].yearlySpent
        }
        if (data[item].monthlySpent) {
          keys.push(this.state.currentMonth)
          obj[this.state.currentMonth] = data[item].monthlySpent
        }
        if (Object.keys(obj).length) {
          obj.user = userName
          obj.KAM_UID = data[item].userUID
          budgetSpent.push(obj)
        }
      })
    keys = _.uniq(keys)
    budgetSpent = _.sortBy(budgetSpent, ['user', 'Total', this.state.currentMonth, this.state.currentYear])
    return { data: budgetSpent, keys }
  }

  getKamRemaningPoints(data) {
    let result = []
    Object.keys(data).map(item => {
      if (data[item].remainingBudget > 0) {
        const userName = data[item].userName.length > 36 ? `${data[item].userName.substring(0, 36)}...` : data[item].userName
        result.push({
          id: _.capitalize(data[item].userName),
          user: _.capitalize(userName),
          'Ubrugte Point': data[item].remainingBudget,
          KAM_UID: data[item].userUID
        })
      }
    })
    result = this.removeDuplicates(result, 'user')
    return _.sortBy(result, 'id')
  }

  getKamWalletTransactions(KAM_UID) {
    this.setState({ isGettingData: true, err: null, viewMode: 'kamTransactions' })
    Proxies.GET('reports/merchandise-shop-transactions', KAM_UID && KAM_UID).then(response => {
      if (response.error) {
        this.setState({ isGettingData: false, err: true })
      } else {
        this.setState({ isGettingData: false, kamWalletTransactions: _.sortBy(response, 'userName') })
      }
    })
  }

  getMercheandiseProductCategories() {
    this.setState({ loadingCategory: 'LOADING' })
    Proxies.GET('reports/merchandise-shop-catalog-count')
      .then(response => {
        if (response.error) {
          this.setState({ loadingCategory: 'FAILED', err: true })
        } else {
          let kamProductCategories = Object.keys(response).map(item => {
            const catalog = item.length > 36 ? `${item.substring(0, 36)}...` : item
            return {
              product: catalog,
              ...(response[item].totalCount && { Total: response[item].totalCount }),
              ...(response[item].yearCount && { [this.state.currentYear]: response[item].yearCount }),
              ...(response[item].monthCount && { [this.state.currentMonth]: response[item].monthCount }),
              ...(response[item].quarterCount && { [this.state.currentQuarter]: response[item].quarterCount })
            }
          })
          kamProductCategories = this.removeDuplicates(kamProductCategories, 'product')
          this.setState({ loadingCategory: 'SUCCESS', kamProductCategories: _.sortBy(kamProductCategories, 'product') })
        }
      })
      .catch(err => {
        this.setState({ loadingState: 'FAILED', err })
      })
  }

  getCurrentDate() {
    const currentYear = moment()
      .format('YYYY')
      .toUpperCase()
    const currentMonth = moment()
      .format('MMM')
      .toUpperCase()
    const Quarter = Math.floor(moment().month() / 3) + 1
    const currentQuarter = 'Q' + Quarter
    this.setState({ currentYear, currentMonth, currentQuarter })
  }

  getKamDetails(event, kam) {
    event.stopPropagation()
    const name = decodeHtmlEntities(event.target.innerHTML)
    let data = kam && kam.length ? kam.filter(item => item.user === name)[0] : kam.data
    const kamName = data && data.user && data.user.replace(/ /g, '-')
    data.KAM_UID ? window.open(`/dashboard/${data.KAM_UID}?user=${kamName}`, '_blank') : this.setState({ showUserIDError: true })
  }

  getTotalKamDetails(event) {
    event.preventDefault()
    window.open(`/dashboard/all-kam-users-wallet-transactions`, '_blank')
  }

  getMerchendaiseProducts(KAM) {
    let keys = []
    let kamProducts = []
    this.setState({ selectedKam: KAM, loadingProducts: 'LOADING', kamProducts: [], showUserIDError: false })
    KAM.KAM_UID
      ? Proxies.GET('reports/merchandise-shop-product-count-kam', { KAM_UID: KAM.KAM_UID }).then(response => {
          if (response.error) {
            this.setState({ loadingProducts: 'FAILED', err: true })
          } else {
            Object.keys(response).map(item => {
              const product =
                response[item].elementTitle.length > 36 ? `${response[item].elementTitle.substring(0, 36)}...` : response[item].elementTitle
              var obj = {}
              if (response[item].monthCount > 0) {
                keys.push(this.state.currentMonth)
                obj[this.state.currentMonth] = response[item].monthCount
              }
              if (response[item].quarterCount > 0) {
                keys.push(this.state.currentQuarter)
                obj[this.state.currentQuarter] = response[item].quarterCount
              }
              if (response[item].yearCount > 0) {
                keys.push(this.state.currentYear)
                obj[this.state.currentYear] = response[item].yearCount
              }

              if (Object.keys(obj).length) {
                obj.product = product
                obj.productID = item
                kamProducts.push(obj)
              }
            })
            kamProducts = this.removeDuplicates(kamProducts, 'product')
            this.setState({ productKeys: _.uniq(keys), loadingProducts: 'SUCCESS', kamProducts: _.sortBy(kamProducts, 'product') })
          }
        })
      : this.setState({ showUserIDError: true, loadingProducts: 'FAILED' })
  }

  getCategoryDetails(KAM_UID) {
    this.setState({ isGettingData: true, viewMode: 'kamCategoryDetails' })
    Proxies.GET('reports/merchandise-shop-product-details-kam', KAM_UID && KAM_UID)
      .then(response => {
        if (response.error) {
          this.setState({ isGettingData: false, err: true })
        } else {
          this.setState({ isGettingData: false, kamCategoryDetails: _.sortBy(response, 'category') })
        }
      })
      .catch(err => {
        this.setState({ isGettingData: false, err })
      })
  }

  getProductDetails(KAM_UID) {
    this.setState({ isGettingData: true, viewMode: 'kamProductDetails' })
    Proxies.GET('reports/merchandise-shop-product-order-details-kam', KAM_UID && KAM_UID)
      .then(response => {
        if (response.error) {
          this.setState({ isGettingData: false, err: true })
        } else {
          response = response.map(item => {
            item.shippedDate = `${item.ShippedDateDay}${item.ShippedDateMonth ? '/' + item.ShippedDateMonth : ''}${
              item.ShippedDateYear ? '/' + item.ShippedDateYear : ''
            }`
            item.orderDate = `${item.orderDateDay}${item.orderDateMonth ? '/' + item.orderDateMonth : ''}${
              item.orderDateYear ? '/' + item.orderDateYear : ''
            }`
            return item
          })
          this.setState({ isGettingData: false, kamProductDetails: response })
        }
      })
      .catch(err => {
        this.setState({ isGettingData: false, err })
      })
  }
}
