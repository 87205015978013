import React, { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'

export default function ColorsOverview() {
  const [colors, setColors] = useState([])
  const [loading, setLoading] = useState(false)
  const [colorFilterValue, setColorFilterValue] = useState('active')

  useEffect(() => {
    setLoading(true)
    getColors()
  }, [])

  function getColors() {
    Proxies.GET('colors/admin-pool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setColors(responseJSON)
      }
      setLoading(false)
    })
  }
  function deleteColor(color) {
    setLoading(true)
    Proxies.POST('colors/remove', color).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getColors()
      }
      setLoading(false)
    })
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Colors
          <ButtonGroup className='float-right'>
            <Button
              outline
              color='success'
              onClick={() => setColorFilterValue(colorFilterValue === 'active' ? 'both' : 'active')}
              active={colorFilterValue === 'active'}
            >
              Active
            </Button>
            <Button
              outline
              color='danger'
              onClick={() => setColorFilterValue(colorFilterValue === 'inactive' ? 'both' : 'inactive')}
              active={colorFilterValue === 'inactive'}
            >
              In-Active
            </Button>
          </ButtonGroup>
        </strong>
      }
    >
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'name'
            },
            fieldFunctions: {},
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.ColorID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/colors/' + e.ColorID} id={'edit' + e.ColorID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    {e.removed === 0 && (
                      <React.Fragment>
                        <Tooltip target={'deactivate' + e.ColorID}>Deaktivér</Tooltip>
                        <Button
                          id={'deactivate' + e.ColorID}
                          onClick={() => {
                            if (confirm('Vil du deaktivere denne Color?')) {
                              deleteColor({ ColorID: e.ColorID, removed: 1 })
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-check' />
                        </Button>
                      </React.Fragment>
                    )}
                    {e.removed === 1 && (
                      <React.Fragment>
                        <Tooltip target={'actiavte' + e.ColorID}>Aktivér</Tooltip>
                        <Button
                          id={'actiavte' + e.ColorID}
                          color='danger'
                          onClick={() => {
                            if (confirm('Vil du aktivere denne Color?')) {
                              deleteColor({ ColorID: e.ColorID, removed: 0 })
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-slash' />
                        </Button>
                      </React.Fragment>
                    )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            ColorID: '#',
            name: 'Name',
            hexCode: 'Hex'
          }}
          data={
            colors && colorFilterValue !== 'both' && !!colors.length
              ? colors.filter(color => color.removed === (colorFilterValue === 'inactive' ? 1 : 0))
              : colors
          }
        />
      )}
    </CollapsibleCard>
  )
}
