import React, { useEffect } from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { Link } from 'react-router-dom'
import { STATUS, fetchBatches, insertBatch } from 'Reducers/actions/dataImportActions'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { resetBatchInsertStates } from 'Reducers/dataImportReducer'

export default class ImportsOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        <ImportsOverviewComponent props={this.props} />
      </React.Fragment>
    )
  }
}

function ImportsOverviewComponent({ props }) {
  const dispatch = useDispatch()
  const platformData = useSelector(state => state.login.settings)
  const batchOverviewData = useSelector(state => state.dataImport.batchOverviewData)
  const batchInsertStatus = useSelector(state => state.dataImport.batchInsertStatus)
  const batchInsertError = useSelector(state => state.dataImport.batchInsertError)
  const batchBeingProcessed = useSelector(state => state.dataImport.batchBeingProcessed)
  const brandOrderImportDatasetID =
    props.DatasetID === 91 &&
    platformData &&
    platformData.features &&
    platformData.features.brandOrderImportDatasetID &&
    !isNaN(platformData.features.brandOrderImportDatasetID)
      ? platformData.features.brandOrderImportDatasetID
      : null
  useEffect(() => {
    dispatch(
      fetchBatches({
        DatasetID: brandOrderImportDatasetID ? brandOrderImportDatasetID : props.DatasetID,
        enableWalletActivation: platformData && platformData.features && platformData.features.enableWalletActivation && props.DatasetID === 2
      })
    )
  }, [])
  useEffect(() => {
    if (batchInsertStatus === STATUS.INSERT_BATCH_FAILURE) {
      toast.error('BATCH INSERTION FAILED : ' + batchInsertError, {
        autoClose: 3000
      })
      dispatch(resetBatchInsertStates())
    }
    if (batchInsertStatus === STATUS.INSERT_BATCH_SUCCESS) {
      toast.success('BATCH INSERTED SUCCESSFULY', {
        autoClose: 3000
      })
      dispatch(resetBatchInsertStates())
    }
  }, [batchInsertStatus, batchInsertError])

  const captionText = (type = null) => {
    if (props.DatasetID === 1) {
      if (type === 'page') {
        return 'Bruger Import'
      }
      if (type === 'newButton') {
        return 'Upload ny brugere fil'
      }
    }
    if (props.DatasetID === 2) {
      if (type === 'page') {
        return 'Point Import'
      }
      if (type === 'newButton') {
        return 'Upload ny point fil'
      }
    }
    if (props.DatasetID === 91) {
      if (type === 'page') {
        return 'Ordre Import'
      }
      if (type === 'newButton') {
        return 'Upload ny ordre fil'
      }
    }
    return null
  }

  const newUploadPath = () => {
    if (props.DatasetID === 1) {
      return '/import/users/new'
    }
    if (props.DatasetID === 2) {
      return '/import/points/new'
    }
    if (props.DatasetID === 91) {
      return '/import/orders/new'
    }
    return ''
  }

  const headers =
    props.DatasetID === 2
      ? {
          BatchID: 'Batch ID',
          batchFlowStatus: 'Status',
          batchComment: 'Note',
          pointsInserted: 'Indsatte',
          pointsNotInserted: 'Ikke indsatte',
          batchEncoding: 'Encoding Format',
          timeInsert: 'Date Insert',
          timeUpdate: 'Date Changed'
        }
      : {
          BatchID: 'Batch ID',
          batchFlowStatus: 'Status',
          batchComment: 'Note',
          batchEncoding: 'Encoding Format',
          timeInsert: 'Date Insert',
          timeUpdate: 'Date Changed'
        }

  return (
    <CollapsibleCard
      startOpen
      header={
        <React.Fragment>
          <strong>{captionText('page')}</strong>{' '}
          <Button tag={Link} to={newUploadPath()} className='float-right'>
            {captionText('newButton')}
          </Button>
        </React.Fragment>
      }
    >
      <DynamicTable
        responsive
        size='sm'
        options={{
          startSort: {
            dir: 'DESC',
            column: 'BatchID'
          },
          fieldFunctions: {
            timeInsert: e => {
              return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
            },
            timeUpdate: e => {
              return e.timeUpdate ? moment(e.timeUpdate).format('DD-MM-YYYY') : ''
            }
          },
          addField: e => {
            return (
              <td>
                <ButtonGroup>
                  <Tooltip target={'batch' + e.BatchID}>Vis</Tooltip>
                  <Button tag={Link} to={`${props.location.pathname}/` + e.BatchID + '/elements'} id={'batch' + e.BatchID}>
                    <FontAwesomeIcon icon='eye' />
                  </Button>
                  <Tooltip target={'insert' + e.BatchID}>Insert</Tooltip>
                  <Button
                    disabled={e.batchFlowStatus === 'INSERTED' || e.batchFlowStatus === 'MATCHED'}
                    id={'insert' + e.BatchID}
                    onClick={() => dispatch(insertBatch(e.BatchID, props.DatasetID))}
                  >
                    {e.BatchID === batchBeingProcessed && batchInsertStatus === STATUS.INSERT_BATCH_PROGRESS ? (
                      <FontAwesomeIcon icon='spinner' />
                    ) : (
                      <FontAwesomeIcon icon='compress' />
                    )}
                  </Button>
                </ButtonGroup>
              </td>
            )
          }
        }}
        headers={headers}
        data={batchOverviewData}
      />
    </CollapsibleCard>
  )
}
