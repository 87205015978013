/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import strip_tags from 'Components/striptags'
import keyMirror from 'keymirror'
import { customSlugify } from 'Util/slug'

export const STATUS = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  CLIENT_SUCCESS: null,
  CLIENT_FAILED: null,
  FETCHING_CLIENTS: null,
  FETCHING_CLIENTS_FAILED: null,
  FETCHING_CLIENTS_SUCCESS: null,
  FETCHING_CLIENT: null,
  FETCHING_CLIENT_FAILED: null,
  FETCHING_CLIENT_SUCCESS: null,
  DELETE_CLIENT_SUCCESS: null,
  DELETE_CLIENT_FAILED: null,
  FETCHING_COMPANIES: null,
  FETCHING_COMPANIES_FAILED: null,
  FETCHING_COMPANIES_SUCCESS: null,
  IS_REDIRECT: null
})

export const ClientActions = createActions([
  'clearToast',
  'createEmptyClient',
  'getClients',
  'getClientDetails',
  'updateClientField',
  'saveClient',
  'selectFile',
  'getCompanies',
  'deleteClient'
])

export class ClientStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = ClientActions
    this.state = {
      status: null,
      clientDetails: {
        clientName: '',
        clientNameShort: '',
        CompanyID: '',
        clientAccess: '',
        clientSlug: '',
        clientCNAME: '',
        clientColor: '',
        clientIcon: '',
        clientAppTokenIOS: '',
        clientAppTokenAndroid: '',
        clientCVR: '',
        clientEnableTaxInfo: false
      },
      companies: [],
      clients: []
    }
  }
  clearToast(status) {
    status ? this.setState({ status }) : this.setState({ status: null })
  }
  createEmptyClient() {
    this.setState({
      clientDetails: {
        clientName: '',
        clientNameShort: '',
        CompanyID: '',
        clientAccess: '',
        clientSlug: '',
        clientCNAME: '',
        clientColor: '',
        clientIcon: '',
        clientAppTokenIOS: '',
        clientAppTokenAndroid: '',
        clientCVR: '',
        clientEnableTaxInfo: false
      }
    })
  }
  getClients() {
    if (this.state.status === STATUS.FETCHING_CLIENTS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_CLIENTS })
    GET('clients/pool').then(responseJSON => {
      let nextState = {
        clients: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_CLIENTS_FAILED
      } else {
        nextState.clients = responseJSON
        nextState.status = STATUS.FETCHING_CLIENTS_SUCCESS
      }
      this.setState(nextState)
    })
  }
  getClientDetails(ClientID) {
    this.setState({ status: STATUS.FETCHING_CLIENT })
    GET('clients/fetch', { ClientID }).then(responseJSON => {
      let nextState = {
        clientDetails: {}
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_CLIENT_FAILED
      } else {
        nextState.clientDetails = responseJSON
        nextState.status = STATUS.FETCHING_CLIENT_SUCCESS
      }
      this.setState(nextState)
    })
  }
  updateClientField(field) {
    let value = strip_tags(field.target.value)
    let clientDetails = this.state.clientDetails
    if (field.target.name === 'clientColorPicker') {
      clientDetails[field.target.name] = value
      clientDetails['clientColor'] = value.replace('#', '').toUpperCase()
    } else if (field.target.name === 'clientName') {
      clientDetails[field.target.name] = value
      clientDetails['clientSlug'] = customSlugify(value)
    } else if (field.target.name === 'CompanyID') {
      clientDetails[field.target.name] = Number(value)
    } else if (field.target.name === 'clientEnableTaxInfo') {
      clientDetails[field.target.name] = field.target.checked ? 1 : 0
    } else {
      clientDetails[field.target.name] = value
    }

    this.setState({ clientDetails })
  }
  saveClient() {
    if (this.state.clientDetails.name != '' && this.state.clientDetails.shortName != '') {
      this.setState({ status: STATUS.IS_SAVING })
      POST('clients/replace', this.state.clientDetails).then(responseJSON => {
        let nextState = {
          clientDetails: this.state.clientDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.CLIENT_FAILED
          } else {
            nextState.clientDetails = responseJSON
            nextState.status = STATUS.CLIENT_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }
  deleteClient(ClientID = null) {
    if (ClientID) {
      POST('clients/disable', { ClientID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATUS.DELETE_CLIENT_FAILED })
        } else {
          this.setState({ status: STATUS.DELETE_CLIENT_SUCCESS })
          this.getClients()
        }
      })
    }
  }
  selectFile(data) {
    let file = data.target.files[0]
    this.setState({ file: file, isUploading: true })
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON) {
        let clientDetails = this.state.clientDetails
        clientDetails.clientIcon = responseJSON.imageOriginSource ? (clientDetails.clientIcon = responseJSON.imageOriginSource) : ''
        this.setState({ clientDetails })
      }
    })
  }
  getCompanies() {
    if (this.state.status === STATUS.FETCHING_COMPANIES) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_COMPANIES })
    GET('companies/pool').then(responseJSON => {
      let nextState = {
        companies: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_COMPANIES_FAILED
      } else {
        for (const iterator of responseJSON) {
          nextState.companies.push({ CompanyID: iterator['CompanyID'], companyName: iterator['companyName'] })
        }
        nextState.status = STATUS.FETCHING_COMPANIES_SUCCESS
      }
      this.setState(nextState)
    })
  }
}
