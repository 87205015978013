import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Proxies from './proxies'
import moment from 'moment'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DynamicTable from 'Components/dynamicTable'
import { useSelector } from 'react-redux'

const { GET, POST } = Proxies

export default function Status(props) {
  const { CampaignID, campaignStatus, selectedStatus, submitCampaign, CreatedBy, report } = props
  const [showAdminModal, setShowAdminModal] = useState(false)
  const [selectedAdmins, setSelectedAdmins] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isApprover, setIsApprover] = useState(false)
  const [approvedBy, setApprovedBy] = useState('')
  const [approvedDate, setApprovedDate] = useState()
  const [approvedTime, setApprovedTime] = useState()
  const platformData = useSelector(state => state.login.settings)
  let statusTypes = ['Kladde', 'Afventer', 'Godkendt', 'Afvist', 'Afsluttet']

  const submitStatus = () => {
    setShowAdminModal(true)
  }

  const sendForApproval = admins => {
    setLoading(true)
    let adminIDs = admins && admins.length ? admins.map(admin => admin.UserID) : null
    const adminsInfo = {
      NotificationCampaignID: Number(CampaignID),
      UserIDs: adminIDs
    }
    POST(`notificationcampaign/sendForApproval`, { ...adminsInfo }).then(responseJSON => {
      setLoading(false)
      if (responseJSON === true) {
        toast.success(`Campaign sent for approval successfully`, {
          autoClose: 7000
        })
      } else {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      selectedStatus('Afventer')
      if (Number(CampaignID)) {
        submitCampaign('Afventer')
      }
      setShowAdminModal(false)
    })
  }

  const fetchIsApprover = () => {
    GET(`notificationcampaign/fetch`, { NotificationCampaignID: Number(CampaignID) }).then(responseJSON => {
      if (responseJSON.error) {
        //console.log(responseJSON.error)
      } else {
        let approveddate = moment(responseJSON.approvedOn).format('MMM DD YYYY')
        let approvedtime = moment(responseJSON.approvedOn).format('hh:mm')
        setApprovedDate(approveddate)
        setApprovedTime(approvedtime)
        setIsApprover(responseJSON.isApprover)
        getApprovedBy(responseJSON.approvedBy, responseJSON.createdBy)
      }
    })
  }

  const getApprovedBy = (approverid, createdByid) => {
    if (createdByid === approverid) {
      setApprovedBy(platformData.userName)
      return
    }
    GET(`notificationcampaign/getAdminApprovers`, { NotificationCampaignID: Number(CampaignID) }).then(responseJSON => {
      if (responseJSON.error) {
        //console.log(responseJSON.error)
      } else {
        let approvedby = responseJSON.filter(user => approverid === user.UserID)
        if (approvedby?.length) {
          setApprovedBy(approvedby[0].userName)
        }
      }
    })
  }

  const approveCampaign = () => {
    POST(`notificationcampaign/approve`, { NotificationCampaignID: Number(CampaignID) }).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error(responseJSON.error, {
          autoClose: 7000
        })
      }
      toast.success(`Campaign approved successfully`, {
        autoClose: 7000
      })
    })
  }

  useEffect(() => {
    fetchIsApprover()
  }, [campaignStatus])

  return (
    <div style={{ backgroundColor: '#ffffff', width: '100%', padding: 10, marginTop: 22, borderRadius: 5, marginBottom: 20 }}>
      <FormGroup>
        <Col>
          <Label for='statusTypes' style={{ fontSize: 18 }}>
            Status
          </Label>
        </Col>
        <Col>
          <Input
            type='select'
            name='statusTypes'
            value={campaignStatus}
            id='statusTypes'
            disabled={report ? true : platformData.UserID === CreatedBy || isApprover ? false : true}
            style={platformData.UserID === CreatedBy || isApprover ? { width: '100%' } : { cursor: 'not-allowed', width: '100%' }}
            onChange={e => {
              if (confirm(`Are you sure you want to change the status to "${e.target.value}"?`)) {
                selectedStatus(e.target.value)
                if ((platformData.UserID === CreatedBy || isApprover) && e.target.value === 'Godkendt') {
                  approveCampaign()
                }
              }
            }}
          >
            {statusTypes.map((e, i) => {
              return (
                <option value={e} key={i}>
                  {e}
                </option>
              )
            })}
          </Input>
        </Col>
        <Col>
          {!isApprover ? (
            <Button
              disabled={report ? true : campaignStatus === 'Kladde' && (platformData.UserID === CreatedBy || isApprover) ? false : true}
              style={
                campaignStatus === 'Kladde' && (platformData.UserID === CreatedBy || isApprover)
                  ? { width: '100%', marginTop: 20 }
                  : { cursor: 'not-allowed', width: '100%', marginTop: 20 }
              }
              onClick={submitStatus}
            >
              Send til godkendelse
            </Button>
          ) : null}
          {approvedBy && approvedDate && campaignStatus === 'Godkendt' && (
            <p style={{ fontWeight: 600 }}>
              approved by {approvedBy} on {approvedDate} at {approvedTime}hrs.
            </p>
          )}
          {showAdminModal && (
            <AdminsModal
              report={report}
              setLoad={loading}
              showAdminModal={showAdminModal}
              selAdmins={selectedAdmins}
              closeModal={() => setShowAdminModal(false)}
              onAdminsSelect={admins => {
                sendForApproval(admins)
                setSelectedAdmins(admins)
              }}
            />
          )}
        </Col>
      </FormGroup>
    </div>
  )
}

const AdminsModal = ({ setLoad, showAdminModal, closeModal, onAdminsSelect, selAdmins, report }) => {
  const [selectedAdmins, setSelectedAdmins] = useState(selAdmins || [])
  const [loading, setLoading] = useState(false)
  const [admins, setAdmins] = useState([])

  useEffect(() => {
    setLoading(true)

    GET('notificationcampaign/getAdminApprovers').then(responseJSON => {
      setLoading(false)
      responseJSON = responseJSON.map(item => {
        if (selAdmins && selAdmins.length) {
          let match = selAdmins.find(u => u.UserID === item.UserID)
          if (match) {
            item.checked = true
          } else {
            item.checked = false
          }
        } else {
          item.checked = false
        }
        return item
      })
      setAdmins(responseJSON)
    })
  }, [])

  return (
    <Modal isOpen={showAdminModal} toggle={() => closeModal()} size='xl'>
      <ModalHeader toggle={() => closeModal()}>Select Admins</ModalHeader>
      <ModalBody style={{ maxHeight: '720px', overflowY: 'scroll' }}>
        {loading ? (
          <FontAwesomeIcon icon='sync' spin />
        ) : (
          <DynamicTable
            headers={{
              UserID: ' ',
              userName: 'Name',
              userEmail: 'Email',
              userPhone: 'Mobile'
            }}
            data={admins && admins.length ? admins : []}
            responsive
            size='lg'
            options={{
              fieldFunctions: {
                UserID: e => {
                  return (
                    <Fragment>
                      <Input
                        disabled={report && true}
                        className='order-selection'
                        type='checkbox'
                        id={'checkbox' + e.UserID}
                        name={'checkbox' + e.UserID}
                        checked={e.checked}
                        onChange={event => {
                          setSelectedAdmins(
                            admins
                              .map(u => {
                                if (u.UserID === e.UserID) {
                                  u.checked = event.target.checked
                                }
                                return u
                              })
                              .filter(u => u.checked === true)
                          )
                        }}
                      />
                    </Fragment>
                  )
                }
              }
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Row>
          <Button
            disabled={report && true}
            onClick={() => {
              setAdmins(
                admins.map(user => {
                  user.checked = true
                  return user
                })
              )
              setSelectedAdmins(
                admins.map(u => {
                  u.checked = true
                  return u
                })
              )
            }}
          >
            Select All
          </Button>
          <Button
            disabled={report && true}
            style={{ marginLeft: '8px' }}
            onClick={() => {
              setAdmins(
                admins.map(user => {
                  user.checked = false
                  return user
                })
              )
              setSelectedAdmins([])
            }}
          >
            Unselect All
          </Button>
          <Button disabled={report && true} style={{ marginLeft: '32px' }} onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button disabled={report && true} style={{ marginLeft: '8px' }} onClick={() => onAdminsSelect(selectedAdmins)}>
            {setLoad ? <FontAwesomeIcon icon='spinner' spin /> : 'Ok'}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}
