/* eslint-disable eqeqeq */
/**
 * DynamicTable
 *
 * Showing data in a simple table, using DataTables CSS
 *
 * Expected input
 *
 * @param {object} data - JSON parsed data
 *
 * optional input
 *
 * @param {object} headers - object with key being the key from the data-object, and value being the printed header
 * @param {object} options - large object that can contain everything - {startSort:{dir:"ASC",column:"columnName"}}
 *
 */
import React from 'react'
import Papa from 'papaparse'
import { Button, Form, FormGroup, Input, Label, UncontrolledTooltip as Tooltip, Table, Row, Col, Card, CardBody, CardHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import nl2br from 'Components/nl2br'
import ucwords from 'locutus/php/strings/ucwords'
import { download } from 'Components/download'
import XLSX from 'xlsx'
import _ from 'lodash'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const StyledSpan = styled.span`
  &:hover {
    color: green;
  }
`
export default class DynamicTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sortColumnID: 0,
      sortColumnDIR: 'ASC',
      paginationAmount: 25,
      paginationPoint: 0,
      filterText: '',
      advanceSearchFilters: [],
      appliedAdvanceSearchFilters: [],
      isAllChecked: false,
      isChecked: false
    }
    this.advanceSearchColumns = []
  }
  downloadData() {
    let { data, options, headers } = this.props
    let headerNames = []
    /* hvis der ikke er data, eller data ikke har længde, så stop */
    if (!data || data.length == 0) {
      return null
    }
    /* hvis der ikke er sendt headers med, så lad headers være feltnavnene */
    if (!headers) {
      headers = Object.keys(data[0])
    }
    /* find rækkefølge af felter - hvis headers ikke er sendt med, så lad rækkefølgen være den som data er leveret i */
    let fieldOrder = []
    if (!Array.isArray(headers)) {
      headers.actions && delete headers.actions
      fieldOrder = Object.keys(headers)
      headerNames = Object.values(headers)
    } else {
      fieldOrder = headers
    }
    /* sørg for at options er et objekt */
    if (!options) {
      options = {}
    }
    /* filtrer og sorter data */
    data = this.filterData(data, headers, options)
    data = this.sortData(data, options)
    let csvdata = data.map(e => {
      let line = {}
      fieldOrder.forEach((a, index) => {
        if (!Array.isArray(headers)) {
          if (!headers[a]) {
            return null
          }
        }
        let rVal = e[a]
        if (options.fieldExportFunctions && options.fieldExportFunctions[a] && typeof options.fieldExportFunctions[a] == 'function') {
          rVal = options.fieldExportFunctions[a](e)
        } else if (options.fieldFunctions && options.fieldFunctions[a] && typeof options.fieldFunctions[a] == 'function') {
          rVal = options.fieldFunctions[a](e)
        } else if (options.fieldStringFunctions && options.fieldStringFunctions[a] && typeof options.fieldStringFunctions[a] == 'function') {
          rVal = options.fieldStringFunctions[a](e)
        }
        headerNames.length ? (line[headerNames[index]] = rVal) : (line[a] = rVal)
      })
      return line
    })
    let papasettings = {
      quotes: true,
      delimiter: ';',
      header: true
    }
    download(Papa.unparse(csvdata, papasettings), 'download.csv')
  }
  downloadDataExcel() {
    let { data, options, headers } = this.props
    /* hvis der ikke er data, eller data ikke har længde, så stop */
    if (!data || data.length == 0) {
      return null
    }
    let headerNames = []
    /* hvis der ikke er sendt headers med, så lad headers være feltnavnene */
    if (!headers) {
      headers = Object.keys(data[0])
    }
    /* find rækkefølge af felter - hvis headers ikke er sendt med, så lad rækkefølgen være den som data er leveret i */
    let fieldOrder = []
    if (!Array.isArray(headers)) {
      headers.actions && delete headers.actions
      fieldOrder = Object.keys(headers)
      headerNames = Object.values(headers)
    } else {
      fieldOrder = headers
    }
    /* sørg for at options er et objekt */
    if (!options) {
      options = {}
    }
    /* filtrer og sorter data */
    data = this.filterData(data, headers, options)
    data = this.sortData(data, options)
    let xlData = []
    headerNames.length ? xlData.push(headerNames) : xlData.push(fieldOrder)
    data.map(e => {
      let xlRow = []
      fieldOrder.forEach(a => {
        if (!Array.isArray(headers)) {
          if (!headers[a]) {
            return null
          }
        }
        let rVal = e[a]
        if (options.fieldExportFunctions && options.fieldExportFunctions[a] && typeof options.fieldExportFunctions[a] == 'function') {
          rVal = options.fieldExportFunctions[a](e)
        } else if (options.fieldFunctions && options.fieldFunctions[a] && typeof options.fieldFunctions[a] == 'function') {
          rVal = options.fieldFunctions[a](e)
        } else if (options.fieldStringFunctions && options.fieldStringFunctions[a] && typeof options.fieldStringFunctions[a] == 'function') {
          rVal = options.fieldStringFunctions[a](e)
        }
        xlRow.push(rVal)
      })
      xlData.push(xlRow)
    })
    let worksheet = XLSX.utils.aoa_to_sheet(xlData)
    let workbook = new XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'download')
    XLSX.writeFile(workbook, 'download.xlsx')
  }
  label(label) {
    if (label.match(/^[A-Z_]{3,}$/)) {
      label = label.replace('_', ' ').toLowerCase()
    } else {
      label = label
        .replace(/[_\-.]+/g, ' ')
        .replace(/([A-Z])/g, ' $1')
        .replace(/([A-Z]) /g, '$1')
        .replace(/[\s]+/g, ' ')
    }
    return ucwords(label.trim())
  }
  isAFuckingNumber(x) {
    let parsed = Number.parseInt(x, 10)
    if (Number.isNaN(parsed)) {
      return false
    }
    return true
  }
  isNumber(str) {
    if (typeof str != 'string') return false // we only process strings!
    // could also coerce to string: str = ""+str
    return !isNaN(str) && !isNaN(parseFloat(str))
  }
  naturalCompare(a, b) {
    if ((a == null || a == undefined) && (b == null || b == undefined)) {
      return 0
    }
    if (a == null || a == undefined) {
      return -1
    }
    if (b == null || b == undefined) {
      return 1
    }
    if (this.isAFuckingNumber(a) && this.isAFuckingNumber(b) && this.isNumber(a) && this.isNumber(b)) {
      return a - b
    }
    if (typeof a != 'string' && a.toString) {
      a = a.toString()
    }
    if (typeof b != 'string' && b.toString) {
      b = b.toString()
    }
    let ax = [],
      bx = []
    a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
      ax.push([$1 || Infinity, $2 || ''])
    })
    b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
      bx.push([$1 || Infinity, $2 || ''])
    })
    while (ax.length && bx.length) {
      let an = ax.shift()
      let bn = bx.shift()
      let nn = an[0] - bn[0] || an[1].localeCompare(bn[1])
      if (nn) return nn
    }
    return ax.length - bx.length
  }
  sortColumn(ColumnID = null) {
    if (ColumnID !== null) {
      let nextState = {
        sortColumnDIR: 'ASC',
        sortColumnID: ColumnID
      }
      if (ColumnID == this.state.sortColumnID && this.state.sortColumnDIR == 'ASC') {
        nextState.sortColumnDIR = 'DESC'
      }
      this.setState(nextState)
      if (this.props.options.preserveSort) {
        this.props.options.preserveSort(nextState.sortColumnDIR, nextState.sortColumnID)
      }
    }
  }
  startSort(ColumnID = null, columnDir = 'ASC') {
    if (ColumnID !== null) {
      let nextState = {
        sortColumnDIR: columnDir,
        sortColumnID: ColumnID
      }
      this.setState(nextState)
    }
  }
  sortData(data, options = {}) {
    if (!data || data.length == 0) {
      return false
    } else {
      /*
       * We usually pass state variables (an array of objects) for "data".
       * With Redux, the state variables are read-only and can be changed only with pure functions.
       * So, make a copy of "data" before altering(sorting) its value
       */
      data = data.slice()
    }
    //let sortColumnName = Object.keys(data[0])[this.state.sortColumnID]
    let sortColumnName = this.state.sortColumnID
    if (sortColumnName === 0) {
      return data
    }
    if (
      options.fieldStringFunctions &&
      options.fieldStringFunctions[sortColumnName] &&
      typeof options.fieldStringFunctions[sortColumnName] == 'function'
    ) {
      if (this.state.sortColumnDIR == 'ASC') {
        data = data.sort((a, b) => {
          return this.naturalCompare(options.fieldStringFunctions[sortColumnName](a), options.fieldStringFunctions[sortColumnName](b))
        })
      } else {
        data = data.sort((a, b) => {
          return this.naturalCompare(options.fieldStringFunctions[sortColumnName](b), options.fieldStringFunctions[sortColumnName](a))
        })
      }
    } else if (options.fieldFunctions && options.fieldFunctions[sortColumnName] && typeof options.fieldFunctions[sortColumnName] == 'function') {
      if (this.state.sortColumnDIR == 'ASC') {
        data = data.sort((a, b) => {
          return this.naturalCompare(options.fieldFunctions[sortColumnName](a), options.fieldFunctions[sortColumnName](b))
        })
      } else {
        data = data.sort((a, b) => {
          return this.naturalCompare(options.fieldFunctions[sortColumnName](b), options.fieldFunctions[sortColumnName](a))
        })
      }
    } else {
      if (this.state.sortColumnDIR == 'ASC') {
        data = data.sort((a, b) => {
          return this.naturalCompare(a[sortColumnName], b[sortColumnName])
        })
      } else {
        data = data.sort((a, b) => {
          return this.naturalCompare(b[sortColumnName], a[sortColumnName])
        })
      }
    }
    return data
  }
  filterData(data, columnNames = null, options = {}) {
    if (!data || data.length == 0) {
      return false
    }
    let filteredData = data
    if (this.state.appliedAdvanceSearchFilters.length) {
      filteredData = filteredData.filter(item => {
        for (let filter of this.state.appliedAdvanceSearchFilters) {
          if (!filter.value) {
            continue
          }
          if (!item[filter.column]) {
            return false
          }
          if (filter.operators && filter.operators.length && filter.operators[0] === 'IN') {
            filter.operator = 'IN'
          }
          switch (filter.operator) {
            case 'LIKE':
              if (filter.operators.includes('DATE')) {
                if (this.props.options && this.props.options.fieldExportFunctions && this.props.options.fieldStringFunctions[filter.column]) {
                  let value = this.props.options.fieldExportFunctions[filter.column](item)
                  if (
                    !(
                      value
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.value) !== -1
                    )
                  ) {
                    return false
                  }
                }
              } else if (
                !item[filter.column]
                  .toString()
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              ) {
                return false
              }
              break
            case 'EXACT':
              if (item[filter.column].toString().toLowerCase() != filter.value.toLowerCase()) {
                return false
              }
              break
            case 'LESS_THAN':
              if (parseInt(item[filter.column]) >= parseInt(filter.value)) {
                return false
              }
              break
            case 'GREATER_THAN':
              if (parseInt(item[filter.column]) <= parseInt(filter.value)) {
                return false
              }
              break
            case 'IN':
              if (this.props.options && this.props.options.fieldStringFunctions && this.props.options.fieldStringFunctions[filter.column]) {
                let value = this.props.options.fieldStringFunctions[filter.column](item)
                if (
                  !(
                    value
                      .toString()
                      .toLowerCase()
                      .indexOf(filter.value) !== -1
                  )
                ) {
                  return false
                }
              }
              break
            default:
              if (item[filter.column].toString().toLowerCase() != filter.value.toLowerCase()) {
                return false
              }
              break
          }
        }
        return true
      })
      // return filteredData
    }
    if (this.state.filterText == '') {
      return filteredData
    }
    if (columnNames instanceof Object && !Array.isArray(columnNames)) {
      columnNames = Object.keys(columnNames)
    }
    if (!columnNames || columnNames.length == 0) {
      return null
    }
    let filterText = this.state.filterText.toLowerCase()
    filteredData = filteredData.filter(e => {
      if (!e) {
        return false
      }
      let rVal = false
      rVal = columnNames.map(c => {
        let rVal = false
        if ((options.unsortable && options.unsortable.indexOf(c) > -1) || c == 'functions') {
          return rVal || false
        } else if (options.fieldStringFunctions && options.fieldStringFunctions[c] && typeof options.fieldStringFunctions[c] == 'function') {
          return rVal || options.fieldStringFunctions[c](e)
            ? options.fieldStringFunctions[c](e)
                .toString()
                .toLowerCase()
                .indexOf(filterText) !== -1
            : false
        } else if (options.fieldFunctions && options.fieldFunctions[c] && typeof options.fieldFunctions[c] == 'function') {
          return rVal || options.fieldFunctions[c](e)
            ? options.fieldFunctions[c](e)
                .toString()
                .toLowerCase()
                .indexOf(filterText) !== -1
            : false
        } else if (e[c]) {
          return (
            rVal ||
            e[c]
              .toString()
              .toLowerCase()
              .indexOf(filterText) !== -1
          )
        }
      })
      rVal = rVal.filter(e => {
        return e
      })
      return rVal.length > 0
    })
    return filteredData
  }
  setPaginationAmount(e) {
    let nextState = {
      paginationAmount: e.target.value,
      paginationPoint: 0
    }
    if (this.state.paginationPoint !== 0) {
      let currPaginationCount = (this.state.paginationPoint + 1) * this.state.paginationAmount
      let nextPaginationPoint = Math.floor(currPaginationCount / nextState.paginationAmount)
      nextState.paginationPoint = nextPaginationPoint
    }
    if (_.get(this.props.options, 'preservePaginationAmount')) {
      this.props.options.preservePaginationAmount(nextState.paginationAmount)
    }
    if (_.get(this.props.options, 'isCheckedAll')) {
      this.props.options.preserveAllChecked(false)
    }
    this.setState(nextState)
  }
  setPaginationPoint(e) {
    let paginationPoint = e.target.value - 1
    if (paginationPoint != this.state.paginationPoint) {
      this.setState({ paginationPoint: paginationPoint })
      if (_.get(this.props.options, 'preservePagination')) {
        this.props.options.preservePagination(paginationPoint)
      }
      if (_.get(this.props.options, 'isCheckedAll')) {
        this.props.options.preserveAllChecked(false)
      }
    }
  }
  addFilter() {
    let nextState = { advanceSearchFilters: this.state.advanceSearchFilters }
    nextState.advanceSearchFilters.push({
      column: this.advanceSearchColumns[0].key,
      operator:
        this.advanceSearchColumns[0].operators && this.advanceSearchColumns[0].operators.length ? this.advanceSearchColumns[0].operators[0] : 'LIKE',
      value: '',
      operators:
        this.advanceSearchColumns[0].operators && this.advanceSearchColumns[0].operators.length ? this.advanceSearchColumns[0].operators : 'LIKE'
    })
    this.setState(nextState)
  }
  removeFilter(index) {
    let nextState = {
      advanceSearchFilters: this.state.advanceSearchFilters
    }
    nextState.advanceSearchFilters.splice(index, 1)
    this.setState(nextState)
  }
  updateFilter(element, index) {
    let nextState = {
      advanceSearchFilters: this.state.advanceSearchFilters
    }
    if (element && element.target) {
      let filter = nextState.advanceSearchFilters[index]
      if (element.target.name == 'filterColumn') {
        filter.column = element.target.value
        let match = this.advanceSearchColumns.filter(item => item.key == filter.column)
        if (match && match.length) {
          filter.operators = match[0].operators
        } else {
          filter.operators = ['LIKE']
        }
      } else if (element.target.name == 'filterOperator') {
        filter.operator = element.target.value
      } else if (element.target.name == 'filterValue') {
        filter.value = element.target.value
      }
    }
    this.setState(nextState)
    if (this.props.options && this.props.options.preserveAdvanceSearch) {
      this.props.options.preserveAdvanceSearch(nextState.advanceSearchFilters)
    }
  }
  resetAdvanceSearch() {
    this.setState({
      advanceSearchFilters: [
        {
          column: this.advanceSearchColumns[0].key,
          operator:
            this.advanceSearchColumns[0].operators && this.advanceSearchColumns[0].operators.length
              ? this.advanceSearchColumns[0].operators[0]
              : 'LIKE',
          value: '',
          operators:
            this.advanceSearchColumns[0].operators && this.advanceSearchColumns[0].operators.length ? this.advanceSearchColumns[0].operators : 'LIKE'
        }
      ],
      appliedAdvanceSearchFilters: [],
      paginationPoint: 0
    })
    this.props && this.props.options && this.props.options.showAdminAccessDropdown && this.props.options.preserveAllChecked(this.state.isChecked)
  }
  componentDidMount() {
    let nextState = {}
    if (this.props && this.props.options && this.props.options.startPagination) {
      nextState.paginationAmount = this.props.options.startPagination
    }
    if (this.props && this.props.options && this.props.options.startSort) {
      let columnDir = this.props.options.startSort.dir ? this.props.options.startSort.dir : 'ASC'
      let ColumnID = this.props.options.startSort.column
      this.startSort(ColumnID, columnDir)
    }
    if (this.props && this.props.options && this.props.options.filterText) {
      nextState.filterText = this.props.options.filterText
    }
    if (this.props && this.props.options && this.props.options.advanceSearchColumns) {
      this.advanceSearchColumns = this.props.options.advanceSearchColumns
      this.advanceSearchColumns = this.advanceSearchColumns.map(item => {
        item.selectedColumnIndex = 0
        if (!item.name) {
          item.name = this.label(item.key)
        }
        return item
      })
      if (this.props.options.advanceSearchFilters && this.props.options.advanceSearchFilters.length) {
        nextState.advanceSearchFilters = this.props.options.advanceSearchFilters
        nextState.appliedAdvanceSearchFilters = _.cloneDeep(this.props.options.advanceSearchFilters)
      } else {
        nextState.advanceSearchFilters = [
          {
            column: this.advanceSearchColumns.length ? this.advanceSearchColumns[0].key : '',
            operator:
              this.advanceSearchColumns.length && this.advanceSearchColumns[0].operators && this.advanceSearchColumns[0].operators.length
                ? this.advanceSearchColumns[0].operators[0]
                : 'LIKE',
            value: '',
            operators:
              this.advanceSearchColumns.length && this.advanceSearchColumns[0].operators && this.advanceSearchColumns[0].operators.length
                ? this.advanceSearchColumns[0].operators
                : 'LIKE'
          }
        ]
      }
    }
    if (_.get(this.props.options, 'paginationPoint')) {
      nextState.paginationPoint = this.props.options.paginationPoint
    }
    if (_.get(this.props.options, 'paginationAmount')) {
      nextState.paginationAmount = this.props.options.paginationAmount
    }
    if (_.get(this.props.options, 'isAllChecked')) {
      nextState.isAllChecked = this.props.options.isAllChecked
    }
    this.setState(nextState)
  }
  componentWillReceiveProps(props) {
    if (props.options && props.options.filterText) {
      this.setState({ filterText: props.options.filterText })
    }
    if (_.get(this.props.options, 'isAllChecked')) {
      this.setState({ isAllChecked: props.options.isAllChecked })
    }
  }
  prevPage() {
    if (this.state.paginationPoint > 0) {
      this.setState(
        prevProps => ({ paginationPoint: prevProps.paginationPoint - 1 }),
        () => {
          if (_.get(this.props.options, 'preservePagination')) {
            this.props.options.preservePagination(this.state.paginationPoint)
          }
          if (_.get(this.props.options, 'isCheckedAll')) {
            this.props.options.preserveAllChecked(false)
          }
        }
      )
    }
  }
  nextPage() {
    this.setState(
      prevProps => ({ paginationPoint: prevProps.paginationPoint + 1 }),
      () => {
        if (_.get(this.props.options, 'preservePagination')) {
          this.props.options.preservePagination(this.state.paginationPoint)
        }
        if (_.get(this.props.options, 'isCheckedAll')) {
          this.props.options.preserveAllChecked(false)
        }
      }
    )
  }
  onFilter = (e, checked) => {
    if (e.type === 'click' || (e.type === 'keypress' && e.key === 'Enter')) {
      this.setState(() => ({
        // filterText: '',
        appliedAdvanceSearchFilters: _.cloneDeep(this.state.advanceSearchFilters),
        paginationPoint: 0,
        ...(this.props && this.props.options && this.props.options.showAdminAccessDropdown && { isChecked: checked })
      }))
    }
  }
  copyTextToClipboard(value) {
    if (!navigator.clipboard) {
      // Clipboard API not available
      return
    }
    if (window.getSelection().toString() && this.props.options.copyHightlighText) {
      return
    }
    try {
      navigator.clipboard.writeText(value)
      toast.success('Kopieret: ' + value, {
        autoClose: 3000
      })
    } catch (err) {
      // console.error('Failed to copy!', err)
    }
  }
  rowHandler = (event, type, data) => {
    if (event.target.localName === 'span') {
      this.copyTextToClipboard(event.target.innerText)
    } else if (this.props && this.props.options && this.props.options.rowClickable && this.props.options.redirectTo) {
      type !== 'actions' && event.target.type !== 'checkbox' && this.props.options.redirectTo(data)
    }
  }
  handleQuickFilter = e => {
    this.setState({
      filterText: e.target.value,
      paginationPoint: 0
    })
    if (_.get(this.props.options, 'preserveSearch')) {
      this.props.options.preserveSearch(e.target.value)
    }
  }
  toggleHandler(field, data) {
    if (data && data.length) {
      let startIndex = this.setPaginationPoint === 0 ? 0 : this.state.paginationPoint * this.state.paginationAmount
      let endIndex = startIndex + this.state.paginationAmount
      data.map((item, index) => {
        if (index >= startIndex && index < endIndex) {
          item.checked = field.target.checked
        }
        return item
      })
      this.setState({ isAllChecked: field.target.checked })
      this.props.options.preserveAllChecked({ isAllChecked: field.target.value })
    }
  }
  isSelected(data) {
    let startIndex = this.setPaginationPoint === 0 ? 0 : this.state.paginationPoint * this.state.paginationAmount
    let endIndex = startIndex + this.state.paginationAmount
    let isAllChecked = data && data.length ? data.slice(startIndex, endIndex).reduce((sum, next) => sum && next['checked'], true) : false
    let isIndeterminate = false
    if (isAllChecked === false) {
      let value = data && data.length && data.slice(startIndex, endIndex).filter(item => item.checked && item)
      isIndeterminate = value && value.length > 0 && true
    }
    return { isAllChecked, isIndeterminate }
  }
  render() {
    /* træk data, options og headers ud af this.props og efterlad resten i props */
    let { data, options, headers, className = '', hideSearch = false, showAll = false, ...props } = this.props
    className = className = ' dataTable table-striped table-bordered table-hover'
    /* hvis der ikke er data, eller data ikke har længde, så stop */
    if (!data || data.length == 0 || !Array.isArray(data)) {
      return null
    }
    /* hvis der ikke er sendt headers med, så lad headers være feltnavnene */
    if (!headers) {
      headers = Object.keys(data[0])
    }
    /* find rækkefølge af felter - hvis headers ikke er sendt med, så lad rækkefølgen være den som data er leveret i */
    let fieldOrder = []
    if (!Array.isArray(headers)) {
      fieldOrder = Object.keys(headers)
    } else {
      fieldOrder = headers
    }
    let filters = null
    //AdvanceSearch
    if (this.state.advanceSearchFilters.length) {
      filters = this.state.advanceSearchFilters.map((filter, indx) => {
        return (
          <React.Fragment key={indx}>
            <Row>
              <Col xs='3'>
                <Input type='select' name='filterColumn' value={filter.column} id='filterColumn' onChange={t => this.updateFilter(t, indx)}>
                  {this.advanceSearchColumns.map(e => {
                    if (!e.hide) {
                      return (
                        <option value={e.key} key={e.key}>
                          {e.name}
                        </option>
                      )
                    }
                  })}
                </Input>
              </Col>
              <Col xs='3'>
                <Input type='select' name='filterOperator' value={filter.operator} id='filterOperator' onChange={t => this.updateFilter(t, indx)}>
                  {filter.operators.map(e => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
                </Input>
              </Col>
              <Col xs='5' className='mb-1'>
                <Input
                  required
                  type='text'
                  name='filterValue'
                  value={filter.value}
                  onKeyPress={e => this.onFilter(e, isAllChecked)}
                  id='filterValue'
                  onChange={t => this.updateFilter(t, indx)}
                />
              </Col>
              <Col xs='1'>
                <Button color='danger' onClick={() => this.removeFilter(indx)} className='ml-2'>
                  <FontAwesomeIcon icon='minus' />
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )
      })
    }
    /* sørg for at options er et objekt */
    if (!options) {
      options = {}
    }
    /* filtrer og sorter data */
    data = this.filterData(data, headers, options)
    data = this.sortData(data, options)
    let isAllChecked = false
    let isIndeterminate = false
    if (this.props && this.props.options && this.props.options.showAdminAccessDropdown) {
      const response = this.isSelected(data)
      isAllChecked = response.isAllChecked
      isIndeterminate = response.isIndeterminate
    }
    /* klargør pagination */
    let paginationArr = []
    for (let i = 1; i <= Math.ceil(data.length / this.state.paginationAmount); i++) {
      paginationArr.push(<option key={i}>{i}</option>)
    }
    return (
      <React.Fragment>
        {this.props && this.props.options && !!this.state.advanceSearchFilters.length && (
          <Card>
            <CardHeader tag='h4'>Filtre</CardHeader>
            <CardBody>
              <Row>
                <Col xs='3'>Kolonne</Col>
                <Col xs='3'>Operator</Col>
                <Col xs='5'>Value</Col>
                <Col xs='1' className='mb-2'>
                  <Button onClick={() => this.addFilter(this.operators)} className='ml-2'>
                    <FontAwesomeIcon icon='plus' />
                  </Button>
                </Col>
              </Row>
              {filters}
              <Row className='filter-buttons'>
                <Col>
                  <Button onClick={this.onFilter}>Filtrer</Button>
                  <Button color='danger' onClick={() => this.resetAdvanceSearch()} className='ml-2'>
                    Nulstil
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {this.props && this.props.options && this.props.options.showAdminAccessDropdown && (
          <Row style={{ padding: '19px', marginBottom: '25px' }}>
            <Col xs={3}>
              <input
                className='form-check-input'
                type='checkbox'
                id={'checkbox'}
                name={'checkbox'}
                checked={isAllChecked}
                ref={input => {
                  if (input) {
                    input.indeterminate = isIndeterminate
                  }
                }}
                onChange={e => {
                  this.toggleHandler(e, data)
                }}
              />
              <Label check htmlFor={'checkbox'}>
                Select All
              </Label>
            </Col>
            <Col></Col>
            {this.props.options && this.props.options.showContent && <this.props.options.showContent />}
          </Row>
        )}
        <Row>
          <Col>
            {!showAll && (
              <Form
                inline
                onSubmit={e => {
                  e.preventDefault()
                }}
              >
                <FormGroup>
                  <Label for='paginationAmount'>Vis</Label>
                  <Input
                    className='mr-2 ml-2'
                    type='select'
                    value={this.state.paginationAmount}
                    id='paginationAmount'
                    onChange={this.setPaginationAmount.bind(this)}
                  >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>150</option>
                    <option>250</option>
                    <option>500</option>
                    <option>1000</option>
                  </Input>
                  <Label for='paginationAmount'> rækker</Label>
                </FormGroup>
              </Form>
            )}
          </Col>
          <Col>
            {!showAll && (
              <center>
                <small>
                  Viser {this.state.paginationPoint * this.state.paginationAmount + 1} -{' '}
                  {(this.state.paginationPoint + 1) * this.state.paginationAmount > data.length
                    ? data.length
                    : (this.state.paginationPoint + 1) * this.state.paginationAmount}{' '}
                  ud af {data.length} rækker
                </small>
              </center>
            )}
          </Col>
          <Col>
            {!hideSearch && (
              <React.Fragment>
                <Tooltip target='downloadXlsx'>Download XLSX af det viste</Tooltip>
                <Button onClick={this.downloadDataExcel.bind(this)} id='downloadXlsx' className='float-right downloadBtn ml-1'>
                  <FontAwesomeIcon icon='file-excel' />
                </Button>
                <Tooltip target='download'>Download CSV af det viste</Tooltip>
                <Button onClick={this.downloadData.bind(this)} id='download' className='float-right downloadBtn ml-1'>
                  <FontAwesomeIcon icon='file-download' />
                </Button>
                <Form
                  inline
                  onSubmit={e => {
                    e.preventDefault()
                  }}
                  className='float-right'
                >
                  <Label for='filterText'>Søg: </Label>
                  <Input className='ml-2' id='filterText' value={this.state.filterText} onChange={this.handleQuickFilter} />
                </Form>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Table responsive size='sm' {...props} className={className}>
          {data && data.length > 0 && (
            <React.Fragment>
              {!options.hideHeader && (
                <thead>
                  <tr>
                    {fieldOrder.map((e, i) => {
                      let className = 'sorting'
                      let title = this.label(e)
                      let css = { whiteSpace: 'normal' }
                      if (this.props.customcss) {
                        css = this.props.customcss
                      }
                      if (!Array.isArray(headers)) {
                        if (this.props.headerscss) {
                          if (e in this.props.headerscss) {
                            css = this.props.customcss ? Object.assign(this.props.headerscss[e], this.props.customcss) : this.props.headerscss[e]
                          }
                        }
                        if (!headers[e]) {
                          return null
                        }
                        title = headers[e]
                      }
                      if (e == this.state.sortColumnID) {
                        if (this.state.sortColumnDIR == 'ASC') {
                          className = className + '_asc'
                        } else {
                          className = className + '_desc'
                        }
                      }
                      let onClick = () => {
                        this.sortColumn(e)
                      }
                      if ((options.unsortable && options.unsortable.indexOf(e) > -1) || e == 'functions') {
                        onClick = () => {
                          return false
                        }
                        className = ''
                      }
                      return (
                        <th key={i} style={css} onClick={onClick} className={className}>
                          {title}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
              )}
              <tbody>
                {data.map((e, i) => {
                  if (data.length > this.state.paginationAmount && !showAll) {
                    if (this.state.paginationPoint > 0) {
                      if (i < this.state.paginationPoint * this.state.paginationAmount) {
                        return null
                      }
                    }
                    if (i >= (this.state.paginationPoint + 1) * this.state.paginationAmount) {
                      return null
                    }
                  }
                  let rowAction = () => {
                    return false
                  }
                  if (options.rowAction && typeof options.rowAction == 'function') {
                    rowAction = options.rowAction
                  }
                  return (
                    <tr
                      key={i}
                      onClick={() => {
                        rowAction()
                      }}
                      style={this.props && this.props.options && this.props.options.rowClickable && { cursor: 'pointer' }}
                    >
                      {fieldOrder.map((a, index) => {
                        let css = { whiteSpace: 'normal' }
                        if (this.props.customcss) {
                          css = this.props.customcss
                        }
                        if (!Array.isArray(headers)) {
                          if (this.props.headerscss) {
                            if (a in this.props.headerscss) {
                              css = this.props.customcss ? Object.assign(this.props.headerscss[a], this.props.customcss) : this.props.headerscss[a]
                            }
                          }
                          if (!headers[a]) {
                            return null
                          }
                        }
                        if (a === 'error') {
                          return null
                        }
                        let rVal = e[a]
                        if (options.fieldFunctions && options.fieldFunctions[a] && typeof options.fieldFunctions[a] == 'function') {
                          rVal = options.fieldFunctions[a](e)
                        } else if (rVal instanceof Object) {
                          rVal = <pre>{JSON.stringify(rVal, null, '\t')}</pre>
                        } else {
                          rVal = nl2br(rVal)
                        }
                        return (
                          <td
                            style={e.error && e.error.includes(a) ? { background: 'red', border: '1px solid rgba(0, 0, 0, 0.3)', ...css } : css}
                            key={index}
                            onClick={event => {
                              this.rowHandler(event, a, e)
                            }}
                            onMouseUp={() => {
                              if (this.props && this.props.options && this.props.options.copyHightlighText && window.getSelection().toString()) {
                                navigator.clipboard.writeText(window.getSelection().toString())
                                toast.success('Kopieret: ' + window.getSelection().toString(), {
                                  autoClose: 3000
                                })
                              }
                            }}
                          >
                            <StyledSpan>{rVal}</StyledSpan>
                          </td>
                        )
                      })}
                      {options.addField && typeof options.addField == 'function' && options.addField(e)}
                    </tr>
                  )
                })}
              </tbody>
            </React.Fragment>
          )}
        </Table>
        {data.length > this.state.paginationAmount && !showAll && (
          <Form
            inline
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup>
              <Button className='nextPageBtn' disabled={this.state.paginationPoint == 0} onClick={this.prevPage.bind(this)}>
                Forrige side
              </Button>
              <Label className='ml-2 mr-2' for='paginationPoint'>
                Side{' '}
              </Label>
              <Input type='select' id='paginationPoint' value={this.state.paginationPoint + 1} onChange={this.setPaginationPoint.bind(this)}>
                {paginationArr}
              </Input>
              <Label className='ml-2 mr-2' for='paginationPoint'>
                af {Math.ceil(data.length / this.state.paginationAmount)} sider
              </Label>
              <Button
                className='nextPageBtn'
                disabled={this.state.paginationPoint >= Math.floor(data.length / this.state.paginationAmount)}
                onClick={this.nextPage.bind(this)}
              >
                Næste side
              </Button>
            </FormGroup>
          </Form>
        )}
      </React.Fragment>
    )
  }
}
