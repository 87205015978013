import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import { AddressActions, AddressStore, STATUS } from 'Stores/addressStore'
import { LoginStore } from 'Stores/loginStore'

export default class AddressesList extends Component {
  constructor(props) {
    super(props)
    this.stores = [AddressStore, LoginStore]
    this.storeKeys = ['addresses', 'settings', 'loaidngState', 'addressFilter', 'showActiveAddresses', 'showInActiveAddresses']
  }
  componentDidMount() {
    AddressActions.getAddresses()
  }
  componentDidUpdate() {
    if (this.state.loadingState === STATUS.FETCHING_ADDRESSES_FAILED) {
      toast.error('Fetching Addresses Failed', {
        autoClose: 7500,
        onClose: () => {
          AddressActions.clearToast()
        }
      })
      AddressActions.clearToast()
    }
    if (this.state.loadingState === STATUS.ADDRESS_DELETE_FAILED) {
      toast.error('Delete Address Failed', {
        autoClose: 7500,
        onClose: () => {
          AddressActions.clearToast()
        }
      })
      AddressActions.clearToast()
    }
  }

  render() {
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Adresser
            <ButtonGroup className='float-right'>
              <Button outline color='success' onClick={AddressActions.toggleActiveAddresses} active={this.state.showActiveAddresses}>
                Active
              </Button>
              <Button outline color='danger' onClick={AddressActions.toggleInActiveAddresses} active={this.state.showInActiveAddresses}>
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {this.state.loadingState === STATUS.FETCHING_ADDRESSES ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'AddressID'
              },
              fieldFunctions: {
                brandName: e => {
                  return e.brand ? e.brand.brandName : ''
                },
                timeInsert: e => {
                  if (e.timeInsert) {
                    return moment(e.timeInsert).format('YYYY-MM-DD')
                  }
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.AddressID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/address/' + e.AddressID} id={'edit' + e.AddressID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'deactivate' + e.AddressID}>Deaktivér</Tooltip>
                          <Button
                            id={'deactivate' + e.AddressID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne Address?')) {
                                AddressActions.deleteAddress(e.AddressID, e.removed)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-check' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip target={'actiavte' + e.AddressID}>Aktivér</Tooltip>
                          <Button
                            id={'actiavte' + e.AddressID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du aktivere denne Address?')) {
                                AddressActions.deleteAddress(e.AddressID, e.removed)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              AddressID: 'ID',
              ...(this.state.settings &&
                this.state.settings.BrandID === 9 &&
                this.state.settings.userRole.toLowerCase() === 'system' &&
                this.state.settings.userPrivilege.toLowerCase() === 'manage' && { BrandID: 'BrandID', brandName: 'Brand' }),
              companyName: 'Firma navn',
              addressLine: 'Adresse',
              zip: 'Postnr.',
              city: 'By',
              timeInsert: 'Dato'
            }}
            data={
              this.state.addresses && !!this.state.addresses.length && this.state.addressFilter !== 'both'
                ? this.state.addresses.filter(address => address.removed === (this.state.addressFilter === 'inactive' ? 1 : 0))
                : this.state.addresses
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}
