import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { CompanyActions, CompanyStore, STATUS } from 'Stores/companyStore'
import CompanyForm from 'Modules/company/companyForm'

export default class DatasetDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [CompanyStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    CompanyActions.getCompanyDetails(this.props.match.params.CompanyID)
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_COMPANY_FAILED) {
      toast.error('FETCHING COMPANY FAILED', {
        autoClose: 7500,
        onClose: () => {
          CompanyActions.clearToast()
        }
      })
      CompanyActions.clearToast()
    }

    if (this.state.status === STATUS.COMPANY_FAILED) {
      toast.error('UPDATE COMPANY FAILED', {
        autoClose: 3000,
        onClose: () => {
          CompanyActions.clearToast()
        }
      })
      CompanyActions.clearToast()
    }
    if (this.state.status === STATUS.COMPANY_SUCCESS) {
      toast.success('UPDATE COMPANY SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          CompanyActions.clearToast()
        }
      })
      CompanyActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <CompanyForm {...this.props} />
      </React.Fragment>
    )
  }
}
