/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Form, Input, Label, Row, Col, CustomInput, FormGroup } from 'reactstrap'
import FormField from 'Components/formField'
import { ShopStore, ShopActions } from 'Stores/shopStore'
import { LoginStore } from 'Stores/loginStore'
import _ from 'lodash'
import styled from 'styled-components'

const StyledCustomInput = styled(CustomInput)`
  top: 6px !important;
`
const Shop = props => {
  if (props.create) {
    return <ShopDetails {...props} />
  }
  if (props.match && props.match.params && props.match.params.ShopID) {
    return <ShopDetails {...props} />
  }
  return <ShopList {...props} />
}

export default Shop

class ShopList extends Component {
  constructor(props) {
    super(props)
    this.stores = [ShopStore, LoginStore]
    this.isMSBAdmin = false
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.brandName.toLowerCase() === 'msb' &&
      this.state.settings.userRole.toLowerCase() === 'system' &&
      this.state.settings.userPrivilege.toLowerCase() === 'manage'
    ) {
      this.isMSBAdmin = true
    }
    if (this.state.shops.length == 0) {
      ShopActions.getShops()
    }
  }
  componentDidUpdate() {
    if (this.state.showShopDeleteMessage) {
      toast.error(`SHOP UPDATE FAILED`, {
        autoClose: 5000,
        onClose: () => {
          ShopActions.clearToast()
        }
      })
      ShopActions.clearToast()
    }
  }
  render() {
    if (this.state.fetchingShops) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Butikker
            {this.state.settings &&
              this.state.settings.BrandID === 9 &&
              this.state.settings.brandName === 'MSB' &&
              this.state.settings.userRole === 'System' &&
              this.state.settings.userPrivilege === 'MANAGE' && (
                <ButtonGroup className='float-right'>
                  <Button outline color='success' onClick={ShopActions.toggleActiveShop} active={this.state.showActiveShops}>
                    Active
                  </Button>
                  <Button outline color='danger' onClick={ShopActions.toggleInActiveShop} active={this.state.showInActiveShops}>
                    In-Active
                  </Button>
                </ButtonGroup>
              )}
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'ShopID'
            },
            unsortable: ['groups', 'catalogs'],
            fieldFunctions: {
              groups: e => {
                let groups = e.groups ? e.groups : []
                return (
                  <React.Fragment>
                    {groups.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          {e && e.moduleName}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )
              },
              catalogs: e => {
                let catalogs = e.catalogs ? e.catalogs : []
                return (
                  <React.Fragment>
                    {catalogs.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          {e && e.moduleName}
                          <br />
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )
              }
            },
            fieldExportFunctions: {
              groups: e => {
                let groups = e.groups ? e.groups : []
                let acc = []
                {
                  groups &&
                    groups.map(e => {
                      acc.push(e.moduleName)
                    })
                }
                return acc.join('\n')
              },
              catalogs: e => {
                let catalogs = e.catalogs ? e.catalogs : []
                let acc = []
                {
                  catalogs &&
                    catalogs.map(e => {
                      acc.push(e.moduleName)
                    })
                }
                return acc.join('\n')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.ShopID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/shop/' + e.ShopID} id={'edit' + e.ShopID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    {this.isMSBAdmin && e.removed === 0 && (
                      <React.Fragment>
                        <Tooltip target={'deactivate' + e.ShopID}>Deaktivér</Tooltip>
                        <Button
                          id={'deactivate' + e.ShopID}
                          onClick={() => {
                            if (confirm('Vil du deaktivere denne shop?')) {
                              ShopActions.changeShopStatus(e, true)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-check' />
                        </Button>
                      </React.Fragment>
                    )}
                    {this.isMSBAdmin && e.removed === 1 && (
                      <React.Fragment>
                        <Tooltip color='danger' target={'active' + e.ShopID}>
                          Aktivér
                        </Tooltip>
                        <Button
                          color='danger'
                          id={'active' + e.ShopID}
                          onClick={() => {
                            if (confirm('Vil du aktivere denne shop?')) {
                              ShopActions.changeShopStatus(e, false)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='user-slash' />
                        </Button>
                      </React.Fragment>
                    )}
                    {!this.isMSBAdmin && (
                      <React.Fragment>
                        <Tooltip target={'delete' + e.ShopID}>Slet</Tooltip>
                        <Button
                          id={'delete' + e.ShopID}
                          color='danger'
                          onClick={() => {
                            if (confirm('Vil du slette denne shop?')) {
                              ShopActions.deleteShop(e.ShopID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='trash' />
                        </Button>
                      </React.Fragment>
                    )}
                    {this.isMSBAdmin && (
                      <React.Fragment>
                        <Tooltip target={'copy' + e.ShopID}>Kopier</Tooltip>
                        <Button
                          id={'copy' + e.ShopID}
                          onClick={() => {
                            this.props.history.push(this.props.location.pathname + '/new?copy=' + e.ShopID)
                          }}
                        >
                          <FontAwesomeIcon icon='copy' />
                        </Button>
                      </React.Fragment>
                    )}
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            ShopID: '#',
            ...(this.isMSBAdmin && { BrandID: 'Brand' }),
            moduleName: 'Titel',
            moduleDescription: 'Beskrivelse',
            shopProductLimitType: 'Algoritme',
            shopProductLimit: 'Max antal produkter',
            groups: 'Grupper',
            catalogs: 'Kataloger'
          }}
          data={
            this.state.settings &&
            this.state.settings.BrandID === 9 &&
            this.state.settings.brandName === 'MSB' &&
            this.state.settings.userRole === 'System' &&
            this.state.settings.userPrivilege === 'MANAGE' &&
            this.state.shops &&
            this.state.shopsFilterValue !== 'both' &&
            !!this.state.shops.length
              ? this.state.shops.filter(shop => shop.removed === (this.state.shopsFilterValue === 'inactive' ? 1 : 0))
              : this.state.shops
          }
        />
      </CollapsibleCard>
    )
  }
}

class ShopDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [ShopStore, LoginStore]
    this.resetShop = this.resetShop.bind(this)
    this.isMSBAdmin = false
  }
  componentWillMount() {
    super.componentWillMount()
    ShopActions.createEmptyShop()
  }
  resetShop() {
    if (this.props.create) {
      if (_.get(this.props, 'location.search')) {
        let params = new URLSearchParams(this.props.location.search)
        params.has('copy') && ShopActions.getShopDetails(Number(params.get('copy')), this.isMSBAdmin, true)
      } else {
        ShopActions.createEmptyShop()
      }
    } else {
      ShopActions.getShopDetails(this.props.match.params.ShopID, this.isMSBAdmin)
    }
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.brandName.toLowerCase() === 'msb' &&
      this.state.settings.userRole.toLowerCase() === 'system' &&
      this.state.settings.userPrivilege.toLowerCase() === 'manage'
    ) {
      this.isMSBAdmin = true
    }
    this.resetShop()
    if (this.state.settings && this.state.BrandID) {
      ShopActions.getLanguages(this.state.settings.BrandID)
    }
    if (this.state.catalogs.length == 0 && !this.state.fetchingCatalogs && this.props.create && this.state.settings && this.state.settings.BrandID) {
      ShopActions.getCatalogs(this.state.settings.BrandID)
    }
    if (this.state.groups.length == 0 && !this.state.fetchingGroups && this.props.create && this.state.settings && this.state.settings.BrandID) {
      ShopActions.getGroups(this.state.settings.BrandID)
    }
  }
  componentDidUpdate() {
    if (this.state.showShopError) {
      if (this.props.create) {
        toast.error('Der opstod en fejl i oprettelsen af shoppen', {
          autoClose: 7500,
          onClose: () => {
            ShopActions.clearToast()
          }
        })
      } else {
        toast.error('Der opstod en fejl ved opdateringen af shoppen', {
          autoClose: 7500,
          onClose: () => {
            ShopActions.clearToast()
          }
        })
      }
      ShopActions.clearToast()
    }
    if (this.state.showShopSuccess) {
      if (this.props.create) {
        toast.success('Shoppen er oprettet', {
          autoClose: 7500,
          onClose: () => {
            ShopActions.clearToast()
          }
        })
      } else {
        toast.success('Dine ændringer er gemt', {
          autoClose: 7500,
          onClose: () => {
            ShopActions.clearToast()
          }
        })
      }
      ShopActions.clearToast()
      if (this.ShopDetailRef) {
        window.scrollTo(0, this.ShopDetailRef.offsetTop)
      }
    }
  }
  render() {
    if (this.state.fetchingShops) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    if (this.state.shopDetails.ShopID && this.props.create) {
      return <Redirect to={'/shop/' + this.state.shopDetails.ShopID} />
    }
    let groups = []
    const shopGroupsList =
      this.state.filteredShopGroups && this.state.shopDetails.shopGroupFilterText && this.state.shopDetails.shopGroupFilterText.length
        ? this.state.filteredShopGroups
        : this.state.groups
    let shopGroups = this.state.shopDetails.groups ? this.state.shopDetails.groups.concat([]) : []
    if (shopGroupsList && shopGroupsList.length) {
      shopGroupsList.forEach((e, i) => {
        let isCheckedData = shopGroups.find(s => {
          return s.GroupID == e.GroupID
        })
        let isChecked = isCheckedData !== undefined
        if (isChecked) {
          shopGroups = shopGroups.filter(s => {
            return s.GroupID != e.GroupID
          })
        }
        groups.push(
          <React.Fragment key={i}>
            <Input type='checkbox' checked={isChecked} name={e.GroupID} onChange={ShopActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    if (shopGroups.length > 0) {
      groups.push(
        <React.Fragment key={'overflow'}>
          Følgende grupper skal gendannes via databasen hvis de slettes
          <br />
        </React.Fragment>
      )
      shopGroups.forEach((e, i) => {
        groups.push(
          <React.Fragment key={'overflow' + i}>
            <Input type='checkbox' checked={true} name={e.GroupID} onChange={ShopActions.toggleGroup} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }

    let catalogs = []
    const shopCatalogsList =
      this.state.filteredShopCatalogs && this.state.shopDetails.shopCatalogFilterText && this.state.shopDetails.shopCatalogFilterText.length
        ? this.state.filteredShopCatalogs
        : this.state.catalogs
    let shopCatalogs = this.state.shopDetails.catalogs ? this.state.shopDetails.catalogs.concat([]) : []
    if (shopCatalogsList && shopCatalogsList.length) {
      shopCatalogsList.forEach((e, i) => {
        let isCheckedData = shopCatalogs.find(s => {
          return s.CatalogID == e.CatalogID
        })
        let isChecked = isCheckedData !== undefined
        if (isChecked) {
          shopCatalogs = shopCatalogs.filter(s => {
            return s.CatalogID != e.CatalogID
          })
        }
        catalogs.push(
          <React.Fragment key={i}>
            <Input type='checkbox' checked={isChecked} name={e.CatalogID} onChange={ShopActions.toggleCatalog} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    if (shopCatalogs.length > 0) {
      catalogs.push(
        <React.Fragment key={'overflow'}>
          Følgende kataloger er muligvis private, hvis de fjernes skal de tilføjes via databasen
          <br />
        </React.Fragment>
      )
      shopCatalogs.forEach((e, i) => {
        catalogs.push(
          <React.Fragment key={'overflow' + i}>
            <Input type='checkbox' checked={true} name={e.CatalogID} onChange={ShopActions.toggleCatalog} label={e.moduleName} /> {e.moduleName}
            <br />
          </React.Fragment>
        )
      })
    }
    return (
      <CollapsibleCard startOpen header={<strong>{this.props.create ? 'Opret' : 'Detaljer'}</strong>} ref={ref => (this.ShopDetailRef = ref)}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField name='Navn' field='moduleName' data={this.state.shopDetails} onChange={ShopActions.updateShopField} required />
          <FormField name='Beskrivelse' field='moduleDescription' data={this.state.shopDetails} onChange={ShopActions.updateShopField} />
          {!!this.state.languages && !!(this.state.languages.length > 1) && (
            <FormGroup row>
              <Label for='textColor' sm={3}>
                Translations
              </Label>
              <Col sm={9}>
                {!!this.state.shopDetails &&
                  !!this.state.shopDetails.translations &&
                  !!this.state.shopDetails.translations.length &&
                  this.state.shopDetails.translations.map((translation, inx) => {
                    return (
                      !translation.removed && (
                        <div key={inx}>
                          <Row>
                            <Col sm={11}>
                              <Input
                                type='select'
                                name='LanguageID'
                                value={translation.LanguageID ? Number(translation.LanguageID) : ''}
                                id='LanguageID'
                                onChange={e => ShopActions.updateData(e, inx, 'translations')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {!!this.state.languages &&
                                  !!this.state.languages.length &&
                                  this.state.languages.map((e, i) => {
                                    return (
                                      <option value={Number(e.LanguageID)} key={i}>
                                        {e.languageName}
                                      </option>
                                    )
                                  })}
                              </Input>
                            </Col>
                            <Col sm={1}>
                              <Button onClick={() => ShopActions.removeTranslation(inx)} color={'danger'}>
                                <FontAwesomeIcon icon={'minus'} />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Label for='textColor' sm={2}>
                              Navn
                            </Label>
                            <Col sm={9}>
                              <Input
                                name='moduleName'
                                value={translation.moduleName}
                                type='text'
                                onChange={e => ShopActions.updateData(e, inx, 'translations')}
                              />
                            </Col>
                            <Col sm={1}>
                              <Button onClick={ShopActions.addTranslation}>
                                <FontAwesomeIcon icon='plus' />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                        </div>
                      )
                    )
                  })}
              </Col>
            </FormGroup>
          )}
          <FormField name='Luk Shop' field='shopStatus'>
            <StyledCustomInput
              type='switch'
              checked={this.state.shopDetails.shopStatus === 'CLOSED'}
              id='shopStatus'
              name='shopStatus'
              onChange={ShopActions.updateShopField}
            />
          </FormField>
          <FormField
            name='I feature-element'
            field='moduleFeatured'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='checkbox'
            checkedValue='1'
            unCheckedValue='0'
          />
          <FormField
            name='Max antal produkter'
            field='shopProductLimit'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='number'
          />
          <FormField
            name='Antal nye produkter'
            field='numberNewProducts'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='number'
          />
          <FormField
            name='Antal populære produkter'
            field='numberPopularProducts'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='number'
          />
          <FormField type='number' name='Pool Order' field='poolOrder' data={this.state.shopDetails} onChange={ShopActions.updateShopField} />
          <FormField name='Shuffle tid' field='shuffleTime' data={this.state.shopDetails} onChange={ShopActions.updateShopField} type='number' />
          <FormField name='Algoritme' field='shopProductLimitType' data={this.state.shopDetails}>
            <Input type='select' name='shopProductLimitType' id='shopProductLimitType' onChange={ShopActions.updateShopField}>
              {['MANUAL', 'LATEST', 'SOCIAL', 'RANDOM', 'MAGIC', 'SANTANDER'].map((e, i) => {
                return <option key={i}>{e}</option>
              })}
            </Input>
          </FormField>
          <FormField
            name='Personal Shopper'
            field='shopSupportEnabled'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='checkbox'
            checkedValue='1'
            unCheckedValue='0'
          />
          <FormField name='Module Query' field='moduleQuery' data={this.state.shopDetails} onChange={ShopActions.updateShopField} type='textarea' />
          <FormField name='Shop Type' field='shopType' data={this.state.shopDetails}>
            <Input type='select' name='shopType' id='shopType' onChange={ShopActions.updateShopField}>
              {['POINTS', 'PAYMENT', 'POINTS_PAYMENT'].map((e, i) => {
                return <option key={i}>{e}</option>
              })}
            </Input>
          </FormField>
          <FormField
            name='Shop valg begrænsning'
            field='shopSelectionLimit'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='number'
          />
          <FormField
            name='Shop produktpris minimum'
            field='shopProductPriceMinLimit'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='number'
          />
          <FormField
            name='Shop produktpris maksimum'
            field='shopProductPriceMaxLimit'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='number'
          />
          <FormField
            name='Shop produktpris justering'
            field='shopProductPriceAdjust'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='number'
          />
          <FormField name='Rækkefølge' field='poolOrder' data={this.state.shopDetails} onChange={ShopActions.updateShopField} type='number' />
          <FormField name='Freight Markup' field='freightMarkup' data={this.state.shopDetails} onChange={ShopActions.updateShopField} type='number' />
          <FormField
            name='Enable Freight Intervals'
            field='enableFreightPriceInterval'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='checkbox'
            checkedValue='1'
            unCheckedValue='0'
          />
          <FormField
            name='Enable Dropship Freight'
            field='enableDropshipFreightPrice'
            data={this.state.shopDetails}
            onChange={ShopActions.updateShopField}
            type='checkbox'
            checkedValue='1'
            unCheckedValue='0'
          />

          <FormField name='Kører fra' field='timeStart' data={this.state.shopDetails} onChange={ShopActions.updateShopField} type='date' />
          <FormField name='Kører til' field='timeEnd' data={this.state.shopDetails} onChange={ShopActions.updateShopField} type='date' />
          <FormField name='Grupper' field='groups' data={{}}>
            <Row>
              <Col xs='2'>
                <Label for='shopsGroupFilterHead'>Søg Grupper</Label>
              </Col>
              <Col xs='5'>
                <Input
                  type='text'
                  value={this.state.shopDetails.shopGroupFilterText}
                  name='shopGroupFilterText'
                  id='shopGroupFilterText'
                  onChange={ShopActions.updateShopField}
                />
              </Col>
            </Row>
            <React.Fragment>{groups}</React.Fragment>
          </FormField>
          <FormField name='Kataloger' field='catalogs' data={{}}>
            <Row>
              <Col xs='2'>
                <Label for='shopsCatalogFilterHead'>Søg Kataloger</Label>
              </Col>
              <Col xs='5'>
                <Input
                  type='text'
                  value={this.state.shopDetails.shopCatalogFilterText}
                  name='shopCatalogFilterText'
                  id='shopCatalogFilterText'
                  onChange={ShopActions.updateShopField}
                />
              </Col>
            </Row>
            <React.Fragment>{catalogs}</React.Fragment>
          </FormField>
          <Button
            onClick={() => {
              ShopActions.saveShop()
            }}
            disabled={this.state.isSaving}
          >
            {this.state.isSaving ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={this.resetShop} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
    )
  }
}
