import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from 'reactstrap'
import moment from 'moment'

const CountdownList = () => {
  const [countdowns, setCountdowns] = useState([])
  const [loading, setLoading] = useState(false)
  const [coundownFilterValue, setCoundownFilterValue] = useState('active')

  useEffect(() => {
    setLoading(true)
    getCountdowns()
  }, [])

  function getCountdowns() {
    Proxies.GET('/Countdowns/pool').then(responseJSON => {
      if (!responseJSON.error) {
        setCountdowns(responseJSON)
      }
      setLoading(false)
    })
  }

  function RemoveCountdown(CountdownID) {
    Proxies.POST('Countdowns/removeCountdown', { CountdownID }).then(responseJSON => {
      if (!responseJSON.error) {
        getCountdowns()
      }
    })
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Nedtællinger
          <Button tag={Link} to={'countdown/new'} style={{ marginLeft: '16px' }}>
            Opret ny
          </Button>
          <ButtonGroup className='float-right'>
            <Button
              outline
              color='success'
              onClick={() => setCoundownFilterValue(coundownFilterValue === 'active' ? 'both' : 'active')}
              active={coundownFilterValue === 'active'}
            >
              Active
            </Button>
            <Button
              outline
              color='danger'
              onClick={() => setCoundownFilterValue(coundownFilterValue === 'inactive' ? 'both' : 'inactive')}
              active={coundownFilterValue === 'inactive'}
            >
              In-Active
            </Button>
          </ButtonGroup>
        </strong>
      }
    >
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'name'
            },
            fieldFunctions: {
              endDate: e => {
                return moment(e.endDate).format('DD-MM-YYYY')
              },
              brands: e => {
                return e.brands.map(b => b.brandName).join(', ')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Button tag={Link} to={'/countdown/' + e.CountdownID} id={'edit' + e.CountdownID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Button
                      id={'delete' + e.CountdownID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Are you sure you want to delete?')) {
                          RemoveCountdown(e.CountdownID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            CountdownID: '#',
            title: 'Title',
            description: 'Beskrivelse',
            brands: 'Brands',
            endDate: 'Deaktiveringsdato'
          }}
          data={
            countdowns && coundownFilterValue !== 'both' && !!countdowns.length
              ? countdowns.filter(countdown => countdown.removed === (coundownFilterValue === 'inactive' ? 1 : 0))
              : countdowns
          }
        />
      )}
    </CollapsibleCard>
  )
}

export default CountdownList
