import Proxies from 'Components/proxies'
import { setCronPool, resetCronPool } from 'Reducers/cronReducer'

export const fetchCron = () => dispatch => {
  Proxies.GET('crons/pool').then(responseJSON => {
    if (responseJSON) {
      if (responseJSON.error) {
        dispatch(resetCronPool())
      } else {
        if (responseJSON.length > 0) {
          dispatch(setCronPool(responseJSON))
        } else {
          dispatch(setCronPool([]))
        }
      }
    }
  })
}
