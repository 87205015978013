import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form } from 'reactstrap'
import _ from 'lodash'
import { CompanyActions, CompanyStore, STATUS } from 'Stores/companyStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'

export default class CompanyForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [CompanyStore]
    this.storeKeys = ['status', 'companyDetails']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.CompanyID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      CompanyActions.getCompanyDetails(this.props.match.params.CompanyID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      CompanyActions.createEmptyCompany()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.state.companyDetails.companyName ? true : false,
      isShortNameValid: this.state.companyDetails.companyNameShort ? true : false
    }

    this.setState({ fieldValidation })

    const { isNameValid, isShortNameValid } = fieldValidation

    return isNameValid && isShortNameValid
  }

  saveCompanyForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      CompanyActions.saveCompany()
    }
  }

  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/company' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Company</strong>}>
          <Form>
            <FormField
              required
              data={this.state.companyDetails}
              field={'companyName'}
              name='Navn'
              onChange={e => {
                CompanyActions.updateCompanyField(e)
                this.validateForm()
              }}
            />
            <FormField
              required
              data={this.state.companyDetails}
              field={'companyNameShort'}
              name='Company Name Short '
              onChange={e => {
                CompanyActions.updateCompanyField(e)
                this.validateForm()
              }}
            />
            <Button onClick={this.saveCompanyForm} disabled={this.state.status === STATUS.IS_SAVING}>
              {this.state.status === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
