import React from 'react'
import { Component } from 'reflux'
import 'moment/locale/da'
import { KamActions, KamStore } from 'Stores/santanderKamStore'
import SantanderKAMGraph from 'Components/dashboard/SantanderKAMGraph'
import { decodeHtmlEntities } from 'Util/decodeHtmlEntities'

export default class KamUsersCompanyChart extends Component {
  constructor(props) {
    super(props)
    this.stores = [KamStore]
    this.storeKeys = ['kamCompanies', 'loadingCompany']
  }

  componentDidMount() {
    KamActions.getKamCompanies()
  }

  KamCompanyUsers = (event, value) => {
    event.stopPropagation()
    const name = value && value.length && decodeHtmlEntities(event.target.innerHTML)
    let data = value && value.length ? value.filter(item => item.company === name)[0] : value.data
    const company = data && data.company && data.company.replace(/ /g, '-')
    window.open(`/dashboard/${data.companyId}?company=${company}`, '_blank')
  }

  getTotalCompanyDetails(event) {
    event.preventDefault()
    window.open(`/dashboard/all-kam-company-users`, '_blank')
  }

  render() {
    const longestName = this.state.kamCompanies.length ? Math.max(...this.state.kamCompanies.map(el => el.company.length)) : 2
    return (
      <SantanderKAMGraph
        header='Brugere pr forhandler'
        state={this.state.loadingCompany}
        data={this.state.kamCompanies}
        keys={['Aktive Brugere', 'Inaktive Brugere']}
        indexBy={'company'}
        axisBottom={{ tickRotation: -80, onClick: e => this.KamCompanyUsers(e, this.state.kamCompanies) }}
        getTotalKamDetails={this.getTotalCompanyDetails}
        onClick={this.KamCompanyUsers}
        cursor={{ cursor: 'pointer' }}
        showIcon={true}
        axisLeft={{ format: e => Math.floor(e) === e && e }}
        margin={!!this.state.kamCompanies.length && { top: 50, right: 40, bottom: longestName > 20 ? longestName * 5 : longestName * 5, left: 60 }}
        longestName={this.state.kamCompanies && !!this.state.kamCompanies.length && Math.max(...this.state.kamCompanies.map(el => el.company.length))}
        pagesCount={Math.ceil(this.state.kamCompanies.length / 14)}
        legends={{ translateX: -15, itemsSpacing: 30, itemWidth: 80 }}
      />
    )
  }
}
