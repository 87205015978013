import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Input } from 'reactstrap'
import _ from 'lodash'
import { VaultStore, VaultActions, STATUS } from 'Stores/vaultStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { HofActions, HofStore } from 'Stores/hofStore'
import styled from 'styled-components'

const StyledCheckbox = styled(FormField)`
  position: relative !important;
  left: 20px;
`
export default class VaultForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [VaultStore, HofStore]
    this.storeKeys = ['loadingState', 'vaultDetails', 'brands']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  componentDidMount() {
    HofActions.getBrands()
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.VaultID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      VaultActions.getVaultDetails(this.props.match.params.VaultID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      VaultActions.createEmptyVault()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.state.vaultDetails.vaultName ? true : false,
      isSlugValid: this.state.vaultDetails.vaultSlug ? true : false
    }

    this.setState({ fieldValidation })

    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveVaultForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      VaultActions.saveVault()
    }
  }

  render() {
    if (this.state.loadingState === STATUS.IS_REDIRECT) {
      return <Redirect to='/vaults' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Vault</strong>}>
          <Form>
            <FormField field={'BrandID'} name='Brand'>
              <Input type='select' name='BrandID' value={this.state.vaultDetails.BrandID} onChange={VaultActions.updateVaultField}>
                <option value=''>Select Brand</option>
                {this.state.brands &&
                  !!this.state.brands.length &&
                  this.state.brands.map(e => {
                    return (
                      <option value={e.BrandID} key={e.BrandID}>
                        {e.brandName}
                      </option>
                    )
                  })}
              </Input>
            </FormField>
            <FormField
              required
              data={this.state.vaultDetails}
              field={'vaultName'}
              name='Navn'
              onChange={e => {
                VaultActions.updateVaultField(e)
                this.validateForm()
              }}
            />
            <FormField
              required
              data={this.state.vaultDetails}
              field={'vaultSlug'}
              name='Vault Slug'
              onChange={e => {
                VaultActions.updateVaultField(e)
                this.validateForm()
              }}
            />
            <StyledCheckbox
              type='checkbox'
              name='Default'
              field='isDefault'
              data={this.state.vaultDetails}
              onChange={VaultActions.updateVaultField}
              checkedValue={1}
              unCheckedValue={0}
            />
            <Button onClick={this.saveVaultForm} disabled={this.state.loadingState === STATUS.IS_SAVING}>
              {this.state.loadingState === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
