import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'

import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Form, FormGroup, Input, Label, Col, Row } from 'reactstrap'

import { WalletActions, WalletStore } from 'Stores/walletStore'
import { UserStore, UserActions } from 'Stores/userStore'
import { UserWallet } from 'Modules/user'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { LoginStore } from 'Stores/loginStore'
import { VaultStore, VaultActions } from 'Stores/vaultStore'

const Wallet = props => {
  if (_.get(props, 'match.params.UserID')) {
    if (props.match.params.WalletID) {
      return <WalletCreate {...props} />
    }
    if (props.create) {
      return <WalletCreate {...props} />
    }
    return <UserWallet UserID={props.match.params.UserID} startOpen {...props} />
  } else {
    return <PlainWallet filter={props.filter} isViewMode={props.isViewMode} showKAMName={props.showKAMName} />
  }
}
export default Wallet

class WalletCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [WalletStore, UserStore, LoginStore, VaultStore]
    this.storeKeys = [
      'settings',
      'users',
      'fetchingUsers',
      'walletLine',
      'fetchingWallets',
      'savingWallet',
      'showWalletSuccess',
      'showWalletError',
      'vaults',
      'editedUserData',
      'brandFeatures',
      'isMSBAdmin'
    ]
    this.resetWallet = this.resetWallet.bind(this)
  }
  componentWillMount() {
    super.componentWillMount()
    this.resetWallet()
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE' &&
      this.state.settings.BrandID === 9
    ) {
      UserActions.giveAccess()
    } else {
      VaultActions.getVaults()
    }
    if (this.state.settings && this.state.settings.features && this.state.settings.features.enableBudgetWalletType) {
      let walletLine = this.state.walletLine
      walletLine.walletType = 'BUDGET'
      this.setState(walletLine)
    }
    UserActions.editUser(this.props.match.params.UserID)
  }
  resetWallet() {
    if (this.props.create) {
      WalletActions.createEmptyWallet(this.props.match.params.UserID)
    } else {
      WalletActions.getWalletLine(this.props.match.params.WalletID)
    }
  }
  componentDidUpdate() {
    if (
      (this.state.settings && this.state.settings.features && this.state.settings.features.displayDefaultVault) ||
      (this.state.settings.BrandID === 9 &&
        this.state.settings.userRole === 'System' &&
        this.state.settings.userPrivilege === 'MANAGE' &&
        this.state.brandFeatures &&
        this.state.brandFeatures.features &&
        this.state.brandFeatures.features.displayDefaultVault)
    ) {
      if (
        this.state.editedUserData &&
        Object.keys(this.state.editedUserData).length > 1 &&
        this.state.vaults &&
        !!this.state.vaults.length &&
        this.state.walletLine &&
        !this.state.walletLine.VaultID
      ) {
        let VaultID
        if (this.state.editedUserData.defaultVaultID) {
          VaultID = this.state.editedUserData.defaultVaultID
          WalletActions.updateWalletField({ target: { name: 'VaultID', value: this.state.editedUserData.defaultVaultID } })
        } else {
          let defaultVault = this.state.vaults.filter(item => (item.isDefault = 1))
          defaultVault && defaultVault.length && WalletActions.updateWalletField({ target: { name: 'VaultID', value: defaultVault[0].VaultID } })
          VaultID = defaultVault[0].VaultID
        }
        var vaults = _.sortBy(this.state.vaults, function(item) {
          if (item.VaultID === VaultID) {
            item.vaultName = item.vaultName + ' (Default)'
          }
          return Number(item.VaultID) === Number(VaultID) ? 0 : 1
        })
        this.setState({ vaults })
      }
    }
    if (this.state.showWalletSuccess) {
      const message = this.state.walletLine && this.state.walletLine.walletAmountPoints < 0 ? 'Point er fratrukket' : 'Point er tilføjet'
      toast.success(message, {
        autoClose: 7500,
        onClose: () => {
          WalletActions.clearToast()
        }
      })
      WalletActions.clearToast()
    }
    if (this.state.showWalletError) {
      toast.error('Der opstod en fejl ved pointtilførsel', {
        autoClose: 7500,
        onClose: () => {
          WalletActions.clearToast()
        }
      })
      WalletActions.clearToast()
    }
  }
  render() {
    let currUserID = null
    if (this.props.match.params.UserID) {
      currUserID = this.props.match.params.UserID
    }
    if (this.state.walletLine.UserID) {
      currUserID = this.state.walletLine.UserID
    }
    if (this.state.showWalletSuccess) {
      return <Redirect to={'/users/' + currUserID + '/wallet'} />
    }
    if (this.state.walletLine.WalletID && this.props.create) {
      return <Redirect to={'/users/' + currUserID + '/wallet/' + this.state.walletLine.WalletID} />
    }
    let disableField = true
    if (this.props.create) {
      disableField = false
    }
    const walletTypes =
      this.state.settings && this.state.settings.features && this.state.settings.features.enableBudgetWalletType
        ? [
            { name: 'BUDGET', disabled: false },
            { name: 'SYSTEM', disabled: true },
            { name: 'MACRO', disabled: true },
            { name: 'MANAGE', disabled: false },
            { name: 'USER', disabled: true },
            { name: 'RESERVED', disabled: true }
          ]
        : [
            { name: 'SYSTEM', disabled: true },
            { name: 'MACRO', disabled: true },
            { name: 'MANAGE', disabled: false },
            { name: 'USER', disabled: true },
            { name: 'RESERVED', disabled: true }
          ]
    const defaultWalletType =
      this.state.settings && this.state.settings.features && this.state.settings.features.enableBudgetWalletType ? 'BUDGET' : 'MANAGE'
    return (
      <CollapsibleCard startOpen header={<strong>Opret</strong>}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          {WalletField(
            this.state.walletLine,
            'UserID',
            'Bruger',
            null,
            <React.Fragment>
              {
                <React.Fragment>
                  <Input name='UserID' id='UserID' disabled={true} value={currUserID ? currUserID : ''} onChange={WalletActions.updateWalletField} />
                </React.Fragment>
              }
            </React.Fragment>,
            disableField
          )}
          {WalletField(
            this.state.walletLine,
            'walletType',
            'Type',
            null,
            <Input
              type='select'
              value={this.state.walletLine.walletType ? this.state.walletLine.walletType : defaultWalletType}
              onChange={WalletActions.updateWalletField}
              name='walletType'
            >
              {walletTypes.map((e, i) => {
                return (
                  <option value={e.name} disabled={e.disabled} key={i}>
                    {e.name}
                  </option>
                )
              })}
            </Input>,
            disableField
          )}
          {WalletField(this.state.walletLine, 'walletFlags', 'Flag', null, null, disableField)}
          <hr />
          {((this.state.settings && this.state.settings.features && this.state.settings.features.displayDefaultVault) ||
            (this.state.brandFeatures && this.state.brandFeatures.features && this.state.brandFeatures.features.displayDefaultVault)) &&
            WalletField(
              this.state.walletLine,
              'VaultID',
              'Vault',
              null,
              <Input type='select' value={this.state.walletLine.VaultID} name='VaultID' id='c' onChange={WalletActions.updateWalletField}>
                {this.state.vaults.map((e, i) => {
                  return (
                    <option key={i} value={Number(e.VaultID)}>
                      {e.vaultName}
                    </option>
                  )
                })}
              </Input>
            )}
          {WalletField(this.state.walletLine, 'walletAmountPoints', 'Point', null, null, disableField)}
          {WalletField(this.state.walletLine, 'walletNote', 'Note', null, null, disableField)}
          {this.props.create && (
            <Button onClick={() => WalletActions.saveWallet(this.state.isMSBAdmin, this.state.editedUserData.BrandID)}>
              {this.state.savingWallet ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
          )}
          {this.props.create && (
            <Button onClick={this.resetWallet} color='danger'>
              Nulstil
            </Button>
          )}
        </Form>
      </CollapsibleCard>
    )
  }
}

class PlainWallet extends Component {
  constructor(props) {
    super(props)
    this.store = WalletStore
  }
  componentDidMount() {
    if (this.props.showKAMName) {
      WalletActions.getCampaignWalletsOverview(this.props.filter)
    } else {
      WalletActions.getWallets(this.props.filter)
    }
  }
  componentDidUpdate() {
    if (this.state.showWalletError) {
      toast.error('error while loading wallet', {
        autoClose: 7500,
        onClose: () => {
          WalletActions.clearToast()
        }
      })
      WalletActions.clearToast()
    }
  }
  render() {
    if (this.state.fetchingWallets) {
      return <FontAwesomeIcon icon='sync' spin />
    }
    let headers = {
      WalletID: '#',
      UserID: 'User ID',
      walletUserUID: 'User UID',
      user: 'User Name',
      walletAmountPoints: 'Point',
      walletNote: 'Note',
      walletType: 'Type',
      walletFlags: 'Flag',
      timeInsert: 'Dato'
    }
    if (this.props.showKAMName) {
      ;(headers.KAM = 'KAM'), (headers.dealerNumber = 'Dealer#'), (headers.dealerName = 'Dealer')
    }
    return (
      <CollapsibleCard startOpen header={<strong>Wallet</strong>}>
        <DynamicTable
          responsive
          size='sm'
          data={this.state.wallets}
          headers={headers}
          options={{
            startSort: {
              dir: 'DESC',
              column: 'WalletID'
            },
            addField: e => {
              return (
                this.props.isViewMode && (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'view' + e.WalletID}>Vis</Tooltip>
                      <Button tag={Link} to={'/wallet/' + e.WalletID} id={'view' + e.WalletID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              )
            },
            fieldFunctions: {
              user: e => {
                return e.user ? e.user.userName : ''
              },
              KAM: e => {
                return e.user ? e.user.userSupervisor : ''
              },
              dealerName: e => {
                return e.user ? e.user.userCompany : ''
              },
              dealerNumber: e => {
                return e.user ? e.user.userCompanyUID : ''
              },
              timeInsert: e => moment(e.timeInsert).format('DD-MM-YYYY')
            },
            fieldStringFunctions: {
              timeInsert: e => moment(e.timeInsert).format('x')
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}

const WalletField = (data, fieldName, fieldNameReadable, type = 'text', fieldCode = null, disableField = true) => {
  return (
    <FormGroup>
      <Row>
        <Col xs='3'>
          <Label for={fieldName}>{fieldNameReadable} </Label>
        </Col>
        <Col xs='9'>
          {fieldCode ? (
            fieldCode
          ) : (
            <Input
              type={type}
              value={data[fieldName] ? data[fieldName] : ''}
              name={fieldName}
              id={fieldName}
              disabled={disableField}
              onChange={WalletActions.updateWalletField}
            />
          )}
        </Col>
      </Row>
    </FormGroup>
  )
}
