/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import moment from 'moment'
import keyMirror from 'keymirror'
import strip_tags from 'Components/striptags'
export const STATE = new keyMirror({
  TAKING_INPUT: null,
  IS_SAVING: null,
  IS_REDIRECT: null,
  STAT_FAILED: null,
  STAT_SUCCESS: null,
  FETCHING_STATLIST: null,
  FETCHING_STATLIST_FAILED: null,
  FETCHING_STAT: null,
  FETCHING_STAT_FAILED: null,
  DELETE_STAT_ERROR: null,
  DELETE_STAT_SUCCESS: null
})

export const StatActions = createActions([
  'getStats',
  'getStat',
  'toggleQueryContent',
  'createEmptyStat',
  'getStatDetails',
  'saveStat',
  'updateStatField',
  'clearToast',
  'giveAccess',
  'changeStatStatus',
  'copyStat',
  'preserveAdvanceSearch',
  'toggleActiveStats',
  'toggleInActiveStats'
])

export class StatStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = StatActions
    this.state = {
      statDetails: {
        moduleName: '',
        moduleDescription: '',
        poolOrder: 0,
        moduleQuery: ''
      },
      stats: [],
      fetchingStats: false,
      fetchingSingleStat: false,
      statElements: [],
      loadingState: null,
      isAdminAccess: false,
      advanceSearchFilters: [],
      showActiveStats: true,
      showInActiveStats: false,
      statFilterValue: 'active'
    }
  }
  getStats(value) {
    this.setState({ loadingState: STATE.FETCHING_STATLIST })
    const api = value ? 'stat/admin-pool' : 'stat/pool'
    GET(api).then(responseJSON => {
      let nextState = {
        stats: []
      }
      if (responseJSON.error) {
        nextState.loadingState = STATE.FETCHING_STATLIST_FAILED
      } else {
        responseJSON = responseJSON.map(item => {
          if (item.moduleQuery && item.moduleQuery.length > 200) {
            item.moduleQueryReadMore = true
            item.moduleQueryReadLess = false
          } else {
            item.moduleQueryReadMore = false
            item.moduleQueryReadLess = false
          }
          if (item.brand) {
            item.brandName = item.brand.brandName
          }
          return item
        })
        nextState.stats = responseJSON
        nextState.loadingState = STATE.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }
  getStat(StatID = null, BrandID) {
    if (StatID == null) {
      return false
    }
    this.setState({ fetchingSingleStat: true })
    let nextState = {
      fetchingSingleStat: false,
      statElements: []
    }
    GET('stat/elements', { StatID: StatID, ...(BrandID && { BrandID: Number(BrandID) }) }).then(responseJSON => {
      if (responseJSON && responseJSON.length) {
        const headers = Object.keys(responseJSON[0]).filter(
          key => key === 'Startdato' || key === 'Slutdato' || key === 'timeInsert' || key === 'Sidst aktiv'
        )
        if (headers && headers.length) {
          const responseJSONDatesFormatted = responseJSON.map(item => {
            for (const header of headers) {
              if (item[header] && item[header] !== ' ' && moment(item[header]).isValid()) {
                item[header] = moment(item[header]).format('DD-MM-YYYY')
              } else {
                item[header] = null
              }
            }
            return item
          })
          nextState.statElements = responseJSONDatesFormatted
        } else {
          nextState.statElements = responseJSON
        }
      }
      this.setState(nextState)
    })
  }

  toggleQueryContent(e) {
    let stats = this.state.stats
    stats = stats.map(item => {
      if (item.StatID === e.StatID) {
        item.moduleQueryReadMore = !item.moduleQueryReadMore
        item.moduleQueryReadLess = !item.moduleQueryReadLess
      }
      return item
    })
    this.setState({ stats })
  }

  createEmptyStat() {
    this.setState({
      statDetails: {
        moduleName: '',
        moduleDescription: '',
        moduleQuery: '',
        poolOrder: 0
      }
    })
  }

  getStatDetails(StatID, value) {
    this.setState({ loadingState: STATE.FETCHING_STAT, isPreview: false })
    GET('stat/fetch', { StatID }).then(responseJSON => {
      let nextState = {
        statDetails: {}
      }
      if (responseJSON.error) {
        nextState.loadingState = STATE.FETCHING_STAT_FAILED
      } else {
        value && delete responseJSON.StatID
        nextState.statDetails = responseJSON
        nextState.loadingState = STATE.TAKING_INPUT
      }
      this.setState(nextState)
    })
  }

  saveStat() {
    if (this.state.statDetails.moduleName != '' && this.state.statDetails.moduleQuery !== '') {
      this.setState({ loadingState: STATE.IS_SAVING })
      let statDetails = this.state.statDetails
      statDetails.poolOrder === '' && delete statDetails.poolOrder
      POST('stat/replace', statDetails).then(responseJSON => {
        let nextState = {
          statDetails: this.state.statDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.loadingState = STATE.STAT_FAILED
          } else {
            nextState.statDetails = responseJSON
            nextState.loadingState = STATE.STAT_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }

  updateStatField(field) {
    let value = strip_tags(field.target.value)
    let statDetails = this.state.statDetails
    statDetails[field.target.name] = field.target.name === 'poolOrder' || field.target.name === 'BrandID' ? Number(value) : value
    this.setState({ statDetails })
  }

  clearToast(loadingState) {
    loadingState ? this.setState({ loadingState }) : this.setState({ loadingState: null })
  }

  giveAccess() {
    this.setState({ isAdminAccess: true })
  }

  changeStatStatus(data, value) {
    let statDetails = data
    statDetails.removed = value ? 1 : 0
    POST('stat/replace', statDetails).then(responseJSON => {
      if (responseJSON.error) {
        this.setState({ loadingState: STATE.DELETE_STAT_ERROR })
      } else {
        this.getStats(true)
      }
    })
  }

  copyStat(stat, props) {
    props.history.push(props.location.pathname + '/new?copy=' + stat.StatID)
  }
  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
  toggleActiveStats() {
    let value = !this.state.showActiveStats ? 'active' : 'both'
    this.setState({ showActiveStats: !this.state.showActiveStats, statFilterValue: value, showInActiveStats: false })
  }
  toggleInActiveStats() {
    let value = this.state.showInActiveStats ? 'both' : 'inactive'
    this.setState({ showInActiveStats: !this.state.showInActiveStats, statFilterValue: value, showActiveStats: false })
  }
}
