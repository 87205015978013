import React from 'react'
import { Component } from 'reflux'
import { DatasetStore, DatasetActions, STATUS } from 'Stores/datasetStore'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { toast } from 'react-toastify'

export default class DatasetList extends Component {
  constructor(props) {
    super(props)
    this.stores = [DatasetStore]
    this.storeKeys = ['datasets', 'status']
  }

  componentDidMount() {
    if (this.state.status !== STATUS.FETCHING_DATASETS) {
      DatasetActions.getDatasets()
    }
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.DATASETS_ERROR) {
      toast.error('FETCHING DATASETS FAILED', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearListToast()
        }
      })
      DatasetActions.clearListToast()
    }
    if (this.state.status === STATUS.DATASET_DELETE_ERROR) {
      toast.error('DATASET DELETE FAILED', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearListToast()
        }
      })
      DatasetActions.clearListToast()
    }
    if (this.state.status === STATUS.DATASET_DELETE_SUCCESS) {
      toast.success('DATASET DELETE SUCCESSFUL', {
        autoClose: 7500,
        onClose: () => {
          DatasetActions.clearListToast()
        }
      })
      DatasetActions.getDatasets()
      DatasetActions.clearListToast()
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_DATASETS) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Datasets</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'DatasetID'
            },
            fieldFunctions: {},
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.DatasetID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/dataset/' + e.DatasetID} id={'edit' + e.DatasetID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'batch' + e.DatasetID}>Vis Batch</Tooltip>
                    <Button tag={Link} to={'/dataset/' + e.DatasetID + '/batch'} id={'batch' + e.DatasetID}>
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    <Tooltip target={'import' + e.DatasetID}>Import</Tooltip>
                    <Button tag={Link} to={'/dataset/' + e.DatasetID + '/import'} id={'import' + e.DatasetID}>
                      <FontAwesomeIcon icon='file-import' />
                    </Button>
                    <Tooltip target={'delete' + e.DatasetID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.DatasetID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne dataset?')) {
                          DatasetActions.deleteDataset(e.DatasetID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            datasetTableName: 'Table',
            moduleName: 'Navn',
            moduleDescription: 'Beskrivelse',
            tableName: 'Titel',
            timeInsert: 'Dato'
          }}
          data={this.state.datasets}
        />
      </CollapsibleCard>
    )
  }
}
