import React, { useEffect, useState } from 'react'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import styled from 'styled-components'
import moment from 'moment'
import keyMirror from 'keymirror'
import _ from 'lodash'
import LineChart from 'Components/dashboard/charts/LineChart.js'
import TimePicker from '../../util/TimePicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Proxies from '../proxies'

const SELECTED_DATA_VIEW = keyMirror({
  YEAR: null,
  QUARTER: null
})

const DataView = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DataViewSelector = styled.button`
  color: #000;
  border: 0;
  background: transparent;
  text-decoration: ${props => props.active && 'underline;'}
  font-weight: ${props => props.active && '700;'}
  
  &:hover,
  &:focus {    
    text-decoration: underline;
    outline: 0;
  }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: relative;
  font-size: 18px;
  float: right;
  bottom: '482px';
  margin-right: 40px !important;
`

export default function UsersChartLine(props) {
  const { status } = props
  const [data, setData] = useState({})
  const [loadingState, setLoadingState] = useState(null)
  const [selectedDataView, setDataView] = useState(SELECTED_DATA_VIEW.YEAR)
  const [startDate, setStartDate] = useState(moment().format())
  const [endDate, setEndDate] = useState(moment().format())
  const [calenderToggle, setCalenderToggle] = useState(null)

  function formatMoment(input) {
    return moment(input).format('YYYY-MM-DD')
  }

  function setFilterToggle() {
    setCalenderToggle(prev => (prev === true ? false : true))
  }

  const getFilterDetails = () => {
    window.open(
      status === 'Declined'
        ? `/all-active-inactive-users/status=${status ? status : 'All'}&startDate=${formatMoment(startDate)}&endDate=${formatMoment(endDate)}`
        : `/all-active-inactive-users/status=${status ? status : 'All'}&endDate=${formatMoment(endDate)}`
    )
    setFilterToggle()
    setEndDate(moment().format())
  }

  const changeDataView = dataView => {
    setDataView(dataView)
  }

  const mappedData = {
    monthlyData: [
      {
        id: 'Aktive brugere',
        data: _.map(data && data.monthlyActiveUsers, value => ({ x: moment(value.month, 'MM/YYYY').format('MMM YY'), y: value.active }))
      },
      {
        id: 'Inaktive brugere',
        data: _.map(data && data.monthlyInactiveUsers, value => ({ x: moment(value.month, 'MM/YYYY').format('MMM YY'), y: value.inactive }))
      }
    ],
    quarterlyData: [
      {
        id: 'Aktive brugere',
        data: _.map(data && data.quarterlyActiveUsers, value => ({ x: moment(value.quarter, 'Q/YYYY').format('Qo[kvt.] YYYY'), y: value.active }))
      },
      {
        id: 'Inaktive brugere',
        data: _.map(data && data.quarterlyInactiveUsers, value => ({ x: moment(value.quarter, 'Q/YYYY').format('Qo[kvt.] YYYY'), y: value.inactive }))
      }
    ]
  }

  useEffect(() => {
    setLoadingState(DASHBOARD_COMPONENT_STATE.LOADING)
    Proxies.GET('reports/active-inactive-users-status', { status }).then(res => {
      setData(res)
      setLoadingState(DASHBOARD_COMPONENT_STATE.SUCCESS)
    })
  }, [])

  return (
    <DashboardComponent header={(status ? status : 'Alle') + ' Brugere'} state={loadingState}>
      {loadingState === DASHBOARD_COMPONENT_STATE.SUCCESS && (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
            <StyledFontAwesomeIcon onClick={() => setFilterToggle()} fixedWidth icon='calendar' />
            <DataView className='mb-1'>
              <DataViewSelector onClick={() => changeDataView(SELECTED_DATA_VIEW.YEAR)} active={selectedDataView === SELECTED_DATA_VIEW.YEAR}>
                År
              </DataViewSelector>
              <DataViewSelector onClick={() => changeDataView(SELECTED_DATA_VIEW.QUARTER)} active={selectedDataView === SELECTED_DATA_VIEW.QUARTER}>
                Kvartal
              </DataViewSelector>
            </DataView>
            {calenderToggle && (
              <div style={{ position: 'absolute', top: 30, zIndex: 1 }}>
                {status === 'Declined' ? (
                  <TimePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    getFilterDetails={getFilterDetails}
                  />
                ) : (
                  <TimePicker endDate={endDate} setEndDate={setEndDate} getFilterDetails={getFilterDetails} />
                )}
              </div>
            )}
          </div>
          <LineChart
            bottomTickRotation={selectedDataView === SELECTED_DATA_VIEW.YEAR ? -40 : -30}
            data={selectedDataView === SELECTED_DATA_VIEW.YEAR ? mappedData.monthlyData : mappedData.quarterlyData}
            tooltip={({ point: { data, serieId } }) => {
              let content

              if (serieId === 'Inaktive brugere') {
                content = (
                  <>
                    Inaktive brugere: <strong>{data.yFormatted}</strong>
                  </>
                )
              } else {
                content = (
                  <>
                    Aktive brugere: <strong>{data.yFormatted}</strong>
                  </>
                )
              }
              return (
                <div style={{ background: 'white', padding: '5px 9px', borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px' }}>
                  <span>{content}</span>
                </div>
              )
            }}
          />
        </>
      )}
    </DashboardComponent>
  )
}
