import React, { useState } from 'react'
import { Row, Col, Input, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import Proxies from 'Components/proxies'
import 'react-image-crop/dist/ReactCrop.css'

const StyledContentRow = styled(Row)`
  border-bottom: 3px dashed #ddd;
`

export default function VideoUpload({ onVideoUploaded }) {
  const [upVideo, setUpVideo] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [origVideo, setOrigVideo] = useState(null)

  const onSelectVideo = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpVideo(reader.result))
      const file = e.target.files[0]
      reader.readAsDataURL(file)
      setOrigVideo(file)
    }
  }

  const onApproveVideo = () => {
    setIsUploading(true)
    Proxies.uploadVideo(origVideo).then(responseJSON => {
      onVideoUploaded(responseJSON)
      setUpVideo(null)
      setIsUploading(false)
      setOrigVideo(null)
    })
  }

  return (
    <>
      <Row>
        <Col xs='3'>
          <label>Video</label>
        </Col>
        <Col xs='2'>
          <Input
            type='file'
            accept='video/*'
            onClick={e => (e.target.value = '') /*To enable uploading same image twice in a row*/}
            onChange={onSelectVideo}
            disabled={isUploading}
          />
        </Col>
        {upVideo && (
          <Col className='mt-3' xs={{ size: 10, offset: 3 }}>
            <Button onClick={onApproveVideo}>{isUploading ? <FontAwesomeIcon icon='spinner' spin /> : 'Godkend'}</Button>
          </Col>
        )}
      </Row>
      <StyledContentRow className='pb-3 mb-3'>
        {upVideo && (
          <Row>
            <Col xs='3'></Col>
            <Col>
              <>
                <video controls className='mt-2' src={upVideo} style={{ width: '33%' }} />
              </>
            </Col>
          </Row>
        )}
      </StyledContentRow>
    </>
  )
}
