import React from 'react'
import { BoxLegendSvg } from '@nivo/legends'

export const BarLegend = ({ height, legends, width }) => (
  <React.Fragment>
    {legends.map(legend => (
      <BoxLegendSvg key={JSON.stringify(legend.data.map(({ id }) => id))} {...legend} containerHeight={height} containerWidth={width} />
    ))}
  </React.Fragment>
)
