import React, { useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from 'reactstrap'
import moment from 'moment'

const Top8List = () => {
  const [top8s, setTop8s] = useState([])
  const [loading, setLoading] = useState(false)
  const [top8, setTop8] = useState('active')

  useEffect(() => {
    setLoading(true)
    getTop8s()
  }, [])

  function getTop8s() {
    Proxies.GET('Top8s/pool').then(responseJSON => {
      if (!responseJSON.error) {
        setTop8s(responseJSON)
      }
      setLoading(false)
    })
  }

  function RemoveTop8(Top8ID) {
    Proxies.POST('Top8s/removeTop8', { Top8ID }).then(responseJSON => {
      if (!responseJSON.error) {
        getTop8s()
      }
    })
  }

  return (
    <CollapsibleCard
      startOpen
      header={
        <strong>
          Top 8
          <Button tag={Link} to={'top8/new'} style={{ marginLeft: '16px' }}>
            Opret ny
          </Button>
          <ButtonGroup className='float-right'>
            <Button outline color='success' onClick={() => setTop8(top8 === 'active' ? 'both' : 'active')} active={top8 === 'active'}>
              Active
            </Button>
            <Button outline color='danger' onClick={() => setTop8(top8 === 'inactive' ? 'both' : 'inactive')} active={top8 === 'inactive'}>
              In-Active
            </Button>
          </ButtonGroup>
        </strong>
      }
    >
      {loading ? (
        <FontAwesomeIcon icon='spinner' spin />
      ) : (
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'name'
            },
            fieldFunctions: {
              endDate: e => {
                return moment(e.endDate).format('DD-MM-YYYY')
              },
              brands: e => {
                return e.brands.map(b => b.brandName).join(', ')
              },
              products: e => {
                return e.products.map(b => b.elementTitle).join(', ')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Button tag={Link} to={'/top8/' + e.Top8ID} id={'edit' + e.Top8ID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Button
                      id={'delete' + e.Top8ID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Are you sure you want to delete?')) {
                          RemoveTop8(e.Top8ID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            Top8ID: '#',
            products: 'Products',
            brands: 'Brands'
          }}
          data={top8s && top8 !== 'both' && !!top8s.length ? top8s.filter(item => item.removed === (top8 === 'inactive' ? 1 : 0)) : top8s}
        />
      )}
    </CollapsibleCard>
  )
}

export default Top8List
