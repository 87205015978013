import React, { useState } from 'react'
import DashboardComponent, { STATE as DASHBOARD_COMPONENT_STATE } from 'Components/dashboard/DashboardComponent.js'
import _ from 'lodash'
import styled from 'styled-components'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import { colorMap } from 'Components/dashboard/colorMap.js'
import { ResponsiveBar } from '@nivo/bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BarLegend } from 'Components/dashboard/charts/BarLegend'
import moment from 'moment'
import TimePicker from '../../util/TimePicker'

const ChartWrap = styled.div`
  height: 450px;
  cursor: pointer;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  float: right;
  position: relative;
  right: 40px;
  bottom: 440px;
`
const keys = ['Aktive dealer', 'Inaktive dealer']

export default function DealersActiveInactiveChart() {
  const [data, loadingState] = useDashboardData('reports/dealers-graph')
  const [filter, setFilter] = useState(null)
  const [startDate, setStartDate] = useState(moment().format())
  const [endDate, setEndDate] = useState(moment().format())
  const [calenderToggle, setCalenderToggle] = useState(null)

  function toDetails() {
    window.open(`/all-dealers-within-dates/startDate=${formatMoment(startDate)}&endDate=${formatMoment(endDate)}`)
    setCalenderToggle(!calenderToggle)
    setEndDate(moment().format())
  }
  function formatMoment(input) {
    return moment(input).format('YYYY-MM-DD')
  }

  const filteredData = filter
    ? _.map(data, value => ({
        MONTH: moment(value.month, 'MM/YYYY').format('MMM YY'),
        ...(filter === 'Aktive dealer' && { 'Aktive dealer': value.active, 'Inaktive dealer': 0 }),
        ...(filter === 'Inaktive dealer' && { 'Inaktive dealer': value.inactive, 'Aktive dealer': 0 })
      }))
    : _.map(data, value => ({
        MONTH: moment(value.month, 'MM/YYYY').format('MMM YY'),
        'Aktive dealer': value.active,
        'Inaktive dealer': value.inactive
      }))

  return (
    <DashboardComponent header={'Dealers'} state={loadingState}>
      {loadingState === DASHBOARD_COMPONENT_STATE.SUCCESS && (
        <>
          <ChartWrap onClick={e => toDetails(e)}>
            <ResponsiveBar
              data={filteredData.reverse()}
              keys={keys}
              indexBy={'MONTH'}
              groupMode='grouped'
              margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
              padding={0.3}
              colors={({ id }) => colorMap[`${id}Color`]}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -50
              }}
              layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              onClick={(value, event) => toDetails(event, value)}
              theme={{
                axis: { legend: { text: { fontWeight: 700, fontFamily: '"Open Sans", sans-serif' } } }
              }}
              enableLabel={false}
              labelSkipWidth={40}
              labelSkipHeight={16}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              tooltipFormat={value => new Intl.NumberFormat().format(value)}
              legends={[
                {
                  dataFrom: 'keys',
                  data: keys.map(id => ({
                    color: colorMap[`${id}Color`],
                    id,
                    label: id
                  })),
                  onClick: (d, event) => {
                    event.stopPropagation()
                    filter === d.label ? setFilter(null) : setFilter(d.label)
                  },
                  anchor: 'top',
                  direction: 'row',
                  justify: false,
                  translateX: -10,
                  translateY: -30,
                  itemsSpacing: 30,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 15
                }
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </ChartWrap>
          <StyledFontAwesomeIcon onClick={() => setCalenderToggle(true)} fixedWidth icon='calendar' />
          {calenderToggle && (
            <div style={{ position: 'absolute', top: 30, zIndex: 1 }}>
              <TimePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} getFilterDetails={toDetails} />
            </div>
          )}
        </>
      )}
    </DashboardComponent>
  )
}
