import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/cmsActions'

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    userReports: [],
    loadingState: null,
    reportDetails: {},
    brands: []
  },
  reducers: {
    resetReportsData: state => {
      state.userReports = []
      state.reportDetails = {}
      state.loadingState = null
    },
    setReportsData: (state, action) => {
      state.userReports = action.payload
    },
    resetUserReportsData: state => {
      state.userReports = []
    },
    updateReportsContent: (state, action) => {
      return {
        ...state,
        reportDetails: { ...state.reportDetails, [action.payload.name]: action.payload.value }
      }
    },
    reportsCreationSuccess: state => {
      state.loadingState = STATUS.FETCHING_USER_REPORTS_SUCCESS
    },
    reportsCreationFailure: state => {
      state.loadingState = STATUS.FETCHING_USER_REPORTS_FAILED
    },
    clearToast: state => {
      state.loadingState = false
    }
  }
})
export const {
  setReportsData,
  resetReportsData,
  updateReportsContent,
  reportsCreationSuccess,
  reportsCreationFailure,
  clearToast,
  resetUserReportsData
} = reportsSlice.actions
export default reportsSlice.reducer
