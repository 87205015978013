import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { VaultStore, VaultActions, STATUS } from 'Stores/vaultStore'
import VaultForm from 'Modules/vault/vaultForm'

export default class VaultCraete extends Component {
  constructor(props) {
    super(props)
    this.stores = [VaultStore]
    this.storeKeys = ['loadingState']
  }
  componentDidMount() {
    VaultActions.createEmptyVault()
  }
  componentDidUpdate() {
    if (this.state.loadingState === STATUS.VAULT_FAILED) {
      toast.error('CREATE VAULT FAILED', {
        autoClose: 3000,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast()
    }
    if (this.state.loadingState === STATUS.VAULT_SUCCESS) {
      toast.success('CREATED VAULT SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast(STATUS.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <VaultForm />
      </React.Fragment>
    )
  }
}
