import React, { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Row, Col, Input, UncontrolledTooltip, ButtonGroup } from 'reactstrap'
import { toast } from 'react-toastify'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'

const ChristmasCalendarForm = props => {
  const [loading, setLoading] = useState(false)
  const { CompetitionID } = props.match.params
  const [christmasCalendar, setChristmasCalender] = useState({})
  const [status, setStatus] = useState('LOADING')
  const [christmasCalendarImage, setChristmasCalendarImage] = useState(null)
  const [christmasCalendarBannerImage, setChristmasCalendarBannerImage] = useState(null)
  const [christmasCalendarDays, setChristmasCalenderDays] = useState([])
  const [christmasCalendarDaysFilterValue, setchristmasCalendarDaysFilterValue] = useState('active')

  const view = props?.match.path === '/christmas-calendar/:CompetitionID/view' ? true : false

  function saveChristmasCalendar() {
    if (christmasCalendarImage) {
      christmasCalendar.ChristmasImageID = christmasCalendarImage.ImageID
    } else {
      delete christmasCalendar.ChristmasImageID
    }
    if (christmasCalendarBannerImage) {
      christmasCalendar.ImageID = christmasCalendarBannerImage.ImageID
    } else {
      delete christmasCalendar.ImageID
    }
    if (!christmasCalendar.startDate) {
      christmasCalendar.startDate = new Date()
    }
    if (!christmasCalendar.endDate) {
      christmasCalendar.endDate = new Date()
    }
    Proxies.POST('competitionV2/createChristmasCalendar', christmasCalendar).then(responseJSON => {
      if (responseJSON.error) {
        setStatus('FAILED')
      } else {
        setStatus('SUCCESS')
      }
    })
  }

  const uploadChristmasCalendarImage = data => {
    let file = data.target.files[0]
    let name = data.target.name
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        if (name === 'image') {
          setChristmasCalendarBannerImage(responseJSON)
        } else {
          setChristmasCalendarImage(responseJSON)
        }
        setChristmasCalender({ ...christmasCalendar, [name]: responseJSON })
      }
    })
  }

  const fetchChristmasCalendar = () => {
    setLoading(true)
    Proxies.GET('competitionV2/fetch', { CompetitionID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setChristmasCalender(responseJSON)
      }
      setLoading(false)
    })
  }

  const resetData = () => {
    if (CompetitionID) {
      fetchChristmasCalendar()
      getChristmasCalendarDays()
    } else {
      setChristmasCalender({})
    }
  }

  const updateChristmasCalendar = ({ target }) => {
    if (target.name === 'imageTextColor') {
      target.value = target.id
    }
    setChristmasCalender({ ...christmasCalendar, [target.name]: target.value })
  }

  useEffect(() => {
    if (CompetitionID) {
      fetchChristmasCalendar(CompetitionID)
      getChristmasCalendarDays()
    }
  }, [])

  function getChristmasCalendarDays() {
    Proxies.GET('competitionV2/fetchChristmasDays', { CompetitionID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setChristmasCalenderDays(responseJSON)
      }
    })
  }

  function RemoveChristmasCalendarDay(ChristmasDayID) {
    Proxies.POST('competitionV2/removeDay', { ChristmasDayID: Number(ChristmasDayID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        getChristmasCalendarDays()
      }
    })
  }

  if (status === 'SUCCESS') {
    toast.success(CompetitionID ? 'Successfully Updated' : 'Successfully Created', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
    return <Redirect to='/christmas-calendar' />
  }

  if (status === 'FAILED') {
    toast.error('Error while saving christmas calendar', {
      autoClose: 5000,
      onClose: () => {
        setStatus('LOADING')
      }
    })
  }

  return (
    <>
      <CollapsibleCard startOpen={true} header={<strong>Julekalender</strong>}>
        <Form>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Title</label>
            </Col>
            <Col xs='10'>
              <Input disabled={view} type='text' value={christmasCalendar.title} name='title' id='title' onChange={e => updateChristmasCalendar(e)} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Short Description</label>
            </Col>
            <Col xs='10'>
              <Input
                type='text'
                disabled={view}
                value={christmasCalendar.shortDescription ? christmasCalendar.shortDescription : ''}
                name='shortDescription'
                id='shortDescription'
                onChange={e => updateChristmasCalendar(e)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Detail Description</label>
            </Col>
            <Col xs='10'>
              <Input
                type='textarea'
                disabled={view}
                value={christmasCalendar.detailDescription ? christmasCalendar.detailDescription : ''}
                name='detailDescription'
                id='detailDescription'
                onChange={e => updateChristmasCalendar(e)}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Start Date</label>
            </Col>
            <Col xs='10'>
              <DatePicker
                className='form-control'
                disabled={view}
                minDate={moment().add(1, 'days')}
                selected={moment(christmasCalendar.startDate)}
                dateFormat={'DD/MM/YYYY'}
                viewMode={'days'}
                name='startDate'
                id='startDate'
                onChange={e => {
                  updateChristmasCalendar({ target: { name: 'startDate', value: e.format() } })
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>End Date</label>
            </Col>
            <Col xs='10'>
              <DatePicker
                className='form-control'
                disabled={view}
                minDate={moment().add(1, 'days')}
                selected={moment(christmasCalendar.endDate)}
                dateFormat={'DD/MM/YYYY'}
                viewMode={'days'}
                name='endDate'
                id='endDate'
                onChange={e => {
                  updateChristmasCalendar({ target: { name: 'endDate', value: e.format() } })
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Select status</label>
            </Col>
            <Col xs='10'>
              <Input
                disabled={view}
                type='select'
                value={christmasCalendar.status}
                name='status'
                id='status'
                onChange={e => updateChristmasCalendar(e)}
              >
                <option value='READY'>Draft</option>
                <option value='STARTED'>Published</option>
              </Input>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Banner Image</label>
              <span>
                <FontAwesomeIcon icon='info-circle' id='profileImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='profileImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
              </span>
            </Col>
            <Col xs='10'>
              <Input
                name='image'
                disabled={view}
                field='upload'
                type='file'
                data={{ upload: '' }}
                noValue={christmasCalendar.image !== ''}
                onChange={e => {
                  uploadChristmasCalendarImage(e)
                }}
                accept='image/*'
              />
              <img
                src={christmasCalendar.image && christmasCalendar.image.imageOriginSource ? christmasCalendar.image.imageOriginSource : ''}
                alt={christmasCalendar.image && christmasCalendar.image.imageOriginSource ? christmasCalendar.image.imageOriginSource : ''}
                style={{
                  height: '120px',
                  width: '288px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: christmasCalendar.image && christmasCalendar.image.imageOriginSource ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Christmas Calendar Image</label>
              <span>
                <FontAwesomeIcon icon='info-circle' id='profileImageTooltip' style={{ color: 'blue' }} />
                <UncontrolledTooltip target='profileImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
              </span>
            </Col>
            <Col xs='10'>
              <Input
                name='christmasImage'
                field='upload'
                type='file'
                disabled={view}
                data={{ upload: '' }}
                noValue={christmasCalendar.christmasImage !== ''}
                onChange={e => {
                  uploadChristmasCalendarImage(e)
                }}
                accept='image/*'
              />
              <img
                src={
                  christmasCalendar.christmasImage && christmasCalendar.christmasImage.imageOriginSource
                    ? christmasCalendar.christmasImage.imageOriginSource
                    : ''
                }
                alt={
                  christmasCalendar.christmasImage && christmasCalendar.christmasImage.imageOriginSource
                    ? christmasCalendar.christmasImage.imageOriginSource
                    : ''
                }
                style={{
                  height: '120px',
                  width: '288px',
                  objectFit: 'cover',
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: christmasCalendar.christmasImage && christmasCalendar.christmasImage.imageOriginSource ? 'inline-block' : 'none'
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='2'>
              <label>Image Text Color</label>
            </Col>
            <Col xs='10'>
              <Input
                name='imageTextColor'
                type='radio'
                id='white'
                disabled={view}
                checked={christmasCalendar.imageTextColor === 'white' && true}
                onChange={e => updateChristmasCalendar(e)}
              />
              <label>White</label>
              <br></br>
              <Input
                name='imageTextColor'
                type='radio'
                id='black'
                disabled={view}
                checked={christmasCalendar.imageTextColor === 'black' && true}
                onChange={e => updateChristmasCalendar(e)}
              />
              <label>Black</label>
            </Col>
          </Row>
          <Button onClick={saveChristmasCalendar} disabled={loading || view}>
            {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button disabled={view} competition='danger' onClick={resetData} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
      {CompetitionID && (
        <CollapsibleCard
          startOpen
          header={
            <strong>
              Dage
              <Button disabled={view} tag={Link} to={`/christmas-calendar/${CompetitionID}/day/new`} style={{ marginLeft: '16px' }}>
                Opret ny
              </Button>
              <ButtonGroup className='float-right'>
                <Button
                  outline
                  color='success'
                  onClick={() => setchristmasCalendarDaysFilterValue(christmasCalendarDaysFilterValue === 'active' ? 'both' : 'active')}
                  active={christmasCalendarDaysFilterValue === 'active'}
                >
                  Active
                </Button>
                <Button
                  outline
                  color='danger'
                  onClick={() => setchristmasCalendarDaysFilterValue(christmasCalendarDaysFilterValue === 'inactive' ? 'both' : 'inactive')}
                  active={christmasCalendarDaysFilterValue === 'inactive'}
                >
                  In-Active
                </Button>
              </ButtonGroup>
            </strong>
          }
        >
          {loading ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            <DynamicTable
              responsive
              size='sm'
              options={{
                startSort: {
                  dir: 'DESC',
                  column: 'name'
                },
                fieldFunctions: {
                  christmasDayDate: e => {
                    return e.christmasDayDate ? moment(e.christmasDayDate).format('DD-MM-YYYY') : ''
                  },
                  winner: e => {
                    return e.winner ? `${e.winner.userName} (${e.winner.UserID})` : ''
                  }
                },
                addField: e => {
                  return (
                    <td>
                      <ButtonGroup>
                        {/* <Button
                        tag={Link}
                        to={{ pathname: `/christmas-calendar/${CompetitionID}/day/${e.ChristmasDayID}`, state: e }}
                        id={'view' + e.ChristmasDayID}
                      >
                        <FontAwesomeIcon icon='eye' />
                      </Button> */}
                        <Button
                          tag={Link}
                          to={{ pathname: `/christmas-calendar/${CompetitionID}/day/${e.ChristmasDayID}`, state: e }}
                          id={'edit' + e.ChristmasDayID}
                        >
                          <FontAwesomeIcon icon='edit' />
                        </Button>
                        <Button
                          id={'delete' + e.ChristmasDayID}
                          color='danger'
                          onClick={() => {
                            if (confirm('Are you sure you want to delete?')) {
                              RemoveChristmasCalendarDay(e.ChristmasDayID)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon='trash' />
                        </Button>
                      </ButtonGroup>
                    </td>
                  )
                }
              }}
              headers={{
                ChristmasDayID: '#',
                dayNumber: 'Dag',
                christmasDayDate: 'Date',
                description: 'Beskrivelse',
                question: 'Spørgsmål',
                ProductID: 'Præmie',
                winner: 'Winner'
              }}
              data={
                christmasCalendarDays && christmasCalendarDaysFilterValue !== 'both' && !!christmasCalendarDays.length
                  ? christmasCalendarDays.filter(
                      christmasCalendarDay => christmasCalendarDay.removed === (christmasCalendarDaysFilterValue === 'inactive' ? 1 : 0)
                    )
                  : christmasCalendarDays
              }
            />
          )}
        </CollapsibleCard>
      )}
    </>
  )
}

export default ChristmasCalendarForm
