import React, { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import { toast } from 'react-toastify'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'

const { GET, POST } = Proxies

const ShopOverview = () => {
  const [loading, setLoading] = useState(false)
  const [shops, setShops] = useState([])

  const fetchShops = () => {
    setLoading(true)
    GET('shopbanners/getMultiple').then(responseJSON => {
      if (!responseJSON.error) {
        setShops(responseJSON)
      }
      setLoading(false)
    })
  }

  const RemoveShop = ShopBannerID => {
    POST('shopbanners/removeShopBanner', { ShopBannerID }).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('success', {
        autoClose: 7000
      })
      fetchShops()
    })
  }

  useEffect(() => {
    fetchShops()
  }, [])

  return (
    <>
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Shops
            <Button className='float-right' tag={Link} to={'shops/new'} style={{ marginLeft: '16px' }}>
              Opret ny
            </Button>
          </strong>
        }
      >
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'ShopBannerID'
              },
              fieldFunctions: {
                brands: e => {
                  return e.brands.map(b => b.brandName).join(', ')
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.ShopBannerID}>Redigere</Tooltip>
                      <Button id={'edit' + e.ShopBannerID} tag={Link} to={'/shops/' + e.ShopBannerID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      <Tooltip target={'delete' + e.ShopBannerID}>Slet</Tooltip>
                      <Button
                        id={'delete' + e.ShopBannerID}
                        color='danger'
                        onClick={() => {
                          if (confirm('Are you sure you want to delete?')) {
                            RemoveShop(e.ShopBannerID)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              ShopBannerID: '#',
              brands: 'Brands',
              isVideo: 'isVideo',
              textColor: 'Text Color',
              title: 'Title'
            }}
            data={shops}
          />
        )}
      </CollapsibleCard>
    </>
  )
}

export default ShopOverview
