import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row, Table, UncontrolledTooltip } from 'reactstrap'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapsibleCard from 'Components/collapsibleCard'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import { StyledDropdown } from '../campaigns/SantanderCampaignUserSelect'

const TaxCampaignHighlightForm = props => {
  const [taxCampaignHighlight, setTaxCampaignHighlight] = useState({})
  const [languages, setLanguages] = useState([])
  const [taxCampaignHighlightData, setTaxCampaignHighlightData] = useState([{}])
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('LOADING')

  const { TaxCampaignHighlightID } = props.match.params

  function getLanguages(BrandIDs) {
    if (!BrandIDs || !BrandIDs.length) return
    Proxies.GET('brands/brand-languages', { BrandIDs }).then(responseJSON => {
      if (!responseJSON.error) {
        setLanguages(responseJSON)
      }
    })
  }

  const fetchBrands = () => {
    Proxies.GET('brands/getAssociatedBrands').then(responseJSON => {
      if (responseJSON.error) return setBrands([])
      let allbrands = responseJSON.map(item => ({ BrandID: item.BrandID, brandName: item.brandName }))
      allbrands.sort((a, b) => a.brandName.localeCompare(b.brandName))
      setBrands(allbrands)
    })
  }

  const handleChange = e => {
    setTaxCampaignHighlight({
      ...taxCampaignHighlight,
      [e.target.name]:
        e.target.type === 'checkbox' || e.target.type === 'radio'
          ? e.target.checked
          : e.target.type === 'number' || e.target.type === 'select-one'
          ? Number(e.target.value)
          : e.target.value
    })
  }

  const updateBrands = item => {
    let selectedBrand = brands.map(brand => {
      if (Number(brand.BrandID) === Number(item.value)) brand.checked = item.checked
      return brand
    })
    setBrands(selectedBrand)
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleChange({ target: { name: 'BrandIDs', value: BrandIDs } })
  }

  const uploadLoginImageURL = data => {
    let file = data.target.files[0]
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        handleChange({ target: { value: responseJSON.imageOriginSource ? responseJSON.imageOriginSource : '', name: 'imageUrl' } })
      }
    })
  }

  const fetchTaxCampaignHighlight = () => {
    setLoading(true)
    Proxies.GET('TaxCampaignHighlights/fetch', { TaxCampaignHighlightID }).then(responseJSON => {
      setLoading(false)
      if (!responseJSON.error) {
        setTaxCampaignHighlight(responseJSON)
        setTaxCampaignHighlightData(responseJSON.translations.length ? responseJSON.translations : [{ title: '', LanguageID: '', content: '' }])
        !!responseJSON && !!responseJSON.BrandIDs && !!responseJSON.BrandIDs.length && getLanguages(responseJSON.BrandIDs)
      }
    })
  }

  const replaceTaxCampaignHighlight = () => {
    Proxies.POST(`TaxCampaignHighlights/replace`, {
      ...taxCampaignHighlight,
      translations: taxCampaignHighlightData,
      title: taxCampaignHighlightData[0].title || '',
      description: taxCampaignHighlightData[0].description
    }).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('TaxCampaignHighlight updated successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(
          `BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another Tax Campaign Highlight`,
          {
            autoClose: 7000
          }
        )
      }
      setStatus('SUCCESS')
    })
  }

  const submitTaxCampaignHighlight = () => {
    Proxies.POST(`TaxCampaignHighlights/saveMultiple`, [
      {
        ...taxCampaignHighlight,
        translations: taxCampaignHighlightData,
        title: taxCampaignHighlightData[0].title || '',
        description: taxCampaignHighlightData[0].description
      }
    ]).then(responseJSON => {
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('TaxCampaignHighlight created successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(
          `BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another Tax Campaign Highlight`,
          {
            autoClose: 7000
          }
        )
      }
      setStatus('SUCCESS')
    })
  }

  useEffect(() => {
    fetchBrands()
    if (TaxCampaignHighlightID) {
      fetchTaxCampaignHighlight()
    }
  }, [])

  const editBrandIDS = item => {
    let selectedBrand = brands.map(brand => {
      for (let i = 0; i < item?.length; i++) {
        if (Number(brand.BrandID) === Number(item[i])) {
          brand.checked = true
          break
        }
      }
      return brand
    })
    let BrandIDs = selectedBrand.filter(e => !!e.checked).map(e => e.BrandID)
    handleChange({ target: { name: 'BrandIDs', value: BrandIDs } })
  }

  useEffect(() => {
    if (!!TaxCampaignHighlightID && brands.length > 0) {
      return editBrandIDS(taxCampaignHighlight.BrandIDs)
    }
    getLanguages(taxCampaignHighlight.BrandIDs)
  }, [brands])

  if (status === 'SUCCESS') {
    return <Redirect to='/tax-campaign-highlight' />
  }

  return loading ? (
    <FontAwesomeIcon icon='spinner' spin />
  ) : (
    <CollapsibleCard startOpen header={<strong>Højdepunkter i skattekampagnen</strong>}>
      <Button
        className='float-right'
        onClick={() => {
          setTaxCampaignHighlightData([
            ...taxCampaignHighlightData,
            {
              LanguageID: '',
              title: '',
              description: ''
            }
          ])
        }}
      >
        <FontAwesomeIcon icon='plus' />
      </Button>
      <Form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <FormGroup>
          <Row>
            <Col xs='3'>
              <Label for='BrandID'> Brands </Label>
            </Col>
            <Col xs='9'>
              <StyledDropdown
                required={true}
                data={
                  !brands.length
                    ? {}
                    : {
                        label: 'All',
                        value: 'all',
                        expanded: true,
                        children: brands.map(item => ({
                          label: `${item.BrandID} - ${item.brandName}`,
                          value: item.BrandID,
                          checked: item.checked
                        }))
                      }
                }
                onChange={item => updateBrands(item)}
                keepTreeOnSearch={true}
                keepChildrenOnSearch={true}
                showPartiallySelected={true}
                texts={{
                  placeholder: !brands.length ? 'Ingen brand fundet' : 'Vælg brand',
                  noMatches: 'Ingen brand fundet',
                  labelRemove: 'Fjern'
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        {!!taxCampaignHighlightData &&
          !!taxCampaignHighlightData.length &&
          taxCampaignHighlightData.map((event, idx) => (
            <TaxCampaignHighlightMultiple
              key={idx}
              idx={idx}
              event={event}
              languages={languages}
              taxCampaignHighlightData={taxCampaignHighlightData}
              setTaxCampaignHighlightData={setTaxCampaignHighlightData}
            />
          ))}
        <FormGroup row>
          <Label for='image' sm={3}>
            Image{' '}
            <span>
              <FontAwesomeIcon icon='info-circle' id='taxCampaignHighlightImageTooltip' style={{ color: 'blue' }} />
              <UncontrolledTooltip target='taxCampaignHighlightImageTooltip'>Best Size for Image: 1440 x 600 </UncontrolledTooltip>
            </span>
          </Label>
          <Col sm={9}>
            <Input
              name='imageUrl'
              field='upload'
              type='file'
              data={{ upload: '' }}
              onChange={e => {
                uploadLoginImageURL(e)
              }}
              accept='image/*'
            />
            <img
              draggable='false'
              src={taxCampaignHighlight.imageUrl}
              alt={taxCampaignHighlight.imageUrl}
              style={{
                height: '100px',
                width: '100px',
                objectFit: 'cover',
                marginTop: '10px',
                marginBottom: '10px',
                display: taxCampaignHighlight.imageUrl ? 'inline-block' : 'none'
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='TaxCampaignPostID' sm={3}>
            TaxCampaignPostID
          </Label>
          <Col sm={9}>
            <Input
              type='number'
              name='TaxCampaignPostID'
              id='TaxCampaignPostID'
              value={taxCampaignHighlight.TaxCampaignPostID ? taxCampaignHighlight.TaxCampaignPostID : ''}
              onChange={handleChange}
              placeholder='Tax Campaign Post ID'
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='textColor' sm={3}>
            Text Color
          </Label>
          <Col sm={3}>
            <Col sm={7} style={{ display: 'flex', gap: '20px' }}>
              <Label for='textColorWhite'>White</Label>
              <Input
                id='textColorWhite'
                name='textColor'
                type='checkbox'
                checked={taxCampaignHighlight.textColor === 'white' ? true : false}
                onChange={e => handleChange({ target: { name: 'textColor', value: e.target.checked ? 'white' : 'black' } })}
              />
            </Col>
            <Col sm={7} style={{ display: 'flex', gap: '20px' }}>
              <Label for='textColorBlack'> Black</Label>
              <Input
                id='textColorBlack'
                name='textColor'
                type='checkbox'
                checked={taxCampaignHighlight.textColor === 'black' ? true : false}
                onChange={e => handleChange({ target: { name: 'textColor', value: e.target.checked ? 'black' : 'white' } })}
              />
            </Col>
          </Col>
        </FormGroup>
        <Button
          onClick={() => {
            TaxCampaignHighlightID ? replaceTaxCampaignHighlight() : submitTaxCampaignHighlight()
          }}
        >
          Gem
        </Button>
      </Form>
    </CollapsibleCard>
  )
}

function TaxCampaignHighlightMultiple(props) {
  const { idx, languages, taxCampaignHighlightData, setTaxCampaignHighlightData, event } = props

  return (
    <Table style={{ borderBottom: '1px solid #e8e8e8' }}>
      <FormGroup row>
        <Label for='LanguageID' sm={3}>
          Language
        </Label>
        <Col>
          <Input
            className={event.LanguageID ? 'is-valid' : 'is-invalid'}
            required
            type='select'
            name='LanguageID'
            value={event.LanguageID ? event.LanguageID : ''}
            id='LanguageID'
            onChange={e => {
              let newTaxCampaignHighlightData = [...taxCampaignHighlightData]
              newTaxCampaignHighlightData[idx]['LanguageID'] = Number(e.target.value)
              setTaxCampaignHighlightData(newTaxCampaignHighlightData)
            }}
          >
            <option value=''>Click to Select</option>
            {languages.map((lan, i) => {
              return (
                <option value={lan.LanguageID} key={i}>
                  {`${lan.languageKey} - ${lan.languageName}`}
                </option>
              )
            })}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='title' sm={3}>
          {' '}
          Title
        </Label>
        <Col>
          <Input
            required
            id='title'
            className={event.title ? 'is-valid' : 'is-invalid'}
            value={event.title ? event.title : ''}
            name='title'
            onChange={e => {
              let newTaxCampaignHighlightData = [...taxCampaignHighlightData]
              newTaxCampaignHighlightData[idx]['title'] = e.target.value
              setTaxCampaignHighlightData(newTaxCampaignHighlightData)
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='description' sm={3}>
          Description
        </Label>
        <Col>
          <Input
            required
            type='textarea'
            id='description'
            className={event.description ? 'is-valid' : 'is-invalid'}
            value={event.description ? event.description : ''}
            name='description'
            onChange={e => {
              let newTaxCampaignHighlightData = [...taxCampaignHighlightData]
              newTaxCampaignHighlightData[idx]['description'] = e.target.value
              setTaxCampaignHighlightData(newTaxCampaignHighlightData)
            }}
          />
        </Col>
      </FormGroup>
    </Table>
  )
}

export default TaxCampaignHighlightForm
