import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { StatActions, StatStore, STATE } from 'Stores/statStore'
import StatForm from 'Modules/stat/statForm'

export default class StatEdit extends Component {
  constructor(props) {
    super(props)
    this.stores = [StatStore]
    this.storeKeys = ['loadingState', 'isAdminAccess']
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      StatActions.giveAccess()
    }
    StatActions.getStatDetails(this.props.match.params.StatID)
  }
  componentDidUpdate() {
    if (this.state.loadingState === STATE.FETCHING_STAT_FAILED) {
      toast.error('FETCHING STAT FAILED', {
        autoClose: 7500,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.clearToast()
    }

    if (this.state.loadingState === STATE.STAT_FAILED) {
      toast.error('UPDATE STAT FAILED', {
        autoClose: 3000,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.clearToast()
    }
    if (this.state.loadingState === STATE.STAT_SUCCESS) {
      toast.success('UPDATE STAT SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.clearToast(STATE.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <StatForm {...this.props} />
      </React.Fragment>
    )
  }
}
