import React, { useEffect, useState } from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Button, Input, Col, FormGroup, Label } from 'reactstrap'
import styled from 'styled-components'
import Proxies from 'Components/proxies'
import { currencyFormatter } from 'Util/currencyFormatter'
import { Link } from 'react-router-dom'

const { GET, POST } = Proxies

const StyledSpan = styled.span`
  margin: 0px 15px !important;
`
export default function AdminToBeInvoiced() {
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState([])

  const markInvoicingCompleted = (completed, InvoiceID) => {
    POST('invoices/markAsCompleted', { completed: +completed, InvoiceID: +InvoiceID }).then(() => {
      fetchInvoices()
    })
  }
  const fetchInvoices = () => {
    setLoading(true)
    GET('invoices/latestInvoices')
      .then(responseJSON => {
        if (responseJSON.length) {
          setBrands([
            ...responseJSON,
            {
              brandName: 'Total',
              latestClosedInvoice: responseJSON.reduce((acc, cur) => acc + (cur.latestClosedInvoice || 0), 0),
              latestClosedInvoiceBasis: responseJSON.reduce((acc, cur) => acc + (cur.latestClosedInvoiceBasis || 0), 0)
            }
          ])
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchInvoices()
  }, [])

  return loading ? (
    <FontAwesomeIcon icon={'spinner'} spin />
  ) : (
    <CollapsibleCard startOpen header={<strong>Invoices to Raise</strong>}>
      <DynamicTable
        responsive
        data={brands}
        headers={{
          InvoiceID: 'InvoiceID',
          brandName: 'Brand',
          latestClosedInvoice: 'Latest Closed Amount',
          latestClosedInvoiceBasis: 'Latest Closed Basis'
        }}
        headerscss={{
          latestClosedInvoice: { textAlign: 'right', whiteSpace: 'nowrap' },
          latestClosedInvoiceBasis: { textAlign: 'right', whiteSpace: 'nowrap' }
        }}
        options={{
          addField: e => {
            return e.BrandID ? (
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip target={'view' + e.BrandID}>Vis</Tooltip>
                  <Button tag={Link} to={`/admininvoice/${e.BrandID}/${e.InvoiceID}`} id={'view' + e.BrandID}>
                    <FontAwesomeIcon icon='eye' />
                  </Button>
                  <Col>
                    <FormGroup check>
                      <Input
                        id='invoiceCheckbox'
                        name='checkbox'
                        type='checkbox'
                        checked={e.invoicingCompleted}
                        onChange={event => markInvoicingCompleted(event.target.checked, e.InvoiceID)}
                      />
                      <Label check for='invoiceCheckbox'>
                        Mark Done
                      </Label>
                    </FormGroup>
                  </Col>
                </div>
              </td>
            ) : null
          },
          fieldFunctions: {
            brandName: e => {
              return <span style={{ fontWeight: e.brandName === 'Total' ? 'bold' : undefined }}>{e.brandName}</span>
            },
            latestClosedInvoice: e => {
              return (
                <StyledSpan style={{ fontWeight: e.brandName === 'Total' ? 'bold' : undefined }}>
                  {e.latestClosedInvoice ? currencyFormatter.format(e.latestClosedInvoice) : '0,00 kr.'}
                </StyledSpan>
              )
            },
            latestClosedInvoiceBasis: e => {
              return (
                <StyledSpan style={{ fontWeight: e.brandName === 'Total' ? 'bold' : undefined }}>
                  {e.latestClosedInvoice ? currencyFormatter.format(e.latestClosedInvoiceBasis) : '0,00 kr.'}
                </StyledSpan>
              )
            }
          },
          fieldExportFunctions: {
            latestClosedInvoice: e => {
              return e.latestClosedInvoice ? currencyFormatter.format(e.latestClosedInvoice) : '0,00 kr.'
            },
            latestClosedInvoiceBasis: e => {
              return e.latestClosedInvoice ? currencyFormatter.format(e.latestClosedInvoiceBasis) : '0,00 kr.'
            }
          }
        }}
      />
    </CollapsibleCard>
  )
}
