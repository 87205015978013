import { createAction } from '@reduxjs/toolkit'
import Proxies from 'Components/proxies'
import _ from 'lodash'

import {
  setBatchOverviewData,
  resetBatchOverviewData,
  fetchBatchElementsRequest,
  setBatchElements,
  fetchBatchElementsFailed,
  batchInsertInitiated,
  batchInsertSuccess,
  batchInsertFailure,
  setBatchDetails,
  fetchingBatchDetailsFailed,
  fetchingBatchDetailsRequest
} from 'Reducers/dataImportReducer'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  LOADING_BATCH_DETAILS: null,
  LOADING_BATCH_DETAILS_SUCCESS: null,
  LOADING_BATCH_DETAILS_FAILED: null,
  INSERT_BATCH_PROGRESS: null,
  INSERT_BATCH_SUCCESS: null,
  INSERT_BATCH_FAILURE: null,
  FETCHING_BATCH_DETAILS: null,
  FETCHING_BATCH_DETAILS_FAILED: null,
  FETCHING_BATCH_DETAILS_SUCCESS: null
})

let enableWalletActivation = false

export const fetchBatchesRequest = createAction('fetchBatchesRequest')

export const fetchBatches = data => dispatch => {
  Proxies.GET('batch/pool', data).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(resetBatchOverviewData())
    } else if (data.enableWalletActivation) {
      enableWalletActivation = data.enableWalletActivation
      Proxies.GET('batch/pool', { DatasetID: 103 }).then(responseJSONNew => {
        let response = _.merge(responseJSON, responseJSONNew)
        response = _.sortBy(response, 'timeInsert')
        dispatch(setBatchOverviewData(response))
      })
    } else {
      dispatch(setBatchOverviewData(responseJSON))
    }
  })
}

export const getBatchElements = BatchID => dispatch => {
  dispatch(fetchBatchElementsRequest())
  Proxies.GET('/batch/preview', { BatchID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchBatchElementsFailed())
    } else {
      dispatch(setBatchElements(responseJSON))
    }
  })
}

export const insertBatch = (BatchID = null, DatasetID = null, isPreview = false) => (dispatch, getState) => {
  if (getState().dataImport.batchInsertStatus === STATUS.INSERT_BATCH_PROGRESS) {
    return
  }
  if (BatchID) {
    dispatch(batchInsertInitiated(BatchID))
    Proxies.POST('batch/insert', { BatchID: BatchID }).then(responseJSON => {
      if (responseJSON) {
        if (responseJSON.error) {
          dispatch(batchInsertFailure(responseJSON.error.message))
        } else {
          dispatch(batchInsertSuccess())
          isPreview ? dispatch(getBatchDetails(BatchID)) : dispatch(fetchBatches({ DatasetID: DatasetID, enableWalletActivation }))
        }
      }
    })
  }
}

export const getBatchDetails = BatchID => dispatch => {
  dispatch(fetchingBatchDetailsRequest())
  Proxies.GET('batch/fetch', { BatchID }).then(responseJSON => {
    if (responseJSON && responseJSON.error) {
      dispatch(fetchingBatchDetailsFailed())
    } else {
      dispatch(setBatchDetails(responseJSON))
    }
  })
}
