import { useEffect, useState } from 'react'
import Proxies from 'Components/proxies'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  LOADING: null,
  SUCCESS: null,
  FAILED: null
})

let promiseMap = {}

export default function useDashboardData(endpoint, query) {
  const [loadingStatus, setLoadingStatus] = useState(null)
  const [data, setData] = useState()

  const resolvePromise = (promise, key) => {
    setLoadingStatus(STATUS.LOADING)
    promise
      .then(responseJSON => {
        if (responseJSON.error) {
          setLoadingStatus(STATUS.FAILED)
        } else {
          setLoadingStatus(STATUS.SUCCESS)
          setData(responseJSON)
        }
      })
      .catch(() => {
        setLoadingStatus(STATUS.FAILED)
      })
      .finally(() => {
        delete promiseMap[key]
      })
  }

  useEffect(() => {
    const cachedPromise = promiseMap[endpoint]
    if (cachedPromise) {
      resolvePromise(cachedPromise, endpoint)
    } else {
      const fetchPromise = Proxies.GET(endpoint, query)
      promiseMap[endpoint] = fetchPromise
      resolvePromise(fetchPromise, endpoint)
    }
  }, [])

  return [data, loadingStatus]
}
