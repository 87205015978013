import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { getCustomerProducts, STATUS, deleteCustomerProduct } from 'Reducers/actions/customerProductActions'
import { connect } from 'react-redux'
import { clearToast, toggleActiveCustomerProduct, toggleInActiveCustomerProduct } from 'Reducers/customerProductReducer'

class CustomerProductsOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getCustomerProducts()
  }

  componentDidUpdate() {
    if (this.props.overviewLoadingState === STATUS.DELETE_CUSTOMER_PRODUCT_SUCCESS) {
      this.props.getCustomerProducts()
    }
  }

  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Klient Produkter</strong>}>
        {this.props.overviewLoadingState === STATUS.LOADING_CUSTOMER_PRODUCTS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'CustomerProductID'
              },
              fieldFunctions: {},
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.CustomerProductID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/customerproducts/' + e.CustomerProductID} id={'edit' + e.CustomerProductID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'delete' + e.CustomerProductID}>Slet</Tooltip>
                          <Button
                            id={'delete' + e.CustomerProductID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du Slet denne CustomerProduct?')) {
                                this.props.deleteCustomerProduct({ CustomerProductID: e.CustomerProductID, removed: 1 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='trash' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip target={'actiavte' + e.CustomerProductID}>Aktivér</Tooltip>
                          <Button
                            id={'actiavte' + e.CustomerProductID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du aktivere denne CustomerProduct?')) {
                                this.props.deleteCustomerProduct({ CustomerProductID: e.CustomerProductID, removed: 0 })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              CustomerProductID: '#',
              moduleName: 'Navn',
              productUID: 'ProduktUID'
            }}
            data={
              this.props.customerProducts && this.props.customerProductFilterValue !== 'both' && !!this.props.customerProducts.length
                ? this.props.customerProducts.filter(
                    customerProduct => customerProduct.removed === (this.props.customerProductFilterValue === 'inactive' ? 1 : 0)
                  )
                : this.props.customerProducts
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({
  showInActiveCustomerProducts: state.customerProduct.showInActiveCustomerProducts,
  showActiveCustomerProducts: state.customerProduct.showActiveCustomerProducts,
  customerProducts: state.customerProduct.customerProducts,
  overviewLoadingState: state.customerProduct.overviewLoadingState,
  customerProductFilterValue: state.customerProduct.customerProductFilterValue
})

export default connect(mapStateToProps, {
  getCustomerProducts,
  deleteCustomerProduct,
  clearToast,
  toggleActiveCustomerProduct,
  toggleInActiveCustomerProduct
})(CustomerProductsOverview)
