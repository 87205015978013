import React from 'react'
import Reflux from 'reflux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import _ from 'lodash'

export default class DeleteModal extends Reflux.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false
    }
  }
  componentDidMount() {
    if (_.get(this.props, 'openModal')) {
      this.setState({ openModal: this.props.openModal })
    }
  }

  logoutModal = openModal => {
    this.setState({ openModal: !openModal })
  }

  componentWillReceiveProps() {
    if (_.get(this.props, 'openModal')) {
      this.setState({ openModal: this.props.openModal })
    }
  }

  render() {
    return (
      <Modal isOpen={this.state.openModal} toggle={() => this.logoutModal(this.state.openModal)} size='md'>
        <ModalHeader toggle={() => this.logoutModal(this.state.openModal)}>{this.props.modalHeader}</ModalHeader>
        <ModalBody>
          <strong>{this.props.modalBody}</strong>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={e => {
              this.logoutModal()
              this.props.saveModal(e)
            }}
          >
            YES
          </Button>
          <Button color='danger' onClick={() => this.logoutModal(this.state.openModal)}>
            NO
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
