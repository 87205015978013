import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, FormGroup, Input, Label, Col, Row, CustomInput } from 'reactstrap'
import _ from 'lodash'
import { ClientActions, ClientStore, STATUS } from 'Stores/clientStore'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'

export default class ClientForm extends Component {
  clientAccess = ['ACTIVE', 'ARCHIVED', 'SUSPENDED']
  constructor(props) {
    super(props)
    this.stores = [ClientStore]
    this.storeKeys = ['status', 'clientDetails', 'companies']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  resetData = () => {
    if (_.get(this.props, 'match.params.ClientID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      ClientActions.getClientDetails(this.props.match.params.ClientID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      ClientActions.createEmptyClient()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    const fieldValidation = {
      isNameValid: this.state.clientDetails.clientName ? true : false,
      isShortNameValid: this.state.clientDetails.clientNameShort ? true : false,
      isCompanyIdValid: this.state.clientDetails.CompanyID ? true : false
    }

    this.setState({ fieldValidation })

    const { isNameValid, isShortNameValid, isCompanyIdValid } = fieldValidation

    return isNameValid && isShortNameValid && isCompanyIdValid
  }

  saveClientForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      ClientActions.saveClient()
    }
  }

  validateCompanyID() {
    if (this.state.clientDetails.CompanyID) {
      return true
    } else {
      return false
    }
  }

  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/client' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Client</strong>}>
          <Form>
            <FormField
              required
              data={this.state.clientDetails}
              field={'clientName'}
              name='Name'
              onChange={e => {
                ClientActions.updateClientField(e)
                // this.validateForm()
              }}
            />
            <FormField
              required
              data={this.state.clientDetails}
              field={'clientNameShort'}
              name='Short Name '
              onChange={e => {
                ClientActions.updateClientField(e)
                // this.validateForm()
              }}
            />

            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='CompanyID'> Company ID </Label>
                </Col>
                <Col xs='9'>
                  <Input
                    className={this.validateCompanyID() ? 'is-valid' : 'is-invalid'}
                    required
                    type='select'
                    name='CompanyID'
                    value={Number(this.state.clientDetails.CompanyID)}
                    id='CompanyID'
                    onChange={e => {
                      ClientActions.updateClientField(e)
                      this.validateForm()
                    }}
                  >
                    <option value=''>Click to Select</option>
                    {this.state.companies.map((e, i) => {
                      return (
                        <option value={Number(e.CompanyID)} key={i}>
                          {e.CompanyID} - {e.companyName}
                        </option>
                      )
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='clientAccess'> Access Status </Label>
                </Col>
                <Col xs='9'>
                  <Input
                    type='select'
                    value={this.state.clientDetails.clientAccess}
                    name='clientAccess'
                    id='clientAccess'
                    onChange={e => {
                      ClientActions.updateClientField(e)
                    }}
                  >
                    <option value='' disabled>
                      Click to Select
                    </option>
                    <option value='ACTIVE'>ACTIVE</option>
                    <option value='ARCHIVED'>ARCHIVED</option>
                    <option value='SUSPENDED'>SUSPENDED</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormField
              name='clientSlug'
              field='clientSlug'
              data={this.state.clientDetails}
              onChange={e => {
                ClientActions.updateClientField(e)
              }}
            />

            <FormGroup>
              <Row>
                <Col xs='3'>
                  <Label for='clientColor'> Color </Label>
                </Col>
                <Col xs='3'>
                  <Input
                    type='text'
                    value={this.state.clientDetails.clientColor ? this.state.clientDetails.clientColor : 'FFFFFF'}
                    name='clientColor'
                    id='clientColor'
                    onChange={e => {
                      ClientActions.updateClientField(e)
                    }}
                  ></Input>
                </Col>
                <Col xs='3'>
                  <Input
                    type='color'
                    value={this.state.clientDetails.clientColor ? '#' + this.state.clientDetails.clientColor : '#FFFFFF'}
                    name='clientColorPicker'
                    id='clientColorPicker'
                    onChange={e => {
                      ClientActions.updateClientField(e)
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormField
              name='clientCNAME'
              field='clientCNAME'
              data={this.state.clientDetails}
              onChange={e => {
                ClientActions.updateClientField(e)
              }}
            />
            <FormField
              name='clientAppTokenIOS'
              field='clientAppTokenIOS'
              data={this.state.clientDetails}
              onChange={e => {
                ClientActions.updateClientField(e)
              }}
            />
            <FormField
              name='clientAppTokenAndroid'
              field='clientAppTokenAndroid'
              data={this.state.clientDetails}
              onChange={e => {
                ClientActions.updateClientField(e)
              }}
            />
            <FormField
              name='Favicon'
              field='upload'
              type='file'
              data={{ upload: '' }}
              noValue={this.state.clientDetails.clientIcon !== ''}
              onChange={ClientActions.selectFile}
              disabled={this.state.isUploading}
              accept='image/*'
            />
            <img
              src={this.state.clientDetails.clientIcon}
              alt={this.state.clientDetails.clientIcon}
              style={{
                height: '15vw',
                width: '15vw',
                objectFit: 'cover',
                marginBottom: '10px',
                display: this.state.clientDetails.clientIcon ? 'inline-block' : 'none'
              }}
            />
            <FormField
              field='clientCVR'
              name='Client CVR'
              data={this.state.clientDetails}
              onChange={e => {
                ClientActions.updateClientField(e)
              }}
            />
            <FormGroup row>
              <Label for='clientEnableTaxInfo' sm={3}>
                Enable Tax Info
              </Label>
              <Col sm={7}>
                <CustomInput
                  type='switch'
                  checked={this.state.clientDetails.clientEnableTaxInfo}
                  id='clientEnableTaxInfo'
                  name='clientEnableTaxInfo'
                  onChange={ClientActions.updateClientField}
                />
              </Col>
            </FormGroup>
            <br />

            <Button onClick={this.saveClientForm} disabled={this.state.status === STATUS.IS_SAVING}>
              {this.state.status === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
