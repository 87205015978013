import { createAction } from '@reduxjs/toolkit'
import Proxies from 'Components/proxies'
import keyMirror from 'keymirror'

export const STATUS = keyMirror({
  LOADING_PRODUCTS: null,
  LOADING_PRODUCTS_SUCCESS: null,
  LOADING_PRODUCTS_FAILED: null,
  LOADING_PRODUCT_HISTORY: null,
  LOADING_PRODUCT_HISTORY_SUCCESS: null,
  LOADING_PRODUCT_HSITORY_FAILED: null
})

export const fetchProductsRequest = createAction('fetchProductsRequest')
export const fetchProductsSuccess = createAction('fetchProductsSuccess')
export const fetchProductsFailure = createAction('fetchProductsFailure')
export const updateProductVariation = createAction('updateProductVariation')

export const fetchProductHistoryRequest = createAction('fetchProductHistoryRequest')
export const fetchProductHistorySuccess = createAction('fetchProductHistorySuccess')
export const fetchProductHistoryFailure = createAction('fetchProductHistoryFailure')

export const fetchProducts = () => (dispatch, getState) => {
  const { fetchingProducts } = getState().stock
  if (fetchingProducts !== STATUS.LOADING_PRODUCTS) {
    dispatch(fetchProductsRequest())
    Proxies.GET('products/client-pool').then(responseJSON => {
      if (responseJSON.error) {
        dispatch(fetchProductsFailure())
      } else {
        if (responseJSON && responseJSON.length) {
          responseJSON.map(item => {
            if (item && item.variations && item.variations.length > 3) {
              item.variationReadLess = true
              item.variationReadMore = false
            } else {
              item.variationReadMore = true
              item.variationReadLess = false
            }
            return item
          })
        }
        dispatch(fetchProductsSuccess(responseJSON))
      }
    })
  }
}

export const toggleProductVariationContent = data => dispatch => {
  dispatch(updateProductVariation(data))
}

export const fetchProductHistory = ProductID => (dispatch, getState) => {
  const { fetchingProductHistory } = getState().stock
  if (fetchingProductHistory !== STATUS.LOADING_PRODUCT_HISTORY) {
    dispatch(fetchProductHistoryRequest())
    Proxies.GET('productevents/pool', { ProductID }).then(responseJSON => {
      if (responseJSON.error) {
        dispatch(fetchProductHistoryFailure())
      } else {
        if (responseJSON.events && !!responseJSON.events.length) {
          responseJSON.events = responseJSON.events.map(product => {
            product.catalogs = responseJSON.catalogs
            return product
          })
        }
        dispatch(fetchProductHistorySuccess(responseJSON.events))
      }
    })
  }
}
