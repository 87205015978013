import React, { useState, useEffect } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import { Label, Col, CustomInput, FormGroup } from 'reactstrap'
import styled from 'styled-components'
import Proxies from '../components/proxies'
import { toast } from 'react-toastify'
const { GET, wrapFilter, PUT } = Proxies

const StyledCustomInput = styled(CustomInput)`
  top: 6px !important;
`
export default function Maintenance() {
  const [shutDownCustomisation, setShutDownCustomization] = useState({
    customisationData: '',
    customisedType: 'System',
    description: 'shutDownAllBrands',
    shutDownAllBrands: false,
    title: 'shutDownAllBrands',
    CustomisedID: 1
  })

  function createShutDownCostumization(ShutDownCustomisation) {
    PUT('customisationv2', ShutDownCustomisation).then(response => {
      if (response.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      } else {
        toast.success(`Success`, {
          autoClose: 7000
        })
      }
    })
  }

  function updateShutDownCustomisation(ShutDownCustomisation) {
    PUT('customisationv2', ShutDownCustomisation).then(response => {
      if (response.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      } else {
        toast.success(`Success`, {
          autoClose: 7000
        })
      }
    })
  }

  function save(field) {
    let ShutDownCustomisation = { ...shutDownCustomisation, shutDownAllBrands: field.target.checked }
    setShutDownCustomization(ShutDownCustomisation)
    if (ShutDownCustomisation && ShutDownCustomisation.customisationData !== '') {
      let data = JSON.parse(ShutDownCustomisation.customisationData)
      data.features.shutDownAllBrands = field.target.checked
      ShutDownCustomisation = { ...ShutDownCustomisation, customisationData: JSON.stringify(data) }
      updateShutDownCustomisation(ShutDownCustomisation)
    } else {
      let data = {
        features: {
          shutDownAllBrands: field.target.checked
        }
      }
      ShutDownCustomisation = { ...ShutDownCustomisation, customisationData: JSON.stringify(data) }
      createShutDownCostumization(ShutDownCustomisation)
    }
  }

  function getSystemCustomisations() {
    GET('customisationv2/admin-pool', wrapFilter({})).then(responseJSON => {
      if (responseJSON && responseJSON.length) {
        responseJSON.map(e => {
          if (e.title === 'shutDownAllBrands') {
            let data = {
              customisationData: e.customisationData,
              customisedType: e.customisedType,
              description: e.description,
              shutDownAllBrands: JSON.parse(e.customisationData).features.shutDownAllBrands,
              title: e.title,
              CustomisationID: e.CustomisationID,
              CustomisedID: e.CustomisedID
            }
            setShutDownCustomization(data)
          }
        })
      }
    })
  }

  useEffect(() => {
    getSystemCustomisations()
  }, [])

  return (
    <React.Fragment>
      <CollapsibleCard startOpen={true} header={<strong>Maintenance</strong>}>
        <FormGroup row>
          <Label for='shutDownAllBrands' sm={2}>
            Shut Down All Brands
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={shutDownCustomisation.shutDownAllBrands}
              id='shutDownAllBrands'
              name='shutDownAllBrands'
              onChange={e => {
                if (e.target.checked) {
                  if (confirm(`Are you sure you want to shut down all brands?`)) {
                    save(e)
                  }
                } else {
                  save(e)
                }
              }}
            />
          </Col>
        </FormGroup>
      </CollapsibleCard>
    </React.Fragment>
  )
}
