import React, { useState } from 'react'
import { Button, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function BroadCastPopUp(props) {
    const { broadCastToggle,togglePopUp,title } = props

    return (
        <div style={{ textAlign: 'center',marginBottom: "30px" }}>
            <Modal isOpen={broadCastToggle} style={{ textAlign: 'center' }} dialogClassName="border-radius-2">
                <p style={{ paddingTop: "50px", fontSize: "20px", marginBottom: "0", fontWeight: "400" }}>
                Udsendelse af kampagnen
                </p>
                <p style={{ fontSize: "25px", fontWeight: "bold",marginBottom: "0", }}>
               {`"${title}"`}
                </p>
                <p style={{ fontSize: "20px",marginBottom: "10px" }}>
                er gennemført
                </p>
                <div style={{height:'50px',width:'60px',margin:'auto'}}> <FontAwesomeIcon style={{width:'100%',height:'100%'}}icon='check' /></div>
                <Button style={{ borderRadius: "40px",width: "300px", height: "50px", fontSize: "20px", fontWeight: "bold",margin:'auto', marginTop: "20px",marginBottom: "30px"}} onClick={togglePopUp}>
                Luk dette vindue
                </Button>
            </Modal>
        </div>
    );
}

export default BroadCastPopUp;
