export function csvToArray(str, delimiter = ';') {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter)

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function(row) {
    const values = row.split(delimiter)
    const el = headers.reduce(function(object, header, index) {
      let headerTxt = header
        .replaceAll('"', '')
        .replaceAll("'", '')
        .replaceAll('\r', '')
      let value = !values[index]
        ? ''
        : values[index]
            .replaceAll('"', '')
            .replaceAll("'", '')
            .replaceAll('\r', '')
      object[headerTxt] = !!value && !isNaN(value) ? Number(value) : value
      return object
    }, {})
    return el
  })

  // return the array
  return arr
}

export function delimiter(t) {
  t = t.split('\n')[0]
  let delArray = [',', ';', '|', '    ']
  let comma = 0
  let semiclon = 0
  let pipe = 0
  let tab = 0

  delArray.forEach((e, i) => {
    if (i === 0) {
      comma = t.split(e).length
    } else if (i === 1) {
      semiclon = t.split(e).length
    } else if (i === 2) {
      pipe = t.split(e).length
    } else if (i === 3) {
      tab = t.split(e).length
    }
  })
  let tmpArray1 = [comma, semiclon, pipe, tab]
  let tmpArray = [comma, semiclon, pipe, tab]
  let maxLen = tmpArray.sort((a, b) => b - a)[0]
  let delimiter_i = 0
  tmpArray1.forEach((e, i) => {
    if (maxLen === e) {
      delimiter_i = i
    }
  })
  if (delimiter_i === 0) {
    return ','
  } else if (delimiter_i === 1) {
    return ';'
  } else if (delimiter_i === 2) {
    return '|'
  } else if (delimiter_i === 3) {
    return '    '
  }
}
