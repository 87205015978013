import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { TranslationActions, TranslationStore, STATUS } from 'Stores/translationStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import FormField from 'Components/formField'
import { Redirect } from 'react-router-dom'
import { Button, Form, Input, Table } from 'reactstrap'

export default class TranslationForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [TranslationStore]
    this.storeKeys = ['status', 'translationDetails', 'languages']
    this.state = {
      fieldValidation: {},
      hasFormBeenSubmitted: false
    }
  }

  componentDidMount() {
    TranslationActions.getLanguages()
  }
  resetData = () => {
    if (_.get(this.props, 'match.params.TranslationID')) {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      TranslationActions.getTranslationDetail(this.props.match.params.TranslationID)
    } else {
      this.setState({ hasFormBeenSubmitted: false, fieldValidation: {} })
      TranslationActions.createEmptyTranslation()
    }
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_LANGUAGE_ERROR) {
      toast.error('FETCHING LANGUAGES FAILED', {
        autoClose: 3000,
        onClose: () => {
          TranslationActions.clearToast()
        }
      })
      TranslationActions.clearToast()
    }
  }

  validateForm = force => {
    if (!this.state.hasFormBeenSubmitted && !force) return

    let check = false

    !!this.state.translationDetails.phrases &&
      !!this.state.translationDetails.phrases.length &&
      this.state.translationDetails.phrases.map(e => {
        if (e.LanguageID !== '' && e.translationComment !== '' && e.translationValue !== '') {
          check = true
        }
      })

    const fieldValidation = {
      iskeyValid: this.state.translationDetails.translationKey ? true : false,
      isPhrases: check
    }

    this.setState({ fieldValidation })

    return Object.keys(fieldValidation).reduce((sum, next) => sum && fieldValidation[next], true)
  }

  saveTranslationForm = ev => {
    ev.preventDefault()
    const isFormValid = this.validateForm(true)
    this.setState({ hasFormBeenSubmitted: true })
    if (isFormValid) {
      TranslationActions.saveTranslation()
    }
  }

  addPhrase = () => {
    let translationDetails = this.state.translationDetails
    translationDetails['phrases'].push({
      LanguageID: '',
      translationComment: '',
      translationValue: ''
    })
    this.setState({ translationDetails })
  }

  render() {
    if (this.state.status === STATUS.IS_REDIRECT) {
      return <Redirect to='/translations' />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={true} header={<strong>Translation</strong>}>
          <Form>
            <FormField
              required
              data={this.state.translationDetails}
              field={'translationKey'}
              name='Translation Key'
              onChange={e => {
                TranslationActions.updateTranslationField(e)
                this.validateForm()
              }}
            />
            <FormField field={'LanguageID'} name='Phrases'>
              <Table>
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>Translation Value</th>
                    <th>Translation Comment</th>
                    <th className='float-right'>
                      <Button onClick={this.addPhrase}>
                        <FontAwesomeIcon icon='plus' />
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!this.state.translationDetails &&
                    !!this.state.translationDetails.phrases.length &&
                    this.state.translationDetails.phrases.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Input
                              className={item.LanguageID ? 'is-valid' : 'is-invalid'}
                              required
                              type='select'
                              name='LanguageID'
                              value={item.LanguageID}
                              id='LanguageID'
                              onChange={e => TranslationActions.updateData(e, key, 'phrases')}
                            >
                              <option value='' disabled>
                                Click to Select
                              </option>
                              {this.state.languages.map((e, i) => {
                                return (
                                  <option value={Number(e.LanguageID)} key={i}>
                                    {e.languageName}
                                  </option>
                                )
                              })}
                            </Input>
                          </td>
                          <td>
                            <Input
                              required
                              value={item.translationValue}
                              className={item.translationValue ? 'is-valid' : 'is-invalid'}
                              name='translationValue'
                              onChange={e => TranslationActions.updateData(e, key, 'phrases')}
                            />
                          </td>
                          <td>
                            <Input
                              required
                              value={item.translationComment}
                              className={item.translationComment ? 'is-valid' : 'is-invalid'}
                              name='translationComment'
                              onChange={e => TranslationActions.updateData(e, key, 'phrases')}
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </FormField>
            <Button onClick={this.saveTranslationForm} disabled={this.state.status === STATUS.IS_SAVING}>
              {this.state.status === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetData} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
