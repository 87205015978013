import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import CampaignForm from 'Modules/campaigns/campaignForm'
import { Redirect } from 'react-router-dom'
import { CampaignActions, CampaignStore, STATUS } from 'Stores/campaignStore'

export default class CampaignCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [CampaignStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    CampaignActions.resetMacro()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.CAMPAIGN_ERROR) {
      toast.error('Campaign Failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
    if (this.state.status === STATUS.REDIRECT) {
      CampaignActions.clearToast()
      toast.success('Successfully saved', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearToast()
        }
      })
    }
  }
  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return this.props.isKam ? <Redirect to={'/kam/campaigns'} /> : <Redirect to={'/macros/campaigns'} />
    }
    return <CampaignForm isKam={this.props.isKam} isCreateMode={true} />
  }
}
