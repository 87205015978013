import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'Reducers/actions/customerProductActions'

export const CustomerProductSlice = createSlice({
  name: 'customerProduct',
  initialState: {
    overviewLoadingState: false,
    customerProducts: [],
    customerProductDetails: {
      moduleName: '',
      CustomerProductID: '',
      groups: []
    },
    loadingState: false,
    showActiveCustomerProducts: true,
    showInActiveCustomerProducts: false,
    customerProductFilterValue: 'active'
  },
  reducers: {
    fetchCustomerProductsRequest: state => {
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_PRODUCTS
    },
    fetchCustomerProductsFailed: state => {
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_PRODUCTS_FAILED
    },
    fetchCustomerProductsSuccess: (state, action) => {
      state.customerProducts = action.payload
      state.overviewLoadingState = STATUS.FETCHING_CUSTOMER_PRODUCTS_SUCCESS
    },
    fetchCustomerProductDetailsRequest: state => {
      state.loadingState = STATUS.FETCHING_CUSTOMER_PRODUCT_DETAILS
    },
    fetchCustomerProductDetailsFailed: state => {
      state.loadingState = STATUS.FETCHING_CUSTOMER_PRODUCT_DETAILS_FAILED
    },
    fetchCustomerProductDetailsSuccess: (state, action) => {
      state.loadingState = STATUS.FETCHING_CUSTOMER_PRODUCT_DETAILS_SUCCESS
      state.customerProductDetails = action.payload
    },
    customerProductCreationSuccess: (state, action) => {
      state.loadingState = action.payload
      state.loadingState = STATUS.CUSTOMER_PRODUCT_SUCCESS
    },
    customerProductCreationFailure: state => {
      state.loadingState = STATUS.CUSTOMER_PRODUCT_FAILED
    },
    isSavingCustomerProduct: state => {
      state.loadingState = STATUS.IS_SAVING
    },
    createEmptyCustomerProduct: state => {
      state.customerProductDetails = {
        moduleName: '',
        productUID: ''
      }
    },
    clearToast: (state, action) => {
      state.loadingState = action.payload ? action.payload : null
      state.overviewLoadingState = false
    },
    updateCustomerProductField: (state, action) => {
      return {
        ...state,
        customerProductDetails: { ...state.customerProductDetails, [action.payload.name]: action.payload.value }
      }
    },
    removeCustomerProductFailure: state => {
      state.overviewLoadingState = STATUS.DELETE_CUSTOMER_PRODUCT_FAILED
    },
    removeCustomerProductSuccess: state => {
      state.overviewLoadingState = STATUS.DELETE_CUSTOMER_PRODUCT_SUCCESS
    },
    toggleActiveCustomerProduct: state => {
      let value = !state.showActiveCustomerProducts ? 'active' : 'both'
      state.showActiveCustomerProducts = !state.showActiveCustomerProducts
      state.customerProductFilterValue = value
      state.showInActiveCustomerProducts = false
    },
    toggleInActiveCustomerProduct: state => {
      let value = state.showInActiveCustomerProducts ? 'both' : 'inactive'
      state.showInActiveCustomerProducts = !state.showInActiveCustomerProducts
      state.customerProductFilterValue = value
      state.showActiveCustomerProducts = false
    }
  }
})

export const {
  fetchCustomerProductsRequest,
  fetchCustomerProductsFailed,
  fetchCustomerProductsSuccess,
  fetchCustomerProductDetailsRequest,
  fetchCustomerProductDetailsFailed,
  fetchCustomerProductDetailsSuccess,
  customerProductCreationSuccess,
  customerProductCreationFailure,
  toggleActiveCustomerProduct,
  toggleInActiveCustomerProduct,
  removeCustomerProductFailure,
  resetCustomerProductsDetails,
  isSavingCustomerProduct,
  createEmptyCustomerProduct,
  clearToast,
  updateCustomerProductField,
  removeCustomerProductSuccess
} = CustomerProductSlice.actions

export default CustomerProductSlice.reducer
