import React from 'react'
import { Component } from 'reflux'
import { CommunicationStore, CommunicationActions, STATUS } from 'Stores/communicationStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { LoginStore } from 'Stores/loginStore'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'

const StyledSpan = styled.span`
  white-space: pre-wrap !important;
`
export default class CommunicationDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [CommunicationStore, LoginStore]
    this.storeKeys = ['communicationDetails', 'isMSBAdmin', 'settings', 'status']
  }

  componentDidMount() {
    CommunicationActions.getCommunicationDetails(this.props.match.params.CommunicationID)
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_COMMUNICATION_DETAILS_FAILED) {
      toast.error('FETCHING COMMUNICATION DETAILS FAILED', {
        autoClose: 7500,
        onClose: () => {
          CommunicationActions.clearToast()
        }
      })
      CommunicationActions.clearToast()
    }
  }

  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Kommunikation detaljer</strong>}>
        {this.state.status === STATUS.FETCHING_COMMUNICATION_DETAILS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <Card>
            <CardBody>
              {this.state.communicationDetails && this.state.communicationDetails.communicationChannel === 'EMAIL' ? (
                <div dangerouslySetInnerHTML={{ __html: this.state.communicationDetails.communicationMessage }}></div>
              ) : (
                <StyledSpan>{this.state.communicationDetails.communicationMessage}</StyledSpan>
              )}
            </CardBody>
          </Card>
        )}
      </CollapsibleCard>
    )
  }
}
