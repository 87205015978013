import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { CompanyActions, CompanyStore, STATUS } from 'Stores/companyStore'
import CompanyForm from 'Modules/company/companyForm'

export default class CompanyCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [CompanyStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    CompanyActions.createEmptyCompany()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.COMPANY_FAILED) {
      toast.error('CREATE COMPANY FAILED', {
        autoClose: 3000,
        onClose: () => {
          CompanyActions.clearToast()
        }
      })
      CompanyActions.clearToast()
    }
    if (this.state.status === STATUS.COMPANY_SUCCESS) {
      toast.success('CREATED COMPANY SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          CompanyActions.clearToast()
        }
      })
      CompanyActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <CompanyForm />
      </React.Fragment>
    )
  }
}
