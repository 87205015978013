import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { MenuActions, MenuStore, STATUS } from 'Stores/menuStore'
import MenuForm from 'Modules/menu/menuForm'

export default class MenuCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [MenuStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    MenuActions.getBrands()
    MenuActions.getPoolNames()

    let queryString = window.location.search
    let params = new URLSearchParams(queryString)
    let cloneFromMenuID = params.get('copy')
    if (cloneFromMenuID) {
      MenuActions.getMenuDetails(cloneFromMenuID, true)
    } else {
      MenuActions.createEmptyMenu()
    }
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.MENU_FAILED) {
      toast.error('MENU CREATION FAILED', {
        autoClose: 7000,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast()
    }
    if (this.state.status === STATUS.MENU_SUCCESS) {
      toast.success('CREATED MENU SUCCESSFULLY', {
        autoClose: 7000,
        onClose: () => {
          MenuActions.clearToast()
        }
      })
      MenuActions.clearToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <MenuForm />
      </React.Fragment>
    )
  }
}
