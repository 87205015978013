import React from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'

export default function CampaignUserPreview({ users }) {
  return (
    <CollapsibleCard startOpen header={<strong>Kampagne Preview - Brugere</strong>}>
      <DynamicTable
        options={{
          startSort: {
            dir: 'ASC',
            column: 'userCompany'
          }
        }}
        headers={{
          UserID: '#',
          userName: 'Navn',
          userCompany: 'Virksomhed',
          userUID: 'UID',
          userEmail: 'Email',
          userPhone: 'Telefon',
          userSupervisor: 'KAM'
        }}
        data={users}
      />
    </CollapsibleCard>
  )
}
