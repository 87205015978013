import React from 'react'
import { Component } from 'reflux'

export default class WelcomeDashboard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const DashboardID = this.props.dashboardId
    const DashboardToken = this.props.dashboardToken
    const Criteria = this.props.criteria ? this.props.criteria : ''
    if (!DashboardID || !DashboardToken) return <h3>Could not load dashboard</h3>

    const source = `https://analytics.zoho.eu/open-view/${DashboardID}/${DashboardToken}${Criteria}`
    return (
      <React.Fragment>
        <iframe frameBorder='0' width='100%' height='100%' src={source}></iframe>
      </React.Fragment>
    )
  }
}
