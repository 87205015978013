import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Button, Input } from 'reactstrap'
import FormField from 'Components/formField'
import moment from 'moment'

let headers = {
  clientCVR: 'Client CVR',
  dateFrom: 'Dato fra',
  dateTo: 'Dato to',
  UserID: 'BrugerID',
  userUID: 'UID',
  userEmail: 'Email',
  userPhone: 'Mobile',
  userAccess: 'Access',
  cprExists: 'CPR',
  pointsReceived: 'Earned point',
  pointsReceivedDKK: 'Earned DKK'
}

const MonthlyTaxInfo = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [BrandID, setBrandID] = useState(null)
  const [brands, setBrands] = useState([])
  // const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(null)
  const [error, setError] = useState(null)

  function fetchEarningsTaxReport() {
    setLoading(true)
    Proxies.GET('walletactivation/monthlyTaxInfo', { BrandID }).then(response => {
      if (response.error) {
        setError(response.error.message)
      } else {
        setError(null)
        setData(response)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    Proxies.GET('menus/listBrands').then(responseJSON => {
      setBrands(responseJSON)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <FontAwesomeIcon icon='spinner' spin />
  }

  return (
    <React.Fragment>
      <CollapsibleCard header={<strong>Monthly tax info</strong>} startOpen={true}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField field={'BrandID'} name='Brand'>
            <Input
              type='select'
              name='BrandID'
              value={BrandID}
              onChange={e => {
                setBrandID(e.target.value)
              }}
            >
              <option selected='true' disabled={true} value={''}>
                Select Brand
              </option>
              {brands &&
                !!brands.length &&
                brands.map(e => {
                  return (
                    <option value={e.BrandID} key={e.BrandID}>
                      {e.BrandID} - {e.brandName}
                    </option>
                  )
                })}
            </Input>
          </FormField>
          {/* <Row className='mb-3'>
            <Col xs={3}>
              <Label htmlFor='startDate'>Date range</Label>
            </Col>
            <Col xs={4}>
              <ReactDatePicker
                isClearable
                placeholderText='Vælg en dato at filtrere fra'
                className='form-control'
                maxDate={moment().add(0, 'days')}
                dateFormat={'DD/MM/YYYY'}
                selected={startDate ? moment(startDate) : null}
                viewMode={'days'}
                name='startDate'
                id='startDate'
                onChange={date => {
                  setStartDate(date.format())
                }}
              />
            </Col>
            <Col xs={4}>
              <ReactDatePicker
                isClearable
                placeholderText='Vælg en dato at filtrere to'
                className='form-control'
                minDate={startDate ? moment(startDate).add(1, 'days') : moment().add(0, 'days')}
                maxDate={moment().add(0, 'days')}
                dateFormat={'DD/MM/YYYY'}
                selected={endDate ? moment(endDate) : null}
                viewMode={'days'}
                name='endDate'
                id='endDate'
                onChange={date => {
                  setEndDate(date.format())
                }}
              />
            </Col>
          </Row>          */}
        </Form>
        <div style={{ flexDirection: 'row', marginBottom: 32, paddingHorizontal: 16 }}>
          <Button disabled={!BrandID} style={{ marginRight: 16 }} onClick={() => fetchEarningsTaxReport()}>
            Search
          </Button>
        </div>
        {data && !data.length ? <p>No Orders in the selected time.</p> : null}
        {error ? <p>{error}</p> : null}
        {data?.length ? (
          <DynamicTable
            data={data}
            headers={headers}
            options={{
              fieldFunctions: {
                dateFrom: e => {
                  return e.dateFrom ? moment(e.dateFrom, 'DD-MM-YYYY').format('DD-MM-YYYY') : ''
                },
                dateTo: e => {
                  return e.dateTo ? moment(e.dateTo, 'DD-MM-YYYY').format('DD-MM-YYYY') : ''
                }
              }
            }}
          />
        ) : null}
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default MonthlyTaxInfo
