import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import 'moment/locale/da'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { StatActions, StatStore, STATE } from 'Stores/statStore'
import moment from 'moment'
import styled from 'styled-components'
import { LoginStore } from 'Stores/loginStore'
import { toast } from 'react-toastify'

const StyledAnchor = styled.a`
  cursor: pointer !important;
`
export default class statList extends Component {
  constructor(props) {
    super(props)
    this.stores = [StatStore, LoginStore]
    this.storeKeys = [
      'stats',
      'settings',
      'showActiveStats',
      'showInActiveStats',
      'statFilterValue',
      'loadingState',
      'isAdminAccess',
      'advanceSearchFilters',
      'adminAdvanceSearchFilters'
    ]
    this.advanceSearchColumns = [
      { key: 'StatID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'BrandID', name: 'BrandID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT'] },
      { key: 'moduleName', name: 'Navn', operators: ['LIKE', 'EXACT'] },
      { key: 'moduleDescription', name: 'Beskrivelse', operators: ['LIKE', 'EXACT'] },
      { key: 'moduleQuery', name: 'Module Query', operators: ['LIKE', 'EXACT'] },
      { key: 'timeInsert', name: 'Date', operators: ['LIKE'] }
    ]
  }
  componentDidMount() {
    if (
      this.state.settings &&
      this.state.settings.BrandID === 9 &&
      this.state.settings.userRole === 'System' &&
      this.state.settings.userPrivilege === 'MANAGE'
    ) {
      StatActions.giveAccess()
    }
    StatActions.getStats(this.props.msb)
  }

  componentDidUpdate() {
    if (this.state.loadingState === STATE.FETCHING_STATLIST_FAILED) {
      toast.error('FETCHING STATS FAILED', {
        autoClose: 7500,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.clearToast()
    }

    if (this.state.loadingState === STATE.DELETE_STAT_ERROR) {
      toast.error('STAT UPDATE FAILED', {
        autoClose: 7500,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.clearToast()
    }

    if (this.state.loadingState === STATE.DELETE_STAT_SUCCESS) {
      toast.success('STAT DELETED', {
        autoClose: 7500,
        onClose: () => {
          StatActions.clearToast()
        }
      })
      StatActions.getStats(this.props.msb)
    }
  }

  render() {
    if (this.state.loadingState === STATE.FETCHING_STATLIST) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Stat
            {this.state.isAdminAccess && this.props.msb && (
              <ButtonGroup className='float-right'>
                <Button outline color='success' onClick={StatActions.toggleActiveStats} active={this.state.showActiveStats}>
                  Active
                </Button>
                <Button outline color='danger' onClick={StatActions.toggleInActiveStats} active={this.state.showInActiveStats}>
                  In-Active
                </Button>
              </ButtonGroup>
            )}
          </strong>
        }
      >
        <DynamicTable
          responsive
          size='sm'
          data={
            this.state.stats && !!this.state.stats.length && this.state.isAdminAccess && this.state.statFilterValue !== 'both'
              ? this.state.stats.filter(stat => stat.removed === (this.state.statFilterValue === 'inactive' ? 1 : 0))
              : this.state.stats
          }
          headers={{
            ...(this.state.isAdminAccess && this.props.msb && { StatID: 'Stat ID' }),
            ...(this.state.isAdminAccess && this.props.msb && { BrandID: 'Brand', brandName: 'Brand Name' }),
            moduleName: 'Navn',
            moduleDescription: 'Beskrivelse',
            ...(this.state.isAdminAccess && this.props.msb && { moduleQuery: 'Module Query' }),
            ...(this.state.isAdminAccess && this.props.msb && { timeInsert: 'Date' })
          }}
          options={{
            ...(this.props.msb &&
              this.state.isAdminAccess && {
                advanceSearchColumns: this.advanceSearchColumns,
                preserveAdvanceSearch: StatActions.preserveAdvanceSearch,
                advanceSearchFilters: this.state.advanceSearchFilters
              }),
            hideHeader: false,
            startSort: {
              ...(this.state.isAdminAccess && this.props.msb ? { dir: 'DESC' } : { dir: 'ASC' }),
              ...(this.state.isAdminAccess && this.props.msb ? { column: 'StatID' } : { dir: 'moduleName' })
            },
            fieldFunctions: {
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('YYYY-MM-DD') : ''
              },
              moduleQuery: e => {
                let acc = []
                if (!e.moduleQueryReadMore && !e.moduleQueryReadLess) {
                  return e.moduleQuery
                }
                if (e.moduleQueryReadMore && !e.moduleQueryReadLess) {
                  acc.push(e.moduleQuery.slice(0, 200) + '...')
                  acc.push(
                    <StyledAnchor key={e.HalloffameID} onClick={() => StatActions.toggleQueryContent(e)}>
                      Show More
                    </StyledAnchor>
                  )
                  return acc
                }
                if (!e.moduleQueryReadMore && e.moduleQueryReadLess) {
                  acc.push(e.moduleQuery)
                  acc.push(
                    <StyledAnchor key={e.HalloffameID} onClick={() => StatActions.toggleQueryContent(e)}>
                      <br /> Show Less
                    </StyledAnchor>
                  )
                  return acc
                }
              }
            },
            addField: e => {
              return (
                <td>
                  {this.state.isAdminAccess && this.props.msb ? (
                    <ButtonGroup>
                      <Tooltip target={'elements' + e.StatID}>Vis</Tooltip>
                      <Button tag={Link} to={this.props.match.url + '/' + e.StatID + '/elements?BrandID=' + e.BrandID} id={'elements' + e.StatID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                      <Tooltip target={'edit' + e.StatID}>Rediger</Tooltip>
                      <Button tag={Link} to={this.props.match.url + '/' + e.StatID} id={'edit' + e.StatID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      {e.removed === 0 && (
                        <React.Fragment>
                          <Tooltip target={'deactivate' + e.StatID}>Deaktivér</Tooltip>
                          <Button
                            id={'deactivate' + e.StatID}
                            onClick={() => {
                              if (confirm('Vil du deaktivere denne stat?')) {
                                StatActions.changeStatStatus(e, true)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-check' />
                          </Button>
                        </React.Fragment>
                      )}
                      {e.removed === 1 && (
                        <React.Fragment>
                          <Tooltip color='danger' target={'active' + e.StatID}>
                            Aktivér
                          </Tooltip>
                          <Button
                            color='danger'
                            id={'active' + e.StatID}
                            onClick={() => {
                              if (confirm('Vil du aktivere denne stat?')) {
                                StatActions.changeStatStatus(e, false)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='user-slash' />
                          </Button>
                        </React.Fragment>
                      )}

                      <Tooltip target={'copy' + e.StatID}>Kopier</Tooltip>
                      <Button
                        id={'copy' + e.StatID}
                        onClick={() => {
                          StatActions.copyStat(e, this.props)
                        }}
                      >
                        <FontAwesomeIcon icon='copy' />
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup>
                      <Tooltip target={'view' + e.StatID}>Detaljer</Tooltip>
                      <Button tag={Link} to={'/stat/' + e.StatID + '/elements'} id={'view' + e.StatID}>
                        <FontAwesomeIcon icon='info-circle' />
                      </Button>
                    </ButtonGroup>
                  )}
                </td>
              )
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
