import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import CustomerProductForm from 'Modules/customerProduct/customerProductForm'
import { clearToast } from 'Reducers/customerProductReducer'
import { STATUS, getCustomerProductDetails } from 'Reducers/actions/customerProductActions'
import { connect } from 'react-redux'

class CustomerProductEdit extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getCustomerProductDetails(this.props.match.params.CustomerProductID)
  }
  componentDidUpdate() {
    if (this.props.loadingState === STATUS.FETCHING_CUSTOMER_PRODUCT_DETAILS_FAILED) {
      toast.error('FETCHING CUSTOMER_PRODUCT FAILED', {
        autoClose: 7500,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }

    if (this.props.loadingState === STATUS.CUSTOMER_PRODUCT_FAILED) {
      toast.error('UPDATE CUSTOMER_PRODUCT FAILED', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast()
    }
    if (this.props.loadingState === STATUS.CUSTOMER_PRODUCT_SUCCESS) {
      toast.success('UPDATE CUSTOMER_PRODUCT SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          this.props.clearToast()
        }
      })
      this.props.clearToast(STATUS.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <CustomerProductForm {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ loadingState: state.customerProduct.loadingState })

export default connect(mapStateToProps, { getCustomerProductDetails, clearToast })(CustomerProductEdit)
