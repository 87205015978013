import React, { Fragment, useEffect, useState } from 'react'
import Proxies from '../../components/proxies'
import CollapsibleCard from 'Components/collapsibleCard'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DynamicTable from 'Components/dynamicTable'
import { Button, ButtonGroup, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import FormField from 'Components/formField'

const CompetitionUsers = props => {
  const CompetitionID = props && props.match && props.match.params && props.match.params.CompetitionID
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [userFilterValue, setUserFilterValue] = useState('active')
  const [selectWinner, setSelectWinner] = useState(null)
  const [modelOpen, setModalOpen] = useState(false)
  const [competitionState, setCompetitionState] = useState({})

  function getCompetition() {
    Proxies.GET('competitionv2/fetch', { CompetitionID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setCompetitionState(responseJSON)
      }
      setLoading(false)
    })
  }

  function getCompetitionUsers() {
    Proxies.GET('competitionv2/users', { CompetitionID }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setUsers(responseJSON)
      }
      setLoading(false)
    })
  }

  function selectWinnerFun() {
    Proxies.POST('competitionv2/selectWinner', { CompetitionID: Number(CompetitionID), UserID: Number(selectWinner) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setModalOpen(false)
        setSelectWinner(null)
        getCompetition()
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    getCompetition()
    getCompetitionUsers()
  }, [])

  let acceptedUserColumn = {
    UserID: 'UserID',
    userUID: 'UserUID',
    userName: 'Brugernavn',
    userCompany: 'Brugerfirma',
    userEmail: 'Bruger e-mail',
    userPhone: 'Bruger telefon',
    timeInsert: 'Registreringsdato',
    acceptedCount: 'Indgange'
  }

  let declinedCountColumn = {
    UserID: 'UserID',
    userUID: 'UserUID',
    userName: 'Brugernavn',
    userCompany: 'Brugerfirma',
    userEmail: 'Bruger e-mail',
    userPhone: 'Bruger telefon',
    timeInsert: 'Registreringsdato',
    declinedCount: 'Udgange'
  }

  return (
    <>
      <CollapsibleCard
        startOpen={true}
        header={
          <strong>
            Brugere deltagere
            {competitionState &&
            competitionState.status === 'ENDED' &&
            competitionState.winningStrategy === 'MANUAL' &&
            !competitionState.winnerUserID ? (
              <Button style={{ marginLeft: '16px' }} onClick={() => setModalOpen(true)}>
                Vælg Vinder
              </Button>
            ) : null}
            {competitionState && competitionState.status === 'ENDED' && competitionState.winner ? (
              <span style={{ marginLeft: '32px' }}>Vinder: {competitionState.winner.userName} </span>
            ) : null}
            <ButtonGroup className='float-right'>
              <Button
                outline
                color='success'
                onClick={() => setUserFilterValue(userFilterValue === 'active' ? 'both' : 'active')}
                active={userFilterValue === 'active'}
              >
                Active
              </Button>
              <Button
                outline
                color='danger'
                onClick={() => setUserFilterValue(userFilterValue === 'inactive' ? 'both' : 'inactive')}
                active={userFilterValue === 'inactive'}
              >
                In-Active
              </Button>
            </ButtonGroup>
          </strong>
        }
      >
        {loading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'name'
              },
              fieldFunctions: {
                timeInsert: e => {
                  return <Fragment>{moment(e.timeInsert).format('DD-MM-YYYY')}</Fragment>
                },
                userUID: e => {
                  if (e.userUID) return e.userUID
                  return '-'
                }
              }
            }}
            headers={userFilterValue === 'inactive' ? declinedCountColumn : acceptedUserColumn}
            // data={users}
            data={
              users && userFilterValue !== 'both' && !!users.length
                ? users.filter(user => user.currentStatus === (userFilterValue === 'inactive' ? 'DECLINED' : 'ACCEPTED'))
                : users
            }
          />
        )}
      </CollapsibleCard>
      <Modal className='customization__modal' isOpen={modelOpen}>
        <ModalHeader toggle={() => setModalOpen(!modelOpen)}>Select Winner</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormField name='Choose user' field='winner' required>
              <Input type='select' name='winner' value={selectWinner} id='winner' onChange={e => setSelectWinner(e.target.value)}>
                {users &&
                  users.map((e, i) => {
                    return (
                      <option value={e.UserID} key={i}>
                        {e.userName}
                      </option>
                    )
                  })}
              </Input>
            </FormField>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => selectWinnerFun()}>
            Gem
          </Button>
          <Button color='secondary' onClick={() => setModalOpen(!modelOpen)}>
            Luk
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CompetitionUsers
