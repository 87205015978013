import React from 'react'
import { Component } from 'reflux'

export default class DashboardFrame extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { DashboardID, DashboardToken, Criteria } = this.props.match.params

    if (!DashboardID || !DashboardToken) return <h3>Could not load dashboard</h3>

    const source = `https://analytics.zoho.eu/open-view/${DashboardID}/${DashboardToken}?${Criteria}`
    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh', width: '100%' }}>
          <iframe frameBorder='0' width='100%' height='100%' src={source}></iframe>
        </div>
      </React.Fragment>
    )
  }
}
