import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import CollapsibleCard from 'Components/collapsibleCard'
import FormField from 'Components/formField'
import Proxies from '../../components/proxies'
import { StyledDropdown } from '../campaigns/SantanderCampaignUserSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { Link, Redirect } from 'react-router-dom'
import Comment from '../../components/comment'
import { TagsInput } from 'Components/tagsInput'
import moment from 'moment'
import 'moment/locale/da'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import TimePicker from 'react-time-picker'
import styledComponent from 'styled-components'
import Status from '../../components/status'
import { getCroppedImg } from '../imageCropPreview'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import BroadCastPopUp from '../../components/broadcastPopUp'

const { GET, POST } = Proxies

const defaultImage = 'https://cdn.upgrader.club/4e163601-0ff6-4e2d-b29d-54441cd7ef20-default.png'

const defaultCrop = {
  unit: '%',
  width: 50,
  aspect: 1 / 1.67
}

const EmailLayoutOption = styledComponent.div`
height: 110px;
margin: auto;
text-align: center;
`
const EmailLayoutPreviewContainer = styledComponent.div`
width: 100%;
min-height: 500px;
border: 0.5px solid #CCC;
`

const L001 = () => (
  <EmailLayoutOption>
    <img width='90' height='100' src='https://cdn.upgrader.club/2387c36e-cb69-4b50-849c-9851e0fcd4ee-default.png' />
  </EmailLayoutOption>
)

const L002 = () => (
  <EmailLayoutOption>
    <img width='90' height='100' src='https://cdn.upgrader.club/159d84f3-e4a3-42f2-9429-e4d6323e0dd7-default.png' />
  </EmailLayoutOption>
)

const L003 = () => (
  <EmailLayoutOption>
    <img width='90' height='100' src='https://cdn.upgrader.club/2dd974e8-d718-4231-bbcc-9bac14afbeb6-default.png' />
  </EmailLayoutOption>
)

const emailLayouts = [
  {
    layoutName: 'L001',
    selectionHtml: <L001 />
  },
  {
    layoutName: 'L002',
    selectionHtml: <L002 />
  },
  {
    layoutName: 'L003',
    selectionHtml: <L003 />
  }
]

const CampaignForm = props => {
  const [campaignDetails, setCampaignDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [showLinkModal, setShowLinkModal] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [src, setSrc] = useState(null)
  const [linkValue, setLinkValue] = useState('')
  const [brands, setBrands] = useState([])
  const [groups, setGroups] = useState(campaignDetails.groups || [])
  const [users, setUsers] = useState(campaignDetails.users || [])
  const [title, setTitle] = useState(campaignDetails.title || '')
  const [smsBody, setSmsBody] = useState(campaignDetails.smsBody || '')
  const [tags, setTags] = useState(campaignDetails.tags || '')
  const [pickedDate, setPickedDate] = useState(new Date())
  const [pickedTime, setPickedTime] = useState(new Date().getHours() + ':' + new Date().getMinutes())
  const [publishDate, setPublishDate] = useState(null)
  const [chosenDate, setChosenDate] = useState('')
  const [emailSubject, setEmailSubject] = useState(campaignDetails.emailSubject || '')
  const [emailBody, setEmailBody] = useState(campaignDetails.emailBody || '')
  const [emailLayout, setEmailLayout] = useState(campaignDetails.emailLayout || emailLayouts[0].layoutName)
  const [includeAdmins, setIncludeAdmins] = useState(campaignDetails.includeAdmins || 0)
  const [notificationType, setNotificationType] = useState(campaignDetails.notificationType)
  const imageRef = useRef(null)
  const [fileName, setFileName] = useState('')
  const [crop, setCrop] = useState(defaultCrop)
  const [broadCastPopUp, setBroadCastPopUp] = useState(false)
  const [createdBy, setCreatedBy] = useState('')
  const [collapse, setCollapse] = useState(null)

  const { report } = props

  const assignImageUrl = identifier => {
    if (!campaignDetails.images || !campaignDetails.images.length) return defaultImage

    const relevantImage = campaignDetails.images.filter(item => item.imageIdentifier === identifier)
    if (!relevantImage.length) return defaultImage

    return relevantImage[0].imageUrl
  }

  const assignImageID = identifier => {
    if (!campaignDetails.images || !campaignDetails.images.length) return null

    const relevantImage = campaignDetails.images.filter(item => item.imageIdentifier === identifier)
    if (!relevantImage.length) return null

    return relevantImage[0].ImageID
  }

  const assignImageLink = identifier => {
    if (!campaignDetails.images || !campaignDetails.images.length) return null

    const relevantImage = campaignDetails.images.filter(item => item.imageIdentifier === identifier)
    if (!relevantImage.length) return null

    return relevantImage[0].imageLink
  }

  const imageData = () => [
    {
      imageIdentifier: 'L001_left',
      imageUrl: assignImageUrl('L001_left'),
      imageLink: assignImageLink('L001_left'),
      ImageID: assignImageID('L001_left')
    },
    {
      imageIdentifier: 'L001_center',
      imageUrl: assignImageUrl('L001_center'),
      imageLink: assignImageLink('L001_center'),
      ImageID: assignImageID('L001_center')
    },
    {
      imageIdentifier: 'L001_right',
      imageUrl: assignImageUrl('L001_right'),
      imageLink: assignImageLink('L001_right'),
      ImageID: assignImageID('L001_right')
    },
    {
      imageIdentifier: 'L002_left',
      imageUrl: assignImageUrl('L002_left'),
      imageLink: assignImageLink('L002_left'),
      ImageID: assignImageID('L002_left')
    },
    {
      imageIdentifier: 'L002_right',
      imageUrl: assignImageUrl('L002_right'),
      imageLink: assignImageLink('L002_right'),
      ImageID: assignImageID('L002_right')
    },
    {
      imageIdentifier: 'L003_topLeft',
      imageUrl: assignImageUrl('L003_topLeft'),
      imageLink: assignImageLink('L003_topLeft'),
      ImageID: assignImageID('L003_topLeft')
    },
    {
      imageIdentifier: 'L003_topRight',
      imageUrl: assignImageUrl('L003_topRight'),
      imageLink: assignImageLink('L003_topRight'),
      ImageID: assignImageID('L003_topRight')
    },
    {
      imageIdentifier: 'L003_bottomLeft',
      imageUrl: assignImageUrl('L003_bottomLeft'),
      imageLink: assignImageLink('L003_bottomLeft'),
      ImageID: assignImageID('L003_bottomLeft')
    },
    {
      imageIdentifier: 'L003_bottomRight',
      imageUrl: assignImageUrl('L003_bottomRight'),
      imageLink: assignImageLink('L003_bottomLinkht'),
      ImageID: assignImageID('L003_bottomRight')
    }
  ]

  const [images, setImages] = useState(imageData())
  const [selectedLayoutDesign, setSelectedLayoutDesign] = useState('')
  const [templatecampaign, setTemplateCampaign] = useState([])
  const [selectedTemplateCampaign, setSelectedTemplateCampaign] = useState([])
  const [currentImageId, setCurrentImageId] = useState(null)

  const [campaignStatus, setCampaignStatus] = useState('Kladde')
  const [CampaignID, setCampaignID] = useState(
    !!props.match.params && !isNaN(props.match.params.NotificationCampaignID) ? Number(props.match.params.NotificationCampaignID) : null
  )
  const [numIntervals, setNumIntervals] = useState('')
  const [intervalType, setIntervalType] = useState(null)

  const selectedTags = tag => {
    setTags(tag.join(','))
  }

  const fetchBrands = useCallback(() => {
    GET('brands/getAssociatedBrands').then(responseJSON => {
      if (responseJSON.error) return setBrands([])
      if (responseJSON && responseJSON.length === 1) {
        setCampaignDetails({ ...campaignDetails, BrandID: Number(responseJSON[0].BrandID) || null })
      }
      return setBrands(responseJSON.map(item => ({ BrandID: item.BrandID, brandName: item.brandName })))
    })
  }, [])

  const fetchGroups = useCallback(() => {
    if (!campaignDetails || !campaignDetails.BrandID) return setGroups([])
    GET(`groups/fetch-groups`, { BrandID: campaignDetails.BrandID }).then(responseJSON => {
      if (responseJSON.error) return setGroups([])
      const groupsInfo = responseJSON.map(item => ({ GroupID: item.GroupID, moduleName: item.moduleName }))
      if (!!campaignDetails && !!campaignDetails.groups && !!campaignDetails.groups.length) {
        for (const group of groupsInfo) {
          group.checked = !!campaignDetails.groups.filter(item => item.GroupID === group.GroupID).length
        }
      }
      return setGroups(groupsInfo)
    })
  }, [campaignDetails])

  const fetchUsers = useCallback(() => {
    if (!campaignDetails || !campaignDetails.BrandID) return setUsers([])
    GET('notificationcampaign/getUsersOfBrand', { BrandID: campaignDetails.BrandID }).then(responseJSON => {
      if (responseJSON.error) return setUsers([])
      const usersInfo = responseJSON.map(item => ({ UserID: item.UserID, userName: item.userName, userEmail: item.userEmail }))
      if (!!campaignDetails && !!campaignDetails.users && !!campaignDetails.users.length) {
        for (const user of usersInfo) {
          user.checked = !!campaignDetails.users.filter(item => item.UserID === user.UserID).length
        }
      }
      return setUsers(usersInfo)
    })
  }, [campaignDetails])

  const fetchCampaignDetails = useCallback(() => {
    if (!CampaignID) return
    GET('notificationcampaign/fetch', { NotificationCampaignID: Number(CampaignID) }).then(responseJSON => {
      if (responseJSON.error) return setCampaignDetails(null)
      if (responseJSON.publishDate) {
        setPublishDate(new Date(responseJSON.publishDate))
        setPickedDate(new Date(responseJSON.publishDate))
        setPickedTime(new Date(responseJSON.publishDate).getHours() + ':' + new Date(responseJSON.publishDate).getMinutes())
      }

      setCampaignStatus(translateStatusToDanish(responseJSON.status) || campaignStatus)
      setTags(responseJSON.tags || tags || '')
      setTitle(responseJSON.title || title || '')
      setSmsBody(responseJSON.smsBody || smsBody || '')
      setEmailSubject(responseJSON.emailSubject || emailSubject || '')
      setEmailBody(responseJSON.emailBody || emailBody || '')
      setEmailLayout(responseJSON.emailLayout || emailLayouts[0].layoutName)
      setIncludeAdmins(responseJSON.includeAdmins || includeAdmins || 0)
      setNotificationType(responseJSON.notificationType)
      setCreatedBy(responseJSON.createdBy || createdBy || '')
      if (responseJSON.schedule) {
        setIntervalType(responseJSON.schedule.intervalType)
        setNumIntervals(responseJSON.schedule.numIntervals)
      }
      return setCampaignDetails(responseJSON)
    })
  }, [CampaignID])

  const fetchSelectedTemplate = () => {
    if (campaignDetails.BrandID) {
      setSelectedTemplateCampaign(templatecampaign.filter(e => e.BrandID === campaignDetails.BrandID))
    }
  }

  const submitCampaign = value => {
    setLoading(true)
    let status = translateStatusToEnglish(value)
    const campaignInfo = {
      ...campaignDetails,
      users: users.filter(item => !!item.checked),
      groups: groups.filter(item => !!item.checked),
      images: images.filter(item => !!item.ImageID),
      title,
      smsBody,
      tags,
      publishDate,
      emailSubject,
      emailBody,
      emailLayout,
      status,
      includeAdmins,
      notificationType
    }

    if (intervalType || campaignDetails.schedule) {
      if (campaignDetails.schedule && !intervalType) {
        campaignDetails.schedule.removed = true
      } else {
        campaignInfo.schedule = campaignDetails.schedule || {}
        campaignInfo.schedule.numIntervals = numIntervals
        campaignInfo.schedule.intervalType = intervalType
        campaignInfo.schedule.isRecurring = campaignDetails.schedule ? campaignDetails.schedule.isRecurring : true
      }
    }
    POST(`notificationcampaign/replace`, { ...campaignInfo }).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        return toast.error(responseJSON.error.message, {
          autoClose: 7000
        })
      }
      setCampaignDetails(responseJSON)
      if (!CampaignID) {
        toast.success('Campaign created successfully', {
          autoClose: 7000
        })
        window.location.assign(`/campaign/${responseJSON.NotificationCampaignID}`)
      } else {
        toast.success('Campaign updated successfully', {
          autoClose: 7000
        })
      }
    })
  }

  const selectedStatus = item => {
    setCampaignStatus(item)
  }

  const togglePopUp = () => {
    setBroadCastPopUp(false)
    window.location.assign(`/campaign/${CampaignID}/report`)
  }

  const campaignNotFound = () => {
    toast.error('Campaign not found', {
      autoClose: 7000
    })
  }

  const broadcastImmediately = CampaignID => {
    if (confirm(`Are you sure you want to publish "${title}" immediately?`)) {
      Proxies.POST('notificationcampaign/publishCampaign', { NotificationCampaignID: Number(CampaignID) }).then(responseJSON => {
        if (responseJSON.error) {
          toast.error('Action Failed', {
            autoClose: 7000
          })
        } else if (responseJSON === 'CAMPAIGN_UNKNOWN' || responseJSON === 'CANNOT_PUBLISH_CAMPAIGN') {
          toast.error(responseJSON, {
            autoClose: 7000
          })
        } else {
          setBroadCastPopUp(true)
        }
      })
    }
  }

  const translateStatusToEnglish = item => {
    switch (item) {
      case 'Kladde':
        return 'DRAFT'
      case 'Afventer':
        return 'SENT'
      case 'Godkendt':
        return 'APPROVED'
      case 'Afvist':
        return 'REJECTED'
      case 'Afsluttet':
        return 'PUBLISHED'
      default:
        return 'DRAFT'
    }
  }

  const translateStatusToDanish = item => {
    switch (item) {
      case 'DRAFT':
        return 'Kladde'
      case 'SENT':
        return 'Afventer'
      case 'APPROVED':
        return 'Godkendt'
      case 'REJECTED':
        return 'Afvist'
      case 'PUBLISHED':
        return 'Afsluttet'
      default:
        return 'Kladde'
    }
  }

  const resetCampaign = () => {
    if (isNaN(CampaignID)) {
      return <Redirect to='/campaign' push />
    }
    fetchCampaignDetails()
    fetchBrands()
    fetchGroups()
    fetchUsers()
    return
  }

  const updateGroups = item =>
    setGroups(
      groups.map(group => {
        if (Number(group.GroupID) === Number(item.value)) group.checked = item.checked
        return group
      })
    )

  const updateUsers = item =>
    setUsers(
      users.map(user => {
        if (Number(user.UserID) === Number(item.value)) user.checked = item.checked
        return user
      })
    )

  const pickDateFunction = e => {
    campareDateFunction(e)
    setPickedDate(e)
    let date = new Date(e.getFullYear() + '-' + (+e.getMonth() + 1) + '-' + e.getDate() + ' ' + pickedTime)
    setPublishDate(date)
  }

  const pickTimeFunction = e => {
    setPickedTime(e)
    let date = new Date(pickedDate.getFullYear() + '-' + (+pickedDate.getMonth() + 1) + '-' + pickedDate.getDate() + ' ' + e)
    setPublishDate(date)
  }

  const campareDateFunction = e => {
    let chooseDate = e.getFullYear() + '-' + (+e.getMonth() + 1) + '-' + e.getDate()
    let currentDate = new Date()
    currentDate = currentDate.getFullYear() + '-' + (+currentDate.getMonth() + 1) + '-' + currentDate.getDate()
    if (chooseDate === currentDate) {
      setChosenDate(true)
    } else {
      setChosenDate(false)
    }
  }

  function getTemplateCampaign() {
    Proxies.GET('notificationcampaigntemplate/pool').then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        setTemplateCampaign(responseJSON)
      }
    })
  }

  const getEmailContentLayout = () => {
    if (!images || !images.length) return
    const imageData = []
    images.forEach(item => {
      // if(!item.ImageID) return
      // imageData[item.imageIdentifier] = item.imageUrl
      imageData[item.imageIdentifier] = {
        imageUrl: item.imageUrl,
        imageLink: item.imageLink || `javascript:void(0)`
      }
    })
    POST('notificationcampaign/getEmailContentLayout', {
      layoutData: {
        layoutName: emailLayout,
        title: emailSubject,
        content: emailBody.replace('\n', '<br/>'),
        ...imageData
      }
    }).then(responseJSON => {
      setSelectedLayoutDesign(responseJSON.html)
      processImageUploads(responseJSON.imageIdentifiers)
    })
  }

  const processImageUploads = imageIdentifiers => {
    if (!imageIdentifiers || !imageIdentifiers.length) return
    imageIdentifiers.forEach(imgId => {
      const imgElement = document.getElementById(imgId)
      if (!imgElement) return

      const addImage = () => {
        const imgField = document.getElementById('campaignEmailImage')
        if (!imgField) return
        setCurrentImageId(imgId)
        imgField.click()
      }

      const linkImage = () => {
        setCurrentImageId(imgId)
        setShowLinkModal(true)
      }

      if (report) {
        imgElement.style.filter = 'grayscale(100%)'
      }

      const deleteImage = () => {
        setCurrentImageId(imgId)
        const updatedImages = images.map(item => {
          if (item.imageIdentifier !== imgId) return item
          item.ImageID = null
          item.imageUrl = defaultImage
          return item
        })
        setImages(updatedImages)
      }

      // imgElement.style.cursor = 'pointer'
      imgElement.insertAdjacentHTML(
        'afterend',
        `
        <div style="display: flex;gap: 5px;cursor: pointer;position: absolute;top: 15px;left: 25px;width: 50px;height: 25px;">
          <svg id="campaign-image-control-link-${imgId}" style="cursor: pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>
        </div>
        <div style="display: flex;gap: 5px;cursor: pointer;position: absolute;top: 15px;right: 25px;width: 50px;height: 25px;">
          <svg id="campaign-image-control-edit-${imgId}" style="cursor: pointer" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" class="svg-inline--fa fa-edit fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg>

          <svg id="campaign-image-control-delete-${imgId}" style="cursor: pointer" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg>
        </div>
      `
      )
      const editIcon = document.getElementById(`campaign-image-control-edit-${imgId}`)
      const deleteIcon = document.getElementById(`campaign-image-control-delete-${imgId}`)
      const linkIcon = document.getElementById(`campaign-image-control-link-${imgId}`)

      if (editIcon)
        !report &&
          editIcon.addEventListener('click', e => {
            addImage(imgId)
            e.preventDefault()
          })
      if (deleteIcon)
        !report &&
          deleteIcon.addEventListener('click', e => {
            deleteImage(imgId)
            e.preventDefault()
          })
      if (linkIcon)
        !report &&
          linkIcon.addEventListener('click', e => {
            linkImage(imgId)
            e.preventDefault()
          })
    })
  }

  const updateImageLink = value => {
    const updatedImages = images.map(item => {
      if (item.imageIdentifier !== currentImageId) return item
      item.imageLink = `${value.includes('http') ? '' : 'https://'}${value}`
      return item
    })
    setImages(updatedImages)
    setShowLinkModal(false)
  }

  const onImageLoaded = image => {
    imageRef.current = image
  }
  const onCropComplete = crop => {
    // makeClientCrop(crop);
    setCrop(crop)
  }
  const onCropChange = crop => {
    // 2nd param is percentCrop but not used here
    setCrop(crop)
  }
  // const makeClientCrop = async(crop) => {
  //   if (imageRef.current && crop.width && crop.height) {
  //     const croppedImageUrlVal = await getCroppedImage(
  //       imageRef.current,
  //       crop,
  //       fileName
  //     );
  //     setCroppedImageUrl(croppedImageUrlVal)
  //   }
  // }

  const readImage = data => {
    if (!data.target.files.length) return
    let file = data.target.files[0]
    setFileName(file.name)
    const reader = new FileReader()
    reader.addEventListener('load', () => setSrc(reader.result))
    reader.readAsDataURL(file)
  }

  const cropImage = () => {
    if (!src) return
    setShowImageModal(true)
  }

  const uploadImage = () => {
    getCroppedImg(imageRef.current, crop, fileName).then(result => {
      Proxies.uploadImage(result).then(responseJSON => {
        if (!responseJSON || responseJSON.error) return
        const emailImages = [...images]
        emailImages.forEach(element => {
          if (element.imageIdentifier !== currentImageId) return
          element.ImageID = responseJSON.ImageID
          element.imageUrl = responseJSON.imageOriginSource ? responseJSON.imageOriginSource : null
        })
        setImages(emailImages)
        setSrc(null)
        setShowImageModal(false)
        setCrop(defaultCrop)
      })
    })
  }

  function RemoveCampaign(CampaignID) {
    Proxies.POST('notificationcampaign/removeCampaign', { NotificationCampaignID: Number(CampaignID) }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        window.location.assign('/campaign')
      }
    })
  }

  function sendCopyToAdmins(val) {
    if (val === true) {
      setIncludeAdmins(1)
    } else {
      setIncludeAdmins(0)
    }
  }

  useEffect(() => {
    setCampaignID(!!props.match.params && !!props.match.params.NotificationCampaignID ? Number(props.match.params.NotificationCampaignID) : null)
  }, [props.match.params])

  useEffect(() => {
    fetchBrands()
    getTemplateCampaign()
    fetchCampaignDetails()
  }, [CampaignID])

  useEffect(() => {
    fetchGroups()
    fetchUsers()
    fetchSelectedTemplate()
  }, [campaignDetails.BrandID])

  useEffect(() => {
    getEmailContentLayout()
  }, [emailLayout, emailSubject, images, emailBody])

  useEffect(() => {
    setImages(imageData())
    getEmailContentLayout()
  }, [campaignDetails])

  useEffect(() => {
    if (!showLinkModal) return
    const img = images.filter(i => i.imageIdentifier === currentImageId)
    if (!img.length) return setLinkValue('')
    return setLinkValue(img[0].imageLink || '')
  }, [showLinkModal])

  useEffect(() => {
    cropImage()
  }, [src])

  return (
    <div style={{ marginBottom: collapse ? 80 : 20 }}>
      <div style={{ width: '100%', height: '40px', marginBottom: '10px', display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
        <Button disabled={report ? true : false} className='float-right' onClick={() => submitCampaign(campaignStatus)} style={{ color: 'white' }}>
          Gem ændringer
        </Button>
        <Button
          disabled={report ? true : false}
          style={{ backgroundColor: 'black', color: 'white', border: 'none', padding: '8px' }}
          className='float-right'
          tag={Link}
          to={'/campaign'}
        >
          Annuller ændringer
        </Button>
        {!report && (
          <Button
            color='danger'
            className='float-right'
            disabled={!CampaignID ? true : false}
            onClick={() => {
              if (CampaignID) {
                if (confirm(`Are you sure you want to delete "${title}"?`)) {
                  RemoveCampaign(CampaignID)
                }
              }
            }}
          >
            <FontAwesomeIcon icon='trash' />
            &nbsp;&nbsp; Slet kampagne
          </Button>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '70%', marginRight: 20 }}>
          <CollapsibleCard
            onClick={() => setCollapse(!collapse)}
            startOpen={report ? false : true}
            header={
              <strong>
                {report
                  ? collapse
                    ? 'Fold sammen'
                    : 'Fold kampagne ud'
                  : !campaignDetails.NotificationCampaignID
                  ? 'Opret ny kampagne'
                  : 'Rediger kampagne'}
              </strong>
            }
          >
            <Form>
              {/* Select Brand */}
              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='BrandID'> Brand </Label>
                  </Col>
                  {brands && brands.length !== 1 && (
                    <Col xs='9'>
                      <Input
                        className={campaignDetails.BrandID ? 'is-valid' : 'is-invalid'}
                        required
                        disabled={report && true}
                        type='select'
                        name='BrandID'
                        value={campaignDetails.BrandID ? Number(campaignDetails.BrandID) : ''}
                        id='BrandID'
                        onChange={e => {
                          setSelectedTemplateCampaign(templatecampaign.filter(temp => Number(temp.BrandID) === Number(e.target.value)))
                          setCampaignDetails({ ...campaignDetails, BrandID: Number(e.target.value) || null })
                        }}
                      >
                        <option value=''>Click to Select</option>
                        {brands.map((e, i) => (
                          <option value={Number(e.BrandID)} key={i}>
                            {e.BrandID} - {e.brandName}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  )}
                  {brands && brands.length === 1 && (
                    <Col xs='9'>
                      <Input
                        className={campaignDetails.BrandID ? 'is-valid' : 'is-invalid'}
                        required
                        disabled={report && true}
                        type='text'
                        name='BrandID'
                        value={brands[0] ? `${brands[0].BrandID} - ${brands[0].brandName}` : ''}
                        id='BrandID'
                      ></Input>
                    </Col>
                  )}
                </Row>
              </FormGroup>

              {/* Select Groups */}
              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='BrandID'> Groups </Label>
                  </Col>
                  <Col xs='9'>
                    <StyledDropdown
                      disabled={report ? true : !campaignDetails || !campaignDetails.BrandID}
                      data={
                        !groups.length
                          ? {}
                          : {
                              label: 'All',
                              value: 'all',
                              expanded: true,
                              children: groups.map(item => ({ label: item.moduleName, value: item.GroupID, checked: item.checked }))
                            }
                      }
                      onChange={item => updateGroups(item)}
                      keepTreeOnSearch={true}
                      keepChildrenOnSearch={true}
                      showPartiallySelected={true}
                      texts={{
                        placeholder: !!campaignDetails && campaignDetails.BrandID && !groups.length ? 'Ingen grupper fundet' : 'Vælg grupper',
                        noMatches: 'Ingen grupper fundet',
                        labelRemove: 'Fjern'
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>

              {/* Select Users */}
              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='BrandID'> Users </Label>
                  </Col>
                  <Col xs='9'>
                    <StyledDropdown
                      disabled={report ? true : !campaignDetails || !campaignDetails.BrandID}
                      data={
                        !users.length
                          ? {}
                          : {
                              label: 'All',
                              value: 'all',
                              expanded: true,
                              children: users.map(item => ({
                                label: `${item.userName} - ${item.userEmail}`,
                                value: item.UserID,
                                checked: item.checked
                              }))
                            }
                      }
                      onChange={item => updateUsers(item)}
                      keepTreeOnSearch={true}
                      keepChildrenOnSearch={true}
                      showPartiallySelected={true}
                      texts={{
                        placeholder: !!campaignDetails && campaignDetails.BrandID && !users.length ? 'Ingen brugger fundet' : 'Vælg brugger',
                        noMatches: 'Ingen brugger fundet',
                        labelRemove: 'Fjern'
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>

              {/* Title */}
              <FormField
                disabled={report && true}
                required
                fieldWidth='9'
                data={{ title }}
                field={'title'}
                name='Title'
                onChange={e => setTitle(e.target.value || '')}
              />
              <Row className='mb-2'>
                <Col xs='3'>
                  <Label for='notificationType'>Send e-mails til alle</Label>
                </Col>
                <Col xs='9'>
                  <Input
                    disabled={!!report}
                    type='checkbox'
                    value={notificationType}
                    className='m-1 batch-header'
                    checked={notificationType === 'EMAIL' ? true : false}
                    name='notificationType'
                    onChange={e => {
                      setNotificationType(e.target.checked === true ? 'EMAIL' : null)
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs='3'></Col>
                <Col xs='9' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', fontSize: 12, marginBottom: 5 }}>
                  {smsBody.length > 0 && smsBody.length + ' tegn'}
                </Col>
              </Row>
              <Row>
                <Col xs='3'>
                  <label>Indhold SMS</label>
                </Col>
                <Col xs='2' style={{ marginBottom: 16 }}>
                  <Input
                    disabled={!!report || notificationType === 'EMAIL'}
                    type='select'
                    id='BrandID'
                    onChange={e => setSmsBody(smsBody + ` {{${e.target.value}}} `)}
                  >
                    <option value=''>Indsæt felt</option>
                    {[
                      { label: 'Navn', text: 'userName' },
                      { label: 'Fornavn', text: 'userFirstName' },
                      { label: 'Email', text: 'userEmail' },
                      { label: 'Pointstatus', text: 'walletAmountPoints' }
                    ].map((e, i) => (
                      <option value={e.text} key={i}>
                        {e.label}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <FormField
                disabled={!!report || notificationType === 'EMAIL'}
                name='SMS visning'
                fieldWidth='9'
                rows={5}
                data={{ smsBody }}
                onChange={e => setSmsBody(e.target.value)}
                field={'smsBody'}
                type='textarea'
              />
              <FormField
                fieldWidth='9'
                disabled={report && true}
                data={{ emailSubject }}
                field={'emailSubject'}
                name='Overskrift'
                onChange={e => setEmailSubject(e.target.value || '')}
              />
              <Row>
                <Col xs='3'>
                  <label>Indhold Email</label>
                </Col>
                <Col xs='2' style={{ marginBottom: 16 }}>
                  <Input disabled={report && true} type='select' id='BrandID' onChange={e => setEmailBody(emailBody + ` {{${e.target.value}}} `)}>
                    <option value=''>Indsæt felt</option>
                    {[
                      { label: 'Navn', text: 'userName' },
                      { label: 'Fornavn', text: 'userFirstName' },
                      { label: 'Email', text: 'userEmail' },
                      { label: 'Pointstatus', text: 'walletAmountPoints' }
                    ].map((e, i) => (
                      <option value={e.text} key={i}>
                        {e.label}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <FormField
                fieldWidth='9'
                disabled={report && true}
                rows={5}
                data={{ emailBody }}
                onChange={e => setEmailBody(e.target.value)}
                field={'emailBody'}
                type='textarea'
              />

              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='NotificationCampaignTemplateID'> Vælg template </Label>
                  </Col>
                  <Col xs='9'>
                    <Input
                      className={campaignDetails.NotificationCampaignTemplateID ? 'is-valid' : 'is-invalid'}
                      required
                      disabled={report && true}
                      type='select'
                      name='NotificationCampaignTemplateID'
                      value={campaignDetails.NotificationCampaignTemplateID ? Number(campaignDetails.NotificationCampaignTemplateID) : ''}
                      id='NotificationCampaignTemplateID'
                      onChange={e => setCampaignDetails({ ...campaignDetails, NotificationCampaignTemplateID: Number(e.target.value) || null })}
                    >
                      <option value=''>Click to Select</option>
                      {selectedTemplateCampaign.map((e, i) => (
                        <option value={Number(e.NotificationCampaignTemplateID) || ''} key={i}>
                          {e.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Row>
                  <Col xs='3'>
                    <Label for='emailLayout'> Design Email </Label>
                  </Col>
                  <Col xs='3'>
                    <UncontrolledDropdown>
                      <DropdownToggle caret disabled={report && true}>
                        Change layout
                      </DropdownToggle>
                      <DropdownMenu style={{ overflow: 'visible' }}>
                        {emailLayouts.map((s, is) => {
                          return (
                            <DropdownItem
                              onClick={() => {
                                setEmailLayout(s.layoutName)
                              }}
                              value={s.layoutName}
                              key={is}
                              style={{ color: 'black' }}
                            >
                              {s.selectionHtml}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col xs='3'>
                    {emailLayout === 'L001' && <L001 />}
                    {emailLayout === 'L002' && <L002 />}
                    {emailLayout === 'L003' && <L003 />}
                  </Col>
                </Row>
                <Row style={{ margin: '2rem 0' }}>
                  <Col>
                    <EmailLayoutPreviewContainer dangerouslySetInnerHTML={{ __html: selectedLayoutDesign }} />
                  </Col>
                </Row>
              </FormGroup>
              <div style={{ display: 'none' }}>
                {
                  <Input
                    name='campaignEmailImage'
                    disabled={report && true}
                    id='campaignEmailImage'
                    field='upload'
                    type='file'
                    noValue={true}
                    onChange={e => {
                      readImage(e)
                    }}
                    accept='image/*'
                  />
                }
              </div>
              <Button
                color='success'
                disabled={report && true}
                onClick={() => submitCampaign(campaignStatus)}
                // disabled={!campaignDetails.BrandID || (!campaignDetails.groups.length && !campaignDetails.users.length)}
              >
                {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
              </Button>
              <Button disabled={report && true} color='danger' onClick={resetCampaign} className='ml-2'>
                Nulstil
              </Button>
            </Form>
          </CollapsibleCard>
        </div>
        <div style={{ width: '34%', display: !report ? 'block' : collapse ? 'block' : 'none' }}>
          <Button
            className='float-right'
            tag={Link}
            to={'/campaign'}
            style={{ marginLeft: '16px', backgroundColor: 'transparent', color: 'GrayText', fontWeight: 'bold', border: 'none' }}
          >
            <FontAwesomeIcon icon='arrow-left' />
            &nbsp;&nbsp;Tilbage til oversigt
          </Button>
          <div style={{ backgroundColor: '#ffffff', width: '100%', padding: 20, marginTop: 50, borderRadius: 5, paddingBottom: 30 }}>
            <Label style={{ fontSize: 18 }} for='BrandID'>
              Vælg publiseringsdato og tidspunkt
            </Label>
            <FormGroup>
              <div>
                <Calendar minDate={new Date()} onChange={e => pickDateFunction(e)} value={pickedDate} />
                <div style={{ backgroundColor: '#006edc', color: 'white', display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                  <p style={{ paddingLeft: '15px' }}>{chosenDate ? 'I dag' : 'valgt dato'}</p>
                  <p style={{ paddingRight: '15px' }}>{moment(pickedDate).format('MMM DD, YYYY')}</p>
                </div>
              </div>
              <div style={{ width: '100%', marginTop: '10px' }}>
                Klokken &nbsp;&nbsp;
                <TimePicker disabled={report && true} clearIcon={null} disableClock={true} onChange={e => pickTimeFunction(e)} value={pickedTime} />
              </div>
            </FormGroup>
          </div>
          <div style={{ backgroundColor: '#ffffff', width: '100%', padding: 20, marginTop: 22, borderRadius: 5 }}>
            <Label style={{ fontSize: 18 }} for='schedule'>
              Gentag
            </Label>
            <Input disabled={report && true} type='select' id='schedule' value={intervalType} onChange={e => setIntervalType(e.target.value)}>
              <option value=''>None</option>
              <option value='DAILY'>Dagligt</option>
              <option value='WEEKLY'>Ugentlig</option>
              <option value='FORTNIGHTLY'>Hver 14. dag</option>
              <option value='MONTHLY'>Månedligt</option>
              <option value='YEARLY'>Årligt</option>
            </Input>
            <Row style={{ marginTop: '20px' }}>
              <Col xs='3'>
                <label># Intervals</label>
              </Col>
              <Col xs='9' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', fontSize: 12, marginBottom: 5 }}>
                <Input
                  disabled={report && true}
                  value={numIntervals}
                  type='text'
                  id='scheduleIntervalNumber'
                  onChange={e => setNumIntervals(e.target.value)}
                />
              </Col>
            </Row>
          </div>
          <div style={{ backgroundColor: '#ffffff', width: '100%', padding: 20, marginTop: 22, borderRadius: 5 }}>
            <Label style={{ fontSize: 18 }} for='BrandID'>
              Send kopi til admins ved udsendelse
            </Label>
            <FormGroup check style={{ display: 'flex', gap: '40px' }}>
              <Input
                disabled={report && true}
                type='checkbox'
                checked={includeAdmins === 1 ? true : false}
                onClick={e => {
                  sendCopyToAdmins(e.target.checked)
                }}
              />{' '}
              <Label check style={{ width: '98%', marginLeft: '5px' }}>
                Ja tak, jeg ønsker at sende en kopi af kampangen til admins
              </Label>
            </FormGroup>
          </div>
          <div style={{ backgroundColor: '#ffffff', width: '100%', padding: 20, marginTop: 22, borderRadius: 5 }}>
            <Label style={{ fontSize: 18 }} for='BrandID'>
              Tags
            </Label>
            <FormGroup>
              <TagsInput disabled={report && true} CampaignID={CampaignID} selectedTags={selectedTags} tag={tags === '' ? [] : tags.split(',')} />
            </FormGroup>
          </div>
          <div style={{ width: '100%' }}>
            <Comment CampaignID={CampaignID} />
          </div>
          <div style={{ width: '100%' }}>
            <Status
              report={report}
              CreatedBy={createdBy}
              CampaignID={CampaignID}
              submitCampaign={submitCampaign}
              campaignStatus={campaignStatus}
              selectedStatus={selectedStatus}
            />
          </div>
          <div style={{ backgroundColor: '#ffffff', width: '100%', padding: 20, marginTop: 22, borderRadius: 5 }}>
            <Label style={{ fontSize: 18 }} for='BrandID'>
              Publish Immediately
            </Label>
            <FormGroup>
              <Button
                disabled={report ? true : campaignStatus === 'Godkendt' || campaignStatus === 'Afsluttet' ? false : true}
                style={
                  campaignStatus === 'Godkendt' || campaignStatus === 'Afsluttet'
                    ? { width: '100%', marginTop: 20 }
                    : { cursor: 'not-allowed', width: '100%', marginTop: 20 }
                }
                onClick={
                  CampaignID
                    ? () => {
                        broadcastImmediately(CampaignID)
                      }
                    : () => {
                        campaignNotFound()
                      }
                }
              >
                Publish Immediately
              </Button>
            </FormGroup>
            {broadCastPopUp && <BroadCastPopUp togglePopUp={togglePopUp} broadCastToggle={broadCastPopUp} title={title} />}
          </div>
        </div>
      </div>
      <Modal isOpen={showLinkModal} toggle={() => setShowLinkModal(false)} size='md' centered>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
            <Input
              id='campaign-image-link'
              defaultValue={linkValue}
              placeholder='provide link for this image'
              onKeyUp={e => {
                if (e.key.toUpperCase() === 'ENTER') updateImageLink(e.target.value)
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button onClick={() => updateImageLink(document.getElementById('campaign-image-link').value)}>Gem</Button>
              <Button color='danger' onClick={() => setShowLinkModal(false)}>
                Nulstil
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={showImageModal} toggle={() => setShowImageModal(false)} size='md' centered>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
            {!!src && <ReactCrop src={src} crop={crop} onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={onCropChange} />}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button onClick={() => uploadImage()}>Gem</Button>
              <Button color='danger' onClick={() => setShowImageModal(false)}>
                Nulstil
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CampaignForm
