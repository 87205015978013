import { configureStore } from '@reduxjs/toolkit'
import kamCompanyReducer from 'Reducers/kamCompanyReducers'
import kamCompanyDashboardReducer from 'Reducers/kamCompanyDashboardReducer'
import stockReducer from 'Reducers/stockReducer'
import loginReducer from 'Reducers/loginReducer'
import dataImportReducer from 'Reducers/dataImportReducer'
import dataRetentionReducer from 'Reducers/dataRetentionReducer'
import supplierReducer from 'Reducers/supplierReducer'
import announcementReducer from 'Reducers/announcementReducer'
import customerProductReducer from 'Reducers/customerProductReducer'
import cronReducer from 'Reducers/cronReducer'
import cmsReducer from 'Reducers/cmsReducer'
import reportsReducer from 'Reducers/reportsReducer'
import customerSalesRewardReducer from 'Reducers/customerSalesRewardReducer'
import customerSalesClaimReducer from 'Reducers/customerSalesClaimReducer'

const store = configureStore({
  reducer: {
    kamCompany: kamCompanyReducer,
    kamDashboard: kamCompanyDashboardReducer,
    stock: stockReducer,
    login: loginReducer,
    dataImport: dataImportReducer,
    dataRetention: dataRetentionReducer,
    supplier: supplierReducer,
    announcement: announcementReducer,
    customerProduct: customerProductReducer,
    cron: cronReducer,
    cms: cmsReducer,
    reports: reportsReducer,
    customerSalesReward: customerSalesRewardReducer,
    customerSalesClaim: customerSalesClaimReducer
  }
})

export default store
