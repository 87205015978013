import React, { useState } from 'react'
import { ContactModal, ShowSendEmailModal } from '../../components/dashboard/LastMonthTotalActivationPoints'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MailIcon = styled(FontAwesomeIcon)`
  color: #28d094;
`

const SettingIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -5;
  left: 8px;
  color: #1b966a;
  width: 15px;
  height: 15px;
`

export default function KamDashboard() {
  const [showBrandContactModal, setShowBrandContactModal] = useState(false)
  const [showSendEmailModal, setShowSendEmailModal] = useState(false)
  const source = `https://analytics.zoho.eu/open-view/69771000004654224/755e0041c1d6b724c90c3601d5b1bb88`

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', padding: 16 }}>
        <div style={{ cursor: 'pointer', marginRight: 8 }} onClick={() => setShowBrandContactModal(true)}>
          <div style={{ position: 'relative' }}>
            <MailIcon icon={'envelope'} size='lg' title='Mail indstilling' />
            <SettingIcon icon={'cog'} size='lg' title='Mail indstilling' />
          </div>
        </div>

        <div style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => setShowSendEmailModal(true)}>
          <div style={{ position: 'relative' }}>
            <MailIcon icon={'envelope'} size='lg' title='Mail sende' />
            <SettingIcon icon={'share'} size='sm' title='Mail sende' />
          </div>
        </div>
      </div>

      <ContactModal showDepartments={true} showBrandContactModal={showBrandContactModal} closeModal={() => setShowBrandContactModal(false)} />

      <ShowSendEmailModal
        emailBody={``}
        showSendEmailModal={showSendEmailModal}
        closeModal={() => {
          setShowSendEmailModal(false)
        }}
        isKamDashboard={true}
      />
      <iframe frameBorder='0' width='100%' height='1200' src={source}></iframe>
    </React.Fragment>
  )
}
