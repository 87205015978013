import React, { useEffect, useState } from 'react'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import moment from 'moment'
import { toast } from 'react-toastify'
import Proxies from '../../components/proxies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let headers = {
  userName: 'Bruger Navn',
  userUID: 'Unikt ID',
  dealerCVR: 'DealerCVR',
  dealerUID: 'DealerID',
  userCompany: 'Virksomhed',
  walletNote: 'Wallet Note',
  walletAmountPoints: 'Wallet Point',
  walletType: 'Wallet Type',
  walletInsertDate: 'Dato',
  orderFlowStatus: 'Order Status',
  shippedDate: 'Sendt dato'
}

const OrdersListWithinDates = props => {
  let { startDate, endDate } = props.match.params
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    Proxies.GET('reports/all-orders-within-dates', {
      endDate,
      startDate
    })
      .then(responseJSON => {
        if (responseJSON && responseJSON.length && Array.isArray(responseJSON)) {
          setData(
            responseJSON.map(item => {
              item.walletInsertDate = moment(item.walletInsertDate).format('DD-MM-YYYY')
              item.shippedDate = moment(item.shippedDate).format('DD-MM-YYYY')
              return item
            })
          )
        } else {
          setData([])
        }
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <FontAwesomeIcon icon='spinner' spin />
  }
  if (!data.length) {
    toast.error(`No details as on ${endDate}`, {
      autoClose: 5500,
      onClose: () => {
        window.close()
      }
    })
    return <p>No details as on {endDate}</p>
  }
  return (
    <React.Fragment>
      <CollapsibleCard
        header={
          <strong>
            Ordrer fra {moment(startDate).format('DD-MM-YYYY')} til {moment(endDate).format('DD-MM-YYYY')}
          </strong>
        }
        startOpen={data.length ? true : false}
      >
        <DynamicTable data={data} headers={headers} />
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default OrdersListWithinDates
