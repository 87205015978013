import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { fetchProducts, STATUS, toggleProductVariationContent } from 'Reducers/actions/stockActions'
import styled from 'styled-components'
import { connect } from 'react-redux'

const StyledSpan = styled.span`
  white-space: nowrap !important;
`
const StyledAnchor = styled.a`
  cursor: pointer !important;
`
class ProductsOverview extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.fetchProducts()
  }

  render() {
    return (
      <CollapsibleCard startOpen header={<strong>Products</strong>}>
        {this.props.fetchingProducts === STATUS.LOADING_PRODUCTS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'ProductID'
              },
              fieldFunctions: {
                variations: e => {
                  let variations = []
                  let reducedVariations = e.variations.reduce((acc, cur, idx) => {
                    let line = cur.variationTitle
                    if (cur.variationStockable !== 0) {
                      line += ' (' + cur.variationStock + ')'
                    }
                    acc.push(line)
                    acc.push(<br key={idx} />)
                    return acc
                  }, [])
                  if (e.variationReadMore && e.variations.length > 3) {
                    variations.push(
                      <StyledAnchor
                        key={reducedVariations.length}
                        onClick={() => this.props.toggleProductVariationContent({ ProductID: e.ProductID, type: 'variation' })}
                      >
                        Show Less
                      </StyledAnchor>
                    )
                    variations.push(<br />)
                  }
                  if (e.variationReadLess) {
                    reducedVariations = reducedVariations.splice(0, 4)
                    variations.push(
                      <StyledAnchor
                        key={reducedVariations.length}
                        onClick={() => this.props.toggleProductVariationContent({ ProductID: e.ProductID, type: 'variation' })}
                      >
                        Show More
                      </StyledAnchor>
                    )
                    variations.push(<br />)
                  }
                  variations = variations.concat(reducedVariations)
                  return <StyledSpan>{variations}</StyledSpan>
                },
                minimumStock: e => {
                  let minimumStock = []
                  let reducedVariations = e.variations.reduce((acc, cur, idx) => {
                    let line = cur.variationTitle
                    if (cur.subscriptions && cur.subscriptions.length > 0) {
                      line += ' (' + cur.subscriptions[0].triggerValue + ')'
                      acc.push(line)
                      acc.push(<br key={idx} />)
                    } else {
                      acc.push('-')
                      acc.push(<br key={idx} />)
                    }
                    return acc
                  }, [])
                  if (e.variationReadMore && e.variations.length > 3) {
                    minimumStock.push(<br />)
                  }
                  if (e.variationReadLess) {
                    reducedVariations = reducedVariations.splice(0, 4)
                    minimumStock.push(<br />)
                  }
                  minimumStock = minimumStock.concat(reducedVariations)
                  return <StyledSpan>{minimumStock}</StyledSpan>
                },
                contactEmail: e => {
                  let contactEmail = []
                  let reducedVariations = e.variations.reduce((acc, cur, idx) => {
                    if (cur.subscriptions && cur.subscriptions.length > 0) {
                      acc.push(cur.subscriptions[0].subscriberContactValue)
                      acc.push(<br key={idx} />)
                    } else {
                      acc.push('-')
                      acc.push(<br key={idx} />)
                    }
                    return acc
                  }, [])
                  if (e.variationReadMore && e.variations.length > 3) {
                    contactEmail.push(<br />)
                  }
                  if (e.variationReadLess) {
                    reducedVariations = reducedVariations.splice(0, 4)
                    contactEmail.push(<br />)
                  }
                  contactEmail = contactEmail.concat(reducedVariations)
                  return <StyledSpan>{contactEmail}</StyledSpan>
                },
                catalogs: e => {
                  if (e.catalogs && e.catalogs.length) {
                    const reducedCatalogs = e.catalogs.reduce((acc, cur, idx) => {
                      acc.push(cur.moduleName)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedCatalogs.length > 0) {
                      reducedCatalogs.pop()
                    }
                    return reducedCatalogs
                  }
                }
              },
              fieldStringFunctions: {
                variations: e => {
                  let eVar = e.variations ? e.variations : []
                  let variations = eVar.map(v => {
                    return v.variationStockable !== 0 ? v.variationTitle + ' (' + v.variationStock + ')' : v.variationTitle
                  })
                  return variations.toString()
                },
                minimumStock: e => {
                  let eVar = e.variations ? e.variations : []
                  let minimumStock = []
                  eVar.map(
                    v => v.subscriptions && !!v.subscriptions.length && minimumStock.push(`${v.variationTitle}(${v.subscriptions[0].triggerValue})`)
                  )
                  return minimumStock.toString()
                },
                contactEmail: e => {
                  let eVar = e.variations ? e.variations : []
                  let contactEmails = []
                  eVar.map(v => v.subscriptions && !!v.subscriptions.length && contactEmails.push(v.subscriptions[0].subscriberContactValue))
                  return contactEmails.toString()
                }
              },
              fieldExportFunctions: {
                variations: e => {
                  let eVar = e.variations ? e.variations : []
                  let variations = eVar.map(v => {
                    return v.variationStockable !== 0 ? v.variationTitle + ' (' + v.variationStock + ')' : v.variationTitle
                  })
                  return variations.toString()
                },
                minimumStock: e => {
                  let eVar = e.variations ? e.variations : []
                  let minimumStock = []
                  eVar.map(
                    v => v.subscriptions && !!v.subscriptions.length && minimumStock.push(`${v.variationTitle}(${v.subscriptions[0].triggerValue})`)
                  )
                  return minimumStock.toString()
                },
                contactEmail: e => {
                  let eVar = e.variations ? e.variations : []
                  let contactEmails = []
                  eVar.map(v => v.subscriptions && !!v.subscriptions.length && contactEmails.push(v.subscriptions[0].subscriberContactValue))
                  return contactEmails.toString()
                },
                catalogs: e => {
                  let eVar = e.catalogs ? e.catalogs : []
                  let catalogs = eVar.map(v => {
                    return v.moduleName
                  })
                  return catalogs.toString()
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'view' + e.ProductID}>Vis</Tooltip>
                      <Button tag={Link} to={'/stock/' + e.ProductID} id={'view' + e.ProductID}>
                        <FontAwesomeIcon icon='eye' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              ProductID: '#',
              elementTitle: 'Title',
              variations: 'Variations',
              minimumStock: 'Minimum lagerbeholdning',
              contactEmail: 'Kontakt email',
              catalogs: 'Katalog'
            }}
            data={[...this.props.products]}
          />
        )}
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({ products: state.stock.products, fetchingProducts: state.stock.fetchingProducts, settings: state.login.settigs })

export default connect(mapStateToProps, { fetchProducts, toggleProductVariationContent })(ProductsOverview)
