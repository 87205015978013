import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { OrderActions } from 'Stores/orderStore'
import { Redirect } from 'react-router-dom'
const AreaSeperator = styled(FormGroup)`
  border-bottom: 1px dashed #ddd;
`

const CenteredCheckbox = styled(Col)`
  display: flex;
  align-items: center;
`

export default function OrderDetailsForm({ initialOrderDetails, isSeasonOrder, addressList }) {
  const orderStatus = {
    PREORDER: 'Preorder',
    NEW: 'Ny',
    TENTATIVE: 'Afventer',
    ...(!isSeasonOrder && { BACKORDER: 'Backorder' }),
    ORDERED: 'Bestilt',
    PACKED: 'Pakket',
    SHIPPED: 'Sendt',
    RECEIVED: 'Modtaget',
    ...(isSeasonOrder && { TO_ORDER: 'Skal bestilles' }),
    ...(!isSeasonOrder && { REFUNDED: 'Refunderet', COMPLAINT: 'Reklamation', DECLINED: 'Afvist', FAILED: 'Fejlet' })
  }
  const initialOrder = {
    AddressID: initialOrderDetails.AddressID,
    originBrandID: initialOrderDetails.originBrandID,
    orderFlowStatus: initialOrderDetails.orderFlowStatus,
    orderParcels: initialOrderDetails.orderParcels ? initialOrderDetails.orderParcels.toString() : '',
    orderWeight: initialOrderDetails.orderWeight ? initialOrderDetails.orderWeight.toString() : '',
    orderConsignee: initialOrderDetails.orderConsignee,
    orderConsigneeName: initialOrderDetails.orderConsigneeName,
    orderConsigneeAddress1: initialOrderDetails.orderConsigneeAddress1,
    orderConsigneeAddress2: initialOrderDetails.orderConsigneeAddress2,
    orderConsigneeZipcode: initialOrderDetails.orderConsigneeZipcode,
    orderConsigneeCity: initialOrderDetails.orderConsigneeCity,
    orderConsigneePhone: initialOrderDetails.orderConsigneePhone,
    orderConsigneeEmail: initialOrderDetails.orderConsigneeEmail,
    orderDropShipment: !!initialOrderDetails.orderDropShipment,
    orderComment: initialOrderDetails.orderComment,
    orderRemarks: initialOrderDetails.orderRemarks ? initialOrderDetails.orderRemarks : '',
    orderConsigneeCountry: initialOrderDetails.orderConsigneeCountry ? initialOrderDetails.orderConsigneeCountry : 'DK',
    ParcelshopID: initialOrderDetails.ParcelshopID,
    orderGlsCustomerNumber: initialOrderDetails.orderGlsCustomerNumber ? initialOrderDetails.orderGlsCustomerNumber : '',
    userOrderRemarks: initialOrderDetails.userOrderRemarks ? initialOrderDetails.userOrderRemarks : ''
  }

  const [formState, updateFormState] = useState(initialOrder)
  const initialOrderStringified = JSON.stringify(initialOrder)
  const [isLoading, setIsLoading] = useState(false)
  const [isRedirect, setIsRedirect] = useState(false)

  const handleChange = event => {
    updateFormState({ ...formState, ...{ [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value } })
  }

  const handleFormSubmit = event => {
    event.preventDefault()
    setIsLoading(true)
    OrderActions.saveOrder.completed.listen(() => {
      setIsLoading(false)
      setIsRedirect(true)
    })
    // Check if there is any update in the form only then call save method, otherwise return
    if (JSON.stringify(formState) !== initialOrderStringified) {
      OrderActions.saveOrder(formState)
    } else {
      setIsLoading(false)
      setIsRedirect(true)
    }
  }
  if (isRedirect) {
    return <Redirect to={isSeasonOrder ? '/season-orders' : '/orders'} />
  }

  const getOrderCountryCode = country => {
    let countryCode = '008'
    switch (country) {
      case 'DK':
        countryCode = '008'
        break
      case 'SE':
        countryCode = '030'
        break
      case 'NO':
        countryCode = '028'
        break
      case 'FI':
        countryCode = '032'
        break
      default:
        countryCode = '008'
        break
    }
    return countryCode
  }

  return (
    <Card data-cy='order.form'>
      <CardHeader data-cy='order.formTitle'>Ordre detaljer ({initialOrderDetails.orderUID})</CardHeader>
      <CardBody>
        {initialOrderDetails.orderRefunded === 1 && (
          <h4 className='text-center text-danger font-weight-bold mb-3'>ORDREN ER REFUNDERET TIL KUNDEN</h4>
        )}
        <Form onSubmit={handleFormSubmit}>
          <FormGroup row>
            <Label for='orderFlowStatus' sm={2}>
              Status
            </Label>
            <Col sm={10}>
              <Input
                type='select'
                name='orderFlowStatus'
                id='orderFlowStatus'
                placeholder='Status'
                onChange={handleChange}
                value={formState.orderFlowStatus}
                min={0}
              >
                {Object.keys(orderStatus).map((s, is) => {
                  return (
                    <option key={is} value={s}>
                      {orderStatus[s]}
                    </option>
                  )
                })}
              </Input>
            </Col>
          </FormGroup>
          <AreaSeperator row />
          <FormGroup row>
            <Label for='orderParcels' sm={2}>
              Pakker
            </Label>
            <Col sm={10}>
              <Input
                type='number'
                name='orderParcels'
                id='orderParcels'
                placeholder='Pakke antal'
                onChange={handleChange}
                value={formState.orderParcels}
                min={0}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderWeight' sm={2}>
              Vægt i gram
            </Label>
            <Col sm={10}>
              <Input
                type='number'
                name='orderWeight'
                id='orderWeight'
                placeholder='Vægt'
                onChange={handleChange}
                value={formState.orderWeight}
                min={0}
              />
            </Col>
          </FormGroup>
          <AreaSeperator row />
          <FormGroup row>
            <Label for='orderConsignee' sm={2}>
              Attention
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsignee'
                id='orderConsignee'
                placeholder='Att. Navn'
                onChange={handleChange}
                value={formState.orderConsignee}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneeName' sm={2}>
              Navn
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneeName'
                id='orderConsigneeName'
                placeholder='Navn'
                onChange={handleChange}
                value={formState.orderConsigneeName}
              />
            </Col>
          </FormGroup>
          {isSeasonOrder && (
            <FormGroup row>
              <Label for='address' sm={2}>
                Address
              </Label>
              <Col sm={10}>
                <Input
                  type='select'
                  name='AddressID'
                  id='AddressID'
                  placeholder='Address'
                  onChange={handleChange}
                  value={formState.AddressID}
                  min={0}
                >
                  <option selected='true' disabled={true} value={false}>
                    Select Address
                  </option>
                  {addressList
                    .filter(i => i.BrandID === formState.originBrandID && i.removed === 0)
                    .map((s, is) => {
                      return (
                        <option key={is} value={s.AddressID}>
                          {s.AddressID}, {s.companyName}, {s.addressLine}, {s.zip}
                        </option>
                      )
                    })}
                </Input>
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Label for='orderConsigneeAddress1' sm={2}>
              Adresse 1
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneeAddress1'
                id='orderConsigneeAddress1'
                placeholder='Adresse 1'
                onChange={handleChange}
                value={formState.orderConsigneeAddress1}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneeAddress2' sm={2}>
              Adresse 2
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneeAddress2'
                id='orderConsigneeAddress2'
                placeholder='Adresse 2'
                onChange={handleChange}
                value={formState.orderConsigneeAddress2}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderRemarks' sm={2}>
              Bemærkning
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderRemarks'
                id='orderRemarks'
                placeholder='Bemærkning'
                onChange={handleChange}
                value={formState.orderRemarks}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneeZipcode' sm={2}>
              Postnummer
            </Label>
            <Col sm={10}>
              <Input
                type='number'
                name='orderConsigneeZipcode'
                id='orderConsigneeZipcode'
                placeholder='Adresse 2'
                onChange={handleChange}
                value={formState.orderConsigneeZipcode}
                min={0}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneeCity' sm={2}>
              By
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneeCity'
                id='orderConsigneeCity'
                placeholder='Adresse 2'
                onChange={handleChange}
                value={formState.orderConsigneeCity}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneeCountry' sm={2}>
              Land
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneeCountry'
                id='orderConsigneeCountry'
                placeholder='Country'
                onChange={handleChange}
                value={formState.orderConsigneeCountry}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneeCountryCode' sm={2}>
              Land Code
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneeCountryCode'
                id='orderConsigneeCountryCode'
                placeholder='Country Code'
                value={getOrderCountryCode(formState.orderConsigneeCountry)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='ParcelshopID' sm={2}>
              Pakkeshop ID
            </Label>
            <Col sm={10}>
              <Input
                type='number'
                name='ParcelshopID'
                id='ParcelshopID'
                placeholder='Pakkeshop ID'
                onChange={handleChange}
                value={formState.ParcelshopID}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderGlsCustomerNumber' sm={2}>
              Afsendelsesnummer
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderGlsCustomerNumber'
                id='orderGlsCustomerNumber'
                placeholder='Afsendernummer'
                onChange={handleChange}
                value={formState.orderGlsCustomerNumber}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneePhone' sm={2}>
              Telefon
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneePhone'
                id='orderConsigneePhone'
                placeholder='Telefon'
                onChange={handleChange}
                value={formState.orderConsigneePhone}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderConsigneeEmail' sm={2}>
              Email
            </Label>
            <Col sm={10}>
              <Input
                type='text'
                name='orderConsigneeEmail'
                id='orderConsigneeEmail'
                placeholder='Telefon'
                onChange={handleChange}
                value={formState.orderConsigneeEmail}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for='orderDropShipment' sm={2}>
              Dropship
            </Label>
            <CenteredCheckbox sm={{ size: 10 }}>
              <FormGroup check>
                <Label check>
                  <Input
                    type='checkbox'
                    id='orderDropShipment'
                    name='orderDropShipment'
                    onChange={handleChange}
                    checked={formState.orderDropShipment}
                  />
                  Slå notifikationer fra
                </Label>
              </FormGroup>
            </CenteredCheckbox>
          </FormGroup>
          <AreaSeperator row />
          <FormGroup row>
            <Label for='orderComment' sm={2}>
              Note
            </Label>
            <Col sm={10}>
              <Input
                type='textarea'
                name='orderComment'
                id='orderComment'
                placeholder='Note'
                onChange={handleChange}
                value={formState.orderComment}
              />
            </Col>
          </FormGroup>
          <AreaSeperator row />
          <FormGroup row>
            <Label for='userOrderRemarks' sm={2}>
              User Remark
            </Label>
            <Col sm={10}>
              <Input
                type='textarea'
                name='userOrderRemarks'
                id='userOrderRemarks'
                placeholder='Note for user'
                onChange={handleChange}
                value={formState.userOrderRemarks}
              />
            </Col>
          </FormGroup>
          <AreaSeperator row />
          <FormGroup row>
            <Col sm={{ size: 10, offset: 2 }}>
              <Button disabled={isLoading}>{isLoading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem & underret'}</Button>
            </Col>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  )
}
