/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET } = Proxies
import keyMirror from 'keymirror'

export const STATUS = new keyMirror({
  FETCHING_COMMUNICATIONS: null,
  FETCHING_COMMUNICATIONS_FAILED: null,
  FETCHING_COMMUNICATIONS_SUCCESS: null,
  FETCHING_COMMUNICATION_DETAILS: null,
  FETCHING_COMMUNICATION_DETAILS_FAILED: null,
  FETCHING_COMMUNICATION_DETAILS_SUCCESS: null
})

export const CommunicationActions = createActions([
  'clearToast',
  'getCommunications',
  'giveAccess',
  'getCommunicationDetails',
  'preserveAdvanceSearch'
])

export class CommunicationStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = CommunicationActions
    this.state = {
      status: null,
      isMSBAdmin: false,
      communicationList: [],
      communicationDetails: {},
      advanceSearchFilters: []
    }
  }
  clearToast() {
    this.setState({ status: null })
  }
  getCommunications(type = '') {
    if (this.state.status === STATUS.FETCHING_COMMUNICATIONS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_COMMUNICATIONS })
    let communicationType = type.toLowerCase() === 'point' ? 'POINT' : ''
    if (this.state.isMSBAdmin) {
      GET('communications/admin-pool', { communicationType: communicationType }).then(responseJSON => {
        let nextState = {
          communicationList: []
        }
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_COMMUNICATIONS_FAILED
        } else {
          nextState.communicationList = responseJSON
          nextState.status = STATUS.FETCHING_COMMUNICATIONS_SUCCESS
        }
        this.setState(nextState)
      })
    } else {
      GET('communications/pool', { communicationType: communicationType }).then(responseJSON => {
        let nextState = {
          communicationList: []
        }
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_COMMUNICATIONS_FAILED
        } else {
          nextState.communicationList = responseJSON
          nextState.status = STATUS.FETCHING_COMMUNICATIONS_SUCCESS
        }
        this.setState(nextState)
      })
    }
  }
  giveAccess() {
    this.setState({ isMSBAdmin: true })
  }
  getCommunicationDetails(CommunicationID = null) {
    this.setState({ status: STATUS.FETCHING_COMMUNICATION_DETAILS })
    if (!this.state.isMSBAdmin) {
      GET('communications/fetch', { CommunicationID }).then(responseJSON => {
        let nextState = {
          communicationDetails: {}
        }
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_COMMUNICATION_DETAILS_FAILED
        } else {
          nextState.communicationDetails = responseJSON
          nextState.status = STATUS.FETCHING_COMMUNICATION_DETAILS_SUCCESS
        }
        this.setState(nextState)
      })
    } else {
      GET('communications/admin-fetch', { CommunicationID }).then(responseJSON => {
        let nextState = {
          communicationDetails: {}
        }
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_COMMUNICATION_DETAILS_FAILED
        } else {
          nextState.communicationDetails = responseJSON
          nextState.status = STATUS.FETCHING_COMMUNICATION_DETAILS_SUCCESS
        }
        this.setState(nextState)
      })
    }
  }
  preserveAdvanceSearch(advanceSearchFilters) {
    if (advanceSearchFilters && advanceSearchFilters.length) {
      this.setState({ advanceSearchFilters: advanceSearchFilters })
    }
  }
}
