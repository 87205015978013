import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { getCustomerSalesClaims, STATUS, statusCustomerSalesClaim } from 'Reducers/actions/customerSalesClaimActions'
import { clearToast } from 'Reducers/customerSalesClaimReducer'
import { connect } from 'react-redux'
import moment from 'moment'

class CustomerSalesClaimsOverview extends React.Component {
  getQueryParams(paramName) {
    const params = new URLSearchParams(this.props.location.search)
    return params.get(paramName)
  }

  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props.getCustomerSalesClaims()
  }

  componentDidUpdate() {
    if (this.props.overviewLoadingState === STATUS.STATUS_CUSTOMER_SALES_CLAIM_SUCCESS) {
      this.props.getCustomerSalesClaims()
    }
  }

  render() {
    const status = this.getQueryParams('status')
    return (
      <CollapsibleCard
        startOpen
        header={
          <strong>
            Indberetninger <span style={{ textTransform: 'capitalize' }}>{`(${status})`}</span>
          </strong>
        }
      >
        {this.props.overviewLoadingState === STATUS.LOADING_CUSTOMER_SALES_CLAIMS ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'CustomerSalesClaimID'
              },
              fieldFunctions: {
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY') : ''
                },
                moduleName: e => {
                  return e.customerProduct.moduleName
                },
                productUID: e => {
                  return e.customerProduct.productUID
                },
                userName: e => {
                  return e.claimedBy.userName
                },
                userUID: e => {
                  return e.claimedBy.userUID
                },
                userCompany: e => {
                  return e.claimedBy.userCompany
                },
                statusUpdateBy: e => {
                  return e.statusUpdateBy ? e.statusUpdateBy.userName : ''
                },
                claimDate: e => {
                  return e.claimDate ? moment(e.claimDate).format('DD-MM-YYYY') : ''
                },
                statusDate: e => {
                  return e.statusDate ? moment(e.statusDate).format('DD-MM-YYYY hh:mm') : ''
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      {/* <Tooltip target={'edit' + e.CustomerSalesClaimID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/customersalesclaims/' + e.CustomerSalesClaimID} id={'edit' + e.CustomerSalesClaimID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button> */}
                      {(e.saleStatus === 'NEW' || e.saleStatus === 'APPROVED') && (
                        <React.Fragment>
                          <Tooltip target={'approve' + e.CustomerSalesClaimID}>Godkende</Tooltip>
                          <Button
                            disabled={e.saleStatus === 'APPROVED'}
                            id={'approve' + e.CustomerSalesClaimID}
                            color='success'
                            onClick={() => {
                              if (confirm('Vil du godkende denne CustomerSalesClaim?')) {
                                this.props.statusCustomerSalesClaim({ CustomerSalesClaimID: e.CustomerSalesClaimID, saleStatus: 'APPROVED' })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='check' />
                          </Button>
                        </React.Fragment>
                      )}
                      {(e.saleStatus === 'NEW' || e.saleStatus === 'REJECTED') && (
                        <React.Fragment>
                          <Tooltip target={'reject' + e.CustomerSalesClaimID}>Afvis</Tooltip>
                          <Button
                            disabled={e.saleStatus === 'REJECTED'}
                            id={'reject' + e.CustomerSalesClaimID}
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du afvis denne CustomerSalesClaim?')) {
                                this.props.statusCustomerSalesClaim({ CustomerSalesClaimID: e.CustomerSalesClaimID, saleStatus: 'REJECTED' })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='times' />
                          </Button>
                        </React.Fragment>
                      )}
                      {(e.saleStatus === 'APPROVED' || e.saleStatus === 'REJECTED') && (
                        <React.Fragment>
                          <Tooltip target={'new' + e.CustomerSalesClaimID}>Fortryd</Tooltip>
                          <Button
                            id={'new' + e.CustomerSalesClaimID}
                            color='info'
                            onClick={() => {
                              if (confirm('Vil du fortryd denne CustomerSalesClaim?')) {
                                this.props.statusCustomerSalesClaim({ CustomerSalesClaimID: e.CustomerSalesClaimID, saleStatus: 'NEW' })
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='undo' />
                          </Button>
                        </React.Fragment>
                      )}
                    </ButtonGroup>
                  </td>
                )
              }
            }}
            headers={{
              CustomerSalesClaimID: '#',
              UserID: 'UserID',
              userUID: 'User UID',
              userName: 'Oprettet af',
              userCompany: 'Firma',
              claimDate: 'Salgsdato',
              timeInsert: 'Oprettet Dato',
              moduleName: 'Produkt navn',
              reference: 'Reference',
              customerName: 'Kundenavn',
              financeSum: 'Finansieringsbeløb',
              rewardPoints: 'Belønning point',
              saleStatus: 'Status',
              statusUpdateBy: 'Senest ændret af',
              statusDate: 'Status dato'
            }}
            data={
              this.props.customerSalesClaims && this.props.customerSalesClaimFilterValue !== 'both' && !!this.props.customerSalesClaims.length
                ? this.props.customerSalesClaims.filter(customerSalesClaim =>
                    status
                      ? customerSalesClaim.saleStatus === status.toUpperCase()
                      : customerSalesClaim.removed === (this.props.customerSalesClaimFilterValue === 'inactive' ? 1 : 0)
                  )
                : this.props.customerSalesClaims
            }
          />
        )}
      </CollapsibleCard>
    )
  }
}

const mapStateToProps = state => ({
  customerSalesClaims: state.customerSalesClaim.customerSalesClaims,
  overviewLoadingState: state.customerSalesClaim.overviewLoadingState,
  customerSalesClaimFilterValue: state.customerSalesClaim.customerSalesClaimFilterValue
})

export default connect(mapStateToProps, {
  getCustomerSalesClaims,
  statusCustomerSalesClaim,
  clearToast
})(CustomerSalesClaimsOverview)
