import React from 'react'
import { Component } from 'reflux'
import { KamActions, KamStore } from 'Stores/santanderKamStore'
import SantanderKAMGraph from 'Components/dashboard/SantanderKAMGraph'
import { toast } from 'react-toastify'

export default class SantanderKamBudgetReport extends Component {
  constructor(props) {
    super(props)
    this.stores = [KamStore]
    this.storeKeys = ['showUserIDError', 'loadingState', 'budgetData', 'longestName', 'budgetKeys', 'currentYear', 'currentMonth']
  }

  componentDidMount() {
    KamActions.getCurrentDate()
    KamActions.getMercheandiseShopBudget()
  }

  componentDidUpdate() {
    if (this.state.showUserIDError) {
      toast.error('Bruger mangler Unikt ID', {
        autoClose: 7500,
        onClose: () => {
          KamActions.clearToast({ showUserIDError: false })
        }
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <SantanderKAMGraph
          header='Budget pr. KAM'
          state={this.state.loadingState}
          data={this.state.budgetData}
          keys={this.state.budgetKeys}
          indexBy={'user'}
          axisLeft={{ format: '~s' }}
          axisBottom={{ onClick: e => KamActions.getKamDetails(e, this.state.budgetData) }}
          onClick={KamActions.getKamDetails}
          getTotalKamDetails={KamActions.getTotalKamDetails}
          cursor={{ cursor: 'pointer' }}
          showIcon={true}
          longestName={this.state.budgetData && !!this.state.budgetData.length && Math.max(...this.state.budgetData.map(el => el.user.length))}
          pagesCount={Math.ceil(this.state.budgetData.length / 14)}
          legends={{ translateX: 0 }}
          colors={{
            Total: '#1F78B4',
            [this.state.currentYear]: '#a6cee3',
            [this.state.currentMonth]: '#b2df8a'
          }}
        />
      </React.Fragment>
    )
  }
}
