import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { VaultStore, VaultActions, STATUS } from 'Stores/vaultStore'
import VaultForm from 'Modules/vault/vaultForm'

export default class VaultEdit extends Component {
  constructor(props) {
    super(props)
    this.stores = [VaultStore]
    this.storeKeys = ['loadingState']
  }
  componentDidMount() {
    VaultActions.getVaultDetails(this.props.match.params.VaultID)
  }
  componentDidUpdate() {
    if (this.state.loadingState === STATUS.FETCHING_VAULT_FAILED) {
      toast.error('FETCHING VAULT FAILED', {
        autoClose: 7500,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast()
    }

    if (this.state.loadingState === STATUS.VAULT_FAILED) {
      toast.error('UPDATE VAULT FAILED', {
        autoClose: 3000,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast()
    }
    if (this.state.loadingState === STATUS.VAULT_SUCCESS) {
      toast.success('UPDATE VAULT SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          VaultActions.clearToast()
        }
      })
      VaultActions.clearToast(STATUS.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <VaultForm {...this.props} />
      </React.Fragment>
    )
  }
}
