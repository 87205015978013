import React from 'react'
import _ from 'lodash'
import useDashboardData from 'Components/dashboard/useDashboardData.js'
import TotalPointsChart from 'Components/dashboard/charts/TotalPointsChart.js'

export default function KamUsersPieChart() {
  const [data, loadingState] = useDashboardData('supervisors/active-inactive-users-count')
  data && data.active === 0 && delete data.active
  data && data.inactive === 0 && delete data.inactive
  return (
    <TotalPointsChart
      header='Total antal brugere'
      state={loadingState}
      data={_.map(data, (value, key) => {
        const label = key === 'active' ? 'aktiv' : 'inaktiv'
        return { id: _.capitalize(label), label: _.capitalize(label), value }
      })}
    />
  )
}
