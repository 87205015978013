import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { ProductStore, ProductActions, STATUS } from 'Stores/productStore'
import ProductForm from 'Modules/products/productForm'

export default class ProductCreate extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProductStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    ProductActions.createEmptyProduct()
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.PRODUCT_SUCCESS) {
      toast.success('Successfully Created', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearFormToast()
        }
      })
      ProductActions.clearFormToast(STATUS.REDIRECT)
    }
    if (this.state.status === STATUS.PRODUCT_ERROR) {
      toast.error('Error while saving product', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearFormToast()
        }
      })
      ProductActions.clearFormToast()
    }
  }

  render() {
    return (
      <React.Fragment>
        <ProductForm />
      </React.Fragment>
    )
  }
}
