import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import CollapsibleCard from 'Components/collapsibleCard'
import DynamicTable from 'Components/dynamicTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Input, Modal, ModalHeader, ModalBody, ModalFooter, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { LoginStore } from 'Stores/loginStore'
import { UserStore, UserActions } from 'Stores/userStore'

const userRoles = ['System', 'Agent', 'Company', 'Client', 'Brand', 'User']
const userPrivilege = ['MANAGE', 'VIEW', 'EDIT', 'OWNER']

export default class AdminUsersList extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserStore, LoginStore]
    this.storeKeys = ['showAdminUsersError', 'adminAdvanceSearchFilters', 'adminUsers', 'fetchingAdminUsers', 'openModal', 'status', 'settings']
    this.advanceSearchColumns = [
      { key: 'UserID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'BrandID', name: 'BrandID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
      { key: 'brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT'] },
      { key: 'userName', name: 'Name', operators: ['LIKE', 'EXACT'] },
      { key: 'userCompany', name: 'Company', operators: ['LIKE', 'EXACT'] },
      { key: 'userUID', name: 'Uid', operators: ['LIKE', 'EXACT'] },
      { key: 'userPhone', name: 'Phone', operators: ['LIKE', 'EXACT'] },
      { key: 'userExtraPhone', name: 'Extra Phone', operators: ['LIKE', 'EXACT'] },
      { key: 'userEmail', name: 'Email', operators: ['LIKE', 'EXACT'] },
      { key: 'userAccess', name: 'Access', operators: ['EXACT'] },
      { key: 'userRole', name: 'User Role', operators: ['EXACT'] },
      { key: 'userPrivilege', name: 'User Privilege', operators: ['EXACT'] }
    ]
  }

  componentDidMount() {
    UserActions.getAdminUsersData()
  }

  componentDidUpdate() {
    if (this.state.showAdminUsersError) {
      toast.error('FETCHING ADMIN USERS FAILED', {
        autoClose: 7500,
        onClose: () => {
          UserActions.clearAdminListToast()
        }
      })
      UserActions.clearAdminListToast()
    }
  }
  statusDropdown = () => {
    return (
      <React.Fragment>
        <Col xs={2} style={{ float: 'right' }}>
          <Input type='select' name='status' id='status' value={this.state.status} onChange={e => UserActions.openStatusModal(e)}>
            <option value={''}>Change User Role</option>
            {Object.keys(userRoles).map((s, is) => {
              return (
                <option key={is} value={userRoles[s]}>
                  {userRoles[s]}
                </option>
              )
            })}
          </Input>
        </Col>
        <Col xs={2} style={{ float: 'right' }}>
          <Input type='select' name='status' id='status' value={this.state.status} onChange={e => UserActions.openStatusModal(e)}>
            <option value={''}>Change User Privilege</option>
            {Object.keys(userPrivilege).map((s, is) => {
              return (
                <option key={is} value={userPrivilege[s]}>
                  {userPrivilege[s]}
                </option>
              )
            })}
          </Input>
        </Col>
      </React.Fragment>
    )
  }

  render() {
    if (this.state.fetchingAdminUsers) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        {this.state.openModal && (
          <Modal isOpen={this.state.openModal} toggle={() => UserActions.logoutModal(this.state.openModal)} size='md'>
            <ModalHeader toggle={() => UserActions.logoutModal(this.state.openModal)}>Change Status</ModalHeader>
            <ModalBody>
              <strong>Do you want to change the status?</strong>
            </ModalBody>
            <ModalFooter>
              <Button onClick={e => UserActions.updateStatusHandler(e, this.state.adminUsers, true)}>yes</Button>
              <Button color='danger' onClick={() => UserActions.logoutModal(this.state.openModal)}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <CollapsibleCard startOpen header={<strong>Admin Users</strong>}>
          <DynamicTable
            responsive
            size='sm'
            data={this.state.adminUsers}
            headers={{
              UserID: 'ID',
              BrandID: 'BrandID',
              brandName: 'Brand Name',
              userName: 'Navn',
              userCompany: 'Virksomhed',
              userUID: 'Uid',
              userPhone: 'Telefon',
              userExtraPhone: 'Ekstra telefon',
              userEmail: 'Email',
              userAccess: 'Adgang',
              userRole: 'Role',
              userPrivilege: 'Privilege'
            }}
            options={{
              showAdminAccessDropdown: true,
              showContent: this.statusDropdown,
              isAllChecked: this.state.isAllChecked,
              preserveAllChecked: UserActions.preserveAllChecked,
              ...(this.state.settings &&
                this.state.settings.userRole.toLowerCase() === 'system' &&
                this.state.settings.userPrivilege.toLowerCase() === 'manage' &&
                this.state.settings.BrandID === 9 && {
                  advanceSearchColumns: this.advanceSearchColumns,
                  preserveAdvanceSearch: UserActions.preserveAdminAdvanceSearch,
                  advanceSearchFilters: this.state.adminAdvanceSearchFilters
                }),
              startSort: {
                dir: 'DESC',
                column: 'UserID'
              },
              fieldFunctions: {
                UserID: e => {
                  return (
                    <React.Fragment>
                      <Input
                        type='checkbox'
                        id={'checkbox' + e.UserID}
                        name={'checkbox' + e.UserID}
                        checked={e.checked}
                        onChange={event => {
                          UserActions.selectAdminUserHandler(event, e.UserID)
                        }}
                      />
                      {e.UserID}
                    </React.Fragment>
                  )
                }
              },
              fieldStringFunctions: {
                UserID: e => {
                  return e.UserID
                }
              },
              fieldExportFunctions: {
                UserID: e => {
                  return e.UserID
                }
              },
              addField: e => {
                return (
                  <td>
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.UserID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/admin-users/' + e.UserID} id={'edit' + e.UserID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      <Tooltip target={'trash' + e.UserID}>Slet</Tooltip>
                      <Button
                        id={'trash' + e.UserID}
                        color='danger'
                        onClick={() => {
                          if (confirm('Vil du slette denne bruger?')) {
                            UserActions.deleteUser(e.UserID, true)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                      <Tooltip target={'copy' + e.UserID}>Copy</Tooltip>
                      <Button id={'copy' + e.UserID} onClick={() => UserActions.copyAdminUser(e, this.props)}>
                        <FontAwesomeIcon icon='copy' />
                      </Button>
                    </ButtonGroup>
                  </td>
                )
              }
            }}
          />
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
