import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import 'react-image-crop/dist/ReactCrop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Proxies from 'Components/proxies'
import HighlightedProductForm from './highlightedProductForm'
import { Redirect } from 'react-router-dom'

const { POST } = Proxies

const HighlightedProduct = props => {
  const [highlightedProducts, setHighlightedProducts] = useState([{}])
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [HighlightedProductID, setHighlightedProductID] = useState(
    !!props.match.params && !isNaN(props.match.params.HighlightedProductID) ? Number(props.match.params.HighlightedProductID) : null
  )

  const resetForm = () => {
    setHighlightedProducts([{}])
  }

  const replaceHighlightedProduct = () => {
    setLoading(true)
    POST(`highlightedproducts/replace`, highlightedProducts[0]).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('Highlighted product updated successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(`BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another product`, {
          autoClose: 7000
        })
      }
      setSuccess(true)
    })
  }
  const submitHighlightedProduct = () => {
    setLoading(true)
    POST(`highlightedproducts/saveMultiple`, highlightedProducts).then(responseJSON => {
      setLoading(false)
      if (responseJSON.error) {
        return toast.error('Action failed', {
          autoClose: 7000
        })
      }
      toast.success('Highlighted product created successfully', {
        autoClose: 7000
      })
      if (responseJSON.omittedBrandIDs && responseJSON.omittedBrandIDs.length) {
        toast.warn(`BrandIDs ${responseJSON.omittedBrandIDs.join(', ')} have been omitted as they are already associated with another product`, {
          autoClose: 7000
        })
      }
      setSuccess(true)
    })
  }

  if (success) {
    return <Redirect to='/highlighted-product'></Redirect>
  }
  return (
    <>
      {highlightedProducts.map((event, idx) => (
        <HighlightedProductForm
          key={idx}
          event={event}
          idx={idx}
          highlightedProducts={highlightedProducts}
          setHighlightedProducts={setHighlightedProducts}
          HighlightedProductID={HighlightedProductID}
          setHighlightedProductID={setHighlightedProductID}
        />
      ))}
      <div style={{ marginBottom: '5%' }}>
        {!HighlightedProductID && (
          <Button
            onClick={() => {
              setHighlightedProducts([...highlightedProducts, {}])
            }}
          >
            + Add More
          </Button>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Button
          onClick={() => {
            HighlightedProductID ? replaceHighlightedProduct() : submitHighlightedProduct()
          }}
        >
          {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button color='danger' onClick={() => resetForm()}>
          Nulstil
        </Button>
      </div>
    </>
  )
}

export default HighlightedProduct
