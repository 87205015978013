import { createSlice } from '@reduxjs/toolkit'
import {
  STATUS,
  fetchProductsRequest,
  fetchProductsSuccess,
  fetchProductsFailure,
  updateProductVariation,
  fetchProductHistoryRequest,
  fetchProductHistorySuccess,
  fetchProductHistoryFailure
} from 'Reducers/actions/stockActions'

const slice = createSlice({
  name: 'stockReducer',
  initialState: {
    fetchingProducts: null,
    products: [],
    fetchingProductHistory: null,
    productHistory: []
  },
  reducers: {},
  extraReducers: {
    [fetchProductsRequest]: state => {
      state.fetchingProducts = STATUS.LOADING_PRODUCTS
    },
    [fetchProductsSuccess]: (state, action) => {
      state.products = action.payload
      state.fetchingProducts = STATUS.LOADING_PRODUCTS_SUCCESS
    },
    [fetchProductsFailure]: state => {
      state.fetchingProducts = STATUS.LOADING_PRODUCTS_FAILED
    },
    [updateProductVariation]: (state, action) => {
      return {
        ...state,
        products: state.products.map(product =>
          product.ProductID === action.payload.ProductID
            ? {
                ...product,
                [`${action.payload.type}ReadLess`]: !product[`${action.payload.type}ReadLess`],
                [`${action.payload.type}ReadMore`]: !product[`${action.payload.type}ReadMore`]
              }
            : product
        )
      }
    },
    [fetchProductHistoryRequest]: state => {
      state.productHistory = []
      state.fetchingProductHistory = STATUS.LOADING_PRODUCT_HISTORY
    },
    [fetchProductHistorySuccess]: (state, action) => {
      state.productHistory = action.payload
      state.fetchingProductHistory = STATUS.LOADING_PRODUCT_HISTORY_SUCCESS
    },
    [fetchProductHistoryFailure]: state => {
      state.fetchingProductHistory = STATUS.LOADING_PRODUCT_HSITORY_FAILED
    }
  }
})

export default slice.reducer
