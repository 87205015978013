import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { HofActions, HofStore, STATE } from 'Stores/hofStore'
import HOFForm from 'Modules/hallOfFame/hofForm'

export default class DatasetDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [HofStore]
    this.storeKeys = ['status']
  }
  componentDidMount() {
    HofActions.getHOFDetails(this.props.match.params.HOFID)
  }
  componentDidUpdate() {
    if (this.state.status === STATE.FETCHING_HOF_FAILED) {
      toast.error('FETCHING HALL OF FAME FAILED', {
        autoClose: 7500,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }

    if (this.state.status === STATE.HOF_FAILED) {
      toast.error('UPDATE HALL OF FAME FAILED', {
        autoClose: 3000,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast()
    }
    if (this.state.status === STATE.HOF_SUCCESS) {
      toast.success('UPDATE HALL OF FAME SUCCESSFUL', {
        autoClose: 3000,
        onClose: () => {
          HofActions.clearToast()
        }
      })
      HofActions.clearToast(STATE.IS_REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <HOFForm {...this.props} />
      </React.Fragment>
    )
  }
}
