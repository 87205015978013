export const postalCodesSE = [
  { postalCode: '10004', city: 'STOCKHOLM' },
  { postalCode: '10005', city: 'STOCKHOLM' },
  { postalCode: '10012', city: 'STOCKHOLM' },
  { postalCode: '10019', city: 'STOCKHOLM' },
  { postalCode: '10026', city: 'STOCKHOLM' },
  { postalCode: '10028', city: 'STOCKHOLM' },
  { postalCode: '10029', city: 'STOCKHOLM' },
  { postalCode: '10031', city: 'STOCKHOLM' },
  { postalCode: '10040', city: 'STOCKHOLM' },
  { postalCode: '10041', city: 'STOCKHOLM' },
  { postalCode: '10044', city: 'STOCKHOLM' },
  { postalCode: '10052', city: 'STOCKHOLM' },
  { postalCode: '10054', city: 'STOCKHOLM' },
  { postalCode: '10055', city: 'STOCKHOLM' },
  { postalCode: '10061', city: 'STOCKHOLM' },
  { postalCode: '10064', city: 'STOCKHOLM' },
  { postalCode: '10073', city: 'STOCKHOLM' },
  { postalCode: '10074', city: 'STOCKHOLM' },
  { postalCode: '10101', city: 'STOCKHOLM' },
  { postalCode: '10110', city: 'STOCKHOLM' },
  { postalCode: '10120', city: 'STOCKHOLM' },
  { postalCode: '10121', city: 'STOCKHOLM' },
  { postalCode: '10122', city: 'STOCKHOLM' },
  { postalCode: '10123', city: 'STOCKHOLM' },
  { postalCode: '10124', city: 'STOCKHOLM' },
  { postalCode: '10125', city: 'STOCKHOLM' },
  { postalCode: '10126', city: 'STOCKHOLM' },
  { postalCode: '10127', city: 'STOCKHOLM' },
  { postalCode: '10128', city: 'STOCKHOLM' },
  { postalCode: '10129', city: 'STOCKHOLM' },
  { postalCode: '10130', city: 'STOCKHOLM' },
  { postalCode: '10131', city: 'STOCKHOLM' },
  { postalCode: '10132', city: 'STOCKHOLM' },
  { postalCode: '10133', city: 'STOCKHOLM' },
  { postalCode: '10134', city: 'STOCKHOLM' },
  { postalCode: '10135', city: 'STOCKHOLM' },
  { postalCode: '10136', city: 'STOCKHOLM' },
  { postalCode: '10137', city: 'STOCKHOLM' },
  { postalCode: '10138', city: 'STOCKHOLM' },
  { postalCode: '10139', city: 'STOCKHOLM' },
  { postalCode: '10153', city: 'STOCKHOLM' },
  { postalCode: '10154', city: 'STOCKHOLM' },
  { postalCode: '10155', city: 'STOCKHOLM' },
  { postalCode: '10156', city: 'STOCKHOLM' },
  { postalCode: '10158', city: 'STOCKHOLM' },
  { postalCode: '10159', city: 'STOCKHOLM' },
  { postalCode: '10204', city: 'STOCKHOLM' },
  { postalCode: '10214', city: 'STOCKHOLM' },
  { postalCode: '10215', city: 'STOCKHOLM' },
  { postalCode: '10216', city: 'STOCKHOLM' },
  { postalCode: '10217', city: 'STOCKHOLM' },
  { postalCode: '10220', city: 'STOCKHOLM' },
  { postalCode: '10221', city: 'STOCKHOLM' },
  { postalCode: '10222', city: 'STOCKHOLM' },
  { postalCode: '10223', city: 'STOCKHOLM' },
  { postalCode: '10224', city: 'STOCKHOLM' },
  { postalCode: '10225', city: 'STOCKHOLM' },
  { postalCode: '10226', city: 'STOCKHOLM' },
  { postalCode: '10227', city: 'STOCKHOLM' },
  { postalCode: '10228', city: 'STOCKHOLM' },
  { postalCode: '10229', city: 'STOCKHOLM' },
  { postalCode: '10230', city: 'STOCKHOLM' },
  { postalCode: '10231', city: 'STOCKHOLM' },
  { postalCode: '10232', city: 'STOCKHOLM' },
  { postalCode: '10233', city: 'STOCKHOLM' },
  { postalCode: '10234', city: 'STOCKHOLM' },
  { postalCode: '10235', city: 'STOCKHOLM' },
  { postalCode: '10239', city: 'STOCKHOLM' },
  { postalCode: '10240', city: 'STOCKHOLM' },
  { postalCode: '10241', city: 'STOCKHOLM' },
  { postalCode: '10242', city: 'STOCKHOLM' },
  { postalCode: '10243', city: 'STOCKHOLM' },
  { postalCode: '10244', city: 'STOCKHOLM' },
  { postalCode: '10245', city: 'STOCKHOLM' },
  { postalCode: '10246', city: 'STOCKHOLM' },
  { postalCode: '10247', city: 'STOCKHOLM' },
  { postalCode: '10248', city: 'STOCKHOLM' },
  { postalCode: '10249', city: 'STOCKHOLM' },
  { postalCode: '10250', city: 'STOCKHOLM' },
  { postalCode: '10251', city: 'STOCKHOLM' },
  { postalCode: '10252', city: 'STOCKHOLM' },
  { postalCode: '10253', city: 'STOCKHOLM' },
  { postalCode: '10254', city: 'STOCKHOLM' },
  { postalCode: '10260', city: 'STOCKHOLM' },
  { postalCode: '10261', city: 'STOCKHOLM' },
  { postalCode: '10262', city: 'STOCKHOLM' },
  { postalCode: '10263', city: 'STOCKHOLM' },
  { postalCode: '10264', city: 'STOCKHOLM' },
  { postalCode: '10265', city: 'STOCKHOLM' },
  { postalCode: '10266', city: 'STOCKHOLM' },
  { postalCode: '10267', city: 'STOCKHOLM' },
  { postalCode: '10268', city: 'STOCKHOLM' },
  { postalCode: '10269', city: 'STOCKHOLM' },
  { postalCode: '10270', city: 'STOCKHOLM' },
  { postalCode: '10271', city: 'STOCKHOLM' },
  { postalCode: '10272', city: 'STOCKHOLM' },
  { postalCode: '10273', city: 'STOCKHOLM' },
  { postalCode: '10274', city: 'STOCKHOLM' },
  { postalCode: '10301', city: 'STOCKHOLM' },
  { postalCode: '10302', city: 'STOCKHOLM' },
  { postalCode: '10303', city: 'STOCKHOLM' },
  { postalCode: '10310', city: 'STOCKHOLM' },
  { postalCode: '10311', city: 'STOCKHOLM' },
  { postalCode: '10312', city: 'STOCKHOLM' },
  { postalCode: '10313', city: 'STOCKHOLM' },
  { postalCode: '10314', city: 'STOCKHOLM' },
  { postalCode: '10315', city: 'STOCKHOLM' },
  { postalCode: '10316', city: 'STOCKHOLM' },
  { postalCode: '10317', city: 'STOCKHOLM' },
  { postalCode: '10318', city: 'STOCKHOLM' },
  { postalCode: '10320', city: 'STOCKHOLM' },
  { postalCode: '10321', city: 'STOCKHOLM' },
  { postalCode: '10322', city: 'STOCKHOLM' },
  { postalCode: '10323', city: 'STOCKHOLM' },
  { postalCode: '10324', city: 'STOCKHOLM' },
  { postalCode: '10325', city: 'STOCKHOLM' },
  { postalCode: '10327', city: 'STOCKHOLM' },
  { postalCode: '10329', city: 'STOCKHOLM' },
  { postalCode: '10332', city: 'STOCKHOLM' },
  { postalCode: '10333', city: 'STOCKHOLM' },
  { postalCode: '10334', city: 'STOCKHOLM' },
  { postalCode: '10335', city: 'STOCKHOLM' },
  { postalCode: '10336', city: 'STOCKHOLM' },
  { postalCode: '10337', city: 'STOCKHOLM' },
  { postalCode: '10338', city: 'STOCKHOLM' },
  { postalCode: '10339', city: 'STOCKHOLM' },
  { postalCode: '10351', city: 'STOCKHOLM' },
  { postalCode: '10354', city: 'STOCKHOLM' },
  { postalCode: '10355', city: 'STOCKHOLM' },
  { postalCode: '10359', city: 'STOCKHOLM' },
  { postalCode: '10360', city: 'STOCKHOLM' },
  { postalCode: '10361', city: 'STOCKHOLM' },
  { postalCode: '10362', city: 'STOCKHOLM' },
  { postalCode: '10363', city: 'STOCKHOLM' },
  { postalCode: '10364', city: 'STOCKHOLM' },
  { postalCode: '10365', city: 'STOCKHOLM' },
  { postalCode: '10366', city: 'STOCKHOLM' },
  { postalCode: '10367', city: 'STOCKHOLM' },
  { postalCode: '10368', city: 'STOCKHOLM' },
  { postalCode: '10369', city: 'STOCKHOLM' },
  { postalCode: '10370', city: 'STOCKHOLM' },
  { postalCode: '10371', city: 'STOCKHOLM' },
  { postalCode: '10373', city: 'STOCKHOLM' },
  { postalCode: '10374', city: 'STOCKHOLM' },
  { postalCode: '10375', city: 'STOCKHOLM' },
  { postalCode: '10376', city: 'STOCKHOLM' },
  { postalCode: '10377', city: 'STOCKHOLM' },
  { postalCode: '10380', city: 'STOCKHOLM' },
  { postalCode: '10382', city: 'STOCKHOLM' },
  { postalCode: '10383', city: 'STOCKHOLM' },
  { postalCode: '10384', city: 'STOCKHOLM' },
  { postalCode: '10385', city: 'STOCKHOLM' },
  { postalCode: '10386', city: 'STOCKHOLM' },
  { postalCode: '10387', city: 'STOCKHOLM' },
  { postalCode: '10388', city: 'STOCKHOLM' },
  { postalCode: '10389', city: 'STOCKHOLM' },
  { postalCode: '10390', city: 'STOCKHOLM' },
  { postalCode: '10391', city: 'STOCKHOLM' },
  { postalCode: '10392', city: 'STOCKHOLM' },
  { postalCode: '10393', city: 'STOCKHOLM' },
  { postalCode: '10394', city: 'STOCKHOLM' },
  { postalCode: '10395', city: 'STOCKHOLM' },
  { postalCode: '10396', city: 'STOCKHOLM' },
  { postalCode: '10397', city: 'STOCKHOLM' },
  { postalCode: '10398', city: 'STOCKHOLM' },
  { postalCode: '10399', city: 'STOCKHOLM' },
  { postalCode: '10405', city: 'STOCKHOLM' },
  { postalCode: '10420', city: 'STOCKHOLM' },
  { postalCode: '10422', city: 'STOCKHOLM' },
  { postalCode: '10425', city: 'STOCKHOLM' },
  { postalCode: '10430', city: 'STOCKHOLM' },
  { postalCode: '10431', city: 'STOCKHOLM' },
  { postalCode: '10432', city: 'STOCKHOLM' },
  { postalCode: '10435', city: 'STOCKHOLM' },
  { postalCode: '10440', city: 'STOCKHOLM' },
  { postalCode: '10441', city: 'STOCKHOLM' },
  { postalCode: '10450', city: 'STOCKHOLM' },
  { postalCode: '10451', city: 'STOCKHOLM' },
  { postalCode: '10455', city: 'STOCKHOLM' },
  { postalCode: '10460', city: 'STOCKHOLM' },
  { postalCode: '10462', city: 'STOCKHOLM' },
  { postalCode: '10465', city: 'STOCKHOLM' },
  { postalCode: '10500', city: 'STOCKHOLM' },
  { postalCode: '10506', city: 'STOCKHOLM' },
  { postalCode: '10507', city: 'STOCKHOLM' },
  { postalCode: '10509', city: 'STOCKHOLM' },
  { postalCode: '10510', city: 'STOCKHOLM' },
  { postalCode: '10511', city: 'STOCKHOLM' },
  { postalCode: '10512', city: 'STOCKHOLM' },
  { postalCode: '10515', city: 'STOCKHOLM' },
  { postalCode: '10516', city: 'STOCKHOLM' },
  { postalCode: '10517', city: 'STOCKHOLM' },
  { postalCode: '10518', city: 'STOCKHOLM' },
  { postalCode: '10519', city: 'STOCKHOLM' },
  { postalCode: '10520', city: 'STOCKHOLM' },
  { postalCode: '10521', city: 'STOCKHOLM' },
  { postalCode: '10522', city: 'STOCKHOLM' },
  { postalCode: '10523', city: 'STOCKHOLM' },
  { postalCode: '10524', city: 'STOCKHOLM' },
  { postalCode: '10525', city: 'STOCKHOLM' },
  { postalCode: '10526', city: 'STOCKHOLM' },
  { postalCode: '10530', city: 'STOCKHOLM' },
  { postalCode: '10531', city: 'STOCKHOLM' },
  { postalCode: '10532', city: 'STOCKHOLM' },
  { postalCode: '10533', city: 'STOCKHOLM' },
  { postalCode: '10534', city: 'STOCKHOLM' },
  { postalCode: '10535', city: 'STOCKHOLM' },
  { postalCode: '10536', city: 'STOCKHOLM' },
  { postalCode: '10539', city: 'STOCKHOLM' },
  { postalCode: '10540', city: 'STOCKHOLM' },
  { postalCode: '10544', city: 'STOCKHOLM' },
  { postalCode: '10545', city: 'STOCKHOLM' },
  { postalCode: '10546', city: 'STOCKHOLM' },
  { postalCode: '10547', city: 'STOCKHOLM' },
  { postalCode: '10549', city: 'STOCKHOLM' },
  { postalCode: '10550', city: 'STOCKHOLM' },
  { postalCode: '10552', city: 'STOCKHOLM' },
  { postalCode: '10553', city: 'STOCKHOLM' },
  { postalCode: '10560', city: 'STOCKHOLM' },
  { postalCode: '10561', city: 'STOCKHOLM' },
  { postalCode: '10569', city: 'STOCKHOLM' },
  { postalCode: '10570', city: 'STOCKHOLM' },
  { postalCode: '10571', city: 'STOCKHOLM' },
  { postalCode: '10573', city: 'STOCKHOLM' },
  { postalCode: '10578', city: 'STOCKHOLM' },
  { postalCode: '10580', city: 'STOCKHOLM' },
  { postalCode: '10582', city: 'STOCKHOLM' },
  { postalCode: '10583', city: 'STOCKHOLM' },
  { postalCode: '10588', city: 'STOCKHOLM' },
  { postalCode: '10599', city: 'STOCKHOLM' },
  { postalCode: '10600', city: 'STOCKHOLM' },
  { postalCode: '10610', city: 'STOCKHOLM' },
  { postalCode: '10611', city: 'STOCKHOLM' },
  { postalCode: '10612', city: 'STOCKHOLM' },
  { postalCode: '10614', city: 'STOCKHOLM' },
  { postalCode: '10618', city: 'STOCKHOLM' },
  { postalCode: '10620', city: 'STOCKHOLM' },
  { postalCode: '10626', city: 'STOCKHOLM' },
  { postalCode: '10627', city: 'STOCKHOLM' },
  { postalCode: '10630', city: 'STOCKHOLM' },
  { postalCode: '10631', city: 'STOCKHOLM' },
  { postalCode: '10632', city: 'STOCKHOLM' },
  { postalCode: '10635', city: 'STOCKHOLM' },
  { postalCode: '10636', city: 'STOCKHOLM' },
  { postalCode: '10637', city: 'STOCKHOLM' },
  { postalCode: '10638', city: 'STOCKHOLM' },
  { postalCode: '10640', city: 'STOCKHOLM' },
  { postalCode: '10642', city: 'STOCKHOLM' },
  { postalCode: '10644', city: 'STOCKHOLM' },
  { postalCode: '10645', city: 'STOCKHOLM' },
  { postalCode: '10647', city: 'STOCKHOLM' },
  { postalCode: '10648', city: 'STOCKHOLM' },
  { postalCode: '10650', city: 'STOCKHOLM' },
  { postalCode: '10653', city: 'STOCKHOLM' },
  { postalCode: '10654', city: 'STOCKHOLM' },
  { postalCode: '10655', city: 'STOCKHOLM' },
  { postalCode: '10656', city: 'STOCKHOLM' },
  { postalCode: '10657', city: 'STOCKHOLM' },
  { postalCode: '10660', city: 'STOCKHOLM' },
  { postalCode: '10661', city: 'STOCKHOLM' },
  { postalCode: '10663', city: 'STOCKHOLM' },
  { postalCode: '10664', city: 'STOCKHOLM' },
  { postalCode: '10665', city: 'STOCKHOLM' },
  { postalCode: '10666', city: 'STOCKHOLM' },
  { postalCode: '10667', city: 'STOCKHOLM' },
  { postalCode: '10670', city: 'STOCKHOLM' },
  { postalCode: '10675', city: 'STOCKHOLM' },
  { postalCode: '10677', city: 'STOCKHOLM' },
  { postalCode: '10678', city: 'STOCKHOLM' },
  { postalCode: '10680', city: 'STOCKHOLM' },
  { postalCode: '10682', city: 'STOCKHOLM' },
  { postalCode: '10685', city: 'STOCKHOLM' },
  { postalCode: '10688', city: 'STOCKHOLM' },
  { postalCode: '10691', city: 'STOCKHOLM' },
  { postalCode: '10700', city: 'STOCKHOLM' },
  { postalCode: '10722', city: 'STOCKHOLM' },
  { postalCode: '10723', city: 'STOCKHOLM' },
  { postalCode: '10724', city: 'STOCKHOLM' },
  { postalCode: '10725', city: 'STOCKHOLM' },
  { postalCode: '10726', city: 'STOCKHOLM' },
  { postalCode: '10767', city: 'STOCKHOLM' },
  { postalCode: '10768', city: 'STOCKHOLM' },
  { postalCode: '10769', city: 'STOCKHOLM' },
  { postalCode: '10770', city: 'STOCKHOLM' },
  { postalCode: '10772', city: 'STOCKHOLM' },
  { postalCode: '10775', city: 'STOCKHOLM' },
  { postalCode: '10776', city: 'STOCKHOLM' },
  { postalCode: '10777', city: 'STOCKHOLM' },
  { postalCode: '10781', city: 'STOCKHOLM' },
  { postalCode: '10782', city: 'STOCKHOLM' },
  { postalCode: '10785', city: 'STOCKHOLM' },
  { postalCode: '10786', city: 'STOCKHOLM' },
  { postalCode: '10788', city: 'STOCKHOLM' },
  { postalCode: '10790', city: 'STOCKHOLM' },
  { postalCode: '10791', city: 'STOCKHOLM' },
  { postalCode: '10795', city: 'STOCKHOLM' },
  { postalCode: '10796', city: 'STOCKHOLM' },
  { postalCode: '10900', city: 'STOCKHOLM' },
  { postalCode: '10901', city: 'STOCKHOLM' },
  { postalCode: '10902', city: 'STOCKHOLM' },
  { postalCode: '10903', city: 'STOCKHOLM' },
  { postalCode: '10904', city: 'STOCKHOLM' },
  { postalCode: '10905', city: 'STOCKHOLM' },
  { postalCode: '10906', city: 'STOCKHOLM' },
  { postalCode: '10907', city: 'STOCKHOLM' },
  { postalCode: '10908', city: 'STOCKHOLM' },
  { postalCode: '10909', city: 'STOCKHOLM' },
  { postalCode: '10910', city: 'STOCKHOLM' },
  { postalCode: '10911', city: 'STOCKHOLM' },
  { postalCode: '10912', city: 'STOCKHOLM' },
  { postalCode: '10913', city: 'STOCKHOLM' },
  { postalCode: '10914', city: 'STOCKHOLM' },
  { postalCode: '10915', city: 'STOCKHOLM' },
  { postalCode: '10916', city: 'STOCKHOLM' },
  { postalCode: '10917', city: 'STOCKHOLM' },
  { postalCode: '10918', city: 'STOCKHOLM' },
  { postalCode: '10919', city: 'STOCKHOLM' },
  { postalCode: '10920', city: 'STOCKHOLM' },
  { postalCode: '10921', city: 'STOCKHOLM' },
  { postalCode: '10922', city: 'STOCKHOLM' },
  { postalCode: '10923', city: 'STOCKHOLM' },
  { postalCode: '10924', city: 'STOCKHOLM' },
  { postalCode: '10925', city: 'STOCKHOLM' },
  { postalCode: '10926', city: 'STOCKHOLM' },
  { postalCode: '10927', city: 'STOCKHOLM' },
  { postalCode: '10928', city: 'STOCKHOLM' },
  { postalCode: '10929', city: 'STOCKHOLM' },
  { postalCode: '10930', city: 'STOCKHOLM' },
  { postalCode: '10931', city: 'STOCKHOLM' },
  { postalCode: '10932', city: 'STOCKHOLM' },
  { postalCode: '10933', city: 'STOCKHOLM' },
  { postalCode: '10934', city: 'STOCKHOLM' },
  { postalCode: '10935', city: 'STOCKHOLM' },
  { postalCode: '10936', city: 'STOCKHOLM' },
  { postalCode: '10937', city: 'STOCKHOLM' },
  { postalCode: '10938', city: 'STOCKHOLM' },
  { postalCode: '10939', city: 'STOCKHOLM' },
  { postalCode: '10940', city: 'STOCKHOLM' },
  { postalCode: '10941', city: 'STOCKHOLM' },
  { postalCode: '10942', city: 'STOCKHOLM' },
  { postalCode: '10943', city: 'STOCKHOLM' },
  { postalCode: '10944', city: 'STOCKHOLM' },
  { postalCode: '10945', city: 'STOCKHOLM' },
  { postalCode: '10946', city: 'STOCKHOLM' },
  { postalCode: '10947', city: 'STOCKHOLM' },
  { postalCode: '10948', city: 'STOCKHOLM' },
  { postalCode: '10949', city: 'STOCKHOLM' },
  { postalCode: '10950', city: 'STOCKHOLM' },
  { postalCode: '10951', city: 'STOCKHOLM' },
  { postalCode: '10952', city: 'STOCKHOLM' },
  { postalCode: '10953', city: 'STOCKHOLM' },
  { postalCode: '10954', city: 'STOCKHOLM' },
  { postalCode: '10955', city: 'STOCKHOLM' },
  { postalCode: '10956', city: 'STOCKHOLM' },
  { postalCode: '10957', city: 'STOCKHOLM' },
  { postalCode: '10958', city: 'STOCKHOLM' },
  { postalCode: '10959', city: 'STOCKHOLM' },
  { postalCode: '10960', city: 'STOCKHOLM' },
  { postalCode: '10961', city: 'STOCKHOLM' },
  { postalCode: '10962', city: 'STOCKHOLM' },
  { postalCode: '10963', city: 'STOCKHOLM' },
  { postalCode: '10964', city: 'STOCKHOLM' },
  { postalCode: '10965', city: 'STOCKHOLM' },
  { postalCode: '10966', city: 'STOCKHOLM' },
  { postalCode: '10967', city: 'STOCKHOLM' },
  { postalCode: '10968', city: 'STOCKHOLM' },
  { postalCode: '10969', city: 'STOCKHOLM' },
  { postalCode: '10970', city: 'STOCKHOLM' },
  { postalCode: '10971', city: 'STOCKHOLM' },
  { postalCode: '10972', city: 'STOCKHOLM' },
  { postalCode: '10973', city: 'STOCKHOLM' },
  { postalCode: '10974', city: 'STOCKHOLM' },
  { postalCode: '10975', city: 'STOCKHOLM' },
  { postalCode: '10976', city: 'STOCKHOLM' },
  { postalCode: '10977', city: 'STOCKHOLM' },
  { postalCode: '10978', city: 'STOCKHOLM' },
  { postalCode: '10979', city: 'STOCKHOLM' },
  { postalCode: '10980', city: 'STOCKHOLM' },
  { postalCode: '10981', city: 'STOCKHOLM' },
  { postalCode: '10982', city: 'STOCKHOLM' },
  { postalCode: '10983', city: 'STOCKHOLM' },
  { postalCode: '10984', city: 'STOCKHOLM' },
  { postalCode: '10985', city: 'STOCKHOLM' },
  { postalCode: '10986', city: 'STOCKHOLM' },
  { postalCode: '10987', city: 'STOCKHOLM' },
  { postalCode: '10988', city: 'STOCKHOLM' },
  { postalCode: '10989', city: 'STOCKHOLM' },
  { postalCode: '10990', city: 'STOCKHOLM' },
  { postalCode: '10991', city: 'STOCKHOLM' },
  { postalCode: '10992', city: 'STOCKHOLM' },
  { postalCode: '10993', city: 'STOCKHOLM' },
  { postalCode: '10994', city: 'STOCKHOLM' },
  { postalCode: '10995', city: 'STOCKHOLM' },
  { postalCode: '10996', city: 'STOCKHOLM' },
  { postalCode: '10997', city: 'STOCKHOLM' },
  { postalCode: '10998', city: 'STOCKHOLM' },
  { postalCode: '10999', city: 'STOCKHOLM' },
  { postalCode: '11000', city: 'STOCKHOLM' },
  { postalCode: '11001', city: 'STOCKHOLM' },
  { postalCode: '11002', city: 'STOCKHOLM' },
  { postalCode: '11003', city: 'STOCKHOLM' },
  { postalCode: '11004', city: 'STOCKHOLM' },
  { postalCode: '11005', city: 'STOCKHOLM' },
  { postalCode: '11006', city: 'STOCKHOLM' },
  { postalCode: '11007', city: 'STOCKHOLM' },
  { postalCode: '11008', city: 'STOCKHOLM' },
  { postalCode: '11009', city: 'STOCKHOLM' },
  { postalCode: '11010', city: 'STOCKHOLM' },
  { postalCode: '11012', city: 'STOCKHOLM' },
  { postalCode: '11015', city: 'STOCKHOLM' },
  { postalCode: '11016', city: 'STOCKHOLM' },
  { postalCode: '11017', city: 'STOCKHOLM' },
  { postalCode: '11019', city: 'STOCKHOLM' },
  { postalCode: '11022', city: 'STOCKHOLM' },
  { postalCode: '11023', city: 'STOCKHOLM' },
  { postalCode: '11026', city: 'STOCKHOLM' },
  { postalCode: '11027', city: 'STOCKHOLM' },
  { postalCode: '11030', city: 'STOCKHOLM' },
  { postalCode: '11034', city: 'STOCKHOLM' },
  { postalCode: '11043', city: 'STOCKHOLM' },
  { postalCode: '11045', city: 'STOCKHOLM' },
  { postalCode: '11050', city: 'STOCKHOLM' },
  { postalCode: '11052', city: 'STOCKHOLM' },
  { postalCode: '11053', city: 'STOCKHOLM' },
  { postalCode: '11054', city: 'STOCKHOLM' },
  { postalCode: '11055', city: 'STOCKHOLM' },
  { postalCode: '11056', city: 'STOCKHOLM' },
  { postalCode: '11058', city: 'STOCKHOLM' },
  { postalCode: '11060', city: 'STOCKHOLM' },
  { postalCode: '11077', city: 'STOCKHOLM' },
  { postalCode: '11088', city: 'STOCKHOLM' },
  { postalCode: '11090', city: 'STOCKHOLM' },
  { postalCode: '11101', city: 'STOCKHOLM' },
  { postalCode: '11102', city: 'STOCKHOLM' },
  { postalCode: '11115', city: 'STOCKHOLM' },
  { postalCode: '11120', city: 'STOCKHOLM' },
  { postalCode: '11121', city: 'STOCKHOLM' },
  { postalCode: '11122', city: 'STOCKHOLM' },
  { postalCode: '11123', city: 'STOCKHOLM' },
  { postalCode: '11124', city: 'STOCKHOLM' },
  { postalCode: '11127', city: 'STOCKHOLM' },
  { postalCode: '11128', city: 'STOCKHOLM' },
  { postalCode: '11129', city: 'STOCKHOLM' },
  { postalCode: '11130', city: 'STOCKHOLM' },
  { postalCode: '11131', city: 'STOCKHOLM' },
  { postalCode: '11134', city: 'STOCKHOLM' },
  { postalCode: '11135', city: 'STOCKHOLM' },
  { postalCode: '11136', city: 'STOCKHOLM' },
  { postalCode: '11137', city: 'STOCKHOLM' },
  { postalCode: '11138', city: 'STOCKHOLM' },
  { postalCode: '11139', city: 'STOCKHOLM' },
  { postalCode: '11140', city: 'STOCKHOLM' },
  { postalCode: '11143', city: 'STOCKHOLM' },
  { postalCode: '11144', city: 'STOCKHOLM' },
  { postalCode: '11145', city: 'STOCKHOLM' },
  { postalCode: '11146', city: 'STOCKHOLM' },
  { postalCode: '11147', city: 'STOCKHOLM' },
  { postalCode: '11148', city: 'STOCKHOLM' },
  { postalCode: '11149', city: 'STOCKHOLM' },
  { postalCode: '11151', city: 'STOCKHOLM' },
  { postalCode: '11152', city: 'STOCKHOLM' },
  { postalCode: '11153', city: 'STOCKHOLM' },
  { postalCode: '11156', city: 'STOCKHOLM' },
  { postalCode: '11157', city: 'STOCKHOLM' },
  { postalCode: '11160', city: 'STOCKHOLM' },
  { postalCode: '11161', city: 'STOCKHOLM' },
  { postalCode: '11164', city: 'STOCKHOLM' },
  { postalCode: '11173', city: 'STOCKHOLM' },
  { postalCode: '11174', city: 'STOCKHOLM' },
  { postalCode: '11175', city: 'STOCKHOLM' },
  { postalCode: '11176', city: 'STOCKHOLM' },
  { postalCode: '11177', city: 'STOCKHOLM' },
  { postalCode: '11181', city: 'STOCKHOLM' },
  { postalCode: '11182', city: 'STOCKHOLM' },
  { postalCode: '11183', city: 'STOCKHOLM' },
  { postalCode: '11184', city: 'STOCKHOLM' },
  { postalCode: '11186', city: 'STOCKHOLM' },
  { postalCode: '11187', city: 'STOCKHOLM' },
  { postalCode: '11191', city: 'STOCKHOLM' },
  { postalCode: '11193', city: 'STOCKHOLM' },
  { postalCode: '11196', city: 'STOCKHOLM' },
  { postalCode: '11201', city: 'STOCKHOLM' },
  { postalCode: '11202', city: 'STOCKHOLM' },
  { postalCode: '11214', city: 'STOCKHOLM' },
  { postalCode: '11215', city: 'STOCKHOLM' },
  { postalCode: '11216', city: 'STOCKHOLM' },
  { postalCode: '11217', city: 'STOCKHOLM' },
  { postalCode: '11218', city: 'STOCKHOLM' },
  { postalCode: '11219', city: 'STOCKHOLM' },
  { postalCode: '11220', city: 'STOCKHOLM' },
  { postalCode: '11221', city: 'STOCKHOLM' },
  { postalCode: '11222', city: 'STOCKHOLM' },
  { postalCode: '11223', city: 'STOCKHOLM' },
  { postalCode: '11224', city: 'STOCKHOLM' },
  { postalCode: '11225', city: 'STOCKHOLM' },
  { postalCode: '11226', city: 'STOCKHOLM' },
  { postalCode: '11227', city: 'STOCKHOLM' },
  { postalCode: '11228', city: 'STOCKHOLM' },
  { postalCode: '11229', city: 'STOCKHOLM' },
  { postalCode: '11230', city: 'STOCKHOLM' },
  { postalCode: '11231', city: 'STOCKHOLM' },
  { postalCode: '11232', city: 'STOCKHOLM' },
  { postalCode: '11233', city: 'STOCKHOLM' },
  { postalCode: '11234', city: 'STOCKHOLM' },
  { postalCode: '11235', city: 'STOCKHOLM' },
  { postalCode: '11236', city: 'STOCKHOLM' },
  { postalCode: '11237', city: 'STOCKHOLM' },
  { postalCode: '11238', city: 'STOCKHOLM' },
  { postalCode: '11239', city: 'STOCKHOLM' },
  { postalCode: '11240', city: 'STOCKHOLM' },
  { postalCode: '11241', city: 'STOCKHOLM' },
  { postalCode: '11242', city: 'STOCKHOLM' },
  { postalCode: '11243', city: 'STOCKHOLM' },
  { postalCode: '11244', city: 'STOCKHOLM' },
  { postalCode: '11245', city: 'STOCKHOLM' },
  { postalCode: '11246', city: 'STOCKHOLM' },
  { postalCode: '11247', city: 'STOCKHOLM' },
  { postalCode: '11248', city: 'STOCKHOLM' },
  { postalCode: '11249', city: 'STOCKHOLM' },
  { postalCode: '11250', city: 'STOCKHOLM' },
  { postalCode: '11251', city: 'STOCKHOLM' },
  { postalCode: '11252', city: 'STOCKHOLM' },
  { postalCode: '11253', city: 'STOCKHOLM' },
  { postalCode: '11254', city: 'STOCKHOLM' },
  { postalCode: '11255', city: 'STOCKHOLM' },
  { postalCode: '11256', city: 'STOCKHOLM' },
  { postalCode: '11257', city: 'STOCKHOLM' },
  { postalCode: '11258', city: 'STOCKHOLM' },
  { postalCode: '11259', city: 'STOCKHOLM' },
  { postalCode: '11260', city: 'STOCKHOLM' },
  { postalCode: '11261', city: 'STOCKHOLM' },
  { postalCode: '11262', city: 'STOCKHOLM' },
  { postalCode: '11263', city: 'STOCKHOLM' },
  { postalCode: '11264', city: 'STOCKHOLM' },
  { postalCode: '11265', city: 'STOCKHOLM' },
  { postalCode: '11266', city: 'STOCKHOLM' },
  { postalCode: '11267', city: 'STOCKHOLM' },
  { postalCode: '11269', city: 'STOCKHOLM' },
  { postalCode: '11273', city: 'STOCKHOLM' },
  { postalCode: '11274', city: 'STOCKHOLM' },
  { postalCode: '11275', city: 'STOCKHOLM' },
  { postalCode: '11276', city: 'STOCKHOLM' },
  { postalCode: '11277', city: 'STOCKHOLM' },
  { postalCode: '11278', city: 'STOCKHOLM' },
  { postalCode: '11279', city: 'STOCKHOLM' },
  { postalCode: '11280', city: 'STOCKHOLM' },
  { postalCode: '11281', city: 'STOCKHOLM' },
  { postalCode: '11282', city: 'STOCKHOLM' },
  { postalCode: '11284', city: 'STOCKHOLM' },
  { postalCode: '11287', city: 'STOCKHOLM' },
  { postalCode: '11288', city: 'STOCKHOLM' },
  { postalCode: '11289', city: 'STOCKHOLM' },
  { postalCode: '11292', city: 'STOCKHOLM' },
  { postalCode: '11293', city: 'STOCKHOLM' },
  { postalCode: '11294', city: 'STOCKHOLM' },
  { postalCode: '11296', city: 'STOCKHOLM' },
  { postalCode: '11297', city: 'STOCKHOLM' },
  { postalCode: '11298', city: 'STOCKHOLM' },
  { postalCode: '11301', city: 'STOCKHOLM' },
  { postalCode: '11320', city: 'STOCKHOLM' },
  { postalCode: '11321', city: 'STOCKHOLM' },
  { postalCode: '11322', city: 'STOCKHOLM' },
  { postalCode: '11323', city: 'STOCKHOLM' },
  { postalCode: '11324', city: 'STOCKHOLM' },
  { postalCode: '11325', city: 'STOCKHOLM' },
  { postalCode: '11326', city: 'STOCKHOLM' },
  { postalCode: '11327', city: 'STOCKHOLM' },
  { postalCode: '11328', city: 'STOCKHOLM' },
  { postalCode: '11329', city: 'STOCKHOLM' },
  { postalCode: '11330', city: 'STOCKHOLM' },
  { postalCode: '11331', city: 'STOCKHOLM' },
  { postalCode: '11332', city: 'STOCKHOLM' },
  { postalCode: '11333', city: 'STOCKHOLM' },
  { postalCode: '11334', city: 'STOCKHOLM' },
  { postalCode: '11335', city: 'STOCKHOLM' },
  { postalCode: '11336', city: 'STOCKHOLM' },
  { postalCode: '11337', city: 'STOCKHOLM' },
  { postalCode: '11338', city: 'STOCKHOLM' },
  { postalCode: '11339', city: 'STOCKHOLM' },
  { postalCode: '11340', city: 'STOCKHOLM' },
  { postalCode: '11341', city: 'STOCKHOLM' },
  { postalCode: '11342', city: 'STOCKHOLM' },
  { postalCode: '11343', city: 'STOCKHOLM' },
  { postalCode: '11344', city: 'STOCKHOLM' },
  { postalCode: '11345', city: 'STOCKHOLM' },
  { postalCode: '11346', city: 'STOCKHOLM' },
  { postalCode: '11347', city: 'STOCKHOLM' },
  { postalCode: '11348', city: 'STOCKHOLM' },
  { postalCode: '11349', city: 'STOCKHOLM' },
  { postalCode: '11350', city: 'STOCKHOLM' },
  { postalCode: '11351', city: 'STOCKHOLM' },
  { postalCode: '11352', city: 'STOCKHOLM' },
  { postalCode: '11353', city: 'STOCKHOLM' },
  { postalCode: '11354', city: 'STOCKHOLM' },
  { postalCode: '11355', city: 'STOCKHOLM' },
  { postalCode: '11356', city: 'STOCKHOLM' },
  { postalCode: '11357', city: 'STOCKHOLM' },
  { postalCode: '11358', city: 'STOCKHOLM' },
  { postalCode: '11359', city: 'STOCKHOLM' },
  { postalCode: '11360', city: 'STOCKHOLM' },
  { postalCode: '11361', city: 'STOCKHOLM' },
  { postalCode: '11362', city: 'STOCKHOLM' },
  { postalCode: '11363', city: 'STOCKHOLM' },
  { postalCode: '11364', city: 'STOCKHOLM' },
  { postalCode: '11365', city: 'STOCKHOLM' },
  { postalCode: '11366', city: 'STOCKHOLM' },
  { postalCode: '11367', city: 'STOCKHOLM' },
  { postalCode: '11368', city: 'STOCKHOLM' },
  { postalCode: '11369', city: 'STOCKHOLM' },
  { postalCode: '11376', city: 'STOCKHOLM' },
  { postalCode: '11379', city: 'STOCKHOLM' },
  { postalCode: '11380', city: 'STOCKHOLM' },
  { postalCode: '11381', city: 'STOCKHOLM' },
  { postalCode: '11382', city: 'STOCKHOLM' },
  { postalCode: '11383', city: 'STOCKHOLM' },
  { postalCode: '11384', city: 'STOCKHOLM' },
  { postalCode: '11385', city: 'STOCKHOLM' },
  { postalCode: '11386', city: 'STOCKHOLM' },
  { postalCode: '11387', city: 'STOCKHOLM' },
  { postalCode: '11388', city: 'STOCKHOLM' },
  { postalCode: '11389', city: 'STOCKHOLM' },
  { postalCode: '11390', city: 'STOCKHOLM' },
  { postalCode: '11393', city: 'STOCKHOLM' },
  { postalCode: '11394', city: 'STOCKHOLM' },
  { postalCode: '11396', city: 'STOCKHOLM' },
  { postalCode: '11397', city: 'STOCKHOLM' },
  { postalCode: '11398', city: 'STOCKHOLM' },
  { postalCode: '11399', city: 'STOCKHOLM' },
  { postalCode: '11401', city: 'STOCKHOLM' },
  { postalCode: '11402', city: 'STOCKHOLM' },
  { postalCode: '11411', city: 'STOCKHOLM' },
  { postalCode: '11415', city: 'STOCKHOLM' },
  { postalCode: '11416', city: 'STOCKHOLM' },
  { postalCode: '11417', city: 'STOCKHOLM' },
  { postalCode: '11418', city: 'STOCKHOLM' },
  { postalCode: '11419', city: 'STOCKHOLM' },
  { postalCode: '11420', city: 'STOCKHOLM' },
  { postalCode: '11421', city: 'STOCKHOLM' },
  { postalCode: '11422', city: 'STOCKHOLM' },
  { postalCode: '11423', city: 'STOCKHOLM' },
  { postalCode: '11424', city: 'STOCKHOLM' },
  { postalCode: '11425', city: 'STOCKHOLM' },
  { postalCode: '11426', city: 'STOCKHOLM' },
  { postalCode: '11427', city: 'STOCKHOLM' },
  { postalCode: '11428', city: 'STOCKHOLM' },
  { postalCode: '11429', city: 'STOCKHOLM' },
  { postalCode: '11430', city: 'STOCKHOLM' },
  { postalCode: '11431', city: 'STOCKHOLM' },
  { postalCode: '11432', city: 'STOCKHOLM' },
  { postalCode: '11433', city: 'STOCKHOLM' },
  { postalCode: '11434', city: 'STOCKHOLM' },
  { postalCode: '11435', city: 'STOCKHOLM' },
  { postalCode: '11436', city: 'STOCKHOLM' },
  { postalCode: '11437', city: 'STOCKHOLM' },
  { postalCode: '11438', city: 'STOCKHOLM' },
  { postalCode: '11439', city: 'STOCKHOLM' },
  { postalCode: '11440', city: 'STOCKHOLM' },
  { postalCode: '11441', city: 'STOCKHOLM' },
  { postalCode: '11442', city: 'STOCKHOLM' },
  { postalCode: '11443', city: 'STOCKHOLM' },
  { postalCode: '11444', city: 'STOCKHOLM' },
  { postalCode: '11445', city: 'STOCKHOLM' },
  { postalCode: '11446', city: 'STOCKHOLM' },
  { postalCode: '11447', city: 'STOCKHOLM' },
  { postalCode: '11448', city: 'STOCKHOLM' },
  { postalCode: '11449', city: 'STOCKHOLM' },
  { postalCode: '11450', city: 'STOCKHOLM' },
  { postalCode: '11451', city: 'STOCKHOLM' },
  { postalCode: '11452', city: 'STOCKHOLM' },
  { postalCode: '11453', city: 'STOCKHOLM' },
  { postalCode: '11454', city: 'STOCKHOLM' },
  { postalCode: '11455', city: 'STOCKHOLM' },
  { postalCode: '11456', city: 'STOCKHOLM' },
  { postalCode: '11457', city: 'STOCKHOLM' },
  { postalCode: '11458', city: 'STOCKHOLM' },
  { postalCode: '11459', city: 'STOCKHOLM' },
  { postalCode: '11460', city: 'STOCKHOLM' },
  { postalCode: '11473', city: 'STOCKHOLM' },
  { postalCode: '11476', city: 'STOCKHOLM' },
  { postalCode: '11478', city: 'STOCKHOLM' },
  { postalCode: '11479', city: 'STOCKHOLM' },
  { postalCode: '11480', city: 'STOCKHOLM' },
  { postalCode: '11482', city: 'STOCKHOLM' },
  { postalCode: '11483', city: 'STOCKHOLM' },
  { postalCode: '11484', city: 'STOCKHOLM' },
  { postalCode: '11485', city: 'STOCKHOLM' },
  { postalCode: '11486', city: 'STOCKHOLM' },
  { postalCode: '11487', city: 'STOCKHOLM' },
  { postalCode: '11488', city: 'STOCKHOLM' },
  { postalCode: '11489', city: 'STOCKHOLM' },
  { postalCode: '11490', city: 'STOCKHOLM' },
  { postalCode: '11494', city: 'STOCKHOLM' },
  { postalCode: '11495', city: 'STOCKHOLM' },
  { postalCode: '11497', city: 'STOCKHOLM' },
  { postalCode: '11499', city: 'STOCKHOLM' },
  { postalCode: '11501', city: 'STOCKHOLM' },
  { postalCode: '11502', city: 'STOCKHOLM' },
  { postalCode: '11520', city: 'STOCKHOLM' },
  { postalCode: '11521', city: 'STOCKHOLM' },
  { postalCode: '11522', city: 'STOCKHOLM' },
  { postalCode: '11523', city: 'STOCKHOLM' },
  { postalCode: '11524', city: 'STOCKHOLM' },
  { postalCode: '11525', city: 'STOCKHOLM' },
  { postalCode: '11526', city: 'STOCKHOLM' },
  { postalCode: '11527', city: 'STOCKHOLM' },
  { postalCode: '11528', city: 'STOCKHOLM' },
  { postalCode: '11529', city: 'STOCKHOLM' },
  { postalCode: '11530', city: 'STOCKHOLM' },
  { postalCode: '11531', city: 'STOCKHOLM' },
  { postalCode: '11532', city: 'STOCKHOLM' },
  { postalCode: '11533', city: 'STOCKHOLM' },
  { postalCode: '11534', city: 'STOCKHOLM' },
  { postalCode: '11535', city: 'STOCKHOLM' },
  { postalCode: '11536', city: 'STOCKHOLM' },
  { postalCode: '11537', city: 'STOCKHOLM' },
  { postalCode: '11538', city: 'STOCKHOLM' },
  { postalCode: '11539', city: 'STOCKHOLM' },
  { postalCode: '11540', city: 'STOCKHOLM' },
  { postalCode: '11541', city: 'STOCKHOLM' },
  { postalCode: '11542', city: 'STOCKHOLM' },
  { postalCode: '11543', city: 'STOCKHOLM' },
  { postalCode: '11544', city: 'STOCKHOLM' },
  { postalCode: '11545', city: 'STOCKHOLM' },
  { postalCode: '11546', city: 'STOCKHOLM' },
  { postalCode: '11547', city: 'STOCKHOLM' },
  { postalCode: '11548', city: 'STOCKHOLM' },
  { postalCode: '11549', city: 'STOCKHOLM' },
  { postalCode: '11550', city: 'STOCKHOLM' },
  { postalCode: '11551', city: 'STOCKHOLM' },
  { postalCode: '11553', city: 'STOCKHOLM' },
  { postalCode: '11556', city: 'STOCKHOLM' },
  { postalCode: '11557', city: 'STOCKHOLM' },
  { postalCode: '11558', city: 'STOCKHOLM' },
  { postalCode: '11559', city: 'STOCKHOLM' },
  { postalCode: '11560', city: 'STOCKHOLM' },
  { postalCode: '11571', city: 'STOCKHOLM' },
  { postalCode: '11573', city: 'STOCKHOLM' },
  { postalCode: '11576', city: 'STOCKHOLM' },
  { postalCode: '11577', city: 'STOCKHOLM' },
  { postalCode: '11578', city: 'STOCKHOLM' },
  { postalCode: '11579', city: 'STOCKHOLM' },
  { postalCode: '11580', city: 'STOCKHOLM' },
  { postalCode: '11582', city: 'STOCKHOLM' },
  { postalCode: '11583', city: 'STOCKHOLM' },
  { postalCode: '11584', city: 'STOCKHOLM' },
  { postalCode: '11587', city: 'STOCKHOLM' },
  { postalCode: '11588', city: 'STOCKHOLM' },
  { postalCode: '11589', city: 'STOCKHOLM' },
  { postalCode: '11591', city: 'STOCKHOLM' },
  { postalCode: '11593', city: 'STOCKHOLM' },
  { postalCode: '11594', city: 'STOCKHOLM' },
  { postalCode: '11596', city: 'STOCKHOLM' },
  { postalCode: '11597', city: 'STOCKHOLM' },
  { postalCode: '11599', city: 'STOCKHOLM' },
  { postalCode: '11601', city: 'STOCKHOLM' },
  { postalCode: '11618', city: 'STOCKHOLM' },
  { postalCode: '11620', city: 'STOCKHOLM' },
  { postalCode: '11621', city: 'STOCKHOLM' },
  { postalCode: '11622', city: 'STOCKHOLM' },
  { postalCode: '11623', city: 'STOCKHOLM' },
  { postalCode: '11624', city: 'STOCKHOLM' },
  { postalCode: '11625', city: 'STOCKHOLM' },
  { postalCode: '11628', city: 'STOCKHOLM' },
  { postalCode: '11629', city: 'STOCKHOLM' },
  { postalCode: '11630', city: 'STOCKHOLM' },
  { postalCode: '11631', city: 'STOCKHOLM' },
  { postalCode: '11632', city: 'STOCKHOLM' },
  { postalCode: '11633', city: 'STOCKHOLM' },
  { postalCode: '11634', city: 'STOCKHOLM' },
  { postalCode: '11635', city: 'STOCKHOLM' },
  { postalCode: '11636', city: 'STOCKHOLM' },
  { postalCode: '11637', city: 'STOCKHOLM' },
  { postalCode: '11638', city: 'STOCKHOLM' },
  { postalCode: '11639', city: 'STOCKHOLM' },
  { postalCode: '11640', city: 'STOCKHOLM' },
  { postalCode: '11641', city: 'STOCKHOLM' },
  { postalCode: '11642', city: 'STOCKHOLM' },
  { postalCode: '11643', city: 'STOCKHOLM' },
  { postalCode: '11644', city: 'STOCKHOLM' },
  { postalCode: '11645', city: 'STOCKHOLM' },
  { postalCode: '11646', city: 'STOCKHOLM' },
  { postalCode: '11647', city: 'STOCKHOLM' },
  { postalCode: '11648', city: 'STOCKHOLM' },
  { postalCode: '11661', city: 'STOCKHOLM' },
  { postalCode: '11662', city: 'STOCKHOLM' },
  { postalCode: '11663', city: 'STOCKHOLM' },
  { postalCode: '11664', city: 'STOCKHOLM' },
  { postalCode: '11665', city: 'STOCKHOLM' },
  { postalCode: '11666', city: 'STOCKHOLM' },
  { postalCode: '11667', city: 'STOCKHOLM' },
  { postalCode: '11668', city: 'STOCKHOLM' },
  { postalCode: '11674', city: 'STOCKHOLM' },
  { postalCode: '11681', city: 'STOCKHOLM' },
  { postalCode: '11685', city: 'STOCKHOLM' },
  { postalCode: '11688', city: 'STOCKHOLM' },
  { postalCode: '11691', city: 'STOCKHOLM' },
  { postalCode: '11692', city: 'STOCKHOLM' },
  { postalCode: '11694', city: 'STOCKHOLM' },
  { postalCode: '11701', city: 'STOCKHOLM' },
  { postalCode: '11705', city: 'STOCKHOLM' },
  { postalCode: '11718', city: 'STOCKHOLM' },
  { postalCode: '11726', city: 'STOCKHOLM' },
  { postalCode: '11727', city: 'STOCKHOLM' },
  { postalCode: '11728', city: 'STOCKHOLM' },
  { postalCode: '11729', city: 'STOCKHOLM' },
  { postalCode: '11730', city: 'STOCKHOLM' },
  { postalCode: '11731', city: 'STOCKHOLM' },
  { postalCode: '11732', city: 'STOCKHOLM' },
  { postalCode: '11733', city: 'STOCKHOLM' },
  { postalCode: '11734', city: 'STOCKHOLM' },
  { postalCode: '11735', city: 'STOCKHOLM' },
  { postalCode: '11736', city: 'STOCKHOLM' },
  { postalCode: '11737', city: 'STOCKHOLM' },
  { postalCode: '11738', city: 'STOCKHOLM' },
  { postalCode: '11739', city: 'STOCKHOLM' },
  { postalCode: '11740', city: 'STOCKHOLM' },
  { postalCode: '11741', city: 'STOCKHOLM' },
  { postalCode: '11743', city: 'STOCKHOLM' },
  { postalCode: '11750', city: 'STOCKHOLM' },
  { postalCode: '11754', city: 'STOCKHOLM' },
  { postalCode: '11755', city: 'STOCKHOLM' },
  { postalCode: '11756', city: 'STOCKHOLM' },
  { postalCode: '11757', city: 'STOCKHOLM' },
  { postalCode: '11758', city: 'STOCKHOLM' },
  { postalCode: '11759', city: 'STOCKHOLM' },
  { postalCode: '11760', city: 'STOCKHOLM' },
  { postalCode: '11761', city: 'STOCKHOLM' },
  { postalCode: '11762', city: 'STOCKHOLM' },
  { postalCode: '11763', city: 'STOCKHOLM' },
  { postalCode: '11764', city: 'STOCKHOLM' },
  { postalCode: '11765', city: 'STOCKHOLM' },
  { postalCode: '11766', city: 'STOCKHOLM' },
  { postalCode: '11767', city: 'STOCKHOLM' },
  { postalCode: '11768', city: 'STOCKHOLM' },
  { postalCode: '11769', city: 'STOCKHOLM' },
  { postalCode: '11770', city: 'STOCKHOLM' },
  { postalCode: '11771', city: 'STOCKHOLM' },
  { postalCode: '11775', city: 'STOCKHOLM' },
  { postalCode: '11780', city: 'STOCKHOLM' },
  { postalCode: '11783', city: 'STOCKHOLM' },
  { postalCode: '11785', city: 'STOCKHOLM' },
  { postalCode: '11788', city: 'STOCKHOLM' },
  { postalCode: '11790', city: 'STOCKHOLM' },
  { postalCode: '11791', city: 'STOCKHOLM' },
  { postalCode: '11794', city: 'STOCKHOLM' },
  { postalCode: '11797', city: 'STOCKHOLM' },
  { postalCode: '11798', city: 'STOCKHOLM' },
  { postalCode: '11799', city: 'STOCKHOLM' },
  { postalCode: '11801', city: 'STOCKHOLM' },
  { postalCode: '11805', city: 'STOCKHOLM' },
  { postalCode: '11818', city: 'STOCKHOLM' },
  { postalCode: '11820', city: 'STOCKHOLM' },
  { postalCode: '11821', city: 'STOCKHOLM' },
  { postalCode: '11822', city: 'STOCKHOLM' },
  { postalCode: '11823', city: 'STOCKHOLM' },
  { postalCode: '11824', city: 'STOCKHOLM' },
  { postalCode: '11825', city: 'STOCKHOLM' },
  { postalCode: '11826', city: 'STOCKHOLM' },
  { postalCode: '11827', city: 'STOCKHOLM' },
  { postalCode: '11828', city: 'STOCKHOLM' },
  { postalCode: '11829', city: 'STOCKHOLM' },
  { postalCode: '11830', city: 'STOCKHOLM' },
  { postalCode: '11842', city: 'STOCKHOLM' },
  { postalCode: '11846', city: 'STOCKHOLM' },
  { postalCode: '11847', city: 'STOCKHOLM' },
  { postalCode: '11848', city: 'STOCKHOLM' },
  { postalCode: '11849', city: 'STOCKHOLM' },
  { postalCode: '11850', city: 'STOCKHOLM' },
  { postalCode: '11851', city: 'STOCKHOLM' },
  { postalCode: '11852', city: 'STOCKHOLM' },
  { postalCode: '11853', city: 'STOCKHOLM' },
  { postalCode: '11854', city: 'STOCKHOLM' },
  { postalCode: '11855', city: 'STOCKHOLM' },
  { postalCode: '11856', city: 'STOCKHOLM' },
  { postalCode: '11857', city: 'STOCKHOLM' },
  { postalCode: '11858', city: 'STOCKHOLM' },
  { postalCode: '11859', city: 'STOCKHOLM' },
  { postalCode: '11860', city: 'STOCKHOLM' },
  { postalCode: '11861', city: 'STOCKHOLM' },
  { postalCode: '11862', city: 'STOCKHOLM' },
  { postalCode: '11863', city: 'STOCKHOLM' },
  { postalCode: '11864', city: 'STOCKHOLM' },
  { postalCode: '11865', city: 'STOCKHOLM' },
  { postalCode: '11866', city: 'STOCKHOLM' },
  { postalCode: '11867', city: 'STOCKHOLM' },
  { postalCode: '11869', city: 'STOCKHOLM' },
  { postalCode: '11872', city: 'STOCKHOLM' },
  { postalCode: '11880', city: 'STOCKHOLM' },
  { postalCode: '11881', city: 'STOCKHOLM' },
  { postalCode: '11882', city: 'STOCKHOLM' },
  { postalCode: '11883', city: 'STOCKHOLM' },
  { postalCode: '11885', city: 'STOCKHOLM' },
  { postalCode: '11886', city: 'STOCKHOLM' },
  { postalCode: '11888', city: 'STOCKHOLM' },
  { postalCode: '11889', city: 'STOCKHOLM' },
  { postalCode: '11890', city: 'STOCKHOLM' },
  { postalCode: '11891', city: 'STOCKHOLM' },
  { postalCode: '11892', city: 'STOCKHOLM' },
  { postalCode: '11893', city: 'STOCKHOLM' },
  { postalCode: '11894', city: 'STOCKHOLM' },
  { postalCode: '11895', city: 'STOCKHOLM' },
  { postalCode: '12000', city: 'STOCKHOLM' },
  { postalCode: '12001', city: 'STOCKHOLM' },
  { postalCode: '12002', city: 'ÅRSTA' },
  { postalCode: '12003', city: 'ÅRSTA' },
  { postalCode: '12004', city: 'ÅRSTA' },
  { postalCode: '12005', city: 'ÅRSTA' },
  { postalCode: '12006', city: 'STOCKHOLM' },
  { postalCode: '12007', city: 'STOCKHOLM' },
  { postalCode: '12008', city: 'STOCKHOLM' },
  { postalCode: '12009', city: 'STOCKHOLM' },
  { postalCode: '12010', city: 'ÅRSTA' },
  { postalCode: '12011', city: 'STOCKHOLM' },
  { postalCode: '12012', city: 'STOCKHOLM' },
  { postalCode: '12020', city: 'STOCKHOLM' },
  { postalCode: '12021', city: 'STOCKHOLM' },
  { postalCode: '12022', city: 'STOCKHOLM' },
  { postalCode: '12023', city: 'STOCKHOLM' },
  { postalCode: '12024', city: 'STOCKHOLM' },
  { postalCode: '12025', city: 'STOCKHOLM' },
  { postalCode: '12030', city: 'STOCKHOLM' },
  { postalCode: '12031', city: 'STOCKHOLM' },
  { postalCode: '12032', city: 'STOCKHOLM' },
  { postalCode: '12038', city: 'ÅRSTA' },
  { postalCode: '12039', city: 'ÅRSTA' },
  { postalCode: '12040', city: 'ÅRSTA' },
  { postalCode: '12044', city: 'ÅRSTA' },
  { postalCode: '12047', city: 'ENSKEDE GÅRD' },
  { postalCode: '12048', city: 'ENSKEDE GÅRD' },
  { postalCode: '12050', city: 'ÅRSTA' },
  { postalCode: '12051', city: 'ÅRSTA' },
  { postalCode: '12052', city: 'ÅRSTA' },
  { postalCode: '12053', city: 'ÅRSTA' },
  { postalCode: '12054', city: 'ÅRSTA' },
  { postalCode: '12055', city: 'ÅRSTA' },
  { postalCode: '12056', city: 'ÅRSTA' },
  { postalCode: '12057', city: 'ÅRSTA' },
  { postalCode: '12058', city: 'ÅRSTA' },
  { postalCode: '12059', city: 'ÅRSTA' },
  { postalCode: '12060', city: 'ÅRSTA' },
  { postalCode: '12061', city: 'STOCKHOLM' },
  { postalCode: '12062', city: 'STOCKHOLM' },
  { postalCode: '12063', city: 'STOCKHOLM' },
  { postalCode: '12064', city: 'STOCKHOLM' },
  { postalCode: '12065', city: 'STOCKHOLM' },
  { postalCode: '12066', city: 'STOCKHOLM' },
  { postalCode: '12067', city: 'STOCKHOLM' },
  { postalCode: '12068', city: 'STOCKHOLM' },
  { postalCode: '12069', city: 'STOCKHOLM' },
  { postalCode: '12070', city: 'STOCKHOLM' },
  { postalCode: '12071', city: 'STOCKHOLM' },
  { postalCode: '12078', city: 'STOCKHOLM' },
  { postalCode: '12079', city: 'STOCKHOLM' },
  { postalCode: '12082', city: 'STOCKHOLM' },
  { postalCode: '12083', city: 'STOCKHOLM' },
  { postalCode: '12086', city: 'STOCKHOLM' },
  { postalCode: '12087', city: 'STOCKHOLM' },
  { postalCode: '12088', city: 'STOCKHOLM' },
  { postalCode: '12090', city: 'STOCKHOLM' },
  { postalCode: '12100', city: 'JOHANNESHOV' },
  { postalCode: '12105', city: 'JOHANNESHOV' },
  { postalCode: '12106', city: 'JOHANNESHOV' },
  { postalCode: '12107', city: 'STOCKHOLM-GLOBEN' },
  { postalCode: '12114', city: 'ENSKEDEDALEN' },
  { postalCode: '12115', city: 'ENSKEDEDALEN' },
  { postalCode: '12116', city: 'JOHANNESHOV' },
  { postalCode: '12117', city: 'JOHANNESHOV' },
  { postalCode: '12118', city: 'JOHANNESHOV' },
  { postalCode: '12120', city: 'JOHANNESHOV' },
  { postalCode: '12121', city: 'JOHANNESHOV' },
  { postalCode: '12122', city: 'JOHANNESHOV' },
  { postalCode: '12123', city: 'JOHANNESHOV' },
  { postalCode: '12125', city: 'STOCKHOLM-GLOBEN' },
  { postalCode: '12126', city: 'STOCKHOLM-GLOBEN' },
  { postalCode: '12127', city: 'STOCKHOLM-GLOBEN' },
  { postalCode: '12128', city: 'STOCKHOLM-GLOBEN' },
  { postalCode: '12129', city: 'STOCKHOLM' },
  { postalCode: '12130', city: 'ENSKEDEDALEN' },
  { postalCode: '12131', city: 'ENSKEDEDALEN' },
  { postalCode: '12132', city: 'ENSKEDEDALEN' },
  { postalCode: '12133', city: 'ENSKEDEDALEN' },
  { postalCode: '12134', city: 'ENSKEDEDALEN' },
  { postalCode: '12135', city: 'JOHANNESHOV' },
  { postalCode: '12136', city: 'JOHANNESHOV' },
  { postalCode: '12137', city: 'JOHANNESHOV' },
  { postalCode: '12138', city: 'JOHANNESHOV' },
  { postalCode: '12139', city: 'JOHANNESHOV' },
  { postalCode: '12140', city: 'JOHANNESHOV' },
  { postalCode: '12143', city: 'JOHANNESHOV' },
  { postalCode: '12144', city: 'JOHANNESHOV' },
  { postalCode: '12145', city: 'JOHANNESHOV' },
  { postalCode: '12146', city: 'JOHANNESHOV' },
  { postalCode: '12147', city: 'JOHANNESHOV' },
  { postalCode: '12148', city: 'JOHANNESHOV' },
  { postalCode: '12149', city: 'JOHANNESHOV' },
  { postalCode: '12150', city: 'JOHANNESHOV' },
  { postalCode: '12151', city: 'JOHANNESHOV' },
  { postalCode: '12152', city: 'JOHANNESHOV' },
  { postalCode: '12153', city: 'JOHANNESHOV' },
  { postalCode: '12154', city: 'JOHANNESHOV' },
  { postalCode: '12155', city: 'JOHANNESHOV' },
  { postalCode: '12156', city: 'JOHANNESHOV' },
  { postalCode: '12162', city: 'JOHANNESHOV' },
  { postalCode: '12163', city: 'JOHANNESHOV' },
  { postalCode: '12177', city: 'JOHANNESHOV' },
  { postalCode: '12187', city: 'ENSKEDEDALEN' },
  { postalCode: '12188', city: 'STOCKHOLM-GLOBEN' },
  { postalCode: '12200', city: 'ENSKEDE' },
  { postalCode: '12203', city: 'ENSKEDE' },
  { postalCode: '12206', city: 'ENSKEDE' },
  { postalCode: '12220', city: 'ENSKEDE' },
  { postalCode: '12231', city: 'ENSKEDE' },
  { postalCode: '12232', city: 'ENSKEDE' },
  { postalCode: '12233', city: 'ENSKEDE' },
  { postalCode: '12234', city: 'ENSKEDE' },
  { postalCode: '12237', city: 'ENSKEDE' },
  { postalCode: '12238', city: 'ENSKEDE' },
  { postalCode: '12239', city: 'ENSKEDE' },
  { postalCode: '12240', city: 'ENSKEDE' },
  { postalCode: '12241', city: 'ENSKEDE' },
  { postalCode: '12242', city: 'ENSKEDE' },
  { postalCode: '12243', city: 'ENSKEDE' },
  { postalCode: '12244', city: 'ENSKEDE' },
  { postalCode: '12245', city: 'ENSKEDE' },
  { postalCode: '12246', city: 'ENSKEDE' },
  { postalCode: '12247', city: 'ENSKEDE' },
  { postalCode: '12248', city: 'ENSKEDE' },
  { postalCode: '12249', city: 'ENSKEDE' },
  { postalCode: '12260', city: 'ENSKEDE' },
  { postalCode: '12262', city: 'ENSKEDE' },
  { postalCode: '12263', city: 'ENSKEDE' },
  { postalCode: '12264', city: 'ENSKEDE' },
  { postalCode: '12265', city: 'ENSKEDE' },
  { postalCode: '12266', city: 'ENSKEDE' },
  { postalCode: '12286', city: 'ENSKEDE' },
  { postalCode: '12288', city: 'ENSKEDE' },
  { postalCode: '12300', city: 'FARSTA' },
  { postalCode: '12305', city: 'FARSTA' },
  { postalCode: '12320', city: 'FARSTA' },
  { postalCode: '12321', city: 'FARSTA' },
  { postalCode: '12322', city: 'FARSTA' },
  { postalCode: '12323', city: 'FARSTA' },
  { postalCode: '12331', city: 'FARSTA' },
  { postalCode: '12332', city: 'FARSTA' },
  { postalCode: '12333', city: 'FARSTA' },
  { postalCode: '12334', city: 'FARSTA' },
  { postalCode: '12340', city: 'FARSTA' },
  { postalCode: '12341', city: 'FARSTA' },
  { postalCode: '12342', city: 'FARSTA' },
  { postalCode: '12343', city: 'FARSTA' },
  { postalCode: '12344', city: 'FARSTA' },
  { postalCode: '12346', city: 'FARSTA' },
  { postalCode: '12347', city: 'FARSTA' },
  { postalCode: '12348', city: 'FARSTA' },
  { postalCode: '12349', city: 'FARSTA' },
  { postalCode: '12350', city: 'FARSTA' },
  { postalCode: '12352', city: 'FARSTA' },
  { postalCode: '12353', city: 'FARSTA' },
  { postalCode: '12354', city: 'FARSTA' },
  { postalCode: '12355', city: 'FARSTA' },
  { postalCode: '12356', city: 'FARSTA' },
  { postalCode: '12357', city: 'FARSTA' },
  { postalCode: '12358', city: 'FARSTA' },
  { postalCode: '12359', city: 'FARSTA' },
  { postalCode: '12360', city: 'FARSTA' },
  { postalCode: '12361', city: 'FARSTA' },
  { postalCode: '12363', city: 'FARSTA' },
  { postalCode: '12371', city: 'FARSTA' },
  { postalCode: '12372', city: 'FARSTA' },
  { postalCode: '12373', city: 'FARSTA' },
  { postalCode: '12386', city: 'FARSTA' },
  { postalCode: '12400', city: 'BANDHAGEN' },
  { postalCode: '12404', city: 'BANDHAGEN' },
  { postalCode: '12420', city: 'BANDHAGEN' },
  { postalCode: '12421', city: 'BANDHAGEN' },
  { postalCode: '12422', city: 'BANDHAGEN' },
  { postalCode: '12430', city: 'BANDHAGEN' },
  { postalCode: '12431', city: 'BANDHAGEN' },
  { postalCode: '12432', city: 'BANDHAGEN' },
  { postalCode: '12433', city: 'BANDHAGEN' },
  { postalCode: '12450', city: 'BANDHAGEN' },
  { postalCode: '12451', city: 'BANDHAGEN' },
  { postalCode: '12452', city: 'BANDHAGEN' },
  { postalCode: '12453', city: 'BANDHAGEN' },
  { postalCode: '12454', city: 'BANDHAGEN' },
  { postalCode: '12455', city: 'BANDHAGEN' },
  { postalCode: '12456', city: 'BANDHAGEN' },
  { postalCode: '12457', city: 'BANDHAGEN' },
  { postalCode: '12458', city: 'BANDHAGEN' },
  { postalCode: '12459', city: 'BANDHAGEN' },
  { postalCode: '12460', city: 'BANDHAGEN' },
  { postalCode: '12461', city: 'BANDHAGEN' },
  { postalCode: '12462', city: 'BANDHAGEN' },
  { postalCode: '12463', city: 'BANDHAGEN' },
  { postalCode: '12464', city: 'BANDHAGEN' },
  { postalCode: '12465', city: 'BANDHAGEN' },
  { postalCode: '12466', city: 'BANDHAGEN' },
  { postalCode: '12467', city: 'BANDHAGEN' },
  { postalCode: '12470', city: 'BANDHAGEN' },
  { postalCode: '12471', city: 'BANDHAGEN' },
  { postalCode: '12472', city: 'BANDHAGEN' },
  { postalCode: '12473', city: 'BANDHAGEN' },
  { postalCode: '12474', city: 'BANDHAGEN' },
  { postalCode: '12475', city: 'BANDHAGEN' },
  { postalCode: '12476', city: 'BANDHAGEN' },
  { postalCode: '12502', city: 'ÄLVSJÖ' },
  { postalCode: '12503', city: 'ÄLVSJÖ' },
  { postalCode: '12505', city: 'ÄLVSJÖ' },
  { postalCode: '12520', city: 'ÄLVSJÖ' },
  { postalCode: '12521', city: 'ÄLVSJÖ' },
  { postalCode: '12522', city: 'ÄLVSJÖ' },
  { postalCode: '12523', city: 'ÄLVSJÖ' },
  { postalCode: '12524', city: 'ÄLVSJÖ' },
  { postalCode: '12530', city: 'ÄLVSJÖ' },
  { postalCode: '12531', city: 'ÄLVSJÖ' },
  { postalCode: '12532', city: 'ÄLVSJÖ' },
  { postalCode: '12533', city: 'ÄLVSJÖ' },
  { postalCode: '12534', city: 'ÄLVSJÖ' },
  { postalCode: '12535', city: 'ÄLVSJÖ' },
  { postalCode: '12540', city: 'ÄLVSJÖ' },
  { postalCode: '12541', city: 'ÄLVSJÖ' },
  { postalCode: '12542', city: 'ÄLVSJÖ' },
  { postalCode: '12543', city: 'ÄLVSJÖ' },
  { postalCode: '12544', city: 'ÄLVSJÖ' },
  { postalCode: '12545', city: 'ÄLVSJÖ' },
  { postalCode: '12546', city: 'ÄLVSJÖ' },
  { postalCode: '12547', city: 'ÄLVSJÖ' },
  { postalCode: '12548', city: 'ÄLVSJÖ' },
  { postalCode: '12551', city: 'ÄLVSJÖ' },
  { postalCode: '12552', city: 'ÄLVSJÖ' },
  { postalCode: '12553', city: 'ÄLVSJÖ' },
  { postalCode: '12554', city: 'ÄLVSJÖ' },
  { postalCode: '12555', city: 'ÄLVSJÖ' },
  { postalCode: '12556', city: 'ÄLVSJÖ' },
  { postalCode: '12557', city: 'ÄLVSJÖ' },
  { postalCode: '12558', city: 'ÄLVSJÖ' },
  { postalCode: '12559', city: 'ÄLVSJÖ' },
  { postalCode: '12560', city: 'ÄLVSJÖ' },
  { postalCode: '12570', city: 'ÄLVSJÖ' },
  { postalCode: '12571', city: 'ÄLVSJÖ' },
  { postalCode: '12572', city: 'ÄLVSJÖ' },
  { postalCode: '12573', city: 'ÄLVSJÖ' },
  { postalCode: '12574', city: 'ÄLVSJÖ' },
  { postalCode: '12580', city: 'STOCKHOLM' },
  { postalCode: '12581', city: 'STOCKHOLM' },
  { postalCode: '12582', city: 'STOCKHOLM' },
  { postalCode: '12586', city: 'ÄLVSJÖ' },
  { postalCode: '12600', city: 'HÄGERSTEN' },
  { postalCode: '12608', city: 'STOCKHOLM' },
  { postalCode: '12609', city: 'HÄGERSTEN' },
  { postalCode: '12611', city: 'STOCKHOLM' },
  { postalCode: '12612', city: 'STOCKHOLM' },
  { postalCode: '12613', city: 'STOCKHOLM' },
  { postalCode: '12614', city: 'STOCKHOLM' },
  { postalCode: '12615', city: 'STOCKHOLM' },
  { postalCode: '12616', city: 'STOCKHOLM' },
  { postalCode: '12617', city: 'STOCKHOLM' },
  { postalCode: '12620', city: 'STOCKHOLM' },
  { postalCode: '12625', city: 'STOCKHOLM' },
  { postalCode: '12626', city: 'HÄGERSTEN' },
  { postalCode: '12627', city: 'STOCKHOLM' },
  { postalCode: '12628', city: 'HÄGERSTEN' },
  { postalCode: '12629', city: 'HÄGERSTEN' },
  { postalCode: '12630', city: 'HÄGERSTEN' },
  { postalCode: '12631', city: 'HÄGERSTEN' },
  { postalCode: '12632', city: 'HÄGERSTEN' },
  { postalCode: '12633', city: 'HÄGERSTEN' },
  { postalCode: '12634', city: 'HÄGERSTEN' },
  { postalCode: '12635', city: 'HÄGERSTEN' },
  { postalCode: '12636', city: 'HÄGERSTEN' },
  { postalCode: '12637', city: 'HÄGERSTEN' },
  { postalCode: '12638', city: 'HÄGERSTEN' },
  { postalCode: '12639', city: 'HÄGERSTEN' },
  { postalCode: '12640', city: 'HÄGERSTEN' },
  { postalCode: '12641', city: 'HÄGERSTEN' },
  { postalCode: '12642', city: 'HÄGERSTEN' },
  { postalCode: '12647', city: 'HÄGERSTEN' },
  { postalCode: '12648', city: 'HÄGERSTEN' },
  { postalCode: '12649', city: 'HÄGERSTEN' },
  { postalCode: '12650', city: 'HÄGERSTEN' },
  { postalCode: '12651', city: 'HÄGERSTEN' },
  { postalCode: '12652', city: 'HÄGERSTEN' },
  { postalCode: '12653', city: 'HÄGERSTEN' },
  { postalCode: '12654', city: 'HÄGERSTEN' },
  { postalCode: '12655', city: 'HÄGERSTEN' },
  { postalCode: '12677', city: 'HÄGERSTEN' },
  { postalCode: '12678', city: 'HÄGERSTEN' },
  { postalCode: '12679', city: 'HÄGERSTEN' },
  { postalCode: '12680', city: 'STOCKHOLM' },
  { postalCode: '12681', city: 'STOCKHOLM' },
  { postalCode: '12686', city: 'STOCKHOLM' },
  { postalCode: '12688', city: 'STOCKHOLM' },
  { postalCode: '12700', city: 'SKÄRHOLMEN' },
  { postalCode: '12702', city: 'SKÄRHOLMEN' },
  { postalCode: '12720', city: 'SKÄRHOLMEN' },
  { postalCode: '12722', city: 'SKÄRHOLMEN' },
  { postalCode: '12723', city: 'SKÄRHOLMEN' },
  { postalCode: '12724', city: 'SKÄRHOLMEN' },
  { postalCode: '12725', city: 'SKÄRHOLMEN' },
  { postalCode: '12726', city: 'SKÄRHOLMEN' },
  { postalCode: '12729', city: 'STOCKHOLM' },
  { postalCode: '12730', city: 'SKÄRHOLMEN' },
  { postalCode: '12731', city: 'SKÄRHOLMEN' },
  { postalCode: '12732', city: 'SKÄRHOLMEN' },
  { postalCode: '12733', city: 'SKÄRHOLMEN' },
  { postalCode: '12734', city: 'SKÄRHOLMEN' },
  { postalCode: '12735', city: 'SKÄRHOLMEN' },
  { postalCode: '12736', city: 'SKÄRHOLMEN' },
  { postalCode: '12737', city: 'SKÄRHOLMEN' },
  { postalCode: '12738', city: 'SKÄRHOLMEN' },
  { postalCode: '12739', city: 'SKÄRHOLMEN' },
  { postalCode: '12740', city: 'SKÄRHOLMEN' },
  { postalCode: '12741', city: 'SKÄRHOLMEN' },
  { postalCode: '12742', city: 'SKÄRHOLMEN' },
  { postalCode: '12743', city: 'SKÄRHOLMEN' },
  { postalCode: '12744', city: 'SKÄRHOLMEN' },
  { postalCode: '12745', city: 'SKÄRHOLMEN' },
  { postalCode: '12746', city: 'SKÄRHOLMEN' },
  { postalCode: '12747', city: 'SKÄRHOLMEN' },
  { postalCode: '12748', city: 'SKÄRHOLMEN' },
  { postalCode: '12749', city: 'SKÄRHOLMEN' },
  { postalCode: '12751', city: 'SKÄRHOLMEN' },
  { postalCode: '12760', city: 'SKÄRHOLMEN' },
  { postalCode: '12761', city: 'SKÄRHOLMEN' },
  { postalCode: '12784', city: 'SKÄRHOLMEN' },
  { postalCode: '12785', city: 'SKÄRHOLMEN' },
  { postalCode: '12800', city: 'SKARPNÄCK' },
  { postalCode: '12804', city: 'BAGARMOSSEN' },
  { postalCode: '12805', city: 'BAGARMOSSEN' },
  { postalCode: '12806', city: 'SKÖNDAL' },
  { postalCode: '12807', city: 'SKÖNDAL' },
  { postalCode: '12820', city: 'SKARPNÄCK' },
  { postalCode: '12821', city: 'SKARPNÄCK' },
  { postalCode: '12822', city: 'SKARPNÄCK' },
  { postalCode: '12823', city: 'SKARPNÄCK' },
  { postalCode: '12830', city: 'SKARPNÄCK' },
  { postalCode: '12831', city: 'SKARPNÄCK' },
  { postalCode: '12832', city: 'SKARPNÄCK' },
  { postalCode: '12833', city: 'SKARPNÄCK' },
  { postalCode: '12834', city: 'SKARPNÄCK' },
  { postalCode: '12835', city: 'SKARPNÄCK' },
  { postalCode: '12836', city: 'SKARPNÄCK' },
  { postalCode: '12838', city: 'SKARPNÄCK' },
  { postalCode: '12839', city: 'SKARPNÄCK' },
  { postalCode: '12841', city: 'BAGARMOSSEN' },
  { postalCode: '12842', city: 'BAGARMOSSEN' },
  { postalCode: '12843', city: 'BAGARMOSSEN' },
  { postalCode: '12844', city: 'BAGARMOSSEN' },
  { postalCode: '12845', city: 'BAGARMOSSEN' },
  { postalCode: '12846', city: 'BAGARMOSSEN' },
  { postalCode: '12847', city: 'BAGARMOSSEN' },
  { postalCode: '12848', city: 'BAGARMOSSEN' },
  { postalCode: '12862', city: 'SKÖNDAL' },
  { postalCode: '12863', city: 'SKÖNDAL' },
  { postalCode: '12864', city: 'SKÖNDAL' },
  { postalCode: '12865', city: 'SKÖNDAL' },
  { postalCode: '12866', city: 'SKÖNDAL' },
  { postalCode: '12867', city: 'SKÖNDAL' },
  { postalCode: '12868', city: 'SKÖNDAL' },
  { postalCode: '12869', city: 'SKÖNDAL' },
  { postalCode: '12885', city: 'SKÖNDAL' },
  { postalCode: '12900', city: 'HÄGERSTEN' },
  { postalCode: '12904', city: 'HÄGERSTEN' },
  { postalCode: '12906', city: 'HÄGERSTEN' },
  { postalCode: '12907', city: 'HÄGERSTEN' },
  { postalCode: '12920', city: 'HÄGERSTEN' },
  { postalCode: '12921', city: 'HÄGERSTEN' },
  { postalCode: '12922', city: 'HÄGERSTEN' },
  { postalCode: '12923', city: 'HÄGERSTEN' },
  { postalCode: '12930', city: 'HÄGERSTEN' },
  { postalCode: '12931', city: 'HÄGERSTEN' },
  { postalCode: '12932', city: 'HÄGERSTEN' },
  { postalCode: '12933', city: 'HÄGERSTEN' },
  { postalCode: '12934', city: 'HÄGERSTEN' },
  { postalCode: '12935', city: 'HÄGERSTEN' },
  { postalCode: '12936', city: 'HÄGERSTEN' },
  { postalCode: '12937', city: 'HÄGERSTEN' },
  { postalCode: '12938', city: 'HÄGERSTEN' },
  { postalCode: '12939', city: 'HÄGERSTEN' },
  { postalCode: '12940', city: 'HÄGERSTEN' },
  { postalCode: '12941', city: 'HÄGERSTEN' },
  { postalCode: '12942', city: 'HÄGERSTEN' },
  { postalCode: '12943', city: 'HÄGERSTEN' },
  { postalCode: '12944', city: 'HÄGERSTEN' },
  { postalCode: '12945', city: 'HÄGERSTEN' },
  { postalCode: '12946', city: 'HÄGERSTEN' },
  { postalCode: '12947', city: 'HÄGERSTEN' },
  { postalCode: '12948', city: 'HÄGERSTEN' },
  { postalCode: '12949', city: 'HÄGERSTEN' },
  { postalCode: '12950', city: 'HÄGERSTEN' },
  { postalCode: '12951', city: 'HÄGERSTEN' },
  { postalCode: '12952', city: 'HÄGERSTEN' },
  { postalCode: '12953', city: 'HÄGERSTEN' },
  { postalCode: '12954', city: 'HÄGERSTEN' },
  { postalCode: '12955', city: 'HÄGERSTEN' },
  { postalCode: '12956', city: 'HÄGERSTEN' },
  { postalCode: '12957', city: 'HÄGERSTEN' },
  { postalCode: '13025', city: 'INGMARSÖ' },
  { postalCode: '13033', city: 'GÄLLNÖBY' },
  { postalCode: '13034', city: 'SKÄLVIK' },
  { postalCode: '13036', city: 'NÄMDÖ' },
  { postalCode: '13038', city: 'RUNMARÖ' },
  { postalCode: '13039', city: 'SANDHAMN' },
  { postalCode: '13041', city: 'SOLLENKROKA Ö' },
  { postalCode: '13042', city: 'STAVSUDDA' },
  { postalCode: '13043', city: 'MÖJA' },
  { postalCode: '13044', city: 'HARÖ' },
  { postalCode: '13045', city: 'GRÄNÖ' },
  { postalCode: '13055', city: 'ORNÖ' },
  { postalCode: '13056', city: 'UTÖ' },
  { postalCode: '13061', city: 'HÅRSFJÄRDEN' },
  { postalCode: '13100', city: 'NACKA' },
  { postalCode: '13102', city: 'NACKA' },
  { postalCode: '13104', city: 'NACKA' },
  { postalCode: '13105', city: 'NACKA' },
  { postalCode: '13106', city: 'NACKA' },
  { postalCode: '13107', city: 'NACKA' },
  { postalCode: '13108', city: 'NACKA' },
  { postalCode: '13120', city: 'NACKA' },
  { postalCode: '13121', city: 'NACKA' },
  { postalCode: '13122', city: 'NACKA' },
  { postalCode: '13124', city: 'NACKA' },
  { postalCode: '13125', city: 'NACKA' },
  { postalCode: '13126', city: 'NACKA STRAND' },
  { postalCode: '13127', city: 'NACKA STRAND' },
  { postalCode: '13128', city: 'NACKA STRAND' },
  { postalCode: '13130', city: 'NACKA' },
  { postalCode: '13131', city: 'NACKA' },
  { postalCode: '13132', city: 'NACKA' },
  { postalCode: '13133', city: 'NACKA' },
  { postalCode: '13134', city: 'NACKA' },
  { postalCode: '13135', city: 'NACKA' },
  { postalCode: '13136', city: 'NACKA' },
  { postalCode: '13137', city: 'NACKA' },
  { postalCode: '13138', city: 'NACKA' },
  { postalCode: '13139', city: 'NACKA' },
  { postalCode: '13140', city: 'NACKA' },
  { postalCode: '13141', city: 'NACKA' },
  { postalCode: '13142', city: 'NACKA' },
  { postalCode: '13144', city: 'NACKA' },
  { postalCode: '13145', city: 'NACKA' },
  { postalCode: '13146', city: 'NACKA' },
  { postalCode: '13147', city: 'NACKA' },
  { postalCode: '13148', city: 'NACKA' },
  { postalCode: '13149', city: 'NACKA' },
  { postalCode: '13150', city: 'SALTSJÖ-DUVNÄS' },
  { postalCode: '13151', city: 'NACKA' },
  { postalCode: '13152', city: 'NACKA STRAND' },
  { postalCode: '13153', city: 'NACKA' },
  { postalCode: '13154', city: 'NACKA' },
  { postalCode: '13155', city: 'NACKA' },
  { postalCode: '13156', city: 'NACKA' },
  { postalCode: '13157', city: 'NACKA' },
  { postalCode: '13160', city: 'NACKA' },
  { postalCode: '13161', city: 'NACKA' },
  { postalCode: '13165', city: 'NACKA' },
  { postalCode: '13171', city: 'NACKA' },
  { postalCode: '13172', city: 'NACKA' },
  { postalCode: '13173', city: 'NACKA' },
  { postalCode: '13174', city: 'NACKA' },
  { postalCode: '13175', city: 'NACKA' },
  { postalCode: '13181', city: 'NACKA' },
  { postalCode: '13183', city: 'NACKA' },
  { postalCode: '13185', city: 'STOCKHOLM' },
  { postalCode: '13188', city: 'STOCKHOLM' },
  { postalCode: '13191', city: 'STOCKHOLM' },
  { postalCode: '13192', city: 'NACKA' },
  { postalCode: '13200', city: 'SALTSJÖ-BOO' },
  { postalCode: '13202', city: 'SALTSJÖ-BOO' },
  { postalCode: '13204', city: 'SALTSJÖ-BOO' },
  { postalCode: '13220', city: 'SALTSJÖ-BOO' },
  { postalCode: '13221', city: 'SALTSJÖ-BOO' },
  { postalCode: '13222', city: 'SALTSJÖ-BOO' },
  { postalCode: '13223', city: 'SALTSJÖ-BOO' },
  { postalCode: '13225', city: 'SALTSJÖ-BOO' },
  { postalCode: '13226', city: 'SALTSJÖ-BOO' },
  { postalCode: '13230', city: 'SALTSJÖ-BOO' },
  { postalCode: '13231', city: 'SALTSJÖ-BOO' },
  { postalCode: '13232', city: 'SALTSJÖ-BOO' },
  { postalCode: '13233', city: 'SALTSJÖ-BOO' },
  { postalCode: '13234', city: 'SALTSJÖ-BOO' },
  { postalCode: '13235', city: 'SALTSJÖ-BOO' },
  { postalCode: '13236', city: 'SALTSJÖ-BOO' },
  { postalCode: '13237', city: 'SALTSJÖ-BOO' },
  { postalCode: '13238', city: 'SALTSJÖ-BOO' },
  { postalCode: '13239', city: 'SALTSJÖ-BOO' },
  { postalCode: '13240', city: 'SALTSJÖ-BOO' },
  { postalCode: '13241', city: 'SALTSJÖ-BOO' },
  { postalCode: '13242', city: 'SALTSJÖ-BOO' },
  { postalCode: '13243', city: 'SALTSJÖ-BOO' },
  { postalCode: '13244', city: 'SALTSJÖ-BOO' },
  { postalCode: '13245', city: 'SALTSJÖ-BOO' },
  { postalCode: '13246', city: 'SALTSJÖ-BOO' },
  { postalCode: '13247', city: 'SALTSJÖ-BOO' },
  { postalCode: '13248', city: 'SALTSJÖ-BOO' },
  { postalCode: '13249', city: 'SALTSJÖ-BOO' },
  { postalCode: '13251', city: 'SALTSJÖ-BOO' },
  { postalCode: '13252', city: 'SALTSJÖ-BOO' },
  { postalCode: '13253', city: 'SALTSJÖ-BOO' },
  { postalCode: '13281', city: 'SALTSJÖ-BOO' },
  { postalCode: '13282', city: 'SALTSJÖ-BOO' },
  { postalCode: '13300', city: 'SALTSJÖBADEN' },
  { postalCode: '13302', city: 'SALTSJÖBADEN' },
  { postalCode: '13304', city: 'SALTSJÖBADEN' },
  { postalCode: '13320', city: 'SALTSJÖBADEN' },
  { postalCode: '13321', city: 'SALTSJÖBADEN' },
  { postalCode: '13322', city: 'SALTSJÖBADEN' },
  { postalCode: '13331', city: 'SALTSJÖBADEN' },
  { postalCode: '13332', city: 'SALTSJÖBADEN' },
  { postalCode: '13333', city: 'SALTSJÖBADEN' },
  { postalCode: '13334', city: 'SALTSJÖBADEN' },
  { postalCode: '13335', city: 'SALTSJÖBADEN' },
  { postalCode: '13336', city: 'SALTSJÖBADEN' },
  { postalCode: '13337', city: 'SALTSJÖBADEN' },
  { postalCode: '13338', city: 'SALTSJÖBADEN' },
  { postalCode: '13339', city: 'SALTSJÖBADEN' },
  { postalCode: '13341', city: 'SALTSJÖBADEN' },
  { postalCode: '13342', city: 'SALTSJÖBADEN' },
  { postalCode: '13343', city: 'SALTSJÖBADEN' },
  { postalCode: '13344', city: 'SALTSJÖBADEN' },
  { postalCode: '13348', city: 'SALTSJÖBADEN' },
  { postalCode: '13349', city: 'SALTSJÖBADEN' },
  { postalCode: '13380', city: 'SALTSJÖBADEN' },
  { postalCode: '13381', city: 'SALTSJÖBADEN' },
  { postalCode: '13383', city: 'SALTSJÖBADEN' },
  { postalCode: '13400', city: 'GUSTAVSBERG' },
  { postalCode: '13401', city: 'GUSTAVSBERG' },
  { postalCode: '13406', city: 'INGARÖ' },
  { postalCode: '13407', city: 'INGARÖ' },
  { postalCode: '13408', city: 'INGARÖ' },
  { postalCode: '13420', city: 'GUSTAVSBERG' },
  { postalCode: '13421', city: 'GUSTAVSBERG' },
  { postalCode: '13422', city: 'GUSTAVSBERG' },
  { postalCode: '13423', city: 'GUSTAVSBERG' },
  { postalCode: '13427', city: 'GUSTAVSBERG' },
  { postalCode: '13429', city: 'GUSTAVSBERG' },
  { postalCode: '13430', city: 'GUSTAVSBERG' },
  { postalCode: '13431', city: 'GUSTAVSBERG' },
  { postalCode: '13432', city: 'GUSTAVSBERG' },
  { postalCode: '13433', city: 'GUSTAVSBERG' },
  { postalCode: '13434', city: 'GUSTAVSBERG' },
  { postalCode: '13435', city: 'GUSTAVSBERG' },
  { postalCode: '13436', city: 'GUSTAVSBERG' },
  { postalCode: '13437', city: 'GUSTAVSBERG' },
  { postalCode: '13438', city: 'GUSTAVSBERG' },
  { postalCode: '13439', city: 'GUSTAVSBERG' },
  { postalCode: '13440', city: 'GUSTAVSBERG' },
  { postalCode: '13441', city: 'GUSTAVSBERG' },
  { postalCode: '13442', city: 'GUSTAVSBERG' },
  { postalCode: '13443', city: 'GUSTAVSBERG' },
  { postalCode: '13444', city: 'GUSTAVSBERG' },
  { postalCode: '13450', city: 'GUSTAVSBERG' },
  { postalCode: '13451', city: 'GUSTAVSBERG' },
  { postalCode: '13452', city: 'GUSTAVSBERG' },
  { postalCode: '13453', city: 'GUSTAVSBERG' },
  { postalCode: '13460', city: 'INGARÖ' },
  { postalCode: '13461', city: 'INGARÖ' },
  { postalCode: '13462', city: 'INGARÖ' },
  { postalCode: '13463', city: 'INGARÖ' },
  { postalCode: '13464', city: 'INGARÖ' },
  { postalCode: '13465', city: 'INGARÖ' },
  { postalCode: '13466', city: 'INGARÖ' },
  { postalCode: '13467', city: 'INGARÖ' },
  { postalCode: '13469', city: 'INGARÖ' },
  { postalCode: '13481', city: 'GUSTAVSBERG' },
  { postalCode: '13482', city: 'GUSTAVSBERG' },
  { postalCode: '13492', city: 'TYNNINGÖ' },
  { postalCode: '13500', city: 'TYRESÖ' },
  { postalCode: '13501', city: 'TYRESÖ' },
  { postalCode: '13502', city: 'TYRESÖ' },
  { postalCode: '13517', city: 'TYRESÖ' },
  { postalCode: '13520', city: 'TYRESÖ' },
  { postalCode: '13521', city: 'TYRESÖ' },
  { postalCode: '13522', city: 'TYRESÖ' },
  { postalCode: '13523', city: 'TYRESÖ' },
  { postalCode: '13525', city: 'TYRESÖ' },
  { postalCode: '13526', city: 'TYRESÖ' },
  { postalCode: '13527', city: 'TYRESÖ' },
  { postalCode: '13528', city: 'TYRESÖ' },
  { postalCode: '13529', city: 'TYRESÖ' },
  { postalCode: '13530', city: 'TYRESÖ' },
  { postalCode: '13531', city: 'TYRESÖ' },
  { postalCode: '13533', city: 'TYRESÖ' },
  { postalCode: '13534', city: 'TYRESÖ' },
  { postalCode: '13535', city: 'TYRESÖ' },
  { postalCode: '13536', city: 'TYRESÖ' },
  { postalCode: '13537', city: 'TYRESÖ' },
  { postalCode: '13538', city: 'TYRESÖ' },
  { postalCode: '13539', city: 'TYRESÖ' },
  { postalCode: '13540', city: 'TYRESÖ' },
  { postalCode: '13541', city: 'TYRESÖ' },
  { postalCode: '13542', city: 'TYRESÖ' },
  { postalCode: '13543', city: 'TYRESÖ' },
  { postalCode: '13544', city: 'TYRESÖ' },
  { postalCode: '13545', city: 'TYRESÖ' },
  { postalCode: '13546', city: 'TYRESÖ' },
  { postalCode: '13547', city: 'TYRESÖ' },
  { postalCode: '13548', city: 'TYRESÖ' },
  { postalCode: '13549', city: 'TYRESÖ' },
  { postalCode: '13550', city: 'TYRESÖ' },
  { postalCode: '13551', city: 'TYRESÖ' },
  { postalCode: '13552', city: 'TYRESÖ' },
  { postalCode: '13553', city: 'TYRESÖ' },
  { postalCode: '13554', city: 'TYRESÖ' },
  { postalCode: '13555', city: 'TYRESÖ' },
  { postalCode: '13558', city: 'TYRESÖ' },
  { postalCode: '13559', city: 'TYRESÖ' },
  { postalCode: '13560', city: 'TYRESÖ' },
  { postalCode: '13561', city: 'TYRESÖ' },
  { postalCode: '13562', city: 'TYRESÖ' },
  { postalCode: '13563', city: 'TYRESÖ' },
  { postalCode: '13567', city: 'TYRESÖ' },
  { postalCode: '13568', city: 'TYRESÖ' },
  { postalCode: '13569', city: 'TYRESÖ' },
  { postalCode: '13570', city: 'STOCKHOLM' },
  { postalCode: '13581', city: 'TYRESÖ' },
  { postalCode: '13582', city: 'TYRESÖ' },
  { postalCode: '13600', city: 'HANINGE' },
  { postalCode: '13602', city: 'HANINGE' },
  { postalCode: '13603', city: 'HANINGE' },
  { postalCode: '13612', city: 'HANINGE' },
  { postalCode: '13618', city: 'HANINGE' },
  { postalCode: '13620', city: 'HANINGE' },
  { postalCode: '13621', city: 'HANINGE' },
  { postalCode: '13622', city: 'HANINGE' },
  { postalCode: '13623', city: 'HANINGE' },
  { postalCode: '13625', city: 'HANINGE' },
  { postalCode: '13626', city: 'HANINGE' },
  { postalCode: '13635', city: 'HANDEN' },
  { postalCode: '13636', city: 'HANDEN' },
  { postalCode: '13637', city: 'HANDEN' },
  { postalCode: '13638', city: 'HANDEN' },
  { postalCode: '13639', city: 'HANDEN' },
  { postalCode: '13640', city: 'HANDEN' },
  { postalCode: '13641', city: 'HANDEN' },
  { postalCode: '13642', city: 'HANDEN' },
  { postalCode: '13643', city: 'HANDEN' },
  { postalCode: '13644', city: 'HANDEN' },
  { postalCode: '13645', city: 'HANDEN' },
  { postalCode: '13646', city: 'HANDEN' },
  { postalCode: '13647', city: 'HANINGE' },
  { postalCode: '13648', city: 'HANDEN' },
  { postalCode: '13649', city: 'VEGA' },
  { postalCode: '13650', city: 'JORDBRO' },
  { postalCode: '13655', city: 'VEGA' },
  { postalCode: '13656', city: 'VEGA' },
  { postalCode: '13657', city: 'VEGA' },
  { postalCode: '13658', city: 'VEGA' },
  { postalCode: '13659', city: 'VENDELSÖ' },
  { postalCode: '13660', city: 'BRANDBERGEN' },
  { postalCode: '13661', city: 'BRANDBERGEN' },
  { postalCode: '13662', city: 'BRANDBERGEN' },
  { postalCode: '13663', city: 'BRANDBERGEN' },
  { postalCode: '13664', city: 'HANINGE' },
  { postalCode: '13665', city: 'SÖDERBY' },
  { postalCode: '13666', city: 'VENDELSÖ' },
  { postalCode: '13667', city: 'VENDELSÖ' },
  { postalCode: '13668', city: 'VENDELSÖ' },
  { postalCode: '13669', city: 'VENDELSÖ' },
  { postalCode: '13670', city: 'VENDELSÖ' },
  { postalCode: '13671', city: 'VENDELSÖ' },
  { postalCode: '13672', city: 'VENDELSÖ' },
  { postalCode: '13673', city: 'VENDELSÖ' },
  { postalCode: '13674', city: 'NORRBY' },
  { postalCode: '13675', city: 'VENDELSÖ' },
  { postalCode: '13681', city: 'HANINGE' },
  { postalCode: '13683', city: 'HANINGE' },
  { postalCode: '13687', city: 'HANINGE' },
  { postalCode: '13689', city: 'HANINGE' },
  { postalCode: '13700', city: 'VÄSTERHANINGE' },
  { postalCode: '13701', city: 'VÄSTERHANINGE' },
  { postalCode: '13702', city: 'TUNGELSTA' },
  { postalCode: '13720', city: 'VÄSTERHANINGE' },
  { postalCode: '13721', city: 'VÄSTERHANINGE' },
  { postalCode: '13722', city: 'VÄSTERHANINGE' },
  { postalCode: '13723', city: 'VÄSTERHANINGE' },
  { postalCode: '13725', city: 'DALARÖ' },
  { postalCode: '13731', city: 'VÄSTERHANINGE' },
  { postalCode: '13732', city: 'VÄSTERHANINGE' },
  { postalCode: '13734', city: 'VÄSTERHANINGE' },
  { postalCode: '13736', city: 'VÄSTERHANINGE' },
  { postalCode: '13737', city: 'VÄSTERHANINGE' },
  { postalCode: '13738', city: 'VÄSTERHANINGE' },
  { postalCode: '13739', city: 'VÄSTERHANINGE' },
  { postalCode: '13740', city: 'VÄSTERHANINGE' },
  { postalCode: '13741', city: 'VÄSTERHANINGE' },
  { postalCode: '13742', city: 'VÄSTERHANINGE' },
  { postalCode: '13743', city: 'VÄSTERHANINGE' },
  { postalCode: '13754', city: 'TUNGELSTA' },
  { postalCode: '13755', city: 'TUNGELSTA' },
  { postalCode: '13756', city: 'TUNGELSTA' },
  { postalCode: '13757', city: 'TUNGELSTA' },
  { postalCode: '13758', city: 'TUNGELSTA' },
  { postalCode: '13761', city: 'JORDBRO' },
  { postalCode: '13762', city: 'JORDBRO' },
  { postalCode: '13763', city: 'JORDBRO' },
  { postalCode: '13764', city: 'JORDBRO' },
  { postalCode: '13765', city: 'JORDBRO' },
  { postalCode: '13766', city: 'JORDBRO' },
  { postalCode: '13767', city: 'JORDBRO' },
  { postalCode: '13768', city: 'JORDBRO' },
  { postalCode: '13769', city: 'ÖSTERHANINGE' },
  { postalCode: '13770', city: 'DALARÖ' },
  { postalCode: '13771', city: 'DALARÖ' },
  { postalCode: '13791', city: 'VÄSTERHANINGE' },
  { postalCode: '13792', city: 'TUNGELSTA' },
  { postalCode: '13793', city: 'TUNGELSTA' },
  { postalCode: '13794', city: 'NORRA SORUNDA' },
  { postalCode: '13795', city: 'ÖSTERHANINGE' },
  { postalCode: '13796', city: 'GÅLÖ' },
  { postalCode: '13797', city: 'ÅRSTA HAVSBAD' },
  { postalCode: '13821', city: 'ÄLTA' },
  { postalCode: '13822', city: 'ÄLTA' },
  { postalCode: '13830', city: 'ÄLTA' },
  { postalCode: '13831', city: 'ÄLTA' },
  { postalCode: '13832', city: 'ÄLTA' },
  { postalCode: '13833', city: 'ÄLTA' },
  { postalCode: '13834', city: 'ÄLTA' },
  { postalCode: '13835', city: 'ÄLTA' },
  { postalCode: '13836', city: 'ÄLTA' },
  { postalCode: '13837', city: 'ÄLTA' },
  { postalCode: '13839', city: 'ÄLTA' },
  { postalCode: '13840', city: 'ÄLTA' },
  { postalCode: '13901', city: 'VÄRMDÖ' },
  { postalCode: '13902', city: 'DJURHAMN' },
  { postalCode: '13903', city: 'STAVSNÄS' },
  { postalCode: '13921', city: 'VÄRMDÖ' },
  { postalCode: '13922', city: 'VÄRMDÖ' },
  { postalCode: '13923', city: 'VÄRMDÖ' },
  { postalCode: '13930', city: 'VÄRMDÖ' },
  { postalCode: '13931', city: 'VÄRMDÖ' },
  { postalCode: '13932', city: 'VÄRMDÖ' },
  { postalCode: '13933', city: 'VÄRMDÖ' },
  { postalCode: '13934', city: 'VÄRMDÖ' },
  { postalCode: '13935', city: 'VÄRMDÖ' },
  { postalCode: '13936', city: 'VÄRMDÖ' },
  { postalCode: '13940', city: 'VÄRMDÖ' },
  { postalCode: '13941', city: 'VÄRMDÖ' },
  { postalCode: '13950', city: 'VÄRMDÖ' },
  { postalCode: '13951', city: 'VÄRMDÖ' },
  { postalCode: '13952', city: 'VÄRMDÖ' },
  { postalCode: '13953', city: 'VÄRMDÖ' },
  { postalCode: '13954', city: 'VÄRMDÖ' },
  { postalCode: '13955', city: 'VÄRMDÖ' },
  { postalCode: '13956', city: 'VÄRMDÖ' },
  { postalCode: '13960', city: 'VÄRMDÖ' },
  { postalCode: '13970', city: 'STAVSNÄS' },
  { postalCode: '13971', city: 'STAVSNÄS' },
  { postalCode: '13972', city: 'DJURHAMN' },
  { postalCode: '13973', city: 'DJURHAMN' },
  { postalCode: '13974', city: 'DJURHAMN' },
  { postalCode: '13975', city: 'DJURHAMN' },
  { postalCode: '13990', city: 'VÄRMDÖ' },
  { postalCode: '14100', city: 'HUDDINGE' },
  { postalCode: '14102', city: 'HUDDINGE' },
  { postalCode: '14104', city: 'HUDDINGE' },
  { postalCode: '14105', city: 'KUNGENS KURVA' },
  { postalCode: '14106', city: 'KUNGENS KURVA' },
  { postalCode: '14107', city: 'KUNGENS KURVA' },
  { postalCode: '14108', city: 'KUNGENS KURVA' },
  { postalCode: '14109', city: 'HUDDINGE' },
  { postalCode: '14116', city: 'SEGELTORP' },
  { postalCode: '14117', city: 'SEGELTORP' },
  { postalCode: '14118', city: 'HUDDINGE' },
  { postalCode: '14119', city: 'SEGELTORP' },
  { postalCode: '14120', city: 'HUDDINGE' },
  { postalCode: '14121', city: 'HUDDINGE' },
  { postalCode: '14122', city: 'HUDDINGE' },
  { postalCode: '14123', city: 'HUDDINGE' },
  { postalCode: '14124', city: 'HUDDINGE' },
  { postalCode: '14125', city: 'HUDDINGE' },
  { postalCode: '14126', city: 'HUDDINGE' },
  { postalCode: '14127', city: 'HUDDINGE' },
  { postalCode: '14128', city: 'HUDDINGE' },
  { postalCode: '14130', city: 'HUDDINGE' },
  { postalCode: '14131', city: 'HUDDINGE' },
  { postalCode: '14132', city: 'HUDDINGE' },
  { postalCode: '14133', city: 'HUDDINGE' },
  { postalCode: '14134', city: 'HUDDINGE' },
  { postalCode: '14135', city: 'HUDDINGE' },
  { postalCode: '14137', city: 'HUDDINGE' },
  { postalCode: '14138', city: 'HUDDINGE' },
  { postalCode: '14139', city: 'HUDDINGE' },
  { postalCode: '14140', city: 'HUDDINGE' },
  { postalCode: '14141', city: 'HUDDINGE' },
  { postalCode: '14142', city: 'HUDDINGE' },
  { postalCode: '14143', city: 'HUDDINGE' },
  { postalCode: '14144', city: 'HUDDINGE' },
  { postalCode: '14145', city: 'HUDDINGE' },
  { postalCode: '14146', city: 'HUDDINGE' },
  { postalCode: '14147', city: 'HUDDINGE' },
  { postalCode: '14148', city: 'HUDDINGE' },
  { postalCode: '14149', city: 'HUDDINGE' },
  { postalCode: '14150', city: 'HUDDINGE' },
  { postalCode: '14151', city: 'HUDDINGE' },
  { postalCode: '14152', city: 'HUDDINGE' },
  { postalCode: '14153', city: 'HUDDINGE' },
  { postalCode: '14154', city: 'HUDDINGE' },
  { postalCode: '14155', city: 'HUDDINGE' },
  { postalCode: '14156', city: 'HUDDINGE' },
  { postalCode: '14157', city: 'HUDDINGE' },
  { postalCode: '14158', city: 'HUDDINGE' },
  { postalCode: '14159', city: 'HUDDINGE' },
  { postalCode: '14160', city: 'HUDDINGE' },
  { postalCode: '14161', city: 'HUDDINGE' },
  { postalCode: '14162', city: 'HUDDINGE' },
  { postalCode: '14163', city: 'HUDDINGE' },
  { postalCode: '14168', city: 'HUDDINGE' },
  { postalCode: '14169', city: 'HUDDINGE' },
  { postalCode: '14170', city: 'SEGELTORP' },
  { postalCode: '14171', city: 'SEGELTORP' },
  { postalCode: '14172', city: 'SEGELTORP' },
  { postalCode: '14173', city: 'SEGELTORP' },
  { postalCode: '14174', city: 'SEGELTORP' },
  { postalCode: '14175', city: 'KUNGENS KURVA' },
  { postalCode: '14181', city: 'HUDDINGE' },
  { postalCode: '14182', city: 'STOCKHOLM' },
  { postalCode: '14183', city: 'HUDDINGE' },
  { postalCode: '14184', city: 'HUDDINGE' },
  { postalCode: '14185', city: 'HUDDINGE' },
  { postalCode: '14186', city: 'STOCKHOLM' },
  { postalCode: '14187', city: 'HUDDINGE' },
  { postalCode: '14188', city: 'HUDDINGE' },
  { postalCode: '14189', city: 'HUDDINGE' },
  { postalCode: '14191', city: 'HUDDINGE' },
  { postalCode: '14192', city: 'HUDDINGE' },
  { postalCode: '14199', city: 'STOCKHOLM' },
  { postalCode: '14200', city: 'SKOGÅS' },
  { postalCode: '14202', city: 'TRÅNGSUND' },
  { postalCode: '14220', city: 'SKOGÅS' },
  { postalCode: '14221', city: 'SKOGÅS' },
  { postalCode: '14222', city: 'SKOGÅS' },
  { postalCode: '14230', city: 'SKOGÅS' },
  { postalCode: '14231', city: 'SKOGÅS' },
  { postalCode: '14232', city: 'SKOGÅS' },
  { postalCode: '14233', city: 'SKOGÅS' },
  { postalCode: '14234', city: 'SKOGÅS' },
  { postalCode: '14235', city: 'SKOGÅS' },
  { postalCode: '14240', city: 'SKOGÅS' },
  { postalCode: '14241', city: 'SKOGÅS' },
  { postalCode: '14242', city: 'SKOGÅS' },
  { postalCode: '14243', city: 'SKOGÅS' },
  { postalCode: '14250', city: 'SKOGÅS' },
  { postalCode: '14251', city: 'SKOGÅS' },
  { postalCode: '14252', city: 'SKOGÅS' },
  { postalCode: '14253', city: 'SKOGÅS' },
  { postalCode: '14260', city: 'TRÅNGSUND' },
  { postalCode: '14261', city: 'TRÅNGSUND' },
  { postalCode: '14262', city: 'TRÅNGSUND' },
  { postalCode: '14263', city: 'TRÅNGSUND' },
  { postalCode: '14264', city: 'TRÅNGSUND' },
  { postalCode: '14265', city: 'TRÅNGSUND' },
  { postalCode: '14266', city: 'TRÅNGSUND' },
  { postalCode: '14291', city: 'SKOGÅS' },
  { postalCode: '14300', city: 'VÅRBY' },
  { postalCode: '14301', city: 'VÅRBY' },
  { postalCode: '14303', city: 'VÅRBY' },
  { postalCode: '14330', city: 'VÅRBY' },
  { postalCode: '14331', city: 'VÅRBY' },
  { postalCode: '14332', city: 'VÅRBY' },
  { postalCode: '14340', city: 'VÅRBY' },
  { postalCode: '14341', city: 'VÅRBY' },
  { postalCode: '14342', city: 'VÅRBY' },
  { postalCode: '14343', city: 'VÅRBY' },
  { postalCode: '14344', city: 'VÅRBY' },
  { postalCode: '14345', city: 'VÅRBY' },
  { postalCode: '14346', city: 'VÅRBY' },
  { postalCode: '14347', city: 'VÅRBY' },
  { postalCode: '14400', city: 'RÖNNINGE' },
  { postalCode: '14402', city: 'RÖNNINGE' },
  { postalCode: '14420', city: 'RÖNNINGE' },
  { postalCode: '14421', city: 'RÖNNINGE' },
  { postalCode: '14430', city: 'RÖNNINGE' },
  { postalCode: '14431', city: 'RÖNNINGE' },
  { postalCode: '14432', city: 'RÖNNINGE' },
  { postalCode: '14433', city: 'RÖNNINGE' },
  { postalCode: '14437', city: 'RÖNNINGE' },
  { postalCode: '14440', city: 'RÖNNINGE' },
  { postalCode: '14442', city: 'RÖNNINGE' },
  { postalCode: '14444', city: 'RÖNNINGE' },
  { postalCode: '14451', city: 'RÖNNINGE' },
  { postalCode: '14452', city: 'RÖNNINGE' },
  { postalCode: '14461', city: 'RÖNNINGE' },
  { postalCode: '14462', city: 'RÖNNINGE' },
  { postalCode: '14463', city: 'RÖNNINGE' },
  { postalCode: '14464', city: 'RÖNNINGE' },
  { postalCode: '14480', city: 'RÖNNINGE' },
  { postalCode: '14500', city: 'NORSBORG' },
  { postalCode: '14501', city: 'NORSBORG' },
  { postalCode: '14502', city: 'NORSBORG' },
  { postalCode: '14503', city: 'NORSBORG' },
  { postalCode: '14504', city: 'NORSBORG' },
  { postalCode: '14505', city: 'NORSBORG' },
  { postalCode: '14520', city: 'NORSBORG' },
  { postalCode: '14550', city: 'NORSBORG' },
  { postalCode: '14551', city: 'NORSBORG' },
  { postalCode: '14552', city: 'NORSBORG' },
  { postalCode: '14553', city: 'NORSBORG' },
  { postalCode: '14556', city: 'NORSBORG' },
  { postalCode: '14557', city: 'NORSBORG' },
  { postalCode: '14558', city: 'NORSBORG' },
  { postalCode: '14559', city: 'NORSBORG' },
  { postalCode: '14560', city: 'NORSBORG' },
  { postalCode: '14563', city: 'NORSBORG' },
  { postalCode: '14564', city: 'NORSBORG' },
  { postalCode: '14565', city: 'NORSBORG' },
  { postalCode: '14567', city: 'NORSBORG' },
  { postalCode: '14568', city: 'NORSBORG' },
  { postalCode: '14569', city: 'NORSBORG' },
  { postalCode: '14570', city: 'NORSBORG' },
  { postalCode: '14571', city: 'NORSBORG' },
  { postalCode: '14572', city: 'NORSBORG' },
  { postalCode: '14573', city: 'NORSBORG' },
  { postalCode: '14574', city: 'NORSBORG' },
  { postalCode: '14575', city: 'NORSBORG' },
  { postalCode: '14576', city: 'NORSBORG' },
  { postalCode: '14581', city: 'NORSBORG' },
  { postalCode: '14583', city: 'NORSBORG' },
  { postalCode: '14584', city: 'STOCKHOLM' },
  { postalCode: '14588', city: 'NORSBORG' },
  { postalCode: '14590', city: 'NORSBORG' },
  { postalCode: '14600', city: 'TULLINGE' },
  { postalCode: '14620', city: 'TULLINGE' },
  { postalCode: '14621', city: 'TULLINGE' },
  { postalCode: '14622', city: 'TULLINGE' },
  { postalCode: '14630', city: 'TULLINGE' },
  { postalCode: '14631', city: 'TULLINGE' },
  { postalCode: '14632', city: 'TULLINGE' },
  { postalCode: '14633', city: 'TULLINGE' },
  { postalCode: '14634', city: 'TULLINGE' },
  { postalCode: '14636', city: 'TULLINGE' },
  { postalCode: '14637', city: 'TULLINGE' },
  { postalCode: '14638', city: 'TULLINGE' },
  { postalCode: '14640', city: 'TULLINGE' },
  { postalCode: '14641', city: 'TULLINGE' },
  { postalCode: '14645', city: 'TULLINGE' },
  { postalCode: '14646', city: 'TULLINGE' },
  { postalCode: '14648', city: 'TULLINGE' },
  { postalCode: '14649', city: 'TULLINGE' },
  { postalCode: '14650', city: 'TULLINGE' },
  { postalCode: '14652', city: 'TULLINGE' },
  { postalCode: '14653', city: 'TULLINGE' },
  { postalCode: '14654', city: 'TULLINGE' },
  { postalCode: '14680', city: 'TULLINGE' },
  { postalCode: '14700', city: 'TUMBA' },
  { postalCode: '14701', city: 'TUMBA' },
  { postalCode: '14707', city: 'GRÖDINGE' },
  { postalCode: '14720', city: 'TUMBA' },
  { postalCode: '14721', city: 'TUMBA' },
  { postalCode: '14722', city: 'TUMBA' },
  { postalCode: '14723', city: 'TUMBA' },
  { postalCode: '14730', city: 'TUMBA' },
  { postalCode: '14731', city: 'TUMBA' },
  { postalCode: '14732', city: 'TUMBA' },
  { postalCode: '14733', city: 'TUMBA' },
  { postalCode: '14734', city: 'TUMBA' },
  { postalCode: '14739', city: 'TUMBA' },
  { postalCode: '14740', city: 'TUMBA' },
  { postalCode: '14741', city: 'TUMBA' },
  { postalCode: '14742', city: 'TUMBA' },
  { postalCode: '14743', city: 'TUMBA' },
  { postalCode: '14744', city: 'TUMBA' },
  { postalCode: '14745', city: 'TUMBA' },
  { postalCode: '14746', city: 'TUMBA' },
  { postalCode: '14750', city: 'TUMBA' },
  { postalCode: '14751', city: 'TUMBA' },
  { postalCode: '14752', city: 'TUMBA' },
  { postalCode: '14753', city: 'TUMBA' },
  { postalCode: '14754', city: 'TUMBA' },
  { postalCode: '14755', city: 'TUMBA' },
  { postalCode: '14760', city: 'UTTRAN' },
  { postalCode: '14761', city: 'UTTRAN' },
  { postalCode: '14762', city: 'UTTRAN' },
  { postalCode: '14763', city: 'UTTRAN' },
  { postalCode: '14770', city: 'GRÖDINGE' },
  { postalCode: '14771', city: 'GRÖDINGE' },
  { postalCode: '14780', city: 'TUMBA' },
  { postalCode: '14782', city: 'TUMBA' },
  { postalCode: '14785', city: 'TUMBA' },
  { postalCode: '14791', city: 'GRÖDINGE' },
  { postalCode: '14792', city: 'GRÖDINGE' },
  { postalCode: '14800', city: 'ÖSMO' },
  { postalCode: '14802', city: 'SORUNDA' },
  { postalCode: '14821', city: 'ÖSMO' },
  { postalCode: '14822', city: 'ÖSMO' },
  { postalCode: '14830', city: 'ÖSMO' },
  { postalCode: '14831', city: 'ÖSMO' },
  { postalCode: '14832', city: 'ÖSMO' },
  { postalCode: '14833', city: 'ÖSMO' },
  { postalCode: '14834', city: 'ÖSMO' },
  { postalCode: '14835', city: 'ÖSMO' },
  { postalCode: '14840', city: 'SEGERSÄNG' },
  { postalCode: '14860', city: 'STORA VIKA' },
  { postalCode: '14870', city: 'SORUNDA' },
  { postalCode: '14871', city: 'SORUNDA' },
  { postalCode: '14880', city: 'MUSKÖ' },
  { postalCode: '14891', city: 'ÖSMO' },
  { postalCode: '14892', city: 'ÖSMO' },
  { postalCode: '14893', city: 'LANDFJÄRDEN' },
  { postalCode: '14895', city: 'MUSKÖ' },
  { postalCode: '14896', city: 'SORUNDA' },
  { postalCode: '14897', city: 'SORUNDA' },
  { postalCode: '14900', city: 'NYNÄSHAMN' },
  { postalCode: '14920', city: 'NYNÄSHAMN' },
  { postalCode: '14921', city: 'NYNÄSHAMN' },
  { postalCode: '14922', city: 'NYNÄSHAMN' },
  { postalCode: '14923', city: 'NYNÄSHAMN' },
  { postalCode: '14925', city: 'NYNÄSHAMN' },
  { postalCode: '14930', city: 'NYNÄSHAMN' },
  { postalCode: '14931', city: 'NYNÄSHAMN' },
  { postalCode: '14932', city: 'NYNÄSHAMN' },
  { postalCode: '14933', city: 'NYNÄSHAMN' },
  { postalCode: '14934', city: 'NYNÄSHAMN' },
  { postalCode: '14935', city: 'NYNÄSHAMN' },
  { postalCode: '14940', city: 'NYNÄSHAMN' },
  { postalCode: '14941', city: 'NYNÄSHAMN' },
  { postalCode: '14943', city: 'NYNÄSHAMN' },
  { postalCode: '14945', city: 'NYNÄSHAMN' },
  { postalCode: '14950', city: 'NYNÄSHAMN' },
  { postalCode: '14951', city: 'NYNÄSHAMN' },
  { postalCode: '14980', city: 'NYNÄSHAMN' },
  { postalCode: '14981', city: 'NYNÄSHAMN' },
  { postalCode: '14982', city: 'NYNÄSHAMN' },
  { postalCode: '14991', city: 'NYNÄSHAMN' },
  { postalCode: '14992', city: 'NYNÄSHAMN' },
  { postalCode: '14995', city: 'NYNÄSHAMN' },
  { postalCode: '15100', city: 'SÖDERTÄLJE' },
  { postalCode: '15101', city: 'SÖDERTÄLJE' },
  { postalCode: '15102', city: 'SÖDERTÄLJE' },
  { postalCode: '15103', city: 'SÖDERTÄLJE' },
  { postalCode: '15105', city: 'SÖDERTÄLJE' },
  { postalCode: '15107', city: 'SÖDERTÄLJE' },
  { postalCode: '15108', city: 'SÖDERTÄLJE' },
  { postalCode: '15113', city: 'SÖDERTÄLJE' },
  { postalCode: '15120', city: 'SÖDERTÄLJE' },
  { postalCode: '15121', city: 'SÖDERTÄLJE' },
  { postalCode: '15122', city: 'SÖDERTÄLJE' },
  { postalCode: '15123', city: 'SÖDERTÄLJE' },
  { postalCode: '15124', city: 'SÖDERTÄLJE' },
  { postalCode: '15127', city: 'SÖDERTÄLJE' },
  { postalCode: '15128', city: 'ENHÖRNA' },
  { postalCode: '15129', city: 'SÖDERTÄLJE' },
  { postalCode: '15132', city: 'SÖDERTÄLJE' },
  { postalCode: '15133', city: 'SÖDERTÄLJE' },
  { postalCode: '15134', city: 'SÖDERTÄLJE' },
  { postalCode: '15135', city: 'SÖDERTÄLJE' },
  { postalCode: '15136', city: 'SÖDERTÄLJE' },
  { postalCode: '15137', city: 'SÖDERTÄLJE' },
  { postalCode: '15138', city: 'SÖDERTÄLJE' },
  { postalCode: '15139', city: 'SÖDERTÄLJE' },
  { postalCode: '15140', city: 'SÖDERTÄLJE' },
  { postalCode: '15144', city: 'SÖDERTÄLJE' },
  { postalCode: '15145', city: 'SÖDERTÄLJE' },
  { postalCode: '15146', city: 'SÖDERTÄLJE' },
  { postalCode: '15147', city: 'SÖDERTÄLJE' },
  { postalCode: '15148', city: 'SÖDERTÄLJE' },
  { postalCode: '15150', city: 'ENHÖRNA' },
  { postalCode: '15151', city: 'SÖDERTÄLJE' },
  { postalCode: '15152', city: 'SÖDERTÄLJE' },
  { postalCode: '15153', city: 'SÖDERTÄLJE' },
  { postalCode: '15154', city: 'SÖDERTÄLJE' },
  { postalCode: '15155', city: 'SÖDERTÄLJE' },
  { postalCode: '15156', city: 'SÖDERTÄLJE' },
  { postalCode: '15157', city: 'SÖDERTÄLJE' },
  { postalCode: '15159', city: 'SÖDERTÄLJE' },
  { postalCode: '15160', city: 'SÖDERTÄLJE' },
  { postalCode: '15161', city: 'SÖDERTÄLJE' },
  { postalCode: '15162', city: 'SÖDERTÄLJE' },
  { postalCode: '15163', city: 'SÖDERTÄLJE' },
  { postalCode: '15164', city: 'SÖDERTÄLJE' },
  { postalCode: '15165', city: 'SÖDERTÄLJE' },
  { postalCode: '15166', city: 'SÖDERTÄLJE' },
  { postalCode: '15167', city: 'SÖDERTÄLJE' },
  { postalCode: '15168', city: 'SÖDERTÄLJE' },
  { postalCode: '15171', city: 'SÖDERTÄLJE' },
  { postalCode: '15172', city: 'SÖDERTÄLJE' },
  { postalCode: '15173', city: 'SÖDERTÄLJE' },
  { postalCode: '15181', city: 'SÖDERTÄLJE' },
  { postalCode: '15182', city: 'SÖDERTÄLJE' },
  { postalCode: '15183', city: 'SÖDERTÄLJE' },
  { postalCode: '15185', city: 'SÖDERTÄLJE' },
  { postalCode: '15187', city: 'SÖDERTÄLJE' },
  { postalCode: '15188', city: 'SÖDERTÄLJE' },
  { postalCode: '15189', city: 'SÖDERTÄLJE' },
  { postalCode: '15196', city: 'ENHÖRNA' },
  { postalCode: '15200', city: 'SÖDERTÄLJE' },
  { postalCode: '15220', city: 'SÖDERTÄLJE' },
  { postalCode: '15225', city: 'SÖDERTÄLJE' },
  { postalCode: '15226', city: 'SÖDERTÄLJE' },
  { postalCode: '15227', city: 'SÖDERTÄLJE' },
  { postalCode: '15228', city: 'SÖDERTÄLJE' },
  { postalCode: '15230', city: 'SÖDERTÄLJE' },
  { postalCode: '15231', city: 'SÖDERTÄLJE' },
  { postalCode: '15240', city: 'SÖDERTÄLJE' },
  { postalCode: '15241', city: 'SÖDERTÄLJE' },
  { postalCode: '15242', city: 'SÖDERTÄLJE' },
  { postalCode: '15243', city: 'SÖDERTÄLJE' },
  { postalCode: '15244', city: 'SÖDERTÄLJE' },
  { postalCode: '15248', city: 'SÖDERTÄLJE' },
  { postalCode: '15249', city: 'SÖDERTÄLJE' },
  { postalCode: '15250', city: 'SÖDERTÄLJE' },
  { postalCode: '15251', city: 'SÖDERTÄLJE' },
  { postalCode: '15252', city: 'SÖDERTÄLJE' },
  { postalCode: '15254', city: 'SÖDERTÄLJE' },
  { postalCode: '15256', city: 'SÖDERTÄLJE' },
  { postalCode: '15257', city: 'SÖDERTÄLJE' },
  { postalCode: '15258', city: 'SÖDERTÄLJE' },
  { postalCode: '15259', city: 'SÖDERTÄLJE' },
  { postalCode: '15270', city: 'SÖDERTÄLJE' },
  { postalCode: '15271', city: 'SÖDERTÄLJE' },
  { postalCode: '15286', city: 'SÖDERTÄLJE' },
  { postalCode: '15295', city: 'SÖDERTÄLJE' },
  { postalCode: '15297', city: 'SÖDERTÄLJE' },
  { postalCode: '15300', city: 'JÄRNA' },
  { postalCode: '15307', city: 'HÖLÖ' },
  { postalCode: '15320', city: 'JÄRNA' },
  { postalCode: '15321', city: 'JÄRNA' },
  { postalCode: '15322', city: 'JÄRNA' },
  { postalCode: '15330', city: 'JÄRNA' },
  { postalCode: '15331', city: 'JÄRNA' },
  { postalCode: '15332', city: 'JÄRNA' },
  { postalCode: '15335', city: 'JÄRNA' },
  { postalCode: '15336', city: 'JÄRNA' },
  { postalCode: '15337', city: 'JÄRNA' },
  { postalCode: '15338', city: 'JÄRNA' },
  { postalCode: '15360', city: 'MÖLNBO' },
  { postalCode: '15371', city: 'HÖLÖ' },
  { postalCode: '15381', city: 'JÄRNA' },
  { postalCode: '15391', city: 'JÄRNA' },
  { postalCode: '15392', city: 'HÖLÖ' },
  { postalCode: '15393', city: 'MÖRKÖ' },
  { postalCode: '15394', city: 'HÖLÖ' },
  { postalCode: '15395', city: 'JÄRNA' },
  { postalCode: '15396', city: 'MÖLNBO' },
  { postalCode: '15521', city: 'NYKVARN' },
  { postalCode: '15530', city: 'NYKVARN' },
  { postalCode: '15531', city: 'NYKVARN' },
  { postalCode: '15532', city: 'NYKVARN' },
  { postalCode: '15533', city: 'NYKVARN' },
  { postalCode: '15534', city: 'NYKVARN' },
  { postalCode: '15535', city: 'NYKVARN' },
  { postalCode: '15580', city: 'NYKVARN' },
  { postalCode: '15581', city: 'NYKVARN' },
  { postalCode: '15591', city: 'NYKVARN' },
  { postalCode: '15592', city: 'NYKVARN' },
  { postalCode: '15593', city: 'NYKVARN' },
  { postalCode: '15594', city: 'NYKVARN' },
  { postalCode: '16100', city: 'BROMMA' },
  { postalCode: '16102', city: 'BROMMA' },
  { postalCode: '16103', city: 'BROMMA' },
  { postalCode: '16105', city: 'BROMMA' },
  { postalCode: '16111', city: 'BROMMA' },
  { postalCode: '16124', city: 'BROMMA' },
  { postalCode: '16125', city: 'BROMMA' },
  { postalCode: '16126', city: 'BROMMA' },
  { postalCode: '16186', city: 'STOCKHOLM' },
  { postalCode: '16189', city: 'STOCKHOLM' },
  { postalCode: '16200', city: 'VÄLLINGBY' },
  { postalCode: '16206', city: 'VÄLLINGBY' },
  { postalCode: '16211', city: 'VÄLLINGBY' },
  { postalCode: '16212', city: 'VÄLLINGBY' },
  { postalCode: '16213', city: 'VÄLLINGBY' },
  { postalCode: '16215', city: 'VÄLLINGBY' },
  { postalCode: '16216', city: 'STOCKHOLM' },
  { postalCode: '16220', city: 'VÄLLINGBY' },
  { postalCode: '16243', city: 'VÄLLINGBY' },
  { postalCode: '16244', city: 'VÄLLINGBY' },
  { postalCode: '16245', city: 'VÄLLINGBY' },
  { postalCode: '16246', city: 'VÄLLINGBY' },
  { postalCode: '16247', city: 'VÄLLINGBY' },
  { postalCode: '16250', city: 'VÄLLINGBY' },
  { postalCode: '16252', city: 'VÄLLINGBY' },
  { postalCode: '16253', city: 'VÄLLINGBY' },
  { postalCode: '16254', city: 'VÄLLINGBY' },
  { postalCode: '16255', city: 'VÄLLINGBY' },
  { postalCode: '16256', city: 'VÄLLINGBY' },
  { postalCode: '16257', city: 'VÄLLINGBY' },
  { postalCode: '16258', city: 'VÄLLINGBY' },
  { postalCode: '16260', city: 'VÄLLINGBY' },
  { postalCode: '16261', city: 'VÄLLINGBY' },
  { postalCode: '16262', city: 'VÄLLINGBY' },
  { postalCode: '16263', city: 'VÄLLINGBY' },
  { postalCode: '16264', city: 'VÄLLINGBY' },
  { postalCode: '16265', city: 'VÄLLINGBY' },
  { postalCode: '16266', city: 'VÄLLINGBY' },
  { postalCode: '16267', city: 'VÄLLINGBY' },
  { postalCode: '16268', city: 'VÄLLINGBY' },
  { postalCode: '16270', city: 'VÄLLINGBY' },
  { postalCode: '16271', city: 'VÄLLINGBY' },
  { postalCode: '16272', city: 'VÄLLINGBY' },
  { postalCode: '16273', city: 'VÄLLINGBY' },
  { postalCode: '16274', city: 'VÄLLINGBY' },
  { postalCode: '16283', city: 'VÄLLINGBY' },
  { postalCode: '16287', city: 'STOCKHOLM' },
  { postalCode: '16288', city: 'VÄLLINGBY' },
  { postalCode: '16289', city: 'STOCKHOLM' },
  { postalCode: '16300', city: 'SPÅNGA' },
  { postalCode: '16303', city: 'SPÅNGA' },
  { postalCode: '16304', city: 'SPÅNGA' },
  { postalCode: '16305', city: 'SPÅNGA' },
  { postalCode: '16308', city: 'SPÅNGA' },
  { postalCode: '16310', city: 'SPÅNGA' },
  { postalCode: '16319', city: 'SPÅNGA' },
  { postalCode: '16320', city: 'SPÅNGA' },
  { postalCode: '16340', city: 'SPÅNGA' },
  { postalCode: '16341', city: 'SPÅNGA' },
  { postalCode: '16342', city: 'SPÅNGA' },
  { postalCode: '16343', city: 'SPÅNGA' },
  { postalCode: '16344', city: 'SPÅNGA' },
  { postalCode: '16345', city: 'SPÅNGA' },
  { postalCode: '16346', city: 'SPÅNGA' },
  { postalCode: '16347', city: 'SPÅNGA' },
  { postalCode: '16350', city: 'SPÅNGA' },
  { postalCode: '16351', city: 'SPÅNGA' },
  { postalCode: '16352', city: 'SPÅNGA' },
  { postalCode: '16353', city: 'SPÅNGA' },
  { postalCode: '16354', city: 'SPÅNGA' },
  { postalCode: '16355', city: 'SPÅNGA' },
  { postalCode: '16356', city: 'SPÅNGA' },
  { postalCode: '16357', city: 'SPÅNGA' },
  { postalCode: '16361', city: 'SPÅNGA' },
  { postalCode: '16362', city: 'SPÅNGA' },
  { postalCode: '16363', city: 'SPÅNGA' },
  { postalCode: '16364', city: 'SPÅNGA' },
  { postalCode: '16365', city: 'SPÅNGA' },
  { postalCode: '16366', city: 'SPÅNGA' },
  { postalCode: '16367', city: 'SPÅNGA' },
  { postalCode: '16368', city: 'SPÅNGA' },
  { postalCode: '16370', city: 'SPÅNGA' },
  { postalCode: '16371', city: 'SPÅNGA' },
  { postalCode: '16372', city: 'SPÅNGA' },
  { postalCode: '16373', city: 'SPÅNGA' },
  { postalCode: '16374', city: 'SPÅNGA' },
  { postalCode: '16375', city: 'SPÅNGA' },
  { postalCode: '16376', city: 'SPÅNGA' },
  { postalCode: '16377', city: 'SPÅNGA' },
  { postalCode: '16388', city: 'SPÅNGA' },
  { postalCode: '16389', city: 'SPÅNGA' },
  { postalCode: '16391', city: 'SPÅNGA' },
  { postalCode: '16394', city: 'STOCKHOLM' },
  { postalCode: '16400', city: 'KISTA' },
  { postalCode: '16406', city: 'KISTA' },
  { postalCode: '16407', city: 'KISTA' },
  { postalCode: '16419', city: 'KISTA' },
  { postalCode: '16420', city: 'KISTA' },
  { postalCode: '16421', city: 'KISTA' },
  { postalCode: '16422', city: 'KISTA' },
  { postalCode: '16425', city: 'KISTA' },
  { postalCode: '16426', city: 'KISTA' },
  { postalCode: '16428', city: 'KISTA' },
  { postalCode: '16429', city: 'KISTA' },
  { postalCode: '16430', city: 'KISTA' },
  { postalCode: '16431', city: 'KISTA' },
  { postalCode: '16432', city: 'KISTA' },
  { postalCode: '16433', city: 'KISTA' },
  { postalCode: '16434', city: 'KISTA' },
  { postalCode: '16435', city: 'KISTA' },
  { postalCode: '16436', city: 'KISTA' },
  { postalCode: '16437', city: 'KISTA' },
  { postalCode: '16438', city: 'KISTA' },
  { postalCode: '16439', city: 'KISTA' },
  { postalCode: '16440', city: 'KISTA' },
  { postalCode: '16441', city: 'KISTA' },
  { postalCode: '16442', city: 'KISTA' },
  { postalCode: '16443', city: 'KISTA' },
  { postalCode: '16444', city: 'KISTA' },
  { postalCode: '16445', city: 'KISTA' },
  { postalCode: '16446', city: 'KISTA' },
  { postalCode: '16447', city: 'KISTA' },
  { postalCode: '16448', city: 'KISTA' },
  { postalCode: '16451', city: 'KISTA' },
  { postalCode: '16452', city: 'KISTA' },
  { postalCode: '16453', city: 'KISTA' },
  { postalCode: '16455', city: 'KISTA' },
  { postalCode: '16471', city: 'KISTA' },
  { postalCode: '16472', city: 'KISTA' },
  { postalCode: '16473', city: 'KISTA' },
  { postalCode: '16474', city: 'KISTA' },
  { postalCode: '16475', city: 'KISTA' },
  { postalCode: '16476', city: 'KISTA' },
  { postalCode: '16477', city: 'KISTA' },
  { postalCode: '16478', city: 'KISTA' },
  { postalCode: '16479', city: 'KISTA' },
  { postalCode: '16480', city: 'STOCKHOLM' },
  { postalCode: '16481', city: 'KISTA' },
  { postalCode: '16482', city: 'STOCKHOLM' },
  { postalCode: '16483', city: 'STOCKHOLM' },
  { postalCode: '16484', city: 'STOCKHOLM' },
  { postalCode: '16485', city: 'STOCKHOLM' },
  { postalCode: '16486', city: 'STOCKHOLM' },
  { postalCode: '16487', city: 'KISTA' },
  { postalCode: '16488', city: 'KISTA' },
  { postalCode: '16489', city: 'STOCKHOLM' },
  { postalCode: '16490', city: 'STOCKHOLM' },
  { postalCode: '16491', city: 'KISTA' },
  { postalCode: '16492', city: 'STOCKHOLM' },
  { postalCode: '16493', city: 'KISTA' },
  { postalCode: '16494', city: 'KISTA' },
  { postalCode: '16495', city: 'KISTA' },
  { postalCode: '16496', city: 'KISTA' },
  { postalCode: '16497', city: 'KISTA' },
  { postalCode: '16498', city: 'STOCKHOLM' },
  { postalCode: '16499', city: 'KISTA' },
  { postalCode: '16500', city: 'HÄSSELBY' },
  { postalCode: '16510', city: 'HÄSSELBY' },
  { postalCode: '16511', city: 'HÄSSELBY' },
  { postalCode: '16512', city: 'HÄSSELBY' },
  { postalCode: '16513', city: 'HÄSSELBY' },
  { postalCode: '16515', city: 'HÄSSELBY' },
  { postalCode: '16516', city: 'HÄSSELBY' },
  { postalCode: '16520', city: 'HÄSSELBY' },
  { postalCode: '16521', city: 'HÄSSELBY' },
  { postalCode: '16522', city: 'HÄSSELBY' },
  { postalCode: '16523', city: 'HÄSSELBY' },
  { postalCode: '16524', city: 'HÄSSELBY' },
  { postalCode: '16552', city: 'HÄSSELBY' },
  { postalCode: '16553', city: 'HÄSSELBY' },
  { postalCode: '16554', city: 'HÄSSELBY' },
  { postalCode: '16555', city: 'HÄSSELBY' },
  { postalCode: '16556', city: 'HÄSSELBY' },
  { postalCode: '16557', city: 'HÄSSELBY' },
  { postalCode: '16558', city: 'HÄSSELBY' },
  { postalCode: '16559', city: 'HÄSSELBY' },
  { postalCode: '16560', city: 'HÄSSELBY' },
  { postalCode: '16561', city: 'HÄSSELBY' },
  { postalCode: '16562', city: 'HÄSSELBY' },
  { postalCode: '16563', city: 'HÄSSELBY' },
  { postalCode: '16564', city: 'HÄSSELBY' },
  { postalCode: '16565', city: 'HÄSSELBY' },
  { postalCode: '16566', city: 'HÄSSELBY' },
  { postalCode: '16570', city: 'HÄSSELBY' },
  { postalCode: '16571', city: 'HÄSSELBY' },
  { postalCode: '16572', city: 'HÄSSELBY' },
  { postalCode: '16573', city: 'HÄSSELBY' },
  { postalCode: '16574', city: 'HÄSSELBY' },
  { postalCode: '16575', city: 'HÄSSELBY' },
  { postalCode: '16576', city: 'HÄSSELBY' },
  { postalCode: '16577', city: 'HÄSSELBY' },
  { postalCode: '16578', city: 'HÄSSELBY' },
  { postalCode: '16700', city: 'BROMMA' },
  { postalCode: '16714', city: 'BROMMA' },
  { postalCode: '16715', city: 'BROMMA' },
  { postalCode: '16717', city: 'BROMMA' },
  { postalCode: '16719', city: 'BROMMA' },
  { postalCode: '16720', city: 'BROMMA' },
  { postalCode: '16731', city: 'BROMMA' },
  { postalCode: '16732', city: 'BROMMA' },
  { postalCode: '16733', city: 'BROMMA' },
  { postalCode: '16735', city: 'BROMMA' },
  { postalCode: '16736', city: 'BROMMA' },
  { postalCode: '16737', city: 'BROMMA' },
  { postalCode: '16738', city: 'BROMMA' },
  { postalCode: '16739', city: 'BROMMA' },
  { postalCode: '16740', city: 'BROMMA' },
  { postalCode: '16741', city: 'BROMMA' },
  { postalCode: '16743', city: 'BROMMA' },
  { postalCode: '16744', city: 'BROMMA' },
  { postalCode: '16745', city: 'BROMMA' },
  { postalCode: '16751', city: 'BROMMA' },
  { postalCode: '16752', city: 'BROMMA' },
  { postalCode: '16753', city: 'BROMMA' },
  { postalCode: '16754', city: 'BROMMA' },
  { postalCode: '16755', city: 'BROMMA' },
  { postalCode: '16756', city: 'BROMMA' },
  { postalCode: '16757', city: 'BROMMA' },
  { postalCode: '16758', city: 'BROMMA' },
  { postalCode: '16761', city: 'BROMMA' },
  { postalCode: '16762', city: 'BROMMA' },
  { postalCode: '16763', city: 'BROMMA' },
  { postalCode: '16764', city: 'BROMMA' },
  { postalCode: '16765', city: 'BROMMA' },
  { postalCode: '16766', city: 'BROMMA' },
  { postalCode: '16767', city: 'BROMMA' },
  { postalCode: '16771', city: 'BROMMA' },
  { postalCode: '16772', city: 'BROMMA' },
  { postalCode: '16773', city: 'BROMMA' },
  { postalCode: '16774', city: 'BROMMA' },
  { postalCode: '16775', city: 'BROMMA' },
  { postalCode: '16776', city: 'BROMMA' },
  { postalCode: '16780', city: 'BROMMA' },
  { postalCode: '16781', city: 'BROMMA' },
  { postalCode: '16800', city: 'BROMMA' },
  { postalCode: '16820', city: 'BROMMA' },
  { postalCode: '16827', city: 'BROMMA' },
  { postalCode: '16830', city: 'BROMMA' },
  { postalCode: '16831', city: 'BROMMA' },
  { postalCode: '16832', city: 'BROMMA' },
  { postalCode: '16833', city: 'BROMMA' },
  { postalCode: '16834', city: 'BROMMA' },
  { postalCode: '16835', city: 'BROMMA' },
  { postalCode: '16836', city: 'BROMMA' },
  { postalCode: '16837', city: 'BROMMA' },
  { postalCode: '16838', city: 'BROMMA' },
  { postalCode: '16839', city: 'BROMMA' },
  { postalCode: '16840', city: 'BROMMA' },
  { postalCode: '16841', city: 'BROMMA' },
  { postalCode: '16842', city: 'BROMMA' },
  { postalCode: '16843', city: 'BROMMA' },
  { postalCode: '16844', city: 'BROMMA' },
  { postalCode: '16845', city: 'BROMMA' },
  { postalCode: '16846', city: 'BROMMA' },
  { postalCode: '16847', city: 'BROMMA' },
  { postalCode: '16848', city: 'BROMMA' },
  { postalCode: '16849', city: 'BROMMA' },
  { postalCode: '16850', city: 'BROMMA' },
  { postalCode: '16851', city: 'BROMMA' },
  { postalCode: '16852', city: 'BROMMA' },
  { postalCode: '16853', city: 'BROMMA' },
  { postalCode: '16854', city: 'BROMMA' },
  { postalCode: '16855', city: 'BROMMA' },
  { postalCode: '16856', city: 'BROMMA' },
  { postalCode: '16857', city: 'BROMMA' },
  { postalCode: '16858', city: 'BROMMA' },
  { postalCode: '16859', city: 'BROMMA' },
  { postalCode: '16860', city: 'BROMMA' },
  { postalCode: '16861', city: 'BROMMA' },
  { postalCode: '16862', city: 'BROMMA' },
  { postalCode: '16863', city: 'BROMMA' },
  { postalCode: '16864', city: 'BROMMA' },
  { postalCode: '16865', city: 'BROMMA' },
  { postalCode: '16866', city: 'BROMMA' },
  { postalCode: '16867', city: 'BROMMA' },
  { postalCode: '16868', city: 'BROMMA' },
  { postalCode: '16869', city: 'BROMMA' },
  { postalCode: '16870', city: 'BROMMA' },
  { postalCode: '16871', city: 'BROMMA' },
  { postalCode: '16872', city: 'BROMMA' },
  { postalCode: '16873', city: 'BROMMA' },
  { postalCode: '16874', city: 'BROMMA' },
  { postalCode: '16875', city: 'BROMMA' },
  { postalCode: '16876', city: 'BROMMA' },
  { postalCode: '16877', city: 'BROMMA' },
  { postalCode: '16878', city: 'BROMMA' },
  { postalCode: '16880', city: 'BROMMA' },
  { postalCode: '16900', city: 'SOLNA' },
  { postalCode: '16902', city: 'SOLNA' },
  { postalCode: '16903', city: 'SOLNA' },
  { postalCode: '16904', city: 'SOLNA' },
  { postalCode: '16905', city: 'SOLNA' },
  { postalCode: '16920', city: 'SOLNA' },
  { postalCode: '16926', city: 'SOLNA' },
  { postalCode: '16927', city: 'SOLNA' },
  { postalCode: '16928', city: 'SOLNA' },
  { postalCode: '16929', city: 'SOLNA' },
  { postalCode: '16930', city: 'SOLNA' },
  { postalCode: '16931', city: 'SOLNA' },
  { postalCode: '16932', city: 'SOLNA' },
  { postalCode: '16933', city: 'SOLNA' },
  { postalCode: '16934', city: 'SOLNA' },
  { postalCode: '16935', city: 'SOLNA' },
  { postalCode: '16936', city: 'SOLNA' },
  { postalCode: '16937', city: 'SOLNA' },
  { postalCode: '16938', city: 'SOLNA' },
  { postalCode: '16939', city: 'SOLNA' },
  { postalCode: '16940', city: 'SOLNA' },
  { postalCode: '16941', city: 'SOLNA' },
  { postalCode: '16950', city: 'SOLNA' },
  { postalCode: '16951', city: 'SOLNA' },
  { postalCode: '16952', city: 'SOLNA' },
  { postalCode: '16953', city: 'SOLNA' },
  { postalCode: '16954', city: 'SOLNA' },
  { postalCode: '16955', city: 'SOLNA' },
  { postalCode: '16956', city: 'SOLNA' },
  { postalCode: '16957', city: 'SOLNA' },
  { postalCode: '16958', city: 'SOLNA' },
  { postalCode: '16959', city: 'SOLNA' },
  { postalCode: '16960', city: 'SOLNA' },
  { postalCode: '16961', city: 'SOLNA' },
  { postalCode: '16962', city: 'SOLNA' },
  { postalCode: '16963', city: 'SOLNA' },
  { postalCode: '16964', city: 'SOLNA' },
  { postalCode: '16965', city: 'SOLNA' },
  { postalCode: '16966', city: 'SOLNA' },
  { postalCode: '16967', city: 'SOLNA' },
  { postalCode: '16968', city: 'SOLNA' },
  { postalCode: '16969', city: 'SOLNA' },
  { postalCode: '16970', city: 'SOLNA' },
  { postalCode: '16971', city: 'SOLNA' },
  { postalCode: '16972', city: 'SOLNA' },
  { postalCode: '16973', city: 'SOLNA' },
  { postalCode: '16974', city: 'SOLNA' },
  { postalCode: '16975', city: 'SOLNA' },
  { postalCode: '16979', city: 'SOLNA' },
  { postalCode: '16980', city: 'SOLNA' },
  { postalCode: '16981', city: 'SOLNA' },
  { postalCode: '16982', city: 'STOCKHOLM' },
  { postalCode: '16983', city: 'SOLNA' },
  { postalCode: '16984', city: 'STOCKHOLM' },
  { postalCode: '16985', city: 'STOCKHOLM' },
  { postalCode: '16986', city: 'STOCKHOLM' },
  { postalCode: '16987', city: 'STOCKHOLM' },
  { postalCode: '16988', city: 'SOLNA' },
  { postalCode: '16989', city: 'SOLNA' },
  { postalCode: '16990', city: 'SOLNA' },
  { postalCode: '16991', city: 'SOLNA' },
  { postalCode: '16992', city: 'STOCKHOLM' },
  { postalCode: '16993', city: 'SOLNA' },
  { postalCode: '16994', city: 'SOLNA' },
  { postalCode: '16995', city: 'SOLNA' },
  { postalCode: '16997', city: 'SOLNA' },
  { postalCode: '16999', city: 'STOCKHOLM' },
  { postalCode: '17000', city: 'SOLNA' },
  { postalCode: '17007', city: 'SOLNA' },
  { postalCode: '17008', city: 'SOLNA' },
  { postalCode: '17009', city: 'SOLNA' },
  { postalCode: '17020', city: 'SOLNA' },
  { postalCode: '17061', city: 'SOLNA' },
  { postalCode: '17062', city: 'SOLNA' },
  { postalCode: '17063', city: 'SOLNA' },
  { postalCode: '17065', city: 'SOLNA' },
  { postalCode: '17066', city: 'SOLNA' },
  { postalCode: '17067', city: 'SOLNA' },
  { postalCode: '17068', city: 'SOLNA' },
  { postalCode: '17069', city: 'SOLNA' },
  { postalCode: '17070', city: 'SOLNA' },
  { postalCode: '17071', city: 'SOLNA' },
  { postalCode: '17072', city: 'SOLNA' },
  { postalCode: '17073', city: 'SOLNA' },
  { postalCode: '17074', city: 'SOLNA' },
  { postalCode: '17075', city: 'SOLNA' },
  { postalCode: '17076', city: 'SOLNA' },
  { postalCode: '17077', city: 'SOLNA' },
  { postalCode: '17078', city: 'SOLNA' },
  { postalCode: '17079', city: 'SOLNA' },
  { postalCode: '17080', city: 'SOLNA' },
  { postalCode: '17081', city: 'SOLNA' },
  { postalCode: '17082', city: 'SOLNA' },
  { postalCode: '17084', city: 'SOLNA' },
  { postalCode: '17085', city: 'SOLNA' },
  { postalCode: '17087', city: 'STOCKHOLM' },
  { postalCode: '17100', city: 'SOLNA' },
  { postalCode: '17101', city: 'SOLNA' },
  { postalCode: '17102', city: 'SOLNA' },
  { postalCode: '17104', city: 'SOLNA' },
  { postalCode: '17105', city: 'SOLNA' },
  { postalCode: '17106', city: 'SOLNA' },
  { postalCode: '17108', city: 'SOLNA' },
  { postalCode: '17109', city: 'SOLNA' },
  { postalCode: '17111', city: 'SOLNA' },
  { postalCode: '17113', city: 'SOLNA' },
  { postalCode: '17116', city: 'STOCKHOLM' },
  { postalCode: '17119', city: 'SOLNA' },
  { postalCode: '17120', city: 'SOLNA' },
  { postalCode: '17121', city: 'SOLNA' },
  { postalCode: '17122', city: 'SOLNA' },
  { postalCode: '17123', city: 'SOLNA' },
  { postalCode: '17124', city: 'SOLNA' },
  { postalCode: '17125', city: 'SOLNA' },
  { postalCode: '17126', city: 'SOLNA' },
  { postalCode: '17127', city: 'SOLNA' },
  { postalCode: '17128', city: 'SOLNA' },
  { postalCode: '17129', city: 'SOLNA' },
  { postalCode: '17141', city: 'SOLNA' },
  { postalCode: '17142', city: 'SOLNA' },
  { postalCode: '17143', city: 'SOLNA' },
  { postalCode: '17144', city: 'SOLNA' },
  { postalCode: '17145', city: 'SOLNA' },
  { postalCode: '17147', city: 'SOLNA' },
  { postalCode: '17148', city: 'SOLNA' },
  { postalCode: '17149', city: 'SOLNA' },
  { postalCode: '17150', city: 'SOLNA' },
  { postalCode: '17151', city: 'SOLNA' },
  { postalCode: '17152', city: 'SOLNA' },
  { postalCode: '17153', city: 'SOLNA' },
  { postalCode: '17154', city: 'SOLNA' },
  { postalCode: '17155', city: 'SOLNA' },
  { postalCode: '17156', city: 'SOLNA' },
  { postalCode: '17157', city: 'SOLNA' },
  { postalCode: '17158', city: 'SOLNA' },
  { postalCode: '17159', city: 'SOLNA' },
  { postalCode: '17160', city: 'SOLNA' },
  { postalCode: '17161', city: 'SOLNA' },
  { postalCode: '17162', city: 'SOLNA' },
  { postalCode: '17163', city: 'SOLNA' },
  { postalCode: '17164', city: 'SOLNA' },
  { postalCode: '17165', city: 'SOLNA' },
  { postalCode: '17166', city: 'SOLNA' },
  { postalCode: '17167', city: 'SOLNA' },
  { postalCode: '17168', city: 'SOLNA' },
  { postalCode: '17169', city: 'SOLNA' },
  { postalCode: '17170', city: 'SOLNA' },
  { postalCode: '17171', city: 'SOLNA' },
  { postalCode: '17172', city: 'SOLNA' },
  { postalCode: '17173', city: 'SOLNA' },
  { postalCode: '17174', city: 'SOLNA' },
  { postalCode: '17175', city: 'STOCKHOLM' },
  { postalCode: '17176', city: 'STOCKHOLM' },
  { postalCode: '17177', city: 'STOCKHOLM' },
  { postalCode: '17178', city: 'SOLNA' },
  { postalCode: '17179', city: 'SOLNA' },
  { postalCode: '17180', city: 'SOLNA' },
  { postalCode: '17181', city: 'SOLNA' },
  { postalCode: '17182', city: 'SOLNA' },
  { postalCode: '17183', city: 'STOCKHOLM' },
  { postalCode: '17184', city: 'STOCKHOLM' },
  { postalCode: '17185', city: 'SOLNA' },
  { postalCode: '17186', city: 'SOLNA' },
  { postalCode: '17187', city: 'SOLNA' },
  { postalCode: '17188', city: 'SOLNA' },
  { postalCode: '17189', city: 'SOLNA' },
  { postalCode: '17190', city: 'SOLNA' },
  { postalCode: '17191', city: 'SOLNA' },
  { postalCode: '17192', city: 'SOLNA' },
  { postalCode: '17193', city: 'SOLNA' },
  { postalCode: '17194', city: 'SOLNA' },
  { postalCode: '17196', city: 'SOLNA' },
  { postalCode: '17197', city: 'SOLNA' },
  { postalCode: '17199', city: 'SOLNA' },
  { postalCode: '17205', city: 'SUNDBYBERG' },
  { postalCode: '17213', city: 'SUNDBYBERG' },
  { postalCode: '17219', city: 'SUNDBYBERG' },
  { postalCode: '17220', city: 'SUNDBYBERG' },
  { postalCode: '17221', city: 'SUNDBYBERG' },
  { postalCode: '17222', city: 'SUNDBYBERG' },
  { postalCode: '17223', city: 'SUNDBYBERG' },
  { postalCode: '17224', city: 'SUNDBYBERG' },
  { postalCode: '17225', city: 'SUNDBYBERG' },
  { postalCode: '17226', city: 'SUNDBYBERG' },
  { postalCode: '17227', city: 'SUNDBYBERG' },
  { postalCode: '17229', city: 'SUNDBYBERG' },
  { postalCode: '17230', city: 'SUNDBYBERG' },
  { postalCode: '17231', city: 'SUNDBYBERG' },
  { postalCode: '17232', city: 'SUNDBYBERG' },
  { postalCode: '17233', city: 'SUNDBYBERG' },
  { postalCode: '17234', city: 'SUNDBYBERG' },
  { postalCode: '17235', city: 'SUNDBYBERG' },
  { postalCode: '17236', city: 'SUNDBYBERG' },
  { postalCode: '17237', city: 'SUNDBYBERG' },
  { postalCode: '17238', city: 'SUNDBYBERG' },
  { postalCode: '17239', city: 'SUNDBYBERG' },
  { postalCode: '17240', city: 'SUNDBYBERG' },
  { postalCode: '17241', city: 'SUNDBYBERG' },
  { postalCode: '17261', city: 'SUNDBYBERG' },
  { postalCode: '17262', city: 'SUNDBYBERG' },
  { postalCode: '17263', city: 'SUNDBYBERG' },
  { postalCode: '17264', city: 'SUNDBYBERG' },
  { postalCode: '17265', city: 'SUNDBYBERG' },
  { postalCode: '17266', city: 'SUNDBYBERG' },
  { postalCode: '17267', city: 'SUNDBYBERG' },
  { postalCode: '17268', city: 'SUNDBYBERG' },
  { postalCode: '17269', city: 'SUNDBYBERG' },
  { postalCode: '17270', city: 'SUNDBYBERG' },
  { postalCode: '17271', city: 'SUNDBYBERG' },
  { postalCode: '17272', city: 'SUNDBYBERG' },
  { postalCode: '17273', city: 'SUNDBYBERG' },
  { postalCode: '17274', city: 'SUNDBYBERG' },
  { postalCode: '17275', city: 'SUNDBYBERG' },
  { postalCode: '17276', city: 'SUNDBYBERG' },
  { postalCode: '17277', city: 'SUNDBYBERG' },
  { postalCode: '17278', city: 'SUNDBYBERG' },
  { postalCode: '17279', city: 'SUNDBYBERG' },
  { postalCode: '17281', city: 'STOCKHOLM' },
  { postalCode: '17282', city: 'SUNDBYBERG' },
  { postalCode: '17284', city: 'SUNDBYBERG' },
  { postalCode: '17287', city: 'SUNDBYBERG' },
  { postalCode: '17288', city: 'SUNDBYBERG' },
  { postalCode: '17290', city: 'SUNDBYBERG' },
  { postalCode: '17292', city: 'SUNDBYBERG' },
  { postalCode: '17298', city: 'STOCKHOLM' },
  { postalCode: '17299', city: 'SUNDBYBERG' },
  { postalCode: '17300', city: 'TOMTEBODA' },
  { postalCode: '17313', city: 'TOMTEBODA' },
  { postalCode: '17315', city: 'TOMTEBODA' },
  { postalCode: '17317', city: 'TOMTEBODA' },
  { postalCode: '17318', city: 'TOMTEBODA' },
  { postalCode: '17350', city: 'STOCKHOLM' },
  { postalCode: '17351', city: 'STOCKHOLM' },
  { postalCode: '17360', city: 'TOMTEBODA' },
  { postalCode: '17365', city: 'STOCKHOLM' },
  { postalCode: '17381', city: 'STOCKHOLM' },
  { postalCode: '17385', city: 'TOMTEBODA' },
  { postalCode: '17386', city: 'STOCKHOLM' },
  { postalCode: '17400', city: 'SUNDBYBERG' },
  { postalCode: '17402', city: 'SUNDBYBERG' },
  { postalCode: '17407', city: 'SUNDBYBERG' },
  { postalCode: '17413', city: 'SUNDBYBERG' },
  { postalCode: '17420', city: 'SUNDBYBERG' },
  { postalCode: '17427', city: 'SUNDBYBERG' },
  { postalCode: '17441', city: 'SUNDBYBERG' },
  { postalCode: '17442', city: 'SUNDBYBERG' },
  { postalCode: '17443', city: 'SUNDBYBERG' },
  { postalCode: '17444', city: 'SUNDBYBERG' },
  { postalCode: '17445', city: 'SUNDBYBERG' },
  { postalCode: '17446', city: 'SUNDBYBERG' },
  { postalCode: '17447', city: 'SUNDBYBERG' },
  { postalCode: '17448', city: 'SUNDBYBERG' },
  { postalCode: '17449', city: 'SUNDBYBERG' },
  { postalCode: '17450', city: 'SUNDBYBERG' },
  { postalCode: '17451', city: 'SUNDBYBERG' },
  { postalCode: '17452', city: 'SUNDBYBERG' },
  { postalCode: '17453', city: 'SUNDBYBERG' },
  { postalCode: '17454', city: 'SUNDBYBERG' },
  { postalCode: '17455', city: 'SUNDBYBERG' },
  { postalCode: '17456', city: 'SUNDBYBERG' },
  { postalCode: '17457', city: 'SUNDBYBERG' },
  { postalCode: '17458', city: 'SUNDBYBERG' },
  { postalCode: '17459', city: 'SUNDBYBERG' },
  { postalCode: '17460', city: 'SUNDBYBERG' },
  { postalCode: '17461', city: 'SUNDBYBERG' },
  { postalCode: '17462', city: 'SUNDBYBERG' },
  { postalCode: '17463', city: 'SUNDBYBERG' },
  { postalCode: '17464', city: 'SUNDBYBERG' },
  { postalCode: '17465', city: 'SUNDBYBERG' },
  { postalCode: '17466', city: 'SUNDBYBERG' },
  { postalCode: '17486', city: 'SUNDBYBERG' },
  { postalCode: '17487', city: 'SUNDBYBERG' },
  { postalCode: '17488', city: 'SUNDBYBERG' },
  { postalCode: '17500', city: 'JÄRFÄLLA' },
  { postalCode: '17504', city: 'JÄRFÄLLA' },
  { postalCode: '17506', city: 'JÄRFÄLLA' },
  { postalCode: '17508', city: 'JÄRFÄLLA' },
  { postalCode: '17514', city: 'JÄRFÄLLA' },
  { postalCode: '17515', city: 'JÄRFÄLLA' },
  { postalCode: '17516', city: 'JÄRFÄLLA' },
  { postalCode: '17517', city: 'JÄRFÄLLA' },
  { postalCode: '17518', city: 'JÄRFÄLLA' },
  { postalCode: '17519', city: 'JÄRFÄLLA' },
  { postalCode: '17520', city: 'JÄRFÄLLA' },
  { postalCode: '17526', city: 'JÄRFÄLLA' },
  { postalCode: '17527', city: 'JÄRFÄLLA' },
  { postalCode: '17529', city: 'JÄRFÄLLA' },
  { postalCode: '17541', city: 'JÄRFÄLLA' },
  { postalCode: '17543', city: 'JÄRFÄLLA' },
  { postalCode: '17544', city: 'JÄRFÄLLA' },
  { postalCode: '17545', city: 'JÄRFÄLLA' },
  { postalCode: '17546', city: 'JÄRFÄLLA' },
  { postalCode: '17547', city: 'JÄRFÄLLA' },
  { postalCode: '17548', city: 'JÄRFÄLLA' },
  { postalCode: '17549', city: 'JÄRFÄLLA' },
  { postalCode: '17550', city: 'JÄRFÄLLA' },
  { postalCode: '17551', city: 'JÄRFÄLLA' },
  { postalCode: '17552', city: 'JÄRFÄLLA' },
  { postalCode: '17553', city: 'JÄRFÄLLA' },
  { postalCode: '17554', city: 'JÄRFÄLLA' },
  { postalCode: '17555', city: 'JÄRFÄLLA' },
  { postalCode: '17556', city: 'JÄRFÄLLA' },
  { postalCode: '17557', city: 'JÄRFÄLLA' },
  { postalCode: '17560', city: 'JÄRFÄLLA' },
  { postalCode: '17561', city: 'JÄRFÄLLA' },
  { postalCode: '17562', city: 'JÄRFÄLLA' },
  { postalCode: '17563', city: 'JÄRFÄLLA' },
  { postalCode: '17564', city: 'JÄRFÄLLA' },
  { postalCode: '17565', city: 'JÄRFÄLLA' },
  { postalCode: '17566', city: 'JÄRFÄLLA' },
  { postalCode: '17567', city: 'JÄRFÄLLA' },
  { postalCode: '17568', city: 'JÄRFÄLLA' },
  { postalCode: '17569', city: 'JÄRFÄLLA' },
  { postalCode: '17580', city: 'JÄRFÄLLA' },
  { postalCode: '17581', city: 'JÄRFÄLLA' },
  { postalCode: '17584', city: 'JÄRFÄLLA' },
  { postalCode: '17588', city: 'JÄRFÄLLA' },
  { postalCode: '17589', city: 'JÄRFÄLLA' },
  { postalCode: '17600', city: 'JÄRFÄLLA' },
  { postalCode: '17602', city: 'JÄRFÄLLA' },
  { postalCode: '17627', city: 'JÄRFÄLLA' },
  { postalCode: '17668', city: 'JÄRFÄLLA' },
  { postalCode: '17669', city: 'JÄRFÄLLA' },
  { postalCode: '17670', city: 'JÄRFÄLLA' },
  { postalCode: '17671', city: 'JÄRFÄLLA' },
  { postalCode: '17672', city: 'JÄRFÄLLA' },
  { postalCode: '17673', city: 'JÄRFÄLLA' },
  { postalCode: '17674', city: 'JÄRFÄLLA' },
  { postalCode: '17675', city: 'JÄRFÄLLA' },
  { postalCode: '17676', city: 'JÄRFÄLLA' },
  { postalCode: '17677', city: 'JÄRFÄLLA' },
  { postalCode: '17680', city: 'JÄRFÄLLA' },
  { postalCode: '17700', city: 'JÄRFÄLLA' },
  { postalCode: '17721', city: 'JÄRFÄLLA' },
  { postalCode: '17722', city: 'JÄRFÄLLA' },
  { postalCode: '17723', city: 'JÄRFÄLLA' },
  { postalCode: '17724', city: 'JÄRFÄLLA' },
  { postalCode: '17725', city: 'JÄRFÄLLA' },
  { postalCode: '17730', city: 'JÄRFÄLLA' },
  { postalCode: '17731', city: 'JÄRFÄLLA' },
  { postalCode: '17732', city: 'JÄRFÄLLA' },
  { postalCode: '17733', city: 'JÄRFÄLLA' },
  { postalCode: '17734', city: 'JÄRFÄLLA' },
  { postalCode: '17735', city: 'JÄRFÄLLA' },
  { postalCode: '17736', city: 'JÄRFÄLLA' },
  { postalCode: '17737', city: 'JÄRFÄLLA' },
  { postalCode: '17738', city: 'JÄRFÄLLA' },
  { postalCode: '17739', city: 'JÄRFÄLLA' },
  { postalCode: '17740', city: 'JÄRFÄLLA' },
  { postalCode: '17741', city: 'JÄRFÄLLA' },
  { postalCode: '17742', city: 'JÄRFÄLLA' },
  { postalCode: '17743', city: 'JÄRFÄLLA' },
  { postalCode: '17744', city: 'JÄRFÄLLA' },
  { postalCode: '17745', city: 'JÄRFÄLLA' },
  { postalCode: '17746', city: 'JÄRFÄLLA' },
  { postalCode: '17747', city: 'JÄRFÄLLA' },
  { postalCode: '17750', city: 'JÄRFÄLLA' },
  { postalCode: '17751', city: 'JÄRFÄLLA' },
  { postalCode: '17752', city: 'JÄRFÄLLA' },
  { postalCode: '17753', city: 'JÄRFÄLLA' },
  { postalCode: '17754', city: 'JÄRFÄLLA' },
  { postalCode: '17755', city: 'JÄRFÄLLA' },
  { postalCode: '17756', city: 'JÄRFÄLLA' },
  { postalCode: '17757', city: 'JÄRFÄLLA' },
  { postalCode: '17758', city: 'JÄRFÄLLA' },
  { postalCode: '17759', city: 'JÄRFÄLLA' },
  { postalCode: '17760', city: 'JÄRFÄLLA' },
  { postalCode: '17761', city: 'JÄRFÄLLA' },
  { postalCode: '17762', city: 'JÄRFÄLLA' },
  { postalCode: '17763', city: 'JÄRFÄLLA' },
  { postalCode: '17764', city: 'JÄRFÄLLA' },
  { postalCode: '17765', city: 'JÄRFÄLLA' },
  { postalCode: '17770', city: 'JÄRFÄLLA' },
  { postalCode: '17771', city: 'JÄRFÄLLA' },
  { postalCode: '17780', city: 'JÄRFÄLLA' },
  { postalCode: '17781', city: 'JÄRFÄLLA' },
  { postalCode: '17800', city: 'EKERÖ' },
  { postalCode: '17802', city: 'DROTTNINGHOLM' },
  { postalCode: '17820', city: 'EKERÖ' },
  { postalCode: '17821', city: 'EKERÖ' },
  { postalCode: '17822', city: 'EKERÖ' },
  { postalCode: '17823', city: 'EKERÖ' },
  { postalCode: '17824', city: 'EKERÖ' },
  { postalCode: '17830', city: 'EKERÖ' },
  { postalCode: '17831', city: 'EKERÖ' },
  { postalCode: '17832', city: 'EKERÖ' },
  { postalCode: '17833', city: 'EKERÖ' },
  { postalCode: '17834', city: 'EKERÖ' },
  { postalCode: '17835', city: 'EKERÖ' },
  { postalCode: '17836', city: 'EKERÖ' },
  { postalCode: '17837', city: 'EKERÖ' },
  { postalCode: '17838', city: 'EKERÖ' },
  { postalCode: '17839', city: 'EKERÖ' },
  { postalCode: '17840', city: 'EKERÖ' },
  { postalCode: '17841', city: 'EKERÖ' },
  { postalCode: '17851', city: 'EKERÖ' },
  { postalCode: '17852', city: 'EKERÖ' },
  { postalCode: '17853', city: 'EKERÖ' },
  { postalCode: '17854', city: 'EKERÖ' },
  { postalCode: '17890', city: 'EKERÖ' },
  { postalCode: '17891', city: 'MUNSÖ' },
  { postalCode: '17892', city: 'ADELSÖ' },
  { postalCode: '17893', city: 'DROTTNINGHOLM' },
  { postalCode: '17903', city: 'STENHAMRA' },
  { postalCode: '17960', city: 'STENHAMRA' },
  { postalCode: '17961', city: 'STENHAMRA' },
  { postalCode: '17962', city: 'STENHAMRA' },
  { postalCode: '17963', city: 'STENHAMRA' },
  { postalCode: '17965', city: 'STENHAMRA' },
  { postalCode: '17975', city: 'SKÅ' },
  { postalCode: '17995', city: 'SVARTSJÖ' },
  { postalCode: '17996', city: 'SVARTSJÖ' },
  { postalCode: '17997', city: 'FÄRENTUNA' },
  { postalCode: '17998', city: 'FÄRENTUNA' },
  { postalCode: '18100', city: 'LIDINGÖ' },
  { postalCode: '18103', city: 'LIDINGÖ' },
  { postalCode: '18104', city: 'LIDINGÖ' },
  { postalCode: '18105', city: 'LIDINGÖ' },
  { postalCode: '18106', city: 'LIDINGÖ' },
  { postalCode: '18110', city: 'LIDINGÖ' },
  { postalCode: '18118', city: 'LIDINGÖ' },
  { postalCode: '18120', city: 'LIDINGÖ' },
  { postalCode: '18121', city: 'LIDINGÖ' },
  { postalCode: '18122', city: 'LIDINGÖ' },
  { postalCode: '18123', city: 'LIDINGÖ' },
  { postalCode: '18124', city: 'LIDINGÖ' },
  { postalCode: '18125', city: 'LIDINGÖ' },
  { postalCode: '18129', city: 'LIDINGÖ' },
  { postalCode: '18130', city: 'LIDINGÖ' },
  { postalCode: '18131', city: 'LIDINGÖ' },
  { postalCode: '18132', city: 'LIDINGÖ' },
  { postalCode: '18133', city: 'LIDINGÖ' },
  { postalCode: '18134', city: 'LIDINGÖ' },
  { postalCode: '18135', city: 'LIDINGÖ' },
  { postalCode: '18136', city: 'LIDINGÖ' },
  { postalCode: '18137', city: 'LIDINGÖ' },
  { postalCode: '18138', city: 'LIDINGÖ' },
  { postalCode: '18139', city: 'LIDINGÖ' },
  { postalCode: '18140', city: 'LIDINGÖ' },
  { postalCode: '18141', city: 'LIDINGÖ' },
  { postalCode: '18142', city: 'LIDINGÖ' },
  { postalCode: '18143', city: 'LIDINGÖ' },
  { postalCode: '18144', city: 'LIDINGÖ' },
  { postalCode: '18145', city: 'LIDINGÖ' },
  { postalCode: '18146', city: 'LIDINGÖ' },
  { postalCode: '18147', city: 'LIDINGÖ' },
  { postalCode: '18148', city: 'LIDINGÖ' },
  { postalCode: '18150', city: 'LIDINGÖ' },
  { postalCode: '18151', city: 'LIDINGÖ' },
  { postalCode: '18155', city: 'LIDINGÖ' },
  { postalCode: '18156', city: 'LIDINGÖ' },
  { postalCode: '18157', city: 'LIDINGÖ' },
  { postalCode: '18158', city: 'LIDINGÖ' },
  { postalCode: '18159', city: 'LIDINGÖ' },
  { postalCode: '18160', city: 'LIDINGÖ' },
  { postalCode: '18161', city: 'LIDINGÖ' },
  { postalCode: '18162', city: 'LIDINGÖ' },
  { postalCode: '18163', city: 'LIDINGÖ' },
  { postalCode: '18164', city: 'LIDINGÖ' },
  { postalCode: '18165', city: 'LIDINGÖ' },
  { postalCode: '18166', city: 'LIDINGÖ' },
  { postalCode: '18167', city: 'LIDINGÖ' },
  { postalCode: '18181', city: 'LIDINGÖ' },
  { postalCode: '18182', city: 'LIDINGÖ' },
  { postalCode: '18185', city: 'LIDINGÖ' },
  { postalCode: '18186', city: 'LIDINGÖ' },
  { postalCode: '18187', city: 'LIDINGÖ' },
  { postalCode: '18190', city: 'LIDINGÖ' },
  { postalCode: '18200', city: 'DANDERYD' },
  { postalCode: '18202', city: 'DANDERYD' },
  { postalCode: '18203', city: 'DANDERYD' },
  { postalCode: '18204', city: 'ENEBYBERG' },
  { postalCode: '18205', city: 'DJURSHOLM' },
  { postalCode: '18206', city: 'DJURSHOLM' },
  { postalCode: '18207', city: 'STOCKSUND' },
  { postalCode: '18211', city: 'DANDERYD' },
  { postalCode: '18212', city: 'DANDERYD' },
  { postalCode: '18214', city: 'DANDERYD' },
  { postalCode: '18215', city: 'DANDERYD' },
  { postalCode: '18216', city: 'DANDERYD' },
  { postalCode: '18217', city: 'DANDERYD' },
  { postalCode: '18220', city: 'DANDERYD' },
  { postalCode: '18230', city: 'DANDERYD' },
  { postalCode: '18231', city: 'DANDERYD' },
  { postalCode: '18232', city: 'DANDERYD' },
  { postalCode: '18233', city: 'DANDERYD' },
  { postalCode: '18234', city: 'DANDERYD' },
  { postalCode: '18235', city: 'DANDERYD' },
  { postalCode: '18236', city: 'DANDERYD' },
  { postalCode: '18237', city: 'DANDERYD' },
  { postalCode: '18238', city: 'DANDERYD' },
  { postalCode: '18239', city: 'DANDERYD' },
  { postalCode: '18245', city: 'ENEBYBERG' },
  { postalCode: '18246', city: 'ENEBYBERG' },
  { postalCode: '18247', city: 'ENEBYBERG' },
  { postalCode: '18248', city: 'ENEBYBERG' },
  { postalCode: '18249', city: 'ENEBYBERG' },
  { postalCode: '18250', city: 'DANDERYD' },
  { postalCode: '18252', city: 'DANDERYD' },
  { postalCode: '18253', city: 'DANDERYD' },
  { postalCode: '18254', city: 'DJURSHOLM' },
  { postalCode: '18255', city: 'DJURSHOLM' },
  { postalCode: '18256', city: 'DANDERYD' },
  { postalCode: '18257', city: 'DANDERYD' },
  { postalCode: '18260', city: 'DJURSHOLM' },
  { postalCode: '18261', city: 'DJURSHOLM' },
  { postalCode: '18262', city: 'DJURSHOLM' },
  { postalCode: '18263', city: 'DJURSHOLM' },
  { postalCode: '18264', city: 'DJURSHOLM' },
  { postalCode: '18265', city: 'DJURSHOLM' },
  { postalCode: '18266', city: 'DJURSHOLM' },
  { postalCode: '18267', city: 'DJURSHOLM' },
  { postalCode: '18268', city: 'DJURSHOLM' },
  { postalCode: '18269', city: 'DJURSHOLM' },
  { postalCode: '18270', city: 'STOCKSUND' },
  { postalCode: '18273', city: 'STOCKSUND' },
  { postalCode: '18274', city: 'STOCKSUND' },
  { postalCode: '18275', city: 'STOCKSUND' },
  { postalCode: '18276', city: 'STOCKSUND' },
  { postalCode: '18277', city: 'STOCKSUND' },
  { postalCode: '18278', city: 'STOCKSUND' },
  { postalCode: '18279', city: 'STOCKSUND' },
  { postalCode: '18282', city: 'STOCKHOLM' },
  { postalCode: '18285', city: 'DANDERYD' },
  { postalCode: '18287', city: 'DANDERYD' },
  { postalCode: '18288', city: 'STOCKHOLM' },
  { postalCode: '18291', city: 'DANDERYD' },
  { postalCode: '18300', city: 'TÄBY' },
  { postalCode: '18301', city: 'TÄBY' },
  { postalCode: '18302', city: 'TÄBY' },
  { postalCode: '18303', city: 'TÄBY' },
  { postalCode: '18304', city: 'TÄBY' },
  { postalCode: '18311', city: 'TÄBY' },
  { postalCode: '18312', city: 'TÄBY' },
  { postalCode: '18313', city: 'TÄBY' },
  { postalCode: '18314', city: 'TÄBY' },
  { postalCode: '18315', city: 'TÄBY' },
  { postalCode: '18316', city: 'TÄBY' },
  { postalCode: '18320', city: 'TÄBY' },
  { postalCode: '18321', city: 'TÄBY' },
  { postalCode: '18322', city: 'TÄBY' },
  { postalCode: '18323', city: 'TÄBY' },
  { postalCode: '18325', city: 'TÄBY' },
  { postalCode: '18326', city: 'TÄBY' },
  { postalCode: '18330', city: 'TÄBY' },
  { postalCode: '18331', city: 'TÄBY' },
  { postalCode: '18332', city: 'TÄBY' },
  { postalCode: '18333', city: 'TÄBY' },
  { postalCode: '18334', city: 'TÄBY' },
  { postalCode: '18335', city: 'TÄBY' },
  { postalCode: '18336', city: 'TÄBY' },
  { postalCode: '18337', city: 'TÄBY' },
  { postalCode: '18338', city: 'TÄBY' },
  { postalCode: '18339', city: 'TÄBY' },
  { postalCode: '18341', city: 'TÄBY' },
  { postalCode: '18347', city: 'TÄBY' },
  { postalCode: '18348', city: 'TÄBY' },
  { postalCode: '18349', city: 'TÄBY' },
  { postalCode: '18350', city: 'TÄBY' },
  { postalCode: '18351', city: 'TÄBY' },
  { postalCode: '18352', city: 'TÄBY' },
  { postalCode: '18353', city: 'TÄBY' },
  { postalCode: '18354', city: 'TÄBY' },
  { postalCode: '18355', city: 'TÄBY' },
  { postalCode: '18356', city: 'TÄBY' },
  { postalCode: '18357', city: 'TÄBY' },
  { postalCode: '18358', city: 'TÄBY' },
  { postalCode: '18359', city: 'TÄBY' },
  { postalCode: '18360', city: 'TÄBY' },
  { postalCode: '18361', city: 'TÄBY' },
  { postalCode: '18362', city: 'TÄBY' },
  { postalCode: '18363', city: 'TÄBY' },
  { postalCode: '18364', city: 'TÄBY' },
  { postalCode: '18365', city: 'TÄBY' },
  { postalCode: '18366', city: 'TÄBY' },
  { postalCode: '18367', city: 'TÄBY' },
  { postalCode: '18368', city: 'TÄBY' },
  { postalCode: '18369', city: 'TÄBY' },
  { postalCode: '18370', city: 'TÄBY' },
  { postalCode: '18371', city: 'TÄBY' },
  { postalCode: '18372', city: 'TÄBY' },
  { postalCode: '18374', city: 'TÄBY' },
  { postalCode: '18375', city: 'TÄBY' },
  { postalCode: '18376', city: 'TÄBY' },
  { postalCode: '18377', city: 'TÄBY' },
  { postalCode: '18378', city: 'TÄBY' },
  { postalCode: '18379', city: 'TÄBY' },
  { postalCode: '18380', city: 'TÄBY' },
  { postalCode: '18381', city: 'TÄBY' },
  { postalCode: '18383', city: 'TÄBY' },
  { postalCode: '18384', city: 'TÄBY' },
  { postalCode: '18385', city: 'TÄBY' },
  { postalCode: '18400', city: 'ÅKERSBERGA' },
  { postalCode: '18403', city: 'LJUSTERÖ' },
  { postalCode: '18420', city: 'ÅKERSBERGA' },
  { postalCode: '18421', city: 'ÅKERSBERGA' },
  { postalCode: '18422', city: 'ÅKERSBERGA' },
  { postalCode: '18423', city: 'ÅKERSBERGA' },
  { postalCode: '18424', city: 'ÅKERSBERGA' },
  { postalCode: '18425', city: 'ÅKERSBERGA' },
  { postalCode: '18426', city: 'ÅKERSBERGA' },
  { postalCode: '18427', city: 'ÅKERSBERGA' },
  { postalCode: '18428', city: 'ÅKERSBERGA' },
  { postalCode: '18430', city: 'ÅKERSBERGA' },
  { postalCode: '18431', city: 'ÅKERSBERGA' },
  { postalCode: '18432', city: 'ÅKERSBERGA' },
  { postalCode: '18433', city: 'ÅKERSBERGA' },
  { postalCode: '18434', city: 'ÅKERSBERGA' },
  { postalCode: '18435', city: 'ÅKERSBERGA' },
  { postalCode: '18436', city: 'ÅKERSBERGA' },
  { postalCode: '18437', city: 'ÅKERSBERGA' },
  { postalCode: '18438', city: 'ÅKERSBERGA' },
  { postalCode: '18439', city: 'ÅKERSBERGA' },
  { postalCode: '18440', city: 'ÅKERSBERGA' },
  { postalCode: '18441', city: 'ÅKERSBERGA' },
  { postalCode: '18442', city: 'ÅKERSBERGA' },
  { postalCode: '18443', city: 'ÅKERSBERGA' },
  { postalCode: '18444', city: 'ÅKERSBERGA' },
  { postalCode: '18450', city: 'ÅKERSBERGA' },
  { postalCode: '18451', city: 'ÖSTERSKÄR' },
  { postalCode: '18452', city: 'ÖSTERSKÄR' },
  { postalCode: '18453', city: 'ÅKERSBERGA' },
  { postalCode: '18460', city: 'ÅKERSBERGA' },
  { postalCode: '18461', city: 'ÅKERSBERGA' },
  { postalCode: '18462', city: 'ÅKERSBERGA' },
  { postalCode: '18463', city: 'ÅKERSBERGA' },
  { postalCode: '18464', city: 'ÅKERSBERGA' },
  { postalCode: '18470', city: 'ÅKERSBERGA' },
  { postalCode: '18486', city: 'ÅKERSBERGA' },
  { postalCode: '18491', city: 'ÅKERSBERGA' },
  { postalCode: '18492', city: 'ÅKERSBERGA' },
  { postalCode: '18493', city: 'ÅKERSBERGA' },
  { postalCode: '18494', city: 'ÅKERSBERGA' },
  { postalCode: '18495', city: 'LJUSTERÖ' },
  { postalCode: '18496', city: 'ÅKERSBERGA' },
  { postalCode: '18497', city: 'LJUSTERÖ' },
  { postalCode: '18499', city: 'HUSARÖ' },
  { postalCode: '18500', city: 'VAXHOLM' },
  { postalCode: '18520', city: 'VAXHOLM' },
  { postalCode: '18521', city: 'VAXHOLM' },
  { postalCode: '18522', city: 'VAXHOLM' },
  { postalCode: '18523', city: 'VAXHOLM' },
  { postalCode: '18531', city: 'VAXHOLM' },
  { postalCode: '18532', city: 'VAXHOLM' },
  { postalCode: '18533', city: 'VAXHOLM' },
  { postalCode: '18534', city: 'VAXHOLM' },
  { postalCode: '18535', city: 'VAXHOLM' },
  { postalCode: '18537', city: 'VAXHOLM' },
  { postalCode: '18539', city: 'VAXHOLM' },
  { postalCode: '18541', city: 'VAXHOLM' },
  { postalCode: '18551', city: 'VAXHOLM' },
  { postalCode: '18583', city: 'VAXHOLM' },
  { postalCode: '18591', city: 'VAXHOLM' },
  { postalCode: '18593', city: 'VAXHOLM' },
  { postalCode: '18594', city: 'VAXHOLM' },
  { postalCode: '18595', city: 'VAXHOLM' },
  { postalCode: '18599', city: 'VAXHOLM' },
  { postalCode: '18600', city: 'VALLENTUNA' },
  { postalCode: '18603', city: 'BROTTBY' },
  { postalCode: '18620', city: 'VALLENTUNA' },
  { postalCode: '18621', city: 'VALLENTUNA' },
  { postalCode: '18622', city: 'VALLENTUNA' },
  { postalCode: '18623', city: 'VALLENTUNA' },
  { postalCode: '18624', city: 'VALLENTUNA' },
  { postalCode: '18625', city: 'VALLENTUNA' },
  { postalCode: '18626', city: 'VALLENTUNA' },
  { postalCode: '18630', city: 'VALLENTUNA' },
  { postalCode: '18631', city: 'VALLENTUNA' },
  { postalCode: '18632', city: 'VALLENTUNA' },
  { postalCode: '18633', city: 'VALLENTUNA' },
  { postalCode: '18634', city: 'VALLENTUNA' },
  { postalCode: '18635', city: 'VALLENTUNA' },
  { postalCode: '18636', city: 'VALLENTUNA' },
  { postalCode: '18637', city: 'VALLENTUNA' },
  { postalCode: '18638', city: 'VALLENTUNA' },
  { postalCode: '18639', city: 'VALLENTUNA' },
  { postalCode: '18640', city: 'VALLENTUNA' },
  { postalCode: '18641', city: 'VALLENTUNA' },
  { postalCode: '18642', city: 'VALLENTUNA' },
  { postalCode: '18643', city: 'VALLENTUNA' },
  { postalCode: '18644', city: 'VALLENTUNA' },
  { postalCode: '18645', city: 'VALLENTUNA' },
  { postalCode: '18646', city: 'VALLENTUNA' },
  { postalCode: '18647', city: 'VALLENTUNA' },
  { postalCode: '18648', city: 'VALLENTUNA' },
  { postalCode: '18650', city: 'VALLENTUNA' },
  { postalCode: '18651', city: 'VALLENTUNA' },
  { postalCode: '18652', city: 'VALLENTUNA' },
  { postalCode: '18653', city: 'VALLENTUNA' },
  { postalCode: '18654', city: 'VALLENTUNA' },
  { postalCode: '18655', city: 'VALLENTUNA' },
  { postalCode: '18656', city: 'VALLENTUNA' },
  { postalCode: '18660', city: 'VALLENTUNA' },
  { postalCode: '18670', city: 'BROTTBY' },
  { postalCode: '18686', city: 'VALLENTUNA' },
  { postalCode: '18691', city: 'VALLENTUNA' },
  { postalCode: '18692', city: 'VALLENTUNA' },
  { postalCode: '18693', city: 'VALLENTUNA' },
  { postalCode: '18694', city: 'VALLENTUNA' },
  { postalCode: '18695', city: 'VALLENTUNA' },
  { postalCode: '18696', city: 'VALLENTUNA' },
  { postalCode: '18697', city: 'BROTTBY' },
  { postalCode: '18700', city: 'TÄBY' },
  { postalCode: '18705', city: 'TÄBY' },
  { postalCode: '18706', city: 'TÄBY' },
  { postalCode: '18711', city: 'TÄBY' },
  { postalCode: '18712', city: 'TÄBY' },
  { postalCode: '18713', city: 'TÄBY' },
  { postalCode: '18714', city: 'TÄBY' },
  { postalCode: '18715', city: 'TÄBY' },
  { postalCode: '18720', city: 'TÄBY' },
  { postalCode: '18726', city: 'TÄBY' },
  { postalCode: '18727', city: 'TÄBY' },
  { postalCode: '18728', city: 'TÄBY' },
  { postalCode: '18729', city: 'TÄBY' },
  { postalCode: '18730', city: 'TÄBY' },
  { postalCode: '18731', city: 'TÄBY' },
  { postalCode: '18732', city: 'TÄBY' },
  { postalCode: '18733', city: 'TÄBY' },
  { postalCode: '18734', city: 'TÄBY' },
  { postalCode: '18735', city: 'TÄBY' },
  { postalCode: '18736', city: 'TÄBY' },
  { postalCode: '18737', city: 'TÄBY' },
  { postalCode: '18738', city: 'TÄBY' },
  { postalCode: '18740', city: 'TÄBY' },
  { postalCode: '18741', city: 'TÄBY' },
  { postalCode: '18742', city: 'TÄBY' },
  { postalCode: '18743', city: 'TÄBY' },
  { postalCode: '18744', city: 'TÄBY' },
  { postalCode: '18745', city: 'TÄBY' },
  { postalCode: '18746', city: 'TÄBY' },
  { postalCode: '18749', city: 'TÄBY' },
  { postalCode: '18750', city: 'TÄBY' },
  { postalCode: '18751', city: 'TÄBY' },
  { postalCode: '18752', city: 'TÄBY' },
  { postalCode: '18753', city: 'TÄBY' },
  { postalCode: '18754', city: 'TÄBY' },
  { postalCode: '18760', city: 'TÄBY' },
  { postalCode: '18761', city: 'TÄBY' },
  { postalCode: '18762', city: 'TÄBY' },
  { postalCode: '18763', city: 'TÄBY' },
  { postalCode: '18764', city: 'TÄBY' },
  { postalCode: '18765', city: 'TÄBY' },
  { postalCode: '18766', city: 'TÄBY' },
  { postalCode: '18767', city: 'TÄBY' },
  { postalCode: '18768', city: 'TÄBY' },
  { postalCode: '18769', city: 'TÄBY' },
  { postalCode: '18770', city: 'TÄBY' },
  { postalCode: '18772', city: 'TÄBY' },
  { postalCode: '18773', city: 'TÄBY' },
  { postalCode: '18774', city: 'TÄBY' },
  { postalCode: '18775', city: 'TÄBY' },
  { postalCode: '18776', city: 'TÄBY' },
  { postalCode: '18777', city: 'TÄBY' },
  { postalCode: '19000', city: 'ROSERSBERG' },
  { postalCode: '19002', city: 'ROSERSBERG' },
  { postalCode: '19010', city: 'ROSERSBERG' },
  { postalCode: '19011', city: 'ROSERSBERG' },
  { postalCode: '19012', city: 'ROSERSBERG' },
  { postalCode: '19017', city: 'ROSERSBERG' },
  { postalCode: '19020', city: 'ROSERSBERG' },
  { postalCode: '19045', city: 'STOCKHOLM-ARLANDA' },
  { postalCode: '19046', city: 'STOCKHOLM-ARLANDA' },
  { postalCode: '19047', city: 'STOCKHOLM-ARLANDA' },
  { postalCode: '19050', city: 'STOCKHOLM-ARLANDA' },
  { postalCode: '19060', city: 'STOCKHOLM-ARLANDA' },
  { postalCode: '19070', city: 'ROSERSBERG' },
  { postalCode: '19080', city: 'ROSERSBERG' },
  { postalCode: '19081', city: 'ROSERSBERG' },
  { postalCode: '19082', city: 'ROSERSBERG' },
  { postalCode: '19083', city: 'ROSERSBERG' },
  { postalCode: '19084', city: 'ROSERSBERG' },
  { postalCode: '19085', city: 'ROSERSBERG' },
  { postalCode: '19090', city: 'STOCKHOLM-ARLANDA' },
  { postalCode: '19100', city: 'SOLLENTUNA' },
  { postalCode: '19102', city: 'SOLLENTUNA' },
  { postalCode: '19118', city: 'SOLLENTUNA' },
  { postalCode: '19120', city: 'SOLLENTUNA' },
  { postalCode: '19121', city: 'SOLLENTUNA' },
  { postalCode: '19122', city: 'SOLLENTUNA' },
  { postalCode: '19123', city: 'SOLLENTUNA' },
  { postalCode: '19124', city: 'SOLLENTUNA' },
  { postalCode: '19126', city: 'SOLLENTUNA' },
  { postalCode: '19127', city: 'SOLLENTUNA' },
  { postalCode: '19128', city: 'SOLLENTUNA' },
  { postalCode: '19129', city: 'SOLLENTUNA' },
  { postalCode: '19133', city: 'SOLLENTUNA' },
  { postalCode: '19134', city: 'SOLLENTUNA' },
  { postalCode: '19135', city: 'SOLLENTUNA' },
  { postalCode: '19136', city: 'SOLLENTUNA' },
  { postalCode: '19137', city: 'SOLLENTUNA' },
  { postalCode: '19138', city: 'SOLLENTUNA' },
  { postalCode: '19139', city: 'SOLLENTUNA' },
  { postalCode: '19140', city: 'SOLLENTUNA' },
  { postalCode: '19141', city: 'SOLLENTUNA' },
  { postalCode: '19142', city: 'SOLLENTUNA' },
  { postalCode: '19143', city: 'SOLLENTUNA' },
  { postalCode: '19144', city: 'SOLLENTUNA' },
  { postalCode: '19145', city: 'SOLLENTUNA' },
  { postalCode: '19146', city: 'SOLLENTUNA' },
  { postalCode: '19147', city: 'SOLLENTUNA' },
  { postalCode: '19148', city: 'SOLLENTUNA' },
  { postalCode: '19149', city: 'SOLLENTUNA' },
  { postalCode: '19150', city: 'SOLLENTUNA' },
  { postalCode: '19160', city: 'SOLLENTUNA' },
  { postalCode: '19161', city: 'SOLLENTUNA' },
  { postalCode: '19162', city: 'SOLLENTUNA' },
  { postalCode: '19163', city: 'SOLLENTUNA' },
  { postalCode: '19164', city: 'SOLLENTUNA' },
  { postalCode: '19181', city: 'SOLLENTUNA' },
  { postalCode: '19183', city: 'SOLLENTUNA' },
  { postalCode: '19184', city: 'SOLLENTUNA' },
  { postalCode: '19185', city: 'SOLLENTUNA' },
  { postalCode: '19186', city: 'SOLLENTUNA' },
  { postalCode: '19189', city: 'SOLLENTUNA' },
  { postalCode: '19190', city: 'SOLLENTUNA' },
  { postalCode: '19200', city: 'SOLLENTUNA' },
  { postalCode: '19205', city: 'SOLLENTUNA' },
  { postalCode: '19206', city: 'SOLLENTUNA' },
  { postalCode: '19207', city: 'SOLLENTUNA' },
  { postalCode: '19208', city: 'SOLLENTUNA' },
  { postalCode: '19220', city: 'SOLLENTUNA' },
  { postalCode: '19221', city: 'SOLLENTUNA' },
  { postalCode: '19232', city: 'SOLLENTUNA' },
  { postalCode: '19248', city: 'SOLLENTUNA' },
  { postalCode: '19251', city: 'SOLLENTUNA' },
  { postalCode: '19252', city: 'SOLLENTUNA' },
  { postalCode: '19253', city: 'SOLLENTUNA' },
  { postalCode: '19254', city: 'SOLLENTUNA' },
  { postalCode: '19255', city: 'SOLLENTUNA' },
  { postalCode: '19256', city: 'SOLLENTUNA' },
  { postalCode: '19257', city: 'SOLLENTUNA' },
  { postalCode: '19258', city: 'SOLLENTUNA' },
  { postalCode: '19259', city: 'SOLLENTUNA' },
  { postalCode: '19265', city: 'SOLLENTUNA' },
  { postalCode: '19266', city: 'SOLLENTUNA' },
  { postalCode: '19267', city: 'SOLLENTUNA' },
  { postalCode: '19268', city: 'SOLLENTUNA' },
  { postalCode: '19269', city: 'SOLLENTUNA' },
  { postalCode: '19270', city: 'SOLLENTUNA' },
  { postalCode: '19271', city: 'SOLLENTUNA' },
  { postalCode: '19272', city: 'SOLLENTUNA' },
  { postalCode: '19273', city: 'SOLLENTUNA' },
  { postalCode: '19274', city: 'SOLLENTUNA' },
  { postalCode: '19275', city: 'SOLLENTUNA' },
  { postalCode: '19276', city: 'SOLLENTUNA' },
  { postalCode: '19277', city: 'SOLLENTUNA' },
  { postalCode: '19278', city: 'SOLLENTUNA' },
  { postalCode: '19279', city: 'SOLLENTUNA' },
  { postalCode: '19300', city: 'SIGTUNA' },
  { postalCode: '19320', city: 'SIGTUNA' },
  { postalCode: '19321', city: 'SIGTUNA' },
  { postalCode: '19322', city: 'SIGTUNA' },
  { postalCode: '19323', city: 'SIGTUNA' },
  { postalCode: '19328', city: 'SIGTUNA' },
  { postalCode: '19330', city: 'SIGTUNA' },
  { postalCode: '19331', city: 'SIGTUNA' },
  { postalCode: '19332', city: 'SIGTUNA' },
  { postalCode: '19333', city: 'SIGTUNA' },
  { postalCode: '19334', city: 'SIGTUNA' },
  { postalCode: '19335', city: 'SIGTUNA' },
  { postalCode: '19336', city: 'SIGTUNA' },
  { postalCode: '19337', city: 'SIGTUNA' },
  { postalCode: '19340', city: 'SIGTUNA' },
  { postalCode: '19341', city: 'SIGTUNA' },
  { postalCode: '19391', city: 'SIGTUNA' },
  { postalCode: '19400', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19402', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19404', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19405', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19410', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19417', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19419', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19420', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19421', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19422', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19423', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19426', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19427', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19428', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19429', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19430', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19431', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19432', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19433', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19434', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19435', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19436', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19437', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19438', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19439', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19440', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19441', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19442', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19443', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19444', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19445', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19446', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19447', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19449', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19451', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19452', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19453', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19454', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19455', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19456', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19457', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19460', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19461', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19462', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19463', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19464', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19465', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19466', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19467', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19468', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19469', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19470', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19471', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19472', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19473', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19474', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19475', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19476', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19477', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19478', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19479', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19480', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19481', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19482', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19483', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19484', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19486', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19487', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19488', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19489', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19491', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19492', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19498', city: 'UPPLANDS VÄSBY' },
  { postalCode: '19500', city: 'MÄRSTA' },
  { postalCode: '19502', city: 'MÄRSTA' },
  { postalCode: '19503', city: 'MÄRSTA' },
  { postalCode: '19504', city: 'ROSERSBERG' },
  { postalCode: '19505', city: 'ARLANDASTAD' },
  { postalCode: '19506', city: 'MÄRSTA' },
  { postalCode: '19518', city: 'MÄRSTA' },
  { postalCode: '19519', city: 'MÄRSTA' },
  { postalCode: '19520', city: 'MÄRSTA' },
  { postalCode: '19521', city: 'MÄRSTA' },
  { postalCode: '19522', city: 'MÄRSTA' },
  { postalCode: '19523', city: 'MÄRSTA' },
  { postalCode: '19524', city: 'MÄRSTA' },
  { postalCode: '19525', city: 'MÄRSTA' },
  { postalCode: '19530', city: 'MÄRSTA' },
  { postalCode: '19531', city: 'MÄRSTA' },
  { postalCode: '19532', city: 'MÄRSTA' },
  { postalCode: '19533', city: 'MÄRSTA' },
  { postalCode: '19534', city: 'MÄRSTA' },
  { postalCode: '19535', city: 'MÄRSTA' },
  { postalCode: '19536', city: 'MÄRSTA' },
  { postalCode: '19540', city: 'MÄRSTA' },
  { postalCode: '19541', city: 'MÄRSTA' },
  { postalCode: '19542', city: 'MÄRSTA' },
  { postalCode: '19543', city: 'MÄRSTA' },
  { postalCode: '19544', city: 'MÄRSTA' },
  { postalCode: '19545', city: 'MÄRSTA' },
  { postalCode: '19546', city: 'MÄRSTA' },
  { postalCode: '19547', city: 'MÄRSTA' },
  { postalCode: '19550', city: 'MÄRSTA' },
  { postalCode: '19551', city: 'MÄRSTA' },
  { postalCode: '19552', city: 'MÄRSTA' },
  { postalCode: '19553', city: 'MÄRSTA' },
  { postalCode: '19554', city: 'MÄRSTA' },
  { postalCode: '19555', city: 'MÄRSTA' },
  { postalCode: '19556', city: 'MÄRSTA' },
  { postalCode: '19557', city: 'MÄRSTA' },
  { postalCode: '19558', city: 'MÄRSTA' },
  { postalCode: '19559', city: 'MÄRSTA' },
  { postalCode: '19560', city: 'ARLANDASTAD' },
  { postalCode: '19561', city: 'ARLANDASTAD' },
  { postalCode: '19565', city: 'STENINGEHÖJDEN' },
  { postalCode: '19570', city: 'ROSERSBERG' },
  { postalCode: '19571', city: 'ROSERSBERG' },
  { postalCode: '19572', city: 'ROSERSBERG' },
  { postalCode: '19580', city: 'MÄRSTA' },
  { postalCode: '19581', city: 'MÄRSTA' },
  { postalCode: '19582', city: 'MÄRSTA' },
  { postalCode: '19583', city: 'MÄRSTA' },
  { postalCode: '19585', city: 'MÄRSTA' },
  { postalCode: '19587', city: 'STOCKHOLM' },
  { postalCode: '19591', city: 'MÄRSTA' },
  { postalCode: '19592', city: 'MÄRSTA' },
  { postalCode: '19593', city: 'MÄRSTA' },
  { postalCode: '19594', city: 'MÄRSTA' },
  { postalCode: '19595', city: 'ROSERSBERG' },
  { postalCode: '19596', city: 'ROSERSBERG' },
  { postalCode: '19600', city: 'KUNGSÄNGEN' },
  { postalCode: '19602', city: 'KUNGSÄNGEN' },
  { postalCode: '19620', city: 'KUNGSÄNGEN' },
  { postalCode: '19621', city: 'KUNGSÄNGEN' },
  { postalCode: '19622', city: 'KUNGSÄNGEN' },
  { postalCode: '19623', city: 'KUNGSÄNGEN' },
  { postalCode: '19630', city: 'KUNGSÄNGEN' },
  { postalCode: '19631', city: 'KUNGSÄNGEN' },
  { postalCode: '19632', city: 'KUNGSÄNGEN' },
  { postalCode: '19633', city: 'KUNGSÄNGEN' },
  { postalCode: '19634', city: 'KUNGSÄNGEN' },
  { postalCode: '19635', city: 'KUNGSÄNGEN' },
  { postalCode: '19636', city: 'KUNGSÄNGEN' },
  { postalCode: '19637', city: 'KUNGSÄNGEN' },
  { postalCode: '19638', city: 'KUNGSÄNGEN' },
  { postalCode: '19639', city: 'KUNGSÄNGEN' },
  { postalCode: '19640', city: 'KUNGSÄNGEN' },
  { postalCode: '19641', city: 'KUNGSÄNGEN' },
  { postalCode: '19681', city: 'KUNGSÄNGEN' },
  { postalCode: '19685', city: 'KUNGSÄNGEN' },
  { postalCode: '19691', city: 'KUNGSÄNGEN' },
  { postalCode: '19692', city: 'KUNGSÄNGEN' },
  { postalCode: '19693', city: 'KUNGSÄNGEN' },
  { postalCode: '19700', city: 'BRO' },
  { postalCode: '19720', city: 'BRO' },
  { postalCode: '19721', city: 'BRO' },
  { postalCode: '19722', city: 'BRO' },
  { postalCode: '19730', city: 'BRO' },
  { postalCode: '19731', city: 'BRO' },
  { postalCode: '19732', city: 'BRO' },
  { postalCode: '19733', city: 'BRO' },
  { postalCode: '19734', city: 'BRO' },
  { postalCode: '19735', city: 'BRO' },
  { postalCode: '19736', city: 'BRO' },
  { postalCode: '19740', city: 'BRO' },
  { postalCode: '19791', city: 'BRO' },
  { postalCode: '19792', city: 'BRO' },
  { postalCode: '19793', city: 'BRO' },
  { postalCode: '20001', city: 'MALMÖ' },
  { postalCode: '20005', city: 'MALMÖ' },
  { postalCode: '20010', city: 'MALMÖ' },
  { postalCode: '20011', city: 'MALMÖ' },
  { postalCode: '20021', city: 'MALMÖ' },
  { postalCode: '20022', city: 'MALMÖ' },
  { postalCode: '20024', city: 'MALMÖ' },
  { postalCode: '20025', city: 'MALMÖ' },
  { postalCode: '20031', city: 'MALMÖ' },
  { postalCode: '20032', city: 'MALMÖ' },
  { postalCode: '20039', city: 'MALMÖ' },
  { postalCode: '20041', city: 'MALMÖ' },
  { postalCode: '20042', city: 'MALMÖ' },
  { postalCode: '20043', city: 'MALMÖ' },
  { postalCode: '20045', city: 'MALMÖ' },
  { postalCode: '20049', city: 'MALMÖ' },
  { postalCode: '20061', city: 'LIMHAMN' },
  { postalCode: '20062', city: 'LIMHAMN' },
  { postalCode: '20071', city: 'MALMÖ' },
  { postalCode: '20072', city: 'MALMÖ' },
  { postalCode: '20073', city: 'MALMÖ' },
  { postalCode: '20074', city: 'MALMÖ' },
  { postalCode: '20110', city: 'MALMÖ' },
  { postalCode: '20120', city: 'MALMÖ' },
  { postalCode: '20121', city: 'MALMÖ' },
  { postalCode: '20122', city: 'MALMÖ' },
  { postalCode: '20123', city: 'MALMÖ' },
  { postalCode: '20124', city: 'MALMÖ' },
  { postalCode: '20125', city: 'MALMÖ' },
  { postalCode: '20180', city: 'MALMÖ' },
  { postalCode: '20210', city: 'MALMÖ' },
  { postalCode: '20211', city: 'MALMÖ' },
  { postalCode: '20212', city: 'MALMÖ' },
  { postalCode: '20213', city: 'MALMÖ' },
  { postalCode: '20214', city: 'MALMÖ' },
  { postalCode: '20215', city: 'MALMÖ' },
  { postalCode: '20220', city: 'MALMÖ' },
  { postalCode: '20221', city: 'MALMÖ' },
  { postalCode: '20222', city: 'MALMÖ' },
  { postalCode: '20223', city: 'MALMÖ' },
  { postalCode: '20224', city: 'MALMÖ' },
  { postalCode: '20225', city: 'MALMÖ' },
  { postalCode: '20226', city: 'MALMÖ' },
  { postalCode: '20227', city: 'MALMÖ' },
  { postalCode: '20228', city: 'MALMÖ' },
  { postalCode: '20229', city: 'MALMÖ' },
  { postalCode: '20230', city: 'MALMÖ' },
  { postalCode: '20240', city: 'MALMÖ' },
  { postalCode: '20250', city: 'MALMÖ' },
  { postalCode: '20260', city: 'MALMÖ' },
  { postalCode: '20270', city: 'MALMÖ' },
  { postalCode: '20271', city: 'MALMÖ' },
  { postalCode: '20280', city: 'MALMÖ' },
  { postalCode: '20310', city: 'MALMÖ' },
  { postalCode: '20311', city: 'MALMÖ' },
  { postalCode: '20312', city: 'MALMÖ' },
  { postalCode: '20313', city: 'MALMÖ' },
  { postalCode: '20314', city: 'MALMÖ' },
  { postalCode: '20315', city: 'MALMÖ' },
  { postalCode: '20320', city: 'MALMÖ' },
  { postalCode: '20501', city: 'MALMÖ' },
  { postalCode: '20502', city: 'MALMÖ' },
  { postalCode: '20503', city: 'MALMÖ' },
  { postalCode: '20504', city: 'MALMÖ' },
  { postalCode: '20505', city: 'MALMÖ' },
  { postalCode: '20506', city: 'MALMÖ' },
  { postalCode: '20507', city: 'MALMÖ' },
  { postalCode: '20508', city: 'MALMÖ' },
  { postalCode: '20509', city: 'MALMÖ' },
  { postalCode: '20510', city: 'MALMÖ' },
  { postalCode: '20511', city: 'MALMÖ' },
  { postalCode: '20512', city: 'MALMÖ' },
  { postalCode: '20513', city: 'MALMÖ' },
  { postalCode: '20514', city: 'MALMÖ' },
  { postalCode: '20515', city: 'MALMÖ' },
  { postalCode: '20516', city: 'MALMÖ' },
  { postalCode: '20517', city: 'MALMÖ' },
  { postalCode: '20518', city: 'MALMÖ' },
  { postalCode: '20519', city: 'MALMÖ' },
  { postalCode: '20520', city: 'MALMÖ' },
  { postalCode: '20521', city: 'MALMÖ' },
  { postalCode: '20522', city: 'MALMÖ' },
  { postalCode: '20523', city: 'MALMÖ' },
  { postalCode: '20525', city: 'MALMÖ' },
  { postalCode: '20526', city: 'MALMÖ' },
  { postalCode: '20528', city: 'MALMÖ' },
  { postalCode: '20529', city: 'MALMÖ' },
  { postalCode: '20530', city: 'MALMÖ' },
  { postalCode: '20531', city: 'MALMÖ' },
  { postalCode: '20533', city: 'MALMÖ' },
  { postalCode: '20535', city: 'MALMÖ' },
  { postalCode: '20536', city: 'MALMÖ' },
  { postalCode: '20537', city: 'MALMÖ' },
  { postalCode: '20538', city: 'MALMÖ' },
  { postalCode: '20539', city: 'MALMÖ' },
  { postalCode: '20540', city: 'MALMÖ' },
  { postalCode: '20541', city: 'MALMÖ' },
  { postalCode: '20542', city: 'MALMÖ' },
  { postalCode: '20543', city: 'MALMÖ' },
  { postalCode: '20544', city: 'MALMÖ' },
  { postalCode: '20545', city: 'MALMÖ' },
  { postalCode: '20547', city: 'MALMÖ' },
  { postalCode: '20550', city: 'MALMÖ' },
  { postalCode: '20552', city: 'MALMÖ' },
  { postalCode: '20555', city: 'MALMÖ' },
  { postalCode: '20556', city: 'MALMÖ' },
  { postalCode: '20557', city: 'MALMÖ' },
  { postalCode: '20558', city: 'MALMÖ' },
  { postalCode: '20559', city: 'MALMÖ' },
  { postalCode: '20560', city: 'MALMÖ' },
  { postalCode: '20565', city: 'MALMÖ' },
  { postalCode: '20570', city: 'MALMÖ' },
  { postalCode: '20574', city: 'MALMÖ' },
  { postalCode: '20575', city: 'MALMÖ' },
  { postalCode: '20576', city: 'MALMÖ' },
  { postalCode: '20578', city: 'MALMÖ' },
  { postalCode: '20579', city: 'MALMÖ' },
  { postalCode: '20580', city: 'MALMÖ' },
  { postalCode: '20581', city: 'MALMÖ' },
  { postalCode: '20590', city: 'MALMÖ' },
  { postalCode: '20595', city: 'MALMÖ' },
  { postalCode: '20701', city: 'MALMÖ' },
  { postalCode: '20702', city: 'MALMÖ' },
  { postalCode: '20703', city: 'MALMÖ' },
  { postalCode: '20704', city: 'MALMÖ' },
  { postalCode: '20705', city: 'MALMÖ' },
  { postalCode: '20706', city: 'MALMÖ' },
  { postalCode: '20707', city: 'MALMÖ' },
  { postalCode: '20708', city: 'MALMÖ' },
  { postalCode: '20709', city: 'MALMÖ' },
  { postalCode: '20710', city: 'MALMÖ' },
  { postalCode: '20711', city: 'MALMÖ' },
  { postalCode: '20712', city: 'MALMÖ' },
  { postalCode: '20713', city: 'MALMÖ' },
  { postalCode: '20714', city: 'MALMÖ' },
  { postalCode: '20715', city: 'MALMÖ' },
  { postalCode: '20716', city: 'MALMÖ' },
  { postalCode: '20717', city: 'MALMÖ' },
  { postalCode: '20718', city: 'MALMÖ' },
  { postalCode: '20719', city: 'MALMÖ' },
  { postalCode: '20720', city: 'MALMÖ' },
  { postalCode: '20721', city: 'MALMÖ' },
  { postalCode: '20722', city: 'MALMÖ' },
  { postalCode: '20723', city: 'MALMÖ' },
  { postalCode: '20724', city: 'MALMÖ' },
  { postalCode: '20725', city: 'MALMÖ' },
  { postalCode: '20726', city: 'MALMÖ' },
  { postalCode: '20727', city: 'MALMÖ' },
  { postalCode: '20728', city: 'MALMÖ' },
  { postalCode: '20791', city: 'MALMÖ' },
  { postalCode: '20792', city: 'MALMÖ' },
  { postalCode: '20794', city: 'MALMÖ' },
  { postalCode: '20795', city: 'MALMÖ' },
  { postalCode: '20797', city: 'MALMÖ' },
  { postalCode: '20798', city: 'MALMÖ' },
  { postalCode: '20799', city: 'MALMÖ' },
  { postalCode: '20800', city: 'MALMÖ' },
  { postalCode: '20801', city: 'MALMÖ' },
  { postalCode: '20802', city: 'MALMÖ' },
  { postalCode: '20805', city: 'MALMÖ' },
  { postalCode: '20810', city: 'MALMÖ' },
  { postalCode: '20813', city: 'MALMÖ' },
  { postalCode: '20815', city: 'MALMÖ' },
  { postalCode: '20818', city: 'MALMÖ' },
  { postalCode: '20820', city: 'MALMÖ' },
  { postalCode: '20821', city: 'MALMÖ' },
  { postalCode: '20824', city: 'MALMÖ' },
  { postalCode: '20830', city: 'MALMÖ' },
  { postalCode: '20831', city: 'MALMÖ' },
  { postalCode: '20834', city: 'MALMÖ' },
  { postalCode: '20850', city: 'MALMÖ' },
  { postalCode: '20860', city: 'MALMÖ' },
  { postalCode: '20867', city: 'MALMÖ' },
  { postalCode: '20870', city: 'MALMÖ' },
  { postalCode: '20891', city: 'MALMÖ' },
  { postalCode: '20897', city: 'MALMÖ' },
  { postalCode: '20900', city: 'MALMÖ' },
  { postalCode: '20901', city: 'MALMÖ' },
  { postalCode: '20902', city: 'MALMÖ' },
  { postalCode: '20903', city: 'MALMÖ' },
  { postalCode: '20904', city: 'MALMÖ' },
  { postalCode: '20905', city: 'MALMÖ' },
  { postalCode: '20906', city: 'MALMÖ' },
  { postalCode: '20907', city: 'MALMÖ' },
  { postalCode: '20908', city: 'MALMÖ' },
  { postalCode: '20909', city: 'MALMÖ' },
  { postalCode: '20910', city: 'MALMÖ' },
  { postalCode: '20911', city: 'MALMÖ' },
  { postalCode: '20912', city: 'MALMÖ' },
  { postalCode: '20913', city: 'MALMÖ' },
  { postalCode: '20914', city: 'MALMÖ' },
  { postalCode: '20915', city: 'MALMÖ' },
  { postalCode: '20916', city: 'MALMÖ' },
  { postalCode: '20917', city: 'MALMÖ' },
  { postalCode: '20918', city: 'MALMÖ' },
  { postalCode: '20919', city: 'MALMÖ' },
  { postalCode: '20920', city: 'MALMÖ' },
  { postalCode: '20921', city: 'MALMÖ' },
  { postalCode: '20922', city: 'MALMÖ' },
  { postalCode: '20923', city: 'MALMÖ' },
  { postalCode: '20924', city: 'MALMÖ' },
  { postalCode: '20925', city: 'MALMÖ' },
  { postalCode: '20926', city: 'MALMÖ' },
  { postalCode: '20927', city: 'MALMÖ' },
  { postalCode: '20928', city: 'MALMÖ' },
  { postalCode: '20929', city: 'MALMÖ' },
  { postalCode: '20930', city: 'MALMÖ' },
  { postalCode: '20931', city: 'MALMÖ' },
  { postalCode: '20932', city: 'MALMÖ' },
  { postalCode: '20933', city: 'MALMÖ' },
  { postalCode: '20934', city: 'MALMÖ' },
  { postalCode: '20935', city: 'MALMÖ' },
  { postalCode: '20936', city: 'MALMÖ' },
  { postalCode: '20937', city: 'MALMÖ' },
  { postalCode: '20938', city: 'MALMÖ' },
  { postalCode: '20939', city: 'MALMÖ' },
  { postalCode: '20940', city: 'MALMÖ' },
  { postalCode: '20941', city: 'MALMÖ' },
  { postalCode: '20942', city: 'MALMÖ' },
  { postalCode: '20943', city: 'MALMÖ' },
  { postalCode: '20944', city: 'MALMÖ' },
  { postalCode: '20945', city: 'MALMÖ' },
  { postalCode: '20946', city: 'MALMÖ' },
  { postalCode: '20947', city: 'MALMÖ' },
  { postalCode: '20948', city: 'MALMÖ' },
  { postalCode: '20949', city: 'MALMÖ' },
  { postalCode: '20950', city: 'MALMÖ' },
  { postalCode: '20951', city: 'MALMÖ' },
  { postalCode: '20952', city: 'MALMÖ' },
  { postalCode: '20953', city: 'MALMÖ' },
  { postalCode: '20954', city: 'MALMÖ' },
  { postalCode: '20955', city: 'MALMÖ' },
  { postalCode: '20956', city: 'MALMÖ' },
  { postalCode: '20957', city: 'MALMÖ' },
  { postalCode: '20958', city: 'MALMÖ' },
  { postalCode: '20959', city: 'MALMÖ' },
  { postalCode: '20960', city: 'MALMÖ' },
  { postalCode: '20961', city: 'MALMÖ' },
  { postalCode: '20962', city: 'MALMÖ' },
  { postalCode: '20963', city: 'MALMÖ' },
  { postalCode: '20964', city: 'MALMÖ' },
  { postalCode: '20965', city: 'MALMÖ' },
  { postalCode: '20966', city: 'MALMÖ' },
  { postalCode: '20967', city: 'MALMÖ' },
  { postalCode: '20968', city: 'MALMÖ' },
  { postalCode: '20969', city: 'MALMÖ' },
  { postalCode: '20970', city: 'MALMÖ' },
  { postalCode: '20971', city: 'MALMÖ' },
  { postalCode: '20972', city: 'MALMÖ' },
  { postalCode: '20973', city: 'MALMÖ' },
  { postalCode: '20974', city: 'MALMÖ' },
  { postalCode: '20975', city: 'MALMÖ' },
  { postalCode: '20976', city: 'MALMÖ' },
  { postalCode: '20977', city: 'MALMÖ' },
  { postalCode: '20978', city: 'MALMÖ' },
  { postalCode: '20979', city: 'MALMÖ' },
  { postalCode: '20980', city: 'MALMÖ' },
  { postalCode: '20981', city: 'MALMÖ' },
  { postalCode: '20982', city: 'MALMÖ' },
  { postalCode: '20983', city: 'MALMÖ' },
  { postalCode: '20984', city: 'MALMÖ' },
  { postalCode: '20985', city: 'MALMÖ' },
  { postalCode: '20986', city: 'MALMÖ' },
  { postalCode: '20987', city: 'MALMÖ' },
  { postalCode: '20988', city: 'MALMÖ' },
  { postalCode: '20989', city: 'MALMÖ' },
  { postalCode: '20990', city: 'MALMÖ' },
  { postalCode: '20991', city: 'MALMÖ' },
  { postalCode: '20992', city: 'MALMÖ' },
  { postalCode: '20993', city: 'MALMÖ' },
  { postalCode: '20994', city: 'MALMÖ' },
  { postalCode: '20995', city: 'MALMÖ' },
  { postalCode: '20996', city: 'MALMÖ' },
  { postalCode: '20997', city: 'MALMÖ' },
  { postalCode: '20998', city: 'MALMÖ' },
  { postalCode: '20999', city: 'MALMÖ' },
  { postalCode: '21100', city: 'MALMÖ' },
  { postalCode: '21101', city: 'MALMÖ' },
  { postalCode: '21105', city: 'MALMÖ' },
  { postalCode: '21111', city: 'MALMÖ' },
  { postalCode: '21112', city: 'MALMÖ' },
  { postalCode: '21113', city: 'MALMÖ' },
  { postalCode: '21114', city: 'MALMÖ' },
  { postalCode: '21115', city: 'MALMÖ' },
  { postalCode: '21116', city: 'MALMÖ' },
  { postalCode: '21117', city: 'MALMÖ' },
  { postalCode: '21118', city: 'MALMÖ' },
  { postalCode: '21119', city: 'MALMÖ' },
  { postalCode: '21120', city: 'MALMÖ' },
  { postalCode: '21121', city: 'MALMÖ' },
  { postalCode: '21122', city: 'MALMÖ' },
  { postalCode: '21124', city: 'MALMÖ' },
  { postalCode: '21125', city: 'MALMÖ' },
  { postalCode: '21126', city: 'MALMÖ' },
  { postalCode: '21127', city: 'MALMÖ' },
  { postalCode: '21128', city: 'MALMÖ' },
  { postalCode: '21129', city: 'MALMÖ' },
  { postalCode: '21130', city: 'MALMÖ' },
  { postalCode: '21131', city: 'MALMÖ' },
  { postalCode: '21132', city: 'MALMÖ' },
  { postalCode: '21133', city: 'MALMÖ' },
  { postalCode: '21134', city: 'MALMÖ' },
  { postalCode: '21135', city: 'MALMÖ' },
  { postalCode: '21136', city: 'MALMÖ' },
  { postalCode: '21137', city: 'MALMÖ' },
  { postalCode: '21138', city: 'MALMÖ' },
  { postalCode: '21139', city: 'MALMÖ' },
  { postalCode: '21140', city: 'MALMÖ' },
  { postalCode: '21141', city: 'MALMÖ' },
  { postalCode: '21142', city: 'MALMÖ' },
  { postalCode: '21143', city: 'MALMÖ' },
  { postalCode: '21144', city: 'MALMÖ' },
  { postalCode: '21145', city: 'MALMÖ' },
  { postalCode: '21146', city: 'MALMÖ' },
  { postalCode: '21147', city: 'MALMÖ' },
  { postalCode: '21148', city: 'MALMÖ' },
  { postalCode: '21149', city: 'MALMÖ' },
  { postalCode: '21150', city: 'MALMÖ' },
  { postalCode: '21151', city: 'MALMÖ' },
  { postalCode: '21152', city: 'MALMÖ' },
  { postalCode: '21153', city: 'MALMÖ' },
  { postalCode: '21154', city: 'MALMÖ' },
  { postalCode: '21155', city: 'MALMÖ' },
  { postalCode: '21156', city: 'MALMÖ' },
  { postalCode: '21157', city: 'MALMÖ' },
  { postalCode: '21158', city: 'MALMÖ' },
  { postalCode: '21159', city: 'MALMÖ' },
  { postalCode: '21160', city: 'MALMÖ' },
  { postalCode: '21165', city: 'MALMÖ' },
  { postalCode: '21171', city: 'MALMÖ' },
  { postalCode: '21173', city: 'MALMÖ' },
  { postalCode: '21174', city: 'MALMÖ' },
  { postalCode: '21175', city: 'MALMÖ' },
  { postalCode: '21176', city: 'MALMÖ' },
  { postalCode: '21177', city: 'MALMÖ' },
  { postalCode: '21200', city: 'MALMÖ' },
  { postalCode: '21201', city: 'MALMÖ' },
  { postalCode: '21206', city: 'MALMÖ' },
  { postalCode: '21209', city: 'MALMÖ' },
  { postalCode: '21210', city: 'MALMÖ' },
  { postalCode: '21211', city: 'MALMÖ' },
  { postalCode: '21212', city: 'MALMÖ' },
  { postalCode: '21213', city: 'MALMÖ' },
  { postalCode: '21214', city: 'MALMÖ' },
  { postalCode: '21215', city: 'MALMÖ' },
  { postalCode: '21216', city: 'MALMÖ' },
  { postalCode: '21217', city: 'MALMÖ' },
  { postalCode: '21218', city: 'MALMÖ' },
  { postalCode: '21219', city: 'MALMÖ' },
  { postalCode: '21220', city: 'MALMÖ' },
  { postalCode: '21221', city: 'MALMÖ' },
  { postalCode: '21222', city: 'MALMÖ' },
  { postalCode: '21223', city: 'MALMÖ' },
  { postalCode: '21224', city: 'MALMÖ' },
  { postalCode: '21225', city: 'MALMÖ' },
  { postalCode: '21226', city: 'MALMÖ' },
  { postalCode: '21227', city: 'MALMÖ' },
  { postalCode: '21228', city: 'MALMÖ' },
  { postalCode: '21229', city: 'MALMÖ' },
  { postalCode: '21230', city: 'MALMÖ' },
  { postalCode: '21231', city: 'MALMÖ' },
  { postalCode: '21232', city: 'MALMÖ' },
  { postalCode: '21233', city: 'MALMÖ' },
  { postalCode: '21234', city: 'MALMÖ' },
  { postalCode: '21235', city: 'MALMÖ' },
  { postalCode: '21236', city: 'MALMÖ' },
  { postalCode: '21237', city: 'MALMÖ' },
  { postalCode: '21238', city: 'MALMÖ' },
  { postalCode: '21239', city: 'MALMÖ' },
  { postalCode: '21240', city: 'MALMÖ' },
  { postalCode: '21241', city: 'MALMÖ' },
  { postalCode: '21242', city: 'MALMÖ' },
  { postalCode: '21243', city: 'MALMÖ' },
  { postalCode: '21244', city: 'MALMÖ' },
  { postalCode: '21245', city: 'MALMÖ' },
  { postalCode: '21246', city: 'MALMÖ' },
  { postalCode: '21250', city: 'MALMÖ' },
  { postalCode: '21251', city: 'MALMÖ' },
  { postalCode: '21252', city: 'MALMÖ' },
  { postalCode: '21291', city: 'MALMÖ' },
  { postalCode: '21300', city: 'MALMÖ' },
  { postalCode: '21361', city: 'MALMÖ' },
  { postalCode: '21362', city: 'MALMÖ' },
  { postalCode: '21363', city: 'MALMÖ' },
  { postalCode: '21364', city: 'MALMÖ' },
  { postalCode: '21365', city: 'MALMÖ' },
  { postalCode: '21366', city: 'MALMÖ' },
  { postalCode: '21367', city: 'MALMÖ' },
  { postalCode: '21368', city: 'MALMÖ' },
  { postalCode: '21369', city: 'MALMÖ' },
  { postalCode: '21370', city: 'MALMÖ' },
  { postalCode: '21371', city: 'MALMÖ' },
  { postalCode: '21372', city: 'MALMÖ' },
  { postalCode: '21373', city: 'MALMÖ' },
  { postalCode: '21374', city: 'MALMÖ' },
  { postalCode: '21375', city: 'MALMÖ' },
  { postalCode: '21376', city: 'MALMÖ' },
  { postalCode: '21377', city: 'MALMÖ' },
  { postalCode: '21400', city: 'MALMÖ' },
  { postalCode: '21402', city: 'MALMÖ' },
  { postalCode: '21410', city: 'MALMÖ' },
  { postalCode: '21413', city: 'MALMÖ' },
  { postalCode: '21420', city: 'MALMÖ' },
  { postalCode: '21421', city: 'MALMÖ' },
  { postalCode: '21422', city: 'MALMÖ' },
  { postalCode: '21423', city: 'MALMÖ' },
  { postalCode: '21424', city: 'MALMÖ' },
  { postalCode: '21425', city: 'MALMÖ' },
  { postalCode: '21426', city: 'MALMÖ' },
  { postalCode: '21427', city: 'MALMÖ' },
  { postalCode: '21428', city: 'MALMÖ' },
  { postalCode: '21429', city: 'MALMÖ' },
  { postalCode: '21430', city: 'MALMÖ' },
  { postalCode: '21431', city: 'MALMÖ' },
  { postalCode: '21432', city: 'MALMÖ' },
  { postalCode: '21433', city: 'MALMÖ' },
  { postalCode: '21434', city: 'MALMÖ' },
  { postalCode: '21435', city: 'MALMÖ' },
  { postalCode: '21436', city: 'MALMÖ' },
  { postalCode: '21437', city: 'MALMÖ' },
  { postalCode: '21438', city: 'MALMÖ' },
  { postalCode: '21439', city: 'MALMÖ' },
  { postalCode: '21440', city: 'MALMÖ' },
  { postalCode: '21441', city: 'MALMÖ' },
  { postalCode: '21442', city: 'MALMÖ' },
  { postalCode: '21443', city: 'MALMÖ' },
  { postalCode: '21444', city: 'MALMÖ' },
  { postalCode: '21445', city: 'MALMÖ' },
  { postalCode: '21446', city: 'MALMÖ' },
  { postalCode: '21447', city: 'MALMÖ' },
  { postalCode: '21448', city: 'MALMÖ' },
  { postalCode: '21449', city: 'MALMÖ' },
  { postalCode: '21450', city: 'MALMÖ' },
  { postalCode: '21454', city: 'MALMÖ' },
  { postalCode: '21455', city: 'MALMÖ' },
  { postalCode: '21456', city: 'MALMÖ' },
  { postalCode: '21457', city: 'MALMÖ' },
  { postalCode: '21458', city: 'MALMÖ' },
  { postalCode: '21459', city: 'MALMÖ' },
  { postalCode: '21463', city: 'MALMÖ' },
  { postalCode: '21466', city: 'MALMÖ' },
  { postalCode: '21467', city: 'MALMÖ' },
  { postalCode: '21500', city: 'MALMÖ' },
  { postalCode: '21526', city: 'MALMÖ' },
  { postalCode: '21527', city: 'MALMÖ' },
  { postalCode: '21532', city: 'MALMÖ' },
  { postalCode: '21533', city: 'MALMÖ' },
  { postalCode: '21534', city: 'MALMÖ' },
  { postalCode: '21535', city: 'MALMÖ' },
  { postalCode: '21536', city: 'MALMÖ' },
  { postalCode: '21537', city: 'MALMÖ' },
  { postalCode: '21550', city: 'MALMÖ' },
  { postalCode: '21551', city: 'MALMÖ' },
  { postalCode: '21552', city: 'MALMÖ' },
  { postalCode: '21553', city: 'MALMÖ' },
  { postalCode: '21558', city: 'MALMÖ' },
  { postalCode: '21559', city: 'MALMÖ' },
  { postalCode: '21560', city: 'MALMÖ' },
  { postalCode: '21561', city: 'MALMÖ' },
  { postalCode: '21562', city: 'MALMÖ' },
  { postalCode: '21563', city: 'MALMÖ' },
  { postalCode: '21564', city: 'MALMÖ' },
  { postalCode: '21565', city: 'MALMÖ' },
  { postalCode: '21566', city: 'MALMÖ' },
  { postalCode: '21567', city: 'MALMÖ' },
  { postalCode: '21568', city: 'MALMÖ' },
  { postalCode: '21569', city: 'MALMÖ' },
  { postalCode: '21570', city: 'MALMÖ' },
  { postalCode: '21571', city: 'MALMÖ' },
  { postalCode: '21572', city: 'MALMÖ' },
  { postalCode: '21573', city: 'MALMÖ' },
  { postalCode: '21574', city: 'MALMÖ' },
  { postalCode: '21578', city: 'MALMÖ' },
  { postalCode: '21579', city: 'MALMÖ' },
  { postalCode: '21580', city: 'MALMÖ' },
  { postalCode: '21581', city: 'MALMÖ' },
  { postalCode: '21582', city: 'MALMÖ' },
  { postalCode: '21583', city: 'MALMÖ' },
  { postalCode: '21584', city: 'MALMÖ' },
  { postalCode: '21585', city: 'MALMÖ' },
  { postalCode: '21586', city: 'MALMÖ' },
  { postalCode: '21600', city: 'LIMHAMN' },
  { postalCode: '21608', city: 'LIMHAMN' },
  { postalCode: '21609', city: 'LIMHAMN' },
  { postalCode: '21610', city: 'LIMHAMN' },
  { postalCode: '21611', city: 'LIMHAMN' },
  { postalCode: '21612', city: 'LIMHAMN' },
  { postalCode: '21613', city: 'LIMHAMN' },
  { postalCode: '21614', city: 'LIMHAMN' },
  { postalCode: '21615', city: 'LIMHAMN' },
  { postalCode: '21616', city: 'LIMHAMN' },
  { postalCode: '21617', city: 'LIMHAMN' },
  { postalCode: '21618', city: 'LIMHAMN' },
  { postalCode: '21619', city: 'MALMÖ' },
  { postalCode: '21620', city: 'MALMÖ' },
  { postalCode: '21621', city: 'MALMÖ' },
  { postalCode: '21622', city: 'LIMHAMN' },
  { postalCode: '21623', city: 'MALMÖ' },
  { postalCode: '21624', city: 'MALMÖ' },
  { postalCode: '21625', city: 'MALMÖ' },
  { postalCode: '21630', city: 'LIMHAMN' },
  { postalCode: '21631', city: 'LIMHAMN' },
  { postalCode: '21632', city: 'LIMHAMN' },
  { postalCode: '21633', city: 'LIMHAMN' },
  { postalCode: '21641', city: 'LIMHAMN' },
  { postalCode: '21642', city: 'LIMHAMN' },
  { postalCode: '21643', city: 'LIMHAMN' },
  { postalCode: '21644', city: 'LIMHAMN' },
  { postalCode: '21645', city: 'LIMHAMN' },
  { postalCode: '21646', city: 'LIMHAMN' },
  { postalCode: '21647', city: 'LIMHAMN' },
  { postalCode: '21648', city: 'LIMHAMN' },
  { postalCode: '21649', city: 'LIMHAMN' },
  { postalCode: '21700', city: 'MALMÖ' },
  { postalCode: '21701', city: 'MALMÖ' },
  { postalCode: '21741', city: 'MALMÖ' },
  { postalCode: '21742', city: 'MALMÖ' },
  { postalCode: '21743', city: 'MALMÖ' },
  { postalCode: '21744', city: 'MALMÖ' },
  { postalCode: '21745', city: 'MALMÖ' },
  { postalCode: '21746', city: 'MALMÖ' },
  { postalCode: '21747', city: 'MALMÖ' },
  { postalCode: '21748', city: 'MALMÖ' },
  { postalCode: '21749', city: 'MALMÖ' },
  { postalCode: '21751', city: 'MALMÖ' },
  { postalCode: '21752', city: 'MALMÖ' },
  { postalCode: '21753', city: 'MALMÖ' },
  { postalCode: '21754', city: 'MALMÖ' },
  { postalCode: '21755', city: 'MALMÖ' },
  { postalCode: '21756', city: 'MALMÖ' },
  { postalCode: '21757', city: 'MALMÖ' },
  { postalCode: '21758', city: 'MALMÖ' },
  { postalCode: '21759', city: 'MALMÖ' },
  { postalCode: '21761', city: 'MALMÖ' },
  { postalCode: '21762', city: 'MALMÖ' },
  { postalCode: '21763', city: 'MALMÖ' },
  { postalCode: '21764', city: 'MALMÖ' },
  { postalCode: '21765', city: 'MALMÖ' },
  { postalCode: '21766', city: 'MALMÖ' },
  { postalCode: '21767', city: 'MALMÖ' },
  { postalCode: '21771', city: 'MALMÖ' },
  { postalCode: '21772', city: 'MALMÖ' },
  { postalCode: '21773', city: 'MALMÖ' },
  { postalCode: '21774', city: 'MALMÖ' },
  { postalCode: '21775', city: 'MALMÖ' },
  { postalCode: '21800', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21820', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21824', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21831', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21832', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21833', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21834', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21836', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21837', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21838', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21839', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21840', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21841', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21842', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21845', city: 'VINTRIE' },
  { postalCode: '21846', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21847', city: 'BUNKEFLOSTRAND' },
  { postalCode: '21851', city: 'KLAGSHAMN' },
  { postalCode: '21852', city: 'KLAGSHAMN' },
  { postalCode: '21853', city: 'KLAGSHAMN' },
  { postalCode: '21854', city: 'KLAGSHAMN' },
  { postalCode: '21855', city: 'KLAGSHAMN' },
  { postalCode: '21871', city: 'TYGELSJÖ' },
  { postalCode: '21872', city: 'TYGELSJÖ' },
  { postalCode: '21873', city: 'TYGELSJÖ' },
  { postalCode: '21874', city: 'TYGELSJÖ' },
  { postalCode: '21875', city: 'TYGELSJÖ' },
  { postalCode: '22002', city: 'LUND' },
  { postalCode: '22007', city: 'LUND' },
  { postalCode: '22009', city: 'LUND' },
  { postalCode: '22010', city: 'LUND' },
  { postalCode: '22013', city: 'LUND' },
  { postalCode: '22100', city: 'LUND' },
  { postalCode: '22101', city: 'LUND' },
  { postalCode: '22104', city: 'LUND' },
  { postalCode: '22105', city: 'LUND' },
  { postalCode: '22109', city: 'LUND' },
  { postalCode: '22182', city: 'LUND' },
  { postalCode: '22183', city: 'LUND' },
  { postalCode: '22184', city: 'LUND' },
  { postalCode: '22185', city: 'LUND' },
  { postalCode: '22186', city: 'LUND' },
  { postalCode: '22187', city: 'LUND' },
  { postalCode: '22188', city: 'LUND' },
  { postalCode: '22200', city: 'LUND' },
  { postalCode: '22220', city: 'LUND' },
  { postalCode: '22221', city: 'LUND' },
  { postalCode: '22222', city: 'LUND' },
  { postalCode: '22223', city: 'LUND' },
  { postalCode: '22224', city: 'LUND' },
  { postalCode: '22225', city: 'LUND' },
  { postalCode: '22226', city: 'LUND' },
  { postalCode: '22227', city: 'LUND' },
  { postalCode: '22228', city: 'LUND' },
  { postalCode: '22229', city: 'LUND' },
  { postalCode: '22235', city: 'LUND' },
  { postalCode: '22236', city: 'LUND' },
  { postalCode: '22237', city: 'LUND' },
  { postalCode: '22240', city: 'LUND' },
  { postalCode: '22241', city: 'LUND' },
  { postalCode: '22242', city: 'LUND' },
  { postalCode: '22270', city: 'LUND' },
  { postalCode: '22271', city: 'LUND' },
  { postalCode: '22274', city: 'LUND' },
  { postalCode: '22300', city: 'LUND' },
  { postalCode: '22349', city: 'LUND' },
  { postalCode: '22350', city: 'LUND' },
  { postalCode: '22351', city: 'LUND' },
  { postalCode: '22352', city: 'LUND' },
  { postalCode: '22353', city: 'LUND' },
  { postalCode: '22354', city: 'LUND' },
  { postalCode: '22355', city: 'LUND' },
  { postalCode: '22356', city: 'LUND' },
  { postalCode: '22357', city: 'LUND' },
  { postalCode: '22358', city: 'LUND' },
  { postalCode: '22359', city: 'LUND' },
  { postalCode: '22361', city: 'LUND' },
  { postalCode: '22362', city: 'LUND' },
  { postalCode: '22363', city: 'LUND' },
  { postalCode: '22369', city: 'LUND' },
  { postalCode: '22370', city: 'LUND' },
  { postalCode: '22381', city: 'LUND' },
  { postalCode: '22400', city: 'LUND' },
  { postalCode: '22421', city: 'LUND' },
  { postalCode: '22456', city: 'LUND' },
  { postalCode: '22457', city: 'LUND' },
  { postalCode: '22458', city: 'LUND' },
  { postalCode: '22460', city: 'LUND' },
  { postalCode: '22464', city: 'LUND' },
  { postalCode: '22465', city: 'LUND' },
  { postalCode: '22466', city: 'LUND' },
  { postalCode: '22467', city: 'LUND' },
  { postalCode: '22468', city: 'LUND' },
  { postalCode: '22471', city: 'LUND' },
  { postalCode: '22472', city: 'LUND' },
  { postalCode: '22473', city: 'LUND' },
  { postalCode: '22474', city: 'LUND' },
  { postalCode: '22475', city: 'LUND' },
  { postalCode: '22476', city: 'LUND' },
  { postalCode: '22477', city: 'LUND' },
  { postalCode: '22478', city: 'LUND' },
  { postalCode: '22479', city: 'LUND' },
  { postalCode: '22480', city: 'LUND' },
  { postalCode: '22481', city: 'LUND' },
  { postalCode: '22482', city: 'LUND' },
  { postalCode: '22484', city: 'LUND' },
  { postalCode: '22591', city: 'LUND' },
  { postalCode: '22592', city: 'LUND' },
  { postalCode: '22593', city: 'LUND' },
  { postalCode: '22594', city: 'LUND' },
  { postalCode: '22600', city: 'LUND' },
  { postalCode: '22639', city: 'LUND' },
  { postalCode: '22642', city: 'LUND' },
  { postalCode: '22643', city: 'LUND' },
  { postalCode: '22644', city: 'LUND' },
  { postalCode: '22645', city: 'LUND' },
  { postalCode: '22646', city: 'LUND' },
  { postalCode: '22647', city: 'LUND' },
  { postalCode: '22648', city: 'LUND' },
  { postalCode: '22649', city: 'LUND' },
  { postalCode: '22650', city: 'LUND' },
  { postalCode: '22651', city: 'LUND' },
  { postalCode: '22652', city: 'LUND' },
  { postalCode: '22653', city: 'LUND' },
  { postalCode: '22654', city: 'LUND' },
  { postalCode: '22655', city: 'LUND' },
  { postalCode: '22657', city: 'LUND' },
  { postalCode: '22660', city: 'LUND' },
  { postalCode: '22700', city: 'LUND' },
  { postalCode: '22721', city: 'LUND' },
  { postalCode: '22722', city: 'LUND' },
  { postalCode: '22730', city: 'LUND' },
  { postalCode: '22731', city: 'LUND' },
  { postalCode: '22732', city: 'LUND' },
  { postalCode: '22733', city: 'LUND' },
  { postalCode: '22734', city: 'LUND' },
  { postalCode: '22735', city: 'LUND' },
  { postalCode: '22736', city: 'LUND' },
  { postalCode: '22738', city: 'LUND' },
  { postalCode: '22760', city: 'LUND' },
  { postalCode: '22761', city: 'LUND' },
  { postalCode: '22762', city: 'LUND' },
  { postalCode: '22763', city: 'LUND' },
  { postalCode: '22764', city: 'LUND' },
  { postalCode: '22800', city: 'LUND' },
  { postalCode: '22810', city: 'LUND' },
  { postalCode: '22814', city: 'LUND' },
  { postalCode: '22820', city: 'LUND' },
  { postalCode: '23032', city: 'MALMÖ-STURUP' },
  { postalCode: '23105', city: 'SMYGEHAMN' },
  { postalCode: '23106', city: 'BEDDINGESTRAND' },
  { postalCode: '23108', city: 'ANDERSLÖV' },
  { postalCode: '23110', city: 'TRELLEBORG' },
  { postalCode: '23120', city: 'TRELLEBORG' },
  { postalCode: '23121', city: 'TRELLEBORG' },
  { postalCode: '23122', city: 'TRELLEBORG' },
  { postalCode: '23123', city: 'TRELLEBORG' },
  { postalCode: '23124', city: 'TRELLEBORG' },
  { postalCode: '23125', city: 'TRELLEBORG' },
  { postalCode: '23127', city: 'TRELLEBORG' },
  { postalCode: '23131', city: 'TRELLEBORG' },
  { postalCode: '23132', city: 'TRELLEBORG' },
  { postalCode: '23133', city: 'TRELLEBORG' },
  { postalCode: '23134', city: 'TRELLEBORG' },
  { postalCode: '23136', city: 'TRELLEBORG' },
  { postalCode: '23138', city: 'TRELLEBORG' },
  { postalCode: '23142', city: 'TRELLEBORG' },
  { postalCode: '23143', city: 'TRELLEBORG' },
  { postalCode: '23144', city: 'TRELLEBORG' },
  { postalCode: '23145', city: 'TRELLEBORG' },
  { postalCode: '23151', city: 'TRELLEBORG' },
  { postalCode: '23152', city: 'TRELLEBORG' },
  { postalCode: '23153', city: 'TRELLEBORG' },
  { postalCode: '23154', city: 'TRELLEBORG' },
  { postalCode: '23155', city: 'TRELLEBORG' },
  { postalCode: '23156', city: 'TRELLEBORG' },
  { postalCode: '23161', city: 'TRELLEBORG' },
  { postalCode: '23162', city: 'TRELLEBORG' },
  { postalCode: '23163', city: 'TRELLEBORG' },
  { postalCode: '23164', city: 'TRELLEBORG' },
  { postalCode: '23165', city: 'TRELLEBORG' },
  { postalCode: '23166', city: 'TRELLEBORG' },
  { postalCode: '23167', city: 'TRELLEBORG' },
  { postalCode: '23168', city: 'TRELLEBORG' },
  { postalCode: '23169', city: 'SKEGRIE' },
  { postalCode: '23170', city: 'ANDERSLÖV' },
  { postalCode: '23172', city: 'ANDERSLÖV' },
  { postalCode: '23173', city: 'ANDERSLÖV' },
  { postalCode: '23175', city: 'BEDDINGESTRAND' },
  { postalCode: '23176', city: 'BEDDINGESTRAND' },
  { postalCode: '23178', city: 'SMYGEHAMN' },
  { postalCode: '23179', city: 'SMYGEHAMN' },
  { postalCode: '23181', city: 'TRELLEBORG' },
  { postalCode: '23183', city: 'TRELLEBORG' },
  { postalCode: '23185', city: 'TRELLEBORG' },
  { postalCode: '23187', city: 'TRELLEBORG' },
  { postalCode: '23191', city: 'TRELLEBORG' },
  { postalCode: '23192', city: 'TRELLEBORG' },
  { postalCode: '23193', city: 'TRELLEBORG' },
  { postalCode: '23194', city: 'TRELLEBORG' },
  { postalCode: '23195', city: 'TRELLEBORG' },
  { postalCode: '23196', city: 'TRELLEBORG' },
  { postalCode: '23197', city: 'KLAGSTORP' },
  { postalCode: '23198', city: 'KLAGSTORP' },
  { postalCode: '23199', city: 'KLAGSTORP' },
  { postalCode: '23201', city: 'ARLÖV' },
  { postalCode: '23202', city: 'ÅKARP' },
  { postalCode: '23220', city: 'ARLÖV' },
  { postalCode: '23221', city: 'ARLÖV' },
  { postalCode: '23222', city: 'ARLÖV' },
  { postalCode: '23223', city: 'ARLÖV' },
  { postalCode: '23224', city: 'ARLÖV' },
  { postalCode: '23231', city: 'ARLÖV' },
  { postalCode: '23232', city: 'ARLÖV' },
  { postalCode: '23233', city: 'ARLÖV' },
  { postalCode: '23234', city: 'ARLÖV' },
  { postalCode: '23235', city: 'ARLÖV' },
  { postalCode: '23236', city: 'ARLÖV' },
  { postalCode: '23237', city: 'ARLÖV' },
  { postalCode: '23238', city: 'ARLÖV' },
  { postalCode: '23239', city: 'ARLÖV' },
  { postalCode: '23241', city: 'ARLÖV' },
  { postalCode: '23251', city: 'ÅKARP' },
  { postalCode: '23252', city: 'ÅKARP' },
  { postalCode: '23253', city: 'ÅKARP' },
  { postalCode: '23254', city: 'ÅKARP' },
  { postalCode: '23261', city: 'ARLÖV' },
  { postalCode: '23291', city: 'ARLÖV' },
  { postalCode: '23301', city: 'SVEDALA' },
  { postalCode: '23302', city: 'BARA' },
  { postalCode: '23320', city: 'SVEDALA' },
  { postalCode: '23321', city: 'SVEDALA' },
  { postalCode: '23322', city: 'SVEDALA' },
  { postalCode: '23323', city: 'SVEDALA' },
  { postalCode: '23325', city: 'SVEDALA' },
  { postalCode: '23326', city: 'SVEDALA' },
  { postalCode: '23331', city: 'SVEDALA' },
  { postalCode: '23332', city: 'SVEDALA' },
  { postalCode: '23333', city: 'SVEDALA' },
  { postalCode: '23334', city: 'SVEDALA' },
  { postalCode: '23335', city: 'SVEDALA' },
  { postalCode: '23336', city: 'SVEDALA' },
  { postalCode: '23337', city: 'SVEDALA' },
  { postalCode: '23338', city: 'SVEDALA' },
  { postalCode: '23339', city: 'SVEDALA' },
  { postalCode: '23341', city: 'SVEDALA' },
  { postalCode: '23342', city: 'SVEDALA' },
  { postalCode: '23343', city: 'SVEDALA' },
  { postalCode: '23344', city: 'SVEDALA' },
  { postalCode: '23351', city: 'SVEDALA' },
  { postalCode: '23361', city: 'BARA' },
  { postalCode: '23362', city: 'BARA' },
  { postalCode: '23363', city: 'BARA' },
  { postalCode: '23364', city: 'BARA' },
  { postalCode: '23365', city: 'BARA' },
  { postalCode: '23371', city: 'MALMÖ-STURUP' },
  { postalCode: '23375', city: 'KLÅGERUP' },
  { postalCode: '23376', city: 'KLÅGERUP' },
  { postalCode: '23380', city: 'SVEDALA' },
  { postalCode: '23381', city: 'SVEDALA' },
  { postalCode: '23391', city: 'SVEDALA' },
  { postalCode: '23392', city: 'SVEDALA' },
  { postalCode: '23393', city: 'SVEDALA' },
  { postalCode: '23394', city: 'SVEDALA' },
  { postalCode: '23401', city: 'LOMMA' },
  { postalCode: '23420', city: 'LOMMA' },
  { postalCode: '23421', city: 'LOMMA' },
  { postalCode: '23422', city: 'LOMMA' },
  { postalCode: '23423', city: 'LOMMA' },
  { postalCode: '23431', city: 'LOMMA' },
  { postalCode: '23432', city: 'LOMMA' },
  { postalCode: '23433', city: 'LOMMA' },
  { postalCode: '23434', city: 'LOMMA' },
  { postalCode: '23435', city: 'LOMMA' },
  { postalCode: '23436', city: 'LOMMA' },
  { postalCode: '23437', city: 'LOMMA' },
  { postalCode: '23438', city: 'LOMMA' },
  { postalCode: '23439', city: 'LOMMA' },
  { postalCode: '23440', city: 'LOMMA' },
  { postalCode: '23441', city: 'LOMMA' },
  { postalCode: '23442', city: 'LOMMA' },
  { postalCode: '23443', city: 'LOMMA' },
  { postalCode: '23456', city: 'ALNARP' },
  { postalCode: '23481', city: 'LOMMA' },
  { postalCode: '23501', city: 'VELLINGE' },
  { postalCode: '23520', city: 'VELLINGE' },
  { postalCode: '23521', city: 'VELLINGE' },
  { postalCode: '23522', city: 'VELLINGE' },
  { postalCode: '23525', city: 'VELLINGE' },
  { postalCode: '23531', city: 'VELLINGE' },
  { postalCode: '23532', city: 'VELLINGE' },
  { postalCode: '23533', city: 'VELLINGE' },
  { postalCode: '23534', city: 'VELLINGE' },
  { postalCode: '23535', city: 'VELLINGE' },
  { postalCode: '23536', city: 'VELLINGE' },
  { postalCode: '23537', city: 'VELLINGE' },
  { postalCode: '23538', city: 'VELLINGE' },
  { postalCode: '23539', city: 'VELLINGE' },
  { postalCode: '23541', city: 'VELLINGE' },
  { postalCode: '23542', city: 'VELLINGE' },
  { postalCode: '23543', city: 'VELLINGE' },
  { postalCode: '23581', city: 'VELLINGE' },
  { postalCode: '23591', city: 'VELLINGE' },
  { postalCode: '23593', city: 'VELLINGE' },
  { postalCode: '23594', city: 'VELLINGE' },
  { postalCode: '23599', city: 'VELLINGE' },
  { postalCode: '23601', city: 'HÖLLVIKEN' },
  { postalCode: '23621', city: 'HÖLLVIKEN' },
  { postalCode: '23622', city: 'HÖLLVIKEN' },
  { postalCode: '23623', city: 'HÖLLVIKEN' },
  { postalCode: '23624', city: 'HÖLLVIKEN' },
  { postalCode: '23625', city: 'HÖLLVIKEN' },
  { postalCode: '23631', city: 'HÖLLVIKEN' },
  { postalCode: '23632', city: 'HÖLLVIKEN' },
  { postalCode: '23633', city: 'HÖLLVIKEN' },
  { postalCode: '23634', city: 'HÖLLVIKEN' },
  { postalCode: '23635', city: 'HÖLLVIKEN' },
  { postalCode: '23636', city: 'HÖLLVIKEN' },
  { postalCode: '23637', city: 'HÖLLVIKEN' },
  { postalCode: '23638', city: 'HÖLLVIKEN' },
  { postalCode: '23641', city: 'HÖLLVIKEN' },
  { postalCode: '23642', city: 'HÖLLVIKEN' },
  { postalCode: '23651', city: 'HÖLLVIKEN' },
  { postalCode: '23661', city: 'HÖLLVIKEN' },
  { postalCode: '23691', city: 'HÖLLVIKEN' },
  { postalCode: '23701', city: 'BJÄRRED' },
  { postalCode: '23721', city: 'BJÄRRED' },
  { postalCode: '23722', city: 'BJÄRRED' },
  { postalCode: '23723', city: 'BJÄRRED' },
  { postalCode: '23724', city: 'BJÄRRED' },
  { postalCode: '23725', city: 'BJÄRRED' },
  { postalCode: '23731', city: 'BJÄRRED' },
  { postalCode: '23732', city: 'BJÄRRED' },
  { postalCode: '23733', city: 'BJÄRRED' },
  { postalCode: '23734', city: 'BJÄRRED' },
  { postalCode: '23735', city: 'BJÄRRED' },
  { postalCode: '23736', city: 'BJÄRRED' },
  { postalCode: '23737', city: 'BJÄRRED' },
  { postalCode: '23741', city: 'BJÄRRED' },
  { postalCode: '23742', city: 'BJÄRRED' },
  { postalCode: '23791', city: 'BJÄRRED' },
  { postalCode: '23820', city: 'OXIE' },
  { postalCode: '23821', city: 'OXIE' },
  { postalCode: '23822', city: 'OXIE' },
  { postalCode: '23830', city: 'OXIE' },
  { postalCode: '23831', city: 'OXIE' },
  { postalCode: '23832', city: 'OXIE' },
  { postalCode: '23833', city: 'OXIE' },
  { postalCode: '23834', city: 'OXIE' },
  { postalCode: '23835', city: 'OXIE' },
  { postalCode: '23836', city: 'OXIE' },
  { postalCode: '23837', city: 'OXIE' },
  { postalCode: '23838', city: 'OXIE' },
  { postalCode: '23839', city: 'OXIE' },
  { postalCode: '23840', city: 'OXIE' },
  { postalCode: '23841', city: 'OXIE' },
  { postalCode: '23842', city: 'OXIE' },
  { postalCode: '23843', city: 'OXIE' },
  { postalCode: '23901', city: 'SKANÖR' },
  { postalCode: '23920', city: 'SKANÖR' },
  { postalCode: '23921', city: 'SKANÖR' },
  { postalCode: '23922', city: 'SKANÖR' },
  { postalCode: '23930', city: 'SKANÖR' },
  { postalCode: '23931', city: 'SKANÖR' },
  { postalCode: '23932', city: 'SKANÖR' },
  { postalCode: '23933', city: 'SKANÖR' },
  { postalCode: '23934', city: 'SKANÖR' },
  { postalCode: '23935', city: 'SKANÖR' },
  { postalCode: '23936', city: 'SKANÖR' },
  { postalCode: '23940', city: 'FALSTERBO' },
  { postalCode: '23941', city: 'FALSTERBO' },
  { postalCode: '23942', city: 'FALSTERBO' },
  { postalCode: '24102', city: 'MARIEHOLM' },
  { postalCode: '24104', city: 'LÖBERÖD' },
  { postalCode: '24105', city: 'HARLÖSA' },
  { postalCode: '24110', city: 'ESLÖV' },
  { postalCode: '24120', city: 'ESLÖV' },
  { postalCode: '24121', city: 'ESLÖV' },
  { postalCode: '24122', city: 'ESLÖV' },
  { postalCode: '24123', city: 'ESLÖV' },
  { postalCode: '24124', city: 'ESLÖV' },
  { postalCode: '24126', city: 'ESLÖV' },
  { postalCode: '24130', city: 'ESLÖV' },
  { postalCode: '24131', city: 'ESLÖV' },
  { postalCode: '24132', city: 'ESLÖV' },
  { postalCode: '24133', city: 'ESLÖV' },
  { postalCode: '24134', city: 'ESLÖV' },
  { postalCode: '24135', city: 'ESLÖV' },
  { postalCode: '24136', city: 'ESLÖV' },
  { postalCode: '24137', city: 'ESLÖV' },
  { postalCode: '24138', city: 'ESLÖV' },
  { postalCode: '24139', city: 'ESLÖV' },
  { postalCode: '24140', city: 'ESLÖV' },
  { postalCode: '24161', city: 'LÖBERÖD' },
  { postalCode: '24162', city: 'LÖBERÖD' },
  { postalCode: '24164', city: 'HARLÖSA' },
  { postalCode: '24165', city: 'HARLÖSA' },
  { postalCode: '24171', city: 'MARIEHOLM' },
  { postalCode: '24172', city: 'MARIEHOLM' },
  { postalCode: '24174', city: 'STEHAG' },
  { postalCode: '24175', city: 'STEHAG' },
  { postalCode: '24180', city: 'ESLÖV' },
  { postalCode: '24181', city: 'ESLÖV' },
  { postalCode: '24191', city: 'ESLÖV' },
  { postalCode: '24192', city: 'ESLÖV' },
  { postalCode: '24193', city: 'ESLÖV' },
  { postalCode: '24194', city: 'HURVA' },
  { postalCode: '24195', city: 'BILLINGE' },
  { postalCode: '24196', city: 'STOCKAMÖLLAN' },
  { postalCode: '24201', city: 'HÖRBY' },
  { postalCode: '24220', city: 'HÖRBY' },
  { postalCode: '24221', city: 'HÖRBY' },
  { postalCode: '24222', city: 'HÖRBY' },
  { postalCode: '24230', city: 'HÖRBY' },
  { postalCode: '24231', city: 'HÖRBY' },
  { postalCode: '24232', city: 'HÖRBY' },
  { postalCode: '24233', city: 'HÖRBY' },
  { postalCode: '24234', city: 'HÖRBY' },
  { postalCode: '24235', city: 'HÖRBY' },
  { postalCode: '24271', city: 'LUDVIGSBORG' },
  { postalCode: '24272', city: 'LUDVIGSBORG' },
  { postalCode: '24280', city: 'HÖRBY' },
  { postalCode: '24291', city: 'HÖRBY' },
  { postalCode: '24292', city: 'HÖRBY' },
  { postalCode: '24293', city: 'HÖRBY' },
  { postalCode: '24294', city: 'HÖRBY' },
  { postalCode: '24295', city: 'HÖRBY' },
  { postalCode: '24296', city: 'HÖRBY' },
  { postalCode: '24297', city: 'HÖRBY' },
  { postalCode: '24298', city: 'HÖRBY' },
  { postalCode: '24301', city: 'HÖÖR' },
  { postalCode: '24320', city: 'HÖÖR' },
  { postalCode: '24321', city: 'HÖÖR' },
  { postalCode: '24322', city: 'HÖÖR' },
  { postalCode: '24323', city: 'HÖÖR' },
  { postalCode: '24326', city: 'HÖÖR' },
  { postalCode: '24330', city: 'HÖÖR' },
  { postalCode: '24331', city: 'HÖÖR' },
  { postalCode: '24332', city: 'HÖÖR' },
  { postalCode: '24333', city: 'HÖÖR' },
  { postalCode: '24334', city: 'HÖÖR' },
  { postalCode: '24335', city: 'HÖÖR' },
  { postalCode: '24336', city: 'HÖÖR' },
  { postalCode: '24339', city: 'HÖÖR' },
  { postalCode: '24340', city: 'HÖÖR' },
  { postalCode: '24371', city: 'TJÖRNARP' },
  { postalCode: '24372', city: 'TJÖRNARP' },
  { postalCode: '24373', city: 'TJÖRNARP' },
  { postalCode: '24391', city: 'HÖÖR' },
  { postalCode: '24392', city: 'HÖÖR' },
  { postalCode: '24393', city: 'HÖÖR' },
  { postalCode: '24394', city: 'HÖÖR' },
  { postalCode: '24395', city: 'HÖÖR' },
  { postalCode: '24396', city: 'HÖÖR' },
  { postalCode: '24397', city: 'HALLARÖD' },
  { postalCode: '24400', city: 'KÄVLINGE' },
  { postalCode: '24401', city: 'KÄVLINGE' },
  { postalCode: '24402', city: 'FURULUND' },
  { postalCode: '24420', city: 'KÄVLINGE' },
  { postalCode: '24421', city: 'KÄVLINGE' },
  { postalCode: '24422', city: 'KÄVLINGE' },
  { postalCode: '24423', city: 'KÄVLINGE' },
  { postalCode: '24430', city: 'KÄVLINGE' },
  { postalCode: '24431', city: 'KÄVLINGE' },
  { postalCode: '24432', city: 'KÄVLINGE' },
  { postalCode: '24433', city: 'KÄVLINGE' },
  { postalCode: '24436', city: 'KÄVLINGE' },
  { postalCode: '24438', city: 'KÄVLINGE' },
  { postalCode: '24439', city: 'KÄVLINGE' },
  { postalCode: '24441', city: 'KÄVLINGE' },
  { postalCode: '24444', city: 'KÄVLINGE' },
  { postalCode: '24445', city: 'KÄVLINGE' },
  { postalCode: '24446', city: 'KÄVLINGE' },
  { postalCode: '24460', city: 'FURULUND' },
  { postalCode: '24462', city: 'FURULUND' },
  { postalCode: '24463', city: 'FURULUND' },
  { postalCode: '24465', city: 'FURULUND' },
  { postalCode: '24466', city: 'FURULUND' },
  { postalCode: '24471', city: 'DÖSJEBRO' },
  { postalCode: '24480', city: 'KÄVLINGE' },
  { postalCode: '24482', city: 'KÄVLINGE' },
  { postalCode: '24491', city: 'KÄVLINGE' },
  { postalCode: '24493', city: 'KÄVLINGE' },
  { postalCode: '24494', city: 'FURULUND' },
  { postalCode: '24495', city: 'DÖSJEBRO' },
  { postalCode: '24501', city: 'STAFFANSTORP' },
  { postalCode: '24518', city: 'STAFFANSTORP' },
  { postalCode: '24520', city: 'STAFFANSTORP' },
  { postalCode: '24521', city: 'STAFFANSTORP' },
  { postalCode: '24522', city: 'STAFFANSTORP' },
  { postalCode: '24525', city: 'STAFFANSTORP' },
  { postalCode: '24531', city: 'STAFFANSTORP' },
  { postalCode: '24532', city: 'STAFFANSTORP' },
  { postalCode: '24533', city: 'STAFFANSTORP' },
  { postalCode: '24534', city: 'STAFFANSTORP' },
  { postalCode: '24535', city: 'STAFFANSTORP' },
  { postalCode: '24537', city: 'STAFFANSTORP' },
  { postalCode: '24538', city: 'STAFFANSTORP' },
  { postalCode: '24541', city: 'STAFFANSTORP' },
  { postalCode: '24542', city: 'STAFFANSTORP' },
  { postalCode: '24543', city: 'STAFFANSTORP' },
  { postalCode: '24544', city: 'STAFFANSTORP' },
  { postalCode: '24545', city: 'STAFFANSTORP' },
  { postalCode: '24561', city: 'STAFFANSTORP' },
  { postalCode: '24562', city: 'HJÄRUP' },
  { postalCode: '24563', city: 'HJÄRUP' },
  { postalCode: '24564', city: 'HJÄRUP' },
  { postalCode: '24565', city: 'HJÄRUP' },
  { postalCode: '24566', city: 'HJÄRUP' },
  { postalCode: '24580', city: 'STAFFANSTORP' },
  { postalCode: '24591', city: 'STAFFANSTORP' },
  { postalCode: '24592', city: 'STAFFANSTORP' },
  { postalCode: '24593', city: 'STAFFANSTORP' },
  { postalCode: '24601', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24602', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24620', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24621', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24622', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24625', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24630', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24631', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24632', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24633', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24634', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24635', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24636', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24642', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24643', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24650', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24651', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24652', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24655', city: 'LÖDDEKÖPINGE' },
  { postalCode: '24657', city: 'BARSEBÄCK' },
  { postalCode: '24700', city: 'DALBY' },
  { postalCode: '24719', city: 'SÖDRA SANDBY' },
  { postalCode: '24720', city: 'DALBY' },
  { postalCode: '24721', city: 'SÖDRA SANDBY' },
  { postalCode: '24722', city: 'SÖDRA SANDBY' },
  { postalCode: '24723', city: 'DALBY' },
  { postalCode: '24724', city: 'DALBY' },
  { postalCode: '24725', city: 'VEBERÖD' },
  { postalCode: '24726', city: 'VEBERÖD' },
  { postalCode: '24727', city: 'GENARP' },
  { postalCode: '24728', city: 'GENARP' },
  { postalCode: '24729', city: 'FLYINGE' },
  { postalCode: '24731', city: 'SÖDRA SANDBY' },
  { postalCode: '24732', city: 'SÖDRA SANDBY' },
  { postalCode: '24733', city: 'SÖDRA SANDBY' },
  { postalCode: '24734', city: 'SÖDRA SANDBY' },
  { postalCode: '24735', city: 'SÖDRA SANDBY' },
  { postalCode: '24736', city: 'SÖDRA SANDBY' },
  { postalCode: '24741', city: 'SÖDRA SANDBY' },
  { postalCode: '24745', city: 'TORNA-HÄLLESTAD' },
  { postalCode: '24747', city: 'FLYINGE' },
  { postalCode: '24750', city: 'DALBY' },
  { postalCode: '24751', city: 'DALBY' },
  { postalCode: '24752', city: 'DALBY' },
  { postalCode: '24753', city: 'DALBY' },
  { postalCode: '24754', city: 'DALBY' },
  { postalCode: '24755', city: 'DALBY' },
  { postalCode: '24756', city: 'DALBY' },
  { postalCode: '24760', city: 'VEBERÖD' },
  { postalCode: '24761', city: 'VEBERÖD' },
  { postalCode: '24762', city: 'VEBERÖD' },
  { postalCode: '24763', city: 'VEBERÖD' },
  { postalCode: '24764', city: 'VEBERÖD' },
  { postalCode: '24770', city: 'GENARP' },
  { postalCode: '24771', city: 'GENARP' },
  { postalCode: '24772', city: 'GENARP' },
  { postalCode: '24781', city: 'SÖDRA SANDBY' },
  { postalCode: '24782', city: 'SÖDRA SANDBY' },
  { postalCode: '24791', city: 'SÖDRA SANDBY' },
  { postalCode: '24792', city: 'SÖDRA SANDBY' },
  { postalCode: '24793', city: 'FLYINGE' },
  { postalCode: '24794', city: 'DALBY' },
  { postalCode: '24795', city: 'TORNA-HÄLLESTAD' },
  { postalCode: '24796', city: 'VEBERÖD' },
  { postalCode: '24797', city: 'FLYINGE' },
  { postalCode: '24798', city: 'GENARP' },
  { postalCode: '24799', city: 'GENARP' },
  { postalCode: '25002', city: 'HELSINGBORG' },
  { postalCode: '25003', city: 'HELSINGBORG' },
  { postalCode: '25005', city: 'HELSINGBORG' },
  { postalCode: '25006', city: 'HELSINGBORG' },
  { postalCode: '25007', city: 'HELSINGBORG' },
  { postalCode: '25009', city: 'HELSINGBORG' },
  { postalCode: '25013', city: 'HELSINGBORG' },
  { postalCode: '25015', city: 'HELSINGBORG' },
  { postalCode: '25016', city: 'RÅÅ' },
  { postalCode: '25019', city: 'HELSINGBORG' },
  { postalCode: '25022', city: 'HELSINGBORG' },
  { postalCode: '25023', city: 'HELSINGBORG' },
  { postalCode: '25024', city: 'HELSINGBORG' },
  { postalCode: '25025', city: 'HELSINGBORG' },
  { postalCode: '25053', city: 'HELSINGBORG' },
  { postalCode: '25054', city: 'HELSINGBORG' },
  { postalCode: '25100', city: 'HELSINGBORG' },
  { postalCode: '25101', city: 'HELSINGBORG' },
  { postalCode: '25106', city: 'HELSINGBORG' },
  { postalCode: '25107', city: 'HELSINGBORG' },
  { postalCode: '25108', city: 'HELSINGBORG' },
  { postalCode: '25109', city: 'HELSINGBORG' },
  { postalCode: '25110', city: 'HELSINGBORG' },
  { postalCode: '25111', city: 'HELSINGBORG' },
  { postalCode: '25112', city: 'HELSINGBORG' },
  { postalCode: '25113', city: 'HELSINGBORG' },
  { postalCode: '25114', city: 'HELSINGBORG' },
  { postalCode: '25181', city: 'HELSINGBORG' },
  { postalCode: '25182', city: 'RAMLÖSA' },
  { postalCode: '25183', city: 'HELSINGBORG' },
  { postalCode: '25184', city: 'HELSINGBORG' },
  { postalCode: '25185', city: 'HELSINGBORG' },
  { postalCode: '25187', city: 'HELSINGBORG' },
  { postalCode: '25188', city: 'HELSINGBORG' },
  { postalCode: '25189', city: 'HELSINGBORG' },
  { postalCode: '25200', city: 'HELSINGBORG' },
  { postalCode: '25201', city: 'HELSINGBORG' },
  { postalCode: '25202', city: 'HELSINGBORG' },
  { postalCode: '25208', city: 'HELSINGBORG' },
  { postalCode: '25218', city: 'HELSINGBORG' },
  { postalCode: '25219', city: 'HELSINGBORG' },
  { postalCode: '25220', city: 'HELSINGBORG' },
  { postalCode: '25221', city: 'HELSINGBORG' },
  { postalCode: '25222', city: 'HELSINGBORG' },
  { postalCode: '25223', city: 'HELSINGBORG' },
  { postalCode: '25224', city: 'HELSINGBORG' },
  { postalCode: '25225', city: 'HELSINGBORG' },
  { postalCode: '25226', city: 'HELSINGBORG' },
  { postalCode: '25227', city: 'HELSINGBORG' },
  { postalCode: '25228', city: 'HELSINGBORG' },
  { postalCode: '25229', city: 'HELSINGBORG' },
  { postalCode: '25230', city: 'HELSINGBORG' },
  { postalCode: '25231', city: 'HELSINGBORG' },
  { postalCode: '25232', city: 'HELSINGBORG' },
  { postalCode: '25233', city: 'HELSINGBORG' },
  { postalCode: '25234', city: 'HELSINGBORG' },
  { postalCode: '25236', city: 'HELSINGBORG' },
  { postalCode: '25237', city: 'HELSINGBORG' },
  { postalCode: '25238', city: 'HELSINGBORG' },
  { postalCode: '25240', city: 'HELSINGBORG' },
  { postalCode: '25241', city: 'HELSINGBORG' },
  { postalCode: '25244', city: 'HELSINGBORG' },
  { postalCode: '25245', city: 'HELSINGBORG' },
  { postalCode: '25246', city: 'HELSINGBORG' },
  { postalCode: '25247', city: 'HELSINGBORG' },
  { postalCode: '25248', city: 'HELSINGBORG' },
  { postalCode: '25249', city: 'HELSINGBORG' },
  { postalCode: '25250', city: 'HELSINGBORG' },
  { postalCode: '25251', city: 'HELSINGBORG' },
  { postalCode: '25252', city: 'HELSINGBORG' },
  { postalCode: '25263', city: 'HELSINGBORG' },
  { postalCode: '25266', city: 'HELSINGBORG' },
  { postalCode: '25267', city: 'HELSINGBORG' },
  { postalCode: '25268', city: 'HELSINGBORG' },
  { postalCode: '25269', city: 'RÅÅ' },
  { postalCode: '25270', city: 'RÅÅ' },
  { postalCode: '25271', city: 'RÅÅ' },
  { postalCode: '25275', city: 'HELSINGBORG' },
  { postalCode: '25276', city: 'HELSINGBORG' },
  { postalCode: '25277', city: 'HELSINGBORG' },
  { postalCode: '25278', city: 'HELSINGBORG' },
  { postalCode: '25279', city: 'HELSINGBORG' },
  { postalCode: '25300', city: 'HELSINGBORG' },
  { postalCode: '25301', city: 'HELSINGBORG' },
  { postalCode: '25302', city: 'HELSINGBORG' },
  { postalCode: '25341', city: 'VALLÅKRA' },
  { postalCode: '25342', city: 'VALLÅKRA' },
  { postalCode: '25351', city: 'PÅARP' },
  { postalCode: '25352', city: 'PÅARP' },
  { postalCode: '25353', city: 'PÅARP' },
  { postalCode: '25354', city: 'MÖRARP' },
  { postalCode: '25355', city: 'MÖRARP' },
  { postalCode: '25356', city: 'MÖRARP' },
  { postalCode: '25360', city: 'RAMLÖSA' },
  { postalCode: '25361', city: 'HELSINGBORG' },
  { postalCode: '25362', city: 'HELSINGBORG' },
  { postalCode: '25368', city: 'HELSINGBORG' },
  { postalCode: '25373', city: 'GANTOFTA' },
  { postalCode: '25374', city: 'HELSINGBORG' },
  { postalCode: '25375', city: 'HELSINGBORG' },
  { postalCode: '25400', city: 'HELSINGBORG' },
  { postalCode: '25402', city: 'HELSINGBORG' },
  { postalCode: '25403', city: 'ÖDÅKRA' },
  { postalCode: '25404', city: 'ÖDÅKRA' },
  { postalCode: '25408', city: 'HELSINGBORG' },
  { postalCode: '25420', city: 'HELSINGBORG' },
  { postalCode: '25421', city: 'HELSINGBORG' },
  { postalCode: '25422', city: 'HELSINGBORG' },
  { postalCode: '25423', city: 'HELSINGBORG' },
  { postalCode: '25424', city: 'HELSINGBORG' },
  { postalCode: '25426', city: 'HELSINGBORG' },
  { postalCode: '25430', city: 'HELSINGBORG' },
  { postalCode: '25431', city: 'HELSINGBORG' },
  { postalCode: '25433', city: 'HELSINGBORG' },
  { postalCode: '25434', city: 'HELSINGBORG' },
  { postalCode: '25435', city: 'HELSINGBORG' },
  { postalCode: '25436', city: 'HELSINGBORG' },
  { postalCode: '25437', city: 'HELSINGBORG' },
  { postalCode: '25438', city: 'HELSINGBORG' },
  { postalCode: '25439', city: 'HELSINGBORG' },
  { postalCode: '25440', city: 'HELSINGBORG' },
  { postalCode: '25441', city: 'HELSINGBORG' },
  { postalCode: '25442', city: 'HELSINGBORG' },
  { postalCode: '25443', city: 'HELSINGBORG' },
  { postalCode: '25444', city: 'HELSINGBORG' },
  { postalCode: '25445', city: 'HELSINGBORG' },
  { postalCode: '25446', city: 'HELSINGBORG' },
  { postalCode: '25447', city: 'HELSINGBORG' },
  { postalCode: '25448', city: 'HELSINGBORG' },
  { postalCode: '25449', city: 'HELSINGBORG' },
  { postalCode: '25450', city: 'HELSINGBORG' },
  { postalCode: '25451', city: 'HELSINGBORG' },
  { postalCode: '25452', city: 'HELSINGBORG' },
  { postalCode: '25453', city: 'HELSINGBORG' },
  { postalCode: '25454', city: 'HELSINGBORG' },
  { postalCode: '25455', city: 'HELSINGBORG' },
  { postalCode: '25457', city: 'HELSINGBORG' },
  { postalCode: '25458', city: 'HELSINGBORG' },
  { postalCode: '25459', city: 'HELSINGBORG' },
  { postalCode: '25460', city: 'HELSINGBORG' },
  { postalCode: '25462', city: 'HELSINGBORG' },
  { postalCode: '25463', city: 'HELSINGBORG' },
  { postalCode: '25464', city: 'HELSINGBORG' },
  { postalCode: '25465', city: 'HELSINGBORG' },
  { postalCode: '25466', city: 'HELSINGBORG' },
  { postalCode: '25467', city: 'HELSINGBORG' },
  { postalCode: '25468', city: 'HELSINGBORG' },
  { postalCode: '25469', city: 'ÖDÅKRA' },
  { postalCode: '25470', city: 'KATTARP' },
  { postalCode: '25471', city: 'HASSLARP' },
  { postalCode: '25472', city: 'ÖDÅKRA' },
  { postalCode: '25473', city: 'ÖDÅKRA' },
  { postalCode: '25474', city: 'ÖDÅKRA' },
  { postalCode: '25475', city: 'ÖDÅKRA' },
  { postalCode: '25476', city: 'ALLERUM' },
  { postalCode: '25477', city: 'FLENINGE' },
  { postalCode: '25478', city: 'DOMSTEN' },
  { postalCode: '25481', city: 'HELSINGBORG' },
  { postalCode: '25482', city: 'HELSINGBORG' },
  { postalCode: '25483', city: 'HELSINGBORG' },
  { postalCode: '25484', city: 'HELSINGBORG' },
  { postalCode: '25600', city: 'HELSINGBORG' },
  { postalCode: '25654', city: 'RAMLÖSA' },
  { postalCode: '25655', city: 'HELSINGBORG' },
  { postalCode: '25656', city: 'HELSINGBORG' },
  { postalCode: '25657', city: 'RAMLÖSA' },
  { postalCode: '25658', city: 'HELSINGBORG' },
  { postalCode: '25659', city: 'HELSINGBORG' },
  { postalCode: '25661', city: 'HELSINGBORG' },
  { postalCode: '25662', city: 'HELSINGBORG' },
  { postalCode: '25663', city: 'HELSINGBORG' },
  { postalCode: '25664', city: 'HELSINGBORG' },
  { postalCode: '25665', city: 'HELSINGBORG' },
  { postalCode: '25666', city: 'HELSINGBORG' },
  { postalCode: '25667', city: 'HELSINGBORG' },
  { postalCode: '25668', city: 'HELSINGBORG' },
  { postalCode: '25669', city: 'HELSINGBORG' },
  { postalCode: '25671', city: 'HELSINGBORG' },
  { postalCode: '25721', city: 'RYDEBÄCK' },
  { postalCode: '25730', city: 'RYDEBÄCK' },
  { postalCode: '25731', city: 'RYDEBÄCK' },
  { postalCode: '25732', city: 'RYDEBÄCK' },
  { postalCode: '25733', city: 'RYDEBÄCK' },
  { postalCode: '25734', city: 'RYDEBÄCK' },
  { postalCode: '25800', city: 'HELSINGBORG' },
  { postalCode: '25802', city: 'HELSINGBORG' },
  { postalCode: '25803', city: 'HELSINGBORG' },
  { postalCode: '25805', city: 'HELSINGBORG' },
  { postalCode: '25960', city: 'HELSINGBORG' },
  { postalCode: '25961', city: 'HELSINGBORG' },
  { postalCode: '25962', city: 'HELSINGBORG' },
  { postalCode: '25963', city: 'HELSINGBORG' },
  { postalCode: '25964', city: 'HELSINGBORG' },
  { postalCode: '25965', city: 'HELSINGBORG' },
  { postalCode: '25966', city: 'HELSINGBORG' },
  { postalCode: '25967', city: 'HELSINGBORG' },
  { postalCode: '25968', city: 'HELSINGBORG' },
  { postalCode: '25969', city: 'HELSINGBORG' },
  { postalCode: '25970', city: 'HELSINGBORG' },
  { postalCode: '25971', city: 'HELSINGBORG' },
  { postalCode: '25972', city: 'HELSINGBORG' },
  { postalCode: '25973', city: 'HELSINGBORG' },
  { postalCode: '25974', city: 'HELSINGBORG' },
  { postalCode: '25975', city: 'HELSINGBORG' },
  { postalCode: '25976', city: 'HELSINGBORG' },
  { postalCode: '25977', city: 'HELSINGBORG' },
  { postalCode: '25978', city: 'HELSINGBORG' },
  { postalCode: '25979', city: 'HELSINGBORG' },
  { postalCode: '25980', city: 'HELSINGBORG' },
  { postalCode: '25981', city: 'HELSINGBORG' },
  { postalCode: '25982', city: 'HELSINGBORG' },
  { postalCode: '25983', city: 'HELSINGBORG' },
  { postalCode: '25984', city: 'HELSINGBORG' },
  { postalCode: '25985', city: 'HELSINGBORG' },
  { postalCode: '25986', city: 'HELSINGBORG' },
  { postalCode: '25987', city: 'HELSINGBORG' },
  { postalCode: '25988', city: 'HELSINGBORG' },
  { postalCode: '25989', city: 'HELSINGBORG' },
  { postalCode: '25990', city: 'HELSINGBORG' },
  { postalCode: '25991', city: 'HELSINGBORG' },
  { postalCode: '25992', city: 'HELSINGBORG' },
  { postalCode: '25994', city: 'HELSINGBORG' },
  { postalCode: '25995', city: 'HELSINGBORG' },
  { postalCode: '25996', city: 'HELSINGBORG' },
  { postalCode: '25997', city: 'HELSINGBORG' },
  { postalCode: '25998', city: 'HELSINGBORG' },
  { postalCode: '25999', city: 'HELSINGBORG' },
  { postalCode: '26100', city: 'LANDSKRONA' },
  { postalCode: '26103', city: 'LANDSKRONA' },
  { postalCode: '26104', city: 'LANDSKRONA' },
  { postalCode: '26106', city: 'HÄLJARP' },
  { postalCode: '26107', city: 'ASMUNDTORP' },
  { postalCode: '26108', city: 'GLUMSLÖV' },
  { postalCode: '26120', city: 'LANDSKRONA' },
  { postalCode: '26122', city: 'LANDSKRONA' },
  { postalCode: '26123', city: 'LANDSKRONA' },
  { postalCode: '26124', city: 'LANDSKRONA' },
  { postalCode: '26125', city: 'LANDSKRONA' },
  { postalCode: '26126', city: 'LANDSKRONA' },
  { postalCode: '26127', city: 'LANDSKRONA' },
  { postalCode: '26129', city: 'LANDSKRONA' },
  { postalCode: '26130', city: 'LANDSKRONA' },
  { postalCode: '26131', city: 'LANDSKRONA' },
  { postalCode: '26132', city: 'LANDSKRONA' },
  { postalCode: '26133', city: 'LANDSKRONA' },
  { postalCode: '26134', city: 'LANDSKRONA' },
  { postalCode: '26135', city: 'LANDSKRONA' },
  { postalCode: '26136', city: 'LANDSKRONA' },
  { postalCode: '26137', city: 'LANDSKRONA' },
  { postalCode: '26138', city: 'LANDSKRONA' },
  { postalCode: '26139', city: 'LANDSKRONA' },
  { postalCode: '26140', city: 'LANDSKRONA' },
  { postalCode: '26141', city: 'LANDSKRONA' },
  { postalCode: '26142', city: 'LANDSKRONA' },
  { postalCode: '26143', city: 'LANDSKRONA' },
  { postalCode: '26144', city: 'LANDSKRONA' },
  { postalCode: '26145', city: 'LANDSKRONA' },
  { postalCode: '26146', city: 'LANDSKRONA' },
  { postalCode: '26147', city: 'LANDSKRONA' },
  { postalCode: '26151', city: 'LANDSKRONA' },
  { postalCode: '26152', city: 'LANDSKRONA' },
  { postalCode: '26153', city: 'LANDSKRONA' },
  { postalCode: '26160', city: 'LANDSKRONA' },
  { postalCode: '26161', city: 'LANDSKRONA' },
  { postalCode: '26162', city: 'GLUMSLÖV' },
  { postalCode: '26163', city: 'GLUMSLÖV' },
  { postalCode: '26165', city: 'HÄRSLÖV' },
  { postalCode: '26171', city: 'HÄLJARP' },
  { postalCode: '26172', city: 'HÄLJARP' },
  { postalCode: '26173', city: 'HÄLJARP' },
  { postalCode: '26175', city: 'ASMUNDTORP' },
  { postalCode: '26176', city: 'ASMUNDTORP' },
  { postalCode: '26180', city: 'LANDSKRONA' },
  { postalCode: '26191', city: 'LANDSKRONA' },
  { postalCode: '26192', city: 'HÄRSLÖV' },
  { postalCode: '26193', city: 'SAXTORP' },
  { postalCode: '26194', city: 'ANNELÖV' },
  { postalCode: '26195', city: 'SANKT IBB' },
  { postalCode: '26200', city: 'ÄNGELHOLM' },
  { postalCode: '26201', city: 'ÄNGELHOLM' },
  { postalCode: '26210', city: 'ÄNGELHOLM' },
  { postalCode: '26220', city: 'ÄNGELHOLM' },
  { postalCode: '26221', city: 'ÄNGELHOLM' },
  { postalCode: '26222', city: 'ÄNGELHOLM' },
  { postalCode: '26223', city: 'ÄNGELHOLM' },
  { postalCode: '26224', city: 'ÄNGELHOLM' },
  { postalCode: '26230', city: 'ÄNGELHOLM' },
  { postalCode: '26231', city: 'ÄNGELHOLM' },
  { postalCode: '26232', city: 'ÄNGELHOLM' },
  { postalCode: '26233', city: 'ÄNGELHOLM' },
  { postalCode: '26234', city: 'ÄNGELHOLM' },
  { postalCode: '26235', city: 'ÄNGELHOLM' },
  { postalCode: '26236', city: 'ÄNGELHOLM' },
  { postalCode: '26237', city: 'ÄNGELHOLM' },
  { postalCode: '26241', city: 'ÄNGELHOLM' },
  { postalCode: '26242', city: 'ÄNGELHOLM' },
  { postalCode: '26243', city: 'ÄNGELHOLM' },
  { postalCode: '26244', city: 'ÄNGELHOLM' },
  { postalCode: '26250', city: 'ÄNGELHOLM' },
  { postalCode: '26251', city: 'ÄNGELHOLM' },
  { postalCode: '26252', city: 'ÄNGELHOLM' },
  { postalCode: '26253', city: 'ÄNGELHOLM' },
  { postalCode: '26254', city: 'ÄNGELHOLM' },
  { postalCode: '26255', city: 'ÄNGELHOLM' },
  { postalCode: '26257', city: 'ÄNGELHOLM' },
  { postalCode: '26258', city: 'ÄNGELHOLM' },
  { postalCode: '26260', city: 'ÄNGELHOLM' },
  { postalCode: '26261', city: 'ÄNGELHOLM' },
  { postalCode: '26262', city: 'ÄNGELHOLM' },
  { postalCode: '26263', city: 'ÄNGELHOLM' },
  { postalCode: '26264', city: 'ÄNGELHOLM' },
  { postalCode: '26265', city: 'ÄNGELHOLM' },
  { postalCode: '26266', city: 'ÄNGELHOLM' },
  { postalCode: '26267', city: 'ÄNGELHOLM' },
  { postalCode: '26268', city: 'ÄNGELHOLM' },
  { postalCode: '26270', city: 'STRÖVELSTORP' },
  { postalCode: '26271', city: 'ÄNGELHOLM' },
  { postalCode: '26272', city: 'ÄNGELHOLM' },
  { postalCode: '26273', city: 'ÄNGELHOLM' },
  { postalCode: '26274', city: 'ÄNGELHOLM' },
  { postalCode: '26278', city: 'ÄNGELHOLM' },
  { postalCode: '26279', city: 'ÄNGELHOLM' },
  { postalCode: '26280', city: 'ÄNGELHOLM' },
  { postalCode: '26281', city: 'ÄNGELHOLM' },
  { postalCode: '26282', city: 'ÄNGELHOLM' },
  { postalCode: '26291', city: 'ÄNGELHOLM' },
  { postalCode: '26292', city: 'ÄNGELHOLM' },
  { postalCode: '26293', city: 'ÄNGELHOLM' },
  { postalCode: '26294', city: 'ÄNGELHOLM' },
  { postalCode: '26295', city: 'ÄNGELHOLM' },
  { postalCode: '26296', city: 'ÄNGELHOLM' },
  { postalCode: '26300', city: 'HÖGANÄS' },
  { postalCode: '26303', city: 'VIKEN' },
  { postalCode: '26320', city: 'HÖGANÄS' },
  { postalCode: '26321', city: 'HÖGANÄS' },
  { postalCode: '26322', city: 'HÖGANÄS' },
  { postalCode: '26323', city: 'HÖGANÄS' },
  { postalCode: '26331', city: 'HÖGANÄS' },
  { postalCode: '26332', city: 'HÖGANÄS' },
  { postalCode: '26333', city: 'HÖGANÄS' },
  { postalCode: '26334', city: 'HÖGANÄS' },
  { postalCode: '26335', city: 'HÖGANÄS' },
  { postalCode: '26336', city: 'HÖGANÄS' },
  { postalCode: '26337', city: 'HÖGANÄS' },
  { postalCode: '26338', city: 'HÖGANÄS' },
  { postalCode: '26339', city: 'HÖGANÄS' },
  { postalCode: '26350', city: 'HÖGANÄS' },
  { postalCode: '26351', city: 'HÖGANÄS' },
  { postalCode: '26352', city: 'LERBERGET' },
  { postalCode: '26353', city: 'LERBERGET' },
  { postalCode: '26354', city: 'LERBERGET' },
  { postalCode: '26357', city: 'HÖGANÄS' },
  { postalCode: '26358', city: 'STRANDBADEN' },
  { postalCode: '26361', city: 'VIKEN' },
  { postalCode: '26362', city: 'VIKEN' },
  { postalCode: '26363', city: 'VIKEN' },
  { postalCode: '26364', city: 'VIKEN' },
  { postalCode: '26365', city: 'VIKEN' },
  { postalCode: '26371', city: 'JONSTORP' },
  { postalCode: '26372', city: 'SKÄRET' },
  { postalCode: '26373', city: 'ARILD' },
  { postalCode: '26375', city: 'NYHAMNSLÄGE' },
  { postalCode: '26376', city: 'NYHAMNSLÄGE' },
  { postalCode: '26377', city: 'MÖLLE' },
  { postalCode: '26382', city: 'HÖGANÄS' },
  { postalCode: '26383', city: 'HÖGANÄS' },
  { postalCode: '26391', city: 'HÖGANÄS' },
  { postalCode: '26392', city: 'JONSTORP' },
  { postalCode: '26394', city: 'MJÖHULT' },
  { postalCode: '26395', city: 'FARHULT' },
  { postalCode: '26401', city: 'KLIPPAN' },
  { postalCode: '26402', city: 'ÖSTRA LJUNGBY' },
  { postalCode: '26403', city: 'LJUNGBYHED' },
  { postalCode: '26420', city: 'KLIPPAN' },
  { postalCode: '26421', city: 'KLIPPAN' },
  { postalCode: '26422', city: 'KLIPPAN' },
  { postalCode: '26423', city: 'KLIPPAN' },
  { postalCode: '26431', city: 'KLIPPAN' },
  { postalCode: '26432', city: 'KLIPPAN' },
  { postalCode: '26433', city: 'KLIPPAN' },
  { postalCode: '26434', city: 'KLIPPAN' },
  { postalCode: '26435', city: 'KLIPPAN' },
  { postalCode: '26436', city: 'KLIPPAN' },
  { postalCode: '26437', city: 'KLIPPAN' },
  { postalCode: '26438', city: 'KLIPPAN' },
  { postalCode: '26439', city: 'KLIPPAN' },
  { postalCode: '26451', city: 'LJUNGBYHED' },
  { postalCode: '26452', city: 'LJUNGBYHED' },
  { postalCode: '26453', city: 'LJUNGBYHED' },
  { postalCode: '26454', city: 'LJUNGBYHED' },
  { postalCode: '26470', city: 'KLIPPAN' },
  { postalCode: '26471', city: 'ÖSTRA LJUNGBY' },
  { postalCode: '26480', city: 'KLIPPAN' },
  { postalCode: '26491', city: 'KLIPPAN' },
  { postalCode: '26492', city: 'KLIPPAN' },
  { postalCode: '26493', city: 'KLIPPAN' },
  { postalCode: '26494', city: 'KLIPPAN' },
  { postalCode: '26501', city: 'ÅSTORP' },
  { postalCode: '26502', city: 'KVIDINGE' },
  { postalCode: '26503', city: 'HYLLINGE' },
  { postalCode: '26520', city: 'ÅSTORP' },
  { postalCode: '26521', city: 'ÅSTORP' },
  { postalCode: '26522', city: 'ÅSTORP' },
  { postalCode: '26531', city: 'ÅSTORP' },
  { postalCode: '26532', city: 'ÅSTORP' },
  { postalCode: '26533', city: 'ÅSTORP' },
  { postalCode: '26534', city: 'ÅSTORP' },
  { postalCode: '26535', city: 'ÅSTORP' },
  { postalCode: '26536', city: 'ÅSTORP' },
  { postalCode: '26537', city: 'ÅSTORP' },
  { postalCode: '26538', city: 'ÅSTORP' },
  { postalCode: '26539', city: 'ÅSTORP' },
  { postalCode: '26540', city: 'ÅSTORP' },
  { postalCode: '26550', city: 'NYVÅNG' },
  { postalCode: '26571', city: 'KVIDINGE' },
  { postalCode: '26572', city: 'KVIDINGE' },
  { postalCode: '26574', city: 'HYLLINGE' },
  { postalCode: '26575', city: 'HYLLINGE' },
  { postalCode: '26580', city: 'ÅSTORP' },
  { postalCode: '26581', city: 'ÅSTORP' },
  { postalCode: '26582', city: 'ÅSTORP' },
  { postalCode: '26590', city: 'ÅSTORP' },
  { postalCode: '26602', city: 'VEJBYSTRAND' },
  { postalCode: '26621', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26631', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26632', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26633', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26634', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26651', city: 'VEJBYSTRAND' },
  { postalCode: '26652', city: 'VEJBYSTRAND' },
  { postalCode: '26653', city: 'VEJBYSTRAND' },
  { postalCode: '26654', city: 'VEJBYSTRAND' },
  { postalCode: '26655', city: 'VEJBYSTRAND' },
  { postalCode: '26675', city: 'HJÄRNARP' },
  { postalCode: '26691', city: 'ÖSSJÖ' },
  { postalCode: '26692', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26693', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26694', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26695', city: 'MUNKA-LJUNGBY' },
  { postalCode: '26697', city: 'HJÄRNARP' },
  { postalCode: '26698', city: 'HJÄRNARP' },
  { postalCode: '26701', city: 'BJUV' },
  { postalCode: '26702', city: 'BILLESHOLM' },
  { postalCode: '26703', city: 'EKEBY' },
  { postalCode: '26720', city: 'BJUV' },
  { postalCode: '26721', city: 'BJUV' },
  { postalCode: '26722', city: 'BJUV' },
  { postalCode: '26723', city: 'BJUV' },
  { postalCode: '26725', city: 'BJUV' },
  { postalCode: '26731', city: 'BJUV' },
  { postalCode: '26732', city: 'BJUV' },
  { postalCode: '26733', city: 'BJUV' },
  { postalCode: '26734', city: 'BJUV' },
  { postalCode: '26735', city: 'BJUV' },
  { postalCode: '26736', city: 'BJUV' },
  { postalCode: '26737', city: 'BJUV' },
  { postalCode: '26738', city: 'BJUV' },
  { postalCode: '26739', city: 'BJUV' },
  { postalCode: '26740', city: 'BJUV' },
  { postalCode: '26741', city: 'BJUV' },
  { postalCode: '26771', city: 'BILLESHOLM' },
  { postalCode: '26772', city: 'BILLESHOLM' },
  { postalCode: '26773', city: 'BILLESHOLM' },
  { postalCode: '26774', city: 'BILLESHOLM' },
  { postalCode: '26775', city: 'EKEBY' },
  { postalCode: '26776', city: 'EKEBY' },
  { postalCode: '26777', city: 'EKEBY' },
  { postalCode: '26781', city: 'BJUV' },
  { postalCode: '26782', city: 'BILLESHOLM' },
  { postalCode: '26790', city: 'BJUV' },
  { postalCode: '26801', city: 'SVALÖV' },
  { postalCode: '26802', city: 'TECKOMATORP' },
  { postalCode: '26805', city: 'KÅGERÖD' },
  { postalCode: '26820', city: 'SVALÖV' },
  { postalCode: '26821', city: 'SVALÖV' },
  { postalCode: '26822', city: 'SVALÖV' },
  { postalCode: '26831', city: 'SVALÖV' },
  { postalCode: '26832', city: 'SVALÖV' },
  { postalCode: '26833', city: 'SVALÖV' },
  { postalCode: '26834', city: 'SVALÖV' },
  { postalCode: '26868', city: 'RÖSTÅNGA' },
  { postalCode: '26871', city: 'TECKOMATORP' },
  { postalCode: '26872', city: 'TECKOMATORP' },
  { postalCode: '26873', city: 'BILLEBERGA' },
  { postalCode: '26874', city: 'BILLEBERGA' },
  { postalCode: '26875', city: 'TÅGARP' },
  { postalCode: '26876', city: 'KÅGERÖD' },
  { postalCode: '26877', city: 'KÅGERÖD' },
  { postalCode: '26878', city: 'KÅGERÖD' },
  { postalCode: '26880', city: 'SVALÖV' },
  { postalCode: '26881', city: 'SVALÖV' },
  { postalCode: '26890', city: 'SVALÖV' },
  { postalCode: '26901', city: 'BÅSTAD' },
  { postalCode: '26904', city: 'TOREKOV' },
  { postalCode: '26920', city: 'BÅSTAD' },
  { postalCode: '26921', city: 'BÅSTAD' },
  { postalCode: '26922', city: 'BÅSTAD' },
  { postalCode: '26931', city: 'BÅSTAD' },
  { postalCode: '26932', city: 'BÅSTAD' },
  { postalCode: '26933', city: 'BÅSTAD' },
  { postalCode: '26934', city: 'BÅSTAD' },
  { postalCode: '26935', city: 'BÅSTAD' },
  { postalCode: '26936', city: 'BÅSTAD' },
  { postalCode: '26937', city: 'BÅSTAD' },
  { postalCode: '26938', city: 'BÅSTAD' },
  { postalCode: '26939', city: 'BÅSTAD' },
  { postalCode: '26940', city: 'BÅSTAD' },
  { postalCode: '26941', city: 'ÖSTRA KARUP' },
  { postalCode: '26942', city: 'BÅSTAD' },
  { postalCode: '26943', city: 'BÅSTAD' },
  { postalCode: '26944', city: 'BÅSTAD' },
  { postalCode: '26962', city: 'GREVIE' },
  { postalCode: '26971', city: 'FÖRSLÖV' },
  { postalCode: '26972', city: 'FÖRSLÖV' },
  { postalCode: '26973', city: 'FÖRSLÖV' },
  { postalCode: '26974', city: 'VÄSTRA KARUP' },
  { postalCode: '26976', city: 'TOREKOV' },
  { postalCode: '26977', city: 'TOREKOV' },
  { postalCode: '26978', city: 'TOREKOV' },
  { postalCode: '26980', city: 'BÅSTAD' },
  { postalCode: '26982', city: 'BÅSTAD' },
  { postalCode: '26983', city: 'BÅSTAD' },
  { postalCode: '26991', city: 'BÅSTAD' },
  { postalCode: '26992', city: 'BÅSTAD' },
  { postalCode: '26993', city: 'BÅSTAD' },
  { postalCode: '26994', city: 'BÅSTAD' },
  { postalCode: '26995', city: 'BÅSTAD' },
  { postalCode: '26996', city: 'BÅSTAD' },
  { postalCode: '27100', city: 'YSTAD' },
  { postalCode: '27101', city: 'YSTAD' },
  { postalCode: '27102', city: 'YSTAD' },
  { postalCode: '27120', city: 'YSTAD' },
  { postalCode: '27121', city: 'YSTAD' },
  { postalCode: '27122', city: 'YSTAD' },
  { postalCode: '27123', city: 'YSTAD' },
  { postalCode: '27124', city: 'YSTAD' },
  { postalCode: '27125', city: 'YSTAD' },
  { postalCode: '27131', city: 'YSTAD' },
  { postalCode: '27132', city: 'YSTAD' },
  { postalCode: '27133', city: 'YSTAD' },
  { postalCode: '27134', city: 'YSTAD' },
  { postalCode: '27135', city: 'YSTAD' },
  { postalCode: '27136', city: 'YSTAD' },
  { postalCode: '27137', city: 'YSTAD' },
  { postalCode: '27138', city: 'YSTAD' },
  { postalCode: '27139', city: 'YSTAD' },
  { postalCode: '27140', city: 'YSTAD' },
  { postalCode: '27141', city: 'YSTAD' },
  { postalCode: '27142', city: 'YSTAD' },
  { postalCode: '27143', city: 'YSTAD' },
  { postalCode: '27144', city: 'YSTAD' },
  { postalCode: '27145', city: 'YSTAD' },
  { postalCode: '27146', city: 'YSTAD' },
  { postalCode: '27150', city: 'YSTAD' },
  { postalCode: '27151', city: 'YSTAD' },
  { postalCode: '27152', city: 'YSTAD' },
  { postalCode: '27153', city: 'YSTAD' },
  { postalCode: '27154', city: 'YSTAD' },
  { postalCode: '27155', city: 'YSTAD' },
  { postalCode: '27156', city: 'YSTAD' },
  { postalCode: '27157', city: 'YSTAD' },
  { postalCode: '27160', city: 'YSTAD' },
  { postalCode: '27170', city: 'NYBROSTRAND' },
  { postalCode: '27171', city: 'NYBROSTRAND' },
  { postalCode: '27172', city: 'KÖPINGEBRO' },
  { postalCode: '27173', city: 'KÖPINGEBRO' },
  { postalCode: '27174', city: 'GLEMMINGEBRO' },
  { postalCode: '27175', city: 'GLEMMINGEBRO' },
  { postalCode: '27176', city: 'LÖDERUP' },
  { postalCode: '27177', city: 'LÖDERUP' },
  { postalCode: '27178', city: 'LÖDERUP' },
  { postalCode: '27180', city: 'YSTAD' },
  { postalCode: '27181', city: 'YSTAD' },
  { postalCode: '27182', city: 'YSTAD' },
  { postalCode: '27191', city: 'YSTAD' },
  { postalCode: '27192', city: 'YSTAD' },
  { postalCode: '27193', city: 'YSTAD' },
  { postalCode: '27194', city: 'YSTAD' },
  { postalCode: '27195', city: 'YSTAD' },
  { postalCode: '27198', city: 'YSTAD' },
  { postalCode: '27199', city: 'YSTAD' },
  { postalCode: '27200', city: 'SIMRISHAMN' },
  { postalCode: '27220', city: 'SIMRISHAMN' },
  { postalCode: '27221', city: 'SIMRISHAMN' },
  { postalCode: '27222', city: 'SIMRISHAMN' },
  { postalCode: '27223', city: 'SIMRISHAMN' },
  { postalCode: '27224', city: 'SIMRISHAMN' },
  { postalCode: '27231', city: 'SIMRISHAMN' },
  { postalCode: '27232', city: 'SIMRISHAMN' },
  { postalCode: '27233', city: 'SIMRISHAMN' },
  { postalCode: '27234', city: 'SIMRISHAMN' },
  { postalCode: '27235', city: 'SIMRISHAMN' },
  { postalCode: '27236', city: 'SIMRISHAMN' },
  { postalCode: '27237', city: 'SIMRISHAMN' },
  { postalCode: '27238', city: 'BRANTEVIK' },
  { postalCode: '27239', city: 'SIMRISHAMN' },
  { postalCode: '27261', city: 'GÄRSNÄS' },
  { postalCode: '27280', city: 'SIMRISHAMN' },
  { postalCode: '27281', city: 'SIMRISHAMN' },
  { postalCode: '27291', city: 'SIMRISHAMN' },
  { postalCode: '27292', city: 'SIMRISHAMN' },
  { postalCode: '27293', city: 'TOMMARP' },
  { postalCode: '27294', city: 'SIMRISHAMN' },
  { postalCode: '27295', city: 'SIMRISHAMN' },
  { postalCode: '27296', city: 'GÄRSNÄS' },
  { postalCode: '27297', city: 'GÄRSNÄS' },
  { postalCode: '27301', city: 'TOMELILLA' },
  { postalCode: '27320', city: 'TOMELILLA' },
  { postalCode: '27321', city: 'TOMELILLA' },
  { postalCode: '27322', city: 'TOMELILLA' },
  { postalCode: '27330', city: 'TOMELILLA' },
  { postalCode: '27331', city: 'TOMELILLA' },
  { postalCode: '27332', city: 'TOMELILLA' },
  { postalCode: '27333', city: 'TOMELILLA' },
  { postalCode: '27334', city: 'TOMELILLA' },
  { postalCode: '27335', city: 'TOMELILLA' },
  { postalCode: '27336', city: 'TOMELILLA' },
  { postalCode: '27350', city: 'BRÖSARP' },
  { postalCode: '27355', city: 'BRÖSARP' },
  { postalCode: '27356', city: 'BRÖSARP' },
  { postalCode: '27357', city: 'BRÖSARP' },
  { postalCode: '27380', city: 'TOMELILLA' },
  { postalCode: '27391', city: 'TOMELILLA' },
  { postalCode: '27392', city: 'SKÅNE-TRANÅS' },
  { postalCode: '27393', city: 'TOMELILLA' },
  { postalCode: '27394', city: 'TOMELILLA' },
  { postalCode: '27395', city: 'ONSLUNDA' },
  { postalCode: '27396', city: 'TOMELILLA' },
  { postalCode: '27397', city: 'TOMELILLA' },
  { postalCode: '27398', city: 'SMEDSTORP' },
  { postalCode: '27401', city: 'SKURUP' },
  { postalCode: '27402', city: 'SKIVARP' },
  { postalCode: '27406', city: 'SKURUP' },
  { postalCode: '27420', city: 'SKURUP' },
  { postalCode: '27421', city: 'SKURUP' },
  { postalCode: '27422', city: 'SKURUP' },
  { postalCode: '27423', city: 'SKURUP' },
  { postalCode: '27424', city: 'SKURUP' },
  { postalCode: '27430', city: 'SKURUP' },
  { postalCode: '27431', city: 'SKURUP' },
  { postalCode: '27432', city: 'SKURUP' },
  { postalCode: '27433', city: 'SKURUP' },
  { postalCode: '27434', city: 'SKURUP' },
  { postalCode: '27435', city: 'SKURUP' },
  { postalCode: '27436', city: 'SKURUP' },
  { postalCode: '27437', city: 'SKURUP' },
  { postalCode: '27450', city: 'SKIVARP' },
  { postalCode: '27451', city: 'SKIVARP' },
  { postalCode: '27453', city: 'SKIVARP' },
  { postalCode: '27454', city: 'SKIVARP' },
  { postalCode: '27456', city: 'ABBEKÅS' },
  { postalCode: '27460', city: 'RYDSGÅRD' },
  { postalCode: '27461', city: 'RYDSGÅRD' },
  { postalCode: '27462', city: 'RYDSGÅRD' },
  { postalCode: '27463', city: 'RYDSGÅRD' },
  { postalCode: '27480', city: 'SKURUP' },
  { postalCode: '27491', city: 'SKURUP' },
  { postalCode: '27492', city: 'SKURUP' },
  { postalCode: '27493', city: 'SKURUP' },
  { postalCode: '27494', city: 'SKURUP' },
  { postalCode: '27500', city: 'SJÖBO' },
  { postalCode: '27505', city: 'BLENTARP' },
  { postalCode: '27506', city: 'VOLLSJÖ' },
  { postalCode: '27520', city: 'SJÖBO' },
  { postalCode: '27521', city: 'SJÖBO' },
  { postalCode: '27522', city: 'SJÖBO' },
  { postalCode: '27523', city: 'SJÖBO' },
  { postalCode: '27530', city: 'SJÖBO' },
  { postalCode: '27531', city: 'SJÖBO' },
  { postalCode: '27532', city: 'SJÖBO' },
  { postalCode: '27533', city: 'SJÖBO' },
  { postalCode: '27534', city: 'SJÖBO' },
  { postalCode: '27535', city: 'SJÖBO' },
  { postalCode: '27536', city: 'SJÖBO' },
  { postalCode: '27537', city: 'SJÖBO' },
  { postalCode: '27538', city: 'SJÖBO' },
  { postalCode: '27539', city: 'SJÖBO' },
  { postalCode: '27562', city: 'BLENTARP' },
  { postalCode: '27563', city: 'BLENTARP' },
  { postalCode: '27564', city: 'BLENTARP' },
  { postalCode: '27566', city: 'VOLLSJÖ' },
  { postalCode: '27567', city: 'VOLLSJÖ' },
  { postalCode: '27568', city: 'VOLLSJÖ' },
  { postalCode: '27570', city: 'LÖVESTAD' },
  { postalCode: '27571', city: 'LÖVESTAD' },
  { postalCode: '27572', city: 'LÖVESTAD' },
  { postalCode: '27575', city: 'LÖVESTAD' },
  { postalCode: '27576', city: 'ÄSPERÖD' },
  { postalCode: '27580', city: 'SJÖBO' },
  { postalCode: '27591', city: 'SJÖBO' },
  { postalCode: '27592', city: 'SJÖBO' },
  { postalCode: '27593', city: 'SJÖBO' },
  { postalCode: '27594', city: 'SJÖBO' },
  { postalCode: '27595', city: 'SJÖBO' },
  { postalCode: '27596', city: 'SÖVDE' },
  { postalCode: '27630', city: 'BORRBY' },
  { postalCode: '27635', city: 'BORRBY' },
  { postalCode: '27636', city: 'BORRBY' },
  { postalCode: '27637', city: 'BORRBY' },
  { postalCode: '27650', city: 'HAMMENHÖG' },
  { postalCode: '27655', city: 'HAMMENHÖG' },
  { postalCode: '27656', city: 'HAMMENHÖG' },
  { postalCode: '27660', city: 'SKILLINGE' },
  { postalCode: '27730', city: 'KIVIK' },
  { postalCode: '27731', city: 'KIVIK' },
  { postalCode: '27732', city: 'KIVIK' },
  { postalCode: '27735', city: 'KIVIK' },
  { postalCode: '27736', city: 'VITABY' },
  { postalCode: '27737', city: 'KIVIK' },
  { postalCode: '27740', city: 'SANKT OLOF' },
  { postalCode: '27745', city: 'SANKT OLOF' },
  { postalCode: '28101', city: 'HÄSSLEHOLM' },
  { postalCode: '28102', city: 'HÄSSLEHOLM' },
  { postalCode: '28108', city: 'HÄSSLEHOLM' },
  { postalCode: '28110', city: 'HÄSSLEHOLM' },
  { postalCode: '28111', city: 'HÄSSLEHOLM' },
  { postalCode: '28120', city: 'HÄSSLEHOLM' },
  { postalCode: '28121', city: 'HÄSSLEHOLM' },
  { postalCode: '28122', city: 'HÄSSLEHOLM' },
  { postalCode: '28123', city: 'HÄSSLEHOLM' },
  { postalCode: '28124', city: 'HÄSSLEHOLM' },
  { postalCode: '28125', city: 'HÄSSLEHOLM' },
  { postalCode: '28131', city: 'HÄSSLEHOLM' },
  { postalCode: '28132', city: 'HÄSSLEHOLM' },
  { postalCode: '28133', city: 'HÄSSLEHOLM' },
  { postalCode: '28134', city: 'HÄSSLEHOLM' },
  { postalCode: '28135', city: 'HÄSSLEHOLM' },
  { postalCode: '28136', city: 'HÄSSLEHOLM' },
  { postalCode: '28137', city: 'HÄSSLEHOLM' },
  { postalCode: '28138', city: 'HÄSSLEHOLM' },
  { postalCode: '28139', city: 'HÄSSLEHOLM' },
  { postalCode: '28140', city: 'HÄSSLEHOLM' },
  { postalCode: '28141', city: 'HÄSSLEHOLM' },
  { postalCode: '28142', city: 'HÄSSLEHOLM' },
  { postalCode: '28143', city: 'HÄSSLEHOLM' },
  { postalCode: '28144', city: 'STOBY' },
  { postalCode: '28145', city: 'HÄSSLEHOLM' },
  { postalCode: '28146', city: 'TORMESTORP' },
  { postalCode: '28147', city: 'HÄSSLEHOLM' },
  { postalCode: '28148', city: 'HÄSSLEHOLM' },
  { postalCode: '28149', city: 'HÄSSLEHOLM' },
  { postalCode: '28150', city: 'HÄSSLEHOLM' },
  { postalCode: '28151', city: 'HÄSSLEHOLM' },
  { postalCode: '28152', city: 'HÄSSLEHOLM' },
  { postalCode: '28153', city: 'FINJA' },
  { postalCode: '28180', city: 'HÄSSLEHOLM' },
  { postalCode: '28181', city: 'HÄSSLEHOLM' },
  { postalCode: '28182', city: 'HÄSSLEHOLM' },
  { postalCode: '28183', city: 'HÄSSLEHOLM' },
  { postalCode: '28185', city: 'HÄSSLEHOLM' },
  { postalCode: '28187', city: 'BALLINGSLÖV' },
  { postalCode: '28191', city: 'HÄSSLEHOLM' },
  { postalCode: '28192', city: 'HÄSSLEHOLM' },
  { postalCode: '28193', city: 'FINJA' },
  { postalCode: '28195', city: 'VANKIVA' },
  { postalCode: '28197', city: 'BALLINGSLÖV' },
  { postalCode: '28201', city: 'TYRINGE' },
  { postalCode: '28203', city: 'BJÄRNUM' },
  { postalCode: '28204', city: 'VITTSJÖ' },
  { postalCode: '28205', city: 'SÖSDALA' },
  { postalCode: '28206', city: 'HÄSTVEDA' },
  { postalCode: '28221', city: 'TYRINGE' },
  { postalCode: '28222', city: 'TYRINGE' },
  { postalCode: '28223', city: 'TYRINGE' },
  { postalCode: '28224', city: 'TYRINGE' },
  { postalCode: '28231', city: 'TYRINGE' },
  { postalCode: '28232', city: 'TYRINGE' },
  { postalCode: '28233', city: 'TYRINGE' },
  { postalCode: '28234', city: 'TYRINGE' },
  { postalCode: '28235', city: 'TYRINGE' },
  { postalCode: '28261', city: 'BJÄRNUM' },
  { postalCode: '28262', city: 'BJÄRNUM' },
  { postalCode: '28263', city: 'BJÄRNUM' },
  { postalCode: '28264', city: 'BJÄRNUM' },
  { postalCode: '28265', city: 'BJÄRNUM' },
  { postalCode: '28266', city: 'VITTSJÖ' },
  { postalCode: '28267', city: 'VITTSJÖ' },
  { postalCode: '28268', city: 'VITTSJÖ' },
  { postalCode: '28269', city: 'VITTSJÖ' },
  { postalCode: '28271', city: 'SÖSDALA' },
  { postalCode: '28272', city: 'SÖSDALA' },
  { postalCode: '28273', city: 'SÖSDALA' },
  { postalCode: '28274', city: 'HÄSTVEDA' },
  { postalCode: '28275', city: 'HÄSTVEDA' },
  { postalCode: '28276', city: 'HÄSTVEDA' },
  { postalCode: '28291', city: 'TYRINGE' },
  { postalCode: '28292', city: 'VÄSTRA TORUP' },
  { postalCode: '28293', city: 'RÖKE' },
  { postalCode: '28294', city: 'HÖRJA' },
  { postalCode: '28295', city: 'TYRINGE' },
  { postalCode: '28300', city: 'OSBY' },
  { postalCode: '28301', city: 'OSBY' },
  { postalCode: '28302', city: 'LÖNSBODA' },
  { postalCode: '28320', city: 'OSBY' },
  { postalCode: '28321', city: 'OSBY' },
  { postalCode: '28322', city: 'OSBY' },
  { postalCode: '28323', city: 'OSBY' },
  { postalCode: '28331', city: 'OSBY' },
  { postalCode: '28332', city: 'OSBY' },
  { postalCode: '28333', city: 'OSBY' },
  { postalCode: '28334', city: 'OSBY' },
  { postalCode: '28335', city: 'OSBY' },
  { postalCode: '28336', city: 'OSBY' },
  { postalCode: '28341', city: 'OSBY' },
  { postalCode: '28342', city: 'OSBY' },
  { postalCode: '28343', city: 'OSBY' },
  { postalCode: '28344', city: 'OSBY' },
  { postalCode: '28345', city: 'OSBY' },
  { postalCode: '28350', city: 'OSBY' },
  { postalCode: '28371', city: 'LÖNSBODA' },
  { postalCode: '28372', city: 'LÖNSBODA' },
  { postalCode: '28376', city: 'KILLEBERG' },
  { postalCode: '28380', city: 'OSBY' },
  { postalCode: '28383', city: 'OSBY' },
  { postalCode: '28391', city: 'OSBY' },
  { postalCode: '28393', city: 'LÖNSBODA' },
  { postalCode: '28394', city: 'LÖNSBODA' },
  { postalCode: '28395', city: 'VISSELTOFTA' },
  { postalCode: '28396', city: 'KILLEBERG' },
  { postalCode: '28401', city: 'PERSTORP' },
  { postalCode: '28420', city: 'PERSTORP' },
  { postalCode: '28421', city: 'PERSTORP' },
  { postalCode: '28422', city: 'PERSTORP' },
  { postalCode: '28431', city: 'PERSTORP' },
  { postalCode: '28432', city: 'PERSTORP' },
  { postalCode: '28433', city: 'PERSTORP' },
  { postalCode: '28434', city: 'PERSTORP' },
  { postalCode: '28435', city: 'PERSTORP' },
  { postalCode: '28436', city: 'PERSTORP' },
  { postalCode: '28437', city: 'PERSTORP' },
  { postalCode: '28438', city: 'PERSTORP' },
  { postalCode: '28480', city: 'PERSTORP' },
  { postalCode: '28485', city: 'PERSTORP' },
  { postalCode: '28491', city: 'PERSTORP' },
  { postalCode: '28492', city: 'PERSTORP' },
  { postalCode: '28501', city: 'MARKARYD' },
  { postalCode: '28520', city: 'MARKARYD' },
  { postalCode: '28521', city: 'MARKARYD' },
  { postalCode: '28522', city: 'MARKARYD' },
  { postalCode: '28523', city: 'MARKARYD' },
  { postalCode: '28531', city: 'MARKARYD' },
  { postalCode: '28532', city: 'MARKARYD' },
  { postalCode: '28533', city: 'MARKARYD' },
  { postalCode: '28534', city: 'MARKARYD' },
  { postalCode: '28535', city: 'MARKARYD' },
  { postalCode: '28536', city: 'MARKARYD' },
  { postalCode: '28537', city: 'MARKARYD' },
  { postalCode: '28538', city: 'MARKARYD' },
  { postalCode: '28539', city: 'MARKARYD' },
  { postalCode: '28540', city: 'MARKARYD' },
  { postalCode: '28591', city: 'MARKARYD' },
  { postalCode: '28593', city: 'MARKARYD' },
  { postalCode: '28601', city: 'ÖRKELLJUNGA' },
  { postalCode: '28620', city: 'ÖRKELLJUNGA' },
  { postalCode: '28621', city: 'ÖRKELLJUNGA' },
  { postalCode: '28622', city: 'ÖRKELLJUNGA' },
  { postalCode: '28631', city: 'ÖRKELLJUNGA' },
  { postalCode: '28632', city: 'ÖRKELLJUNGA' },
  { postalCode: '28633', city: 'ÖRKELLJUNGA' },
  { postalCode: '28634', city: 'ÖRKELLJUNGA' },
  { postalCode: '28635', city: 'ÖRKELLJUNGA' },
  { postalCode: '28636', city: 'ÖRKELLJUNGA' },
  { postalCode: '28637', city: 'ÖRKELLJUNGA' },
  { postalCode: '28638', city: 'ÖRKELLJUNGA' },
  { postalCode: '28672', city: 'ÅSLJUNGA' },
  { postalCode: '28673', city: 'SKÅNES FAGERHULT' },
  { postalCode: '28674', city: 'SKÅNES FAGERHULT' },
  { postalCode: '28675', city: 'SKÅNES FAGERHULT' },
  { postalCode: '28680', city: 'ÖRKELLJUNGA' },
  { postalCode: '28685', city: 'ÖRKELLJUNGA' },
  { postalCode: '28691', city: 'ÖRKELLJUNGA' },
  { postalCode: '28692', city: 'ÖRKELLJUNGA' },
  { postalCode: '28695', city: 'EKET' },
  { postalCode: '28721', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28722', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28731', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28732', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28733', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28734', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28772', city: 'TRARYD' },
  { postalCode: '28791', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28792', city: 'TRARYD' },
  { postalCode: '28793', city: 'STRÖMSNÄSBRUK' },
  { postalCode: '28820', city: 'VINSLÖV' },
  { postalCode: '28821', city: 'VINSLÖV' },
  { postalCode: '28822', city: 'VINSLÖV' },
  { postalCode: '28831', city: 'VINSLÖV' },
  { postalCode: '28832', city: 'VINSLÖV' },
  { postalCode: '28833', city: 'VINSLÖV' },
  { postalCode: '28834', city: 'VINSLÖV' },
  { postalCode: '28890', city: 'VINSLÖV' },
  { postalCode: '28893', city: 'NÄVLINGE' },
  { postalCode: '28900', city: 'BROBY' },
  { postalCode: '28903', city: 'BROBY' },
  { postalCode: '28904', city: 'GLIMÅKRA' },
  { postalCode: '28905', city: 'HANASKOG' },
  { postalCode: '28906', city: 'SIBBHULT' },
  { postalCode: '28920', city: 'BROBY' },
  { postalCode: '28921', city: 'KNISLINGE' },
  { postalCode: '28931', city: 'KNISLINGE' },
  { postalCode: '28932', city: 'KNISLINGE' },
  { postalCode: '28933', city: 'KNISLINGE' },
  { postalCode: '28941', city: 'BROBY' },
  { postalCode: '28942', city: 'BROBY' },
  { postalCode: '28943', city: 'BROBY' },
  { postalCode: '28946', city: 'GLIMÅKRA' },
  { postalCode: '28947', city: 'GLIMÅKRA' },
  { postalCode: '28950', city: 'HANASKOG' },
  { postalCode: '28971', city: 'SIBBHULT' },
  { postalCode: '28972', city: 'SIBBHULT' },
  { postalCode: '28973', city: 'IMMELN' },
  { postalCode: '28989', city: 'HANASKOG' },
  { postalCode: '28990', city: 'KNISLINGE' },
  { postalCode: '28992', city: 'BROBY' },
  { postalCode: '28993', city: 'BROBY' },
  { postalCode: '28994', city: 'GLIMÅKRA' },
  { postalCode: '29100', city: 'KRISTIANSTAD' },
  { postalCode: '29102', city: 'KRISTIANSTAD' },
  { postalCode: '29103', city: 'KRISTIANSTAD' },
  { postalCode: '29105', city: 'KRISTIANSTAD' },
  { postalCode: '29107', city: 'FJÄLKINGE' },
  { postalCode: '29109', city: 'KRISTIANSTAD' },
  { postalCode: '29111', city: 'FÄRLÖV' },
  { postalCode: '29112', city: 'ÖNNESTAD' },
  { postalCode: '29113', city: 'GÄRDS KÖPINGE' },
  { postalCode: '29115', city: 'KRISTIANSTAD' },
  { postalCode: '29116', city: 'KRISTIANSTAD' },
  { postalCode: '29117', city: 'KRISTIANSTAD' },
  { postalCode: '29120', city: 'KRISTIANSTAD' },
  { postalCode: '29121', city: 'KRISTIANSTAD' },
  { postalCode: '29122', city: 'KRISTIANSTAD' },
  { postalCode: '29123', city: 'KRISTIANSTAD' },
  { postalCode: '29125', city: 'KRISTIANSTAD' },
  { postalCode: '29126', city: 'KRISTIANSTAD' },
  { postalCode: '29128', city: 'KRISTIANSTAD' },
  { postalCode: '29131', city: 'KRISTIANSTAD' },
  { postalCode: '29132', city: 'KRISTIANSTAD' },
  { postalCode: '29133', city: 'KRISTIANSTAD' },
  { postalCode: '29134', city: 'KRISTIANSTAD' },
  { postalCode: '29135', city: 'KRISTIANSTAD' },
  { postalCode: '29136', city: 'KRISTIANSTAD' },
  { postalCode: '29137', city: 'KRISTIANSTAD' },
  { postalCode: '29138', city: 'KRISTIANSTAD' },
  { postalCode: '29139', city: 'KRISTIANSTAD' },
  { postalCode: '29140', city: 'KRISTIANSTAD' },
  { postalCode: '29141', city: 'KRISTIANSTAD' },
  { postalCode: '29142', city: 'KRISTIANSTAD' },
  { postalCode: '29143', city: 'KRISTIANSTAD' },
  { postalCode: '29144', city: 'KRISTIANSTAD' },
  { postalCode: '29145', city: 'KRISTIANSTAD' },
  { postalCode: '29146', city: 'KRISTIANSTAD' },
  { postalCode: '29147', city: 'KRISTIANSTAD' },
  { postalCode: '29148', city: 'KRISTIANSTAD' },
  { postalCode: '29149', city: 'KRISTIANSTAD' },
  { postalCode: '29150', city: 'KRISTIANSTAD' },
  { postalCode: '29151', city: 'KRISTIANSTAD' },
  { postalCode: '29153', city: 'KRISTIANSTAD' },
  { postalCode: '29154', city: 'KRISTIANSTAD' },
  { postalCode: '29155', city: 'ARKELSTORP' },
  { postalCode: '29156', city: 'ARKELSTORP' },
  { postalCode: '29157', city: 'ARKELSTORP' },
  { postalCode: '29158', city: 'KRISTIANSTAD' },
  { postalCode: '29159', city: 'KRISTIANSTAD' },
  { postalCode: '29160', city: 'KRISTIANSTAD' },
  { postalCode: '29161', city: 'KRISTIANSTAD' },
  { postalCode: '29162', city: 'KRISTIANSTAD' },
  { postalCode: '29163', city: 'KRISTIANSTAD' },
  { postalCode: '29165', city: 'KRISTIANSTAD' },
  { postalCode: '29166', city: 'KRISTIANSTAD' },
  { postalCode: '29167', city: 'FJÄLKINGE' },
  { postalCode: '29168', city: 'FJÄLKINGE' },
  { postalCode: '29169', city: 'FJÄLKINGE' },
  { postalCode: '29170', city: 'KRISTIANSTAD' },
  { postalCode: '29173', city: 'ÖNNESTAD' },
  { postalCode: '29175', city: 'FÄRLÖV' },
  { postalCode: '29176', city: 'RINKABY' },
  { postalCode: '29177', city: 'GÄRDS KÖPINGE' },
  { postalCode: '29180', city: 'KRISTIANSTAD' },
  { postalCode: '29181', city: 'KRISTIANSTAD' },
  { postalCode: '29184', city: 'KRISTIANSTAD' },
  { postalCode: '29185', city: 'KRISTIANSTAD' },
  { postalCode: '29186', city: 'KRISTIANSTAD' },
  { postalCode: '29187', city: 'KRISTIANSTAD' },
  { postalCode: '29188', city: 'KRISTIANSTAD' },
  { postalCode: '29189', city: 'KRISTIANSTAD' },
  { postalCode: '29191', city: 'KRISTIANSTAD' },
  { postalCode: '29192', city: 'KRISTIANSTAD' },
  { postalCode: '29193', city: 'ÖNNESTAD' },
  { postalCode: '29194', city: 'KRISTIANSTAD' },
  { postalCode: '29195', city: 'FÄRLÖV' },
  { postalCode: '29197', city: 'GÄRDS KÖPINGE' },
  { postalCode: '29198', city: 'VILLANDS VÅNGA' },
  { postalCode: '29300', city: 'OLOFSTRÖM' },
  { postalCode: '29302', city: 'JÄMSHÖG' },
  { postalCode: '29320', city: 'OLOFSTRÖM' },
  { postalCode: '29321', city: 'OLOFSTRÖM' },
  { postalCode: '29322', city: 'OLOFSTRÖM' },
  { postalCode: '29323', city: 'OLOFSTRÖM' },
  { postalCode: '29324', city: 'OLOFSTRÖM' },
  { postalCode: '29331', city: 'OLOFSTRÖM' },
  { postalCode: '29332', city: 'OLOFSTRÖM' },
  { postalCode: '29333', city: 'OLOFSTRÖM' },
  { postalCode: '29334', city: 'OLOFSTRÖM' },
  { postalCode: '29335', city: 'OLOFSTRÖM' },
  { postalCode: '29336', city: 'OLOFSTRÖM' },
  { postalCode: '29337', city: 'OLOFSTRÖM' },
  { postalCode: '29338', city: 'OLOFSTRÖM' },
  { postalCode: '29339', city: 'OLOFSTRÖM' },
  { postalCode: '29340', city: 'OLOFSTRÖM' },
  { postalCode: '29341', city: 'OLOFSTRÖM' },
  { postalCode: '29342', city: 'OLOFSTRÖM' },
  { postalCode: '29372', city: 'JÄMSHÖG' },
  { postalCode: '29373', city: 'JÄMSHÖG' },
  { postalCode: '29374', city: 'KYRKHULT' },
  { postalCode: '29380', city: 'OLOFSTRÖM' },
  { postalCode: '29391', city: 'OLOFSTRÖM' },
  { postalCode: '29392', city: 'JÄMSHÖG' },
  { postalCode: '29393', city: 'OLOFSTRÖM' },
  { postalCode: '29394', city: 'KYRKHULT' },
  { postalCode: '29395', city: 'VILSHULT' },
  { postalCode: '29400', city: 'SÖLVESBORG' },
  { postalCode: '29407', city: 'SÖLVESBORG' },
  { postalCode: '29420', city: 'SÖLVESBORG' },
  { postalCode: '29421', city: 'SÖLVESBORG' },
  { postalCode: '29422', city: 'SÖLVESBORG' },
  { postalCode: '29425', city: 'SÖLVESBORG' },
  { postalCode: '29431', city: 'SÖLVESBORG' },
  { postalCode: '29432', city: 'SÖLVESBORG' },
  { postalCode: '29433', city: 'SÖLVESBORG' },
  { postalCode: '29434', city: 'SÖLVESBORG' },
  { postalCode: '29435', city: 'SÖLVESBORG' },
  { postalCode: '29436', city: 'SÖLVESBORG' },
  { postalCode: '29437', city: 'SÖLVESBORG' },
  { postalCode: '29438', city: 'SÖLVESBORG' },
  { postalCode: '29439', city: 'SÖLVESBORG' },
  { postalCode: '29440', city: 'SÖLVESBORG' },
  { postalCode: '29471', city: 'SÖLVESBORG' },
  { postalCode: '29472', city: 'SÖLVESBORG' },
  { postalCode: '29474', city: 'SÖLVESBORG' },
  { postalCode: '29475', city: 'SÖLVESBORG' },
  { postalCode: '29476', city: 'SÖLVESBORG' },
  { postalCode: '29477', city: 'SÖLVESBORG' },
  { postalCode: '29480', city: 'SÖLVESBORG' },
  { postalCode: '29481', city: 'SÖLVESBORG' },
  { postalCode: '29491', city: 'SÖLVESBORG' },
  { postalCode: '29492', city: 'SÖLVESBORG' },
  { postalCode: '29493', city: 'SÖLVESBORG' },
  { postalCode: '29494', city: 'SÖLVESBORG' },
  { postalCode: '29495', city: 'SÖLVESBORG' },
  { postalCode: '29500', city: 'BROMÖLLA' },
  { postalCode: '29504', city: 'NÄSUM' },
  { postalCode: '29520', city: 'BROMÖLLA' },
  { postalCode: '29521', city: 'BROMÖLLA' },
  { postalCode: '29522', city: 'BROMÖLLA' },
  { postalCode: '29531', city: 'BROMÖLLA' },
  { postalCode: '29532', city: 'BROMÖLLA' },
  { postalCode: '29534', city: 'BROMÖLLA' },
  { postalCode: '29535', city: 'BROMÖLLA' },
  { postalCode: '29536', city: 'BROMÖLLA' },
  { postalCode: '29537', city: 'BROMÖLLA' },
  { postalCode: '29538', city: 'BROMÖLLA' },
  { postalCode: '29539', city: 'BROMÖLLA' },
  { postalCode: '29551', city: 'BROMÖLLA' },
  { postalCode: '29572', city: 'GUALÖV' },
  { postalCode: '29573', city: 'NYMÖLLA' },
  { postalCode: '29574', city: 'NÄSUM' },
  { postalCode: '29580', city: 'NYMÖLLA' },
  { postalCode: '29591', city: 'BROMÖLLA' },
  { postalCode: '29594', city: 'NÄSUM' },
  { postalCode: '29600', city: 'ÅHUS' },
  { postalCode: '29620', city: 'ÅHUS' },
  { postalCode: '29621', city: 'ÅHUS' },
  { postalCode: '29622', city: 'ÅHUS' },
  { postalCode: '29623', city: 'ÅHUS' },
  { postalCode: '29624', city: 'ÅHUS' },
  { postalCode: '29625', city: 'ÅHUS' },
  { postalCode: '29631', city: 'ÅHUS' },
  { postalCode: '29632', city: 'ÅHUS' },
  { postalCode: '29633', city: 'ÅHUS' },
  { postalCode: '29634', city: 'ÅHUS' },
  { postalCode: '29635', city: 'ÅHUS' },
  { postalCode: '29637', city: 'ÅHUS' },
  { postalCode: '29638', city: 'ÅHUS' },
  { postalCode: '29641', city: 'ÅHUS' },
  { postalCode: '29672', city: 'YNGSJÖ' },
  { postalCode: '29680', city: 'ÅHUS' },
  { postalCode: '29681', city: 'ÅHUS' },
  { postalCode: '29685', city: 'ÅHUS' },
  { postalCode: '29686', city: 'ÅHUS' },
  { postalCode: '29691', city: 'ÅHUS' },
  { postalCode: '29692', city: 'YNGSJÖ' },
  { postalCode: '29702', city: 'EVERÖD' },
  { postalCode: '29721', city: 'DEGEBERGA' },
  { postalCode: '29731', city: 'DEGEBERGA' },
  { postalCode: '29732', city: 'DEGEBERGA' },
  { postalCode: '29772', city: 'EVERÖD' },
  { postalCode: '29791', city: 'VITTSKÖVLE' },
  { postalCode: '29792', city: 'EVERÖD' },
  { postalCode: '29794', city: 'DEGEBERGA' },
  { postalCode: '29795', city: 'DEGEBERGA' },
  { postalCode: '29801', city: 'TOLLARP' },
  { postalCode: '29820', city: 'TOLLARP' },
  { postalCode: '29821', city: 'TOLLARP' },
  { postalCode: '29831', city: 'TOLLARP' },
  { postalCode: '29832', city: 'TOLLARP' },
  { postalCode: '29833', city: 'TOLLARP' },
  { postalCode: '29834', city: 'TOLLARP' },
  { postalCode: '29891', city: 'TOLLARP' },
  { postalCode: '29892', city: 'TOLLARP' },
  { postalCode: '29893', city: 'HUARÖD' },
  { postalCode: '29894', city: 'LINDERÖD' },
  { postalCode: '29896', city: 'ÖSTRA SÖNNARSLÖV' },
  { postalCode: '30004', city: 'HALMSTAD' },
  { postalCode: '30007', city: 'HALMSTAD' },
  { postalCode: '30008', city: 'HALMSTAD' },
  { postalCode: '30010', city: 'HALMSTAD' },
  { postalCode: '30102', city: 'HALMSTAD' },
  { postalCode: '30103', city: 'HALMSTAD' },
  { postalCode: '30104', city: 'HALMSTAD' },
  { postalCode: '30105', city: 'HALMSTAD' },
  { postalCode: '30106', city: 'HALMSTAD' },
  { postalCode: '30107', city: 'HALMSTAD' },
  { postalCode: '30108', city: 'HALMSTAD' },
  { postalCode: '30109', city: 'HALMSTAD' },
  { postalCode: '30110', city: 'HALMSTAD' },
  { postalCode: '30116', city: 'HALMSTAD' },
  { postalCode: '30118', city: 'HALMSTAD' },
  { postalCode: '30121', city: 'HALMSTAD' },
  { postalCode: '30122', city: 'HALMSTAD' },
  { postalCode: '30123', city: 'HALMSTAD' },
  { postalCode: '30124', city: 'HALMSTAD' },
  { postalCode: '30125', city: 'HALMSTAD' },
  { postalCode: '30127', city: 'HALMSTAD' },
  { postalCode: '30128', city: 'HALMSTAD' },
  { postalCode: '30180', city: 'HALMSTAD' },
  { postalCode: '30181', city: 'HALMSTAD' },
  { postalCode: '30182', city: 'HALMSTAD' },
  { postalCode: '30185', city: 'HALMSTAD' },
  { postalCode: '30186', city: 'HALMSTAD' },
  { postalCode: '30187', city: 'HALMSTAD' },
  { postalCode: '30201', city: 'HALMSTAD' },
  { postalCode: '30202', city: 'HALMSTAD' },
  { postalCode: '30203', city: 'HALMSTAD' },
  { postalCode: '30204', city: 'HALMSTAD' },
  { postalCode: '30220', city: 'HALMSTAD' },
  { postalCode: '30221', city: 'HALMSTAD' },
  { postalCode: '30222', city: 'HALMSTAD' },
  { postalCode: '30223', city: 'HALMSTAD' },
  { postalCode: '30224', city: 'HALMSTAD' },
  { postalCode: '30225', city: 'HALMSTAD' },
  { postalCode: '30226', city: 'HALMSTAD' },
  { postalCode: '30227', city: 'HALMSTAD' },
  { postalCode: '30228', city: 'HALMSTAD' },
  { postalCode: '30229', city: 'HALMSTAD' },
  { postalCode: '30230', city: 'HALMSTAD' },
  { postalCode: '30231', city: 'HALMSTAD' },
  { postalCode: '30232', city: 'HALMSTAD' },
  { postalCode: '30233', city: 'HALMSTAD' },
  { postalCode: '30234', city: 'HALMSTAD' },
  { postalCode: '30235', city: 'HALMSTAD' },
  { postalCode: '30236', city: 'HALMSTAD' },
  { postalCode: '30237', city: 'HALMSTAD' },
  { postalCode: '30238', city: 'HALMSTAD' },
  { postalCode: '30239', city: 'HALMSTAD' },
  { postalCode: '30240', city: 'HALMSTAD' },
  { postalCode: '30241', city: 'HALMSTAD' },
  { postalCode: '30242', city: 'HALMSTAD' },
  { postalCode: '30243', city: 'HALMSTAD' },
  { postalCode: '30244', city: 'HALMSTAD' },
  { postalCode: '30245', city: 'HALMSTAD' },
  { postalCode: '30246', city: 'HALMSTAD' },
  { postalCode: '30247', city: 'HALMSTAD' },
  { postalCode: '30248', city: 'HALMSTAD' },
  { postalCode: '30249', city: 'HALMSTAD' },
  { postalCode: '30250', city: 'HALMSTAD' },
  { postalCode: '30251', city: 'HALMSTAD' },
  { postalCode: '30252', city: 'HALMSTAD' },
  { postalCode: '30253', city: 'HALMSTAD' },
  { postalCode: '30254', city: 'HALMSTAD' },
  { postalCode: '30255', city: 'HALMSTAD' },
  { postalCode: '30256', city: 'HALMSTAD' },
  { postalCode: '30257', city: 'HALMSTAD' },
  { postalCode: '30258', city: 'HALMSTAD' },
  { postalCode: '30259', city: 'HALMSTAD' },
  { postalCode: '30260', city: 'HALMSTAD' },
  { postalCode: '30261', city: 'HALMSTAD' },
  { postalCode: '30262', city: 'HALMSTAD' },
  { postalCode: '30263', city: 'HALMSTAD' },
  { postalCode: '30264', city: 'HALMSTAD' },
  { postalCode: '30265', city: 'HALMSTAD' },
  { postalCode: '30266', city: 'HALMSTAD' },
  { postalCode: '30267', city: 'HALMSTAD' },
  { postalCode: '30268', city: 'HALMSTAD' },
  { postalCode: '30269', city: 'HALMSTAD' },
  { postalCode: '30270', city: 'HALMSTAD' },
  { postalCode: '30271', city: 'HALMSTAD' },
  { postalCode: '30272', city: 'HALMSTAD' },
  { postalCode: '30273', city: 'HALMSTAD' },
  { postalCode: '30274', city: 'HALMSTAD' },
  { postalCode: '30275', city: 'HALMSTAD' },
  { postalCode: '30276', city: 'HALMSTAD' },
  { postalCode: '30277', city: 'HALMSTAD' },
  { postalCode: '30279', city: 'HOLM' },
  { postalCode: '30283', city: 'HALMSTAD' },
  { postalCode: '30290', city: 'HALMSTAD' },
  { postalCode: '30291', city: 'HALMSTAD' },
  { postalCode: '30292', city: 'HALMSTAD' },
  { postalCode: '30293', city: 'HALMSTAD' },
  { postalCode: '30294', city: 'HALMSTAD' },
  { postalCode: '30295', city: 'HALMSTAD' },
  { postalCode: '30296', city: 'HALMSTAD' },
  { postalCode: '30504', city: 'HAVERDAL' },
  { postalCode: '30505', city: 'GETINGE' },
  { postalCode: '30560', city: 'HARPLINGE' },
  { postalCode: '30561', city: 'HARPLINGE' },
  { postalCode: '30564', city: 'GULLBRANDSTORP' },
  { postalCode: '30565', city: 'VILLSHÄRAD' },
  { postalCode: '30570', city: 'HAVERDAL' },
  { postalCode: '30571', city: 'HAVERDAL' },
  { postalCode: '30572', city: 'STENINGE' },
  { postalCode: '30575', city: 'GETINGE' },
  { postalCode: '30576', city: 'GETINGE' },
  { postalCode: '30577', city: 'GETINGE' },
  { postalCode: '30578', city: 'KVIBILLE' },
  { postalCode: '30591', city: 'HALMSTAD' },
  { postalCode: '30592', city: 'HOLM' },
  { postalCode: '30593', city: 'HALMSTAD' },
  { postalCode: '30594', city: 'HALMSTAD' },
  { postalCode: '30595', city: 'HALMSTAD' },
  { postalCode: '30596', city: 'ELDSBERGA' },
  { postalCode: '30597', city: 'ELDSBERGA' },
  { postalCode: '30800', city: 'HALMSTAD' },
  { postalCode: '30801', city: 'HALMSTAD' },
  { postalCode: '31100', city: 'FALKENBERG' },
  { postalCode: '31101', city: 'FALKENBERG' },
  { postalCode: '31103', city: 'LÅNGÅS' },
  { postalCode: '31105', city: 'VINBERG' },
  { postalCode: '31106', city: 'HEBERG' },
  { postalCode: '31109', city: 'ULLARED' },
  { postalCode: '31110', city: 'FALKENBERG' },
  { postalCode: '31120', city: 'FALKENBERG' },
  { postalCode: '31121', city: 'FALKENBERG' },
  { postalCode: '31122', city: 'FALKENBERG' },
  { postalCode: '31123', city: 'FALKENBERG' },
  { postalCode: '31130', city: 'FALKENBERG' },
  { postalCode: '31131', city: 'FALKENBERG' },
  { postalCode: '31132', city: 'FALKENBERG' },
  { postalCode: '31133', city: 'FALKENBERG' },
  { postalCode: '31134', city: 'FALKENBERG' },
  { postalCode: '31135', city: 'FALKENBERG' },
  { postalCode: '31136', city: 'FALKENBERG' },
  { postalCode: '31137', city: 'FALKENBERG' },
  { postalCode: '31138', city: 'FALKENBERG' },
  { postalCode: '31139', city: 'FALKENBERG' },
  { postalCode: '31140', city: 'FALKENBERG' },
  { postalCode: '31141', city: 'FALKENBERG' },
  { postalCode: '31142', city: 'FALKENBERG' },
  { postalCode: '31143', city: 'FALKENBERG' },
  { postalCode: '31144', city: 'FALKENBERG' },
  { postalCode: '31145', city: 'FALKENBERG' },
  { postalCode: '31146', city: 'FALKENBERG' },
  { postalCode: '31147', city: 'FALKENBERG' },
  { postalCode: '31148', city: 'FALKENBERG' },
  { postalCode: '31150', city: 'FALKENBERG' },
  { postalCode: '31151', city: 'ÄTRAN' },
  { postalCode: '31152', city: 'FEGEN' },
  { postalCode: '31154', city: 'GLOMMEN' },
  { postalCode: '31160', city: 'ULLARED' },
  { postalCode: '31161', city: 'ULLARED' },
  { postalCode: '31162', city: 'ULLARED' },
  { postalCode: '31163', city: 'ÄLVSERED' },
  { postalCode: '31164', city: 'VESSIGEBRO' },
  { postalCode: '31165', city: 'VESSIGEBRO' },
  { postalCode: '31167', city: 'SLÖINGE' },
  { postalCode: '31168', city: 'SLÖINGE' },
  { postalCode: '31169', city: 'UGGLARP' },
  { postalCode: '31170', city: 'FALKENBERG' },
  { postalCode: '31171', city: 'FALKENBERG' },
  { postalCode: '31172', city: 'FALKENBERG' },
  { postalCode: '31173', city: 'FALKENBERG' },
  { postalCode: '31174', city: 'FALKENBERG' },
  { postalCode: '31175', city: 'FALKENBERG' },
  { postalCode: '31176', city: 'FALKENBERG' },
  { postalCode: '31177', city: 'FALKENBERG' },
  { postalCode: '31178', city: 'FALKENBERG' },
  { postalCode: '31179', city: 'FALKENBERG' },
  { postalCode: '31180', city: 'FALKENBERG' },
  { postalCode: '31181', city: 'FALKENBERG' },
  { postalCode: '31183', city: 'FALKENBERG' },
  { postalCode: '31184', city: 'FALKENBERG' },
  { postalCode: '31185', city: 'ULLARED' },
  { postalCode: '31190', city: 'MORUP' },
  { postalCode: '31191', city: 'FALKENBERG' },
  { postalCode: '31192', city: 'FALKENBERG' },
  { postalCode: '31193', city: 'LÅNGÅS' },
  { postalCode: '31194', city: 'FALKENBERG' },
  { postalCode: '31195', city: 'FALKENBERG' },
  { postalCode: '31196', city: 'HEBERG' },
  { postalCode: '31197', city: 'FALKENBERG' },
  { postalCode: '31198', city: 'GLOMMEN' },
  { postalCode: '31199', city: 'VINBERG' },
  { postalCode: '31200', city: 'LAHOLM' },
  { postalCode: '31220', city: 'LAHOLM' },
  { postalCode: '31221', city: 'LAHOLM' },
  { postalCode: '31222', city: 'LAHOLM' },
  { postalCode: '31230', city: 'LAHOLM' },
  { postalCode: '31231', city: 'LAHOLM' },
  { postalCode: '31232', city: 'LAHOLM' },
  { postalCode: '31233', city: 'LAHOLM' },
  { postalCode: '31234', city: 'LAHOLM' },
  { postalCode: '31235', city: 'LAHOLM' },
  { postalCode: '31236', city: 'LAHOLM' },
  { postalCode: '31240', city: 'GENEVAD' },
  { postalCode: '31245', city: 'VEINGE' },
  { postalCode: '31250', city: 'VALLBERGA' },
  { postalCode: '31251', city: 'KNÄRED' },
  { postalCode: '31252', city: 'KNÄRED' },
  { postalCode: '31253', city: 'HISHULT' },
  { postalCode: '31260', city: 'MELLBYSTRAND' },
  { postalCode: '31261', city: 'MELLBYSTRAND' },
  { postalCode: '31262', city: 'MELLBYSTRAND' },
  { postalCode: '31271', city: 'SKUMMESLÖVSSTRAND' },
  { postalCode: '31272', city: 'SKUMMESLÖVSSTRAND' },
  { postalCode: '31273', city: 'SKUMMESLÖVSSTRAND' },
  { postalCode: '31275', city: 'VÅXTORP' },
  { postalCode: '31277', city: 'SKOTTORP' },
  { postalCode: '31280', city: 'LAHOLM' },
  { postalCode: '31291', city: 'LAHOLM' },
  { postalCode: '31292', city: 'LAHOLM' },
  { postalCode: '31293', city: 'LAHOLM' },
  { postalCode: '31294', city: 'LAHOLM' },
  { postalCode: '31295', city: 'LAHOLM' },
  { postalCode: '31296', city: 'LAHOLM' },
  { postalCode: '31297', city: 'LAHOLM' },
  { postalCode: '31298', city: 'VÅXTORP' },
  { postalCode: '31320', city: 'OSKARSTRÖM' },
  { postalCode: '31321', city: 'OSKARSTRÖM' },
  { postalCode: '31322', city: 'OSKARSTRÖM' },
  { postalCode: '31330', city: 'OSKARSTRÖM' },
  { postalCode: '31331', city: 'OSKARSTRÖM' },
  { postalCode: '31332', city: 'OSKARSTRÖM' },
  { postalCode: '31333', city: 'OSKARSTRÖM' },
  { postalCode: '31350', city: 'ÅLED' },
  { postalCode: '31391', city: 'OSKARSTRÖM' },
  { postalCode: '31392', city: 'OSKARSTRÖM' },
  { postalCode: '31393', city: 'SLÄTTÅKRA' },
  { postalCode: '31394', city: 'SENNAN' },
  { postalCode: '31395', city: 'ÅLED' },
  { postalCode: '31396', city: 'SIMLÅNGSDALEN' },
  { postalCode: '31397', city: 'SIMLÅNGSDALEN' },
  { postalCode: '31401', city: 'HYLTEBRUK' },
  { postalCode: '31403', city: 'TORUP' },
  { postalCode: '31404', city: 'LANDERYD' },
  { postalCode: '31405', city: 'KINNARED' },
  { postalCode: '31420', city: 'HYLTEBRUK' },
  { postalCode: '31421', city: 'HYLTEBRUK' },
  { postalCode: '31422', city: 'HYLTEBRUK' },
  { postalCode: '31431', city: 'HYLTEBRUK' },
  { postalCode: '31432', city: 'HYLTEBRUK' },
  { postalCode: '31434', city: 'HYLTEBRUK' },
  { postalCode: '31441', city: 'TORUP' },
  { postalCode: '31442', city: 'RYDÖBRUK' },
  { postalCode: '31451', city: 'UNNARYD' },
  { postalCode: '31452', city: 'UNNARYD' },
  { postalCode: '31480', city: 'HYLTEBRUK' },
  { postalCode: '31481', city: 'HYLTEBRUK' },
  { postalCode: '31482', city: 'HYLTEBRUK' },
  { postalCode: '31491', city: 'HYLTEBRUK' },
  { postalCode: '31492', city: 'LÅNGARYD' },
  { postalCode: '31494', city: 'LANDERYD' },
  { postalCode: '31495', city: 'KINNARED' },
  { postalCode: '31496', city: 'TORUP' },
  { postalCode: '33101', city: 'VÄRNAMO' },
  { postalCode: '33102', city: 'VÄRNAMO' },
  { postalCode: '33103', city: 'FORSHEDA' },
  { postalCode: '33104', city: 'RYDAHOLM' },
  { postalCode: '33120', city: 'VÄRNAMO' },
  { postalCode: '33121', city: 'VÄRNAMO' },
  { postalCode: '33122', city: 'VÄRNAMO' },
  { postalCode: '33123', city: 'VÄRNAMO' },
  { postalCode: '33124', city: 'VÄRNAMO' },
  { postalCode: '33125', city: 'VÄRNAMO' },
  { postalCode: '33126', city: 'VÄRNAMO' },
  { postalCode: '33127', city: 'VÄRNAMO' },
  { postalCode: '33129', city: 'VÄRNAMO' },
  { postalCode: '33130', city: 'VÄRNAMO' },
  { postalCode: '33131', city: 'VÄRNAMO' },
  { postalCode: '33132', city: 'VÄRNAMO' },
  { postalCode: '33133', city: 'VÄRNAMO' },
  { postalCode: '33134', city: 'VÄRNAMO' },
  { postalCode: '33135', city: 'VÄRNAMO' },
  { postalCode: '33136', city: 'VÄRNAMO' },
  { postalCode: '33137', city: 'VÄRNAMO' },
  { postalCode: '33140', city: 'VÄRNAMO' },
  { postalCode: '33141', city: 'VÄRNAMO' },
  { postalCode: '33142', city: 'VÄRNAMO' },
  { postalCode: '33143', city: 'VÄRNAMO' },
  { postalCode: '33144', city: 'VÄRNAMO' },
  { postalCode: '33150', city: 'VÄRNAMO' },
  { postalCode: '33151', city: 'VÄRNAMO' },
  { postalCode: '33152', city: 'VÄRNAMO' },
  { postalCode: '33153', city: 'VÄRNAMO' },
  { postalCode: '33154', city: 'VÄRNAMO' },
  { postalCode: '33155', city: 'VÄRNAMO' },
  { postalCode: '33156', city: 'VÄRNAMO' },
  { postalCode: '33171', city: 'FORSHEDA' },
  { postalCode: '33172', city: 'FORSHEDA' },
  { postalCode: '33173', city: 'BOR' },
  { postalCode: '33174', city: 'BOR' },
  { postalCode: '33176', city: 'RYDAHOLM' },
  { postalCode: '33177', city: 'RYDAHOLM' },
  { postalCode: '33179', city: 'HORDA' },
  { postalCode: '33182', city: 'VÄRNAMO' },
  { postalCode: '33183', city: 'VÄRNAMO' },
  { postalCode: '33184', city: 'VÄRNAMO' },
  { postalCode: '33185', city: 'VÄRNAMO' },
  { postalCode: '33191', city: 'VÄRNAMO' },
  { postalCode: '33192', city: 'VÄRNAMO' },
  { postalCode: '33193', city: 'VÄRNAMO' },
  { postalCode: '33194', city: 'VÄRNAMO' },
  { postalCode: '33195', city: 'VÄRNAMO' },
  { postalCode: '33196', city: 'VÄRNAMO' },
  { postalCode: '33197', city: 'VÄRNAMO' },
  { postalCode: '33198', city: 'VÄRNAMO' },
  { postalCode: '33201', city: 'GISLAVED' },
  { postalCode: '33220', city: 'GISLAVED' },
  { postalCode: '33221', city: 'GISLAVED' },
  { postalCode: '33222', city: 'GISLAVED' },
  { postalCode: '33223', city: 'GISLAVED' },
  { postalCode: '33224', city: 'GISLAVED' },
  { postalCode: '33225', city: 'GISLAVED' },
  { postalCode: '33228', city: 'GISLAVED' },
  { postalCode: '33230', city: 'GISLAVED' },
  { postalCode: '33231', city: 'GISLAVED' },
  { postalCode: '33232', city: 'GISLAVED' },
  { postalCode: '33233', city: 'GISLAVED' },
  { postalCode: '33234', city: 'GISLAVED' },
  { postalCode: '33235', city: 'GISLAVED' },
  { postalCode: '33236', city: 'GISLAVED' },
  { postalCode: '33237', city: 'GISLAVED' },
  { postalCode: '33238', city: 'GISLAVED' },
  { postalCode: '33280', city: 'GISLAVED' },
  { postalCode: '33291', city: 'GISLAVED' },
  { postalCode: '33292', city: 'GISLAVED' },
  { postalCode: '33301', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33302', city: 'BREDARYD' },
  { postalCode: '33303', city: 'REFTELE' },
  { postalCode: '33304', city: 'BURSERYD' },
  { postalCode: '33320', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33321', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33322', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33323', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33324', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33328', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33330', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33331', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33332', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33333', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33371', city: 'BREDARYD' },
  { postalCode: '33372', city: 'BREDARYD' },
  { postalCode: '33373', city: 'BREDARYD' },
  { postalCode: '33374', city: 'BREDARYD' },
  { postalCode: '33375', city: 'REFTELE' },
  { postalCode: '33376', city: 'REFTELE' },
  { postalCode: '33377', city: 'BURSERYD' },
  { postalCode: '33378', city: 'BURSERYD' },
  { postalCode: '33391', city: 'SMÅLANDSSTENAR' },
  { postalCode: '33392', city: 'BROARYD' },
  { postalCode: '33393', city: 'SKEPPSHULT' },
  { postalCode: '33420', city: 'ANDERSTORP' },
  { postalCode: '33421', city: 'ANDERSTORP' },
  { postalCode: '33422', city: 'ANDERSTORP' },
  { postalCode: '33423', city: 'ANDERSTORP' },
  { postalCode: '33424', city: 'ANDERSTORP' },
  { postalCode: '33425', city: 'ANDERSTORP' },
  { postalCode: '33431', city: 'ANDERSTORP' },
  { postalCode: '33432', city: 'ANDERSTORP' },
  { postalCode: '33433', city: 'ANDERSTORP' },
  { postalCode: '33435', city: 'ANDERSTORP' },
  { postalCode: '33491', city: 'ANDERSTORP' },
  { postalCode: '33501', city: 'GNOSJÖ' },
  { postalCode: '33503', city: 'HESTRA' },
  { postalCode: '33504', city: 'HILLERSTORP' },
  { postalCode: '33520', city: 'GNOSJÖ' },
  { postalCode: '33521', city: 'GNOSJÖ' },
  { postalCode: '33522', city: 'GNOSJÖ' },
  { postalCode: '33523', city: 'GNOSJÖ' },
  { postalCode: '33524', city: 'GNOSJÖ' },
  { postalCode: '33525', city: 'GNOSJÖ' },
  { postalCode: '33528', city: 'GNOSJÖ' },
  { postalCode: '33530', city: 'GNOSJÖ' },
  { postalCode: '33531', city: 'GNOSJÖ' },
  { postalCode: '33532', city: 'GNOSJÖ' },
  { postalCode: '33533', city: 'GNOSJÖ' },
  { postalCode: '33571', city: 'HESTRA' },
  { postalCode: '33572', city: 'HESTRA' },
  { postalCode: '33573', city: 'HILLERSTORP' },
  { postalCode: '33574', city: 'HILLERSTORP' },
  { postalCode: '33580', city: 'GNOSJÖ' },
  { postalCode: '33591', city: 'GNOSJÖ' },
  { postalCode: '33592', city: 'NISSAFORS' },
  { postalCode: '33593', city: 'ÅSENHÖGA' },
  { postalCode: '33594', city: 'MARIEHOLMSBRUK' },
  { postalCode: '33595', city: 'STRANDUDDEN' },
  { postalCode: '33596', city: 'KULLTORP' },
  { postalCode: '34101', city: 'LJUNGBY' },
  { postalCode: '34102', city: 'LAGAN' },
  { postalCode: '34104', city: 'LJUNGBY' },
  { postalCode: '34105', city: 'LJUNGBY' },
  { postalCode: '34106', city: 'LJUNGBY' },
  { postalCode: '34107', city: 'LJUNGBY' },
  { postalCode: '34108', city: 'LJUNGBY' },
  { postalCode: '34109', city: 'LJUNGBY' },
  { postalCode: '34110', city: 'LJUNGBY' },
  { postalCode: '34111', city: 'LJUNGBY' },
  { postalCode: '34112', city: 'LJUNGBY' },
  { postalCode: '34114', city: 'LJUNGBY' },
  { postalCode: '34116', city: 'LJUNGBY' },
  { postalCode: '34117', city: 'LAGAN' },
  { postalCode: '34118', city: 'LJUNGBY' },
  { postalCode: '34120', city: 'LJUNGBY' },
  { postalCode: '34121', city: 'LJUNGBY' },
  { postalCode: '34122', city: 'LJUNGBY' },
  { postalCode: '34123', city: 'LJUNGBY' },
  { postalCode: '34124', city: 'LJUNGBY' },
  { postalCode: '34125', city: 'LJUNGBY' },
  { postalCode: '34126', city: 'LJUNGBY' },
  { postalCode: '34131', city: 'LJUNGBY' },
  { postalCode: '34132', city: 'LJUNGBY' },
  { postalCode: '34133', city: 'LJUNGBY' },
  { postalCode: '34134', city: 'LJUNGBY' },
  { postalCode: '34135', city: 'LJUNGBY' },
  { postalCode: '34136', city: 'LJUNGBY' },
  { postalCode: '34137', city: 'LJUNGBY' },
  { postalCode: '34138', city: 'LJUNGBY' },
  { postalCode: '34139', city: 'LJUNGBY' },
  { postalCode: '34140', city: 'LJUNGBY' },
  { postalCode: '34141', city: 'LJUNGBY' },
  { postalCode: '34142', city: 'LJUNGBY' },
  { postalCode: '34143', city: 'LJUNGBY' },
  { postalCode: '34144', city: 'LJUNGBY' },
  { postalCode: '34150', city: 'LAGAN' },
  { postalCode: '34151', city: 'LAGAN' },
  { postalCode: '34152', city: 'LAGAN' },
  { postalCode: '34155', city: 'VITTARYD' },
  { postalCode: '34160', city: 'LJUNGBY' },
  { postalCode: '34170', city: 'LJUNGBY' },
  { postalCode: '34171', city: 'LIDHULT' },
  { postalCode: '34172', city: 'LIDHULT' },
  { postalCode: '34173', city: 'LIDHULT' },
  { postalCode: '34174', city: 'ANNERSTAD' },
  { postalCode: '34175', city: 'HAMNEDA' },
  { postalCode: '34176', city: 'RYSSBY' },
  { postalCode: '34177', city: 'AGUNNARYD' },
  { postalCode: '34180', city: 'LJUNGBY' },
  { postalCode: '34181', city: 'LJUNGBY' },
  { postalCode: '34182', city: 'LJUNGBY' },
  { postalCode: '34183', city: 'LJUNGBY' },
  { postalCode: '34184', city: 'LJUNGBY' },
  { postalCode: '34185', city: 'LJUNGBY' },
  { postalCode: '34191', city: 'LJUNGBY' },
  { postalCode: '34193', city: 'BOLMSÖ' },
  { postalCode: '34194', city: 'LJUNGBY' },
  { postalCode: '34196', city: 'LJUNGBY' },
  { postalCode: '34200', city: 'ALVESTA' },
  { postalCode: '34201', city: 'ALVESTA' },
  { postalCode: '34205', city: 'VISLANDA' },
  { postalCode: '34210', city: 'ALVESTA' },
  { postalCode: '34211', city: 'ALVESTA' },
  { postalCode: '34220', city: 'ALVESTA' },
  { postalCode: '34221', city: 'ALVESTA' },
  { postalCode: '34222', city: 'ALVESTA' },
  { postalCode: '34223', city: 'ALVESTA' },
  { postalCode: '34230', city: 'ALVESTA' },
  { postalCode: '34231', city: 'ALVESTA' },
  { postalCode: '34232', city: 'ALVESTA' },
  { postalCode: '34233', city: 'ALVESTA' },
  { postalCode: '34234', city: 'ALVESTA' },
  { postalCode: '34235', city: 'ALVESTA' },
  { postalCode: '34236', city: 'ALVESTA' },
  { postalCode: '34250', city: 'VISLANDA' },
  { postalCode: '34251', city: 'VISLANDA' },
  { postalCode: '34252', city: 'VISLANDA' },
  { postalCode: '34253', city: 'LÖNASHULT' },
  { postalCode: '34254', city: 'GRIMSLÖV' },
  { postalCode: '34255', city: 'GRIMSLÖV' },
  { postalCode: '34260', city: 'MOHEDA' },
  { postalCode: '34261', city: 'MOHEDA' },
  { postalCode: '34262', city: 'MOHEDA' },
  { postalCode: '34263', city: 'MOHEDA' },
  { postalCode: '34264', city: 'ÖR' },
  { postalCode: '34265', city: 'TORPSBRUK' },
  { postalCode: '34280', city: 'ALVESTA' },
  { postalCode: '34290', city: 'ALVESTA' },
  { postalCode: '34291', city: 'ALVESTA' },
  { postalCode: '34292', city: 'ALVESTA' },
  { postalCode: '34293', city: 'HJORTSBERGA' },
  { postalCode: '34294', city: 'ALVESTA' },
  { postalCode: '34301', city: 'ÄLMHULT' },
  { postalCode: '34320', city: 'ÄLMHULT' },
  { postalCode: '34321', city: 'ÄLMHULT' },
  { postalCode: '34322', city: 'ÄLMHULT' },
  { postalCode: '34323', city: 'ÄLMHULT' },
  { postalCode: '34324', city: 'ÄLMHULT' },
  { postalCode: '34330', city: 'ÄLMHULT' },
  { postalCode: '34331', city: 'ÄLMHULT' },
  { postalCode: '34332', city: 'ÄLMHULT' },
  { postalCode: '34333', city: 'ÄLMHULT' },
  { postalCode: '34334', city: 'ÄLMHULT' },
  { postalCode: '34335', city: 'ÄLMHULT' },
  { postalCode: '34336', city: 'ÄLMHULT' },
  { postalCode: '34337', city: 'ÄLMHULT' },
  { postalCode: '34338', city: 'ÄLMHULT' },
  { postalCode: '34371', city: 'DIÖ' },
  { postalCode: '34372', city: 'ENERYDA' },
  { postalCode: '34373', city: 'VIRESTAD' },
  { postalCode: '34374', city: 'LIATORP' },
  { postalCode: '34375', city: 'DIÖ' },
  { postalCode: '34376', city: 'LIATORP' },
  { postalCode: '34381', city: 'ÄLMHULT' },
  { postalCode: '34382', city: 'ÄLMHULT' },
  { postalCode: '34390', city: 'ÄLMHULT' },
  { postalCode: '34391', city: 'ÄLMHULT' },
  { postalCode: '34392', city: 'HÄRADSBÄCK' },
  { postalCode: '34393', city: 'ÄLMHULT' },
  { postalCode: '34394', city: 'ÄLMHULT' },
  { postalCode: '34395', city: 'ÄLMHULT' },
  { postalCode: '34396', city: 'HALLARYD' },
  { postalCode: '34397', city: 'ÄLMHULT' },
  { postalCode: '35002', city: 'VÄXJÖ' },
  { postalCode: '35003', city: 'VÄXJÖ' },
  { postalCode: '35004', city: 'VÄXJÖ' },
  { postalCode: '35005', city: 'VÄXJÖ' },
  { postalCode: '35007', city: 'VÄXJÖ' },
  { postalCode: '35008', city: 'VÄXJÖ' },
  { postalCode: '35033', city: 'VÄXJÖ' },
  { postalCode: '35043', city: 'VÄXJÖ' },
  { postalCode: '35053', city: 'VÄXJÖ' },
  { postalCode: '35101', city: 'VÄXJÖ' },
  { postalCode: '35102', city: 'VÄXJÖ' },
  { postalCode: '35103', city: 'VÄXJÖ' },
  { postalCode: '35104', city: 'VÄXJÖ' },
  { postalCode: '35105', city: 'VÄXJÖ' },
  { postalCode: '35106', city: 'VÄXJÖ' },
  { postalCode: '35108', city: 'VÄXJÖ' },
  { postalCode: '35112', city: 'VÄXJÖ' },
  { postalCode: '35115', city: 'VÄXJÖ' },
  { postalCode: '35121', city: 'VÄXJÖ' },
  { postalCode: '35123', city: 'VÄXJÖ' },
  { postalCode: '35124', city: 'VÄXJÖ' },
  { postalCode: '35125', city: 'VÄXJÖ' },
  { postalCode: '35126', city: 'VÄXJÖ' },
  { postalCode: '35127', city: 'VÄXJÖ' },
  { postalCode: '35128', city: 'VÄXJÖ' },
  { postalCode: '35129', city: 'VÄXJÖ' },
  { postalCode: '35170', city: 'VÄXJÖ' },
  { postalCode: '35180', city: 'VÄXJÖ' },
  { postalCode: '35181', city: 'VÄXJÖ' },
  { postalCode: '35184', city: 'VÄXJÖ' },
  { postalCode: '35185', city: 'VÄXJÖ' },
  { postalCode: '35186', city: 'VÄXJÖ' },
  { postalCode: '35187', city: 'VÄXJÖ' },
  { postalCode: '35188', city: 'VÄXJÖ' },
  { postalCode: '35189', city: 'VÄXJÖ' },
  { postalCode: '35194', city: 'VÄXJÖ' },
  { postalCode: '35195', city: 'VÄXJÖ' },
  { postalCode: '35196', city: 'VÄXJÖ' },
  { postalCode: '35197', city: 'VÄXJÖ' },
  { postalCode: '35220', city: 'VÄXJÖ' },
  { postalCode: '35221', city: 'VÄXJÖ' },
  { postalCode: '35222', city: 'VÄXJÖ' },
  { postalCode: '35223', city: 'VÄXJÖ' },
  { postalCode: '35229', city: 'VÄXJÖ' },
  { postalCode: '35230', city: 'VÄXJÖ' },
  { postalCode: '35231', city: 'VÄXJÖ' },
  { postalCode: '35232', city: 'VÄXJÖ' },
  { postalCode: '35233', city: 'VÄXJÖ' },
  { postalCode: '35234', city: 'VÄXJÖ' },
  { postalCode: '35235', city: 'VÄXJÖ' },
  { postalCode: '35236', city: 'VÄXJÖ' },
  { postalCode: '35237', city: 'VÄXJÖ' },
  { postalCode: '35238', city: 'VÄXJÖ' },
  { postalCode: '35239', city: 'VÄXJÖ' },
  { postalCode: '35240', city: 'VÄXJÖ' },
  { postalCode: '35241', city: 'VÄXJÖ' },
  { postalCode: '35242', city: 'VÄXJÖ' },
  { postalCode: '35243', city: 'VÄXJÖ' },
  { postalCode: '35244', city: 'VÄXJÖ' },
  { postalCode: '35245', city: 'VÄXJÖ' },
  { postalCode: '35246', city: 'VÄXJÖ' },
  { postalCode: '35247', city: 'VÄXJÖ' },
  { postalCode: '35248', city: 'VÄXJÖ' },
  { postalCode: '35249', city: 'VÄXJÖ' },
  { postalCode: '35250', city: 'VÄXJÖ' },
  { postalCode: '35251', city: 'VÄXJÖ' },
  { postalCode: '35252', city: 'VÄXJÖ' },
  { postalCode: '35253', city: 'VÄXJÖ' },
  { postalCode: '35254', city: 'VÄXJÖ' },
  { postalCode: '35255', city: 'VÄXJÖ' },
  { postalCode: '35256', city: 'VÄXJÖ' },
  { postalCode: '35257', city: 'VÄXJÖ' },
  { postalCode: '35258', city: 'VÄXJÖ' },
  { postalCode: '35259', city: 'VÄXJÖ' },
  { postalCode: '35260', city: 'VÄXJÖ' },
  { postalCode: '35261', city: 'VÄXJÖ' },
  { postalCode: '35262', city: 'VÄXJÖ' },
  { postalCode: '35263', city: 'VÄXJÖ' },
  { postalCode: '35264', city: 'VÄXJÖ' },
  { postalCode: '35265', city: 'VÄXJÖ' },
  { postalCode: '35270', city: 'VÄXJÖ' },
  { postalCode: '35271', city: 'VÄXJÖ' },
  { postalCode: '35272', city: 'VÄXJÖ' },
  { postalCode: '35273', city: 'VÄXJÖ' },
  { postalCode: '35274', city: 'VÄXJÖ' },
  { postalCode: '35571', city: 'INGELSTAD' },
  { postalCode: '35572', city: 'INGELSTAD' },
  { postalCode: '35573', city: 'GEMLA' },
  { postalCode: '35574', city: 'VÄRENDS NÖBBELE' },
  { postalCode: '35575', city: 'ÅRYD' },
  { postalCode: '35591', city: 'VÄXJÖ' },
  { postalCode: '35592', city: 'VÄXJÖ' },
  { postalCode: '35593', city: 'VÄXJÖ' },
  { postalCode: '35594', city: 'VEDERSLÖV' },
  { postalCode: '35595', city: 'TÄVELSÅS' },
  { postalCode: '35596', city: 'KALVSVIK' },
  { postalCode: '35597', city: 'VÄXJÖ' },
  { postalCode: '35598', city: 'INGELSTAD' },
  { postalCode: '35599', city: 'GEMLA' },
  { postalCode: '35801', city: 'VÄXJÖ' },
  { postalCode: '35803', city: 'VÄXJÖ' },
  { postalCode: '35810', city: 'VÄXJÖ' },
  { postalCode: '35811', city: 'VÄXJÖ' },
  { postalCode: '35812', city: 'VÄXJÖ' },
  { postalCode: '35930', city: 'VÄXJÖ' },
  { postalCode: '35940', city: 'VÄXJÖ' },
  { postalCode: '35970', city: 'VÄXJÖ' },
  { postalCode: '35999', city: 'VÄXJÖ' },
  { postalCode: '36101', city: 'EMMABODA' },
  { postalCode: '36120', city: 'EMMABODA' },
  { postalCode: '36121', city: 'EMMABODA' },
  { postalCode: '36122', city: 'EMMABODA' },
  { postalCode: '36130', city: 'EMMABODA' },
  { postalCode: '36131', city: 'EMMABODA' },
  { postalCode: '36132', city: 'EMMABODA' },
  { postalCode: '36133', city: 'EMMABODA' },
  { postalCode: '36142', city: 'LINDÅS' },
  { postalCode: '36153', city: 'BROAKULLA' },
  { postalCode: '36154', city: 'VISSEFJÄRDA' },
  { postalCode: '36180', city: 'EMMABODA' },
  { postalCode: '36191', city: 'EMMABODA' },
  { postalCode: '36192', city: 'EMMABODA' },
  { postalCode: '36193', city: 'BROAKULLA' },
  { postalCode: '36194', city: 'ERIKSMÅLA' },
  { postalCode: '36195', city: 'LÅNGASJÖ' },
  { postalCode: '36196', city: 'VISSEFJÄRDA' },
  { postalCode: '36197', city: 'BODA GLASBRUK' },
  { postalCode: '36200', city: 'TINGSRYD' },
  { postalCode: '36201', city: 'TINGSRYD' },
  { postalCode: '36220', city: 'TINGSRYD' },
  { postalCode: '36221', city: 'TINGSRYD' },
  { postalCode: '36222', city: 'TINGSRYD' },
  { postalCode: '36230', city: 'TINGSRYD' },
  { postalCode: '36231', city: 'TINGSRYD' },
  { postalCode: '36232', city: 'TINGSRYD' },
  { postalCode: '36240', city: 'KONGA' },
  { postalCode: '36250', city: 'VÄCKELSÅNG' },
  { postalCode: '36251', city: 'VÄCKELSÅNG' },
  { postalCode: '36252', city: 'JÄT' },
  { postalCode: '36253', city: 'URÅSA' },
  { postalCode: '36254', city: 'URSHULT' },
  { postalCode: '36256', city: 'RYD' },
  { postalCode: '36257', city: 'RYD' },
  { postalCode: '36258', city: 'LINNERYD' },
  { postalCode: '36291', city: 'TINGSRYD' },
  { postalCode: '36292', city: 'TINGSRYD' },
  { postalCode: '36293', city: 'TINGSRYD' },
  { postalCode: '36294', city: 'TINGSRYD' },
  { postalCode: '36295', city: 'URSHULT' },
  { postalCode: '36296', city: 'RYD' },
  { postalCode: '36297', city: 'LINNERYD' },
  { postalCode: '36298', city: 'ÄLMEBODA' },
  { postalCode: '36303', city: 'LAMMHULT' },
  { postalCode: '36321', city: 'ROTTNE' },
  { postalCode: '36330', city: 'ROTTNE' },
  { postalCode: '36331', city: 'ROTTNE' },
  { postalCode: '36333', city: 'ROTTNE' },
  { postalCode: '36334', city: 'TJUREDA' },
  { postalCode: '36335', city: 'TOLG' },
  { postalCode: '36341', city: 'BRAÅS' },
  { postalCode: '36342', city: 'BRAÅS' },
  { postalCode: '36344', city: 'LAMMHULT' },
  { postalCode: '36345', city: 'LAMMHULT' },
  { postalCode: '36391', city: 'BRAÅS' },
  { postalCode: '36392', city: 'LAMMHULT' },
  { postalCode: '36393', city: 'LAMMHULT' },
  { postalCode: '36394', city: 'LAMMHULT' },
  { postalCode: '36403', city: 'LENHOVDA' },
  { postalCode: '36404', city: 'NORRHULT' },
  { postalCode: '36421', city: 'ÅSEDA' },
  { postalCode: '36430', city: 'ÅSEDA' },
  { postalCode: '36431', city: 'ÅSEDA' },
  { postalCode: '36433', city: 'ÅSEDA' },
  { postalCode: '36441', city: 'LENHOVDA' },
  { postalCode: '36442', city: 'LENHOVDA' },
  { postalCode: '36443', city: 'ALSTERMO' },
  { postalCode: '36444', city: 'NORRHULT' },
  { postalCode: '36445', city: 'KLAVRESTRÖM' },
  { postalCode: '36491', city: 'LENHOVDA' },
  { postalCode: '36492', city: 'LENHOVDA' },
  { postalCode: '36493', city: 'ALSTERMO' },
  { postalCode: '36495', city: 'ÄLGHULT' },
  { postalCode: '36496', city: 'SÄVSJÖSTRÖM' },
  { postalCode: '36497', city: 'FRÖSEKE' },
  { postalCode: '36531', city: 'LESSEBO' },
  { postalCode: '36532', city: 'LESSEBO' },
  { postalCode: '36533', city: 'LESSEBO' },
  { postalCode: '36541', city: 'HOVMANTORP' },
  { postalCode: '36542', city: 'HOVMANTORP' },
  { postalCode: '36543', city: 'KOSTA' },
  { postalCode: '36544', city: 'KOSTA' },
  { postalCode: '36591', city: 'LESSEBO' },
  { postalCode: '36592', city: 'HOVMANTORP' },
  { postalCode: '36593', city: 'FURUBY' },
  { postalCode: '36594', city: 'SKRUV' },
  { postalCode: '37100', city: 'KARLSKRONA' },
  { postalCode: '37101', city: 'KARLSKRONA' },
  { postalCode: '37102', city: 'KARLSKRONA' },
  { postalCode: '37104', city: 'KARLSKRONA' },
  { postalCode: '37106', city: 'KARLSKRONA' },
  { postalCode: '37108', city: 'KARLSKRONA' },
  { postalCode: '37110', city: 'KARLSKRONA' },
  { postalCode: '37120', city: 'KARLSKRONA' },
  { postalCode: '37121', city: 'KARLSKRONA' },
  { postalCode: '37122', city: 'KARLSKRONA' },
  { postalCode: '37123', city: 'KARLSKRONA' },
  { postalCode: '37124', city: 'KARLSKRONA' },
  { postalCode: '37125', city: 'KARLSKRONA' },
  { postalCode: '37130', city: 'KARLSKRONA' },
  { postalCode: '37131', city: 'KARLSKRONA' },
  { postalCode: '37132', city: 'KARLSKRONA' },
  { postalCode: '37133', city: 'KARLSKRONA' },
  { postalCode: '37134', city: 'KARLSKRONA' },
  { postalCode: '37135', city: 'KARLSKRONA' },
  { postalCode: '37136', city: 'KARLSKRONA' },
  { postalCode: '37137', city: 'KARLSKRONA' },
  { postalCode: '37138', city: 'KARLSKRONA' },
  { postalCode: '37139', city: 'KARLSKRONA' },
  { postalCode: '37140', city: 'KARLSKRONA' },
  { postalCode: '37141', city: 'KARLSKRONA' },
  { postalCode: '37142', city: 'KARLSKRONA' },
  { postalCode: '37143', city: 'KARLSKRONA' },
  { postalCode: '37144', city: 'KARLSKRONA' },
  { postalCode: '37145', city: 'KARLSKRONA' },
  { postalCode: '37146', city: 'KARLSKRONA' },
  { postalCode: '37147', city: 'KARLSKRONA' },
  { postalCode: '37148', city: 'KARLSKRONA' },
  { postalCode: '37149', city: 'KARLSKRONA' },
  { postalCode: '37150', city: 'KARLSKRONA' },
  { postalCode: '37151', city: 'KARLSKRONA' },
  { postalCode: '37152', city: 'KARLSKRONA' },
  { postalCode: '37153', city: 'KARLSKRONA' },
  { postalCode: '37154', city: 'KARLSKRONA' },
  { postalCode: '37155', city: 'KARLSKRONA' },
  { postalCode: '37156', city: 'KARLSKRONA' },
  { postalCode: '37157', city: 'KARLSKRONA' },
  { postalCode: '37160', city: 'LYCKEBY' },
  { postalCode: '37161', city: 'LYCKEBY' },
  { postalCode: '37162', city: 'LYCKEBY' },
  { postalCode: '37163', city: 'LYCKEBY' },
  { postalCode: '37164', city: 'LYCKEBY' },
  { postalCode: '37165', city: 'LYCKEBY' },
  { postalCode: '37175', city: 'KARLSKRONA' },
  { postalCode: '37179', city: 'KARLSKRONA' },
  { postalCode: '37180', city: 'KARLSKRONA' },
  { postalCode: '37181', city: 'KARLSKRONA' },
  { postalCode: '37182', city: 'KARLSKRONA' },
  { postalCode: '37183', city: 'KARLSKRONA' },
  { postalCode: '37184', city: 'KARLSKRONA' },
  { postalCode: '37185', city: 'KARLSKRONA' },
  { postalCode: '37186', city: 'KARLSKRONA' },
  { postalCode: '37187', city: 'KARLSKRONA' },
  { postalCode: '37188', city: 'KARLSKRONA' },
  { postalCode: '37189', city: 'KARLSKRONA' },
  { postalCode: '37191', city: 'KARLSKRONA' },
  { postalCode: '37192', city: 'KARLSKRONA' },
  { postalCode: '37193', city: 'KARLSKRONA' },
  { postalCode: '37194', city: 'LYCKEBY' },
  { postalCode: '37200', city: 'RONNEBY' },
  { postalCode: '37201', city: 'RONNEBY' },
  { postalCode: '37202', city: 'KALLINGE' },
  { postalCode: '37206', city: 'BRÄKNE-HOBY' },
  { postalCode: '37220', city: 'RONNEBY' },
  { postalCode: '37221', city: 'RONNEBY' },
  { postalCode: '37222', city: 'RONNEBY' },
  { postalCode: '37225', city: 'RONNEBY' },
  { postalCode: '37230', city: 'RONNEBY' },
  { postalCode: '37231', city: 'RONNEBY' },
  { postalCode: '37232', city: 'RONNEBY' },
  { postalCode: '37233', city: 'RONNEBY' },
  { postalCode: '37234', city: 'RONNEBY' },
  { postalCode: '37235', city: 'RONNEBY' },
  { postalCode: '37236', city: 'RONNEBY' },
  { postalCode: '37237', city: 'RONNEBY' },
  { postalCode: '37238', city: 'RONNEBY' },
  { postalCode: '37239', city: 'RONNEBY' },
  { postalCode: '37240', city: 'RONNEBY' },
  { postalCode: '37250', city: 'KALLINGE' },
  { postalCode: '37251', city: 'KALLINGE' },
  { postalCode: '37252', city: 'KALLINGE' },
  { postalCode: '37253', city: 'KALLINGE' },
  { postalCode: '37260', city: 'BRÄKNE-HOBY' },
  { postalCode: '37261', city: 'BRÄKNE-HOBY' },
  { postalCode: '37262', city: 'BRÄKNE-HOBY' },
  { postalCode: '37263', city: 'BRÄKNE-HOBY' },
  { postalCode: '37273', city: 'RONNEBY' },
  { postalCode: '37274', city: 'LISTERBY' },
  { postalCode: '37275', city: 'JOHANNISHUS' },
  { postalCode: '37276', city: 'HALLABRO' },
  { postalCode: '37277', city: 'BACKARYD' },
  { postalCode: '37278', city: 'ERINGSBODA' },
  { postalCode: '37280', city: 'RONNEBY' },
  { postalCode: '37281', city: 'RONNEBY' },
  { postalCode: '37291', city: 'RONNEBY' },
  { postalCode: '37292', city: 'KALLINGE' },
  { postalCode: '37293', city: 'HALLABRO' },
  { postalCode: '37294', city: 'LISTERBY' },
  { postalCode: '37295', city: 'JOHANNISHUS' },
  { postalCode: '37296', city: 'BACKARYD' },
  { postalCode: '37297', city: 'RONNEBY' },
  { postalCode: '37298', city: 'ERINGSBODA' },
  { postalCode: '37303', city: 'RÖDEBY' },
  { postalCode: '37304', city: 'JÄMJÖ' },
  { postalCode: '37305', city: 'FÅGELMARA' },
  { postalCode: '37306', city: 'NÄTTRABY' },
  { postalCode: '37307', city: 'STURKÖ' },
  { postalCode: '37320', city: 'KARLSKRONA' },
  { postalCode: '37330', city: 'NÄTTRABY' },
  { postalCode: '37331', city: 'NÄTTRABY' },
  { postalCode: '37332', city: 'NÄTTRABY' },
  { postalCode: '37333', city: 'NÄTTRABY' },
  { postalCode: '37334', city: 'NÄTTRABY' },
  { postalCode: '37335', city: 'FRIDLEVSTAD' },
  { postalCode: '37336', city: 'FRIDLEVSTAD' },
  { postalCode: '37337', city: 'HOLMSJÖ' },
  { postalCode: '37338', city: 'HOLMSJÖ' },
  { postalCode: '37339', city: 'TVING' },
  { postalCode: '37340', city: 'TVING' },
  { postalCode: '37341', city: 'RÖDEBY' },
  { postalCode: '37342', city: 'RÖDEBY' },
  { postalCode: '37343', city: 'RÖDEBY' },
  { postalCode: '37344', city: 'RÖDEBY' },
  { postalCode: '37345', city: 'RÖDEBY' },
  { postalCode: '37351', city: 'RAMDALA' },
  { postalCode: '37352', city: 'RAMDALA' },
  { postalCode: '37353', city: 'RAMDALA' },
  { postalCode: '37354', city: 'SENOREN' },
  { postalCode: '37361', city: 'STURKÖ' },
  { postalCode: '37362', city: 'STURKÖ' },
  { postalCode: '37363', city: 'TJURKÖ' },
  { postalCode: '37364', city: 'DROTTNINGSKÄR' },
  { postalCode: '37365', city: 'HASSLÖ' },
  { postalCode: '37366', city: 'HASSLÖ' },
  { postalCode: '37371', city: 'TORHAMN' },
  { postalCode: '37372', city: 'JÄMJÖ' },
  { postalCode: '37373', city: 'JÄMJÖ' },
  { postalCode: '37374', city: 'JÄMJÖ' },
  { postalCode: '37375', city: 'JÄMJÖ' },
  { postalCode: '37377', city: 'FÅGELMARA' },
  { postalCode: '37378', city: 'FÅGELMARA' },
  { postalCode: '37379', city: 'FÅGELMARA' },
  { postalCode: '37400', city: 'KARLSHAMN' },
  { postalCode: '37401', city: 'KARLSHAMN' },
  { postalCode: '37402', city: 'ASARUM' },
  { postalCode: '37420', city: 'KARLSHAMN' },
  { postalCode: '37421', city: 'KARLSHAMN' },
  { postalCode: '37422', city: 'KARLSHAMN' },
  { postalCode: '37423', city: 'KARLSHAMN' },
  { postalCode: '37424', city: 'KARLSHAMN' },
  { postalCode: '37430', city: 'KARLSHAMN' },
  { postalCode: '37431', city: 'KARLSHAMN' },
  { postalCode: '37432', city: 'KARLSHAMN' },
  { postalCode: '37433', city: 'KARLSHAMN' },
  { postalCode: '37434', city: 'KARLSHAMN' },
  { postalCode: '37435', city: 'KARLSHAMN' },
  { postalCode: '37436', city: 'KARLSHAMN' },
  { postalCode: '37437', city: 'KARLSHAMN' },
  { postalCode: '37438', city: 'KARLSHAMN' },
  { postalCode: '37439', city: 'KARLSHAMN' },
  { postalCode: '37440', city: 'KARLSHAMN' },
  { postalCode: '37441', city: 'KARLSHAMN' },
  { postalCode: '37450', city: 'ASARUM' },
  { postalCode: '37451', city: 'ASARUM' },
  { postalCode: '37452', city: 'ASARUM' },
  { postalCode: '37453', city: 'ASARUM' },
  { postalCode: '37454', city: 'ASARUM' },
  { postalCode: '37470', city: 'TRENSUM' },
  { postalCode: '37480', city: 'KARLSHAMN' },
  { postalCode: '37481', city: 'KARLSHAMN' },
  { postalCode: '37482', city: 'KARLSHAMN' },
  { postalCode: '37483', city: 'KARLSHAMN' },
  { postalCode: '37491', city: 'ASARUM' },
  { postalCode: '37492', city: 'ASARUM' },
  { postalCode: '37493', city: 'KARLSHAMN' },
  { postalCode: '37494', city: 'TRENSUM' },
  { postalCode: '37495', city: 'TRENSUM' },
  { postalCode: '37496', city: 'TRENSUM' },
  { postalCode: '37521', city: 'MÖRRUM' },
  { postalCode: '37522', city: 'MÖRRUM' },
  { postalCode: '37530', city: 'MÖRRUM' },
  { postalCode: '37531', city: 'MÖRRUM' },
  { postalCode: '37532', city: 'MÖRRUM' },
  { postalCode: '37533', city: 'MÖRRUM' },
  { postalCode: '37534', city: 'MÖRRUM' },
  { postalCode: '37585', city: 'MÖRRUM' },
  { postalCode: '37586', city: 'MÖRRUM' },
  { postalCode: '37590', city: 'MÖRRUM' },
  { postalCode: '37591', city: 'MÖRRUM' },
  { postalCode: '37623', city: 'SVÄNGSTA' },
  { postalCode: '37635', city: 'SVÄNGSTA' },
  { postalCode: '37636', city: 'SVÄNGSTA' },
  { postalCode: '37637', city: 'SVÄNGSTA' },
  { postalCode: '37681', city: 'SVÄNGSTA' },
  { postalCode: '37682', city: 'SVÄNGSTA' },
  { postalCode: '37692', city: 'SVÄNGSTA' },
  { postalCode: '37693', city: 'SVÄNGSTA' },
  { postalCode: '38201', city: 'NYBRO' },
  { postalCode: '38220', city: 'NYBRO' },
  { postalCode: '38221', city: 'NYBRO' },
  { postalCode: '38222', city: 'NYBRO' },
  { postalCode: '38223', city: 'NYBRO' },
  { postalCode: '38224', city: 'NYBRO' },
  { postalCode: '38228', city: 'NYBRO' },
  { postalCode: '38230', city: 'NYBRO' },
  { postalCode: '38231', city: 'NYBRO' },
  { postalCode: '38232', city: 'NYBRO' },
  { postalCode: '38233', city: 'NYBRO' },
  { postalCode: '38234', city: 'NYBRO' },
  { postalCode: '38235', city: 'NYBRO' },
  { postalCode: '38236', city: 'NYBRO' },
  { postalCode: '38237', city: 'NYBRO' },
  { postalCode: '38238', city: 'NYBRO' },
  { postalCode: '38239', city: 'NYBRO' },
  { postalCode: '38240', city: 'NYBRO' },
  { postalCode: '38241', city: 'NYBRO' },
  { postalCode: '38242', city: 'NYBRO' },
  { postalCode: '38243', city: 'NYBRO' },
  { postalCode: '38244', city: 'NYBRO' },
  { postalCode: '38245', city: 'NYBRO' },
  { postalCode: '38246', city: 'NYBRO' },
  { postalCode: '38271', city: 'ORREFORS' },
  { postalCode: '38272', city: 'ORREFORS' },
  { postalCode: '38273', city: 'ALSTERBRO' },
  { postalCode: '38274', city: 'ALSTERBRO' },
  { postalCode: '38275', city: 'GULLASKRUV' },
  { postalCode: '38276', city: 'MÅLERÅS' },
  { postalCode: '38280', city: 'NYBRO' },
  { postalCode: '38290', city: 'ÖRSJÖ' },
  { postalCode: '38291', city: 'NYBRO' },
  { postalCode: '38292', city: 'NYBRO' },
  { postalCode: '38293', city: 'NYBRO' },
  { postalCode: '38294', city: 'NYBRO' },
  { postalCode: '38296', city: 'NYBRO' },
  { postalCode: '38301', city: 'MÖNSTERÅS' },
  { postalCode: '38320', city: 'MÖNSTERÅS' },
  { postalCode: '38321', city: 'MÖNSTERÅS' },
  { postalCode: '38322', city: 'MÖNSTERÅS' },
  { postalCode: '38323', city: 'MÖNSTERÅS' },
  { postalCode: '38324', city: 'MÖNSTERÅS' },
  { postalCode: '38325', city: 'MÖNSTERÅS' },
  { postalCode: '38330', city: 'MÖNSTERÅS' },
  { postalCode: '38331', city: 'MÖNSTERÅS' },
  { postalCode: '38332', city: 'MÖNSTERÅS' },
  { postalCode: '38333', city: 'MÖNSTERÅS' },
  { postalCode: '38334', city: 'MÖNSTERÅS' },
  { postalCode: '38335', city: 'MÖNSTERÅS' },
  { postalCode: '38336', city: 'MÖNSTERÅS' },
  { postalCode: '38337', city: 'MÖNSTERÅS' },
  { postalCode: '38338', city: 'MÖNSTERÅS' },
  { postalCode: '38339', city: 'MÖNSTERÅS' },
  { postalCode: '38391', city: 'MÖNSTERÅS' },
  { postalCode: '38392', city: 'MÖNSTERÅS' },
  { postalCode: '38402', city: 'ÅLEM' },
  { postalCode: '38421', city: 'BLOMSTERMÅLA' },
  { postalCode: '38422', city: 'BLOMSTERMÅLA' },
  { postalCode: '38430', city: 'BLOMSTERMÅLA' },
  { postalCode: '38431', city: 'BLOMSTERMÅLA' },
  { postalCode: '38440', city: 'ÅLEM' },
  { postalCode: '38471', city: 'TIMMERNABBEN' },
  { postalCode: '38472', city: 'TIMMERNABBEN' },
  { postalCode: '38473', city: 'FLISERYD' },
  { postalCode: '38491', city: 'BLOMSTERMÅLA' },
  { postalCode: '38492', city: 'ÅLEM' },
  { postalCode: '38493', city: 'ÅLEM' },
  { postalCode: '38501', city: 'TORSÅS' },
  { postalCode: '38502', city: 'BERGKVARA' },
  { postalCode: '38503', city: 'SÖDERÅKRA' },
  { postalCode: '38520', city: 'TORSÅS' },
  { postalCode: '38521', city: 'TORSÅS' },
  { postalCode: '38522', city: 'TORSÅS' },
  { postalCode: '38525', city: 'TORSÅS' },
  { postalCode: '38530', city: 'TORSÅS' },
  { postalCode: '38531', city: 'TORSÅS' },
  { postalCode: '38532', city: 'TORSÅS' },
  { postalCode: '38533', city: 'TORSÅS' },
  { postalCode: '38534', city: 'TORSÅS' },
  { postalCode: '38540', city: 'BERGKVARA' },
  { postalCode: '38550', city: 'SÖDERÅKRA' },
  { postalCode: '38551', city: 'SÖDERÅKRA' },
  { postalCode: '38590', city: 'SÖDERÅKRA' },
  { postalCode: '38591', city: 'TORSÅS' },
  { postalCode: '38592', city: 'GULLABO' },
  { postalCode: '38593', city: 'TORSÅS' },
  { postalCode: '38594', city: 'BERGKVARA' },
  { postalCode: '38595', city: 'TORSÅS' },
  { postalCode: '38597', city: 'SÖDERÅKRA' },
  { postalCode: '38598', city: 'BERGKVARA' },
  { postalCode: '38599', city: 'TORSÅS' },
  { postalCode: '38601', city: 'FÄRJESTADEN' },
  { postalCode: '38602', city: 'MÖRBYLÅNGA' },
  { postalCode: '38620', city: 'FÄRJESTADEN' },
  { postalCode: '38621', city: 'FÄRJESTADEN' },
  { postalCode: '38622', city: 'FÄRJESTADEN' },
  { postalCode: '38623', city: 'FÄRJESTADEN' },
  { postalCode: '38630', city: 'FÄRJESTADEN' },
  { postalCode: '38631', city: 'FÄRJESTADEN' },
  { postalCode: '38632', city: 'FÄRJESTADEN' },
  { postalCode: '38633', city: 'FÄRJESTADEN' },
  { postalCode: '38634', city: 'FÄRJESTADEN' },
  { postalCode: '38635', city: 'FÄRJESTADEN' },
  { postalCode: '38650', city: 'MÖRBYLÅNGA' },
  { postalCode: '38651', city: 'MÖRBYLÅNGA' },
  { postalCode: '38660', city: 'MÖRBYLÅNGA' },
  { postalCode: '38661', city: 'MÖRBYLÅNGA' },
  { postalCode: '38662', city: 'MÖRBYLÅNGA' },
  { postalCode: '38663', city: 'DEGERHAMN' },
  { postalCode: '38664', city: 'DEGERHAMN' },
  { postalCode: '38680', city: 'MÖRBYLÅNGA' },
  { postalCode: '38690', city: 'FÄRJESTADEN' },
  { postalCode: '38692', city: 'FÄRJESTADEN' },
  { postalCode: '38693', city: 'FÄRJESTADEN' },
  { postalCode: '38694', city: 'FÄRJESTADEN' },
  { postalCode: '38695', city: 'FÄRJESTADEN' },
  { postalCode: '38696', city: 'FÄRJESTADEN' },
  { postalCode: '38701', city: 'BORGHOLM' },
  { postalCode: '38703', city: 'LÖTTORP' },
  { postalCode: '38704', city: 'BYXELKROK' },
  { postalCode: '38720', city: 'BORGHOLM' },
  { postalCode: '38721', city: 'BORGHOLM' },
  { postalCode: '38722', city: 'BORGHOLM' },
  { postalCode: '38723', city: 'BORGHOLM' },
  { postalCode: '38724', city: 'BORGHOLM' },
  { postalCode: '38731', city: 'BORGHOLM' },
  { postalCode: '38732', city: 'BORGHOLM' },
  { postalCode: '38733', city: 'BORGHOLM' },
  { postalCode: '38734', city: 'BORGHOLM' },
  { postalCode: '38735', city: 'BORGHOLM' },
  { postalCode: '38736', city: 'BORGHOLM' },
  { postalCode: '38737', city: 'BORGHOLM' },
  { postalCode: '38750', city: 'KÖPINGSVIK' },
  { postalCode: '38752', city: 'KÖPINGSVIK' },
  { postalCode: '38770', city: 'LÖTTORP' },
  { postalCode: '38771', city: 'LÖTTORP' },
  { postalCode: '38772', city: 'LÖTTORP' },
  { postalCode: '38773', city: 'LÖTTORP' },
  { postalCode: '38774', city: 'LÖTTORP' },
  { postalCode: '38775', city: 'BYXELKROK' },
  { postalCode: '38788', city: 'BORGHOLM' },
  { postalCode: '38790', city: 'KÖPINGSVIK' },
  { postalCode: '38791', city: 'BORGHOLM' },
  { postalCode: '38792', city: 'BORGHOLM' },
  { postalCode: '38793', city: 'BORGHOLM' },
  { postalCode: '38794', city: 'BORGHOLM' },
  { postalCode: '38795', city: 'KÖPINGSVIK' },
  { postalCode: '38796', city: 'KÖPINGSVIK' },
  { postalCode: '38804', city: 'TREKANTEN' },
  { postalCode: '38820', city: 'LJUNGBYHOLM' },
  { postalCode: '38830', city: 'LJUNGBYHOLM' },
  { postalCode: '38831', city: 'LJUNGBYHOLM' },
  { postalCode: '38832', city: 'LJUNGBYHOLM' },
  { postalCode: '38840', city: 'TREKANTEN' },
  { postalCode: '38841', city: 'TREKANTEN' },
  { postalCode: '38850', city: 'PÅRYD' },
  { postalCode: '38891', city: 'VASSMOLÖSA' },
  { postalCode: '38892', city: 'LJUNGBYHOLM' },
  { postalCode: '38893', city: 'LJUNGBYHOLM' },
  { postalCode: '38894', city: 'VASSMOLÖSA' },
  { postalCode: '38896', city: 'HAGBY' },
  { postalCode: '38897', city: 'HALLTORP' },
  { postalCode: '38898', city: 'TREKANTEN' },
  { postalCode: '38899', city: 'PÅRYD' },
  { postalCode: '39004', city: 'KALMAR' },
  { postalCode: '39006', city: 'KALMAR' },
  { postalCode: '39008', city: 'KALMAR' },
  { postalCode: '39100', city: 'KALMAR' },
  { postalCode: '39120', city: 'KALMAR' },
  { postalCode: '39121', city: 'KALMAR' },
  { postalCode: '39122', city: 'KALMAR' },
  { postalCode: '39123', city: 'KALMAR' },
  { postalCode: '39125', city: 'KALMAR' },
  { postalCode: '39126', city: 'KALMAR' },
  { postalCode: '39127', city: 'KALMAR' },
  { postalCode: '39128', city: 'KALMAR' },
  { postalCode: '39129', city: 'KALMAR' },
  { postalCode: '39182', city: 'KALMAR' },
  { postalCode: '39183', city: 'KALMAR' },
  { postalCode: '39184', city: 'KALMAR' },
  { postalCode: '39185', city: 'KALMAR' },
  { postalCode: '39186', city: 'KALMAR' },
  { postalCode: '39187', city: 'KALMAR' },
  { postalCode: '39188', city: 'KALMAR' },
  { postalCode: '39230', city: 'KALMAR' },
  { postalCode: '39231', city: 'KALMAR' },
  { postalCode: '39232', city: 'KALMAR' },
  { postalCode: '39233', city: 'KALMAR' },
  { postalCode: '39234', city: 'KALMAR' },
  { postalCode: '39235', city: 'KALMAR' },
  { postalCode: '39236', city: 'KALMAR' },
  { postalCode: '39237', city: 'KALMAR' },
  { postalCode: '39238', city: 'KALMAR' },
  { postalCode: '39239', city: 'KALMAR' },
  { postalCode: '39240', city: 'KALMAR' },
  { postalCode: '39241', city: 'KALMAR' },
  { postalCode: '39242', city: 'KALMAR' },
  { postalCode: '39243', city: 'KALMAR' },
  { postalCode: '39244', city: 'KALMAR' },
  { postalCode: '39245', city: 'KALMAR' },
  { postalCode: '39246', city: 'KALMAR' },
  { postalCode: '39247', city: 'KALMAR' },
  { postalCode: '39248', city: 'KALMAR' },
  { postalCode: '39249', city: 'KALMAR' },
  { postalCode: '39250', city: 'KALMAR' },
  { postalCode: '39251', city: 'KALMAR' },
  { postalCode: '39252', city: 'KALMAR' },
  { postalCode: '39300', city: 'KALMAR' },
  { postalCode: '39349', city: 'KALMAR' },
  { postalCode: '39350', city: 'KALMAR' },
  { postalCode: '39351', city: 'KALMAR' },
  { postalCode: '39352', city: 'KALMAR' },
  { postalCode: '39353', city: 'KALMAR' },
  { postalCode: '39354', city: 'KALMAR' },
  { postalCode: '39355', city: 'KALMAR' },
  { postalCode: '39356', city: 'KALMAR' },
  { postalCode: '39357', city: 'KALMAR' },
  { postalCode: '39358', city: 'KALMAR' },
  { postalCode: '39359', city: 'KALMAR' },
  { postalCode: '39360', city: 'KALMAR' },
  { postalCode: '39361', city: 'KALMAR' },
  { postalCode: '39363', city: 'KALMAR' },
  { postalCode: '39364', city: 'KALMAR' },
  { postalCode: '39365', city: 'KALMAR' },
  { postalCode: '39366', city: 'KALMAR' },
  { postalCode: '39470', city: 'KALMAR' },
  { postalCode: '39471', city: 'KALMAR' },
  { postalCode: '39474', city: 'KALMAR' },
  { postalCode: '39477', city: 'KALMAR' },
  { postalCode: '39590', city: 'KALMAR' },
  { postalCode: '39591', city: 'KALMAR' },
  { postalCode: '39592', city: 'ROCKNEBY' },
  { postalCode: '39593', city: 'ROCKNEBY' },
  { postalCode: '39594', city: 'ROCKNEBY' },
  { postalCode: '39595', city: 'ROCKNEBY' },
  { postalCode: '39597', city: 'LÄCKEBY' },
  { postalCode: '39598', city: 'LÄCKEBY' },
  { postalCode: '39800', city: 'KALMAR' },
  { postalCode: '39809', city: 'KALMAR' },
  { postalCode: '39810', city: 'KALMAR' },
  { postalCode: '39811', city: 'KALMAR' },
  { postalCode: '40010', city: 'GÖTEBORG' },
  { postalCode: '40012', city: 'GÖTEBORG' },
  { postalCode: '40013', city: 'GÖTEBORG' },
  { postalCode: '40014', city: 'GÖTEBORG' },
  { postalCode: '40015', city: 'GÖTEBORG' },
  { postalCode: '40016', city: 'GÖTEBORG' },
  { postalCode: '40020', city: 'GÖTEBORG' },
  { postalCode: '40022', city: 'GÖTEBORG' },
  { postalCode: '40024', city: 'GÖTEBORG' },
  { postalCode: '40025', city: 'GÖTEBORG' },
  { postalCode: '40031', city: 'GÖTEBORG' },
  { postalCode: '40032', city: 'GÖTEBORG' },
  { postalCode: '40040', city: 'GÖTEBORG' },
  { postalCode: '40043', city: 'GÖTEBORG' },
  { postalCode: '40050', city: 'GÖTEBORG' },
  { postalCode: '40053', city: 'GÖTEBORG' },
  { postalCode: '40060', city: 'GÖTEBORG' },
  { postalCode: '40070', city: 'GÖTEBORG' },
  { postalCode: '40072', city: 'GÖTEBORG' },
  { postalCode: '40090', city: 'GÖTEBORG' },
  { postalCode: '40091', city: 'GÖTEBORG' },
  { postalCode: '40092', city: 'GÖTEBORG' },
  { postalCode: '40093', city: 'GÖTEBORG' },
  { postalCode: '40094', city: 'GÖTEBORG' },
  { postalCode: '40095', city: 'GÖTEBORG' },
  { postalCode: '40096', city: 'GÖTEBORG' },
  { postalCode: '40097', city: 'GÖTEBORG' },
  { postalCode: '40099', city: 'GÖTEBORG' },
  { postalCode: '40110', city: 'GÖTEBORG' },
  { postalCode: '40120', city: 'GÖTEBORG' },
  { postalCode: '40121', city: 'GÖTEBORG' },
  { postalCode: '40122', city: 'GÖTEBORG' },
  { postalCode: '40123', city: 'GÖTEBORG' },
  { postalCode: '40124', city: 'GÖTEBORG' },
  { postalCode: '40125', city: 'GÖTEBORG' },
  { postalCode: '40126', city: 'GÖTEBORG' },
  { postalCode: '40127', city: 'GÖTEBORG' },
  { postalCode: '40150', city: 'GÖTEBORG' },
  { postalCode: '40151', city: 'GÖTEBORG' },
  { postalCode: '40180', city: 'GÖTEBORG' },
  { postalCode: '40182', city: 'GÖTEBORG' },
  { postalCode: '40183', city: 'GÖTEBORG' },
  { postalCode: '40184', city: 'GÖTEBORG' },
  { postalCode: '40220', city: 'GÖTEBORG' },
  { postalCode: '40221', city: 'GÖTEBORG' },
  { postalCode: '40222', city: 'GÖTEBORG' },
  { postalCode: '40223', city: 'GÖTEBORG' },
  { postalCode: '40224', city: 'GÖTEBORG' },
  { postalCode: '40225', city: 'GÖTEBORG' },
  { postalCode: '40226', city: 'GÖTEBORG' },
  { postalCode: '40227', city: 'GÖTEBORG' },
  { postalCode: '40228', city: 'GÖTEBORG' },
  { postalCode: '40229', city: 'GÖTEBORG' },
  { postalCode: '40231', city: 'GÖTEBORG' },
  { postalCode: '40232', city: 'GÖTEBORG' },
  { postalCode: '40233', city: 'GÖTEBORG' },
  { postalCode: '40234', city: 'GÖTEBORG' },
  { postalCode: '40235', city: 'GÖTEBORG' },
  { postalCode: '40236', city: 'GÖTEBORG' },
  { postalCode: '40241', city: 'GÖTEBORG' },
  { postalCode: '40242', city: 'GÖTEBORG' },
  { postalCode: '40251', city: 'GÖTEBORG' },
  { postalCode: '40252', city: 'GÖTEBORG' },
  { postalCode: '40257', city: 'GÖTEBORG' },
  { postalCode: '40258', city: 'GÖTEBORG' },
  { postalCode: '40259', city: 'GÖTEBORG' },
  { postalCode: '40261', city: 'GÖTEBORG' },
  { postalCode: '40271', city: 'GÖTEBORG' },
  { postalCode: '40272', city: 'GÖTEBORG' },
  { postalCode: '40273', city: 'GÖTEBORG' },
  { postalCode: '40274', city: 'GÖTEBORG' },
  { postalCode: '40275', city: 'GÖTEBORG' },
  { postalCode: '40276', city: 'GÖTEBORG' },
  { postalCode: '40277', city: 'GÖTEBORG' },
  { postalCode: '40278', city: 'GÖTEBORG' },
  { postalCode: '40300', city: 'GÖTEBORG' },
  { postalCode: '40310', city: 'GÖTEBORG' },
  { postalCode: '40311', city: 'GÖTEBORG' },
  { postalCode: '40312', city: 'GÖTEBORG' },
  { postalCode: '40313', city: 'GÖTEBORG' },
  { postalCode: '40314', city: 'GÖTEBORG' },
  { postalCode: '40315', city: 'GÖTEBORG' },
  { postalCode: '40316', city: 'GÖTEBORG' },
  { postalCode: '40317', city: 'GÖTEBORG' },
  { postalCode: '40320', city: 'GÖTEBORG' },
  { postalCode: '40330', city: 'GÖTEBORG' },
  { postalCode: '40331', city: 'GÖTEBORG' },
  { postalCode: '40332', city: 'GÖTEBORG' },
  { postalCode: '40336', city: 'GÖTEBORG' },
  { postalCode: '40338', city: 'GÖTEBORG' },
  { postalCode: '40339', city: 'GÖTEBORG' },
  { postalCode: '40340', city: 'GÖTEBORG' },
  { postalCode: '40420', city: 'GÖTEBORG' },
  { postalCode: '40421', city: 'GÖTEBORG' },
  { postalCode: '40422', city: 'GÖTEBORG' },
  { postalCode: '40423', city: 'GÖTEBORG' },
  { postalCode: '40424', city: 'GÖTEBORG' },
  { postalCode: '40425', city: 'GÖTEBORG' },
  { postalCode: '40426', city: 'GÖTEBORG' },
  { postalCode: '40427', city: 'GÖTEBORG' },
  { postalCode: '40428', city: 'GÖTEBORG' },
  { postalCode: '40429', city: 'GÖTEBORG' },
  { postalCode: '40430', city: 'GÖTEBORG' },
  { postalCode: '40439', city: 'GÖTEBORG' },
  { postalCode: '40480', city: 'GÖTEBORG' },
  { postalCode: '40482', city: 'GÖTEBORG' },
  { postalCode: '40483', city: 'GÖTEBORG' },
  { postalCode: '40484', city: 'GÖTEBORG' },
  { postalCode: '40485', city: 'GÖTEBORG' },
  { postalCode: '40500', city: 'GÖTEBORG' },
  { postalCode: '40502', city: 'GÖTEBORG' },
  { postalCode: '40503', city: 'GÖTEBORG' },
  { postalCode: '40504', city: 'GÖTEBORG' },
  { postalCode: '40505', city: 'GÖTEBORG' },
  { postalCode: '40507', city: 'GÖTEBORG' },
  { postalCode: '40508', city: 'GÖTEBORG' },
  { postalCode: '40509', city: 'GÖTEBORG' },
  { postalCode: '40510', city: 'GÖTEBORG' },
  { postalCode: '40512', city: 'GÖTEBORG' },
  { postalCode: '40513', city: 'GÖTEBORG' },
  { postalCode: '40514', city: 'GÖTEBORG' },
  { postalCode: '40515', city: 'GÖTEBORG' },
  { postalCode: '40516', city: 'GÖTEBORG' },
  { postalCode: '40518', city: 'GÖTEBORG' },
  { postalCode: '40519', city: 'GÖTEBORG' },
  { postalCode: '40520', city: 'GÖTEBORG' },
  { postalCode: '40521', city: 'GÖTEBORG' },
  { postalCode: '40522', city: 'GÖTEBORG' },
  { postalCode: '40523', city: 'GÖTEBORG' },
  { postalCode: '40530', city: 'GÖTEBORG' },
  { postalCode: '40531', city: 'GÖTEBORG' },
  { postalCode: '40532', city: 'GÖTEBORG' },
  { postalCode: '40533', city: 'GÖTEBORG' },
  { postalCode: '40535', city: 'GÖTEBORG' },
  { postalCode: '40536', city: 'GÖTEBORG' },
  { postalCode: '40537', city: 'GÖTEBORG' },
  { postalCode: '40538', city: 'GÖTEBORG' },
  { postalCode: '40540', city: 'GÖTEBORG' },
  { postalCode: '40544', city: 'GÖTEBORG' },
  { postalCode: '40545', city: 'GÖTEBORG' },
  { postalCode: '40550', city: 'GÖTEBORG' },
  { postalCode: '40570', city: 'GÖTEBORG' },
  { postalCode: '40583', city: 'GÖTEBORG' },
  { postalCode: '40590', city: 'GÖTEBORG' },
  { postalCode: '40800', city: 'GÖTEBORG' },
  { postalCode: '40830', city: 'GÖTEBORG' },
  { postalCode: '40901', city: 'GÖTEBORG' },
  { postalCode: '40912', city: 'GÖTEBORG' },
  { postalCode: '40917', city: 'GÖTEBORG' },
  { postalCode: '40926', city: 'GÖTEBORG' },
  { postalCode: '40927', city: 'GÖTEBORG' },
  { postalCode: '41100', city: 'GÖTEBORG' },
  { postalCode: '41101', city: 'GÖTEBORG' },
  { postalCode: '41102', city: 'GÖTEBORG' },
  { postalCode: '41103', city: 'GÖTEBORG' },
  { postalCode: '41104', city: 'GÖTEBORG' },
  { postalCode: '41105', city: 'GÖTEBORG' },
  { postalCode: '41106', city: 'GÖTEBORG' },
  { postalCode: '41107', city: 'GÖTEBORG' },
  { postalCode: '41108', city: 'GÖTEBORG' },
  { postalCode: '41109', city: 'GÖTEBORG' },
  { postalCode: '41110', city: 'GÖTEBORG' },
  { postalCode: '41111', city: 'GÖTEBORG' },
  { postalCode: '41112', city: 'GÖTEBORG' },
  { postalCode: '41113', city: 'GÖTEBORG' },
  { postalCode: '41114', city: 'GÖTEBORG' },
  { postalCode: '41115', city: 'GÖTEBORG' },
  { postalCode: '41116', city: 'GÖTEBORG' },
  { postalCode: '41117', city: 'GÖTEBORG' },
  { postalCode: '41118', city: 'GÖTEBORG' },
  { postalCode: '41119', city: 'GÖTEBORG' },
  { postalCode: '41120', city: 'GÖTEBORG' },
  { postalCode: '41121', city: 'GÖTEBORG' },
  { postalCode: '41122', city: 'GÖTEBORG' },
  { postalCode: '41123', city: 'GÖTEBORG' },
  { postalCode: '41124', city: 'GÖTEBORG' },
  { postalCode: '41125', city: 'GÖTEBORG' },
  { postalCode: '41126', city: 'GÖTEBORG' },
  { postalCode: '41127', city: 'GÖTEBORG' },
  { postalCode: '41128', city: 'GÖTEBORG' },
  { postalCode: '41129', city: 'GÖTEBORG' },
  { postalCode: '41130', city: 'GÖTEBORG' },
  { postalCode: '41131', city: 'GÖTEBORG' },
  { postalCode: '41132', city: 'GÖTEBORG' },
  { postalCode: '41133', city: 'GÖTEBORG' },
  { postalCode: '41134', city: 'GÖTEBORG' },
  { postalCode: '41135', city: 'GÖTEBORG' },
  { postalCode: '41136', city: 'GÖTEBORG' },
  { postalCode: '41137', city: 'GÖTEBORG' },
  { postalCode: '41138', city: 'GÖTEBORG' },
  { postalCode: '41139', city: 'GÖTEBORG' },
  { postalCode: '41140', city: 'GÖTEBORG' },
  { postalCode: '41141', city: 'GÖTEBORG' },
  { postalCode: '41142', city: 'GÖTEBORG' },
  { postalCode: '41143', city: 'GÖTEBORG' },
  { postalCode: '41181', city: 'GÖTEBORG' },
  { postalCode: '41221', city: 'GÖTEBORG' },
  { postalCode: '41249', city: 'GÖTEBORG' },
  { postalCode: '41250', city: 'GÖTEBORG' },
  { postalCode: '41251', city: 'GÖTEBORG' },
  { postalCode: '41252', city: 'GÖTEBORG' },
  { postalCode: '41253', city: 'GÖTEBORG' },
  { postalCode: '41254', city: 'GÖTEBORG' },
  { postalCode: '41255', city: 'GÖTEBORG' },
  { postalCode: '41256', city: 'GÖTEBORG' },
  { postalCode: '41257', city: 'GÖTEBORG' },
  { postalCode: '41258', city: 'GÖTEBORG' },
  { postalCode: '41259', city: 'GÖTEBORG' },
  { postalCode: '41260', city: 'GÖTEBORG' },
  { postalCode: '41261', city: 'GÖTEBORG' },
  { postalCode: '41262', city: 'GÖTEBORG' },
  { postalCode: '41263', city: 'GÖTEBORG' },
  { postalCode: '41264', city: 'GÖTEBORG' },
  { postalCode: '41265', city: 'GÖTEBORG' },
  { postalCode: '41266', city: 'GÖTEBORG' },
  { postalCode: '41267', city: 'GÖTEBORG' },
  { postalCode: '41268', city: 'GÖTEBORG' },
  { postalCode: '41269', city: 'GÖTEBORG' },
  { postalCode: '41270', city: 'GÖTEBORG' },
  { postalCode: '41271', city: 'GÖTEBORG' },
  { postalCode: '41272', city: 'GÖTEBORG' },
  { postalCode: '41273', city: 'GÖTEBORG' },
  { postalCode: '41274', city: 'GÖTEBORG' },
  { postalCode: '41275', city: 'GÖTEBORG' },
  { postalCode: '41276', city: 'GÖTEBORG' },
  { postalCode: '41277', city: 'GÖTEBORG' },
  { postalCode: '41278', city: 'GÖTEBORG' },
  { postalCode: '41279', city: 'GÖTEBORG' },
  { postalCode: '41280', city: 'GÖTEBORG' },
  { postalCode: '41281', city: 'GÖTEBORG' },
  { postalCode: '41282', city: 'GÖTEBORG' },
  { postalCode: '41285', city: 'GÖTEBORG' },
  { postalCode: '41286', city: 'GÖTEBORG' },
  { postalCode: '41288', city: 'GÖTEBORG' },
  { postalCode: '41289', city: 'GÖTEBORG' },
  { postalCode: '41290', city: 'GÖTEBORG' },
  { postalCode: '41292', city: 'GÖTEBORG' },
  { postalCode: '41293', city: 'GÖTEBORG' },
  { postalCode: '41294', city: 'GÖTEBORG' },
  { postalCode: '41295', city: 'GÖTEBORG' },
  { postalCode: '41296', city: 'GÖTEBORG' },
  { postalCode: '41297', city: 'GÖTEBORG' },
  { postalCode: '41298', city: 'GÖTEBORG' },
  { postalCode: '41299', city: 'GÖTEBORG' },
  { postalCode: '41300', city: 'GÖTEBORG' },
  { postalCode: '41301', city: 'GÖTEBORG' },
  { postalCode: '41302', city: 'GÖTEBORG' },
  { postalCode: '41303', city: 'GÖTEBORG' },
  { postalCode: '41304', city: 'GÖTEBORG' },
  { postalCode: '41305', city: 'GÖTEBORG' },
  { postalCode: '41306', city: 'GÖTEBORG' },
  { postalCode: '41307', city: 'GÖTEBORG' },
  { postalCode: '41308', city: 'GÖTEBORG' },
  { postalCode: '41309', city: 'GÖTEBORG' },
  { postalCode: '41310', city: 'GÖTEBORG' },
  { postalCode: '41311', city: 'GÖTEBORG' },
  { postalCode: '41312', city: 'GÖTEBORG' },
  { postalCode: '41313', city: 'GÖTEBORG' },
  { postalCode: '41314', city: 'GÖTEBORG' },
  { postalCode: '41315', city: 'GÖTEBORG' },
  { postalCode: '41316', city: 'GÖTEBORG' },
  { postalCode: '41317', city: 'GÖTEBORG' },
  { postalCode: '41318', city: 'GÖTEBORG' },
  { postalCode: '41319', city: 'GÖTEBORG' },
  { postalCode: '41320', city: 'GÖTEBORG' },
  { postalCode: '41321', city: 'GÖTEBORG' },
  { postalCode: '41322', city: 'GÖTEBORG' },
  { postalCode: '41323', city: 'GÖTEBORG' },
  { postalCode: '41324', city: 'GÖTEBORG' },
  { postalCode: '41325', city: 'GÖTEBORG' },
  { postalCode: '41326', city: 'GÖTEBORG' },
  { postalCode: '41327', city: 'GÖTEBORG' },
  { postalCode: '41328', city: 'GÖTEBORG' },
  { postalCode: '41345', city: 'GÖTEBORG' },
  { postalCode: '41346', city: 'GÖTEBORG' },
  { postalCode: '41380', city: 'GÖTEBORG' },
  { postalCode: '41382', city: 'GÖTEBORG' },
  { postalCode: '41390', city: 'GÖTEBORG' },
  { postalCode: '41420', city: 'GÖTEBORG' },
  { postalCode: '41450', city: 'GÖTEBORG' },
  { postalCode: '41451', city: 'GÖTEBORG' },
  { postalCode: '41452', city: 'GÖTEBORG' },
  { postalCode: '41453', city: 'GÖTEBORG' },
  { postalCode: '41454', city: 'GÖTEBORG' },
  { postalCode: '41455', city: 'GÖTEBORG' },
  { postalCode: '41456', city: 'GÖTEBORG' },
  { postalCode: '41457', city: 'GÖTEBORG' },
  { postalCode: '41458', city: 'GÖTEBORG' },
  { postalCode: '41459', city: 'GÖTEBORG' },
  { postalCode: '41460', city: 'GÖTEBORG' },
  { postalCode: '41461', city: 'GÖTEBORG' },
  { postalCode: '41462', city: 'GÖTEBORG' },
  { postalCode: '41463', city: 'GÖTEBORG' },
  { postalCode: '41464', city: 'GÖTEBORG' },
  { postalCode: '41465', city: 'GÖTEBORG' },
  { postalCode: '41466', city: 'GÖTEBORG' },
  { postalCode: '41467', city: 'GÖTEBORG' },
  { postalCode: '41468', city: 'GÖTEBORG' },
  { postalCode: '41469', city: 'GÖTEBORG' },
  { postalCode: '41470', city: 'GÖTEBORG' },
  { postalCode: '41471', city: 'GÖTEBORG' },
  { postalCode: '41472', city: 'GÖTEBORG' },
  { postalCode: '41473', city: 'GÖTEBORG' },
  { postalCode: '41474', city: 'GÖTEBORG' },
  { postalCode: '41475', city: 'GÖTEBORG' },
  { postalCode: '41476', city: 'GÖTEBORG' },
  { postalCode: '41477', city: 'GÖTEBORG' },
  { postalCode: '41478', city: 'GÖTEBORG' },
  { postalCode: '41479', city: 'GÖTEBORG' },
  { postalCode: '41480', city: 'GÖTEBORG' },
  { postalCode: '41481', city: 'GÖTEBORG' },
  { postalCode: '41482', city: 'GÖTEBORG' },
  { postalCode: '41483', city: 'GÖTEBORG' },
  { postalCode: '41484', city: 'GÖTEBORG' },
  { postalCode: '41500', city: 'GÖTEBORG' },
  { postalCode: '41502', city: 'GÖTEBORG' },
  { postalCode: '41505', city: 'GÖTEBORG' },
  { postalCode: '41511', city: 'GÖTEBORG' },
  { postalCode: '41512', city: 'GÖTEBORG' },
  { postalCode: '41513', city: 'GÖTEBORG' },
  { postalCode: '41514', city: 'GÖTEBORG' },
  { postalCode: '41515', city: 'GÖTEBORG' },
  { postalCode: '41516', city: 'GÖTEBORG' },
  { postalCode: '41517', city: 'GÖTEBORG' },
  { postalCode: '41522', city: 'GÖTEBORG' },
  { postalCode: '41523', city: 'GÖTEBORG' },
  { postalCode: '41524', city: 'GÖTEBORG' },
  { postalCode: '41525', city: 'GÖTEBORG' },
  { postalCode: '41526', city: 'GÖTEBORG' },
  { postalCode: '41527', city: 'GÖTEBORG' },
  { postalCode: '41528', city: 'GÖTEBORG' },
  { postalCode: '41531', city: 'GÖTEBORG' },
  { postalCode: '41532', city: 'GÖTEBORG' },
  { postalCode: '41533', city: 'GÖTEBORG' },
  { postalCode: '41534', city: 'GÖTEBORG' },
  { postalCode: '41535', city: 'GÖTEBORG' },
  { postalCode: '41536', city: 'GÖTEBORG' },
  { postalCode: '41537', city: 'GÖTEBORG' },
  { postalCode: '41538', city: 'GÖTEBORG' },
  { postalCode: '41541', city: 'GÖTEBORG' },
  { postalCode: '41542', city: 'GÖTEBORG' },
  { postalCode: '41543', city: 'GÖTEBORG' },
  { postalCode: '41544', city: 'GÖTEBORG' },
  { postalCode: '41545', city: 'GÖTEBORG' },
  { postalCode: '41546', city: 'GÖTEBORG' },
  { postalCode: '41547', city: 'GÖTEBORG' },
  { postalCode: '41548', city: 'GÖTEBORG' },
  { postalCode: '41550', city: 'GÖTEBORG' },
  { postalCode: '41551', city: 'GÖTEBORG' },
  { postalCode: '41552', city: 'GÖTEBORG' },
  { postalCode: '41553', city: 'GÖTEBORG' },
  { postalCode: '41554', city: 'GÖTEBORG' },
  { postalCode: '41555', city: 'GÖTEBORG' },
  { postalCode: '41556', city: 'GÖTEBORG' },
  { postalCode: '41557', city: 'GÖTEBORG' },
  { postalCode: '41561', city: 'GÖTEBORG' },
  { postalCode: '41562', city: 'GÖTEBORG' },
  { postalCode: '41563', city: 'GÖTEBORG' },
  { postalCode: '41564', city: 'GÖTEBORG' },
  { postalCode: '41565', city: 'GÖTEBORG' },
  { postalCode: '41566', city: 'GÖTEBORG' },
  { postalCode: '41567', city: 'GÖTEBORG' },
  { postalCode: '41568', city: 'GÖTEBORG' },
  { postalCode: '41571', city: 'GÖTEBORG' },
  { postalCode: '41572', city: 'GÖTEBORG' },
  { postalCode: '41573', city: 'GÖTEBORG' },
  { postalCode: '41574', city: 'GÖTEBORG' },
  { postalCode: '41582', city: 'GÖTEBORG' },
  { postalCode: '41599', city: 'GÖTEBORG' },
  { postalCode: '41600', city: 'GÖTEBORG' },
  { postalCode: '41647', city: 'GÖTEBORG' },
  { postalCode: '41648', city: 'GÖTEBORG' },
  { postalCode: '41649', city: 'GÖTEBORG' },
  { postalCode: '41650', city: 'GÖTEBORG' },
  { postalCode: '41651', city: 'GÖTEBORG' },
  { postalCode: '41652', city: 'GÖTEBORG' },
  { postalCode: '41653', city: 'GÖTEBORG' },
  { postalCode: '41654', city: 'GÖTEBORG' },
  { postalCode: '41655', city: 'GÖTEBORG' },
  { postalCode: '41656', city: 'GÖTEBORG' },
  { postalCode: '41657', city: 'GÖTEBORG' },
  { postalCode: '41658', city: 'GÖTEBORG' },
  { postalCode: '41659', city: 'GÖTEBORG' },
  { postalCode: '41660', city: 'GÖTEBORG' },
  { postalCode: '41661', city: 'GÖTEBORG' },
  { postalCode: '41662', city: 'GÖTEBORG' },
  { postalCode: '41663', city: 'GÖTEBORG' },
  { postalCode: '41664', city: 'GÖTEBORG' },
  { postalCode: '41665', city: 'GÖTEBORG' },
  { postalCode: '41666', city: 'GÖTEBORG' },
  { postalCode: '41667', city: 'GÖTEBORG' },
  { postalCode: '41668', city: 'GÖTEBORG' },
  { postalCode: '41669', city: 'GÖTEBORG' },
  { postalCode: '41670', city: 'GÖTEBORG' },
  { postalCode: '41671', city: 'GÖTEBORG' },
  { postalCode: '41672', city: 'GÖTEBORG' },
  { postalCode: '41673', city: 'GÖTEBORG' },
  { postalCode: '41674', city: 'GÖTEBORG' },
  { postalCode: '41675', city: 'GÖTEBORG' },
  { postalCode: '41676', city: 'GÖTEBORG' },
  { postalCode: '41677', city: 'GÖTEBORG' },
  { postalCode: '41678', city: 'GÖTEBORG' },
  { postalCode: '41679', city: 'GÖTEBORG' },
  { postalCode: '41680', city: 'GÖTEBORG' },
  { postalCode: '41681', city: 'GÖTEBORG' },
  { postalCode: '41685', city: 'GÖTEBORG' },
  { postalCode: '41699', city: 'GÖTEBORG' },
  { postalCode: '41701', city: 'GÖTEBORG' },
  { postalCode: '41702', city: 'GÖTEBORG' },
  { postalCode: '41703', city: 'GÖTEBORG' },
  { postalCode: '41704', city: 'GÖTEBORG' },
  { postalCode: '41705', city: 'GÖTEBORG' },
  { postalCode: '41706', city: 'GÖTEBORG' },
  { postalCode: '41707', city: 'GÖTEBORG' },
  { postalCode: '41708', city: 'GÖTEBORG' },
  { postalCode: '41709', city: 'GÖTEBORG' },
  { postalCode: '41710', city: 'GÖTEBORG' },
  { postalCode: '41711', city: 'GÖTEBORG' },
  { postalCode: '41712', city: 'GÖTEBORG' },
  { postalCode: '41713', city: 'GÖTEBORG' },
  { postalCode: '41714', city: 'GÖTEBORG' },
  { postalCode: '41715', city: 'GÖTEBORG' },
  { postalCode: '41716', city: 'GÖTEBORG' },
  { postalCode: '41717', city: 'GÖTEBORG' },
  { postalCode: '41718', city: 'GÖTEBORG' },
  { postalCode: '41720', city: 'GÖTEBORG' },
  { postalCode: '41721', city: 'GÖTEBORG' },
  { postalCode: '41722', city: 'GÖTEBORG' },
  { postalCode: '41723', city: 'GÖTEBORG' },
  { postalCode: '41724', city: 'GÖTEBORG' },
  { postalCode: '41725', city: 'GÖTEBORG' },
  { postalCode: '41726', city: 'GÖTEBORG' },
  { postalCode: '41727', city: 'GÖTEBORG' },
  { postalCode: '41728', city: 'GÖTEBORG' },
  { postalCode: '41729', city: 'GÖTEBORG' },
  { postalCode: '41730', city: 'GÖTEBORG' },
  { postalCode: '41739', city: 'GÖTEBORG' },
  { postalCode: '41740', city: 'GÖTEBORG' },
  { postalCode: '41741', city: 'GÖTEBORG' },
  { postalCode: '41742', city: 'GÖTEBORG' },
  { postalCode: '41743', city: 'GÖTEBORG' },
  { postalCode: '41744', city: 'GÖTEBORG' },
  { postalCode: '41745', city: 'GÖTEBORG' },
  { postalCode: '41746', city: 'GÖTEBORG' },
  { postalCode: '41747', city: 'GÖTEBORG' },
  { postalCode: '41748', city: 'GÖTEBORG' },
  { postalCode: '41749', city: 'GÖTEBORG' },
  { postalCode: '41750', city: 'GÖTEBORG' },
  { postalCode: '41752', city: 'GÖTEBORG' },
  { postalCode: '41753', city: 'GÖTEBORG' },
  { postalCode: '41755', city: 'GÖTEBORG' },
  { postalCode: '41756', city: 'GÖTEBORG' },
  { postalCode: '41757', city: 'GÖTEBORG' },
  { postalCode: '41758', city: 'GÖTEBORG' },
  { postalCode: '41760', city: 'GÖTEBORG' },
  { postalCode: '41761', city: 'GÖTEBORG' },
  { postalCode: '41762', city: 'GÖTEBORG' },
  { postalCode: '41763', city: 'GÖTEBORG' },
  { postalCode: '41764', city: 'GÖTEBORG' },
  { postalCode: '41765', city: 'GÖTEBORG' },
  { postalCode: '41766', city: 'GÖTEBORG' },
  { postalCode: '41767', city: 'GÖTEBORG' },
  { postalCode: '41768', city: 'GÖTEBORG' },
  { postalCode: '41769', city: 'GÖTEBORG' },
  { postalCode: '41770', city: 'GÖTEBORG' },
  { postalCode: '41779', city: 'GÖTEBORG' },
  { postalCode: '41780', city: 'GÖTEBORG' },
  { postalCode: '41785', city: 'GÖTEBORG' },
  { postalCode: '41800', city: 'GÖTEBORG' },
  { postalCode: '41802', city: 'GÖTEBORG' },
  { postalCode: '41804', city: 'GÖTEBORG' },
  { postalCode: '41812', city: 'GÖTEBORG' },
  { postalCode: '41821', city: 'GÖTEBORG' },
  { postalCode: '41822', city: 'GÖTEBORG' },
  { postalCode: '41823', city: 'GÖTEBORG' },
  { postalCode: '41824', city: 'GÖTEBORG' },
  { postalCode: '41830', city: 'GÖTEBORG' },
  { postalCode: '41831', city: 'GÖTEBORG' },
  { postalCode: '41832', city: 'GÖTEBORG' },
  { postalCode: '41833', city: 'GÖTEBORG' },
  { postalCode: '41834', city: 'GÖTEBORG' },
  { postalCode: '41835', city: 'GÖTEBORG' },
  { postalCode: '41836', city: 'GÖTEBORG' },
  { postalCode: '41837', city: 'GÖTEBORG' },
  { postalCode: '41838', city: 'GÖTEBORG' },
  { postalCode: '41839', city: 'GÖTEBORG' },
  { postalCode: '41840', city: 'GÖTEBORG' },
  { postalCode: '41841', city: 'GÖTEBORG' },
  { postalCode: '41842', city: 'GÖTEBORG' },
  { postalCode: '41843', city: 'GÖTEBORG' },
  { postalCode: '41870', city: 'GÖTEBORG' },
  { postalCode: '41871', city: 'GÖTEBORG' },
  { postalCode: '41872', city: 'GÖTEBORG' },
  { postalCode: '41873', city: 'GÖTEBORG' },
  { postalCode: '41874', city: 'GÖTEBORG' },
  { postalCode: '41875', city: 'GÖTEBORG' },
  { postalCode: '41876', city: 'GÖTEBORG' },
  { postalCode: '41877', city: 'GÖTEBORG' },
  { postalCode: '41878', city: 'GÖTEBORG' },
  { postalCode: '41879', city: 'GÖTEBORG' },
  { postalCode: '42100', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42102', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42108', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42120', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42121', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42122', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42123', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42130', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42131', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42132', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42133', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42134', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42135', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42137', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42138', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42139', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42140', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42141', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42142', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42143', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42144', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42145', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42146', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42147', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42148', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42149', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42150', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42151', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42152', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42153', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42154', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42157', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42158', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42159', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42160', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42161', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42162', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42163', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42164', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42165', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42166', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42167', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42170', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42171', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42172', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42173', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42174', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42200', city: 'HISINGS BACKA' },
  { postalCode: '42202', city: 'HISINGS BACKA' },
  { postalCode: '42203', city: 'HISINGS BACKA' },
  { postalCode: '42204', city: 'HISINGS BACKA' },
  { postalCode: '42210', city: 'HISINGS BACKA' },
  { postalCode: '42220', city: 'HISINGS BACKA' },
  { postalCode: '42241', city: 'HISINGS BACKA' },
  { postalCode: '42242', city: 'HISINGS BACKA' },
  { postalCode: '42243', city: 'HISINGS BACKA' },
  { postalCode: '42244', city: 'HISINGS BACKA' },
  { postalCode: '42245', city: 'HISINGS BACKA' },
  { postalCode: '42246', city: 'HISINGS BACKA' },
  { postalCode: '42247', city: 'HISINGS BACKA' },
  { postalCode: '42248', city: 'HISINGS BACKA' },
  { postalCode: '42249', city: 'HISINGS BACKA' },
  { postalCode: '42250', city: 'HISINGS BACKA' },
  { postalCode: '42251', city: 'HISINGS BACKA' },
  { postalCode: '42252', city: 'HISINGS BACKA' },
  { postalCode: '42253', city: 'HISINGS BACKA' },
  { postalCode: '42254', city: 'HISINGS BACKA' },
  { postalCode: '42255', city: 'HISINGS BACKA' },
  { postalCode: '42256', city: 'HISINGS BACKA' },
  { postalCode: '42257', city: 'HISINGS BACKA' },
  { postalCode: '42258', city: 'HISINGS BACKA' },
  { postalCode: '42259', city: 'HISINGS BACKA' },
  { postalCode: '42280', city: 'HISINGS BACKA' },
  { postalCode: '42300', city: 'TORSLANDA' },
  { postalCode: '42320', city: 'TORSLANDA' },
  { postalCode: '42321', city: 'TORSLANDA' },
  { postalCode: '42322', city: 'TORSLANDA' },
  { postalCode: '42323', city: 'TORSLANDA' },
  { postalCode: '42332', city: 'TORSLANDA' },
  { postalCode: '42333', city: 'TORSLANDA' },
  { postalCode: '42334', city: 'TORSLANDA' },
  { postalCode: '42336', city: 'TORSLANDA' },
  { postalCode: '42337', city: 'TORSLANDA' },
  { postalCode: '42338', city: 'TORSLANDA' },
  { postalCode: '42339', city: 'TORSLANDA' },
  { postalCode: '42340', city: 'TORSLANDA' },
  { postalCode: '42341', city: 'TORSLANDA' },
  { postalCode: '42342', city: 'TORSLANDA' },
  { postalCode: '42343', city: 'TORSLANDA' },
  { postalCode: '42346', city: 'TORSLANDA' },
  { postalCode: '42347', city: 'TORSLANDA' },
  { postalCode: '42349', city: 'TORSLANDA' },
  { postalCode: '42350', city: 'TORSLANDA' },
  { postalCode: '42351', city: 'TORSLANDA' },
  { postalCode: '42352', city: 'TORSLANDA' },
  { postalCode: '42353', city: 'TORSLANDA' },
  { postalCode: '42354', city: 'TORSLANDA' },
  { postalCode: '42355', city: 'TORSLANDA' },
  { postalCode: '42356', city: 'TORSLANDA' },
  { postalCode: '42359', city: 'TORSLANDA' },
  { postalCode: '42361', city: 'TORSLANDA' },
  { postalCode: '42363', city: 'TORSLANDA' },
  { postalCode: '42370', city: 'SÄVE' },
  { postalCode: '42371', city: 'SÄVE' },
  { postalCode: '42372', city: 'SÄVE' },
  { postalCode: '42373', city: 'SÄVE' },
  { postalCode: '42400', city: 'ANGERED' },
  { postalCode: '42401', city: 'ANGERED' },
  { postalCode: '42402', city: 'ANGERED' },
  { postalCode: '42420', city: 'ANGERED' },
  { postalCode: '42421', city: 'ANGERED' },
  { postalCode: '42422', city: 'ANGERED' },
  { postalCode: '42423', city: 'ANGERED' },
  { postalCode: '42424', city: 'ANGERED' },
  { postalCode: '42431', city: 'ANGERED' },
  { postalCode: '42432', city: 'ANGERED' },
  { postalCode: '42433', city: 'ANGERED' },
  { postalCode: '42434', city: 'ANGERED' },
  { postalCode: '42435', city: 'ANGERED' },
  { postalCode: '42436', city: 'ANGERED' },
  { postalCode: '42437', city: 'ANGERED' },
  { postalCode: '42438', city: 'AGNESBERG' },
  { postalCode: '42439', city: 'ANGERED' },
  { postalCode: '42440', city: 'ANGERED' },
  { postalCode: '42441', city: 'ANGERED' },
  { postalCode: '42442', city: 'ANGERED' },
  { postalCode: '42443', city: 'ANGERED' },
  { postalCode: '42444', city: 'ANGERED' },
  { postalCode: '42445', city: 'ANGERED' },
  { postalCode: '42446', city: 'ANGERED' },
  { postalCode: '42447', city: 'ANGERED' },
  { postalCode: '42448', city: 'ANGERED' },
  { postalCode: '42449', city: 'ANGERED' },
  { postalCode: '42450', city: 'ANGERED' },
  { postalCode: '42453', city: 'ANGERED' },
  { postalCode: '42454', city: 'ANGERED' },
  { postalCode: '42455', city: 'ANGERED' },
  { postalCode: '42456', city: 'GUNNILSE' },
  { postalCode: '42457', city: 'GUNNILSE' },
  { postalCode: '42461', city: 'ANGERED' },
  { postalCode: '42465', city: 'ANGERED' },
  { postalCode: '42466', city: 'ANGERED' },
  { postalCode: '42467', city: 'ANGERED' },
  { postalCode: '42468', city: 'ANGERED' },
  { postalCode: '42469', city: 'ANGERED' },
  { postalCode: '42470', city: 'OLOFSTORP' },
  { postalCode: '42471', city: 'OLOFSTORP' },
  { postalCode: '42472', city: 'OLOFSTORP' },
  { postalCode: '42480', city: 'ANGERED' },
  { postalCode: '42490', city: 'OLOFSTORP' },
  { postalCode: '42491', city: 'OLOFSTORP' },
  { postalCode: '42500', city: 'HISINGS KÄRRA' },
  { postalCode: '42502', city: 'HISINGS KÄRRA' },
  { postalCode: '42508', city: 'HISINGS KÄRRA' },
  { postalCode: '42530', city: 'HISINGS KÄRRA' },
  { postalCode: '42531', city: 'HISINGS KÄRRA' },
  { postalCode: '42532', city: 'HISINGS KÄRRA' },
  { postalCode: '42533', city: 'HISINGS KÄRRA' },
  { postalCode: '42534', city: 'HISINGS KÄRRA' },
  { postalCode: '42535', city: 'HISINGS KÄRRA' },
  { postalCode: '42536', city: 'HISINGS KÄRRA' },
  { postalCode: '42537', city: 'HISINGS KÄRRA' },
  { postalCode: '42538', city: 'HISINGS KÄRRA' },
  { postalCode: '42539', city: 'HISINGS KÄRRA' },
  { postalCode: '42541', city: 'HISINGS KÄRRA' },
  { postalCode: '42542', city: 'HISINGS KÄRRA' },
  { postalCode: '42543', city: 'HISINGS KÄRRA' },
  { postalCode: '42565', city: 'HISINGS KÄRRA' },
  { postalCode: '42600', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42604', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42605', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42620', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42650', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42651', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42652', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42653', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42654', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42655', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42656', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42658', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42659', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42668', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42669', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42670', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42671', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42672', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42673', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42674', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42676', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42677', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42679', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42680', city: 'VÄSTRA FRÖLUNDA' },
  { postalCode: '42700', city: 'BILLDAL' },
  { postalCode: '42720', city: 'BILLDAL' },
  { postalCode: '42721', city: 'BILLDAL' },
  { postalCode: '42722', city: 'BILLDAL' },
  { postalCode: '42723', city: 'BILLDAL' },
  { postalCode: '42734', city: 'BILLDAL' },
  { postalCode: '42735', city: 'BILLDAL' },
  { postalCode: '42736', city: 'BILLDAL' },
  { postalCode: '42737', city: 'BILLDAL' },
  { postalCode: '42738', city: 'BILLDAL' },
  { postalCode: '42739', city: 'BILLDAL' },
  { postalCode: '42740', city: 'BILLDAL' },
  { postalCode: '42750', city: 'BILLDAL' },
  { postalCode: '42821', city: 'KÅLLERED' },
  { postalCode: '42822', city: 'KÅLLERED' },
  { postalCode: '42830', city: 'KÅLLERED' },
  { postalCode: '42831', city: 'KÅLLERED' },
  { postalCode: '42832', city: 'KÅLLERED' },
  { postalCode: '42833', city: 'KÅLLERED' },
  { postalCode: '42834', city: 'KÅLLERED' },
  { postalCode: '42835', city: 'KÅLLERED' },
  { postalCode: '42836', city: 'KÅLLERED' },
  { postalCode: '42837', city: 'KÅLLERED' },
  { postalCode: '42880', city: 'KÅLLERED' },
  { postalCode: '42881', city: 'KÅLLERED' },
  { postalCode: '42911', city: 'SÄRÖ' },
  { postalCode: '42912', city: 'SÄRÖ' },
  { postalCode: '42913', city: 'SÄRÖ' },
  { postalCode: '42921', city: 'KULLAVIK' },
  { postalCode: '42922', city: 'KULLAVIK' },
  { postalCode: '42923', city: 'KULLAVIK' },
  { postalCode: '42930', city: 'KULLAVIK' },
  { postalCode: '42931', city: 'KULLAVIK' },
  { postalCode: '42932', city: 'KULLAVIK' },
  { postalCode: '42933', city: 'KULLAVIK' },
  { postalCode: '42934', city: 'KULLAVIK' },
  { postalCode: '42935', city: 'KULLAVIK' },
  { postalCode: '42941', city: 'SÄRÖ' },
  { postalCode: '42942', city: 'SÄRÖ' },
  { postalCode: '42943', city: 'SÄRÖ' },
  { postalCode: '42944', city: 'SÄRÖ' },
  { postalCode: '42980', city: 'SÄRÖ' },
  { postalCode: '43080', city: 'ASPERÖ' },
  { postalCode: '43081', city: 'KÖPSTADSÖ' },
  { postalCode: '43082', city: 'DONSÖ' },
  { postalCode: '43083', city: 'VRÅNGÖ' },
  { postalCode: '43084', city: 'STYRSÖ' },
  { postalCode: '43085', city: 'BRÄNNÖ' },
  { postalCode: '43100', city: 'MÖLNDAL' },
  { postalCode: '43102', city: 'MÖLNDAL' },
  { postalCode: '43104', city: 'MÖLNDAL' },
  { postalCode: '43119', city: 'MÖLNDAL' },
  { postalCode: '43120', city: 'MÖLNDAL' },
  { postalCode: '43121', city: 'MÖLNDAL' },
  { postalCode: '43122', city: 'MÖLNDAL' },
  { postalCode: '43123', city: 'MÖLNDAL' },
  { postalCode: '43124', city: 'MÖLNDAL' },
  { postalCode: '43125', city: 'MÖLNDAL' },
  { postalCode: '43126', city: 'MÖLNDAL' },
  { postalCode: '43130', city: 'MÖLNDAL' },
  { postalCode: '43131', city: 'MÖLNDAL' },
  { postalCode: '43132', city: 'MÖLNDAL' },
  { postalCode: '43133', city: 'MÖLNDAL' },
  { postalCode: '43134', city: 'MÖLNDAL' },
  { postalCode: '43135', city: 'MÖLNDAL' },
  { postalCode: '43136', city: 'MÖLNDAL' },
  { postalCode: '43137', city: 'MÖLNDAL' },
  { postalCode: '43138', city: 'MÖLNDAL' },
  { postalCode: '43139', city: 'MÖLNDAL' },
  { postalCode: '43140', city: 'MÖLNDAL' },
  { postalCode: '43141', city: 'MÖLNDAL' },
  { postalCode: '43142', city: 'MÖLNDAL' },
  { postalCode: '43143', city: 'MÖLNDAL' },
  { postalCode: '43144', city: 'MÖLNDAL' },
  { postalCode: '43145', city: 'MÖLNDAL' },
  { postalCode: '43146', city: 'MÖLNDAL' },
  { postalCode: '43147', city: 'MÖLNDAL' },
  { postalCode: '43148', city: 'MÖLNDAL' },
  { postalCode: '43149', city: 'MÖLNDAL' },
  { postalCode: '43150', city: 'MÖLNDAL' },
  { postalCode: '43151', city: 'MÖLNDAL' },
  { postalCode: '43153', city: 'MÖLNDAL' },
  { postalCode: '43159', city: 'MÖLNDAL' },
  { postalCode: '43160', city: 'MÖLNDAL' },
  { postalCode: '43161', city: 'MÖLNDAL' },
  { postalCode: '43162', city: 'MÖLNDAL' },
  { postalCode: '43163', city: 'MÖLNDAL' },
  { postalCode: '43164', city: 'MÖLNDAL' },
  { postalCode: '43166', city: 'MÖLNDAL' },
  { postalCode: '43167', city: 'MÖLNDAL' },
  { postalCode: '43168', city: 'MÖLNDAL' },
  { postalCode: '43169', city: 'MÖLNDAL' },
  { postalCode: '43180', city: 'MÖLNDAL' },
  { postalCode: '43182', city: 'MÖLNDAL' },
  { postalCode: '43183', city: 'MÖLNDAL' },
  { postalCode: '43184', city: 'MÖLNDAL' },
  { postalCode: '43185', city: 'MÖLNDAL' },
  { postalCode: '43186', city: 'MÖLNDAL' },
  { postalCode: '43187', city: 'MÖLNDAL' },
  { postalCode: '43190', city: 'MÖLNDAL' },
  { postalCode: '43200', city: 'VARBERG' },
  { postalCode: '43201', city: 'VARBERG' },
  { postalCode: '43202', city: 'VARBERG' },
  { postalCode: '43203', city: 'TRÄSLÖVSLÄGE' },
  { postalCode: '43205', city: 'TVÅÅKER' },
  { postalCode: '43206', city: 'BUA' },
  { postalCode: '43207', city: 'VEDDIGE' },
  { postalCode: '43208', city: 'ROLFSTORP' },
  { postalCode: '43210', city: 'VARBERG' },
  { postalCode: '43213', city: 'VARBERG' },
  { postalCode: '43214', city: 'VARBERG' },
  { postalCode: '43215', city: 'VARBERG' },
  { postalCode: '43216', city: 'VARBERG' },
  { postalCode: '43217', city: 'VARBERG' },
  { postalCode: '43218', city: 'VARBERG' },
  { postalCode: '43219', city: 'VARBERG' },
  { postalCode: '43221', city: 'VARBERG' },
  { postalCode: '43222', city: 'VARBERG' },
  { postalCode: '43223', city: 'VARBERG' },
  { postalCode: '43224', city: 'VARBERG' },
  { postalCode: '43225', city: 'VARBERG' },
  { postalCode: '43230', city: 'VARBERG' },
  { postalCode: '43231', city: 'VARBERG' },
  { postalCode: '43232', city: 'VARBERG' },
  { postalCode: '43233', city: 'VARBERG' },
  { postalCode: '43234', city: 'VARBERG' },
  { postalCode: '43235', city: 'VARBERG' },
  { postalCode: '43236', city: 'VARBERG' },
  { postalCode: '43237', city: 'VARBERG' },
  { postalCode: '43238', city: 'VARBERG' },
  { postalCode: '43239', city: 'VARBERG' },
  { postalCode: '43240', city: 'VARBERG' },
  { postalCode: '43241', city: 'VARBERG' },
  { postalCode: '43242', city: 'VARBERG' },
  { postalCode: '43243', city: 'VARBERG' },
  { postalCode: '43244', city: 'VARBERG' },
  { postalCode: '43245', city: 'VARBERG' },
  { postalCode: '43246', city: 'VARBERG' },
  { postalCode: '43247', city: 'VARBERG' },
  { postalCode: '43248', city: 'VARBERG' },
  { postalCode: '43250', city: 'VARBERG' },
  { postalCode: '43251', city: 'VARBERG' },
  { postalCode: '43252', city: 'VARBERG' },
  { postalCode: '43253', city: 'VARBERG' },
  { postalCode: '43254', city: 'VARBERG' },
  { postalCode: '43255', city: 'VARBERG' },
  { postalCode: '43256', city: 'VARBERG' },
  { postalCode: '43257', city: 'VARBERG' },
  { postalCode: '43263', city: 'BUA' },
  { postalCode: '43264', city: 'BUA' },
  { postalCode: '43265', city: 'VÄRÖBACKA' },
  { postalCode: '43266', city: 'VEDDIGE' },
  { postalCode: '43267', city: 'VEDDIGE' },
  { postalCode: '43268', city: 'VEDDIGE' },
  { postalCode: '43269', city: 'KARL GUSTAV' },
  { postalCode: '43274', city: 'TRÄSLÖVSLÄGE' },
  { postalCode: '43275', city: 'TRÄSLÖVSLÄGE' },
  { postalCode: '43276', city: 'TVÅÅKER' },
  { postalCode: '43277', city: 'TVÅÅKER' },
  { postalCode: '43278', city: 'TVÅÅKER' },
  { postalCode: '43279', city: 'TVÅÅKER' },
  { postalCode: '43280', city: 'VARBERG' },
  { postalCode: '43281', city: 'VARBERG' },
  { postalCode: '43282', city: 'VARBERG' },
  { postalCode: '43284', city: 'VARBERG' },
  { postalCode: '43285', city: 'VÄRÖBACKA' },
  { postalCode: '43286', city: 'VÄRÖBACKA' },
  { postalCode: '43287', city: 'VEDDIGE' },
  { postalCode: '43289', city: 'VARBERG' },
  { postalCode: '43291', city: 'VARBERG' },
  { postalCode: '43292', city: 'VARBERG' },
  { postalCode: '43293', city: 'VARBERG' },
  { postalCode: '43294', city: 'VARBERG' },
  { postalCode: '43295', city: 'VARBERG' },
  { postalCode: '43296', city: 'ÅSKLOSTER' },
  { postalCode: '43297', city: 'ROLFSTORP' },
  { postalCode: '43298', city: 'GRIMETON' },
  { postalCode: '43299', city: 'SKÄLLINGE' },
  { postalCode: '43300', city: 'PARTILLE' },
  { postalCode: '43302', city: 'SÄVEDALEN' },
  { postalCode: '43320', city: 'PARTILLE' },
  { postalCode: '43321', city: 'PARTILLE' },
  { postalCode: '43322', city: 'PARTILLE' },
  { postalCode: '43323', city: 'PARTILLE' },
  { postalCode: '43324', city: 'PARTILLE' },
  { postalCode: '43325', city: 'PARTILLE' },
  { postalCode: '43326', city: 'PARTILLE' },
  { postalCode: '43328', city: 'PARTILLE' },
  { postalCode: '43330', city: 'PARTILLE' },
  { postalCode: '43331', city: 'PARTILLE' },
  { postalCode: '43332', city: 'PARTILLE' },
  { postalCode: '43333', city: 'PARTILLE' },
  { postalCode: '43334', city: 'PARTILLE' },
  { postalCode: '43335', city: 'PARTILLE' },
  { postalCode: '43336', city: 'PARTILLE' },
  { postalCode: '43337', city: 'PARTILLE' },
  { postalCode: '43338', city: 'PARTILLE' },
  { postalCode: '43339', city: 'PARTILLE' },
  { postalCode: '43341', city: 'PARTILLE' },
  { postalCode: '43342', city: 'PARTILLE' },
  { postalCode: '43343', city: 'PARTILLE' },
  { postalCode: '43344', city: 'PARTILLE' },
  { postalCode: '43345', city: 'PARTILLE' },
  { postalCode: '43346', city: 'PARTILLE' },
  { postalCode: '43347', city: 'PARTILLE' },
  { postalCode: '43348', city: 'PARTILLE' },
  { postalCode: '43349', city: 'PARTILLE' },
  { postalCode: '43350', city: 'ÖJERSJÖ' },
  { postalCode: '43351', city: 'ÖJERSJÖ' },
  { postalCode: '43352', city: 'ÖJERSJÖ' },
  { postalCode: '43353', city: 'ÖJERSJÖ' },
  { postalCode: '43360', city: 'SÄVEDALEN' },
  { postalCode: '43361', city: 'SÄVEDALEN' },
  { postalCode: '43362', city: 'SÄVEDALEN' },
  { postalCode: '43363', city: 'SÄVEDALEN' },
  { postalCode: '43364', city: 'SÄVEDALEN' },
  { postalCode: '43365', city: 'SÄVEDALEN' },
  { postalCode: '43366', city: 'SÄVEDALEN' },
  { postalCode: '43367', city: 'SÄVEDALEN' },
  { postalCode: '43368', city: 'SÄVEDALEN' },
  { postalCode: '43369', city: 'SÄVEDALEN' },
  { postalCode: '43370', city: 'SÄVEDALEN' },
  { postalCode: '43375', city: 'JONSERED' },
  { postalCode: '43376', city: 'JONSERED' },
  { postalCode: '43381', city: 'PARTILLE' },
  { postalCode: '43382', city: 'PARTILLE' },
  { postalCode: '43383', city: 'PARTILLE' },
  { postalCode: '43384', city: 'PARTILLE' },
  { postalCode: '43386', city: 'PARTILLE' },
  { postalCode: '43400', city: 'KUNGSBACKA' },
  { postalCode: '43420', city: 'KUNGSBACKA' },
  { postalCode: '43421', city: 'KUNGSBACKA' },
  { postalCode: '43422', city: 'KUNGSBACKA' },
  { postalCode: '43423', city: 'KUNGSBACKA' },
  { postalCode: '43424', city: 'KUNGSBACKA' },
  { postalCode: '43430', city: 'KUNGSBACKA' },
  { postalCode: '43431', city: 'KUNGSBACKA' },
  { postalCode: '43432', city: 'KUNGSBACKA' },
  { postalCode: '43433', city: 'KUNGSBACKA' },
  { postalCode: '43434', city: 'KUNGSBACKA' },
  { postalCode: '43435', city: 'KUNGSBACKA' },
  { postalCode: '43436', city: 'KUNGSBACKA' },
  { postalCode: '43437', city: 'KUNGSBACKA' },
  { postalCode: '43439', city: 'KUNGSBACKA' },
  { postalCode: '43440', city: 'KUNGSBACKA' },
  { postalCode: '43441', city: 'KUNGSBACKA' },
  { postalCode: '43442', city: 'KUNGSBACKA' },
  { postalCode: '43443', city: 'KUNGSBACKA' },
  { postalCode: '43444', city: 'KUNGSBACKA' },
  { postalCode: '43445', city: 'KUNGSBACKA' },
  { postalCode: '43446', city: 'KUNGSBACKA' },
  { postalCode: '43447', city: 'KUNGSBACKA' },
  { postalCode: '43448', city: 'KUNGSBACKA' },
  { postalCode: '43450', city: 'KUNGSBACKA' },
  { postalCode: '43451', city: 'KUNGSBACKA' },
  { postalCode: '43452', city: 'KUNGSBACKA' },
  { postalCode: '43460', city: 'KUNGSBACKA' },
  { postalCode: '43479', city: 'VALLDA' },
  { postalCode: '43480', city: 'KUNGSBACKA' },
  { postalCode: '43481', city: 'KUNGSBACKA' },
  { postalCode: '43490', city: 'VALLDA' },
  { postalCode: '43491', city: 'KUNGSBACKA' },
  { postalCode: '43492', city: 'VALLDA' },
  { postalCode: '43493', city: 'VALLDA' },
  { postalCode: '43494', city: 'VALLDA' },
  { postalCode: '43495', city: 'KUNGSBACKA' },
  { postalCode: '43496', city: 'KUNGSBACKA' },
  { postalCode: '43497', city: 'KUNGSBACKA' },
  { postalCode: '43498', city: 'KUNGSBACKA' },
  { postalCode: '43499', city: 'KUNGSBACKA' },
  { postalCode: '43500', city: 'MÖLNLYCKE' },
  { postalCode: '43501', city: 'MÖLNLYCKE' },
  { postalCode: '43520', city: 'MÖLNLYCKE' },
  { postalCode: '43521', city: 'MÖLNLYCKE' },
  { postalCode: '43522', city: 'MÖLNLYCKE' },
  { postalCode: '43523', city: 'MÖLNLYCKE' },
  { postalCode: '43524', city: 'MÖLNLYCKE' },
  { postalCode: '43525', city: 'MÖLNLYCKE' },
  { postalCode: '43530', city: 'MÖLNLYCKE' },
  { postalCode: '43531', city: 'MÖLNLYCKE' },
  { postalCode: '43532', city: 'MÖLNLYCKE' },
  { postalCode: '43533', city: 'MÖLNLYCKE' },
  { postalCode: '43535', city: 'MÖLNLYCKE' },
  { postalCode: '43537', city: 'MÖLNLYCKE' },
  { postalCode: '43538', city: 'MÖLNLYCKE' },
  { postalCode: '43539', city: 'MÖLNLYCKE' },
  { postalCode: '43540', city: 'MÖLNLYCKE' },
  { postalCode: '43541', city: 'MÖLNLYCKE' },
  { postalCode: '43542', city: 'MÖLNLYCKE' },
  { postalCode: '43543', city: 'PIXBO' },
  { postalCode: '43544', city: 'MÖLNLYCKE' },
  { postalCode: '43580', city: 'MÖLNLYCKE' },
  { postalCode: '43582', city: 'MÖLNLYCKE' },
  { postalCode: '43583', city: 'MÖLNLYCKE' },
  { postalCode: '43587', city: 'MÖLNLYCKE' },
  { postalCode: '43588', city: 'MÖLNLYCKE' },
  { postalCode: '43600', city: 'ASKIM' },
  { postalCode: '43602', city: 'HOVÅS' },
  { postalCode: '43620', city: 'ASKIM' },
  { postalCode: '43621', city: 'ASKIM' },
  { postalCode: '43622', city: 'ASKIM' },
  { postalCode: '43623', city: 'ASKIM' },
  { postalCode: '43632', city: 'ASKIM' },
  { postalCode: '43633', city: 'ASKIM' },
  { postalCode: '43634', city: 'ASKIM' },
  { postalCode: '43635', city: 'ASKIM' },
  { postalCode: '43636', city: 'ASKIM' },
  { postalCode: '43637', city: 'ASKIM' },
  { postalCode: '43638', city: 'ASKIM' },
  { postalCode: '43639', city: 'ASKIM' },
  { postalCode: '43640', city: 'ASKIM' },
  { postalCode: '43642', city: 'ASKIM' },
  { postalCode: '43643', city: 'ASKIM' },
  { postalCode: '43644', city: 'ASKIM' },
  { postalCode: '43645', city: 'ASKIM' },
  { postalCode: '43650', city: 'HOVÅS' },
  { postalCode: '43651', city: 'HOVÅS' },
  { postalCode: '43652', city: 'HOVÅS' },
  { postalCode: '43653', city: 'HOVÅS' },
  { postalCode: '43654', city: 'HOVÅS' },
  { postalCode: '43655', city: 'HOVÅS' },
  { postalCode: '43656', city: 'HOVÅS' },
  { postalCode: '43657', city: 'HOVÅS' },
  { postalCode: '43658', city: 'HOVÅS' },
  { postalCode: '43700', city: 'LINDOME' },
  { postalCode: '43720', city: 'LINDOME' },
  { postalCode: '43721', city: 'LINDOME' },
  { postalCode: '43722', city: 'LINDOME' },
  { postalCode: '43730', city: 'LINDOME' },
  { postalCode: '43731', city: 'LINDOME' },
  { postalCode: '43732', city: 'LINDOME' },
  { postalCode: '43733', city: 'LINDOME' },
  { postalCode: '43734', city: 'LINDOME' },
  { postalCode: '43735', city: 'LINDOME' },
  { postalCode: '43736', city: 'LINDOME' },
  { postalCode: '43738', city: 'LINDOME' },
  { postalCode: '43740', city: 'LINDOME' },
  { postalCode: '43741', city: 'LINDOME' },
  { postalCode: '43742', city: 'LINDOME' },
  { postalCode: '43791', city: 'LINDOME' },
  { postalCode: '43792', city: 'LINDOME' },
  { postalCode: '43793', city: 'LINDOME' },
  { postalCode: '43805', city: 'HINDÅS' },
  { postalCode: '43807', city: 'HÄRRYDA' },
  { postalCode: '43808', city: 'HÄRRYDA' },
  { postalCode: '43809', city: 'HÄRRYDA' },
  { postalCode: '43811', city: 'LANDVETTER' },
  { postalCode: '43812', city: 'LANDVETTER' },
  { postalCode: '43813', city: 'LANDVETTER' },
  { postalCode: '43814', city: 'LANDVETTER' },
  { postalCode: '43820', city: 'LANDVETTER' },
  { postalCode: '43821', city: 'LANDVETTER' },
  { postalCode: '43822', city: 'LANDVETTER' },
  { postalCode: '43823', city: 'LANDVETTER' },
  { postalCode: '43832', city: 'LANDVETTER' },
  { postalCode: '43833', city: 'LANDVETTER' },
  { postalCode: '43834', city: 'LANDVETTER' },
  { postalCode: '43835', city: 'LANDVETTER' },
  { postalCode: '43836', city: 'LANDVETTER' },
  { postalCode: '43838', city: 'LANDVETTER' },
  { postalCode: '43839', city: 'LANDVETTER' },
  { postalCode: '43851', city: 'RÄVLANDA' },
  { postalCode: '43852', city: 'RÄVLANDA' },
  { postalCode: '43853', city: 'HINDÅS' },
  { postalCode: '43854', city: 'HINDÅS' },
  { postalCode: '43870', city: 'LANDVETTER' },
  { postalCode: '43880', city: 'LANDVETTER' },
  { postalCode: '43881', city: 'LANDVETTER' },
  { postalCode: '43891', city: 'LANDVETTER' },
  { postalCode: '43892', city: 'HÄRRYDA' },
  { postalCode: '43893', city: 'LANDVETTER' },
  { postalCode: '43894', city: 'HÄRRYDA' },
  { postalCode: '43895', city: 'HÄLLINGSJÖ' },
  { postalCode: '43896', city: 'HÄLLINGSJÖ' },
  { postalCode: '43897', city: 'RÄVLANDA' },
  { postalCode: '43898', city: 'HINDÅS' },
  { postalCode: '43900', city: 'ONSALA' },
  { postalCode: '43903', city: 'FJÄRÅS' },
  { postalCode: '43905', city: 'ÅSA' },
  { postalCode: '43908', city: 'FRILLESÅS' },
  { postalCode: '43921', city: 'ONSALA' },
  { postalCode: '43930', city: 'ONSALA' },
  { postalCode: '43931', city: 'ONSALA' },
  { postalCode: '43932', city: 'ONSALA' },
  { postalCode: '43933', city: 'ONSALA' },
  { postalCode: '43934', city: 'ONSALA' },
  { postalCode: '43935', city: 'ONSALA' },
  { postalCode: '43936', city: 'ONSALA' },
  { postalCode: '43937', city: 'ONSALA' },
  { postalCode: '43951', city: 'ÅSA' },
  { postalCode: '43952', city: 'ÅSA' },
  { postalCode: '43953', city: 'ÅSA' },
  { postalCode: '43954', city: 'ÅSA' },
  { postalCode: '43955', city: 'ÅSA' },
  { postalCode: '43961', city: 'FRILLESÅS' },
  { postalCode: '43962', city: 'FRILLESÅS' },
  { postalCode: '43963', city: 'FRILLESÅS' },
  { postalCode: '43964', city: 'FRILLESÅS' },
  { postalCode: '43965', city: 'STRÅVALLA' },
  { postalCode: '43971', city: 'FJÄRÅS' },
  { postalCode: '43972', city: 'FJÄRÅS' },
  { postalCode: '43973', city: 'FJÄRÅS' },
  { postalCode: '43974', city: 'FJÄRÅS' },
  { postalCode: '43975', city: 'FJÄRÅS' },
  { postalCode: '43991', city: 'ONSALA' },
  { postalCode: '43992', city: 'ONSALA' },
  { postalCode: '43993', city: 'ONSALA' },
  { postalCode: '43994', city: 'ONSALA' },
  { postalCode: '44100', city: 'ALINGSÅS' },
  { postalCode: '44111', city: 'SOLLEBRUNN' },
  { postalCode: '44115', city: 'ALINGSÅS' },
  { postalCode: '44116', city: 'ALINGSÅS' },
  { postalCode: '44117', city: 'ALINGSÅS' },
  { postalCode: '44118', city: 'ALINGSÅS' },
  { postalCode: '44120', city: 'ALINGSÅS' },
  { postalCode: '44121', city: 'ALINGSÅS' },
  { postalCode: '44122', city: 'ALINGSÅS' },
  { postalCode: '44123', city: 'ALINGSÅS' },
  { postalCode: '44124', city: 'ALINGSÅS' },
  { postalCode: '44125', city: 'ALINGSÅS' },
  { postalCode: '44126', city: 'ALINGSÅS' },
  { postalCode: '44127', city: 'ALINGSÅS' },
  { postalCode: '44128', city: 'ALINGSÅS' },
  { postalCode: '44129', city: 'ALINGSÅS' },
  { postalCode: '44130', city: 'ALINGSÅS' },
  { postalCode: '44131', city: 'ALINGSÅS' },
  { postalCode: '44132', city: 'ALINGSÅS' },
  { postalCode: '44133', city: 'ALINGSÅS' },
  { postalCode: '44134', city: 'ALINGSÅS' },
  { postalCode: '44135', city: 'ALINGSÅS' },
  { postalCode: '44136', city: 'ALINGSÅS' },
  { postalCode: '44137', city: 'ALINGSÅS' },
  { postalCode: '44138', city: 'ALINGSÅS' },
  { postalCode: '44139', city: 'ALINGSÅS' },
  { postalCode: '44140', city: 'ALINGSÅS' },
  { postalCode: '44141', city: 'ALINGSÅS' },
  { postalCode: '44142', city: 'ALINGSÅS' },
  { postalCode: '44143', city: 'ALINGSÅS' },
  { postalCode: '44144', city: 'ALINGSÅS' },
  { postalCode: '44145', city: 'ALINGSÅS' },
  { postalCode: '44146', city: 'ALINGSÅS' },
  { postalCode: '44150', city: 'ALINGSÅS' },
  { postalCode: '44151', city: 'ALINGSÅS' },
  { postalCode: '44155', city: 'ALINGSÅS' },
  { postalCode: '44156', city: 'ALINGSÅS' },
  { postalCode: '44157', city: 'ALINGSÅS' },
  { postalCode: '44158', city: 'ALINGSÅS' },
  { postalCode: '44160', city: 'ALINGSÅS' },
  { postalCode: '44163', city: 'ALINGSÅS' },
  { postalCode: '44165', city: 'ALINGSÅS' },
  { postalCode: '44170', city: 'SOLLEBRUNN' },
  { postalCode: '44171', city: 'SOLLEBRUNN' },
  { postalCode: '44172', city: 'SOLLEBRUNN' },
  { postalCode: '44173', city: 'SOLLEBRUNN' },
  { postalCode: '44174', city: 'SOLLEBRUNN' },
  { postalCode: '44175', city: 'SOLLEBRUNN' },
  { postalCode: '44180', city: 'ALINGSÅS' },
  { postalCode: '44181', city: 'ALINGSÅS' },
  { postalCode: '44183', city: 'ALINGSÅS' },
  { postalCode: '44184', city: 'ALINGSÅS' },
  { postalCode: '44185', city: 'ALINGSÅS' },
  { postalCode: '44186', city: 'ALINGSÅS' },
  { postalCode: '44191', city: 'ALINGSÅS' },
  { postalCode: '44192', city: 'ALINGSÅS' },
  { postalCode: '44193', city: 'ALINGSÅS' },
  { postalCode: '44194', city: 'ALINGSÅS' },
  { postalCode: '44195', city: 'ALINGSÅS' },
  { postalCode: '44196', city: 'ALINGSÅS' },
  { postalCode: '44200', city: 'KUNGÄLV' },
  { postalCode: '44202', city: 'YTTERBY' },
  { postalCode: '44210', city: 'KUNGÄLV' },
  { postalCode: '44211', city: 'KUNGÄLV' },
  { postalCode: '44215', city: 'KUNGÄLV' },
  { postalCode: '44216', city: 'KUNGÄLV' },
  { postalCode: '44217', city: 'KUNGÄLV' },
  { postalCode: '44218', city: 'KUNGÄLV' },
  { postalCode: '44219', city: 'MARSTRAND' },
  { postalCode: '44220', city: 'KUNGÄLV' },
  { postalCode: '44230', city: 'KUNGÄLV' },
  { postalCode: '44231', city: 'KUNGÄLV' },
  { postalCode: '44232', city: 'KUNGÄLV' },
  { postalCode: '44233', city: 'KUNGÄLV' },
  { postalCode: '44234', city: 'KUNGÄLV' },
  { postalCode: '44235', city: 'KUNGÄLV' },
  { postalCode: '44236', city: 'KUNGÄLV' },
  { postalCode: '44237', city: 'KUNGÄLV' },
  { postalCode: '44238', city: 'KUNGÄLV' },
  { postalCode: '44239', city: 'KUNGÄLV' },
  { postalCode: '44240', city: 'KUNGÄLV' },
  { postalCode: '44241', city: 'KUNGÄLV' },
  { postalCode: '44242', city: 'KUNGÄLV' },
  { postalCode: '44243', city: 'KUNGÄLV' },
  { postalCode: '44244', city: 'KUNGÄLV' },
  { postalCode: '44245', city: 'KUNGÄLV' },
  { postalCode: '44246', city: 'KAREBY' },
  { postalCode: '44247', city: 'KUNGÄLV' },
  { postalCode: '44248', city: 'KUNGÄLV' },
  { postalCode: '44249', city: 'KUNGÄLV' },
  { postalCode: '44250', city: 'YTTERBY' },
  { postalCode: '44251', city: 'YTTERBY' },
  { postalCode: '44252', city: 'YTTERBY' },
  { postalCode: '44253', city: 'YTTERBY' },
  { postalCode: '44254', city: 'YTTERBY' },
  { postalCode: '44255', city: 'YTTERBY' },
  { postalCode: '44260', city: 'KODE' },
  { postalCode: '44265', city: 'MARSTRAND' },
  { postalCode: '44266', city: 'MARSTRAND' },
  { postalCode: '44267', city: 'MARSTRAND' },
  { postalCode: '44270', city: 'KÄRNA' },
  { postalCode: '44271', city: 'KÄRNA' },
  { postalCode: '44272', city: 'KÄRNA' },
  { postalCode: '44273', city: 'KÄRNA' },
  { postalCode: '44274', city: 'HARESTAD' },
  { postalCode: '44275', city: 'LYCKE' },
  { postalCode: '44277', city: 'ROMELANDA' },
  { postalCode: '44280', city: 'KUNGÄLV' },
  { postalCode: '44281', city: 'KUNGÄLV' },
  { postalCode: '44282', city: 'KUNGÄLV' },
  { postalCode: '44283', city: 'KUNGÄLV' },
  { postalCode: '44284', city: 'KUNGÄLV' },
  { postalCode: '44290', city: 'KUNGÄLV' },
  { postalCode: '44291', city: 'ROMELANDA' },
  { postalCode: '44292', city: 'ROMELANDA' },
  { postalCode: '44293', city: 'KAREBY' },
  { postalCode: '44294', city: 'YTTERBY' },
  { postalCode: '44295', city: 'HÅLTA' },
  { postalCode: '44296', city: 'KODE' },
  { postalCode: '44297', city: 'KODE' },
  { postalCode: '44298', city: 'KODE' },
  { postalCode: '44300', city: 'STENKULLEN' },
  { postalCode: '44303', city: 'LERUM' },
  { postalCode: '44311', city: 'GRÅBO' },
  { postalCode: '44312', city: 'GRÅBO' },
  { postalCode: '44313', city: 'GRÅBO' },
  { postalCode: '44315', city: 'GRÅBO' },
  { postalCode: '44320', city: 'LERUM' },
  { postalCode: '44321', city: 'LERUM' },
  { postalCode: '44322', city: 'LERUM' },
  { postalCode: '44323', city: 'LERUM' },
  { postalCode: '44324', city: 'LERUM' },
  { postalCode: '44325', city: 'LERUM' },
  { postalCode: '44330', city: 'LERUM' },
  { postalCode: '44331', city: 'LERUM' },
  { postalCode: '44332', city: 'LERUM' },
  { postalCode: '44333', city: 'LERUM' },
  { postalCode: '44334', city: 'LERUM' },
  { postalCode: '44335', city: 'LERUM' },
  { postalCode: '44336', city: 'LERUM' },
  { postalCode: '44338', city: 'LERUM' },
  { postalCode: '44339', city: 'LERUM' },
  { postalCode: '44340', city: 'GRÅBO' },
  { postalCode: '44341', city: 'GRÅBO' },
  { postalCode: '44342', city: 'GRÅBO' },
  { postalCode: '44343', city: 'GRÅBO' },
  { postalCode: '44344', city: 'GRÅBO' },
  { postalCode: '44345', city: 'SJÖVIK' },
  { postalCode: '44350', city: 'LERUM' },
  { postalCode: '44351', city: 'LERUM' },
  { postalCode: '44360', city: 'STENKULLEN' },
  { postalCode: '44361', city: 'STENKULLEN' },
  { postalCode: '44370', city: 'GRÅBO' },
  { postalCode: '44371', city: 'GRÅBO' },
  { postalCode: '44372', city: 'GRÅBO' },
  { postalCode: '44373', city: 'GRÅBO' },
  { postalCode: '44374', city: 'SJÖVIK' },
  { postalCode: '44380', city: 'LERUM' },
  { postalCode: '44391', city: 'LERUM' },
  { postalCode: '44392', city: 'LERUM' },
  { postalCode: '44395', city: 'STENKULLEN' },
  { postalCode: '44400', city: 'STENUNGSUND' },
  { postalCode: '44402', city: 'STORA HÖGA' },
  { postalCode: '44419', city: 'STENUNGSUND' },
  { postalCode: '44420', city: 'STENUNGSUND' },
  { postalCode: '44421', city: 'STENUNGSUND' },
  { postalCode: '44422', city: 'STENUNGSUND' },
  { postalCode: '44423', city: 'STENUNGSUND' },
  { postalCode: '44424', city: 'STENUNGSUND' },
  { postalCode: '44428', city: 'STENUNGSUND' },
  { postalCode: '44430', city: 'STENUNGSUND' },
  { postalCode: '44431', city: 'STENUNGSUND' },
  { postalCode: '44432', city: 'STENUNGSUND' },
  { postalCode: '44440', city: 'STENUNGSUND' },
  { postalCode: '44441', city: 'STENUNGSUND' },
  { postalCode: '44442', city: 'STENUNGSUND' },
  { postalCode: '44443', city: 'STENUNGSUND' },
  { postalCode: '44444', city: 'STENUNGSUND' },
  { postalCode: '44445', city: 'STENUNGSUND' },
  { postalCode: '44446', city: 'STENUNGSUND' },
  { postalCode: '44447', city: 'STENUNGSUND' },
  { postalCode: '44448', city: 'STENUNGSUND' },
  { postalCode: '44452', city: 'STENUNGSUND' },
  { postalCode: '44453', city: 'STENUNGSUND' },
  { postalCode: '44454', city: 'STENUNGSUND' },
  { postalCode: '44455', city: 'STENUNGSUND' },
  { postalCode: '44460', city: 'STORA HÖGA' },
  { postalCode: '44461', city: 'STORA HÖGA' },
  { postalCode: '44465', city: 'JÖRLANDA' },
  { postalCode: '44482', city: 'STENUNGSUND' },
  { postalCode: '44483', city: 'STENUNGSUND' },
  { postalCode: '44484', city: 'STENUNGSUND' },
  { postalCode: '44485', city: 'STENUNGSUND' },
  { postalCode: '44486', city: 'STENUNGSUND' },
  { postalCode: '44491', city: 'STENUNGSUND' },
  { postalCode: '44492', city: 'JÖRLANDA' },
  { postalCode: '44493', city: 'SPEKERÖD' },
  { postalCode: '44494', city: 'UCKLUM' },
  { postalCode: '44495', city: 'ÖDSMÅL' },
  { postalCode: '44496', city: 'ÖDSMÅL' },
  { postalCode: '44497', city: 'SVENSHÖGEN' },
  { postalCode: '44502', city: 'SURTE' },
  { postalCode: '44521', city: 'BOHUS' },
  { postalCode: '44522', city: 'BOHUS' },
  { postalCode: '44523', city: 'BOHUS' },
  { postalCode: '44532', city: 'BOHUS' },
  { postalCode: '44533', city: 'BOHUS' },
  { postalCode: '44534', city: 'BOHUS' },
  { postalCode: '44536', city: 'BOHUS' },
  { postalCode: '44537', city: 'BOHUS' },
  { postalCode: '44552', city: 'SURTE' },
  { postalCode: '44555', city: 'SURTE' },
  { postalCode: '44556', city: 'SURTE' },
  { postalCode: '44557', city: 'SURTE' },
  { postalCode: '44580', city: 'BOHUS' },
  { postalCode: '44620', city: 'ÄLVÄNGEN' },
  { postalCode: '44621', city: 'ÄLVÄNGEN' },
  { postalCode: '44622', city: 'ÄLVÄNGEN' },
  { postalCode: '44623', city: 'ÄLVÄNGEN' },
  { postalCode: '44632', city: 'ÄLVÄNGEN' },
  { postalCode: '44633', city: 'ÄLVÄNGEN' },
  { postalCode: '44634', city: 'ÄLVÄNGEN' },
  { postalCode: '44635', city: 'ÄLVÄNGEN' },
  { postalCode: '44636', city: 'ÄLVÄNGEN' },
  { postalCode: '44637', city: 'ÄLVÄNGEN' },
  { postalCode: '44638', city: 'ÄLVÄNGEN' },
  { postalCode: '44640', city: 'SKEPPLANDA' },
  { postalCode: '44641', city: 'SKEPPLANDA' },
  { postalCode: '44691', city: 'ALVHEM' },
  { postalCode: '44692', city: 'SKEPPLANDA' },
  { postalCode: '44693', city: 'SKEPPLANDA' },
  { postalCode: '44694', city: 'SKEPPLANDA' },
  { postalCode: '44695', city: 'ÄLVÄNGEN' },
  { postalCode: '44696', city: 'HÅLANDA' },
  { postalCode: '44697', city: 'ÄLVÄNGEN' },
  { postalCode: '44700', city: 'VÅRGÅRDA' },
  { postalCode: '44720', city: 'VÅRGÅRDA' },
  { postalCode: '44721', city: 'VÅRGÅRDA' },
  { postalCode: '44722', city: 'VÅRGÅRDA' },
  { postalCode: '44723', city: 'VÅRGÅRDA' },
  { postalCode: '44724', city: 'VÅRGÅRDA' },
  { postalCode: '44730', city: 'VÅRGÅRDA' },
  { postalCode: '44731', city: 'VÅRGÅRDA' },
  { postalCode: '44732', city: 'VÅRGÅRDA' },
  { postalCode: '44733', city: 'VÅRGÅRDA' },
  { postalCode: '44734', city: 'VÅRGÅRDA' },
  { postalCode: '44735', city: 'VÅRGÅRDA' },
  { postalCode: '44737', city: 'VÅRGÅRDA' },
  { postalCode: '44780', city: 'VÅRGÅRDA' },
  { postalCode: '44781', city: 'VÅRGÅRDA' },
  { postalCode: '44782', city: 'VÅRGÅRDA' },
  { postalCode: '44783', city: 'VÅRGÅRDA' },
  { postalCode: '44784', city: 'VÅRGÅRDA' },
  { postalCode: '44791', city: 'VÅRGÅRDA' },
  { postalCode: '44792', city: 'VÅRGÅRDA' },
  { postalCode: '44793', city: 'VÅRGÅRDA' },
  { postalCode: '44794', city: 'VÅRGÅRDA' },
  { postalCode: '44795', city: 'VÅRGÅRDA' },
  { postalCode: '44820', city: 'FLODA' },
  { postalCode: '44821', city: 'FLODA' },
  { postalCode: '44822', city: 'FLODA' },
  { postalCode: '44830', city: 'FLODA' },
  { postalCode: '44831', city: 'FLODA' },
  { postalCode: '44832', city: 'FLODA' },
  { postalCode: '44833', city: 'FLODA' },
  { postalCode: '44834', city: 'FLODA' },
  { postalCode: '44835', city: 'FLODA' },
  { postalCode: '44836', city: 'FLODA' },
  { postalCode: '44837', city: 'FLODA' },
  { postalCode: '44850', city: 'TOLLERED' },
  { postalCode: '44891', city: 'FLODA' },
  { postalCode: '44892', city: 'FLODA' },
  { postalCode: '44895', city: 'TOLLERED' },
  { postalCode: '44896', city: 'TOLLERED' },
  { postalCode: '44900', city: 'NOL' },
  { postalCode: '44911', city: 'NOL' },
  { postalCode: '44914', city: 'ALAFORS' },
  { postalCode: '44915', city: 'ALAFORS' },
  { postalCode: '44920', city: 'NÖDINGE' },
  { postalCode: '44921', city: 'NÖDINGE' },
  { postalCode: '44922', city: 'NÖDINGE' },
  { postalCode: '44930', city: 'NÖDINGE' },
  { postalCode: '44931', city: 'NÖDINGE' },
  { postalCode: '44932', city: 'NÖDINGE' },
  { postalCode: '44933', city: 'NÖDINGE' },
  { postalCode: '44934', city: 'NÖDINGE' },
  { postalCode: '44935', city: 'NÖDINGE' },
  { postalCode: '44936', city: 'NÖDINGE' },
  { postalCode: '44940', city: 'NOL' },
  { postalCode: '44941', city: 'NOL' },
  { postalCode: '44942', city: 'NOL' },
  { postalCode: '44943', city: 'NOL' },
  { postalCode: '44944', city: 'NOL' },
  { postalCode: '44950', city: 'ALAFORS' },
  { postalCode: '44951', city: 'ALAFORS' },
  { postalCode: '44970', city: 'NÖDINGE' },
  { postalCode: '44980', city: 'ALAFORS' },
  { postalCode: '44990', city: 'NOL' },
  { postalCode: '45101', city: 'UDDEVALLA' },
  { postalCode: '45115', city: 'UDDEVALLA' },
  { postalCode: '45116', city: 'UDDEVALLA' },
  { postalCode: '45117', city: 'UDDEVALLA' },
  { postalCode: '45118', city: 'UDDEVALLA' },
  { postalCode: '45119', city: 'UDDEVALLA' },
  { postalCode: '45120', city: 'UDDEVALLA' },
  { postalCode: '45121', city: 'UDDEVALLA' },
  { postalCode: '45122', city: 'UDDEVALLA' },
  { postalCode: '45123', city: 'UDDEVALLA' },
  { postalCode: '45124', city: 'UDDEVALLA' },
  { postalCode: '45125', city: 'UDDEVALLA' },
  { postalCode: '45126', city: 'UDDEVALLA' },
  { postalCode: '45127', city: 'UDDEVALLA' },
  { postalCode: '45130', city: 'UDDEVALLA' },
  { postalCode: '45131', city: 'UDDEVALLA' },
  { postalCode: '45132', city: 'UDDEVALLA' },
  { postalCode: '45133', city: 'UDDEVALLA' },
  { postalCode: '45134', city: 'UDDEVALLA' },
  { postalCode: '45140', city: 'UDDEVALLA' },
  { postalCode: '45141', city: 'UDDEVALLA' },
  { postalCode: '45142', city: 'UDDEVALLA' },
  { postalCode: '45143', city: 'UDDEVALLA' },
  { postalCode: '45144', city: 'UDDEVALLA' },
  { postalCode: '45145', city: 'UDDEVALLA' },
  { postalCode: '45150', city: 'UDDEVALLA' },
  { postalCode: '45151', city: 'UDDEVALLA' },
  { postalCode: '45152', city: 'UDDEVALLA' },
  { postalCode: '45153', city: 'UDDEVALLA' },
  { postalCode: '45154', city: 'UDDEVALLA' },
  { postalCode: '45155', city: 'UDDEVALLA' },
  { postalCode: '45160', city: 'UDDEVALLA' },
  { postalCode: '45161', city: 'UDDEVALLA' },
  { postalCode: '45162', city: 'UDDEVALLA' },
  { postalCode: '45163', city: 'UDDEVALLA' },
  { postalCode: '45170', city: 'UDDEVALLA' },
  { postalCode: '45171', city: 'UDDEVALLA' },
  { postalCode: '45172', city: 'UDDEVALLA' },
  { postalCode: '45173', city: 'UDDEVALLA' },
  { postalCode: '45175', city: 'UDDEVALLA' },
  { postalCode: '45176', city: 'UDDEVALLA' },
  { postalCode: '45177', city: 'UDDEVALLA' },
  { postalCode: '45178', city: 'FISKEBÄCKSKIL' },
  { postalCode: '45179', city: 'GRUNDSUND' },
  { postalCode: '45180', city: 'UDDEVALLA' },
  { postalCode: '45181', city: 'UDDEVALLA' },
  { postalCode: '45182', city: 'UDDEVALLA' },
  { postalCode: '45183', city: 'UDDEVALLA' },
  { postalCode: '45185', city: 'UDDEVALLA' },
  { postalCode: '45190', city: 'UDDEVALLA' },
  { postalCode: '45191', city: 'UDDEVALLA' },
  { postalCode: '45192', city: 'UDDEVALLA' },
  { postalCode: '45193', city: 'UDDEVALLA' },
  { postalCode: '45194', city: 'UDDEVALLA' },
  { postalCode: '45195', city: 'UDDEVALLA' },
  { postalCode: '45196', city: 'UDDEVALLA' },
  { postalCode: '45197', city: 'UDDEVALLA' },
  { postalCode: '45198', city: 'UDDEVALLA' },
  { postalCode: '45201', city: 'STRÖMSTAD' },
  { postalCode: '45204', city: 'NORDKOSTER' },
  { postalCode: '45205', city: 'SYDKOSTER' },
  { postalCode: '45220', city: 'STRÖMSTAD' },
  { postalCode: '45221', city: 'STRÖMSTAD' },
  { postalCode: '45222', city: 'STRÖMSTAD' },
  { postalCode: '45223', city: 'STRÖMSTAD' },
  { postalCode: '45224', city: 'STRÖMSTAD' },
  { postalCode: '45230', city: 'STRÖMSTAD' },
  { postalCode: '45231', city: 'STRÖMSTAD' },
  { postalCode: '45232', city: 'STRÖMSTAD' },
  { postalCode: '45233', city: 'STRÖMSTAD' },
  { postalCode: '45234', city: 'STRÖMSTAD' },
  { postalCode: '45235', city: 'STRÖMSTAD' },
  { postalCode: '45236', city: 'STRÖMSTAD' },
  { postalCode: '45237', city: 'STRÖMSTAD' },
  { postalCode: '45238', city: 'STRÖMSTAD' },
  { postalCode: '45240', city: 'STRÖMSTAD' },
  { postalCode: '45250', city: 'STRÖMSTAD' },
  { postalCode: '45251', city: 'STRÖMSTAD' },
  { postalCode: '45252', city: 'STRÖMSTAD' },
  { postalCode: '45260', city: 'SKEE' },
  { postalCode: '45270', city: 'STRÖMSTAD' },
  { postalCode: '45271', city: 'STRÖMSTAD' },
  { postalCode: '45280', city: 'STRÖMSTAD' },
  { postalCode: '45283', city: 'STRÖMSTAD' },
  { postalCode: '45290', city: 'STRÖMSTAD' },
  { postalCode: '45291', city: 'STRÖMSTAD' },
  { postalCode: '45292', city: 'STRÖMSTAD' },
  { postalCode: '45293', city: 'STRÖMSTAD' },
  { postalCode: '45294', city: 'STRÖMSTAD' },
  { postalCode: '45295', city: 'STRÖMSTAD' },
  { postalCode: '45296', city: 'STRÖMSTAD' },
  { postalCode: '45297', city: 'STRÖMSTAD' },
  { postalCode: '45318', city: 'LYSEKIL' },
  { postalCode: '45320', city: 'LYSEKIL' },
  { postalCode: '45321', city: 'LYSEKIL' },
  { postalCode: '45322', city: 'LYSEKIL' },
  { postalCode: '45323', city: 'LYSEKIL' },
  { postalCode: '45324', city: 'LYSEKIL' },
  { postalCode: '45329', city: 'LYSEKIL' },
  { postalCode: '45330', city: 'LYSEKIL' },
  { postalCode: '45331', city: 'LYSEKIL' },
  { postalCode: '45332', city: 'LYSEKIL' },
  { postalCode: '45333', city: 'LYSEKIL' },
  { postalCode: '45334', city: 'LYSEKIL' },
  { postalCode: '45335', city: 'LYSEKIL' },
  { postalCode: '45336', city: 'LYSEKIL' },
  { postalCode: '45337', city: 'LYSEKIL' },
  { postalCode: '45338', city: 'LYSEKIL' },
  { postalCode: '45340', city: 'LYSEKIL' },
  { postalCode: '45341', city: 'LYSEKIL' },
  { postalCode: '45350', city: 'LYSEKIL' },
  { postalCode: '45380', city: 'LYSEKIL' },
  { postalCode: '45381', city: 'LYSEKIL' },
  { postalCode: '45391', city: 'LYSEKIL' },
  { postalCode: '45392', city: 'LYSEKIL' },
  { postalCode: '45393', city: 'LYSEKIL' },
  { postalCode: '45420', city: 'BRASTAD' },
  { postalCode: '45421', city: 'BRASTAD' },
  { postalCode: '45430', city: 'BRASTAD' },
  { postalCode: '45431', city: 'BRASTAD' },
  { postalCode: '45432', city: 'BRASTAD' },
  { postalCode: '45480', city: 'BRASTAD' },
  { postalCode: '45491', city: 'BRASTAD' },
  { postalCode: '45492', city: 'BRASTAD' },
  { postalCode: '45493', city: 'BRASTAD' },
  { postalCode: '45494', city: 'BRASTAD' },
  { postalCode: '45495', city: 'BRASTAD' },
  { postalCode: '45500', city: 'MUNKEDAL' },
  { postalCode: '45520', city: 'MUNKEDAL' },
  { postalCode: '45521', city: 'MUNKEDAL' },
  { postalCode: '45530', city: 'MUNKEDAL' },
  { postalCode: '45531', city: 'MUNKEDAL' },
  { postalCode: '45532', city: 'MUNKEDAL' },
  { postalCode: '45533', city: 'MUNKEDAL' },
  { postalCode: '45534', city: 'MUNKEDAL' },
  { postalCode: '45535', city: 'MUNKEDAL' },
  { postalCode: '45541', city: 'HEDEKAS' },
  { postalCode: '45561', city: 'DINGLE' },
  { postalCode: '45580', city: 'MUNKEDAL' },
  { postalCode: '45581', city: 'MUNKEDAL' },
  { postalCode: '45583', city: 'DINGLE' },
  { postalCode: '45591', city: 'MUNKEDAL' },
  { postalCode: '45592', city: 'MUNKEDAL' },
  { postalCode: '45593', city: 'MUNKEDAL' },
  { postalCode: '45595', city: 'HEDEKAS' },
  { postalCode: '45596', city: 'HEDEKAS' },
  { postalCode: '45597', city: 'DINGLE' },
  { postalCode: '45598', city: 'DINGLE' },
  { postalCode: '45601', city: 'KUNGSHAMN' },
  { postalCode: '45620', city: 'KUNGSHAMN' },
  { postalCode: '45621', city: 'KUNGSHAMN' },
  { postalCode: '45622', city: 'KUNGSHAMN' },
  { postalCode: '45623', city: 'KUNGSHAMN' },
  { postalCode: '45625', city: 'SMÖGEN' },
  { postalCode: '45626', city: 'HUNNEBOSTRAND' },
  { postalCode: '45631', city: 'KUNGSHAMN' },
  { postalCode: '45632', city: 'KUNGSHAMN' },
  { postalCode: '45633', city: 'KUNGSHAMN' },
  { postalCode: '45634', city: 'KUNGSHAMN' },
  { postalCode: '45642', city: 'HOVENÄSET' },
  { postalCode: '45643', city: 'VÄJERN' },
  { postalCode: '45644', city: 'VÄJERN' },
  { postalCode: '45647', city: 'BOVALLSTRAND' },
  { postalCode: '45650', city: 'SMÖGEN' },
  { postalCode: '45651', city: 'SMÖGEN' },
  { postalCode: '45655', city: 'BOHUS-MALMÖN' },
  { postalCode: '45660', city: 'HUNNEBOSTRAND' },
  { postalCode: '45661', city: 'HUNNEBOSTRAND' },
  { postalCode: '45662', city: 'HUNNEBOSTRAND' },
  { postalCode: '45680', city: 'KUNGSHAMN' },
  { postalCode: '45681', city: 'KUNGSHAMN' },
  { postalCode: '45691', city: 'KUNGSHAMN' },
  { postalCode: '45692', city: 'HUNNEBOSTRAND' },
  { postalCode: '45693', city: 'HUNNEBOSTRAND' },
  { postalCode: '45694', city: 'HUNNEBOSTRAND' },
  { postalCode: '45700', city: 'TANUMSHEDE' },
  { postalCode: '45702', city: 'GREBBESTAD' },
  { postalCode: '45720', city: 'TANUMSHEDE' },
  { postalCode: '45721', city: 'TANUMSHEDE' },
  { postalCode: '45722', city: 'TANUMSHEDE' },
  { postalCode: '45723', city: 'TANUMSHEDE' },
  { postalCode: '45727', city: 'FJÄLLBACKA' },
  { postalCode: '45730', city: 'TANUMSHEDE' },
  { postalCode: '45731', city: 'TANUMSHEDE' },
  { postalCode: '45732', city: 'TANUMSHEDE' },
  { postalCode: '45740', city: 'FJÄLLBACKA' },
  { postalCode: '45741', city: 'FJÄLLBACKA' },
  { postalCode: '45742', city: 'FJÄLLBACKA' },
  { postalCode: '45743', city: 'FJÄLLBACKA' },
  { postalCode: '45745', city: 'HAMBURGSUND' },
  { postalCode: '45746', city: 'HAMBURGSUND' },
  { postalCode: '45747', city: 'HAMBURGSUND' },
  { postalCode: '45748', city: 'HAMBURGSUND' },
  { postalCode: '45750', city: 'BULLAREN' },
  { postalCode: '45751', city: 'BULLAREN' },
  { postalCode: '45755', city: 'RABBALSHEDE' },
  { postalCode: '45756', city: 'RABBALSHEDE' },
  { postalCode: '45760', city: 'HÄLLEVADSHOLM' },
  { postalCode: '45761', city: 'HÄLLEVADSHOLM' },
  { postalCode: '45772', city: 'GREBBESTAD' },
  { postalCode: '45773', city: 'HAVSTENSSUND' },
  { postalCode: '45781', city: 'TANUMSHEDE' },
  { postalCode: '45782', city: 'TANUMSHEDE' },
  { postalCode: '45791', city: 'TANUMSHEDE' },
  { postalCode: '45793', city: 'TANUMSHEDE' },
  { postalCode: '45795', city: 'GREBBESTAD' },
  { postalCode: '45796', city: 'LUR' },
  { postalCode: '45797', city: 'RESÖ' },
  { postalCode: '45820', city: 'FÄRGELANDA' },
  { postalCode: '45821', city: 'FÄRGELANDA' },
  { postalCode: '45822', city: 'FÄRGELANDA' },
  { postalCode: '45830', city: 'FÄRGELANDA' },
  { postalCode: '45831', city: 'FÄRGELANDA' },
  { postalCode: '45832', city: 'FÄRGELANDA' },
  { postalCode: '45840', city: 'ÖDEBORG' },
  { postalCode: '45870', city: 'HÖGSÄTER' },
  { postalCode: '45880', city: 'FÄRGELANDA' },
  { postalCode: '45881', city: 'FÄRGELANDA' },
  { postalCode: '45891', city: 'FÄRGELANDA' },
  { postalCode: '45892', city: 'FÄRGELANDA' },
  { postalCode: '45893', city: 'FÄRGELANDA' },
  { postalCode: '45894', city: 'STIGEN' },
  { postalCode: '45896', city: 'HÖGSÄTER' },
  { postalCode: '45897', city: 'HÖGSÄTER' },
  { postalCode: '45920', city: 'LJUNGSKILE' },
  { postalCode: '45921', city: 'LJUNGSKILE' },
  { postalCode: '45922', city: 'LJUNGSKILE' },
  { postalCode: '45930', city: 'LJUNGSKILE' },
  { postalCode: '45931', city: 'LJUNGSKILE' },
  { postalCode: '45932', city: 'LJUNGSKILE' },
  { postalCode: '45933', city: 'LJUNGSKILE' },
  { postalCode: '45980', city: 'LJUNGSKILE' },
  { postalCode: '45991', city: 'LJUNGSKILE' },
  { postalCode: '45993', city: 'LJUNGSKILE' },
  { postalCode: '45994', city: 'LJUNGSKILE' },
  { postalCode: '46100', city: 'TROLLHÄTTAN' },
  { postalCode: '46104', city: 'TROLLHÄTTAN' },
  { postalCode: '46106', city: 'TROLLHÄTTAN' },
  { postalCode: '46111', city: 'TROLLHÄTTAN' },
  { postalCode: '46120', city: 'TROLLHÄTTAN' },
  { postalCode: '46121', city: 'TROLLHÄTTAN' },
  { postalCode: '46122', city: 'TROLLHÄTTAN' },
  { postalCode: '46123', city: 'TROLLHÄTTAN' },
  { postalCode: '46124', city: 'TROLLHÄTTAN' },
  { postalCode: '46125', city: 'TROLLHÄTTAN' },
  { postalCode: '46126', city: 'TROLLHÄTTAN' },
  { postalCode: '46127', city: 'TROLLHÄTTAN' },
  { postalCode: '46129', city: 'TROLLHÄTTAN' },
  { postalCode: '46130', city: 'TROLLHÄTTAN' },
  { postalCode: '46131', city: 'TROLLHÄTTAN' },
  { postalCode: '46132', city: 'TROLLHÄTTAN' },
  { postalCode: '46133', city: 'TROLLHÄTTAN' },
  { postalCode: '46134', city: 'TROLLHÄTTAN' },
  { postalCode: '46135', city: 'TROLLHÄTTAN' },
  { postalCode: '46137', city: 'TROLLHÄTTAN' },
  { postalCode: '46138', city: 'TROLLHÄTTAN' },
  { postalCode: '46139', city: 'TROLLHÄTTAN' },
  { postalCode: '46140', city: 'TROLLHÄTTAN' },
  { postalCode: '46141', city: 'TROLLHÄTTAN' },
  { postalCode: '46142', city: 'TROLLHÄTTAN' },
  { postalCode: '46143', city: 'TROLLHÄTTAN' },
  { postalCode: '46144', city: 'TROLLHÄTTAN' },
  { postalCode: '46150', city: 'TROLLHÄTTAN' },
  { postalCode: '46151', city: 'TROLLHÄTTAN' },
  { postalCode: '46152', city: 'TROLLHÄTTAN' },
  { postalCode: '46153', city: 'TROLLHÄTTAN' },
  { postalCode: '46154', city: 'TROLLHÄTTAN' },
  { postalCode: '46155', city: 'TROLLHÄTTAN' },
  { postalCode: '46157', city: 'TROLLHÄTTAN' },
  { postalCode: '46158', city: 'TROLLHÄTTAN' },
  { postalCode: '46159', city: 'TROLLHÄTTAN' },
  { postalCode: '46160', city: 'TROLLHÄTTAN' },
  { postalCode: '46161', city: 'TROLLHÄTTAN' },
  { postalCode: '46162', city: 'TROLLHÄTTAN' },
  { postalCode: '46163', city: 'TROLLHÄTTAN' },
  { postalCode: '46164', city: 'TROLLHÄTTAN' },
  { postalCode: '46165', city: 'TROLLHÄTTAN' },
  { postalCode: '46166', city: 'TROLLHÄTTAN' },
  { postalCode: '46167', city: 'TROLLHÄTTAN' },
  { postalCode: '46168', city: 'TROLLHÄTTAN' },
  { postalCode: '46170', city: 'TROLLHÄTTAN' },
  { postalCode: '46171', city: 'TROLLHÄTTAN' },
  { postalCode: '46173', city: 'TROLLHÄTTAN' },
  { postalCode: '46177', city: 'SJUNTORP' },
  { postalCode: '46178', city: 'SJUNTORP' },
  { postalCode: '46179', city: 'UPPHÄRAD' },
  { postalCode: '46180', city: 'TROLLHÄTTAN' },
  { postalCode: '46181', city: 'TROLLHÄTTAN' },
  { postalCode: '46182', city: 'TROLLHÄTTAN' },
  { postalCode: '46183', city: 'TROLLHÄTTAN' },
  { postalCode: '46184', city: 'TROLLHÄTTAN' },
  { postalCode: '46185', city: 'TROLLHÄTTAN' },
  { postalCode: '46186', city: 'TROLLHÄTTAN' },
  { postalCode: '46188', city: 'TROLLHÄTTAN' },
  { postalCode: '46191', city: 'TROLLHÄTTAN' },
  { postalCode: '46192', city: 'TROLLHÄTTAN' },
  { postalCode: '46193', city: 'VÄSTRA TUNHEM' },
  { postalCode: '46194', city: 'TROLLHÄTTAN' },
  { postalCode: '46195', city: 'TROLLHÄTTAN' },
  { postalCode: '46196', city: 'SJUNTORP' },
  { postalCode: '46197', city: 'SJUNTORP' },
  { postalCode: '46198', city: 'TROLLHÄTTAN' },
  { postalCode: '46199', city: 'UPPHÄRAD' },
  { postalCode: '46200', city: 'VÄNERSBORG' },
  { postalCode: '46220', city: 'VÄNERSBORG' },
  { postalCode: '46221', city: 'VÄNERSBORG' },
  { postalCode: '46222', city: 'VÄNERSBORG' },
  { postalCode: '46223', city: 'VÄNERSBORG' },
  { postalCode: '46224', city: 'VÄNERSBORG' },
  { postalCode: '46228', city: 'VÄNERSBORG' },
  { postalCode: '46230', city: 'VÄNERSBORG' },
  { postalCode: '46231', city: 'VÄNERSBORG' },
  { postalCode: '46232', city: 'VÄNERSBORG' },
  { postalCode: '46233', city: 'VÄNERSBORG' },
  { postalCode: '46234', city: 'VÄNERSBORG' },
  { postalCode: '46235', city: 'VÄNERSBORG' },
  { postalCode: '46236', city: 'VÄNERSBORG' },
  { postalCode: '46237', city: 'VÄNERSBORG' },
  { postalCode: '46238', city: 'VÄNERSBORG' },
  { postalCode: '46240', city: 'VÄNERSBORG' },
  { postalCode: '46241', city: 'VÄNERSBORG' },
  { postalCode: '46242', city: 'VÄNERSBORG' },
  { postalCode: '46250', city: 'VÄNERSBORG' },
  { postalCode: '46251', city: 'VÄNERSBORG' },
  { postalCode: '46252', city: 'VÄNERSBORG' },
  { postalCode: '46253', city: 'VÄNERSBORG' },
  { postalCode: '46254', city: 'VÄNERSBORG' },
  { postalCode: '46255', city: 'VÄNERSBORG' },
  { postalCode: '46256', city: 'VÄNERSBORG' },
  { postalCode: '46260', city: 'VÄNERSBORG' },
  { postalCode: '46261', city: 'VÄNERSBORG' },
  { postalCode: '46269', city: 'FRÄNDEFORS' },
  { postalCode: '46273', city: 'VÄNERSBORG' },
  { postalCode: '46280', city: 'VÄNERSBORG' },
  { postalCode: '46281', city: 'VÄNERSBORG' },
  { postalCode: '46282', city: 'VÄNERSBORG' },
  { postalCode: '46285', city: 'VÄNERSBORG' },
  { postalCode: '46291', city: 'VÄNERSBORG' },
  { postalCode: '46293', city: 'VÄNERSBORG' },
  { postalCode: '46294', city: 'FRÄNDEFORS' },
  { postalCode: '46295', city: 'FRÄNDEFORS' },
  { postalCode: '46296', city: 'FRÄNDEFORS' },
  { postalCode: '46297', city: 'FRÄNDEFORS' },
  { postalCode: '46301', city: 'LILLA EDET' },
  { postalCode: '46320', city: 'LILLA EDET' },
  { postalCode: '46324', city: 'LILLA EDET' },
  { postalCode: '46325', city: 'LILLA EDET' },
  { postalCode: '46330', city: 'LILLA EDET' },
  { postalCode: '46331', city: 'LILLA EDET' },
  { postalCode: '46332', city: 'LILLA EDET' },
  { postalCode: '46333', city: 'LILLA EDET' },
  { postalCode: '46334', city: 'LILLA EDET' },
  { postalCode: '46335', city: 'GÖTA' },
  { postalCode: '46371', city: 'LÖDÖSE' },
  { postalCode: '46372', city: 'LÖDÖSE' },
  { postalCode: '46375', city: 'HJÄRTUM' },
  { postalCode: '46380', city: 'LILLA EDET' },
  { postalCode: '46381', city: 'LILLA EDET' },
  { postalCode: '46383', city: 'LILLA EDET' },
  { postalCode: '46392', city: 'LILLA EDET' },
  { postalCode: '46393', city: 'VÄSTERLANDA' },
  { postalCode: '46394', city: 'LILLA EDET' },
  { postalCode: '46395', city: 'LÖDÖSE' },
  { postalCode: '46396', city: 'NYGÅRD' },
  { postalCode: '46397', city: 'PRÄSSEBO' },
  { postalCode: '46401', city: 'MELLERUD' },
  { postalCode: '46406', city: 'BRÅLANDA' },
  { postalCode: '46420', city: 'MELLERUD' },
  { postalCode: '46421', city: 'MELLERUD' },
  { postalCode: '46422', city: 'MELLERUD' },
  { postalCode: '46423', city: 'MELLERUD' },
  { postalCode: '46430', city: 'MELLERUD' },
  { postalCode: '46431', city: 'MELLERUD' },
  { postalCode: '46432', city: 'MELLERUD' },
  { postalCode: '46433', city: 'MELLERUD' },
  { postalCode: '46434', city: 'MELLERUD' },
  { postalCode: '46440', city: 'ÅSENSBRUK' },
  { postalCode: '46450', city: 'DALS ROSTOCK' },
  { postalCode: '46461', city: 'BRÅLANDA' },
  { postalCode: '46462', city: 'BRÅLANDA' },
  { postalCode: '46463', city: 'BRÅLANDA' },
  { postalCode: '46464', city: 'BRÅLANDA' },
  { postalCode: '46465', city: 'BRÅLANDA' },
  { postalCode: '46466', city: 'MELLERUD' },
  { postalCode: '46471', city: 'KÖPMANNEBRO' },
  { postalCode: '46472', city: 'HÅVERUD' },
  { postalCode: '46480', city: 'MELLERUD' },
  { postalCode: '46491', city: 'DALS ROSTOCK' },
  { postalCode: '46492', city: 'MELLERUD' },
  { postalCode: '46493', city: 'MELLERUD' },
  { postalCode: '46494', city: 'MELLERUD' },
  { postalCode: '46495', city: 'DALSKOG' },
  { postalCode: '46520', city: 'NOSSEBRO' },
  { postalCode: '46521', city: 'NOSSEBRO' },
  { postalCode: '46530', city: 'NOSSEBRO' },
  { postalCode: '46531', city: 'NOSSEBRO' },
  { postalCode: '46580', city: 'NOSSEBRO' },
  { postalCode: '46581', city: 'NOSSEBRO' },
  { postalCode: '46582', city: 'NOSSEBRO' },
  { postalCode: '46591', city: 'NOSSEBRO' },
  { postalCode: '46593', city: 'NOSSEBRO' },
  { postalCode: '46594', city: 'NOSSEBRO' },
  { postalCode: '46595', city: 'NOSSEBRO' },
  { postalCode: '46596', city: 'NOSSEBRO' },
  { postalCode: '46597', city: 'NOSSEBRO' },
  { postalCode: '46598', city: 'NOSSEBRO' },
  { postalCode: '46680', city: 'SOLLEBRUNN' },
  { postalCode: '46701', city: 'GRÄSTORP' },
  { postalCode: '46720', city: 'GRÄSTORP' },
  { postalCode: '46721', city: 'GRÄSTORP' },
  { postalCode: '46722', city: 'GRÄSTORP' },
  { postalCode: '46723', city: 'GRÄSTORP' },
  { postalCode: '46730', city: 'GRÄSTORP' },
  { postalCode: '46731', city: 'GRÄSTORP' },
  { postalCode: '46732', city: 'GRÄSTORP' },
  { postalCode: '46735', city: 'GRÄSTORP' },
  { postalCode: '46740', city: 'GRÄSTORP' },
  { postalCode: '46780', city: 'GRÄSTORP' },
  { postalCode: '46781', city: 'GRÄSTORP' },
  { postalCode: '46784', city: 'GRÄSTORP' },
  { postalCode: '46791', city: 'GRÄSTORP' },
  { postalCode: '46792', city: 'GRÄSTORP' },
  { postalCode: '46793', city: 'GRÄSTORP' },
  { postalCode: '46794', city: 'GRÄSTORP' },
  { postalCode: '46795', city: 'GRÄSTORP' },
  { postalCode: '46796', city: 'GRÄSTORP' },
  { postalCode: '46821', city: 'VARGÖN' },
  { postalCode: '46822', city: 'VARGÖN' },
  { postalCode: '46830', city: 'VARGÖN' },
  { postalCode: '46831', city: 'VARGÖN' },
  { postalCode: '46832', city: 'VARGÖN' },
  { postalCode: '46833', city: 'VARGÖN' },
  { postalCode: '46834', city: 'VARGÖN' },
  { postalCode: '46880', city: 'VARGÖN' },
  { postalCode: '46882', city: 'VARGÖN' },
  { postalCode: '46890', city: 'VÄNERSNÄS' },
  { postalCode: '47110', city: 'RÖNNÄNG' },
  { postalCode: '47111', city: 'RÖNNÄNG' },
  { postalCode: '47116', city: 'MYGGENÄS' },
  { postalCode: '47117', city: 'MYGGENÄS' },
  { postalCode: '47120', city: 'SKÄRHAMN' },
  { postalCode: '47121', city: 'SKÄRHAMN' },
  { postalCode: '47122', city: 'SKÄRHAMN' },
  { postalCode: '47130', city: 'SKÄRHAMN' },
  { postalCode: '47131', city: 'SKÄRHAMN' },
  { postalCode: '47132', city: 'SKÄRHAMN' },
  { postalCode: '47133', city: 'SKÄRHAMN' },
  { postalCode: '47141', city: 'RÖNNÄNG' },
  { postalCode: '47142', city: 'RÖNNÄNG' },
  { postalCode: '47143', city: 'DYRÖN' },
  { postalCode: '47144', city: 'ÅSTOL' },
  { postalCode: '47150', city: 'BLEKET' },
  { postalCode: '47151', city: 'KLÄDESHOLMEN' },
  { postalCode: '47160', city: 'MYGGENÄS' },
  { postalCode: '47161', city: 'MYGGENÄS' },
  { postalCode: '47162', city: 'HÖVIKSNÄS' },
  { postalCode: '47170', city: 'HÖVIKSNÄS' },
  { postalCode: '47171', city: 'HAKENÄSET' },
  { postalCode: '47172', city: 'HJÄLTEBY' },
  { postalCode: '47173', city: 'MJÖRN' },
  { postalCode: '47180', city: 'SKÄRHAMN' },
  { postalCode: '47190', city: 'KYRKESUND' },
  { postalCode: '47191', city: 'KLÖVEDAL' },
  { postalCode: '47192', city: 'KLÖVEDAL' },
  { postalCode: '47193', city: 'KÅLLEKÄRR' },
  { postalCode: '47194', city: 'KÅLLEKÄRR' },
  { postalCode: '47195', city: 'SKÄRHAMN' },
  { postalCode: '47197', city: 'RÖNNÄNG' },
  { postalCode: '47198', city: 'FAGERFJÄLL' },
  { postalCode: '47220', city: 'SVANESUND' },
  { postalCode: '47221', city: 'SVANESUND' },
  { postalCode: '47231', city: 'SVANESUND' },
  { postalCode: '47232', city: 'SVANESUND' },
  { postalCode: '47291', city: 'STILLINGSÖN' },
  { postalCode: '47292', city: 'STILLINGSÖN' },
  { postalCode: '47293', city: 'SVANESUND' },
  { postalCode: '47294', city: 'SVANESUND' },
  { postalCode: '47295', city: 'VAREKIL' },
  { postalCode: '47296', city: 'VAREKIL' },
  { postalCode: '47297', city: 'VAREKIL' },
  { postalCode: '47298', city: 'ASKERÖN' },
  { postalCode: '47300', city: 'HENÅN' },
  { postalCode: '47320', city: 'HENÅN' },
  { postalCode: '47321', city: 'HENÅN' },
  { postalCode: '47322', city: 'HENÅN' },
  { postalCode: '47323', city: 'HENÅN' },
  { postalCode: '47331', city: 'HENÅN' },
  { postalCode: '47332', city: 'HENÅN' },
  { postalCode: '47333', city: 'HENÅN' },
  { postalCode: '47334', city: 'HENÅN' },
  { postalCode: '47380', city: 'HENÅN' },
  { postalCode: '47391', city: 'HENÅN' },
  { postalCode: '47392', city: 'HENÅN' },
  { postalCode: '47393', city: 'HENÅN' },
  { postalCode: '47394', city: 'HENÅN' },
  { postalCode: '47395', city: 'HENÅN' },
  { postalCode: '47396', city: 'HENÅN' },
  { postalCode: '47397', city: 'HENÅN' },
  { postalCode: '47398', city: 'HENÅN' },
  { postalCode: '47399', city: 'HENÅN' },
  { postalCode: '47404', city: 'KÄRINGÖN' },
  { postalCode: '47420', city: 'ELLÖS' },
  { postalCode: '47431', city: 'ELLÖS' },
  { postalCode: '47432', city: 'ELLÖS' },
  { postalCode: '47470', city: 'MOLLÖSUND' },
  { postalCode: '47471', city: 'GULLHOLMEN' },
  { postalCode: '47474', city: 'KÄRINGÖN' },
  { postalCode: '47491', city: 'ELLÖS' },
  { postalCode: '47492', city: 'ELLÖS' },
  { postalCode: '47493', city: 'ELLÖS' },
  { postalCode: '47494', city: 'HÄLLEVIKSSTRAND' },
  { postalCode: '47495', city: 'HÄLLEVIKSSTRAND' },
  { postalCode: '47496', city: 'NÖSUND' },
  { postalCode: '47500', city: 'ÖCKERÖ' },
  { postalCode: '47510', city: 'HÄLSÖ' },
  { postalCode: '47511', city: 'HÄLSÖ' },
  { postalCode: '47512', city: 'HÄLSÖ' },
  { postalCode: '47520', city: 'ÖCKERÖ' },
  { postalCode: '47522', city: 'ÖCKERÖ' },
  { postalCode: '47525', city: 'HÖNÖ' },
  { postalCode: '47531', city: 'ÖCKERÖ' },
  { postalCode: '47532', city: 'ÖCKERÖ' },
  { postalCode: '47535', city: 'GRÖTÖ' },
  { postalCode: '47536', city: 'KALVSUND' },
  { postalCode: '47537', city: 'BOHUS-BJÖRKÖ' },
  { postalCode: '47540', city: 'HÖNÖ' },
  { postalCode: '47541', city: 'HÖNÖ' },
  { postalCode: '47542', city: 'HÖNÖ' },
  { postalCode: '47545', city: 'FOTÖ' },
  { postalCode: '47550', city: 'HÄLSÖ' },
  { postalCode: '47551', city: 'KÄLLÖ-KNIPPLA' },
  { postalCode: '47552', city: 'HYPPELN' },
  { postalCode: '47553', city: 'RÖRÖ' },
  { postalCode: '47580', city: 'ÖCKERÖ' },
  { postalCode: '50100', city: 'BORÅS' },
  { postalCode: '50110', city: 'BORÅS' },
  { postalCode: '50111', city: 'BORÅS' },
  { postalCode: '50112', city: 'BORÅS' },
  { postalCode: '50113', city: 'BORÅS' },
  { postalCode: '50114', city: 'BORÅS' },
  { postalCode: '50115', city: 'BORÅS' },
  { postalCode: '50117', city: 'BORÅS' },
  { postalCode: '50118', city: 'BORÅS' },
  { postalCode: '50177', city: 'BORÅS' },
  { postalCode: '50178', city: 'BORÅS' },
  { postalCode: '50179', city: 'BORÅS' },
  { postalCode: '50180', city: 'BORÅS' },
  { postalCode: '50181', city: 'BORÅS' },
  { postalCode: '50182', city: 'BORÅS' },
  { postalCode: '50184', city: 'BORÅS' },
  { postalCode: '50185', city: 'BORÅS' },
  { postalCode: '50186', city: 'BORÅS' },
  { postalCode: '50187', city: 'BORÅS' },
  { postalCode: '50188', city: 'BORÅS' },
  { postalCode: '50189', city: 'BORÅS' },
  { postalCode: '50190', city: 'BORÅS' },
  { postalCode: '50300', city: 'BORÅS' },
  { postalCode: '50330', city: 'BORÅS' },
  { postalCode: '50331', city: 'BORÅS' },
  { postalCode: '50332', city: 'BORÅS' },
  { postalCode: '50333', city: 'BORÅS' },
  { postalCode: '50334', city: 'BORÅS' },
  { postalCode: '50335', city: 'BORÅS' },
  { postalCode: '50336', city: 'BORÅS' },
  { postalCode: '50337', city: 'BORÅS' },
  { postalCode: '50338', city: 'BORÅS' },
  { postalCode: '50342', city: 'BORÅS' },
  { postalCode: '50343', city: 'BORÅS' },
  { postalCode: '50400', city: 'BORÅS' },
  { postalCode: '50407', city: 'BORÅS' },
  { postalCode: '50408', city: 'BORÅS' },
  { postalCode: '50409', city: 'BORÅS' },
  { postalCode: '50410', city: 'BORÅS' },
  { postalCode: '50416', city: 'BORÅS' },
  { postalCode: '50417', city: 'BORÅS' },
  { postalCode: '50418', city: 'BORÅS' },
  { postalCode: '50419', city: 'BORÅS' },
  { postalCode: '50430', city: 'BORÅS' },
  { postalCode: '50431', city: 'BORÅS' },
  { postalCode: '50432', city: 'BORÅS' },
  { postalCode: '50433', city: 'BORÅS' },
  { postalCode: '50434', city: 'BORÅS' },
  { postalCode: '50435', city: 'BORÅS' },
  { postalCode: '50437', city: 'BORÅS' },
  { postalCode: '50438', city: 'BORÅS' },
  { postalCode: '50439', city: 'BORÅS' },
  { postalCode: '50441', city: 'BORÅS' },
  { postalCode: '50442', city: 'BORÅS' },
  { postalCode: '50443', city: 'BORÅS' },
  { postalCode: '50444', city: 'BORÅS' },
  { postalCode: '50445', city: 'BORÅS' },
  { postalCode: '50446', city: 'BORÅS' },
  { postalCode: '50447', city: 'BORÅS' },
  { postalCode: '50450', city: 'BORÅS' },
  { postalCode: '50451', city: 'BORÅS' },
  { postalCode: '50452', city: 'BORÅS' },
  { postalCode: '50453', city: 'BORÅS' },
  { postalCode: '50454', city: 'BORÅS' },
  { postalCode: '50455', city: 'BORÅS' },
  { postalCode: '50456', city: 'BORÅS' },
  { postalCode: '50457', city: 'BORÅS' },
  { postalCode: '50458', city: 'BORÅS' },
  { postalCode: '50460', city: 'BORÅS' },
  { postalCode: '50461', city: 'BORÅS' },
  { postalCode: '50462', city: 'BORÅS' },
  { postalCode: '50463', city: 'BORÅS' },
  { postalCode: '50464', city: 'BORÅS' },
  { postalCode: '50465', city: 'BORÅS' },
  { postalCode: '50466', city: 'BORÅS' },
  { postalCode: '50467', city: 'BORÅS' },
  { postalCode: '50468', city: 'BORÅS' },
  { postalCode: '50470', city: 'BORÅS' },
  { postalCode: '50471', city: 'BORÅS' },
  { postalCode: '50472', city: 'BORÅS' },
  { postalCode: '50475', city: 'BORÅS' },
  { postalCode: '50478', city: 'SANDHULT' },
  { postalCode: '50480', city: 'BORÅS' },
  { postalCode: '50482', city: 'BORÅS' },
  { postalCode: '50483', city: 'BORÅS' },
  { postalCode: '50490', city: 'BORÅS' },
  { postalCode: '50491', city: 'SANDHULT' },
  { postalCode: '50492', city: 'HEDARED' },
  { postalCode: '50493', city: 'BORÅS' },
  { postalCode: '50494', city: 'BORÅS' },
  { postalCode: '50495', city: 'BORÅS' },
  { postalCode: '50496', city: 'BREDARED' },
  { postalCode: '50497', city: 'BREDARED' },
  { postalCode: '50600', city: 'BORÅS' },
  { postalCode: '50630', city: 'BORÅS' },
  { postalCode: '50631', city: 'BORÅS' },
  { postalCode: '50632', city: 'BORÅS' },
  { postalCode: '50634', city: 'BORÅS' },
  { postalCode: '50635', city: 'BORÅS' },
  { postalCode: '50636', city: 'BORÅS' },
  { postalCode: '50637', city: 'BORÅS' },
  { postalCode: '50638', city: 'BORÅS' },
  { postalCode: '50639', city: 'BORÅS' },
  { postalCode: '50640', city: 'BORÅS' },
  { postalCode: '50641', city: 'BORÅS' },
  { postalCode: '50642', city: 'BORÅS' },
  { postalCode: '50643', city: 'BORÅS' },
  { postalCode: '50644', city: 'BORÅS' },
  { postalCode: '50646', city: 'BORÅS' },
  { postalCode: '50647', city: 'BORÅS' },
  { postalCode: '50648', city: 'BORÅS' },
  { postalCode: '50649', city: 'BORÅS' },
  { postalCode: '50670', city: 'FRUFÄLLAN' },
  { postalCode: '50700', city: 'BORÅS' },
  { postalCode: '50710', city: 'BORÅS' },
  { postalCode: '50711', city: 'BORÅS' },
  { postalCode: '50715', city: 'GÅNGHESTER' },
  { postalCode: '50720', city: 'BRÄMHULT' },
  { postalCode: '50730', city: 'BRÄMHULT' },
  { postalCode: '50731', city: 'BRÄMHULT' },
  { postalCode: '50732', city: 'BRÄMHULT' },
  { postalCode: '50733', city: 'BRÄMHULT' },
  { postalCode: '50734', city: 'BRÄMHULT' },
  { postalCode: '50740', city: 'BORÅS' },
  { postalCode: '50741', city: 'BORÅS' },
  { postalCode: '50742', city: 'BORÅS' },
  { postalCode: '50743', city: 'BORÅS' },
  { postalCode: '50744', city: 'BORÅS' },
  { postalCode: '50745', city: 'BORÅS' },
  { postalCode: '50750', city: 'BORÅS' },
  { postalCode: '50751', city: 'BORÅS' },
  { postalCode: '50752', city: 'BORÅS' },
  { postalCode: '50753', city: 'BORÅS' },
  { postalCode: '50760', city: 'BORÅS' },
  { postalCode: '50761', city: 'BORÅS' },
  { postalCode: '50762', city: 'BORÅS' },
  { postalCode: '50763', city: 'BORÅS' },
  { postalCode: '50764', city: 'BORÅS' },
  { postalCode: '50765', city: 'BORÅS' },
  { postalCode: '50770', city: 'GÅNGHESTER' },
  { postalCode: '50771', city: 'GÅNGHESTER' },
  { postalCode: '50790', city: 'BRÄMHULT' },
  { postalCode: '50800', city: 'BORÅS' },
  { postalCode: '50801', city: 'BORÅS' },
  { postalCode: '50820', city: 'BORÅS' },
  { postalCode: '51100', city: 'SKENE' },
  { postalCode: '51102', city: 'SKENE' },
  { postalCode: '51104', city: 'KINNAHULT' },
  { postalCode: '51110', city: 'FRITSLA' },
  { postalCode: '51120', city: 'KINNA' },
  { postalCode: '51121', city: 'KINNA' },
  { postalCode: '51122', city: 'KINNA' },
  { postalCode: '51123', city: 'KINNA' },
  { postalCode: '51124', city: 'KINNA' },
  { postalCode: '51131', city: 'ÖRBY' },
  { postalCode: '51141', city: 'KINNA' },
  { postalCode: '51142', city: 'KINNAHULT' },
  { postalCode: '51153', city: 'KINNA' },
  { postalCode: '51154', city: 'KINNA' },
  { postalCode: '51155', city: 'KINNA' },
  { postalCode: '51156', city: 'KINNA' },
  { postalCode: '51157', city: 'KINNA' },
  { postalCode: '51158', city: 'KINNA' },
  { postalCode: '51159', city: 'KINNA' },
  { postalCode: '51161', city: 'SKENE' },
  { postalCode: '51162', city: 'SKENE' },
  { postalCode: '51163', city: 'SKENE' },
  { postalCode: '51164', city: 'SKENE' },
  { postalCode: '51168', city: 'HYSSNA' },
  { postalCode: '51169', city: 'SÄTILA' },
  { postalCode: '51170', city: 'RYDAL' },
  { postalCode: '51171', city: 'FRITSLA' },
  { postalCode: '51172', city: 'FRITSLA' },
  { postalCode: '51173', city: 'FRITSLA' },
  { postalCode: '51174', city: 'SKEPHULT' },
  { postalCode: '51180', city: 'KINNA' },
  { postalCode: '51181', city: 'SKENE' },
  { postalCode: '51182', city: 'KINNA' },
  { postalCode: '51191', city: 'SKENE' },
  { postalCode: '51192', city: 'ÖRBY' },
  { postalCode: '51193', city: 'TORESTORP' },
  { postalCode: '51194', city: 'ÄLEKULLA' },
  { postalCode: '51195', city: 'ÖXABÄCK' },
  { postalCode: '51196', city: 'BERGHEM' },
  { postalCode: '51197', city: 'HAJOM' },
  { postalCode: '51198', city: 'HYSSNA' },
  { postalCode: '51199', city: 'SÄTILA' },
  { postalCode: '51200', city: 'SVENLJUNGA' },
  { postalCode: '51220', city: 'SVENLJUNGA' },
  { postalCode: '51221', city: 'SVENLJUNGA' },
  { postalCode: '51222', city: 'SVENLJUNGA' },
  { postalCode: '51223', city: 'SVENLJUNGA' },
  { postalCode: '51224', city: 'SVENLJUNGA' },
  { postalCode: '51250', city: 'SVENLJUNGA' },
  { postalCode: '51251', city: 'SVENLJUNGA' },
  { postalCode: '51252', city: 'SVENLJUNGA' },
  { postalCode: '51253', city: 'SVENLJUNGA' },
  { postalCode: '51254', city: 'SVENLJUNGA' },
  { postalCode: '51260', city: 'ÖVERLIDA' },
  { postalCode: '51261', city: 'KALV' },
  { postalCode: '51262', city: 'MÅRDAKLEV' },
  { postalCode: '51263', city: 'ÖSTRA FRÖLUNDA' },
  { postalCode: '51264', city: 'HOLSLJUNGA' },
  { postalCode: '51265', city: 'MJÖBÄCK' },
  { postalCode: '51276', city: 'HILLARED' },
  { postalCode: '51277', city: 'SEXDREGA' },
  { postalCode: '51280', city: 'SVENLJUNGA' },
  { postalCode: '51281', city: 'SVENLJUNGA' },
  { postalCode: '51285', city: 'SVENLJUNGA' },
  { postalCode: '51290', city: 'HILLARED' },
  { postalCode: '51291', city: 'SEXDREGA' },
  { postalCode: '51292', city: 'SVENLJUNGA' },
  { postalCode: '51293', city: 'SVENLJUNGA' },
  { postalCode: '51294', city: 'SVENLJUNGA' },
  { postalCode: '51295', city: 'HÅCKSVIK' },
  { postalCode: '51321', city: 'FRISTAD' },
  { postalCode: '51332', city: 'FRISTAD' },
  { postalCode: '51333', city: 'FRISTAD' },
  { postalCode: '51334', city: 'FRISTAD' },
  { postalCode: '51335', city: 'FRISTAD' },
  { postalCode: '51350', city: 'SPARSÖR' },
  { postalCode: '51370', city: 'BORGSTENA' },
  { postalCode: '51381', city: 'FRISTAD' },
  { postalCode: '51382', city: 'FRISTAD' },
  { postalCode: '51391', city: 'FRISTAD' },
  { postalCode: '51392', city: 'FRISTAD' },
  { postalCode: '51393', city: 'FRISTAD' },
  { postalCode: '51394', city: 'SPARSÖR' },
  { postalCode: '51395', city: 'FRISTAD' },
  { postalCode: '51396', city: 'FRISTAD' },
  { postalCode: '51397', city: 'BORGSTENA' },
  { postalCode: '51400', city: 'BORÅS' },
  { postalCode: '51405', city: 'LÄNGHEM' },
  { postalCode: '51406', city: 'DALSTORP' },
  { postalCode: '51411', city: 'LIMMARED' },
  { postalCode: '51412', city: 'LIMMARED' },
  { postalCode: '51420', city: 'TRANEMO' },
  { postalCode: '51421', city: 'TRANEMO' },
  { postalCode: '51422', city: 'TRANEMO' },
  { postalCode: '51423', city: 'TRANEMO' },
  { postalCode: '51424', city: 'TRANEMO' },
  { postalCode: '51431', city: 'TRANEMO' },
  { postalCode: '51432', city: 'TRANEMO' },
  { postalCode: '51433', city: 'TRANEMO' },
  { postalCode: '51434', city: 'TRANEMO' },
  { postalCode: '51435', city: 'TRANEMO' },
  { postalCode: '51440', city: 'LIMMARED' },
  { postalCode: '51441', city: 'LIMMARED' },
  { postalCode: '51442', city: 'LIMMARED' },
  { postalCode: '51443', city: 'LÄNGHEM' },
  { postalCode: '51444', city: 'LÄNGHEM' },
  { postalCode: '51450', city: 'LIMMARED' },
  { postalCode: '51452', city: 'LÄNGHEM' },
  { postalCode: '51453', city: 'MÅNSTAD' },
  { postalCode: '51454', city: 'NITTORP' },
  { postalCode: '51455', city: 'LJUNGSARP' },
  { postalCode: '51460', city: 'DALSTORP' },
  { postalCode: '51461', city: 'DALSTORP' },
  { postalCode: '51462', city: 'ÖLSREMMA' },
  { postalCode: '51463', city: 'DALSTORP' },
  { postalCode: '51470', city: 'GRIMSÅS' },
  { postalCode: '51480', city: 'TRANEMO' },
  { postalCode: '51481', city: 'GRIMSÅS' },
  { postalCode: '51483', city: 'LIMMARED' },
  { postalCode: '51484', city: 'ÖLSREMMA' },
  { postalCode: '51491', city: 'TRANEMO' },
  { postalCode: '51492', city: 'UDDEBO' },
  { postalCode: '51493', city: 'AMBJÖRNARP' },
  { postalCode: '51494', city: 'SJÖTOFTA' },
  { postalCode: '51495', city: 'GRIMSÅS' },
  { postalCode: '51500', city: 'BORÅS' },
  { postalCode: '51521', city: 'VISKAFORS' },
  { postalCode: '51522', city: 'VISKAFORS' },
  { postalCode: '51532', city: 'VISKAFORS' },
  { postalCode: '51533', city: 'VISKAFORS' },
  { postalCode: '51534', city: 'VISKAFORS' },
  { postalCode: '51535', city: 'VISKAFORS' },
  { postalCode: '51560', city: 'SVANEHOLM' },
  { postalCode: '51561', city: 'SVANEHOLM' },
  { postalCode: '51570', city: 'RYDBOHOLM' },
  { postalCode: '51591', city: 'VISKAFORS' },
  { postalCode: '51592', city: 'KINNARUMMA' },
  { postalCode: '51593', city: 'SEGLORA' },
  { postalCode: '51621', city: 'DALSJÖFORS' },
  { postalCode: '51622', city: 'DALSJÖFORS' },
  { postalCode: '51630', city: 'DALSJÖFORS' },
  { postalCode: '51631', city: 'DALSJÖFORS' },
  { postalCode: '51632', city: 'DALSJÖFORS' },
  { postalCode: '51633', city: 'DALSJÖFORS' },
  { postalCode: '51634', city: 'DALSJÖFORS' },
  { postalCode: '51650', city: 'APLARED' },
  { postalCode: '51677', city: 'MÅLSRYD' },
  { postalCode: '51690', city: 'DALSJÖFORS' },
  { postalCode: '51691', city: 'DALSJÖFORS' },
  { postalCode: '51692', city: 'ÄSPERED' },
  { postalCode: '51693', city: 'RÅNGEDALA' },
  { postalCode: '51694', city: 'DANNIKE' },
  { postalCode: '51695', city: 'MÅLSRYD' },
  { postalCode: '51696', city: 'APLARED' },
  { postalCode: '51700', city: 'BOLLEBYGD' },
  { postalCode: '51720', city: 'BOLLEBYGD' },
  { postalCode: '51721', city: 'BOLLEBYGD' },
  { postalCode: '51722', city: 'BOLLEBYGD' },
  { postalCode: '51723', city: 'BOLLEBYGD' },
  { postalCode: '51733', city: 'BOLLEBYGD' },
  { postalCode: '51734', city: 'BOLLEBYGD' },
  { postalCode: '51735', city: 'BOLLEBYGD' },
  { postalCode: '51736', city: 'BOLLEBYGD' },
  { postalCode: '51737', city: 'BOLLEBYGD' },
  { postalCode: '51770', city: 'TÖLLSJÖ' },
  { postalCode: '51771', city: 'OLSFORS' },
  { postalCode: '51781', city: 'BOLLEBYGD' },
  { postalCode: '51782', city: 'BOLLEBYGD' },
  { postalCode: '51783', city: 'BOLLEBYGD' },
  { postalCode: '51791', city: 'BOLLEBYGD' },
  { postalCode: '51792', city: 'BOLLEBYGD' },
  { postalCode: '51793', city: 'TÖLLSJÖ' },
  { postalCode: '51794', city: 'TÖLLSJÖ' },
  { postalCode: '51795', city: 'OLSFORS' },
  { postalCode: '51796', city: 'HULTAFORS' },
  { postalCode: '51802', city: 'SJÖMARKEN' },
  { postalCode: '51821', city: 'SANDARED' },
  { postalCode: '51822', city: 'SANDARED' },
  { postalCode: '51830', city: 'SANDARED' },
  { postalCode: '51831', city: 'SANDARED' },
  { postalCode: '51832', city: 'SANDARED' },
  { postalCode: '51833', city: 'SANDARED' },
  { postalCode: '51840', city: 'SJÖMARKEN' },
  { postalCode: '51841', city: 'SJÖMARKEN' },
  { postalCode: '51842', city: 'SJÖMARKEN' },
  { postalCode: '51843', city: 'SJÖMARKEN' },
  { postalCode: '51890', city: 'SANDARED' },
  { postalCode: '51891', city: 'SJÖMARKEN' },
  { postalCode: '51930', city: 'HORRED' },
  { postalCode: '51931', city: 'HORRED' },
  { postalCode: '51990', city: 'HORRED' },
  { postalCode: '51991', city: 'ISTORP' },
  { postalCode: '51992', city: 'KUNGSÄTER' },
  { postalCode: '51993', city: 'KUNGSÄTER' },
  { postalCode: '51994', city: 'BJÖRKETORP' },
  { postalCode: '51995', city: 'TOSTARED' },
  { postalCode: '51996', city: 'FOTSKÄL' },
  { postalCode: '51997', city: 'ÖXNEVALLA' },
  { postalCode: '52043', city: 'ÅSARP' },
  { postalCode: '52101', city: 'FALKÖPING' },
  { postalCode: '52102', city: 'FALKÖPING' },
  { postalCode: '52104', city: 'GUDHEM' },
  { postalCode: '52105', city: 'VARTOFTA' },
  { postalCode: '52118', city: 'FALKÖPING' },
  { postalCode: '52120', city: 'FALKÖPING' },
  { postalCode: '52121', city: 'FALKÖPING' },
  { postalCode: '52122', city: 'FALKÖPING' },
  { postalCode: '52129', city: 'FALKÖPING' },
  { postalCode: '52130', city: 'FALKÖPING' },
  { postalCode: '52131', city: 'FALKÖPING' },
  { postalCode: '52132', city: 'FALKÖPING' },
  { postalCode: '52133', city: 'FALKÖPING' },
  { postalCode: '52134', city: 'FALKÖPING' },
  { postalCode: '52135', city: 'FALKÖPING' },
  { postalCode: '52136', city: 'FALKÖPING' },
  { postalCode: '52137', city: 'FALKÖPING' },
  { postalCode: '52138', city: 'FALKÖPING' },
  { postalCode: '52139', city: 'FALKÖPING' },
  { postalCode: '52140', city: 'FALKÖPING' },
  { postalCode: '52141', city: 'FALKÖPING' },
  { postalCode: '52142', city: 'FALKÖPING' },
  { postalCode: '52143', city: 'FALKÖPING' },
  { postalCode: '52144', city: 'FALKÖPING' },
  { postalCode: '52145', city: 'FALKÖPING' },
  { postalCode: '52146', city: 'FALKÖPING' },
  { postalCode: '52147', city: 'FALKÖPING' },
  { postalCode: '52151', city: 'FLOBY' },
  { postalCode: '52152', city: 'FLOBY' },
  { postalCode: '52153', city: 'FLOBY' },
  { postalCode: '52154', city: 'FLOBY' },
  { postalCode: '52155', city: 'FLOBY' },
  { postalCode: '52156', city: 'FLOBY' },
  { postalCode: '52160', city: 'STENSTORP' },
  { postalCode: '52161', city: 'STENSTORP' },
  { postalCode: '52162', city: 'STENSTORP' },
  { postalCode: '52163', city: 'STENSTORP' },
  { postalCode: '52164', city: 'STENSTORP' },
  { postalCode: '52170', city: 'ÅSARP' },
  { postalCode: '52171', city: 'ÅSARP' },
  { postalCode: '52172', city: 'SLUTARP' },
  { postalCode: '52173', city: 'KINNARP' },
  { postalCode: '52174', city: 'GUDHEM' },
  { postalCode: '52175', city: 'VARTOFTA' },
  { postalCode: '52181', city: 'FALKÖPING' },
  { postalCode: '52185', city: 'FALKÖPING' },
  { postalCode: '52188', city: 'KINNARP' },
  { postalCode: '52191', city: 'FALKÖPING' },
  { postalCode: '52192', city: 'FALKÖPING' },
  { postalCode: '52193', city: 'FALKÖPING' },
  { postalCode: '52194', city: 'FALKÖPING' },
  { postalCode: '52195', city: 'KÄTTILSTORP' },
  { postalCode: '52196', city: 'FALKÖPING' },
  { postalCode: '52197', city: 'VARTOFTA' },
  { postalCode: '52198', city: 'BRODDETORP' },
  { postalCode: '52201', city: 'TIDAHOLM' },
  { postalCode: '52220', city: 'TIDAHOLM' },
  { postalCode: '52221', city: 'TIDAHOLM' },
  { postalCode: '52222', city: 'TIDAHOLM' },
  { postalCode: '52223', city: 'TIDAHOLM' },
  { postalCode: '52224', city: 'TIDAHOLM' },
  { postalCode: '52225', city: 'TIDAHOLM' },
  { postalCode: '52226', city: 'TIDAHOLM' },
  { postalCode: '52230', city: 'TIDAHOLM' },
  { postalCode: '52231', city: 'TIDAHOLM' },
  { postalCode: '52232', city: 'TIDAHOLM' },
  { postalCode: '52233', city: 'TIDAHOLM' },
  { postalCode: '52234', city: 'TIDAHOLM' },
  { postalCode: '52235', city: 'TIDAHOLM' },
  { postalCode: '52236', city: 'TIDAHOLM' },
  { postalCode: '52237', city: 'TIDAHOLM' },
  { postalCode: '52281', city: 'TIDAHOLM' },
  { postalCode: '52282', city: 'TIDAHOLM' },
  { postalCode: '52283', city: 'TIDAHOLM' },
  { postalCode: '52285', city: 'TIDAHOLM' },
  { postalCode: '52291', city: 'TIDAHOLM' },
  { postalCode: '52292', city: 'TIDAHOLM' },
  { postalCode: '52293', city: 'TIDAHOLM' },
  { postalCode: '52294', city: 'TIDAHOLM' },
  { postalCode: '52301', city: 'ULRICEHAMN' },
  { postalCode: '52320', city: 'ULRICEHAMN' },
  { postalCode: '52321', city: 'ULRICEHAMN' },
  { postalCode: '52322', city: 'ULRICEHAMN' },
  { postalCode: '52323', city: 'ULRICEHAMN' },
  { postalCode: '52324', city: 'ULRICEHAMN' },
  { postalCode: '52325', city: 'ULRICEHAMN' },
  { postalCode: '52326', city: 'ULRICEHAMN' },
  { postalCode: '52330', city: 'ULRICEHAMN' },
  { postalCode: '52331', city: 'ULRICEHAMN' },
  { postalCode: '52332', city: 'ULRICEHAMN' },
  { postalCode: '52333', city: 'ULRICEHAMN' },
  { postalCode: '52334', city: 'ULRICEHAMN' },
  { postalCode: '52335', city: 'ULRICEHAMN' },
  { postalCode: '52336', city: 'ULRICEHAMN' },
  { postalCode: '52337', city: 'ULRICEHAMN' },
  { postalCode: '52338', city: 'ULRICEHAMN' },
  { postalCode: '52339', city: 'ULRICEHAMN' },
  { postalCode: '52340', city: 'ULRICEHAMN' },
  { postalCode: '52341', city: 'ULRICEHAMN' },
  { postalCode: '52343', city: 'ULRICEHAMN' },
  { postalCode: '52344', city: 'ULRICEHAMN' },
  { postalCode: '52345', city: 'ULRICEHAMN' },
  { postalCode: '52360', city: 'GÄLLSTAD' },
  { postalCode: '52361', city: 'GÄLLSTAD' },
  { postalCode: '52362', city: 'VEGBY' },
  { postalCode: '52372', city: 'TIMMELE' },
  { postalCode: '52374', city: 'HÖKERUM' },
  { postalCode: '52375', city: 'DALUM' },
  { postalCode: '52376', city: 'BLIDSBERG' },
  { postalCode: '52377', city: 'BLIDSBERG' },
  { postalCode: '52378', city: 'TRÄDET' },
  { postalCode: '52384', city: 'ULRICEHAMN' },
  { postalCode: '52385', city: 'ULRICEHAMN' },
  { postalCode: '52386', city: 'ULRICEHAMN' },
  { postalCode: '52390', city: 'ULRICEHAMN' },
  { postalCode: '52391', city: 'ULRICEHAMN' },
  { postalCode: '52392', city: 'TIMMELE' },
  { postalCode: '52393', city: 'MARBÄCK' },
  { postalCode: '52394', city: 'TVÄRRED' },
  { postalCode: '52395', city: 'ÄLMESTAD' },
  { postalCode: '52396', city: 'GRÖNAHÖG' },
  { postalCode: '52397', city: 'ULRICEHAMN' },
  { postalCode: '52398', city: 'HÖKERUM' },
  { postalCode: '52399', city: 'HÖKERUM' },
  { postalCode: '52401', city: 'HERRLJUNGA' },
  { postalCode: '52402', city: 'LJUNG' },
  { postalCode: '52420', city: 'HERRLJUNGA' },
  { postalCode: '52421', city: 'HERRLJUNGA' },
  { postalCode: '52422', city: 'HERRLJUNGA' },
  { postalCode: '52423', city: 'HERRLJUNGA' },
  { postalCode: '52430', city: 'HERRLJUNGA' },
  { postalCode: '52431', city: 'HERRLJUNGA' },
  { postalCode: '52432', city: 'HERRLJUNGA' },
  { postalCode: '52433', city: 'HERRLJUNGA' },
  { postalCode: '52441', city: 'LJUNG' },
  { postalCode: '52442', city: 'LJUNG' },
  { postalCode: '52480', city: 'LJUNG' },
  { postalCode: '52491', city: 'HERRLJUNGA' },
  { postalCode: '52492', city: 'HERRLJUNGA' },
  { postalCode: '52493', city: 'HERRLJUNGA' },
  { postalCode: '52495', city: 'LJUNG' },
  { postalCode: '52496', city: 'LJUNG' },
  { postalCode: '53030', city: 'TUN' },
  { postalCode: '53032', city: 'SÅTENÄS' },
  { postalCode: '53101', city: 'LIDKÖPING' },
  { postalCode: '53102', city: 'LIDKÖPING' },
  { postalCode: '53103', city: 'VINNINGA' },
  { postalCode: '53110', city: 'LIDKÖPING' },
  { postalCode: '53116', city: 'LIDKÖPING' },
  { postalCode: '53117', city: 'LIDKÖPING' },
  { postalCode: '53118', city: 'LIDKÖPING' },
  { postalCode: '53119', city: 'LIDKÖPING' },
  { postalCode: '53120', city: 'LIDKÖPING' },
  { postalCode: '53129', city: 'LIDKÖPING' },
  { postalCode: '53130', city: 'LIDKÖPING' },
  { postalCode: '53131', city: 'LIDKÖPING' },
  { postalCode: '53132', city: 'LIDKÖPING' },
  { postalCode: '53133', city: 'LIDKÖPING' },
  { postalCode: '53134', city: 'LIDKÖPING' },
  { postalCode: '53135', city: 'LIDKÖPING' },
  { postalCode: '53136', city: 'LIDKÖPING' },
  { postalCode: '53137', city: 'LIDKÖPING' },
  { postalCode: '53138', city: 'LIDKÖPING' },
  { postalCode: '53139', city: 'LIDKÖPING' },
  { postalCode: '53140', city: 'LIDKÖPING' },
  { postalCode: '53141', city: 'LIDKÖPING' },
  { postalCode: '53142', city: 'LIDKÖPING' },
  { postalCode: '53143', city: 'LIDKÖPING' },
  { postalCode: '53150', city: 'LIDKÖPING' },
  { postalCode: '53151', city: 'LIDKÖPING' },
  { postalCode: '53152', city: 'LIDKÖPING' },
  { postalCode: '53153', city: 'LIDKÖPING' },
  { postalCode: '53154', city: 'LIDKÖPING' },
  { postalCode: '53155', city: 'LIDKÖPING' },
  { postalCode: '53156', city: 'LIDKÖPING' },
  { postalCode: '53157', city: 'LIDKÖPING' },
  { postalCode: '53158', city: 'LIDKÖPING' },
  { postalCode: '53159', city: 'LIDKÖPING' },
  { postalCode: '53170', city: 'LIDKÖPING' },
  { postalCode: '53171', city: 'VINNINGA' },
  { postalCode: '53173', city: 'KÄLLBY' },
  { postalCode: '53175', city: 'JÄRPÅS' },
  { postalCode: '53182', city: 'KÄLLBY' },
  { postalCode: '53185', city: 'LIDKÖPING' },
  { postalCode: '53188', city: 'LIDKÖPING' },
  { postalCode: '53191', city: 'VINNINGA' },
  { postalCode: '53192', city: 'LIDKÖPING' },
  { postalCode: '53193', city: 'LIDKÖPING' },
  { postalCode: '53194', city: 'JÄRPÅS' },
  { postalCode: '53195', city: 'KÄLLBY' },
  { postalCode: '53196', city: 'LIDKÖPING' },
  { postalCode: '53197', city: 'LIDKÖPING' },
  { postalCode: '53198', city: 'LIDKÖPING' },
  { postalCode: '53199', city: 'LIDKÖPING' },
  { postalCode: '53201', city: 'SKARA' },
  { postalCode: '53220', city: 'SKARA' },
  { postalCode: '53221', city: 'SKARA' },
  { postalCode: '53222', city: 'SKARA' },
  { postalCode: '53223', city: 'SKARA' },
  { postalCode: '53224', city: 'SKARA' },
  { postalCode: '53229', city: 'SKARA' },
  { postalCode: '53230', city: 'SKARA' },
  { postalCode: '53231', city: 'SKARA' },
  { postalCode: '53232', city: 'SKARA' },
  { postalCode: '53233', city: 'SKARA' },
  { postalCode: '53234', city: 'SKARA' },
  { postalCode: '53235', city: 'SKARA' },
  { postalCode: '53236', city: 'SKARA' },
  { postalCode: '53237', city: 'SKARA' },
  { postalCode: '53238', city: 'SKARA' },
  { postalCode: '53240', city: 'SKARA' },
  { postalCode: '53272', city: 'AXVALL' },
  { postalCode: '53273', city: 'VARNHEM' },
  { postalCode: '53274', city: 'SKARA' },
  { postalCode: '53280', city: 'SKARA' },
  { postalCode: '53286', city: 'SKARA' },
  { postalCode: '53288', city: 'SKARA' },
  { postalCode: '53289', city: 'SKARA' },
  { postalCode: '53292', city: 'AXVALL' },
  { postalCode: '53293', city: 'AXVALL' },
  { postalCode: '53294', city: 'SKARA' },
  { postalCode: '53295', city: 'SKARA' },
  { postalCode: '53296', city: 'SKARA' },
  { postalCode: '53301', city: 'GÖTENE' },
  { postalCode: '53320', city: 'GÖTENE' },
  { postalCode: '53321', city: 'GÖTENE' },
  { postalCode: '53322', city: 'GÖTENE' },
  { postalCode: '53323', city: 'GÖTENE' },
  { postalCode: '53329', city: 'GÖTENE' },
  { postalCode: '53330', city: 'GÖTENE' },
  { postalCode: '53331', city: 'GÖTENE' },
  { postalCode: '53332', city: 'GÖTENE' },
  { postalCode: '53333', city: 'GÖTENE' },
  { postalCode: '53334', city: 'GÖTENE' },
  { postalCode: '53372', city: 'LUNDSBRUNN' },
  { postalCode: '53374', city: 'HÄLLEKIS' },
  { postalCode: '53380', city: 'GÖTENE' },
  { postalCode: '53382', city: 'GÖTENE' },
  { postalCode: '53391', city: 'GÖTENE' },
  { postalCode: '53392', city: 'LUNDSBRUNN' },
  { postalCode: '53394', city: 'HÄLLEKIS' },
  { postalCode: '53395', city: 'GÖTENE' },
  { postalCode: '53396', city: 'GÖTENE' },
  { postalCode: '53397', city: 'GÖTENE' },
  { postalCode: '53401', city: 'VARA' },
  { postalCode: '53403', city: 'STORA LEVENE' },
  { postalCode: '53420', city: 'VARA' },
  { postalCode: '53421', city: 'VARA' },
  { postalCode: '53422', city: 'VARA' },
  { postalCode: '53423', city: 'VARA' },
  { postalCode: '53430', city: 'VARA' },
  { postalCode: '53431', city: 'VARA' },
  { postalCode: '53432', city: 'VARA' },
  { postalCode: '53434', city: 'VARA' },
  { postalCode: '53450', city: 'VARA' },
  { postalCode: '53460', city: 'VEDUM' },
  { postalCode: '53461', city: 'VEDUM' },
  { postalCode: '53462', city: 'LARV' },
  { postalCode: '53473', city: 'STORA LEVENE' },
  { postalCode: '53481', city: 'VARA' },
  { postalCode: '53482', city: 'VARA' },
  { postalCode: '53484', city: 'VEDUM' },
  { postalCode: '53490', city: 'VARA' },
  { postalCode: '53491', city: 'VARA' },
  { postalCode: '53492', city: 'TRÅVAD' },
  { postalCode: '53493', city: 'STORA LEVENE' },
  { postalCode: '53494', city: 'VARA' },
  { postalCode: '53495', city: 'VARA' },
  { postalCode: '53496', city: 'VARA' },
  { postalCode: '53521', city: 'KVÄNUM' },
  { postalCode: '53523', city: 'KVÄNUM' },
  { postalCode: '53530', city: 'KVÄNUM' },
  { postalCode: '53591', city: 'KVÄNUM' },
  { postalCode: '53592', city: 'KVÄNUM' },
  { postalCode: '53593', city: 'KVÄNUM' },
  { postalCode: '54101', city: 'SKÖVDE' },
  { postalCode: '54102', city: 'SKÖVDE' },
  { postalCode: '54105', city: 'SKÖVDE' },
  { postalCode: '54106', city: 'SKÖVDE' },
  { postalCode: '54107', city: 'SKÖVDE' },
  { postalCode: '54112', city: 'SKÖVDE' },
  { postalCode: '54120', city: 'SKÖVDE' },
  { postalCode: '54121', city: 'SKÖVDE' },
  { postalCode: '54122', city: 'SKÖVDE' },
  { postalCode: '54123', city: 'SKÖVDE' },
  { postalCode: '54124', city: 'SKÖVDE' },
  { postalCode: '54125', city: 'SKÖVDE' },
  { postalCode: '54126', city: 'SKÖVDE' },
  { postalCode: '54127', city: 'SKÖVDE' },
  { postalCode: '54128', city: 'SKÖVDE' },
  { postalCode: '54129', city: 'SKÖVDE' },
  { postalCode: '54130', city: 'SKÖVDE' },
  { postalCode: '54131', city: 'SKÖVDE' },
  { postalCode: '54132', city: 'SKÖVDE' },
  { postalCode: '54133', city: 'SKÖVDE' },
  { postalCode: '54134', city: 'SKÖVDE' },
  { postalCode: '54135', city: 'SKÖVDE' },
  { postalCode: '54136', city: 'SKÖVDE' },
  { postalCode: '54137', city: 'SKÖVDE' },
  { postalCode: '54138', city: 'SKÖVDE' },
  { postalCode: '54139', city: 'SKÖVDE' },
  { postalCode: '54140', city: 'SKÖVDE' },
  { postalCode: '54141', city: 'SKÖVDE' },
  { postalCode: '54142', city: 'SKÖVDE' },
  { postalCode: '54143', city: 'SKÖVDE' },
  { postalCode: '54144', city: 'SKÖVDE' },
  { postalCode: '54145', city: 'SKÖVDE' },
  { postalCode: '54146', city: 'SKÖVDE' },
  { postalCode: '54147', city: 'SKÖVDE' },
  { postalCode: '54148', city: 'SKÖVDE' },
  { postalCode: '54149', city: 'SKÖVDE' },
  { postalCode: '54150', city: 'SKÖVDE' },
  { postalCode: '54152', city: 'SKÖVDE' },
  { postalCode: '54153', city: 'SKÖVDE' },
  { postalCode: '54154', city: 'SKÖVDE' },
  { postalCode: '54155', city: 'SKÖVDE' },
  { postalCode: '54156', city: 'SKÖVDE' },
  { postalCode: '54157', city: 'SKÖVDE' },
  { postalCode: '54161', city: 'SKÖVDE' },
  { postalCode: '54162', city: 'SKÖVDE' },
  { postalCode: '54163', city: 'SKÖVDE' },
  { postalCode: '54164', city: 'SKÖVDE' },
  { postalCode: '54165', city: 'SKÖVDE' },
  { postalCode: '54166', city: 'SKÖVDE' },
  { postalCode: '54167', city: 'SKÖVDE' },
  { postalCode: '54170', city: 'SKÖVDE' },
  { postalCode: '54171', city: 'TIMMERSDALA' },
  { postalCode: '54176', city: 'VÄRING' },
  { postalCode: '54177', city: 'SKÖVDE' },
  { postalCode: '54178', city: 'VÄRSÅS' },
  { postalCode: '54180', city: 'SKÖVDE' },
  { postalCode: '54183', city: 'SKÖVDE' },
  { postalCode: '54184', city: 'SKÖVDE' },
  { postalCode: '54185', city: 'SKÖVDE' },
  { postalCode: '54186', city: 'SKÖVDE' },
  { postalCode: '54187', city: 'SKÖVDE' },
  { postalCode: '54191', city: 'SKÖVDE' },
  { postalCode: '54192', city: 'SKÖVDE' },
  { postalCode: '54193', city: 'SKÖVDE' },
  { postalCode: '54194', city: 'SKÖVDE' },
  { postalCode: '54195', city: 'TIMMERSDALA' },
  { postalCode: '54196', city: 'VÄRING' },
  { postalCode: '54197', city: 'LERDALA' },
  { postalCode: '54198', city: 'VÄRSÅS' },
  { postalCode: '54201', city: 'MARIESTAD' },
  { postalCode: '54218', city: 'MARIESTAD' },
  { postalCode: '54220', city: 'MARIESTAD' },
  { postalCode: '54221', city: 'MARIESTAD' },
  { postalCode: '54222', city: 'MARIESTAD' },
  { postalCode: '54223', city: 'MARIESTAD' },
  { postalCode: '54224', city: 'MARIESTAD' },
  { postalCode: '54230', city: 'MARIESTAD' },
  { postalCode: '54231', city: 'MARIESTAD' },
  { postalCode: '54232', city: 'MARIESTAD' },
  { postalCode: '54233', city: 'MARIESTAD' },
  { postalCode: '54234', city: 'MARIESTAD' },
  { postalCode: '54235', city: 'MARIESTAD' },
  { postalCode: '54236', city: 'MARIESTAD' },
  { postalCode: '54237', city: 'MARIESTAD' },
  { postalCode: '54238', city: 'MARIESTAD' },
  { postalCode: '54239', city: 'MARIESTAD' },
  { postalCode: '54240', city: 'MARIESTAD' },
  { postalCode: '54241', city: 'MARIESTAD' },
  { postalCode: '54242', city: 'MARIESTAD' },
  { postalCode: '54243', city: 'MARIESTAD' },
  { postalCode: '54244', city: 'MARIESTAD' },
  { postalCode: '54245', city: 'MARIESTAD' },
  { postalCode: '54246', city: 'MARIESTAD' },
  { postalCode: '54247', city: 'MARIESTAD' },
  { postalCode: '54266', city: 'SJÖTORP' },
  { postalCode: '54272', city: 'MARIESTAD' },
  { postalCode: '54273', city: 'MARIESTAD' },
  { postalCode: '54274', city: 'MARIESTAD' },
  { postalCode: '54275', city: 'MARIESTAD' },
  { postalCode: '54285', city: 'MARIESTAD' },
  { postalCode: '54286', city: 'MARIESTAD' },
  { postalCode: '54287', city: 'MARIESTAD' },
  { postalCode: '54288', city: 'MARIESTAD' },
  { postalCode: '54291', city: 'TORSÖ' },
  { postalCode: '54292', city: 'MARIESTAD' },
  { postalCode: '54293', city: 'MARIESTAD' },
  { postalCode: '54294', city: 'MARIESTAD' },
  { postalCode: '54295', city: 'MARIESTAD' },
  { postalCode: '54301', city: 'TIBRO' },
  { postalCode: '54320', city: 'TIBRO' },
  { postalCode: '54321', city: 'TIBRO' },
  { postalCode: '54322', city: 'TIBRO' },
  { postalCode: '54323', city: 'TIBRO' },
  { postalCode: '54330', city: 'TIBRO' },
  { postalCode: '54331', city: 'TIBRO' },
  { postalCode: '54332', city: 'TIBRO' },
  { postalCode: '54333', city: 'TIBRO' },
  { postalCode: '54334', city: 'TIBRO' },
  { postalCode: '54335', city: 'TIBRO' },
  { postalCode: '54336', city: 'TIBRO' },
  { postalCode: '54337', city: 'TIBRO' },
  { postalCode: '54350', city: 'TIBRO' },
  { postalCode: '54351', city: 'TIBRO' },
  { postalCode: '54380', city: 'TIBRO' },
  { postalCode: '54381', city: 'TIBRO' },
  { postalCode: '54391', city: 'TIBRO' },
  { postalCode: '54392', city: 'TIBRO' },
  { postalCode: '54393', city: 'TIBRO' },
  { postalCode: '54394', city: 'TIBRO' },
  { postalCode: '54395', city: 'FAGERSANNA' },
  { postalCode: '54401', city: 'HJO' },
  { postalCode: '54420', city: 'HJO' },
  { postalCode: '54421', city: 'HJO' },
  { postalCode: '54422', city: 'HJO' },
  { postalCode: '54430', city: 'HJO' },
  { postalCode: '54431', city: 'HJO' },
  { postalCode: '54432', city: 'HJO' },
  { postalCode: '54433', city: 'HJO' },
  { postalCode: '54434', city: 'HJO' },
  { postalCode: '54435', city: 'HJO' },
  { postalCode: '54436', city: 'HJO' },
  { postalCode: '54450', city: 'HJO' },
  { postalCode: '54481', city: 'HJO' },
  { postalCode: '54491', city: 'HJO' },
  { postalCode: '54492', city: 'HJO' },
  { postalCode: '54493', city: 'HJO' },
  { postalCode: '54494', city: 'HJO' },
  { postalCode: '54501', city: 'TÖREBODA' },
  { postalCode: '54502', city: 'ÄLGARÅS' },
  { postalCode: '54520', city: 'TÖREBODA' },
  { postalCode: '54521', city: 'TÖREBODA' },
  { postalCode: '54522', city: 'TÖREBODA' },
  { postalCode: '54523', city: 'TÖREBODA' },
  { postalCode: '54530', city: 'TÖREBODA' },
  { postalCode: '54531', city: 'TÖREBODA' },
  { postalCode: '54532', city: 'TÖREBODA' },
  { postalCode: '54533', city: 'TÖREBODA' },
  { postalCode: '54534', city: 'TÖREBODA' },
  { postalCode: '54535', city: 'TÖREBODA' },
  { postalCode: '54572', city: 'ÄLGARÅS' },
  { postalCode: '54590', city: 'TÖREBODA' },
  { postalCode: '54591', city: 'TÖREBODA' },
  { postalCode: '54592', city: 'ÄLGARÅS' },
  { postalCode: '54593', city: 'TÖREBODA' },
  { postalCode: '54601', city: 'KARLSBORG' },
  { postalCode: '54618', city: 'KARLSBORG' },
  { postalCode: '54620', city: 'KARLSBORG' },
  { postalCode: '54621', city: 'KARLSBORG' },
  { postalCode: '54622', city: 'KARLSBORG' },
  { postalCode: '54623', city: 'KARLSBORG' },
  { postalCode: '54630', city: 'KARLSBORG' },
  { postalCode: '54631', city: 'KARLSBORG' },
  { postalCode: '54632', city: 'KARLSBORG' },
  { postalCode: '54633', city: 'KARLSBORG' },
  { postalCode: '54634', city: 'KARLSBORG' },
  { postalCode: '54672', city: 'MÖLLTORP' },
  { postalCode: '54673', city: 'FORSVIK' },
  { postalCode: '54681', city: 'KARLSBORG' },
  { postalCode: '54682', city: 'KARLSBORG' },
  { postalCode: '54691', city: 'KARLSBORG' },
  { postalCode: '54692', city: 'MÖLLTORP' },
  { postalCode: '54693', city: 'UNDENÄS' },
  { postalCode: '54694', city: 'UNDENÄS' },
  { postalCode: '54695', city: 'KARLSBORG' },
  { postalCode: '54721', city: 'GULLSPÅNG' },
  { postalCode: '54722', city: 'GULLSPÅNG' },
  { postalCode: '54730', city: 'GULLSPÅNG' },
  { postalCode: '54731', city: 'GULLSPÅNG' },
  { postalCode: '54772', city: 'OTTERBÄCKEN' },
  { postalCode: '54781', city: 'OTTERBÄCKEN' },
  { postalCode: '54782', city: 'OTTERBÄCKEN' },
  { postalCode: '54791', city: 'GULLSPÅNG' },
  { postalCode: '54792', city: 'GULLSPÅNG' },
  { postalCode: '54793', city: 'SKAGERSVIK' },
  { postalCode: '54800', city: 'HOVA' },
  { postalCode: '54820', city: 'HOVA' },
  { postalCode: '54821', city: 'HOVA' },
  { postalCode: '54822', city: 'HOVA' },
  { postalCode: '54831', city: 'HOVA' },
  { postalCode: '54832', city: 'HOVA' },
  { postalCode: '54873', city: 'LYRESTAD' },
  { postalCode: '54874', city: 'GÅRDSJÖ' },
  { postalCode: '54891', city: 'HOVA' },
  { postalCode: '54892', city: 'HOVA' },
  { postalCode: '54893', city: 'LYRESTAD' },
  { postalCode: '54931', city: 'TIDAN' },
  { postalCode: '54941', city: 'MOHOLM' },
  { postalCode: '54991', city: 'TIDAN' },
  { postalCode: '54992', city: 'MOHOLM' },
  { postalCode: '54993', city: 'MOHOLM' },
  { postalCode: '55001', city: 'JÖNKÖPING' },
  { postalCode: '55002', city: 'JÖNKÖPING' },
  { postalCode: '55003', city: 'JÖNKÖPING' },
  { postalCode: '55004', city: 'JÖNKÖPING' },
  { postalCode: '55005', city: 'JÖNKÖPING' },
  { postalCode: '55006', city: 'JÖNKÖPING' },
  { postalCode: '55008', city: 'JÖNKÖPING' },
  { postalCode: '55009', city: 'JÖNKÖPING' },
  { postalCode: '55011', city: 'JÖNKÖPING' },
  { postalCode: '55100', city: 'JÖNKÖPING' },
  { postalCode: '55101', city: 'JÖNKÖPING' },
  { postalCode: '55103', city: 'JÖNKÖPING' },
  { postalCode: '55110', city: 'JÖNKÖPING' },
  { postalCode: '55111', city: 'JÖNKÖPING' },
  { postalCode: '55112', city: 'JÖNKÖPING' },
  { postalCode: '55113', city: 'JÖNKÖPING' },
  { postalCode: '55114', city: 'JÖNKÖPING' },
  { postalCode: '55115', city: 'JÖNKÖPING' },
  { postalCode: '55116', city: 'JÖNKÖPING' },
  { postalCode: '55117', city: 'JÖNKÖPING' },
  { postalCode: '55118', city: 'JÖNKÖPING' },
  { postalCode: '55119', city: 'JÖNKÖPING' },
  { postalCode: '55120', city: 'JÖNKÖPING' },
  { postalCode: '55130', city: 'JÖNKÖPING' },
  { postalCode: '55180', city: 'JÖNKÖPING' },
  { postalCode: '55181', city: 'JÖNKÖPING' },
  { postalCode: '55182', city: 'JÖNKÖPING' },
  { postalCode: '55183', city: 'JÖNKÖPING' },
  { postalCode: '55184', city: 'JÖNKÖPING' },
  { postalCode: '55185', city: 'JÖNKÖPING' },
  { postalCode: '55186', city: 'JÖNKÖPING' },
  { postalCode: '55189', city: 'JÖNKÖPING' },
  { postalCode: '55190', city: 'JÖNKÖPING' },
  { postalCode: '55191', city: 'JÖNKÖPING' },
  { postalCode: '55192', city: 'JÖNKÖPING' },
  { postalCode: '55193', city: 'JÖNKÖPING' },
  { postalCode: '55195', city: 'JÖNKÖPING' },
  { postalCode: '55199', city: 'JÖNKÖPING' },
  { postalCode: '55300', city: 'JÖNKÖPING' },
  { postalCode: '55302', city: 'JÖNKÖPING' },
  { postalCode: '55303', city: 'JÖNKÖPING' },
  { postalCode: '55305', city: 'JÖNKÖPING' },
  { postalCode: '55307', city: 'JÖNKÖPING' },
  { postalCode: '55308', city: 'JÖNKÖPING' },
  { postalCode: '55309', city: 'JÖNKÖPING' },
  { postalCode: '55310', city: 'JÖNKÖPING' },
  { postalCode: '55311', city: 'JÖNKÖPING' },
  { postalCode: '55312', city: 'JÖNKÖPING' },
  { postalCode: '55313', city: 'JÖNKÖPING' },
  { postalCode: '55314', city: 'JÖNKÖPING' },
  { postalCode: '55315', city: 'JÖNKÖPING' },
  { postalCode: '55316', city: 'JÖNKÖPING' },
  { postalCode: '55317', city: 'JÖNKÖPING' },
  { postalCode: '55318', city: 'JÖNKÖPING' },
  { postalCode: '55320', city: 'JÖNKÖPING' },
  { postalCode: '55321', city: 'JÖNKÖPING' },
  { postalCode: '55322', city: 'JÖNKÖPING' },
  { postalCode: '55323', city: 'JÖNKÖPING' },
  { postalCode: '55324', city: 'JÖNKÖPING' },
  { postalCode: '55329', city: 'JÖNKÖPING' },
  { postalCode: '55330', city: 'JÖNKÖPING' },
  { postalCode: '55331', city: 'JÖNKÖPING' },
  { postalCode: '55332', city: 'JÖNKÖPING' },
  { postalCode: '55333', city: 'JÖNKÖPING' },
  { postalCode: '55334', city: 'JÖNKÖPING' },
  { postalCode: '55335', city: 'JÖNKÖPING' },
  { postalCode: '55336', city: 'JÖNKÖPING' },
  { postalCode: '55337', city: 'JÖNKÖPING' },
  { postalCode: '55338', city: 'JÖNKÖPING' },
  { postalCode: '55339', city: 'JÖNKÖPING' },
  { postalCode: '55400', city: 'JÖNKÖPING' },
  { postalCode: '55438', city: 'JÖNKÖPING' },
  { postalCode: '55439', city: 'JÖNKÖPING' },
  { postalCode: '55445', city: 'JÖNKÖPING' },
  { postalCode: '55446', city: 'JÖNKÖPING' },
  { postalCode: '55447', city: 'JÖNKÖPING' },
  { postalCode: '55448', city: 'JÖNKÖPING' },
  { postalCode: '55449', city: 'JÖNKÖPING' },
  { postalCode: '55450', city: 'JÖNKÖPING' },
  { postalCode: '55451', city: 'JÖNKÖPING' },
  { postalCode: '55452', city: 'JÖNKÖPING' },
  { postalCode: '55453', city: 'JÖNKÖPING' },
  { postalCode: '55454', city: 'JÖNKÖPING' },
  { postalCode: '55456', city: 'JÖNKÖPING' },
  { postalCode: '55457', city: 'JÖNKÖPING' },
  { postalCode: '55458', city: 'JÖNKÖPING' },
  { postalCode: '55459', city: 'JÖNKÖPING' },
  { postalCode: '55462', city: 'JÖNKÖPING' },
  { postalCode: '55463', city: 'JÖNKÖPING' },
  { postalCode: '55464', city: 'JÖNKÖPING' },
  { postalCode: '55465', city: 'JÖNKÖPING' },
  { postalCode: '55466', city: 'JÖNKÖPING' },
  { postalCode: '55472', city: 'JÖNKÖPING' },
  { postalCode: '55473', city: 'JÖNKÖPING' },
  { postalCode: '55474', city: 'JÖNKÖPING' },
  { postalCode: '55475', city: 'JÖNKÖPING' },
  { postalCode: '55592', city: 'JÖNKÖPING' },
  { postalCode: '55593', city: 'JÖNKÖPING' },
  { postalCode: '55594', city: 'JÖNKÖPING' },
  { postalCode: '55600', city: 'JÖNKÖPING' },
  { postalCode: '55610', city: 'JÖNKÖPING' },
  { postalCode: '55611', city: 'JÖNKÖPING' },
  { postalCode: '55612', city: 'JÖNKÖPING' },
  { postalCode: '55614', city: 'JÖNKÖPING' },
  { postalCode: '55615', city: 'JÖNKÖPING' },
  { postalCode: '55616', city: 'JÖNKÖPING' },
  { postalCode: '55625', city: 'JÖNKÖPING' },
  { postalCode: '55626', city: 'JÖNKÖPING' },
  { postalCode: '55627', city: 'JÖNKÖPING' },
  { postalCode: '55628', city: 'JÖNKÖPING' },
  { postalCode: '55629', city: 'JÖNKÖPING' },
  { postalCode: '55631', city: 'JÖNKÖPING' },
  { postalCode: '55632', city: 'JÖNKÖPING' },
  { postalCode: '55633', city: 'JÖNKÖPING' },
  { postalCode: '55650', city: 'JÖNKÖPING' },
  { postalCode: '55652', city: 'JÖNKÖPING' },
  { postalCode: '55800', city: 'JÖNKÖPING' },
  { postalCode: '55810', city: 'JÖNKÖPING' },
  { postalCode: '56101', city: 'HUSKVARNA' },
  { postalCode: '56102', city: 'HUSKVARNA' },
  { postalCode: '56104', city: 'HUSKVARNA' },
  { postalCode: '56105', city: 'HUSKVARNA' },
  { postalCode: '56106', city: 'TENHULT' },
  { postalCode: '56120', city: 'HUSKVARNA' },
  { postalCode: '56121', city: 'HUSKVARNA' },
  { postalCode: '56122', city: 'HUSKVARNA' },
  { postalCode: '56123', city: 'HUSKVARNA' },
  { postalCode: '56124', city: 'HUSKVARNA' },
  { postalCode: '56125', city: 'HUSKVARNA' },
  { postalCode: '56130', city: 'HUSKVARNA' },
  { postalCode: '56131', city: 'HUSKVARNA' },
  { postalCode: '56132', city: 'HUSKVARNA' },
  { postalCode: '56133', city: 'HUSKVARNA' },
  { postalCode: '56134', city: 'HUSKVARNA' },
  { postalCode: '56135', city: 'HUSKVARNA' },
  { postalCode: '56136', city: 'HUSKVARNA' },
  { postalCode: '56138', city: 'HUSKVARNA' },
  { postalCode: '56139', city: 'HUSKVARNA' },
  { postalCode: '56140', city: 'HUSKVARNA' },
  { postalCode: '56141', city: 'HUSKVARNA' },
  { postalCode: '56142', city: 'HUSKVARNA' },
  { postalCode: '56143', city: 'HUSKVARNA' },
  { postalCode: '56144', city: 'HUSKVARNA' },
  { postalCode: '56146', city: 'HUSKVARNA' },
  { postalCode: '56148', city: 'HUSKVARNA' },
  { postalCode: '56149', city: 'HUSKVARNA' },
  { postalCode: '56150', city: 'HUSKVARNA' },
  { postalCode: '56151', city: 'HUSKVARNA' },
  { postalCode: '56160', city: 'TENHULT' },
  { postalCode: '56161', city: 'TENHULT' },
  { postalCode: '56162', city: 'TENHULT' },
  { postalCode: '56163', city: 'TENHULT' },
  { postalCode: '56182', city: 'HUSKVARNA' },
  { postalCode: '56183', city: 'HUSKVARNA' },
  { postalCode: '56184', city: 'HUSKVARNA' },
  { postalCode: '56185', city: 'HUSKVARNA' },
  { postalCode: '56191', city: 'HUSKVARNA' },
  { postalCode: '56192', city: 'HUSKVARNA' },
  { postalCode: '56193', city: 'HUSKVARNA' },
  { postalCode: '56195', city: 'LEKERYD' },
  { postalCode: '56196', city: 'LEKERYD' },
  { postalCode: '56202', city: 'TABERG' },
  { postalCode: '56220', city: 'NORRAHAMMAR' },
  { postalCode: '56221', city: 'NORRAHAMMAR' },
  { postalCode: '56222', city: 'NORRAHAMMAR' },
  { postalCode: '56223', city: 'NORRAHAMMAR' },
  { postalCode: '56230', city: 'NORRAHAMMAR' },
  { postalCode: '56231', city: 'NORRAHAMMAR' },
  { postalCode: '56232', city: 'NORRAHAMMAR' },
  { postalCode: '56233', city: 'NORRAHAMMAR' },
  { postalCode: '56240', city: 'TABERG' },
  { postalCode: '56241', city: 'TABERG' },
  { postalCode: '56242', city: 'TABERG' },
  { postalCode: '56250', city: 'MÅNSARP' },
  { postalCode: '56280', city: 'NORRAHAMMAR' },
  { postalCode: '56291', city: 'MÅNSARP' },
  { postalCode: '56301', city: 'GRÄNNA' },
  { postalCode: '56320', city: 'GRÄNNA' },
  { postalCode: '56321', city: 'GRÄNNA' },
  { postalCode: '56322', city: 'GRÄNNA' },
  { postalCode: '56331', city: 'GRÄNNA' },
  { postalCode: '56332', city: 'GRÄNNA' },
  { postalCode: '56391', city: 'GRÄNNA' },
  { postalCode: '56392', city: 'GRÄNNA' },
  { postalCode: '56393', city: 'GRÄNNA' },
  { postalCode: '56394', city: 'VISINGSÖ' },
  { postalCode: '56421', city: 'BANKERYD' },
  { postalCode: '56422', city: 'BANKERYD' },
  { postalCode: '56423', city: 'BANKERYD' },
  { postalCode: '56424', city: 'BANKERYD' },
  { postalCode: '56428', city: 'BANKERYD' },
  { postalCode: '56431', city: 'BANKERYD' },
  { postalCode: '56432', city: 'BANKERYD' },
  { postalCode: '56433', city: 'BANKERYD' },
  { postalCode: '56434', city: 'BANKERYD' },
  { postalCode: '56435', city: 'BANKERYD' },
  { postalCode: '56436', city: 'BANKERYD' },
  { postalCode: '56491', city: 'BANKERYD' },
  { postalCode: '56518', city: 'MULLSJÖ' },
  { postalCode: '56520', city: 'MULLSJÖ' },
  { postalCode: '56521', city: 'MULLSJÖ' },
  { postalCode: '56522', city: 'MULLSJÖ' },
  { postalCode: '56523', city: 'MULLSJÖ' },
  { postalCode: '56524', city: 'MULLSJÖ' },
  { postalCode: '56528', city: 'MULLSJÖ' },
  { postalCode: '56531', city: 'MULLSJÖ' },
  { postalCode: '56532', city: 'MULLSJÖ' },
  { postalCode: '56533', city: 'MULLSJÖ' },
  { postalCode: '56534', city: 'MULLSJÖ' },
  { postalCode: '56576', city: 'BOTTNARYD' },
  { postalCode: '56591', city: 'MULLSJÖ' },
  { postalCode: '56592', city: 'MULLSJÖ' },
  { postalCode: '56593', city: 'MULLSJÖ' },
  { postalCode: '56594', city: 'SANDHEM' },
  { postalCode: '56596', city: 'BOTTNARYD' },
  { postalCode: '56597', city: 'BOTTNARYD' },
  { postalCode: '56600', city: 'HABO' },
  { postalCode: '56601', city: 'HABO' },
  { postalCode: '56618', city: 'HABO' },
  { postalCode: '56620', city: 'HABO' },
  { postalCode: '56621', city: 'HABO' },
  { postalCode: '56622', city: 'HABO' },
  { postalCode: '56623', city: 'HABO' },
  { postalCode: '56624', city: 'HABO' },
  { postalCode: '56631', city: 'HABO' },
  { postalCode: '56632', city: 'HABO' },
  { postalCode: '56633', city: 'HABO' },
  { postalCode: '56634', city: 'HABO' },
  { postalCode: '56635', city: 'HABO' },
  { postalCode: '56636', city: 'HABO' },
  { postalCode: '56637', city: 'HABO' },
  { postalCode: '56680', city: 'HABO' },
  { postalCode: '56691', city: 'HABO' },
  { postalCode: '56692', city: 'HABO' },
  { postalCode: '56693', city: 'BRANDSTORP' },
  { postalCode: '56701', city: 'VAGGERYD' },
  { postalCode: '56720', city: 'VAGGERYD' },
  { postalCode: '56721', city: 'VAGGERYD' },
  { postalCode: '56722', city: 'VAGGERYD' },
  { postalCode: '56723', city: 'VAGGERYD' },
  { postalCode: '56724', city: 'VAGGERYD' },
  { postalCode: '56730', city: 'VAGGERYD' },
  { postalCode: '56731', city: 'VAGGERYD' },
  { postalCode: '56732', city: 'VAGGERYD' },
  { postalCode: '56733', city: 'VAGGERYD' },
  { postalCode: '56791', city: 'VAGGERYD' },
  { postalCode: '56792', city: 'VAGGERYD' },
  { postalCode: '56793', city: 'HOK' },
  { postalCode: '56821', city: 'SKILLINGARYD' },
  { postalCode: '56822', city: 'SKILLINGARYD' },
  { postalCode: '56823', city: 'SKILLINGARYD' },
  { postalCode: '56824', city: 'SKILLINGARYD' },
  { postalCode: '56830', city: 'SKILLINGARYD' },
  { postalCode: '56831', city: 'SKILLINGARYD' },
  { postalCode: '56832', city: 'SKILLINGARYD' },
  { postalCode: '56891', city: 'SKILLINGARYD' },
  { postalCode: '56892', city: 'SKILLINGARYD' },
  { postalCode: '56893', city: 'SKILLINGARYD' },
  { postalCode: '57100', city: 'NÄSSJÖ' },
  { postalCode: '57103', city: 'FORSERUM' },
  { postalCode: '57106', city: 'BODAFORS' },
  { postalCode: '57110', city: 'NÄSSJÖ' },
  { postalCode: '57119', city: 'NÄSSJÖ' },
  { postalCode: '57120', city: 'NÄSSJÖ' },
  { postalCode: '57121', city: 'NÄSSJÖ' },
  { postalCode: '57122', city: 'NÄSSJÖ' },
  { postalCode: '57123', city: 'NÄSSJÖ' },
  { postalCode: '57124', city: 'NÄSSJÖ' },
  { postalCode: '57129', city: 'NÄSSJÖ' },
  { postalCode: '57131', city: 'NÄSSJÖ' },
  { postalCode: '57132', city: 'NÄSSJÖ' },
  { postalCode: '57133', city: 'NÄSSJÖ' },
  { postalCode: '57134', city: 'NÄSSJÖ' },
  { postalCode: '57135', city: 'NÄSSJÖ' },
  { postalCode: '57136', city: 'NÄSSJÖ' },
  { postalCode: '57137', city: 'NÄSSJÖ' },
  { postalCode: '57138', city: 'NÄSSJÖ' },
  { postalCode: '57139', city: 'NÄSSJÖ' },
  { postalCode: '57140', city: 'NÄSSJÖ' },
  { postalCode: '57141', city: 'NÄSSJÖ' },
  { postalCode: '57142', city: 'NÄSSJÖ' },
  { postalCode: '57143', city: 'NÄSSJÖ' },
  { postalCode: '57161', city: 'BODAFORS' },
  { postalCode: '57162', city: 'BODAFORS' },
  { postalCode: '57163', city: 'BODAFORS' },
  { postalCode: '57164', city: 'SANDSJÖFORS' },
  { postalCode: '57165', city: 'GRIMSTORP' },
  { postalCode: '57166', city: 'BODAFORS' },
  { postalCode: '57167', city: 'BODAFORS' },
  { postalCode: '57168', city: 'MALMBÄCK' },
  { postalCode: '57169', city: 'MALMBÄCK' },
  { postalCode: '57171', city: 'ANNEBERG' },
  { postalCode: '57172', city: 'ORMARYD' },
  { postalCode: '57173', city: 'STENSJÖN' },
  { postalCode: '57174', city: 'ÄNG' },
  { postalCode: '57175', city: 'FREDRIKSDAL' },
  { postalCode: '57176', city: 'SOLBERGA' },
  { postalCode: '57177', city: 'FORSERUM' },
  { postalCode: '57178', city: 'FORSERUM' },
  { postalCode: '57180', city: 'NÄSSJÖ' },
  { postalCode: '57181', city: 'NÄSSJÖ' },
  { postalCode: '57182', city: 'NÄSSJÖ' },
  { postalCode: '57183', city: 'NÄSSJÖ' },
  { postalCode: '57185', city: 'NÄSSJÖ' },
  { postalCode: '57188', city: 'NÄSSJÖ' },
  { postalCode: '57191', city: 'NÄSSJÖ' },
  { postalCode: '57192', city: 'NÄSSJÖ' },
  { postalCode: '57193', city: 'NÄSSJÖ' },
  { postalCode: '57194', city: 'NÄSSJÖ' },
  { postalCode: '57195', city: 'NÄSSJÖ' },
  { postalCode: '57197', city: 'FORSERUM' },
  { postalCode: '57200', city: 'OSKARSHAMN' },
  { postalCode: '57201', city: 'OSKARSHAMN' },
  { postalCode: '57204', city: 'OSKARSHAMN' },
  { postalCode: '57205', city: 'FIGEHOLM' },
  { postalCode: '57206', city: 'PÅSKALLAVIK' },
  { postalCode: '57207', city: 'KRISTDALA' },
  { postalCode: '57220', city: 'OSKARSHAMN' },
  { postalCode: '57221', city: 'OSKARSHAMN' },
  { postalCode: '57222', city: 'OSKARSHAMN' },
  { postalCode: '57223', city: 'OSKARSHAMN' },
  { postalCode: '57224', city: 'OSKARSHAMN' },
  { postalCode: '57225', city: 'OSKARSHAMN' },
  { postalCode: '57226', city: 'OSKARSHAMN' },
  { postalCode: '57227', city: 'OSKARSHAMN' },
  { postalCode: '57228', city: 'OSKARSHAMN' },
  { postalCode: '57229', city: 'OSKARSHAMN' },
  { postalCode: '57230', city: 'OSKARSHAMN' },
  { postalCode: '57231', city: 'OSKARSHAMN' },
  { postalCode: '57232', city: 'OSKARSHAMN' },
  { postalCode: '57235', city: 'OSKARSHAMN' },
  { postalCode: '57236', city: 'OSKARSHAMN' },
  { postalCode: '57237', city: 'OSKARSHAMN' },
  { postalCode: '57240', city: 'OSKARSHAMN' },
  { postalCode: '57241', city: 'OSKARSHAMN' },
  { postalCode: '57242', city: 'OSKARSHAMN' },
  { postalCode: '57250', city: 'OSKARSHAMN' },
  { postalCode: '57251', city: 'OSKARSHAMN' },
  { postalCode: '57255', city: 'OSKARSHAMN' },
  { postalCode: '57256', city: 'OSKARSHAMN' },
  { postalCode: '57257', city: 'OSKARSHAMN' },
  { postalCode: '57258', city: 'OSKARSHAMN' },
  { postalCode: '57260', city: 'OSKARSHAMN' },
  { postalCode: '57261', city: 'OSKARSHAMN' },
  { postalCode: '57262', city: 'OSKARSHAMN' },
  { postalCode: '57263', city: 'OSKARSHAMN' },
  { postalCode: '57273', city: 'KRISTDALA' },
  { postalCode: '57274', city: 'PÅSKALLAVIK' },
  { postalCode: '57275', city: 'FIGEHOLM' },
  { postalCode: '57276', city: 'FÅRBO' },
  { postalCode: '57283', city: 'OSKARSHAMN' },
  { postalCode: '57291', city: 'OSKARSHAMN' },
  { postalCode: '57292', city: 'OSKARSHAMN' },
  { postalCode: '57293', city: 'OSKARSHAMN' },
  { postalCode: '57295', city: 'FIGEHOLM' },
  { postalCode: '57296', city: 'FÅRBO' },
  { postalCode: '57297', city: 'KRISTDALA' },
  { postalCode: '57300', city: 'TRANÅS' },
  { postalCode: '57302', city: 'YDRE' },
  { postalCode: '57320', city: 'TRANÅS' },
  { postalCode: '57321', city: 'TRANÅS' },
  { postalCode: '57322', city: 'TRANÅS' },
  { postalCode: '57323', city: 'TRANÅS' },
  { postalCode: '57324', city: 'TRANÅS' },
  { postalCode: '57325', city: 'TRANÅS' },
  { postalCode: '57328', city: 'TRANÅS' },
  { postalCode: '57329', city: 'TRANÅS' },
  { postalCode: '57331', city: 'TRANÅS' },
  { postalCode: '57332', city: 'TRANÅS' },
  { postalCode: '57333', city: 'TRANÅS' },
  { postalCode: '57334', city: 'TRANÅS' },
  { postalCode: '57335', city: 'TRANÅS' },
  { postalCode: '57336', city: 'TRANÅS' },
  { postalCode: '57337', city: 'TRANÅS' },
  { postalCode: '57338', city: 'TRANÅS' },
  { postalCode: '57339', city: 'TRANÅS' },
  { postalCode: '57340', city: 'TRANÅS' },
  { postalCode: '57341', city: 'TRANÅS' },
  { postalCode: '57342', city: 'TRANÅS' },
  { postalCode: '57343', city: 'TRANÅS' },
  { postalCode: '57361', city: 'SOMMEN' },
  { postalCode: '57373', city: 'SUNHULTSBRUNN' },
  { postalCode: '57374', city: 'YDRE' },
  { postalCode: '57375', city: 'YDRE' },
  { postalCode: '57376', city: 'YDRE' },
  { postalCode: '57377', city: 'YDRE' },
  { postalCode: '57382', city: 'TRANÅS' },
  { postalCode: '57383', city: 'TRANÅS' },
  { postalCode: '57391', city: 'TRANÅS' },
  { postalCode: '57392', city: 'TRANÅS' },
  { postalCode: '57393', city: 'TRANÅS' },
  { postalCode: '57394', city: 'YDRE' },
  { postalCode: '57395', city: 'YDRE' },
  { postalCode: '57396', city: 'TRANÅS' },
  { postalCode: '57397', city: 'TRANÅS' },
  { postalCode: '57398', city: 'TRANÅS' },
  { postalCode: '57399', city: 'TRANÅS' },
  { postalCode: '57400', city: 'VETLANDA' },
  { postalCode: '57403', city: 'LANDSBRO' },
  { postalCode: '57420', city: 'VETLANDA' },
  { postalCode: '57421', city: 'VETLANDA' },
  { postalCode: '57422', city: 'VETLANDA' },
  { postalCode: '57423', city: 'VETLANDA' },
  { postalCode: '57428', city: 'VETLANDA' },
  { postalCode: '57431', city: 'VETLANDA' },
  { postalCode: '57432', city: 'VETLANDA' },
  { postalCode: '57433', city: 'VETLANDA' },
  { postalCode: '57434', city: 'VETLANDA' },
  { postalCode: '57435', city: 'VETLANDA' },
  { postalCode: '57436', city: 'VETLANDA' },
  { postalCode: '57437', city: 'VETLANDA' },
  { postalCode: '57438', city: 'VETLANDA' },
  { postalCode: '57439', city: 'VETLANDA' },
  { postalCode: '57440', city: 'VETLANDA' },
  { postalCode: '57441', city: 'VETLANDA' },
  { postalCode: '57442', city: 'VETLANDA' },
  { postalCode: '57443', city: 'VETLANDA' },
  { postalCode: '57450', city: 'EKENÄSSJÖN' },
  { postalCode: '57453', city: 'HOLSBYBRUNN' },
  { postalCode: '57454', city: 'HOLSBYBRUNN' },
  { postalCode: '57455', city: 'KVILLSFORS' },
  { postalCode: '57471', city: 'MYRESJÖ' },
  { postalCode: '57472', city: 'LANDSBRO' },
  { postalCode: '57473', city: 'LANDSBRO' },
  { postalCode: '57474', city: 'RAMKVILLA' },
  { postalCode: '57475', city: 'KORSBERGA' },
  { postalCode: '57476', city: 'KORSBERGA' },
  { postalCode: '57480', city: 'VETLANDA' },
  { postalCode: '57481', city: 'VETLANDA' },
  { postalCode: '57485', city: 'VETLANDA' },
  { postalCode: '57491', city: 'VETLANDA' },
  { postalCode: '57492', city: 'VETLANDA' },
  { postalCode: '57493', city: 'VETLANDA' },
  { postalCode: '57494', city: 'VETLANDA' },
  { postalCode: '57495', city: 'BJÖRKÖBY' },
  { postalCode: '57496', city: 'VETLANDA' },
  { postalCode: '57497', city: 'VETLANDA' },
  { postalCode: '57498', city: 'NYE' },
  { postalCode: '57501', city: 'EKSJÖ' },
  { postalCode: '57520', city: 'EKSJÖ' },
  { postalCode: '57521', city: 'EKSJÖ' },
  { postalCode: '57522', city: 'EKSJÖ' },
  { postalCode: '57523', city: 'EKSJÖ' },
  { postalCode: '57528', city: 'EKSJÖ' },
  { postalCode: '57531', city: 'EKSJÖ' },
  { postalCode: '57532', city: 'EKSJÖ' },
  { postalCode: '57533', city: 'EKSJÖ' },
  { postalCode: '57534', city: 'EKSJÖ' },
  { postalCode: '57535', city: 'EKSJÖ' },
  { postalCode: '57536', city: 'EKSJÖ' },
  { postalCode: '57537', city: 'EKSJÖ' },
  { postalCode: '57538', city: 'EKSJÖ' },
  { postalCode: '57539', city: 'EKSJÖ' },
  { postalCode: '57580', city: 'EKSJÖ' },
  { postalCode: '57581', city: 'EKSJÖ' },
  { postalCode: '57582', city: 'EKSJÖ' },
  { postalCode: '57591', city: 'EKSJÖ' },
  { postalCode: '57592', city: 'HULT' },
  { postalCode: '57593', city: 'EKSJÖ' },
  { postalCode: '57594', city: 'EKSJÖ' },
  { postalCode: '57595', city: 'EKSJÖ' },
  { postalCode: '57596', city: 'EKSJÖ' },
  { postalCode: '57597', city: 'INGATORP' },
  { postalCode: '57598', city: 'HJÄLTEVAD' },
  { postalCode: '57599', city: 'BRUZAHOLM' },
  { postalCode: '57601', city: 'SÄVSJÖ' },
  { postalCode: '57602', city: 'VRIGSTAD' },
  { postalCode: '57620', city: 'SÄVSJÖ' },
  { postalCode: '57621', city: 'SÄVSJÖ' },
  { postalCode: '57622', city: 'SÄVSJÖ' },
  { postalCode: '57623', city: 'SÄVSJÖ' },
  { postalCode: '57624', city: 'SÄVSJÖ' },
  { postalCode: '57631', city: 'SÄVSJÖ' },
  { postalCode: '57632', city: 'SÄVSJÖ' },
  { postalCode: '57633', city: 'SÄVSJÖ' },
  { postalCode: '57635', city: 'SÄVSJÖ' },
  { postalCode: '57636', city: 'SÄVSJÖ' },
  { postalCode: '57680', city: 'SÄVSJÖ' },
  { postalCode: '57691', city: 'SÄVSJÖ' },
  { postalCode: '57692', city: 'SÄVSJÖ' },
  { postalCode: '57693', city: 'RÖRVIK' },
  { postalCode: '57694', city: 'STOCKARYD' },
  { postalCode: '57695', city: 'STOCKARYD' },
  { postalCode: '57696', city: 'VRIGSTAD' },
  { postalCode: '57697', city: 'VRIGSTAD' },
  { postalCode: '57701', city: 'HULTSFRED' },
  { postalCode: '57702', city: 'SILVERDALEN' },
  { postalCode: '57703', city: 'VIRSERUM' },
  { postalCode: '57704', city: 'MÅLILLA' },
  { postalCode: '57705', city: 'JÄRNFORSEN' },
  { postalCode: '57720', city: 'HULTSFRED' },
  { postalCode: '57721', city: 'HULTSFRED' },
  { postalCode: '57722', city: 'HULTSFRED' },
  { postalCode: '57723', city: 'HULTSFRED' },
  { postalCode: '57724', city: 'HULTSFRED' },
  { postalCode: '57725', city: 'HULTSFRED' },
  { postalCode: '57726', city: 'HULTSFRED' },
  { postalCode: '57730', city: 'HULTSFRED' },
  { postalCode: '57731', city: 'HULTSFRED' },
  { postalCode: '57732', city: 'HULTSFRED' },
  { postalCode: '57733', city: 'HULTSFRED' },
  { postalCode: '57734', city: 'HULTSFRED' },
  { postalCode: '57735', city: 'HULTSFRED' },
  { postalCode: '57736', city: 'HULTSFRED' },
  { postalCode: '57737', city: 'HULTSFRED' },
  { postalCode: '57738', city: 'HULTSFRED' },
  { postalCode: '57739', city: 'HULTSFRED' },
  { postalCode: '57750', city: 'SILVERDALEN' },
  { postalCode: '57751', city: 'SILVERDALEN' },
  { postalCode: '57771', city: 'VIRSERUM' },
  { postalCode: '57772', city: 'VIRSERUM' },
  { postalCode: '57773', city: 'MÅLILLA' },
  { postalCode: '57774', city: 'MÅLILLA' },
  { postalCode: '57775', city: 'MÖRLUNDA' },
  { postalCode: '57776', city: 'JÄRNFORSEN' },
  { postalCode: '57777', city: 'JÄRNFORSEN' },
  { postalCode: '57780', city: 'ROSENFORS' },
  { postalCode: '57790', city: 'VENA' },
  { postalCode: '57791', city: 'HULTSFRED' },
  { postalCode: '57792', city: 'VENA' },
  { postalCode: '57793', city: 'VENA' },
  { postalCode: '57794', city: 'LÖNNEBERGA' },
  { postalCode: '57795', city: 'VIRSERUM' },
  { postalCode: '57796', city: 'MÅLILLA' },
  { postalCode: '57797', city: 'ROSENFORS' },
  { postalCode: '57798', city: 'MÖRLUNDA' },
  { postalCode: '57799', city: 'PAULISTRÖM' },
  { postalCode: '57801', city: 'ANEBY' },
  { postalCode: '57820', city: 'ANEBY' },
  { postalCode: '57821', city: 'ANEBY' },
  { postalCode: '57822', city: 'ANEBY' },
  { postalCode: '57823', city: 'ANEBY' },
  { postalCode: '57824', city: 'ANEBY' },
  { postalCode: '57831', city: 'ANEBY' },
  { postalCode: '57832', city: 'ANEBY' },
  { postalCode: '57833', city: 'ANEBY' },
  { postalCode: '57874', city: 'FRINNARYD' },
  { postalCode: '57875', city: 'FLISBY' },
  { postalCode: '57891', city: 'ANEBY' },
  { postalCode: '57892', city: 'ANEBY' },
  { postalCode: '57893', city: 'ANEBY' },
  { postalCode: '57894', city: 'ANEBY' },
  { postalCode: '57895', city: 'FLISBY' },
  { postalCode: '57920', city: 'HÖGSBY' },
  { postalCode: '57921', city: 'HÖGSBY' },
  { postalCode: '57922', city: 'HÖGSBY' },
  { postalCode: '57923', city: 'HÖGSBY' },
  { postalCode: '57930', city: 'HÖGSBY' },
  { postalCode: '57931', city: 'HÖGSBY' },
  { postalCode: '57932', city: 'HÖGSBY' },
  { postalCode: '57933', city: 'HÖGSBY' },
  { postalCode: '57940', city: 'BERGA' },
  { postalCode: '57971', city: 'RUDA' },
  { postalCode: '57972', city: 'FÅGELFORS' },
  { postalCode: '57980', city: 'HÖGSBY' },
  { postalCode: '57990', city: 'BERGA' },
  { postalCode: '57991', city: 'BOCKARA' },
  { postalCode: '57992', city: 'HÖGSBY' },
  { postalCode: '57993', city: 'GRÖNSKÅRA' },
  { postalCode: '57994', city: 'FAGERHULT' },
  { postalCode: '57995', city: 'RUDA' },
  { postalCode: '57996', city: 'FÅGELFORS' },
  { postalCode: '58002', city: 'LINKÖPING' },
  { postalCode: '58003', city: 'LINKÖPING' },
  { postalCode: '58005', city: 'LINKÖPING' },
  { postalCode: '58006', city: 'LINKÖPING' },
  { postalCode: '58008', city: 'LINKÖPING' },
  { postalCode: '58009', city: 'LINKÖPING' },
  { postalCode: '58010', city: 'LINKÖPING' },
  { postalCode: '58011', city: 'LINKÖPING' },
  { postalCode: '58013', city: 'LINKÖPING' },
  { postalCode: '58015', city: 'LINKÖPING' },
  { postalCode: '58100', city: 'LINKÖPING' },
  { postalCode: '58101', city: 'LINKÖPING' },
  { postalCode: '58102', city: 'LINKÖPING' },
  { postalCode: '58103', city: 'LINKÖPING' },
  { postalCode: '58104', city: 'LINKÖPING' },
  { postalCode: '58105', city: 'LINKÖPING' },
  { postalCode: '58106', city: 'LINKÖPING' },
  { postalCode: '58107', city: 'LINKÖPING' },
  { postalCode: '58110', city: 'LINKÖPING' },
  { postalCode: '58111', city: 'LINKÖPING' },
  { postalCode: '58112', city: 'LINKÖPING' },
  { postalCode: '58113', city: 'LINKÖPING' },
  { postalCode: '58114', city: 'LINKÖPING' },
  { postalCode: '58115', city: 'LINKÖPING' },
  { postalCode: '58116', city: 'LINKÖPING' },
  { postalCode: '58117', city: 'LINKÖPING' },
  { postalCode: '58118', city: 'LINKÖPING' },
  { postalCode: '58119', city: 'LINKÖPING' },
  { postalCode: '58128', city: 'LINKÖPING' },
  { postalCode: '58180', city: 'LINKÖPING' },
  { postalCode: '58181', city: 'LINKÖPING' },
  { postalCode: '58182', city: 'LINKÖPING' },
  { postalCode: '58183', city: 'LINKÖPING' },
  { postalCode: '58184', city: 'LINKÖPING' },
  { postalCode: '58185', city: 'LINKÖPING' },
  { postalCode: '58186', city: 'LINKÖPING' },
  { postalCode: '58187', city: 'LINKÖPING' },
  { postalCode: '58188', city: 'LINKÖPING' },
  { postalCode: '58189', city: 'LINKÖPING' },
  { postalCode: '58190', city: 'LINKÖPING' },
  { postalCode: '58191', city: 'LINKÖPING' },
  { postalCode: '58192', city: 'LINKÖPING' },
  { postalCode: '58193', city: 'LINKÖPING' },
  { postalCode: '58194', city: 'LINKÖPING' },
  { postalCode: '58195', city: 'LINKÖPING' },
  { postalCode: '58196', city: 'LINKÖPING' },
  { postalCode: '58198', city: 'LINKÖPING' },
  { postalCode: '58199', city: 'LINKÖPING' },
  { postalCode: '58200', city: 'LINKÖPING' },
  { postalCode: '58201', city: 'LINKÖPING' },
  { postalCode: '58202', city: 'LINKÖPING' },
  { postalCode: '58203', city: 'LINKÖPING' },
  { postalCode: '58212', city: 'LINKÖPING' },
  { postalCode: '58213', city: 'LINKÖPING' },
  { postalCode: '58214', city: 'LINKÖPING' },
  { postalCode: '58215', city: 'LINKÖPING' },
  { postalCode: '58216', city: 'LINKÖPING' },
  { postalCode: '58217', city: 'LINKÖPING' },
  { postalCode: '58218', city: 'LINKÖPING' },
  { postalCode: '58219', city: 'LINKÖPING' },
  { postalCode: '58220', city: 'LINKÖPING' },
  { postalCode: '58221', city: 'LINKÖPING' },
  { postalCode: '58222', city: 'LINKÖPING' },
  { postalCode: '58223', city: 'LINKÖPING' },
  { postalCode: '58224', city: 'LINKÖPING' },
  { postalCode: '58225', city: 'LINKÖPING' },
  { postalCode: '58226', city: 'LINKÖPING' },
  { postalCode: '58227', city: 'LINKÖPING' },
  { postalCode: '58228', city: 'LINKÖPING' },
  { postalCode: '58229', city: 'LINKÖPING' },
  { postalCode: '58230', city: 'LINKÖPING' },
  { postalCode: '58231', city: 'LINKÖPING' },
  { postalCode: '58232', city: 'LINKÖPING' },
  { postalCode: '58233', city: 'LINKÖPING' },
  { postalCode: '58234', city: 'LINKÖPING' },
  { postalCode: '58235', city: 'LINKÖPING' },
  { postalCode: '58236', city: 'LINKÖPING' },
  { postalCode: '58237', city: 'LINKÖPING' },
  { postalCode: '58238', city: 'LINKÖPING' },
  { postalCode: '58239', city: 'LINKÖPING' },
  { postalCode: '58240', city: 'LINKÖPING' },
  { postalCode: '58241', city: 'LINKÖPING' },
  { postalCode: '58242', city: 'LINKÖPING' },
  { postalCode: '58243', city: 'LINKÖPING' },
  { postalCode: '58244', city: 'LINKÖPING' },
  { postalCode: '58245', city: 'LINKÖPING' },
  { postalCode: '58246', city: 'LINKÖPING' },
  { postalCode: '58247', city: 'LINKÖPING' },
  { postalCode: '58248', city: 'LINKÖPING' },
  { postalCode: '58249', city: 'LINKÖPING' },
  { postalCode: '58252', city: 'LINKÖPING' },
  { postalCode: '58253', city: 'LINKÖPING' },
  { postalCode: '58254', city: 'LINKÖPING' },
  { postalCode: '58255', city: 'LINKÖPING' },
  { postalCode: '58256', city: 'LINKÖPING' },
  { postalCode: '58272', city: 'LINKÖPING' },
  { postalCode: '58273', city: 'LINKÖPING' },
  { postalCode: '58274', city: 'LINKÖPING' },
  { postalCode: '58275', city: 'LINKÖPING' },
  { postalCode: '58276', city: 'LINKÖPING' },
  { postalCode: '58277', city: 'LINKÖPING' },
  { postalCode: '58278', city: 'LINKÖPING' },
  { postalCode: '58300', city: 'LINKÖPING' },
  { postalCode: '58302', city: 'VIKINGSTAD' },
  { postalCode: '58328', city: 'LINKÖPING' },
  { postalCode: '58329', city: 'LINKÖPING' },
  { postalCode: '58330', city: 'LINKÖPING' },
  { postalCode: '58331', city: 'LINKÖPING' },
  { postalCode: '58332', city: 'LINKÖPING' },
  { postalCode: '58333', city: 'LINKÖPING' },
  { postalCode: '58334', city: 'LINKÖPING' },
  { postalCode: '58335', city: 'LINKÖPING' },
  { postalCode: '58336', city: 'LINKÖPING' },
  { postalCode: '58337', city: 'LINKÖPING' },
  { postalCode: '58338', city: 'LINKÖPING' },
  { postalCode: '58360', city: 'GAMMALKIL' },
  { postalCode: '58371', city: 'VIKINGSTAD' },
  { postalCode: '58372', city: 'VIKINGSTAD' },
  { postalCode: '58391', city: 'VIKINGSTAD' },
  { postalCode: '58392', city: 'VIKINGSTAD' },
  { postalCode: '58393', city: 'VIKINGSTAD' },
  { postalCode: '58394', city: 'NYKIL' },
  { postalCode: '58400', city: 'LINKÖPING' },
  { postalCode: '58422', city: 'LINKÖPING' },
  { postalCode: '58431', city: 'LINKÖPING' },
  { postalCode: '58432', city: 'LINKÖPING' },
  { postalCode: '58434', city: 'LINKÖPING' },
  { postalCode: '58435', city: 'LINKÖPING' },
  { postalCode: '58436', city: 'LINKÖPING' },
  { postalCode: '58437', city: 'LINKÖPING' },
  { postalCode: '58439', city: 'LINKÖPING' },
  { postalCode: '58500', city: 'LINKÖPING' },
  { postalCode: '58506', city: 'LINGHEM' },
  { postalCode: '58507', city: 'LJUNGSBRO' },
  { postalCode: '58561', city: 'LINGHEM' },
  { postalCode: '58562', city: 'LINGHEM' },
  { postalCode: '58563', city: 'LINGHEM' },
  { postalCode: '58564', city: 'LINGHEM' },
  { postalCode: '58565', city: 'LINGHEM' },
  { postalCode: '58571', city: 'LJUNGSBRO' },
  { postalCode: '58572', city: 'LJUNGSBRO' },
  { postalCode: '58573', city: 'LJUNGSBRO' },
  { postalCode: '58574', city: 'LJUNGSBRO' },
  { postalCode: '58575', city: 'LJUNGSBRO' },
  { postalCode: '58576', city: 'VRETA KLOSTER' },
  { postalCode: '58577', city: 'VRETA KLOSTER' },
  { postalCode: '58578', city: 'VRETA KLOSTER' },
  { postalCode: '58580', city: 'LJUNGSBRO' },
  { postalCode: '58591', city: 'LINKÖPING' },
  { postalCode: '58592', city: 'LINKÖPING' },
  { postalCode: '58593', city: 'LINKÖPING' },
  { postalCode: '58594', city: 'LINKÖPING' },
  { postalCode: '58595', city: 'LINKÖPING' },
  { postalCode: '58596', city: 'LINKÖPING' },
  { postalCode: '58597', city: 'LINKÖPING' },
  { postalCode: '58598', city: 'LINKÖPING' },
  { postalCode: '58599', city: 'LINKÖPING' },
  { postalCode: '58600', city: 'LINKÖPING' },
  { postalCode: '58642', city: 'LINKÖPING' },
  { postalCode: '58643', city: 'LINKÖPING' },
  { postalCode: '58644', city: 'LINKÖPING' },
  { postalCode: '58645', city: 'LINKÖPING' },
  { postalCode: '58646', city: 'LINKÖPING' },
  { postalCode: '58647', city: 'LINKÖPING' },
  { postalCode: '58648', city: 'LINKÖPING' },
  { postalCode: '58649', city: 'LINKÖPING' },
  { postalCode: '58662', city: 'LINKÖPING' },
  { postalCode: '58663', city: 'LINKÖPING' },
  { postalCode: '58665', city: 'LINKÖPING' },
  { postalCode: '58666', city: 'LINKÖPING' },
  { postalCode: '58700', city: 'LINKÖPING' },
  { postalCode: '58723', city: 'LINKÖPING' },
  { postalCode: '58724', city: 'LINKÖPING' },
  { postalCode: '58725', city: 'LINKÖPING' },
  { postalCode: '58726', city: 'LINKÖPING' },
  { postalCode: '58727', city: 'LINKÖPING' },
  { postalCode: '58729', city: 'LINKÖPING' },
  { postalCode: '58731', city: 'LINKÖPING' },
  { postalCode: '58732', city: 'LINKÖPING' },
  { postalCode: '58733', city: 'LINKÖPING' },
  { postalCode: '58734', city: 'LINKÖPING' },
  { postalCode: '58735', city: 'LINKÖPING' },
  { postalCode: '58736', city: 'LINKÖPING' },
  { postalCode: '58737', city: 'LINKÖPING' },
  { postalCode: '58738', city: 'LINKÖPING' },
  { postalCode: '58739', city: 'LINKÖPING' },
  { postalCode: '58750', city: 'LINKÖPING' },
  { postalCode: '58752', city: 'LINKÖPING' },
  { postalCode: '58758', city: 'LINKÖPING' },
  { postalCode: '58801', city: 'LINKÖPING' },
  { postalCode: '58802', city: 'LINKÖPING' },
  { postalCode: '58813', city: 'LINKÖPING' },
  { postalCode: '58815', city: 'LINKÖPING' },
  { postalCode: '58818', city: 'LINKÖPING' },
  { postalCode: '58820', city: 'LINKÖPING' },
  { postalCode: '58900', city: 'LINKÖPING' },
  { postalCode: '58921', city: 'LINKÖPING' },
  { postalCode: '58923', city: 'LINKÖPING' },
  { postalCode: '58925', city: 'LINKÖPING' },
  { postalCode: '58927', city: 'LINKÖPING' },
  { postalCode: '58929', city: 'LINKÖPING' },
  { postalCode: '58931', city: 'LINKÖPING' },
  { postalCode: '58933', city: 'LINKÖPING' },
  { postalCode: '58935', city: 'LINKÖPING' },
  { postalCode: '58937', city: 'LINKÖPING' },
  { postalCode: '58939', city: 'LINKÖPING' },
  { postalCode: '58941', city: 'LINKÖPING' },
  { postalCode: '58943', city: 'LINKÖPING' },
  { postalCode: '58950', city: 'LINKÖPING' },
  { postalCode: '58951', city: 'LINKÖPING' },
  { postalCode: '58952', city: 'LINKÖPING' },
  { postalCode: '58953', city: 'LINKÖPING' },
  { postalCode: '58955', city: 'LINKÖPING' },
  { postalCode: '58957', city: 'LINKÖPING' },
  { postalCode: '58961', city: 'STUREFORS' },
  { postalCode: '58963', city: 'STUREFORS' },
  { postalCode: '58964', city: 'STUREFORS' },
  { postalCode: '58965', city: 'BESTORP' },
  { postalCode: '59021', city: 'VÄDERSTAD' },
  { postalCode: '59036', city: 'KISA' },
  { postalCode: '59037', city: 'KISA' },
  { postalCode: '59038', city: 'KISA' },
  { postalCode: '59039', city: 'KISA' },
  { postalCode: '59040', city: 'KISA' },
  { postalCode: '59041', city: 'RIMFORSA' },
  { postalCode: '59042', city: 'HORN' },
  { postalCode: '59043', city: 'RIMFORSA' },
  { postalCode: '59044', city: 'RIMFORSA' },
  { postalCode: '59045', city: 'BROKIND' },
  { postalCode: '59046', city: 'RIMFORSA' },
  { postalCode: '59053', city: 'ULRIKA' },
  { postalCode: '59100', city: 'MOTALA' },
  { postalCode: '59101', city: 'MOTALA' },
  { postalCode: '59102', city: 'MOTALA' },
  { postalCode: '59104', city: 'MOTALA' },
  { postalCode: '59105', city: 'MOTALA' },
  { postalCode: '59106', city: 'BORENSBERG' },
  { postalCode: '59120', city: 'MOTALA' },
  { postalCode: '59121', city: 'MOTALA' },
  { postalCode: '59122', city: 'MOTALA' },
  { postalCode: '59123', city: 'MOTALA' },
  { postalCode: '59124', city: 'MOTALA' },
  { postalCode: '59126', city: 'MOTALA' },
  { postalCode: '59127', city: 'MOTALA' },
  { postalCode: '59129', city: 'MOTALA' },
  { postalCode: '59130', city: 'MOTALA' },
  { postalCode: '59132', city: 'MOTALA' },
  { postalCode: '59135', city: 'MOTALA' },
  { postalCode: '59136', city: 'MOTALA' },
  { postalCode: '59137', city: 'MOTALA' },
  { postalCode: '59138', city: 'MOTALA' },
  { postalCode: '59139', city: 'MOTALA' },
  { postalCode: '59145', city: 'MOTALA' },
  { postalCode: '59146', city: 'MOTALA' },
  { postalCode: '59147', city: 'MOTALA' },
  { postalCode: '59150', city: 'MOTALA' },
  { postalCode: '59151', city: 'MOTALA' },
  { postalCode: '59152', city: 'MOTALA' },
  { postalCode: '59153', city: 'MOTALA' },
  { postalCode: '59154', city: 'MOTALA' },
  { postalCode: '59155', city: 'MOTALA' },
  { postalCode: '59160', city: 'MOTALA' },
  { postalCode: '59161', city: 'MOTALA' },
  { postalCode: '59162', city: 'MOTALA' },
  { postalCode: '59170', city: 'MOTALA' },
  { postalCode: '59171', city: 'MOTALA' },
  { postalCode: '59172', city: 'MOTALA' },
  { postalCode: '59173', city: 'MOTALA' },
  { postalCode: '59174', city: 'BORENSBERG' },
  { postalCode: '59175', city: 'BORENSBERG' },
  { postalCode: '59176', city: 'BORENSBERG' },
  { postalCode: '59177', city: 'BORENSBERG' },
  { postalCode: '59178', city: 'KLOCKRIKE' },
  { postalCode: '59179', city: 'FORNÅSA' },
  { postalCode: '59181', city: 'MOTALA' },
  { postalCode: '59182', city: 'MOTALA' },
  { postalCode: '59184', city: 'MOTALA' },
  { postalCode: '59185', city: 'MOTALA' },
  { postalCode: '59186', city: 'MOTALA' },
  { postalCode: '59190', city: 'TJÄLLMO' },
  { postalCode: '59191', city: 'MOTALA' },
  { postalCode: '59192', city: 'MOTALA' },
  { postalCode: '59193', city: 'MOTALA' },
  { postalCode: '59194', city: 'MOTALA' },
  { postalCode: '59195', city: 'MOTALA' },
  { postalCode: '59196', city: 'MOTALA' },
  { postalCode: '59197', city: 'MOTALA' },
  { postalCode: '59198', city: 'MOTALA' },
  { postalCode: '59199', city: 'MOTALA' },
  { postalCode: '59201', city: 'VADSTENA' },
  { postalCode: '59220', city: 'VADSTENA' },
  { postalCode: '59221', city: 'VADSTENA' },
  { postalCode: '59222', city: 'VADSTENA' },
  { postalCode: '59223', city: 'VADSTENA' },
  { postalCode: '59224', city: 'VADSTENA' },
  { postalCode: '59230', city: 'VADSTENA' },
  { postalCode: '59231', city: 'VADSTENA' },
  { postalCode: '59232', city: 'VADSTENA' },
  { postalCode: '59240', city: 'VADSTENA' },
  { postalCode: '59241', city: 'VADSTENA' },
  { postalCode: '59242', city: 'VADSTENA' },
  { postalCode: '59280', city: 'VADSTENA' },
  { postalCode: '59281', city: 'VADSTENA' },
  { postalCode: '59291', city: 'VADSTENA' },
  { postalCode: '59292', city: 'VADSTENA' },
  { postalCode: '59293', city: 'BORGHAMN' },
  { postalCode: '59294', city: 'VADSTENA' },
  { postalCode: '59300', city: 'VÄSTERVIK' },
  { postalCode: '59302', city: 'VÄSTERVIK' },
  { postalCode: '59303', city: 'VÄSTERVIK' },
  { postalCode: '59310', city: 'ANKARSRUM' },
  { postalCode: '59311', city: 'GUNNEBO' },
  { postalCode: '59320', city: 'VÄSTERVIK' },
  { postalCode: '59321', city: 'VÄSTERVIK' },
  { postalCode: '59322', city: 'VÄSTERVIK' },
  { postalCode: '59323', city: 'VÄSTERVIK' },
  { postalCode: '59324', city: 'VÄSTERVIK' },
  { postalCode: '59325', city: 'VÄSTERVIK' },
  { postalCode: '59326', city: 'VÄSTERVIK' },
  { postalCode: '59330', city: 'VÄSTERVIK' },
  { postalCode: '59331', city: 'VÄSTERVIK' },
  { postalCode: '59332', city: 'VÄSTERVIK' },
  { postalCode: '59333', city: 'VÄSTERVIK' },
  { postalCode: '59334', city: 'VÄSTERVIK' },
  { postalCode: '59335', city: 'VÄSTERVIK' },
  { postalCode: '59336', city: 'VÄSTERVIK' },
  { postalCode: '59337', city: 'VÄSTERVIK' },
  { postalCode: '59338', city: 'VÄSTERVIK' },
  { postalCode: '59339', city: 'VÄSTERVIK' },
  { postalCode: '59340', city: 'VÄSTERVIK' },
  { postalCode: '59341', city: 'VÄSTERVIK' },
  { postalCode: '59342', city: 'VÄSTERVIK' },
  { postalCode: '59343', city: 'VÄSTERVIK' },
  { postalCode: '59344', city: 'VÄSTERVIK' },
  { postalCode: '59350', city: 'VÄSTERVIK' },
  { postalCode: '59351', city: 'VÄSTERVIK' },
  { postalCode: '59352', city: 'VÄSTERVIK' },
  { postalCode: '59353', city: 'VÄSTERVIK' },
  { postalCode: '59354', city: 'VÄSTERVIK' },
  { postalCode: '59361', city: 'VÄSTERVIK' },
  { postalCode: '59362', city: 'VÄSTERVIK' },
  { postalCode: '59370', city: 'ANKARSRUM' },
  { postalCode: '59371', city: 'ANKARSRUM' },
  { postalCode: '59372', city: 'TOTEBO' },
  { postalCode: '59373', city: 'HJORTED' },
  { postalCode: '59374', city: 'GUNNEBO' },
  { postalCode: '59375', city: 'GUNNEBO' },
  { postalCode: '59376', city: 'BLACKSTAD' },
  { postalCode: '59380', city: 'VÄSTERVIK' },
  { postalCode: '59381', city: 'VÄSTERVIK' },
  { postalCode: '59382', city: 'VÄSTERVIK' },
  { postalCode: '59383', city: 'VÄSTERVIK' },
  { postalCode: '59384', city: 'VÄSTERVIK' },
  { postalCode: '59385', city: 'VÄSTERVIK' },
  { postalCode: '59387', city: 'VÄSTERVIK' },
  { postalCode: '59391', city: 'VÄSTERVIK' },
  { postalCode: '59392', city: 'VÄSTERVIK' },
  { postalCode: '59393', city: 'VÄSTERVIK' },
  { postalCode: '59395', city: 'VÄSTERVIK' },
  { postalCode: '59396', city: 'VÄSTERVIK' },
  { postalCode: '59397', city: 'BLANKAHOLM' },
  { postalCode: '59401', city: 'GAMLEBY' },
  { postalCode: '59420', city: 'GAMLEBY' },
  { postalCode: '59421', city: 'GAMLEBY' },
  { postalCode: '59422', city: 'GAMLEBY' },
  { postalCode: '59423', city: 'GAMLEBY' },
  { postalCode: '59430', city: 'GAMLEBY' },
  { postalCode: '59431', city: 'GAMLEBY' },
  { postalCode: '59432', city: 'GAMLEBY' },
  { postalCode: '59470', city: 'LOFTAHAMMAR' },
  { postalCode: '59471', city: 'LOFTAHAMMAR' },
  { postalCode: '59472', city: 'ÖVERUM' },
  { postalCode: '59473', city: 'ÖVERUM' },
  { postalCode: '59474', city: 'EDSBRUK' },
  { postalCode: '59475', city: 'EDSBRUK' },
  { postalCode: '59491', city: 'GAMLEBY' },
  { postalCode: '59492', city: 'GAMLEBY' },
  { postalCode: '59493', city: 'GAMLEBY' },
  { postalCode: '59494', city: 'ODENSVI' },
  { postalCode: '59501', city: 'MJÖLBY' },
  { postalCode: '59503', city: 'BOXHOLM' },
  { postalCode: '59504', city: 'MANTORP' },
  { postalCode: '59520', city: 'MJÖLBY' },
  { postalCode: '59521', city: 'MJÖLBY' },
  { postalCode: '59522', city: 'MJÖLBY' },
  { postalCode: '59523', city: 'MJÖLBY' },
  { postalCode: '59524', city: 'MJÖLBY' },
  { postalCode: '59529', city: 'MJÖLBY' },
  { postalCode: '59530', city: 'MJÖLBY' },
  { postalCode: '59531', city: 'MJÖLBY' },
  { postalCode: '59532', city: 'MJÖLBY' },
  { postalCode: '59533', city: 'MJÖLBY' },
  { postalCode: '59534', city: 'MJÖLBY' },
  { postalCode: '59535', city: 'MJÖLBY' },
  { postalCode: '59540', city: 'MJÖLBY' },
  { postalCode: '59541', city: 'MJÖLBY' },
  { postalCode: '59542', city: 'MJÖLBY' },
  { postalCode: '59543', city: 'MJÖLBY' },
  { postalCode: '59544', city: 'MJÖLBY' },
  { postalCode: '59550', city: 'MJÖLBY' },
  { postalCode: '59551', city: 'MJÖLBY' },
  { postalCode: '59552', city: 'MJÖLBY' },
  { postalCode: '59553', city: 'MJÖLBY' },
  { postalCode: '59554', city: 'MJÖLBY' },
  { postalCode: '59557', city: 'MANTORP' },
  { postalCode: '59558', city: 'MANTORP' },
  { postalCode: '59559', city: 'MANTORP' },
  { postalCode: '59570', city: 'BOXHOLM' },
  { postalCode: '59571', city: 'BOXHOLM' },
  { postalCode: '59572', city: 'BOXHOLM' },
  { postalCode: '59573', city: 'BOXHOLM' },
  { postalCode: '59575', city: 'BOXHOLM' },
  { postalCode: '59576', city: 'BOXHOLM' },
  { postalCode: '59580', city: 'MJÖLBY' },
  { postalCode: '59581', city: 'MJÖLBY' },
  { postalCode: '59591', city: 'MJÖLBY' },
  { postalCode: '59592', city: 'MJÖLBY' },
  { postalCode: '59593', city: 'MJÖLBY' },
  { postalCode: '59594', city: 'MJÖLBY' },
  { postalCode: '59595', city: 'MJÖLBY' },
  { postalCode: '59596', city: 'MJÖLBY' },
  { postalCode: '59597', city: 'MANTORP' },
  { postalCode: '59600', city: 'SKÄNNINGE' },
  { postalCode: '59601', city: 'SKÄNNINGE' },
  { postalCode: '59620', city: 'SKÄNNINGE' },
  { postalCode: '59621', city: 'SKÄNNINGE' },
  { postalCode: '59622', city: 'SKÄNNINGE' },
  { postalCode: '59623', city: 'SKÄNNINGE' },
  { postalCode: '59631', city: 'SKÄNNINGE' },
  { postalCode: '59632', city: 'SKÄNNINGE' },
  { postalCode: '59633', city: 'SKÄNNINGE' },
  { postalCode: '59634', city: 'SKÄNNINGE' },
  { postalCode: '59636', city: 'VÄDERSTAD' },
  { postalCode: '59691', city: 'SKÄNNINGE' },
  { postalCode: '59692', city: 'SKÄNNINGE' },
  { postalCode: '59693', city: 'SKÄNNINGE' },
  { postalCode: '59695', city: 'VÄDERSTAD' },
  { postalCode: '59700', city: 'ÅTVIDABERG' },
  { postalCode: '59720', city: 'ÅTVIDABERG' },
  { postalCode: '59721', city: 'ÅTVIDABERG' },
  { postalCode: '59722', city: 'ÅTVIDABERG' },
  { postalCode: '59723', city: 'ÅTVIDABERG' },
  { postalCode: '59724', city: 'ÅTVIDABERG' },
  { postalCode: '59725', city: 'ÅTVIDABERG' },
  { postalCode: '59726', city: 'ÅTVIDABERG' },
  { postalCode: '59730', city: 'ÅTVIDABERG' },
  { postalCode: '59731', city: 'ÅTVIDABERG' },
  { postalCode: '59732', city: 'ÅTVIDABERG' },
  { postalCode: '59740', city: 'ÅTVIDABERG' },
  { postalCode: '59742', city: 'ÅTVIDABERG' },
  { postalCode: '59743', city: 'ÅTVIDABERG' },
  { postalCode: '59750', city: 'ÅTVIDABERG' },
  { postalCode: '59752', city: 'ÅTVIDABERG' },
  { postalCode: '59753', city: 'ÅTVIDABERG' },
  { postalCode: '59755', city: 'ÅTVIDABERG' },
  { postalCode: '59780', city: 'ÅTVIDABERG' },
  { postalCode: '59791', city: 'ÅTVIDABERG' },
  { postalCode: '59794', city: 'ÅTVIDABERG' },
  { postalCode: '59796', city: 'ÅTVIDABERG' },
  { postalCode: '59797', city: 'ÅTVIDABERG' },
  { postalCode: '59801', city: 'VIMMERBY' },
  { postalCode: '59805', city: 'MARIANNELUND' },
  { postalCode: '59820', city: 'VIMMERBY' },
  { postalCode: '59821', city: 'VIMMERBY' },
  { postalCode: '59822', city: 'VIMMERBY' },
  { postalCode: '59823', city: 'VIMMERBY' },
  { postalCode: '59830', city: 'VIMMERBY' },
  { postalCode: '59831', city: 'VIMMERBY' },
  { postalCode: '59832', city: 'VIMMERBY' },
  { postalCode: '59834', city: 'VIMMERBY' },
  { postalCode: '59835', city: 'VIMMERBY' },
  { postalCode: '59836', city: 'VIMMERBY' },
  { postalCode: '59837', city: 'VIMMERBY' },
  { postalCode: '59838', city: 'VIMMERBY' },
  { postalCode: '59839', city: 'VIMMERBY' },
  { postalCode: '59840', city: 'VIMMERBY' },
  { postalCode: '59870', city: 'STOREBRO' },
  { postalCode: '59871', city: 'SÖDRA VI' },
  { postalCode: '59872', city: 'SÖDRA VI' },
  { postalCode: '59873', city: 'GULLRINGEN' },
  { postalCode: '59880', city: 'VIMMERBY' },
  { postalCode: '59881', city: 'VIMMERBY' },
  { postalCode: '59884', city: 'VIMMERBY' },
  { postalCode: '59885', city: 'VIMMERBY' },
  { postalCode: '59886', city: 'VIMMERBY' },
  { postalCode: '59891', city: 'VIMMERBY' },
  { postalCode: '59892', city: 'VIMMERBY' },
  { postalCode: '59893', city: 'VIMMERBY' },
  { postalCode: '59894', city: 'VIMMERBY' },
  { postalCode: '59895', city: 'VIMMERBY' },
  { postalCode: '59896', city: 'VIMMERBY' },
  { postalCode: '59897', city: 'MARIANNELUND' },
  { postalCode: '59898', city: 'MARIANNELUND' },
  { postalCode: '59901', city: 'ÖDESHÖG' },
  { postalCode: '59920', city: 'ÖDESHÖG' },
  { postalCode: '59921', city: 'ÖDESHÖG' },
  { postalCode: '59922', city: 'ÖDESHÖG' },
  { postalCode: '59923', city: 'ÖDESHÖG' },
  { postalCode: '59931', city: 'ÖDESHÖG' },
  { postalCode: '59932', city: 'ÖDESHÖG' },
  { postalCode: '59933', city: 'ÖDESHÖG' },
  { postalCode: '59980', city: 'ÖDESHÖG' },
  { postalCode: '59991', city: 'ÖDESHÖG' },
  { postalCode: '59992', city: 'ÖDESHÖG' },
  { postalCode: '59993', city: 'ÖDESHÖG' },
  { postalCode: '59994', city: 'ÖDESHÖG' },
  { postalCode: '60000', city: 'NORRKÖPING' },
  { postalCode: '60002', city: 'NORRKÖPING' },
  { postalCode: '60003', city: 'NORRKÖPING' },
  { postalCode: '60004', city: 'NORRKÖPING' },
  { postalCode: '60005', city: 'NORRKÖPING' },
  { postalCode: '60006', city: 'NORRKÖPING' },
  { postalCode: '60007', city: 'NORRKÖPING' },
  { postalCode: '60011', city: 'NORRKÖPING' },
  { postalCode: '60012', city: 'NORRKÖPING' },
  { postalCode: '60013', city: 'NORRKÖPING' },
  { postalCode: '60014', city: 'NORRKÖPING' },
  { postalCode: '60040', city: 'NORRKÖPING' },
  { postalCode: '60041', city: 'NORRKÖPING' },
  { postalCode: '60042', city: 'NORRKÖPING' },
  { postalCode: '60043', city: 'NORRKÖPING' },
  { postalCode: '60044', city: 'NORRKÖPING' },
  { postalCode: '60045', city: 'NORRKÖPING' },
  { postalCode: '60046', city: 'NORRKÖPING' },
  { postalCode: '60100', city: 'NORRKÖPING' },
  { postalCode: '60102', city: 'NORRKÖPING' },
  { postalCode: '60103', city: 'NORRKÖPING' },
  { postalCode: '60104', city: 'NORRKÖPING' },
  { postalCode: '60105', city: 'NORRKÖPING' },
  { postalCode: '60107', city: 'NORRKÖPING' },
  { postalCode: '60110', city: 'NORRKÖPING' },
  { postalCode: '60114', city: 'NORRKÖPING' },
  { postalCode: '60115', city: 'NORRKÖPING' },
  { postalCode: '60116', city: 'NORRKÖPING' },
  { postalCode: '60117', city: 'NORRKÖPING' },
  { postalCode: '60119', city: 'NORRKÖPING' },
  { postalCode: '60160', city: 'NORRKÖPING' },
  { postalCode: '60169', city: 'NORRKÖPING' },
  { postalCode: '60170', city: 'NORRKÖPING' },
  { postalCode: '60171', city: 'NORRKÖPING' },
  { postalCode: '60172', city: 'NORRKÖPING' },
  { postalCode: '60173', city: 'NORRKÖPING' },
  { postalCode: '60174', city: 'NORRKÖPING' },
  { postalCode: '60176', city: 'NORRKÖPING' },
  { postalCode: '60177', city: 'NORRKÖPING' },
  { postalCode: '60178', city: 'NORRKÖPING' },
  { postalCode: '60179', city: 'NORRKÖPING' },
  { postalCode: '60180', city: 'NORRKÖPING' },
  { postalCode: '60181', city: 'NORRKÖPING' },
  { postalCode: '60182', city: 'NORRKÖPING' },
  { postalCode: '60183', city: 'NORRKÖPING' },
  { postalCode: '60184', city: 'NORRKÖPING' },
  { postalCode: '60185', city: 'NORRKÖPING' },
  { postalCode: '60186', city: 'NORRKÖPING' },
  { postalCode: '60187', city: 'NORRKÖPING' },
  { postalCode: '60188', city: 'NORRKÖPING' },
  { postalCode: '60189', city: 'NORRKÖPING' },
  { postalCode: '60200', city: 'NORRKÖPING' },
  { postalCode: '60201', city: 'NORRKÖPING' },
  { postalCode: '60202', city: 'NORRKÖPING' },
  { postalCode: '60203', city: 'NORRKÖPING' },
  { postalCode: '60204', city: 'NORRKÖPING' },
  { postalCode: '60205', city: 'NORRKÖPING' },
  { postalCode: '60206', city: 'NORRKÖPING' },
  { postalCode: '60208', city: 'NORRKÖPING' },
  { postalCode: '60209', city: 'NORRKÖPING' },
  { postalCode: '60210', city: 'NORRKÖPING' },
  { postalCode: '60211', city: 'NORRKÖPING' },
  { postalCode: '60212', city: 'NORRKÖPING' },
  { postalCode: '60213', city: 'NORRKÖPING' },
  { postalCode: '60214', city: 'NORRKÖPING' },
  { postalCode: '60215', city: 'NORRKÖPING' },
  { postalCode: '60216', city: 'NORRKÖPING' },
  { postalCode: '60217', city: 'NORRKÖPING' },
  { postalCode: '60218', city: 'NORRKÖPING' },
  { postalCode: '60219', city: 'NORRKÖPING' },
  { postalCode: '60220', city: 'NORRKÖPING' },
  { postalCode: '60221', city: 'NORRKÖPING' },
  { postalCode: '60222', city: 'NORRKÖPING' },
  { postalCode: '60223', city: 'NORRKÖPING' },
  { postalCode: '60224', city: 'NORRKÖPING' },
  { postalCode: '60225', city: 'NORRKÖPING' },
  { postalCode: '60226', city: 'NORRKÖPING' },
  { postalCode: '60227', city: 'NORRKÖPING' },
  { postalCode: '60228', city: 'NORRKÖPING' },
  { postalCode: '60229', city: 'NORRKÖPING' },
  { postalCode: '60230', city: 'NORRKÖPING' },
  { postalCode: '60231', city: 'NORRKÖPING' },
  { postalCode: '60232', city: 'NORRKÖPING' },
  { postalCode: '60233', city: 'NORRKÖPING' },
  { postalCode: '60234', city: 'NORRKÖPING' },
  { postalCode: '60235', city: 'NORRKÖPING' },
  { postalCode: '60238', city: 'NORRKÖPING' },
  { postalCode: '60239', city: 'NORRKÖPING' },
  { postalCode: '60240', city: 'NORRKÖPING' },
  { postalCode: '60241', city: 'NORRKÖPING' },
  { postalCode: '60242', city: 'NORRKÖPING' },
  { postalCode: '60243', city: 'NORRKÖPING' },
  { postalCode: '60244', city: 'NORRKÖPING' },
  { postalCode: '60245', city: 'NORRKÖPING' },
  { postalCode: '60246', city: 'NORRKÖPING' },
  { postalCode: '60247', city: 'NORRKÖPING' },
  { postalCode: '60300', city: 'NORRKÖPING' },
  { postalCode: '60301', city: 'NORRKÖPING' },
  { postalCode: '60302', city: 'NORRKÖPING' },
  { postalCode: '60336', city: 'NORRKÖPING' },
  { postalCode: '60337', city: 'NORRKÖPING' },
  { postalCode: '60345', city: 'NORRKÖPING' },
  { postalCode: '60347', city: 'NORRKÖPING' },
  { postalCode: '60348', city: 'NORRKÖPING' },
  { postalCode: '60349', city: 'NORRKÖPING' },
  { postalCode: '60350', city: 'NORRKÖPING' },
  { postalCode: '60351', city: 'NORRKÖPING' },
  { postalCode: '60352', city: 'NORRKÖPING' },
  { postalCode: '60353', city: 'NORRKÖPING' },
  { postalCode: '60354', city: 'NORRKÖPING' },
  { postalCode: '60355', city: 'NORRKÖPING' },
  { postalCode: '60356', city: 'NORRKÖPING' },
  { postalCode: '60357', city: 'NORRKÖPING' },
  { postalCode: '60358', city: 'NORRKÖPING' },
  { postalCode: '60359', city: 'NORRKÖPING' },
  { postalCode: '60360', city: 'NORRKÖPING' },
  { postalCode: '60361', city: 'NORRKÖPING' },
  { postalCode: '60362', city: 'NORRKÖPING' },
  { postalCode: '60363', city: 'NORRKÖPING' },
  { postalCode: '60364', city: 'NORRKÖPING' },
  { postalCode: '60365', city: 'NORRKÖPING' },
  { postalCode: '60366', city: 'NORRKÖPING' },
  { postalCode: '60367', city: 'NORRKÖPING' },
  { postalCode: '60368', city: 'NORRKÖPING' },
  { postalCode: '60369', city: 'NORRKÖPING' },
  { postalCode: '60370', city: 'NORRKÖPING' },
  { postalCode: '60371', city: 'NORRKÖPING' },
  { postalCode: '60372', city: 'NORRKÖPING' },
  { postalCode: '60373', city: 'NORRKÖPING' },
  { postalCode: '60374', city: 'NORRKÖPING' },
  { postalCode: '60375', city: 'NORRKÖPING' },
  { postalCode: '60376', city: 'NORRKÖPING' },
  { postalCode: '60377', city: 'NORRKÖPING' },
  { postalCode: '60378', city: 'NORRKÖPING' },
  { postalCode: '60379', city: 'NORRKÖPING' },
  { postalCode: '60380', city: 'NORRKÖPING' },
  { postalCode: '60381', city: 'NORRKÖPING' },
  { postalCode: '60385', city: 'NORRKÖPING' },
  { postalCode: '60386', city: 'NORRKÖPING' },
  { postalCode: '60387', city: 'NORRKÖPING' },
  { postalCode: '60400', city: 'NORRKÖPING' },
  { postalCode: '60500', city: 'NORRKÖPING' },
  { postalCode: '60560', city: 'SVÄRTINGE' },
  { postalCode: '60570', city: 'SVÄRTINGE' },
  { postalCode: '60580', city: 'SVÄRTINGE' },
  { postalCode: '60584', city: 'SVÄRTINGE' },
  { postalCode: '60591', city: 'NORRKÖPING' },
  { postalCode: '60592', city: 'NORRKÖPING' },
  { postalCode: '60593', city: 'NORRKÖPING' },
  { postalCode: '60594', city: 'NORRKÖPING' },
  { postalCode: '60595', city: 'NORRKÖPING' },
  { postalCode: '60596', city: 'NORRKÖPING' },
  { postalCode: '60597', city: 'NORRKÖPING' },
  { postalCode: '60599', city: 'NORRKÖPING' },
  { postalCode: '60801', city: 'NORRKÖPING' },
  { postalCode: '60803', city: 'NORRKÖPING' },
  { postalCode: '60910', city: 'NORRKÖPING' },
  { postalCode: '60911', city: 'NORRKÖPING' },
  { postalCode: '61024', city: 'VIKBOLANDET' },
  { postalCode: '61025', city: 'VIKBOLANDET' },
  { postalCode: '61027', city: 'VIKBOLANDET' },
  { postalCode: '61029', city: 'VIKBOLANDET' },
  { postalCode: '61031', city: 'VIKBOLANDET' },
  { postalCode: '61033', city: 'VIKBOLANDET' },
  { postalCode: '61100', city: 'NYKÖPING' },
  { postalCode: '61102', city: 'NYKÖPING' },
  { postalCode: '61103', city: 'NYKÖPING' },
  { postalCode: '61104', city: 'NYKÖPING' },
  { postalCode: '61105', city: 'NYKÖPING' },
  { postalCode: '61107', city: 'NYKÖPING' },
  { postalCode: '61108', city: 'NYKÖPING' },
  { postalCode: '61110', city: 'NYKÖPING' },
  { postalCode: '61111', city: 'JÖNÅKER' },
  { postalCode: '61112', city: 'STIGTOMTA' },
  { postalCode: '61114', city: 'TYSTBERGA' },
  { postalCode: '61115', city: 'ENSTABERGA' },
  { postalCode: '61116', city: 'NÄVEKVARN' },
  { postalCode: '61120', city: 'NYKÖPING' },
  { postalCode: '61122', city: 'NYKÖPING' },
  { postalCode: '61123', city: 'NYKÖPING' },
  { postalCode: '61124', city: 'NYKÖPING' },
  { postalCode: '61125', city: 'NYKÖPING' },
  { postalCode: '61126', city: 'NYKÖPING' },
  { postalCode: '61127', city: 'NYKÖPING' },
  { postalCode: '61129', city: 'NYKÖPING' },
  { postalCode: '61130', city: 'NYKÖPING' },
  { postalCode: '61131', city: 'NYKÖPING' },
  { postalCode: '61132', city: 'NYKÖPING' },
  { postalCode: '61133', city: 'NYKÖPING' },
  { postalCode: '61134', city: 'NYKÖPING' },
  { postalCode: '61135', city: 'NYKÖPING' },
  { postalCode: '61136', city: 'NYKÖPING' },
  { postalCode: '61137', city: 'NYKÖPING' },
  { postalCode: '61138', city: 'NYKÖPING' },
  { postalCode: '61139', city: 'NYKÖPING' },
  { postalCode: '61144', city: 'NYKÖPING' },
  { postalCode: '61145', city: 'NYKÖPING' },
  { postalCode: '61146', city: 'NYKÖPING' },
  { postalCode: '61147', city: 'NYKÖPING' },
  { postalCode: '61150', city: 'NYKÖPING' },
  { postalCode: '61155', city: 'NYKÖPING' },
  { postalCode: '61156', city: 'NYKÖPING' },
  { postalCode: '61157', city: 'NYKÖPING' },
  { postalCode: '61160', city: 'NYKÖPING' },
  { postalCode: '61161', city: 'NYKÖPING' },
  { postalCode: '61162', city: 'NYKÖPING' },
  { postalCode: '61163', city: 'NYKÖPING' },
  { postalCode: '61164', city: 'NYKÖPING' },
  { postalCode: '61165', city: 'NYKÖPING' },
  { postalCode: '61166', city: 'NYKÖPING' },
  { postalCode: '61167', city: 'NYKÖPING' },
  { postalCode: '61168', city: 'NYKÖPING' },
  { postalCode: '61170', city: 'JÖNÅKER' },
  { postalCode: '61172', city: 'STIGTOMTA' },
  { postalCode: '61173', city: 'VRENA' },
  { postalCode: '61174', city: 'TYSTBERGA' },
  { postalCode: '61175', city: 'ENSTABERGA' },
  { postalCode: '61176', city: 'NÄVEKVARN' },
  { postalCode: '61179', city: 'NYKÖPING' },
  { postalCode: '61180', city: 'NYKÖPING' },
  { postalCode: '61181', city: 'NYKÖPING' },
  { postalCode: '61182', city: 'NYKÖPING' },
  { postalCode: '61183', city: 'NYKÖPING' },
  { postalCode: '61184', city: 'NYKÖPING' },
  { postalCode: '61185', city: 'NYKÖPING' },
  { postalCode: '61186', city: 'NYKÖPING' },
  { postalCode: '61188', city: 'NYKÖPING' },
  { postalCode: '61189', city: 'NYKÖPING' },
  { postalCode: '61190', city: 'ÅLBERGA' },
  { postalCode: '61191', city: 'NYKÖPING' },
  { postalCode: '61192', city: 'NYKÖPING' },
  { postalCode: '61193', city: 'NYKÖPING' },
  { postalCode: '61194', city: 'NYKÖPING' },
  { postalCode: '61195', city: 'NYKÖPING' },
  { postalCode: '61196', city: 'JÖNÅKER' },
  { postalCode: '61197', city: 'STIGTOMTA' },
  { postalCode: '61198', city: 'VRENA' },
  { postalCode: '61199', city: 'TYSTBERGA' },
  { postalCode: '61201', city: 'FINSPÅNG' },
  { postalCode: '61202', city: 'FINSPÅNG' },
  { postalCode: '61203', city: 'REJMYRE' },
  { postalCode: '61220', city: 'FINSPÅNG' },
  { postalCode: '61221', city: 'FINSPÅNG' },
  { postalCode: '61222', city: 'FINSPÅNG' },
  { postalCode: '61223', city: 'FINSPÅNG' },
  { postalCode: '61224', city: 'FINSPÅNG' },
  { postalCode: '61225', city: 'FINSPÅNG' },
  { postalCode: '61230', city: 'FINSPÅNG' },
  { postalCode: '61231', city: 'FINSPÅNG' },
  { postalCode: '61232', city: 'FINSPÅNG' },
  { postalCode: '61233', city: 'FINSPÅNG' },
  { postalCode: '61234', city: 'FINSPÅNG' },
  { postalCode: '61235', city: 'FINSPÅNG' },
  { postalCode: '61236', city: 'FINSPÅNG' },
  { postalCode: '61237', city: 'FINSPÅNG' },
  { postalCode: '61238', city: 'FINSPÅNG' },
  { postalCode: '61240', city: 'FINSPÅNG' },
  { postalCode: '61241', city: 'FINSPÅNG' },
  { postalCode: '61242', city: 'FINSPÅNG' },
  { postalCode: '61243', city: 'FINSPÅNG' },
  { postalCode: '61244', city: 'FINSPÅNG' },
  { postalCode: '61245', city: 'FINSPÅNG' },
  { postalCode: '61246', city: 'FINSPÅNG' },
  { postalCode: '61271', city: 'REJMYRE' },
  { postalCode: '61272', city: 'REJMYRE' },
  { postalCode: '61273', city: 'LJUSFALLSHAMMAR' },
  { postalCode: '61274', city: 'GRYTGÖL' },
  { postalCode: '61275', city: 'HÄLLESTAD' },
  { postalCode: '61276', city: 'LOTORP' },
  { postalCode: '61280', city: 'FINSPÅNG' },
  { postalCode: '61281', city: 'FINSPÅNG' },
  { postalCode: '61282', city: 'FINSPÅNG' },
  { postalCode: '61283', city: 'FINSPÅNG' },
  { postalCode: '61291', city: 'FINSPÅNG' },
  { postalCode: '61292', city: 'FINSPÅNG' },
  { postalCode: '61293', city: 'FINSPÅNG' },
  { postalCode: '61294', city: 'FINSPÅNG' },
  { postalCode: '61295', city: 'FINSPÅNG' },
  { postalCode: '61296', city: 'FINSPÅNG' },
  { postalCode: '61300', city: 'OXELÖSUND' },
  { postalCode: '61320', city: 'OXELÖSUND' },
  { postalCode: '61321', city: 'OXELÖSUND' },
  { postalCode: '61322', city: 'OXELÖSUND' },
  { postalCode: '61323', city: 'OXELÖSUND' },
  { postalCode: '61330', city: 'OXELÖSUND' },
  { postalCode: '61331', city: 'OXELÖSUND' },
  { postalCode: '61332', city: 'OXELÖSUND' },
  { postalCode: '61333', city: 'OXELÖSUND' },
  { postalCode: '61335', city: 'OXELÖSUND' },
  { postalCode: '61336', city: 'OXELÖSUND' },
  { postalCode: '61337', city: 'OXELÖSUND' },
  { postalCode: '61338', city: 'OXELÖSUND' },
  { postalCode: '61340', city: 'OXELÖSUND' },
  { postalCode: '61341', city: 'OXELÖSUND' },
  { postalCode: '61351', city: 'OXELÖSUND' },
  { postalCode: '61380', city: 'OXELÖSUND' },
  { postalCode: '61381', city: 'OXELÖSUND' },
  { postalCode: '61401', city: 'SÖDERKÖPING' },
  { postalCode: '61420', city: 'SÖDERKÖPING' },
  { postalCode: '61421', city: 'SÖDERKÖPING' },
  { postalCode: '61422', city: 'SÖDERKÖPING' },
  { postalCode: '61430', city: 'SÖDERKÖPING' },
  { postalCode: '61431', city: 'SÖDERKÖPING' },
  { postalCode: '61432', city: 'SÖDERKÖPING' },
  { postalCode: '61433', city: 'SÖDERKÖPING' },
  { postalCode: '61434', city: 'SÖDERKÖPING' },
  { postalCode: '61480', city: 'SÖDERKÖPING' },
  { postalCode: '61481', city: 'SÖDERKÖPING' },
  { postalCode: '61482', city: 'SÖDERKÖPING' },
  { postalCode: '61490', city: 'SÖDERKÖPING' },
  { postalCode: '61492', city: 'SÖDERKÖPING' },
  { postalCode: '61493', city: 'SÖDERKÖPING' },
  { postalCode: '61494', city: 'SÖDERKÖPING' },
  { postalCode: '61495', city: 'SÖDERKÖPING' },
  { postalCode: '61496', city: 'ÖSTRA RYD' },
  { postalCode: '61497', city: 'SÖDERKÖPING' },
  { postalCode: '61498', city: 'SANKT ANNA' },
  { postalCode: '61499', city: 'SÖDERKÖPING' },
  { postalCode: '61501', city: 'VALDEMARSVIK' },
  { postalCode: '61502', city: 'GUSUM' },
  { postalCode: '61520', city: 'VALDEMARSVIK' },
  { postalCode: '61521', city: 'VALDEMARSVIK' },
  { postalCode: '61522', city: 'VALDEMARSVIK' },
  { postalCode: '61523', city: 'VALDEMARSVIK' },
  { postalCode: '61524', city: 'VALDEMARSVIK' },
  { postalCode: '61530', city: 'VALDEMARSVIK' },
  { postalCode: '61531', city: 'VALDEMARSVIK' },
  { postalCode: '61532', city: 'VALDEMARSVIK' },
  { postalCode: '61533', city: 'VALDEMARSVIK' },
  { postalCode: '61571', city: 'GUSUM' },
  { postalCode: '61572', city: 'GUSUM' },
  { postalCode: '61573', city: 'GUSUM' },
  { postalCode: '61574', city: 'RINGARUM' },
  { postalCode: '61575', city: 'RINGARUM' },
  { postalCode: '61580', city: 'VALDEMARSVIK' },
  { postalCode: '61591', city: 'VALDEMARSVIK' },
  { postalCode: '61592', city: 'VALDEMARSVIK' },
  { postalCode: '61593', city: 'VALDEMARSVIK' },
  { postalCode: '61594', city: 'VALDEMARSVIK' },
  { postalCode: '61595', city: 'VALDEMARSVIK' },
  { postalCode: '61596', city: 'GRYT' },
  { postalCode: '61600', city: 'ÅBY' },
  { postalCode: '61621', city: 'ÅBY' },
  { postalCode: '61622', city: 'ÅBY' },
  { postalCode: '61630', city: 'ÅBY' },
  { postalCode: '61631', city: 'ÅBY' },
  { postalCode: '61632', city: 'ÅBY' },
  { postalCode: '61633', city: 'ÅBY' },
  { postalCode: '61634', city: 'ÅBY' },
  { postalCode: '61690', city: 'ÅBY' },
  { postalCode: '61691', city: 'ÅBY' },
  { postalCode: '61700', city: 'SKÄRBLACKA' },
  { postalCode: '61710', city: 'SKÄRBLACKA' },
  { postalCode: '61720', city: 'SKÄRBLACKA' },
  { postalCode: '61721', city: 'SKÄRBLACKA' },
  { postalCode: '61722', city: 'SKÄRBLACKA' },
  { postalCode: '61730', city: 'SKÄRBLACKA' },
  { postalCode: '61731', city: 'SKÄRBLACKA' },
  { postalCode: '61732', city: 'SKÄRBLACKA' },
  { postalCode: '61734', city: 'SKÄRBLACKA' },
  { postalCode: '61771', city: 'KIMSTAD' },
  { postalCode: '61790', city: 'SKÄRBLACKA' },
  { postalCode: '61791', city: 'KIMSTAD' },
  { postalCode: '61792', city: 'NORSHOLM' },
  { postalCode: '61800', city: 'KOLMÅRDEN' },
  { postalCode: '61821', city: 'KOLMÅRDEN' },
  { postalCode: '61830', city: 'KOLMÅRDEN' },
  { postalCode: '61831', city: 'KOLMÅRDEN' },
  { postalCode: '61832', city: 'KOLMÅRDEN' },
  { postalCode: '61833', city: 'KOLMÅRDEN' },
  { postalCode: '61834', city: 'KOLMÅRDEN' },
  { postalCode: '61835', city: 'KOLMÅRDEN' },
  { postalCode: '61892', city: 'KOLMÅRDEN' },
  { postalCode: '61893', city: 'KOLMÅRDEN' },
  { postalCode: '61895', city: 'STAVSJÖ' },
  { postalCode: '61901', city: 'TROSA' },
  { postalCode: '61902', city: 'VAGNHÄRAD' },
  { postalCode: '61920', city: 'TROSA' },
  { postalCode: '61921', city: 'TROSA' },
  { postalCode: '61922', city: 'TROSA' },
  { postalCode: '61923', city: 'TROSA' },
  { postalCode: '61930', city: 'TROSA' },
  { postalCode: '61931', city: 'TROSA' },
  { postalCode: '61932', city: 'TROSA' },
  { postalCode: '61933', city: 'TROSA' },
  { postalCode: '61934', city: 'TROSA' },
  { postalCode: '61935', city: 'TROSA' },
  { postalCode: '61936', city: 'TROSA' },
  { postalCode: '61971', city: 'VAGNHÄRAD' },
  { postalCode: '61972', city: 'VAGNHÄRAD' },
  { postalCode: '61973', city: 'VAGNHÄRAD' },
  { postalCode: '61980', city: 'TROSA' },
  { postalCode: '61991', city: 'TROSA' },
  { postalCode: '61992', city: 'TROSA' },
  { postalCode: '61994', city: 'VAGNHÄRAD' },
  { postalCode: '61995', city: 'VÄSTERLJUNG' },
  { postalCode: '62100', city: 'VISBY' },
  { postalCode: '62111', city: 'VISBY' },
  { postalCode: '62112', city: 'VISBY' },
  { postalCode: '62113', city: 'VISBY' },
  { postalCode: '62114', city: 'VISBY' },
  { postalCode: '62120', city: 'VISBY' },
  { postalCode: '62121', city: 'VISBY' },
  { postalCode: '62122', city: 'VISBY' },
  { postalCode: '62123', city: 'VISBY' },
  { postalCode: '62124', city: 'VISBY' },
  { postalCode: '62125', city: 'VISBY' },
  { postalCode: '62126', city: 'VISBY' },
  { postalCode: '62137', city: 'VISBY' },
  { postalCode: '62138', city: 'VISBY' },
  { postalCode: '62139', city: 'VISBY' },
  { postalCode: '62140', city: 'VISBY' },
  { postalCode: '62141', city: 'VISBY' },
  { postalCode: '62142', city: 'VISBY' },
  { postalCode: '62143', city: 'VISBY' },
  { postalCode: '62144', city: 'VISBY' },
  { postalCode: '62145', city: 'VISBY' },
  { postalCode: '62146', city: 'VISBY' },
  { postalCode: '62147', city: 'VISBY' },
  { postalCode: '62149', city: 'VISBY' },
  { postalCode: '62150', city: 'VISBY' },
  { postalCode: '62151', city: 'VISBY' },
  { postalCode: '62152', city: 'VISBY' },
  { postalCode: '62153', city: 'VISBY' },
  { postalCode: '62154', city: 'VISBY' },
  { postalCode: '62155', city: 'VISBY' },
  { postalCode: '62156', city: 'VISBY' },
  { postalCode: '62157', city: 'VISBY' },
  { postalCode: '62158', city: 'VISBY' },
  { postalCode: '62167', city: 'VISBY' },
  { postalCode: '62170', city: 'VISBY' },
  { postalCode: '62171', city: 'VISBY' },
  { postalCode: '62173', city: 'VISBY' },
  { postalCode: '62174', city: 'VISBY' },
  { postalCode: '62175', city: 'VISBY' },
  { postalCode: '62176', city: 'VISBY' },
  { postalCode: '62177', city: 'VISBY' },
  { postalCode: '62178', city: 'VISBY' },
  { postalCode: '62179', city: 'VISBY' },
  { postalCode: '62180', city: 'VISBY' },
  { postalCode: '62181', city: 'VISBY' },
  { postalCode: '62182', city: 'VISBY' },
  { postalCode: '62183', city: 'VISBY' },
  { postalCode: '62184', city: 'VISBY' },
  { postalCode: '62185', city: 'VISBY' },
  { postalCode: '62187', city: 'VISBY' },
  { postalCode: '62188', city: 'VISBY' },
  { postalCode: '62189', city: 'VISBY' },
  { postalCode: '62191', city: 'VISBY' },
  { postalCode: '62192', city: 'VISBY' },
  { postalCode: '62193', city: 'VISBY' },
  { postalCode: '62194', city: 'VISBY' },
  { postalCode: '62195', city: 'VISBY' },
  { postalCode: '62196', city: 'VISBY' },
  { postalCode: '62199', city: 'VISBY' },
  { postalCode: '62230', city: 'GOTLANDS TOFTA' },
  { postalCode: '62232', city: 'GOTLANDS TOFTA' },
  { postalCode: '62234', city: 'ROMAKLOSTER' },
  { postalCode: '62236', city: 'ROMAKLOSTER' },
  { postalCode: '62238', city: 'ROMAKLOSTER' },
  { postalCode: '62240', city: 'ROMAKLOSTER' },
  { postalCode: '62242', city: 'ROMAKLOSTER' },
  { postalCode: '62244', city: 'ROMAKLOSTER' },
  { postalCode: '62246', city: 'ROMAKLOSTER' },
  { postalCode: '62248', city: 'ROMAKLOSTER' },
  { postalCode: '62250', city: 'ROMAKLOSTER' },
  { postalCode: '62252', city: 'ROMAKLOSTER' },
  { postalCode: '62254', city: 'ROMAKLOSTER' },
  { postalCode: '62256', city: 'DALHEM' },
  { postalCode: '62258', city: 'ROMAKLOSTER' },
  { postalCode: '62259', city: 'VISBY' },
  { postalCode: '62260', city: 'VISBY' },
  { postalCode: '62261', city: 'VISBY' },
  { postalCode: '62265', city: 'GOTLANDS TOFTA' },
  { postalCode: '62266', city: 'GOTLANDS TOFTA' },
  { postalCode: '62270', city: 'GOTLANDS TOFTA' },
  { postalCode: '62275', city: 'VISBY' },
  { postalCode: '62276', city: 'VISBY' },
  { postalCode: '62300', city: 'HEMSE' },
  { postalCode: '62311', city: 'HEMSE' },
  { postalCode: '62312', city: 'HEMSE' },
  { postalCode: '62313', city: 'HEMSE' },
  { postalCode: '62314', city: 'KLINTEHAMN' },
  { postalCode: '62315', city: 'STÅNGA' },
  { postalCode: '62316', city: 'HAVDHEM' },
  { postalCode: '62320', city: 'HEMSE' },
  { postalCode: '62321', city: 'LJUGARN' },
  { postalCode: '62325', city: 'LJUGARN' },
  { postalCode: '62330', city: 'BURGSVIK' },
  { postalCode: '62331', city: 'BURGSVIK' },
  { postalCode: '62332', city: 'BURGSVIK' },
  { postalCode: '62333', city: 'BURGSVIK' },
  { postalCode: '62335', city: 'BURGSVIK' },
  { postalCode: '62336', city: 'BURGSVIK' },
  { postalCode: '62337', city: 'HAVDHEM' },
  { postalCode: '62338', city: 'HAVDHEM' },
  { postalCode: '62340', city: 'HAVDHEM' },
  { postalCode: '62341', city: 'HAVDHEM' },
  { postalCode: '62342', city: 'HAVDHEM' },
  { postalCode: '62343', city: 'HAVDHEM' },
  { postalCode: '62344', city: 'KLINTEHAMN' },
  { postalCode: '62346', city: 'HEMSE' },
  { postalCode: '62347', city: 'HEMSE' },
  { postalCode: '62348', city: 'STÅNGA' },
  { postalCode: '62349', city: 'STÅNGA' },
  { postalCode: '62350', city: 'HEMSE' },
  { postalCode: '62351', city: 'HEMSE' },
  { postalCode: '62352', city: 'HEMSE' },
  { postalCode: '62353', city: 'HEMSE' },
  { postalCode: '62354', city: 'KLINTEHAMN' },
  { postalCode: '62355', city: 'KLINTEHAMN' },
  { postalCode: '62356', city: 'HEMSE' },
  { postalCode: '62357', city: 'HEMSE' },
  { postalCode: '62359', city: 'HEMSE' },
  { postalCode: '62360', city: 'STÅNGA' },
  { postalCode: '62361', city: 'STÅNGA' },
  { postalCode: '62362', city: 'LJUGARN' },
  { postalCode: '62363', city: 'LJUGARN' },
  { postalCode: '62364', city: 'LJUGARN' },
  { postalCode: '62365', city: 'LJUGARN' },
  { postalCode: '62366', city: 'LJUGARN' },
  { postalCode: '62367', city: 'KATTHAMMARSVIK' },
  { postalCode: '62368', city: 'KATTHAMMARSVIK' },
  { postalCode: '62369', city: 'KATTHAMMARSVIK' },
  { postalCode: '62370', city: 'KATTHAMMARSVIK' },
  { postalCode: '62371', city: 'KATTHAMMARSVIK' },
  { postalCode: '62372', city: 'KATTHAMMARSVIK' },
  { postalCode: '62374', city: 'STÅNGA' },
  { postalCode: '62375', city: 'KLINTEHAMN' },
  { postalCode: '62376', city: 'KLINTEHAMN' },
  { postalCode: '62377', city: 'KLINTEHAMN' },
  { postalCode: '62378', city: 'KLINTEHAMN' },
  { postalCode: '62379', city: 'KLINTEHAMN' },
  { postalCode: '62400', city: 'SLITE' },
  { postalCode: '62420', city: 'SLITE' },
  { postalCode: '62421', city: 'SLITE' },
  { postalCode: '62422', city: 'SLITE' },
  { postalCode: '62430', city: 'SLITE' },
  { postalCode: '62432', city: 'SLITE' },
  { postalCode: '62434', city: 'SLITE' },
  { postalCode: '62436', city: 'SLITE' },
  { postalCode: '62438', city: 'TINGSTÄDE' },
  { postalCode: '62440', city: 'TINGSTÄDE' },
  { postalCode: '62442', city: 'TINGSTÄDE' },
  { postalCode: '62444', city: 'TINGSTÄDE' },
  { postalCode: '62446', city: 'SLITE' },
  { postalCode: '62448', city: 'SLITE' },
  { postalCode: '62449', city: 'SLITE' },
  { postalCode: '62450', city: 'LÄRBRO' },
  { postalCode: '62452', city: 'LÄRBRO' },
  { postalCode: '62453', city: 'LÄRBRO' },
  { postalCode: '62454', city: 'LÄRBRO' },
  { postalCode: '62455', city: 'LÄRBRO' },
  { postalCode: '62456', city: 'LÄRBRO' },
  { postalCode: '62458', city: 'LÄRBRO' },
  { postalCode: '62460', city: 'LÄRBRO' },
  { postalCode: '62462', city: 'FÅRÖSUND' },
  { postalCode: '62464', city: 'FÅRÖSUND' },
  { postalCode: '62465', city: 'FÅRÖSUND' },
  { postalCode: '62466', city: 'FÅRÖ' },
  { postalCode: '62467', city: 'FÅRÖ' },
  { postalCode: '62470', city: 'FÅRÖSUND' },
  { postalCode: '63003', city: 'ESKILSTUNA' },
  { postalCode: '63004', city: 'ESKILSTUNA' },
  { postalCode: '63005', city: 'ESKILSTUNA' },
  { postalCode: '63006', city: 'ESKILSTUNA' },
  { postalCode: '63008', city: 'ESKILSTUNA' },
  { postalCode: '63009', city: 'SKOGSTORP' },
  { postalCode: '63014', city: 'ESKILSTUNA' },
  { postalCode: '63101', city: 'ESKILSTUNA' },
  { postalCode: '63102', city: 'ESKILSTUNA' },
  { postalCode: '63103', city: 'ESKILSTUNA' },
  { postalCode: '63104', city: 'ESKILSTUNA' },
  { postalCode: '63105', city: 'ESKILSTUNA' },
  { postalCode: '63106', city: 'ESKILSTUNA' },
  { postalCode: '63107', city: 'ESKILSTUNA' },
  { postalCode: '63108', city: 'ESKILSTUNA' },
  { postalCode: '63180', city: 'ESKILSTUNA' },
  { postalCode: '63181', city: 'ESKILSTUNA' },
  { postalCode: '63185', city: 'ESKILSTUNA' },
  { postalCode: '63186', city: 'ESKILSTUNA' },
  { postalCode: '63187', city: 'ESKILSTUNA' },
  { postalCode: '63188', city: 'ESKILSTUNA' },
  { postalCode: '63189', city: 'ESKILSTUNA' },
  { postalCode: '63193', city: 'ESKILSTUNA' },
  { postalCode: '63194', city: 'ESKILSTUNA' },
  { postalCode: '63201', city: 'ESKILSTUNA' },
  { postalCode: '63217', city: 'ESKILSTUNA' },
  { postalCode: '63218', city: 'ESKILSTUNA' },
  { postalCode: '63219', city: 'ESKILSTUNA' },
  { postalCode: '63220', city: 'ESKILSTUNA' },
  { postalCode: '63221', city: 'ESKILSTUNA' },
  { postalCode: '63222', city: 'ESKILSTUNA' },
  { postalCode: '63223', city: 'ESKILSTUNA' },
  { postalCode: '63224', city: 'ESKILSTUNA' },
  { postalCode: '63225', city: 'ESKILSTUNA' },
  { postalCode: '63226', city: 'ESKILSTUNA' },
  { postalCode: '63227', city: 'ESKILSTUNA' },
  { postalCode: '63228', city: 'ESKILSTUNA' },
  { postalCode: '63229', city: 'ESKILSTUNA' },
  { postalCode: '63230', city: 'ESKILSTUNA' },
  { postalCode: '63231', city: 'ESKILSTUNA' },
  { postalCode: '63232', city: 'ESKILSTUNA' },
  { postalCode: '63233', city: 'ESKILSTUNA' },
  { postalCode: '63234', city: 'ESKILSTUNA' },
  { postalCode: '63235', city: 'ESKILSTUNA' },
  { postalCode: '63236', city: 'ESKILSTUNA' },
  { postalCode: '63237', city: 'ESKILSTUNA' },
  { postalCode: '63239', city: 'ESKILSTUNA' },
  { postalCode: '63301', city: 'ESKILSTUNA' },
  { postalCode: '63302', city: 'ESKILSTUNA' },
  { postalCode: '63340', city: 'ESKILSTUNA' },
  { postalCode: '63341', city: 'ESKILSTUNA' },
  { postalCode: '63342', city: 'ESKILSTUNA' },
  { postalCode: '63343', city: 'ESKILSTUNA' },
  { postalCode: '63344', city: 'ESKILSTUNA' },
  { postalCode: '63345', city: 'ESKILSTUNA' },
  { postalCode: '63346', city: 'ESKILSTUNA' },
  { postalCode: '63347', city: 'ESKILSTUNA' },
  { postalCode: '63348', city: 'ESKILSTUNA' },
  { postalCode: '63349', city: 'ESKILSTUNA' },
  { postalCode: '63350', city: 'ESKILSTUNA' },
  { postalCode: '63351', city: 'ESKILSTUNA' },
  { postalCode: '63352', city: 'ESKILSTUNA' },
  { postalCode: '63353', city: 'ESKILSTUNA' },
  { postalCode: '63354', city: 'ESKILSTUNA' },
  { postalCode: '63355', city: 'ESKILSTUNA' },
  { postalCode: '63356', city: 'ESKILSTUNA' },
  { postalCode: '63357', city: 'ESKILSTUNA' },
  { postalCode: '63358', city: 'ESKILSTUNA' },
  { postalCode: '63359', city: 'ESKILSTUNA' },
  { postalCode: '63360', city: 'ESKILSTUNA' },
  { postalCode: '63361', city: 'ESKILSTUNA' },
  { postalCode: '63362', city: 'ESKILSTUNA' },
  { postalCode: '63363', city: 'ESKILSTUNA' },
  { postalCode: '63369', city: 'SKOGSTORP' },
  { postalCode: '63370', city: 'HÅLLSTA' },
  { postalCode: '63401', city: 'ESKILSTUNA' },
  { postalCode: '63502', city: 'ESKILSTUNA' },
  { postalCode: '63503', city: 'ESKILSTUNA' },
  { postalCode: '63504', city: 'ESKILSTUNA' },
  { postalCode: '63505', city: 'ESKILSTUNA' },
  { postalCode: '63506', city: 'ESKILSTUNA' },
  { postalCode: '63507', city: 'ESKILSTUNA' },
  { postalCode: '63508', city: 'ESKILSTUNA' },
  { postalCode: '63509', city: 'ESKILSTUNA' },
  { postalCode: '63510', city: 'ESKILSTUNA' },
  { postalCode: '63511', city: 'ESKILSTUNA' },
  { postalCode: '63512', city: 'ESKILSTUNA' },
  { postalCode: '63513', city: 'ESKILSTUNA' },
  { postalCode: '63514', city: 'ESKILSTUNA' },
  { postalCode: '63517', city: 'NÄSHULTA' },
  { postalCode: '63518', city: 'HUSBY-REKARNE' },
  { postalCode: '63519', city: 'ESKILSTUNA' },
  { postalCode: '63520', city: 'ESKILSTUNA' },
  { postalCode: '63530', city: 'KVICKSUND' },
  { postalCode: '63531', city: 'KVICKSUND' },
  { postalCode: '63532', city: 'KVICKSUND' },
  { postalCode: '63533', city: 'STORA SUNDBY' },
  { postalCode: '63534', city: 'STORA SUNDBY' },
  { postalCode: '63535', city: 'STORA SUNDBY' },
  { postalCode: '63536', city: 'ÄRLA' },
  { postalCode: '63537', city: 'ÄRLA' },
  { postalCode: '63538', city: 'ÄRLA' },
  { postalCode: '63801', city: 'ESKILSTUNA' },
  { postalCode: '64101', city: 'KATRINEHOLM' },
  { postalCode: '64102', city: 'KATRINEHOLM' },
  { postalCode: '64103', city: 'KATRINEHOLM' },
  { postalCode: '64105', city: 'VALLA' },
  { postalCode: '64120', city: 'KATRINEHOLM' },
  { postalCode: '64121', city: 'KATRINEHOLM' },
  { postalCode: '64122', city: 'KATRINEHOLM' },
  { postalCode: '64123', city: 'KATRINEHOLM' },
  { postalCode: '64127', city: 'KATRINEHOLM' },
  { postalCode: '64130', city: 'KATRINEHOLM' },
  { postalCode: '64131', city: 'KATRINEHOLM' },
  { postalCode: '64132', city: 'KATRINEHOLM' },
  { postalCode: '64133', city: 'KATRINEHOLM' },
  { postalCode: '64134', city: 'KATRINEHOLM' },
  { postalCode: '64135', city: 'KATRINEHOLM' },
  { postalCode: '64136', city: 'KATRINEHOLM' },
  { postalCode: '64137', city: 'KATRINEHOLM' },
  { postalCode: '64138', city: 'KATRINEHOLM' },
  { postalCode: '64139', city: 'KATRINEHOLM' },
  { postalCode: '64145', city: 'KATRINEHOLM' },
  { postalCode: '64146', city: 'KATRINEHOLM' },
  { postalCode: '64147', city: 'KATRINEHOLM' },
  { postalCode: '64148', city: 'KATRINEHOLM' },
  { postalCode: '64149', city: 'KATRINEHOLM' },
  { postalCode: '64150', city: 'KATRINEHOLM' },
  { postalCode: '64151', city: 'KATRINEHOLM' },
  { postalCode: '64152', city: 'KATRINEHOLM' },
  { postalCode: '64153', city: 'KATRINEHOLM' },
  { postalCode: '64161', city: 'VALLA' },
  { postalCode: '64162', city: 'VALLA' },
  { postalCode: '64163', city: 'BJÖRKVIK' },
  { postalCode: '64164', city: 'SKÖLDINGE' },
  { postalCode: '64180', city: 'KATRINEHOLM' },
  { postalCode: '64181', city: 'KATRINEHOLM' },
  { postalCode: '64182', city: 'KATRINEHOLM' },
  { postalCode: '64191', city: 'KATRINEHOLM' },
  { postalCode: '64192', city: 'KATRINEHOLM' },
  { postalCode: '64193', city: 'KATRINEHOLM' },
  { postalCode: '64194', city: 'KATRINEHOLM' },
  { postalCode: '64195', city: 'KATRINEHOLM' },
  { postalCode: '64196', city: 'KATRINEHOLM' },
  { postalCode: '64197', city: 'KATRINEHOLM' },
  { postalCode: '64198', city: 'BJÖRKVIK' },
  { postalCode: '64199', city: 'SKÖLDINGE' },
  { postalCode: '64201', city: 'FLEN' },
  { postalCode: '64206', city: 'MALMKÖPING' },
  { postalCode: '64220', city: 'FLEN' },
  { postalCode: '64221', city: 'FLEN' },
  { postalCode: '64222', city: 'FLEN' },
  { postalCode: '64223', city: 'FLEN' },
  { postalCode: '64224', city: 'FLEN' },
  { postalCode: '64228', city: 'FLEN' },
  { postalCode: '64231', city: 'FLEN' },
  { postalCode: '64232', city: 'FLEN' },
  { postalCode: '64233', city: 'FLEN' },
  { postalCode: '64234', city: 'FLEN' },
  { postalCode: '64235', city: 'FLEN' },
  { postalCode: '64236', city: 'FLEN' },
  { postalCode: '64237', city: 'FLEN' },
  { postalCode: '64260', city: 'MALMKÖPING' },
  { postalCode: '64261', city: 'MALMKÖPING' },
  { postalCode: '64262', city: 'BETTNA' },
  { postalCode: '64263', city: 'MELLÖSA' },
  { postalCode: '64281', city: 'FLEN' },
  { postalCode: '64283', city: 'FLEN' },
  { postalCode: '64291', city: 'FLEN' },
  { postalCode: '64292', city: 'FLEN' },
  { postalCode: '64293', city: 'FLEN' },
  { postalCode: '64294', city: 'FLEN' },
  { postalCode: '64295', city: 'FLEN' },
  { postalCode: '64296', city: 'MALMKÖPING' },
  { postalCode: '64297', city: 'BETTNA' },
  { postalCode: '64298', city: 'BETTNA' },
  { postalCode: '64301', city: 'VINGÅKER' },
  { postalCode: '64306', city: 'JULITA' },
  { postalCode: '64320', city: 'VINGÅKER' },
  { postalCode: '64321', city: 'VINGÅKER' },
  { postalCode: '64322', city: 'VINGÅKER' },
  { postalCode: '64330', city: 'VINGÅKER' },
  { postalCode: '64331', city: 'VINGÅKER' },
  { postalCode: '64332', city: 'VINGÅKER' },
  { postalCode: '64333', city: 'VINGÅKER' },
  { postalCode: '64360', city: 'JULITA' },
  { postalCode: '64362', city: 'HÖGSJÖ' },
  { postalCode: '64380', city: 'VINGÅKER' },
  { postalCode: '64391', city: 'VINGÅKER' },
  { postalCode: '64392', city: 'VINGÅKER' },
  { postalCode: '64393', city: 'VINGÅKER' },
  { postalCode: '64394', city: 'VINGÅKER' },
  { postalCode: '64395', city: 'VINGÅKER' },
  { postalCode: '64396', city: 'JULITA' },
  { postalCode: '64397', city: 'VÄSTRA NÄSHULTA' },
  { postalCode: '64398', city: 'JULITA' },
  { postalCode: '64399', city: 'HÖGSJÖ' },
  { postalCode: '64401', city: 'TORSHÄLLA' },
  { postalCode: '64420', city: 'TORSHÄLLA' },
  { postalCode: '64421', city: 'TORSHÄLLA' },
  { postalCode: '64422', city: 'TORSHÄLLA' },
  { postalCode: '64430', city: 'TORSHÄLLA' },
  { postalCode: '64431', city: 'TORSHÄLLA' },
  { postalCode: '64432', city: 'TORSHÄLLA' },
  { postalCode: '64433', city: 'TORSHÄLLA' },
  { postalCode: '64434', city: 'TORSHÄLLA' },
  { postalCode: '64435', city: 'TORSHÄLLA' },
  { postalCode: '64436', city: 'TORSHÄLLA' },
  { postalCode: '64480', city: 'TORSHÄLLA' },
  { postalCode: '64500', city: 'STRÄNGNÄS' },
  { postalCode: '64501', city: 'STRÄNGNÄS' },
  { postalCode: '64502', city: 'STRÄNGNÄS' },
  { postalCode: '64506', city: 'STALLARHOLMEN' },
  { postalCode: '64510', city: 'STRÄNGNÄS' },
  { postalCode: '64520', city: 'STRÄNGNÄS' },
  { postalCode: '64521', city: 'STRÄNGNÄS' },
  { postalCode: '64522', city: 'STRÄNGNÄS' },
  { postalCode: '64523', city: 'STRÄNGNÄS' },
  { postalCode: '64524', city: 'STRÄNGNÄS' },
  { postalCode: '64525', city: 'STRÄNGNÄS' },
  { postalCode: '64530', city: 'STRÄNGNÄS' },
  { postalCode: '64531', city: 'STRÄNGNÄS' },
  { postalCode: '64532', city: 'STRÄNGNÄS' },
  { postalCode: '64533', city: 'STRÄNGNÄS' },
  { postalCode: '64534', city: 'STRÄNGNÄS' },
  { postalCode: '64535', city: 'STRÄNGNÄS' },
  { postalCode: '64540', city: 'STRÄNGNÄS' },
  { postalCode: '64541', city: 'STRÄNGNÄS' },
  { postalCode: '64542', city: 'STRÄNGNÄS' },
  { postalCode: '64543', city: 'STRÄNGNÄS' },
  { postalCode: '64544', city: 'STRÄNGNÄS' },
  { postalCode: '64545', city: 'STRÄNGNÄS' },
  { postalCode: '64547', city: 'STRÄNGNÄS' },
  { postalCode: '64550', city: 'STRÄNGNÄS' },
  { postalCode: '64551', city: 'STRÄNGNÄS' },
  { postalCode: '64552', city: 'STRÄNGNÄS' },
  { postalCode: '64561', city: 'STALLARHOLMEN' },
  { postalCode: '64562', city: 'STALLARHOLMEN' },
  { postalCode: '64571', city: 'STRÄNGNÄS' },
  { postalCode: '64580', city: 'STRÄNGNÄS' },
  { postalCode: '64582', city: 'STRÄNGNÄS' },
  { postalCode: '64583', city: 'STRÄNGNÄS' },
  { postalCode: '64584', city: 'STRÄNGNÄS' },
  { postalCode: '64587', city: 'STRÄNGNÄS' },
  { postalCode: '64588', city: 'STRÄNGNÄS' },
  { postalCode: '64591', city: 'STRÄNGNÄS' },
  { postalCode: '64592', city: 'STRÄNGNÄS' },
  { postalCode: '64593', city: 'STRÄNGNÄS' },
  { postalCode: '64594', city: 'STRÄNGNÄS' },
  { postalCode: '64596', city: 'STALLARHOLMEN' },
  { postalCode: '64597', city: 'STALLARHOLMEN' },
  { postalCode: '64601', city: 'GNESTA' },
  { postalCode: '64602', city: 'BJÖRNLUNDA' },
  { postalCode: '64603', city: 'STJÄRNHOV' },
  { postalCode: '64620', city: 'GNESTA' },
  { postalCode: '64621', city: 'GNESTA' },
  { postalCode: '64622', city: 'GNESTA' },
  { postalCode: '64623', city: 'GNESTA' },
  { postalCode: '64630', city: 'GNESTA' },
  { postalCode: '64631', city: 'GNESTA' },
  { postalCode: '64632', city: 'GNESTA' },
  { postalCode: '64633', city: 'GNESTA' },
  { postalCode: '64634', city: 'GNESTA' },
  { postalCode: '64635', city: 'GNESTA' },
  { postalCode: '64650', city: 'BJÖRNLUNDA' },
  { postalCode: '64651', city: 'STJÄRNHOV' },
  { postalCode: '64680', city: 'GNESTA' },
  { postalCode: '64691', city: 'GNESTA' },
  { postalCode: '64692', city: 'GNESTA' },
  { postalCode: '64693', city: 'GNESTA' },
  { postalCode: '64694', city: 'BJÖRNLUNDA' },
  { postalCode: '64695', city: 'BJÖRNLUNDA' },
  { postalCode: '64696', city: 'STJÄRNHOV' },
  { postalCode: '64705', city: 'ÅKERS STYCKEBRUK' },
  { postalCode: '64720', city: 'MARIEFRED' },
  { postalCode: '64721', city: 'MARIEFRED' },
  { postalCode: '64722', city: 'MARIEFRED' },
  { postalCode: '64723', city: 'MARIEFRED' },
  { postalCode: '64724', city: 'MARIEFRED' },
  { postalCode: '64730', city: 'MARIEFRED' },
  { postalCode: '64731', city: 'MARIEFRED' },
  { postalCode: '64732', city: 'MARIEFRED' },
  { postalCode: '64733', city: 'MARIEFRED' },
  { postalCode: '64735', city: 'MARIEFRED' },
  { postalCode: '64750', city: 'ÅKERS STYCKEBRUK' },
  { postalCode: '64751', city: 'ÅKERS STYCKEBRUK' },
  { postalCode: '64752', city: 'ÅKERS STYCKEBRUK' },
  { postalCode: '64783', city: 'ÅKERS STYCKEBRUK' },
  { postalCode: '64791', city: 'MARIEFRED' },
  { postalCode: '64792', city: 'MARIEFRED' },
  { postalCode: '64793', city: 'MARIEFRED' },
  { postalCode: '64795', city: 'ÅKERS STYCKEBRUK' },
  { postalCode: '64806', city: 'HÄLLEFORSNÄS' },
  { postalCode: '64830', city: 'HÄLLEFORSNÄS' },
  { postalCode: '64831', city: 'HÄLLEFORSNÄS' },
  { postalCode: '64930', city: 'SPARREHOLM' },
  { postalCode: '64991', city: 'SPARREHOLM' },
  { postalCode: '65001', city: 'KARLSTAD' },
  { postalCode: '65002', city: 'KARLSTAD' },
  { postalCode: '65004', city: 'KARLSTAD' },
  { postalCode: '65005', city: 'KARLSTAD' },
  { postalCode: '65007', city: 'KARLSTAD' },
  { postalCode: '65008', city: 'KARLSTAD' },
  { postalCode: '65009', city: 'KARLSTAD' },
  { postalCode: '65012', city: 'KARLSTAD' },
  { postalCode: '65100', city: 'KARLSTAD' },
  { postalCode: '65102', city: 'KARLSTAD' },
  { postalCode: '65103', city: 'KARLSTAD' },
  { postalCode: '65104', city: 'KARLSTAD' },
  { postalCode: '65105', city: 'KARLSTAD' },
  { postalCode: '65106', city: 'KARLSTAD' },
  { postalCode: '65107', city: 'KARLSTAD' },
  { postalCode: '65108', city: 'KARLSTAD' },
  { postalCode: '65109', city: 'KARLSTAD' },
  { postalCode: '65110', city: 'KARLSTAD' },
  { postalCode: '65111', city: 'KARLSTAD' },
  { postalCode: '65112', city: 'KARLSTAD' },
  { postalCode: '65113', city: 'KARLSTAD' },
  { postalCode: '65114', city: 'KARLSTAD' },
  { postalCode: '65115', city: 'KARLSTAD' },
  { postalCode: '65119', city: 'KARLSTAD' },
  { postalCode: '65121', city: 'KARLSTAD' },
  { postalCode: '65122', city: 'KARLSTAD' },
  { postalCode: '65128', city: 'KARLSTAD' },
  { postalCode: '65180', city: 'KARLSTAD' },
  { postalCode: '65181', city: 'KARLSTAD' },
  { postalCode: '65182', city: 'KARLSTAD' },
  { postalCode: '65183', city: 'KARLSTAD' },
  { postalCode: '65184', city: 'KARLSTAD' },
  { postalCode: '65185', city: 'KARLSTAD' },
  { postalCode: '65186', city: 'KARLSTAD' },
  { postalCode: '65187', city: 'KARLSTAD' },
  { postalCode: '65188', city: 'KARLSTAD' },
  { postalCode: '65189', city: 'KARLSTAD' },
  { postalCode: '65201', city: 'KARLSTAD' },
  { postalCode: '65210', city: 'KARLSTAD' },
  { postalCode: '65211', city: 'KARLSTAD' },
  { postalCode: '65212', city: 'KARLSTAD' },
  { postalCode: '65213', city: 'KARLSTAD' },
  { postalCode: '65214', city: 'KARLSTAD' },
  { postalCode: '65215', city: 'KARLSTAD' },
  { postalCode: '65216', city: 'KARLSTAD' },
  { postalCode: '65217', city: 'KARLSTAD' },
  { postalCode: '65218', city: 'KARLSTAD' },
  { postalCode: '65219', city: 'KARLSTAD' },
  { postalCode: '65220', city: 'KARLSTAD' },
  { postalCode: '65221', city: 'KARLSTAD' },
  { postalCode: '65222', city: 'KARLSTAD' },
  { postalCode: '65223', city: 'KARLSTAD' },
  { postalCode: '65224', city: 'KARLSTAD' },
  { postalCode: '65225', city: 'KARLSTAD' },
  { postalCode: '65226', city: 'KARLSTAD' },
  { postalCode: '65227', city: 'KARLSTAD' },
  { postalCode: '65228', city: 'KARLSTAD' },
  { postalCode: '65229', city: 'KARLSTAD' },
  { postalCode: '65230', city: 'KARLSTAD' },
  { postalCode: '65231', city: 'KARLSTAD' },
  { postalCode: '65232', city: 'KARLSTAD' },
  { postalCode: '65233', city: 'KARLSTAD' },
  { postalCode: '65301', city: 'KARLSTAD' },
  { postalCode: '65338', city: 'KARLSTAD' },
  { postalCode: '65339', city: 'KARLSTAD' },
  { postalCode: '65340', city: 'KARLSTAD' },
  { postalCode: '65341', city: 'KARLSTAD' },
  { postalCode: '65342', city: 'KARLSTAD' },
  { postalCode: '65343', city: 'KARLSTAD' },
  { postalCode: '65344', city: 'KARLSTAD' },
  { postalCode: '65345', city: 'KARLSTAD' },
  { postalCode: '65346', city: 'KARLSTAD' },
  { postalCode: '65347', city: 'KARLSTAD' },
  { postalCode: '65348', city: 'KARLSTAD' },
  { postalCode: '65349', city: 'KARLSTAD' },
  { postalCode: '65350', city: 'KARLSTAD' },
  { postalCode: '65351', city: 'KARLSTAD' },
  { postalCode: '65352', city: 'KARLSTAD' },
  { postalCode: '65455', city: 'KARLSTAD' },
  { postalCode: '65457', city: 'KARLSTAD' },
  { postalCode: '65458', city: 'KARLSTAD' },
  { postalCode: '65459', city: 'KARLSTAD' },
  { postalCode: '65460', city: 'KARLSTAD' },
  { postalCode: '65461', city: 'KARLSTAD' },
  { postalCode: '65462', city: 'KARLSTAD' },
  { postalCode: '65463', city: 'KARLSTAD' },
  { postalCode: '65464', city: 'KARLSTAD' },
  { postalCode: '65465', city: 'KARLSTAD' },
  { postalCode: '65466', city: 'KARLSTAD' },
  { postalCode: '65467', city: 'KARLSTAD' },
  { postalCode: '65468', city: 'KARLSTAD' },
  { postalCode: '65469', city: 'KARLSTAD' },
  { postalCode: '65502', city: 'MOLKOM' },
  { postalCode: '65560', city: 'MOLKOM' },
  { postalCode: '65561', city: 'MOLKOM' },
  { postalCode: '65591', city: 'KARLSTAD' },
  { postalCode: '65592', city: 'KARLSTAD' },
  { postalCode: '65593', city: 'KARLSTAD' },
  { postalCode: '65594', city: 'KARLSTAD' },
  { postalCode: '65595', city: 'VÄSE' },
  { postalCode: '65596', city: 'VÄSE' },
  { postalCode: '65597', city: 'MOLKOM' },
  { postalCode: '65598', city: 'MOLKOM' },
  { postalCode: '65601', city: 'KARLSTAD' },
  { postalCode: '65631', city: 'KARLSTAD' },
  { postalCode: '65632', city: 'KARLSTAD' },
  { postalCode: '65633', city: 'KARLSTAD' },
  { postalCode: '65634', city: 'KARLSTAD' },
  { postalCode: '65635', city: 'KARLSTAD' },
  { postalCode: '65636', city: 'KARLSTAD' },
  { postalCode: '65637', city: 'KARLSTAD' },
  { postalCode: '65638', city: 'KARLSTAD' },
  { postalCode: '65639', city: 'KARLSTAD' },
  { postalCode: '65671', city: 'SKATTKÄRR' },
  { postalCode: '65672', city: 'SKATTKÄRR' },
  { postalCode: '65800', city: 'KARLSTAD' },
  { postalCode: '65997', city: 'KARLSTAD' },
  { postalCode: '65998', city: 'KARLSTAD' },
  { postalCode: '65999', city: 'KARLSTAD' },
  { postalCode: '66100', city: 'SÄFFLE' },
  { postalCode: '66120', city: 'SÄFFLE' },
  { postalCode: '66121', city: 'SÄFFLE' },
  { postalCode: '66122', city: 'SÄFFLE' },
  { postalCode: '66123', city: 'SÄFFLE' },
  { postalCode: '66124', city: 'SÄFFLE' },
  { postalCode: '66125', city: 'SÄFFLE' },
  { postalCode: '66126', city: 'SÄFFLE' },
  { postalCode: '66129', city: 'SÄFFLE' },
  { postalCode: '66130', city: 'SÄFFLE' },
  { postalCode: '66131', city: 'SÄFFLE' },
  { postalCode: '66132', city: 'SÄFFLE' },
  { postalCode: '66133', city: 'SÄFFLE' },
  { postalCode: '66140', city: 'SÄFFLE' },
  { postalCode: '66141', city: 'SÄFFLE' },
  { postalCode: '66142', city: 'SÄFFLE' },
  { postalCode: '66143', city: 'SÄFFLE' },
  { postalCode: '66180', city: 'SÄFFLE' },
  { postalCode: '66181', city: 'SÄFFLE' },
  { postalCode: '66191', city: 'SÄFFLE' },
  { postalCode: '66192', city: 'SÄFFLE' },
  { postalCode: '66193', city: 'SÄFFLE' },
  { postalCode: '66194', city: 'SÄFFLE' },
  { postalCode: '66195', city: 'VÄRMLANDS NYSÄTER' },
  { postalCode: '66196', city: 'LÅNGSERUD' },
  { postalCode: '66200', city: 'ÅMÅL' },
  { postalCode: '66203', city: 'SVANSKOG' },
  { postalCode: '66220', city: 'ÅMÅL' },
  { postalCode: '66221', city: 'ÅMÅL' },
  { postalCode: '66222', city: 'ÅMÅL' },
  { postalCode: '66223', city: 'ÅMÅL' },
  { postalCode: '66224', city: 'ÅMÅL' },
  { postalCode: '66227', city: 'ÅMÅL' },
  { postalCode: '66230', city: 'ÅMÅL' },
  { postalCode: '66231', city: 'ÅMÅL' },
  { postalCode: '66232', city: 'ÅMÅL' },
  { postalCode: '66233', city: 'ÅMÅL' },
  { postalCode: '66234', city: 'ÅMÅL' },
  { postalCode: '66235', city: 'ÅMÅL' },
  { postalCode: '66236', city: 'ÅMÅL' },
  { postalCode: '66237', city: 'ÅMÅL' },
  { postalCode: '66250', city: 'SVANSKOG' },
  { postalCode: '66291', city: 'ÅMÅL' },
  { postalCode: '66292', city: 'ÅMÅL' },
  { postalCode: '66295', city: 'FENGERSFORS' },
  { postalCode: '66296', city: 'SVANSKOG' },
  { postalCode: '66297', city: 'ÅNIMSKOG' },
  { postalCode: '66298', city: 'TÖSSE' },
  { postalCode: '66301', city: 'SKOGHALL' },
  { postalCode: '66311', city: 'HAMMARÖ' },
  { postalCode: '66312', city: 'HAMMARÖ' },
  { postalCode: '66320', city: 'SKOGHALL' },
  { postalCode: '66321', city: 'SKOGHALL' },
  { postalCode: '66322', city: 'SKOGHALL' },
  { postalCode: '66329', city: 'SKOGHALL' },
  { postalCode: '66330', city: 'SKOGHALL' },
  { postalCode: '66331', city: 'SKOGHALL' },
  { postalCode: '66332', city: 'SKOGHALL' },
  { postalCode: '66333', city: 'SKOGHALL' },
  { postalCode: '66334', city: 'SKOGHALL' },
  { postalCode: '66340', city: 'HAMMARÖ' },
  { postalCode: '66341', city: 'HAMMARÖ' },
  { postalCode: '66342', city: 'HAMMARÖ' },
  { postalCode: '66343', city: 'HAMMARÖ' },
  { postalCode: '66391', city: 'HAMMARÖ' },
  { postalCode: '66392', city: 'HAMMARÖ' },
  { postalCode: '66401', city: 'GRUMS' },
  { postalCode: '66420', city: 'GRUMS' },
  { postalCode: '66421', city: 'GRUMS' },
  { postalCode: '66422', city: 'GRUMS' },
  { postalCode: '66423', city: 'GRUMS' },
  { postalCode: '66428', city: 'GRUMS' },
  { postalCode: '66430', city: 'GRUMS' },
  { postalCode: '66431', city: 'GRUMS' },
  { postalCode: '66432', city: 'GRUMS' },
  { postalCode: '66433', city: 'GRUMS' },
  { postalCode: '66434', city: 'GRUMS' },
  { postalCode: '66440', city: 'SLOTTSBRON' },
  { postalCode: '66450', city: 'VÅLBERG' },
  { postalCode: '66451', city: 'VÅLBERG' },
  { postalCode: '66452', city: 'VÅLBERG' },
  { postalCode: '66480', city: 'GRUMS' },
  { postalCode: '66491', city: 'GRUMS' },
  { postalCode: '66492', city: 'VÄRMSKOG' },
  { postalCode: '66493', city: 'EDSVALLA' },
  { postalCode: '66494', city: 'VÅLBERG' },
  { postalCode: '66495', city: 'BORGVIK' },
  { postalCode: '66496', city: 'SEGMON' },
  { postalCode: '66501', city: 'KIL' },
  { postalCode: '66520', city: 'KIL' },
  { postalCode: '66521', city: 'KIL' },
  { postalCode: '66522', city: 'KIL' },
  { postalCode: '66523', city: 'KIL' },
  { postalCode: '66524', city: 'KIL' },
  { postalCode: '66525', city: 'KIL' },
  { postalCode: '66530', city: 'KIL' },
  { postalCode: '66531', city: 'KIL' },
  { postalCode: '66532', city: 'KIL' },
  { postalCode: '66533', city: 'KIL' },
  { postalCode: '66534', city: 'KIL' },
  { postalCode: '66535', city: 'KIL' },
  { postalCode: '66591', city: 'KIL' },
  { postalCode: '66592', city: 'KIL' },
  { postalCode: '66593', city: 'KIL' },
  { postalCode: '66594', city: 'FAGERÅS' },
  { postalCode: '66600', city: 'BENGTSFORS' },
  { postalCode: '66620', city: 'BENGTSFORS' },
  { postalCode: '66621', city: 'BENGTSFORS' },
  { postalCode: '66622', city: 'BENGTSFORS' },
  { postalCode: '66623', city: 'BENGTSFORS' },
  { postalCode: '66624', city: 'BENGTSFORS' },
  { postalCode: '66625', city: 'BENGTSFORS' },
  { postalCode: '66630', city: 'BENGTSFORS' },
  { postalCode: '66631', city: 'BENGTSFORS' },
  { postalCode: '66632', city: 'BENGTSFORS' },
  { postalCode: '66640', city: 'SKÅPAFORS' },
  { postalCode: '66641', city: 'BILLINGSFORS' },
  { postalCode: '66691', city: 'BENGTSFORS' },
  { postalCode: '66692', city: 'BENGTSFORS' },
  { postalCode: '66693', city: 'GUSTAVSFORS' },
  { postalCode: '66694', city: 'DALS LÅNGED' },
  { postalCode: '66695', city: 'DALS LÅNGED' },
  { postalCode: '66701', city: 'FORSHAGA' },
  { postalCode: '66720', city: 'FORSHAGA' },
  { postalCode: '66721', city: 'FORSHAGA' },
  { postalCode: '66722', city: 'FORSHAGA' },
  { postalCode: '66730', city: 'FORSHAGA' },
  { postalCode: '66731', city: 'FORSHAGA' },
  { postalCode: '66732', city: 'FORSHAGA' },
  { postalCode: '66733', city: 'FORSHAGA' },
  { postalCode: '66734', city: 'FORSHAGA' },
  { postalCode: '66791', city: 'FORSHAGA' },
  { postalCode: '66800', city: 'ED' },
  { postalCode: '66820', city: 'ED' },
  { postalCode: '66821', city: 'ED' },
  { postalCode: '66822', city: 'ED' },
  { postalCode: '66824', city: 'ED' },
  { postalCode: '66830', city: 'ED' },
  { postalCode: '66831', city: 'ED' },
  { postalCode: '66832', city: 'ED' },
  { postalCode: '66840', city: 'BÄCKEFORS' },
  { postalCode: '66888', city: 'BÄCKEFORS' },
  { postalCode: '66891', city: 'ED' },
  { postalCode: '66892', city: 'ED' },
  { postalCode: '66893', city: 'ED' },
  { postalCode: '66894', city: 'BÄCKEFORS' },
  { postalCode: '66895', city: 'ÖDSKÖLT' },
  { postalCode: '66921', city: 'DEJE' },
  { postalCode: '66922', city: 'DEJE' },
  { postalCode: '66930', city: 'DEJE' },
  { postalCode: '66931', city: 'DEJE' },
  { postalCode: '66932', city: 'DEJE' },
  { postalCode: '66991', city: 'DEJE' },
  { postalCode: '66992', city: 'DEJE' },
  { postalCode: '67101', city: 'ARVIKA' },
  { postalCode: '67120', city: 'ARVIKA' },
  { postalCode: '67121', city: 'ARVIKA' },
  { postalCode: '67122', city: 'ARVIKA' },
  { postalCode: '67123', city: 'ARVIKA' },
  { postalCode: '67124', city: 'ARVIKA' },
  { postalCode: '67125', city: 'ARVIKA' },
  { postalCode: '67126', city: 'ARVIKA' },
  { postalCode: '67127', city: 'ARVIKA' },
  { postalCode: '67129', city: 'ARVIKA' },
  { postalCode: '67130', city: 'ARVIKA' },
  { postalCode: '67131', city: 'ARVIKA' },
  { postalCode: '67132', city: 'ARVIKA' },
  { postalCode: '67133', city: 'ARVIKA' },
  { postalCode: '67134', city: 'ARVIKA' },
  { postalCode: '67140', city: 'ARVIKA' },
  { postalCode: '67141', city: 'ARVIKA' },
  { postalCode: '67142', city: 'ARVIKA' },
  { postalCode: '67150', city: 'ARVIKA' },
  { postalCode: '67151', city: 'ARVIKA' },
  { postalCode: '67152', city: 'ARVIKA' },
  { postalCode: '67160', city: 'ARVIKA' },
  { postalCode: '67170', city: 'EDANE' },
  { postalCode: '67180', city: 'ARVIKA' },
  { postalCode: '67181', city: 'ARVIKA' },
  { postalCode: '67182', city: 'ARVIKA' },
  { postalCode: '67191', city: 'ARVIKA' },
  { postalCode: '67192', city: 'ARVIKA' },
  { postalCode: '67193', city: 'ARVIKA' },
  { postalCode: '67194', city: 'BRUNSKOG' },
  { postalCode: '67195', city: 'KLÄSSBOL' },
  { postalCode: '67196', city: 'MANGSKOG' },
  { postalCode: '67197', city: 'GLAVA' },
  { postalCode: '67198', city: 'GUNNARSKOG' },
  { postalCode: '67201', city: 'ÅRJÄNG' },
  { postalCode: '67202', city: 'TÖCKSFORS' },
  { postalCode: '67220', city: 'ÅRJÄNG' },
  { postalCode: '67221', city: 'ÅRJÄNG' },
  { postalCode: '67222', city: 'ÅRJÄNG' },
  { postalCode: '67223', city: 'ÅRJÄNG' },
  { postalCode: '67229', city: 'ÅRJÄNG' },
  { postalCode: '67230', city: 'ÅRJÄNG' },
  { postalCode: '67231', city: 'ÅRJÄNG' },
  { postalCode: '67232', city: 'ÅRJÄNG' },
  { postalCode: '67241', city: 'TÖCKSFORS' },
  { postalCode: '67291', city: 'ÅRJÄNG' },
  { postalCode: '67292', city: 'ÅRJÄNG' },
  { postalCode: '67293', city: 'ÅRJÄNG' },
  { postalCode: '67294', city: 'ÅRJÄNG' },
  { postalCode: '67295', city: 'ÅRJÄNG' },
  { postalCode: '67296', city: 'TÖCKSFORS' },
  { postalCode: '67301', city: 'CHARLOTTENBERG' },
  { postalCode: '67302', city: 'ÅMOTFORS' },
  { postalCode: '67320', city: 'CHARLOTTENBERG' },
  { postalCode: '67321', city: 'CHARLOTTENBERG' },
  { postalCode: '67322', city: 'CHARLOTTENBERG' },
  { postalCode: '67331', city: 'CHARLOTTENBERG' },
  { postalCode: '67332', city: 'CHARLOTTENBERG' },
  { postalCode: '67334', city: 'ÅMOTFORS' },
  { postalCode: '67335', city: 'ÅMOTFORS' },
  { postalCode: '67337', city: 'KOPPOM' },
  { postalCode: '67391', city: 'CHARLOTTENBERG' },
  { postalCode: '67392', city: 'CHARLOTTENBERG' },
  { postalCode: '67393', city: 'MOROKULIEN' },
  { postalCode: '67396', city: 'ÅMOTFORS' },
  { postalCode: '67397', city: 'ÅMOTFORS' },
  { postalCode: '67398', city: 'KOPPOM' },
  { postalCode: '67399', city: 'SKILLINGSFORS' },
  { postalCode: '68051', city: 'STÖLLET' },
  { postalCode: '68052', city: 'AMBJÖRBY' },
  { postalCode: '68060', city: 'SYSSLEBÄCK' },
  { postalCode: '68061', city: 'BOGRANGEN' },
  { postalCode: '68063', city: 'LIKENÄS' },
  { postalCode: '68065', city: 'HÖLJES' },
  { postalCode: '68101', city: 'KRISTINEHAMN' },
  { postalCode: '68104', city: 'KRISTINEHAMN' },
  { postalCode: '68106', city: 'KRISTINEHAMN' },
  { postalCode: '68120', city: 'KRISTINEHAMN' },
  { postalCode: '68121', city: 'KRISTINEHAMN' },
  { postalCode: '68122', city: 'KRISTINEHAMN' },
  { postalCode: '68123', city: 'KRISTINEHAMN' },
  { postalCode: '68124', city: 'KRISTINEHAMN' },
  { postalCode: '68125', city: 'KRISTINEHAMN' },
  { postalCode: '68126', city: 'KRISTINEHAMN' },
  { postalCode: '68127', city: 'KRISTINEHAMN' },
  { postalCode: '68128', city: 'KRISTINEHAMN' },
  { postalCode: '68129', city: 'KRISTINEHAMN' },
  { postalCode: '68130', city: 'KRISTINEHAMN' },
  { postalCode: '68131', city: 'KRISTINEHAMN' },
  { postalCode: '68132', city: 'KRISTINEHAMN' },
  { postalCode: '68133', city: 'KRISTINEHAMN' },
  { postalCode: '68134', city: 'KRISTINEHAMN' },
  { postalCode: '68135', city: 'KRISTINEHAMN' },
  { postalCode: '68136', city: 'KRISTINEHAMN' },
  { postalCode: '68137', city: 'KRISTINEHAMN' },
  { postalCode: '68138', city: 'KRISTINEHAMN' },
  { postalCode: '68140', city: 'KRISTINEHAMN' },
  { postalCode: '68141', city: 'KRISTINEHAMN' },
  { postalCode: '68142', city: 'KRISTINEHAMN' },
  { postalCode: '68143', city: 'KRISTINEHAMN' },
  { postalCode: '68150', city: 'KRISTINEHAMN' },
  { postalCode: '68151', city: 'KRISTINEHAMN' },
  { postalCode: '68152', city: 'KRISTINEHAMN' },
  { postalCode: '68153', city: 'KRISTINEHAMN' },
  { postalCode: '68154', city: 'KRISTINEHAMN' },
  { postalCode: '68155', city: 'KRISTINEHAMN' },
  { postalCode: '68160', city: 'KRISTINEHAMN' },
  { postalCode: '68161', city: 'BJÖRNEBORG' },
  { postalCode: '68180', city: 'KRISTINEHAMN' },
  { postalCode: '68183', city: 'KRISTINEHAMN' },
  { postalCode: '68184', city: 'KRISTINEHAMN' },
  { postalCode: '68185', city: 'KRISTINEHAMN' },
  { postalCode: '68191', city: 'KRISTINEHAMN' },
  { postalCode: '68192', city: 'KRISTINEHAMN' },
  { postalCode: '68193', city: 'KRISTINEHAMN' },
  { postalCode: '68194', city: 'KRISTINEHAMN' },
  { postalCode: '68195', city: 'KRISTINEHAMN' },
  { postalCode: '68196', city: 'KRISTINEHAMN' },
  { postalCode: '68201', city: 'FILIPSTAD' },
  { postalCode: '68203', city: 'LESJÖFORS' },
  { postalCode: '68204', city: 'NYKROPPA' },
  { postalCode: '68220', city: 'FILIPSTAD' },
  { postalCode: '68221', city: 'FILIPSTAD' },
  { postalCode: '68222', city: 'FILIPSTAD' },
  { postalCode: '68223', city: 'FILIPSTAD' },
  { postalCode: '68224', city: 'FILIPSTAD' },
  { postalCode: '68227', city: 'FILIPSTAD' },
  { postalCode: '68230', city: 'FILIPSTAD' },
  { postalCode: '68231', city: 'FILIPSTAD' },
  { postalCode: '68232', city: 'FILIPSTAD' },
  { postalCode: '68233', city: 'FILIPSTAD' },
  { postalCode: '68234', city: 'FILIPSTAD' },
  { postalCode: '68240', city: 'FILIPSTAD' },
  { postalCode: '68260', city: 'LESJÖFORS' },
  { postalCode: '68282', city: 'FILIPSTAD' },
  { postalCode: '68290', city: 'NYKROPPA' },
  { postalCode: '68291', city: 'FILIPSTAD' },
  { postalCode: '68292', city: 'FILIPSTAD' },
  { postalCode: '68293', city: 'NORDMARKSHYTTAN' },
  { postalCode: '68294', city: 'LESJÖFORS' },
  { postalCode: '68301', city: 'HAGFORS' },
  { postalCode: '68302', city: 'EKSHÄRAD' },
  { postalCode: '68320', city: 'HAGFORS' },
  { postalCode: '68321', city: 'HAGFORS' },
  { postalCode: '68322', city: 'HAGFORS' },
  { postalCode: '68323', city: 'HAGFORS' },
  { postalCode: '68324', city: 'HAGFORS' },
  { postalCode: '68329', city: 'HAGFORS' },
  { postalCode: '68330', city: 'HAGFORS' },
  { postalCode: '68331', city: 'HAGFORS' },
  { postalCode: '68332', city: 'HAGFORS' },
  { postalCode: '68333', city: 'HAGFORS' },
  { postalCode: '68334', city: 'HAGFORS' },
  { postalCode: '68340', city: 'UDDEHOLM' },
  { postalCode: '68360', city: 'EKSHÄRAD' },
  { postalCode: '68361', city: 'EKSHÄRAD' },
  { postalCode: '68362', city: 'EKSHÄRAD' },
  { postalCode: '68380', city: 'HAGFORS' },
  { postalCode: '68385', city: 'HAGFORS' },
  { postalCode: '68391', city: 'HAGFORS' },
  { postalCode: '68392', city: 'HAGFORS' },
  { postalCode: '68393', city: 'RÅDA' },
  { postalCode: '68394', city: 'LAKENE' },
  { postalCode: '68395', city: 'SUNNEMO' },
  { postalCode: '68421', city: 'MUNKFORS' },
  { postalCode: '68422', city: 'MUNKFORS' },
  { postalCode: '68423', city: 'MUNKFORS' },
  { postalCode: '68428', city: 'MUNKFORS' },
  { postalCode: '68430', city: 'MUNKFORS' },
  { postalCode: '68431', city: 'MUNKFORS' },
  { postalCode: '68432', city: 'MUNKFORS' },
  { postalCode: '68491', city: 'MUNKFORS' },
  { postalCode: '68492', city: 'MUNKFORS' },
  { postalCode: '68493', city: 'RANSÄTER' },
  { postalCode: '68495', city: 'HÖJE' },
  { postalCode: '68501', city: 'TORSBY' },
  { postalCode: '68520', city: 'TORSBY' },
  { postalCode: '68521', city: 'TORSBY' },
  { postalCode: '68522', city: 'TORSBY' },
  { postalCode: '68523', city: 'TORSBY' },
  { postalCode: '68524', city: 'TORSBY' },
  { postalCode: '68525', city: 'TORSBY' },
  { postalCode: '68529', city: 'TORSBY' },
  { postalCode: '68530', city: 'TORSBY' },
  { postalCode: '68532', city: 'TORSBY' },
  { postalCode: '68533', city: 'TORSBY' },
  { postalCode: '68534', city: 'TORSBY' },
  { postalCode: '68580', city: 'TORSBY' },
  { postalCode: '68591', city: 'TORSBY' },
  { postalCode: '68592', city: 'TORSBY' },
  { postalCode: '68593', city: 'TORSBY' },
  { postalCode: '68594', city: 'TORSBY' },
  { postalCode: '68597', city: 'ÖSTMARK' },
  { postalCode: '68601', city: 'SUNNE' },
  { postalCode: '68620', city: 'SUNNE' },
  { postalCode: '68621', city: 'SUNNE' },
  { postalCode: '68622', city: 'SUNNE' },
  { postalCode: '68623', city: 'SUNNE' },
  { postalCode: '68624', city: 'SUNNE' },
  { postalCode: '68625', city: 'SUNNE' },
  { postalCode: '68626', city: 'SUNNE' },
  { postalCode: '68628', city: 'SUNNE' },
  { postalCode: '68629', city: 'SUNNE' },
  { postalCode: '68630', city: 'SUNNE' },
  { postalCode: '68631', city: 'SUNNE' },
  { postalCode: '68633', city: 'SUNNE' },
  { postalCode: '68634', city: 'SUNNE' },
  { postalCode: '68635', city: 'SUNNE' },
  { postalCode: '68680', city: 'SUNNE' },
  { postalCode: '68691', city: 'SUNNE' },
  { postalCode: '68692', city: 'SUNNE' },
  { postalCode: '68693', city: 'SUNNE' },
  { postalCode: '68694', city: 'ROTTNEROS' },
  { postalCode: '68695', city: 'VÄSTRA ÄMTERVIK' },
  { postalCode: '68696', city: 'ÖSTRA ÄMTERVIK' },
  { postalCode: '68697', city: 'LYSVIK' },
  { postalCode: '68698', city: 'GRÄSMARK' },
  { postalCode: '68821', city: 'STORFORS' },
  { postalCode: '68822', city: 'STORFORS' },
  { postalCode: '68823', city: 'STORFORS' },
  { postalCode: '68829', city: 'STORFORS' },
  { postalCode: '68830', city: 'STORFORS' },
  { postalCode: '68831', city: 'STORFORS' },
  { postalCode: '68891', city: 'STORFORS' },
  { postalCode: '68892', city: 'STORFORS' },
  { postalCode: '69101', city: 'KARLSKOGA' },
  { postalCode: '69102', city: 'KARLSKOGA' },
  { postalCode: '69105', city: 'KARLSKOGA' },
  { postalCode: '69120', city: 'KARLSKOGA' },
  { postalCode: '69121', city: 'KARLSKOGA' },
  { postalCode: '69122', city: 'KARLSKOGA' },
  { postalCode: '69123', city: 'KARLSKOGA' },
  { postalCode: '69124', city: 'KARLSKOGA' },
  { postalCode: '69125', city: 'KARLSKOGA' },
  { postalCode: '69127', city: 'KARLSKOGA' },
  { postalCode: '69131', city: 'KARLSKOGA' },
  { postalCode: '69132', city: 'KARLSKOGA' },
  { postalCode: '69133', city: 'KARLSKOGA' },
  { postalCode: '69134', city: 'KARLSKOGA' },
  { postalCode: '69135', city: 'KARLSKOGA' },
  { postalCode: '69136', city: 'KARLSKOGA' },
  { postalCode: '69137', city: 'KARLSKOGA' },
  { postalCode: '69138', city: 'KARLSKOGA' },
  { postalCode: '69141', city: 'KARLSKOGA' },
  { postalCode: '69142', city: 'KARLSKOGA' },
  { postalCode: '69143', city: 'KARLSKOGA' },
  { postalCode: '69144', city: 'KARLSKOGA' },
  { postalCode: '69145', city: 'KARLSKOGA' },
  { postalCode: '69146', city: 'KARLSKOGA' },
  { postalCode: '69147', city: 'KARLSKOGA' },
  { postalCode: '69148', city: 'KARLSKOGA' },
  { postalCode: '69150', city: 'KARLSKOGA' },
  { postalCode: '69151', city: 'KARLSKOGA' },
  { postalCode: '69152', city: 'KARLSKOGA' },
  { postalCode: '69153', city: 'KARLSKOGA' },
  { postalCode: '69154', city: 'KARLSKOGA' },
  { postalCode: '69175', city: 'KARLSKOGA' },
  { postalCode: '69176', city: 'KARLSKOGA' },
  { postalCode: '69178', city: 'KARLSKOGA' },
  { postalCode: '69179', city: 'KARLSKOGA' },
  { postalCode: '69180', city: 'KARLSKOGA' },
  { postalCode: '69181', city: 'KARLSKOGA' },
  { postalCode: '69182', city: 'KARLSKOGA' },
  { postalCode: '69183', city: 'KARLSKOGA' },
  { postalCode: '69185', city: 'KARLSKOGA' },
  { postalCode: '69186', city: 'KARLSKOGA' },
  { postalCode: '69188', city: 'KARLSKOGA' },
  { postalCode: '69191', city: 'KARLSKOGA' },
  { postalCode: '69192', city: 'GRANBERGSDAL' },
  { postalCode: '69193', city: 'KARLSKOGA' },
  { postalCode: '69194', city: 'KARLSKOGA' },
  { postalCode: '69201', city: 'KUMLA' },
  { postalCode: '69220', city: 'KUMLA' },
  { postalCode: '69221', city: 'KUMLA' },
  { postalCode: '69222', city: 'KUMLA' },
  { postalCode: '69223', city: 'KUMLA' },
  { postalCode: '69224', city: 'KUMLA' },
  { postalCode: '69225', city: 'KUMLA' },
  { postalCode: '69229', city: 'KUMLA' },
  { postalCode: '69230', city: 'KUMLA' },
  { postalCode: '69231', city: 'KUMLA' },
  { postalCode: '69232', city: 'KUMLA' },
  { postalCode: '69233', city: 'KUMLA' },
  { postalCode: '69234', city: 'KUMLA' },
  { postalCode: '69235', city: 'KUMLA' },
  { postalCode: '69236', city: 'KUMLA' },
  { postalCode: '69237', city: 'KUMLA' },
  { postalCode: '69238', city: 'KUMLA' },
  { postalCode: '69271', city: 'KUMLA' },
  { postalCode: '69272', city: 'KUMLA' },
  { postalCode: '69273', city: 'KUMLA' },
  { postalCode: '69275', city: 'KUMLA' },
  { postalCode: '69280', city: 'KUMLA' },
  { postalCode: '69281', city: 'KUMLA' },
  { postalCode: '69282', city: 'KUMLA' },
  { postalCode: '69284', city: 'KUMLA' },
  { postalCode: '69285', city: 'KUMLA' },
  { postalCode: '69291', city: 'KUMLA' },
  { postalCode: '69292', city: 'KUMLA' },
  { postalCode: '69293', city: 'KUMLA' },
  { postalCode: '69301', city: 'DEGERFORS' },
  { postalCode: '69320', city: 'DEGERFORS' },
  { postalCode: '69321', city: 'DEGERFORS' },
  { postalCode: '69322', city: 'DEGERFORS' },
  { postalCode: '69329', city: 'DEGERFORS' },
  { postalCode: '69330', city: 'DEGERFORS' },
  { postalCode: '69331', city: 'DEGERFORS' },
  { postalCode: '69332', city: 'DEGERFORS' },
  { postalCode: '69333', city: 'DEGERFORS' },
  { postalCode: '69334', city: 'DEGERFORS' },
  { postalCode: '69335', city: 'DEGERFORS' },
  { postalCode: '69370', city: 'ÅTORP' },
  { postalCode: '69373', city: 'SVARTÅ' },
  { postalCode: '69380', city: 'DEGERFORS' },
  { postalCode: '69381', city: 'DEGERFORS' },
  { postalCode: '69391', city: 'DEGERFORS' },
  { postalCode: '69392', city: 'DEGERFORS' },
  { postalCode: '69393', city: 'SVARTÅ' },
  { postalCode: '69394', city: 'ÅTORP' },
  { postalCode: '69395', city: 'ÅTORP' },
  { postalCode: '69400', city: 'HALLSBERG' },
  { postalCode: '69401', city: 'HALLSBERG' },
  { postalCode: '69402', city: 'HALLSBERG' },
  { postalCode: '69420', city: 'HALLSBERG' },
  { postalCode: '69421', city: 'HALLSBERG' },
  { postalCode: '69422', city: 'HALLSBERG' },
  { postalCode: '69423', city: 'HALLSBERG' },
  { postalCode: '69424', city: 'HALLSBERG' },
  { postalCode: '69430', city: 'HALLSBERG' },
  { postalCode: '69431', city: 'HALLSBERG' },
  { postalCode: '69432', city: 'HALLSBERG' },
  { postalCode: '69433', city: 'HALLSBERG' },
  { postalCode: '69434', city: 'HALLSBERG' },
  { postalCode: '69435', city: 'HALLSBERG' },
  { postalCode: '69436', city: 'HALLSBERG' },
  { postalCode: '69437', city: 'HALLSBERG' },
  { postalCode: '69450', city: 'VRETSTORP' },
  { postalCode: '69460', city: 'ÅSBRO' },
  { postalCode: '69472', city: 'ÖSTANSJÖ' },
  { postalCode: '69480', city: 'HALLSBERG' },
  { postalCode: '69481', city: 'HALLSBERG' },
  { postalCode: '69482', city: 'HALLSBERG' },
  { postalCode: '69483', city: 'HALLSBERG' },
  { postalCode: '69491', city: 'HALLSBERG' },
  { postalCode: '69492', city: 'HALLSBERG' },
  { postalCode: '69493', city: 'ÖSTANSJÖ' },
  { postalCode: '69494', city: 'VRETSTORP' },
  { postalCode: '69495', city: 'VRETSTORP' },
  { postalCode: '69496', city: 'ÅSBRO' },
  { postalCode: '69497', city: 'RÖNNESHYTTA' },
  { postalCode: '69498', city: 'NÄRKESBERG' },
  { postalCode: '69499', city: 'SKYLLBERG' },
  { postalCode: '69501', city: 'LAXÅ' },
  { postalCode: '69520', city: 'LAXÅ' },
  { postalCode: '69521', city: 'LAXÅ' },
  { postalCode: '69522', city: 'LAXÅ' },
  { postalCode: '69530', city: 'LAXÅ' },
  { postalCode: '69531', city: 'LAXÅ' },
  { postalCode: '69532', city: 'LAXÅ' },
  { postalCode: '69550', city: 'FINNERÖDJA' },
  { postalCode: '69560', city: 'HASSELFORS' },
  { postalCode: '69572', city: 'RÖFORS' },
  { postalCode: '69580', city: 'LAXÅ' },
  { postalCode: '69581', city: 'LAXÅ' },
  { postalCode: '69584', city: 'HASSELFORS' },
  { postalCode: '69585', city: 'HASSELFORS' },
  { postalCode: '69591', city: 'LAXÅ' },
  { postalCode: '69592', city: 'LAXÅ' },
  { postalCode: '69593', city: 'FINNERÖDJA' },
  { postalCode: '69594', city: 'FINNERÖDJA' },
  { postalCode: '69595', city: 'HASSELFORS' },
  { postalCode: '69596', city: 'TIVED' },
  { postalCode: '69597', city: 'TIVED' },
  { postalCode: '69601', city: 'ASKERSUND' },
  { postalCode: '69620', city: 'ASKERSUND' },
  { postalCode: '69621', city: 'ASKERSUND' },
  { postalCode: '69622', city: 'ASKERSUND' },
  { postalCode: '69623', city: 'ASKERSUND' },
  { postalCode: '69630', city: 'ASKERSUND' },
  { postalCode: '69631', city: 'ASKERSUND' },
  { postalCode: '69632', city: 'ASKERSUND' },
  { postalCode: '69633', city: 'ASKERSUND' },
  { postalCode: '69634', city: 'ASKERSUND' },
  { postalCode: '69673', city: 'ASPABRUK' },
  { postalCode: '69674', city: 'HAMMAR' },
  { postalCode: '69675', city: 'ÅMMEBERG' },
  { postalCode: '69676', city: 'ZINKGRUVAN' },
  { postalCode: '69680', city: 'ASPABRUK' },
  { postalCode: '69681', city: 'ZINKGRUVAN' },
  { postalCode: '69682', city: 'ASKERSUND' },
  { postalCode: '69691', city: 'ASKERSUND' },
  { postalCode: '69692', city: 'ASKERSUND' },
  { postalCode: '69693', city: 'ASPABRUK' },
  { postalCode: '69694', city: 'HAMMAR' },
  { postalCode: '69695', city: 'ÅMMEBERG' },
  { postalCode: '69696', city: 'ZINKGRUVAN' },
  { postalCode: '69697', city: 'MARIEDAMM' },
  { postalCode: '69721', city: 'PÅLSBODA' },
  { postalCode: '69722', city: 'PÅLSBODA' },
  { postalCode: '69730', city: 'PÅLSBODA' },
  { postalCode: '69731', city: 'PÅLSBODA' },
  { postalCode: '69732', city: 'PÅLSBODA' },
  { postalCode: '69774', city: 'SKÖLLERSTA' },
  { postalCode: '69780', city: 'SKÖLLERSTA' },
  { postalCode: '69791', city: 'PÅLSBODA' },
  { postalCode: '69792', city: 'PÅLSBODA' },
  { postalCode: '69793', city: 'HJORTKVARN' },
  { postalCode: '69794', city: 'SKÖLLERSTA' },
  { postalCode: '70002', city: 'ÖREBRO' },
  { postalCode: '70005', city: 'ÖREBRO' },
  { postalCode: '70006', city: 'ÖREBRO' },
  { postalCode: '70008', city: 'ÖREBRO' },
  { postalCode: '70009', city: 'ÖREBRO' },
  { postalCode: '70014', city: 'ÖREBRO' },
  { postalCode: '70100', city: 'ÖREBRO' },
  { postalCode: '70111', city: 'ÖREBRO' },
  { postalCode: '70112', city: 'ÖREBRO' },
  { postalCode: '70113', city: 'ÖREBRO' },
  { postalCode: '70114', city: 'ÖREBRO' },
  { postalCode: '70115', city: 'ÖREBRO' },
  { postalCode: '70116', city: 'ÖREBRO' },
  { postalCode: '70117', city: 'ÖREBRO' },
  { postalCode: '70118', city: 'ÖREBRO' },
  { postalCode: '70123', city: 'ÖREBRO' },
  { postalCode: '70130', city: 'ÖREBRO' },
  { postalCode: '70131', city: 'ÖREBRO' },
  { postalCode: '70132', city: 'ÖREBRO' },
  { postalCode: '70133', city: 'ÖREBRO' },
  { postalCode: '70135', city: 'ÖREBRO' },
  { postalCode: '70140', city: 'ÖREBRO' },
  { postalCode: '70141', city: 'ÖREBRO' },
  { postalCode: '70142', city: 'ÖREBRO' },
  { postalCode: '70143', city: 'ÖREBRO' },
  { postalCode: '70144', city: 'ÖREBRO' },
  { postalCode: '70145', city: 'ÖREBRO' },
  { postalCode: '70146', city: 'ÖREBRO' },
  { postalCode: '70147', city: 'ÖREBRO' },
  { postalCode: '70148', city: 'ÖREBRO' },
  { postalCode: '70149', city: 'ÖREBRO' },
  { postalCode: '70150', city: 'ÖREBRO' },
  { postalCode: '70171', city: 'ÖREBRO' },
  { postalCode: '70179', city: 'ÖREBRO' },
  { postalCode: '70180', city: 'ÖREBRO' },
  { postalCode: '70181', city: 'ÖREBRO' },
  { postalCode: '70182', city: 'ÖREBRO' },
  { postalCode: '70184', city: 'ÖREBRO' },
  { postalCode: '70185', city: 'ÖREBRO' },
  { postalCode: '70186', city: 'ÖREBRO' },
  { postalCode: '70187', city: 'ÖREBRO' },
  { postalCode: '70188', city: 'ÖREBRO' },
  { postalCode: '70189', city: 'ÖREBRO' },
  { postalCode: '70190', city: 'ÖREBRO' },
  { postalCode: '70191', city: 'ÖREBRO' },
  { postalCode: '70192', city: 'ÖREBRO' },
  { postalCode: '70193', city: 'ÖREBRO' },
  { postalCode: '70194', city: 'ÖREBRO' },
  { postalCode: '70195', city: 'ÖREBRO' },
  { postalCode: '70196', city: 'ÖREBRO' },
  { postalCode: '70197', city: 'ÖREBRO' },
  { postalCode: '70198', city: 'ÖREBRO' },
  { postalCode: '70199', city: 'ÖREBRO' },
  { postalCode: '70201', city: 'ÖREBRO' },
  { postalCode: '70202', city: 'ÖREBRO' },
  { postalCode: '70203', city: 'ÖREBRO' },
  { postalCode: '70204', city: 'ÖREBRO' },
  { postalCode: '70205', city: 'ÖREBRO' },
  { postalCode: '70210', city: 'ÖREBRO' },
  { postalCode: '70211', city: 'ÖREBRO' },
  { postalCode: '70212', city: 'ÖREBRO' },
  { postalCode: '70213', city: 'ÖREBRO' },
  { postalCode: '70214', city: 'ÖREBRO' },
  { postalCode: '70215', city: 'ÖREBRO' },
  { postalCode: '70216', city: 'ÖREBRO' },
  { postalCode: '70217', city: 'ÖREBRO' },
  { postalCode: '70218', city: 'ÖREBRO' },
  { postalCode: '70219', city: 'ÖREBRO' },
  { postalCode: '70221', city: 'ÖREBRO' },
  { postalCode: '70222', city: 'ÖREBRO' },
  { postalCode: '70223', city: 'ÖREBRO' },
  { postalCode: '70224', city: 'ÖREBRO' },
  { postalCode: '70225', city: 'ÖREBRO' },
  { postalCode: '70226', city: 'ÖREBRO' },
  { postalCode: '70227', city: 'ÖREBRO' },
  { postalCode: '70228', city: 'ÖREBRO' },
  { postalCode: '70229', city: 'ÖREBRO' },
  { postalCode: '70230', city: 'ÖREBRO' },
  { postalCode: '70231', city: 'ÖREBRO' },
  { postalCode: '70232', city: 'ÖREBRO' },
  { postalCode: '70233', city: 'ÖREBRO' },
  { postalCode: '70234', city: 'ÖREBRO' },
  { postalCode: '70235', city: 'ÖREBRO' },
  { postalCode: '70236', city: 'ÖREBRO' },
  { postalCode: '70237', city: 'ÖREBRO' },
  { postalCode: '70239', city: 'ÖREBRO' },
  { postalCode: '70240', city: 'ÖREBRO' },
  { postalCode: '70241', city: 'ÖREBRO' },
  { postalCode: '70242', city: 'ÖREBRO' },
  { postalCode: '70281', city: 'ÖREBRO' },
  { postalCode: '70282', city: 'ÖREBRO' },
  { postalCode: '70283', city: 'ÖREBRO' },
  { postalCode: '70284', city: 'ÖREBRO' },
  { postalCode: '70285', city: 'ÖREBRO' },
  { postalCode: '70286', city: 'ÖREBRO' },
  { postalCode: '70287', city: 'ÖREBRO' },
  { postalCode: '70298', city: 'ÖREBRO' },
  { postalCode: '70299', city: 'ÖREBRO' },
  { postalCode: '70301', city: 'ÖREBRO' },
  { postalCode: '70302', city: 'ÖREBRO' },
  { postalCode: '70303', city: 'ÖREBRO' },
  { postalCode: '70340', city: 'ÖREBRO' },
  { postalCode: '70341', city: 'ÖREBRO' },
  { postalCode: '70342', city: 'ÖREBRO' },
  { postalCode: '70343', city: 'ÖREBRO' },
  { postalCode: '70344', city: 'ÖREBRO' },
  { postalCode: '70345', city: 'ÖREBRO' },
  { postalCode: '70346', city: 'ÖREBRO' },
  { postalCode: '70347', city: 'ÖREBRO' },
  { postalCode: '70348', city: 'ÖREBRO' },
  { postalCode: '70349', city: 'ÖREBRO' },
  { postalCode: '70350', city: 'ÖREBRO' },
  { postalCode: '70351', city: 'ÖREBRO' },
  { postalCode: '70352', city: 'ÖREBRO' },
  { postalCode: '70353', city: 'ÖREBRO' },
  { postalCode: '70354', city: 'ÖREBRO' },
  { postalCode: '70355', city: 'ÖREBRO' },
  { postalCode: '70356', city: 'ÖREBRO' },
  { postalCode: '70357', city: 'ÖREBRO' },
  { postalCode: '70358', city: 'ÖREBRO' },
  { postalCode: '70359', city: 'ÖREBRO' },
  { postalCode: '70360', city: 'ÖREBRO' },
  { postalCode: '70361', city: 'ÖREBRO' },
  { postalCode: '70362', city: 'ÖREBRO' },
  { postalCode: '70363', city: 'ÖREBRO' },
  { postalCode: '70364', city: 'ÖREBRO' },
  { postalCode: '70365', city: 'ÖREBRO' },
  { postalCode: '70366', city: 'ÖREBRO' },
  { postalCode: '70367', city: 'ÖREBRO' },
  { postalCode: '70368', city: 'ÖREBRO' },
  { postalCode: '70369', city: 'ÖREBRO' },
  { postalCode: '70370', city: 'ÖREBRO' },
  { postalCode: '70371', city: 'ÖREBRO' },
  { postalCode: '70372', city: 'ÖREBRO' },
  { postalCode: '70373', city: 'ÖREBRO' },
  { postalCode: '70374', city: 'ÖREBRO' },
  { postalCode: '70375', city: 'ÖREBRO' },
  { postalCode: '70376', city: 'ÖREBRO' },
  { postalCode: '70378', city: 'ÖREBRO' },
  { postalCode: '70380', city: 'ÖREBRO' },
  { postalCode: '70381', city: 'ÖREBRO' },
  { postalCode: '70382', city: 'ÖREBRO' },
  { postalCode: '70383', city: 'ÖREBRO' },
  { postalCode: '70385', city: 'GLANSHAMMAR' },
  { postalCode: '70510', city: 'ÖREBRO' },
  { postalCode: '70591', city: 'ÖREBRO' },
  { postalCode: '70592', city: 'ÖREBRO' },
  { postalCode: '70593', city: 'ÖREBRO' },
  { postalCode: '70594', city: 'ÖREBRO' },
  { postalCode: '70595', city: 'ÖREBRO' },
  { postalCode: '70596', city: 'GLANSHAMMAR' },
  { postalCode: '70597', city: 'GLANSHAMMAR' },
  { postalCode: '70598', city: 'LILLKYRKA' },
  { postalCode: '70800', city: 'ÖREBRO' },
  { postalCode: '70801', city: 'ÖREBRO' },
  { postalCode: '70804', city: 'ÖREBRO' },
  { postalCode: '70806', city: 'ÖREBRO' },
  { postalCode: '71101', city: 'LINDESBERG' },
  { postalCode: '71102', city: 'LINDESBERG' },
  { postalCode: '71104', city: 'STORÅ' },
  { postalCode: '71120', city: 'LINDESBERG' },
  { postalCode: '71121', city: 'LINDESBERG' },
  { postalCode: '71122', city: 'LINDESBERG' },
  { postalCode: '71130', city: 'LINDESBERG' },
  { postalCode: '71131', city: 'LINDESBERG' },
  { postalCode: '71132', city: 'LINDESBERG' },
  { postalCode: '71133', city: 'LINDESBERG' },
  { postalCode: '71134', city: 'LINDESBERG' },
  { postalCode: '71135', city: 'LINDESBERG' },
  { postalCode: '71136', city: 'LINDESBERG' },
  { postalCode: '71172', city: 'VEDEVÅG' },
  { postalCode: '71176', city: 'STORÅ' },
  { postalCode: '71177', city: 'STRÅSSA' },
  { postalCode: '71178', city: 'GULDSMEDSHYTTAN' },
  { postalCode: '71180', city: 'LINDESBERG' },
  { postalCode: '71181', city: 'LINDESBERG' },
  { postalCode: '71182', city: 'LINDESBERG' },
  { postalCode: '71191', city: 'LINDESBERG' },
  { postalCode: '71192', city: 'VEDEVÅG' },
  { postalCode: '71193', city: 'LINDESBERG' },
  { postalCode: '71194', city: 'LINDESBERG' },
  { postalCode: '71195', city: 'GUSSELBY' },
  { postalCode: '71196', city: 'STORÅ' },
  { postalCode: '71197', city: 'STORÅ' },
  { postalCode: '71198', city: 'RAMSBERG' },
  { postalCode: '71201', city: 'HÄLLEFORS' },
  { postalCode: '71202', city: 'GRYTHYTTAN' },
  { postalCode: '71220', city: 'HÄLLEFORS' },
  { postalCode: '71221', city: 'HÄLLEFORS' },
  { postalCode: '71230', city: 'HÄLLEFORS' },
  { postalCode: '71231', city: 'HÄLLEFORS' },
  { postalCode: '71232', city: 'HÄLLEFORS' },
  { postalCode: '71233', city: 'HÄLLEFORS' },
  { postalCode: '71234', city: 'HÄLLEFORS' },
  { postalCode: '71260', city: 'GRYTHYTTAN' },
  { postalCode: '71280', city: 'HÄLLEFORS' },
  { postalCode: '71281', city: 'GRYTHYTTAN' },
  { postalCode: '71282', city: 'GRYTHYTTAN' },
  { postalCode: '71283', city: 'HÄLLEFORS' },
  { postalCode: '71291', city: 'HÄLLEFORS' },
  { postalCode: '71293', city: 'HÄLLEFORS' },
  { postalCode: '71294', city: 'GRYTHYTTAN' },
  { postalCode: '71295', city: 'GRYTHYTTAN' },
  { postalCode: '71301', city: 'NORA' },
  { postalCode: '71320', city: 'NORA' },
  { postalCode: '71321', city: 'NORA' },
  { postalCode: '71322', city: 'NORA' },
  { postalCode: '71323', city: 'NORA' },
  { postalCode: '71330', city: 'NORA' },
  { postalCode: '71331', city: 'NORA' },
  { postalCode: '71332', city: 'NORA' },
  { postalCode: '71333', city: 'NORA' },
  { postalCode: '71334', city: 'NORA' },
  { postalCode: '71372', city: 'GYTTORP' },
  { postalCode: '71380', city: 'NORA' },
  { postalCode: '71382', city: 'NORA' },
  { postalCode: '71391', city: 'NORA' },
  { postalCode: '71392', city: 'GYTTORP' },
  { postalCode: '71393', city: 'NORA' },
  { postalCode: '71394', city: 'NORA' },
  { postalCode: '71401', city: 'KOPPARBERG' },
  { postalCode: '71420', city: 'KOPPARBERG' },
  { postalCode: '71421', city: 'KOPPARBERG' },
  { postalCode: '71422', city: 'KOPPARBERG' },
  { postalCode: '71430', city: 'KOPPARBERG' },
  { postalCode: '71431', city: 'KOPPARBERG' },
  { postalCode: '71432', city: 'KOPPARBERG' },
  { postalCode: '71433', city: 'KOPPARBERG' },
  { postalCode: '71434', city: 'KOPPARBERG' },
  { postalCode: '71435', city: 'KOPPARBERG' },
  { postalCode: '71436', city: 'KOPPARBERG' },
  { postalCode: '71437', city: 'KOPPARBERG' },
  { postalCode: '71472', city: 'STÄLLDALEN' },
  { postalCode: '71480', city: 'KOPPARBERG' },
  { postalCode: '71481', city: 'STÄLLDALEN' },
  { postalCode: '71482', city: 'KOPPARBERG' },
  { postalCode: '71491', city: 'KOPPARBERG' },
  { postalCode: '71492', city: 'KOPPARBERG' },
  { postalCode: '71493', city: 'KOPPARBERG' },
  { postalCode: '71494', city: 'KOPPARBERG' },
  { postalCode: '71501', city: 'ODENSBACKEN' },
  { postalCode: '71520', city: 'ODENSBACKEN' },
  { postalCode: '71531', city: 'ODENSBACKEN' },
  { postalCode: '71532', city: 'ODENSBACKEN' },
  { postalCode: '71572', city: 'STORA MELLÖSA' },
  { postalCode: '71591', city: 'ODENSBACKEN' },
  { postalCode: '71592', city: 'STORA MELLÖSA' },
  { postalCode: '71593', city: 'ODENSBACKEN' },
  { postalCode: '71594', city: 'ODENSBACKEN' },
  { postalCode: '71595', city: 'KILSMO' },
  { postalCode: '71596', city: 'BREVENS BRUK' },
  { postalCode: '71620', city: 'FJUGESTA' },
  { postalCode: '71621', city: 'FJUGESTA' },
  { postalCode: '71630', city: 'FJUGESTA' },
  { postalCode: '71631', city: 'FJUGESTA' },
  { postalCode: '71681', city: 'FJUGESTA' },
  { postalCode: '71691', city: 'FJUGESTA' },
  { postalCode: '71692', city: 'FJUGESTA' },
  { postalCode: '71693', city: 'FJUGESTA' },
  { postalCode: '71694', city: 'MULLHYTTAN' },
  { postalCode: '71820', city: 'FRÖVI' },
  { postalCode: '71821', city: 'FRÖVI' },
  { postalCode: '71822', city: 'FRÖVI' },
  { postalCode: '71830', city: 'FRÖVI' },
  { postalCode: '71831', city: 'FRÖVI' },
  { postalCode: '71832', city: 'FRÖVI' },
  { postalCode: '71833', city: 'FRÖVI' },
  { postalCode: '71840', city: 'DYLTABRUK' },
  { postalCode: '71880', city: 'FRÖVI' },
  { postalCode: '71881', city: 'FRÖVI' },
  { postalCode: '71891', city: 'FRÖVI' },
  { postalCode: '71892', city: 'FRÖVI' },
  { postalCode: '71893', city: 'FRÖVI' },
  { postalCode: '71894', city: 'ERVALLA' },
  { postalCode: '71895', city: 'ERVALLA' },
  { postalCode: '71896', city: 'DYLTABRUK' },
  { postalCode: '71897', city: 'DYLTABRUK' },
  { postalCode: '71930', city: 'VINTROSA' },
  { postalCode: '71931', city: 'VINTROSA' },
  { postalCode: '71932', city: 'VINTROSA' },
  { postalCode: '71940', city: 'GARPHYTTAN' },
  { postalCode: '71941', city: 'GARPHYTTAN' },
  { postalCode: '71980', city: 'GARPHYTTAN' },
  { postalCode: '71991', city: 'VINTROSA' },
  { postalCode: '71992', city: 'VINTROSA' },
  { postalCode: '71993', city: 'VINTROSA' },
  { postalCode: '71994', city: 'VINTROSA' },
  { postalCode: '71995', city: 'VINTROSA' },
  { postalCode: '72001', city: 'VÄSTERÅS' },
  { postalCode: '72002', city: 'VÄSTERÅS' },
  { postalCode: '72003', city: 'VÄSTERÅS' },
  { postalCode: '72007', city: 'VÄSTERÅS' },
  { postalCode: '72010', city: 'VÄSTERÅS' },
  { postalCode: '72011', city: 'VÄSTERÅS' },
  { postalCode: '72012', city: 'VÄSTERÅS' },
  { postalCode: '72016', city: 'VÄSTERÅS' },
  { postalCode: '72017', city: 'VÄSTERÅS' },
  { postalCode: '72018', city: 'VÄSTERÅS' },
  { postalCode: '72020', city: 'VÄSTERÅS' },
  { postalCode: '72100', city: 'VÄSTERÅS' },
  { postalCode: '72101', city: 'VÄSTERÅS' },
  { postalCode: '72103', city: 'VÄSTERÅS' },
  { postalCode: '72104', city: 'VÄSTERÅS' },
  { postalCode: '72105', city: 'VÄSTERÅS' },
  { postalCode: '72106', city: 'VÄSTERÅS' },
  { postalCode: '72107', city: 'VÄSTERÅS' },
  { postalCode: '72108', city: 'VÄSTERÅS' },
  { postalCode: '72109', city: 'VÄSTERÅS' },
  { postalCode: '72110', city: 'VÄSTERÅS' },
  { postalCode: '72111', city: 'VÄSTERÅS' },
  { postalCode: '72119', city: 'VÄSTERÅS' },
  { postalCode: '72120', city: 'VÄSTERÅS' },
  { postalCode: '72122', city: 'VÄSTERÅS' },
  { postalCode: '72123', city: 'VÄSTERÅS' },
  { postalCode: '72126', city: 'VÄSTERÅS' },
  { postalCode: '72127', city: 'VÄSTERÅS' },
  { postalCode: '72128', city: 'VÄSTERÅS' },
  { postalCode: '72129', city: 'VÄSTERÅS' },
  { postalCode: '72130', city: 'VÄSTERÅS' },
  { postalCode: '72131', city: 'VÄSTERÅS' },
  { postalCode: '72132', city: 'VÄSTERÅS' },
  { postalCode: '72133', city: 'VÄSTERÅS' },
  { postalCode: '72134', city: 'VÄSTERÅS' },
  { postalCode: '72135', city: 'VÄSTERÅS' },
  { postalCode: '72136', city: 'VÄSTERÅS' },
  { postalCode: '72137', city: 'VÄSTERÅS' },
  { postalCode: '72138', city: 'VÄSTERÅS' },
  { postalCode: '72150', city: 'VÄSTERÅS' },
  { postalCode: '72151', city: 'VÄSTERÅS' },
  { postalCode: '72153', city: 'VÄSTERÅS' },
  { postalCode: '72157', city: 'VÄSTERÅS' },
  { postalCode: '72159', city: 'VÄSTERÅS' },
  { postalCode: '72161', city: 'VÄSTERÅS' },
  { postalCode: '72162', city: 'VÄSTERÅS' },
  { postalCode: '72163', city: 'VÄSTERÅS' },
  { postalCode: '72164', city: 'VÄSTERÅS' },
  { postalCode: '72165', city: 'VÄSTERÅS' },
  { postalCode: '72166', city: 'VÄSTERÅS' },
  { postalCode: '72167', city: 'VÄSTERÅS' },
  { postalCode: '72168', city: 'VÄSTERÅS' },
  { postalCode: '72169', city: 'VÄSTERÅS' },
  { postalCode: '72170', city: 'VÄSTERÅS' },
  { postalCode: '72171', city: 'VÄSTERÅS' },
  { postalCode: '72173', city: 'VÄSTERÅS' },
  { postalCode: '72174', city: 'VÄSTERÅS' },
  { postalCode: '72175', city: 'VÄSTERÅS' },
  { postalCode: '72176', city: 'VÄSTERÅS' },
  { postalCode: '72178', city: 'VÄSTERÅS' },
  { postalCode: '72182', city: 'VÄSTERÅS' },
  { postalCode: '72183', city: 'VÄSTERÅS' },
  { postalCode: '72184', city: 'VÄSTERÅS' },
  { postalCode: '72186', city: 'VÄSTERÅS' },
  { postalCode: '72187', city: 'VÄSTERÅS' },
  { postalCode: '72189', city: 'VÄSTERÅS' },
  { postalCode: '72209', city: 'VÄSTERÅS' },
  { postalCode: '72210', city: 'VÄSTERÅS' },
  { postalCode: '72211', city: 'VÄSTERÅS' },
  { postalCode: '72212', city: 'VÄSTERÅS' },
  { postalCode: '72213', city: 'VÄSTERÅS' },
  { postalCode: '72214', city: 'VÄSTERÅS' },
  { postalCode: '72215', city: 'VÄSTERÅS' },
  { postalCode: '72216', city: 'VÄSTERÅS' },
  { postalCode: '72217', city: 'VÄSTERÅS' },
  { postalCode: '72218', city: 'VÄSTERÅS' },
  { postalCode: '72219', city: 'VÄSTERÅS' },
  { postalCode: '72220', city: 'VÄSTERÅS' },
  { postalCode: '72221', city: 'VÄSTERÅS' },
  { postalCode: '72222', city: 'VÄSTERÅS' },
  { postalCode: '72223', city: 'VÄSTERÅS' },
  { postalCode: '72224', city: 'VÄSTERÅS' },
  { postalCode: '72225', city: 'VÄSTERÅS' },
  { postalCode: '72226', city: 'VÄSTERÅS' },
  { postalCode: '72227', city: 'VÄSTERÅS' },
  { postalCode: '72228', city: 'VÄSTERÅS' },
  { postalCode: '72231', city: 'VÄSTERÅS' },
  { postalCode: '72233', city: 'VÄSTERÅS' },
  { postalCode: '72240', city: 'VÄSTERÅS' },
  { postalCode: '72241', city: 'VÄSTERÅS' },
  { postalCode: '72242', city: 'VÄSTERÅS' },
  { postalCode: '72243', city: 'VÄSTERÅS' },
  { postalCode: '72244', city: 'VÄSTERÅS' },
  { postalCode: '72245', city: 'VÄSTERÅS' },
  { postalCode: '72246', city: 'VÄSTERÅS' },
  { postalCode: '72301', city: 'VÄSTERÅS' },
  { postalCode: '72334', city: 'VÄSTERÅS' },
  { postalCode: '72335', city: 'VÄSTERÅS' },
  { postalCode: '72336', city: 'VÄSTERÅS' },
  { postalCode: '72337', city: 'VÄSTERÅS' },
  { postalCode: '72338', city: 'VÄSTERÅS' },
  { postalCode: '72339', city: 'VÄSTERÅS' },
  { postalCode: '72340', city: 'VÄSTERÅS' },
  { postalCode: '72341', city: 'VÄSTERÅS' },
  { postalCode: '72342', city: 'VÄSTERÅS' },
  { postalCode: '72343', city: 'VÄSTERÅS' },
  { postalCode: '72344', city: 'VÄSTERÅS' },
  { postalCode: '72345', city: 'VÄSTERÅS' },
  { postalCode: '72346', city: 'VÄSTERÅS' },
  { postalCode: '72347', city: 'VÄSTERÅS' },
  { postalCode: '72348', city: 'VÄSTERÅS' },
  { postalCode: '72349', city: 'VÄSTERÅS' },
  { postalCode: '72350', city: 'VÄSTERÅS' },
  { postalCode: '72351', city: 'VÄSTERÅS' },
  { postalCode: '72352', city: 'VÄSTERÅS' },
  { postalCode: '72353', city: 'VÄSTERÅS' },
  { postalCode: '72355', city: 'VÄSTERÅS' },
  { postalCode: '72356', city: 'VÄSTERÅS' },
  { postalCode: '72357', city: 'VÄSTERÅS' },
  { postalCode: '72358', city: 'VÄSTERÅS' },
  { postalCode: '72359', city: 'VÄSTERÅS' },
  { postalCode: '72455', city: 'VÄSTERÅS' },
  { postalCode: '72460', city: 'VÄSTERÅS' },
  { postalCode: '72461', city: 'VÄSTERÅS' },
  { postalCode: '72462', city: 'VÄSTERÅS' },
  { postalCode: '72463', city: 'VÄSTERÅS' },
  { postalCode: '72464', city: 'VÄSTERÅS' },
  { postalCode: '72465', city: 'VÄSTERÅS' },
  { postalCode: '72466', city: 'VÄSTERÅS' },
  { postalCode: '72467', city: 'VÄSTERÅS' },
  { postalCode: '72468', city: 'VÄSTERÅS' },
  { postalCode: '72469', city: 'VÄSTERÅS' },
  { postalCode: '72470', city: 'VÄSTERÅS' },
  { postalCode: '72471', city: 'VÄSTERÅS' },
  { postalCode: '72472', city: 'VÄSTERÅS' },
  { postalCode: '72473', city: 'VÄSTERÅS' },
  { postalCode: '72474', city: 'VÄSTERÅS' },
  { postalCode: '72475', city: 'VÄSTERÅS' },
  { postalCode: '72476', city: 'VÄSTERÅS' },
  { postalCode: '72477', city: 'VÄSTERÅS' },
  { postalCode: '72478', city: 'VÄSTERÅS' },
  { postalCode: '72479', city: 'VÄSTERÅS' },
  { postalCode: '72480', city: 'VÄSTERÅS' },
  { postalCode: '72481', city: 'VÄSTERÅS' },
  { postalCode: '72482', city: 'VÄSTERÅS' },
  { postalCode: '72483', city: 'VÄSTERÅS' },
  { postalCode: '72487', city: 'VÄSTERÅS' },
  { postalCode: '72590', city: 'VÄSTERÅS' },
  { postalCode: '72591', city: 'VÄSTERÅS' },
  { postalCode: '72592', city: 'VÄSTERÅS' },
  { postalCode: '72593', city: 'VÄSTERÅS' },
  { postalCode: '72594', city: 'VÄSTERÅS' },
  { postalCode: '72595', city: 'VÄSTERÅS' },
  { postalCode: '72596', city: 'VÄSTERÅS' },
  { postalCode: '72597', city: 'VÄSTERÅS' },
  { postalCode: '72598', city: 'VÄSTERÅS' },
  { postalCode: '72620', city: 'SKULTUNA' },
  { postalCode: '72630', city: 'SKULTUNA' },
  { postalCode: '72631', city: 'SKULTUNA' },
  { postalCode: '72691', city: 'SKULTUNA' },
  { postalCode: '72692', city: 'SKULTUNA' },
  { postalCode: '72805', city: 'VÄSTERÅS' },
  { postalCode: '72820', city: 'VÄSTERÅS' },
  { postalCode: '73101', city: 'KÖPING' },
  { postalCode: '73102', city: 'KÖPING' },
  { postalCode: '73103', city: 'KÖPING' },
  { postalCode: '73110', city: 'KOLSVA' },
  { postalCode: '73111', city: 'KOLSVA' },
  { postalCode: '73112', city: 'KOLSVA' },
  { postalCode: '73113', city: 'KOLSVA' },
  { postalCode: '73114', city: 'KOLSVA' },
  { postalCode: '73115', city: 'KOLSVA' },
  { postalCode: '73120', city: 'KÖPING' },
  { postalCode: '73121', city: 'KÖPING' },
  { postalCode: '73122', city: 'KÖPING' },
  { postalCode: '73123', city: 'KÖPING' },
  { postalCode: '73124', city: 'KÖPING' },
  { postalCode: '73125', city: 'KÖPING' },
  { postalCode: '73126', city: 'KÖPING' },
  { postalCode: '73127', city: 'KÖPING' },
  { postalCode: '73129', city: 'KÖPING' },
  { postalCode: '73130', city: 'KÖPING' },
  { postalCode: '73131', city: 'KÖPING' },
  { postalCode: '73132', city: 'KÖPING' },
  { postalCode: '73133', city: 'KÖPING' },
  { postalCode: '73134', city: 'KÖPING' },
  { postalCode: '73135', city: 'KÖPING' },
  { postalCode: '73136', city: 'KÖPING' },
  { postalCode: '73137', city: 'KÖPING' },
  { postalCode: '73140', city: 'KÖPING' },
  { postalCode: '73141', city: 'KÖPING' },
  { postalCode: '73142', city: 'KÖPING' },
  { postalCode: '73143', city: 'KÖPING' },
  { postalCode: '73150', city: 'KÖPING' },
  { postalCode: '73151', city: 'KÖPING' },
  { postalCode: '73152', city: 'KÖPING' },
  { postalCode: '73153', city: 'KÖPING' },
  { postalCode: '73154', city: 'KÖPING' },
  { postalCode: '73160', city: 'VALSKOG' },
  { postalCode: '73170', city: 'KÖPING' },
  { postalCode: '73180', city: 'KÖPING' },
  { postalCode: '73181', city: 'KÖPING' },
  { postalCode: '73185', city: 'KÖPING' },
  { postalCode: '73191', city: 'KÖPING' },
  { postalCode: '73192', city: 'KÖPING' },
  { postalCode: '73193', city: 'KÖPING' },
  { postalCode: '73194', city: 'KÖPING' },
  { postalCode: '73195', city: 'KÖPING' },
  { postalCode: '73196', city: 'KÖPING' },
  { postalCode: '73197', city: 'KÖPING' },
  { postalCode: '73198', city: 'KÖPING' },
  { postalCode: '73201', city: 'ARBOGA' },
  { postalCode: '73202', city: 'FELLINGSBRO' },
  { postalCode: '73220', city: 'ARBOGA' },
  { postalCode: '73221', city: 'ARBOGA' },
  { postalCode: '73222', city: 'ARBOGA' },
  { postalCode: '73223', city: 'ARBOGA' },
  { postalCode: '73224', city: 'ARBOGA' },
  { postalCode: '73225', city: 'ARBOGA' },
  { postalCode: '73226', city: 'ARBOGA' },
  { postalCode: '73230', city: 'ARBOGA' },
  { postalCode: '73231', city: 'ARBOGA' },
  { postalCode: '73232', city: 'ARBOGA' },
  { postalCode: '73233', city: 'ARBOGA' },
  { postalCode: '73234', city: 'ARBOGA' },
  { postalCode: '73245', city: 'ARBOGA' },
  { postalCode: '73246', city: 'ARBOGA' },
  { postalCode: '73247', city: 'ARBOGA' },
  { postalCode: '73248', city: 'ARBOGA' },
  { postalCode: '73249', city: 'ARBOGA' },
  { postalCode: '73250', city: 'ARBOGA' },
  { postalCode: '73271', city: 'FELLINGSBRO' },
  { postalCode: '73272', city: 'FELLINGSBRO' },
  { postalCode: '73273', city: 'FELLINGSBRO' },
  { postalCode: '73281', city: 'ARBOGA' },
  { postalCode: '73291', city: 'ARBOGA' },
  { postalCode: '73292', city: 'ARBOGA' },
  { postalCode: '73293', city: 'ARBOGA' },
  { postalCode: '73294', city: 'ARBOGA' },
  { postalCode: '73295', city: 'ARBOGA' },
  { postalCode: '73296', city: 'ARBOGA' },
  { postalCode: '73297', city: 'ARBOGA' },
  { postalCode: '73298', city: 'ARBOGA' },
  { postalCode: '73301', city: 'SALA' },
  { postalCode: '73320', city: 'SALA' },
  { postalCode: '73321', city: 'SALA' },
  { postalCode: '73322', city: 'SALA' },
  { postalCode: '73323', city: 'SALA' },
  { postalCode: '73325', city: 'SALA' },
  { postalCode: '73330', city: 'SALA' },
  { postalCode: '73331', city: 'SALA' },
  { postalCode: '73332', city: 'SALA' },
  { postalCode: '73333', city: 'SALA' },
  { postalCode: '73334', city: 'SALA' },
  { postalCode: '73335', city: 'SALA' },
  { postalCode: '73336', city: 'SALA' },
  { postalCode: '73337', city: 'SALA' },
  { postalCode: '73338', city: 'SALA' },
  { postalCode: '73339', city: 'SALA' },
  { postalCode: '73340', city: 'SALA' },
  { postalCode: '73360', city: 'VÄSTERFÄRNEBO' },
  { postalCode: '73361', city: 'VÄSTERFÄRNEBO' },
  { postalCode: '73362', city: 'VÄSTERFÄRNEBO' },
  { postalCode: '73363', city: 'SALBOHED' },
  { postalCode: '73373', city: 'RANSTA' },
  { postalCode: '73375', city: 'MÖKLINTA' },
  { postalCode: '73391', city: 'SALA' },
  { postalCode: '73392', city: 'SALA' },
  { postalCode: '73393', city: 'SALA' },
  { postalCode: '73394', city: 'SALA' },
  { postalCode: '73395', city: 'SALA' },
  { postalCode: '73396', city: 'SALA' },
  { postalCode: '73397', city: 'SALA' },
  { postalCode: '73398', city: 'RANSTA' },
  { postalCode: '73399', city: 'MÖKLINTA' },
  { postalCode: '73401', city: 'HALLSTAHAMMAR' },
  { postalCode: '73420', city: 'HALLSTAHAMMAR' },
  { postalCode: '73421', city: 'HALLSTAHAMMAR' },
  { postalCode: '73422', city: 'HALLSTAHAMMAR' },
  { postalCode: '73423', city: 'HALLSTAHAMMAR' },
  { postalCode: '73424', city: 'HALLSTAHAMMAR' },
  { postalCode: '73425', city: 'HALLSTAHAMMAR' },
  { postalCode: '73427', city: 'HALLSTAHAMMAR' },
  { postalCode: '73428', city: 'KOLBÄCK' },
  { postalCode: '73430', city: 'HALLSTAHAMMAR' },
  { postalCode: '73431', city: 'HALLSTAHAMMAR' },
  { postalCode: '73432', city: 'HALLSTAHAMMAR' },
  { postalCode: '73433', city: 'HALLSTAHAMMAR' },
  { postalCode: '73434', city: 'HALLSTAHAMMAR' },
  { postalCode: '73435', city: 'HALLSTAHAMMAR' },
  { postalCode: '73436', city: 'HALLSTAHAMMAR' },
  { postalCode: '73437', city: 'HALLSTAHAMMAR' },
  { postalCode: '73438', city: 'HALLSTAHAMMAR' },
  { postalCode: '73439', city: 'HALLSTAHAMMAR' },
  { postalCode: '73440', city: 'HALLSTAHAMMAR' },
  { postalCode: '73450', city: 'KOLBÄCK' },
  { postalCode: '73451', city: 'KOLBÄCK' },
  { postalCode: '73480', city: 'HALLSTAHAMMAR' },
  { postalCode: '73491', city: 'HALLSTAHAMMAR' },
  { postalCode: '73492', city: 'HALLSTAHAMMAR' },
  { postalCode: '73493', city: 'KOLBÄCK' },
  { postalCode: '73494', city: 'STRÖMSHOLM' },
  { postalCode: '73502', city: 'RAMNÄS' },
  { postalCode: '73520', city: 'SURAHAMMAR' },
  { postalCode: '73521', city: 'SURAHAMMAR' },
  { postalCode: '73522', city: 'SURAHAMMAR' },
  { postalCode: '73523', city: 'SURAHAMMAR' },
  { postalCode: '73531', city: 'SURAHAMMAR' },
  { postalCode: '73532', city: 'SURAHAMMAR' },
  { postalCode: '73533', city: 'SURAHAMMAR' },
  { postalCode: '73534', city: 'SURAHAMMAR' },
  { postalCode: '73535', city: 'SURAHAMMAR' },
  { postalCode: '73536', city: 'SURAHAMMAR' },
  { postalCode: '73537', city: 'SURAHAMMAR' },
  { postalCode: '73538', city: 'SURAHAMMAR' },
  { postalCode: '73560', city: 'RAMNÄS' },
  { postalCode: '73591', city: 'SURAHAMMAR' },
  { postalCode: '73592', city: 'RAMNÄS' },
  { postalCode: '73621', city: 'KUNGSÖR' },
  { postalCode: '73622', city: 'KUNGSÖR' },
  { postalCode: '73623', city: 'KUNGSÖR' },
  { postalCode: '73625', city: 'KUNGSÖR' },
  { postalCode: '73630', city: 'KUNGSÖR' },
  { postalCode: '73631', city: 'KUNGSÖR' },
  { postalCode: '73632', city: 'KUNGSÖR' },
  { postalCode: '73633', city: 'KUNGSÖR' },
  { postalCode: '73634', city: 'KUNGSÖR' },
  { postalCode: '73635', city: 'KUNGSÖR' },
  { postalCode: '73636', city: 'KUNGSÖR' },
  { postalCode: '73685', city: 'KUNGSÖR' },
  { postalCode: '73691', city: 'KUNGSÖR' },
  { postalCode: '73692', city: 'KUNGSÖR' },
  { postalCode: '73693', city: 'KUNGSÖR' },
  { postalCode: '73700', city: 'FAGERSTA' },
  { postalCode: '73701', city: 'FAGERSTA' },
  { postalCode: '73702', city: 'FAGERSTA' },
  { postalCode: '73703', city: 'VIRSBO' },
  { postalCode: '73720', city: 'FAGERSTA' },
  { postalCode: '73721', city: 'FAGERSTA' },
  { postalCode: '73722', city: 'FAGERSTA' },
  { postalCode: '73723', city: 'FAGERSTA' },
  { postalCode: '73724', city: 'FAGERSTA' },
  { postalCode: '73725', city: 'FAGERSTA' },
  { postalCode: '73726', city: 'FAGERSTA' },
  { postalCode: '73730', city: 'FAGERSTA' },
  { postalCode: '73731', city: 'FAGERSTA' },
  { postalCode: '73732', city: 'FAGERSTA' },
  { postalCode: '73733', city: 'FAGERSTA' },
  { postalCode: '73734', city: 'FAGERSTA' },
  { postalCode: '73740', city: 'FAGERSTA' },
  { postalCode: '73741', city: 'FAGERSTA' },
  { postalCode: '73742', city: 'FAGERSTA' },
  { postalCode: '73743', city: 'FAGERSTA' },
  { postalCode: '73744', city: 'FAGERSTA' },
  { postalCode: '73745', city: 'FAGERSTA' },
  { postalCode: '73746', city: 'FAGERSTA' },
  { postalCode: '73747', city: 'FAGERSTA' },
  { postalCode: '73748', city: 'FAGERSTA' },
  { postalCode: '73749', city: 'FAGERSTA' },
  { postalCode: '73761', city: 'VIRSBO' },
  { postalCode: '73780', city: 'FAGERSTA' },
  { postalCode: '73781', city: 'FAGERSTA' },
  { postalCode: '73782', city: 'FAGERSTA' },
  { postalCode: '73783', city: 'FAGERSTA' },
  { postalCode: '73784', city: 'FAGERSTA' },
  { postalCode: '73790', city: 'ÄNGELSBERG' },
  { postalCode: '73791', city: 'FAGERSTA' },
  { postalCode: '73792', city: 'FAGERSTA' },
  { postalCode: '73821', city: 'NORBERG' },
  { postalCode: '73822', city: 'NORBERG' },
  { postalCode: '73830', city: 'NORBERG' },
  { postalCode: '73831', city: 'NORBERG' },
  { postalCode: '73832', city: 'NORBERG' },
  { postalCode: '73833', city: 'NORBERG' },
  { postalCode: '73834', city: 'NORBERG' },
  { postalCode: '73835', city: 'NORBERG' },
  { postalCode: '73850', city: 'NORBERG' },
  { postalCode: '73891', city: 'NORBERG' },
  { postalCode: '73892', city: 'NORBERG' },
  { postalCode: '73920', city: 'SKINNSKATTEBERG' },
  { postalCode: '73921', city: 'SKINNSKATTEBERG' },
  { postalCode: '73922', city: 'SKINNSKATTEBERG' },
  { postalCode: '73930', city: 'SKINNSKATTEBERG' },
  { postalCode: '73931', city: 'SKINNSKATTEBERG' },
  { postalCode: '73970', city: 'SKINNSKATTEBERG' },
  { postalCode: '73991', city: 'SKINNSKATTEBERG' },
  { postalCode: '73992', city: 'SKINNSKATTEBERG' },
  { postalCode: '73993', city: 'RIDDARHYTTAN' },
  { postalCode: '74010', city: 'ALMUNGE' },
  { postalCode: '74011', city: 'LÄNNA' },
  { postalCode: '74012', city: 'KNUTBY' },
  { postalCode: '74045', city: 'TÄRNSJÖ' },
  { postalCode: '74046', city: 'ÖSTERVÅLA' },
  { postalCode: '74047', city: 'HARBO' },
  { postalCode: '74101', city: 'KNIVSTA' },
  { postalCode: '74120', city: 'KNIVSTA' },
  { postalCode: '74121', city: 'KNIVSTA' },
  { postalCode: '74122', city: 'KNIVSTA' },
  { postalCode: '74123', city: 'KNIVSTA' },
  { postalCode: '74124', city: 'KNIVSTA' },
  { postalCode: '74130', city: 'KNIVSTA' },
  { postalCode: '74131', city: 'KNIVSTA' },
  { postalCode: '74139', city: 'KNIVSTA' },
  { postalCode: '74140', city: 'KNIVSTA' },
  { postalCode: '74141', city: 'KNIVSTA' },
  { postalCode: '74142', city: 'KNIVSTA' },
  { postalCode: '74143', city: 'KNIVSTA' },
  { postalCode: '74144', city: 'KNIVSTA' },
  { postalCode: '74145', city: 'KNIVSTA' },
  { postalCode: '74146', city: 'KNIVSTA' },
  { postalCode: '74147', city: 'KNIVSTA' },
  { postalCode: '74171', city: 'KNIVSTA' },
  { postalCode: '74175', city: 'KNIVSTA' },
  { postalCode: '74180', city: 'KNIVSTA' },
  { postalCode: '74190', city: 'KNIVSTA' },
  { postalCode: '74191', city: 'KNIVSTA' },
  { postalCode: '74192', city: 'KNIVSTA' },
  { postalCode: '74193', city: 'KNIVSTA' },
  { postalCode: '74194', city: 'KNIVSTA' },
  { postalCode: '74195', city: 'KNIVSTA' },
  { postalCode: '74200', city: 'ÖSTHAMMAR' },
  { postalCode: '74203', city: 'ÖSTHAMMAR' },
  { postalCode: '74220', city: 'ÖSTHAMMAR' },
  { postalCode: '74221', city: 'ÖSTHAMMAR' },
  { postalCode: '74231', city: 'ÖSTHAMMAR' },
  { postalCode: '74232', city: 'ÖSTHAMMAR' },
  { postalCode: '74233', city: 'ÖSTHAMMAR' },
  { postalCode: '74234', city: 'ÖSTHAMMAR' },
  { postalCode: '74235', city: 'ÖSTHAMMAR' },
  { postalCode: '74236', city: 'ÖSTHAMMAR' },
  { postalCode: '74241', city: 'ÖREGRUND' },
  { postalCode: '74242', city: 'ÖREGRUND' },
  { postalCode: '74243', city: 'ÖREGRUND' },
  { postalCode: '74250', city: 'HARGSHAMN' },
  { postalCode: '74291', city: 'ÖSTHAMMAR' },
  { postalCode: '74292', city: 'ÖSTHAMMAR' },
  { postalCode: '74293', city: 'ÖSTHAMMAR' },
  { postalCode: '74294', city: 'ÖSTHAMMAR' },
  { postalCode: '74295', city: 'HARGSHAMN' },
  { postalCode: '74296', city: 'GRÄSÖ' },
  { postalCode: '74297', city: 'GRÄSÖ' },
  { postalCode: '74301', city: 'STORVRETA' },
  { postalCode: '74305', city: 'BÄLINGE' },
  { postalCode: '74310', city: 'BJÖRKLINGE' },
  { postalCode: '74320', city: 'STORVRETA' },
  { postalCode: '74330', city: 'STORVRETA' },
  { postalCode: '74332', city: 'STORVRETA' },
  { postalCode: '74334', city: 'STORVRETA' },
  { postalCode: '74335', city: 'STORVRETA' },
  { postalCode: '74340', city: 'STORVRETA' },
  { postalCode: '74341', city: 'STORVRETA' },
  { postalCode: '74345', city: 'STORVRETA' },
  { postalCode: '74350', city: 'VATTHOLMA' },
  { postalCode: '74361', city: 'BJÖRKLINGE' },
  { postalCode: '74362', city: 'BJÖRKLINGE' },
  { postalCode: '74363', city: 'BJÖRKLINGE' },
  { postalCode: '74364', city: 'BJÖRKLINGE' },
  { postalCode: '74371', city: 'BJÖRKLINGE' },
  { postalCode: '74372', city: 'BJÖRKLINGE' },
  { postalCode: '74373', city: 'BJÖRKLINGE' },
  { postalCode: '74374', city: 'BJÖRKLINGE' },
  { postalCode: '74381', city: 'BÄLINGE' },
  { postalCode: '74382', city: 'BÄLINGE' },
  { postalCode: '74386', city: 'BÄLINGE' },
  { postalCode: '74387', city: 'BÄLINGE' },
  { postalCode: '74391', city: 'STORVRETA' },
  { postalCode: '74392', city: 'VATTHOLMA' },
  { postalCode: '74393', city: 'VATTHOLMA' },
  { postalCode: '74394', city: 'SKYTTORP' },
  { postalCode: '74401', city: 'HEBY' },
  { postalCode: '74420', city: 'HEBY' },
  { postalCode: '74421', city: 'HEBY' },
  { postalCode: '74431', city: 'HEBY' },
  { postalCode: '74432', city: 'HEBY' },
  { postalCode: '74450', city: 'MORGONGÅVA' },
  { postalCode: '74451', city: 'MORGONGÅVA' },
  { postalCode: '74481', city: 'MORGONGÅVA' },
  { postalCode: '74482', city: 'MORGONGÅVA' },
  { postalCode: '74488', city: 'HEBY' },
  { postalCode: '74491', city: 'HEBY' },
  { postalCode: '74492', city: 'HUDDUNGEBY' },
  { postalCode: '74493', city: 'RUNHÄLLEN' },
  { postalCode: '74494', city: 'MORGONGÅVA' },
  { postalCode: '74495', city: 'VITTINGE' },
  { postalCode: '74496', city: 'JÄRLÅSA' },
  { postalCode: '74497', city: 'JÄRLÅSA' },
  { postalCode: '74500', city: 'ENKÖPING' },
  { postalCode: '74502', city: 'ENKÖPING' },
  { postalCode: '74503', city: 'ENKÖPING' },
  { postalCode: '74510', city: 'ENKÖPING' },
  { postalCode: '74520', city: 'ENKÖPING' },
  { postalCode: '74521', city: 'ENKÖPING' },
  { postalCode: '74522', city: 'ENKÖPING' },
  { postalCode: '74523', city: 'ENKÖPING' },
  { postalCode: '74525', city: 'ENKÖPING' },
  { postalCode: '74526', city: 'ENKÖPING' },
  { postalCode: '74527', city: 'ENKÖPING' },
  { postalCode: '74528', city: 'ENKÖPING' },
  { postalCode: '74530', city: 'ENKÖPING' },
  { postalCode: '74531', city: 'ENKÖPING' },
  { postalCode: '74532', city: 'ENKÖPING' },
  { postalCode: '74533', city: 'ENKÖPING' },
  { postalCode: '74534', city: 'ENKÖPING' },
  { postalCode: '74536', city: 'ENKÖPING' },
  { postalCode: '74537', city: 'ENKÖPING' },
  { postalCode: '74538', city: 'ENKÖPING' },
  { postalCode: '74539', city: 'ENKÖPING' },
  { postalCode: '74560', city: 'ENKÖPING' },
  { postalCode: '74561', city: 'ENKÖPING' },
  { postalCode: '74562', city: 'ENKÖPING' },
  { postalCode: '74563', city: 'ENKÖPING' },
  { postalCode: '74569', city: 'ENKÖPING' },
  { postalCode: '74571', city: 'ENKÖPING' },
  { postalCode: '74580', city: 'ENKÖPING' },
  { postalCode: '74582', city: 'ENKÖPING' },
  { postalCode: '74583', city: 'ENKÖPING' },
  { postalCode: '74584', city: 'ENKÖPING' },
  { postalCode: '74585', city: 'ENKÖPING' },
  { postalCode: '74586', city: 'ENKÖPING' },
  { postalCode: '74588', city: 'ENKÖPING' },
  { postalCode: '74592', city: 'ENKÖPING' },
  { postalCode: '74593', city: 'ENKÖPING' },
  { postalCode: '74594', city: 'ENKÖPING' },
  { postalCode: '74595', city: 'ENKÖPING' },
  { postalCode: '74596', city: 'ENKÖPING' },
  { postalCode: '74597', city: 'ENKÖPING' },
  { postalCode: '74598', city: 'ENKÖPING' },
  { postalCode: '74599', city: 'ENKÖPING' },
  { postalCode: '74601', city: 'BÅLSTA' },
  { postalCode: '74620', city: 'BÅLSTA' },
  { postalCode: '74621', city: 'BÅLSTA' },
  { postalCode: '74622', city: 'BÅLSTA' },
  { postalCode: '74623', city: 'BÅLSTA' },
  { postalCode: '74624', city: 'BÅLSTA' },
  { postalCode: '74625', city: 'BÅLSTA' },
  { postalCode: '74626', city: 'BÅLSTA' },
  { postalCode: '74627', city: 'BÅLSTA' },
  { postalCode: '74629', city: 'BÅLSTA' },
  { postalCode: '74630', city: 'BÅLSTA' },
  { postalCode: '74631', city: 'BÅLSTA' },
  { postalCode: '74632', city: 'BÅLSTA' },
  { postalCode: '74633', city: 'BÅLSTA' },
  { postalCode: '74634', city: 'BÅLSTA' },
  { postalCode: '74635', city: 'BÅLSTA' },
  { postalCode: '74636', city: 'BÅLSTA' },
  { postalCode: '74637', city: 'BÅLSTA' },
  { postalCode: '74638', city: 'BÅLSTA' },
  { postalCode: '74639', city: 'BÅLSTA' },
  { postalCode: '74640', city: 'BÅLSTA' },
  { postalCode: '74641', city: 'BÅLSTA' },
  { postalCode: '74650', city: 'BÅLSTA' },
  { postalCode: '74651', city: 'BÅLSTA' },
  { postalCode: '74652', city: 'BÅLSTA' },
  { postalCode: '74680', city: 'BÅLSTA' },
  { postalCode: '74691', city: 'BÅLSTA' },
  { postalCode: '74692', city: 'BÅLSTA' },
  { postalCode: '74693', city: 'BÅLSTA' },
  { postalCode: '74694', city: 'HÄGGEBY' },
  { postalCode: '74695', city: 'SKOKLOSTER' },
  { postalCode: '74696', city: 'SKOKLOSTER' },
  { postalCode: '74700', city: 'ALUNDA' },
  { postalCode: '74701', city: 'GIMO' },
  { postalCode: '74702', city: 'GIMO' },
  { postalCode: '74703', city: 'GIMO' },
  { postalCode: '74704', city: 'GIMO' },
  { postalCode: '74705', city: 'GIMO' },
  { postalCode: '74720', city: 'ALUNDA' },
  { postalCode: '74721', city: 'ALUNDA' },
  { postalCode: '74730', city: 'ALUNDA' },
  { postalCode: '74731', city: 'ALUNDA' },
  { postalCode: '74732', city: 'ALUNDA' },
  { postalCode: '74740', city: 'GIMO' },
  { postalCode: '74741', city: 'GIMO' },
  { postalCode: '74742', city: 'GIMO' },
  { postalCode: '74743', city: 'GIMO' },
  { postalCode: '74744', city: 'GIMO' },
  { postalCode: '74780', city: 'GIMO' },
  { postalCode: '74791', city: 'ALUNDA' },
  { postalCode: '74792', city: 'ALUNDA' },
  { postalCode: '74793', city: 'ALUNDA' },
  { postalCode: '74794', city: 'ALUNDA' },
  { postalCode: '74795', city: 'GIMO' },
  { postalCode: '74801', city: 'ÖSTERBYBRUK' },
  { postalCode: '74820', city: 'ÖSTERBYBRUK' },
  { postalCode: '74821', city: 'ÖSTERBYBRUK' },
  { postalCode: '74822', city: 'ÖSTERBYBRUK' },
  { postalCode: '74830', city: 'ÖSTERBYBRUK' },
  { postalCode: '74831', city: 'ÖSTERBYBRUK' },
  { postalCode: '74832', city: 'ÖSTERBYBRUK' },
  { postalCode: '74840', city: 'ÖRBYHUS' },
  { postalCode: '74841', city: 'ÖRBYHUS' },
  { postalCode: '74842', city: 'ÖRBYHUS' },
  { postalCode: '74850', city: 'TOBO' },
  { postalCode: '74891', city: 'ÖSTERBYBRUK' },
  { postalCode: '74892', city: 'ÖSTERBYBRUK' },
  { postalCode: '74893', city: 'ÖSTERBYBRUK' },
  { postalCode: '74894', city: 'ÖRBYHUS' },
  { postalCode: '74895', city: 'ÖRBYHUS' },
  { postalCode: '74896', city: 'TOBO' },
  { postalCode: '74900', city: 'ENKÖPING' },
  { postalCode: '74906', city: 'ÖRSUNDSBRO' },
  { postalCode: '74935', city: 'ENKÖPING' },
  { postalCode: '74940', city: 'ENKÖPING' },
  { postalCode: '74941', city: 'ENKÖPING' },
  { postalCode: '74942', city: 'ENKÖPING' },
  { postalCode: '74943', city: 'ENKÖPING' },
  { postalCode: '74944', city: 'ENKÖPING' },
  { postalCode: '74945', city: 'ENKÖPING' },
  { postalCode: '74946', city: 'ENKÖPING' },
  { postalCode: '74947', city: 'ENKÖPING' },
  { postalCode: '74948', city: 'ENKÖPING' },
  { postalCode: '74949', city: 'ENKÖPING' },
  { postalCode: '74950', city: 'EKOLSUND' },
  { postalCode: '74951', city: 'GRILLBY' },
  { postalCode: '74952', city: 'GRILLBY' },
  { postalCode: '74959', city: 'ENKÖPING' },
  { postalCode: '74960', city: 'ÖRSUNDSBRO' },
  { postalCode: '74961', city: 'ÖRSUNDSBRO' },
  { postalCode: '74962', city: 'ÖRSUNDSBRO' },
  { postalCode: '74963', city: 'ÖRSUNDSBRO' },
  { postalCode: '74970', city: 'FJÄRDHUNDRA' },
  { postalCode: '74971', city: 'FJÄRDHUNDRA' },
  { postalCode: '74972', city: 'FJÄRDHUNDRA' },
  { postalCode: '74981', city: 'ENKÖPING' },
  { postalCode: '75002', city: 'UPPSALA' },
  { postalCode: '75003', city: 'UPPSALA' },
  { postalCode: '75007', city: 'UPPSALA' },
  { postalCode: '75009', city: 'UPPSALA' },
  { postalCode: '75010', city: 'UPPSALA' },
  { postalCode: '75015', city: 'UPPSALA' },
  { postalCode: '75016', city: 'UPPSALA' },
  { postalCode: '75017', city: 'UPPSALA' },
  { postalCode: '75023', city: 'UPPSALA' },
  { postalCode: '75024', city: 'UPPSALA' },
  { postalCode: '75025', city: 'UPPSALA' },
  { postalCode: '75026', city: 'UPPSALA' },
  { postalCode: '75100', city: 'UPPSALA' },
  { postalCode: '75103', city: 'UPPSALA' },
  { postalCode: '75104', city: 'UPPSALA' },
  { postalCode: '75105', city: 'UPPSALA' },
  { postalCode: '75106', city: 'UPPSALA' },
  { postalCode: '75108', city: 'UPPSALA' },
  { postalCode: '75109', city: 'UPPSALA' },
  { postalCode: '75110', city: 'UPPSALA' },
  { postalCode: '75120', city: 'UPPSALA' },
  { postalCode: '75121', city: 'UPPSALA' },
  { postalCode: '75122', city: 'UPPSALA' },
  { postalCode: '75123', city: 'UPPSALA' },
  { postalCode: '75124', city: 'UPPSALA' },
  { postalCode: '75125', city: 'UPPSALA' },
  { postalCode: '75126', city: 'UPPSALA' },
  { postalCode: '75127', city: 'UPPSALA' },
  { postalCode: '75128', city: 'UPPSALA' },
  { postalCode: '75135', city: 'UPPSALA' },
  { postalCode: '75136', city: 'UPPSALA' },
  { postalCode: '75137', city: 'UPPSALA' },
  { postalCode: '75138', city: 'UPPSALA' },
  { postalCode: '75140', city: 'UPPSALA' },
  { postalCode: '75141', city: 'UPPSALA' },
  { postalCode: '75142', city: 'UPPSALA' },
  { postalCode: '75143', city: 'UPPSALA' },
  { postalCode: '75144', city: 'UPPSALA' },
  { postalCode: '75145', city: 'UPPSALA' },
  { postalCode: '75146', city: 'UPPSALA' },
  { postalCode: '75147', city: 'UPPSALA' },
  { postalCode: '75148', city: 'UPPSALA' },
  { postalCode: '75149', city: 'UPPSALA' },
  { postalCode: '75170', city: 'UPPSALA' },
  { postalCode: '75171', city: 'UPPSALA' },
  { postalCode: '75174', city: 'UPPSALA' },
  { postalCode: '75175', city: 'UPPSALA' },
  { postalCode: '75176', city: 'UPPSALA' },
  { postalCode: '75181', city: 'UPPSALA' },
  { postalCode: '75182', city: 'UPPSALA' },
  { postalCode: '75183', city: 'UPPSALA' },
  { postalCode: '75184', city: 'UPPSALA' },
  { postalCode: '75185', city: 'UPPSALA' },
  { postalCode: '75186', city: 'UPPSALA' },
  { postalCode: '75187', city: 'UPPSALA' },
  { postalCode: '75188', city: 'UPPSALA' },
  { postalCode: '75189', city: 'UPPSALA' },
  { postalCode: '75200', city: 'UPPSALA' },
  { postalCode: '75217', city: 'UPPSALA' },
  { postalCode: '75218', city: 'UPPSALA' },
  { postalCode: '75219', city: 'UPPSALA' },
  { postalCode: '75220', city: 'UPPSALA' },
  { postalCode: '75221', city: 'UPPSALA' },
  { postalCode: '75222', city: 'UPPSALA' },
  { postalCode: '75223', city: 'UPPSALA' },
  { postalCode: '75224', city: 'UPPSALA' },
  { postalCode: '75225', city: 'UPPSALA' },
  { postalCode: '75226', city: 'UPPSALA' },
  { postalCode: '75227', city: 'UPPSALA' },
  { postalCode: '75228', city: 'UPPSALA' },
  { postalCode: '75229', city: 'UPPSALA' },
  { postalCode: '75230', city: 'UPPSALA' },
  { postalCode: '75231', city: 'UPPSALA' },
  { postalCode: '75232', city: 'UPPSALA' },
  { postalCode: '75233', city: 'UPPSALA' },
  { postalCode: '75234', city: 'UPPSALA' },
  { postalCode: '75235', city: 'UPPSALA' },
  { postalCode: '75236', city: 'UPPSALA' },
  { postalCode: '75237', city: 'UPPSALA' },
  { postalCode: '75238', city: 'UPPSALA' },
  { postalCode: '75239', city: 'UPPSALA' },
  { postalCode: '75240', city: 'UPPSALA' },
  { postalCode: '75241', city: 'UPPSALA' },
  { postalCode: '75242', city: 'UPPSALA' },
  { postalCode: '75243', city: 'UPPSALA' },
  { postalCode: '75244', city: 'UPPSALA' },
  { postalCode: '75257', city: 'UPPSALA' },
  { postalCode: '75258', city: 'UPPSALA' },
  { postalCode: '75260', city: 'UPPSALA' },
  { postalCode: '75261', city: 'UPPSALA' },
  { postalCode: '75262', city: 'UPPSALA' },
  { postalCode: '75263', city: 'UPPSALA' },
  { postalCode: '75264', city: 'UPPSALA' },
  { postalCode: '75265', city: 'UPPSALA' },
  { postalCode: '75266', city: 'UPPSALA' },
  { postalCode: '75267', city: 'UPPSALA' },
  { postalCode: '75268', city: 'UPPSALA' },
  { postalCode: '75269', city: 'UPPSALA' },
  { postalCode: '75271', city: 'UPPSALA' },
  { postalCode: '75272', city: 'UPPSALA' },
  { postalCode: '75273', city: 'UPPSALA' },
  { postalCode: '75300', city: 'UPPSALA' },
  { postalCode: '75301', city: 'UPPSALA' },
  { postalCode: '75302', city: 'UPPSALA' },
  { postalCode: '75303', city: 'UPPSALA' },
  { postalCode: '75308', city: 'UPPSALA' },
  { postalCode: '75309', city: 'UPPSALA' },
  { postalCode: '75310', city: 'UPPSALA' },
  { postalCode: '75311', city: 'UPPSALA' },
  { postalCode: '75312', city: 'UPPSALA' },
  { postalCode: '75313', city: 'UPPSALA' },
  { postalCode: '75314', city: 'UPPSALA' },
  { postalCode: '75315', city: 'UPPSALA' },
  { postalCode: '75316', city: 'UPPSALA' },
  { postalCode: '75317', city: 'UPPSALA' },
  { postalCode: '75318', city: 'UPPSALA' },
  { postalCode: '75319', city: 'UPPSALA' },
  { postalCode: '75320', city: 'UPPSALA' },
  { postalCode: '75321', city: 'UPPSALA' },
  { postalCode: '75322', city: 'UPPSALA' },
  { postalCode: '75323', city: 'UPPSALA' },
  { postalCode: '75324', city: 'UPPSALA' },
  { postalCode: '75325', city: 'UPPSALA' },
  { postalCode: '75326', city: 'UPPSALA' },
  { postalCode: '75327', city: 'UPPSALA' },
  { postalCode: '75328', city: 'UPPSALA' },
  { postalCode: '75329', city: 'UPPSALA' },
  { postalCode: '75330', city: 'UPPSALA' },
  { postalCode: '75331', city: 'UPPSALA' },
  { postalCode: '75332', city: 'UPPSALA' },
  { postalCode: '75333', city: 'UPPSALA' },
  { postalCode: '75334', city: 'UPPSALA' },
  { postalCode: '75335', city: 'UPPSALA' },
  { postalCode: '75336', city: 'UPPSALA' },
  { postalCode: '75337', city: 'UPPSALA' },
  { postalCode: '75340', city: 'UPPSALA' },
  { postalCode: '75341', city: 'UPPSALA' },
  { postalCode: '75342', city: 'UPPSALA' },
  { postalCode: '75375', city: 'UPPSALA' },
  { postalCode: '75380', city: 'UPPSALA' },
  { postalCode: '75381', city: 'UPPSALA' },
  { postalCode: '75382', city: 'UPPSALA' },
  { postalCode: '75383', city: 'UPPSALA' },
  { postalCode: '75400', city: 'UPPSALA' },
  { postalCode: '75418', city: 'UPPSALA' },
  { postalCode: '75419', city: 'UPPSALA' },
  { postalCode: '75420', city: 'UPPSALA' },
  { postalCode: '75421', city: 'UPPSALA' },
  { postalCode: '75422', city: 'UPPSALA' },
  { postalCode: '75423', city: 'UPPSALA' },
  { postalCode: '75424', city: 'UPPSALA' },
  { postalCode: '75425', city: 'UPPSALA' },
  { postalCode: '75426', city: 'UPPSALA' },
  { postalCode: '75427', city: 'UPPSALA' },
  { postalCode: '75428', city: 'UPPSALA' },
  { postalCode: '75429', city: 'UPPSALA' },
  { postalCode: '75430', city: 'UPPSALA' },
  { postalCode: '75431', city: 'UPPSALA' },
  { postalCode: '75432', city: 'UPPSALA' },
  { postalCode: '75433', city: 'UPPSALA' },
  { postalCode: '75434', city: 'UPPSALA' },
  { postalCode: '75435', city: 'UPPSALA' },
  { postalCode: '75436', city: 'UPPSALA' },
  { postalCode: '75437', city: 'UPPSALA' },
  { postalCode: '75439', city: 'UPPSALA' },
  { postalCode: '75440', city: 'UPPSALA' },
  { postalCode: '75441', city: 'UPPSALA' },
  { postalCode: '75442', city: 'UPPSALA' },
  { postalCode: '75443', city: 'UPPSALA' },
  { postalCode: '75444', city: 'UPPSALA' },
  { postalCode: '75445', city: 'UPPSALA' },
  { postalCode: '75446', city: 'UPPSALA' },
  { postalCode: '75447', city: 'UPPSALA' },
  { postalCode: '75448', city: 'UPPSALA' },
  { postalCode: '75449', city: 'UPPSALA' },
  { postalCode: '75450', city: 'UPPSALA' },
  { postalCode: '75452', city: 'UPPSALA' },
  { postalCode: '75453', city: 'UPPSALA' },
  { postalCode: '75454', city: 'UPPSALA' },
  { postalCode: '75460', city: 'UPPSALA' },
  { postalCode: '75471', city: 'UPPSALA' },
  { postalCode: '75500', city: 'UPPSALA' },
  { postalCode: '75550', city: 'UPPSALA' },
  { postalCode: '75576', city: 'VÄNGE' },
  { postalCode: '75577', city: 'VÄNGE' },
  { postalCode: '75578', city: 'VÄNGE' },
  { postalCode: '75591', city: 'UPPSALA' },
  { postalCode: '75592', city: 'UPPSALA' },
  { postalCode: '75593', city: 'UPPSALA' },
  { postalCode: '75594', city: 'UPPSALA' },
  { postalCode: '75595', city: 'UPPSALA' },
  { postalCode: '75596', city: 'UPPSALA' },
  { postalCode: '75597', city: 'UPPSALA' },
  { postalCode: '75598', city: 'UPPSALA' },
  { postalCode: '75600', city: 'UPPSALA' },
  { postalCode: '75642', city: 'UPPSALA' },
  { postalCode: '75643', city: 'UPPSALA' },
  { postalCode: '75644', city: 'UPPSALA' },
  { postalCode: '75645', city: 'UPPSALA' },
  { postalCode: '75646', city: 'UPPSALA' },
  { postalCode: '75647', city: 'UPPSALA' },
  { postalCode: '75648', city: 'UPPSALA' },
  { postalCode: '75649', city: 'UPPSALA' },
  { postalCode: '75650', city: 'UPPSALA' },
  { postalCode: '75651', city: 'UPPSALA' },
  { postalCode: '75652', city: 'UPPSALA' },
  { postalCode: '75653', city: 'UPPSALA' },
  { postalCode: '75654', city: 'UPPSALA' },
  { postalCode: '75655', city: 'UPPSALA' },
  { postalCode: '75656', city: 'UPPSALA' },
  { postalCode: '75657', city: 'UPPSALA' },
  { postalCode: '75658', city: 'UPPSALA' },
  { postalCode: '75659', city: 'UPPSALA' },
  { postalCode: '75700', city: 'UPPSALA' },
  { postalCode: '75752', city: 'UPPSALA' },
  { postalCode: '75753', city: 'UPPSALA' },
  { postalCode: '75754', city: 'UPPSALA' },
  { postalCode: '75755', city: 'UPPSALA' },
  { postalCode: '75756', city: 'UPPSALA' },
  { postalCode: '75757', city: 'UPPSALA' },
  { postalCode: '75758', city: 'UPPSALA' },
  { postalCode: '75759', city: 'UPPSALA' },
  { postalCode: '75800', city: 'UPPSALA' },
  { postalCode: '75801', city: 'UPPSALA' },
  { postalCode: '75802', city: 'UPPSALA' },
  { postalCode: '75803', city: 'UPPSALA' },
  { postalCode: '75806', city: 'UPPSALA' },
  { postalCode: '75852', city: 'UPPSALA' },
  { postalCode: '75853', city: 'UPPSALA' },
  { postalCode: '75854', city: 'UPPSALA' },
  { postalCode: '75855', city: 'UPPSALA' },
  { postalCode: '75856', city: 'UPPSALA' },
  { postalCode: '75900', city: 'UPPSALA' },
  { postalCode: '76015', city: 'GRÄDDÖ' },
  { postalCode: '76017', city: 'BLIDÖ' },
  { postalCode: '76018', city: 'YXLAN' },
  { postalCode: '76019', city: 'FURUSUND' },
  { postalCode: '76021', city: 'VÄTÖ' },
  { postalCode: '76049', city: 'HERRÄNG' },
  { postalCode: '76100', city: 'NORRTÄLJE' },
  { postalCode: '76102', city: 'NORRTÄLJE' },
  { postalCode: '76103', city: 'NORRTÄLJE' },
  { postalCode: '76110', city: 'NORRTÄLJE' },
  { postalCode: '76111', city: 'BERGSHAMRA' },
  { postalCode: '76112', city: 'BERGSHAMRA' },
  { postalCode: '76113', city: 'RIALA' },
  { postalCode: '76120', city: 'NORRTÄLJE' },
  { postalCode: '76121', city: 'NORRTÄLJE' },
  { postalCode: '76122', city: 'NORRTÄLJE' },
  { postalCode: '76123', city: 'NORRTÄLJE' },
  { postalCode: '76124', city: 'NORRTÄLJE' },
  { postalCode: '76128', city: 'NORRTÄLJE' },
  { postalCode: '76129', city: 'NORRTÄLJE' },
  { postalCode: '76130', city: 'NORRTÄLJE' },
  { postalCode: '76131', city: 'NORRTÄLJE' },
  { postalCode: '76132', city: 'NORRTÄLJE' },
  { postalCode: '76133', city: 'NORRTÄLJE' },
  { postalCode: '76134', city: 'NORRTÄLJE' },
  { postalCode: '76140', city: 'NORRTÄLJE' },
  { postalCode: '76141', city: 'NORRTÄLJE' },
  { postalCode: '76142', city: 'NORRTÄLJE' },
  { postalCode: '76143', city: 'NORRTÄLJE' },
  { postalCode: '76144', city: 'NORRTÄLJE' },
  { postalCode: '76145', city: 'NORRTÄLJE' },
  { postalCode: '76146', city: 'NORRTÄLJE' },
  { postalCode: '76150', city: 'NORRTÄLJE' },
  { postalCode: '76151', city: 'NORRTÄLJE' },
  { postalCode: '76152', city: 'NORRTÄLJE' },
  { postalCode: '76153', city: 'NORRTÄLJE' },
  { postalCode: '76154', city: 'NORRTÄLJE' },
  { postalCode: '76160', city: 'NORRTÄLJE' },
  { postalCode: '76161', city: 'NORRTÄLJE' },
  { postalCode: '76162', city: 'NORRTÄLJE' },
  { postalCode: '76163', city: 'NORRTÄLJE' },
  { postalCode: '76164', city: 'NORRTÄLJE' },
  { postalCode: '76165', city: 'NORRTÄLJE' },
  { postalCode: '76171', city: 'NORRTÄLJE' },
  { postalCode: '76172', city: 'NORRTÄLJE' },
  { postalCode: '76173', city: 'NORRTÄLJE' },
  { postalCode: '76174', city: 'NORRTÄLJE' },
  { postalCode: '76175', city: 'NORRTÄLJE' },
  { postalCode: '76176', city: 'NORRTÄLJE' },
  { postalCode: '76180', city: 'NORRTÄLJE' },
  { postalCode: '76184', city: 'NORRTÄLJE' },
  { postalCode: '76188', city: 'NORRTÄLJE' },
  { postalCode: '76191', city: 'NORRTÄLJE' },
  { postalCode: '76193', city: 'NORRTÄLJE' },
  { postalCode: '76194', city: 'NORRTÄLJE' },
  { postalCode: '76196', city: 'TJOCKÖ' },
  { postalCode: '76197', city: 'NORRTÄLJE' },
  { postalCode: '76198', city: 'NORRTÄLJE' },
  { postalCode: '76200', city: 'RIMBO' },
  { postalCode: '76220', city: 'RIMBO' },
  { postalCode: '76221', city: 'RIMBO' },
  { postalCode: '76222', city: 'RIMBO' },
  { postalCode: '76223', city: 'RIMBO' },
  { postalCode: '76231', city: 'RIMBO' },
  { postalCode: '76241', city: 'RIMBO' },
  { postalCode: '76251', city: 'RIMBO' },
  { postalCode: '76261', city: 'RIMBO' },
  { postalCode: '76262', city: 'RIMBO' },
  { postalCode: '76271', city: 'RIMBO' },
  { postalCode: '76291', city: 'RIMBO' },
  { postalCode: '76292', city: 'RIMBO' },
  { postalCode: '76293', city: 'RÖ' },
  { postalCode: '76294', city: 'RIMBO' },
  { postalCode: '76295', city: 'RIMBO' },
  { postalCode: '76296', city: 'RÅNÄS' },
  { postalCode: '76297', city: 'EDSBRO' },
  { postalCode: '76298', city: 'EDSBRO' },
  { postalCode: '76300', city: 'HALLSTAVIK' },
  { postalCode: '76320', city: 'HALLSTAVIK' },
  { postalCode: '76321', city: 'HALLSTAVIK' },
  { postalCode: '76322', city: 'HALLSTAVIK' },
  { postalCode: '76323', city: 'HALLSTAVIK' },
  { postalCode: '76330', city: 'HALLSTAVIK' },
  { postalCode: '76334', city: 'HALLSTAVIK' },
  { postalCode: '76335', city: 'HALLSTAVIK' },
  { postalCode: '76340', city: 'HALLSTAVIK' },
  { postalCode: '76341', city: 'HALLSTAVIK' },
  { postalCode: '76342', city: 'HALLSTAVIK' },
  { postalCode: '76343', city: 'HALLSTAVIK' },
  { postalCode: '76381', city: 'HALLSTAVIK' },
  { postalCode: '76382', city: 'HALLSTAVIK' },
  { postalCode: '76391', city: 'HALLSTAVIK' },
  { postalCode: '76392', city: 'HALLSTAVIK' },
  { postalCode: '76393', city: 'SKEBOBRUK' },
  { postalCode: '76394', city: 'HALLSTAVIK' },
  { postalCode: '76421', city: 'VÄDDÖ' },
  { postalCode: '76430', city: 'VÄDDÖ' },
  { postalCode: '76431', city: 'VÄDDÖ' },
  { postalCode: '76453', city: 'BJÖRKÖ' },
  { postalCode: '76454', city: 'ARHOLMA' },
  { postalCode: '76456', city: 'GRISSLEHAMN' },
  { postalCode: '76457', city: 'GRISSLEHAMN' },
  { postalCode: '76491', city: 'VÄDDÖ' },
  { postalCode: '76492', city: 'VÄDDÖ' },
  { postalCode: '76493', city: 'VÄDDÖ' },
  { postalCode: '77010', city: 'FREDRIKSBERG' },
  { postalCode: '77012', city: 'SUNNANSJÖ' },
  { postalCode: '77013', city: 'GRANGÄRDE' },
  { postalCode: '77014', city: 'NYHAMMAR' },
  { postalCode: '77101', city: 'LUDVIKA' },
  { postalCode: '77120', city: 'LUDVIKA' },
  { postalCode: '77121', city: 'LUDVIKA' },
  { postalCode: '77122', city: 'LUDVIKA' },
  { postalCode: '77123', city: 'LUDVIKA' },
  { postalCode: '77124', city: 'LUDVIKA' },
  { postalCode: '77125', city: 'LUDVIKA' },
  { postalCode: '77126', city: 'LUDVIKA' },
  { postalCode: '77128', city: 'LUDVIKA' },
  { postalCode: '77130', city: 'LUDVIKA' },
  { postalCode: '77131', city: 'LUDVIKA' },
  { postalCode: '77132', city: 'LUDVIKA' },
  { postalCode: '77133', city: 'LUDVIKA' },
  { postalCode: '77134', city: 'LUDVIKA' },
  { postalCode: '77135', city: 'LUDVIKA' },
  { postalCode: '77136', city: 'LUDVIKA' },
  { postalCode: '77140', city: 'LUDVIKA' },
  { postalCode: '77141', city: 'LUDVIKA' },
  { postalCode: '77142', city: 'LUDVIKA' },
  { postalCode: '77143', city: 'LUDVIKA' },
  { postalCode: '77150', city: 'LUDVIKA' },
  { postalCode: '77151', city: 'LUDVIKA' },
  { postalCode: '77152', city: 'LUDVIKA' },
  { postalCode: '77153', city: 'LUDVIKA' },
  { postalCode: '77154', city: 'LUDVIKA' },
  { postalCode: '77155', city: 'LUDVIKA' },
  { postalCode: '77160', city: 'LUDVIKA' },
  { postalCode: '77165', city: 'LUDVIKA' },
  { postalCode: '77180', city: 'LUDVIKA' },
  { postalCode: '77181', city: 'LUDVIKA' },
  { postalCode: '77182', city: 'LUDVIKA' },
  { postalCode: '77183', city: 'LUDVIKA' },
  { postalCode: '77184', city: 'LUDVIKA' },
  { postalCode: '77190', city: 'LUDVIKA' },
  { postalCode: '77192', city: 'LUDVIKA' },
  { postalCode: '77194', city: 'LUDVIKA' },
  { postalCode: '77201', city: 'GRÄNGESBERG' },
  { postalCode: '77220', city: 'GRÄNGESBERG' },
  { postalCode: '77221', city: 'GRÄNGESBERG' },
  { postalCode: '77222', city: 'GRÄNGESBERG' },
  { postalCode: '77230', city: 'GRÄNGESBERG' },
  { postalCode: '77231', city: 'GRÄNGESBERG' },
  { postalCode: '77232', city: 'GRÄNGESBERG' },
  { postalCode: '77240', city: 'GRÄNGESBERG' },
  { postalCode: '77250', city: 'GRÄNGESBERG' },
  { postalCode: '77270', city: 'SAXDALEN' },
  { postalCode: '77280', city: 'GRÄNGESBERG' },
  { postalCode: '77290', city: 'GRÄNGESBERG' },
  { postalCode: '77294', city: 'GRÄNGESBERG' },
  { postalCode: '77401', city: 'AVESTA' },
  { postalCode: '77404', city: 'HORNDAL' },
  { postalCode: '77420', city: 'AVESTA' },
  { postalCode: '77421', city: 'AVESTA' },
  { postalCode: '77422', city: 'AVESTA' },
  { postalCode: '77423', city: 'AVESTA' },
  { postalCode: '77424', city: 'AVESTA' },
  { postalCode: '77427', city: 'AVESTA' },
  { postalCode: '77430', city: 'AVESTA' },
  { postalCode: '77431', city: 'AVESTA' },
  { postalCode: '77432', city: 'AVESTA' },
  { postalCode: '77433', city: 'AVESTA' },
  { postalCode: '77434', city: 'AVESTA' },
  { postalCode: '77435', city: 'AVESTA' },
  { postalCode: '77440', city: 'AVESTA' },
  { postalCode: '77441', city: 'AVESTA' },
  { postalCode: '77460', city: 'AVESTA' },
  { postalCode: '77461', city: 'AVESTA' },
  { postalCode: '77462', city: 'AVESTA' },
  { postalCode: '77463', city: 'AVESTA' },
  { postalCode: '77467', city: 'HORNDAL' },
  { postalCode: '77468', city: 'HORNDAL' },
  { postalCode: '77481', city: 'AVESTA' },
  { postalCode: '77482', city: 'AVESTA' },
  { postalCode: '77489', city: 'FORS' },
  { postalCode: '77491', city: 'AVESTA' },
  { postalCode: '77492', city: 'AVESTA' },
  { postalCode: '77493', city: 'AVESTA' },
  { postalCode: '77497', city: 'FORS' },
  { postalCode: '77498', city: 'HORNDAL' },
  { postalCode: '77499', city: 'BY KYRKBY' },
  { postalCode: '77525', city: 'KRYLBO' },
  { postalCode: '77526', city: 'KRYLBO' },
  { postalCode: '77550', city: 'KRYLBO' },
  { postalCode: '77551', city: 'KRYLBO' },
  { postalCode: '77553', city: 'KRYLBO' },
  { postalCode: '77554', city: 'KRYLBO' },
  { postalCode: '77570', city: 'KRYLBO' },
  { postalCode: '77571', city: 'KRYLBO' },
  { postalCode: '77595', city: 'FOLKÄRNA' },
  { postalCode: '77596', city: 'KRYLBO' },
  { postalCode: '77601', city: 'HEDEMORA' },
  { postalCode: '77606', city: 'LÅNGSHYTTAN' },
  { postalCode: '77620', city: 'HEDEMORA' },
  { postalCode: '77621', city: 'HEDEMORA' },
  { postalCode: '77622', city: 'HEDEMORA' },
  { postalCode: '77623', city: 'HEDEMORA' },
  { postalCode: '77628', city: 'HEDEMORA' },
  { postalCode: '77630', city: 'HEDEMORA' },
  { postalCode: '77631', city: 'HEDEMORA' },
  { postalCode: '77632', city: 'HEDEMORA' },
  { postalCode: '77633', city: 'HEDEMORA' },
  { postalCode: '77634', city: 'HEDEMORA' },
  { postalCode: '77635', city: 'HEDEMORA' },
  { postalCode: '77636', city: 'HEDEMORA' },
  { postalCode: '77670', city: 'VIKMANSHYTTAN' },
  { postalCode: '77671', city: 'LÅNGSHYTTAN' },
  { postalCode: '77672', city: 'LÅNGSHYTTAN' },
  { postalCode: '77673', city: 'LÅNGSHYTTAN' },
  { postalCode: '77674', city: 'STJÄRNSUND' },
  { postalCode: '77690', city: 'HEDEMORA' },
  { postalCode: '77691', city: 'HEDEMORA' },
  { postalCode: '77692', city: 'HEDEMORA' },
  { postalCode: '77693', city: 'HEDEMORA' },
  { postalCode: '77695', city: 'VIKMANSHYTTAN' },
  { postalCode: '77696', city: 'DALA-HUSBY' },
  { postalCode: '77697', city: 'DALA-HUSBY' },
  { postalCode: '77698', city: 'GARPENBERG' },
  { postalCode: '77701', city: 'SMEDJEBACKEN' },
  { postalCode: '77720', city: 'SMEDJEBACKEN' },
  { postalCode: '77721', city: 'SMEDJEBACKEN' },
  { postalCode: '77722', city: 'SMEDJEBACKEN' },
  { postalCode: '77723', city: 'SMEDJEBACKEN' },
  { postalCode: '77725', city: 'SMEDJEBACKEN' },
  { postalCode: '77730', city: 'SMEDJEBACKEN' },
  { postalCode: '77731', city: 'SMEDJEBACKEN' },
  { postalCode: '77732', city: 'SMEDJEBACKEN' },
  { postalCode: '77733', city: 'SMEDJEBACKEN' },
  { postalCode: '77734', city: 'SMEDJEBACKEN' },
  { postalCode: '77750', city: 'SMEDJEBACKEN' },
  { postalCode: '77751', city: 'SMEDJEBACKEN' },
  { postalCode: '77760', city: 'SÖDERBÄRKE' },
  { postalCode: '77780', city: 'SMEDJEBACKEN' },
  { postalCode: '77781', city: 'SMEDJEBACKEN' },
  { postalCode: '77782', city: 'SMEDJEBACKEN' },
  { postalCode: '77790', city: 'SMEDJEBACKEN' },
  { postalCode: '77791', city: 'SMEDJEBACKEN' },
  { postalCode: '77793', city: 'SÖDERBÄRKE' },
  { postalCode: '77794', city: 'SÖDERBÄRKE' },
  { postalCode: '78061', city: 'ÖJE' },
  { postalCode: '78064', city: 'LIMA' },
  { postalCode: '78066', city: 'RÖRBÄCKSNÄS' },
  { postalCode: '78067', city: 'SÄLEN' },
  { postalCode: '78068', city: 'TRANSTRAND' },
  { postalCode: '78069', city: 'SÖRSJÖN' },
  { postalCode: '78100', city: 'BORLÄNGE' },
  { postalCode: '78102', city: 'BORLÄNGE' },
  { postalCode: '78105', city: 'BORLÄNGE' },
  { postalCode: '78106', city: 'BORLÄNGE' },
  { postalCode: '78110', city: 'BORLÄNGE' },
  { postalCode: '78120', city: 'BORLÄNGE' },
  { postalCode: '78121', city: 'BORLÄNGE' },
  { postalCode: '78122', city: 'BORLÄNGE' },
  { postalCode: '78123', city: 'BORLÄNGE' },
  { postalCode: '78124', city: 'BORLÄNGE' },
  { postalCode: '78127', city: 'BORLÄNGE' },
  { postalCode: '78128', city: 'BORLÄNGE' },
  { postalCode: '78129', city: 'BORLÄNGE' },
  { postalCode: '78160', city: 'GUSTAFS' },
  { postalCode: '78161', city: 'GUSTAFS' },
  { postalCode: '78162', city: 'GUSTAFS' },
  { postalCode: '78168', city: 'BORLÄNGE' },
  { postalCode: '78169', city: 'BORLÄNGE' },
  { postalCode: '78170', city: 'BORLÄNGE' },
  { postalCode: '78171', city: 'BORLÄNGE' },
  { postalCode: '78172', city: 'BORLÄNGE' },
  { postalCode: '78173', city: 'BORLÄNGE' },
  { postalCode: '78174', city: 'BORLÄNGE' },
  { postalCode: '78181', city: 'BORLÄNGE' },
  { postalCode: '78182', city: 'BORLÄNGE' },
  { postalCode: '78183', city: 'BORLÄNGE' },
  { postalCode: '78184', city: 'BORLÄNGE' },
  { postalCode: '78185', city: 'BORLÄNGE' },
  { postalCode: '78187', city: 'BORLÄNGE' },
  { postalCode: '78188', city: 'BORLÄNGE' },
  { postalCode: '78189', city: 'BORLÄNGE' },
  { postalCode: '78190', city: 'BORLÄNGE' },
  { postalCode: '78191', city: 'BORLÄNGE' },
  { postalCode: '78192', city: 'BORLÄNGE' },
  { postalCode: '78193', city: 'BORLÄNGE' },
  { postalCode: '78194', city: 'BORLÄNGE' },
  { postalCode: '78195', city: 'BORLÄNGE' },
  { postalCode: '78196', city: 'BORLÄNGE' },
  { postalCode: '78197', city: 'BORLÄNGE' },
  { postalCode: '78198', city: 'BORLÄNGE' },
  { postalCode: '78199', city: 'IDKERBERGET' },
  { postalCode: '78201', city: 'MALUNG' },
  { postalCode: '78220', city: 'MALUNG' },
  { postalCode: '78221', city: 'MALUNG' },
  { postalCode: '78222', city: 'MALUNG' },
  { postalCode: '78223', city: 'MALUNG' },
  { postalCode: '78224', city: 'MALUNG' },
  { postalCode: '78230', city: 'MALUNG' },
  { postalCode: '78231', city: 'MALUNG' },
  { postalCode: '78233', city: 'MALUNG' },
  { postalCode: '78234', city: 'MALUNG' },
  { postalCode: '78235', city: 'MALUNG' },
  { postalCode: '78275', city: 'MALUNGSFORS' },
  { postalCode: '78290', city: 'MALUNG' },
  { postalCode: '78291', city: 'MALUNG' },
  { postalCode: '78301', city: 'SÄTER' },
  { postalCode: '78320', city: 'SÄTER' },
  { postalCode: '78321', city: 'SÄTER' },
  { postalCode: '78322', city: 'SÄTER' },
  { postalCode: '78323', city: 'SÄTER' },
  { postalCode: '78327', city: 'SÄTER' },
  { postalCode: '78330', city: 'SÄTER' },
  { postalCode: '78331', city: 'SÄTER' },
  { postalCode: '78332', city: 'SÄTER' },
  { postalCode: '78334', city: 'SÄTER' },
  { postalCode: '78335', city: 'SÄTER' },
  { postalCode: '78390', city: 'SÄTER' },
  { postalCode: '78391', city: 'SÄTER' },
  { postalCode: '78392', city: 'STORA SKEDVI' },
  { postalCode: '78393', city: 'STORA SKEDVI' },
  { postalCode: '78401', city: 'BORLÄNGE' },
  { postalCode: '78430', city: 'BORLÄNGE' },
  { postalCode: '78431', city: 'BORLÄNGE' },
  { postalCode: '78432', city: 'BORLÄNGE' },
  { postalCode: '78433', city: 'BORLÄNGE' },
  { postalCode: '78434', city: 'BORLÄNGE' },
  { postalCode: '78435', city: 'BORLÄNGE' },
  { postalCode: '78436', city: 'BORLÄNGE' },
  { postalCode: '78437', city: 'BORLÄNGE' },
  { postalCode: '78439', city: 'BORLÄNGE' },
  { postalCode: '78440', city: 'BORLÄNGE' },
  { postalCode: '78441', city: 'BORLÄNGE' },
  { postalCode: '78442', city: 'BORLÄNGE' },
  { postalCode: '78443', city: 'BORLÄNGE' },
  { postalCode: '78444', city: 'BORLÄNGE' },
  { postalCode: '78445', city: 'BORLÄNGE' },
  { postalCode: '78450', city: 'BORLÄNGE' },
  { postalCode: '78451', city: 'BORLÄNGE' },
  { postalCode: '78452', city: 'BORLÄNGE' },
  { postalCode: '78453', city: 'BORLÄNGE' },
  { postalCode: '78454', city: 'BORLÄNGE' },
  { postalCode: '78455', city: 'BORLÄNGE' },
  { postalCode: '78456', city: 'BORLÄNGE' },
  { postalCode: '78460', city: 'BORLÄNGE' },
  { postalCode: '78461', city: 'BORLÄNGE' },
  { postalCode: '78462', city: 'BORLÄNGE' },
  { postalCode: '78463', city: 'BORLÄNGE' },
  { postalCode: '78465', city: 'BORLÄNGE' },
  { postalCode: '78466', city: 'BORLÄNGE' },
  { postalCode: '78467', city: 'BORLÄNGE' },
  { postalCode: '78468', city: 'BORLÄNGE' },
  { postalCode: '78472', city: 'BORLÄNGE' },
  { postalCode: '78473', city: 'BORLÄNGE' },
  { postalCode: '78474', city: 'BORLÄNGE' },
  { postalCode: '78475', city: 'BORLÄNGE' },
  { postalCode: '78476', city: 'BORLÄNGE' },
  { postalCode: '78477', city: 'BORLÄNGE' },
  { postalCode: '78478', city: 'BORLÄNGE' },
  { postalCode: '78500', city: 'GAGNEF' },
  { postalCode: '78504', city: 'MOCKFJÄRD' },
  { postalCode: '78520', city: 'GAGNEF' },
  { postalCode: '78521', city: 'GAGNEF' },
  { postalCode: '78530', city: 'GAGNEF' },
  { postalCode: '78542', city: 'MOCKFJÄRD' },
  { postalCode: '78543', city: 'MOCKFJÄRD' },
  { postalCode: '78560', city: 'DJURÅS' },
  { postalCode: '78561', city: 'DJURÅS' },
  { postalCode: '78562', city: 'DJURMO' },
  { postalCode: '78563', city: 'SIFFERBO' },
  { postalCode: '78580', city: 'GAGNEF' },
  { postalCode: '78591', city: 'GAGNEF' },
  { postalCode: '78592', city: 'GAGNEF' },
  { postalCode: '78600', city: 'VANSBRO' },
  { postalCode: '78602', city: 'DALA-JÄRNA' },
  { postalCode: '78620', city: 'VANSBRO' },
  { postalCode: '78621', city: 'VANSBRO' },
  { postalCode: '78631', city: 'VANSBRO' },
  { postalCode: '78632', city: 'VANSBRO' },
  { postalCode: '78633', city: 'VANSBRO' },
  { postalCode: '78671', city: 'DALA-JÄRNA' },
  { postalCode: '78672', city: 'DALA-JÄRNA' },
  { postalCode: '78691', city: 'VANSBRO' },
  { postalCode: '78692', city: 'DALA-JÄRNA' },
  { postalCode: '78693', city: 'NÅS' },
  { postalCode: '78694', city: 'ÄPPELBO' },
  { postalCode: '78695', city: 'DALA-FLODA' },
  { postalCode: '78697', city: 'BJÖRBO' },
  { postalCode: '79015', city: 'SUNDBORN' },
  { postalCode: '79020', city: 'GRYCKSBO' },
  { postalCode: '79021', city: 'BJURSÅS' },
  { postalCode: '79022', city: 'SÅGMYRA' },
  { postalCode: '79023', city: 'SVÄRDSJÖ' },
  { postalCode: '79024', city: 'TOFTBYN' },
  { postalCode: '79025', city: 'LINGHED' },
  { postalCode: '79026', city: 'ENVIKEN' },
  { postalCode: '79070', city: 'FURUDAL' },
  { postalCode: '79090', city: 'SÄRNA' },
  { postalCode: '79091', city: 'IDRE' },
  { postalCode: '79101', city: 'FALUN' },
  { postalCode: '79105', city: 'FALUN' },
  { postalCode: '79106', city: 'FALUN' },
  { postalCode: '79107', city: 'FALUN' },
  { postalCode: '79110', city: 'FALUN' },
  { postalCode: '79111', city: 'FALUN' },
  { postalCode: '79112', city: 'FALUN' },
  { postalCode: '79113', city: 'FALUN' },
  { postalCode: '79114', city: 'FALUN' },
  { postalCode: '79116', city: 'FALUN' },
  { postalCode: '79117', city: 'FALUN' },
  { postalCode: '79119', city: 'FALUN' },
  { postalCode: '79120', city: 'FALUN' },
  { postalCode: '79121', city: 'FALUN' },
  { postalCode: '79122', city: 'FALUN' },
  { postalCode: '79123', city: 'FALUN' },
  { postalCode: '79124', city: 'FALUN' },
  { postalCode: '79125', city: 'FALUN' },
  { postalCode: '79126', city: 'FALUN' },
  { postalCode: '79127', city: 'FALUN' },
  { postalCode: '79128', city: 'FALUN' },
  { postalCode: '79129', city: 'FALUN' },
  { postalCode: '79130', city: 'FALUN' },
  { postalCode: '79131', city: 'FALUN' },
  { postalCode: '79132', city: 'FALUN' },
  { postalCode: '79133', city: 'FALUN' },
  { postalCode: '79134', city: 'FALUN' },
  { postalCode: '79135', city: 'FALUN' },
  { postalCode: '79136', city: 'FALUN' },
  { postalCode: '79137', city: 'FALUN' },
  { postalCode: '79138', city: 'FALUN' },
  { postalCode: '79140', city: 'FALUN' },
  { postalCode: '79141', city: 'FALUN' },
  { postalCode: '79142', city: 'FALUN' },
  { postalCode: '79143', city: 'FALUN' },
  { postalCode: '79144', city: 'FALUN' },
  { postalCode: '79145', city: 'FALUN' },
  { postalCode: '79146', city: 'FALUN' },
  { postalCode: '79147', city: 'FALUN' },
  { postalCode: '79150', city: 'FALUN' },
  { postalCode: '79151', city: 'FALUN' },
  { postalCode: '79152', city: 'FALUN' },
  { postalCode: '79153', city: 'FALUN' },
  { postalCode: '79155', city: 'FALUN' },
  { postalCode: '79156', city: 'FALUN' },
  { postalCode: '79160', city: 'FALUN' },
  { postalCode: '79161', city: 'FALUN' },
  { postalCode: '79162', city: 'FALUN' },
  { postalCode: '79170', city: 'FALUN' },
  { postalCode: '79171', city: 'FALUN' },
  { postalCode: '79172', city: 'FALUN' },
  { postalCode: '79173', city: 'FALUN' },
  { postalCode: '79174', city: 'FALUN' },
  { postalCode: '79175', city: 'FALUN' },
  { postalCode: '79176', city: 'FALUN' },
  { postalCode: '79177', city: 'FALUN' },
  { postalCode: '79179', city: 'FALUN' },
  { postalCode: '79180', city: 'FALUN' },
  { postalCode: '79182', city: 'FALUN' },
  { postalCode: '79183', city: 'FALUN' },
  { postalCode: '79184', city: 'FALUN' },
  { postalCode: '79185', city: 'FALUN' },
  { postalCode: '79186', city: 'FALUN' },
  { postalCode: '79187', city: 'FALUN' },
  { postalCode: '79188', city: 'FALUN' },
  { postalCode: '79189', city: 'FALUN' },
  { postalCode: '79191', city: 'FALUN' },
  { postalCode: '79192', city: 'FALUN' },
  { postalCode: '79193', city: 'FALUN' },
  { postalCode: '79194', city: 'FALUN' },
  { postalCode: '79195', city: 'FALUN' },
  { postalCode: '79196', city: 'FALUN' },
  { postalCode: '79201', city: 'MORA' },
  { postalCode: '79202', city: 'MORA' },
  { postalCode: '79203', city: 'MORA' },
  { postalCode: '79205', city: 'SOLLERÖN' },
  { postalCode: '79220', city: 'MORA' },
  { postalCode: '79221', city: 'MORA' },
  { postalCode: '79222', city: 'MORA' },
  { postalCode: '79223', city: 'MORA' },
  { postalCode: '79224', city: 'MORA' },
  { postalCode: '79225', city: 'MORA' },
  { postalCode: '79227', city: 'MORA' },
  { postalCode: '79230', city: 'MORA' },
  { postalCode: '79231', city: 'MORA' },
  { postalCode: '79232', city: 'MORA' },
  { postalCode: '79233', city: 'MORA' },
  { postalCode: '79234', city: 'MORA' },
  { postalCode: '79235', city: 'MORA' },
  { postalCode: '79236', city: 'MORA' },
  { postalCode: '79237', city: 'MORA' },
  { postalCode: '79250', city: 'MORA' },
  { postalCode: '79251', city: 'MORA' },
  { postalCode: '79252', city: 'MORA' },
  { postalCode: '79275', city: 'FÄRNÄS' },
  { postalCode: '79277', city: 'NUSNÄS' },
  { postalCode: '79280', city: 'MORA' },
  { postalCode: '79285', city: 'MORA' },
  { postalCode: '79290', city: 'SOLLERÖN' },
  { postalCode: '79291', city: 'MORA' },
  { postalCode: '79292', city: 'MORA' },
  { postalCode: '79293', city: 'VENJAN' },
  { postalCode: '79294', city: 'MORA' },
  { postalCode: '79295', city: 'MORA' },
  { postalCode: '79296', city: 'VÅMHUS' },
  { postalCode: '79297', city: 'MORA' },
  { postalCode: '79298', city: 'GARSÅS' },
  { postalCode: '79301', city: 'LEKSAND' },
  { postalCode: '79311', city: 'INSJÖN' },
  { postalCode: '79312', city: 'INSJÖN' },
  { postalCode: '79313', city: 'INSJÖN' },
  { postalCode: '79320', city: 'LEKSAND' },
  { postalCode: '79321', city: 'LEKSAND' },
  { postalCode: '79322', city: 'LEKSAND' },
  { postalCode: '79323', city: 'LEKSAND' },
  { postalCode: '79324', city: 'LEKSAND' },
  { postalCode: '79325', city: 'LEKSAND' },
  { postalCode: '79326', city: 'LEKSAND' },
  { postalCode: '79327', city: 'LEKSAND' },
  { postalCode: '79330', city: 'LEKSAND' },
  { postalCode: '79331', city: 'LEKSAND' },
  { postalCode: '79332', city: 'LEKSAND' },
  { postalCode: '79333', city: 'LEKSAND' },
  { postalCode: '79335', city: 'LEKSAND' },
  { postalCode: '79336', city: 'LEKSAND' },
  { postalCode: '79340', city: 'INSJÖN' },
  { postalCode: '79341', city: 'INSJÖN' },
  { postalCode: '79350', city: 'LEKSAND' },
  { postalCode: '79360', city: 'SILJANSNÄS' },
  { postalCode: '79370', city: 'TÄLLBERG' },
  { postalCode: '79380', city: 'LEKSAND' },
  { postalCode: '79385', city: 'INSJÖN' },
  { postalCode: '79386', city: 'INSJÖN' },
  { postalCode: '79390', city: 'LEKSAND' },
  { postalCode: '79391', city: 'LEKSAND' },
  { postalCode: '79392', city: 'LEKSAND' },
  { postalCode: '79393', city: 'DJURA' },
  { postalCode: '79394', city: 'TÄLLBERG' },
  { postalCode: '79397', city: 'SILJANSNÄS' },
  { postalCode: '79420', city: 'ORSA' },
  { postalCode: '79421', city: 'ORSA' },
  { postalCode: '79422', city: 'ORSA' },
  { postalCode: '79430', city: 'ORSA' },
  { postalCode: '79431', city: 'ORSA' },
  { postalCode: '79432', city: 'ORSA' },
  { postalCode: '79433', city: 'ORSA' },
  { postalCode: '79434', city: 'ORSA' },
  { postalCode: '79490', city: 'ORSA' },
  { postalCode: '79491', city: 'ORSA' },
  { postalCode: '79492', city: 'ORSA' },
  { postalCode: '79493', city: 'ORSA' },
  { postalCode: '79495', city: 'SKATTUNGBYN' },
  { postalCode: '79498', city: 'ORSA' },
  { postalCode: '79501', city: 'RÄTTVIK' },
  { postalCode: '79503', city: 'RÄTTVIK' },
  { postalCode: '79520', city: 'RÄTTVIK' },
  { postalCode: '79521', city: 'RÄTTVIK' },
  { postalCode: '79522', city: 'RÄTTVIK' },
  { postalCode: '79523', city: 'RÄTTVIK' },
  { postalCode: '79530', city: 'RÄTTVIK' },
  { postalCode: '79531', city: 'RÄTTVIK' },
  { postalCode: '79532', city: 'RÄTTVIK' },
  { postalCode: '79533', city: 'RÄTTVIK' },
  { postalCode: '79535', city: 'RÄTTVIK' },
  { postalCode: '79536', city: 'RÄTTVIK' },
  { postalCode: '79570', city: 'VIKARBYN' },
  { postalCode: '79580', city: 'RÄTTVIK' },
  { postalCode: '79590', city: 'RÄTTVIK' },
  { postalCode: '79591', city: 'RÄTTVIK' },
  { postalCode: '79592', city: 'RÄTTVIK' },
  { postalCode: '79595', city: 'VIKARBYN' },
  { postalCode: '79596', city: 'BODA KYRKBY' },
  { postalCode: '79601', city: 'ÄLVDALEN' },
  { postalCode: '79620', city: 'ÄLVDALEN' },
  { postalCode: '79621', city: 'ÄLVDALEN' },
  { postalCode: '79622', city: 'ÄLVDALEN' },
  { postalCode: '79626', city: 'ÄLVDALEN' },
  { postalCode: '79630', city: 'ÄLVDALEN' },
  { postalCode: '79631', city: 'ÄLVDALEN' },
  { postalCode: '79690', city: 'ÄLVDALEN' },
  { postalCode: '79691', city: 'ÄLVDALEN' },
  { postalCode: '79699', city: 'ÄLVDALEN' },
  { postalCode: '80002', city: 'GÄVLE' },
  { postalCode: '80004', city: 'GÄVLE' },
  { postalCode: '80006', city: 'GÄVLE' },
  { postalCode: '80008', city: 'GÄVLE' },
  { postalCode: '80010', city: 'GÄVLE' },
  { postalCode: '80011', city: 'GÄVLE' },
  { postalCode: '80015', city: 'GÄVLE' },
  { postalCode: '80100', city: 'GÄVLE' },
  { postalCode: '80102', city: 'GÄVLE' },
  { postalCode: '80103', city: 'GÄVLE' },
  { postalCode: '80104', city: 'GÄVLE' },
  { postalCode: '80105', city: 'GÄVLE' },
  { postalCode: '80106', city: 'GÄVLE' },
  { postalCode: '80107', city: 'GÄVLE' },
  { postalCode: '80108', city: 'GÄVLE' },
  { postalCode: '80126', city: 'GÄVLE' },
  { postalCode: '80127', city: 'GÄVLE' },
  { postalCode: '80128', city: 'GÄVLE' },
  { postalCode: '80129', city: 'GÄVLE' },
  { postalCode: '80130', city: 'GÄVLE' },
  { postalCode: '80131', city: 'GÄVLE' },
  { postalCode: '80132', city: 'GÄVLE' },
  { postalCode: '80133', city: 'GÄVLE' },
  { postalCode: '80134', city: 'GÄVLE' },
  { postalCode: '80135', city: 'GÄVLE' },
  { postalCode: '80136', city: 'GÄVLE' },
  { postalCode: '80137', city: 'GÄVLE' },
  { postalCode: '80138', city: 'GÄVLE' },
  { postalCode: '80139', city: 'GÄVLE' },
  { postalCode: '80170', city: 'GÄVLE' },
  { postalCode: '80171', city: 'GÄVLE' },
  { postalCode: '80172', city: 'GÄVLE' },
  { postalCode: '80173', city: 'GÄVLE' },
  { postalCode: '80174', city: 'GÄVLE' },
  { postalCode: '80176', city: 'GÄVLE' },
  { postalCode: '80181', city: 'GÄVLE' },
  { postalCode: '80182', city: 'GÄVLE' },
  { postalCode: '80183', city: 'GÄVLE' },
  { postalCode: '80184', city: 'GÄVLE' },
  { postalCode: '80185', city: 'GÄVLE' },
  { postalCode: '80186', city: 'GÄVLE' },
  { postalCode: '80187', city: 'GÄVLE' },
  { postalCode: '80188', city: 'GÄVLE' },
  { postalCode: '80200', city: 'GÄVLE' },
  { postalCode: '80208', city: 'GÄVLE' },
  { postalCode: '80210', city: 'GÄVLE' },
  { postalCode: '80250', city: 'GÄVLE' },
  { postalCode: '80251', city: 'GÄVLE' },
  { postalCode: '80252', city: 'GÄVLE' },
  { postalCode: '80253', city: 'GÄVLE' },
  { postalCode: '80254', city: 'GÄVLE' },
  { postalCode: '80255', city: 'GÄVLE' },
  { postalCode: '80256', city: 'GÄVLE' },
  { postalCode: '80257', city: 'GÄVLE' },
  { postalCode: '80258', city: 'GÄVLE' },
  { postalCode: '80260', city: 'GÄVLE' },
  { postalCode: '80261', city: 'GÄVLE' },
  { postalCode: '80262', city: 'GÄVLE' },
  { postalCode: '80263', city: 'GÄVLE' },
  { postalCode: '80264', city: 'GÄVLE' },
  { postalCode: '80265', city: 'GÄVLE' },
  { postalCode: '80266', city: 'GÄVLE' },
  { postalCode: '80267', city: 'GÄVLE' },
  { postalCode: '80269', city: 'GÄVLE' },
  { postalCode: '80270', city: 'GÄVLE' },
  { postalCode: '80271', city: 'GÄVLE' },
  { postalCode: '80272', city: 'GÄVLE' },
  { postalCode: '80274', city: 'GÄVLE' },
  { postalCode: '80275', city: 'GÄVLE' },
  { postalCode: '80276', city: 'GÄVLE' },
  { postalCode: '80277', city: 'GÄVLE' },
  { postalCode: '80280', city: 'GÄVLE' },
  { postalCode: '80281', city: 'GÄVLE' },
  { postalCode: '80282', city: 'GÄVLE' },
  { postalCode: '80283', city: 'GÄVLE' },
  { postalCode: '80284', city: 'GÄVLE' },
  { postalCode: '80285', city: 'GÄVLE' },
  { postalCode: '80286', city: 'GÄVLE' },
  { postalCode: '80287', city: 'GÄVLE' },
  { postalCode: '80288', city: 'GÄVLE' },
  { postalCode: '80291', city: 'GÄVLE' },
  { postalCode: '80292', city: 'GÄVLE' },
  { postalCode: '80293', city: 'GÄVLE' },
  { postalCode: '80301', city: 'GÄVLE' },
  { postalCode: '80302', city: 'GÄVLE' },
  { postalCode: '80309', city: 'GÄVLE' },
  { postalCode: '80310', city: 'GÄVLE' },
  { postalCode: '80311', city: 'GÄVLE' },
  { postalCode: '80320', city: 'GÄVLE' },
  { postalCode: '80321', city: 'GÄVLE' },
  { postalCode: '80322', city: 'GÄVLE' },
  { postalCode: '80323', city: 'GÄVLE' },
  { postalCode: '80324', city: 'GÄVLE' },
  { postalCode: '80325', city: 'GÄVLE' },
  { postalCode: '80400', city: 'GÄVLE' },
  { postalCode: '80423', city: 'GÄVLE' },
  { postalCode: '80424', city: 'GÄVLE' },
  { postalCode: '80425', city: 'GÄVLE' },
  { postalCode: '80426', city: 'GÄVLE' },
  { postalCode: '80427', city: 'GÄVLE' },
  { postalCode: '80428', city: 'GÄVLE' },
  { postalCode: '80429', city: 'GÄVLE' },
  { postalCode: '80430', city: 'GÄVLE' },
  { postalCode: '80431', city: 'GÄVLE' },
  { postalCode: '80432', city: 'GÄVLE' },
  { postalCode: '80433', city: 'GÄVLE' },
  { postalCode: '80434', city: 'GÄVLE' },
  { postalCode: '80501', city: 'GÄVLE' },
  { postalCode: '80591', city: 'GÄVLE' },
  { postalCode: '80592', city: 'GÄVLE' },
  { postalCode: '80595', city: 'GÄVLE' },
  { postalCode: '80596', city: 'GÄVLE' },
  { postalCode: '80597', city: 'GÄVLE' },
  { postalCode: '80598', city: 'GÄVLE' },
  { postalCode: '80626', city: 'GÄVLE' },
  { postalCode: '80627', city: 'GÄVLE' },
  { postalCode: '80628', city: 'GÄVLE' },
  { postalCode: '80630', city: 'GÄVLE' },
  { postalCode: '80631', city: 'GÄVLE' },
  { postalCode: '80632', city: 'GÄVLE' },
  { postalCode: '80633', city: 'GÄVLE' },
  { postalCode: '80634', city: 'GÄVLE' },
  { postalCode: '80635', city: 'GÄVLE' },
  { postalCode: '80636', city: 'GÄVLE' },
  { postalCode: '80637', city: 'GÄVLE' },
  { postalCode: '80638', city: 'GÄVLE' },
  { postalCode: '80641', city: 'GÄVLE' },
  { postalCode: '80642', city: 'GÄVLE' },
  { postalCode: '80643', city: 'GÄVLE' },
  { postalCode: '80645', city: 'GÄVLE' },
  { postalCode: '80646', city: 'GÄVLE' },
  { postalCode: '80647', city: 'GÄVLE' },
  { postalCode: '80648', city: 'GÄVLE' },
  { postalCode: '80649', city: 'GÄVLE' },
  { postalCode: '80800', city: 'GÄVLE' },
  { postalCode: '80886', city: 'GÄVLE' },
  { postalCode: '81100', city: 'SANDVIKEN' },
  { postalCode: '81102', city: 'JÄRBO' },
  { postalCode: '81103', city: 'SANDVIKEN' },
  { postalCode: '81104', city: 'SANDVIKEN' },
  { postalCode: '81120', city: 'SANDVIKEN' },
  { postalCode: '81121', city: 'SANDVIKEN' },
  { postalCode: '81122', city: 'SANDVIKEN' },
  { postalCode: '81123', city: 'SANDVIKEN' },
  { postalCode: '81125', city: 'SANDVIKEN' },
  { postalCode: '81126', city: 'SANDVIKEN' },
  { postalCode: '81130', city: 'SANDVIKEN' },
  { postalCode: '81131', city: 'SANDVIKEN' },
  { postalCode: '81132', city: 'SANDVIKEN' },
  { postalCode: '81133', city: 'SANDVIKEN' },
  { postalCode: '81134', city: 'SANDVIKEN' },
  { postalCode: '81135', city: 'SANDVIKEN' },
  { postalCode: '81136', city: 'SANDVIKEN' },
  { postalCode: '81137', city: 'SANDVIKEN' },
  { postalCode: '81138', city: 'SANDVIKEN' },
  { postalCode: '81139', city: 'SANDVIKEN' },
  { postalCode: '81140', city: 'SANDVIKEN' },
  { postalCode: '81141', city: 'SANDVIKEN' },
  { postalCode: '81150', city: 'SANDVIKEN' },
  { postalCode: '81151', city: 'SANDVIKEN' },
  { postalCode: '81152', city: 'SANDVIKEN' },
  { postalCode: '81153', city: 'SANDVIKEN' },
  { postalCode: '81154', city: 'SANDVIKEN' },
  { postalCode: '81160', city: 'SANDVIKEN' },
  { postalCode: '81161', city: 'SANDVIKEN' },
  { postalCode: '81162', city: 'SANDVIKEN' },
  { postalCode: '81170', city: 'JÄRBO' },
  { postalCode: '81171', city: 'JÄRBO' },
  { postalCode: '81173', city: 'ÅRSUNDA' },
  { postalCode: '81174', city: 'ÖSTERFÄRNEBO' },
  { postalCode: '81180', city: 'SANDVIKEN' },
  { postalCode: '81181', city: 'SANDVIKEN' },
  { postalCode: '81185', city: 'SANDVIKEN' },
  { postalCode: '81188', city: 'SANDVIKEN' },
  { postalCode: '81189', city: 'SANDVIKEN' },
  { postalCode: '81191', city: 'SANDVIKEN' },
  { postalCode: '81192', city: 'SANDVIKEN' },
  { postalCode: '81193', city: 'SANDVIKEN' },
  { postalCode: '81194', city: 'SANDVIKEN' },
  { postalCode: '81195', city: 'JÄRBO' },
  { postalCode: '81196', city: 'ÅRSUNDA' },
  { postalCode: '81197', city: 'GYSINGE' },
  { postalCode: '81198', city: 'ÖSTERFÄRNEBO' },
  { postalCode: '81199', city: 'ÅRSUNDA' },
  { postalCode: '81220', city: 'STORVIK' },
  { postalCode: '81221', city: 'STORVIK' },
  { postalCode: '81222', city: 'STORVIK' },
  { postalCode: '81225', city: 'STORVIK' },
  { postalCode: '81230', city: 'STORVIK' },
  { postalCode: '81231', city: 'STORVIK' },
  { postalCode: '81240', city: 'KUNGSGÅRDEN' },
  { postalCode: '81241', city: 'GÄSTRIKE-HAMMARBY' },
  { postalCode: '81251', city: 'ÅSHAMMAR' },
  { postalCode: '81290', city: 'STORVIK' },
  { postalCode: '81292', city: 'GÄSTRIKE-HAMMARBY' },
  { postalCode: '81293', city: 'KUNGSGÅRDEN' },
  { postalCode: '81294', city: 'ÅSHAMMAR' },
  { postalCode: '81302', city: 'TORSÅKER' },
  { postalCode: '81320', city: 'HOFORS' },
  { postalCode: '81321', city: 'HOFORS' },
  { postalCode: '81322', city: 'HOFORS' },
  { postalCode: '81323', city: 'HOFORS' },
  { postalCode: '81330', city: 'HOFORS' },
  { postalCode: '81331', city: 'HOFORS' },
  { postalCode: '81332', city: 'HOFORS' },
  { postalCode: '81333', city: 'HOFORS' },
  { postalCode: '81334', city: 'HOFORS' },
  { postalCode: '81335', city: 'HOFORS' },
  { postalCode: '81336', city: 'HOFORS' },
  { postalCode: '81340', city: 'TORSÅKER' },
  { postalCode: '81381', city: 'HOFORS' },
  { postalCode: '81382', city: 'HOFORS' },
  { postalCode: '81391', city: 'HOFORS' },
  { postalCode: '81394', city: 'TORSÅKER' },
  { postalCode: '81395', city: 'TORSÅKER' },
  { postalCode: '81396', city: 'TORSÅKER' },
  { postalCode: '81401', city: 'SKUTSKÄR' },
  { postalCode: '81420', city: 'SKUTSKÄR' },
  { postalCode: '81421', city: 'SKUTSKÄR' },
  { postalCode: '81422', city: 'SKUTSKÄR' },
  { postalCode: '81423', city: 'SKUTSKÄR' },
  { postalCode: '81425', city: 'ÄLVKARLEBY' },
  { postalCode: '81430', city: 'SKUTSKÄR' },
  { postalCode: '81431', city: 'SKUTSKÄR' },
  { postalCode: '81432', city: 'SKUTSKÄR' },
  { postalCode: '81440', city: 'SKUTSKÄR' },
  { postalCode: '81441', city: 'SKUTSKÄR' },
  { postalCode: '81442', city: 'SKUTSKÄR' },
  { postalCode: '81470', city: 'ÄLVKARLEBY' },
  { postalCode: '81481', city: 'SKUTSKÄR' },
  { postalCode: '81491', city: 'FURUVIK' },
  { postalCode: '81492', city: 'SKUTSKÄR' },
  { postalCode: '81493', city: 'SKUTSKÄR' },
  { postalCode: '81494', city: 'ÄLVKARLEBY' },
  { postalCode: '81495', city: 'ÄLVKARLEBY' },
  { postalCode: '81500', city: 'TIERP' },
  { postalCode: '81503', city: 'MÅNKARBO' },
  { postalCode: '81504', city: 'SÖDERFORS' },
  { postalCode: '81520', city: 'TIERP' },
  { postalCode: '81521', city: 'TIERP' },
  { postalCode: '81522', city: 'TIERP' },
  { postalCode: '81523', city: 'TIERP' },
  { postalCode: '81535', city: 'TIERP' },
  { postalCode: '81536', city: 'TIERP' },
  { postalCode: '81537', city: 'TIERP' },
  { postalCode: '81538', city: 'TIERP' },
  { postalCode: '81540', city: 'TIERP' },
  { postalCode: '81541', city: 'TIERP' },
  { postalCode: '81544', city: 'TIERP' },
  { postalCode: '81545', city: 'TIERP' },
  { postalCode: '81569', city: 'MÅNKARBO' },
  { postalCode: '81575', city: 'SÖDERFORS' },
  { postalCode: '81576', city: 'SÖDERFORS' },
  { postalCode: '81580', city: 'TIERP' },
  { postalCode: '81582', city: 'SÖDERFORS' },
  { postalCode: '81583', city: 'TIERP' },
  { postalCode: '81591', city: 'TIERP' },
  { postalCode: '81592', city: 'TIERP' },
  { postalCode: '81593', city: 'TIERP' },
  { postalCode: '81594', city: 'TIERP' },
  { postalCode: '81595', city: 'MÅNKARBO' },
  { postalCode: '81596', city: 'STRÖMSBERGS BRUK' },
  { postalCode: '81601', city: 'OCKELBO' },
  { postalCode: '81620', city: 'OCKELBO' },
  { postalCode: '81621', city: 'OCKELBO' },
  { postalCode: '81622', city: 'OCKELBO' },
  { postalCode: '81623', city: 'OCKELBO' },
  { postalCode: '81630', city: 'OCKELBO' },
  { postalCode: '81631', city: 'OCKELBO' },
  { postalCode: '81680', city: 'OCKELBO' },
  { postalCode: '81690', city: 'OCKELBO' },
  { postalCode: '81691', city: 'JÄDRAÅS' },
  { postalCode: '81692', city: 'LINGBO' },
  { postalCode: '81693', city: 'OCKELBO' },
  { postalCode: '81694', city: 'OCKELBO' },
  { postalCode: '81695', city: 'ÅMOTSBRUK' },
  { postalCode: '81702', city: 'BERGBY' },
  { postalCode: '81721', city: 'NORRSUNDET' },
  { postalCode: '81730', city: 'NORRSUNDET' },
  { postalCode: '81740', city: 'BERGBY' },
  { postalCode: '81791', city: 'HAMRÅNGEFJÄRDEN' },
  { postalCode: '81792', city: 'BERGBY' },
  { postalCode: '81793', city: 'HAMRÅNGEFJÄRDEN' },
  { postalCode: '81794', city: 'AXMAR' },
  { postalCode: '81801', city: 'VALBO' },
  { postalCode: '81802', city: 'VALBO' },
  { postalCode: '81803', city: 'FORSBACKA' },
  { postalCode: '81804', city: 'HEDESUNDA' },
  { postalCode: '81820', city: 'VALBO' },
  { postalCode: '81821', city: 'VALBO' },
  { postalCode: '81830', city: 'VALBO' },
  { postalCode: '81831', city: 'VALBO' },
  { postalCode: '81832', city: 'VALBO' },
  { postalCode: '81833', city: 'VALBO' },
  { postalCode: '81834', city: 'VALBO' },
  { postalCode: '81835', city: 'VALBO' },
  { postalCode: '81841', city: 'FORSBACKA' },
  { postalCode: '81842', city: 'FORSBACKA' },
  { postalCode: '81843', city: 'FORSBACKA' },
  { postalCode: '81845', city: 'HEDESUNDA' },
  { postalCode: '81891', city: 'VALBO' },
  { postalCode: '81892', city: 'VALBO' },
  { postalCode: '81895', city: 'HEDESUNDA' },
  { postalCode: '81896', city: 'HEDESUNDA' },
  { postalCode: '81900', city: 'SKÄRPLINGE' },
  { postalCode: '81911', city: 'KARLHOLMSBRUK' },
  { postalCode: '81930', city: 'SKÄRPLINGE' },
  { postalCode: '81940', city: 'KARLHOLMSBRUK' },
  { postalCode: '81961', city: 'SKÄRPLINGE' },
  { postalCode: '81962', city: 'KARLHOLMSBRUK' },
  { postalCode: '81963', city: 'HÅLLNÄS' },
  { postalCode: '81964', city: 'HÅLLNÄS' },
  { postalCode: '81965', city: 'HÅLLNÄS' },
  { postalCode: '81966', city: 'LÖVSTABRUK' },
  { postalCode: '82100', city: 'BOLLNÄS' },
  { postalCode: '82110', city: 'BOLLNÄS' },
  { postalCode: '82111', city: 'BOLLNÄS' },
  { postalCode: '82112', city: 'BOLLNÄS' },
  { postalCode: '82113', city: 'BOLLNÄS' },
  { postalCode: '82120', city: 'BOLLNÄS' },
  { postalCode: '82121', city: 'BOLLNÄS' },
  { postalCode: '82122', city: 'BOLLNÄS' },
  { postalCode: '82123', city: 'BOLLNÄS' },
  { postalCode: '82124', city: 'BOLLNÄS' },
  { postalCode: '82125', city: 'BOLLNÄS' },
  { postalCode: '82126', city: 'BOLLNÄS' },
  { postalCode: '82127', city: 'ARBRÅ' },
  { postalCode: '82130', city: 'BOLLNÄS' },
  { postalCode: '82131', city: 'BOLLNÄS' },
  { postalCode: '82132', city: 'BOLLNÄS' },
  { postalCode: '82133', city: 'BOLLNÄS' },
  { postalCode: '82134', city: 'BOLLNÄS' },
  { postalCode: '82135', city: 'BOLLNÄS' },
  { postalCode: '82136', city: 'BOLLNÄS' },
  { postalCode: '82140', city: 'BOLLNÄS' },
  { postalCode: '82141', city: 'BOLLNÄS' },
  { postalCode: '82142', city: 'BOLLNÄS' },
  { postalCode: '82143', city: 'BOLLNÄS' },
  { postalCode: '82150', city: 'BOLLNÄS' },
  { postalCode: '82151', city: 'BOLLNÄS' },
  { postalCode: '82158', city: 'RENGSJÖ' },
  { postalCode: '82160', city: 'ARBRÅ' },
  { postalCode: '82161', city: 'ARBRÅ' },
  { postalCode: '82162', city: 'ARBRÅ' },
  { postalCode: '82163', city: 'ARBRÅ' },
  { postalCode: '82167', city: 'VALLSTA' },
  { postalCode: '82168', city: 'VALLSTA' },
  { postalCode: '82180', city: 'BOLLNÄS' },
  { postalCode: '82181', city: 'BOLLNÄS' },
  { postalCode: '82189', city: 'ARBRÅ' },
  { postalCode: '82191', city: 'BOLLNÄS' },
  { postalCode: '82192', city: 'BOLLNÄS' },
  { postalCode: '82193', city: 'BOLLNÄS' },
  { postalCode: '82194', city: 'BOLLNÄS' },
  { postalCode: '82195', city: 'BOLLNÄS' },
  { postalCode: '82198', city: 'RENGSJÖ' },
  { postalCode: '82220', city: 'ALFTA' },
  { postalCode: '82221', city: 'ALFTA' },
  { postalCode: '82222', city: 'ALFTA' },
  { postalCode: '82230', city: 'ALFTA' },
  { postalCode: '82231', city: 'ALFTA' },
  { postalCode: '82240', city: 'ALFTA' },
  { postalCode: '82281', city: 'ALFTA' },
  { postalCode: '82291', city: 'ALFTA' },
  { postalCode: '82292', city: 'ALFTA' },
  { postalCode: '82299', city: 'ALFTA' },
  { postalCode: '82320', city: 'KILAFORS' },
  { postalCode: '82321', city: 'KILAFORS' },
  { postalCode: '82322', city: 'KILAFORS' },
  { postalCode: '82330', city: 'KILAFORS' },
  { postalCode: '82380', city: 'KILAFORS' },
  { postalCode: '82391', city: 'KILAFORS' },
  { postalCode: '82392', city: 'HOLMSVEDEN' },
  { postalCode: '82393', city: 'SEGERSTA' },
  { postalCode: '82400', city: 'HUDIKSVALL' },
  { postalCode: '82402', city: 'HUDIKSVALL' },
  { postalCode: '82408', city: 'DELSBO' },
  { postalCode: '82409', city: 'NÄSVIKEN' },
  { postalCode: '82411', city: 'HUDIKSVALL' },
  { postalCode: '82412', city: 'HUDIKSVALL' },
  { postalCode: '82413', city: 'HUDIKSVALL' },
  { postalCode: '82414', city: 'HUDIKSVALL' },
  { postalCode: '82415', city: 'HUDIKSVALL' },
  { postalCode: '82420', city: 'HUDIKSVALL' },
  { postalCode: '82421', city: 'HUDIKSVALL' },
  { postalCode: '82422', city: 'HUDIKSVALL' },
  { postalCode: '82423', city: 'HUDIKSVALL' },
  { postalCode: '82424', city: 'HUDIKSVALL' },
  { postalCode: '82425', city: 'HUDIKSVALL' },
  { postalCode: '82426', city: 'HUDIKSVALL' },
  { postalCode: '82427', city: 'HUDIKSVALL' },
  { postalCode: '82428', city: 'HUDIKSVALL' },
  { postalCode: '82430', city: 'HUDIKSVALL' },
  { postalCode: '82431', city: 'HUDIKSVALL' },
  { postalCode: '82432', city: 'HUDIKSVALL' },
  { postalCode: '82433', city: 'HUDIKSVALL' },
  { postalCode: '82434', city: 'HUDIKSVALL' },
  { postalCode: '82435', city: 'HUDIKSVALL' },
  { postalCode: '82439', city: 'HUDIKSVALL' },
  { postalCode: '82440', city: 'HUDIKSVALL' },
  { postalCode: '82441', city: 'HUDIKSVALL' },
  { postalCode: '82442', city: 'HUDIKSVALL' },
  { postalCode: '82443', city: 'HUDIKSVALL' },
  { postalCode: '82444', city: 'HUDIKSVALL' },
  { postalCode: '82445', city: 'HUDIKSVALL' },
  { postalCode: '82450', city: 'HUDIKSVALL' },
  { postalCode: '82451', city: 'HUDIKSVALL' },
  { postalCode: '82452', city: 'HUDIKSVALL' },
  { postalCode: '82453', city: 'HUDIKSVALL' },
  { postalCode: '82454', city: 'HUDIKSVALL' },
  { postalCode: '82455', city: 'HUDIKSVALL' },
  { postalCode: '82456', city: 'HUDIKSVALL' },
  { postalCode: '82460', city: 'FORSA' },
  { postalCode: '82461', city: 'FORSA' },
  { postalCode: '82462', city: 'FORSA' },
  { postalCode: '82465', city: 'NÄSVIKEN' },
  { postalCode: '82466', city: 'NÄSVIKEN' },
  { postalCode: '82467', city: 'NÄSVIKEN' },
  { postalCode: '82470', city: 'DELSBO' },
  { postalCode: '82471', city: 'DELSBO' },
  { postalCode: '82472', city: 'DELSBO' },
  { postalCode: '82473', city: 'DELSBO' },
  { postalCode: '82474', city: 'DELSBO' },
  { postalCode: '82477', city: 'BJURÅKER' },
  { postalCode: '82478', city: 'BJURÅKER' },
  { postalCode: '82479', city: 'BJURÅKER' },
  { postalCode: '82480', city: 'HUDIKSVALL' },
  { postalCode: '82481', city: 'HUDIKSVALL' },
  { postalCode: '82482', city: 'HUDIKSVALL' },
  { postalCode: '82483', city: 'HUDIKSVALL' },
  { postalCode: '82491', city: 'HUDIKSVALL' },
  { postalCode: '82492', city: 'HUDIKSVALL' },
  { postalCode: '82493', city: 'HUDIKSVALL' },
  { postalCode: '82494', city: 'HUDIKSVALL' },
  { postalCode: '82495', city: 'STRÖMSBRUK' },
  { postalCode: '82496', city: 'STOCKA' },
  { postalCode: '82501', city: 'IGGESUND' },
  { postalCode: '82520', city: 'IGGESUND' },
  { postalCode: '82521', city: 'IGGESUND' },
  { postalCode: '82530', city: 'IGGESUND' },
  { postalCode: '82531', city: 'IGGESUND' },
  { postalCode: '82532', city: 'IGGESUND' },
  { postalCode: '82580', city: 'IGGESUND' },
  { postalCode: '82591', city: 'IGGESUND' },
  { postalCode: '82592', city: 'NJUTÅNGER' },
  { postalCode: '82593', city: 'NJUTÅNGER' },
  { postalCode: '82595', city: 'ENÅNGER' },
  { postalCode: '82596', city: 'ENÅNGER' },
  { postalCode: '82601', city: 'SÖDERHAMN' },
  { postalCode: '82608', city: 'TRÖNÖDAL' },
  { postalCode: '82611', city: 'BERGVIK' },
  { postalCode: '82612', city: 'SANDARNE' },
  { postalCode: '82613', city: 'LJUSNE' },
  { postalCode: '82620', city: 'SÖDERHAMN' },
  { postalCode: '82621', city: 'SÖDERHAMN' },
  { postalCode: '82622', city: 'SÖDERHAMN' },
  { postalCode: '82623', city: 'SÖDERHAMN' },
  { postalCode: '82624', city: 'SÖDERHAMN' },
  { postalCode: '82625', city: 'SÖDERHAMN' },
  { postalCode: '82626', city: 'SÖDERHAMN' },
  { postalCode: '82627', city: 'SÖDERHAMN' },
  { postalCode: '82630', city: 'SÖDERHAMN' },
  { postalCode: '82631', city: 'SÖDERHAMN' },
  { postalCode: '82632', city: 'SÖDERHAMN' },
  { postalCode: '82634', city: 'SÖDERHAMN' },
  { postalCode: '82635', city: 'SÖDERHAMN' },
  { postalCode: '82636', city: 'SÖDERHAMN' },
  { postalCode: '82637', city: 'SÖDERHAMN' },
  { postalCode: '82639', city: 'SÖDERHAMN' },
  { postalCode: '82640', city: 'SÖDERHAMN' },
  { postalCode: '82650', city: 'SÖDERHAMN' },
  { postalCode: '82660', city: 'SÖDERHAMN' },
  { postalCode: '82661', city: 'SÖDERALA' },
  { postalCode: '82662', city: 'NORRALA' },
  { postalCode: '82666', city: 'MARMAVERKEN' },
  { postalCode: '82667', city: 'BERGVIK' },
  { postalCode: '82668', city: 'BERGVIK' },
  { postalCode: '82669', city: 'STRÅTJÄRA' },
  { postalCode: '82670', city: 'SÖDERHAMN' },
  { postalCode: '82672', city: 'SANDARNE' },
  { postalCode: '82673', city: 'SANDARNE' },
  { postalCode: '82675', city: 'LJUSNE' },
  { postalCode: '82676', city: 'LJUSNE' },
  { postalCode: '82677', city: 'LJUSNE' },
  { postalCode: '82679', city: 'VALLVIK' },
  { postalCode: '82680', city: 'SÖDERHAMN' },
  { postalCode: '82681', city: 'SÖDERHAMN' },
  { postalCode: '82682', city: 'SÖDERHAMN' },
  { postalCode: '82683', city: 'SÖDERHAMN' },
  { postalCode: '82684', city: 'SÖDERHAMN' },
  { postalCode: '82685', city: 'SÖDERHAMN' },
  { postalCode: '82686', city: 'SÖDERHAMN' },
  { postalCode: '82691', city: 'SÖDERHAMN' },
  { postalCode: '82692', city: 'SÖDERALA' },
  { postalCode: '82693', city: 'SÖDERHAMN' },
  { postalCode: '82694', city: 'NORRALA' },
  { postalCode: '82695', city: 'TRÖNÖDAL' },
  { postalCode: '82701', city: 'LJUSDAL' },
  { postalCode: '82720', city: 'LJUSDAL' },
  { postalCode: '82721', city: 'LJUSDAL' },
  { postalCode: '82722', city: 'LJUSDAL' },
  { postalCode: '82723', city: 'LJUSDAL' },
  { postalCode: '82724', city: 'LJUSDAL' },
  { postalCode: '82725', city: 'LJUSDAL' },
  { postalCode: '82727', city: 'JÄRVSÖ' },
  { postalCode: '82728', city: 'FÄRILA' },
  { postalCode: '82730', city: 'LJUSDAL' },
  { postalCode: '82731', city: 'LJUSDAL' },
  { postalCode: '82732', city: 'LJUSDAL' },
  { postalCode: '82733', city: 'LJUSDAL' },
  { postalCode: '82734', city: 'LJUSDAL' },
  { postalCode: '82735', city: 'LJUSDAL' },
  { postalCode: '82740', city: 'TALLÅSEN' },
  { postalCode: '82750', city: 'JÄRVSÖ' },
  { postalCode: '82751', city: 'JÄRVSÖ' },
  { postalCode: '82752', city: 'JÄRVSÖ' },
  { postalCode: '82753', city: 'JÄRVSÖ' },
  { postalCode: '82754', city: 'JÄRVSÖ' },
  { postalCode: '82760', city: 'FÄRILA' },
  { postalCode: '82761', city: 'FÄRILA' },
  { postalCode: '82762', city: 'FÄRILA' },
  { postalCode: '82763', city: 'FÄRILA' },
  { postalCode: '82770', city: 'LOS' },
  { postalCode: '82771', city: 'HAMRA' },
  { postalCode: '82772', city: 'KORSKROGEN' },
  { postalCode: '82773', city: 'KÅRBÖLE' },
  { postalCode: '82775', city: 'RAMSJÖ' },
  { postalCode: '82776', city: 'RAMSJÖ' },
  { postalCode: '82780', city: 'LJUSDAL' },
  { postalCode: '82781', city: 'LJUSDAL' },
  { postalCode: '82782', city: 'LJUSDAL' },
  { postalCode: '82791', city: 'LJUSDAL' },
  { postalCode: '82792', city: 'LJUSDAL' },
  { postalCode: '82793', city: 'LJUSDAL' },
  { postalCode: '82794', city: 'LJUSDAL' },
  { postalCode: '82795', city: 'TALLÅSEN' },
  { postalCode: '82801', city: 'EDSBYN' },
  { postalCode: '82820', city: 'EDSBYN' },
  { postalCode: '82821', city: 'EDSBYN' },
  { postalCode: '82822', city: 'EDSBYN' },
  { postalCode: '82823', city: 'EDSBYN' },
  { postalCode: '82825', city: 'EDSBYN' },
  { postalCode: '82830', city: 'EDSBYN' },
  { postalCode: '82831', city: 'EDSBYN' },
  { postalCode: '82832', city: 'EDSBYN' },
  { postalCode: '82833', city: 'EDSBYN' },
  { postalCode: '82834', city: 'EDSBYN' },
  { postalCode: '82880', city: 'EDSBYN' },
  { postalCode: '82881', city: 'EDSBYN' },
  { postalCode: '82891', city: 'EDSBYN' },
  { postalCode: '82892', city: 'EDSBYN' },
  { postalCode: '82893', city: 'VOXNABRUK' },
  { postalCode: '82894', city: 'EDSBYN' },
  { postalCode: '82895', city: 'VIKSJÖFORS' },
  { postalCode: '82899', city: 'EDSBYN' },
  { postalCode: '82920', city: 'BERGSJÖ' },
  { postalCode: '82921', city: 'BERGSJÖ' },
  { postalCode: '82922', city: 'HARMÅNGER' },
  { postalCode: '82923', city: 'GNARP' },
  { postalCode: '82924', city: 'HASSELA' },
  { postalCode: '82950', city: 'BERGSJÖ' },
  { postalCode: '82951', city: 'BERGSJÖ' },
  { postalCode: '82952', city: 'BERGSJÖ' },
  { postalCode: '82953', city: 'BERGSJÖ' },
  { postalCode: '82960', city: 'GNARP' },
  { postalCode: '82961', city: 'GNARP' },
  { postalCode: '82962', city: 'GNARP' },
  { postalCode: '82991', city: 'ILSBO' },
  { postalCode: '82995', city: 'HARMÅNGER' },
  { postalCode: '82996', city: 'JÄTTENDAL' },
  { postalCode: '82998', city: 'HASSELA' },
  { postalCode: '83100', city: 'ÖSTERSUND' },
  { postalCode: '83102', city: 'ÖSTERSUND' },
  { postalCode: '83103', city: 'ÖSTERSUND' },
  { postalCode: '83104', city: 'ÖSTERSUND' },
  { postalCode: '83105', city: 'ÖSTERSUND' },
  { postalCode: '83108', city: 'ÖSTERSUND' },
  { postalCode: '83109', city: 'ÖSTERSUND' },
  { postalCode: '83111', city: 'ÖSTERSUND' },
  { postalCode: '83117', city: 'ÖSTERSUND' },
  { postalCode: '83118', city: 'ÖSTERSUND' },
  { postalCode: '83120', city: 'ÖSTERSUND' },
  { postalCode: '83121', city: 'ÖSTERSUND' },
  { postalCode: '83122', city: 'ÖSTERSUND' },
  { postalCode: '83123', city: 'ÖSTERSUND' },
  { postalCode: '83124', city: 'ÖSTERSUND' },
  { postalCode: '83125', city: 'ÖSTERSUND' },
  { postalCode: '83126', city: 'ÖSTERSUND' },
  { postalCode: '83127', city: 'ÖSTERSUND' },
  { postalCode: '83128', city: 'ÖSTERSUND' },
  { postalCode: '83129', city: 'ÖSTERSUND' },
  { postalCode: '83130', city: 'ÖSTERSUND' },
  { postalCode: '83131', city: 'ÖSTERSUND' },
  { postalCode: '83132', city: 'ÖSTERSUND' },
  { postalCode: '83133', city: 'ÖSTERSUND' },
  { postalCode: '83134', city: 'ÖSTERSUND' },
  { postalCode: '83135', city: 'ÖSTERSUND' },
  { postalCode: '83136', city: 'ÖSTERSUND' },
  { postalCode: '83137', city: 'ÖSTERSUND' },
  { postalCode: '83138', city: 'ÖSTERSUND' },
  { postalCode: '83139', city: 'ÖSTERSUND' },
  { postalCode: '83140', city: 'ÖSTERSUND' },
  { postalCode: '83141', city: 'ÖSTERSUND' },
  { postalCode: '83142', city: 'ÖSTERSUND' },
  { postalCode: '83143', city: 'ÖSTERSUND' },
  { postalCode: '83144', city: 'ÖSTERSUND' },
  { postalCode: '83145', city: 'ÖSTERSUND' },
  { postalCode: '83146', city: 'ÖSTERSUND' },
  { postalCode: '83147', city: 'ÖSTERSUND' },
  { postalCode: '83148', city: 'ÖSTERSUND' },
  { postalCode: '83149', city: 'ÖSTERSUND' },
  { postalCode: '83150', city: 'ÖSTERSUND' },
  { postalCode: '83151', city: 'ÖSTERSUND' },
  { postalCode: '83152', city: 'ÖSTERSUND' },
  { postalCode: '83153', city: 'ÖSTERSUND' },
  { postalCode: '83155', city: 'ÖSTERSUND' },
  { postalCode: '83156', city: 'ÖSTERSUND' },
  { postalCode: '83157', city: 'ÖSTERSUND' },
  { postalCode: '83158', city: 'ÖSTERSUND' },
  { postalCode: '83159', city: 'ÖSTERSUND' },
  { postalCode: '83160', city: 'ÖSTERSUND' },
  { postalCode: '83161', city: 'ÖSTERSUND' },
  { postalCode: '83162', city: 'ÖSTERSUND' },
  { postalCode: '83163', city: 'ÖSTERSUND' },
  { postalCode: '83164', city: 'ÖSTERSUND' },
  { postalCode: '83165', city: 'ÖSTERSUND' },
  { postalCode: '83166', city: 'ÖSTERSUND' },
  { postalCode: '83170', city: 'ÖSTERSUND' },
  { postalCode: '83171', city: 'ÖSTERSUND' },
  { postalCode: '83172', city: 'ÖSTERSUND' },
  { postalCode: '83173', city: 'ÖSTERSUND' },
  { postalCode: '83174', city: 'ÖSTERSUND' },
  { postalCode: '83175', city: 'ÖSTERSUND' },
  { postalCode: '83176', city: 'ÖSTERSUND' },
  { postalCode: '83177', city: 'ÖSTERSUND' },
  { postalCode: '83178', city: 'ÖSTERSUND' },
  { postalCode: '83180', city: 'ÖSTERSUND' },
  { postalCode: '83181', city: 'ÖSTERSUND' },
  { postalCode: '83182', city: 'ÖSTERSUND' },
  { postalCode: '83183', city: 'ÖSTERSUND' },
  { postalCode: '83184', city: 'ÖSTERSUND' },
  { postalCode: '83186', city: 'ÖSTERSUND' },
  { postalCode: '83187', city: 'ÖSTERSUND' },
  { postalCode: '83188', city: 'ÖSTERSUND' },
  { postalCode: '83189', city: 'ÖSTERSUND' },
  { postalCode: '83190', city: 'ÖSTERSUND' },
  { postalCode: '83191', city: 'ÖSTERSUND' },
  { postalCode: '83192', city: 'ÖSTERSUND' },
  { postalCode: '83196', city: 'ÖSTERSUND' },
  { postalCode: '83197', city: 'ÖSTERSUND' },
  { postalCode: '83199', city: 'ÖSTERSUND' },
  { postalCode: '83201', city: 'FRÖSÖN' },
  { postalCode: '83221', city: 'FRÖSÖN' },
  { postalCode: '83223', city: 'FRÖSÖN' },
  { postalCode: '83241', city: 'FRÖSÖN' },
  { postalCode: '83242', city: 'FRÖSÖN' },
  { postalCode: '83243', city: 'FRÖSÖN' },
  { postalCode: '83244', city: 'FRÖSÖN' },
  { postalCode: '83245', city: 'FRÖSÖN' },
  { postalCode: '83246', city: 'FRÖSÖN' },
  { postalCode: '83247', city: 'FRÖSÖN' },
  { postalCode: '83251', city: 'FRÖSÖN' },
  { postalCode: '83253', city: 'FRÖSÖN' },
  { postalCode: '83254', city: 'FRÖSÖN' },
  { postalCode: '83255', city: 'FRÖSÖN' },
  { postalCode: '83256', city: 'FRÖSÖN' },
  { postalCode: '83280', city: 'FRÖSÖN' },
  { postalCode: '83281', city: 'FRÖSÖN' },
  { postalCode: '83282', city: 'FRÖSÖN' },
  { postalCode: '83293', city: 'FRÖSÖN' },
  { postalCode: '83294', city: 'ORRVIKEN' },
  { postalCode: '83296', city: 'FRÖSÖN' },
  { postalCode: '83301', city: 'STRÖMSUND' },
  { postalCode: '83302', city: 'HAMMERDAL' },
  { postalCode: '83303', city: 'HOTING' },
  { postalCode: '83304', city: 'GÄDDEDE' },
  { postalCode: '83318', city: 'STRÖMSUND' },
  { postalCode: '83319', city: 'STRÖMSUND' },
  { postalCode: '83320', city: 'STRÖMSUND' },
  { postalCode: '83321', city: 'STRÖMSUND' },
  { postalCode: '83322', city: 'STRÖMSUND' },
  { postalCode: '83323', city: 'STRÖMSUND' },
  { postalCode: '83324', city: 'STRÖMSUND' },
  { postalCode: '83326', city: 'STRÖMSUND' },
  { postalCode: '83327', city: 'STRÖMSUND' },
  { postalCode: '83331', city: 'STRÖMSUND' },
  { postalCode: '83332', city: 'STRÖMSUND' },
  { postalCode: '83333', city: 'STRÖMSUND' },
  { postalCode: '83334', city: 'STRÖMSUND' },
  { postalCode: '83335', city: 'STRÖMSUND' },
  { postalCode: '83336', city: 'STRÖMSUND' },
  { postalCode: '83341', city: 'HAMMERDAL' },
  { postalCode: '83348', city: 'HAMMERDAL' },
  { postalCode: '83349', city: 'HAMMERDAL' },
  { postalCode: '83351', city: 'HOTING' },
  { postalCode: '83359', city: 'HOTING' },
  { postalCode: '83361', city: 'GÄDDEDE' },
  { postalCode: '83380', city: 'STRÖMSUND' },
  { postalCode: '83381', city: 'STRÖMSUND' },
  { postalCode: '83382', city: 'STRÖMSUND' },
  { postalCode: '83383', city: 'STRÖMSUND' },
  { postalCode: '83384', city: 'STRÖMSUND' },
  { postalCode: '83385', city: 'STRÖMSUND' },
  { postalCode: '83386', city: 'STRÖMSUND' },
  { postalCode: '83387', city: 'STRÖMSUND' },
  { postalCode: '83391', city: 'NORRÅKER' },
  { postalCode: '83392', city: 'STRÖMSUND' },
  { postalCode: '83393', city: 'STRÖMSUND' },
  { postalCode: '83394', city: 'STRÖMSUND' },
  { postalCode: '83395', city: 'STRÖMSUND' },
  { postalCode: '83396', city: 'STRÖMSUND' },
  { postalCode: '83397', city: 'LIDSJÖBERG' },
  { postalCode: '83398', city: 'GÄDDEDE' },
  { postalCode: '83399', city: 'STORA BLÅSJÖN' },
  { postalCode: '83401', city: 'BRUNFLO' },
  { postalCode: '83421', city: 'BRUNFLO' },
  { postalCode: '83431', city: 'BRUNFLO' },
  { postalCode: '83432', city: 'BRUNFLO' },
  { postalCode: '83433', city: 'BRUNFLO' },
  { postalCode: '83434', city: 'BRUNFLO' },
  { postalCode: '83496', city: 'BRUNFLO' },
  { postalCode: '83497', city: 'BRUNFLO' },
  { postalCode: '83498', city: 'BRUNFLO' },
  { postalCode: '83499', city: 'TANDSBYN' },
  { postalCode: '83501', city: 'KROKOM' },
  { postalCode: '83505', city: 'NÄLDEN' },
  { postalCode: '83520', city: 'KROKOM' },
  { postalCode: '83521', city: 'KROKOM' },
  { postalCode: '83522', city: 'KROKOM' },
  { postalCode: '83523', city: 'FÖLLINGE' },
  { postalCode: '83531', city: 'KROKOM' },
  { postalCode: '83532', city: 'KROKOM' },
  { postalCode: '83533', city: 'KROKOM' },
  { postalCode: '83539', city: 'NÄLDEN' },
  { postalCode: '83540', city: 'NÄLDEN' },
  { postalCode: '83541', city: 'DVÄRSÄTT' },
  { postalCode: '83542', city: 'KROKOM' },
  { postalCode: '83561', city: 'FÖLLINGE' },
  { postalCode: '83562', city: 'FÖLLINGE' },
  { postalCode: '83563', city: 'FÖLLINGE' },
  { postalCode: '83564', city: 'FÖLLINGE' },
  { postalCode: '83567', city: 'VALSJÖBYN' },
  { postalCode: '83568', city: 'MUNKFLOHÖGEN' },
  { postalCode: '83580', city: 'KROKOM' },
  { postalCode: '83591', city: 'KROKOM' },
  { postalCode: '83592', city: 'KROKOM' },
  { postalCode: '83593', city: 'ALSEN' },
  { postalCode: '83594', city: 'ASPÅS' },
  { postalCode: '83595', city: 'NÄLDEN' },
  { postalCode: '83596', city: 'TRÅNGSVIKEN' },
  { postalCode: '83597', city: 'OFFERDAL' },
  { postalCode: '83598', city: 'OFFERDAL' },
  { postalCode: '83599', city: 'KROKOM' },
  { postalCode: '83631', city: 'LIT' },
  { postalCode: '83691', city: 'LIT' },
  { postalCode: '83692', city: 'LIT' },
  { postalCode: '83693', city: 'HÄGGENÅS' },
  { postalCode: '83694', city: 'ÅS' },
  { postalCode: '83695', city: 'ÅS' },
  { postalCode: '83701', city: 'JÄRPEN' },
  { postalCode: '83720', city: 'JÄRPEN' },
  { postalCode: '83721', city: 'MÖRSIL' },
  { postalCode: '83722', city: 'JÄRPEN' },
  { postalCode: '83723', city: 'ÅRE' },
  { postalCode: '83724', city: 'DUVED' },
  { postalCode: '83725', city: 'STORLIEN' },
  { postalCode: '83731', city: 'JÄRPEN' },
  { postalCode: '83732', city: 'JÄRPEN' },
  { postalCode: '83741', city: 'MÖRSIL' },
  { postalCode: '83751', city: 'ÅRE' },
  { postalCode: '83752', city: 'ÅRE' },
  { postalCode: '83771', city: 'DUVED' },
  { postalCode: '83772', city: 'DUVED' },
  { postalCode: '83775', city: 'VÅLÅDALEN' },
  { postalCode: '83791', city: 'MATTMAR' },
  { postalCode: '83792', city: 'MÖRSIL' },
  { postalCode: '83793', city: 'JÄRPEN' },
  { postalCode: '83794', city: 'JÄRPEN' },
  { postalCode: '83795', city: 'UNDERSÅKER' },
  { postalCode: '83796', city: 'UNDERSÅKER' },
  { postalCode: '83797', city: 'ÅRE' },
  { postalCode: '83798', city: 'ÅRE' },
  { postalCode: '83799', city: 'STORLIEN' },
  { postalCode: '83801', city: 'FRÖSÖN' },
  { postalCode: '83820', city: 'FRÖSÖN' },
  { postalCode: '83821', city: 'FRÖSÖN' },
  { postalCode: '83826', city: 'FRÖSÖN' },
  { postalCode: '83827', city: 'FRÖSÖN' },
  { postalCode: '83871', city: 'FRÖSÖN' },
  { postalCode: '83872', city: 'FRÖSÖN' },
  { postalCode: '83873', city: 'FRÖSÖN' },
  { postalCode: '83874', city: 'FRÖSÖN' },
  { postalCode: '83875', city: 'FRÖSÖN' },
  { postalCode: '83876', city: 'FRÖSÖN' },
  { postalCode: '83877', city: 'FRÖSÖN' },
  { postalCode: '83878', city: 'FRÖSÖN' },
  { postalCode: '83879', city: 'FRÖSÖN' },
  { postalCode: '83880', city: 'FRÖSÖN' },
  { postalCode: '83881', city: 'FRÖSÖN' },
  { postalCode: '83882', city: 'FRÖSÖN' },
  { postalCode: '83883', city: 'FRÖSÖN' },
  { postalCode: '83905', city: 'ÖSTERSUND' },
  { postalCode: '83928', city: 'ÖSTERSUND' },
  { postalCode: '83929', city: 'ÖSTERSUND' },
  { postalCode: '83930', city: 'ÖSTERSUND' },
  { postalCode: '83942', city: 'ÖSTERSUND' },
  { postalCode: '83977', city: 'ÖSTERSUND' },
  { postalCode: '83980', city: 'ÖSTERSUND' },
  { postalCode: '83981', city: 'ÖSTERSUND' },
  { postalCode: '83983', city: 'ÖSTERSUND' },
  { postalCode: '83984', city: 'ÖSTERSUND' },
  { postalCode: '83985', city: 'ÖSTERSUND' },
  { postalCode: '83986', city: 'ÖSTERSUND' },
  { postalCode: '83987', city: 'ÖSTERSUND' },
  { postalCode: '83988', city: 'ÖSTERSUND' },
  { postalCode: '83989', city: 'ÖSTERSUND' },
  { postalCode: '84100', city: 'ÅNGE' },
  { postalCode: '84102', city: 'FRÄNSTA' },
  { postalCode: '84120', city: 'ÅNGE' },
  { postalCode: '84121', city: 'ÅNGE' },
  { postalCode: '84122', city: 'ÅNGE' },
  { postalCode: '84131', city: 'ÅNGE' },
  { postalCode: '84132', city: 'ÅNGE' },
  { postalCode: '84133', city: 'ÅNGE' },
  { postalCode: '84134', city: 'ÅNGE' },
  { postalCode: '84144', city: 'ALBY' },
  { postalCode: '84171', city: 'FRÄNSTA' },
  { postalCode: '84172', city: 'FRÄNSTA' },
  { postalCode: '84173', city: 'FRÄNSTA' },
  { postalCode: '84174', city: 'FRÄNSTA' },
  { postalCode: '84175', city: 'TORPSHAMMAR' },
  { postalCode: '84176', city: 'TORPSHAMMAR' },
  { postalCode: '84177', city: 'TORPSHAMMAR' },
  { postalCode: '84181', city: 'ÅNGE' },
  { postalCode: '84191', city: 'ÅNGE' },
  { postalCode: '84192', city: 'ÅNGE' },
  { postalCode: '84193', city: 'ÖSTAVALL' },
  { postalCode: '84194', city: 'ALBY' },
  { postalCode: '84195', city: 'ÖVERTURINGEN' },
  { postalCode: '84196', city: 'ERIKSLUND' },
  { postalCode: '84197', city: 'ERIKSLUND' },
  { postalCode: '84198', city: 'LJUNGAVERK' },
  { postalCode: '84199', city: 'LJUNGAVERK' },
  { postalCode: '84201', city: 'SVEG' },
  { postalCode: '84220', city: 'SVEG' },
  { postalCode: '84221', city: 'SVEG' },
  { postalCode: '84222', city: 'SVEG' },
  { postalCode: '84231', city: 'SVEG' },
  { postalCode: '84232', city: 'SVEG' },
  { postalCode: '84233', city: 'SVEG' },
  { postalCode: '84271', city: 'YTTERHOGDAL' },
  { postalCode: '84280', city: 'SVEG' },
  { postalCode: '84291', city: 'SVEG' },
  { postalCode: '84292', city: 'SVEG' },
  { postalCode: '84293', city: 'SVEG' },
  { postalCode: '84294', city: 'SVEG' },
  { postalCode: '84295', city: 'LILLHÄRDAL' },
  { postalCode: '84296', city: 'LOFSDALEN' },
  { postalCode: '84297', city: 'YTTERHOGDAL' },
  { postalCode: '84299', city: 'SVEG' },
  { postalCode: '84301', city: 'BRÄCKE' },
  { postalCode: '84320', city: 'BRÄCKE' },
  { postalCode: '84321', city: 'BRÄCKE' },
  { postalCode: '84331', city: 'BRÄCKE' },
  { postalCode: '84332', city: 'BRÄCKE' },
  { postalCode: '84341', city: 'GÄLLÖ' },
  { postalCode: '84391', city: 'BRÄCKE' },
  { postalCode: '84392', city: 'BRÄCKE' },
  { postalCode: '84393', city: 'BRÄCKE' },
  { postalCode: '84394', city: 'GÄLLÖ' },
  { postalCode: '84395', city: 'GÄLLÖ' },
  { postalCode: '84396', city: 'GÄLLÖ' },
  { postalCode: '84397', city: 'PILGRIMSTAD' },
  { postalCode: '84398', city: 'SÖRBYGDEN' },
  { postalCode: '84401', city: 'HAMMARSTRAND' },
  { postalCode: '84420', city: 'HAMMARSTRAND' },
  { postalCode: '84421', city: 'HAMMARSTRAND' },
  { postalCode: '84422', city: 'BISPGÅRDEN' },
  { postalCode: '84423', city: 'KÄLARNE' },
  { postalCode: '84431', city: 'HAMMARSTRAND' },
  { postalCode: '84432', city: 'HAMMARSTRAND' },
  { postalCode: '84441', city: 'BISPGÅRDEN' },
  { postalCode: '84451', city: 'STUGUN' },
  { postalCode: '84461', city: 'KÄLARNE' },
  { postalCode: '84491', city: 'HAMMARSTRAND' },
  { postalCode: '84492', city: 'HAMMARSTRAND' },
  { postalCode: '84493', city: 'BISPGÅRDEN' },
  { postalCode: '84494', city: 'BISPGÅRDEN' },
  { postalCode: '84495', city: 'STUGUN' },
  { postalCode: '84496', city: 'STUGUN' },
  { postalCode: '84497', city: 'KÄLARNE' },
  { postalCode: '84498', city: 'KÄLARNE' },
  { postalCode: '84501', city: 'SVENSTAVIK' },
  { postalCode: '84520', city: 'SVENSTAVIK' },
  { postalCode: '84521', city: 'SVENSTAVIK' },
  { postalCode: '84531', city: 'SVENSTAVIK' },
  { postalCode: '84551', city: 'ÅSARNA' },
  { postalCode: '84552', city: 'ÅSARNA' },
  { postalCode: '84561', city: 'SVENSTAVIK' },
  { postalCode: '84562', city: 'SVENSTAVIK' },
  { postalCode: '84571', city: 'OVIKEN' },
  { postalCode: '84572', city: 'OVIKEN' },
  { postalCode: '84573', city: 'OVIKEN' },
  { postalCode: '84591', city: 'HALLEN' },
  { postalCode: '84593', city: 'FÅKER' },
  { postalCode: '84594', city: 'HACKÅS' },
  { postalCode: '84595', city: 'RÄTAN' },
  { postalCode: '84597', city: 'KLÖVSJÖ' },
  { postalCode: '84598', city: 'STORSJÖ KAPELL' },
  { postalCode: '84599', city: 'LJUNGDALEN' },
  { postalCode: '84601', city: 'HEDE' },
  { postalCode: '84602', city: 'VEMDALEN' },
  { postalCode: '84603', city: 'FUNÄSDALEN' },
  { postalCode: '84620', city: 'HEDE' },
  { postalCode: '84621', city: 'HEDE' },
  { postalCode: '84631', city: 'HEDE' },
  { postalCode: '84671', city: 'VEMDALEN' },
  { postalCode: '84672', city: 'FUNÄSDALEN' },
  { postalCode: '84673', city: 'FUNÄSDALEN' },
  { postalCode: '84674', city: 'FUNÄSDALEN' },
  { postalCode: '84691', city: 'HEDE' },
  { postalCode: '84692', city: 'HEDE' },
  { postalCode: '84693', city: 'VEMHÅN' },
  { postalCode: '84694', city: 'VEMDALEN' },
  { postalCode: '84695', city: 'TÄNNÄS' },
  { postalCode: '84696', city: 'LJUSNEDAL' },
  { postalCode: '84697', city: 'BRUKSVALLARNA' },
  { postalCode: '84698', city: 'TÄNNDALEN' },
  { postalCode: '85003', city: 'SUNDSVALL' },
  { postalCode: '85006', city: 'SUNDSVALL' },
  { postalCode: '85007', city: 'SUNDSVALL' },
  { postalCode: '85009', city: 'SUNDSVALL' },
  { postalCode: '85010', city: 'SUNDSVALL' },
  { postalCode: '85012', city: 'SUNDSVALL' },
  { postalCode: '85013', city: 'SUNDSVALL' },
  { postalCode: '85015', city: 'SUNDSVALL' },
  { postalCode: '85100', city: 'SUNDSVALL' },
  { postalCode: '85102', city: 'SUNDSVALL' },
  { postalCode: '85103', city: 'SUNDSVALL' },
  { postalCode: '85104', city: 'SUNDSVALL' },
  { postalCode: '85105', city: 'SUNDSVALL' },
  { postalCode: '85106', city: 'SUNDSVALL' },
  { postalCode: '85107', city: 'SUNDSVALL' },
  { postalCode: '85108', city: 'SUNDSVALL' },
  { postalCode: '85111', city: 'SUNDSVALL' },
  { postalCode: '85121', city: 'SUNDSVALL' },
  { postalCode: '85122', city: 'SUNDSVALL' },
  { postalCode: '85123', city: 'SUNDSVALL' },
  { postalCode: '85124', city: 'SUNDSVALL' },
  { postalCode: '85125', city: 'SUNDSVALL' },
  { postalCode: '85170', city: 'SUNDSVALL' },
  { postalCode: '85171', city: 'SUNDSVALL' },
  { postalCode: '85172', city: 'SUNDSVALL' },
  { postalCode: '85173', city: 'SUNDSVALL' },
  { postalCode: '85174', city: 'SUNDSVALL' },
  { postalCode: '85175', city: 'SUNDSVALL' },
  { postalCode: '85176', city: 'SUNDSVALL' },
  { postalCode: '85178', city: 'SUNDSVALL' },
  { postalCode: '85179', city: 'SUNDSVALL' },
  { postalCode: '85180', city: 'SUNDSVALL' },
  { postalCode: '85181', city: 'SUNDSVALL' },
  { postalCode: '85182', city: 'SUNDSVALL' },
  { postalCode: '85183', city: 'SUNDSVALL' },
  { postalCode: '85184', city: 'SUNDSVALL' },
  { postalCode: '85185', city: 'SUNDSVALL' },
  { postalCode: '85186', city: 'SUNDSVALL' },
  { postalCode: '85187', city: 'SUNDSVALL' },
  { postalCode: '85188', city: 'SUNDSVALL' },
  { postalCode: '85189', city: 'SUNDSVALL' },
  { postalCode: '85190', city: 'SUNDSVALL' },
  { postalCode: '85192', city: 'SUNDSVALL' },
  { postalCode: '85193', city: 'SUNDSVALL' },
  { postalCode: '85194', city: 'SUNDSVALL' },
  { postalCode: '85196', city: 'SUNDSVALL' },
  { postalCode: '85198', city: 'SUNDSVALL' },
  { postalCode: '85199', city: 'SUNDSVALL' },
  { postalCode: '85229', city: 'SUNDSVALL' },
  { postalCode: '85231', city: 'SUNDSVALL' },
  { postalCode: '85232', city: 'SUNDSVALL' },
  { postalCode: '85233', city: 'SUNDSVALL' },
  { postalCode: '85234', city: 'SUNDSVALL' },
  { postalCode: '85236', city: 'SUNDSVALL' },
  { postalCode: '85237', city: 'SUNDSVALL' },
  { postalCode: '85238', city: 'SUNDSVALL' },
  { postalCode: '85240', city: 'SUNDSVALL' },
  { postalCode: '85241', city: 'SUNDSVALL' },
  { postalCode: '85242', city: 'SUNDSVALL' },
  { postalCode: '85243', city: 'SUNDSVALL' },
  { postalCode: '85244', city: 'SUNDSVALL' },
  { postalCode: '85301', city: 'SUNDSVALL' },
  { postalCode: '85350', city: 'SUNDSVALL' },
  { postalCode: '85351', city: 'SUNDSVALL' },
  { postalCode: '85352', city: 'SUNDSVALL' },
  { postalCode: '85353', city: 'SUNDSVALL' },
  { postalCode: '85356', city: 'SUNDSVALL' },
  { postalCode: '85357', city: 'SUNDSVALL' },
  { postalCode: '85358', city: 'SUNDSVALL' },
  { postalCode: '85359', city: 'SUNDSVALL' },
  { postalCode: '85401', city: 'SUNDSVALL' },
  { postalCode: '85402', city: 'SUNDSVALL' },
  { postalCode: '85460', city: 'SUNDSVALL' },
  { postalCode: '85461', city: 'SUNDSVALL' },
  { postalCode: '85462', city: 'SUNDSVALL' },
  { postalCode: '85463', city: 'SUNDSVALL' },
  { postalCode: '85466', city: 'SUNDSVALL' },
  { postalCode: '85467', city: 'SUNDSVALL' },
  { postalCode: '85468', city: 'SUNDSVALL' },
  { postalCode: '85561', city: 'KOVLAND' },
  { postalCode: '85591', city: 'SUNDSVALL' },
  { postalCode: '85592', city: 'KOVLAND' },
  { postalCode: '85597', city: 'INDAL' },
  { postalCode: '85598', city: 'LIDEN' },
  { postalCode: '85599', city: 'LIDEN' },
  { postalCode: '85601', city: 'SUNDSVALL' },
  { postalCode: '85630', city: 'SUNDSVALL' },
  { postalCode: '85631', city: 'SUNDSVALL' },
  { postalCode: '85632', city: 'SUNDSVALL' },
  { postalCode: '85633', city: 'SUNDSVALL' },
  { postalCode: '85634', city: 'SUNDSVALL' },
  { postalCode: '85635', city: 'SUNDSVALL' },
  { postalCode: '85640', city: 'SUNDSVALL' },
  { postalCode: '85641', city: 'SUNDSVALL' },
  { postalCode: '85642', city: 'SUNDSVALL' },
  { postalCode: '85643', city: 'SUNDSVALL' },
  { postalCode: '85644', city: 'SUNDSVALL' },
  { postalCode: '85645', city: 'SUNDSVALL' },
  { postalCode: '85650', city: 'SUNDSVALL' },
  { postalCode: '85651', city: 'SUNDSVALL' },
  { postalCode: '85652', city: 'SUNDSVALL' },
  { postalCode: '85653', city: 'SUNDSVALL' },
  { postalCode: '85700', city: 'SUNDSVALL' },
  { postalCode: '85701', city: 'SUNDSVALL' },
  { postalCode: '85730', city: 'SUNDSVALL' },
  { postalCode: '85731', city: 'SUNDSVALL' },
  { postalCode: '85732', city: 'SUNDSVALL' },
  { postalCode: '85733', city: 'SUNDSVALL' },
  { postalCode: '85734', city: 'SUNDSVALL' },
  { postalCode: '85735', city: 'SUNDSVALL' },
  { postalCode: '85740', city: 'SUNDSVALL' },
  { postalCode: '85741', city: 'SUNDSVALL' },
  { postalCode: '85742', city: 'SUNDSVALL' },
  { postalCode: '85750', city: 'SUNDSVALL' },
  { postalCode: '85751', city: 'SUNDSVALL' },
  { postalCode: '85752', city: 'SUNDSVALL' },
  { postalCode: '85800', city: 'SUNDSVALL' },
  { postalCode: '85900', city: 'SUNDSVALL' },
  { postalCode: '85950', city: 'SUNDSVALL' },
  { postalCode: '85951', city: 'SUNDSVALL' },
  { postalCode: '85952', city: 'SUNDSVALL' },
  { postalCode: '85953', city: 'SUNDSVALL' },
  { postalCode: '85954', city: 'SUNDSVALL' },
  { postalCode: '85955', city: 'SUNDSVALL' },
  { postalCode: '85956', city: 'SUNDSVALL' },
  { postalCode: '85957', city: 'SUNDSVALL' },
  { postalCode: '85958', city: 'SUNDSVALL' },
  { postalCode: '85959', city: 'SUNDSVALL' },
  { postalCode: '86101', city: 'TIMRÅ' },
  { postalCode: '86120', city: 'TIMRÅ' },
  { postalCode: '86121', city: 'TIMRÅ' },
  { postalCode: '86122', city: 'TIMRÅ' },
  { postalCode: '86123', city: 'TIMRÅ' },
  { postalCode: '86124', city: 'TIMRÅ' },
  { postalCode: '86126', city: 'TIMRÅ' },
  { postalCode: '86127', city: 'SÖRBERGE' },
  { postalCode: '86128', city: 'SÖRÅKER' },
  { postalCode: '86131', city: 'TIMRÅ' },
  { postalCode: '86132', city: 'TIMRÅ' },
  { postalCode: '86133', city: 'TIMRÅ' },
  { postalCode: '86134', city: 'TIMRÅ' },
  { postalCode: '86135', city: 'TIMRÅ' },
  { postalCode: '86136', city: 'TIMRÅ' },
  { postalCode: '86138', city: 'BERGEFORSEN' },
  { postalCode: '86139', city: 'BERGEFORSEN' },
  { postalCode: '86141', city: 'SÖRBERGE' },
  { postalCode: '86142', city: 'SÖRBERGE' },
  { postalCode: '86143', city: 'SÖRBERGE' },
  { postalCode: '86145', city: 'FAGERVIK' },
  { postalCode: '86151', city: 'SÖRÅKER' },
  { postalCode: '86152', city: 'SÖRÅKER' },
  { postalCode: '86181', city: 'TIMRÅ' },
  { postalCode: '86182', city: 'TIMRÅ' },
  { postalCode: '86183', city: 'TIMRÅ' },
  { postalCode: '86184', city: 'TIMRÅ' },
  { postalCode: '86191', city: 'TIMRÅ' },
  { postalCode: '86192', city: 'BERGEFORSEN' },
  { postalCode: '86193', city: 'LJUSTORP' },
  { postalCode: '86194', city: 'SÖRÅKER' },
  { postalCode: '86195', city: 'SÖRÅKER' },
  { postalCode: '86202', city: 'NJURUNDA' },
  { postalCode: '86221', city: 'KVISSLEBY' },
  { postalCode: '86222', city: 'KVISSLEBY' },
  { postalCode: '86223', city: 'KVISSLEBY' },
  { postalCode: '86224', city: 'KVISSLEBY' },
  { postalCode: '86231', city: 'KVISSLEBY' },
  { postalCode: '86232', city: 'KVISSLEBY' },
  { postalCode: '86233', city: 'KVISSLEBY' },
  { postalCode: '86234', city: 'KVISSLEBY' },
  { postalCode: '86240', city: 'NJURUNDA' },
  { postalCode: '86241', city: 'NJURUNDA' },
  { postalCode: '86291', city: 'KVISSLEBY' },
  { postalCode: '86292', city: 'KVISSLEBY' },
  { postalCode: '86294', city: 'NJURUNDA' },
  { postalCode: '86295', city: 'NJURUNDA' },
  { postalCode: '86296', city: 'NJURUNDA' },
  { postalCode: '86301', city: 'SUNDSVALL' },
  { postalCode: '86321', city: 'SUNDSBRUK' },
  { postalCode: '86322', city: 'SUNDSBRUK' },
  { postalCode: '86323', city: 'SUNDSBRUK' },
  { postalCode: '86331', city: 'SUNDSBRUK' },
  { postalCode: '86332', city: 'SUNDSBRUK' },
  { postalCode: '86333', city: 'SUNDSBRUK' },
  { postalCode: '86334', city: 'SUNDSBRUK' },
  { postalCode: '86335', city: 'SUNDSBRUK' },
  { postalCode: '86336', city: 'SUNDSBRUK' },
  { postalCode: '86337', city: 'SUNDSVALL' },
  { postalCode: '86341', city: 'SUNDSVALL' },
  { postalCode: '86342', city: 'SUNDSVALL' },
  { postalCode: '86401', city: 'MATFORS' },
  { postalCode: '86420', city: 'MATFORS' },
  { postalCode: '86421', city: 'MATFORS' },
  { postalCode: '86422', city: 'MATFORS' },
  { postalCode: '86423', city: 'MATFORS' },
  { postalCode: '86431', city: 'MATFORS' },
  { postalCode: '86432', city: 'MATFORS' },
  { postalCode: '86433', city: 'MATFORS' },
  { postalCode: '86441', city: 'STÖDE' },
  { postalCode: '86491', city: 'MATFORS' },
  { postalCode: '86492', city: 'MATFORS' },
  { postalCode: '86495', city: 'STÖDE' },
  { postalCode: '86496', city: 'STÖDE' },
  { postalCode: '86501', city: 'ALNÖ' },
  { postalCode: '86521', city: 'ALNÖ' },
  { postalCode: '86531', city: 'ALNÖ' },
  { postalCode: '86532', city: 'ALNÖ' },
  { postalCode: '86533', city: 'ALNÖ' },
  { postalCode: '86551', city: 'ANKARSVIK' },
  { postalCode: '86591', city: 'ALNÖ' },
  { postalCode: '86592', city: 'ALNÖ' },
  { postalCode: '87101', city: 'HÄRNÖSAND' },
  { postalCode: '87103', city: 'HÄRNÖSAND' },
  { postalCode: '87104', city: 'ÄLANDSBRO' },
  { postalCode: '87120', city: 'HÄRNÖSAND' },
  { postalCode: '87121', city: 'HÄRNÖSAND' },
  { postalCode: '87122', city: 'HÄRNÖSAND' },
  { postalCode: '87123', city: 'HÄRNÖSAND' },
  { postalCode: '87124', city: 'HÄRNÖSAND' },
  { postalCode: '87125', city: 'HÄRNÖSAND' },
  { postalCode: '87126', city: 'HÄRNÖSAND' },
  { postalCode: '87127', city: 'HÄRNÖSAND' },
  { postalCode: '87129', city: 'HÄRNÖSAND' },
  { postalCode: '87130', city: 'HÄRNÖSAND' },
  { postalCode: '87131', city: 'HÄRNÖSAND' },
  { postalCode: '87132', city: 'HÄRNÖSAND' },
  { postalCode: '87133', city: 'HÄRNÖSAND' },
  { postalCode: '87134', city: 'HÄRNÖSAND' },
  { postalCode: '87140', city: 'HÄRNÖSAND' },
  { postalCode: '87141', city: 'HÄRNÖSAND' },
  { postalCode: '87142', city: 'HÄRNÖSAND' },
  { postalCode: '87145', city: 'HÄRNÖSAND' },
  { postalCode: '87150', city: 'HÄRNÖSAND' },
  { postalCode: '87151', city: 'HÄRNÖSAND' },
  { postalCode: '87152', city: 'HÄRNÖSAND' },
  { postalCode: '87153', city: 'HÄRNÖSAND' },
  { postalCode: '87154', city: 'HÄRNÖSAND' },
  { postalCode: '87160', city: 'HÄRNÖSAND' },
  { postalCode: '87161', city: 'HÄRNÖSAND' },
  { postalCode: '87162', city: 'HÄRNÖSAND' },
  { postalCode: '87165', city: 'HÄRNÖSAND' },
  { postalCode: '87166', city: 'HÄRNÖSAND' },
  { postalCode: '87171', city: 'ÄLANDSBRO' },
  { postalCode: '87180', city: 'HÄRNÖSAND' },
  { postalCode: '87181', city: 'HÄRNÖSAND' },
  { postalCode: '87182', city: 'HÄRNÖSAND' },
  { postalCode: '87185', city: 'HÄRNÖSAND' },
  { postalCode: '87186', city: 'HÄRNÖSAND' },
  { postalCode: '87187', city: 'HÄRNÖSAND' },
  { postalCode: '87188', city: 'HÄRNÖSAND' },
  { postalCode: '87191', city: 'HÄRNÖSAND' },
  { postalCode: '87192', city: 'HÄRNÖSAND' },
  { postalCode: '87193', city: 'HÄRNÖSAND' },
  { postalCode: '87194', city: 'VIKSJÖ' },
  { postalCode: '87195', city: 'ÄLANDSBRO' },
  { postalCode: '87196', city: 'HEMSÖN' },
  { postalCode: '87197', city: 'UTANSJÖ' },
  { postalCode: '87198', city: 'RAMVIK' },
  { postalCode: '87199', city: 'HÄRNÖSAND' },
  { postalCode: '87200', city: 'KRAMFORS' },
  { postalCode: '87220', city: 'KRAMFORS' },
  { postalCode: '87221', city: 'KRAMFORS' },
  { postalCode: '87222', city: 'KRAMFORS' },
  { postalCode: '87223', city: 'KRAMFORS' },
  { postalCode: '87224', city: 'KRAMFORS' },
  { postalCode: '87225', city: 'KRAMFORS' },
  { postalCode: '87230', city: 'KRAMFORS' },
  { postalCode: '87231', city: 'KRAMFORS' },
  { postalCode: '87232', city: 'KRAMFORS' },
  { postalCode: '87233', city: 'KRAMFORS' },
  { postalCode: '87234', city: 'KRAMFORS' },
  { postalCode: '87235', city: 'KRAMFORS' },
  { postalCode: '87236', city: 'KRAMFORS' },
  { postalCode: '87243', city: 'KRAMFORS' },
  { postalCode: '87263', city: 'LUNDE' },
  { postalCode: '87264', city: 'SANDÖVERKEN' },
  { postalCode: '87275', city: 'LUGNVIK' },
  { postalCode: '87280', city: 'KRAMFORS' },
  { postalCode: '87291', city: 'KRAMFORS' },
  { postalCode: '87292', city: 'KRAMFORS' },
  { postalCode: '87293', city: 'LUNDE' },
  { postalCode: '87294', city: 'SANDÖVERKEN' },
  { postalCode: '87295', city: 'LUGNVIK' },
  { postalCode: '87296', city: 'BJÄRTRÅ' },
  { postalCode: '87297', city: 'SKOG' },
  { postalCode: '87298', city: 'NORASTRÖM' },
  { postalCode: '87302', city: 'NYLAND' },
  { postalCode: '87303', city: 'ULLÅNGER' },
  { postalCode: '87321', city: 'BOLLSTABRUK' },
  { postalCode: '87322', city: 'BOLLSTABRUK' },
  { postalCode: '87330', city: 'BOLLSTABRUK' },
  { postalCode: '87340', city: 'BOLLSTABRUK' },
  { postalCode: '87350', city: 'VÄJA' },
  { postalCode: '87371', city: 'NYLAND' },
  { postalCode: '87372', city: 'ULLÅNGER' },
  { postalCode: '87380', city: 'BOLLSTABRUK' },
  { postalCode: '87381', city: 'VÄJA' },
  { postalCode: '87391', city: 'BOLLSTABRUK' },
  { postalCode: '87392', city: 'BOLLSTABRUK' },
  { postalCode: '87393', city: 'NYLAND' },
  { postalCode: '87394', city: 'NYLAND' },
  { postalCode: '87395', city: 'ULLÅNGER' },
  { postalCode: '87396', city: 'DOCKSTA' },
  { postalCode: '87397', city: 'MJÄLLOM' },
  { postalCode: '87398', city: 'NORDINGRÅ' },
  { postalCode: '87399', city: 'NORDINGRÅ' },
  { postalCode: '88101', city: 'SOLLEFTEÅ' },
  { postalCode: '88104', city: 'SOLLEFTEÅ' },
  { postalCode: '88106', city: 'NÄSÅKER' },
  { postalCode: '88120', city: 'SOLLEFTEÅ' },
  { postalCode: '88121', city: 'SOLLEFTEÅ' },
  { postalCode: '88122', city: 'SOLLEFTEÅ' },
  { postalCode: '88123', city: 'SOLLEFTEÅ' },
  { postalCode: '88124', city: 'SOLLEFTEÅ' },
  { postalCode: '88125', city: 'SOLLEFTEÅ' },
  { postalCode: '88126', city: 'SOLLEFTEÅ' },
  { postalCode: '88127', city: 'SOLLEFTEÅ' },
  { postalCode: '88130', city: 'SOLLEFTEÅ' },
  { postalCode: '88131', city: 'SOLLEFTEÅ' },
  { postalCode: '88132', city: 'SOLLEFTEÅ' },
  { postalCode: '88133', city: 'SOLLEFTEÅ' },
  { postalCode: '88134', city: 'SOLLEFTEÅ' },
  { postalCode: '88135', city: 'SOLLEFTEÅ' },
  { postalCode: '88140', city: 'SOLLEFTEÅ' },
  { postalCode: '88141', city: 'SOLLEFTEÅ' },
  { postalCode: '88150', city: 'SOLLEFTEÅ' },
  { postalCode: '88151', city: 'SOLLEFTEÅ' },
  { postalCode: '88152', city: 'SOLLEFTEÅ' },
  { postalCode: '88160', city: 'SOLLEFTEÅ' },
  { postalCode: '88170', city: 'NÄSÅKER' },
  { postalCode: '88180', city: 'SOLLEFTEÅ' },
  { postalCode: '88181', city: 'SOLLEFTEÅ' },
  { postalCode: '88185', city: 'SOLLEFTEÅ' },
  { postalCode: '88186', city: 'SOLLEFTEÅ' },
  { postalCode: '88191', city: 'SOLLEFTEÅ' },
  { postalCode: '88192', city: 'SOLLEFTEÅ' },
  { postalCode: '88193', city: 'SOLLEFTEÅ' },
  { postalCode: '88194', city: 'SOLLEFTEÅ' },
  { postalCode: '88195', city: 'UNDROM' },
  { postalCode: '88196', city: 'RESELE' },
  { postalCode: '88197', city: 'RESELE' },
  { postalCode: '88198', city: 'NÄSÅKER' },
  { postalCode: '88199', city: 'NÄSÅKER' },
  { postalCode: '88230', city: 'LÅNGSELE' },
  { postalCode: '88240', city: 'LÅNGSELE' },
  { postalCode: '88250', city: 'LÅNGSELE' },
  { postalCode: '88291', city: 'LÅNGSELE' },
  { postalCode: '88293', city: 'HELGUM' },
  { postalCode: '88295', city: 'GRANINGE' },
  { postalCode: '88302', city: 'EDSELE' },
  { postalCode: '88303', city: 'RAMSELE' },
  { postalCode: '88304', city: 'JUNSELE' },
  { postalCode: '88305', city: 'BACKE' },
  { postalCode: '88370', city: 'RAMSELE' },
  { postalCode: '88371', city: 'JUNSELE' },
  { postalCode: '88372', city: 'BACKE' },
  { postalCode: '88391', city: 'EDSELE' },
  { postalCode: '88392', city: 'RAMSELE' },
  { postalCode: '88393', city: 'RAMSELE' },
  { postalCode: '88394', city: 'BACKE' },
  { postalCode: '88395', city: 'ROSSÖN' },
  { postalCode: '88396', city: 'JUNSELE' },
  { postalCode: '88397', city: 'JUNSELE' },
  { postalCode: '89050', city: 'BJÖRNA' },
  { postalCode: '89051', city: 'LÅNGVIKSMON' },
  { postalCode: '89054', city: 'TREHÖRNINGSJÖ' },
  { postalCode: '89101', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89104', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89115', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89117', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89118', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89120', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89121', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89122', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89123', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89124', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89125', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89126', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89127', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89128', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89129', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89130', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89131', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89132', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89133', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89134', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89135', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89136', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89137', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89138', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89139', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89140', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89141', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89142', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89143', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89150', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89151', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89155', city: 'ARNÄSVALL' },
  { postalCode: '89160', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89161', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89162', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89177', city: 'JÄRVED' },
  { postalCode: '89178', city: 'BONÄSSUND' },
  { postalCode: '89180', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89182', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89185', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89186', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89188', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89189', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89190', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89192', city: 'ÖRNSKÖLDSVIK' },
  { postalCode: '89195', city: 'ARNÄSVALL' },
  { postalCode: '89196', city: 'ARNÄSVALL' },
  { postalCode: '89200', city: 'DOMSJÖ' },
  { postalCode: '89220', city: 'DOMSJÖ' },
  { postalCode: '89221', city: 'DOMSJÖ' },
  { postalCode: '89222', city: 'DOMSJÖ' },
  { postalCode: '89223', city: 'DOMSJÖ' },
  { postalCode: '89230', city: 'DOMSJÖ' },
  { postalCode: '89231', city: 'DOMSJÖ' },
  { postalCode: '89232', city: 'DOMSJÖ' },
  { postalCode: '89233', city: 'DOMSJÖ' },
  { postalCode: '89234', city: 'DOMSJÖ' },
  { postalCode: '89240', city: 'DOMSJÖ' },
  { postalCode: '89241', city: 'DOMSJÖ' },
  { postalCode: '89242', city: 'DOMSJÖ' },
  { postalCode: '89243', city: 'DOMSJÖ' },
  { postalCode: '89250', city: 'DOMSJÖ' },
  { postalCode: '89251', city: 'DOMSJÖ' },
  { postalCode: '89292', city: 'DOMSJÖ' },
  { postalCode: '89301', city: 'BJÄSTA' },
  { postalCode: '89321', city: 'BJÄSTA' },
  { postalCode: '89322', city: 'BJÄSTA' },
  { postalCode: '89323', city: 'BJÄSTA' },
  { postalCode: '89330', city: 'BJÄSTA' },
  { postalCode: '89331', city: 'BJÄSTA' },
  { postalCode: '89340', city: 'KÖPMANHOLMEN' },
  { postalCode: '89391', city: 'BJÄSTA' },
  { postalCode: '89392', city: 'BJÄSTA' },
  { postalCode: '89395', city: 'SIDENSJÖ' },
  { postalCode: '89396', city: 'SIDENSJÖ' },
  { postalCode: '89399', city: 'ULVÖHAMN' },
  { postalCode: '89421', city: 'SJÄLEVAD' },
  { postalCode: '89422', city: 'SJÄLEVAD' },
  { postalCode: '89423', city: 'SJÄLEVAD' },
  { postalCode: '89430', city: 'SJÄLEVAD' },
  { postalCode: '89431', city: 'SJÄLEVAD' },
  { postalCode: '89435', city: 'SJÄLEVAD' },
  { postalCode: '89440', city: 'ÖVERHÖRNÄS' },
  { postalCode: '89441', city: 'ÖVERHÖRNÄS' },
  { postalCode: '89491', city: 'SJÄLEVAD' },
  { postalCode: '89493', city: 'ÖVERHÖRNÄS' },
  { postalCode: '89495', city: 'MOLIDEN' },
  { postalCode: '89501', city: 'BREDBYN' },
  { postalCode: '89520', city: 'BREDBYN' },
  { postalCode: '89521', city: 'BREDBYN' },
  { postalCode: '89530', city: 'BREDBYN' },
  { postalCode: '89540', city: 'MELLANSEL' },
  { postalCode: '89580', city: 'MELLANSEL' },
  { postalCode: '89591', city: 'BREDBYN' },
  { postalCode: '89592', city: 'BREDBYN' },
  { postalCode: '89593', city: 'BREDBYN' },
  { postalCode: '89595', city: 'MELLANSEL' },
  { postalCode: '89597', city: 'SKORPED' },
  { postalCode: '89598', city: 'SOLBERG' },
  { postalCode: '89599', city: 'SOLBERG' },
  { postalCode: '89621', city: 'HUSUM' },
  { postalCode: '89631', city: 'HUSUM' },
  { postalCode: '89632', city: 'HUSUM' },
  { postalCode: '89680', city: 'HUSUM' },
  { postalCode: '89691', city: 'HUSUM' },
  { postalCode: '89693', city: 'GIDEÅ' },
  { postalCode: '90001', city: 'UMEÅ' },
  { postalCode: '90100', city: 'UMEÅ' },
  { postalCode: '90102', city: 'UMEÅ' },
  { postalCode: '90103', city: 'UMEÅ' },
  { postalCode: '90104', city: 'UMEÅ' },
  { postalCode: '90105', city: 'UMEÅ' },
  { postalCode: '90106', city: 'UMEÅ' },
  { postalCode: '90107', city: 'UMEÅ' },
  { postalCode: '90108', city: 'UMEÅ' },
  { postalCode: '90109', city: 'UMEÅ' },
  { postalCode: '90110', city: 'UMEÅ' },
  { postalCode: '90119', city: 'UMEÅ' },
  { postalCode: '90120', city: 'UMEÅ' },
  { postalCode: '90121', city: 'UMEÅ' },
  { postalCode: '90122', city: 'UMEÅ' },
  { postalCode: '90123', city: 'UMEÅ' },
  { postalCode: '90124', city: 'UMEÅ' },
  { postalCode: '90130', city: 'UMEÅ' },
  { postalCode: '90131', city: 'UMEÅ' },
  { postalCode: '90132', city: 'UMEÅ' },
  { postalCode: '90133', city: 'UMEÅ' },
  { postalCode: '90137', city: 'UMEÅ' },
  { postalCode: '90170', city: 'UMEÅ' },
  { postalCode: '90171', city: 'UMEÅ' },
  { postalCode: '90172', city: 'UMEÅ' },
  { postalCode: '90173', city: 'UMEÅ' },
  { postalCode: '90174', city: 'UMEÅ' },
  { postalCode: '90175', city: 'UMEÅ' },
  { postalCode: '90176', city: 'UMEÅ' },
  { postalCode: '90177', city: 'UMEÅ' },
  { postalCode: '90178', city: 'UMEÅ' },
  { postalCode: '90179', city: 'UMEÅ' },
  { postalCode: '90180', city: 'UMEÅ' },
  { postalCode: '90181', city: 'UMEÅ' },
  { postalCode: '90182', city: 'UMEÅ' },
  { postalCode: '90183', city: 'UMEÅ' },
  { postalCode: '90184', city: 'UMEÅ' },
  { postalCode: '90185', city: 'UMEÅ' },
  { postalCode: '90186', city: 'UMEÅ' },
  { postalCode: '90187', city: 'UMEÅ' },
  { postalCode: '90188', city: 'UMEÅ' },
  { postalCode: '90189', city: 'UMEÅ' },
  { postalCode: '90300', city: 'UMEÅ' },
  { postalCode: '90302', city: 'UMEÅ' },
  { postalCode: '90303', city: 'UMEÅ' },
  { postalCode: '90304', city: 'UMEÅ' },
  { postalCode: '90310', city: 'UMEÅ' },
  { postalCode: '90311', city: 'UMEÅ' },
  { postalCode: '90313', city: 'UMEÅ' },
  { postalCode: '90314', city: 'UMEÅ' },
  { postalCode: '90320', city: 'UMEÅ' },
  { postalCode: '90321', city: 'UMEÅ' },
  { postalCode: '90322', city: 'UMEÅ' },
  { postalCode: '90323', city: 'UMEÅ' },
  { postalCode: '90325', city: 'UMEÅ' },
  { postalCode: '90326', city: 'UMEÅ' },
  { postalCode: '90327', city: 'UMEÅ' },
  { postalCode: '90328', city: 'UMEÅ' },
  { postalCode: '90329', city: 'UMEÅ' },
  { postalCode: '90330', city: 'UMEÅ' },
  { postalCode: '90331', city: 'UMEÅ' },
  { postalCode: '90332', city: 'UMEÅ' },
  { postalCode: '90333', city: 'UMEÅ' },
  { postalCode: '90334', city: 'UMEÅ' },
  { postalCode: '90335', city: 'UMEÅ' },
  { postalCode: '90336', city: 'UMEÅ' },
  { postalCode: '90337', city: 'UMEÅ' },
  { postalCode: '90338', city: 'UMEÅ' },
  { postalCode: '90339', city: 'UMEÅ' },
  { postalCode: '90340', city: 'UMEÅ' },
  { postalCode: '90342', city: 'UMEÅ' },
  { postalCode: '90343', city: 'UMEÅ' },
  { postalCode: '90344', city: 'UMEÅ' },
  { postalCode: '90345', city: 'UMEÅ' },
  { postalCode: '90346', city: 'UMEÅ' },
  { postalCode: '90347', city: 'UMEÅ' },
  { postalCode: '90351', city: 'UMEÅ' },
  { postalCode: '90352', city: 'UMEÅ' },
  { postalCode: '90353', city: 'UMEÅ' },
  { postalCode: '90354', city: 'UMEÅ' },
  { postalCode: '90355', city: 'UMEÅ' },
  { postalCode: '90360', city: 'UMEÅ' },
  { postalCode: '90361', city: 'UMEÅ' },
  { postalCode: '90362', city: 'UMEÅ' },
  { postalCode: '90363', city: 'UMEÅ' },
  { postalCode: '90364', city: 'UMEÅ' },
  { postalCode: '90365', city: 'UMEÅ' },
  { postalCode: '90400', city: 'UMEÅ' },
  { postalCode: '90402', city: 'UMEÅ' },
  { postalCode: '90403', city: 'UMEÅ' },
  { postalCode: '90404', city: 'UMEÅ' },
  { postalCode: '90405', city: 'UMEÅ' },
  { postalCode: '90406', city: 'UMEÅ' },
  { postalCode: '90420', city: 'UMEÅ' },
  { postalCode: '90421', city: 'UMEÅ' },
  { postalCode: '90422', city: 'UMEÅ' },
  { postalCode: '90426', city: 'UMEÅ' },
  { postalCode: '90431', city: 'UMEÅ' },
  { postalCode: '90432', city: 'UMEÅ' },
  { postalCode: '90433', city: 'UMEÅ' },
  { postalCode: '90434', city: 'UMEÅ' },
  { postalCode: '90435', city: 'UMEÅ' },
  { postalCode: '90436', city: 'UMEÅ' },
  { postalCode: '90440', city: 'RÖBÄCK' },
  { postalCode: '90441', city: 'RÖBÄCK' },
  { postalCode: '90500', city: 'UMEÅ' },
  { postalCode: '90521', city: 'HÖRNEFORS' },
  { postalCode: '90531', city: 'HÖRNEFORS' },
  { postalCode: '90532', city: 'HÖRNEFORS' },
  { postalCode: '90571', city: 'HÖRNEFORS' },
  { postalCode: '90572', city: 'HÖRNEFORS' },
  { postalCode: '90580', city: 'UMEÅ' },
  { postalCode: '90581', city: 'UMEÅ' },
  { postalCode: '90582', city: 'UMEÅ' },
  { postalCode: '90583', city: 'UMEÅ' },
  { postalCode: '90586', city: 'UMEÅ' },
  { postalCode: '90587', city: 'UMEÅ' },
  { postalCode: '90588', city: 'UMEÅ' },
  { postalCode: '90591', city: 'UMEÅ' },
  { postalCode: '90592', city: 'UMEÅ' },
  { postalCode: '90593', city: 'UMEÅ' },
  { postalCode: '90594', city: 'UMEÅ' },
  { postalCode: '90595', city: 'UMEÅ' },
  { postalCode: '90596', city: 'UMEÅ' },
  { postalCode: '90600', city: 'UMEÅ' },
  { postalCode: '90603', city: 'UMEÅ' },
  { postalCode: '90620', city: 'UMEÅ' },
  { postalCode: '90621', city: 'UMEÅ' },
  { postalCode: '90622', city: 'UMEÅ' },
  { postalCode: '90624', city: 'UMEÅ' },
  { postalCode: '90625', city: 'UMEÅ' },
  { postalCode: '90626', city: 'UMEÅ' },
  { postalCode: '90627', city: 'UMEÅ' },
  { postalCode: '90628', city: 'UMEÅ' },
  { postalCode: '90629', city: 'UMEÅ' },
  { postalCode: '90630', city: 'UMEÅ' },
  { postalCode: '90637', city: 'UMEÅ' },
  { postalCode: '90638', city: 'UMEÅ' },
  { postalCode: '90640', city: 'UMEÅ' },
  { postalCode: '90641', city: 'UMEÅ' },
  { postalCode: '90642', city: 'UMEÅ' },
  { postalCode: '90643', city: 'UMEÅ' },
  { postalCode: '90650', city: 'UMEÅ' },
  { postalCode: '90651', city: 'UMEÅ' },
  { postalCode: '90652', city: 'UMEÅ' },
  { postalCode: '90653', city: 'UMEÅ' },
  { postalCode: '90654', city: 'UMEÅ' },
  { postalCode: '90655', city: 'UMEÅ' },
  { postalCode: '90656', city: 'UMEÅ' },
  { postalCode: '90660', city: 'UMEÅ' },
  { postalCode: '90700', city: 'UMEÅ' },
  { postalCode: '90702', city: 'UMEÅ' },
  { postalCode: '90703', city: 'UMEÅ' },
  { postalCode: '90704', city: 'UMEÅ' },
  { postalCode: '90712', city: 'UMEÅ' },
  { postalCode: '90713', city: 'UMEÅ' },
  { postalCode: '90719', city: 'UMEÅ' },
  { postalCode: '90727', city: 'UMEÅ' },
  { postalCode: '90728', city: 'UMEÅ' },
  { postalCode: '90729', city: 'UMEÅ' },
  { postalCode: '90730', city: 'UMEÅ' },
  { postalCode: '90731', city: 'UMEÅ' },
  { postalCode: '90732', city: 'UMEÅ' },
  { postalCode: '90733', city: 'UMEÅ' },
  { postalCode: '90734', city: 'UMEÅ' },
  { postalCode: '90735', city: 'UMEÅ' },
  { postalCode: '90736', city: 'UMEÅ' },
  { postalCode: '90737', city: 'UMEÅ' },
  { postalCode: '90738', city: 'UMEÅ' },
  { postalCode: '90739', city: 'UMEÅ' },
  { postalCode: '90740', city: 'UMEÅ' },
  { postalCode: '90741', city: 'UMEÅ' },
  { postalCode: '90742', city: 'UMEÅ' },
  { postalCode: '90743', city: 'UMEÅ' },
  { postalCode: '90746', city: 'UMEÅ' },
  { postalCode: '90749', city: 'UMEÅ' },
  { postalCode: '90750', city: 'UMEÅ' },
  { postalCode: '90751', city: 'UMEÅ' },
  { postalCode: '90752', city: 'UMEÅ' },
  { postalCode: '90753', city: 'UMEÅ' },
  { postalCode: '90754', city: 'UMEÅ' },
  { postalCode: '90755', city: 'UMEÅ' },
  { postalCode: '90788', city: 'TÄFTEÅ' },
  { postalCode: '90801', city: 'UMEÅ' },
  { postalCode: '90804', city: 'UMEÅ' },
  { postalCode: '90806', city: 'UMEÅ' },
  { postalCode: '90807', city: 'UMEÅ' },
  { postalCode: '90810', city: 'UMEÅ' },
  { postalCode: '90811', city: 'UMEÅ' },
  { postalCode: '90812', city: 'UMEÅ' },
  { postalCode: '90814', city: 'UMEÅ' },
  { postalCode: '90815', city: 'UMEÅ' },
  { postalCode: '90850', city: 'UMEÅ' },
  { postalCode: '91088', city: 'MARSFJÄLL' },
  { postalCode: '91101', city: 'VÄNNÄS' },
  { postalCode: '91120', city: 'VÄNNÄS' },
  { postalCode: '91121', city: 'VÄNNÄS' },
  { postalCode: '91130', city: 'VÄNNÄS' },
  { postalCode: '91131', city: 'VÄNNÄS' },
  { postalCode: '91132', city: 'VÄNNÄS' },
  { postalCode: '91133', city: 'VÄNNÄS' },
  { postalCode: '91134', city: 'VÄNNÄS' },
  { postalCode: '91135', city: 'VÄNNÄSBY' },
  { postalCode: '91136', city: 'VÄNNÄSBY' },
  { postalCode: '91181', city: 'VÄNNÄS' },
  { postalCode: '91182', city: 'VÄNNÄS' },
  { postalCode: '91191', city: 'VÄNNÄS' },
  { postalCode: '91192', city: 'VÄNNÄS' },
  { postalCode: '91193', city: 'VÄNNÄS' },
  { postalCode: '91194', city: 'VÄNNÄS' },
  { postalCode: '91201', city: 'VILHELMINA' },
  { postalCode: '91220', city: 'VILHELMINA' },
  { postalCode: '91221', city: 'VILHELMINA' },
  { postalCode: '91222', city: 'VILHELMINA' },
  { postalCode: '91223', city: 'VILHELMINA' },
  { postalCode: '91224', city: 'VILHELMINA' },
  { postalCode: '91231', city: 'VILHELMINA' },
  { postalCode: '91232', city: 'VILHELMINA' },
  { postalCode: '91233', city: 'VILHELMINA' },
  { postalCode: '91234', city: 'VILHELMINA' },
  { postalCode: '91281', city: 'VILHELMINA' },
  { postalCode: '91290', city: 'VILHELMINA' },
  { postalCode: '91291', city: 'VILHELMINA' },
  { postalCode: '91292', city: 'VILHELMINA' },
  { postalCode: '91293', city: 'VILHELMINA' },
  { postalCode: '91294', city: 'LATIKBERG' },
  { postalCode: '91295', city: 'STORSELEBY' },
  { postalCode: '91297', city: 'MALGOMAJ' },
  { postalCode: '91298', city: 'KLIMPFJÄLL' },
  { postalCode: '91299', city: 'VILHELMINA' },
  { postalCode: '91301', city: 'HOLMSUND' },
  { postalCode: '91320', city: 'HOLMSUND' },
  { postalCode: '91331', city: 'HOLMSUND' },
  { postalCode: '91332', city: 'HOLMSUND' },
  { postalCode: '91333', city: 'HOLMSUND' },
  { postalCode: '91334', city: 'HOLMSUND' },
  { postalCode: '91335', city: 'HOLMSUND' },
  { postalCode: '91341', city: 'OBBOLA' },
  { postalCode: '91342', city: 'OBBOLA' },
  { postalCode: '91380', city: 'OBBOLA' },
  { postalCode: '91401', city: 'NORDMALING' },
  { postalCode: '91420', city: 'NORDMALING' },
  { postalCode: '91421', city: 'NORDMALING' },
  { postalCode: '91422', city: 'NORDMALING' },
  { postalCode: '91429', city: 'RUNDVIK' },
  { postalCode: '91431', city: 'NORDMALING' },
  { postalCode: '91432', city: 'NORDMALING' },
  { postalCode: '91433', city: 'NORDMALING' },
  { postalCode: '91441', city: 'RUNDVIK' },
  { postalCode: '91481', city: 'NORDMALING' },
  { postalCode: '91490', city: 'NORDMALING' },
  { postalCode: '91491', city: 'NORDMALING' },
  { postalCode: '91492', city: 'LÖGDEÅ' },
  { postalCode: '91493', city: 'NORRFORS' },
  { postalCode: '91494', city: 'NYÅKER' },
  { postalCode: '91495', city: 'GRÄSMYR' },
  { postalCode: '91496', city: 'HÅKNÄS' },
  { postalCode: '91497', city: 'HÖRNSJÖ' },
  { postalCode: '91501', city: 'ROBERTSFORS' },
  { postalCode: '91520', city: 'ROBERTSFORS' },
  { postalCode: '91521', city: 'ROBERTSFORS' },
  { postalCode: '91523', city: 'ROBERTSFORS' },
  { postalCode: '91531', city: 'ROBERTSFORS' },
  { postalCode: '91532', city: 'ROBERTSFORS' },
  { postalCode: '91534', city: 'ÅNÄSET' },
  { postalCode: '91581', city: 'ROBERTSFORS' },
  { postalCode: '91591', city: 'ROBERTSFORS' },
  { postalCode: '91592', city: 'ROBERTSFORS' },
  { postalCode: '91593', city: 'ROBERTSFORS' },
  { postalCode: '91594', city: 'ÅNÄSET' },
  { postalCode: '91595', city: 'ÅNÄSET' },
  { postalCode: '91596', city: 'FLARKEN' },
  { postalCode: '91597', city: 'BYGDEÅ' },
  { postalCode: '91598', city: 'BYGDEÅ' },
  { postalCode: '91601', city: 'BJURHOLM' },
  { postalCode: '91620', city: 'BJURHOLM' },
  { postalCode: '91621', city: 'BJURHOLM' },
  { postalCode: '91631', city: 'BJURHOLM' },
  { postalCode: '91681', city: 'BJURHOLM' },
  { postalCode: '91691', city: 'BJURHOLM' },
  { postalCode: '91692', city: 'BJURHOLM' },
  { postalCode: '91693', city: 'BJURHOLM' },
  { postalCode: '91695', city: 'ÖRTRÄSK' },
  { postalCode: '91701', city: 'DOROTEA' },
  { postalCode: '91702', city: 'HÖGLAND' },
  { postalCode: '91703', city: 'RISBÄCK' },
  { postalCode: '91704', city: 'BORGAFJÄLL' },
  { postalCode: '91720', city: 'DOROTEA' },
  { postalCode: '91721', city: 'DOROTEA' },
  { postalCode: '91722', city: 'DOROTEA' },
  { postalCode: '91731', city: 'DOROTEA' },
  { postalCode: '91732', city: 'DOROTEA' },
  { postalCode: '91781', city: 'DOROTEA' },
  { postalCode: '91782', city: 'DOROTEA' },
  { postalCode: '91787', city: 'DOROTEA' },
  { postalCode: '91791', city: 'DOROTEA' },
  { postalCode: '91793', city: 'LAJKSJÖBERG' },
  { postalCode: '91794', city: 'LAVSJÖN' },
  { postalCode: '91795', city: 'SVANAVATTNET' },
  { postalCode: '91799', city: 'DOROTEA' },
  { postalCode: '91801', city: 'SÄVAR' },
  { postalCode: '91803', city: 'HOLMÖN' },
  { postalCode: '91820', city: 'SÄVAR' },
  { postalCode: '91821', city: 'SÄVAR' },
  { postalCode: '91831', city: 'SÄVAR' },
  { postalCode: '91832', city: 'SÄVAR' },
  { postalCode: '91891', city: 'SÄVAR' },
  { postalCode: '91892', city: 'BULLMARK' },
  { postalCode: '91901', city: 'ÅSELE' },
  { postalCode: '91920', city: 'ÅSELE' },
  { postalCode: '91921', city: 'ÅSELE' },
  { postalCode: '91931', city: 'ÅSELE' },
  { postalCode: '91932', city: 'ÅSELE' },
  { postalCode: '91985', city: 'ÅSELE' },
  { postalCode: '91991', city: 'ÅSELE' },
  { postalCode: '91992', city: 'ÅSELE' },
  { postalCode: '91993', city: 'FREDRIKA' },
  { postalCode: '91994', city: 'HÄLLASTRÖM' },
  { postalCode: '91995', city: 'LÅNGBÄCKEN' },
  { postalCode: '92034', city: 'VORMSELE' },
  { postalCode: '92038', city: 'KRISTINEBERG' },
  { postalCode: '92039', city: 'RÖKÅ' },
  { postalCode: '92040', city: 'KRISTINEBERG' },
  { postalCode: '92041', city: 'BJÖRKSELE' },
  { postalCode: '92042', city: 'VINDELGRANSELE' },
  { postalCode: '92051', city: 'GUNNARN' },
  { postalCode: '92064', city: 'TÄRNABY' },
  { postalCode: '92066', city: 'HEMAVAN' },
  { postalCode: '92101', city: 'LYCKSELE' },
  { postalCode: '92120', city: 'LYCKSELE' },
  { postalCode: '92121', city: 'LYCKSELE' },
  { postalCode: '92122', city: 'LYCKSELE' },
  { postalCode: '92123', city: 'LYCKSELE' },
  { postalCode: '92124', city: 'LYCKSELE' },
  { postalCode: '92128', city: 'LYCKSELE' },
  { postalCode: '92129', city: 'HORNMYR' },
  { postalCode: '92131', city: 'LYCKSELE' },
  { postalCode: '92132', city: 'LYCKSELE' },
  { postalCode: '92133', city: 'LYCKSELE' },
  { postalCode: '92134', city: 'LYCKSELE' },
  { postalCode: '92135', city: 'LYCKSELE' },
  { postalCode: '92136', city: 'LYCKSELE' },
  { postalCode: '92137', city: 'LYCKSELE' },
  { postalCode: '92141', city: 'LYCKSELE' },
  { postalCode: '92142', city: 'LYCKSELE' },
  { postalCode: '92145', city: 'LYCKSELE' },
  { postalCode: '92146', city: 'LYCKSELE' },
  { postalCode: '92181', city: 'LYCKSELE' },
  { postalCode: '92182', city: 'LYCKSELE' },
  { postalCode: '92191', city: 'LYCKSELE' },
  { postalCode: '92192', city: 'LYCKSELE' },
  { postalCode: '92193', city: 'LYCKSELE' },
  { postalCode: '92194', city: 'RUSKSELE' },
  { postalCode: '92195', city: 'BLÅVIKSJÖN' },
  { postalCode: '92199', city: 'LYCKSELE' },
  { postalCode: '92201', city: 'VINDELN' },
  { postalCode: '92220', city: 'VINDELN' },
  { postalCode: '92221', city: 'VINDELN' },
  { postalCode: '92231', city: 'VINDELN' },
  { postalCode: '92232', city: 'VINDELN' },
  { postalCode: '92261', city: 'TAVELSJÖ' },
  { postalCode: '92262', city: 'TAVELSJÖ' },
  { postalCode: '92263', city: 'TAVELSJÖ' },
  { postalCode: '92266', city: 'TAVELSJÖ' },
  { postalCode: '92267', city: 'TAVELSJÖ' },
  { postalCode: '92273', city: 'HÄLLNÄS' },
  { postalCode: '92275', city: 'ÅMSELE' },
  { postalCode: '92276', city: 'BOTSMARK' },
  { postalCode: '92277', city: 'BOTSMARK' },
  { postalCode: '92278', city: 'BOTSMARK' },
  { postalCode: '92281', city: 'VINDELN' },
  { postalCode: '92282', city: 'VINDELN' },
  { postalCode: '92291', city: 'VINDELN' },
  { postalCode: '92292', city: 'VINDELN' },
  { postalCode: '92293', city: 'VINDELN' },
  { postalCode: '92294', city: 'TVÄRÅLUND' },
  { postalCode: '92295', city: 'GRANÖ' },
  { postalCode: '92301', city: 'STORUMAN' },
  { postalCode: '92320', city: 'STORUMAN' },
  { postalCode: '92321', city: 'STORUMAN' },
  { postalCode: '92322', city: 'STORUMAN' },
  { postalCode: '92323', city: 'STORUMAN' },
  { postalCode: '92324', city: 'STORUMAN' },
  { postalCode: '92331', city: 'STORUMAN' },
  { postalCode: '92332', city: 'STORUMAN' },
  { postalCode: '92341', city: 'STENSELE' },
  { postalCode: '92381', city: 'STORUMAN' },
  { postalCode: '92394', city: 'DIKANÄS' },
  { postalCode: '92395', city: 'KITTELFJÄLL' },
  { postalCode: '92396', city: 'BARSELE' },
  { postalCode: '92397', city: 'SLUSSFORS' },
  { postalCode: '92398', city: 'STORUMAN' },
  { postalCode: '92399', city: 'STORUMAN' },
  { postalCode: '92401', city: 'SORSELE' },
  { postalCode: '92420', city: 'SORSELE' },
  { postalCode: '92421', city: 'SORSELE' },
  { postalCode: '92422', city: 'AMMARNÄS' },
  { postalCode: '92431', city: 'SORSELE' },
  { postalCode: '92432', city: 'SORSELE' },
  { postalCode: '92481', city: 'SORSELE' },
  { postalCode: '92492', city: 'BLATTNICKSELE' },
  { postalCode: '92493', city: 'GARGNÄS' },
  { postalCode: '92494', city: 'SORSELE' },
  { postalCode: '92495', city: 'AMMARNÄS' },
  { postalCode: '93027', city: 'KALVTRÄSK' },
  { postalCode: '93061', city: 'BASTUTRÄSK' },
  { postalCode: '93081', city: 'GLOMMERSTRÄSK' },
  { postalCode: '93082', city: 'ABBORRTRÄSK' },
  { postalCode: '93085', city: 'ARJEPLOG' },
  { postalCode: '93086', city: 'MOSKOSEL' },
  { postalCode: '93087', city: 'ARJEPLOG' },
  { postalCode: '93088', city: 'ARJEPLOG' },
  { postalCode: '93089', city: 'ARJEPLOG' },
  { postalCode: '93091', city: 'SLAGNÄS' },
  { postalCode: '93100', city: 'SKELLEFTEÅ' },
  { postalCode: '93102', city: 'SKELLEFTEÅ' },
  { postalCode: '93120', city: 'SKELLEFTEÅ' },
  { postalCode: '93121', city: 'SKELLEFTEÅ' },
  { postalCode: '93122', city: 'SKELLEFTEÅ' },
  { postalCode: '93123', city: 'SKELLEFTEÅ' },
  { postalCode: '93124', city: 'SKELLEFTEÅ' },
  { postalCode: '93127', city: 'SKELLEFTEÅ' },
  { postalCode: '93128', city: 'SKELLEFTEÅ' },
  { postalCode: '93130', city: 'SKELLEFTEÅ' },
  { postalCode: '93131', city: 'SKELLEFTEÅ' },
  { postalCode: '93132', city: 'SKELLEFTEÅ' },
  { postalCode: '93133', city: 'SKELLEFTEÅ' },
  { postalCode: '93134', city: 'SKELLEFTEÅ' },
  { postalCode: '93135', city: 'SKELLEFTEÅ' },
  { postalCode: '93136', city: 'SKELLEFTEÅ' },
  { postalCode: '93137', city: 'SKELLEFTEÅ' },
  { postalCode: '93138', city: 'SKELLEFTEÅ' },
  { postalCode: '93139', city: 'SKELLEFTEÅ' },
  { postalCode: '93140', city: 'SKELLEFTEÅ' },
  { postalCode: '93141', city: 'SKELLEFTEÅ' },
  { postalCode: '93142', city: 'SKELLEFTEÅ' },
  { postalCode: '93144', city: 'SKELLEFTEÅ' },
  { postalCode: '93145', city: 'SKELLEFTEÅ' },
  { postalCode: '93146', city: 'SKELLEFTEÅ' },
  { postalCode: '93148', city: 'SKELLEFTEÅ' },
  { postalCode: '93149', city: 'SKELLEFTEÅ' },
  { postalCode: '93150', city: 'SKELLEFTEÅ' },
  { postalCode: '93151', city: 'SKELLEFTEÅ' },
  { postalCode: '93152', city: 'SKELLEFTEÅ' },
  { postalCode: '93153', city: 'SKELLEFTEÅ' },
  { postalCode: '93154', city: 'SKELLEFTEÅ' },
  { postalCode: '93155', city: 'SKELLEFTEÅ' },
  { postalCode: '93156', city: 'SKELLEFTEÅ' },
  { postalCode: '93157', city: 'SKELLEFTEÅ' },
  { postalCode: '93158', city: 'SKELLEFTEÅ' },
  { postalCode: '93161', city: 'SKELLEFTEÅ' },
  { postalCode: '93162', city: 'SKELLEFTEÅ' },
  { postalCode: '93163', city: 'SKELLEFTEÅ' },
  { postalCode: '93164', city: 'SKELLEFTEÅ' },
  { postalCode: '93165', city: 'SKELLEFTEÅ' },
  { postalCode: '93170', city: 'SKELLEFTEÅ' },
  { postalCode: '93176', city: 'SKELLEFTEÅ' },
  { postalCode: '93177', city: 'SKELLEFTEÅ' },
  { postalCode: '93178', city: 'SKELLEFTEÅ' },
  { postalCode: '93180', city: 'SKELLEFTEÅ' },
  { postalCode: '93185', city: 'SKELLEFTEÅ' },
  { postalCode: '93186', city: 'SKELLEFTEÅ' },
  { postalCode: '93187', city: 'SKELLEFTEÅ' },
  { postalCode: '93191', city: 'SKELLEFTEÅ' },
  { postalCode: '93192', city: 'SKELLEFTEÅ' },
  { postalCode: '93193', city: 'SKELLEFTEÅ' },
  { postalCode: '93194', city: 'SKELLEFTEÅ' },
  { postalCode: '93195', city: 'SKELLEFTEÅ' },
  { postalCode: '93196', city: 'SKELLEFTEÅ' },
  { postalCode: '93197', city: 'SKELLEFTEÅ' },
  { postalCode: '93198', city: 'SKELLEFTEÅ' },
  { postalCode: '93199', city: 'SKELLEFTEÅ' },
  { postalCode: '93201', city: 'SKELLEFTEHAMN' },
  { postalCode: '93220', city: 'SKELLEFTEHAMN' },
  { postalCode: '93221', city: 'SKELLEFTEHAMN' },
  { postalCode: '93223', city: 'BUREÅ' },
  { postalCode: '93231', city: 'SKELLEFTEHAMN' },
  { postalCode: '93232', city: 'SKELLEFTEHAMN' },
  { postalCode: '93233', city: 'SKELLEFTEHAMN' },
  { postalCode: '93234', city: 'ÖRVIKEN' },
  { postalCode: '93235', city: 'URSVIKEN' },
  { postalCode: '93236', city: 'URSVIKEN' },
  { postalCode: '93237', city: 'URSVIKEN' },
  { postalCode: '93251', city: 'BUREÅ' },
  { postalCode: '93252', city: 'BUREÅ' },
  { postalCode: '93261', city: 'LÖVÅNGER' },
  { postalCode: '93281', city: 'SKELLEFTEHAMN' },
  { postalCode: '93282', city: 'URSVIKEN' },
  { postalCode: '93291', city: 'BUREÅ' },
  { postalCode: '93293', city: 'LÖVÅNGER' },
  { postalCode: '93294', city: 'LÖVÅNGER' },
  { postalCode: '93300', city: 'ARVIDSJAUR' },
  { postalCode: '93301', city: 'ARVIDSJAUR' },
  { postalCode: '93320', city: 'ARVIDSJAUR' },
  { postalCode: '93321', city: 'ARVIDSJAUR' },
  { postalCode: '93322', city: 'ARVIDSJAUR' },
  { postalCode: '93327', city: 'ARVIDSJAUR' },
  { postalCode: '93331', city: 'ARVIDSJAUR' },
  { postalCode: '93332', city: 'ARVIDSJAUR' },
  { postalCode: '93333', city: 'ARVIDSJAUR' },
  { postalCode: '93334', city: 'ARVIDSJAUR' },
  { postalCode: '93381', city: 'ARVIDSJAUR' },
  { postalCode: '93382', city: 'ARVIDSJAUR' },
  { postalCode: '93383', city: 'ARVIDSJAUR' },
  { postalCode: '93391', city: 'ARVIDSJAUR' },
  { postalCode: '93399', city: 'ARVIDSJAUR' },
  { postalCode: '93401', city: 'KÅGE' },
  { postalCode: '93420', city: 'KÅGE' },
  { postalCode: '93421', city: 'KÅGE' },
  { postalCode: '93424', city: 'BYSKE' },
  { postalCode: '93431', city: 'KÅGE' },
  { postalCode: '93432', city: 'KÅGE' },
  { postalCode: '93441', city: 'ERSMARK' },
  { postalCode: '93451', city: 'BYSKE' },
  { postalCode: '93452', city: 'BYSKE' },
  { postalCode: '93461', city: 'FÄLLFORS' },
  { postalCode: '93481', city: 'ERSMARK' },
  { postalCode: '93482', city: 'ERSMARK' },
  { postalCode: '93491', city: 'KÅGE' },
  { postalCode: '93492', city: 'KÅGE' },
  { postalCode: '93493', city: 'KUSMARK' },
  { postalCode: '93494', city: 'KÅGE' },
  { postalCode: '93495', city: 'KÅGE' },
  { postalCode: '93496', city: 'KÅGE' },
  { postalCode: '93497', city: 'BYSKE' },
  { postalCode: '93498', city: 'BYSKE' },
  { postalCode: '93501', city: 'NORSJÖ' },
  { postalCode: '93520', city: 'NORSJÖ' },
  { postalCode: '93521', city: 'NORSJÖ' },
  { postalCode: '93531', city: 'NORSJÖ' },
  { postalCode: '93532', city: 'NORSJÖ' },
  { postalCode: '93581', city: 'NORSJÖ' },
  { postalCode: '93591', city: 'NORSJÖ' },
  { postalCode: '93592', city: 'NORSJÖ' },
  { postalCode: '93593', city: 'NORSJÖ' },
  { postalCode: '93601', city: 'BOLIDEN' },
  { postalCode: '93620', city: 'BOLIDEN' },
  { postalCode: '93621', city: 'BOLIDEN' },
  { postalCode: '93631', city: 'BOLIDEN' },
  { postalCode: '93632', city: 'BOLIDEN' },
  { postalCode: '93651', city: 'JÖRN' },
  { postalCode: '93681', city: 'BOLIDEN' },
  { postalCode: '93691', city: 'BOLIDEN' },
  { postalCode: '93692', city: 'BOLIDEN' },
  { postalCode: '93693', city: 'BOLIDEN' },
  { postalCode: '93694', city: 'JÖRN' },
  { postalCode: '93695', city: 'JÖRN' },
  { postalCode: '93701', city: 'BURTRÄSK' },
  { postalCode: '93720', city: 'BURTRÄSK' },
  { postalCode: '93721', city: 'BURTRÄSK' },
  { postalCode: '93731', city: 'BURTRÄSK' },
  { postalCode: '93732', city: 'BURTRÄSK' },
  { postalCode: '93733', city: 'BYGDSILJUM' },
  { postalCode: '93780', city: 'BYGDSILJUM' },
  { postalCode: '93791', city: 'BURTRÄSK' },
  { postalCode: '93792', city: 'BURTRÄSK' },
  { postalCode: '93793', city: 'BURTRÄSK' },
  { postalCode: '93794', city: 'BURTRÄSK' },
  { postalCode: '93795', city: 'BURTRÄSK' },
  { postalCode: '93796', city: 'BYGDSILJUM' },
  { postalCode: '93801', city: 'ARJEPLOG' },
  { postalCode: '93819', city: 'ARJEPLOG' },
  { postalCode: '93820', city: 'ARJEPLOG' },
  { postalCode: '93821', city: 'ARJEPLOG' },
  { postalCode: '93831', city: 'ARJEPLOG' },
  { postalCode: '93832', city: 'ARJEPLOG' },
  { postalCode: '93881', city: 'ARJEPLOG' },
  { postalCode: '93892', city: 'MELLANSTRÖM' },
  { postalCode: '93893', city: 'LAISVALL' },
  { postalCode: '93894', city: 'ARJEPLOG' },
  { postalCode: '93895', city: 'JÄCKVIK' },
  { postalCode: '93896', city: 'ARJEPLOG' },
  { postalCode: '93920', city: 'MALÅ' },
  { postalCode: '93921', city: 'MALÅ' },
  { postalCode: '93931', city: 'MALÅ' },
  { postalCode: '93932', city: 'MALÅ' },
  { postalCode: '93981', city: 'MALÅ' },
  { postalCode: '93991', city: 'RENTJÄRN' },
  { postalCode: '93992', city: 'ADAK' },
  { postalCode: '93993', city: 'MALÅ' },
  { postalCode: '93994', city: 'MALÅ' },
  { postalCode: '93995', city: 'MALÅ' },
  { postalCode: '94101', city: 'PITEÅ' },
  { postalCode: '94117', city: 'PITEÅ' },
  { postalCode: '94119', city: 'PITEÅ' },
  { postalCode: '94120', city: 'PITEÅ' },
  { postalCode: '94121', city: 'PITEÅ' },
  { postalCode: '94122', city: 'PITEÅ' },
  { postalCode: '94123', city: 'PITEÅ' },
  { postalCode: '94124', city: 'PITEÅ' },
  { postalCode: '94125', city: 'PITEÅ' },
  { postalCode: '94126', city: 'PITEÅ' },
  { postalCode: '94128', city: 'PITEÅ' },
  { postalCode: '94131', city: 'PITEÅ' },
  { postalCode: '94132', city: 'PITEÅ' },
  { postalCode: '94133', city: 'PITEÅ' },
  { postalCode: '94134', city: 'PITEÅ' },
  { postalCode: '94135', city: 'PITEÅ' },
  { postalCode: '94136', city: 'PITEÅ' },
  { postalCode: '94137', city: 'PITEÅ' },
  { postalCode: '94138', city: 'PITEÅ' },
  { postalCode: '94139', city: 'PITEÅ' },
  { postalCode: '94140', city: 'PITEÅ' },
  { postalCode: '94141', city: 'PITEÅ' },
  { postalCode: '94142', city: 'PITEÅ' },
  { postalCode: '94143', city: 'PITEÅ' },
  { postalCode: '94146', city: 'PITEÅ' },
  { postalCode: '94147', city: 'PITEÅ' },
  { postalCode: '94148', city: 'PITEÅ' },
  { postalCode: '94150', city: 'PITEÅ' },
  { postalCode: '94151', city: 'PITEÅ' },
  { postalCode: '94152', city: 'PITEÅ' },
  { postalCode: '94153', city: 'PITEÅ' },
  { postalCode: '94161', city: 'PITEÅ' },
  { postalCode: '94162', city: 'PITEÅ' },
  { postalCode: '94163', city: 'PITEÅ' },
  { postalCode: '94164', city: 'PITEÅ' },
  { postalCode: '94165', city: 'PITEÅ' },
  { postalCode: '94166', city: 'PITEÅ' },
  { postalCode: '94180', city: 'PITEÅ' },
  { postalCode: '94185', city: 'PITEÅ' },
  { postalCode: '94186', city: 'PITEÅ' },
  { postalCode: '94187', city: 'PITEÅ' },
  { postalCode: '94191', city: 'PITEÅ' },
  { postalCode: '94192', city: 'ARNEMARK' },
  { postalCode: '94199', city: 'PITEÅ' },
  { postalCode: '94201', city: 'ÄLVSBYN' },
  { postalCode: '94203', city: 'KOLER' },
  { postalCode: '94204', city: 'STORSUND' },
  { postalCode: '94205', city: 'LÅNGTRÄSK' },
  { postalCode: '94220', city: 'ÄLVSBYN' },
  { postalCode: '94221', city: 'ÄLVSBYN' },
  { postalCode: '94222', city: 'ÄLVSBYN' },
  { postalCode: '94231', city: 'ÄLVSBYN' },
  { postalCode: '94232', city: 'ÄLVSBYN' },
  { postalCode: '94233', city: 'ÄLVSBYN' },
  { postalCode: '94234', city: 'ÄLVSBYN' },
  { postalCode: '94235', city: 'ÄLVSBYN' },
  { postalCode: '94236', city: 'ÄLVSBYN' },
  { postalCode: '94281', city: 'ÄLVSBYN' },
  { postalCode: '94282', city: 'ÄLVSBYN' },
  { postalCode: '94285', city: 'ÄLVSBYN' },
  { postalCode: '94291', city: 'VISTTRÄSK' },
  { postalCode: '94292', city: 'ÄLVSBYN' },
  { postalCode: '94293', city: 'ÄLVSBYN' },
  { postalCode: '94294', city: 'SIKFORS' },
  { postalCode: '94295', city: 'VIDSEL' },
  { postalCode: '94301', city: 'ÖJEBYN' },
  { postalCode: '94320', city: 'ÖJEBYN' },
  { postalCode: '94321', city: 'ÖJEBYN' },
  { postalCode: '94322', city: 'ÖJEBYN' },
  { postalCode: '94323', city: 'ÖJEBYN' },
  { postalCode: '94328', city: 'ÖJEBYN' },
  { postalCode: '94331', city: 'ÖJEBYN' },
  { postalCode: '94332', city: 'ÖJEBYN' },
  { postalCode: '94333', city: 'ÖJEBYN' },
  { postalCode: '94335', city: 'ÖJEBYN' },
  { postalCode: '94336', city: 'ÖJEBYN' },
  { postalCode: '94431', city: 'HORTLAX' },
  { postalCode: '94432', city: 'HORTLAX' },
  { postalCode: '94471', city: 'PITEÅ' },
  { postalCode: '94472', city: 'PITEÅ' },
  { postalCode: '94473', city: 'PITEÅ' },
  { postalCode: '94491', city: 'HORTLAX' },
  { postalCode: '94492', city: 'BLÅSMARK' },
  { postalCode: '94493', city: 'HEMMINGSMARK' },
  { postalCode: '94494', city: 'JÄVREBYN' },
  { postalCode: '94521', city: 'NORRFJÄRDEN' },
  { postalCode: '94523', city: 'ROSVIK' },
  { postalCode: '94531', city: 'NORRFJÄRDEN' },
  { postalCode: '94532', city: 'NORRFJÄRDEN' },
  { postalCode: '94533', city: 'ROSVIK' },
  { postalCode: '94534', city: 'ROSVIK' },
  { postalCode: '94591', city: 'NORRFJÄRDEN' },
  { postalCode: '94592', city: 'ALTERSBRUK' },
  { postalCode: '94593', city: 'SJULSMARK' },
  { postalCode: '94631', city: 'ROKNÄS' },
  { postalCode: '94640', city: 'SVENSBYN' },
  { postalCode: '94691', city: 'ROKNÄS' },
  { postalCode: '94692', city: 'LILLPITE' },
  { postalCode: '94699', city: 'ROKNÄS' },
  { postalCode: '95042', city: 'MORJÄRV' },
  { postalCode: '95200', city: 'KALIX' },
  { postalCode: '95204', city: 'PÅLÄNG' },
  { postalCode: '95220', city: 'KALIX' },
  { postalCode: '95221', city: 'KALIX' },
  { postalCode: '95222', city: 'KALIX' },
  { postalCode: '95223', city: 'KALIX' },
  { postalCode: '95227', city: 'KALIX' },
  { postalCode: '95231', city: 'KALIX' },
  { postalCode: '95232', city: 'KALIX' },
  { postalCode: '95233', city: 'KALIX' },
  { postalCode: '95234', city: 'KALIX' },
  { postalCode: '95235', city: 'KALIX' },
  { postalCode: '95236', city: 'KALIX' },
  { postalCode: '95241', city: 'KALIX' },
  { postalCode: '95242', city: 'KALIX' },
  { postalCode: '95243', city: 'TÖRE' },
  { postalCode: '95244', city: 'TÖRE' },
  { postalCode: '95250', city: 'KALIX-NYBORG' },
  { postalCode: '95251', city: 'KALIX' },
  { postalCode: '95261', city: 'KALIX' },
  { postalCode: '95262', city: 'KALIX' },
  { postalCode: '95263', city: 'KALIX' },
  { postalCode: '95270', city: 'RISÖGRUND' },
  { postalCode: '95271', city: 'KARLSBORGSVERKEN' },
  { postalCode: '95272', city: 'SANGIS' },
  { postalCode: '95281', city: 'KALIX' },
  { postalCode: '95282', city: 'KALIX' },
  { postalCode: '95283', city: 'KARLSBORGSVERKEN' },
  { postalCode: '95291', city: 'KALIX' },
  { postalCode: '95292', city: 'KALIX' },
  { postalCode: '95293', city: 'KALIX' },
  { postalCode: '95294', city: 'KALIX' },
  { postalCode: '95295', city: 'BÅTSKÄRSNÄS' },
  { postalCode: '95296', city: 'LAPPTRÄSK' },
  { postalCode: '95297', city: 'KALIX' },
  { postalCode: '95301', city: 'HAPARANDA' },
  { postalCode: '95320', city: 'HAPARANDA' },
  { postalCode: '95321', city: 'HAPARANDA' },
  { postalCode: '95322', city: 'HAPARANDA' },
  { postalCode: '95323', city: 'HAPARANDA' },
  { postalCode: '95324', city: 'HAPARANDA' },
  { postalCode: '95328', city: 'HAPARANDA' },
  { postalCode: '95331', city: 'HAPARANDA' },
  { postalCode: '95332', city: 'HAPARANDA' },
  { postalCode: '95333', city: 'HAPARANDA' },
  { postalCode: '95334', city: 'HAPARANDA' },
  { postalCode: '95335', city: 'HAPARANDA' },
  { postalCode: '95336', city: 'HAPARANDA' },
  { postalCode: '95337', city: 'HAPARANDA' },
  { postalCode: '95385', city: 'HAPARANDA' },
  { postalCode: '95391', city: 'HAPARANDA' },
  { postalCode: '95392', city: 'HAPARANDA' },
  { postalCode: '95393', city: 'KARUNGI' },
  { postalCode: '95394', city: 'SESKARÖ' },
  { postalCode: '95395', city: 'NIKKALA' },
  { postalCode: '95421', city: 'GAMMELSTAD' },
  { postalCode: '95422', city: 'GAMMELSTAD' },
  { postalCode: '95423', city: 'GAMMELSTAD' },
  { postalCode: '95431', city: 'GAMMELSTAD' },
  { postalCode: '95432', city: 'GAMMELSTAD' },
  { postalCode: '95433', city: 'GAMMELSTAD' },
  { postalCode: '95434', city: 'GAMMELSTAD' },
  { postalCode: '95435', city: 'GAMMELSTAD' },
  { postalCode: '95441', city: 'SÖDRA SUNDERBYN' },
  { postalCode: '95442', city: 'SÖDRA SUNDERBYN' },
  { postalCode: '95521', city: 'RÅNEÅ' },
  { postalCode: '95522', city: 'RÅNEÅ' },
  { postalCode: '95531', city: 'RÅNEÅ' },
  { postalCode: '95532', city: 'RÅNEÅ' },
  { postalCode: '95591', city: 'RÅNEÅ' },
  { postalCode: '95592', city: 'RÅNEÅ' },
  { postalCode: '95595', city: 'NIEMISEL' },
  { postalCode: '95596', city: 'VITÅ' },
  { postalCode: '95599', city: 'RÅNEÅ' },
  { postalCode: '95601', city: 'ÖVERKALIX' },
  { postalCode: '95620', city: 'ÖVERKALIX' },
  { postalCode: '95621', city: 'ÖVERKALIX' },
  { postalCode: '95622', city: 'ÖVERKALIX' },
  { postalCode: '95623', city: 'ÖVERKALIX' },
  { postalCode: '95631', city: 'ÖVERKALIX' },
  { postalCode: '95632', city: 'ÖVERKALIX' },
  { postalCode: '95681', city: 'ÖVERKALIX' },
  { postalCode: '95691', city: 'ÖVERKALIX' },
  { postalCode: '95692', city: 'ÖVERKALIX' },
  { postalCode: '95693', city: 'ÖVERKALIX' },
  { postalCode: '95698', city: 'LANSJÄRV' },
  { postalCode: '95699', city: 'ÖVERKALIX' },
  { postalCode: '95701', city: 'ÖVERTORNEÅ' },
  { postalCode: '95720', city: 'ÖVERTORNEÅ' },
  { postalCode: '95721', city: 'ÖVERTORNEÅ' },
  { postalCode: '95722', city: 'ÖVERTORNEÅ' },
  { postalCode: '95723', city: 'JUOKSENGI' },
  { postalCode: '95731', city: 'ÖVERTORNEÅ' },
  { postalCode: '95732', city: 'ÖVERTORNEÅ' },
  { postalCode: '95785', city: 'ÖVERTORNEÅ' },
  { postalCode: '95791', city: 'ÖVERTORNEÅ' },
  { postalCode: '95792', city: 'ÖVERTORNEÅ' },
  { postalCode: '95793', city: 'PELLO' },
  { postalCode: '95794', city: 'ÖVERTORNEÅ' },
  { postalCode: '95795', city: 'HEDENÄSET' },
  { postalCode: '95799', city: 'ÖVERTORNEÅ' },
  { postalCode: '96024', city: 'HARADS' },
  { postalCode: '96030', city: 'VUOLLERIM' },
  { postalCode: '96032', city: 'SNESUDDEN' },
  { postalCode: '96033', city: 'MURJEK' },
  { postalCode: '96100', city: 'BODEN' },
  { postalCode: '96117', city: 'BODEN' },
  { postalCode: '96119', city: 'BODEN' },
  { postalCode: '96120', city: 'BODEN' },
  { postalCode: '96121', city: 'BODEN' },
  { postalCode: '96122', city: 'BODEN' },
  { postalCode: '96123', city: 'BODEN' },
  { postalCode: '96128', city: 'BODEN' },
  { postalCode: '96131', city: 'BODEN' },
  { postalCode: '96132', city: 'BODEN' },
  { postalCode: '96133', city: 'BODEN' },
  { postalCode: '96134', city: 'BODEN' },
  { postalCode: '96135', city: 'BODEN' },
  { postalCode: '96136', city: 'BODEN' },
  { postalCode: '96137', city: 'BODEN' },
  { postalCode: '96138', city: 'BODEN' },
  { postalCode: '96139', city: 'BODEN' },
  { postalCode: '96140', city: 'BODEN' },
  { postalCode: '96142', city: 'BODEN' },
  { postalCode: '96143', city: 'BODEN' },
  { postalCode: '96144', city: 'BODEN' },
  { postalCode: '96145', city: 'BODEN' },
  { postalCode: '96146', city: 'BODEN' },
  { postalCode: '96147', city: 'BODEN' },
  { postalCode: '96148', city: 'BODEN' },
  { postalCode: '96149', city: 'BODEN' },
  { postalCode: '96150', city: 'BODEN' },
  { postalCode: '96151', city: 'BODEN' },
  { postalCode: '96161', city: 'BODEN' },
  { postalCode: '96162', city: 'BODEN' },
  { postalCode: '96164', city: 'BODEN' },
  { postalCode: '96167', city: 'BODEN' },
  { postalCode: '96168', city: 'BODEN' },
  { postalCode: '96173', city: 'BODEN' },
  { postalCode: '96174', city: 'BODEN' },
  { postalCode: '96175', city: 'BODEN' },
  { postalCode: '96176', city: 'BODEN' },
  { postalCode: '96177', city: 'BODEN' },
  { postalCode: '96186', city: 'BODEN' },
  { postalCode: '96191', city: 'BODEN' },
  { postalCode: '96192', city: 'BODEN' },
  { postalCode: '96193', city: 'BODEN' },
  { postalCode: '96194', city: 'MÅRDSEL' },
  { postalCode: '96195', city: 'BODEN' },
  { postalCode: '96196', city: 'BODEN' },
  { postalCode: '96197', city: 'GUNNARSBYN' },
  { postalCode: '96198', city: 'BODEN' },
  { postalCode: '96201', city: 'JOKKMOKK' },
  { postalCode: '96202', city: 'KVIKKJOKK' },
  { postalCode: '96203', city: 'MATTISUDDEN' },
  { postalCode: '96205', city: 'KÅBDALIS' },
  { postalCode: '96220', city: 'JOKKMOKK' },
  { postalCode: '96221', city: 'JOKKMOKK' },
  { postalCode: '96222', city: 'JOKKMOKK' },
  { postalCode: '96223', city: 'JOKKMOKK' },
  { postalCode: '96224', city: 'JOKKMOKK' },
  { postalCode: '96225', city: 'JOKKMOKK' },
  { postalCode: '96226', city: 'JOKKMOKK' },
  { postalCode: '96231', city: 'JOKKMOKK' },
  { postalCode: '96232', city: 'JOKKMOKK' },
  { postalCode: '96233', city: 'JOKKMOKK' },
  { postalCode: '96280', city: 'JOKKMOKK' },
  { postalCode: '96285', city: 'JOKKMOKK' },
  { postalCode: '96298', city: 'RANDIJAUR' },
  { postalCode: '96299', city: 'JOKKMOKK' },
  { postalCode: '97100', city: 'LULEÅ' },
  { postalCode: '97102', city: 'LULEÅ' },
  { postalCode: '97103', city: 'LULEÅ' },
  { postalCode: '97104', city: 'LULEÅ' },
  { postalCode: '97105', city: 'LULEÅ' },
  { postalCode: '97106', city: 'LULEÅ' },
  { postalCode: '97107', city: 'LULEÅ' },
  { postalCode: '97108', city: 'LULEÅ' },
  { postalCode: '97109', city: 'LULEÅ' },
  { postalCode: '97110', city: 'LULEÅ' },
  { postalCode: '97125', city: 'LULEÅ' },
  { postalCode: '97126', city: 'LULEÅ' },
  { postalCode: '97127', city: 'LULEÅ' },
  { postalCode: '97128', city: 'LULEÅ' },
  { postalCode: '97129', city: 'LULEÅ' },
  { postalCode: '97160', city: 'LULEÅ' },
  { postalCode: '97170', city: 'LULEÅ' },
  { postalCode: '97171', city: 'LULEÅ' },
  { postalCode: '97172', city: 'LULEÅ' },
  { postalCode: '97173', city: 'LULEÅ' },
  { postalCode: '97174', city: 'LULEÅ' },
  { postalCode: '97176', city: 'LULEÅ' },
  { postalCode: '97177', city: 'LULEÅ' },
  { postalCode: '97179', city: 'LULEÅ' },
  { postalCode: '97180', city: 'LULEÅ' },
  { postalCode: '97182', city: 'LULEÅ' },
  { postalCode: '97183', city: 'LULEÅ' },
  { postalCode: '97185', city: 'LULEÅ' },
  { postalCode: '97186', city: 'LULEÅ' },
  { postalCode: '97187', city: 'LULEÅ' },
  { postalCode: '97188', city: 'LULEÅ' },
  { postalCode: '97189', city: 'LULEÅ' },
  { postalCode: '97190', city: 'LULEÅ' },
  { postalCode: '97221', city: 'LULEÅ' },
  { postalCode: '97222', city: 'LULEÅ' },
  { postalCode: '97223', city: 'LULEÅ' },
  { postalCode: '97231', city: 'LULEÅ' },
  { postalCode: '97232', city: 'LULEÅ' },
  { postalCode: '97233', city: 'LULEÅ' },
  { postalCode: '97234', city: 'LULEÅ' },
  { postalCode: '97235', city: 'LULEÅ' },
  { postalCode: '97236', city: 'LULEÅ' },
  { postalCode: '97238', city: 'LULEÅ' },
  { postalCode: '97239', city: 'LULEÅ' },
  { postalCode: '97241', city: 'LULEÅ' },
  { postalCode: '97242', city: 'LULEÅ' },
  { postalCode: '97245', city: 'LULEÅ' },
  { postalCode: '97251', city: 'LULEÅ' },
  { postalCode: '97252', city: 'LULEÅ' },
  { postalCode: '97253', city: 'LULEÅ' },
  { postalCode: '97254', city: 'LULEÅ' },
  { postalCode: '97300', city: 'LULEÅ' },
  { postalCode: '97318', city: 'LULEÅ' },
  { postalCode: '97321', city: 'LULEÅ' },
  { postalCode: '97322', city: 'LULEÅ' },
  { postalCode: '97323', city: 'LULEÅ' },
  { postalCode: '97324', city: 'LULEÅ' },
  { postalCode: '97325', city: 'LULEÅ' },
  { postalCode: '97328', city: 'LULEÅ' },
  { postalCode: '97329', city: 'LULEÅ' },
  { postalCode: '97331', city: 'LULEÅ' },
  { postalCode: '97332', city: 'LULEÅ' },
  { postalCode: '97333', city: 'LULEÅ' },
  { postalCode: '97334', city: 'LULEÅ' },
  { postalCode: '97335', city: 'LULEÅ' },
  { postalCode: '97341', city: 'LULEÅ' },
  { postalCode: '97342', city: 'LULEÅ' },
  { postalCode: '97343', city: 'LULEÅ' },
  { postalCode: '97344', city: 'LULEÅ' },
  { postalCode: '97345', city: 'LULEÅ' },
  { postalCode: '97346', city: 'LULEÅ' },
  { postalCode: '97347', city: 'LULEÅ' },
  { postalCode: '97421', city: 'LULEÅ' },
  { postalCode: '97422', city: 'LULEÅ' },
  { postalCode: '97432', city: 'LULEÅ' },
  { postalCode: '97433', city: 'LULEÅ' },
  { postalCode: '97434', city: 'LULEÅ' },
  { postalCode: '97435', city: 'LULEÅ' },
  { postalCode: '97436', city: 'LULEÅ' },
  { postalCode: '97437', city: 'LULEÅ' },
  { postalCode: '97438', city: 'LULEÅ' },
  { postalCode: '97439', city: 'LULEÅ' },
  { postalCode: '97441', city: 'LULEÅ' },
  { postalCode: '97442', city: 'LULEÅ' },
  { postalCode: '97443', city: 'LULEÅ' },
  { postalCode: '97444', city: 'LULEÅ' },
  { postalCode: '97451', city: 'LULEÅ' },
  { postalCode: '97452', city: 'LULEÅ' },
  { postalCode: '97453', city: 'LULEÅ' },
  { postalCode: '97454', city: 'LULEÅ' },
  { postalCode: '97455', city: 'LULEÅ' },
  { postalCode: '97499', city: 'LULEÅ' },
  { postalCode: '97561', city: 'LULEÅ' },
  { postalCode: '97591', city: 'LULEÅ' },
  { postalCode: '97592', city: 'LULEÅ' },
  { postalCode: '97593', city: 'LULEÅ' },
  { postalCode: '97594', city: 'LULEÅ' },
  { postalCode: '97595', city: 'LULEÅ' },
  { postalCode: '97596', city: 'LULEÅ' },
  { postalCode: '97597', city: 'LULEÅ' },
  { postalCode: '97598', city: 'LULEÅ' },
  { postalCode: '97627', city: 'LULEÅ' },
  { postalCode: '97631', city: 'LULEÅ' },
  { postalCode: '97632', city: 'LULEÅ' },
  { postalCode: '97633', city: 'LULEÅ' },
  { postalCode: '97634', city: 'LULEÅ' },
  { postalCode: '97636', city: 'LULEÅ' },
  { postalCode: '97751', city: 'LULEÅ' },
  { postalCode: '97752', city: 'LULEÅ' },
  { postalCode: '97753', city: 'LULEÅ' },
  { postalCode: '97754', city: 'LULEÅ' },
  { postalCode: '97755', city: 'LULEÅ' },
  { postalCode: '97756', city: 'LULEÅ' },
  { postalCode: '97775', city: 'LULEÅ' },
  { postalCode: '97800', city: 'LULEÅ' },
  { postalCode: '97820', city: 'LULEÅ' },
  { postalCode: '98010', city: 'VITTANGI' },
  { postalCode: '98013', city: 'LANNAVAARA' },
  { postalCode: '98014', city: 'ÖVRE SOPPERO' },
  { postalCode: '98016', city: 'KARESUANDO' },
  { postalCode: '98020', city: 'SVAPPAVAARA' },
  { postalCode: '98041', city: 'HAKKAS' },
  { postalCode: '98042', city: 'ULLATTI' },
  { postalCode: '98045', city: 'VETTASJÄRVI' },
  { postalCode: '98060', city: 'KORPILOMBOLO' },
  { postalCode: '98061', city: 'TÄRENDÖ' },
  { postalCode: '98062', city: 'JUNOSUANDO' },
  { postalCode: '98063', city: 'KANGOS' },
  { postalCode: '98065', city: 'KAINULASJÄRVI' },
  { postalCode: '98101', city: 'KIRUNA' },
  { postalCode: '98102', city: 'KIRUNA' },
  { postalCode: '98105', city: 'KIRUNA' },
  { postalCode: '98107', city: 'ABISKO' },
  { postalCode: '98115', city: 'KIRUNA' },
  { postalCode: '98120', city: 'KIRUNA' },
  { postalCode: '98121', city: 'KIRUNA' },
  { postalCode: '98122', city: 'KIRUNA' },
  { postalCode: '98123', city: 'KIRUNA' },
  { postalCode: '98124', city: 'KIRUNA' },
  { postalCode: '98127', city: 'KIRUNA' },
  { postalCode: '98128', city: 'KIRUNA' },
  { postalCode: '98130', city: 'KIRUNA' },
  { postalCode: '98131', city: 'KIRUNA' },
  { postalCode: '98132', city: 'KIRUNA' },
  { postalCode: '98133', city: 'KIRUNA' },
  { postalCode: '98134', city: 'KIRUNA' },
  { postalCode: '98135', city: 'KIRUNA' },
  { postalCode: '98136', city: 'KIRUNA' },
  { postalCode: '98137', city: 'KIRUNA' },
  { postalCode: '98138', city: 'KIRUNA' },
  { postalCode: '98139', city: 'KIRUNA' },
  { postalCode: '98140', city: 'KIRUNA' },
  { postalCode: '98141', city: 'KIRUNA' },
  { postalCode: '98142', city: 'KIRUNA' },
  { postalCode: '98143', city: 'KIRUNA' },
  { postalCode: '98144', city: 'KIRUNA' },
  { postalCode: '98145', city: 'KIRUNA' },
  { postalCode: '98146', city: 'KURRAVAARA' },
  { postalCode: '98147', city: 'KIRUNA' },
  { postalCode: '98180', city: 'KIRUNA' },
  { postalCode: '98181', city: 'KIRUNA' },
  { postalCode: '98182', city: 'KIRUNA' },
  { postalCode: '98183', city: 'KIRUNA' },
  { postalCode: '98184', city: 'KIRUNA' },
  { postalCode: '98185', city: 'KIRUNA' },
  { postalCode: '98186', city: 'KIRUNA' },
  { postalCode: '98187', city: 'KIRUNA' },
  { postalCode: '98188', city: 'KIRUNA' },
  { postalCode: '98189', city: 'KIRUNA' },
  { postalCode: '98191', city: 'JUKKASJÄRVI' },
  { postalCode: '98192', city: 'KIRUNA' },
  { postalCode: '98193', city: 'BJÖRKLIDEN' },
  { postalCode: '98194', city: 'RIKSGRÄNSEN' },
  { postalCode: '98195', city: 'KIRUNA' },
  { postalCode: '98199', city: 'KIRUNA' },
  { postalCode: '98201', city: 'GÄLLIVARE' },
  { postalCode: '98203', city: 'GÄLLIVARE' },
  { postalCode: '98204', city: 'SKAULO' },
  { postalCode: '98205', city: 'PUOLTIKASVAARA' },
  { postalCode: '98206', city: 'NATTAVAARA' },
  { postalCode: '98207', city: 'NATTAVAARABY' },
  { postalCode: '98220', city: 'GÄLLIVARE' },
  { postalCode: '98221', city: 'GÄLLIVARE' },
  { postalCode: '98222', city: 'GÄLLIVARE' },
  { postalCode: '98228', city: 'GÄLLIVARE' },
  { postalCode: '98231', city: 'GÄLLIVARE' },
  { postalCode: '98232', city: 'GÄLLIVARE' },
  { postalCode: '98234', city: 'GÄLLIVARE' },
  { postalCode: '98235', city: 'GÄLLIVARE' },
  { postalCode: '98236', city: 'GÄLLIVARE' },
  { postalCode: '98237', city: 'GÄLLIVARE' },
  { postalCode: '98238', city: 'GÄLLIVARE' },
  { postalCode: '98239', city: 'GÄLLIVARE' },
  { postalCode: '98241', city: 'GÄLLIVARE' },
  { postalCode: '98260', city: 'PORJUS' },
  { postalCode: '98281', city: 'GÄLLIVARE' },
  { postalCode: '98282', city: 'GÄLLIVARE' },
  { postalCode: '98291', city: 'GÄLLIVARE' },
  { postalCode: '98292', city: 'GÄLLIVARE' },
  { postalCode: '98299', city: 'GÄLLIVARE' },
  { postalCode: '98303', city: 'KOSKULLSKULLE' },
  { postalCode: '98321', city: 'MALMBERGET' },
  { postalCode: '98322', city: 'MALMBERGET' },
  { postalCode: '98325', city: 'MALMBERGET' },
  { postalCode: '98331', city: 'MALMBERGET' },
  { postalCode: '98332', city: 'MALMBERGET' },
  { postalCode: '98333', city: 'MALMBERGET' },
  { postalCode: '98334', city: 'MALMBERGET' },
  { postalCode: '98335', city: 'MALMBERGET' },
  { postalCode: '98336', city: 'MALMBERGET' },
  { postalCode: '98341', city: 'KOSKULLSKULLE' },
  { postalCode: '98381', city: 'MALMBERGET' },
  { postalCode: '98401', city: 'PAJALA' },
  { postalCode: '98420', city: 'PAJALA' },
  { postalCode: '98421', city: 'PAJALA' },
  { postalCode: '98422', city: 'PAJALA' },
  { postalCode: '98423', city: 'PAJALA' },
  { postalCode: '98431', city: 'PAJALA' },
  { postalCode: '98432', city: 'PAJALA' },
  { postalCode: '98485', city: 'PAJALA' },
  { postalCode: '98491', city: 'PAJALA' },
  { postalCode: '98492', city: 'PAJALA' },
  { postalCode: '98494', city: 'LOVIKKA' },
  { postalCode: '98495', city: 'MUODOSLOMPOLO' },
  { postalCode: '98499', city: 'PAJALA' }
]
