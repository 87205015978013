import React from 'react'
import { Component } from 'reflux'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DynamicTable from 'Components/dynamicTable'
import { toast } from 'react-toastify'
import 'moment/locale/da'
import { CampaignActions, CampaignStore, STATUS } from 'Stores/campaignStore'
import CampaignUserPreview from 'Modules/campaigns/CampaignUserPreview.js'
import { Badge } from 'reactstrap'

export default class CampaignPreview extends Component {
  constructor(props) {
    super(props)
    this.stores = [CampaignStore]
    this.storeKeys = ['campaignPreview', 'status', 'campaignID']
  }

  componentDidMount() {
    CampaignActions.getCampaignPreview(this.props.match.params.CampaignID)
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.PREVIEW_CAMPAIGN_ERROR) {
      toast.error('Campaign preview failed', {
        autoClose: 7500,
        onClose: () => {
          CampaignActions.clearListToast()
        }
      })
    }
  }

  render() {
    if (this.state.status === STATUS.FETCHING_CAMPAIGN_PREVIEW) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>Kampagne Preview - Clause Results</strong>}>
          <DynamicTable
            data={this.state.campaignPreview.clauseResults}
            options={{
              fieldFunctions: {
                status: e => {
                  if (e.status) {
                    if (e.status.lesserThanMinimum) {
                      return <Badge color='warning'>{'Afventer'}</Badge>
                    }
                    if (e.status.greaterThanMaximum) {
                      return <Badge color='danger'>{'Overstiger betingelser'}</Badge>
                    }
                  }
                }
              }
            }}
          />
        </CollapsibleCard>
        <CollapsibleCard startOpen header={<strong>Kampagne Preview - Action Results</strong>}>
          <DynamicTable
            data={this.state.campaignPreview.actionResults}
            options={{
              fieldFunctions: {
                status: e => {
                  if (e.status) {
                    if (e.status.lesserThanMinimum) {
                      return <Badge color='warning'>{'Afventer'}</Badge>
                    }
                    if (e.status.greaterThanMaximum) {
                      return <Badge color='danger'>{'Overstiger betingelser'}</Badge>
                    }
                  }
                }
              }
            }}
          />
        </CollapsibleCard>
        <CampaignUserPreview users={this.state.campaignPreview.users} />
      </React.Fragment>
    )
  }
}
